import { AnswerTemplateData, Answers } from '../@types/Knowledge/types'
import { shouldTranslationBeDisplayed } from './translationsUtils'

export const ANSWER_TEMPLATE_VARIABLE_REGEX = /\$\{(.+?)\}/g // matches variables in the form of ${info.category.variable}

/**
 * Builds final answer by filling answer template with answer data.
 * If template has no variables defined it uses the answerTemplate as answer.
 * If template has variables defined, but answer data does not provide values, gives back without filled in data.
 * @param {string} answerTemplate
 * @param {AnswerTemplateData} answerData
 */
export function fillAnswerTemplate(answerTemplate: string, answerData: AnswerTemplateData): string {
  const matches: RegExpExecArray[] = []
  let m
  // find all variables in answer template
  while ((m = ANSWER_TEMPLATE_VARIABLE_REGEX.exec(answerTemplate)) !== null) {
    matches.push(m)
  }

  // if no variables in template, return template as answer
  if (matches.length === 0) return answerTemplate

  let answer = answerTemplate
  // iterate over matches and replace with variables
  // if no variable for match throw exception
  // NOTE: Structure: ${category.variable}
  try {
    for (const match of matches) {
      const parts = match[1].split('.')
      if (parts[0] === 'info') parts.splice(0, 1) // remove first part "info" as it is the same for all

      let foundVariable = false // as soon as a var is found for the match, break and handle next match
      for (const template of answerData) {
        // for each template
        // check if category fits
        if (template.topic.name === parts[0]) {
          // check if variable name fits
          for (const varObject of template.data) {
            if (varObject.key === parts[1]) {
              // variable fits

              const value = varObject.value
              foundVariable = true

              // fill answer
              // we need to escape the '$' indicator in the variable name for the regex to work
              const patternEscaped = match[0].replace('$', '\\$')
              const re = new RegExp(patternEscaped, 'g')
              answer = answer.replace(re, value)
              break
            }
          }
          if (foundVariable) break
        }
      }
      // if not found, just go to the next
      // if (!foundVariable) {
      //   break
      // }
    }
  } catch (err) {
    // TODO: Error Handling
    // if (!(err instanceof NoDataError)) {
    //   this.logEvent({
    //     event_name: 'Error filling template. No NoDataError, something else',
    //     error: err,
    //   })
    // }
    // throw err
  }
  return answer
}

/**
 * Checks if all texts of all answers of a language have translation.
 * Returns if this is the case and also total number of terms and number of translations.
 * @param terms
 * @param primaryLanguage
 * @param language
 */
export function areAllTextsOfAnswersTranslated(
  answers: Answers,
  primaryLanguage: string,
  language: string,
): { complete: boolean; nrTotalTexts: number; nrTranslatedTexts: number } {
  const answersPrimary = answers[primaryLanguage]
  const answersLang = answers[language]

  let translatedTexts = 0
  let totalTexts = 0

  for (const answer of Object.values(answersPrimary)) {
    const answerId = answer.answerId
    if (answer.answerTemplate && shouldTranslationBeDisplayed(answer.answerTemplate)) {
      totalTexts += 1
      if (answersLang && answersLang[answerId]?.answerTemplate) translatedTexts += 1
    }

    if (answer.intentTriggerButtons) {
      if (answer.intentTriggerButtons.startDialogButton) totalTexts += 1
      if (answersLang && answersLang[answerId]?.intentTriggerButtons?.startDialogButton) translatedTexts += 1

      if (answer.intentTriggerButtons.skipButton) totalTexts += 1
      if (answersLang && answersLang[answerId]?.intentTriggerButtons?.skipButton) translatedTexts += 1
    }

    if (answer.actions && answersLang && answersLang[answerId]?.actions?.length === answer.actions.length) {
      for (let idx = 0; idx < answer.actions.length; idx++) {
        if (answer.actions[idx].action) totalTexts += 1
        if (answersLang && answersLang[answerId]?.actions[idx].action) translatedTexts += 1
      }
    }
  }

  return {
    complete: translatedTexts === totalTexts,
    nrTotalTexts: totalTexts,
    nrTranslatedTexts: translatedTexts,
  }
}
