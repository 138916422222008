import React, { memo, useEffect, useState } from 'react'

//
import { makeStyles } from 'tss-react/mui'

import { TextField, Typography } from '@mui/material'
// Types
import { Node } from '../../../../../../../@types/Flowchart/types'
import { TranslationFile } from '../../../../../../../@types/Translations/types'
import { Card, IContainer, ITextBlock } from '../../../../../../../@types/SmartCards/types'
import { SmartCardBase, SmartCardTitleContainer, SmartCardTitleBlock } from 'components/SmartCard/smartCardModules'
import { cloneDeep, isEqual } from 'lodash'

const useStyles = makeStyles()((theme) => ({
  editor: {},
}))

type CardTitleProps = {
  card: Card
  setCardCallback: (card: Card) => void
  handleNextStage: () => void // Since this has only one Input press Enter to continue - this is the callback for that
}

// TODO: Since this has only one Input press Enter to continue
// NOTE: not using onBlur to trigger setCardCallback - because we want a live preview
export default memo(function CardTitle({
  card: origCard,
  setCardCallback,
  handleNextStage,
}: CardTitleProps): React.ReactElement {
  const { classes } = useStyles()
  const [card, setCard] = useState<Card>(origCard)
  const [title, setTitle] = useState<string>('')

  useEffect(() => {
    // Check on initial render if a title is given and if so set it in the state else leave empty string
    if (card.data.body) {
      const titleIndex = card.data.body?.findIndex((block, index) => block.id === SmartCardTitleContainer.id)
      if (typeof titleIndex !== 'undefined' && titleIndex >= 0) {
        // Has Title Container - Check for Title block and edit
        const titleContainer = card.data.body[titleIndex] as IContainer
        const blockIndex = titleContainer.items?.findIndex((block, index) => block.id === SmartCardTitleBlock.id)
        if (typeof blockIndex !== 'undefined' && blockIndex >= 0) {
          const titleBlock = titleContainer.items?.[blockIndex]
          if (titleBlock) setTitle(titleBlock.text)
        }
      }
    }
  }, [])

  useEffect(() => {
    if (title.length === 0 && card.data.body) {
      // Remove title container
      // Check for existing title container in the card
      const titleIndex = card.data.body?.findIndex((block, index) => block.id === SmartCardTitleContainer.id)
      if (typeof titleIndex !== 'undefined' && titleIndex >= 0) {
        // Remove Title container, since the title property is empty => aka no title set
        const cardTmp = { ...card } // create copy so the useEffect on the card triggers
        cardTmp.data.body?.splice(titleIndex, 1)
        setCard(cardTmp)
      }
    } else if (card.data.body) {
      // Check if Title container is set and edit it, else create one with title
      const titleIndex = card.data.body?.findIndex((block, index) => block.id === SmartCardTitleContainer.id)
      if (typeof titleIndex !== 'undefined' && titleIndex >= 0) {
        // Has Title Container - Check for Title block and edit
        const titleContainer = card.data.body[titleIndex] as IContainer
        const blockIndex = titleContainer.items?.findIndex((block, index) => block.id === SmartCardTitleBlock.id)
        // Title Block exists?
        if (typeof blockIndex !== 'undefined' && blockIndex >= 0) {
          // title block exists -> edit and setCard
          const titleBlock = titleContainer.items?.[blockIndex]
          if (titleBlock) {
            titleBlock.text = title
            titleContainer.items?.splice(blockIndex, 1, titleBlock)
          }
        } else {
          // set new title block, edit and setCard
          const titleBlock = { ...SmartCardTitleBlock } as ITextBlock
          titleBlock.text = title
          titleContainer.items?.push(titleBlock)
        }
        const cardTmp = { ...card } // create copy so the useEffect on the card triggers
        // exchange the title container with the new one
        cardTmp.data.body?.splice(titleIndex, 1, titleContainer) // NOTE: set title always at titleIndex (which should be 0) and push rest
        setCard(cardTmp)
      } else {
        // Has no title Container
        // Create title Container and title block
        const titleContainer = cloneDeep(SmartCardTitleContainer) as IContainer
        const titleBlock = cloneDeep(SmartCardTitleBlock) as ITextBlock
        titleBlock.text = title
        titleContainer.items?.push(titleBlock)
        // Add titleContainer to the card
        const cardTmp = { ...card } // create copy so the useEffect on the card triggers
        // Add as highest body element since its the title
        cardTmp.data.body?.splice(0, 0, titleContainer) // NOTE: set title always at 0 and push rest
        setCard(cardTmp)
      }
    }
  }, [title])

  useEffect(() => {
    setCardCallback(card)
  }, [card])

  return (
    <div className={classes.editor}>
      <form
        onSubmit={(): void => {
          handleNextStage()
        }}
      >
        <TextField
          id='create-new-card-name'
          label='Karten Titel'
          value={title}
          fullWidth
          onChange={(event): void => setTitle(event.target.value)}
          margin='normal'
          autoFocus
          inputProps={{
            autoComplete: 'off',
          }}
        />
      </form>
    </div>
  )
}, isEqual)
