import React, { ReactElement } from 'react'
import { makeStyles } from 'tss-react/mui'

import { IconButton } from '@mui/material'
import { CheckCircle, Delete } from '@mui/icons-material'

import { useDraggableContextDispatch } from '../../../hooks/contexts/useDraggableContext'

const useStyles = makeStyles()((theme) => ({
  footer: {
    display: 'flex',
    width: '100%',
  },
  // iconButton: {
  //   marginLeft: 'auto',
  // },
  danger: {
    color: theme.palette.error.main,
  },
  successButton: {
    marginLeft: 'auto',
    color: theme.palette.success.main,
  },
}))

type PopupFooterProps = {
  onConfirm: () => void
  onDelete?: () => void
  disabledConfirm?: boolean
}

export const PopupFooter = ({ onConfirm, onDelete, disabledConfirm }: PopupFooterProps): ReactElement => {
  const { classes } = useStyles()
  const draggableContextDispatch = useDraggableContextDispatch()

  const onConfirmButtonClick = (): void => {
    onConfirm()
  }

  const onDeleteButtonClick = (): void => {
    if (onDelete) onDelete()
  }

  return (
    <div className={classes.footer}>
      {/* <div className={classes.actions}> */}
      {onDelete && (
        <IconButton
          onMouseEnter={(): void => draggableContextDispatch({ type: false })}
          onMouseLeave={(): void => draggableContextDispatch({ type: true })}
          onClick={onDeleteButtonClick}
          size='small'
        >
          <Delete className={classes.danger} />
        </IconButton>
      )}
      <IconButton
        onMouseEnter={(): void => draggableContextDispatch({ type: false })}
        onMouseLeave={(): void => draggableContextDispatch({ type: true })}
        className={classes.successButton}
        onClick={onConfirmButtonClick}
        disabled={disabledConfirm ? disabledConfirm : false}
        size='small'
      >
        <CheckCircle />
      </IconButton>
      {/* </div> */}
    </div>
  )
}
