import React from 'react'
import { useParams } from 'react-router-dom'

import { useBotContext } from '../../../../hooks/contexts/bot-context'
// Custom Components

import TermTranslationPrimaryLanguage from './TermTranslationPrimaryLanguage'
import TermTranslationSecondaryLanguage from './TermTranslationSecondaryLanguage'

/**
 * This is the "root" component of the term translations.
 */
export default function TermTranslations(): React.ReactElement {
  const { langCode } = useParams()
  const { bot } = useBotContext()

  return langCode === bot?.primaryLanguage ? (
    // Primary language
    <TermTranslationPrimaryLanguage />
  ) : (
    // Secondary language
    <TermTranslationSecondaryLanguage />
  )
}
