import { cloneDeep } from 'lodash'
import { v4 as uuid } from 'uuid'
import { CustomNotification } from '../@types/CustomNotifications/types'

/**
 * Checks if notification is expired.
 * If end date < current date, it is expired.
 * @param notification
 */
export function isNotificationExpired(notification: CustomNotification): boolean {
  const now = new Date()
  const endDate = new Date(notification.endDate)
  // set end time correctly to end date
  // for this we find the latest time of the notification against which we compare
  let latestTime = new Date(notification.times[0].endTime)
  latestTime.setFullYear(2020, 0, 1) // we fix this to only compare time; date is irrelvant
  for (let i = 1; i < notification.times.length; i += 1) {
    const d = new Date(notification.times[i].endTime)
    d.setFullYear(2020, 0, 1)
    if (d > latestTime) latestTime = d
  }

  endDate.setHours(latestTime.getHours(), latestTime.getMinutes(), latestTime.getSeconds())

  return endDate < now
}

/**
 * Checks if all required properties exist for the notification to be created
 * @param notification
 */
export function isNotificationComplete(notification: CustomNotification): boolean {
  return !!(
    notification.notificationId &&
    notification.botId &&
    notification.name &&
    notification.title &&
    notification.content &&
    notification.startDate &&
    // notification.startTime &&
    notification.endDate &&
    // notification.endTime &&
    notification.times &&
    notification.type &&
    (notification.type === 'weekly' ? notification.series?.days : true)
  )
}

/**
 * Duplicates a notification.
 * Clones the existing notification.
 * Changes id and name
 * @param notification
 */
export function duplicateNotification(
  notification: CustomNotification,
  allNotifications: CustomNotification[],
): CustomNotification {
  const newNotification = cloneDeep(notification)
  newNotification.notificationId = uuid()

  // increase suffix as long as there already exists a name
  const allExistingNames = allNotifications.map((notification) => notification.name)
  let suffix = 2
  let newNotificationName = `${notification.name}-${suffix}`
  do {
    newNotificationName = `${notification.name}-${suffix}`
    if (!allExistingNames.includes(newNotificationName)) {
      newNotification.name = newNotificationName
      break
    } else {
      suffix += 1
    }
  } while (allExistingNames.includes(newNotificationName))

  return newNotification
}

// mapping of week day number to string (sunday is 0, saturday is 6)
const dayMapping = ['So.', 'Mo.', 'Di.', 'Mi.', 'Do.', 'Fr.', 'Sa.']

/**
 * Builds display string (e.g. "Einmalig: 27.10.2021" or "Wöchentlich: Mo, Di, Mi")
 */
export function buildTurnusString(notification: CustomNotification): string {
  const startdd = String(notification.startDate.getDate()).padStart(2, '0')
  const startmm = String(notification.startDate.getMonth() + 1).padStart(2, '0') //January is 0!
  const startyyyy = notification.startDate.getFullYear()

  const enddd = String(notification.endDate.getDate()).padStart(2, '0')
  const endmm = String(notification.endDate.getMonth() + 1).padStart(2, '0') //January is 0!
  const endyyyy = notification.endDate.getFullYear()

  switch (notification.type) {
    case 'once': {
      const dd = String(notification.startDate.getDate()).padStart(2, '0')
      const mm = String(notification.startDate.getMonth() + 1).padStart(2, '0') //January is 0!
      const yyyy = notification.startDate.getFullYear()
      return `Einmalig: ${dd}.${mm}.${yyyy}`
    }
    case 'daily': {
      return `Täglich von ${startdd}.${startmm}.${startyyyy} bis ${enddd}.${endmm}.${endyyyy}`
    }
    case 'weekly': {
      if (!notification.series?.days) return ''
      let days = ''
      for (const [idx, day] of notification.series.days.entries()) {
        if (notification.series.days.length === 1) {
          days += dayMapping[day]
        } else if (idx < notification.series.days.length - 1) {
          days += ` ${dayMapping[day]},`
        } else {
          // last entry
          days += ` ${dayMapping[day]}`
        }
      }
      return `${days} von ${startdd}.${startmm}.${startyyyy} bis ${enddd}.${endmm}.${endyyyy}`
    }
    default:
      return ''
  }
}

/**
 * Builds time string (e.g. "Uhrzeit: 10:00 Uhr bis 13:00 Uhr")
 */
export function buildTimeString(notification: CustomNotification, timespanIndex?: number): string {
  if (typeof timespanIndex !== 'undefined') {
    return `Uhrzeit: ${String(notification.times[timespanIndex].startTime.getHours()).padStart(2, '0')}:${String(
      notification.times[timespanIndex].startTime.getMinutes(),
    ).padStart(2, '0')} Uhr bis ${String(notification.times[timespanIndex].endTime.getHours()).padStart(
      2,
      '0',
    )}:${String(notification.times[timespanIndex].endTime.getMinutes()).padStart(2, '0')} Uhr`
  } else {
    // show all
    let string = notification.times.length > 1 ? 'Uhrzeiten: ' : 'Uhrzeit: '

    for (const [index, time] of notification.times.entries()) {
      if (index > 0) string += ', '
      string += `${String(time.startTime.getHours()).padStart(2, '0')}:${String(time.startTime.getMinutes()).padStart(
        2,
        '0',
      )} Uhr bis ${String(time.endTime.getHours()).padStart(2, '0')}:${String(time.endTime.getMinutes()).padStart(
        2,
        '0',
      )} Uhr`
    }

    return string
  }
}
