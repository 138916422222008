import React from 'react'
import { Dialog as MUIDialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { Close } from '@mui/icons-material'
import Button from '../Buttons/Button'

type StyleProps = {
  size?: 'small' | 'medium' | 'medium-large' | 'large' | 'extra-large'
}

// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. ArrowFunctionExpression in CSS prop.
const useStyles = makeStyles<StyleProps>()((theme, props, classes) => ({
  dialog: {
    zIndex: 1,
    borderRadius:
      typeof theme.shape.borderRadius === 'string'
        ? parseInt(theme.shape.borderRadius) * 2
        : theme.shape.borderRadius * 2,
    padding: theme.spacing(1),
    height:
      props.size === 'extra-large'
        ? '1000px'
        : props.size === 'large'
        ? '900px'
        : props.size === 'medium-large'
        ? '700px'
        : props.size === 'medium'
        ? '500px'
        : '300px',
  },
  dialogPaper: {
    overflow: 'visible',
  },
  titleContainer: {
    display: 'flex',
  },
  dialogTitle: {
    padding: '24px 24px 0 24px',
    overflow: 'hidden',
  },
  dialogTitleText: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  backButton: {
    margin: `auto ${theme.spacing(1)} auto 0`,
  },
  closeButton: {
    marginLeft: 'auto',
    marginBottom: 'auto',
  },
}))

type DialogProps = {
  children: React.ReactNode
  id: string
  size?: 'small' | 'medium' | 'medium-large' | 'large' | 'extra-large'
  open?: boolean
  closable?: boolean
  disableBackdropClick?: boolean
  title?: string | React.ReactNode
  showBackButton?: boolean
  onBackClick?: () => void
  onClose?: () => void
  primaryActionButton?: React.ReactNode
  onSecondaryActionClick?: () => void
  secondaryActionText?: string
  isSecondaryActionDisabled?: boolean
  additionalActionButtons?: React.ReactNode[] // additional action buttons that are shown in the left part of the actions area
}

export default function Dialog({
  children,
  id,
  size = 'medium',
  open,
  closable = true,
  disableBackdropClick = false,
  title,
  showBackButton,
  onBackClick,
  onClose,
  primaryActionButton,
  onSecondaryActionClick,
  secondaryActionText,
  isSecondaryActionDisabled,
  additionalActionButtons,
}: DialogProps): React.ReactElement {
  const { classes } = useStyles({ size })

  return (
    <MUIDialog
      open={!!open}
      onClose={(event, reason) => {
        if (closable && typeof onClose !== 'undefined') {
          if (reason === 'backdropClick') {
            if (!disableBackdropClick) onClose()
          } else {
            onClose()
          }
        }
      }}
      maxWidth={
        size === 'extra-large'
          ? 'xl'
          : size === 'large'
          ? 'lg'
          : size === 'medium' || size === 'medium-large'
          ? 'md'
          : 'sm'
      }
      classes={{ paper: classes.dialog }}
      PaperProps={{ className: classes.dialogPaper }}
      disableEscapeKeyDown={!closable}
      fullWidth
    >
      <div className={classes.titleContainer}>
        {showBackButton && (
          <IconButton className={classes.backButton} onClick={onBackClick}>
            <i className={`ri-arrow-left-line`} />
          </IconButton>
        )}
        {title && (
          <div className={classes.dialogTitle}>
            {typeof title === 'string' ? (
              <Typography variant='h4' className={classes.dialogTitleText}>
                {title}
              </Typography>
            ) : (
              title
            )}
          </div>
        )}
        {closable && (
          <div className={classes.closeButton}>
            <IconButton aria-label='close' color='primary' onClick={onClose}>
              <Close />
            </IconButton>
          </div>
        )}
      </div>
      <DialogContent
        style={{ height: title ? 'calc(100% - 56px)' : '100%', maxHeight: title ? 'calc(100% - 56px)' : '100%' }}
      >
        {children}
      </DialogContent>
      {(primaryActionButton || secondaryActionText || additionalActionButtons) && (
        <DialogActions>
          {additionalActionButtons ? additionalActionButtons.map((button) => button) : null}
          <div style={{ flex: '1 0 0' }} />
          {onSecondaryActionClick && secondaryActionText && (
            <Button
              size='small'
              variant='tertiary'
              onClick={onSecondaryActionClick}
              disabled={isSecondaryActionDisabled}
            >
              {secondaryActionText}
            </Button>
          )}
          {primaryActionButton}
        </DialogActions>
      )}
    </MUIDialog>
  )
}
