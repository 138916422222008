import { IconButton, Typography } from '@mui/material'
import EditorMdxMarkdown from 'components/TextEditors/editor-mdx-markdown'
import CustomizedTooltip from 'components/Tooltips/CustomContentTooltip'
import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { makeStyles } from 'tss-react/mui'

type StyleProps = {
  fontSize: string
}

const useStyles = makeStyles<StyleProps>()((theme, params, classes) => {
  return {
    container: { fontSize: params.fontSize },
    editModeContainer: { display: 'flex' },
    icon: {
      fontSize: '1.2rem',
    },
    iconButton: {
      marginLeft: theme.spacing(1),
      marginBottom: 'auto',
      marginTop: 'auto',
      color: theme.palette.primary.main,
    },
  }
})

type EditableMarkdownEditorProps = {
  value: string
  onChange: (newValue: string) => void
  placeholder?: string
  editMode?: boolean
  isValidNewValue?: (newValue: string) => { isValid: boolean; message?: string }
  disabled?: boolean
  fontSize?: string
}

export default function EditableMarkdownText({
  value,
  onChange,
  editMode: editModeProps = false,
  placeholder,
  isValidNewValue,
  disabled,
  fontSize = '1rem',
}: EditableMarkdownEditorProps): React.ReactElement {
  const { classes } = useStyles({ fontSize })
  const [error, setError] = useState<string>()
  const [editMode, setEditMode] = useState<boolean>(editModeProps)
  const [text, setText] = useState<string>(value)

  function onConfirm(): void {
    setEditMode(false)
    onChange(text)
  }

  /**
   * Handles text change. Validates new text if validation function if provided.
   * @param text
   */
  function onTextChange(text: string): void {
    if (isValidNewValue) {
      const validityCheckResult = isValidNewValue(text)
      if (!validityCheckResult.isValid) {
        setError(validityCheckResult.message ?? 'Ungültiger Wert')
      } else {
        setError(undefined)
      }
    }
    setText(text)
  }

  useEffect(
    function updateEditMode() {
      setEditMode(editModeProps)
    },
    [editModeProps],
  )

  return (
    <div className={classes.container}>
      {editMode ? (
        <div className={classes.editModeContainer}>
          <EditorMdxMarkdown input={value} type='markdown' placeholder={placeholder} onChangeCallback={onTextChange} />
          <CustomizedTooltip
            placement='top'
            disableInteractive
            content={<Typography>Übernehmen</Typography>}
            elements={
              <IconButton
                onClick={onConfirm}
                aria-label='Add'
                className={classes.iconButton}
                disabled={!!error || disabled}
              >
                <i className={'ri-check-fill ' + classes.icon}></i>
              </IconButton>
            }
          />
        </div>
      ) : (
        <ReactMarkdown>{value ? value : `_${placeholder}_` ?? ''}</ReactMarkdown>
      )}
    </div>
  )
}
