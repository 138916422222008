import React, { useState, useEffect } from 'react'
import { withStyles, makeStyles } from 'tss-react/mui'

import { v4 as uuid } from 'uuid'

import { CheckCircle, Cancel } from '@mui/icons-material'
import { TextField, Grid, Switch, Button, Typography, useTheme } from '@mui/material'

import SAPITest from './ApiResponse/SAPITest'
import ResponseConfig from './ResponseConfig'

import { removeVariableFromNode } from '../../../../../utils/chartUtils'

import { APITestResult, Chart } from '../../../../../@types/Flowchart/types'
import { Textfield } from 'components/TextInput/Textfield'

const useStyles = makeStyles()({
  selectedDialogContainer: {},
  inputWrapper: {},
  statusCodeTextField: {
    width: '75px',
    '& .MuiOutlinedInput-input': {
      // disable arrows for increasing / decreasing number
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    },
  },
  button: {
    color: '#FFF',
    border: 'none',
    cursor: 'pointer',
    margin: '.3125rem 1px',
    padding: '12px 30px',
    position: 'relative',
    fontSize: '12px',
    minHeight: 'auto',
    minWidth: 'auto',
    maxWidth: '150px',
    textAlign: 'center',
    transition: 'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
    fontWeight: 400,
    textTransform: 'uppercase',
  },
  closeButton: {
    backgroundColor: '#3c4858',
    '&:hover,&:focus': {
      color: '#FFF',
      backgroundColor: '#3c4858',
      boxShadow: '0 14px 26px -12px rgba(60, 72, 88, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px',
    },
  },
})

type SAPIResponseProps = {
  chart: Chart
  setStateCallback: (chart: Chart) => void
}

/**
 * Selected API Response if not in loop.
 */
export default function SAPIResponse({ chart: origChart, setStateCallback }: SAPIResponseProps): React.ReactElement {
  const { classes } = useStyles()
  const theme = useTheme()

  const [chart, setChart] = useState<Chart>(origChart)
  // const [apiSuccess, setApiSuccess] = useState<boolean>()
  const [saveConfigIds, setSaveConfigIds] = useState<string[]>()
  const [responseBody, setResponseBody] = useState<string>()
  const [testResult, setTestResult] = useState<APITestResult>()
  const [expectedStatusCode, setExpectedStatusCode] = useState<string | undefined>()

  /**
   * Tests API call.
   * Sets success and response body in state.
   * Runs callback with response set in chart.
   */
  // async function testAPI(): Promise<void> {
  //   const selectedId = chart.selected?.id
  //   if (typeof selectedId === 'undefined') return
  //   const api = chart.nodes[selectedId].properties.api
  //   if (typeof api === 'undefined') return

  //   const request = {
  //     method: api.request.method,
  //     url: api.request.url,
  //     headers: api.request.headers,
  //     params: api.request.params,
  //     data: api.request.body,
  //   }

  //   // prepare response object
  //   // if (!localChart.nodes[localChart.selected.id].properties.api.response)
  //   // localChart.nodes[localChart.selected.id].properties.api.response = {}

  //   const response = api.response || {}
  //   try {
  //     const responseData = await axios(request)
  //     if (responseData.status < 200 || responseData.status > 299) throw { response: responseData.status }

  //     response.successful = true
  //     response.status = responseData.status
  //     setApiSuccess(true)
  //     setResponseBody(responseData.data ? JSON.stringify(responseData.data, undefined, 2) : undefined)
  //   } catch (err) {
  //     response.successful = false
  //     if (err.response && err.response.status) response.status = err.response.status
  //     setApiSuccess(false)
  //   }
  //   api.response = response
  //   chart.nodes[selectedId].properties.api = api
  //   setStateCallback(chart)
  // }

  /**
   *
   * @param event
   */
  function onExpectedStatusCodeChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const code = event.target.value
    if (code.length > 3) return // max length is 3

    // console.log(typeof code)

    try {
      const codeNr = parseInt(code)

      setExpectedStatusCode(code)

      const selectedId = chart.selected?.id
      if (typeof selectedId === 'undefined') return
      const api = chart.nodes[selectedId].properties.api
      if (typeof api === 'undefined') return

      api.response.status = codeNr

      const newChart = { ...chart }
      newChart.nodes[selectedId].properties.api = api
      setChart(newChart)
    } catch (err) {
      console.error('Error onChange StatusCode: ', err)
    }
  }

  /**
   * Handles save switch toggle.
   * On toggleOn: Creates new configId and adds it to state
   * On toggleOff: Removes all saveConfigs from node and all variables as well.
   * @param {React.ChangeEvent<HTMLInputElement>} event
   */
  function onSaveSwitchToggle(event: React.ChangeEvent<HTMLInputElement>): void {
    if (event.target.checked) {
      // toggle on
      const newConfigId = uuid()
      const sConfigIds = saveConfigIds || []
      sConfigIds.push(newConfigId)
      setSaveConfigIds([...sConfigIds])
    } else {
      // toggle off
      // remove variables from node
      const selectedId = chart.selected?.id
      if (typeof selectedId === 'undefined') return
      const api = chart.nodes[selectedId].properties.api
      if (typeof api === 'undefined') return

      let newChart = chart

      let vars: string[] = []
      const saveConfig = api.response.saveConfig || {}
      Object.keys(saveConfig).forEach((configId) => {
        vars = [...vars, ...saveConfig[configId].variables]
      })
      vars = Array.from(new Set([...vars]))
      vars.forEach((varId) => {
        newChart = removeVariableFromNode(newChart, 'set', varId, selectedId)
      })
      delete api.response.saveConfig
      newChart.nodes[selectedId].properties.api = api
      setSaveConfigIds([])
      // set chart in parent element
      setStateCallback(newChart)
    }
  }

  /**
   * Creates and adds new save config by creating new id and adding empty object to chart.
   */
  function onAddSaveConfig(): void {
    const newSaveConfigId = uuid()
    const sConfigIds = saveConfigIds || []
    sConfigIds.push(newSaveConfigId)
    setSaveConfigIds([...sConfigIds])
  }

  /**
   * Handles deletion of empty saveconfig, that can not be dealt with via the chart.
   */
  function onDeleteSaveConfig(saveConfigId: string): void {
    if (typeof saveConfigIds !== 'undefined') setSaveConfigIds(saveConfigIds.filter((id) => id !== saveConfigId))
  }

  /**
   * Callback function for children to update chart in this state.
   * @param chart
   */
  function onUpdateChart(chart: Chart): void {
    if (typeof chart.selected.id === 'undefined') return
    // update testResult if there is one
    const api = chart.nodes[chart.selected.id].properties.api
    setTestResult(api?.apiTestResult)
    setExpectedStatusCode(`${api?.apiTestResult?.status}`)
    setChart({ ...chart })
  }

  useEffect(
    function () {
      const selectedId = origChart.selected?.id
      if (typeof selectedId === 'undefined') return
      const api = origChart.nodes[selectedId].properties.api
      if (typeof api === 'undefined') return

      const apiTestResult = api.apiTestResult
      const saveConfigIds = Object.keys(api.response.saveConfig || {})

      const expectedStatus = apiTestResult?.status ?? api.response.status
      setExpectedStatusCode(`${expectedStatus}`)
      setTestResult(apiTestResult)
      setSaveConfigIds(saveConfigIds)
      setChart(origChart)
    },
    [origChart],
  )

  const node =
    typeof chart !== 'undefined' && typeof chart.selected?.id !== 'undefined'
      ? chart.nodes[chart.selected.id]
      : undefined
  const api = typeof node !== 'undefined' ? node.properties.api : undefined
  return (
    <div className={classes.selectedDialogContainer}>
      {typeof api !== 'undefined' && (
        <div className={classes.inputWrapper}>
          <SAPITest chart={chart} setChartCallback={onUpdateChart} />

          <div>
            <div
              style={{
                width: '100%',
                margin: '15px 0 15px 0',
                verticalAlign: 'middle',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Grid container direction='row' justifyContent='space-evenly' alignItems='center'>
                <Grid item xs={12} style={{ display: 'flex' }}>
                  <Typography style={{ marginTop: 'auto', marginBottom: 'auto', marginRight: theme.spacing(2) }}>
                    Erwarteter Statuscode:
                  </Typography>
                  <Textfield
                    className={classes.statusCodeTextField}
                    type='number'
                    onChange={onExpectedStatusCodeChange}
                    value={expectedStatusCode} // note max length does not work if type number
                    error={typeof expectedStatusCode === 'undefined' || expectedStatusCode === ''}
                    placeholder='Status'
                  />
                </Grid>
                <Grid item xs={11} style={{ height: '100%' }}>
                  <Typography>Antwort in Variable(n) speichern</Typography>
                </Grid>
                <Grid item xs={1} style={{ height: '100%', textAlign: 'right' }} alignItems='center'>
                  <Switch
                    checked={typeof saveConfigIds !== 'undefined' && saveConfigIds.length > 0}
                    onChange={onSaveSwitchToggle}
                    color='primary'
                    value='saveResponse'
                  />
                </Grid>
              </Grid>

              {typeof saveConfigIds !== 'undefined' && saveConfigIds.length > 0 ? (
                <React.Fragment>
                  {saveConfigIds.map((configId, index) => {
                    return (
                      <div
                        key={`api-response-${index}`}
                        style={{ marginBottom: theme.spacing(2), marginTop: theme.spacing(1) }}
                      >
                        <ResponseConfig
                          key={index}
                          chart={chart}
                          setStateCallback={setStateCallback}
                          saveConfigId={configId}
                          deleteSaveConfigCallback={onDeleteSaveConfig}
                        />
                      </div>
                    )
                  })}
                  <div style={{ width: '100%', textAlign: 'center' }}>
                    <Button onClick={onAddSaveConfig} className={classes.button + ' ' + classes.closeButton}>
                      Hinzufügen
                    </Button>
                  </div>
                </React.Fragment>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
