import React, { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'

// @mui/material components
import { makeStyles } from 'tss-react/mui'

import { Grid, Button, Typography } from '@mui/material'
// custom components
import DataCheck from './DataCheck'
import DataCheckAutosuggestSelect, { CheckCallbackType } from './DataCheckAutosuggestSelect'

import { getDatachecksOfNode } from '../../../../../utils/datacheckUtils'

import { Chart, Datacheck } from '../../../../../@types/Flowchart/types'

const useStyles = makeStyles()({
  center: { width: '100%', textAlign: 'center' },
  gridContainer: { marginTop: '15px' },
  gridLeft: { paddingRight: '5px' },
  gridRight: { paddingLeft: '5px' },
  button: {
    color: '#FFF',
    border: 'none',
    cursor: 'pointer',
    margin: '.3125rem 1px',
    padding: '12px 30px',
    position: 'relative',
    fontSize: '12px',
    minHeight: 'auto',
    minWidth: 'auto',
    textAlign: 'center',
    transition: 'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
    fontWeight: 400,
    textTransform: 'uppercase',
  },
  buttonMedium: {
    width: '250px',
  },
  buttonCancel: { background: 'red', width: '100%' },
  buttonNeutral: {
    backgroundColor: '#3c4858',
    '&:hover,&:focus': {
      color: '#FFF',
      backgroundColor: '#3c4858',
      boxShadow: '0 14px 26px -12px rgba(60, 72, 88, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px',
    },
  },
  buttonConfirm: {
    backgroundColor: 'rgb(76, 175, 5)',
    '&:hover,&:focus': {
      color: '#FFF',
      backgroundColor: 'rgb(76, 175, 5)',
      boxShadow: '0 14px 26px -12px rgba(60, 72, 88, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px',
    },
    '&:disabled': {
      color: '#FFF',
      backgroundColor: 'rgba(76, 175, 0.6)',
    },
  },
  noVariablesText: { marginTop: '30px', textAlign: 'center' },
})

type NewDataCheckProps = {
  chart: Chart
  onNewDatacheckCallback: (chart: Chart) => void
  onCancelCallback: () => void
}

function NewDataCheck({ chart, onNewDatacheckCallback, onCancelCallback }: NewDataCheckProps): React.ReactElement {
  const { classes } = useStyles()
  const [newDatacheck, setNewDatacheck] = useState<CheckCallbackType>()

  /**
   * Handles addition of new datacheck.
   * Sets new datacheck into state
   * @param newCheck
   */
  function onAddDatacheckClick(newCheck: CheckCallbackType): void {
    setNewDatacheck(newCheck)
  }

  /**
   * Creates new datacheck object, adds it to the chart and calls callback.
   */
  function createNewDataCheck(): void {
    const selectedId = chart.selected?.id
    if (typeof selectedId === 'undefined' || typeof newDatacheck === 'undefined') return

    const newCheckId = newDatacheck.props.id

    if (newDatacheck.isNew) {
      // create new datacheck object and add it to chart
      const check: Datacheck = {
        id: newCheckId,
        type: 'condition',
        name: newDatacheck.props.name,
        usageCount: 0, // set to 0 because usageCount is increased once variable is linked with this datacheck
        nodes: {
          [selectedId]: [],
        },
        advanced: true, // new datachecks should always be in advanced mode
        predefined: false,
      }

      chart.datachecks = chart.datachecks || {}
      chart.datachecks[newCheckId] = check
    }

    // add datacheck to node
    const datachecks = chart.nodes[selectedId].properties.datachecks || {}
    datachecks[newCheckId] = { id: newCheckId, usageCountNode: 0 }
    chart.nodes[selectedId].properties.datachecks = datachecks

    onNewDatacheckCallback(chart)
  }

  return (
    <div>
      <Typography>Bestehenden Datencheck auswählen oder neuen Datencheck erstellen</Typography>

      <DataCheckAutosuggestSelect chart={chart} setCheckCallback={onAddDatacheckClick} selectOnly={false} />

      <Grid
        container
        direction='row'
        justifyContent='space-evenly'
        alignItems='baseline'
        className={classes.gridContainer}
      >
        <Grid item xs={6} className={classes.gridLeft + ' ' + classes.center}>
          <Button
            className={classes.button + ' ' + classes.buttonNeutral + ' ' + classes.buttonMedium}
            onClick={onCancelCallback}
          >
            Abbrechen
          </Button>
        </Grid>
        <Grid item xs={6} className={classes.gridRight + ' ' + classes.center}>
          <Button
            disabled={!newDatacheck}
            className={classes.button + ' ' + classes.buttonConfirm + ' ' + classes.buttonMedium}
            onClick={createNewDataCheck}
          >
            Datencheck hinzufügen
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

type DataCheckTabContentProps = {
  chart: Chart
  setStateCallback: (chart: Chart) => void
}

export default function DataCheckTabContent({ chart, setStateCallback }: DataCheckTabContentProps): React.ReactElement {
  const { classes } = useStyles()
  const [dataCheckIds, setDataCheckIds] = useState<string[]>([])
  const [renderNew, setRenderNew] = useState<boolean>(false)
  const [hasVariables, setHasVariables] = useState<boolean>(false)
  // const [newDatacheck, setNewDatacheck] = useState<CheckCallbackType>()

  /**
   * Handles add datacheck button click.
   * Sets state to render new
   */
  function onAddButtonClick(): void {
    setRenderNew(true)
  }

  /**
   * Handles addition of newly configured datacheck.
   * @param chart
   * @param dataCheckIds
   */
  function onNewDatacheck(chart: Chart): void {
    const selectedId = chart.selected?.id
    if (typeof selectedId === 'undefined') return
    const checkIds = getDatachecksOfNode(chart, selectedId)
    setRenderNew(false)
    setDataCheckIds(checkIds)
    setStateCallback(chart)
  }

  /**
   * Cancels addition of new datacheck
   */
  function onCancelButtonClick(): void {
    setRenderNew(false)
  }

  useEffect(
    function () {
      const selectedId = chart.selected?.id
      if (typeof selectedId === 'undefined') return

      const hasVariables =
        chart.nodes[selectedId].properties.variables && !isEmpty(chart.nodes[selectedId].properties.variables)
      const checkIds = getDatachecksOfNode(chart, selectedId)
      setDataCheckIds(checkIds)
      setHasVariables(!!hasVariables)
    },
    [chart],
  )

  return renderNew ? (
    <NewDataCheck chart={chart} onNewDatacheckCallback={onNewDatacheck} onCancelCallback={onCancelButtonClick} />
  ) : (
    <div>
      <div>
        {dataCheckIds.map((checkId, index) => {
          return <DataCheck key={index} dataCheckId={checkId} setStateCallback={onNewDatacheck} chart={chart} />
        })}
      </div>

      <div className={classes.center}>
        {hasVariables ? (
          <Button className={classes.button + ' ' + classes.buttonNeutral} onClick={onAddButtonClick}>
            Neuen Datencheck hinzufügen
          </Button>
        ) : (
          <div className={classes.noVariablesText}>
            <Typography>
              Diese Node hat noch keine Variablen, die überprüft werden können. Ein Datencheck kann nur hinzugefügt
              werden, wenn die Node Variablen nutzt, um Nutzereingaben zu speichern.
            </Typography>
          </div>
        )}
      </div>
    </div>
  )
}
