import { useEffect, useRef } from 'react'

/**
 * Use previous hook. Can be used to compare current value of a state to previous one in useEffect hook.
 * @param value
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}
