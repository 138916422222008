import NotificationSnackbar from 'components/NotificationSnackbar/NotificationSnackbar'
import React, { createContext, useContext, useReducer, useCallback, useState, useEffect } from 'react'

// ----- TYPE DEFINITIONS -----

// Maps Actions to Payloads - Standard does not need customization
type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key
      }
    : {
        type: Key
        payload: M[Key]
      }
}

// Possible Actions
enum Types {
  SetNotification = 'SET_NOTIFICATION',
}

export type NotificationType = 'info' | 'success' | 'error' | 'warning'
export type SetNotificationType = (
  notificationType: NotificationType,
  notificationText: string,
  timeout?: number | undefined,
  closable?: boolean | undefined,
) => void

// Payloads for each action
type StudioContextPayload = {
  [Types.SetNotification]: {
    notificationType: NotificationType | null
    notificationText: string | null
    notificationTimeout: number | null
    closable: boolean | null
  }
}

type Action = ActionMap<StudioContextPayload>[keyof ActionMap<StudioContextPayload>]
type State = {
  notificationType: NotificationType | null
  notificationText: string | null
  notificationTimeout: number | null
  closable: boolean | null
}

type Dispatch = (action: Action) => void
type StudioProviderProps = { children: React.ReactNode }

/**
 * ----- CONTEXT -----
 * Studio Notification context is a global context used for displaying notifications to the studio user.
 *
 * To access this context:
 * import useStudioNotificationContext from '<path>/hooks/contexts/studio-notification-context.tsx'
 *
 * // in a function
 * const {Notification, showNotification} = useStudioNotificationContext()
 *
 * // usage
 * <Notification />
 * showNotification(type="error", text="Something went wrong...", timeout=3000)
 */
const StudioNotificationContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined)

function studioReducer(state: State, action: Action): State {
  switch (action.type) {
    case Types.SetNotification: {
      // make sure to spread that state just in case!
      return {
        ...state,
        notificationText: action.payload.notificationText,
        notificationType: action.payload.notificationType,
        notificationTimeout: action.payload.notificationTimeout,
        closable: action.payload.closable ?? true,
      }
    }
    default: {
      // helps us avoid typos!
      throw new Error(`[StudioNotificationContext-Reducer] Unhandled action type: ${action.type}`)
    }
  }
}

/**
 * Context provider for providing StudioContext
 * @returns
 */
function StudioNotificationContextProvider({ children }: StudioProviderProps): JSX.Element {
  const [state, dispatch] = useReducer(studioReducer, {
    notificationText: null,
    notificationType: null,
    notificationTimeout: null,
    closable: null,
  })
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  // const value = useMemo(() => [state, dispatch], [state])
  const value = { state, dispatch }
  return <StudioNotificationContext.Provider value={value}>{children}</StudioNotificationContext.Provider>
}

/**
 * Hook for accessing and manipulating the StudioContext state
 * @returns
 */
function useStudioNotificationContext(): {
  Notification: React.ReactNode
  setNotification: (
    notificationType: NotificationType,
    notificationText: string,
    timeout?: number,
    closable?: boolean,
  ) => void
  clearNotification: () => void
} {
  const context = useContext(StudioNotificationContext)
  if (context === undefined) {
    throw new Error(
      '[useStudioNotificationContext] useStudioNotificationContext must be used within a StudioNotificationContextProvider',
    )
  }

  // splitting it up, allows us to add additional api calls
  const { state, dispatch } = context
  const { notificationType, notificationText, notificationTimeout, closable } = state

  // ---- API ----
  // To not let anything work on the context state directly and to have an easy to use API

  // Functions // TODO: use default timeout value if none is provided? constants -> SNACKBAR_NOTIFICATIONS_DEFAULT_TIMEOUT
  const setNotification = useCallback((notificationType, notificationText, timeout, closable): void => {
    // IDEA: add a way to configure auto-hide and closability
    dispatch({
      type: Types.SetNotification,
      payload: { notificationText, notificationType, notificationTimeout: timeout, closable },
    })
  }, [])

  const clearNotification = useCallback(() => {
    // setTimeoutInMs(null)
    dispatch({
      type: Types.SetNotification,
      payload: { notificationText: null, notificationType: null, notificationTimeout: null, closable: null },
    })
  }, [])

  useEffect(
    function () {
      let timeout
      if (notificationTimeout) {
        timeout = setTimeout(() => {
          // console.log('Clear notification (to)')
          clearNotification()
        }, notificationTimeout)
      }

      return (): void => {
        if (timeout) {
          clearTimeout(timeout)
        }
      }
    },
    [notificationTimeout, clearNotification],
  )

  function onCloseNotification(): void {
    if (closable) clearNotification()
  }

  const Notification =
    notificationText && notificationType ? (
      <NotificationSnackbar
        position='top'
        severity={notificationType}
        open={true}
        message={notificationText}
        onCloseNotification={closable ? onCloseNotification : undefined}
      />
    ) : null

  // Controll what is returned
  return { Notification, setNotification, clearNotification }
}

export { StudioNotificationContextProvider, useStudioNotificationContext }
