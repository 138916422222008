import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { CircularProgress, Typography } from '@mui/material'
import CustomizedTooltip from 'components/Tooltips/CustomContentTooltip'

type StyleProps = {
  direction?: 'row' | 'column'
}

const useStyles = makeStyles<StyleProps>()((theme, props) => ({
  root: {
    display: 'flex',
    flexDirection: props?.direction ?? 'column',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  circularProgress: {
    color: theme.palette.primary.main,
    marginTop: props?.direction === 'row' ? 'auto' : undefined,
    marginBottom: props?.direction === 'row' ? 'auto' : undefined,
    marginRight: props?.direction === 'row' ? theme.spacing(1) : undefined,
  },
  text: {
    maxWidth: '75%',
    marginTop: props?.direction === 'row' ? 'auto' : theme.spacing(1),
    marginBottom: props?.direction === 'row' ? 'auto' : undefined,
  },
}))

type Size = 'extra-small' | 'small' | 'medium' | 'large' | 'extra-large'

const SIZEMAP: { [key in Size]: number } = {
  'extra-small': 16,
  small: 20,
  medium: 35,
  large: 50,
  'extra-large': 80,
}

type CircularLoadingProps = {
  text?: string
  size?: Size
  delayDisplay?: boolean
  tooltip?: string
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right'
  direction?: 'row' | 'column'
  fontStyle?: 'italic' | 'normal'
}

export default function CircularLoading({
  text,
  size,
  tooltip,
  tooltipPlacement = 'top',
  delayDisplay = false,
  direction = 'column',
  fontStyle = 'normal',
}: CircularLoadingProps): React.ReactElement {
  const { classes } = useStyles({ direction })
  const [show, setShow] = useState<boolean>(delayDisplay ? false : true)

  useEffect(function () {
    const timer = setTimeout(function () {
      setShow(true)
    }, 500)

    return (): void => {
      clearTimeout(timer)
    }
  }, [])

  return (
    <div className={classes.root}>
      {show ? (
        <>
          <CustomizedTooltip
            placement={tooltipPlacement}
            elements={
              <CircularProgress classes={{ root: classes.circularProgress }} size={SIZEMAP[size || 'medium']} />
            }
            content={<Typography>{tooltip}</Typography>}
          />
          {text && (
            <div className={classes.text}>
              <Typography fontStyle={fontStyle}>{text}</Typography>
            </div>
          )}
        </>
      ) : null}
      {/* <CircularProgress className={classes.circularProgress} /> */}
    </div>
  )
}
