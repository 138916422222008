import { InputType, ActionType } from '../../@types/SmartCards/types'

export const SmartCardBase = {
  type: 'AdaptiveCard' as const,
  body: [],
  version: '1.3',
  $data: '${$root.variables}',
  $schema: 'https://adaptivecards.io/schemas/adaptive-card.json',
}

export const SmartCardTitleContainer = {
  type: 'Container',
  id: 'title',
  items: [],
}

export const SmartCardTitleBlock = {
  type: 'TextBlock',
  text: '',
  weight: 'Bolder',
  wrap: true,
  id: 'titleBlock',
}

export const SmartCardContentContainer = {
  type: 'Container',
  separator: true,
  spacing: 'Medium',
  id: 'content',
  items: [],
}

export const SmartCardContentBlock = {
  type: 'TextBlock',
  text: '',
  wrap: true,
  id: 'contentBlock',
}

export const SmartCardInputContainer = {
  type: 'Container',
  spacing: 'Medium',
  id: 'input',
  items: [],
}

// INPUT BLOCKS

export const SmartCardInputTextBlock = {
  type: 'Input.Text',
  placeholder: '--Bitte eingeben--',
  label: '',
  isRequired: false,
  errorMessage: '',
  id: '',
  isMultiline: false,
  value: '',
}

export const SmartCardInputDateBlock = {
  type: 'Input.Date',
  label: '',
  isRequired: false,
  errorMessage: '',
  id: '',
  value: '',
}

export const SmartCardInputTimeBlock = {
  type: 'Input.Time',
  label: '',
  isRequired: false,
  errorMessage: '',
  id: '',
  value: '',
}

export const SmartCardInputNumberBlock = {
  type: 'Input.Number',
  placeholder: '',
  label: '',
  isRequired: false,
  errorMessage: '',
  id: '',
  value: '',
}

export const SmartCardInputChoiceBlock = {
  type: 'Input.ChoiceSet',
  choices: [
    {
      title: 'Choice 1',
      value: 'Choice 1',
    },
    {
      title: 'Choice 2',
      value: 'Choice 2',
    },
  ],
  placeholder: '--Bitte auswählen--',
  label: '',
  isRequired: false,
  errorMessage: '',
  id: '',
  wrap: true,
  value: '',
  style: 'compact',
}

export const SmartCardInputToggleBlock = {
  type: 'Input.Toggle',
  title: '',
  label: '',
  isRequired: false,
  errorMessage: '',
  id: '',
  wrap: true,
  value: '',
}

export const SmartCardActionsContainer = {
  type: 'Container',
  separator: true,
  spacing: 'Medium',
  id: 'actions',
  items: [],
}

export const SmartCardTwoActionsColumns = {
  type: 'ColumnSet',
  columns: [
    {
      type: 'Column',
      width: 'stretch',
      items: [
        {
          type: 'ActionSet',
          actions: [],
        },
      ],
    },
    {
      type: 'Column',
      width: 'stretch',
      items: [
        {
          type: 'ActionSet',
          actions: [],
        },
      ],
    },
  ],
}

// these are the input field options supported by adaptive cards
export const SmartCardInputOptions: InputType[] = [
  'Input.Text',
  'Input.Date',
  'Input.Time',
  'Input.Number',
  'Input.ChoiceSet',
  'Input.Toggle',
  // custom type by us for email data check
  'Input.TextLetterOnly',
  'Input.Email',
  'Input.IBAN',
  'Input.Phone',
]

export function inputOptionsToLabel(option: InputType): string {
  switch (option) {
    case 'Input.Text':
      return 'Text'
    case 'Input.TextLetterOnly':
      return 'Buchstaben'
    case 'Input.Number':
      return 'Zahl'
    case 'Input.Email':
      return 'E-Mail'
    case 'Input.Phone':
      return 'Telefon'
    case 'Input.Date':
      return 'Datum'
    case 'Input.Time':
      return 'Zeit'
    case 'Input.IBAN':
      return 'IBAN'
    case 'Input.ChoiceSet':
      return 'Auswahl'
    case 'Input.Toggle':
      return 'Schalter'
    default:
      return ''
  }
}

export const SmartCardActionOptions: ActionType[] = ['Action.OpenUrl', 'Action.Submit']

export function actionOptionsToLabel(option: ActionType): string {
  switch (option) {
    case 'Action.OpenUrl':
      return 'Url öffnen'
    case 'Action.Submit':
      return 'Bestätigen'
    default:
      return ''
  }
}
