import React, { memo, useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
// Components
import { FabButton } from 'components/Buttons'
// Types
import { AnswerAction, IntentTriggerButtons } from '../../../../../../@types/Knowledge/types'
// Hooks
import { useAnswers } from 'hooks/contexts/answers-context'
import { useLockingContext } from 'hooks/contexts/locking-context'
import ActionCard from 'components/Cards/ActionCard'
import EditAnswerActionsDialog from './EditAnswerActionsDialog'
import { Can } from 'components/Can/Can'
import { Textfield } from 'components/TextInput/Textfield'
import { cloneDeep } from 'lodash'

const useStyles = makeStyles()((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
  },
  textfieldContainer: {
    marginTop: theme.spacing(2),
  },
}))

type Props = {
  answerId: string
  onButtonsChange: (buttons: IntentTriggerButtons) => void
}

// Trigger answer only
export default memo(function EditAnswerIntentTriggerButtons({ answerId, onButtonsChange }: Props): React.ReactElement {
  const { classes } = useStyles()
  const { getAnswer, canIEditAnswer } = useAnswers()
  const { lockState } = useLockingContext()

  const [startDialogButtonText, setStartDialogButtonText] = useState<string>(
    getAnswer(answerId)?.intentTriggerButtons?.startDialogButton ?? '',
  )
  const [skipButtonText, setSkipButtontext] = useState<string>(
    getAnswer(answerId)?.intentTriggerButtons?.skipButton ?? '',
  )

  return (
    <Can I='update' a='knowledge' passThrough>
      {(can): React.ReactElement => (
        <div className={classes.content}>
          <div className={classes.textfieldContainer}>
            <Textfield
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (can && canIEditAnswer(answerId)) {
                  setStartDialogButtonText(event.target.value)
                  const buttons = cloneDeep(
                    getAnswer(answerId)?.intentTriggerButtons ?? { startDialogButton: '', skipButton: '' },
                  )
                  buttons.startDialogButton = event.target.value
                  onButtonsChange(buttons)
                }
              }}
              disabled={lockState !== 'canEdit'}
              value={startDialogButtonText}
              fullWidth
              label='Text: Dialog starten Button'
              placeholder='Dialog starten'
            />
          </div>{' '}
          <div className={classes.textfieldContainer}>
            <Textfield
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (can && canIEditAnswer(answerId)) {
                  setSkipButtontext(event.target.value)
                  const buttons = cloneDeep(
                    getAnswer(answerId)?.intentTriggerButtons ?? { startDialogButton: '', skipButton: '' },
                  )
                  buttons.skipButton = event.target.value
                  onButtonsChange(buttons)
                }
              }}
              disabled={lockState !== 'canEdit'}
              value={skipButtonText}
              fullWidth
              label='Text: Neue Frage (Überspringen) Button'
              placeholder='Neue Frage'
            />
          </div>
        </div>
      )}
    </Can>
  )
})
