import React from 'react'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  happy: {
    fontSize: '32px',
    color: theme.palette.success.dark,
  },
}))

export default function Happy(): React.ReactElement {
  const { classes } = useStyles()
  return <i className={'ri-emotion-happy-line ' + classes.happy} />
}
