import React, { useEffect, useState } from 'react'
import Dialog from 'components/Dialogs/Dialog'
import { Button } from 'components/Buttons'
import { Typography } from '@mui/material'
import { BotEnvironment, BotInfos } from '../../../@types/BotInformation/types'
import { SelectDropdown, Option } from 'components/Dropdown'
import { useBotContext } from 'hooks/contexts/bot-context'

const dropdownOptions: Option[] = [
  { label: 'Test', value: 'staging', disabled: true, disabledText: '(nicht konfiguriert)' },
  { label: 'Production', value: 'production' },
]

type ConfirmPublishDialogProps = {
  onConfirm: (deployEnvironment: BotEnvironment) => void
  onClose: () => void
}

// TODO add check and warning if production version would be higher than published staging version -> untested
export default function PublishDialog({ onConfirm, onClose }: ConfirmPublishDialogProps): React.ReactElement {
  const { bot: botInfos } = useBotContext() as { bot: BotInfos } // can cast safely because this dialog is only shown if the botinfos have been loaded and set
  const [hasStaging, setHasStaging] = useState<boolean>(false)
  const [deployEnvironment, setDeployEnvironment] = useState<BotEnvironment>()

  useEffect(function () {
    // check if bot has staging env, if not, simply offer production publish
    if (
      botInfos.webchatVersion === 'v4' &&
      (botInfos.integrationChannels.staging.courier !== null ||
        botInfos.integrationChannels.staging.directline !== null)
    ) {
      setHasStaging(true)
      setDeployEnvironment('staging')
      dropdownOptions[0].disabled = false
    }
  }, [])

  return (
    <Dialog
      id='confirm-publish-dialog'
      open
      title='Konversationsfluss veröffentlichen'
      size='medium'
      primaryActionButton={
        <Button type='normal' onClick={(): void => onConfirm(deployEnvironment ?? 'production')}>
          Veröffentlichen
        </Button>
      }
      onClose={onClose}
    >
      {hasStaging ? (
        <Typography>
          Bitte wählen Sie die Umgebung in die der aktuelle Konversationsfluss veröffentlicht werden soll. Bitte
          beachten Sie, dass bei einer Veröffentichung in <i>Production</i> auch immer die <i>Test</i> -umgebung mit
          aktualisiert wird. Vorsicht beim Veröffentichen in <i>Production</i>. Stellen Sie immer zuerst in der
          Test-Umgebung sicher, dass alles so funktioniert wie geplant!
        </Typography>
      ) : (
        <>
          <Typography>
            Möchten Sie den Konversationsfluss veröffentlichen? Dadurch wird der aktuelle Konversationsfluss des
            Live-Assistenten ersetzt und die Änderungen sofort veröffentlicht!
          </Typography>
          <Typography variant='caption' component='p' style={{ marginTop: '16px' }}>
            Dieser Assistent hat keine Test-Umgebung konfiguriert. &quot;Veröffentlichen&quot; macht die Änderungen
            direkt im Live-Assistenten für alle Nutzer*innen verfügbar.
          </Typography>
        </>
      )}

      <div style={{ marginTop: '16px' }}>
        <SelectDropdown
          options={dropdownOptions}
          onChange={(newValue): void => setDeployEnvironment(newValue.value)}
          selected={deployEnvironment}
          isClearable={false}
          isCreatable={false}
          isSearchable={true}
          width={'100%'}
        />
      </div>
    </Dialog>
  )
}
