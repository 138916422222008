import React, { useState, useEffect } from 'react'

// @mui/material components
import { makeStyles } from 'tss-react/mui'

import { Checkbox, FormControlLabel, TextField, Typography, useTheme } from '@mui/material'
// Types
import { Chart } from '../../../../../@types/Flowchart/types'

const useStyles = makeStyles()((theme) => ({
  selectedDialogContainer: {},
  fieldContainer: {
    marginTop: theme.spacing(3),
  },
  description: {
    marginBottom: theme.spacing(2),
    // marginTop: theme.spacing(2),
  },
}))

type SLLMTaskProps = {
  chart: Chart
  setStateCallback: (chart: Chart) => void
  setIsSaveDisabled: (disabled: boolean) => void
}

export default function SLLMTask({
  chart,
  setStateCallback: _setStateCallback,
  setIsSaveDisabled,
}: SLLMTaskProps): React.ReactElement {
  const { classes } = useStyles()
  const theme = useTheme()

  const [name, setName] = useState<string>() // name of the task
  const [systemPrompt, setSystemPrompt] = useState<string>() // system prompt for the LLM - can be empty
  const [userPrompt, setUserPrompt] = useState<string>() // user prompt for the LLM - can be empty
  const [displayTextFirstTurn, setDisplayTextFirstTurn] = useState<string>() // message that will be presented to the user during the first turn - must not be empty
  const [displayTextLaterTurns, setDisplayTextLaterTurns] = useState<string>() // message that will be presented to the user in later turns (if multiturn is enabled)
  const [buttonTextFinish, setButtonTextFinish] = useState<string>() // button text for finish button (if multiturn is enabled)
  const [buttonTextTweak, setButtonTextTweak] = useState<string>() // button text for tweak button (if multiturn is enabled)
  const [multiTurnEnabled, _setMultiTurnEnabled] = useState<boolean>(false)

  /**
   * Checks if all fields are configured.
   * If chart is provided, checks based on values in chart, else uses state values.
   * @returns
   */
  function areAllFieldsConfigured(chart?: Chart): boolean {
    if (chart) {
      const selectedId = chart.selected?.id
      if (typeof selectedId === 'undefined' || !chart.nodes[selectedId].properties) return false
      const nodeProperties = chart.nodes[selectedId].properties
      if (typeof nodeProperties?.llmTask === 'undefined') return false
      return !!(
        nodeProperties.text &&
        nodeProperties.text !== 'Nicht konfiguriert' &&
        (nodeProperties.llmTask.systemPrompt || nodeProperties.llmTask.userPrompt) &&
        nodeProperties.llmTask.displayTextFirstTurn &&
        (nodeProperties.llmTask.historyEnabled
          ? nodeProperties.llmTask.displayTextLaterTurns &&
            nodeProperties.llmTask.buttonTextTweak &&
            nodeProperties.llmTask.buttonTextFinish
          : true)
      )
    } else {
      // use state value
      return !!(
        name &&
        name !== 'Nicht konfiguriert' &&
        (userPrompt || systemPrompt) &&
        displayTextFirstTurn &&
        (multiTurnEnabled ? displayTextLaterTurns : true)
      )
    }
  }

  function setStateCallback(chart: Chart): void {
    setIsSaveDisabled(!areAllFieldsConfigured())
    _setStateCallback({ ...chart })
  }

  /**
   * Handles history checkbox change event.
   * @param event
   * @param checked
   */
  function onMultiTurnEnabledChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void {
    const selectedId = chart.selected?.id
    if (typeof selectedId === 'undefined' || !chart.nodes[selectedId].properties) return
    const nodeProperties = chart.nodes[selectedId].properties
    if (typeof nodeProperties?.llmTask === 'undefined') return

    nodeProperties.llmTask.historyEnabled = checked
    _setMultiTurnEnabled(checked)
    chart.nodes[selectedId].properties = nodeProperties
    setStateCallback(chart)
  }

  /**
   * Handles userInputPrephase text change
   * @param event
   */
  function onTextChange(
    event: React.ChangeEvent<HTMLInputElement>,
    field:
      | 'name'
      | 'systemPrompt'
      | 'userPrompt'
      | 'userInputPrephase'
      | 'displayTextFirstTurn'
      | 'displayTextLaterTurns'
      | 'buttonTextFinish'
      | 'buttonTextTweak',
  ): void {
    const selectedId = chart.selected?.id
    if (typeof selectedId === 'undefined' || !chart.nodes[selectedId].properties) return
    const nodeProperties = chart.nodes[selectedId].properties
    if (typeof nodeProperties?.llmTask === 'undefined') return

    const value = event.target.value

    switch (field) {
      case 'name':
        setName(value)
        nodeProperties.text = value
        break
      case 'systemPrompt':
        setSystemPrompt(value)
        nodeProperties.llmTask.systemPrompt = value
        break
      case 'userPrompt':
        setUserPrompt(value)
        nodeProperties.llmTask.userPrompt = value
        break
      case 'displayTextFirstTurn':
        setDisplayTextFirstTurn(value)
        nodeProperties.llmTask.displayTextFirstTurn = value
        break
      case 'displayTextLaterTurns':
        setDisplayTextLaterTurns(value)
        nodeProperties.llmTask.displayTextLaterTurns = value
        break
      case 'buttonTextFinish':
        setButtonTextFinish(value)
        nodeProperties.llmTask.buttonTextFinish = value
        break
      case 'buttonTextTweak':
        setButtonTextTweak(value)
        nodeProperties.llmTask.buttonTextTweak = value
        break
    }

    chart.nodes[selectedId].properties = nodeProperties
    setStateCallback(chart)
  }

  useEffect(
    function () {
      const selectedId = chart.selected?.id
      if (typeof selectedId === 'undefined') return
      const nodeProperties = chart.nodes[selectedId].properties
      const llmTask = nodeProperties?.llmTask
      if (!llmTask) return

      setName(nodeProperties.text)
      setSystemPrompt(llmTask.systemPrompt)
      setUserPrompt(llmTask.userPrompt)
      setDisplayTextFirstTurn(llmTask.displayTextFirstTurn)
      setDisplayTextLaterTurns(llmTask.displayTextLaterTurns)
      _setMultiTurnEnabled(llmTask.historyEnabled)
      setButtonTextFinish(llmTask.buttonTextFinish)
      setButtonTextTweak(llmTask.buttonTextTweak)

      setIsSaveDisabled(!areAllFieldsConfigured(chart))
    },
    [chart],
  )

  return (
    <div className={classes.selectedDialogContainer}>
      <div className={classes.fieldContainer}>
        <Typography className={classes.description}>
          Geben Sie dem Block einen aussagekräftigen Namen, wie z.B. LinkedIn Post generieren.
        </Typography>
        <TextField
          label='Name'
          fullWidth
          value={name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => onTextChange(event, 'name')}
        />
      </div>
      <div className={classes.fieldContainer}>
        <Typography className={classes.description}>
          Legen Sie den System-Prompt fest, der dem Sprachmodell erklärt, was es zu tun hat. Hier können beispielsweise
          Beispiele für eine gelungene Generation des Sprachmodells angelegt werden. Um z.B. einen Socialmedia post zu
          generieren, könnte dem Sprachmodell hier eine Intranetmeldung und der zugehörige Socialmediapost als Beispiel
          gegeben werden. In der späteren Nutzung würden Nutzer*innen dann eine Intranetmeldung angeben, woraufhin das
          Sprachmodell einen Socialmediapost generiert.
        </Typography>
        <TextField
          label='System-Prompt'
          multiline
          maxRows={12}
          rows={12}
          fullWidth
          value={systemPrompt}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => onTextChange(event, 'systemPrompt')}
        />
      </div>

      <div className={classes.fieldContainer}>
        <Typography className={classes.description}>
          Definieren Sie ein User-Prompt. Das User-Prompt wird der Eingabe der Nutzer*innen vorangestellt und
          beeinflusst das Ergebnis. Es wird empfohlen hier die Anweisungen des System-Prompts nochmal kurz zu
          wiederholen oder zu verfeinern.
        </Typography>
        <TextField
          label='User-Prompt'
          multiline
          maxRows={5}
          rows={3}
          fullWidth
          value={userPrompt}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => onTextChange(event, 'userPrompt')}
        />
      </div>

      <div className={classes.fieldContainer}>
        <Typography className={classes.description}>
          Text zur ersten Eingabeaufforderung an Nutzer*innen. Beispiel: &quot;Zu welcher Intranetmeldung soll ich einen
          Socialmediapost generieren?&quot;.
        </Typography>
        <TextField
          label='Eingabeaufforderung'
          multiline
          maxRows={5}
          fullWidth
          value={displayTextFirstTurn}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => onTextChange(event, 'displayTextFirstTurn')}
        />
      </div>

      {/* History */}
      <div className={classes.fieldContainer}>
        <Typography className={classes.description}>
          Möchten Sie Verfeinerungen des Ergebnisses in darauffolgenden Nachrichten ermöglichen?
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={multiTurnEnabled}
              onChange={onMultiTurnEnabledChange}
              style={{ color: theme.palette.primary.main }}
            />
          }
          label='Verfeinerungen in folgenden Nachrichten erlauben (Multi-Turn)'
        />
      </div>

      {multiTurnEnabled && (
        <>
          <div className={classes.fieldContainer}>
            <Typography className={classes.description}>
              Eingabeaufforderung an Nutzer*innen, um das Ergebnis zu verfeinern. Beispiel: &quot;Was soll ich an dem
              Ergebnis noch verändern?&quot;.
            </Typography>
            <TextField
              label='Eingabeaufforderung zur Verfeinerung'
              multiline
              maxRows={5}
              fullWidth
              value={displayTextLaterTurns}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => onTextChange(event, 'displayTextLaterTurns')}
            />
          </div>

          <div className={classes.fieldContainer}>
            <Typography className={classes.description}>
              Buttontext zum Abschließen des Ergebnisses. Durch Klick auf diesen Button wird die Konversation mit dem
              nächsten Block fortgesetzt.
            </Typography>
            <TextField
              label='Abschlussbutton'
              multiline
              maxRows={1}
              rows={1}
              fullWidth
              value={buttonTextFinish}
              placeholder='Beenden'
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => onTextChange(event, 'buttonTextFinish')}
            />
          </div>
          <div className={classes.fieldContainer}>
            <Typography className={classes.description}>
              Buttontext zum Vornehmen von Änderungen am Ergebnis. Durch Klick auf diesen Buttons können Anpassungen am
              Ergebnis vorgenommen werden.
            </Typography>
            <TextField
              label='Änderungsbutton'
              multiline
              maxRows={1}
              rows={1}
              fullWidth
              value={buttonTextTweak}
              placeholder='Anpassen'
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => onTextChange(event, 'buttonTextTweak')}
            />
          </div>
        </>
      )}
    </div>
  )
}
