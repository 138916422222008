import React from 'react'
import { TextField, MenuItem } from '@mui/material'

// types
import { RequestMethod } from '../../../../../../@types/Flowchart/types'

type RequestMethodFieldProps = {
  method: RequestMethod
  options: { label: string; value: RequestMethod }[]
  methodSelectionCallback: (method: string) => void
}

/**
 * Request method selection function
 */
export default function RequestMethodField({
  method,
  options,
  methodSelectionCallback,
}: RequestMethodFieldProps): React.ReactElement {
  function onChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const value = event.target.value
    methodSelectionCallback(value)
  }

  return (
    <TextField id='request-method' select fullWidth label='Methode' value={method} onChange={onChange} margin='normal'>
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  )
}
