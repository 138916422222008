import { makeStyles } from 'tss-react/mui'

export const useWebchatV4Styles = makeStyles()((theme) => ({
  userMessage: {
    backgroundColor: theme.palette.webchatV4.disabledColor,
    border: `2px solid ${theme.palette.webchatV4.disabledColor}`,
    borderRadius: '28px',
    color: theme.palette.webchatV4.disabledColorText,
    padding: '12px 24px 12px 24px',
    width: '170px',
    height: '31px',
    marginLeft: 'auto',
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  cardMessage: {
    boxSizing: 'border-box',
    marginTop: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    minHeight: '80px',
    // width: '100%',
    // boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.15), 0px 3px 6px rgba(0, 0, 0, 0.1)',
    // border: `1px solid ${theme.palette.grey.A100}`,
    borderRadius: `0px ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
    overflowY: 'auto',
  },
  // input: {
  //   marginTop: 'auto',
  //   marginLeft: 'auto',
  //   marginRight: theme.spacing(2),
  //   marginBottom: theme.spacing(2),
  //   height: '30px',
  //   width: '30px',
  //   borderRadius: '50%',
  //   backgroundColor: theme.palette.primary.main,
  // },
}))
