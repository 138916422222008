import React, { useEffect, useState } from 'react'
import { Chip, TextField, Paper, Box, Typography } from '@mui/material'
import { Textfield } from 'components/TextInput/Textfield'

type KeywordsProps = {
  descriptionText: React.ReactNode
  keywords: string[]
  onKeywordsChange: (keywords: string[]) => void
}

export const Keywords = ({ descriptionText, keywords, onKeywordsChange }: KeywordsProps) => {
  const [words, setWords] = useState<string[]>(keywords)
  const [inputValue, setInputValue] = useState<string>('')

  useEffect(() => {
    setWords(keywords)
  }, [keywords])

  useEffect(() => {
    if (onKeywordsChange) {
      onKeywordsChange(words)
    }
  }, [words, onKeywordsChange])

  const handleInputChange = (event) => {
    setInputValue(event.target.value)
  }

  const handleInputKeyPress = (event) => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      const newWords = [...words, inputValue.trim()]
      setWords(newWords)
      setInputValue('')
    }
  }

  const handleDelete = (wordToDelete: string) => {
    const newWords = words.filter((word) => word !== wordToDelete)
    setWords(newWords)
  }

  return (
    // <Paper elevation={3} sx={{ p: 2 }}>
    <div>
      {descriptionText}
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2, mt: 2 }}>
        {words.map((word, index) => (
          <Chip key={index} label={word} onDelete={() => handleDelete(word)} />
        ))}
      </Box>
      <Textfield
        fullWidth
        value={inputValue}
        onChange={handleInputChange}
        onKeyUp={handleInputKeyPress}
        label='Neues Stichwort hinzufügen'
      />
      <Typography variant='caption'>
        <b>Tipp:</b> Enter zum Hinzufügen eines neuen Stichworts
      </Typography>
    </div>
  )
}
