import { makeStyles } from 'tss-react/mui'
import React, { ReactElement, useState } from 'react'
import { Draggable } from '../Draggable/Draggable'
import { PopupFooter } from './popupElements/PopupFooter'
import { PopupTextfield } from './popupElements/PopupTextfield'
import { PopupTitlebar } from './popupElements/PopupTitlebar'

const useStyles = makeStyles()((theme) => ({
  popup: {
    background: 'rgba(255,255,255,0.98)',
    width: '250px',
    borderRadius: theme.shape.borderRadius,
    padding: '15px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    cursor: 'move',
  },
}))

type SelectionPopupProps = {
  parent: HTMLDivElement
  startPosition: { x: number; y: number }
  title: string
  onConfirm: (selectionName: string) => void
  onClose: () => void
  onDelete?: () => void
  selectionName?: string
}

/**
 * Popup for Infotext & URL selection
 */
export default function SelectionPopup({
  parent,
  startPosition,
  title,
  onConfirm,
  onClose,
  onDelete,
  selectionName,
}: SelectionPopupProps): ReactElement {
  const { classes } = useStyles()
  const [selectionTitle, setSelectionTitle] = useState(selectionName || '')

  // Textfield
  function onChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setSelectionTitle(event.target.value)
  }

  function onConfirmClick(): void {
    onConfirm(selectionTitle)
  }

  const popup = (
    <div className={classes.popup}>
      <PopupTitlebar title={title} onClose={onClose} />
      <PopupTextfield
        title={'Name'}
        onChange={onChange}
        value={selectionTitle}
        multiline
        placeholder={'Name des ausgewählten Bereichs'}
        autofocus={true}
      />
      {/* Footer */}
      {onDelete ? (
        <PopupFooter disabledConfirm={selectionTitle.length === 0} onConfirm={onConfirmClick} onDelete={onDelete} />
      ) : (
        <PopupFooter disabledConfirm={selectionTitle.length === 0} onConfirm={onConfirmClick} />
      )}
    </div>
  )

  return <Draggable parent={parent} startPosition={startPosition} content={popup} />
}
