import React from 'react'
import { ActionMeta, FormatOptionLabelMeta, Options, OnChangeValue, GroupBase } from 'react-select'

import { CSSProperties } from 'react'

import { default as ReactSelectCreatable } from 'react-select/creatable'
import { makeStyles } from 'tss-react/mui'
import { useTheme } from '@mui/material'
import ReactMarkdown from 'react-markdown'

// for styling of the select component see here: https://react-select.com/styles

type getSelectStylesProps = {
  height: string
  menuListHeight?: string
}

function getSelectStyles(theme: any, props: getSelectStylesProps): any {
  const borderRadius = theme.shape?.borderRadius * 2
  const primaryColor = theme.palette?.primary.main
  const textColor = theme.palette?.text?.primary
  const fontFamily = theme.typography?.fontFamily

  return {
    singleValue: (base: CSSProperties): CSSProperties => ({
      ...base,
      fontWeight: 'normal',
      fontSize: '1rem',
      color: primaryColor,
    }),
    multiValue: (base: CSSProperties): CSSProperties => ({
      ...base,
      borderRadius: '10px',
      paddingLeft: '5px',
    }),
    multiValueRemove: (base: CSSProperties): any => ({
      ...base,
      '&:hover': {
        ...base[':hover'],
        borderRadius: '2px 10px 10px 2px', // top left, top right, bottom right, bottom left
      },
    }),
    control: (base: CSSProperties, state: any): any => ({
      ...base,
      border: `1px solid #B3B3B3`,
      borderRadius: borderRadius,
      height: props?.height ? props.height : '56px',
      fontSize: '1rem',
      // color: '#435D6B',
      // color: theme.palette.primary.main,
      fontFamily: fontFamily,
      fontStyle: 'normal',
      lineHeight: '1.4375em',
      // letterSpacing: '-0.045em',
      minWidth: '150px',
      '&:hover': {
        borderColor: primaryColor,
      },
    }),
    indicatorSeparator: (): CSSProperties => ({
      display: 'none',
    }),
    dropdownIndicator: (base: CSSProperties): CSSProperties => ({
      ...base,
      marginRight: '10px',
    }),
    placeholder: (base: CSSProperties): CSSProperties => ({
      ...base,
      fontWeight: 'lighter',
      fontSize: '1rem',
    }),
    valueContainer: (base: CSSProperties): CSSProperties => ({
      ...base,
      fontSize: '1rem',
      paddingLeft: '12px',
      verticalAlign: 'middle',
      // paddingBottom: '15px',
      // paddingTop: '15px',
      color: primaryColor,
    }),
    menu: (base: CSSProperties): any => ({
      ...base,
      zIndex: 10000,
      borderRadius: borderRadius,
    }),
    menuPortal: (base: CSSProperties): any => ({
      ...base,
      zIndex: 10000,
      borderRadius: borderRadius,
    }),
    // menuList: (base: CSSProperties): any => ({
    //   ...base,
    //   maxHeight: props.menuListHeight ?? undefined,
    // }),
  }
}

type DropdownOptionStylesProps = {
  sublabelFontSize?: string
}

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useDropdownOptionStyles = makeStyles<DropdownOptionStylesProps>()((theme, props) => ({
  optionDisabled: {
    borderRadius: `${
      typeof theme.shape.borderRadius === 'string'
        ? parseInt(theme.shape.borderRadius) * 2
        : theme.shape.borderRadius * 2
    }px`,
    color: theme.palette.grey[700],
    margin: theme.spacing(2),
    fontStyle: 'italic',
    fontWeight: 'normal',
  },
  option: {
    borderRadius: `${
      typeof theme.shape.borderRadius === 'string'
        ? parseInt(theme.shape.borderRadius) * 2
        : theme.shape.borderRadius * 2
    }px`,
    color: theme.palette.primary.light,
    margin: theme.spacing(2),
    fontWeight: 'normal',
    '&:hover': {
      color: theme.palette.primary.main,
      fontWeight: 'bolder',
      cursor: 'pointer',
    },
  },
  optionSubTitle: {
    fontWeight: 'lighter',
    fontSize: props.sublabelFontSize ?? '12px',
  },
}))

export interface Option {
  label: string // label (if selectedLabel is provided, label is shown in dropdown, but selectedLabel if option is selected)
  sublabel?: string
  value: any
  selectedLabel?: string // label to show if the option is selected
  disabled?: boolean
  disabledText?: string // text that is shown after option text as a hint to the user why this option is disabled
}

export type { ActionMeta, OnChangeValue as ValueType }

export type ChangeValue = OnChangeValue<Option, boolean>
// export type ActionType = ActionMeta<Option>

type BaseDropdownProps<T extends Option> = {
  onChange: (newValue: OnChangeValue<T, boolean> | OnChangeValue<T, boolean>[], action: ActionMeta<T>) => void
  options: T[]
  className?: string
  isMulti?: boolean
  isSearchable?: boolean
  isCreatable?: boolean
  isClearable?: boolean
  isDisabled?: boolean
  selectedOptions?: T | T[]
  placeholder?: string
  noOptionsMessage?: string
  createOptionText?: (inputValue: string) => string
  allowedChars?: string // string of all allowed chars
  forbiddenLabels?: string[] // strings defined here cannot be used when creating a new option
  maxDisplayOptions?: number // defines how many options are displayed before menu scrolls
  height?: string
  marginTop?: string
  sublabelFontSize?: string
  sublabelType?: 'text' | 'markdown'
  allowLargeOptions?: boolean // if true, increases the max size of the dropdown to accomodate for large options
}

/**
 * This component is an abstraction for the react-select/creatable component (https://react-select.com/creatable).
 * It offers better usability than the MUI Autocomplete component (e.g. does not behave like textfield when selecting single value).
 * This component is the basis for e.g. the VariablesAutosuggestSelect component that handles all variable related stuff.
 */
export default function BaseDropdown({
  className,
  onChange,
  options,
  isMulti = false,
  isSearchable = false,
  isCreatable = false,
  isDisabled = false,
  isClearable = true,
  selectedOptions = [],
  placeholder,
  noOptionsMessage,
  createOptionText,
  allowedChars,
  forbiddenLabels = [],
  maxDisplayOptions = 5,
  height = '56px',
  sublabelFontSize = '12px',
  sublabelType = 'text',
  allowLargeOptions = false,
}: BaseDropdownProps<Option>): React.ReactElement {
  const theme = useTheme()
  const { classes } = useDropdownOptionStyles({ sublabelFontSize })
  const reactSelectStyles = getSelectStyles(theme, { height, menuListHeight: allowLargeOptions ? '500px' : '250px' })
  /**
   * Returns the label for an option
   * @param {DropdownOption} option
   */
  function getOptionLabel(option: Option): string {
    return option.label
  }

  function getOptionValue(option: Option): string {
    return option.value
  }

  /**
   * Formats option. This is used to also display options with subtext.
   * TODO: renderer for variables from pdf
   * @param option
   * @param labelMeta provides information about whether formatting is for dropdown menu or for selected value (via the context object)
   * @returns
   */
  function formatOptionLabel(option: Option, labelMeta: FormatOptionLabelMeta<Option>): React.ReactNode {
    if (!option.sublabel || labelMeta.context === 'value') {
      return labelMeta.context === 'value' && option.selectedLabel ? option.selectedLabel : option.label
    } else {
      // has sublabel
      return (
        <>
          <div>{option.label}</div>
          {sublabelType === 'text' ? (
            <div className={classes.optionSubTitle}>{option.sublabel}</div>
          ) : sublabelType === 'markdown' ? (
            <div className={classes.optionSubTitle}>
              <ReactMarkdown>{option.sublabel}</ReactMarkdown>
            </div>
          ) : null}
        </>
      )
    }
  }

  /**
   * Checks if the value that should be created is allowed.
   * @param inputValue
   * @param value
   * @param options
   * @returns
   */
  function isValidNewOption(
    inputValue: string,
    value: ReadonlyArray<Option>,
    options: ReadonlyArray<Option | GroupBase<Option>>,
  ): boolean {
    if (!isCreatable) return false

    if (inputValue.trim().length === 0) return false

    if (forbiddenLabels.includes(inputValue)) return false

    if (typeof allowedChars !== 'undefined') {
      // check if each char of the input value is a allowed char
      for (const char of inputValue) {
        if (!allowedChars.includes(char)) return false
      }
    }

    const existingLabels = (options as Option[]).map((option) => option.label)
    if (existingLabels.includes(inputValue)) return false

    return true
  }

  function isOptionDisabled(option: Option, options: Options<Option>): boolean {
    return !!option.disabled
  }

  return (
    <div className={className}>
      <ReactSelectCreatable
        isMulti={isMulti}
        isSearchable={isSearchable || isCreatable}
        isClearable={isClearable}
        isDisabled={isDisabled}
        onChange={onChange}
        isValidNewOption={isValidNewOption}
        options={options}
        isOptionDisabled={isOptionDisabled}
        value={selectedOptions}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        styles={reactSelectStyles}
        placeholder={placeholder}
        noOptionsMessage={typeof noOptionsMessage !== 'undefined' ? (): string => noOptionsMessage : undefined}
        formatCreateLabel={createOptionText}
        formatOptionLabel={formatOptionLabel}
        blurInputOnSelect
        // menuIsOpen={true}
        menuPosition={'fixed'}
        menuPlacement='auto'
        maxMenuHeight={allowLargeOptions ? 600 : 300}
        theme={(themeReactSelect: any): any => ({
          ...themeReactSelect,
          colors: {
            ...themeReactSelect.colors,
            primary: theme.palette.primary.main,
            text: theme.palette.text.primary,
          },
        })}
        components={{
          Option: function OptionComponent({
            children,
            innerProps,
            data,
          }: {
            children: React.ReactNode
            innerProps: any
            data: Option // data of the option
          }): React.ReactElement {
            return (
              <div className={data.disabled ? classes.optionDisabled : classes.option} {...innerProps}>
                {typeof children === 'string'
                  ? `${children} ${data.disabled && data.disabledText ? data.disabledText : ''}`
                  : children}
              </div>
            )
          },
        }}
      />
    </div>
  )
}
