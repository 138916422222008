import React, { memo } from 'react'
// material ui
import { makeStyles } from 'tss-react/mui'
import { IconButton, Typography } from '@mui/material'
// Custom components
import CustomizedTooltip from 'components/Tooltips/CustomContentTooltip'

type StyleProps = {
  fill: boolean
}

const useStyles = makeStyles<StyleProps>()((theme, props) => ({
  iconButtonAccept: {
    // marginLeft: theme.spacing(1),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.success.main,
    fontSize: '1.2rem',
    '&:hover': {
      backgroundColor: theme.palette.success.light,
    },
    '&:disabled': {
      backgroundColor: theme.palette.grey[400],
      color: theme.palette.common.white,
    },
  },
  iconButtonDelete: {
    // marginLeft: theme.spacing(1),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    fontSize: '1.2rem',
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
    '&:disabled': {
      backgroundColor: theme.palette.grey[400],
      color: theme.palette.common.white,
    },
  },
  iconButtonDefault: {
    // marginLeft: theme.spacing(1),
    backgroundColor: props.fill ? theme.palette.primary.main : undefined,
    color: props.fill ? theme.palette.common.white : undefined,
    '&:hover': props.fill
      ? {
          backgroundColor: theme.palette.primary.light,
        }
      : undefined,
    '&:disabled': props.fill
      ? {
          backgroundColor: theme.palette.grey[400],
          color: theme.palette.common.white,
        }
      : undefined,
    fontSize: '1.2rem',
  },
}))

type CustomIconButtonProps = {
  type?: 'accept' | 'decline' | 'default'
  tooltip?: string
  onClick: () => void
  icon?: string
  disabled?: boolean
  className?: string
  size?: 'small' | 'medium'
  fill?: boolean
}

export default memo(function CustomIconButton({
  type = 'default',
  tooltip,
  onClick,
  icon,
  disabled = false,
  fill = false,
  className,
  size = 'medium',
}: CustomIconButtonProps): React.ReactElement {
  const { classes } = useStyles({ fill })
  return (
    <>
      {typeof tooltip === 'string' ? (
        disabled ? (
          <IconButton
            onClick={(event: React.MouseEvent): void => {
              event.stopPropagation()
              event.preventDefault()
              onClick()
            }}
            aria-label={type === 'default' ? undefined : type === 'accept' ? 'accept' : 'decline'}
            size={size}
            className={
              (type === 'accept'
                ? classes.iconButtonAccept
                : type === 'decline'
                ? classes.iconButtonDelete
                : type === 'default'
                ? classes.iconButtonDefault
                : undefined) +
              ' ' +
              className
            }
            disabled={disabled}
          >
            {type === 'accept' ? (
              <i className={'ri-check-line '}></i>
            ) : type === 'decline' ? (
              <i className={'ri-close-line '}></i>
            ) : (
              <i className={icon}></i>
            )}
          </IconButton>
        ) : (
          <CustomizedTooltip
            placement='top'
            disableInteractive
            content={<Typography>{tooltip}</Typography>}
            elements={
              <IconButton
                onClick={(event: React.MouseEvent): void => {
                  event.stopPropagation()
                  event.preventDefault()
                  onClick()
                }}
                aria-label={type === 'default' ? undefined : type === 'accept' ? 'accept' : 'decline'}
                size={size}
                className={
                  (type === 'accept'
                    ? classes.iconButtonAccept
                    : type === 'decline'
                    ? classes.iconButtonDelete
                    : type === 'default'
                    ? classes.iconButtonDefault
                    : undefined) +
                  ' ' +
                  className
                }
                disabled={disabled}
              >
                {type === 'accept' ? (
                  <i className={'ri-check-line '}></i>
                ) : type === 'decline' ? (
                  <i className={'ri-close-line '}></i>
                ) : (
                  <i className={icon}></i>
                )}
              </IconButton>
            }
          />
        )
      ) : (
        <IconButton
          onClick={onClick}
          aria-label={type === 'default' ? undefined : type === 'accept' ? 'accept' : 'decline'}
          className={
            (type === 'accept'
              ? classes.iconButtonAccept
              : type === 'decline'
              ? classes.iconButtonDelete
              : type === 'default'
              ? classes.iconButtonDefault
              : undefined) +
            ' ' +
            className
          }
          disabled={disabled}
        >
          {type === 'accept' ? (
            <i className={'ri-check-line'}></i>
          ) : type === 'decline' ? (
            <i className={'ri-close-line'}></i>
          ) : (
            <i className={icon}></i>
          )}
        </IconButton>
      )}
    </>
  )
})
