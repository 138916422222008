import React, { ReactElement, useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'

// types
import { InfotextType } from '../../@types/Ingest/types'

import { Draggable } from '../Draggable/Draggable'

import { PopupTitlebar } from './popupElements/PopupTitlebar'
import { PopupTextblock } from './popupElements/PopupTextblock'
import { PopupTextfield } from './popupElements/PopupTextfield'
import { PopupRadioButton } from './popupElements/PopupRadioButton'
import { PopupFooter } from './popupElements/PopupFooter'
import { PopupDropdown, DropdownOption } from './popupElements/PopupDropdown'

const useStyles = makeStyles()((theme) => ({
  popup: {
    background: 'rgba(255,255,255,0.98)',
    width: '250px',
    borderRadius: theme.shape.borderRadius,
    padding: '15px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    cursor: 'move',
  },
  radioButtonGroup: {
    textAlign: 'left',
    display: 'flex',
    marginBottom: '10px',
  },
  alignRight: {
    marginLeft: 'auto',
  },
}))

type InfotextPopupProps = {
  parent: HTMLDivElement
  startPosition: { x: number; y: number }
  title: string
  selectionOptions: DropdownOption[]
  onOpen?: () => void // executed on first render
  onConfirm: (type: InfotextType, name: string, sections: DropdownOption[] | undefined) => void
  onDelete: () => void
  onClose: () => void
  text: string
  type?: InfotextType
  name?: string
  selectedSections?: DropdownOption[]
}

/**
 * Popup for Infotext & URL selection
 * TODO: I18n
 */
export default function InfotextPopup({
  parent,
  startPosition,
  title,
  selectionOptions,
  onOpen,
  onConfirm,
  onDelete,
  onClose,
  type,
  name,
  text,
  selectedSections,
}: InfotextPopupProps): ReactElement {
  const { classes } = useStyles()
  const [textName, setName] = useState(name || '')
  const [textType, setTextType] = useState<InfotextType>(type || 'infotext')
  const [sections, setSections] = useState<DropdownOption[] | undefined>(selectedSections)

  // effects
  useEffect(() => {
    if (onOpen) {
      onOpen()
    }
  }, [])

  // Textfield
  function onChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setName(event.target.value)
  }

  // Radiobuttons
  function onClick(event: React.MouseEvent | React.TouchEvent, id: string): void {
    // this array is the string literal type 'IntotextType'
    if (['infotext', 'URL'].includes(id)) setTextType(id as InfotextType)
  }

  // Section dropdown
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function onDropdownChange(event: object, options: DropdownOption[], reason: string): void {
    setSections(options)
  }

  function onConfirmClick(): void {
    onConfirm(textType, textName, sections)
  }

  const popup = (
    <div className={classes.popup}>
      <PopupTitlebar title={title} onClose={onClose} />
      <PopupTextfield
        title={'Titel'}
        onChange={onChange}
        value={textName}
        multiline
        placeholder={'Titel des Infotexts'}
      />
      {/* Infotext Type -- Radio Buttons */}
      <div className={classes.radioButtonGroup}>
        <div>
          <PopupRadioButton
            id={'infotext'}
            header={'Type'}
            title={'Into Text'}
            description={'Additional Information Text'}
            selected={textType === 'infotext'}
            onClick={onClick}
          />
        </div>
        <div className={classes.alignRight}>
          <PopupRadioButton
            id={'URL'}
            header={'Type'}
            title={'URL'}
            description={'Only use this for valid HTTP URLs'}
            selected={textType === 'URL'}
            onClick={onClick}
          />
        </div>
      </div>
      {/* Selected Text */}
      <PopupTextblock title={'Example Text'} text={text || 'empty'} />
      {/* Area selection */}
      <PopupDropdown
        title={'Bereich'}
        options={selectionOptions}
        placeholder={'Bereiche'}
        multiple={true}
        onChange={onDropdownChange}
        selectedValues={sections}
      />
      {/* Footer */}
      <PopupFooter
        disabledConfirm={!(textName && textType && sections)}
        onConfirm={onConfirmClick}
        onDelete={onDelete}
      />
    </div>
  )

  return <Draggable parent={parent} startPosition={startPosition} content={popup} />
}
