import React, { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useTheme } from '@mui/material/styles'
// Material ui
import Typography from '@mui/material/Typography'
// Custom Componets
import BaseCard from './BaseCard'
import { Divider, IconButton, ListItemText, MenuItem, MenuList, Menu } from '@mui/material'
import { useLockingContext } from 'hooks/contexts/locking-context'
import { MoreVert } from '@mui/icons-material'
import { CUSTOM_NOTIFICATION_CARD_HEIGHT } from 'utils/constants'
import { Can } from 'components/Can/Can'
import { DocumentInfos } from '../../@types/BotInformation/types'
import CustomizedTooltip from 'components/Tooltips/CustomContentTooltip'

const useStyles = makeStyles()((theme) => ({
  card: {
    transition: 'box-shadow 0.3s',
    cursor: 'pointer',
    '&:hover': {
      boxShadow:
        '0 14px 26px -12px rgb(0 0 0 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)', // '0px 2px 10px 2px rgba(0,0,0, 0.25)'
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '80%',
  },
  popupMenu: {
    padding: 0,
  },
  popupMenuList: {
    padding: 0,
  },
  popupMenuRedText: {
    color: theme.palette.error.main,
  },
  title: {
    // copied and slightly simplified (because we dont need styling props) from base card
    maxHeight: `${(theme.typography.h3.lineHeight as number) * 2}em`,
    marginRight: theme.spacing(4),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    wordWrap: 'break-word',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    flex: '0 1 auto',
  },
  documentIcon: {
    fontSize: theme.typography.h4.fontSize,
    marginRight: theme.spacing(1),
  },
}))

type DocumentCardMenuProps = {
  onDownload: () => void
  onEditProperties: () => void
  onDelete: () => void
  onReplace: () => void
  onView: () => void
  open: boolean
  onClose: () => void
  anchorElement: Element
}

/**
 * Menu component for menu button of card.
 */
function DocumentCardMenu({
  onDownload,
  onEditProperties,
  onDelete,
  onReplace,
  onView,
  open,
  onClose,
  anchorElement,
}: DocumentCardMenuProps): React.ReactElement {
  const { classes } = useStyles()
  return (
    <Menu
      open={open}
      onClose={onClose}
      anchorEl={anchorElement}
      classes={{ list: classes.popupMenuList }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{
        style: {
          width: '250px',
        },
      }}
    >
      <MenuList>
        <MenuItem onClick={onDownload}>
          <ListItemText>Herunterladen</ListItemText>
        </MenuItem>
        <MenuItem disabled onClick={onView}>
          <ListItemText>Ansehen</ListItemText>
        </MenuItem>
        <MenuItem disabled onClick={onReplace}>
          <ListItemText>Ersetzen</ListItemText>
        </MenuItem>
        <MenuItem onClick={onEditProperties}>
          <ListItemText>Eigenschaften bearbeiten</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={onDelete}>
          <ListItemText classes={{ primary: classes.popupMenuRedText }} primary='Löschen' />
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

type CardProps = {
  documentId: string
  document: DocumentInfos[string]
  onEditProperties: () => void
  onDownload: () => Promise<void>
  onReplace: () => void
  onDelete: () => void
  onView: () => void
  inputs: number | string
  height?: string
}

/**
 * Card for Custom Notification
 */
export default function DocumentCard({
  documentId,
  document,
  onEditProperties,
  onDownload,
  onReplace,
  onDelete,
  onView,
  inputs,
  height = CUSTOM_NOTIFICATION_CARD_HEIGHT,
}: CardProps): React.ReactElement {
  const theme = useTheme()
  const { classes } = useStyles()
  const { lockState } = useLockingContext()
  const [anchorElementForMenu, setAnchorElementForMenu] = useState<HTMLElement | null>(null)
  const menuOpen = Boolean(anchorElementForMenu)
  const documentType = document.documentType

  function handleMenuClose(): void {
    setAnchorElementForMenu(null)
  }

  function handleMenuClick(event: React.MouseEvent<HTMLButtonElement>): void {
    event.stopPropagation()
    if (menuOpen) {
      handleMenuClose()
    } else {
      setAnchorElementForMenu(event.currentTarget)
    }
  }

  return (
    <Can I='update' a='notifications' passThrough>
      {(can): React.ReactElement => (
        <>
          <BaseCard
            title={
              <>
                <Typography className={classes.title} variant='h3' gutterBottom>
                  {document.documentType === 'pdf' ? (
                    <CustomizedTooltip
                      placement='top'
                      content={<Typography variant='body1'>PDF-Dokument</Typography>}
                      elements={<i className={classes.documentIcon + ' ri-file-pdf-line'} />}
                    />
                  ) : (
                    <i className={classes.documentIcon + 'ri-file-3-line'} />
                  )}
                  {document.documentTitle}
                </Typography>
              </>
            }
            footerText={`${inputs} Dokument`}
            footerColor={theme.palette.success.main}
            height={height}
            width='100%'
            className={classes.card}
            onClick={(): void => {
              onView()
            }}
            titleVariant='h3'
            topRightActionButton={
              can ? (
                <IconButton
                  onClick={handleMenuClick}
                  disabled={lockState !== 'canEdit'}
                  aria-controls={menuOpen ? 'basic-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={menuOpen ? 'true' : undefined}
                >
                  <MoreVert />
                </IconButton>
              ) : null
            }
          >
            <div className={classes.content}>
              <Typography variant='body2'>{document.documentDescription ?? ''}</Typography>
            </div>
          </BaseCard>
          {anchorElementForMenu && (
            <DocumentCardMenu
              open={menuOpen}
              onEditProperties={(): void => {
                handleMenuClose()
                onEditProperties()
              }}
              onClose={handleMenuClose}
              onDelete={(): void => {
                handleMenuClose()
                onDelete()
              }}
              onDownload={(): void => {
                handleMenuClose()
                onDownload()
              }}
              onReplace={(): void => {
                handleMenuClose()
                onReplace()
              }}
              onView={(): void => {
                handleMenuClose()
                onView()
              }}
              anchorElement={anchorElementForMenu}
            />
          )}
        </>
      )}
    </Can>
  )
}
