import { makeStyles } from 'tss-react/mui'
import { useTheme } from '@mui/material/styles'
import React, { useEffect, useState } from 'react'

// assets
import svgAnalyticsDiagram from 'assets/img/analytics/analytics_diagram.svg'

import ContentContainer from '../../ContentContainer'
import { SelectDropdown, Option, ActionMeta } from 'components/Dropdown'
import { selectAnalyticsEventInNode } from 'utils/chartUtils'
import { Chart } from '../../../../../../@types/Flowchart/types'

import { CONVAISE_ANALYTICS_EVENTS } from '../SAnalyticsTrigger'

const useStyles = makeStyles()({
  container: {
    height: '100%',
  },
  menuContainer: {
    display: 'flex',
    width: '100%',
    height: 'calc(100% - 60px)',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  menuImage1: {
    marginBottom: '50px',
  },
  builderActions: {
    height: '60px',
    marginTop: 'auto',
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'end',
  },
  card: {
    alignItems: 'center',
    // transition: 'box-shadow 0.3s',
    // '&:hover': {
    //   boxShadow:
    //     '0 14px 26px -12px rgb(0 0 0 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)', // '0px 2px 10px 2px rgba(0,0,0, 0.25)'
    // },
  },
})

type SelectConvaiseEventProps = {
  chart: Chart
  onSelectEvent: (chart: Chart) => void
  onBackClick: () => void
}

export default function SelectConvaiseEvent({
  chart,
  onSelectEvent: onSelectEventProps,
  onBackClick,
}: SelectConvaiseEventProps): React.ReactElement {
  const { classes } = useStyles()
  const theme = useTheme()

  const [selectedEvent, setSelectedEvent] = useState<string>()
  const [selectEventOptions, setSelectEventOptions] = useState<Option[]>(CONVAISE_ANALYTICS_EVENTS)

  function onSelectEvent(event: Option): void {
    const selectedNodeId = chart.selected.id
    if (!selectedNodeId) return
    const eventName = event.value
    const newChart = selectAnalyticsEventInNode(chart, 'convaise', selectedNodeId, eventName)
    setSelectedEvent(event.value)
    onSelectEventProps(newChart)
  }

  useEffect(
    function () {
      // build dropdown options and set selected if event is already selected
      const selectedId = chart.selected.id
      if (!selectedId) return
      const selectedEvent = chart.nodes[selectedId].properties.analyticsEvent?.eventName
      if (selectedEvent) setSelectedEvent(selectedEvent)
    },
    [chart],
  )

  return (
    <ContentContainer
      headerText='Convaise Analytics'
      descriptionText={
        'Vordefinierte Convaise Events. Diese Events sind bereits in den Analytics sichtbar. Mit Analytics Triggern können Sie manuell Events den Assistenten Analytics hinzufügen. Zum Beispiel, wenn zur Generierung eines PDFs nicht der Standard Convaise Dienst genutzt wird, sondern ein externen Dienst, der via API angebunden ist. Der erfolgreiche API Call würde dann kein Analytics Event für Generierte PDFs speichern. Die Node können Sie dem erfolgreich Pfad der API Node hinzufügen, um das generierte PDF "manuell" zu tracken.        Bitte beachten Sie, dass Sie mit dieser Node zusätzliche Analytics Events generieren, die die Nutzungseinblicke verfälschen können, wenn nicht korrekt eingesetzt. Sie müssen im Normalfall keine Analytics Trigger setzen.'
      }
      onBackClick={onBackClick}
      svg={svgAnalyticsDiagram}
    >
      <div className={classes.container}>
        <SelectDropdown
          onChange={(newValue: Option, action: ActionMeta<Option>): void => onSelectEvent(newValue)}
          isSearchable
          selected={selectedEvent}
          options={selectEventOptions}
          maxDisplayOptions={5}
          // width={'175px'}
          // height={'56px'}
        />
      </div>
    </ContentContainer>
  )
}
