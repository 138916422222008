import { FormControlLabel, FormGroup, Grid, Switch, Typography, useTheme } from '@mui/material'
import { Textfield } from 'components/TextInput/Textfield'
import { useBotContext } from 'hooks/contexts/bot-context'
import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { BotSettings, EmailFallbackDepartment, EmailFallbackTexts } from '../../@types/Settings/types'
import { getAccount } from 'components/AuthProvider/PublicClientApp'
import { CONVAISE_CUSTOMER_ID } from 'utils/constants'
import { useLockingContext } from 'hooks/contexts/locking-context'
import CustomizedTooltip from 'components/Tooltips/CustomContentTooltip'
import { isEqual } from 'lodash'
import EmailFallbackDepartmentConfiguration from './EmailFallbackDepartmentConfiguration'
import { Button } from 'components/Buttons'
import { OndemandVideoSharp } from '@mui/icons-material'
import EmailFallbackTextsConfig from './EmailFallbackTexts'
import { v4 as uuidv4 } from 'uuid'

type Colors = {
  primaryColor?: string
  primaryColorText?: string
  disabledColor?: string
}

const useStyles = makeStyles()((theme) => ({
  content: {},
  buttons: { display: 'flex' },
  button: { width: '180px', margin: `0 ${theme.spacing(1)}` },
  heading: { margin: `${theme.spacing(2)} 0`, marginBottom: theme.spacing(3) },
  row: { display: 'flex' },
  keyTypography: { margin: 'auto 0' },
  generalContainer: { marginTop: theme.spacing(5) },
  stylesContainerContent: { display: 'flex', flexDirection: 'column' },
  avatarContainer: { marginTop: theme.spacing(2), width: '100%', marginBottom: theme.spacing(2) },
  settingWrapperWithMargin: { marginTop: theme.spacing(3) },
  switchWrapperForHint: { display: 'flex', alignItems: 'center', gap: '8px' },
  colorsContainer: {
    maxWidht: '200px',
    maxHeight: '200px',
  },
  avatarUpload: {
    height: '200px',
    width: '200px',
    border: '3px dotted grey',
    display: 'flex',
  },
  avatarImage: {
    width: '100%',
    height: 'fit-content',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  colorPreview: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: theme.spacing(1),
    width: '40px',
    height: '40px',
    borderRadius: '50%',
  },
}))

type QnASettingsProps = {
  webchatSettings: BotSettings['webchatSettings']
  qnaSettings: BotSettings['qnaSettings']
  onWebchatSettingsChange: (newWebchatSettings: BotSettings['webchatSettings']) => void
  onQnaSettingsChange: (newQnaSettings: BotSettings['qnaSettings']) => void
}

export default function QnASettings({
  webchatSettings,
  qnaSettings,
  onWebchatSettingsChange,
  onQnaSettingsChange,
}: QnASettingsProps): React.ReactElement {
  const { classes } = useStyles()
  const theme = useTheme()
  const { bot, hasModule } = useBotContext()
  const { lockState } = useLockingContext()
  const account = getAccount()
  const customerIdOfCurrentUser = account?.idTokenClaims ? account?.idTokenClaims['extension_CustomerID'] : undefined

  // qna mode texts
  const [qnaTextInputPlaceholder, setQnaTextInputPlaceholder] = useState<string>()
  const [qnaModeWelcomeText, setQnAmodeWelcomeText] = useState<string>()
  const [qnaModeAskForNewQuestionText, setQnaModeAskForNewQuestionText] = useState<string>()
  // qna settings
  const [qnaModeAskForNewQuestions, setQnaModeAskForNewQuestions] = useState<boolean>(false)
  const [rewriteAnswerToFitQuestion, setRewriteAnswerToFitQuestion] = useState<boolean>(false)

  // email fallback settings
  const [emailFallbackEnabled, setEmailFallbackEnabled] = useState<boolean>(false)
  const [emailFallbackSendConversationHistory, setEmailFallbackSendConversationHistory] = useState<boolean>(true)
  const [emailFallbackMessageThreshold, setEmailFallbackMessageThreshold] = useState<number>(1)
  const [emailFallbackDepartments, setEmailFallbackDepartments] = useState<EmailFallbackDepartment[]>([
    {
      departmentName: '',
      departmentDescription: '',
      departmentEmailIntroText: '',
      departmentRecipientAdress: '',
      departmentFallbackSubject: '',
      id: uuidv4(),
    },
  ])
  const [emailFallbackTexts, setEmailFallbackTexts] = useState<EmailFallbackTexts>()

  function onQnaTextInputPlaceholderChange(event: React.ChangeEvent<HTMLInputElement>) {
    setQnaTextInputPlaceholder(event.target.value)
    const newWebchatTexts = { ...(webchatSettings?.webchatTexts ?? {}), qnaTextInputPlaceholder: event.target.value }
    onWebchatSettingsChange({ ...webchatSettings, webchatTexts: newWebchatTexts })
  }

  function onQnaModeWelcomeTextChange(event: React.ChangeEvent<HTMLInputElement>) {
    setQnAmodeWelcomeText(event.target.value)
    const newWebchatTexts = { ...(webchatSettings?.webchatTexts ?? {}), qnaModeWelcomeText: event.target.value }
    onWebchatSettingsChange({ ...webchatSettings, webchatTexts: newWebchatTexts })
  }

  function onQnaModeAskForNewQuestionTextChange(event: React.ChangeEvent<HTMLInputElement>) {
    setQnaModeAskForNewQuestionText(event.target.value)
    const newWebchatTexts = {
      ...(webchatSettings?.webchatTexts ?? {}),
      qnaModeAskForNewQuestionText: event.target.value,
    }
    onWebchatSettingsChange({ ...webchatSettings, webchatTexts: newWebchatTexts })
  }

  function onQnaModeAskForNewQuestionsChange(event: React.ChangeEvent<HTMLInputElement>) {
    setQnaModeAskForNewQuestions(event.target.checked)
    onQnaSettingsChange({ ...qnaSettings, qnaModeAskForNewQuestion: event.target.checked })
  }

  function onRewriteAnswerToFitQuestionChange(event: React.ChangeEvent<HTMLInputElement>) {
    setRewriteAnswerToFitQuestion(event.target.checked)
    onQnaSettingsChange({ ...qnaSettings, rewriteAnswerToFitQuestion: event.target.checked })
  }

  function onEmailFallbackEnabledChange(event: React.ChangeEvent<HTMLInputElement>) {
    setEmailFallbackEnabled(event.target.checked)

    onWebchatSettingsChange({
      ...webchatSettings,
      emailFallbackSettings: {
        emailFallbackEnabled: event.target.checked,
        emailFallbackSendConversationHistory: emailFallbackSendConversationHistory,
        emailFallbackMessageThreshold: emailFallbackMessageThreshold,
        emailFallbackDepartments: emailFallbackDepartments,
      },
    })
  }

  function onEmailFallbackSendConversationHistoryChange(event: React.ChangeEvent<HTMLInputElement>) {
    setEmailFallbackSendConversationHistory(event.target.checked)
    onWebchatSettingsChange({
      ...webchatSettings,
      emailFallbackSettings: {
        emailFallbackEnabled,
        emailFallbackSendConversationHistory: event.target.checked,
        emailFallbackDepartments: emailFallbackDepartments,
        emailFallbackMessageThreshold: emailFallbackMessageThreshold,
      },
    })
  }

  function onEmailFallbackMessageThresholdChange(event: React.ChangeEvent<HTMLInputElement>) {
    setEmailFallbackMessageThreshold(parseInt(event.target.value))
    onWebchatSettingsChange({
      ...webchatSettings,
      emailFallbackSettings: {
        emailFallbackEnabled,
        emailFallbackSendConversationHistory: emailFallbackSendConversationHistory,
        emailFallbackDepartments: emailFallbackDepartments,
        emailFallbackMessageThreshold: parseInt(event.target.value),
      },
    })
  }

  function onEmailFallbackDepartmentsChange(newDepartmentConfig: EmailFallbackDepartment) {
    const index = emailFallbackDepartments.findIndex((department) => department.id === newDepartmentConfig.id)
    const newDepartments = [...emailFallbackDepartments]
    newDepartments[index] = newDepartmentConfig
    setEmailFallbackDepartments(newDepartments)
    onWebchatSettingsChange({
      ...webchatSettings,
      emailFallbackSettings: {
        emailFallbackEnabled,
        emailFallbackSendConversationHistory: emailFallbackSendConversationHistory,
        emailFallbackDepartments: newDepartments,
        emailFallbackMessageThreshold: emailFallbackMessageThreshold,
      },
    })
  }

  function onEmailFallbackDepartmentsDelete(id: string) {
    const newDepartments = emailFallbackDepartments.filter((department) => department.id !== id)
    setEmailFallbackDepartments(newDepartments)
    onWebchatSettingsChange({
      ...webchatSettings,
      emailFallbackSettings: {
        emailFallbackEnabled,
        emailFallbackSendConversationHistory: emailFallbackSendConversationHistory,
        emailFallbackDepartments: newDepartments,
        emailFallbackMessageThreshold: emailFallbackMessageThreshold,
      },
    })
  }

  function onEmailFallbackDepartmentsAdd() {
    const newDepartments = [...emailFallbackDepartments]
    newDepartments.push({
      departmentName: '',
      departmentDescription: '',
      departmentEmailIntroText: '',
      departmentRecipientAdress: '',
      departmentFallbackSubject: '',
      id: uuidv4(),
    })
    setEmailFallbackDepartments(newDepartments)
  }

  function onEmailFallbackTextsChange(newEmailFallbackTexts: EmailFallbackTexts) {
    setEmailFallbackTexts(newEmailFallbackTexts)
    onWebchatSettingsChange({
      ...webchatSettings,
      webchatTexts: { ...webchatSettings?.webchatTexts, emailFallbackTexts: newEmailFallbackTexts },
    })
  }

  useEffect(
    function initFromBotInfos() {
      // init webchat settings
      if (webchatSettings) {
        if (webchatSettings.webchatTexts) {
          if (qnaTextInputPlaceholder !== webchatSettings.webchatTexts.qnaTextInputPlaceholder)
            setQnaTextInputPlaceholder(webchatSettings.webchatTexts.qnaTextInputPlaceholder)
          if (qnaModeWelcomeText !== webchatSettings.webchatTexts.qnaModeWelcomeText)
            setQnAmodeWelcomeText(webchatSettings.webchatTexts.qnaModeWelcomeText)
          if (qnaModeAskForNewQuestionText !== webchatSettings.webchatTexts.qnaModeAskForNewQuestionText)
            setQnaModeAskForNewQuestionText(webchatSettings.webchatTexts.qnaModeAskForNewQuestionText)
          if (!isEqual(emailFallbackTexts, webchatSettings.webchatTexts.emailFallbackTexts)) {
            // init email fallback texts from settings (if they are already set) or with default values if they do not exist yet.
            setEmailFallbackTexts(
              webchatSettings.webchatTexts.emailFallbackTexts ?? {
                emailFallbackChatPromptText:
                  'Möchten Sie Ihre Frage stattdessen per E-Mail an die zuständige Stelle schicken?',
                emailFallbackChatCompletedText:
                  'Ihre Anliegen wurde per E-Mail versandt. Sie erhalten schnellstmöglich eine Antwort an Ihre angegebene E-Mail-Adresse.',
                emailFallbackOverlayTitle: 'Frage per E-Mail stellen',
                emailFallbackOverlayDepartmentText:
                  'Bitte wählen Sie die zustellige Stelle an die Ihr Anliegen per E-Mail geschickt werden soll.',
                emailFallbackOverlayEmailText:
                  'Bitte geben Sie Ihre E-Mail-Adresse an. An diese E-Mail wird schnellstmöglich eine Antwort auf Ihr Anliegen gesendet.',
                emailFallbackOverlayEmailPlaceholderText: 'max.mustermann@email.de',
                emailFallbackOverlayContentText:
                  'Ihre bisherige Konversation mit dem Assistenten, inklusive Ihrer letzten, unbeantworteten Frage wird automatisch an die E-Mail angehängt. Wenn nötig können noch weitere Informationen angeben, um die Bearbeitung Ihres Anliegens zu beschleunigen.',
                emailFallbackOverlayAdditionalTextPlaceholder:
                  'z.B. ihre Kundennummer oder zusätzliche Kontextinformationen zu Ihrer Frage',
                emailFallbackOverlaySendButtonText: 'Abschicken',
              },
            )
          }
        }
        if (emailFallbackEnabled !== webchatSettings.emailFallbackSettings?.emailFallbackEnabled)
          setEmailFallbackEnabled(!!webchatSettings.emailFallbackSettings?.emailFallbackEnabled)
        if (
          emailFallbackSendConversationHistory !==
          webchatSettings.emailFallbackSettings?.emailFallbackSendConversationHistory
        )
          setEmailFallbackSendConversationHistory(
            webchatSettings.emailFallbackSettings?.emailFallbackSendConversationHistory ?? false,
          )
        if (!isEqual(emailFallbackDepartments, webchatSettings.emailFallbackSettings?.emailFallbackDepartments))
          setEmailFallbackDepartments(
            webchatSettings.emailFallbackSettings?.emailFallbackDepartments ?? [
              {
                id: uuidv4(),
                departmentName: '',
                departmentDescription: '',
                departmentEmailIntroText: '',
                departmentRecipientAdress: '',
                departmentFallbackSubject: '',
              },
            ],
          )
        if (emailFallbackMessageThreshold !== webchatSettings.emailFallbackSettings?.emailFallbackMessageThreshold)
          setEmailFallbackMessageThreshold(webchatSettings.emailFallbackSettings?.emailFallbackMessageThreshold ?? 1)
      }

      // init qna settings
      if (qnaSettings) {
        if (qnaModeAskForNewQuestions !== qnaSettings.qnaModeAskForNewQuestion)
          setQnaModeAskForNewQuestions(!!qnaSettings.qnaModeAskForNewQuestion)
        if (rewriteAnswerToFitQuestion !== qnaSettings.rewriteAnswerToFitQuestion)
          setRewriteAnswerToFitQuestion(!!qnaSettings.rewriteAnswerToFitQuestion)
      }
    },
    [webchatSettings, qnaSettings],
  )

  const IS_CURRENT_USER_CONVAISE_ADMIN = customerIdOfCurrentUser === CONVAISE_CUSTOMER_ID

  return (
    <div id='qna-settings' className={classes.generalContainer}>
      <Typography className={classes.heading} variant='h2'>
        QnA Einstellungen
      </Typography>

      <div className={classes.content}>
        {bot?.webchatVersion === 'v4' && (
          <>
            <Typography variant='h4' style={{ marginTop: theme.spacing(4) }}>
              Standardtexte im QnA Modus
            </Typography>
            <div className={classes.settingWrapperWithMargin}>
              <Typography>
                Diese Texte konfigurieren den QnA Modus des Assistenten. Übersetzungen in andere Sprachen des
                Assistenten können über die Übersetzungsseite in Studio festgelegt werden.
              </Typography>
            </div>
            <div className={classes.settingWrapperWithMargin}>
              <Textfield
                label='Willkommensnachricht im QnA Modus'
                value={qnaModeWelcomeText}
                onChange={onQnaModeWelcomeTextChange}
                placeholder='Welche Frage kann ich beantworten?'
                fullWidth
                disabled={lockState !== 'canEdit'}
                multiline
                rows={3}
                rowsMax={8}
                hint='Die Willkommensnachricht wird vom Assistenten am Anfang der Konversation im QnA Modus an die Nutzer*innen gesendet.'
              />
            </div>
            <div className={classes.settingWrapperWithMargin}>
              <Textfield
                label='QnA Textinput Platzhalter'
                value={qnaTextInputPlaceholder}
                onChange={onQnaTextInputPlaceholderChange}
                placeholder='Ihre Frage...'
                fullWidth
                disabled={lockState !== 'canEdit'}
                hint='Der Text im Texteingabefeld des QnA Modus.'
              />
            </div>
          </>
        )}
        <div className={classes.settingWrapperWithMargin}>
          <FormGroup>
            {hasModule('nlu') && (
              <div className={classes.switchWrapperForHint}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={rewriteAnswerToFitQuestion}
                      onChange={onRewriteAnswerToFitQuestionChange}
                      disabled={true}
                    />
                  }
                  label='Antworten an Frage anpassen (bald verfügbar)'
                  disabled={lockState !== 'canEdit' || true}
                />
                <CustomizedTooltip
                  content={
                    <Typography>
                      Wenn aktiviert, formuliert der Assistent die Antwort so um, dass sie grammatikalisch zu der
                      gestellten Frage passt. Dadurch wird ein natürlicheres Konversationserlebnis für die Nutzer*innen
                      geschaffen. In den allermeisten Fällen bleibt der Inhalt und die Aussage der Antwort identisch zu
                      der in der Wissensdatenbank hinterlegen Antwort. In seltenen Fällen kann es jedoch vorkommen, dass
                      durch das umformulieren einzelne Inhalte nicht mehr oder nur noch abgeändert in der Antwort
                      enthalten sind.
                    </Typography>
                  }
                  placement='top'
                  elements={<i className='ri-question-line'></i>}
                />
              </div>
            )}
            <div className={classes.switchWrapperForHint}>
              <FormControlLabel
                control={<Switch checked={qnaModeAskForNewQuestions} onChange={onQnaModeAskForNewQuestionsChange} />}
                label='Nach neuer Frage fragen'
                disabled={lockState !== 'canEdit'}
              />
              <CustomizedTooltip
                content={
                  <Typography>
                    Kontrolliert, ob der Assistent nach einer neuen Frage fragen soll, nachdem er eine Antwort geschickt
                    hat. Grundsätzlich empfehlen wir, bei generativen KI-Assistenten diese Einstellung zu deaktivieren,
                    um eine natürlichere Konversation zu ermöglichen.
                  </Typography>
                }
                placement='top'
                elements={<i className='ri-question-line'></i>}
              />
            </div>
          </FormGroup>
        </div>
        {bot?.webchatVersion === 'v4' && qnaModeAskForNewQuestions && (
          <div className={classes.settingWrapperWithMargin}>
            <Textfield
              label='Text zum Anfordern einer neuen Frage'
              placeholder='Welche Frage kann ich noch beantworten?'
              value={qnaModeAskForNewQuestionText}
              onChange={onQnaModeAskForNewQuestionTextChange}
              fullWidth
              disabled={lockState !== 'canEdit'}
            />
          </div>
        )}

        {/* email fallback settings */}
        {bot?.webchatVersion === 'v4' && (
          <>
            <Typography variant='h4' style={{ marginTop: theme.spacing(4) }}>
              E-Mail Fallback für unbeantwortete Fragen
            </Typography>

            <div className={classes.settingWrapperWithMargin}>
              <div className={classes.switchWrapperForHint}>
                <FormControlLabel
                  control={<Switch checked={emailFallbackEnabled} onChange={onEmailFallbackEnabledChange} />}
                  label='E-Mail Fallback ermöglichen'
                  disabled={lockState !== 'canEdit'}
                />
                <CustomizedTooltip
                  content={
                    <Typography>
                      Wenn der Assistent eine bestimmte Anzahl von Fragen nicht beantworten kann, können Sie den
                      Nutzer*innen die Möglichkeit bieten, ihre Fragen und den bisherigen Gesprächsverlauf per E-Mail an
                      eine von Ihnen festgelegte Stelle zu senden. Sie können selbst bestimmen, nach wie vielen
                      unbeantworteten Fragen diese Option den Nutzern angezeigt werden soll und welche Stellen die
                      Nutzer*innen zur Auswahl haben sollen.
                    </Typography>
                  }
                  placement='top'
                  elements={<i className='ri-question-line'></i>}
                />
              </div>
            </div>
            {emailFallbackEnabled && (
              <>
                <div className={classes.settingWrapperWithMargin}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <Textfield
                        label='Anzahl unbeantworteter Fragen, bevor E-Mail Fallback angezeigt wird'
                        value={emailFallbackMessageThreshold.toString()}
                        onChange={onEmailFallbackMessageThresholdChange}
                        fullWidth
                        type='number'
                        disabled={lockState !== 'canEdit'}
                        InputProps={{ min: 1 }}
                        error={emailFallbackMessageThreshold < 1}
                        helperText={emailFallbackMessageThreshold < 1 ? 'Threshold muss mindestens 1 sein' : ''}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.settingWrapperWithMargin}>
                  <div className={classes.switchWrapperForHint}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={emailFallbackSendConversationHistory}
                          onChange={onEmailFallbackSendConversationHistoryChange}
                        />
                      }
                      label='Konversationsverlauf in E-Mail mitschicken'
                      disabled={lockState !== 'canEdit'}
                    />
                    <CustomizedTooltip
                      content={
                        <Typography>
                          Wenn aktiviert, wird der bisherige Konversationsverlauf in der E-Mail mitgeschickt.
                        </Typography>
                      }
                      placement='top'
                      elements={<i className='ri-question-line'></i>}
                    />
                  </div>
                </div>
                <div className={classes.settingWrapperWithMargin}>
                  <Typography variant='h5' style={{ marginBottom: theme.spacing(2) }}>
                    Zuständige Stellen
                  </Typography>
                  <Typography>
                    Konfigurieren Sie die Stellen, an die Nutzer*innen ihr Anliegen / ihre Frage per E-Mail schicken
                    können. Sie müssen mindestens eine Stelle konfigurieren. Wenn Sie mehrere Stellen mit
                    unterschiedlichen Zuständigkeiten festlegen, müssen die Nutzer*innen auswählen, an welche Stelle ihr
                    Anliegen geschickt werden soll.
                  </Typography>
                  {emailFallbackDepartments.map((department, index) => {
                    return (
                      <EmailFallbackDepartmentConfiguration
                        id={department.id}
                        departmentIndex={index}
                        deletable={emailFallbackDepartments.length > 1}
                        departmentName={department.departmentName}
                        departmentDescription={department.departmentDescription}
                        departmentEmailIntroText={department.departmentEmailIntroText}
                        departmentRecipientAdress={department.departmentRecipientAdress}
                        departmentFallbackSubject={department.departmentFallbackSubject}
                        onDepartmentConfigChange={onEmailFallbackDepartmentsChange}
                        onDeleteDepartment={onEmailFallbackDepartmentsDelete}
                        key={`department-${department.id}`}
                      />
                    )
                  })}
                  <div
                    style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: theme.spacing(2) }}
                  >
                    <Button
                      variant='secondary'
                      onClick={onEmailFallbackDepartmentsAdd}
                      disabled={lockState !== 'canEdit'}
                    >
                      Stelle hinzufügen
                    </Button>
                  </div>
                </div>

                {/* Webchat texts for email fallback feature - configures the texts for the chat and the overlay */}
                <div className={classes.settingWrapperWithMargin}>
                  <EmailFallbackTextsConfig
                    emailFallbackTexts={emailFallbackTexts}
                    onEmailFallbackTextsChange={onEmailFallbackTextsChange}
                  />
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}
