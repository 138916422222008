import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
// Hooks
import { useErrorContext } from 'hooks/contexts/errorContext'
// UTILS
import { lowercaseAndRemoveNonAlphaNumeric } from 'utils/stringUtils'
import { isArrayEqual } from 'utils/utils'
// API
import { saveAnswerData, getAnswerData } from 'api/StudioBackend'

// Material UI components
import { makeStyles } from 'tss-react/mui'

import { Grid, Typography } from '@mui/material'
// Custom Components
import IconCard from 'components/Cards/IconCard'
import Dialog from 'components/Dialogs/Dialog'
import Button from 'components/Buttons/Button'
import { Textfield } from 'components/TextInput/Textfield'
import ContentPage, { ContentPageHeader } from 'components/Page/ContentPage'
import NotificationSnackbar from 'components/NotificationSnackbar/NotificationSnackbar'
import ErrorComponent from 'components/Error/Error'
import CircularLoading from 'components/Loading/CircularLoading'
import TopicCard from './Cards/TopicCard'
import VariableCard from './Cards/VariableCard'
import AddVariableCard from './Cards/AddVariableCard'
import CreateCategoryDialog from './Dialogs/CreateCategoryDialog'
// Types
import {
  AnswerTemplateData,
  AnswerTemplateDataObject,
  AnswerTemplateVariableObject,
} from '../../../../@types/Knowledge/types'
// Constants
import { ROUTE_BOTS, ROUTE_BOTID_KNOWLEDGE } from 'utils/constants'
import { cloneDeep } from 'lodash'

const useStyles = makeStyles()((theme) => ({
  cardsContainer: {
    marginTop: theme.spacing(3),
    width: 'fit-content',
  },
  cardsContainerFullwidth: {
    marginTop: theme.spacing(3),
    width: '100%',
  },
  addIcon: {
    marginTop: '0px',
  },
}))

// FIXME: sometimes says category, sometimes topic for the same thing
// TODO: check if var exists in topic

// Always use name topic for top level

export default function InformationsManagement(): React.ReactElement {
  const { classes } = useStyles()
  const { setError } = useErrorContext()
  const { botId, moduleConfigId } = useParams() as { botId: string; moduleConfigId: string }
  const navigate = useNavigate()

  const [data, setData] = useState<AnswerTemplateData>([]) // holds template data in state, used for populating component
  const [oldData, setOldData] = useState<AnswerTemplateData>([]) // holds template data in state, used for populating component
  const [selectedTopic, setSelectedTopic] = useState<AnswerTemplateDataObject | undefined>(undefined)
  const [searchString, setSearchString] = useState<string>() // search string for fuse search on answer template data
  const [apiState, setApiState] = useState<'saving' | 'loading' | undefined>()
  const [displayCreateCategoryDialog, setDisplayCreateCategoryDialog] = useState<boolean>(false)
  const [expandedVariableName, setExpandedVariableName] = useState<string>() // name (not display name) of currently expanded variable card
  const [discardDialog, setDiscardDialog] = useState<boolean>(false) // defines if the the discard changes dialog is shown
  // notification
  const [notificationStatus, setNotificationStatus] = useState<'success' | 'error'>() // we do not need to specify in more detail because we have error message
  const [showNotification, setShowNotification] = useState<boolean>()
  const [notificationMessage, setNotificationMessage] = useState<string>()

  /**
   * Performs API call to get
   */
  async function load(retry?: boolean): Promise<void> {
    setApiState('loading')
    try {
      const answerTemplateData = await getAnswerData(botId)
      if (answerTemplateData) {
        setData(answerTemplateData)
        // setData(testData) // FIXME: dev
        setOldData(answerTemplateData)
        // setOldData(testData) // FIXME: dev
        setApiState(undefined)
        // setSelectedTopic(testData[0]) // FIXME: dev
        setSelectedTopic(answerTemplateData && answerTemplateData.length > 0 ? answerTemplateData[0] : undefined)
      } else {
        throw new Error('getAnswerData is null')
      }
    } catch (err) {
      if (!retry) {
        console.info('Retry: Load AnswerData')
        load(true)
      } else {
        setError(
          'Knowledge.specific.infomanagement',
          'Die Daten des Assistenten konnten nicht geladen werden. Bitte versuchen Sie es erneut.',
          'Erneut versuchen',
          load,
          'Fehler beim Laden der Daten',
        )
        setApiState(undefined)
      }
    }
  }

  /**
   * Performs API call to save the entered data.
   */
  async function onSave(): Promise<void> {
    setApiState('saving')
    // console.log(data)
    try {
      const response = await saveAnswerData(botId, 'specific', data)
      if (response === null) {
        throw new Error('Response is null')
      }
      setNotification('success', 'Speichern erfolgreich.')
      setOldData(cloneDeep(data))
    } catch (err) {
      console.error('[InformationManagement] Saving error.')
      setNotification('error', 'Speichern fehlgeschlagen. Bitte versuchen Sie es erneut.')
    }
    setApiState(undefined)
  }

  /**
   * Handles user input in searchfield and updates search string in state.
   * @param event
   */
  function onSearchStringChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setSearchString(event.target.value)
  }

  /**
   * Handles variable value change.
   * Updates data.
   * @param topicName Name of the
   * @param variableName
   * @param value
   */
  function onValueChange(topicName: string, variableName: string, value: string): void {
    // find topic object in list
    const index = data.findIndex((topicObj) => topicObj.topic.name === topicName)
    if (index < 0) return // not found

    const topicObj = data[index]
    // get varIndex to update
    if (topicObj.data && topicObj.data.length > 0) {
      const varIndex = topicObj.data.findIndex(
        (variable: AnswerTemplateVariableObject) => variable.key === variableName,
      )
      if (varIndex >= 0) {
        const newVar = { key: variableName, value }
        topicObj.data[varIndex] = newVar
        data[index] = topicObj
      }
    }

    setData([...data])
  }

  /**
   * Handles variable name change.
   * Removes alpha-numeric chars and lowercases new display name to get "normal" name.
   * Sets new name in state and updates the data object with the changed variable name.
   * TODO
   * @param topicName name of the topic that has the var
   * @param oldName old name of the variable
   * @param newName new name of the variable
   */
  function onVariableNameChange(topicName: string, oldName: string, newName: string): void {
    const newCleanName = lowercaseAndRemoveNonAlphaNumeric(newName)

    // NOTE: This is not necessary anymore, since it was fixed that the displayName was given for the oldName ~ Jakob 25.06.21
    // oldName also needs to be cleaned, as it acts as id to find the correct variableObject
    // const oldCleanName = lowercaseAndRemoveNonAlphaNumeric(oldName)
    const oldCleanName = oldName

    // find topic object in list
    const index = data.findIndex((topicObj) => topicObj.topic.name === topicName)
    if (index < 0) return // not found

    const topicObj = data[index]

    // find varIndex in topicObj
    if (topicObj.data && topicObj.data.length > 0) {
      const varIndex = topicObj.data.findIndex(
        (variable: AnswerTemplateVariableObject) => variable.key === oldCleanName,
      )
      const variableObject = topicObj.data[varIndex]
      variableObject.key = newCleanName

      // rename variable key in object
      // const newCategoryObjData = renameObjectKey(
      //   categoryObj.data,
      //   oldCleanName,
      //   newCleanName
      // ) as AnswerTemplateDataObject['data']
      // const newCategoryObj = {
      //   ...categoryObj,
      //   data: newCategoryObjData,
      // }

      // TODO: do we need to update topicObj or are the change passed through because of the reference?
      data[index] = topicObj
    }

    setData([...data])
  }

  /**
   * Handles new topic creation (from dialog).
   * Adds new topic to data list.
   * @param topic
   */
  function onCreateNewCategory(topic: AnswerTemplateDataObject): void {
    setData([...data, topic])
    setSelectedTopic(topic)
    setDisplayCreateCategoryDialog(false)
  }

  /**
   * Deletes variable from topic.
   * @param variableName
   */
  function onDeleteTopic(topic: AnswerTemplateDataObject['topic']['name']): void {
    if (!topic) return
    const newData = [...data].filter((categoryObj) => {
      return categoryObj.topic.name !== topic
    })
    setSelectedTopic(undefined)
    setData(newData)
  }

  /**
   * Closes create topic dialog
   */
  function onCloseCreateCategoryDialog(): void {
    setDisplayCreateCategoryDialog(false)
  }

  /**
   * Creates new variable.
   * @param variableName
   */
  function onCreateVariable(variableName: string): void {
    if (!selectedTopic) return

    const name = lowercaseAndRemoveNonAlphaNumeric(variableName)

    const newVariable: AnswerTemplateVariableObject = { key: name, value: '' }

    const newTopic = { ...selectedTopic }
    // check if variable name does not exist on data in topic
    // const index of name in topic
    const nameIndex = newTopic.data.findIndex(
      (variable: AnswerTemplateVariableObject) => variable.key === newVariable.key,
    )
    if (nameIndex < 0) {
      newTopic.data.push(newVariable)
    } // TODO: else error handling?

    const newData = [...data]

    // replace topic object in data state
    const topicIndex = newData.findIndex(
      (topicObj: AnswerTemplateDataObject) => topicObj.topic.name === newTopic.topic.name,
    )
    if (topicIndex >= 0) newData[topicIndex] = newTopic
    else newData.push(newTopic)

    setSelectedTopic(newTopic)
    setExpandedVariableName(name)
    setData(newData)
  }

  /**
   * Deletes variable from topic.
   * @param variableName
   */
  function onDeleteVariable(variableName: string): void {
    if (!selectedTopic) return

    const newTopic = { ...selectedTopic }
    if (newTopic.data) {
      const varIndex = newTopic.data.findIndex(
        (variable: AnswerTemplateVariableObject) => variable.key === variableName,
      )
      if (varIndex >= 0) {
        newTopic.data.splice(varIndex, 1)
      } else {
        return
      }
    }

    // replace topic object in data
    const newData = [...data]
    const topicIndex = newData.findIndex(
      (topicObj: AnswerTemplateDataObject) => topicObj.topic.name === newTopic.topic.name,
    )
    if (topicIndex >= 0) newData[topicIndex] = newTopic
    else newData.push(newTopic)

    setData(newData)
  }

  /**
   * Callback for expansion change of variable card.
   * @param variableName
   * @param isExpanded
   */
  function onExpandChange(variableName: string, isExpanded: boolean): void {
    if (isExpanded) setExpandedVariableName(variableName)
  }

  /**
   * Checks whether or not new name is valid.
   * @param newDisplayName
   */
  function isValidVariableName(newDisplayName: string): { isValid: boolean; message?: string } {
    // check all variables of current topic if name already exists
    const name = lowercaseAndRemoveNonAlphaNumeric(newDisplayName)

    if (!selectedTopic) return { isValid: false }

    for (const variable of selectedTopic.data) {
      if (variable.key === name) {
        return { isValid: false, message: 'Diese Variable existiert bereits!' }
      }
    }

    return { isValid: true }
  }

  /**
   * Sets the notification snackbar.
   * Also registers timeout of 3 seconds. Resets state and hides notification after 3 seconds
   * @param status
   * @param message
   */
  function setNotification(status: 'success' | 'error', message: string): void {
    setNotificationStatus(status)
    setNotificationMessage(message)
    setShowNotification(true)
    setTimeout(function () {
      setShowNotification(false)
      setNotificationStatus(undefined)
      setNotificationMessage(undefined)
    }, 3000)
  }

  // ======== Content Page Header Actions
  const SearchField = (
    <Textfield
      value={searchString}
      placeholder='Suchen'
      onChange={onSearchStringChange}
      disabled={apiState === 'loading'}
    />
  )

  const SaveButton = (
    <Button
      size='normal'
      type='success'
      icon='save-line'
      iconType='remix'
      onClick={onSave}
      loading={apiState === 'saving'}
      disabled={apiState === 'saving' || apiState === 'loading'}
    >
      Speichern
    </Button>
  )

  useEffect(function () {
    // load data
    load()
  }, [])

  return (
    <>
      <ContentPage>
        <ContentPageHeader
          title='Daten'
          actions={[SearchField, SaveButton]}
          previousUrl={ROUTE_BOTS + '/' + botId + ROUTE_BOTID_KNOWLEDGE + `/${moduleConfigId}`}
          previousUrlCallback={(): boolean => {
            const isEqual = isArrayEqual(data, oldData)
            if (!isEqual) setDiscardDialog(true)
            return isEqual
          }}
        />
        <ErrorComponent errorCode='Knowledge.specific.infomanagement'>
          {apiState === 'loading' && <CircularLoading text='Daten werden geladen. Bitte warten...' size='medium' />}
          {apiState !== 'loading' && (
            <Grid container spacing={2} justifyContent='center'>
              <Grid item xs={12} md={4}>
                {/* Topics */}
                <Typography variant='h3'>Kategorie</Typography>
                <div className={classes.cardsContainer}>
                  {data.map((categoryObj) => (
                    <TopicCard
                      key={`topic-${categoryObj.topic.name}`}
                      templateData={categoryObj}
                      onClick={(): void => {
                        if (selectedTopic?.topic.name !== categoryObj.topic.name) setSelectedTopic(categoryObj)
                      }}
                      isSelected={selectedTopic?.topic.name === categoryObj.topic.name}
                      onDeleteTopic={onDeleteTopic}
                    />
                  ))}
                  <IconCard
                    footerText='Neue Kategorie erstellen'
                    icon={<i className={`ri-add-line ${classes.addIcon}`} />}
                    onClick={(): void => setDisplayCreateCategoryDialog(true)}
                    height='150px'
                    width='350px'
                    iconSize={50}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={8}>
                {/* Variables */}
                <Typography variant='h3'>Variablen</Typography>
                <div className={classes.cardsContainerFullwidth}>
                  {selectedTopic ? (
                    <>
                      {selectedTopic.data.map((variable, idx) => (
                        <VariableCard
                          key={`var-${idx}`}
                          topic={selectedTopic.topic}
                          variable={variable}
                          onVariableNameChange={onVariableNameChange}
                          onDeleteVariable={onDeleteVariable}
                          onValueChange={onValueChange}
                          isValidVariableName={isValidVariableName}
                          isExpanded={variable.key === expandedVariableName}
                          onExpandChange={onExpandChange}
                        />
                      ))}
                      <AddVariableCard isValidVariableName={isValidVariableName} onCreateVariable={onCreateVariable} />
                    </>
                  ) : null}
                </div>
              </Grid>
            </Grid>
          )}
        </ErrorComponent>
        <NotificationSnackbar
          position='top'
          severity={notificationStatus === 'error' ? 'error' : 'success'}
          open={!!showNotification}
          message={notificationMessage ?? ''}
        />
      </ContentPage>
      {displayCreateCategoryDialog && (
        <CreateCategoryDialog
          answerTemplateData={data}
          onClose={onCloseCreateCategoryDialog}
          onCreateCategory={onCreateNewCategory}
        />
      )}
      {/* Discard changes dialog */}
      <Dialog
        id='discard-changes-dialog'
        size='small'
        open={discardDialog}
        closable
        onClose={(): void => {
          setDiscardDialog(false)
        }}
        title='Ungespeicherte Änderungen'
        primaryActionButton={
          <Button
            size='small'
            type='success'
            // icon={<Delete />}
            onClick={(): void => {
              setDiscardDialog(false)
            }}
          >
            Abbrechen
          </Button>
        }
        secondaryActionText='Verwerfen'
        onSecondaryActionClick={(): void =>
          navigate(ROUTE_BOTS + '/' + botId + ROUTE_BOTID_KNOWLEDGE + `/${moduleConfigId}`)
        }
      >
        <Typography>
          Es existieren ungespeicherte Änderungen. Sind Sie sicher, dass Sie zurückgehen möchten? Ihre Änderungen werden
          dabei verworfen.
        </Typography>
      </Dialog>
    </>
  )
}
