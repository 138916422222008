import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Typography } from '@mui/material'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { makeStyles } from 'tss-react/mui'
// Custom Components
import { Button } from 'components/Buttons'
import BaseCard from 'components/Cards/BaseCard'
import ErrorComponent from 'components/Error/Error'
import CircularLoading from 'components/Loading/CircularLoading'
import ContentPage, { ContentPageHeader } from 'components/Page/ContentPage'
// Assets

// import AssignUtteranceDialog from './AssignUtteranceDialog/AssignUtteranceDialog'
import AddUtteranceToAnswerDialog from './ModellManagementComponents/AddUtteranceToAnswerDialog/AddUtteranceToAnswerDialog'

import { Answer, AnswerWithoutIntent, TriggerAnswerWithoutIntent } from 'classes/Knowledge'
import { LLMNLUEndpointUtterance } from '../../../../@types/Knowledge/types'

// constants
import { ROUTE_BOTID_KNOWLEDGE, ROUTE_BOTS } from 'utils/constants'

import svgNoData from 'assets/img/general_noData.svg'
import { useAnswers } from 'hooks/contexts/answers-context'
import { useLockingContext } from 'hooks/contexts/locking-context'
import { useModellmanagementLLMContext } from 'hooks/contexts/modelmanagement-llm-context'
import ModellManagementLLMNLUTable from './ModellManagementComponents/ModellManagementLLMNLUTable'
import CreateAnswerDialog from '../ConversationManagement/AnswerCreation/CreateAnswerDialog'

const useStyles = makeStyles()((theme) => ({
  iconButton: {
    marginLeft: theme.spacing(1),
    marginBottom: 'auto',
    marginTop: 'auto',
    padding: theme.spacing(1),
    color: theme.palette.common.white,
  },
  iconButtonDelete: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
    '&:disabled': {
      backgroundColor: theme.palette.grey[400],
      color: theme.palette.common.white,
    },
  },
  iconButtonAccept: {
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.light,
    },
    '&:disabled': {
      backgroundColor: theme.palette.grey[400],
      color: theme.palette.common.white,
    },
  },
  iconButtonEdit: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    '&:disabled': {
      backgroundColor: theme.palette.grey[400],
      color: theme.palette.common.white,
    },
  },
  icon: { fontSize: '1.2rem' },
  noDataContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  noDataImage: {
    width: '300px',
  },
  noDataText: {
    maxWidth: '350px',
    paddingTop: theme.spacing(2),
    textAlign: 'center',
  },
  markdown: {
    fontSize: '1rem',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical',
    '& p': {
      margin: 0,
    },
  },
  placeholder: {
    height: '32px',
    width: '32px',
  },
  loadMoreButtonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(2),
  },
  loadMoreIndicator: {
    position: 'absolute',
    right: theme.spacing(6),
  },
}))

export default function ModellManagementLLMNLU(): React.ReactElement {
  const { classes } = useStyles()
  const { lockState } = useLockingContext()
  const {
    canIEditAnswer,
    modelStatus,
    isModelTrainingButtonDisabled,
    trainModel,
    flaggedAnswers: answers,
    answersArrayPrimaryLang,
    createAnswer,
    primaryLang,
  } = useAnswers()
  const {
    reset,
    loadNextUtterances,
    endpointUtterances,
    loading,
    addUtteranceToAnswer,
    deleteUtterance,
    onChangeSuggestedAnswer,
    totalNumberEndpointUtterances,
    isInitialized: mmIsInitialized,
  } = useModellmanagementLLMContext()

  const { botId, moduleConfigId } = useParams() as { botId: string; moduleConfigId: string }

  // interaction

  const [selectedUtterance, setSelectedUtterance] = useState<LLMNLUEndpointUtterance>() // holds utterance for which dialog is displayed
  const [displayEditSuggestionDialog, setDisplayEditSuggestionDialog] = useState<boolean>()
  const [displayCreateAnswerDialog, setDisplayCreateAnswerDialog] = useState<boolean>()
  // data
  const [endpointQuestions, setEndpointQuestions] = useState<LLMNLUEndpointUtterance[]>()
  const [sort, setSort] = useState<'oldest' | 'newest'>('newest')
  const prevSortRef = useRef<'oldest' | 'newest'>('newest')
  const [initialized, setInitialilzed] = useState<boolean>(false)

  // ============= API ==============

  /**
   * Removes utterance without assigning it to intent.
   * @param utterance
   */
  async function onDeleteUtterance(utterance: LLMNLUEndpointUtterance): Promise<void> {
    if (lockState !== 'canEdit') return
    if (selectedUtterance || displayEditSuggestionDialog) {
      setSelectedUtterance(undefined)
      setDisplayEditSuggestionDialog(false)
    }

    await deleteUtterance(utterance)
  }

  /**
   * Adds utterance to intent.
   * Removes utterance from displayed list and re-adds it if the API call fails.
   * @param utterance
   * @param intentName
   */
  async function onAddUtterance(utterance: LLMNLUEndpointUtterance): Promise<void> {
    if (lockState !== 'canEdit' || !utterance.suggestedAnswer) return
    if (displayEditSuggestionDialog) setDisplayEditSuggestionDialog(false)
    await addUtteranceToAnswer(utterance, utterance.suggestedAnswer.intent)
  }

  /**
   * Hides create answer dialog.
   * Creates answer and deletes endpoint utterance in parallel.
   * @param answer
   */
  async function onCreateAnswer(
    answer: AnswerWithoutIntent | TriggerAnswerWithoutIntent,
    selectedUtterance: LLMNLUEndpointUtterance,
  ): Promise<void> {
    if (lockState !== 'canEdit') return
    // close dialog
    setDisplayCreateAnswerDialog(false)
    await Promise.all([createAnswer(answer), deleteUtterance(selectedUtterance)])
  }

  /**
   * Selects utterance for editing in the edit dialog. Sets utterance in state.
   * @param utterance
   */
  function onEditUtterance(utterance: LLMNLUEndpointUtterance): void {
    setSelectedUtterance(utterance)
    setDisplayEditSuggestionDialog(true)
  }

  /**
   * Handles dialog confirm click.
   * Finds correct intent based on answerId and adds utterance.
   * @param utterance
   * @param answer
   */
  function onEditDialogConfirm(utterance: LLMNLUEndpointUtterance, answer: Answer): void {
    const { intent, answerId, language } = answer
    utterance.suggestedAnswer = {
      intent,
      answerId,
      language,
    }
    onAddUtterance(utterance)
  }

  /**
   * Handles create answer button click in edit dialog.
   * Hides edit dialog and displays create answer dialog instead.
   */
  function onEditDialogCreateAnswer(): void {
    setDisplayCreateAnswerDialog(true)
    setDisplayEditSuggestionDialog(false)
  }

  function onLoadMoreUtterances(): void {
    loadNextUtterances(sort)
  }

  function onSortChange(event, newValue): void {
    if (newValue !== null && newValue !== sort) {
      setSort(newValue)
    }
  }

  // ============ USE EFFECTS =================
  useEffect(() => {
    // sort switch
    // reset new sort state (if any) and trigger load
    if (sort !== prevSortRef.current) {
      // sort has changed
      setInitialilzed(false)
      reset()
      loadNextUtterances(sort, true)
      prevSortRef.current = sort
    }
  }, [sort, prevSortRef, loadNextUtterances])

  useEffect(() => {
    if (endpointUtterances) {
      setEndpointQuestions(endpointUtterances)
    }
  }, [endpointUtterances])

  useEffect(() => {
    if (endpointQuestions && !initialized && mmIsInitialized) {
      setInitialilzed(true)
    } else {
      // setInitialilzed(false)
    }
  }, [endpointQuestions, initialized, mmIsInitialized])

  // ================ RENDER + COMPONENTS =================

  const Filter = (
    <ToggleButtonGroup value={sort} exclusive onChange={onSortChange} aria-label='Sortierung' size='large'>
      <ToggleButton value='oldest' aria-label='left aligned' disabled={loading === 'loading'}>
        Älteste zuerst
      </ToggleButton>
      <ToggleButton value='newest' aria-label='centered' disabled={loading === 'loading'}>
        Neueste zuerst
      </ToggleButton>
    </ToggleButtonGroup>
  )

  return (
    <>
      <ContentPage>
        <ContentPageHeader
          title={`Fragen aus dem Live-Betrieb`}
          actions={[Filter]}
          previousUrl={ROUTE_BOTS + '/' + botId + ROUTE_BOTID_KNOWLEDGE + `/${moduleConfigId}`}
        />
        <BaseCard width='100%' height='auto'>
          <ErrorComponent errorCode='Knowledge.specific.modelmanagment'>
            {(loading === 'loading' && !initialized) || !answers?.answers || !endpointQuestions ? (
              // initial load
              <CircularLoading text='Nutzerfragen werden geladen...' size='medium' />
            ) : (
              <>
                {totalNumberEndpointUtterances === 0 && endpointQuestions.length === 0 ? (
                  <div className={classes.noDataContainer}>
                    <img className={classes.noDataImage} src={svgNoData} alt='Grafik: Clip Board mit leeren Seiten' />
                    <Typography variant='body1' className={classes.noDataText}>
                      Aktuell liegen keine Fragen aus dem Live Betrieb vor, die zur Verbesserung des Modells verwendet
                      werden können.
                    </Typography>
                  </div>
                ) : (
                  <ModellManagementLLMNLUTable
                    endpointQuestions={endpointQuestions}
                    onEditUtterance={onEditUtterance}
                    onAddUtteranceToSuggestedAnswer={onAddUtterance}
                    onDeleteUtterance={onDeleteUtterance}
                    onSelectSuggestion={onChangeSuggestedAnswer}
                  />
                )}

                <div className={classes.loadMoreButtonContainer}>
                  {endpointQuestions && endpointQuestions.length < totalNumberEndpointUtterances && (
                    <Button
                      loading={loading === 'loading'}
                      icon='arrow-down-s-line'
                      iconType='remix'
                      onClick={onLoadMoreUtterances}
                    >
                      Weitere Fragen laden
                    </Button>
                  )}
                  {endpointQuestions.length > 0 && (
                    <Typography className={classes.loadMoreIndicator} variant='subtitle2'>
                      {`${endpointQuestions.length} / ${totalNumberEndpointUtterances}`}
                    </Typography>
                  )}
                </div>
              </>
            )}
          </ErrorComponent>
        </BaseCard>
        {displayEditSuggestionDialog && selectedUtterance && answers?.answers && primaryLang && (
          <AddUtteranceToAnswerDialog
            utterance={selectedUtterance}
            answers={answers.answers[primaryLang] as { [answerId: string]: Answer }}
            onCreateAnswer={onEditDialogCreateAnswer}
            onClose={(): void => {
              setDisplayEditSuggestionDialog(false)
              setSelectedUtterance(undefined)
            }}
            onRemove={onDeleteUtterance}
            onConfirm={onEditDialogConfirm}
            endpointType='answered'
          />
        )}
        {displayCreateAnswerDialog && selectedUtterance && (
          <CreateAnswerDialog
            answers={answersArrayPrimaryLang}
            existingUtterance={selectedUtterance.endpointUtterance}
            onClose={(): void => {
              setDisplayCreateAnswerDialog(false)
              setSelectedUtterance(undefined)
            }}
            onCreateAnswer={(answer: AnswerWithoutIntent | TriggerAnswerWithoutIntent): Promise<void> =>
              onCreateAnswer(answer, selectedUtterance)
            }
            botId={botId}
          />
        )}
      </ContentPage>
    </>
  )
}
