import { ErrorOutline } from '@mui/icons-material'
import { Grid, TextField, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Button } from 'components/Buttons'
import BaseCard from 'components/Cards/BaseCard'
import NotificationPreview from 'components/Previews/notificationPreview'
import RMEEditor from 'components/TextEditors/editor-mdx-markdown'
import EditableTypography from 'components/TextInput/EditableTypography'
import CustomizedTooltip from 'components/Tooltips/CustomContentTooltip'
import { NotificationActivatingStatus, useCustomNotifications } from 'hooks/contexts/customnotifications-context'
import { useLockingContext } from 'hooks/contexts/locking-context'
import StepTurnus from 'pages/CustomNotifications/SharedComponents/StepTurnus'
import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { buildTimeString, buildTurnusString, isNotificationExpired } from 'utils/notificationUtils'
import { CustomNotification } from '../../../@types/CustomNotifications/types'
import OverlappingNotificationHint from '../SharedComponents/OverlappingNotificationHint'

const useStyles = makeStyles()((theme) => ({
  root: { display: 'flex', flexDirection: 'row', height: '100%', marginBottom: theme.spacing(4) },
  name: { display: 'flex', width: 'fit-content' },
  titleIconButton: { marginLeft: 'auto' },
  tooltip: { width: 'fit-content' },
  flexContainer: { display: 'flex' },
  editAnswerContainer: {
    width: '100%',
    marginTop: theme.spacing(2),
    // height: 'fit-content',
    // display: 'flex',
    // flexDirection: 'column',
  },
  editAnswerItem: {
    // height: '380px',
    display: 'flex',
    flexDirection: 'column',
  },
  editAnswerTextfield: {
    flex: 1,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  editAnswerTips: {
    marginTop: 'auto',
  },
  editAnswerTipContainer: {
    display: 'flex',
    flexDirection: 'row',
    '& h3': {
      marginRight: theme.spacing(1),
    },
    '& p': {
      marginTop: '0.4rem',
    },
  },
  notificationPreviewContainer: {
    height: '100%',
    overflow: 'auto',
  },
  notificationPreviewTitle: {
    marginTop: theme.spacing(4),
  },
  notificationPreviewCard: {
    marginRight: theme.spacing(8),
  },
  answerEditMenu: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginBottom: theme.spacing(4),
    '& > div:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
  answerEdit: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  questionContainer: { marginTop: theme.spacing(4) },
  questions: { display: 'flex', flexDirection: 'column', width: '100%', height: '100%' },
  suggestions: { display: 'flex', flexDirection: 'column', height: '100%' },
  suggestionsContent: {
    // marginTop: theme.spacing(3)
  },
  suggestionChip: { marginRight: theme.spacing(2) },
  suggestionsInput: { marginTop: 'auto' },
  textfield: { width: '100%' },
  icon: {
    fontSize: '1.5rem',
  },
  markdown: { '& p': { margin: '0px !important' } },
  actionContainer: {
    display: 'flex',
    width: '100%',
    maxHeight: '200px',
    overflow: 'auto',
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius:
      typeof theme.shape.borderRadius === 'number' ? theme.shape.borderRadius * 2 : theme.shape.borderRadius,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  actionContainerRed: {
    color: theme.palette.error.main,
  },
  action: {
    width: '100%',
  },
  removeButton: {
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
  },
  removeActionTooltip: {
    display: 'inline-block',
    textAlign: 'right',
  },
  addActionContainer: {
    display: 'flex',
    width: '100%',
    maxHeight: '200px',
    overflow: 'auto',
    border: `1px solid ${theme.palette.grey[500]}`,
    cursor: 'pointer',
    borderRadius:
      typeof theme.shape.borderRadius === 'number' ? theme.shape.borderRadius * 2 : theme.shape.borderRadius,
    padding: `calc( ${theme.spacing(2)} - 1px)`,
    marginTop: 'auto',
    '&:hover, :active': {
      background: theme.palette.grey[100],
    },
  },
  translationContainer: {},
  markdownEditorTextfieldLabel: {
    marginTop: theme.spacing(1),
  },
  markdownEditorTextfieldInputContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  iconButton: {
    marginLeft: theme.spacing(1),
    marginBottom: 'auto',
    marginTop: 'auto',
    padding: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  iconButtonContainer: {
    display: 'flex',
  },
  notificationStatusContainer: {},
  notificationIsActiveContainer: {
    background: theme.palette.success.main,
  },
  notificationIsInActiveContainer: {
    background: theme.palette.grey['500'],
  },
  overlappingHint: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(2),
  },
}))

enum TurnusValue {
  Once = 'once',
  Daily = 'daily',
  Weekly = 'weekly',
}

type EditKnowledgeAnswerProps = {
  notification: CustomNotification
  onEditNotificationCallback: (notification: CustomNotification) => void
  overlappingNotification?: CustomNotification // notification which the current notification would overlap with if saved
}

// TODO: lock state

export default function EditKnowledgeAnswer({
  notification: _notification,
  onEditNotificationCallback,
  overlappingNotification: _overlappingNotification,
}: EditKnowledgeAnswerProps): React.ReactElement {
  const theme = useTheme()
  const { classes } = useStyles()
  const { lockState } = useLockingContext()
  const { notificationActivatingState } = useCustomNotifications()

  const [tab, setTab] = useState<'content' | 'turnus'>('content')
  const [notification, setNotification] = useState<CustomNotification>(_notification)
  const [overlappingNotification, setOverlappingNotification] = useState<CustomNotification | undefined>(
    _overlappingNotification,
  )

  const initialContentRef = useRef<string>(notification.content)

  function onNameChange(newName: string): void {
    onEditNotificationCallback({ ...notification, name: newName })
  }

  function onTitleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    onEditNotificationCallback({ ...notification, title: event.target.value })
  }

  function onSubTitleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    onEditNotificationCallback({ ...notification, subtitle: event.target.value })
  }

  function onContentChange(newContent: string): void {
    onEditNotificationCallback({ ...notification, content: newContent })
  }

  function onSetStartDate(date: Date): void {
    onEditNotificationCallback({
      ...notification,
      startDate: date,
      endDate: notification.type === 'once' ? date : notification.endDate,
    })
  }

  function onSetEndDate(date: Date): void {
    onEditNotificationCallback({ ...notification, endDate: date })
  }

  function onSetTimes(times: CustomNotification['times']): void {
    onEditNotificationCallback({ ...notification, times })
  }

  function onSetTurnus(turnus: CustomNotification['type']): void {
    const newNotification = { ...notification, type: turnus }
    if (turnus !== TurnusValue.Weekly) {
      newNotification.series = null
    } else if (turnus === TurnusValue.Weekly && newNotification.series === null) {
      newNotification.series = { days: [] }
    }
    onEditNotificationCallback(newNotification)
  }

  function onSetDays(days: number[]): void {
    onEditNotificationCallback({ ...notification, series: { days } })
  }

  function getFooterText(): string {
    if (
      notificationActivatingState &&
      notificationActivatingState.notificationIds.includes(notification.notificationId)
    ) {
      if (notificationActivatingState.status === NotificationActivatingStatus.Activating) return 'Wird aktiviert...'
      else if (notificationActivatingState.status === NotificationActivatingStatus.Deactivating)
        return 'Wird deaktiviert...'
    }

    if (isNotificationExpired(notification)) return 'Abgelaufen'
    else if (overlappingNotification) return 'Überlappend'
    else if (notification.isActive) return 'Aktiv'
    else return 'Deaktiviert'
  }

  function getFooterColor(): string {
    if (
      notificationActivatingState &&
      notificationActivatingState.notificationIds.includes(notification.notificationId)
    ) {
      return theme.palette.grey['500']
    }

    if (isNotificationExpired(notification)) return theme.palette.primary.main
    else if (overlappingNotification) return theme.palette.error.main
    else if (notification.isActive) return theme.palette.success.main
    else return theme.palette.grey[500]
  }

  useEffect(
    function () {
      setNotification({ ..._notification })
    },
    [_notification],
  )

  useEffect(
    function () {
      setOverlappingNotification(_overlappingNotification)
    },
    [_overlappingNotification],
  )

  /**
   * Handles title change.
   * @param event
   */
  // function onTitleChange(newTitle: string): void {
  //   setTitle(newTitle)
  //   updateAnswerInParent(undefined, undefined, undefined, newTitle)
  // }

  return (
    <div id='edit-notification' className={classes.root}>
      <BaseCard
        width='450px'
        height='100%'
        minHeight='70vh'
        className={classes.notificationPreviewCard}
        footerText={getFooterText()}
        footerColor={getFooterColor()}
      >
        <div className={classes.name}>
          <EditableTypography
            value={notification.name}
            onChange={onNameChange}
            maxWidth={'500px'}
            placeholder='Notification Name'
            disabled={lockState !== 'canEdit'}
          />
        </div>
        <Typography>{buildTurnusString(notification)}</Typography>
        <Typography>{buildTimeString(notification)}</Typography>
        <Typography className={classes.notificationPreviewTitle}>
          <strong>Vorschau</strong>
        </Typography>
        <div className={classes.notificationPreviewContainer}>
          <NotificationPreview
            title={notification.title}
            subtitle={notification.subtitle ?? ''}
            content={notification.content}
          />
        </div>
      </BaseCard>
      <div id='edit-answer-editing' className={classes.answerEdit}>
        <div id='edit-answer-menu' className={classes.answerEditMenu}>
          <CustomizedTooltip
            placement='bottom'
            disableInteractive
            content={<Typography variant='body1'>Notificationinhalt bearbeiten.</Typography>}
            elements={
              <div>
                <Button
                  size='normal'
                  type='normal'
                  icon='edit-box-line'
                  iconType='remix'
                  onClick={(): void => setTab('content')}
                  variant={tab === 'content' ? 'primary' : 'tertiary'}
                >
                  Inhalt
                </Button>
              </div>
            }
          />
          <CustomizedTooltip
            placement='bottom'
            disableInteractive
            content={<Typography variant='body1'>Zeitplan der Notification bearbeiten</Typography>}
            elements={
              <div>
                <Button
                  size='normal'
                  type='normal'
                  icon='time-line'
                  iconType='remix'
                  onClick={(): void => setTab('turnus')}
                  variant={tab === 'turnus' ? 'primary' : 'tertiary'}
                >
                  Turnus {overlappingNotification ? <ErrorOutline style={{ marginLeft: theme.spacing(1) }} /> : null}
                </Button>
              </div>
            }
          />
        </div>
        {tab === 'content' && (
          <BaseCard width='100%' height='100%'>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <TextField
                  id='notification-title'
                  label='Titel'
                  value={notification.title}
                  fullWidth
                  onChange={onTitleChange}
                  disabled={lockState !== 'canEdit'}
                  margin='normal'
                  helperText={`${notification?.title ? notification.title.length : 0}/150`}
                  inputProps={{
                    autoComplete: 'off',
                    maxLength: 150,
                  }}
                />
                <TextField
                  id='notification-subtitle'
                  label='Untertitel (optional)'
                  value={notification.subtitle}
                  fullWidth
                  onChange={onSubTitleChange}
                  margin='normal'
                  disabled={lockState !== 'canEdit'}
                  helperText={`${
                    notification.subtitle
                      ? notification.subtitle.length > 150
                        ? `${notification.subtitle.length}/150: Der Untertitel ist länger als empfohlen. Dadurch sinkt die Nutzerfreundlichkeit.`
                        : `${notification.subtitle.length}/150`
                      : '0/150'
                  }`}
                  inputProps={{
                    autoComplete: 'off',
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography>
                  <strong>Notificationinhalt bearbeiten</strong>
                </Typography>
                <div className={classes.editAnswerTextfield}>
                  <RMEEditor
                    input={initialContentRef.current}
                    type='html'
                    // autoFocus={true} // NOTE: I think this caused problems with the buttons of the editor ~ Jakob
                    onChangeCallback={onContentChange}
                    // placeholder='Ihr Nachrichteninhalt...'
                    readOnly={lockState !== 'canEdit'}
                  />
                </div>
              </Grid>
            </Grid>
            {overlappingNotification && (
              <div className={classes.overlappingHint}>
                <OverlappingNotificationHint type='edit' overlappingNotification={overlappingNotification} />
              </div>
            )}

            {/* <Grid container spacing={2} className={classes.editAnswerTips}>
              <Grid item xs={12} sm={6} md={4} className={classes.editAnswerTipContainer}>
                <Typography variant='h3'>1.</Typography>
                <Typography>
                  <strong>Kontext</strong>
                  <br />
                  Der Antworttext sollte so geschrieben sein, dass der Kontext der Antwort deutlich wird. Der einfachste
                  Check ist: Kann der Antworttext alleine stehen, ohne eine Frage, und ist noch verständlich?
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} className={classes.editAnswerTipContainer}>
                <Typography variant='h3'>2.</Typography>
                <Typography>
                  <strong>Länge</strong>
                  <br />
                  Für die beste Erfahrung für Ihre Nutzer*innen sollten Antworttexte einfach formuliert und in ihrer
                  Länge nicht zu lang sein.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} className={classes.editAnswerTipContainer}>
                <Typography variant='h3'>3.</Typography>
                <Typography>
                  <strong>Textformatierung</strong>
                  <br />
                  Es gibt zwei Wege Texte zu formatieren: Sie können einen bestehenden Text auswählen und über das
                  Pop-Up Menü formatieren oder eine neue Zeile mit &quot;/&quot; beginnen und eine Formatierung
                  auswählen.
                </Typography>
              </Grid>
            </Grid> */}
          </BaseCard>
        )}
        {tab === 'turnus' && (
          <BaseCard width='100%' height='100%'>
            <StepTurnus
              turnus={notification.type}
              startDate={notification.startDate}
              endDate={notification.endDate}
              times={notification.times}
              days={notification.series?.days}
              setStartDateCallback={onSetStartDate}
              setEndDateCallback={onSetEndDate}
              setTimesCallback={onSetTimes}
              setTurnusCallback={onSetTurnus}
              setDaysCallback={onSetDays}
            />
            {overlappingNotification && (
              <div className={classes.overlappingHint}>
                <OverlappingNotificationHint type='edit' overlappingNotification={overlappingNotification} />
              </div>
            )}
          </BaseCard>
        )}
      </div>
    </div>
  )
}
