import React from 'react'
import deLocale from 'date-fns/locale/de'
import enLocale from 'date-fns/locale/en-US'
import { LocalizationProvider, DatePicker as MUIDatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
// import AdapterDateFns from '@material-ui/lab/AdapterDateFns'
import { TextField } from '@mui/material'

const localeMap = {
  en: enLocale,
  de: deLocale,
}

const maskMap = {
  en: '__/__/____',
  de: '__.__.____',
}

type DatePickerProps = {
  label?: string
  placeholder?: string
  locale?: 'de' | 'en'
  value?: Date | null
  onSelect: (newValue: Date | null) => void
  disableHighlightToday?: boolean
  disableFuture?: boolean
  minDate?: Date
  disabled?: boolean
  margin?: string
  fullWidth?: boolean
}

export default function DatePicker({
  label = '',
  placeholder,
  locale = 'de',
  value,
  onSelect,
  disableHighlightToday,
  disableFuture,
  minDate,
  margin,
  fullWidth,
  disabled = false,
}: DatePickerProps): React.ReactElement {
  /**
   * Intercepts date change and determines if date is valid.
   * If it's valid, calls onChange from properties.
   * @param newDate
   */
  function onChangeIntercept(newDate: Date | null): void {
    if (newDate === null || (newDate instanceof Date && !isNaN(newDate.getTime()))) {
      onSelect(newDate)
    }
  }

  return (
    // TODO readd Textfield
    // <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[locale]}>
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeMap[locale]}>
      <div style={{ margin }}>
        <MUIDatePicker
          // mask={maskMap[locale]}
          label={label}
          value={value}
          onChange={onChangeIntercept}
          slotProps={{ textField: { placeholder: 'TT.MM.JJJJ', fullWidth } }}
          disabled={disabled}
          // toolbarPlaceholder={''}
          disableHighlightToday={disableHighlightToday}
          minDate={minDate}
          maxDate={disableFuture ? new Date() : undefined}
        />
      </div>
    </LocalizationProvider>
  )
}
