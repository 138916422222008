import React from 'react'
import Dialog from '../../../../../../components/Dialogs/Dialog'
import EditAnswerUtterances from './EditAnswerUtterances'

import { Answer } from '../../../../../../classes/Knowledge'

type EditAnswerUtterancesDialogProps = {
  open: boolean
  answer: Answer
  onUtterancesChangeCallback: (newUtterances: string[]) => void
  onClose: () => void
}

export default function EditAnswerUtterancesDialog({
  open,
  answer,
  onUtterancesChangeCallback,
  onClose,
}: EditAnswerUtterancesDialogProps): React.ReactElement {
  return (
    <Dialog
      id='create-answer-dialog'
      size='medium-large'
      open={open}
      closable
      onClose={onClose}
      title='Fragen'
      secondaryActionText={'Schließen'}
      onSecondaryActionClick={onClose}
    >
      <EditAnswerUtterances
        answer={answer}
        onUtterancesChangeCallback={onUtterancesChangeCallback}
        allowDisplayAsDialog={false}
        showTitle={false}
        displayType='list'
        generateUtterancesOption
      />
    </Dialog>
  )
}
