import React, { ReactElement } from 'react'
import { makeStyles } from 'tss-react/mui'

import { Button } from '@mui/material/'

import { PopupContentBlock } from '../popupBuildingBlocks/PopupContentBlock'

const useStyles = makeStyles()((theme) => ({
  button: {
    width: '100%',
    textAlign: 'center',
  },
}))

type PopupButtonProps = {
  title: string
  buttonText: string
  onClick: (event: object) => void
  disabled?: boolean
}

export const PopupButton = ({ title, onClick, buttonText, disabled }: PopupButtonProps): ReactElement => {
  const { classes } = useStyles()

  const Content = (
    <div className={classes.button}>
      <Button onClick={onClick} disabled={disabled} variant='outlined'>
        {buttonText}
      </Button>
    </div>
  )

  return <PopupContentBlock title={title} content={Content} />
}
