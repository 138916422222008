import { useInterval } from '../useInterval/useInterval'
import React, { createContext, useContext, useReducer, useEffect, useCallback } from 'react'
// Contexts
import { useBotContext } from './bot-context'
// API
import {
  getDictionary as getDictionaryApi,
  addLanguage as addLanguageApi,
  deleteLanguage as deleteLanguageApi,
  setAndDeleteDictionaryEntries as setAndDeleteDictionaryEntriesApi,
  reloadBot as reloadBotApi,
  activateLanguage,
  deactivateLanguage,
} from '../../api/StudioBackend'
import { Dictionary, DictionaryEntry, DictionaryType } from '../../@types/Knowledge/Dictionaries/types'
import { cloneDeep, entries, isEqual } from 'lodash'
import { v4 as uuid } from 'uuid'
import { AddLanguageTermsResponse, DeleteLanguageTermsResponse } from '../../@types/Translations/types'
import { BotEnvironment, BotInfos } from '../../@types/BotInformation/types'

// ----- TYPE DEFINITIONS -----

type SnackbarNotification = {
  type: 'success' | 'error'
  message: string
} | null

type LoadingState =
  | 'loading'
  | 'saving'
  | 'deleting'
  | 'addingLanguage'
  | 'publishing'
  | 'activating'
  | 'deactivating'
  | undefined

// Maps Actions to Payloads - Standard does not need customization
type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key
      }
    : {
        type: Key
        payload: M[Key]
      }
}

// Possible Actions
enum Types {
  SET_LOADING = 'SET_LOADING',
  SET_DICTIONARY = 'SET_DICTIONARY',
  SET_ORIG_DICTIONARY = 'SET_ORIG_DICTIONARY',
  SET_NOTIFICATION = 'SET_NOTIFICAITON',
  SET_HAS_LANGUAGE_CHANGE = 'SET_HAS_LANGUAGE_CHANGE',
  SET_PUBLISHED_LANGUAGES = 'SET_PUBLISHED_LANGUAGES',
  SET_LANGUAGES = 'SET_LANGUAGES',
  RESET = 'RESET',
}

// Payloads for each action
type Payload = {
  [Types.SET_LOADING]: {
    loading: LoadingState
  }
  [Types.SET_DICTIONARY]: {
    dictionary: Dictionary
  }
  [Types.SET_ORIG_DICTIONARY]: {
    origDictionary: Dictionary
  }
  [Types.SET_NOTIFICATION]: {
    notification: SnackbarNotification | undefined
  }
  [Types.SET_HAS_LANGUAGE_CHANGE]: {
    hasLanguageChange: boolean
  }
  [Types.SET_PUBLISHED_LANGUAGES]: {
    publishedLanguages: string[]
  }
  [Types.SET_LANGUAGES]: {
    languages: string[]
  }
  [Types.RESET]: {}
}

type Action = ActionMap<Payload>[keyof ActionMap<Payload>]
type State = {
  loading: LoadingState
  origDictionary?: Dictionary // dictionary as fetched from the api - used to find local changes
  dictionary: Dictionary
  notification?: SnackbarNotification
  hasLanguageChange?: boolean // if true, language(s) have changes that require active publishing (and bot reload). This is the case when adding or deleting a language.
  publishedLanguages: string[]
  languages: string[]
}

type Dispatch = (action: Action) => void
type TermTranslationsProviderProps = { children: React.ReactNode }

/**
 * Checks if languages have changed
 * @param origDictionary
 * @param dictionary
 */
function hasLanguageChanges(origDictionary: Dictionary, dictionary: Dictionary): boolean {
  // we only look at first entry to speed things up as all entries should have the same language values!
  const origEntries = Object.values(origDictionary)
  const entries = Object.values(dictionary)

  if (origEntries.length === 0 || entries.length === 0) return true // if unclear, we want to return true to enable publishing (even if there are no changes)

  // check languages in dictionary & languages flagged for deletion ("Deleted langauges")
  let origLanguages: string[] = []
  let origDeletedLanguages: string[] = []
  if (origEntries.length > 0) {
    origLanguages = Object.keys(origEntries[0].entries)
    origDeletedLanguages = origEntries[0].languagesFlaggedForDeletion ?? []
  }
  let languages: string[] = []
  let deletedLanguages: string[] = []
  if (entries.length > 0) {
    languages = Object.keys(entries[0].entries)
    deletedLanguages = entries[0].languagesFlaggedForDeletion ?? []
  }

  return (
    !isEqual(new Set(origLanguages), new Set(languages)) ||
    !isEqual(new Set(deletedLanguages), new Set(origDeletedLanguages))
  )
}

/**
 * ----- CONTEXT -----
 * Context for managing (technical) terms translations.
 *
 * To access this context:
 * import { useTermTranslationsContext } from '<path>/hooks/contexts/term-translations-context.tsx'
 *
 * // in a function
 * const { dictionary } = useTermTranslationsContext()
 *
 */

const TermsTranslationContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined)

function termTranslationsReducer(state: State, action: Action): State {
  switch (action.type) {
    case Types.SET_LOADING: {
      return {
        ...state,
        loading: action.payload.loading,
      }
    }
    case Types.SET_DICTIONARY: {
      // make sure to spread that state just in case!

      return {
        ...state,
        dictionary: cloneDeep(action.payload.dictionary),
      }
    }
    case Types.SET_ORIG_DICTIONARY: {
      return {
        ...state,
        origDictionary: cloneDeep(action.payload.origDictionary),
      }
    }
    case Types.SET_HAS_LANGUAGE_CHANGE: {
      return {
        ...state,
        hasLanguageChange: action.payload.hasLanguageChange,
      }
    }
    case Types.SET_NOTIFICATION: {
      return {
        ...state,
        notification: action.payload.notification,
      }
    }
    case Types.SET_PUBLISHED_LANGUAGES: {
      return {
        ...state,
        publishedLanguages: action.payload.publishedLanguages,
      }
    }
    case Types.SET_LANGUAGES: {
      return {
        ...state,
        languages: action.payload.languages,
      }
    }
    case Types.RESET: {
      return {
        loading: undefined,
        dictionary: {},
        publishedLanguages: [],
        languages: [],
      }
    }
    default: {
      // helps us avoid typos!
      throw new Error(`[Terms-Translations-Reducer] Unhandled action type: ${(action as any).type}`)
    }
  }
}

/**
 * Context provider for providing StudioContext
 * @returns
 */
function TermTranslationsContextProvider({ children }: TermTranslationsProviderProps): JSX.Element {
  const { bot, getLanguages, getPublishedLanguages } = useBotContext()
  const [state, dispatch] = useReducer(termTranslationsReducer, {
    dictionary: {},
    loading: 'loading',
    publishedLanguages: getPublishedLanguages(),
    languages: getLanguages(),
  })

  useEffect(function () {
    // cleanup: reset state on unmount of provider
    return (): void => {
      dispatch({ type: Types.RESET, payload: {} })
    }
  }, [])

  useEffect(
    function () {
      if (bot === null) return

      loadDictionary(bot, dispatch)
    },
    [bot],
  )

  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  // const value = useMemo(() => [state, dispatch], [state])
  const value = { state, dispatch }
  return <TermsTranslationContext.Provider value={value}>{children}</TermsTranslationContext.Provider>
}

/**
 * Hook for accessing and manipulating the TermTranslationsContext state
 * @returns
 *
 * To access this context:
 * import { useTermTranslationsContext } from '<path>/hooks/contexts/term-translations-context.tsx'
 *
 * // in a function
 * const { dictionary, lockedBy} = useLockingContext()
 *
 */
function useTermTranslationsContext(): {
  loading: LoadingState
  hasChanges: boolean
  hasLanguageChange?: boolean // true if languages have changed and require publishing
  termTranslations?: Dictionary // current dictionary
  addNewEntry: (text: string) => void // local: adds a new dictionary entry
  setEntryForLanguage: (termId: string, language: string, text: string) => void // local: updates a entry for a specific language
  setEntryForLanguageBatch: (values: { termId: string; language: string; text: string }[]) => void // local: updates multiple entries
  deleteEntry: (termId: string) => void // local: deletes entry
  // addLanguage: (lang: string) => Promise<void> // api: adds new language to assistant + adds language to dictionary
  // deleteLanguage: (lang: string) => Promise<void> // api: completely deletes language from assistant
  saveTermTranslations: () => Promise<void> // api: saves local dictionary via api and persists all changes
  // publishLanguagesAndTranslations: (deployEnvironment: BotEnvironment) => Promise<void> // api: publishes languages and translations by calling the /reloadBot endpoint
  notification?: SnackbarNotification
  setNotification: (notification: SnackbarNotification) => void // return way to easily set a notification without having to implement it again in the component
  discardChanges: () => void // discards changes and resets dictionary to origDictionary object
  changeLanguageActiveState: (langCode: string) => void // changes the published state of a langauge
  publishedLanguages: string[]
  languages: string[]
} {
  const { bot, setBot, getLanguages, getPublishedLanguages, botHasUnpublishedTranslations } = useBotContext()
  const LANGUAGES = getLanguages()
  const PRIMARY_LANGUAGE = bot?.primaryLanguage
  const DICTIONARY_ID = bot?.dictionaries?.technicalTerms
  const context = useContext(TermsTranslationContext)
  if (context === undefined) {
    throw new Error(
      '[useTermTranslationsContext] useTermTranslationsContext must be used within a TermTranslationsContextProvider',
    )
  }

  // splitting it up, allows us to add additional api calls
  const { state, dispatch } = context
  // State
  const { loading, dictionary, origDictionary, notification, hasLanguageChange, publishedLanguages, languages } = state

  // compare current state to original notifications as they were loaded from the API
  const hasChanges = origDictionary ? !isEqual(dictionary, origDictionary) : false

  // ---- API ----
  // To not let anything work on the context state directly and to have an easy to use API

  // adds a new entry
  const addNewEntry = useCallback(
    (textPrimaryLanguage: string): void => {
      if (!LANGUAGES || !DICTIONARY_ID || !PRIMARY_LANGUAGE) return

      const termId = uuid()
      const newEntry: DictionaryEntry = {
        dictionaryId: DICTIONARY_ID,
        dictionaryTermId: termId,
        term: textPrimaryLanguage,
        entries: LANGUAGES.reduce((obj, key) => ((obj[key] = ''), obj), {}), // init all languages with empty string
        type: DictionaryType.TECHNICAL_TERM,
        primaryLanguage: PRIMARY_LANGUAGE,
      }
      newEntry.entries[PRIMARY_LANGUAGE] = textPrimaryLanguage // set primary language

      dictionary[termId] = newEntry
      dispatch({ type: Types.SET_DICTIONARY, payload: { dictionary } })
    },
    [dictionary, LANGUAGES, DICTIONARY_ID],
  )

  // sets / updates entry for specific language
  const setEntryForLanguage = useCallback(
    (termId: string, language: string, text: string): void => {
      if (!dictionary[termId]) return

      dictionary[termId].entries[language] = text
      if (language === PRIMARY_LANGUAGE) dictionary[termId].term = text

      dispatch({ type: Types.SET_DICTIONARY, payload: { dictionary } })
    },
    [dictionary],
  )

  // sets / updates entry for specific language (in batch -> can set multiple at once)
  const setEntryForLanguageBatch = useCallback(
    (values: { termId: string; language: string; text: string }[]): void => {
      for (const value of values) {
        if (!dictionary[value.termId]) continue
        dictionary[value.termId].entries[value.language] = value.text
        if (value.language === PRIMARY_LANGUAGE) dictionary[value.termId].term = value.text
      }
      dispatch({ type: Types.SET_DICTIONARY, payload: { dictionary } })
    },
    [dictionary],
  )

  // deletes entry from the dictionary
  const deleteEntry = useCallback(
    (termId: string): void => {
      delete dictionary[termId]

      dispatch({ type: Types.SET_DICTIONARY, payload: { dictionary } })
    },
    [dictionary],
  )

  // adds new language to the assistant
  // const addLanguage = useCallback(
  //   async (language: string): Promise<void> => {
  //     if (!bot) return

  //     dispatch({ type: Types.SET_LOADING, payload: { loading: 'addingLanguage' } })

  //     const response = (await addLanguageApi(bot.id, language, 'termTranslations')) as AddLanguageTermsResponse

  //     if (response !== null) {
  //       const { dictionary: newDictionary, botInfos: newBotInfos } = response
  //       const hasLanguageChange = hasLanguageChanges(dictionary, newDictionary)

  //       setBot(newBotInfos) // we need to set botinfos globally as they have changed!
  //       dispatch({ type: Types.SET_HAS_LANGUAGE_CHANGE, payload: { hasLanguageChange } })
  //       dispatch({ type: Types.SET_DICTIONARY, payload: { dictionary: newDictionary } })
  //       dispatch({ type: Types.SET_ORIG_DICTIONARY, payload: { origDictionary: newDictionary } })
  //       dispatch({
  //         type: Types.SET_NOTIFICATION,
  //         payload: { notification: { type: 'success', message: 'Sprache erfolgreich hinzufügt.' } },
  //       })
  //     } else {
  //       dispatch({
  //         type: Types.SET_NOTIFICATION,
  //         payload: { notification: { type: 'error', message: 'Fehler beim Hinzufügen der Sprache.' } },
  //       })
  //     }
  //     dispatch({ type: Types.SET_LOADING, payload: { loading: undefined } })
  //   },
  //   [bot, setBot, dictionary],
  // )

  // delete language from assistant
  // const deleteLanguage = useCallback(
  //   async (language: string): Promise<void> => {
  //     if (!bot) return
  //     dispatch({ type: Types.SET_LOADING, payload: { loading: 'deleting' } })

  //     const response = (await deleteLanguageApi(bot.id, language, 'termTranslations')) as DeleteLanguageTermsResponse
  //     if (response !== null) {
  //       const { dictionary: newDictionary, botInfos: newBotInfos } = response
  //       const hasLanguageChange = hasLanguageChanges(dictionary, newDictionary)

  //       setBot(newBotInfos) // we need to set botinfos globally as they have changed!
  //       dispatch({ type: Types.SET_HAS_LANGUAGE_CHANGE, payload: { hasLanguageChange } })
  //       dispatch({ type: Types.SET_DICTIONARY, payload: { dictionary: newDictionary } })
  //       dispatch({ type: Types.SET_ORIG_DICTIONARY, payload: { origDictionary: newDictionary } })
  //       dispatch({
  //         type: Types.SET_NOTIFICATION,
  //         payload: { notification: { type: 'success', message: 'Sprache erfolgreich gelöscht.' } },
  //       })
  //     } else {
  //       dispatch({
  //         type: Types.SET_NOTIFICATION,
  //         payload: { notification: { type: 'error', message: 'Fehler beim Löschen der Sprache.' } },
  //       })
  //     }
  //     dispatch({ type: Types.SET_LOADING, payload: { loading: undefined } })
  //   },
  //   [bot, dictionary],
  // )

  // publish languages and translations
  // const publishLanguagesAndTranslations = useCallback(
  //   async (deployEnvironment: BotEnvironment): Promise<void> => {
  //     if (!bot) return

  //     dispatch({ type: Types.SET_LOADING, payload: { loading: 'publishing' } })

  //     const response = await reloadBotApi(bot.id, deployEnvironment, true, false)
  //     if (response === null) {
  //       dispatch({
  //         type: Types.SET_NOTIFICATION,
  //         payload: {
  //           notification: { type: 'error', message: `Veröffentlichen in "${deployEnvironment}" fehlgeschlagen.` },
  //         },
  //       })
  //       console.error('Fehler beim Veröffentlichen der Übersetzungen.')
  //     } else {
  //       dispatch({
  //         type: Types.SET_HAS_LANGUAGE_CHANGE,
  //         payload: { hasLanguageChange: false },
  //       })
  //       dispatch({
  //         type: Types.SET_NOTIFICATION,
  //         payload: { notification: { type: 'success', message: 'Erfolgreich veröffentlicht.' } },
  //       })
  //     }
  //     dispatch({ type: Types.SET_LOADING, payload: { loading: undefined } })
  //   },
  //   [bot],
  // )

  // saves local dictionary via api call
  const saveTermTranslations = useCallback(async (): Promise<void> => {
    if (!origDictionary || !dictionary || !bot || !DICTIONARY_ID) return

    dispatch({ type: Types.SET_LOADING, payload: { loading: 'saving' } })

    const dictionaryTermIds = Object.keys(dictionary)
    const origDictionaryTermIds = Object.keys(origDictionary)

    // find new / changed entries and find deleted ones
    const entriesToSave: DictionaryEntry[] = []
    for (const entry of Object.values(dictionary)) {
      if (
        !origDictionaryTermIds.includes(entry.dictionaryTermId) ||
        !isEqual(entry, origDictionary[entry.dictionaryTermId])
      ) {
        // new or has changed
        entriesToSave.push(entry)
      }
    }

    // find deleted entries
    const entryIdsToDelete: string[] = []
    for (const termId of origDictionaryTermIds) {
      if (!dictionaryTermIds.includes(termId)) {
        // deleted
        entryIdsToDelete.push(termId)
      }
    }

    const response = await setAndDeleteDictionaryEntriesApi(bot.id, DICTIONARY_ID, entriesToSave, entryIdsToDelete)
    if (response === null) {
      // something went wrong
      dispatch({
        type: Types.SET_NOTIFICATION,
        payload: { notification: { type: 'error', message: 'Speichern fehlgeschlagen.' } },
      })
    } else {
      dispatch({ type: Types.SET_ORIG_DICTIONARY, payload: { origDictionary: dictionary } })
      dispatch({
        type: Types.SET_NOTIFICATION,
        payload: { notification: { type: 'success', message: 'Speichern erfolgreich.' } },
      })
    }
    dispatch({ type: Types.SET_LOADING, payload: { loading: undefined } })
  }, [bot, dictionary, origDictionary])

  // sets the notification object and triggers notification display
  const setNotification = useCallback((notification: SnackbarNotification): void => {
    dispatch({
      type: Types.SET_NOTIFICATION,
      payload: {
        notification,
      },
    })
  }, [])

  const changeLanguageActiveState = useCallback(
    async (langCode: string): Promise<void> => {
      if (!bot) return

      // activate
      if (!publishedLanguages.includes(langCode)) {
        dispatch({ type: Types.SET_LOADING, payload: { loading: 'activating' } })

        const updatedBotInfos = await activateLanguage(bot.id, langCode)
        if (updatedBotInfos === null) {
          dispatch({
            type: Types.SET_NOTIFICATION,
            payload: { notification: { type: 'error', message: 'Fehler beim Aktivieren der Sprache.' } },
          })
          dispatch({ type: Types.SET_LOADING, payload: { loading: undefined } })
          console.error('Error activating language')
          return
        } else {
          setBot(updatedBotInfos)
          dispatch({ type: Types.SET_HAS_LANGUAGE_CHANGE, payload: { hasLanguageChange: true } })
          dispatch({ type: Types.SET_LOADING, payload: { loading: undefined } })
        }
      } else {
        // deactivate
        dispatch({ type: Types.SET_LOADING, payload: { loading: 'deactivating' } })

        const updatedBotInfos = await deactivateLanguage(bot.id, langCode)
        if (updatedBotInfos === null) {
          dispatch({
            type: Types.SET_NOTIFICATION,
            payload: { notification: { type: 'error', message: 'Fehler beim Deaktivieren der Sprache.' } },
          })
          dispatch({ type: Types.SET_LOADING, payload: { loading: undefined } })
          console.error('Error activating language')
          return
        } else {
          setBot(updatedBotInfos)
          dispatch({ type: Types.SET_HAS_LANGUAGE_CHANGE, payload: { hasLanguageChange: true } })
          dispatch({ type: Types.SET_LOADING, payload: { loading: undefined } })
        }
      }
    },
    [bot, setBot, publishedLanguages],
  )

  const discardChanges = useCallback((): void => {
    if (!origDictionary) return

    dispatch({ type: Types.SET_DICTIONARY, payload: { dictionary: origDictionary } })
  }, [dictionary, origDictionary])

  useEffect(
    function () {
      // hide notification after 3 seconds
      if (notification) {
        setTimeout(function () {
          dispatch({ type: Types.SET_NOTIFICATION, payload: { notification: undefined } })
        }, 3000)
      }
    },
    [notification],
  )

  useEffect(() => {
    if (bot) {
      dispatch({ type: Types.SET_PUBLISHED_LANGUAGES, payload: { publishedLanguages: getPublishedLanguages() } })
      dispatch({ type: Types.SET_LANGUAGES, payload: { languages: getLanguages() } })
    }
  }, [bot])

  // Controll what is returned
  return {
    loading,
    termTranslations: dictionary,
    // addLanguage,
    // deleteLanguage,
    deleteEntry,
    addNewEntry,
    setEntryForLanguage,
    setEntryForLanguageBatch,
    saveTermTranslations,
    // publishLanguagesAndTranslations,
    hasChanges,
    hasLanguageChange,
    notification,
    setNotification,
    discardChanges,
    changeLanguageActiveState,
    publishedLanguages,
    languages,
  }
}

/**
 * Fetches dictionary from API.
 * NOTE: temporary workaround to have this function here so that we can export it.
 * Should normally just be used within the context, but while our LockingContext cannot handle nested locks / multiple locks at once, we need to
 * fetch the terms in the language overview screen in Studio to display the number of translated entries.
 */
async function loadDictionary(bot: BotInfos, dispatch?: (value: Action) => void): Promise<Dictionary | null> {
  if (!bot) return null
  console.info('term-translations-context: Fetching dictionary.')
  if (dispatch) dispatch({ type: Types.SET_LOADING, payload: { loading: 'loading' } })

  const dictionaryId = bot.dictionaries['technicalTerms']

  try {
    const dictionary = await getDictionaryApi(bot.id, dictionaryId)
    if (dictionary) {
      // success, set dictionary
      console.info('term-translations-context: Got dictionary!')
      if (dispatch) {
        dispatch({ type: Types.SET_ORIG_DICTIONARY, payload: { origDictionary: dictionary } })
        dispatch({ type: Types.SET_DICTIONARY, payload: { dictionary } })
        dispatch({ type: Types.SET_LOADING, payload: { loading: undefined } })
      }
      return dictionary
    } else {
      // not successful
      throw new Error()
    }
  } catch (err) {
    // TODO better error handling. Should we route back to translations overview if we cannot load dictioanry translations?
    if (dispatch) dispatch({ type: Types.SET_LOADING, payload: { loading: undefined } })
    console.error('[term-translations-context] Could not load dictionary.')
    return null
  }
}

export { TermTranslationsContextProvider, useTermTranslationsContext, loadDictionary }
