import React, { useEffect, useState } from 'react'
import BaseCard from 'components/Cards/BaseCard'
import ContentPage, { ContentPageHeader } from 'components/Page/ContentPage'
import SelectDropdown, { ActionMeta, Option } from 'components/Dropdown/SelectDropdown'
import {
  LANGUAGE_DICTIONARY_DE,
  ROUTE_BOTID_ANALYTICS,
  ROUTE_BOTID_DOCUMENTS,
  ROUTE_BOTID_DOCUMENTS_PDFINGEST,
  ROUTE_BOTS,
} from 'utils/constants'
import {
  getKnowledgeDbs as getKnowledgeDbsApi,
  getBudgets as getBudgetsApi,
  createBot as createBotApi,
} from 'api/StudioBackend'
import { Textfield } from 'components/TextInput/Textfield'
import { makeStyles } from 'tss-react/mui'
import { Grid, IconButton, Typography, useTheme } from '@mui/material'
import { Button } from 'components/Buttons'
import CircularLoading from 'components/Loading/CircularLoading'
import { useBotContext } from 'hooks/contexts/bot-context'
import CreateKnowledgeDbDialog from './CreateKnowledgeDbDialog'

import svgNoFileMenu from 'assets/img/botCreation/noFiles.svg'
import svgAddFileMenu from 'assets/img/botCreation/addFile.svg'
import CustomizedTooltip from 'components/Tooltips/CustomContentTooltip'
import { Add } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles()((theme) => ({
  textfield: { width: '100%' },
  menuContainer: {
    display: 'flex',
    marginBottom: theme.spacing(4),
    alignItems: 'center',
    marginTop: theme.spacing(6),
    justifyContent: 'space-evenly',
  },
  card: {
    alignItems: 'center',
    boxShadow: '0 14px 26px -12px rgb(0 0 0 / 15%), 0 4px 23px 0px rgb(0 0 0 / 6%), 0 8px 10px -5px rgb(0 0 0 / 10%)', // '0px 2px 10px 2px rgba(0,0,0, 0.25)'
    // transition: 'box-shadow 0.3s',
    // '&:hover': {
    //   boxShadow:
    //     '0 14px 26px -12px rgb(0 0 0 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)', // '0px 2px 10px 2px rgba(0,0,0, 0.25)'
    // },
  },
  knowledgeDbContainer: {
    width: '100%',
    display: 'flex',
  },
  iconButton: {
    marginLeft: 'auto',
    marginBottom: 'auto',
    marginTop: 'auto',
    color: theme.palette.primary.main,
  },
  icon: {
    fontSize: '1.5rem',
  },
  input: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
  menuImage1: {
    marginBottom: '50px',
    maxHeight: '200px',
  },
  createButtonContainer: {
    width: '100%',
    display: 'flex',
    marginTop: theme.spacing(3),
  },
  createButton: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}))

enum IngestChoice {
  PDF = 'pdf',
  SKIP = 'skip',
}

export default function BotCreation(): React.ReactElement {
  const { classes } = useStyles()
  const theme = useTheme()
  const { bot, setBot } = useBotContext()
  const navigate = useNavigate()
  const [showCreateKnowledgeDbDialog, setShowCreaateKnowledgeDbDialog] = useState<boolean>()
  const [creating, setCreating] = useState<'creating' | 'creationSuccess'>()
  const [loadingBudgets, setLoadingBudgets] = useState<'loading'>()
  const [loadingKnowledgeDbs, setLoadingKnowledgeDbs] = useState<'loading'>()
  const [existingKnowledgeDbs, setExistingKnowledgeDbs] = useState<Option[]>([])
  const [selectedKnowledgeDb, setSelectedKnowledgeDb] = useState<Option>()
  const [budgets, setBudgets] = useState<Option[]>([])
  const [selectedBudget, setSelectedBudget] = useState<Option>()
  const [languages, setLanguages] = useState<Option[]>([])
  const [selectedLanguage, setSelectedLanguage] = useState<Option>()

  // values of new knowledge db
  const [name, setName] = useState<string>()
  const [description, setDescription] = useState<string>()
  const [primaryLanguage, setPrimaryLanguage] = useState<keyof typeof LANGUAGE_DICTIONARY_DE>('de')
  const [knowledgeDbId, setKnowledgeDbId] = useState<string>()
  const [knowledgeDbName, setKnowledgeDbName] = useState<string>()
  const [budgetId, setBudgetId] = useState<string>()

  /**
   * Fetches all budgets of customer and populates budget dropdown.
   */
  async function getBudgets(): Promise<void> {
    setLoadingBudgets('loading')
    const result = await getBudgetsApi()
    if (result !== null) {
      const options: Option[] = []
      for (const budget of result.budgets) {
        // prepare dropdown options
        options.push({
          label: budget.budgetName,
          value: budget.budgetId,
        })
      }
      setBudgets(options)
    }
    setLoadingBudgets(undefined)
  }

  /**
   * Fetches all knowledge dbs of customer and populates knowledge db dropdown.
   */
  async function getKnowledgeDbs(): Promise<void> {
    setLoadingKnowledgeDbs('loading')
    const result = await getKnowledgeDbsApi()
    if (result !== null) {
      const options: Option[] = []
      for (const db of result.knowledgeDbs) {
        // prepare dropdown options
        options.push({
          label: db.knowledgeDbName,
          sublabel: db.dbType === 'classic' ? 'Klassisch' : 'Generativ',
          value: db.knowledgeDbId,
        })
      }
      setExistingKnowledgeDbs(options)
    }
    setLoadingKnowledgeDbs(undefined)
  }

  function prepareLanguageDropdown(): void {
    const options: Option[] = []
    for (const [code, lang] of Object.entries(LANGUAGE_DICTIONARY_DE)) {
      options.push({
        label: lang,
        value: code,
      })
    }
    setLanguages(options)
  }

  function onNameChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setName(event.target.value)
  }

  function onDescriptionChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setDescription(event.target.value)
  }

  function onLanguageSelection(option: Option, action: ActionMeta<Option>): void {
    setSelectedLanguage(option)
    setPrimaryLanguage(option.value)
  }

  function onKnowledgeDbSelection(option: Option, action: ActionMeta<Option>): void {
    if (option === null) {
      // selection has been cleared
      setSelectedKnowledgeDb(undefined)
      setKnowledgeDbId(undefined)
      setKnowledgeDbName(undefined)
    } else {
      setSelectedKnowledgeDb(option)
      setKnowledgeDbId(option.value)
      setKnowledgeDbName(option.label)
    }
  }

  function onBudgetSelection(option: Option, action: ActionMeta<Option>): void {
    setSelectedBudget(option)
    setBudgetId(option.value)
  }

  function onKnowledgeDbCreation(knowledgeDbName: string, knowledgeDbId: string): void {
    const newOption = { label: knowledgeDbName, value: knowledgeDbId }
    const knowledgeDbs = [newOption, ...existingKnowledgeDbs]
    setExistingKnowledgeDbs(knowledgeDbs)
    setSelectedKnowledgeDb(newOption)
    setKnowledgeDbId(newOption.value)
    setShowCreaateKnowledgeDbDialog(undefined)
  }

  function onKnowledgeDbCreationClose(): void {
    setShowCreaateKnowledgeDbDialog(undefined)
  }

  async function onCreateAssistent(): Promise<void> {
    if (!name || !budgetId || !primaryLanguage) return
    setCreating('creating')
    const result = await createBotApi({
      name,
      budgetId,
      description,
      knowledgeDbId,
      knowledgeDbName,
      primaryLanguage,
    })
    if (result !== null) {
      const botInfos = result
      setBot(botInfos)
    }
    setCreating('creationSuccess')
  }

  /**
   * Handles user selection in ingest decision menu.
   * @param choice
   */
  // function onChoiceAddPDFOrNot(choice: IngestChoice): void {
  //   if (!bot || bot === null) {
  //     console.error('[Creation] Bot is null.')
  //     return
  //   }
  //   switch (choice) {
  //     case IngestChoice.PDF:
  //       // go to PDF ingest
  //       navigate(ROUTE_BOTS + `/${bot.id}` + ROUTE_BOTID_DOCUMENTS + ROUTE_BOTID_DOCUMENTS_PDFINGEST)
  //       break
  //     case IngestChoice.SKIP:
  //       // don't ingest, go straight to analytics
  //       navigate(`${ROUTE_BOTS}/${bot.id}${ROUTE_BOTID_ANALYTICS}`)
  //       break
  //   }
  // }

  useEffect(
    function redirectAfterCreation() {
      if (creating === 'creationSuccess' && bot) {
        navigate(`${ROUTE_BOTS}/${bot.id}${ROUTE_BOTID_ANALYTICS}`)
      }
    },
    [creating],
  )

  useEffect(function () {
    getKnowledgeDbs()
    getBudgets()
    prepareLanguageDropdown()
  }, [])

  return (
    <ContentPage>
      <ContentPageHeader title='Neuen Assistenten erstellen' previousUrl={ROUTE_BOTS} />
      <BaseCard width='100%' height='auto' minHeight='80vh'>
        {loadingBudgets === 'loading' || loadingKnowledgeDbs === 'loading' ? (
          // Initial loading
          <CircularLoading text='Einen Moment bitte...' size='large' />
        ) : creating === 'creating' ? (
          // creating
          <CircularLoading text='Assistent wird erstellt...' size='large' />
        ) : (
          // ) : creating === 'creationSuccess' ? (
          //   // Assistent has been created -> choice between adding PDF and no PDF
          //   <div>
          //     <Typography>Möchten Sie dem Assistenten ein PDF Formular hinzufügen?</Typography>
          //     <div className={classes.menuContainer}>
          //       <BaseCard
          //         footerText='PDF hinzufügen'
          //         footerColor={theme.palette.primary.main}
          //         onClick={(): void => onChoiceAddPDFOrNot(IngestChoice.PDF)}
          //         shadowWidth={20}
          //         shadowBlur={20}
          //         className={classes.card}
          //       >
          //         <img className={classes.menuImage1} src={svgAddFileMenu} alt={'Datei hinzufügen'} />
          //       </BaseCard>
          //       <BaseCard
          //         footerText='Kein PDF hinzufügen'
          //         footerColor={theme.palette.primary.main}
          //         onClick={(): void => onChoiceAddPDFOrNot(IngestChoice.SKIP)}
          //         shadowWidth={20}
          //         shadowBlur={20}
          //         className={classes.card}
          //       >
          //         <img className={classes.menuImage1} src={svgNoFileMenu} alt={'Keine Datei hinzufügen'} />
          //       </BaseCard>
          //     </div>
          //   </div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {/* Name */}
              <Typography>Name des Assistenten eingeben</Typography>
              <Textfield
                onChange={onNameChange}
                value={name}
                placeholder='Name des Assistenten'
                InputProps={{ autoComplete: 'off' }}
                label='Name des Assistenten'
                className={classes.input}
              />
            </Grid>
            <Grid item xs={12}>
              {/* Description */}
              <Typography>Beschreibung für den Assistenten eingeben</Typography>
              <Textfield
                onChange={onDescriptionChange}
                value={description}
                placeholder='Beschreibung des Assistenten'
                InputProps={{ autoComplete: 'off' }}
                label='Beschreibung des Assistenten'
                className={classes.input}
                multiline
                rowsMax={3}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {/* Primary Language */}
              <Typography>Primäre Sprache des Assistenten festlegen</Typography>
              <SelectDropdown
                className={classes.input}
                width='100%'
                options={languages}
                selected={selectedLanguage}
                onChange={onLanguageSelection}
                isSearchable
                placeholder='Sprache des Assistenten'
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {/* Knowledge db */}
              <Typography>Wissensdatenbank für den Assistenten auswählen oder erstellen</Typography>
              <div className={classes.knowledgeDbContainer}>
                <SelectDropdown
                  width='100%'
                  className={classes.input}
                  options={existingKnowledgeDbs}
                  selected={selectedKnowledgeDb}
                  onChange={onKnowledgeDbSelection}
                  isSearchable
                  isClearable
                  placeholder='Wissensdatenbank des Assistenten'
                />
                <div style={{ marginTop: theme.spacing(1), display: 'flex' }}>
                  <CustomizedTooltip
                    content={<Typography>Neue Wissensdatenbank erstellen</Typography>}
                    elements={
                      <IconButton
                        type='submit'
                        aria-label='Add'
                        className={classes.iconButton}
                        onClick={(): void => setShowCreaateKnowledgeDbDialog(true)}
                      >
                        <Add className={classes.icon} />
                      </IconButton>
                    }
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Typography>Budget auswählen. Darüber wird die Nutzung des Assistenten abgerechnet.</Typography>
              <SelectDropdown
                width='100%'
                className={classes.input}
                options={budgets}
                selected={selectedBudget}
                onChange={onBudgetSelection}
                isSearchable
                placeholder='Budget für den Assistenten auswählen'
              />
            </Grid>
            <div className={classes.createButtonContainer}>
              <Button
                className={classes.createButton}
                onClick={onCreateAssistent}
                disabled={!name || !budgetId || !primaryLanguage}
              >
                Assistenten erstellen
              </Button>
            </div>
          </Grid>
        )}
        {showCreateKnowledgeDbDialog && (
          <CreateKnowledgeDbDialog
            onClose={onKnowledgeDbCreationClose}
            onKnowledgeDbCreated={onKnowledgeDbCreation}
            languages={languages}
            existingKnowledgeDbs={existingKnowledgeDbs.map((db) => db.label)}
            open={showCreateKnowledgeDbDialog}
          />
        )}
      </BaseCard>
    </ContentPage>
  )
}
