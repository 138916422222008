import React, { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'
import { Grid, Switch, Button, IconButton, Typography } from '@mui/material'
import { Delete } from '@mui/icons-material'
import { Separator } from '../../Separator'

import { replaceVarDisplayNameInObject } from './apiRequestUtils'

import { Chart, RequestParams } from '../../../../../../@types/Flowchart/types'
import TextFieldVariablesSuggestions from '../../Variables/TextFieldVariablesSuggestions'

type SAPIRequestParams = {
  chart: Chart
  classes: Record<string, string>
  nodeId: string
  params: RequestParams
  resetAPIResult: (chart?: Chart) => Chart | undefined
  setUpdatedChartCallback: (chart: Chart) => void
}

export default function SAPIRequestParams({
  chart,
  classes: parentClasses,
  params: chartParams,
  resetAPIResult,
  setUpdatedChartCallback,
}: SAPIRequestParams): React.ReactElement {
  const classes = parentClasses
  const [paramsToggle, setParamsToggle] = useState<'on' | 'off'>('off')
  const [params, setParams] = useState<RequestParams>({})
  // const [newParams, setNewParams] = useState<boolean>(false)

  // ===== HANDLER =====

  /**
   * Replaces variable display names with variable ids and updates chart by calling parent's callback.
   * Triggered on each blur of a params value or params key textfield.
   * @param {React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>} event
   */
  function onParamsBlur(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void {
    const nodeId = chart.selected?.id
    if (typeof nodeId === 'undefined') return
    const node = chart.nodes[nodeId]
    const api = node?.properties.api
    if (typeof node === 'undefined' || typeof api === 'undefined') return

    const paramsWithVarIds = replaceVarDisplayNameInObject(chart, params)

    api.request.params = paramsWithVarIds
    node.properties.api = api
    chart.nodes[nodeId] = node
    const newChart = resetAPIResult(chart)
    if (typeof newChart !== 'undefined') setUpdatedChartCallback(newChart)
  }

  /**
   * Handles switch change of params switch.
   * Removes params object(s) if switch gets disabled or creates params object(s) if switch gets enabled.
   * @param {React.ChangeEvent<HTMLInputElement>} event
   * @param {boolean} checked
   */
  function onParamsSwitch(event: React.ChangeEvent<HTMLInputElement>, checked: boolean, loopIter?: string): void {
    const nodeId = chart.selected?.id
    if (typeof nodeId === 'undefined') return
    const node = chart.nodes[nodeId]
    const api = node?.properties.api
    // const params = api?.request.params
    if (typeof node === 'undefined' || typeof api === 'undefined') return

    if (!event.target.checked) {
      api.request.params = undefined
    } else {
      if (!api.request.params)
        api.request.params = {
          '': '',
        }
    }

    node.properties.api = api
    chart.nodes[nodeId] = node
    setParamsToggle(checked ? 'on' : 'off')
    const newChart = resetAPIResult(chart)
    if (typeof newChart !== 'undefined') setUpdatedChartCallback(newChart)
  }

  /**
   * Handles value change of headers key text input.
   * @param {React.ChangeEvent<HTMLInputElement>} event
   * @param {string} key
   * @param {string} loopIter
   */
  function onParamsKeyChange(newKey: string, key: string): void {
    function replaceKey(key: string): void {
      const value = params[key] || ''
      delete params[key]
      params[newKey] = value
      setParams({ ...params })
    }

    // FIXME: For debugging: Remove this if statement and compare behaviour when selecting variable with enter and with mouse click
    // in some cases it can be that key is not the most recent one (i.e. misses the last char)
    // this is for example the case if a variable is selected by mouseclick. I'm not sure why that's the case
    // for now we fix this by using this workaround
    if (!Object.prototype.hasOwnProperty.call(params, key)) {
      const keys = Object.keys(params)
      for (const k of keys) {
        if (newKey.startsWith(k) && k.includes(key)) {
          replaceKey(k)
          return
        }
      }
    }

    replaceKey(key)
    // resetAPIResult()
  }

  /**
   * Handles value change of headers key text input.
   * @param {React.ChangeEvent<HTMLInputElement>} event
   * @param {string} key
   * @param {string} loopIter
   */
  function onParamsValueChange(newValue: string, key: string): void {
    params[key] = newValue

    setParams({ ...params })
    // resetAPIResult()
  }

  /**
   * Handles add params button click.
   * @param {React.MouseEvent<HTMLAnchorElement, MouseEvent>} event
   * @param {string} loopIter
   */
  function onParamsAdd(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    const nodeId = chart.selected?.id
    if (typeof nodeId === 'undefined') return
    const node = chart.nodes[nodeId]
    const api = node?.properties.api
    if (typeof node === 'undefined' || typeof api === 'undefined') return

    api.request.params = api.request.params || {}
    api.request.params[''] = api.request.params[''] || ''
    node.properties.api = api
    chart.nodes[nodeId] = node
    const newChart = resetAPIResult(chart)
    if (typeof newChart !== 'undefined') setUpdatedChartCallback(newChart)
  }

  /**
   * Handles remove params button click.
   * Removes key-value pair from params object.
   * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} event
   * @param {string} key
   * @param {string} loopIter
   */
  function onParamsRemove(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    key?: string,
    loopIter?: string,
  ): void {
    const nodeId = chart.selected?.id
    if (typeof nodeId === 'undefined') return
    const node = chart.nodes[nodeId]
    const api = node?.properties.api
    if (typeof node === 'undefined' || typeof api === 'undefined') return

    if (typeof key !== 'undefined') {
      // remove existing params

      delete params[key]

      api.request.params = params
      node.properties.api = api
      chart.nodes[nodeId] = node
      setUpdatedChartCallback(chart)
    } else {
      // remove new params, i.e. Cancel
      // setNewParams(false)
    }
  }

  useEffect(
    function () {
      if (!isEmpty(chartParams)) setParamsToggle('on')
      setParams(chartParams)
    },
    [chartParams],
  )

  return (
    <div>
      <Grid container direction='row' justifyContent='space-evenly' alignItems='center'>
        <Grid item xs={11} style={{ height: '100%' }}>
          <Typography>Parameter</Typography>
        </Grid>
        <Grid item xs={1} style={{ height: '100%', textAlign: 'right' }} alignItems='center'>
          <Switch
            checked={paramsToggle === 'on'}
            onChange={onParamsSwitch}
            classes={{ switchBase: classes.switchBase, track: classes.switchTrack }}
            value='params'
          />
        </Grid>
      </Grid>
      {typeof params !== 'undefined' && paramsToggle === 'on' ? (
        <div>
          {Object.keys(params).map((key, index) => {
            return (
              <div key={`params-${index}`}>
                <Grid container direction='row' justifyContent='space-evenly' alignItems='center'>
                  <Grid item xs={11} style={{ height: '100%' }}>
                    <div>
                      <TextFieldVariablesSuggestions
                        id={`request-params-key-${index}`}
                        chart={chart}
                        label='Key'
                        value={key}
                        fullWidth
                        autoFocus={key.length === 1}
                        maxRows={1}
                        onBlur={onParamsBlur}
                        onChange={(newValue: string): void => {
                          onParamsKeyChange(newValue, key)
                        }}
                      />
                    </div>
                    {key !== '' && (
                      <div style={{ marginTop: '10px' }}>
                        <TextFieldVariablesSuggestions
                          id={`request-params-value-${index}`}
                          chart={chart}
                          label={`Wert`}
                          value={params[key]}
                          fullWidth
                          maxRows={1}
                          onBlur={onParamsBlur}
                          onChange={(newValue: string): void => onParamsValueChange(newValue, key)}
                        />
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={1} style={{ height: '100%', textAlign: 'left' }} alignItems='center'>
                    <IconButton
                      onClick={(event: React.MouseEvent<HTMLButtonElement>): void => onParamsRemove(event, key)}
                      style={{ marginRight: '7px' }}
                      color='secondary'
                    >
                      <Delete />
                    </IconButton>
                  </Grid>
                </Grid>
                {Object.keys(params).length > 1 && index < Object.keys(params).length - 1 && (
                  <Separator style={{ margin: '15px 50px' }} />
                )}
              </div>
            )
          })}

          <div style={{ textAlign: 'center', marginTop: '10px', marginBottom: '10px' }}>
            <Button disabled={Object.keys(params).includes('')} onClick={onParamsAdd} variant='outlined'>
              Hinzufügen
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  )
}
