import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { useTheme, Menu, MenuList, MenuItem, ListItemText } from '@mui/material'

const useStyles = makeStyles()((theme) => ({
  card: {
    transition: 'box-shadow 0.3s',
    cursor: 'pointer',
    '&:hover': {
      boxShadow:
        '0 14px 26px -12px rgb(0 0 0 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)', // '0px 2px 10px 2px rgba(0,0,0, 0.25)'
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '80%',
  },
  popupMenu: {
    padding: 0,
  },
  popupMenuList: {
    padding: 0,
  },
  popupMenuRedText: {
    color: theme.palette.error.main,
  },
}))

type TimeseriesLinechartMenuProps = {
  onDownloadCsv: () => void
  open: boolean
  onClose: () => void
  anchorElement: Element
}

/**
 * Menu component for menu button of card.
 */
export function TimeseriesLinechartMenu({
  onDownloadCsv,
  open,
  onClose,
  anchorElement,
}: TimeseriesLinechartMenuProps): React.ReactElement {
  const theme = useTheme()
  const { classes } = useStyles()
  return (
    <Menu
      open={open}
      onClose={onClose}
      anchorEl={anchorElement}
      classes={{ list: classes.popupMenuList }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        style: {
          width: '150px',
        },
      }}
    >
      {/* <Paper sx={{ width: 320, maxWidth: '100%' }}> */}
      <MenuList>
        <MenuItem
          onClick={(): void => {
            onDownloadCsv()
            onClose()
          }}
        >
          <ListItemText>Download CSV</ListItemText>
        </MenuItem>
      </MenuList>
      {/* </Paper> */}
    </Menu>
  )
}
