import React, { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'
import { makeStyles } from 'tss-react/mui'
import { TextField, MenuItem, Grid, Switch, Typography } from '@mui/material'
import AceEditor, { IContextMenuOption, IMenuOptionsClickOptions } from '@convaise/react-ace-editor'
// import AceEditor  from '@convaise/react-ace-editor/lib'
import Menu from './SAPIRequestBodyEditorMenu'
import SAPIRequestVariableSelectionDialog from './SAPIRequestVariableSelectionDialog'

import { replaceVarIdInBody, replareVarDisplayNameInBody } from './apiRequestUtils'

import { Chart, RequestBody, RequestType } from '../../../../../../@types/Flowchart/types'

// load required ace-editor configuration
const supportedModesAceEditor = ['json', 'xml']
supportedModesAceEditor.forEach((mode) => {
  require(`ace-builds/src-noconflict/mode-${mode}`)
})
require('ace-builds/src-min-noconflict/theme-xcode')

const useStyles = makeStyles()({
  topGrid: {
    marginTop: '15px',
  },
  gridItem: {
    height: '100%',
  },
  bodySelectFields: {
    width: '200px',
  },
  editorContainer: {
    marginTop: '15px',
  },
})

type RequestBodyTypeSelectionProps = {
  type: RequestType
  options: { label: string; value: RequestType }[]
  typeSelectionCallback: (type: string) => void
}

function RequestBodyTypeSelection({
  type,
  options,
  typeSelectionCallback,
}: RequestBodyTypeSelectionProps): React.ReactElement {
  function onChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const value = event.target.value
    typeSelectionCallback(value)
  }

  return (
    <TextField id='request-type' select fullWidth label='Body Typ' value={type} onChange={onChange}>
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  )
}

type RequestBodyXMLTypeSelectionProps = {
  type: 'xml' | 'xöv' | undefined
  options: { label: string; value: 'xml' | 'xöv' }[]
  xmlTypeSelectionCallback: (xmlType: string) => void
}

function RequestBodyXMLTypeSelection({
  type,
  options,
  xmlTypeSelectionCallback,
}: RequestBodyXMLTypeSelectionProps): React.ReactElement {
  function onChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const value = event.target.value
    xmlTypeSelectionCallback(value)
  }

  return (
    <TextField
      id='request-xml-type'
      select
      fullWidth
      label='XML Standard'
      value={type}
      onChange={onChange}
      placeholder='XML Typ auswählen'
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  )
}

type SAPIRequestBodyProps = {
  chart: Chart
  classes: any
  nodeId: string
  body: RequestBody
  resetAPIResult: () => void
  setUpdatedChartCallback: (chart: Chart) => void
}

export default function SAPIRequestBody({
  chart,
  classes: parentClasses,
  nodeId,
  body: chartBody,
  resetAPIResult,
  setUpdatedChartCallback,
}: SAPIRequestBodyProps): React.ReactElement {
  const { classes: styles } = useStyles()
  const classes = { ...parentClasses, ...styles }

  // supported formats for the ace editor
  const typeOptions: { label: string; value: RequestType }[] = [
    { label: 'JSON', value: 'application/json' },
    { label: 'XML', value: 'application/xml' },
    { label: 'PDF', value: 'application/pdf' },
  ]
  // different xml standards
  const xmlTypeOptions: { label: string; value: 'xml' | 'xöv' }[] = [
    { label: 'XML', value: 'xml' },
    { label: 'XÖV', value: 'xöv' },
  ]

  const [requestType, setRequestType] = useState<RequestType>('application/json')
  const [xmlType, setXmlType] = useState<'xml' | 'xöv' | undefined>()
  const [bodyToggle, setBodyToggle] = useState<'on' | 'off'>('off')
  const [body, setBody] = useState<RequestBody>(chartBody)
  const [newBody, setNewBody] = useState<boolean>(false)
  // Editor
  const [editorMode, setEditorMode] = useState<'xml' | 'json'>('json')
  const [displayDialog, setDisplayDialog] = useState<'variable-selection' | undefined>()
  const [dialogCallback, setDialogCallback] = useState<((textToInsert: string) => void) | undefined>()
  const editorContextMenuSections: IContextMenuOption[] = [
    {
      label: 'Variable einsetzen',
      sectionName: 'Variablen',
      sectionNumber: 0,
      type: 'text-insert',
      onClick: (args?: IMenuOptionsClickOptions): void => {
        setDisplayDialog('variable-selection')
        if (typeof args !== 'undefined' && args.onTextInsert) setDialogCallback(() => args.onTextInsert)
      },
    },
  ]

  /**
   * Replaces variable display names with variable ids and updates chart by calling parent's callback.
   * @param {React.FocusEvent<HTMLDivElement>} event
   * @param {string} value if function is called with value, that value is used instead of the body value from state
   */
  function setBodyInChart(event?: React.FocusEvent<HTMLDivElement>, value?: string): void {
    const nodeId = chart.selected?.id
    if (typeof nodeId === 'undefined') return
    const node = chart.nodes[nodeId]
    const api = node?.properties.api
    if (typeof node === 'undefined' || typeof api === 'undefined') return

    const bodyValue = typeof value !== 'undefined' ? value : body

    const bodyWithVarIds = replareVarDisplayNameInBody(chart, bodyValue)
    api.request.body = bodyWithVarIds
    node.properties.api
    chart.nodes[nodeId] = node
    resetAPIResult()
    setUpdatedChartCallback(chart)
  }

  /**
   * Triggered on each blur of a body editor textfield.
   * @param event
   */
  function onEditorBlur(event: React.FocusEvent<HTMLDivElement>): void {
    setBodyInChart(event)
  }

  /**
   * Handles editor change.
   * Changed value is the body value in this component's state.
   * NOTE: We only update the body in the chart when the editor blurs for performance reasons!
   * @param {string} value
   * @param {any} event
   */
  function onEditorValueChange(value: string, event?: any, shouldBlur?: boolean): void {
    setBody(value)
    if (shouldBlur) {
      setBodyInChart(undefined, value)
    }
  }

  // ===== HANDLER =====

  /**
   * Handles switch change of params switch.
   * Removes params object(s) if switch gets disabled or creates params object(s) if switch gets enabled.
   * @param {React.ChangeEvent<HTMLInputElement>} event
   * @param {boolean} checked
   */
  function onBodySwitch(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void {
    const nodeId = chart.selected?.id
    if (typeof nodeId === 'undefined') return
    const node = chart.nodes[nodeId]
    const api = node?.properties.api
    if (typeof node === 'undefined' || typeof api === 'undefined') return

    const headers = api.request.headers || {}

    if (!event.target.checked) {
      api.request.body = undefined
      const params = api.request.params
      if (typeof headers !== 'undefined') delete headers['Content-Type']
      api.request.params = params
    } else {
      if (!api.request.body) api.request.body = {}
      headers['Content-Type'] = requestType
      setNewBody(true)
    }

    api.request.headers = headers
    node.properties.api = api
    chart.nodes[nodeId] = node
    setBodyToggle(checked ? 'on' : 'off')
    resetAPIResult()
    setUpdatedChartCallback(chart)
  }

  /**
   * Handles request type change.
   * Sets "Content-Type" header to new type.
   * @param {string} type
   * @param {string} loopIter
   */
  function onTypeChange(type: string, loopIter?: string): void {
    if (type === 'application/json' || type === 'application/xml' || type === 'application/pdf') {
      const node = chart.nodes[nodeId]
      const api = node?.properties.api
      if (typeof api === 'undefined') return
      const headers = api.request.headers || {}

      headers['Content-Type'] = type
      if (type === 'application/json') {
        delete api.request.xmlType
        setEditorMode('json')
      }
      if (type === 'application/xml') {
        api.request.xmlType = 'xml'
        setEditorMode('xml')
      }

      api.request.headers = headers
      node.properties.api = api
      chart.nodes[nodeId] = node
      setUpdatedChartCallback(chart)
    }
  }

  /**
   * Handles xml type change.
   * Changes xml type in the chart.
   * @param {string} xmlType
   * @param {string} loopIter
   */
  function onXmlTypeChange(xmlType: string, loopIter?: string): void {
    if (xmlType === 'xml' || xmlType === 'xöv') {
      const node = chart.nodes[nodeId]
      const api = node?.properties.api
      if (typeof api === 'undefined') return

      api.request.xmlType = xmlType

      node.properties.api = api
      chart.nodes[nodeId] = node
      setUpdatedChartCallback(chart)
    }
  }

  /**
   * Handles variable selection.
   * Preprends '%' to variable displayName
   * @param {string} varName
   */
  function onVariableSelect(varName: string): void {
    const displayValue = '%' + varName
    if (typeof dialogCallback !== 'undefined') dialogCallback(displayValue)
    setDisplayDialog(undefined)
  }

  /**
   * Dialog close callback.
   */
  function onVariableDialogClose(): void {
    setDisplayDialog(undefined)
  }

  // ====== USE EFFECT ======

  useEffect(
    function () {
      // find request type from headers
      const headers = chart.nodes[nodeId].properties.api?.request.headers
      if (typeof headers !== 'undefined') {
        const type = headers['Content-Type'] || headers['content-type']
        if (type === 'application/json' || type === 'application/xml' || type === 'application/pdf')
          setRequestType(type)
        if (type === 'application/json') setEditorMode('json')
        if (type === 'application/xml') {
          const xmlType = chart.nodes[nodeId].properties.api?.request.xmlType
          if (typeof xmlType !== 'undefined') setXmlType(xmlType)
          setEditorMode('xml')
        }
      }

      // prepare body
      const displayBody = replaceVarIdInBody(chart, chartBody)
      if (!isEmpty(chartBody)) setBodyToggle('on')
      setBody(displayBody)
    },
    [chart, chartBody],
  )

  return (
    <div>
      <Grid container direction='row' justifyContent='space-evenly' alignItems='center'>
        <Grid item xs={11} className={classes.gridItem}>
          <Typography>Body</Typography>
        </Grid>
        <Grid item xs={1} className={classes.gridItem} style={{ textAlign: 'right' }} alignItems='center'>
          <Switch
            checked={bodyToggle === 'on'}
            onChange={onBodySwitch}
            classes={{ switchBase: classes.switchBase, track: classes.switchTrack }}
            value='body'
          />
        </Grid>
      </Grid>
      {bodyToggle === 'on' ? (
        <div>
          {/* Body Typ Selection */}
          <Grid container direction='row' justifyContent='space-evenly' alignItems='center' className={classes.topGrid}>
            <Grid item xs={4} className={classes.gridItem}>
              <div className={classes.bodySelectFields}>
                <RequestBodyTypeSelection
                  type={requestType}
                  options={typeOptions}
                  typeSelectionCallback={onTypeChange}
                />
              </div>
            </Grid>
            <Grid item xs={4} className={classes.gridItem}>
              {requestType === 'application/xml' ? (
                <div className={classes.bodySelectFields}>
                  <RequestBodyXMLTypeSelection
                    type={xmlType}
                    options={xmlTypeOptions}
                    xmlTypeSelectionCallback={onXmlTypeChange}
                  />
                </div>
              ) : null}
            </Grid>
            <Grid item xs={4} className={classes.gridItem}></Grid>
          </Grid>
          <div className={classes.editorContainer}>
            <AceEditor
              placeholder={''}
              mode={editorMode}
              theme={'xcode'}
              width={'1200px'}
              height={'700px'}
              name='body-editor'
              onBlur={onEditorBlur}
              // onLoad={this.onLoad}
              onChange={onEditorValueChange}
              // onSelectionChange={this.onSelectionChange}
              // onCursorChange={this.onCursorChange}
              // onValidate={this.onValidate}
              value={typeof body === 'string' ? body : ''}
              fontSize={14}
              showPrintMargin={false}
              showGutter={true}
              highlightActiveLine={true}
              setOptions={{
                useWorker: false,
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: false,
                showLineNumbers: true,
                tabSize: 2,
              }}
              enableContextMenu={true}
              ContextMenu={Menu}
              contextMenuOptions={editorContextMenuSections}
            />
          </div>
          <SAPIRequestVariableSelectionDialog
            open={displayDialog === 'variable-selection'}
            chart={chart}
            onConfirm={onVariableSelect}
            onClose={onVariableDialogClose}
          />
        </div>
      ) : null}
    </div>
  )
}
