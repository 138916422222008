import React, { useState, useEffect } from 'react'
import { makeStyles } from 'tss-react/mui'

// @mui/material components
import { Typography } from '@mui/material'

import { SelectDropdown, Option, ActionMeta } from '../../../../../components/Dropdown'

import { NODE_NAMES, FLOWDESIGNER_NODE_WIDTH } from '../../../../../utils/constants'
import { Chart, Node } from '../../../../../@types/Flowchart/types'
import NodeContent from '../../Nodes/NodeContent'
import { TranslationFile } from '../../../../../@types/Translations/types'

const useStyles = makeStyles()((theme) => ({
  selectedDialogContainer: { display: 'flex', flexDirection: 'column' },
  title: {},
  dropdown: { marginTop: theme.spacing(2) },
  outerNode: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '7px',
  },
  nodePreviewContainer: {
    width: `${FLOWDESIGNER_NODE_WIDTH}px`,
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
}))

type SJumpNodeIdProps = {
  chart: Chart
  translationFile: TranslationFile
  onNodeSelection: (nodeId: string) => void
}

export default function SJumpNodeId({ chart, translationFile, onNodeSelection }: SJumpNodeIdProps): React.ReactElement {
  const { classes } = useStyles()
  const [options, setOptions] = useState<Option[]>([])
  const [selection, setSelection] = useState<Option>()
  const [selectedNode, setSelectedNode] = useState<Node>()

  /**
   * Handles dropdown change.
   * @param newValue
   * @param action
   */
  function onDropdownChange(newValue: Option, action: ActionMeta<Option>): void {
    if (newValue === null) {
      setSelection(undefined)
      return
    }

    setSelection(newValue)
    onNodeSelection(newValue.value)
  }

  useEffect(
    function () {
      if (!chart.selected?.id) return

      // prepare dropdown actions
      const nodeIds = Object.keys(chart.nodes).filter((nodeId) => chart.nodes[nodeId].type !== 'basic/note') // filter note nodes
      const options: Option[] = []
      for (const nodeId of nodeIds) {
        options.push({
          value: nodeId,
          label: nodeId,
          sublabel: NODE_NAMES[chart.nodes[nodeId].type],
        })
      }
      setOptions(options)
      // if a node is already configured, set it
      const targetNodeId = chart.nodes[chart.selected.id].properties.targetNode
      if (targetNodeId) {
        const selectedTargetNode = options.find((option) => option.value === targetNodeId)
        if (selectedTargetNode) setSelection(selectedTargetNode)
      }
    },
    [chart],
  )

  useEffect(
    function () {
      if (selection) {
        const selectedNode = Object.values(chart.nodes).find((node) => node.id === selection.value)
        if (selectedNode) {
          setSelectedNode(selectedNode)
          return
        }
      }

      setSelectedNode(undefined)
    },
    [selection],
  )

  return (
    <div className={classes.selectedDialogContainer}>
      <Typography>
        Bitte geben Sie die <em>ID</em> des Zielblocks ein oder wählen Sie die <em>ID</em>.
      </Typography>
      <div className={classes.dropdown}>
        <SelectDropdown
          onChange={onDropdownChange}
          options={options}
          selected={selection}
          placeholder='Block ID eingben oder auswählen'
          maxDisplayOptions={10}
          isSearchable
          isClearable
        />
      </div>
      {selectedNode && (
        <div className={classes.nodePreviewContainer}>
          <div>
            <NodeContent
              chart={chart}
              translationFile={translationFile}
              node={selectedNode}
              onResize={(): void => {
                // do nothing here; this is only used in the Flowdesigner
              }}
              classes={classes}
            />
          </div>
        </div>
      )}
    </div>
  )
}
