import React, { useState, useEffect } from 'react'
import { makeStyles } from 'tss-react/mui'

// @mui/material components
import { TextField, Typography, Button, Grid, IconButton } from '@mui/material'
import VariablesAutosuggestSelect from '../Variables/VariablesAutosuggestSelect'
import { Dropdown, Option } from '../../../../../components/Dropdown/Dropdown'

import {
  getVariables,
  prepareDisplayVariableOptions,
  getAllVariablesForSelect,
  replaceVarDisplayNameWithId,
  replaceVarIdWithDisplayName,
} from '../../../../../utils/chartUtils'

import { Chart, DisplayVariableOption } from '../../../../../@types/Flowchart/types'
import { ActionMeta, SelectDropdown } from 'components/Dropdown'

const useStyles = makeStyles()({
  selectedDialogContainer: {},
  title: {},
  marginTopSmall: { marginTop: '10px' },
  tmpVariableContainer: { width: '100%', display: 'flex' },
})

type SSetVariableProps = {
  chart: Chart
  setStateCallback: (chart: Chart) => void
}

// IDEA: Stefan 30.01.20: show dropdown menu with all possible variables in textfield when % is entered and set variable
// IDEA: Stefan 30.01.20: Validate Variable names and set error if incorrect variable is referenced
// IDEA: Stefan 30.01.20: Simplify UX by not displaying the chars ', +, etc. but keep them in the chart; make it somewhat dynamic
export default function SSetVariable({ chart, setStateCallback }: SSetVariableProps): React.ReactElement {
  const { classes } = useStyles()
  const [allVariablesOptions, setAllVariableOptions] = useState<DisplayVariableOption[]>([])
  const [variables, setVariables] = useState<DisplayVariableOption[]>([])
  const [variableValueText, setVariableValueText] = useState<string>('')
  const [tmpVariableOption, setTmpVariableOption] = useState<Option>()

  /**
   * Handles variable value textfield change
   */
  function onTextChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setVariableValueText(event.target.value)
  }

  /**
   * Handles change in tmp variable dropdown.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function onTmpVariableChange(newValue: Option, action: ActionMeta<Option>): void {
    setTmpVariableOption(newValue)
  }

  /**
   * Takes the displayed value and transforms it into the correct form (var ids instead of display name)
   * and puts it into the chart.
   */
  function setValueToChart(): void {
    if (typeof chart.selected?.id === 'undefined') return
    const setVarValues = chart.nodes[chart.selected.id].properties.setVarValues || {}

    const displayValue = variableValueText
    const value = replaceVarDisplayNameWithId(chart, displayValue)

    for (const variable of variables) {
      setVarValues[variable.value] = value
    }

    chart.nodes[chart.selected.id].properties.setVarValues = setVarValues
    setStateCallback(chart)
  }

  function onAddTmpVariableToValue(): void {
    if (typeof tmpVariableOption === 'undefined') return

    let newVariableValueText = variableValueText

    let value = newVariableValueText
    // if (newVariableValueText) value += newVariableValueText + " + ', ' + "
    // value += `%${chart.variables[tmpVariableOption.value].displayName}` + "'"
    value += `%${chart.variables[tmpVariableOption.value].displayName}`
    newVariableValueText = value

    setVariableValueText(newVariableValueText)
    setTmpVariableOption(undefined)
    setValueToChart()
  }

  /**
   * Callback function for variable selection.
   * Variable is registered in the chart by the selection component.
   * This function deals with setting the variable under setVarValues in the chart.
   *
   * @param {Chart} chart
   * @param {string} selectedVarId id of the selected variable
   */
  function onSetVar(
    chart: Chart,
    selectedVarId: string,
    allSelectedVariables: DisplayVariableOption[],
  ): { chart: Chart; allSelectedVariablesNew: DisplayVariableOption[] } {
    if (typeof chart.selected?.id === 'undefined') return { chart, allSelectedVariablesNew: allSelectedVariables }

    // add value that should be assigned to variable, if already exists
    // NOTE: atm this assumes that only one variable can be set therefore we simply get the old value from the previously existing variable and reuse it.
    // this needs to be refined should we allow multiple variable in the future
    const setVarValues = chart.nodes[chart.selected.id].properties.setVarValues || {}
    const previouslyExistingVarIds = Object.keys(setVarValues)
    let value = ''
    if (previouslyExistingVarIds.length > 0) {
      // another variable was already selected, get its value
      value = setVarValues[previouslyExistingVarIds[0]]
    }

    setVarValues[selectedVarId] = value

    const allSelectedVariablesNew = [...allSelectedVariables]
    const varDisplayOptions = prepareDisplayVariableOptions(chart, [selectedVarId])
    if (varDisplayOptions.length > 0) allSelectedVariablesNew.push(varDisplayOptions[0])

    chart.nodes[chart.selected.id].properties.setVarValues = setVarValues
    return { chart, allSelectedVariablesNew }
  }

  /**
   * Callback function for variable removal.
   * Removes variable from setVarValues in the chart and returns chart.
   **/
  function onRemoveVar(
    chart: Chart,
    varId: string,
    allSelectedVariables: DisplayVariableOption[],
  ): { chart: Chart; allSelectedVariablesNew: DisplayVariableOption[] } {
    if (
      typeof chart.selected.id === 'undefined' ||
      typeof chart.nodes[chart.selected.id].properties.setVarValues === 'undefined'
    )
      return { chart, allSelectedVariablesNew: allSelectedVariables }

    const setVarValues = chart.nodes[chart.selected.id].properties.setVarValues
    if (typeof setVarValues !== 'undefined') {
      delete setVarValues[varId]
      chart.nodes[chart.selected.id].properties.setVarValues = setVarValues
    }

    const newVariables = allSelectedVariables.filter((variable) => variable.value !== varId)
    return { chart, allSelectedVariablesNew: newVariables }
  }

  /**
   * Callback function for variable selection.
   */
  function onVariableChange(chart: Chart, prevSelectedVarIds: string[], selectedVarIds: string[]): void {
    const removedVarIds = prevSelectedVarIds.filter((varId) => !selectedVarIds.includes(varId))
    const newVarIds = selectedVarIds.filter((varId) => !prevSelectedVarIds.includes(varId))

    let selectedVariables = [...variables]

    // remove old variables
    for (const varId of removedVarIds) {
      const removeResult = onRemoveVar(chart, varId, selectedVariables)
      selectedVariables = removeResult.allSelectedVariablesNew
      chart = removeResult.chart
    }
    // add new variables
    for (const varId of newVarIds) {
      const setResult = onSetVar(chart, varId, selectedVariables)
      selectedVariables = setResult.allSelectedVariablesNew
      chart = setResult.chart
    }

    if (selectedVariables.length === 0) setVariableValueText('')
    setVariables(selectedVariables)
    setStateCallback(chart)
  }

  useEffect(function () {
    const variables = getVariables(chart)
    setVariables(variables)
    if (
      variables.length > 0 &&
      typeof chart.selected?.id !== 'undefined' &&
      typeof chart.nodes[chart.selected.id].properties.setVarValues !== 'undefined'
    ) {
      const setVarValues = chart.nodes[chart.selected.id].properties.setVarValues
      if (typeof setVarValues !== 'undefined') {
        const setVarValue = setVarValues[variables[0].value]
        const variableValueText = replaceVarIdWithDisplayName(chart, setVarValue)
        setVariableValueText(variableValueText)
      }
    }
  }, [])

  useEffect(
    function () {
      // we want to display all variables as options. Could be that we want to extend the current variable with itself and another value
      // e.g. var1 = var1 + var2. Therefore, we no longer filter.
      const allVariableOptions = getAllVariablesForSelect(chart)
      // .filter
      // // (varOption) => !variables.some((v: DisplayVariableOption) => v.value === varOption.value)
      // ()
      setAllVariableOptions(allVariableOptions)
    },
    [variables],
  )

  return (
    <div className={classes.selectedDialogContainer}>
      <div>
        <Typography>Erstellen oder wählen Sie die Variable deren Wert gesetzt werden soll.</Typography>
        <div className={classes.marginTopSmall}>
          <VariablesAutosuggestSelect chart={chart} onChange={onVariableChange} usageType='set' />
        </div>
        {variables.length > 0 && (
          <React.Fragment>
            <div style={{ marginTop: '15px' }}>
              <Typography>
                Legen Sie den Wert fest auf den die gewählte Variable gesetzt werden soll. Werte andere Variablen können
                mit <em>%varId</em> genutzt werden.
              </Typography>
            </div>
            <TextField
              id='variable-value-textfield'
              label='Text der in der Variablen gespeichert wird'
              value={variableValueText}
              fullWidth
              onChange={onTextChange}
              onBlur={setValueToChart}
              margin='normal'
            />

            <div className={classes.tmpVariableContainer}>
              <SelectDropdown
                onChange={onTmpVariableChange}
                options={allVariablesOptions}
                selected={tmpVariableOption}
                isSearchable
                placeholder='Variable auswählen'
                width='100%'
              />

              <IconButton disabled={typeof tmpVariableOption === 'undefined'} onClick={onAddTmpVariableToValue}>
                <i className={`ri-add-line`} />
              </IconButton>
            </div>

            {/* <Grid container direction='row' justifyContent='space-evenly' alignItems='center'>
              <Grid item xs={11} style={{ height: '100%' }}>
                <Dropdown
                  onChange={onTmpVariableChange}
                  options={allVariablesOptions}
                  selectedValues={
                    typeof tmpVariableId !== 'undefined'
                      ? [{ label: chart.variables[tmpVariableId].displayName, value: tmpVariableId }]
                      : []
                  }
                  multiple={false}
                  creatable={false}
                  placeholder='Variable auswählen'
                />
              </Grid>

              <Grid item xs={1} style={{ height: '100%' }}>
                <div style={{ textAlign: 'center' }}>
                  <Button
                    disabled={typeof tmpVariableId === 'undefined'}
                    onClick={onAddTmpVariableToValue}
                    variant='outlined'
                  >
                    Hinzufügen
                  </Button>
                </div>
              </Grid>
            </Grid> */}
          </React.Fragment>
        )}
      </div>
    </div>
  )
}
