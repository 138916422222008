// for use with jsonpath - see https://stackoverflow.com/questions/54551800/what-is-the-javascript-library-or-module-that-can-update-json-based-on-jsonpath

import _ from 'lodash'
import jp from 'jsonpath'

export function toJSON(input: string | object): object | null {
  if (!input) return null
  try {
    return JSON.parse(input as string)
  } catch (err) {
    return JSON.parse(JSON.stringify(input as object))
  }
}

function changeValueByPath(object, path, value) {
  if (Array.isArray(path) && path[0] === '$') {
    const pathWithoutFirstElement = path.slice(1)
    _.set(object, pathWithoutFirstElement, value)
  }
}

type Nodes = { path: string[] | jp.PathComponent[]; value: any }[]

/**
 *
 * @param object Json object
 * @param nodes Nodes that changed in the json, has paths and a new value that will be searched and set in the given object
 * @param lastPropertyName Porperty that is changed, will be concated to the node path
 * @returns New JSON object with the changes from nodes
 */
export function changeValuesByPath(object: any, nodes: Nodes, lastPropertyName: string): any {
  nodes.forEach((node) => {
    changeValueByPath(object, node.path.concat(lastPropertyName), node.value)
  })

  return object
}
