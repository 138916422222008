import React, { createContext, useContext, useReducer, useCallback, useEffect } from 'react'

import { useBotContext } from './bot-context'
import { useLockingContext } from './locking-context'
import { useStudioNotificationContext } from './studio-notification-context'
import {
  AnswerTemplateData,
  EndpointUtterance,
  EndpointUtteranceAnswered,
  EndpointUtteranceUnanswered,
  LLMNLUEndpointUtterance,
  LoadingState,
} from '../../@types/Knowledge/types'
import {
  addUtteranceToNLUIntent as addUtteranceToNLUIntentApi,
  removeEndpointUtterances as removeEndpointUtteranceApi,
  getLLMNLUEndpointUtterances as getLLMNLUEndpointUtterancesApi,
} from '../../api/StudioBackend'
import { useErrorContext } from './errorContext'
import ErrorComponent from 'components/Error/Error'
import { useAnswers } from './answers-context'
import { BotInfos } from '../../@types/BotInformation/types'
import { Answer } from 'classes/Knowledge'
import { cloneDeep } from 'lodash'

// ----- TYPE DEFINITIONS -----

// Maps Actions to Payloads - Standard does not need customization
type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key
      }
    : {
        type: Key
        payload: M[Key]
      }
}

type Range = {
  from: number
  to: number
}

type TmpUtterance = {
  utterance: EndpointUtterance
  position: number
}

const LOAD_BATCH_SIZE = 25

type ApiSort = 'oldest' | 'newest'

enum Types {
  SetInitialized = 'SET_INITIALIZED',
  SetLoading = 'SET_LOADING',
  SetRange = 'SET_RANGE',
  SetEndpointUtterances = 'SET_ENDPOINT_UTTERANCES',
  ResetState = 'RESET',
  ResetModeState = 'RESET_MODE',
  ChangeSuggestedAnswer = 'CHANGE_SUGGESTION',
  SetTotalNumberEndpointUtterances = 'SET_TOTAL_NUMBER',
}

type ModellmanagementContextPayload = {
  [Types.ResetState]: {}
  [Types.SetLoading]: {
    loading: LoadingState
  }
  [Types.SetInitialized]: {
    isInitialized: boolean
  }
  [Types.SetRange]: {
    from?: number
    to?: number
  }
  [Types.SetEndpointUtterances]: {
    endpointUtterances?: LLMNLUEndpointUtterance[]
    replace?: boolean
  }
  [Types.ChangeSuggestedAnswer]: {
    endpointUtterance: LLMNLUEndpointUtterance
    suggestedAnswer: Answer
  }
  [Types.SetTotalNumberEndpointUtterances]: {
    totalNumberEndpointUtterances: number
  }
}

type Action = ActionMap<ModellmanagementContextPayload>[keyof ActionMap<ModellmanagementContextPayload>]
type State = {
  loading: LoadingState | null
  isInitialized: boolean
  range: Range
  endpointUtterances: LLMNLUEndpointUtterance[]
  totalNumberEndpointUtterances: number
}

type Dispatch = (action: Action) => void
type ModellmanagementContextProviderProps = { children: React.ReactNode }

/**
 * ----- CONTEXT -----
 * ModellmanagementContext is a local context used for storing everything needed to manage modell / endpoint utterances
 *
 * To access this context:
 * import { useModellmanagementLLMContext } from '<path>/hooks/contexts/modellmanagement-llm-context.tsx'
 *
 * // in a function
 * const {data, loading, resetDatamanagementContext} = useModellmanagementLLMContext()
 *
 */
const ModellmanagementContext = createContext<
  | {
      state: State
      dispatch: Dispatch
      setLoading: (isLoading: LoadingState) => void
      loadEndpointUtterances: (
        sort: ApiSort,
        from?: number,
        to?: number,
        isRetry?: boolean,
      ) => Promise<{
        preparedEndpointUtterances: LLMNLUEndpointUtterance[]
        totalNumberOfEndpointUtterances: number
      } | null>
    }
  | undefined
>(undefined)

function modelmanagementReducer(state: State, action: Action): State {
  switch (action.type) {
    case Types.ResetState:
      return {
        ...state,
        loading: null,
        isInitialized: false,
        range: {
          from: 0,
          to: 25,
        },
        totalNumberEndpointUtterances: 0,
        endpointUtterances: [],
      }
    // case Types.ResetModeState: {
    //   const newState = { ...state }
    //   if (action.payload.mode === 'all' || action.payload.mode === 'answered') {
    //     // reset answered
    //     newState.endpointUtterances.answered = null
    //     newState.endpointUtterances.totalAnswered = null
    //     newState.range.answeredFrom = 0
    //     newState.range.answeredTo = LOAD_BATCH_SIZE
    //   }
    //   if (action.payload.mode === 'all' || action.payload.mode === 'unanswered') {
    //     // reset answered
    //     newState.endpointUtterances.unanswered = null
    //     newState.endpointUtterances.totalUnanswered = null
    //     newState.range.unansweredFrom = 0
    //     newState.range.unansweredTo = LOAD_BATCH_SIZE
    //   }
    //   return newState
    // }
    case Types.SetRange:
      return {
        ...state,
        range: {
          ...state.range,
          ...action.payload,
        },
      }
    case Types.SetLoading: {
      return {
        ...state,
        loading: action.payload.loading,
      }
    }
    case Types.SetInitialized: {
      return {
        ...state,
        isInitialized: action.payload.isInitialized,
      }
    }
    case Types.SetEndpointUtterances: {
      let newEndpointUtts = [...state.endpointUtterances]
      const existingCorrIds = state.endpointUtterances.map((utt) => utt.corrId)

      if (action.payload.endpointUtterances) {
        if (action.payload.replace) {
          // fully replace list
          newEndpointUtts = action.payload.endpointUtterances
        } else {
          // add to existing list
          for (const newUtt of action.payload.endpointUtterances) {
            if (!existingCorrIds.includes(newUtt.corrId)) newEndpointUtts.push(newUtt)
          }
        }
      }

      return {
        ...state,
        endpointUtterances: newEndpointUtts,
      }
    }
    case Types.ChangeSuggestedAnswer: {
      if (action.payload.endpointUtterance && action.payload.suggestedAnswer) {
        const idx = state.endpointUtterances.findIndex((utt) => utt.corrId === action.payload.endpointUtterance.corrId)
        if (idx > -1) {
          const newEndpointUtt = cloneDeep(state.endpointUtterances[idx])
          newEndpointUtt.suggestedAnswer = {
            answerId: action.payload.suggestedAnswer.answerId,
            language: action.payload.suggestedAnswer.language,
            intent: action.payload.suggestedAnswer.intent,
          }
          newEndpointUtt.suggestionChangedByUser = true
          state.endpointUtterances[idx] = newEndpointUtt
          const newEndpointUtts = [...state.endpointUtterances]
          state.endpointUtterances = newEndpointUtts
        }
      }
      return { ...state }
    }
    case Types.SetTotalNumberEndpointUtterances: {
      if (typeof action.payload.totalNumberEndpointUtterances !== 'undefined') {
        return { ...state, totalNumberEndpointUtterances: action.payload.totalNumberEndpointUtterances }
      } else {
        return { ...state }
      }
    }
    default: {
      // helps us avoid typos!
      throw new Error(`[Modellmanagement-llm-Reducer] Unhandled action type: ${(action as any).type}`)
    }
  }
}

export function ModellmanagementLLMNLUContextProvider({ children }: ModellmanagementContextProviderProps): JSX.Element {
  const { setError } = useErrorContext()
  const { bot } = useBotContext()
  const { loading: answersLoading } = useAnswers()
  const [state, dispatch] = useReducer(modelmanagementReducer, {
    isInitialized: false,
    loading: 'loading',
    range: {
      from: 0,
      to: LOAD_BATCH_SIZE,
    },
    endpointUtterances: [],
    totalNumberEndpointUtterances: 0,
  })

  // simple wrapper around setloading dispatch
  function setLoading(loading: LoadingState): void {
    dispatch({ type: Types.SetLoading, payload: { loading } })
  }

  // simple wrapper around setInitialized dispatch
  function setInitialized(isInitialized: boolean): void {
    dispatch({ type: Types.SetInitialized, payload: { isInitialized } })
  }

  /**
   * Loads endpoint utterances.
   * If from and to are specified uses them, if not uses values from state.
   * @param mode
   * @param from
   * @param to
   * @param isRetry
   * @returns
   */
  async function loadEndpointUtterances(
    sort: ApiSort,
    from?: number,
    to?: number,
    isRetry = false,
  ): Promise<{
    preparedEndpointUtterances: LLMNLUEndpointUtterance[]
    totalNumberOfEndpointUtterances: number
  } | null> {
    try {
      if (!bot?.id) return null
      setLoading('loading')

      const result = await getLLMNLUEndpointUtterancesApi(bot.id, from ?? state.range.from, to ?? state.range.to, sort)

      if (!result) throw new Error('Load EndpointUtterances Error')
      setLoading(undefined)
      return result
    } catch (err) {
      if (!isRetry) {
        // try again a second time
        loadEndpointUtterances(sort, from, to, true)
      } else {
        // set error
        setError(
          'Knowledge.specific.modelmanagment',
          'Das Nutzerfragen konnten nicht geladen werden. Bitte versuchen Sie es erneut.',
          'Erneut versuchen',
          () => loadEndpointUtterances(sort, from, to),
          'Fehler beim Laden der Nutzerfragen',
        )
        setLoading(undefined)
      }
    }
    return null
  }

  async function init(): Promise<void> {
    const result = await loadEndpointUtterances('newest')
    if (result === null) return
    // we can put undefined or non-present values here. reduces takes care of correct handling
    dispatch({
      type: Types.SetEndpointUtterances,
      payload: { endpointUtterances: result.preparedEndpointUtterances },
    })
    dispatch({
      type: Types.SetTotalNumberEndpointUtterances,
      payload: { totalNumberEndpointUtterances: result.totalNumberOfEndpointUtterances },
    })
    if (!state.isInitialized) setInitialized(true)
  }

  useEffect(function () {
    init()
    return () => {
      dispatch({ type: Types.ResetState, payload: {} })
    }
  }, [])

  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  // const value = useMemo(() => [state, dispatch], [state])
  const value = { state, dispatch, loadEndpointUtterances, setLoading }
  return (
    <ErrorComponent errorCode='Knowledge.specific.infomanagement'>
      <ModellmanagementContext.Provider value={value}>{children}</ModellmanagementContext.Provider>
    </ErrorComponent>
  )
}

/**
 * Consume only for now.
 * @returns
 */
export function useModellmanagementLLMContext(): {
  loading: LoadingState
  isInitialized: boolean
  range: Range
  endpointUtterances: LLMNLUEndpointUtterance[]
  totalNumberEndpointUtterances: number
  addUtteranceToAnswer: (utterance: LLMNLUEndpointUtterance, intentToAddTo: string) => Promise<void>
  deleteUtterance: (utterance: LLMNLUEndpointUtterance) => Promise<void>
  loadNextUtterances: (sort: ApiSort, reset?: boolean) => Promise<void>
  onChangeSuggestedAnswer: (utterance: LLMNLUEndpointUtterance, newSuggestedAnswer: Answer) => void
  reset: () => void // resets range for utterance fetching (e.g. when switching between sort modes)
} {
  const { bot } = useBotContext() as { bot: BotInfos } // can cast here as botinfos have to be loaded for this to be initialied
  const context = useContext(ModellmanagementContext)
  if (context === undefined) {
    throw new Error(
      '[useModellmanagementLLMContext] useModellmanagementLLMContext must be used within a ModellmanagementLLMContextProvider',
    )
  }
  const { state, dispatch, setLoading, loadEndpointUtterances } = context
  const { range, endpointUtterances, totalNumberEndpointUtterances } = state

  const { flaggedAnswers: answers, loading: answersLoading } = useAnswers()
  const { setNotification } = useStudioNotificationContext()

  /**
   * Loads next batch of utterances based on already loaded ones (determined via range)
   * and mode.
   */
  const loadNextUtterances = useCallback(
    async (sort: ApiSort, reset?: boolean): Promise<void> => {
      const newFrom = reset ? 0 : range.from + LOAD_BATCH_SIZE
      const newTo = reset ? LOAD_BATCH_SIZE : range.to + LOAD_BATCH_SIZE
      if (reset) dispatch({ type: Types.ResetState, payload: {} })
      const result = await loadEndpointUtterances(sort, newFrom, newTo)
      if (result) {
        dispatch({ type: Types.SetRange, payload: { from: newFrom, to: newTo } })
        dispatch({
          type: Types.SetEndpointUtterances,
          payload: {
            endpointUtterances: result.preparedEndpointUtterances,
          },
        })
        dispatch({
          type: Types.SetTotalNumberEndpointUtterances,
          payload: {
            totalNumberEndpointUtterances: result.totalNumberOfEndpointUtterances,
          },
        })
        if (reset) dispatch({ type: Types.SetInitialized, payload: { isInitialized: true } })
      }
    },
    [range],
  )

  /**
   * Changes suggested answer of an endpoint utterance
   */
  const onChangeSuggestedAnswer = useCallback(
    (utterance: LLMNLUEndpointUtterance, newSuggestedAnswer: Answer): void => {
      if (utterance && newSuggestedAnswer) {
        dispatch({
          type: Types.ChangeSuggestedAnswer,
          payload: { suggestedAnswer: newSuggestedAnswer, endpointUtterance: utterance },
        })
      }
    },
    [endpointUtterances],
  )

  /**
   * Adds utterance to answer.
   * - Removes utterance from endpoint list
   * - makes API call to add utterance to answer
   * - TODO: check if we need to update answer locally as well - do we need the new utterance in the loacl context
   * - if call fails, re-adds utterance to list at same position
   */
  const addUtteranceToAnswer = useCallback(
    async (utterance: LLMNLUEndpointUtterance, intentToAddTo: string) => {
      // build tempUtteranceObj to re-add utterance to list if add call fails
      const origEndpointUtterances = [...endpointUtterances]
      const position = endpointUtterances ? endpointUtterances.findIndex((utt) => utt.corrId === utterance.corrId) : -1

      // remove utterance from list
      // we update visible things first and make the api call in the background
      if (position > -1) {
        const payload: ModellmanagementContextPayload[Types.SetEndpointUtterances] = { replace: true }
        if (endpointUtterances) endpointUtterances.splice(position, 1) // inplace - remove utterance

        payload.endpointUtterances = endpointUtterances
        dispatch({
          type: Types.SetEndpointUtterances,
          payload,
        })
      }

      // api call
      try {
        const res = await addUtteranceToNLUIntentApi(bot.id, intentToAddTo, 'specific', [utterance.endpointUtterance])
        // res should be undefined and not null
        if (res === null) throw new Error()
      } catch (err) {
        // api call failed, readd the utterance to the list
        const payload: ModellmanagementContextPayload[Types.SetEndpointUtterances] = { replace: true }
        payload.endpointUtterances = origEndpointUtterances
        dispatch({
          type: Types.SetEndpointUtterances,
          payload,
        })
        setNotification('error', 'Fehler beim Übernehmen der Zuordnung. Bitte versuchen Sie es später erneut.')
      }
    },
    [endpointUtterances, answers],
  )

  /**
   * Deletes utterance.
   * - Removes utterance from endpoint list
   * - makes API call to add utterance to answer
   * - if call fails, re-adds utterance to list at same position
   */
  const deleteUtterance = useCallback(
    async (utterance: LLMNLUEndpointUtterance) => {
      // build tempUtteranceObj to re-add utterance to list if add call fails
      const origEndpointUtterances = [...endpointUtterances]
      const position = endpointUtterances ? endpointUtterances.findIndex((utt) => utt.corrId === utterance.corrId) : -1

      // remove utterance from list
      // we update visible things first and make the api call in the background
      if (position > -1) {
        const payload: ModellmanagementContextPayload[Types.SetEndpointUtterances] = { replace: true }
        if (endpointUtterances) endpointUtterances.splice(position, 1) // inplace - remove utterance
        payload.endpointUtterances = endpointUtterances
        dispatch({
          type: Types.SetEndpointUtterances,
          payload,
        })
      }

      // api call
      try {
        const res = await removeEndpointUtteranceApi(bot.id, 'specific', [utterance.endpointUtterance])
        // res should be undefined
        if (res === null) throw new Error()
        dispatch({
          type: Types.SetTotalNumberEndpointUtterances,
          payload: {
            totalNumberEndpointUtterances: totalNumberEndpointUtterances - 1,
          },
        })
      } catch (err) {
        // api call failed, readd the utterance to the list
        const payload: ModellmanagementContextPayload[Types.SetEndpointUtterances] = { replace: true }
        payload.endpointUtterances = origEndpointUtterances
        dispatch({
          type: Types.SetEndpointUtterances,
          payload,
        })

        setNotification('error', 'Fehler beim Entfernen der Nutzerfrage. Bitte versuchen Sie es später erneut.')
      }
    },
    [endpointUtterances],
  )

  /**
   * Use this to reset when switching between sorts.
   */
  const reset = useCallback((): void => {
    dispatch({ type: Types.ResetState, payload: {} })
  }, [])

  const { loading, isInitialized } = state

  return {
    loading: loading ?? answersLoading === 'loading' ? 'loading' : undefined,
    range,
    endpointUtterances,
    isInitialized,
    totalNumberEndpointUtterances,
    addUtteranceToAnswer,
    deleteUtterance,
    loadNextUtterances,
    onChangeSuggestedAnswer,
    reset,
  }
}
