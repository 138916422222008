import React, { useEffect, useState } from 'react'

import { makeStyles } from 'tss-react/mui'
import { TextField } from '@mui/material'

import VariablesAutosuggestSelect, { OnChangeOptions } from '../../Variables/VariablesAutosuggestSelect'

// types
import { Chart } from '../../../../../../@types/Flowchart/types'

const useStyles = makeStyles()({
  textField: {
    width: '100%',
    marginTop: '20px',
  },
})

type AcChoiceValueInputProps = {
  chart: Chart
  acFieldType: string
  choiceSetId: string
  acChoiceName: string
  setVariablesCallback: (
    chart: Chart,
    prevSelectedVarIds: string[],
    selectedVarIds: string[],
    options: OnChangeOptions,
  ) => void
  setStateCallback: (chart: Chart) => void
}

/**
 *
 * @param param0
 * @returns
 * @deprecated
 */
export default function AcChoiceValueInput({
  chart,
  acFieldType,
  choiceSetId,
  acChoiceName,
  setVariablesCallback,
  setStateCallback,
}: AcChoiceValueInputProps): React.ReactElement {
  const { classes } = useStyles()
  const [text, setText] = useState<string | undefined>()
  const [varId, setVarId] = useState<string | undefined>()

  /**
   * Handles finished input (e.g. onBlur of TextField).
   * Sets value for choice.
   * @param event
   */
  function onInputFinish(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void {
    const nodeId = chart.selected.id
    if (typeof varId === 'undefined' || typeof nodeId === 'undefined') return
    const variable = chart.variables[varId]
    if (typeof variable === 'undefined') return
    const acObj = variable.usage[nodeId].ac
    const acChoicesObj = variable.usage[nodeId].ac?.acChoices
    if (typeof acObj === 'undefined' || typeof acChoicesObj === 'undefined') return

    const acChoices = acChoicesObj[choiceSetId]
    for (let i = 0; i < acChoices.length; i++) {
      if (acChoices[i].choice === acChoiceName) {
        // set value
        acChoices[i].value = event.target.value
        // set changed choice back into the chart
        acChoices[choiceSetId] = acChoices
        acChoicesObj[choiceSetId] = acChoices
        acObj.acChoices = acChoicesObj
        variable.usage[nodeId].ac = acObj
        chart.variables[varId] = variable
        setStateCallback(chart)
        break
      }
    }
  }

  /**
   * Handles va
   * @param event
   */
  function onValueTextChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setText(event.target.value)
  }

  /**
   * Handles selection and deletion of variable.
   * This basically sets value for the displayed text fields.
   * @param {chart} chart
   * @param {string} varId
   * @param {boolean} deleted
   */
  function onVariableChange(chart: Chart, varId: string, deleted = false): void {
    if (deleted) {
      setText(undefined)
    } else {
      // find choice value and set it into state
      const nodeId = chart.selected?.id
      const variable = chart.variables[varId]
      if (typeof variable === 'undefined' || typeof nodeId === 'undefined') return
      setVarId(varId)
      const usageObj = variable.usage[nodeId]
      if (usageObj && usageObj.ac && usageObj.ac.acChoices && usageObj.ac.acChoices[choiceSetId]) {
        for (const choiceObj of usageObj.ac.acChoices[choiceSetId]) {
          if (choiceObj.choice === acChoiceName) {
            const text = choiceObj.value
            setText(text)
            break
          }
        }
      }
    }
  }

  /**
   * Handles onChange callback of the VariablesAutosuggestSelect component.
   * @param {Chart} chart
   * @param {string[]} prevSelectedVarIds
   * @param {string[]} selectedVarIds
   * @param {OnChangeOptions} options
   */
  function onChange(
    chart: Chart,
    prevSelectedVarIds: string[],
    selectedVarIds: string[],
    options: OnChangeOptions,
  ): void {
    if (selectedVarIds >= prevSelectedVarIds) {
      // variable was selected or created
      // find newly selected variable
      const newVarIds = selectedVarIds.filter((varId) => !prevSelectedVarIds.includes(varId))
      if (newVarIds.length > 0) onVariableChange(chart, newVarIds[0])
    } else {
      // variable was removed
      const removedVarIds = prevSelectedVarIds.filter((varId) => !selectedVarIds.includes(varId))
      if (removedVarIds.length > 0) onVariableChange(chart, removedVarIds[0], true)
    }

    setVariablesCallback(chart, prevSelectedVarIds, selectedVarIds, options)
  }

  useEffect(
    function () {
      // find find choice value for this choice and set it in state
      const nodeId = chart.selected.id
      if (typeof nodeId === 'undefined' || typeof chart.nodes[nodeId] === 'undefined') return
      const node = chart.nodes[nodeId]

      // find variable
      // iterate over all node variables until the one for this choice input is found
      for (const variable of Object.values(node.properties.variables || {})) {
        const acObj = chart.variables[variable.id]?.usage[nodeId]?.ac
        if (acObj && acObj.acChoices && acObj.acChoices[choiceSetId]) {
          // variable is responsible for current choiceset
          // check if choice is included and set value and var id if that's the case
          for (const choice of acObj.acChoices[choiceSetId]) {
            if (choice.choice === acChoiceName) {
              setVarId(variable.id)
              setText(choice.value)
            }
          }
        }
      }
    },
    [acChoiceName],
  )

  return (
    <div>
      <VariablesAutosuggestSelect
        onChange={onChange}
        chart={chart}
        usageType='set'
        isChoiceSet={true}
        acFieldId={choiceSetId}
        acFieldType={acFieldType}
        acChoice={acChoiceName}
        isMulti={false}
        isResult={false}
        allowMultiSelectWithinNode
      />
      <TextField
        // id={'choice-value'}
        className={classes.textField}
        value={text}
        disabled={typeof varId === 'undefined'}
        placeholder={'Wert'}
        onChange={onValueTextChange}
        onBlur={onInputFinish}
        margin='dense'
      />
    </div>
  )
}
