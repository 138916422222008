import React, { useState, useEffect, Fragment, useCallback } from 'react'
// types
import { Field, Coordinates, Container, SyncedSignature, SyncedCoordinates } from '../../@types/Ingest/types'
import { IngestPDFResult, IngestPDFSubSection, IngestPDFSection } from '../../classes/Ingest'
// custom components
import Selection from '../Selection/Selection'
import SelectionPopup from '../Popups/SelectionPopup'
import { FieldsDialog } from './Dialogs'

// @mui/material
import { makeStyles } from 'tss-react/mui'

import Fab from '@mui/material/Fab'
// @mui/icons-material
import SaveIcon from '@mui/icons-material/Save'

const CONTAINER_PADDING = 5

const useStyles = makeStyles()((theme) => ({
  containerHighlight: {
    position: 'absolute',
    background: 'rgba(65, 131, 215, 0.15)',
    cursor: 'pointer',
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      background: 'rgba(65, 131, 215, 0.2)',
      '& p': {
        fontWeight: 'bold',
      },
    },
  },
  containerText: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    margin: '0px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    fontSize: 'small',
    paddingRight: '5px',
    paddingLeft: '5px',
  },
  parentText: {
    position: 'absolute',
    top: '-20px',
    left: '0px',
    margin: '0px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    paddingRight: '5px',
    paddingLeft: '5px',
    backgroundColor: 'white',
    borderRadius: theme.shape.borderRadius + 'px ' + theme.shape.borderRadius + 'px 0px 0px',
    pointerEvents: 'none',
  },
  fieldHightlight: {
    position: 'absolute',
    borderRadius: theme.shape.borderRadius,
  },
  backdrop: {
    position: 'absolute',
    borderRadius:
      '0px ' + theme.shape.borderRadius + 'px ' + theme.shape.borderRadius + 'px ' + theme.shape.borderRadius + 'px',
    boxShadow: '0 0 0 100vmax rgba(0,0,0,.3)',
  },
  saveButton: {
    position: 'absolute',
    zIndex: 5,
  },
}))

type Props = {
  syncedSignature: SyncedSignature
  target: HTMLDivElement
  pageNumber: number
  containers: Container[]
  containerId: string
  setContainerId: (id: string) => void
  ingestPDFResult: IngestPDFResult
  setIngestPDFResult: (e: IngestPDFResult) => void
  setStage: (e: string) => void
}

// NOTE: highlight missing fields?
// NOTE: tooltip for disabled button
// TODO: check for double names
// FIXME: match with resultFile. Case: opened existing section to modify existing subsections -> new subsections would be created. So sync with result before creating new subsections. ~ Jakob 19.10.2020

function SubSectionHightlights(props: Props): React.ReactElement {
  const [sig, setSig] = useState<SyncedSignature>([])
  const [fieldHighlightsRef, setFieldHighlightsRef] = useState<HTMLElement[]>([])
  const [selectedIndex, setSelectedIndex] = useState<number[]>([])
  const [hightlightedIndex, setHighlightedIndex] = useState<number[]>([])
  const [selectedSubContainer, setSelectedSubContainer] = useState<number | null>(null)
  const [selectorDisabled, setSelectorDisabled] = useState<boolean>(false)
  const [popUpStartingPoint, setPopUpStartingPoint] = useState({ x: 0, y: 0 })
  const { classes } = useStyles()

  const [section, setSection] = useState<IngestPDFSection | null>(null)
  const [container, setContainer] = useState<Container | null>(null)
  const [subContainers, setSubContainers] = useState<Container[]>([])
  const [backdropRef, setBackdropRef] = useState<HTMLDivElement | null>(null)
  const [warningOpen, setWarningOpen] = useState<boolean>(false)

  const setRef = useCallback((ref) => {
    setBackdropRef(ref)
  }, [])
  // _____ HANDLERS _____

  /**
   * Calculates where the PopUp should be opended
   * @param containerCoords Coordinates of the connected Container
   */
  function calculateStartPositionPopUp(containerCoords: Coordinates): { x: number; y: number } {
    // For now very simple RuleSet
    if (containerCoords.y + containerCoords.h > 450) {
      if (containerCoords.y - 200 >= 0) {
        return { x: containerCoords.x, y: containerCoords.y - 200 }
      } else if (containerCoords.y - 100 >= 0) {
        return { x: containerCoords.x, y: containerCoords.y - 100 }
      } else {
        return { x: containerCoords.x, y: containerCoords.y }
      }
    } else {
      return { x: containerCoords.x, y: containerCoords.y + containerCoords.h + CONTAINER_PADDING }
    }
  }

  /**
   * Determines coordinates and dimension of box.
   * Returns sync (adjusted) coordinates and original coordinates in the PDF.
   * NOTE: For the sync coordinates (0,0) is top left, for the original coordinates (0,0) is bottom left!
   *       -> We have to adjust accordingly.
   * @param fields fields inside the box, used to determine box dimensions and coordinates
   * @param padding padding that is applied to box (increases box dimensions)
   */
  function getOuterBoxCoordinates(fields: Field[], padding = 0): SyncedCoordinates {
    let xSync = 0,
      ySync = 0,
      hSync = 0,
      wSync = 0,
      xOriginal = 0,
      yOriginal = 0,
      hOriginal = 0,
      wOriginal = 0

    for (let i = 0, l = fields.length; i < l; i += 1) {
      // Check x - search for smallest (left most point of section)
      if (i === 0) {
        xSync = fields[i].coords.sync.x
        xOriginal = fields[i].coords.original.x
      } else {
        if (fields[i].coords.sync.x < xSync) {
          xSync = fields[i].coords.sync.x
          xOriginal = fields[i].coords.original.x
        }
      }
      // Check y - search for smallest (= top most point of section)
      if (i === 0) {
        ySync = fields[i].coords.sync.y
        yOriginal = fields[i].coords.original.y + fields[i].coords.original.h // adjusted to switched coordinate system
      } else {
        if (fields[i].coords.sync.y < ySync) {
          ySync = fields[i].coords.sync.y
          yOriginal = fields[i].coords.original.y + fields[i].coords.original.h // adjusted to switched coordinate system
        }
      }

      // Check h - search for highest (height of section)
      // this is the yValue of the highest (= most bottom) point of the section
      if (i === 0) {
        // h+y ("highest" because 0,0 is top left)
        hSync = fields[i].coords.sync.y + fields[i].coords.sync.h
        hOriginal = fields[i].coords.original.y // no need to subtract height because in PDF ycoords are from bottom
      } else {
        if (fields[i].coords.sync.y + fields[i].coords.sync.h > hSync) {
          hSync = fields[i].coords.sync.y + fields[i].coords.sync.h
          hOriginal = fields[i].coords.original.y // no need to subtract height because in PDF ycoords are from bottom
        }
      }
      // Check w - search for widest point (width of section)
      if (i === 0) {
        wSync = fields[i].coords.sync.x + fields[i].coords.sync.w
        wOriginal = fields[i].coords.sync.x + fields[i].coords.original.w
      } else {
        if (fields[i].coords.sync.x + fields[i].coords.sync.w > wSync) {
          wSync = fields[i].coords.sync.x + fields[i].coords.sync.w
          wOriginal = fields[i].coords.original.x + fields[i].coords.original.w
        }
      }
    }

    // NOTE: padding is also added to original
    return {
      sync: { x: xSync - padding, y: ySync - padding, h: hSync - ySync + 2 * padding, w: wSync - xSync + 2 * padding },
      original: {
        x: xOriginal - padding,
        y: hOriginal - padding, // this is lowest point
        h: yOriginal - hOriginal + padding,
        w: wOriginal - xOriginal + padding,
      },
    }
  }

  function createContainerBox(
    container: {
      id: string
      box: {
        left: number
        top: number
        width: number
        height: number
      }
      selected: number[]
    },
    page: number,
  ): void {
    // check if a container should be created
    // filter disabled fields
    const _selected: number[] = []
    for (let i = 0, l = container.selected.length; i < l; i += 1) {
      if (sig[container.selected[i]].selection.enabled) {
        _selected.push(container.selected[i])
      }
    }
    // check if fields are selected
    if (_selected.length === 0) return

    // match selected indexes with fields and collect them
    const fields: Field[] = []
    for (let i = 0, l = _selected.length; i < l; i += 1) {
      fields.push(sig[_selected[i]])
    }

    // disable selected field
    const _sig = sig
    for (let i = 0, l = _selected.length; i < l; i += 1) {
      _sig[_selected[i]].selection.enabled = false
    }
    setSig(_sig)

    // set box coordinates based on the selected fields
    const syncedCoords = getOuterBoxCoordinates(fields, CONTAINER_PADDING)

    // define new container
    const _container: Container = {
      id: container.id,
      box: syncedCoords,
      page,
      selectedFields: fields,
      selectedHightlights: container.selected,
    }

    // Add new container
    const _containers = subContainers
    _containers.push(_container)

    // Open PopUp for new container
    // Define Start Position of PopUp
    setPopUpStartingPoint(calculateStartPositionPopUp(_container.box.sync))
    // minor delay so the user does not see a jump by the popUp
    setTimeout(() => {
      setSelectedSubContainer(_containers.length - 1)
      setSelectorDisabled(true)
    }, 50)

    setSubContainers(_containers)
  }

  function getContainerCoordinates(): { top: number; left: number; width: number; height: number; cursor: string } {
    const additionalPadding = 15
    if (section) {
      return {
        top: section.coords.sync.y >= additionalPadding ? section.coords.sync.y - additionalPadding : 0,
        left: section.coords.sync.x >= additionalPadding ? section.coords.sync.x - additionalPadding : 0,
        width: section.coords.sync.w + 2 * additionalPadding,
        height: section.coords.sync.h + 2 * additionalPadding,
        cursor: 'crosshair',
      }
    } else {
      return {
        top: 0,
        left: 0,
        width: 0,
        height: 0,
        cursor: 'crosshair',
      }
    }
  }

  /**
   * Add the field highlight div reference to the `fieldHighlightsRef` state
   * @param ref Reference of the field highlight div
   */
  function addFieldHighlightRef(ref: HTMLDivElement | null): void {
    const tmp = fieldHighlightsRef
    // console.log(ref?.getBoundingClientRect())
    if (ref !== null && tmp.indexOf(ref) === -1) {
      tmp.push(ref)
    }
    setFieldHighlightsRef(tmp)
  }

  /**
   * Returns the style of a field depending if selected, hover etc.
   * @param index Index of the field ref
   * @param coord Coordinates of the field
   */
  function getStyle(index: number, coord: Coordinates, enabled: boolean): object {
    if (!enabled) {
      return {
        top: coord.y,
        // eslint-disable-next-line react/prop-types
        left: coord.x,
        // eslint-disable-next-line react/prop-types
        width: coord.w,
        // eslint-disable-next-line react/prop-types
        height: coord.h,
        background: 'rgba(0, 0, 0, 0.3)',
        borderColor: '#2185d0',
        color: 'white',
      }
    }
    // eslint-disable-line no-undef
    if (selectedIndex.indexOf(index) > -1) {
      // Selected state
      return {
        // eslint-disable-next-line react/prop-types
        top: coord.y,
        // eslint-disable-next-line react/prop-types
        left: coord.x,
        // eslint-disable-next-line react/prop-types
        width: coord.w,
        // eslint-disable-next-line react/prop-types
        height: coord.h,
        background: 'rgba(255, 255, 0, 0.4)',
        borderColor: '#2185d0',
        color: 'white',
      }
    } else if (hightlightedIndex.indexOf(index) > -1) {
      // Hover state
      return {
        // eslint-disable-next-line react/prop-types
        top: coord.y,
        // eslint-disable-next-line react/prop-types
        left: coord.x,
        // eslint-disable-next-line react/prop-types
        width: coord.w,
        // eslint-disable-next-line react/prop-types
        height: coord.h,
        background: 'rgba(160, 160, 160, 0.4)',
        border: 'solid 5px red',
        color: 'white',
      }
    }
    return {
      // eslint-disable-next-line react/prop-types
      top: coord.y,
      // eslint-disable-next-line react/prop-types
      left: coord.x,
      // eslint-disable-next-line react/prop-types
      width: coord.w,
      // eslint-disable-next-line react/prop-types
      height: coord.h,
      background: 'rgba(255, 0, 0, 0.4)',
    }
  }

  /**
   * Returns the position of the save button. If it is to close to borders the position needs to be adapted
   */
  function getStyleSaveButton(): { right?: string; left?: string; top?: string; bottom?: string } {
    // access backDropRef and get width and height
    const pdfRect = props.target.getBoundingClientRect()
    const contRect = getContainerCoordinates()
    const FAB_RADIUS = 20
    const position: { right?: string; left?: string; top?: string; bottom?: string } = {}

    // Check sides
    if (contRect.left + contRect.width + FAB_RADIUS <= pdfRect.width) {
      // check right border
      position.right = 0 - FAB_RADIUS + 'px'
    } else if (contRect.left - FAB_RADIUS >= 0) {
      // check left border
      position.left = 0 - FAB_RADIUS + 'px'
    } else {
      // if no additional space on either side, place completely in backdrop
      position.right = '0px'
    }

    // Check top and bottom
    if (contRect.top + contRect.height + FAB_RADIUS <= pdfRect.height) {
      position.bottom = 0 - FAB_RADIUS + 'px'
    } else if (contRect.top - FAB_RADIUS >= 0) {
      position.top = 0 - FAB_RADIUS + 'px'
    } else {
      position.bottom = '0px'
    }
    return position
  }

  /**
   * Removes a subcontainer by index
   */
  function removeSubContainer(index?: number): void {
    // not using if(index) because 0 is falsy but a valid value for our logic
    if (typeof index === 'number' && index >= 0) {
      const _subContainers = subContainers

      // activate disabled fields again
      const _sig = sig
      const _selectedFields = _subContainers[index].selectedFields || []
      for (let i = 0, l = _selectedFields.length; i < l; i += 1) {
        // smarter way than double loop? - no time atm ~ Jakob 19.10.2020
        // remove from sig
        for (let j = 0, l = _sig.length; j < l; j += 1) {
          if (_sig[j].fieldName === _selectedFields[i].fieldName) {
            _sig[j].selection.enabled = true
          }
        }
      }
      setSig(_sig)

      // reset selected index - state can be replaced
      setSelectedIndex([])
      // reset highlighted index
      setHighlightedIndex([])

      _subContainers.splice(index, 1)
      setSubContainers(_subContainers)
    }
  }

  function returnToSections(): void {
    // reset selectedId
    if (typeof props.ingestPDFResult.selectedId !== 'undefined') {
      const result = props.ingestPDFResult
      result.removeSelectedId()
      props.setIngestPDFResult(result)
    }
    props.setStage('sections')
  }

  function runFinalChecks(): void {
    // Check if unset fields exists
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    if (hasUnsetFields(props.containerId)) {
      setWarningOpen(true)
      return
    }

    // change stage back to section
    returnToSections()
  }

  // _____ RESULT FILE MANAGEMENT _____

  function createSubsection(
    id: string,
    coordinates: SyncedCoordinates,
    fields: string[],
    page: number,
    parentsections: string[],
    name: string,
  ): void {
    const subsection = new IngestPDFSubSection(id, coordinates, fields, page, parentsections, name)
    const result = props.ingestPDFResult
    result.addSubSection(subsection)
    props.setIngestPDFResult(result)
  }

  function updateSubsection(id: string, newName: string): void {
    // TODO: will props be updated in the function as well?
    const result = props.ingestPDFResult
    const subsection = result.getSubSection(id)
    if (subsection) {
      subsection.setName(newName)
      result.updateSubSection(subsection)
      props.setIngestPDFResult(result)
    } else {
      console.warn(`No subsection found with the id: ${id}`)
    }
  }

  function removeSubsection(id: string): void {
    const result = props.ingestPDFResult
    result.removeSubSection(id)
    props.setIngestPDFResult(result)
  }

  function isSubsection(id: string): boolean {
    const result = props.ingestPDFResult
    const subSection = result.getSubSection(id)
    if (subSection) {
      return true
    } else {
      return false
    }
  }

  // initialize state and create already existing subsections
  function init(signature: Field[]): void {
    // get section to create subsections
    const result = props.ingestPDFResult
    const _section = result.getSection(props.containerId)
    const selectedFields: string[] = []
    if (_section) {
      const _subContainers = subContainers
      for (let i = 0, l = _section.subsections.length; i < l; i += 1) {
        const subsection = result.getSubSection(_section.subsections[i])
        // if a subsection exist
        if (subsection) {
          // collect fieldIndizes from the signature matching to a fields fieldName in result
          const fieldIndizes: Field[] = []
          signature.forEach((field) => {
            if (subsection.fields.includes(field.fieldName)) {
              // NOTE: using fieldName as identifier in subsection.fields
              fieldIndizes.push(field)
              selectedFields.push(field.fieldName)
            }
          })
          // define new container
          const _container: Container = {
            id: subsection.id,
            box: subsection?.coords,
            page: subsection?.page,
            selectedFields: fieldIndizes,
            selectedHightlights: [],
          }

          let index = -1
          for (let i = 0; i < _subContainers.length; i += 1) {
            if (_subContainers[i].id === _container.id) {
              index = i
            }
          }
          if (index >= 0) {
            // replace existing container
            _subContainers[index] = _container
          } else {
            // Add new container
            _subContainers.push(_container)
          }
        }
      }
      setSubContainers(_subContainers)

      // set container
      if (props.containers.some((container) => container.id === _section.id)) {
        const container = props.containers.find((container) => {
          return container.id === _section.id
        })
        if (container) setContainer(container)
      }
    }

    const _sig = signature
    for (let i = 0, l = _sig.length; i < l; i += 1) {
      if (selectedFields.includes(_sig[i].fieldName)) {
        _sig[i].selection.enabled = false
      }
    }
    setSig(_sig)

    // set section
    setSection(_section)
  }

  /**
   * Filters out all fields that are not in the container
   */
  function calculateSignatureForSubsection(syncedSignature: Field[]): Field[] {
    const _sig: Field[] = []
    if (syncedSignature && syncedSignature.length > 0) {
      for (let i = 0; i < syncedSignature.length; i += 1) {
        if (
          container &&
          container.selectedFields &&
          container.selectedFields.filter(
            (selectedField: Field) => selectedField.fieldName === syncedSignature[i].fieldName,
          ).length > 0
        ) {
          const _field = syncedSignature[i]
          _field.selection.enabled = true
          _sig.push(_field)
        }
      }
    }
    return _sig
  }

  function getName(id: string): string {
    const result = props.ingestPDFResult
    const subsection = result.getSubSection(id)
    if (subsection) return subsection.name
    else return ''
  }

  function hasUnsetFields(id: string): boolean {
    const _sig = sig
    const result = props.ingestPDFResult
    const subSections = result.getSectionsSubsetions(id)
    const setFields = subSections
      ? subSections.flatMap((subsection) => {
          return subsection.fields
        })
      : []
    let hasUnsetField = false
    _sig.forEach((field) => {
      if (!setFields.includes(field.id)) {
        hasUnsetField = true
      }
    })
    return hasUnsetField
  }

  // _____ EFFECTS ______

  useEffect(() => {
    // TODO: if section gets deselected => check for unsetFields and show warning
    // Deselect event: if selectedId is undefined
    if (typeof props.ingestPDFResult.selectedId === 'undefined') {
      // close subsection
      props.setStage('sections')
    } else {
      // Check if selectedId is not parent container - only then action is required
      if (props.ingestPDFResult.selectedId !== props.containerId) {
        props.setStage('sections')
      } else {
        // Basically should not be the case -> means somewhere selectedId and containerId are async
        console.warn('[Ingest][SubsectionHighlight] selectedId and containerId are possible async.')
      }
    }
  }, [props.ingestPDFResult.selectedId])

  // Prepare subsection signature when syncedSignature changes
  useEffect(() => {
    init(calculateSignatureForSubsection(props.syncedSignature))
  }, [props.syncedSignature, container])

  // _____ RENDER _____

  function renderFields(): React.ReactElement {
    return (
      <Fragment>
        {/* TODO: onclick create subsection */}
        {sig
          // eslint-disable-next-line react/prop-types
          .filter((field) => field.page === props.pageNumber)
          .map((field, index) => {
            return (
              <div
                id={`field_highlight_${index}`}
                key={index}
                ref={addFieldHighlightRef}
                className={classes.fieldHightlight}
                style={getStyle(
                  index,
                  {
                    y: field.coords.sync.y,
                    x: field.coords.sync.x,
                    w: field.coords.sync.w,
                    h: field.coords.sync.h,
                  },
                  field.selection.enabled,
                )}
              />
            )
          })}
      </Fragment>
    )
  }

  function renderContainers(): React.ReactElement {
    return (
      <Fragment>
        {/* Display overlay containers */}
        {
          // TODO: show warning wenn kein Name gesetzt und nicht ausgewählt
          // TODO: show subsection name
          // eslint-disable-next-line react/prop-types
          subContainers
            // eslint-disable-next-line react/prop-types
            .filter((container) => container.page === props.pageNumber)
            .map((container, index) => {
              return (
                <div
                  id={`container_highlight_${index}`}
                  key={container.id}
                  className={classes.containerHighlight}
                  style={{
                    top: container.box.sync.y,
                    left: container.box.sync.x,
                    width: container.box.sync.w,
                    height: container.box.sync.h,
                  }}
                  onClick={(): void => {
                    if (typeof selectedSubContainer === 'number' && index === selectedSubContainer) {
                      setPopUpStartingPoint({ x: 0, y: 0 })
                      // check if the visual selectedContainer needs to be removed
                      // eslint-disable-next-line @typescript-eslint/no-use-before-define
                      // eslint-disable-next-line react/prop-types
                      if (typeof selectedSubContainer === 'number' && !isSubsection(container.id)) {
                        // remove visual container
                        removeSubContainer(selectedSubContainer)
                      }
                      setSelectedSubContainer(null)
                      setSelectorDisabled(false)
                    } else {
                      setPopUpStartingPoint(calculateStartPositionPopUp(container.box.sync))
                      setTimeout(() => {
                        setSelectedSubContainer(index)
                        setSelectorDisabled(true)
                      }, 50)
                    }
                  }}
                >
                  <p className={classes.containerText}>
                    {
                      // eslint-disable-next-line react/prop-types
                      props.ingestPDFResult.getSubSection(container.id)?.name
                    }
                  </p>
                </div>
              )
            })
        }
      </Fragment>
    )
  }

  return (
    <Fragment>
      <div id='fields'>{renderFields()}</div>
      <div id='backdrop' style={getContainerCoordinates()} className={classes.backdrop} ref={setRef}>
        <Fab
          size='small'
          color='primary'
          aria-label='save'
          onClick={(): void => {
            runFinalChecks()
          }}
          className={classes.saveButton}
          style={getStyleSaveButton()}
        >
          <SaveIcon />
        </Fab>
        <p className={classes.parentText}>{section?.name}</p>
        {backdropRef && (
          <div id='selection'>
            <Selection
              target={backdropRef}
              onDraw={(e: unknown): void => {
                // eslint-disable-next-line no-undef
                process.env.REACT_APP_BRANCH === 'development' && console.info(e)
              }}
              returnBox={(e: {
                id: string
                box: {
                  left: number
                  top: number
                  width: number
                  height: number
                }
                selected: number[]
              }): void => createContainerBox(e, props.pageNumber)}
              elements={fieldHighlightsRef}
              disabled={selectorDisabled}
              onSelectionChange={(indexes: number[]): void => setSelectedIndex(indexes)}
              onHighlightChange={(indexes: number[]): void => setHighlightedIndex(indexes)}
            />
          </div>
        )}
      </div>
      <div id='subContainers'>{renderContainers()}</div>
      <div id='popups'>
        {selectedSubContainer !== null &&
          (isSubsection(subContainers[selectedSubContainer].id) ? (
            <SelectionPopup
              parent={props.target}
              startPosition={popUpStartingPoint}
              selectionName={getName(subContainers[selectedSubContainer].id)}
              title={'Sub-Bereich'} // TODO: what is the title supposed to be? - I18n
              onConfirm={(name: string): void => {
                setPopUpStartingPoint({ x: 0, y: 0 })
                setSelectedSubContainer(null)
                setSelectorDisabled(false)
                // Update Result
                updateSubsection(subContainers[selectedSubContainer].id, name)
              }}
              onClose={(): void => {
                // reset starting point
                setPopUpStartingPoint({ x: 0, y: 0 })
                // NOTE: if a subsection is in the result, closing the popup does nothing, but also changes will not be done to the result
                setSelectedSubContainer(null)
                setSelectorDisabled(false)
              }}
              onDelete={(): void => {
                // remove from result
                removeSubsection(subContainers[selectedSubContainer].id)
                setPopUpStartingPoint({ x: 0, y: 0 })
                // remove visual container
                removeSubContainer(selectedSubContainer)
                setSelectedSubContainer(null)
                setSelectorDisabled(false)
              }}
            />
          ) : (
            <SelectionPopup
              parent={props.target}
              startPosition={popUpStartingPoint}
              title={'Sub-Bereich'} // TODO: what is the title supposed to be? - I18n
              onConfirm={(name: string): void => {
                setPopUpStartingPoint({ x: 0, y: 0 })
                setSelectedSubContainer(null)
                setSelectorDisabled(false)
                // Create result
                const subsection = subContainers[selectedSubContainer]
                const fields = subsection.selectedFields
                  ? subsection.selectedFields.map(function (field) {
                      return field.id
                    })
                  : []
                // TODO: support several parent sections?
                createSubsection(subsection.id, subsection.box, fields, props.pageNumber, [props.containerId], name)
              }}
              onClose={(): void => {
                // reset starting point
                setPopUpStartingPoint({ x: 0, y: 0 })
                // NOTE: remove container when closing? nope - only on delete. Test with this may change later on ~ Jakob 19.10.2020
                // remove visual container
                removeSubContainer(selectedSubContainer)
                setSelectedSubContainer(null)
                setSelectorDisabled(false)
              }}
            />
          ))}
      </div>
      <FieldsDialog
        open={warningOpen}
        onCancle={(): void => setWarningOpen(false)}
        onConfirm={(): void => returnToSections()}
      />
    </Fragment>
  )
}

SubSectionHightlights.defaultProps = {}

export default SubSectionHightlights
