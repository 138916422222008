import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'

// Material Ui
import { makeStyles } from 'tss-react/mui'

import { Grid, Typography, IconButton, Chip } from '@mui/material'
// Custom Components
import Button from 'components/Buttons/Button'
import { DataSeries } from 'components/Charts/Linechart/TimeseriesLinechart'
import {
  formatAnsweredQuestionRatioChartTooltipValue,
  formatHelpfulAnswerRatioChartTooltipValue,
} from './tooltipFormatter'
import Table from 'components/Table/Table'
import { ContentPageHeader } from 'components/Page/ContentPage'
import BaseCard from 'components/Cards/BaseCard'
import CustomizedTooltip from 'components/Tooltips/CustomContentTooltip'
import Dialog from 'components/Dialogs/Dialog'
import AnalyticsChart from './AnalyticsChart'
// Assets
import Happy from 'assets/img/knowledge/smileys/happy'
import Sad from 'assets/img/knowledge/smileys/sad'
import Neutral from 'assets/img/knowledge/smileys/neutral'
import Comment from 'assets/img/knowledge/icons/comment'
// APIs
import { getAnalytics, getAnswers, getFlow } from 'api/StudioBackend'
// Types
import {
  Scope,
  Granularity,
  Language,
  GetAnalyticsResponse,
  GetAnalyticsRequest,
  ChartName,
  Timespan,
  ChartType,
  DetailedCountResult,
  MostAskedTopicsResults,
  MostAskedTopic,
  LanguageCountResult,
  TitleValue,
  DetailedCountResultPerTime,
  XzufiMostAskedTopic,
  XzufiAskedTopic,
  Stat,
  AlephAlphaInquiry,
  WordcloudData,
} from '../../@types/Analytics/types'
import { CustomAnalyticsEvent } from '../../@types/Flowchart/types'
import { AlephAlphaModule, XzufiModule } from '../../@types/BotInformation/types'
import { getEuropeanDateTimeString, subtractDaysFromDate } from 'utils/dateUtils'
import {
  ROUTE_BOTS,
  ROUTE_BOTID_KNOWLEDGE,
  ROUTE_BOTID_KNOWLEDGE_NLU_CONVMANAGEMENT,
  ROUTE_BOTID_DESIGNER,
  ROUTE_BOTID_DESIGNER_NODESEARCH,
  MODULE_TYPE_XZUFI,
  MODULE_TYPE_NLU,
  MODULE_TYPE_ALEPHALPHA,
  MODULE_TYPE_RAG,
} from 'utils/constants'
import { Answer, buildAnswerObject } from 'classes/Knowledge'
import { formatNumberWithThousandsSeparator } from 'utils/stringUtils'
import { useBotContext } from 'hooks/contexts/bot-context'
import { transformEventName } from 'utils/customAnalyticsEventUtils'
import AnalyticsStat from './AnalyticsStat'
import { prepareInquiriesOutsideOpeningHoursScopeStatChart } from './utils'
import WordCloud from 'components/Charts/Wordcloud/Wordcloud'
import { timeStamp } from 'console'

const useStyles = makeStyles()((theme) => ({
  headerContainer: {
    marginTop: '75px',
    marginBottom: '50px',
    width: '100%',
  },
  headerTitleContainer: {
    display: 'flex',
  },
  title: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontStyle: 'normal',
    fontSize: '48px',
    lineHeight: '56.25px',
    letterSpacing: -0.045,
  },
  refreshButton: {
    marginLeft: 'auto',
  },
  markdown: {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical',
    '& p': {
      margin: 0,
    },
  },
  icon: { fontSize: '1.2rem' },
  iconButton: {
    marginLeft: theme.spacing(1),
    marginBottom: 'auto',
    marginTop: 'auto',
    padding: theme.spacing(1),
    color: theme.palette.common.white,
  },
  iconButtonStandard: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    '&:disabled': {
      backgroundColor: theme.palette.grey[400],
      color: theme.palette.common.white,
    },
  },
  pointer: {
    cursor: 'pointer',
  },
  emojiml: {
    marginLeft: 'auto',
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
  },
  moduleHeader: {
    marginTop: theme.spacing(4),
  },
  chipId: {
    marginLeft: theme.spacing(2),
  },
}))

// ========== CONSTANTS & MAPPINGS ===========

// this color palette is inspired from https://venngage.com/blog/color-blind-friendly-palette/ to also work for color-blindness
const COLOR_PALETTE = ['#003454', '#F5793A', '#A95AA1', '#85C0F9', '#EB5E71', '#1CA678', '#8591A1']

/**
 * maps each scope to an individual color
 * scopes used for tables need not color
 */
const SCOPE_COLOR_MAPPING: { [scope in Scope]?: string } = {
  // chart 1 - message
  all_messages: COLOR_PALETTE[0],
  user_messages: COLOR_PALETTE[1],
  bot_messages: COLOR_PALETTE[2],
  answered_questions: COLOR_PALETTE[4],
  unanswered_questions: COLOR_PALETTE[5],
  // chart 2 - inquiries
  all_inquiries: COLOR_PALETTE[0],
  all_questions: COLOR_PALETTE[1],
  subdialog_started: COLOR_PALETTE[2],
  // chart 3 - conversations
  dialog_started: COLOR_PALETTE[0],
  docs_generated: COLOR_PALETTE[2],
  // chart 4 - messages per conversation
  messages_per_conversation: COLOR_PALETTE[0],
  // chart 5 - answered questions ratio
  answered_question_ratio: COLOR_PALETTE[0],
  // chart 6 - helpful answer ratio
  helpful_answer_ratio: COLOR_PALETTE[0],
  // unused atm
  all_dialogs: '#bd4378',
  dialog_completed: '#25345a',
  helpful_answers: '#533e73',
  unhelpful_answers: '#bd4378',
  xzufi_answered_question_ratio: COLOR_PALETTE[0],
  xzufi_helpful_answer_ratio: COLOR_PALETTE[0],
  xzufi_all_questions: COLOR_PALETTE[0],
  // scopes used for tables need not color
}

export const SCOPE_NAME_MAPPING: { [scope in Scope]: string } = {
  all_messages: 'Alle Nachrichten',
  user_messages: 'Nutzer',
  bot_messages: 'Assistent',
  all_inquiries: 'Alle Anfragen',
  inquiries_outside_operating_hours: 'Anfragen außerhalb Betriebszeiten',
  user_messages_wordcloud: 'Nutzernachrichten',
  docs_generated: 'Generierte Dokumente',
  all_dialogs: '',
  all_questions: 'Fragen',
  answered_questions: 'Beantwortete Fragen',
  unanswered_questions: 'Unbeantwortete Fragen',
  dialog_started: 'Konversationen',
  dialog_completed: '',
  subdialog_started: 'Gestartete Prozesse',
  helpful_answers: 'Hilfreiche Antworten',
  unhelpful_answers: 'Nicht hilfreiche Antworten',
  messages_per_conversation: 'Nachrichten pro Konversation',
  answered_question_ratio: 'Anteil beantworteter Fragen',
  helpful_answer_ratio: 'Anteil hilfreicher Fragen',
  most_asked_topics: 'Meistgefragte Themen',
  xzufi_most_asked_topics: 'Meistgefragte Leistungen',
  xzufi_asked_questions: 'Anfragen',
  xzufi_answered_question_ratio: 'Anteil beantworteter Anfragen',
  xzufi_helpful_answer_ratio: 'Anteil hilfreicher Ergebnisse',
  xzufi_all_questions: 'Anfragen',
  // Aleph Alpha Modul
  aleph_alpha_queries_all: 'Anfragen',
  aleph_alpha_queries_voice: 'Voice-Anfragen',
  aleph_alpha_queries_text: 'Text-Anfragen',
  aleph_alpha_queries_voice_ratio: 'Anteil Voice',
  aleph_alpha_queries_answered: 'Beantwortete Anfragen',
  aleph_alpha_queries_unanswered: 'Unbeantwortete Anfragen',
  aleph_alpha_queries_answered_ratio: 'Anteil beantworteter Anfragen',
  aleph_alpha_feedback_positive: 'Positives Feedback',
  aleph_alpha_feedback_negative: 'Negatives Feedback',
  aleph_alpha_feedback_positive_ratio: 'Anteil positives Feedback',
  aleph_alpha_sessions: 'Sitzungen',
  aleph_alpha_session_duration_average: 'Durschnittliche Sitzungslänge',
  aleph_alpha_session_questions_per_session: 'Fragen pro Sitzung',
  aleph_alpha_initial_inquiries: 'Nutzerfragen',
}

// defines names for charts; are used as chart ids
const CHART_NAMES_MAPPING: { [key: string]: ChartName } = {
  messageLinechart: 'message_linechart',
  usageHeatmap: 'usage_heatmap',
  conversationLinechart: 'conversation_linechart',
  messagesPerConversationLinechart: 'messages_per_conversation_linechart',
  answeredQuestionsRatioLinechart: 'answered_question_ratio_linechart',
  helpfulAnswerRatioLinechart: 'helpful_answer_ratio_linechart',
  inquiriesLinechart: 'inquiries_linechart',
  mostUsedAndHelpfulTopicsMindMap: 'topics_mindmap',
  customEventsLinechart: 'custom_events_linechart',
  inquiriesOpeningHoursStat: 'inquiries_opening_hours_stat',
  userMessagesWordcloud: 'user_messages_wordcloud',
  xzufiAnsweredQuestionsRatioLinechart: 'xzufi_answered_question_ratio_linechart',
  xzufiHelpfulAnswerRatioLinechart: 'xzufi_helpful_answer_ratio_linechart',
  xzufiMostAskedTopicTable: 'xzufi_most_asked_topics_table',
  xzufiQueriesTable: 'xzufi_queries_table',
  xzufiQueriesLinechart: 'xzufi_queries_linechart',
  alephAlphaQueriesStat: 'aleph_alpha_queries_stat',
  alephAlphaAnswersRatioStat: 'aleph_alpha_answer_ratio_stat',
  alephAlphaFeedbackRatioStat: 'aleph_alpha_feedback_ratio_stat',
  alephAlphaSessionStat: 'aleph_alpha_sessions_stat',
  alephAlphaInquiriesTable: 'aleph_alpha_inquires_table',
}

// defines type of charts
const CHART_TYPE_MAPPING: { [name in ChartName]: ChartType } = {
  message_linechart: 'linechart',
  usage_heatmap: 'heatmap',
  conversation_linechart: 'linechart',
  messages_per_conversation_linechart: 'linechart',
  answered_question_ratio_linechart: 'linechart',
  helpful_answer_ratio_linechart: 'linechart',
  inquiries_linechart: 'linechart',
  inquiries_opening_hours_stat: 'stat',
  user_messages_wordcloud: 'wordcloud',
  topics_mindmap: 'table',
  custom_events_linechart: 'linechart',
  xzufi_answered_question_ratio_linechart: 'linechart',
  xzufi_helpful_answer_ratio_linechart: 'linechart',
  xzufi_most_asked_topics_table: 'table',
  xzufi_queries_table: 'table',
  xzufi_queries_linechart: 'linechart',
  aleph_alpha_answer_ratio_stat: 'stat',
  aleph_alpha_feedback_ratio_stat: 'stat',
  aleph_alpha_queries_stat: 'stat',
  aleph_alpha_sessions_stat: 'stat',
  aleph_alpha_inquires_table: 'table',
}

// defines which scopes each chart can display
const CHART_SCOPE_MAPPING: { [chart: string]: Scope[] } = {
  [CHART_NAMES_MAPPING.messageLinechart]: ['all_messages', 'user_messages', 'bot_messages'],
  [CHART_NAMES_MAPPING.usageHeatmap]: ['dialog_started'],
  [CHART_NAMES_MAPPING.conversationLinechart]: ['dialog_started', 'docs_generated'],
  [CHART_NAMES_MAPPING.messagesPerConversationLinechart]: ['messages_per_conversation'],
  [CHART_NAMES_MAPPING.answeredQuestionsRatioLinechart]: ['answered_question_ratio'],
  [CHART_NAMES_MAPPING.inquiriesLinechart]: ['all_inquiries', 'all_questions', 'subdialog_started'],
  [CHART_NAMES_MAPPING.inquiriesOpeningHoursStat]: ['inquiries_outside_operating_hours'],
  [CHART_NAMES_MAPPING.userMessagesWordcloud]: ['user_messages_wordcloud'],
  [CHART_NAMES_MAPPING.helpfulAnswerRatioLinechart]: ['helpful_answer_ratio'],
  [CHART_NAMES_MAPPING.mostUsedAndHelpfulTopicsMindMap]: ['most_asked_topics'],
  [CHART_NAMES_MAPPING.xzufiAnsweredQuestionsRatioLinechart]: ['xzufi_answered_question_ratio'],
  [CHART_NAMES_MAPPING.xzufiHelpfulAnswerRatioLinechart]: ['xzufi_helpful_answer_ratio'],
  [CHART_NAMES_MAPPING.xzufiMostAskedTopicTable]: ['xzufi_most_asked_topics'],
  [CHART_NAMES_MAPPING.xzufiQueriesTable]: ['xzufi_asked_questions'],
  [CHART_NAMES_MAPPING.xzufiQueriesLinechart]: ['xzufi_all_questions'],
  [CHART_NAMES_MAPPING.alephAlphaQueriesStat]: [
    'aleph_alpha_queries_all',
    'aleph_alpha_queries_voice',
    'aleph_alpha_queries_text',
    'aleph_alpha_queries_voice_ratio',
  ],
  [CHART_NAMES_MAPPING.alephAlphaAnswersRatioStat]: [
    'aleph_alpha_queries_answered',
    'aleph_alpha_queries_unanswered',
    'aleph_alpha_queries_answered_ratio',
  ],
  [CHART_NAMES_MAPPING.alephAlphaFeedbackRatioStat]: [
    'aleph_alpha_feedback_positive',
    'aleph_alpha_feedback_negative',
    'aleph_alpha_feedback_positive_ratio',
  ],
  [CHART_NAMES_MAPPING.alephAlphaSessionStat]: [
    'aleph_alpha_sessions',
    'aleph_alpha_session_duration_average',
    'aleph_alpha_session_questions_per_session',
  ],
  [CHART_NAMES_MAPPING.alephAlphaInquiriesTable]: ['aleph_alpha_initial_inquiries'],
}

// defines which title char (e.g. "#" or "%" a scope has)
const SCOPE_TITLE_CHAR_MAPPING: {
  [scope in Scope]: {
    titleValueChar: string
    switchTitleValueAndTitleValueChar: boolean
  }
} = {
  inquiries_outside_operating_hours: { titleValueChar: '%', switchTitleValueAndTitleValueChar: true },
  aleph_alpha_queries_all: { titleValueChar: '#', switchTitleValueAndTitleValueChar: false },
  aleph_alpha_queries_voice: { titleValueChar: '#', switchTitleValueAndTitleValueChar: false },
  aleph_alpha_queries_text: { titleValueChar: '#', switchTitleValueAndTitleValueChar: false },
  aleph_alpha_queries_voice_ratio: { titleValueChar: '%', switchTitleValueAndTitleValueChar: true },
  aleph_alpha_queries_answered: { titleValueChar: '#', switchTitleValueAndTitleValueChar: false },
  aleph_alpha_queries_unanswered: { titleValueChar: '#', switchTitleValueAndTitleValueChar: false },
  aleph_alpha_queries_answered_ratio: { titleValueChar: '%', switchTitleValueAndTitleValueChar: true },
  aleph_alpha_feedback_positive: { titleValueChar: '#', switchTitleValueAndTitleValueChar: false },
  aleph_alpha_feedback_negative: { titleValueChar: '#', switchTitleValueAndTitleValueChar: false },
  aleph_alpha_feedback_positive_ratio: { titleValueChar: '%', switchTitleValueAndTitleValueChar: true },
  aleph_alpha_session_duration_average: { titleValueChar: 'Min.', switchTitleValueAndTitleValueChar: true },
  aleph_alpha_session_questions_per_session: { titleValueChar: '#', switchTitleValueAndTitleValueChar: false },
}

// ========= TYPES ===========

type ChartData<T> = {
  xValues: T
  ySeries: DataSeries[]
  scopes: Scope[]
  detailLevel: Granularity
  titleValue: TitleValue
}

type HeatmapData = {
  data: DetailedCountResultPerTime
  titleValue: TitleValue
}

// ===== TYPES HELPER =====
const isTypeChart = (x: any): x is LanguageCountResult => {
  if (typeof x.total === 'undefined') return false
  return true
}

const getEmptyChartData = (): ChartData<Date[]> => {
  return {
    xValues: [],
    ySeries: [],
    scopes: [],
    detailLevel: 'day',
    titleValue: { primaryValue: '-' },
  }
}

/**
 * @since 0.2.12
 *On initial render: Fetches analytics data for the last 7 days with granularity "day" for all scopes
 * FIXME NOTE: xzufi analytics only work for the first xzufi module found - because local chart naming/identifying and data storage for analytics is static atm but needs to be dynamic do deal with more than one xzufi module analytics
 */
export default function Insights(): React.ReactElement {
  const isInitializedRef = useRef<boolean>(false) // only used for dev mode (as react 18 runs side effects twice - we do not want to load data twice)
  const { classes } = useStyles()
  const { botId } = useParams() as { botId: string }
  const { bot, hasModule } = useBotContext()
  // find module config id of NLU module
  const modules = Object.values(bot?.modules ?? {})
  let nluModuleConfigId
  if (
    Object.values(bot?.modules ?? {})
      .map((module) => module.type)
      .includes(MODULE_TYPE_NLU)
  ) {
    // NLU knowledge
    nluModuleConfigId = modules.find((module) => module.type === MODULE_TYPE_NLU)?.moduleConfigId
  }
  const navigate = useNavigate()
  const initialLoadStartedRef = useRef(false)
  const [shouldReload, setShoudReload] = useState<boolean>()
  // custom timespan suggestion
  const [customTimespanSuggestion, setCustomTimespanSuggestion] = useState<{ fromDate: Date; toDate: Date }>()

  // ---- ANALYTICS DATA
  const [usageHeatmapData, setUsageHeatmapData] = useState<HeatmapData>({ data: {}, titleValue: { primaryValue: '-' } })
  const [messageLinechartData, setMessageLinechartData] = useState<ChartData<Date[]>>({
    xValues: [],
    ySeries: [],
    scopes: [],
    detailLevel: 'day',
    titleValue: { primaryValue: '0' },
  }) // data for message statistics
  const [conversationLinechartData, setConversationLinechartData] = useState<ChartData<Date[]>>(getEmptyChartData()) // data for conversation statistics
  const [messagePerConversationLinechartData, setMessagePerConversationLinechartData] = useState<ChartData<Date[]>>(
    getEmptyChartData(),
  )
  const [inquiriesLinechartData, setInquiriesLinechartData] = useState<ChartData<Date[]>>(getEmptyChartData()) // data for inquiries linechart
  const [inquiriesOpeningHoursStatData, setInquiriesOpeningHoursStatData] = useState<Stat[]>([]) // first stat is main stat
  const [userMessagesWordcloudData, setIsUserMessagesWordCloudData] = useState<WordcloudData>({})

  // custom events
  const [customAnalyticsEvents, setCustomAnalyticsEvents] = useState<{ [eventId: string]: CustomAnalyticsEvent }>({})
  const customEventsScopesRef = useRef<string[]>()
  const [customEventsLinechartData, setCustomEventsLinechartData] = useState<ChartData<Date[]>>({
    xValues: [],
    ySeries: [],
    scopes: [],
    detailLevel: 'day',
    titleValue: { primaryValue: '0' },
  })
  const [customAnalyticsEventsDialogOpen, setCustomAnalyticsEventsDialogOpen] = useState<boolean>(false)

  // NLU Module
  const [answeredQuestionRatioLinechartData, setAnsweredQuestionRatioLinechartData] = useState<ChartData<Date[]>>(
    getEmptyChartData(),
  )
  const [helpfulAnswerRatioLinechartData, setHelpfulAnswerRatioLinechartData] = useState<ChartData<Date[]>>(
    getEmptyChartData(),
  )
  const [mostUsedAndHelpfulMindMapData, setMostUsedAndHelpfulMindMapData] = useState<MostAskedTopic[]>([])
  const [mostUsedAndHelpfulDialogOpen, setMostUsedAndHelpfulDialogOpen] = useState<boolean>(false)
  const [mostUsedAndHelpfulDialogIndex, setMostUsedAndHelpfulDialogIndex] = useState<number>()
  const [answers, setAnswers] = useState<Answer[]>()

  // XZuFi Module -> see fixme note of component
  const [xzufiAnsweredQuestionRatioLinechartData, setXzufiAnsweredQuestionRatioLinechartData] = useState<
    ChartData<Date[]>
  >(getEmptyChartData())
  const [xzufiHelpfulAnswerRatioLinechartData, setXzufiHelpfulAnswerRatioLinechartData] = useState<ChartData<Date[]>>(
    getEmptyChartData(),
  )
  const [xzufiQueriesLinechartData, setXzufiQueriesLinechartData] = useState<ChartData<Date[]>>(getEmptyChartData())
  const [xzufiMostAskedTopicsData, setXzufiMostAskedTopicsData] = useState<XzufiMostAskedTopic[]>([])
  const [xzufiAskedTopicsData, setXzufiAskedTopicsData] = useState<XzufiAskedTopic[]>([])

  // Aleph Alpha Module
  const [alephAlphaQueriesStatData, setAlephAlphaQueriesStatData] = useState<Stat[]>([]) // first stat is main stat
  const [alephAlphaAnswerRatioStatData, setIsAlephAlphaAnswerRatioStatData] = useState<Stat[]>([]) // first stat is main stat
  const [alephAlphaFeedbackRatioStatData, setAlephAlphaFeedbackRatioStatData] = useState<Stat[]>([]) // first stat is main stat
  const [alephAlphaSessionsStatData, setAlephAlphaSessionsStatData] = useState<Stat[]>([]) // first stat is main stat
  const [alephAlphaInquiriesData, setAlephAlphaInquiriesData] = useState<AlephAlphaInquiry[]>([]) // user inquiries

  // ----- loading states
  const [isUsageHeatmapLoading, setIsUsageHeatmapLoading] = useState<boolean>()
  const [isMessageLinechartLoading, setIsMessageLinechartLoading] = useState<boolean>()
  const [isConversationLinechartLoading, setIsConversationLinechartLoading] = useState<boolean>()
  const [isMessagePerConversationLinechartLoading, setIsMessagePerConversationLinechartLoading] = useState<boolean>()
  const [isAnsweredQuestionRatioLinechartLoading, setIsAnsweredQuestionRatioLinechartLoading] = useState<boolean>()
  const [isHelpfulAnswerRatioLinechartLoading, setIsHelpfulAnswerRatioLinechartLoading] = useState<boolean>()
  const [isMostUsedAndHelpfulMindMapLoading, setMostUsedAndHelpfulMindMapLoading] = useState<boolean>()
  const [isCustomEventsLinechartLoading, setIsCustomEventsLinechartLoading] = useState<boolean>()
  const [isInquiriesLinechartLoading, setIsInquiriesLinecharLoading] = useState<boolean>()
  const [isInquiriesOpeningHoursStatLoading, setIsInquireiesOpeningHoursStatLoading] = useState<boolean>()
  const [isUserMessagesWordcloudLoading, setIsUserMessagesWordCloudLoading] = useState<boolean>()
  // XZuFi -> see fixme note of component
  const [isXzufiAnsweredQuestionRatioLinechartLoading, setIsXzufiAnsweredQuestionRatioLinechartLoading] =
    useState<boolean>()
  const [isXzufiHelpfulAnswerRatioLinechartLoading, setIsXzufiHelpfulAnswerRatioLinechartLoading] = useState<boolean>()
  const [isXzufiQueriesLinechartLoading, setIsXzufiQueriesLinechartLoading] = useState<boolean>()
  const [isXzufiMostAskedTopicsLoading, setIsXzufiMostAskedTopicsLoading] = useState<boolean>()
  const [isXzufiAskedTopicsLoading, setIsXzufiAskedTopicsLoading] = useState<boolean>()
  // Aleph Alpha module
  const [isAlephAlphaAnswerRatioStatLoading, setIsAlephAlphaAnswerRatioStatLoading] = useState<boolean>()
  const [isAlephAlphaFeedbackRatioStatLoading, setIsAlephAlphaFeedbackRatioStatLoading] = useState<boolean>()
  const [isAlephAlphaQueriesStatLoading, setIsAlephAlphaQueriesStatLoading] = useState<boolean>()
  const [isAlephAlphaSessionsStatLoading, setIsAlephAlphaSessionsStatLoading] = useState<boolean>()
  const [isAlephAlphaInquiriesLoading, setIsAlephAlphaInquiriesLoading] = useState<boolean>()
  /** ROUTING FUNCTIONS */

  function openAnswer(answerId?: string): void {
    if (answerId && typeof answerId === 'string' && answerId.length > 0) {
      navigate(
        `${ROUTE_BOTS}/${botId}${ROUTE_BOTID_KNOWLEDGE}/${nluModuleConfigId}${ROUTE_BOTID_KNOWLEDGE_NLU_CONVMANAGEMENT}/${answerId}?origin=analytics`,
      )
    }
  }

  /**
   * Opens the block search
   * @param eventName EventName
   */
  function openCustomEvent(eventName: string): void {
    navigate(
      `${ROUTE_BOTS}/${botId}${ROUTE_BOTID_DESIGNER}${ROUTE_BOTID_DESIGNER_NODESEARCH}/?q=${encodeURIComponent(
        eventName,
      )}`,
    )
  }

  /**
   * Sets chart loading based on provided scope.
   * If no chartName is provided, sets all charts loading
   */
  function setLoading(isLoading: boolean, chartName?: ChartName): void {
    switch (chartName) {
      case 'message_linechart':
        setIsMessageLinechartLoading(isLoading)
        break
      case 'usage_heatmap':
        setIsUsageHeatmapLoading(isLoading)
        break
      case 'conversation_linechart':
        setIsConversationLinechartLoading(isLoading)
        break
      case 'messages_per_conversation_linechart':
        setIsMessagePerConversationLinechartLoading(isLoading)
        break
      case 'answered_question_ratio_linechart':
        setIsAnsweredQuestionRatioLinechartLoading(isLoading)
        break
      case 'helpful_answer_ratio_linechart':
        setIsHelpfulAnswerRatioLinechartLoading(isLoading)
        break
      case 'inquiries_linechart':
        setIsInquiriesLinecharLoading(isLoading)
        break
      case 'inquiries_opening_hours_stat':
        setIsInquireiesOpeningHoursStatLoading(isLoading)
        break
      case 'user_messages_wordcloud':
        setIsUserMessagesWordCloudLoading(isLoading)
        break
      case 'topics_mindmap':
        setMostUsedAndHelpfulMindMapLoading(isLoading)
        break
      case 'xzufi_answered_question_ratio_linechart':
        setIsXzufiAnsweredQuestionRatioLinechartLoading(isLoading)
        break
      case 'xzufi_helpful_answer_ratio_linechart':
        setIsXzufiHelpfulAnswerRatioLinechartLoading(isLoading)
        break
      case 'xzufi_queries_linechart':
        setIsXzufiQueriesLinechartLoading(isLoading)
        break
      case 'xzufi_most_asked_topics_table':
        setIsXzufiMostAskedTopicsLoading(isLoading)
        break
      case 'xzufi_queries_table':
        setIsXzufiAskedTopicsLoading(isLoading)
        break
      case 'custom_events_linechart':
        setIsCustomEventsLinechartLoading(isLoading)
        break
      case 'aleph_alpha_answer_ratio_stat':
        setIsAlephAlphaAnswerRatioStatLoading(isLoading)
        break
      case 'aleph_alpha_feedback_ratio_stat':
        setIsAlephAlphaFeedbackRatioStatLoading(isLoading)
        break
      case 'aleph_alpha_queries_stat':
        setIsAlephAlphaQueriesStatLoading(isLoading)
        break
      case 'aleph_alpha_sessions_stat':
        setIsAlephAlphaSessionsStatLoading(isLoading)
        break
      case 'aleph_alpha_inquires_table':
        setIsAlephAlphaInquiriesLoading(isLoading)
        break
      case undefined:
        setIsMessageLinechartLoading(isLoading)
        setIsConversationLinechartLoading(isLoading)
        setIsUsageHeatmapLoading(isLoading)
        setIsMessagePerConversationLinechartLoading(isLoading)
        setIsInquiriesLinecharLoading(isLoading)
        setIsInquireiesOpeningHoursStatLoading(isLoading)
        setIsUserMessagesWordCloudLoading(isLoading)
        // custom events
        setIsCustomEventsLinechartLoading(isLoading)
        // NLU
        setIsAnsweredQuestionRatioLinechartLoading(isLoading)
        setIsHelpfulAnswerRatioLinechartLoading(isLoading)
        setMostUsedAndHelpfulMindMapLoading(isLoading)
        // XZuFi
        setIsXzufiAnsweredQuestionRatioLinechartLoading(isLoading)
        setIsXzufiHelpfulAnswerRatioLinechartLoading(isLoading)
        setIsXzufiQueriesLinechartLoading(isLoading)
        setIsXzufiMostAskedTopicsLoading(isLoading)
        setIsXzufiAskedTopicsLoading(isLoading)
        // Aleph Alpha
        setIsAlephAlphaAnswerRatioStatLoading(isLoading)
        setIsAlephAlphaFeedbackRatioStatLoading(isLoading)
        setIsAlephAlphaQueriesStatLoading(isLoading)
        setIsAlephAlphaSessionsStatLoading(isLoading)
        setIsAlephAlphaInquiriesLoading(isLoading)
        break
    }
  }

  /**
   * Updates data in state for one particular linechart.
   * @param chartName
   * @param xValues
   * @param ySeries
   */
  function updateLinechartData(
    chartName: ChartName,
    xValues: Date[],
    ySeries: DataSeries[],
    scopes: Scope[],
    granularity: Granularity,
    titleValue: TitleValue,
  ): void {
    switch (chartName) {
      case 'message_linechart':
        setMessageLinechartData({ xValues, ySeries, titleValue, scopes, detailLevel: granularity })
        // messageLinechartDataRef.current = { xValues, ySeries, scopes, detailLevel: granularity }
        setLoading(false, chartName)
        break
      case 'conversation_linechart':
        setConversationLinechartData({ xValues, ySeries, titleValue, scopes, detailLevel: granularity })
        // conversationLinechartDataRef.current = { xValues, ySeries, scopes, detailLevel: granularity }
        setLoading(false, chartName)

        break
      case 'messages_per_conversation_linechart':
        setMessagePerConversationLinechartData({ xValues, ySeries, titleValue, scopes, detailLevel: granularity })
        setLoading(false, chartName)
        break
      case 'inquiries_linechart':
        setInquiriesLinechartData({ xValues, ySeries, titleValue, scopes, detailLevel: granularity })
        setLoading(false, chartName)
        break
      case 'custom_events_linechart': {
        // transform custom event names to removethe "custom/" tag
        const transformedYSeries: DataSeries[] = []
        for (const series of ySeries) {
          const eventSeries = series
          eventSeries.name = transformEventName(eventSeries.name)
          transformedYSeries.push(eventSeries)
        }
        setCustomEventsLinechartData({
          xValues,
          ySeries: transformedYSeries,
          titleValue,
          scopes,
          detailLevel: granularity,
        })
        setLoading(false, chartName)
        break
      }
      // NLU Charts
      case 'answered_question_ratio_linechart':
        setAnsweredQuestionRatioLinechartData({ xValues, ySeries, titleValue, scopes, detailLevel: granularity })
        setLoading(false, chartName)
        break
      case 'helpful_answer_ratio_linechart':
        setHelpfulAnswerRatioLinechartData({ xValues, ySeries, titleValue, scopes, detailLevel: granularity })
        setLoading(false, chartName)
        break
      // XZuFi Charts
      case 'xzufi_answered_question_ratio_linechart':
        setXzufiAnsweredQuestionRatioLinechartData({ xValues, ySeries, titleValue, scopes, detailLevel: granularity })
        setLoading(false, chartName)
        break
      case 'xzufi_helpful_answer_ratio_linechart':
        setXzufiHelpfulAnswerRatioLinechartData({ xValues, ySeries, titleValue, scopes, detailLevel: granularity })
        setLoading(false, chartName)
        break
      case 'xzufi_queries_linechart':
        setXzufiQueriesLinechartData({ xValues, ySeries, titleValue, scopes, detailLevel: granularity })
        setLoading(false, chartName)
        break
      default:
        break
    }
  }

  /**
   * Prepares titleValue object for a linechart
   * @param chartName
   * @param data
   */
  function prepareTitleValueLinechart(chartName: ChartName, data: LanguageCountResult): TitleValue {
    // we have to differentiate between the fixed convaise scopes in CHART_SCOPE_MAPPING and the dynamic custom scopes
    let scopes: string[] = []
    if (chartName === CHART_NAMES_MAPPING.customEventsLinechart && customEventsScopesRef.current) {
      scopes = customEventsScopesRef.current
    } else {
      scopes = CHART_SCOPE_MAPPING[chartName]
    }
    const series: TitleValue['series'] = {}
    for (const scope of scopes) {
      // is a convaise fixed scope
      if (typeof SCOPE_NAME_MAPPING[scope] !== 'undefined') {
        series[SCOPE_NAME_MAPPING[scope]] = `${
          typeof data.total[scope]?.total?.value !== 'undefined'
            ? formatNumberWithThousandsSeparator(data.total[scope].total.value)
            : '-'
        }`
      } else {
        // is dynamic events scope
        series[scope] = `${
          typeof data.total[scope]?.total?.value !== 'undefined'
            ? formatNumberWithThousandsSeparator(data.total[scope].total.value)
            : '-'
        }`
      }
    }

    switch (chartName) {
      case 'message_linechart':
        return {
          primaryValue:
            typeof data.total['all_messages']?.total?.value !== 'undefined'
              ? formatNumberWithThousandsSeparator(data.total['all_messages'].total.value)
              : '-',
          series,
        }
      case 'conversation_linechart':
        return {
          primaryValue:
            typeof data.total['dialog_started']?.total?.value !== 'undefined'
              ? formatNumberWithThousandsSeparator(data.total['dialog_started'].total.value)
              : '-',
          series,
        }
      case 'messages_per_conversation_linechart':
        return {
          primaryValue:
            typeof data.total['messages_per_conversation']?.total?.value !== 'undefined'
              ? formatNumberWithThousandsSeparator(data.total['messages_per_conversation'].total.value)
              : '-',
          series,
        }
      case 'inquiries_linechart':
        return {
          primaryValue:
            typeof data.total['all_inquiries']?.total?.value !== 'undefined'
              ? formatNumberWithThousandsSeparator(data.total['all_inquiries'].total.value)
              : '-',
          series,
        }
      case 'custom_events_linechart': {
        // calculate total of all custom events
        let total = 0
        for (const scope of Object.keys(data.total)) {
          if (typeof data.total[scope]?.total?.value !== 'undefined') {
            total += data.total[scope]?.total?.value
          }
        }
        return {
          primaryValue: total !== 0 ? formatNumberWithThousandsSeparator(total) : '-',
          series,
        }
      }
      // NLU Charts
      case 'answered_question_ratio_linechart':
        // no need to format 1.000 because percentage
        return {
          primaryValue: `${data.total['answered_question_ratio']?.total?.value || '-'}`,
          series,
        }
      case 'helpful_answer_ratio_linechart':
        // no need to format 1.000 because percentage
        return {
          primaryValue: `${data.total['helpful_answer_ratio']?.total?.value || '-'}`,
          series,
        }
      // XZuFi Charts
      case 'xzufi_answered_question_ratio_linechart':
        // no need to format 1.000 because percentage
        return {
          primaryValue: `${data.total['xzufi_answered_question_ratio']?.total?.value || '-'}`,
          series,
        }
      case 'xzufi_helpful_answer_ratio_linechart':
        // no need to format 1.000 because percentage
        return {
          primaryValue: `${data.total['xzufi_helpful_answer_ratio']?.total?.value || '-'}`,
          series,
        }
      case 'xzufi_queries_linechart':
        return {
          primaryValue:
            typeof data.total['xzufi_all_questions']?.total?.value !== 'undefined'
              ? formatNumberWithThousandsSeparator(data.total['xzufi_all_questions'].total.value)
              : '-',
          series,
        }
      default:
        return { primaryValue: '-', series: {} }
    }
  }

  /**
   * Prepares title value object for heatmap charts
   * @param data
   * @returns
   */
  function prepareTitleValueHeatmap(data: DetailedCountResult['complete']): TitleValue {
    if (data) {
      let sum = Object.values(data)
        .map((entry) => (entry === null ? null : entry.value))
        .reduce((v1, v2) => (v1 !== null ? v1 : 0) + (v2 !== null ? v2 : 0), 0)
      if (!sum) sum = 0
      return {
        primaryValue: formatNumberWithThousandsSeparator(sum),
      }
    }
    return {
      primaryValue: '0',
    }
  }

  /**
   * Processes retrieved data into the required format for the linecharts.
   * Sets them into state and with that updates all affected linecharts.
   */
  function processLinechartData(analyticsData: GetAnalyticsResponse, chartName?: ChartName): void {
    const granularity = analyticsData.granularity
    const data = analyticsData.data as LanguageCountResult
    let xValues: Date[] = []

    let chartsToUpdate: ChartName[] = []
    if (typeof chartName !== 'undefined')
      // if chartName is given, only update that chart
      chartsToUpdate.push(chartName)
    // else update all linecharts
    else
      chartsToUpdate = Object.values(CHART_NAMES_MAPPING).filter((chart) => CHART_TYPE_MAPPING[chart] === 'linechart')

    // if customEventsScopesRef.current is undefined we do not want to update the custom events chart and therefore need to filter it out
    if (typeof customEventsScopesRef.current === 'undefined') {
      chartsToUpdate = chartsToUpdate.filter(
        (chart) => CHART_NAMES_MAPPING[chart] !== CHART_NAMES_MAPPING.customEventsLinechart,
      )
    }

    // process result for all charts and update their state
    for (const chart of chartsToUpdate) {
      if (typeof CHART_TYPE_MAPPING[chart] === 'undefined' || CHART_TYPE_MAPPING[chart] !== 'linechart') continue

      const chartSeries: DataSeries[] = []
      const chartScopes: Scope[] = []

      // prepares series for each scope
      // we have to differentiate between the fixed convaise scopes in CHART_SCOPE_MAPPING and the dynamic custom scopes
      let scopesToAdd: string[] = []
      if (chart === CHART_NAMES_MAPPING.customEventsLinechart && typeof customEventsScopesRef.current !== 'undefined') {
        // scopesToAdd = scopes // the customEventScopes array always seems to be empty
        scopesToAdd = customEventsScopesRef.current
      } else {
        scopesToAdd = CHART_SCOPE_MAPPING[chart]
      }
      for (const [scopeOfChartIndex, scopeOfChart] of scopesToAdd.entries()) {
        const scopeData = data.total[scopeOfChart]
        if (
          typeof scopeData === 'undefined' ||
          typeof scopeData === 'number' ||
          typeof scopeData.detailed === 'undefined' ||
          typeof scopeData.detailed.complete === 'undefined'
        )
          continue

        // if xValues are not set yet, we set them using the current scope
        // we can do this because the xValues are the same for all charts / scopes because the timespan is equal
        if (xValues.length === 0) xValues = Object.keys(scopeData.detailed.complete).map((date) => new Date(date))

        // dynamic coloring for dynamic events since we have no specific mapping for custom events
        let color: string | undefined = undefined
        if (chart === CHART_NAMES_MAPPING.customEventsLinechart) {
          color = COLOR_PALETTE[scopeOfChartIndex % COLOR_PALETTE.length]
        }

        const series = {
          name:
            typeof SCOPE_NAME_MAPPING[scopeOfChart] !== 'undefined'
              ? SCOPE_NAME_MAPPING[scopeOfChart]
              : typeof scopeOfChart !== 'undefined'
              ? scopeOfChart
              : '-',
          color:
            chart === CHART_NAMES_MAPPING.customEventsLinechart && typeof color !== 'undefined'
              ? color
              : SCOPE_COLOR_MAPPING[scopeOfChart] || '#EEEEEE',
          data: Object.values(scopeData.detailed.complete).map((x) => ({
            ...x,
            y: typeof x !== 'undefined' ? (x === null ? null : x.value) : 0,
            value: undefined, // remove value property as this is now set as y value
          })),
        }
        chartSeries.push(series)
        chartScopes.push(scopeOfChart)
      }

      const titleValue = prepareTitleValueLinechart(chart, data)

      // finally update chart data for that particular chart
      // this sets the new data into state
      updateLinechartData(chart, xValues, chartSeries, chartScopes, granularity, titleValue)
    }
  }

  /**
   * Updates data in state for one particular heatmap.
   * @param chartName
   * @param data
   */
  function updateHeatmapData(chartName: ChartName, data: DetailedCountResultPerTime): void {
    switch (chartName) {
      case 'usage_heatmap': {
        const titleValue = prepareTitleValueHeatmap(data)
        setUsageHeatmapData({ data, titleValue })
        // usageHeatmapDataRef.current = data
        setLoading(false, chartName)
        break
      }
    }
  }

  /**
   * Processes data for heatmap charts.
   * @param analyticsData
   * @param chartName
   */
  function processHeatmapData(analyticsData: GetAnalyticsResponse, chartName?: ChartName): void {
    let chartsToUpdate: ChartName[] = []
    const data = analyticsData.data as LanguageCountResult
    if (typeof chartName !== 'undefined')
      // if chartName is given, only update that chart
      chartsToUpdate.push(chartName)
    else chartsToUpdate = Object.values(CHART_NAMES_MAPPING).filter((chart) => CHART_TYPE_MAPPING[chart] === 'heatmap')

    for (const chart of chartsToUpdate) {
      if (typeof CHART_TYPE_MAPPING[chart] === 'undefined' || CHART_TYPE_MAPPING[chart] !== 'heatmap') continue

      const chartScopes = CHART_SCOPE_MAPPING[chart]
      // NOTE: Heatmaps only have single scope!
      if (typeof chartScopes === 'undefined' || chartScopes.length === 0) continue

      const scopeData = data.total[chartScopes[0]]
      if (
        typeof scopeData === 'undefined' ||
        typeof scopeData === 'number' ||
        typeof scopeData.detailed === 'undefined'
      )
        continue

      updateHeatmapData(chart, scopeData.detailed.complete)
    }
  }

  /**
   * Updates data for a single stat chart.
   * @param chartName
   * @param stats
   */
  function updateStatData(chartName: ChartName, stats: Stat[]): void {
    switch (chartName) {
      case 'inquiries_opening_hours_stat': {
        setInquiriesOpeningHoursStatData(stats) // these are already sorted
        setLoading(false, chartName)
        break
      }
      case 'aleph_alpha_sessions_stat': {
        // we need to bring main stat to first position
        const sortedStats = [
          ...stats.filter((stat) => stat.scope === 'aleph_alpha_sessions'),
          ...stats.filter((stat) => stat.scope === 'aleph_alpha_session_duration_average'),
          ...stats.filter((stat) => stat.scope === 'aleph_alpha_session_questions_per_session'),
        ]
        setAlephAlphaSessionsStatData(sortedStats)
        setLoading(false, chartName)
        break
      }
      case 'aleph_alpha_queries_stat': {
        // we need to bring main stat to first position
        const sortedStats = [
          ...stats.filter((stat) => stat.scope === 'aleph_alpha_queries_all'),
          ...stats.filter((stat) => stat.scope === 'aleph_alpha_queries_voice'),
          ...stats.filter((stat) => stat.scope === 'aleph_alpha_queries_text'),
          ...stats.filter((stat) => stat.scope === 'aleph_alpha_queries_voice_ratio'),
        ]
        setAlephAlphaQueriesStatData(sortedStats)
        setLoading(false, chartName)
        break
      }
      case 'aleph_alpha_answer_ratio_stat': {
        // we need to bring main stat to first position
        const sortedStats = [
          ...stats.filter((stat) => stat.scope === 'aleph_alpha_queries_answered_ratio'),
          ...stats.filter((stat) => stat.scope === 'aleph_alpha_queries_answered'),
          ...stats.filter((stat) => stat.scope === 'aleph_alpha_queries_unanswered'),
        ]
        setIsAlephAlphaAnswerRatioStatData(sortedStats)
        setLoading(false, chartName)
        break
      }
      case 'aleph_alpha_feedback_ratio_stat': {
        // we need to bring main stat to first position
        const sortedStats = [
          ...stats.filter((stat) => stat.scope === 'aleph_alpha_feedback_positive_ratio'),
          ...stats.filter((stat) => stat.scope === 'aleph_alpha_feedback_positive'),
          ...stats.filter((stat) => stat.scope === 'aleph_alpha_feedback_negative'),
        ]
        setAlephAlphaFeedbackRatioStatData(sortedStats)
        setLoading(false, chartName)
        break
      }
      default:
        break
    }
  }

  /**
   * Processes analytics data for stat charts
   * @param analyticsData
   * @param chartName
   */
  function processStatData(analyticsData: GetAnalyticsResponse, chartName?: ChartName): void {
    let chartsToUpdate: ChartName[] = []

    if (typeof chartName !== 'undefined')
      // if chartName is given, only update that chart
      chartsToUpdate.push(chartName)
    // else update all stats
    else chartsToUpdate = Object.values(CHART_NAMES_MAPPING).filter((chart) => CHART_TYPE_MAPPING[chart] === 'stat')

    for (const chart of chartsToUpdate) {
      if (typeof CHART_TYPE_MAPPING[chart] === 'undefined' || CHART_TYPE_MAPPING[chart] !== 'stat') continue

      // some stat charts require special preprocessing (e.g. opening hours); most others are handled the same way
      let stats: Stat[] = []
      switch (chart) {
        case 'inquiries_opening_hours_stat': {
          const data = (analyticsData.data as any).inquiries_outside_opening_hours // TODO: fix ugly type cast
          if (data) stats = prepareInquiriesOutsideOpeningHoursScopeStatChart(data)
          break
        }
        default: {
          const scopesToAdd = CHART_SCOPE_MAPPING[chart]
          const data = analyticsData.data as LanguageCountResult
          for (const scope of scopesToAdd) {
            const scopeData = data.total[scope]
            if (!scopeData) continue

            const stat: Stat = {
              title: SCOPE_NAME_MAPPING[scope] ?? '-',
              titleValueChar: SCOPE_TITLE_CHAR_MAPPING[scope]?.titleValueChar ?? '#',
              switchTitleValueAndTitleValueChar:
                SCOPE_TITLE_CHAR_MAPPING[scope]?.switchTitleValueAndTitleValueChar ?? false,
              titleValue: `${scopeData.total.value}`,
              scope: scope,
            }
            stats.push(stat)
          }
        }
      }

      updateStatData(chart, stats)
    }
  }

  /**
   * Updates data in state for one particular talbe. Currently only support MostAskedTopic[], XzufiMostAskedTopic[], XzufiAskedTopic[]
   * @param chartName
   * @param data
   */
  function updateTableData(
    chartName: ChartName,
    data: MostAskedTopic[] | XzufiMostAskedTopic[] | XzufiAskedTopic[] | AlephAlphaInquiry[],
  ): void {
    switch (chartName) {
      case CHART_NAMES_MAPPING.mostUsedAndHelpfulTopicsMindMap:
        setMostUsedAndHelpfulMindMapData(data as MostAskedTopic[])
        setMostUsedAndHelpfulMindMapLoading(false)
        break
      case 'xzufi_most_asked_topics_table':
        setXzufiMostAskedTopicsData(data as XzufiMostAskedTopic[])
        setIsXzufiMostAskedTopicsLoading(false)
        break
      case 'xzufi_queries_table':
        setXzufiAskedTopicsData(data as XzufiAskedTopic[])
        setIsXzufiAskedTopicsLoading(false)
        break
      case CHART_NAMES_MAPPING.alephAlphaInquiriesTable:
        setAlephAlphaInquiriesData(data as AlephAlphaInquiry[])
        setIsAlephAlphaInquiriesLoading(false)
        break
      default:
        break
    }
  }

  /**
   * Processes data for mindmaps
   * @param analyticsData
   * @param chartName
   */
  async function processTableData(analyticsData: GetAnalyticsResponse, chartName: ChartName): Promise<void> {
    // TODO: as soon as we switch from the table to the real minmap we need to preprocess the analyticsData and get it into a tree format

    // NOTE: Explicit processing for different mindmaps, since the mindmaps do not have a general model/useage in this case
    // (the mindmap itself has, but we aggregate data here as well and prepare the mindmap data schema)
    switch (chartName) {
      case CHART_NAMES_MAPPING.mostUsedAndHelpfulTopicsMindMap: {
        const MIN_NUMBER_OF_PREDICTIONS = 3
        // this is for now an array
        let topics: MostAskedTopic[] = []
        const data = analyticsData.data as MostAskedTopicsResults
        if (data.most_asked_topics && data.most_asked_topics.length > 0) {
          const mostAskedTopics = data.most_asked_topics.filter(
            (askedTopic) => askedTopic.amountPredicted >= MIN_NUMBER_OF_PREDICTIONS,
          )
          let answersData: Answer[] = [] // using this we do not have to wait until the state is set, when the answers are initially loaded
          // load answers if none exist
          if (typeof answers === 'undefined') {
            const answersResult = await loadAnswers()
            setAnswers(answersResult)
            answersData = answersResult
          } else {
            answersData = answers
          }
          // go through all topics and generate in combination with answers a fitting result
          for (const mostAskedTopic of mostAskedTopics) {
            // get fitting answer
            const answer = answersData.find((answ) => answ.intent === mostAskedTopic.intent)
            if (typeof answer !== 'undefined') {
              topics.push({
                answerId: answer.answerId,
                topic: answer.topic,
                title: answer.answerType === 'answer' ? answer.title : answer.triggerDialogName ?? '',
                answer: answer.answer,
                ...mostAskedTopic,
              })
            }
          }
          // Sort for highest amount first
          topics.sort((a, b) =>
            a.amountPredicted < b.amountPredicted ? 1 : b.amountPredicted < a.amountPredicted ? -1 : 0,
          )
          // Rules on which ones to show
          // First top 50%
          topics = topics.slice(0, Math.round(topics.length / 2) + 1)
          // Second min 5 utterances
          topics = topics.filter((result) => result.amountPredicted >= 5)
        } else {
          console.info('No data for most_asked_topics found.')
        }
        updateTableData(chartName, topics)
        break
      }
      case CHART_NAMES_MAPPING.xzufiMostAskedTopicTable: {
        // this is for now an array
        let topics: XzufiMostAskedTopic[] = []
        if ('xzufi_most_asked_topics' in analyticsData.data && analyticsData.data.xzufi_most_asked_topics) {
          const data = analyticsData.data.xzufi_most_asked_topics as XzufiMostAskedTopic[]
          if (data && data.length > 0) {
            topics = data
            // Sort for highest count first
            topics.sort((a, b) => (a.count < b.count ? 1 : b.count < a.count ? -1 : 0))
          } else {
            console.info('No data for xzufi_most_asked_topics_table found.')
          }
        }
        updateTableData(chartName, topics)
        break
      }
      case CHART_NAMES_MAPPING.xzufiQueriesTable: {
        // this is for now an array
        let topics: XzufiAskedTopic[] = []
        if ('xzufi_asked_questions' in analyticsData.data && analyticsData.data.xzufi_asked_questions) {
          const data = analyticsData.data.xzufi_asked_questions as XzufiAskedTopic[]
          // Sort by newest on top
          if (data && data.length > 0) {
            topics = data
            // Sort for highest count first
            // topics.sort((a, b) => (a.count < b.count ? 1 : b.count < a.count ? -1 : 0))
          } else {
            console.info('No data for xzufi_queries_table found.')
          }
        }
        updateTableData(chartName, topics)
        break
      }
      case CHART_NAMES_MAPPING.alephAlphaInquiriesTable: {
        let inquiries: AlephAlphaInquiry[] = []
        if ('aleph_alpha_inquiries' in analyticsData.data && analyticsData.data.aleph_alpha_inquiries) {
          const data = analyticsData.data.aleph_alpha_inquiries as AlephAlphaInquiry[]
          // sort by newest on top
          if (data && data.length > 0) {
            data.sort((a, b) => (new Date(a.timestamp) < new Date(b.timestamp) ? -1 : 1))
            inquiries = data
          } else {
            console.info('No data for aleph_alpha_inquires_table found.')
          }
        }
        updateTableData(chartName, inquiries)
        break
      }
      default:
        break
    }
  }

  function updateWordcloudData(chartName: ChartName, data: WordcloudData) {
    switch (chartName) {
      case CHART_NAMES_MAPPING.userMessagesWordcloud:
        setIsUserMessagesWordCloudData(data)
        setIsUserMessagesWordCloudLoading(false)
        break
      default:
        break
    }
  }

  function processWordcloudData(analyticsData: GetAnalyticsResponse, chartName?: ChartName) {
    switch (chartName) {
      case CHART_NAMES_MAPPING.userMessagesWordcloud:
        if ('user_messages_wordcloud' in analyticsData.data) {
          updateWordcloudData(chartName, analyticsData.data.user_messages_wordcloud)
        }
        break
      default:
        break
    }
  }

  // ===== API =====

  /**
   * Performs getAnalytics API call and stores result in state.
   *
   */
  async function loadAnalytics(
    scopes: Scope[],
    timespan: Timespan,
    granularity: Granularity,
    chartType: ChartType,
    chartName?: ChartName,
    languages?: Language[],
    totalSumOnly?: boolean,
    preloadedAnalyticsData?: GetAnalyticsResponse | null,
  ): Promise<void> {
    // reset should update state if this function is called, the update call has been received
    if (typeof shouldReload !== 'undefined') setShoudReload(undefined)
    let analyticsData: GetAnalyticsResponse | null = null

    // set loading
    setLoading(true, chartName)

    if (!preloadedAnalyticsData) {
      const requestObj: GetAnalyticsRequest = {
        botId,
        scopes,
        granularity,
        timespan,
        languages,
        totalSumOnly,
      }

      // we need to add the moduleConfig for xzufi analytics
      if (
        bot &&
        chartName &&
        [
          'xzufi_queries_table',
          'xzufi_most_asked_topics_table',
          'xzufi_helpful_answer_ratio_linechart',
          'xzufi_answered_question_ratio_linechart',
          'xzufi_queries_linechart',
        ].includes(chartName)
      ) {
        const moduleIds = Object.values(bot.modules).filter(
          (module) => module.type === MODULE_TYPE_XZUFI,
        ) as unknown as XzufiModule[]
        // FIXME currently only supports first found xzufi module -> see comment at top of component
        if (moduleIds.length > 0 && moduleIds[0] && moduleIds[0].moduleConfigId) {
          requestObj.moduleConfigId = moduleIds[0].moduleConfigId
        }
      }

      // we need to add moduleConfig for aleph-alpha analytics
      const alephAlphaModules = Object.values(bot?.modules ?? {}).filter(
        (module) => module.type === MODULE_TYPE_ALEPHALPHA,
      ) as unknown as AlephAlphaModule[]
      if (bot && alephAlphaModules.length > 0 && (chartName ? chartName.includes('aleph_alpha') : true)) {
        const modules = alephAlphaModules as AlephAlphaModule[]
        // FIXME currently only supports 'aleph-alpha-lumi-conversational' module
        if (modules.findIndex((module) => module.name === 'aleph-alpha-lumi-conversational') > -1) {
          requestObj.moduleConfigId = modules.find((module) => module.name === 'aleph-alpha-lumi-conversational')
            ?.moduleConfigId
        }
      }

      analyticsData = await getAnalytics(requestObj)
    } else {
      analyticsData = preloadedAnalyticsData
    }

    if (analyticsData === null) return // checks if result is not null
    // const isChart = isTypeChart(analyticsData.data)
    // special checks depending on the type of analyticsData if all needed data exists
    // if (isChart) {
    //   if (!analyticsData.totalSumOnly) {
    //     const data = analyticsData.data as LanguageCountResult // why do we need to do this here?
    //     if ((chartType === 'linechart' || chartType === 'heatmap') && Object.keys(data.total).length === 0) return
    //   }
    // } else {
    //   const data = analyticsData.data as MostAskedTopicsResults // why do we need to do this here?
    //   if (chartType === 'mindmap' && Object.keys(data).length === 0) return
    // }

    switch (chartType) {
      case 'linechart':
        processLinechartData(analyticsData, chartName)
        break
      case 'heatmap':
        processHeatmapData(analyticsData, chartName)
        break
      case 'mindmap':
      case 'table':
        processTableData(analyticsData, chartName ?? 'topics_mindmap')
        break
      case 'stat':
        processStatData(analyticsData, chartName)
        break
      case 'wordcloud':
        processWordcloudData(analyticsData, chartName)
        break
      default:
        break
    }
  }

  /**
   * Loads chart  from api.
   */
  async function loadFlowChart(retry?: boolean): Promise<void> {
    // setLoading('loading')
    try {
      const chart = await getFlow(botId)
      if (chart !== null && chart.chart && chart.chart.customAnalyticsEvents) {
        setCustomAnalyticsEvents(chart.chart.customAnalyticsEvents)
      } else {
        console.error('Error loading chart.')
        throw new Error('getFlow result is null')
      }
    } catch (err) {
      if (!retry) {
        // try again a second time
        console.info('Retry: Load translation file')
        await loadFlowChart(true)
      } else {
        // set error
        console.info('Retry failed: Load translation file')
      }
    }
  }

  /**
   * Loads all answers - this is needed for some mindmaps
   */
  async function loadAnswers(retry?: boolean): Promise<Answer[]> {
    try {
      const getAnswerResult = await getAnswers(botId, 'specific')
      if (getAnswerResult !== null && getAnswerResult.answers) {
        const answers = Object.values(getAnswerResult.answers).map((answer) => {
          return buildAnswerObject(answer)
        })
        return answers
      } else {
        throw new Error('getAnswer result is null')
      }
    } catch (err) {
      if (!retry) {
        // try again a second time
        console.info('Retry: Load Answers')
        return loadAnswers(true)
      } else {
        // TODO: setError
        return []
      }
    }
  }

  /**
   * Loads initial data for ALL scopes with a timespan of last seven days, with granularity "day".
   * Processes the results and sets them into state for each chart.
   */
  async function initialLoad(): Promise<void> {
    // we check if initial load is already running - only relevant for development mode as React 18 runs side effects twice and we do not want to load twice.
    if (isInitializedRef.current) return
    isInitializedRef.current = true

    // DATES and TIMESPANS
    // last 7 days
    const NUM_DAYS = 7

    // linecharts
    const linechartsEndDate = new Date()
    linechartsEndDate.setDate(linechartsEndDate.getDate())
    linechartsEndDate.setHours(23, 59, 59, 999)
    const linechartsStartDate = subtractDaysFromDate(linechartsEndDate, NUM_DAYS)
    linechartsStartDate.setHours(0, 0, 0, 0)
    const linechartsTimespan = { from: linechartsStartDate, to: linechartsEndDate }

    // heatmaps
    const heatmapEndDate = new Date()
    heatmapEndDate.setDate(heatmapEndDate.getDate())
    heatmapEndDate.setHours(23, 59, 59, 999)
    const heatmapStartDate = subtractDaysFromDate(heatmapEndDate, NUM_DAYS)
    heatmapStartDate.setHours(0, 0, 0, 0)
    const heatmapTimespan = { from: heatmapStartDate, to: heatmapEndDate }

    // tables
    const tableEndDate = new Date()
    tableEndDate.setDate(tableEndDate.getDate())
    tableEndDate.setHours(23, 59, 59, 999)
    const tableStartDate = subtractDaysFromDate(tableEndDate, NUM_DAYS)
    tableStartDate.setHours(0, 0, 0, 0)
    const tableTimespan = { from: tableStartDate, to: tableEndDate }

    // stats
    const statsEndDate = new Date()
    statsEndDate.setDate(statsEndDate.getDate())
    statsEndDate.setHours(23, 59, 59, 999)
    const statsStartDate = subtractDaysFromDate(statsEndDate, NUM_DAYS)
    statsStartDate.setHours(0, 0, 0, 0)
    const statsTimespan = { from: statsStartDate, to: statsEndDate }

    // wordclouds
    const wordcloudsEndDate = new Date()
    wordcloudsEndDate.setDate(wordcloudsEndDate.getDate())
    wordcloudsEndDate.setHours(23, 59, 59, 999)
    const wordcloudsStartDate = subtractDaysFromDate(wordcloudsEndDate, NUM_DAYS)
    wordcloudsStartDate.setHours(0, 0, 0, 0)
    const wordcloudsTimespan = { from: wordcloudsStartDate, to: wordcloudsEndDate }

    // SCOPES
    // Linecharts - Base Scopes
    const linechartsScopes: Scope[] = [
      'all_messages',
      'user_messages',
      'bot_messages',
      'dialog_started',
      'docs_generated',
      'messages_per_conversation',
    ]
    // Heatmaps - Base Scopes
    const heatmapScopes: Scope[] = ['dialog_started']
    // Tables - Base Scopes
    const tableScopes: Scope[] = []
    // Stats
    const statScopes: Scope[] = []
    // wordclouds
    const wordcloudsScopes: Scope[] = ['user_messages_wordcloud']

    // BASE PROMISES
    const promises: Promise<void>[] = [
      // Default
      loadAnalytics(
        CHART_SCOPE_MAPPING[CHART_NAMES_MAPPING.messagesPerConversationLinechart],
        linechartsTimespan,
        'day',
        'linechart',
        CHART_NAMES_MAPPING.messagesPerConversationLinechart,
        undefined,
        false,
      ),
      loadAnalytics(
        CHART_SCOPE_MAPPING[CHART_NAMES_MAPPING.conversationLinechart],
        linechartsTimespan,
        'day',
        'linechart',
        CHART_NAMES_MAPPING.conversationLinechart,
        undefined,
        false,
      ),
      loadAnalytics(
        CHART_SCOPE_MAPPING[CHART_NAMES_MAPPING.messageLinechart],
        linechartsTimespan,
        'day',
        'linechart',
        CHART_NAMES_MAPPING.messageLinechart,
        undefined,
        false,
      ),
      loadAnalytics(
        CHART_SCOPE_MAPPING[CHART_NAMES_MAPPING.usageHeatmap],
        heatmapTimespan,
        'hour',
        'heatmap',
        CHART_NAMES_MAPPING.usageHeatmap,
        undefined,
        false,
      ),
      loadAnalytics(
        CHART_SCOPE_MAPPING[CHART_NAMES_MAPPING.userMessagesWordcloud],
        wordcloudsTimespan,
        'day',
        'wordcloud',
        CHART_NAMES_MAPPING.userMessagesWordcloud,
        undefined,
        false,
      ),
    ]

    // MODULES

    if ((bot && hasModule(MODULE_TYPE_NLU)) || hasModule(MODULE_TYPE_XZUFI)) {
      // ANALYLTICS THAT CURRENTLY ONLY WORK FOR ONLY NLU (XZUFI CAUSES SOME ISSUES)
      promises.push(
        loadAnalytics(
          CHART_SCOPE_MAPPING[CHART_NAMES_MAPPING.inquiriesOpeningHoursStat],
          linechartsTimespan,
          'day',
          'stat',
          CHART_NAMES_MAPPING.inquiriesOpeningHoursStat,
          undefined,
          false,
        ),
      )
    }

    // NLU: add nlu scopes if nlu module is set
    if (bot && hasModule(MODULE_TYPE_NLU)) {
      const nluLinechartScopes = [
        'all_questions',
        'answered_question_ratio',
        'helpful_answer_ratio',
        'all_inquiries',
        'subdialog_started',
      ]

      const nluTableScopes = ['most_asked_topics']

      // add nlu promises
      promises.push(
        ...[
          // NLU
          loadAnalytics(
            CHART_SCOPE_MAPPING[CHART_NAMES_MAPPING.helpfulAnswerRatioLinechart],
            linechartsTimespan,
            'day',
            'linechart',
            CHART_NAMES_MAPPING.helpfulAnswerRatioLinechart,
            undefined,
            false,
          ),
          loadAnalytics(
            CHART_SCOPE_MAPPING[CHART_NAMES_MAPPING.answeredQuestionsRatioLinechart],
            linechartsTimespan,
            'day',
            'linechart',
            CHART_NAMES_MAPPING.answeredQuestionsRatioLinechart,
            undefined,
            false,
          ),
          loadAnalytics(
            CHART_SCOPE_MAPPING[CHART_NAMES_MAPPING.inquiriesLinechart],
            linechartsTimespan,
            'day',
            'linechart',
            CHART_NAMES_MAPPING.inquiriesLinechart,
            undefined,
            false,
          ),
          loadAnalytics(
            CHART_SCOPE_MAPPING[CHART_NAMES_MAPPING.mostUsedAndHelpfulTopicsMindMap],
            tableTimespan,
            'day',
            'mindmap',
            CHART_NAMES_MAPPING.mostUsedAndHelpfulTopicsMindMap,
            undefined,
            false,
          ), // granularity is not relevant for the tables, but needed
        ],
      )
    }

    // XZUFI: add xzufi scopes if xzufi module is set
    if (bot && hasModule(MODULE_TYPE_XZUFI)) {
      // for linecharts
      const xzufiLinechartScopes = [
        'xzufi_answered_question_ratio',
        'xzufi_helpful_answer_ratio',
        'xzufi_all_questions',
      ]
      // for tables
      const xzufiTableScopes = ['xzufi_most_asked_topics', 'xzufi_asked_questions']
      // add xufi promises
      promises.push(
        ...[
          loadAnalytics(
            xzufiLinechartScopes,
            linechartsTimespan,
            'day',
            'linechart',
            'xzufi_answered_question_ratio_linechart',
            undefined,
            false,
          ),
          loadAnalytics(
            xzufiLinechartScopes,
            linechartsTimespan,
            'day',
            'linechart',
            'xzufi_helpful_answer_ratio_linechart',
            undefined,
            false,
          ),
          loadAnalytics(
            xzufiLinechartScopes,
            linechartsTimespan,
            'day',
            'linechart',
            'xzufi_queries_linechart',
            undefined,
            false,
          ),
          loadAnalytics(xzufiTableScopes, tableTimespan, 'day', 'table', 'xzufi_queries_table', undefined, false), // granularity is not relevant for the tables, but needed
          loadAnalytics(
            xzufiTableScopes,
            tableTimespan,
            'day',
            'table',
            'xzufi_most_asked_topics_table',
            undefined,
            false,
          ), // granularity is not relevant for the tables, but needed
        ],
      )
    }

    // ALEPH ALPHA: add aleph alpha scopres if aleph alpha module is set
    if (bot && hasModule(MODULE_TYPE_ALEPHALPHA)) {
      const alephAlphaStatScopes = [
        'aleph_alpha_queries_all',
        'aleph_alpha_queries_voice',
        'aleph_alpha_queries_text',
        'aleph_alpha_queries_voice_ratio',
        'aleph_alpha_queries_answered',
        'aleph_alpha_queries_unanswered',
        'aleph_alpha_queries_answered_ratio',
        'aleph_alpha_feedback_positive',
        'aleph_alpha_feedback_negative',
        'aleph_alpha_feedback_positive_ratio',
        'aleph_alpha_sessions',
        'aleph_alpha_session_duration_average',
        'aleph_alpha_session_questions_per_session',
      ]

      promises.push(loadAnalytics(alephAlphaStatScopes, statsTimespan, 'day', 'stat'))

      const alephAlphaTableScopes = ['aleph_alpha_initial_inquiries']
      promises.push(
        loadAnalytics(
          alephAlphaTableScopes,
          tableTimespan,
          'day',
          'table',
          CHART_NAMES_MAPPING.alephAlphaInquiriesTable,
        ),
      )
    }

    // LOAD DATA
    // flow chart used for custom events
    await Promise.all([loadFlowChart(), ...promises])
  }

  function onCustomTimespanSelectionChange(fromDate: Date, toDate: Date): void {
    setCustomTimespanSuggestion({ fromDate, toDate })
  }

  useEffect(() => {
    async function load(customEventScopes, linechartsTimespan): Promise<void> {
      await loadAnalytics(
        customEventScopes,
        linechartsTimespan,
        'day',
        'linechart',
        CHART_NAMES_MAPPING.customEventsLinechart,
      )
    }

    if (customAnalyticsEvents && Object.keys(customAnalyticsEvents).length > 0) {
      const customEventScopes: string[] = []
      for (const eventId of Object.keys(customAnalyticsEvents)) {
        customEventScopes.push(customAnalyticsEvents[eventId].eventName)
      }
      customEventsScopesRef.current = customEventScopes
      // setCustomEventsScopes(customEventScopes)
      // last 7 days
      const NUM_DAYS = 7
      const linechartsEndDate = new Date()
      linechartsEndDate.setDate(linechartsEndDate.getDate())
      linechartsEndDate.setHours(23, 59, 59, 999)
      const linechartsStartDate = subtractDaysFromDate(linechartsEndDate, NUM_DAYS)
      linechartsStartDate.setHours(0, 0, 0, 0)
      const linechartsTimespan = { from: linechartsStartDate, to: linechartsEndDate }

      load(customEventScopes, linechartsTimespan)
    }
  }, [customAnalyticsEvents])

  useEffect(() => {
    // initial load after bot data is loaded
    if (bot && !initialLoadStartedRef.current) {
      initialLoadStartedRef.current = true
      initialLoad()
    }
  }, [bot])

  return (
    <>
      <ContentPageHeader
        title='Nutzungseinblicke'
        actions={[
          <Button
            key='refresh-button'
            onClick={(): void => setShoudReload(true)}
            size='normal'
            type='normal'
            icon='refresh-line'
            iconType='remix'
          >
            Aktualisieren
          </Button>,
        ]}
      />
      <Grid container spacing={4}>
        {/* DEFAULT METRICS */}
        <Grid item sm={12} md={6}>
          <BaseCard width='100%' height='auto'>
            <AnalyticsChart
              id={CHART_NAMES_MAPPING.messageLinechart}
              shouldReload={shouldReload}
              title='Nachrichten'
              chartType='linechart'
              titleValue={messageLinechartData.titleValue}
              linechartXValues={messageLinechartData.xValues}
              linechartYValues={messageLinechartData.ySeries}
              onLoadDataCallback={loadAnalytics}
              scopes={messageLinechartData.scopes}
              granularity={messageLinechartData.detailLevel}
              isLoading={!!isMessageLinechartLoading}
              onCustomTimespanSelection={onCustomTimespanSelectionChange}
              customTimespanSuggestion={customTimespanSuggestion}
            />
          </BaseCard>
        </Grid>
        {bot && (hasModule(MODULE_TYPE_NLU) || hasModule(MODULE_TYPE_XZUFI)) && (
          <>
            <Grid item sm={12} md={6}>
              <BaseCard width='100%' height='auto'>
                <AnalyticsChart
                  id={CHART_NAMES_MAPPING.inquiriesLinechart}
                  shouldReload={shouldReload}
                  title='Anfragen'
                  titleValue={inquiriesLinechartData.titleValue}
                  chartType='linechart'
                  linechartXValues={inquiriesLinechartData.xValues}
                  linechartYValues={inquiriesLinechartData.ySeries}
                  onLoadDataCallback={loadAnalytics}
                  scopes={inquiriesLinechartData.scopes}
                  granularity={inquiriesLinechartData.detailLevel}
                  isLoading={!!isInquiriesLinechartLoading}
                  onCustomTimespanSelection={onCustomTimespanSelectionChange}
                  customTimespanSuggestion={customTimespanSuggestion}
                />
              </BaseCard>
            </Grid>
            <Grid item sm={12} md={6}>
              <BaseCard width='100%' height='100%'>
                <AnalyticsStat
                  id={CHART_NAMES_MAPPING.inquiriesOpeningHoursStat}
                  chartType='stat'
                  onLoadDataCallback={loadAnalytics}
                  shouldReload={shouldReload}
                  scopes={CHART_SCOPE_MAPPING[CHART_NAMES_MAPPING.inquiriesOpeningHoursStat]}
                  title={SCOPE_NAME_MAPPING.inquiries_outside_operating_hours}
                  titleStat={
                    inquiriesOpeningHoursStatData && inquiriesOpeningHoursStatData.length > 0
                      ? inquiriesOpeningHoursStatData[0]
                      : null
                  }
                  subStats={
                    inquiriesOpeningHoursStatData && inquiriesOpeningHoursStatData.length > 1
                      ? inquiriesOpeningHoursStatData.slice(1)
                      : undefined
                  }
                  isLoading={!!isInquiriesOpeningHoursStatLoading}
                  onCustomTimespanSelection={onCustomTimespanSelectionChange}
                  customTimespanSuggestion={customTimespanSuggestion}
                  // height='347px'
                />
              </BaseCard>
            </Grid>
          </>
        )}
        <Grid item sm={12} md={6}>
          <BaseCard width='100%' height='auto'>
            <AnalyticsChart
              id={CHART_NAMES_MAPPING.conversationLinechart}
              shouldReload={shouldReload}
              title='Konversationen'
              chartType='linechart'
              titleValue={conversationLinechartData.titleValue}
              linechartXValues={conversationLinechartData.xValues}
              linechartYValues={conversationLinechartData.ySeries}
              onLoadDataCallback={loadAnalytics}
              scopes={conversationLinechartData.scopes}
              granularity={conversationLinechartData.detailLevel}
              isLoading={!!isConversationLinechartLoading}
              onCustomTimespanSelection={onCustomTimespanSelectionChange}
              customTimespanSuggestion={customTimespanSuggestion}
            />
          </BaseCard>
        </Grid>
        <Grid item sm={12} md={6}>
          <BaseCard width='100%' height='auto'>
            <AnalyticsChart
              id={CHART_NAMES_MAPPING.usageHeatmap}
              shouldReload={shouldReload}
              title='Konversationen'
              chartType='heatmap'
              titleValue={usageHeatmapData.titleValue}
              heatmapData={usageHeatmapData.data}
              onLoadDataCallback={loadAnalytics}
              scopes={CHART_SCOPE_MAPPING[CHART_NAMES_MAPPING.usageHeatmap]}
              granularity='hour'
              isLoading={!!isUsageHeatmapLoading}
              onCustomTimespanSelection={onCustomTimespanSelectionChange}
              customTimespanSuggestion={customTimespanSuggestion}
            />
          </BaseCard>
        </Grid>
        <Grid item sm={12} md={6}>
          <BaseCard width='100%' height='auto'>
            <AnalyticsChart
              id={CHART_NAMES_MAPPING.messagesPerConversationLinechart}
              shouldReload={shouldReload}
              title='Ø Nachrichten pro Konversation'
              titleValue={messagePerConversationLinechartData.titleValue}
              chartType='linechart'
              linechartXValues={messagePerConversationLinechartData.xValues}
              linechartYValues={messagePerConversationLinechartData.ySeries}
              onLoadDataCallback={loadAnalytics}
              scopes={messagePerConversationLinechartData.scopes}
              granularity={messagePerConversationLinechartData.detailLevel}
              isLoading={!!isMessagePerConversationLinechartLoading}
              onCustomTimespanSelection={onCustomTimespanSelectionChange}
              customTimespanSuggestion={customTimespanSuggestion}
            />
          </BaseCard>
        </Grid>

        {bot && (hasModule(MODULE_TYPE_NLU) || hasModule(MODULE_TYPE_XZUFI) || hasModule(MODULE_TYPE_RAG)) ? (
          <Grid item sm={12} md={12}>
            <BaseCard width='100%' height='auto'>
              <AnalyticsChart
                id={CHART_NAMES_MAPPING.userMessagesWordcloud}
                shouldReload={shouldReload}
                title={'Nutzernachrichten (Wordcloud)'}
                chartType='wordcloud'
                wordcloudData={userMessagesWordcloudData}
                onLoadDataCallback={loadAnalytics}
                isLoading={!!isUserMessagesWordcloudLoading}
                onCustomTimespanSelection={onCustomTimespanSelectionChange}
                scopes={CHART_SCOPE_MAPPING[CHART_NAMES_MAPPING.userMessagesWordcloud]}
                granularity='day'
                showTitleValue={false}
              />
            </BaseCard>
          </Grid>
        ) : null}

        {/* Only if CustomEvents exist */}
        {customAnalyticsEvents && Object.keys(customAnalyticsEvents).length > 0 && (
          <>
            <Grid item sm={12} md={6}>
              <BaseCard width='100%' height='auto'>
                <AnalyticsChart
                  id={CHART_NAMES_MAPPING.customEventsLinechart}
                  shouldReload={shouldReload}
                  title='Benutzerdefinierte Events'
                  chartType='linechart'
                  titleValueChar='#'
                  titleValue={customEventsLinechartData.titleValue}
                  linechartXValues={customEventsLinechartData.xValues}
                  linechartYValues={customEventsLinechartData.ySeries}
                  onLoadDataCallback={loadAnalytics}
                  scopes={customEventsLinechartData.scopes}
                  granularity={customEventsLinechartData.detailLevel}
                  isLoading={!!isCustomEventsLinechartLoading}
                  onCustomTimespanSelection={onCustomTimespanSelectionChange}
                  customTimespanSuggestion={customTimespanSuggestion}
                  extraButtonTitle='Übersicht öffnen'
                  extraButtonCallback={(): void => setCustomAnalyticsEventsDialogOpen(true)}
                />
              </BaseCard>
            </Grid>
          </>
        )}

        {/* Only if NLU primary */}
        {bot && hasModule(MODULE_TYPE_NLU) && (
          <>
            <Grid item md={12}>
              <Typography className={classes.moduleHeader} variant='h2'>
                Wissensdatenbank
              </Typography>
            </Grid>
            <Grid item sm={12} md={6}>
              <BaseCard width='100%' height='auto'>
                <AnalyticsChart
                  id={CHART_NAMES_MAPPING.answeredQuestionsRatioLinechart}
                  shouldReload={shouldReload}
                  title='Anteil beantworteter Fragen'
                  titleValue={answeredQuestionRatioLinechartData.titleValue}
                  titleValueChar='%'
                  switchTitleValueAndTitleValueChar={true}
                  chartType='linechart'
                  linechartXValues={answeredQuestionRatioLinechartData.xValues}
                  linechartYValues={answeredQuestionRatioLinechartData.ySeries}
                  onLoadDataCallback={loadAnalytics}
                  scopes={answeredQuestionRatioLinechartData.scopes}
                  granularity={answeredQuestionRatioLinechartData.detailLevel}
                  isLoading={!!isAnsweredQuestionRatioLinechartLoading}
                  scaleType='percent'
                  formatTooltipValue={formatAnsweredQuestionRatioChartTooltipValue}
                  onCustomTimespanSelection={onCustomTimespanSelectionChange}
                  customTimespanSuggestion={customTimespanSuggestion}
                />
              </BaseCard>
            </Grid>
            {/* Only if NLU primary */}
            <Grid item sm={12} md={6}>
              <BaseCard width='100%' height='auto'>
                <AnalyticsChart
                  id={CHART_NAMES_MAPPING.helpfulAnswerRatioLinechart}
                  shouldReload={shouldReload}
                  title='Anteil hilfreicher Antworten'
                  titleValue={helpfulAnswerRatioLinechartData.titleValue}
                  titleValueChar='%'
                  switchTitleValueAndTitleValueChar={true}
                  chartType='linechart'
                  linechartXValues={helpfulAnswerRatioLinechartData.xValues}
                  linechartYValues={helpfulAnswerRatioLinechartData.ySeries}
                  onLoadDataCallback={loadAnalytics}
                  scopes={helpfulAnswerRatioLinechartData.scopes}
                  granularity={helpfulAnswerRatioLinechartData.detailLevel}
                  isLoading={!!isHelpfulAnswerRatioLinechartLoading}
                  scaleType='percent'
                  formatTooltipValue={formatHelpfulAnswerRatioChartTooltipValue}
                  onCustomTimespanSelection={onCustomTimespanSelectionChange}
                  customTimespanSuggestion={customTimespanSuggestion}
                />
              </BaseCard>
            </Grid>

            <Grid item sm={12} md={12}>
              <BaseCard width='100%' height='auto'>
                <AnalyticsChart
                  id={CHART_NAMES_MAPPING.mostUsedAndHelpfulTopicsMindMap}
                  height='auto'
                  shouldReload={shouldReload}
                  title={SCOPE_NAME_MAPPING['most_asked_topics']}
                  titleValue={{
                    primaryValue: isMostUsedAndHelpfulMindMapLoading
                      ? ' - '
                      : mostUsedAndHelpfulMindMapData.length > 0
                      ? mostUsedAndHelpfulMindMapData[0].title
                      : ' - ',
                  }}
                  titleValueChar=''
                  chartType='table'
                  onLoadDataCallback={loadAnalytics}
                  scopes={['most_asked_topics']}
                  granularity={'hour'}
                  isLoading={!!isMostUsedAndHelpfulMindMapLoading}
                  headers={['Thema', 'Antwort', '# Fragen', '', '']}
                  cellAlignPattern={['left', 'left', 'right', 'right', 'right']}
                  width={['auto', '60%', '100px', '32px', '32px']}
                  onCustomTimespanSelection={onCustomTimespanSelectionChange}
                  customTimespanSuggestion={customTimespanSuggestion}
                  rows={(mostUsedAndHelpfulMindMapData ?? []).map((data, idx) => {
                    return [
                      <>
                        <Typography key={`answer-topic-${idx}`} variant='small'>
                          {data.topic ?? ''}
                        </Typography>
                        <Typography key={`answer-title-${idx}`}>{data.title}</Typography>
                      </>,
                      <Typography key={`answer-answer-${idx}`} className={classes.markdown}>
                        <ReactMarkdown>{data.answer}</ReactMarkdown>
                      </Typography>,
                      <Typography key={`answer-amount-${idx}`}>{data.amountPredicted}</Typography>,
                      <>
                        {typeof data.helpfulRatio === 'number' && (
                          <CustomizedTooltip
                            key={`answer-helpful-${idx}`}
                            placement='top'
                            content={
                              <Typography>
                                {Math.round(data.helpfulRatio * 100)}% hilfreich von {data.amountRated}{' '}
                                {data.amountRated === 0 || data.amountRated > 1 ? `Bewertungen` : `Bewertung`}
                              </Typography>
                            }
                            elements={
                              <div
                                id={`answer-helpful-ratio-${idx}`}
                                onClick={(): void => {
                                  setMostUsedAndHelpfulDialogIndex(idx)
                                  setMostUsedAndHelpfulDialogOpen(true)
                                }}
                                className={classes.pointer}
                              >
                                {typeof data.helpfulRatio === 'number' &&
                                  (data.helpfulRatio < 0.2 ? (
                                    <Sad />
                                  ) : data.helpfulRatio < 0.6 ? (
                                    <Neutral />
                                  ) : (
                                    <Happy />
                                  ))}
                              </div>
                            }
                          />
                        )}
                      </>,
                      <CustomizedTooltip
                        key={`answer-action-${idx}`}
                        placement='top'
                        disableInteractive
                        content={<Typography>Antwort öffnen</Typography>}
                        elements={
                          <IconButton
                            onClick={(): void => openAnswer(data.answerId)}
                            aria-label='open'
                            className={classes.iconButton + ' ' + classes.iconButtonStandard}
                            disabled={data.answerId === ''}
                          >
                            <i className={'ri-arrow-right-line ' + classes.icon}></i>
                          </IconButton>
                        }
                      />,
                    ]
                  })}
                />
              </BaseCard>
            </Grid>
          </>
        )}
        {/* XZuFi Module Analytics - only if xzufi modules exists and then one for each config */}
        {/* FIXME currently only supports first found xzufi module -> see comment at top of component */}
        {bot &&
          hasModule(MODULE_TYPE_XZUFI) &&
          // NOTE: see comment at top of component - remove array access etc. for all xzufi modules to be rendered
          [Object.values(bot.modules).filter((module) => module.type === MODULE_TYPE_XZUFI)[0]].map((module) => {
            const _module = module as unknown as XzufiModule
            return (
              <>
                <Grid item md={12} key={'xzufi'}>
                  <Typography className={classes.moduleHeader} variant='h2'>
                    XZuFi{' '}
                    {_module.domain ? `(${_module.domain.charAt(0).toUpperCase() + _module.domain.slice(1)})` : ''}
                  </Typography>
                </Grid>
                {/* XZUFI answered queries */}
                <Grid item sm={12} md={6}>
                  <BaseCard width='100%' height='auto'>
                    <AnalyticsChart
                      id={CHART_NAMES_MAPPING.xzufiAnsweredQuestionsRatioLinechart}
                      shouldReload={shouldReload}
                      title={SCOPE_NAME_MAPPING.xzufi_answered_question_ratio}
                      titleValue={xzufiAnsweredQuestionRatioLinechartData.titleValue}
                      titleValueChar='%'
                      switchTitleValueAndTitleValueChar={true}
                      chartType='linechart'
                      linechartXValues={xzufiAnsweredQuestionRatioLinechartData.xValues}
                      linechartYValues={xzufiAnsweredQuestionRatioLinechartData.ySeries}
                      onLoadDataCallback={loadAnalytics}
                      scopes={xzufiAnsweredQuestionRatioLinechartData.scopes}
                      granularity={xzufiAnsweredQuestionRatioLinechartData.detailLevel}
                      isLoading={!!isXzufiAnsweredQuestionRatioLinechartLoading}
                      scaleType='percent'
                      formatTooltipValue={formatAnsweredQuestionRatioChartTooltipValue}
                      onCustomTimespanSelection={onCustomTimespanSelectionChange}
                      customTimespanSuggestion={customTimespanSuggestion}
                    />
                  </BaseCard>
                </Grid>
                {/* XZUFI ratio helpful answers */}
                <Grid item sm={12} md={6}>
                  <BaseCard width='100%' height='auto'>
                    <AnalyticsChart
                      id={CHART_NAMES_MAPPING.xzufiHelpfulAnswerRatioLinechart}
                      shouldReload={shouldReload}
                      title={SCOPE_NAME_MAPPING.xzufi_helpful_answer_ratio}
                      titleValue={xzufiHelpfulAnswerRatioLinechartData.titleValue}
                      titleValueChar='%'
                      switchTitleValueAndTitleValueChar={true}
                      chartType='linechart'
                      linechartXValues={xzufiHelpfulAnswerRatioLinechartData.xValues}
                      linechartYValues={xzufiHelpfulAnswerRatioLinechartData.ySeries}
                      onLoadDataCallback={loadAnalytics}
                      scopes={xzufiHelpfulAnswerRatioLinechartData.scopes}
                      granularity={xzufiHelpfulAnswerRatioLinechartData.detailLevel}
                      isLoading={!!isXzufiHelpfulAnswerRatioLinechartLoading}
                      scaleType='percent'
                      formatTooltipValue={formatHelpfulAnswerRatioChartTooltipValue}
                      onCustomTimespanSelection={onCustomTimespanSelectionChange}
                      customTimespanSuggestion={customTimespanSuggestion}
                    />
                  </BaseCard>
                </Grid>
                {/* XZUFI asked Leistungen */}
                <Grid item sm={12} md={6}>
                  <BaseCard width='100%' height='auto'>
                    <AnalyticsChart
                      id={CHART_NAMES_MAPPING.xzufiQueriesLinechart}
                      shouldReload={shouldReload}
                      title={SCOPE_NAME_MAPPING.xzufi_all_questions}
                      chartType='linechart'
                      titleValue={xzufiQueriesLinechartData.titleValue}
                      linechartXValues={xzufiQueriesLinechartData.xValues}
                      linechartYValues={xzufiQueriesLinechartData.ySeries}
                      onLoadDataCallback={loadAnalytics}
                      scopes={xzufiQueriesLinechartData.scopes}
                      granularity={xzufiQueriesLinechartData.detailLevel}
                      isLoading={!!isXzufiQueriesLinechartLoading}
                      onCustomTimespanSelection={onCustomTimespanSelectionChange}
                      customTimespanSuggestion={customTimespanSuggestion}
                    />
                  </BaseCard>
                </Grid>
                {/* XZUFI most asked leistungen */}
                <Grid item sm={12} md={6}>
                  <BaseCard width='100%' height='auto'>
                    <AnalyticsChart
                      id={CHART_NAMES_MAPPING.xzufiMostAskedTopicTable}
                      height='auto'
                      shouldReload={shouldReload}
                      title={SCOPE_NAME_MAPPING.xzufi_most_asked_topics}
                      titleValue={{
                        primaryValue: isXzufiMostAskedTopicsLoading
                          ? ' - '
                          : xzufiMostAskedTopicsData.length > 0 && xzufiMostAskedTopicsData[0].currentLeistung
                          ? xzufiMostAskedTopicsData[0].currentLeistung.title
                          : ' - ',
                      }}
                      titleValueChar=''
                      chartType='table'
                      onLoadDataCallback={loadAnalytics}
                      scopes={['xzufi_asked_questions']}
                      granularity={'hour'}
                      isLoading={!!isXzufiMostAskedTopicsLoading}
                      headers={['Leistung', 'Anfragen', 'Feedback']}
                      cellAlignPattern={['left', 'right', 'right']}
                      width={['auto', '60%', '150px', '32px']}
                      onCustomTimespanSelection={onCustomTimespanSelectionChange}
                      customTimespanSuggestion={customTimespanSuggestion}
                      rows={(xzufiMostAskedTopicsData ?? []).map((data, idx) => {
                        return [
                          <>
                            <Typography key={`answer-Leistung-${idx}`}>
                              <strong>{data.currentLeistung ? data.currentLeistung.title : ''}</strong>
                              {data.leistungsId && <Chip className={classes.chipId} label={data.leistungsId} />}
                            </Typography>
                          </>,
                          // <Typography key={`answer-answer-${idx}`} className={classes.markdown}>
                          //   <ReactMarkdown>{data.answer}</ReactMarkdown>
                          // </Typography>,
                          <Typography key={`answer-amount-${idx}`}>{data.count}</Typography>,
                          <>
                            {typeof data.feedback_ratio === 'number' && (
                              <CustomizedTooltip
                                key={`answer-helpful-${idx}`}
                                placement='top'
                                content={
                                  <Typography>
                                    {Math.round(data.feedback_ratio * 100)}% hilfreich von{' '}
                                    {data.feedback_not_helpful + data.feedback_helpful}{' '}
                                    {data.feedback_not_helpful + data.feedback_helpful === 0 ||
                                    data.feedback_not_helpful + data.feedback_helpful > 1
                                      ? `Bewertungen`
                                      : `Bewertung`}
                                  </Typography>
                                }
                                elements={
                                  <div
                                    id={`answer-helpful-ratio-${idx}`}
                                    // onClick={(): void => {
                                    //   setMostUsedAndHelpfulDialogIndex(idx)
                                    //   setMostUsedAndHelpfulDialogOpen(true)
                                    // }}
                                    className={classes.emojiml}
                                  >
                                    {typeof data.feedback_ratio === 'number' &&
                                      (data.feedback_ratio < 0.2 ? (
                                        <Sad />
                                      ) : data.feedback_ratio < 0.6 ? (
                                        <Neutral />
                                      ) : (
                                        <Happy />
                                      ))}
                                  </div>
                                }
                              />
                            )}
                          </>,
                        ]
                      })}
                    />
                  </BaseCard>
                </Grid>
                {/* XZUFI all queries */}
                <Grid item sm={12} md={12}>
                  <BaseCard width='100%' height='auto'>
                    <AnalyticsChart
                      id={CHART_NAMES_MAPPING.xzufiQueriesTable}
                      height='auto'
                      shouldReload={shouldReload}
                      title={SCOPE_NAME_MAPPING.xzufi_asked_questions}
                      titleValue={{
                        primaryValue: isXzufiAskedTopicsLoading
                          ? ' - '
                          : typeof xzufiAskedTopicsData !== 'undefined'
                          ? xzufiAskedTopicsData.length + ''
                          : ' - ',
                      }}
                      chartType='table'
                      onLoadDataCallback={loadAnalytics}
                      scopes={['xzufi_asked_questions']}
                      granularity={'hour'}
                      isLoading={!!isXzufiAskedTopicsLoading}
                      headers={['Frage', 'Leistung', 'Feedback', 'Zeitpunkt']}
                      cellAlignPattern={['left', 'left', 'right', 'right']}
                      width={['50%', 'auto', '100px', '100px']}
                      onCustomTimespanSelection={onCustomTimespanSelectionChange}
                      customTimespanSuggestion={customTimespanSuggestion}
                      rows={(xzufiAskedTopicsData ?? []).map((data, idx) => {
                        return [
                          <>
                            <Typography key={`answer-Leistung-${idx}`}>&quot;{data.query}&quot;</Typography>
                          </>,
                          // <Typography key={`answer-answer-${idx}`} className={classes.markdown}>
                          //   <ReactMarkdown>{data.answer}</ReactMarkdown>
                          // </Typography>,
                          <Typography key={`answer-amount-${idx}`}>
                            {data.currentLeistung && data.currentLeistung.title ? (
                              <strong>{data.currentLeistung.title}</strong>
                            ) : data.feedback && data.feedback === 'noAnswerFound' ? (
                              <Chip label='Keine Antwort gefunden' />
                            ) : (
                              <Chip label='Kein Feedback' />
                            )}
                            {data.leistungsId && <Chip className={classes.chipId} label={data.leistungsId} />}
                          </Typography>,
                          <>
                            <div
                              id={`answer-helpful-ratio-${idx}`}
                              // onClick={(): void => {
                              //   setMostUsedAndHelpfulDialogIndex(idx)
                              //   setMostUsedAndHelpfulDialogOpen(true)
                              // }}
                              className={classes.emojiml}
                            >
                              {typeof data.textFeedback === 'string' && (
                                <CustomizedTooltip
                                  key={`answer-textFeedback-${idx}`}
                                  placement='top'
                                  content={<Typography>{data.textFeedback}</Typography>}
                                  elements={
                                    <div
                                      id={`answer-textFeedback-icon-${idx}`}
                                      style={{ marginRight: '16px' }}
                                      className={classes.pointer}
                                    >
                                      <Comment />
                                    </div>
                                  }
                                />
                              )}
                              {typeof data.feedback === 'string' ? (
                                data.feedback === 'notHelpful' ? (
                                  <Sad />
                                ) : data.feedback === 'noAnswerFound' ? (
                                  <Neutral />
                                ) : (
                                  <Happy />
                                )
                              ) : (
                                <Neutral />
                              )}
                            </div>
                          </>,
                          <Typography variant='small' key={`answer-date-${idx}`}>
                            {/* {new Date(data.timestamp).toLocaleDateString('de-DE', {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                            })} */}
                            {new Date(data.timestamp).toLocaleString('de-DE')}
                          </Typography>,
                        ]
                      })}
                    />
                  </BaseCard>
                </Grid>
              </>
            )
          })}

        {/* ALEPH ALPHA module - only if aleph-alpha module exists */}
        {bot && hasModule(MODULE_TYPE_ALEPHALPHA) && (
          <>
            <Grid item md={12} key={'aleph-alpha'}>
              <Typography className={classes.moduleHeader} variant='h2'>
                Conversational Analytics
              </Typography>
            </Grid>
            <Grid item md={12} lg={6}>
              <BaseCard width='100%' height='auto'>
                <AnalyticsStat
                  id={CHART_NAMES_MAPPING.alephAlphaQueriesStat}
                  chartType='stat'
                  onLoadDataCallback={loadAnalytics}
                  shouldReload={shouldReload}
                  scopes={CHART_SCOPE_MAPPING[CHART_NAMES_MAPPING.alephAlphaQueriesStat]}
                  title={SCOPE_NAME_MAPPING.aleph_alpha_queries_all}
                  titleStat={
                    alephAlphaQueriesStatData && alephAlphaQueriesStatData.length > 0
                      ? alephAlphaQueriesStatData[0]
                      : null
                  }
                  subStats={
                    alephAlphaQueriesStatData && alephAlphaQueriesStatData.length > 1
                      ? alephAlphaQueriesStatData.slice(1)
                      : undefined
                  }
                  isLoading={!!isAlephAlphaQueriesStatLoading}
                  onCustomTimespanSelection={onCustomTimespanSelectionChange}
                  customTimespanSuggestion={customTimespanSuggestion}
                  height='230px'
                />
              </BaseCard>
            </Grid>
            <Grid item md={12} lg={6}>
              <BaseCard width='100%' height='auto'>
                <AnalyticsStat
                  id={CHART_NAMES_MAPPING.alephAlphaAnswersRatioStat}
                  chartType='stat'
                  onLoadDataCallback={loadAnalytics}
                  shouldReload={shouldReload}
                  scopes={CHART_SCOPE_MAPPING[CHART_NAMES_MAPPING.alephAlphaAnswersRatioStat]}
                  title={SCOPE_NAME_MAPPING.aleph_alpha_queries_answered_ratio}
                  titleStat={
                    alephAlphaAnswerRatioStatData && alephAlphaAnswerRatioStatData.length > 0
                      ? alephAlphaAnswerRatioStatData[0]
                      : null
                  }
                  subStats={
                    alephAlphaAnswerRatioStatData && alephAlphaAnswerRatioStatData.length > 1
                      ? alephAlphaAnswerRatioStatData.slice(1)
                      : undefined
                  }
                  isLoading={!!isAlephAlphaAnswerRatioStatLoading}
                  onCustomTimespanSelection={onCustomTimespanSelectionChange}
                  customTimespanSuggestion={customTimespanSuggestion}
                  height='230px'
                />
              </BaseCard>
            </Grid>
            <Grid item md={12} lg={6}>
              <BaseCard width='100%' height='auto'>
                <AnalyticsStat
                  id={CHART_NAMES_MAPPING.alephAlphaFeedbackRatioStat}
                  chartType='stat'
                  onLoadDataCallback={loadAnalytics}
                  shouldReload={shouldReload}
                  scopes={CHART_SCOPE_MAPPING[CHART_NAMES_MAPPING.alephAlphaFeedbackRatioStat]}
                  title={SCOPE_NAME_MAPPING.aleph_alpha_feedback_positive_ratio}
                  titleStat={
                    alephAlphaFeedbackRatioStatData && alephAlphaFeedbackRatioStatData.length > 0
                      ? alephAlphaFeedbackRatioStatData[0]
                      : null
                  }
                  subStats={
                    alephAlphaFeedbackRatioStatData && alephAlphaFeedbackRatioStatData.length > 1
                      ? alephAlphaFeedbackRatioStatData.slice(1)
                      : undefined
                  }
                  isLoading={!!isAlephAlphaFeedbackRatioStatLoading}
                  onCustomTimespanSelection={onCustomTimespanSelectionChange}
                  customTimespanSuggestion={customTimespanSuggestion}
                  height='230px'
                />
              </BaseCard>
            </Grid>
            <Grid item md={12} lg={6}>
              <BaseCard width='100%' height='auto'>
                <AnalyticsStat
                  id={CHART_NAMES_MAPPING.alephAlphaSessionStat}
                  chartType='stat'
                  onLoadDataCallback={loadAnalytics}
                  shouldReload={shouldReload}
                  scopes={CHART_SCOPE_MAPPING[CHART_NAMES_MAPPING.alephAlphaSessionStat]}
                  title={SCOPE_NAME_MAPPING.aleph_alpha_sessions}
                  titleStat={
                    alephAlphaSessionsStatData && alephAlphaSessionsStatData.length > 0
                      ? alephAlphaSessionsStatData[0]
                      : null
                  }
                  subStats={
                    alephAlphaSessionsStatData && alephAlphaSessionsStatData.length > 1
                      ? alephAlphaSessionsStatData.slice(1)
                      : undefined
                  }
                  isLoading={!!isAlephAlphaSessionsStatLoading}
                  onCustomTimespanSelection={onCustomTimespanSelectionChange}
                  customTimespanSuggestion={customTimespanSuggestion}
                  height='230px'
                />
              </BaseCard>
            </Grid>
            <Grid item sm={12} md={12}>
              <BaseCard width='100%' height='auto'>
                <AnalyticsChart
                  id={CHART_NAMES_MAPPING.alephAlphaInquiriesTable}
                  height='auto'
                  shouldReload={shouldReload}
                  title={SCOPE_NAME_MAPPING['aleph_alpha_initial_inquiries']}
                  titleValue={{
                    primaryValue: '',
                  }}
                  titleValueChar=''
                  chartType='table'
                  onLoadDataCallback={loadAnalytics}
                  scopes={['aleph_alpha_initial_inquiries']}
                  granularity={'hour'}
                  isLoading={!!isAlephAlphaInquiriesLoading}
                  headers={['Frage', 'Datum']}
                  cellAlignPattern={['left', 'left']}
                  width={['auto', '11%']}
                  onCustomTimespanSelection={onCustomTimespanSelectionChange}
                  customTimespanSuggestion={customTimespanSuggestion}
                  rows={(alephAlphaInquiriesData ?? []).map((data, idx) => {
                    return [
                      <Typography key={`aa-inquiry-${idx}`}>{data.inquiry ?? ''}</Typography>,
                      <Typography key={`aa-inquiry-date-${idx}`} variant='small'>
                        {getEuropeanDateTimeString(new Date(data.timestamp))}
                      </Typography>,
                    ]
                  })}
                />
              </BaseCard>
            </Grid>
          </>
        )}
      </Grid>
      {/* Dialogs */}
      <Dialog
        id='questions-dialog'
        size='medium'
        open={mostUsedAndHelpfulDialogOpen}
        closable={true}
        aria-describedby='questions-dialog'
        onClose={(): void => setMostUsedAndHelpfulDialogOpen(false)}
        // primaryActionButton={
        //   <Button size='small' type='normal' onClick={onUpgradeConfirm}>
        //     Update
        //   </Button>
        // }
        // secondaryActionText='Abbrechen'
        // onSecondaryActionClick={onUpgradeCancel}
      >
        {mostUsedAndHelpfulDialogOpen && typeof mostUsedAndHelpfulDialogIndex !== 'undefined' && (
          <Table
            headers={['Fragen']}
            disablePagination={true}
            rows={(mostUsedAndHelpfulMindMapData[mostUsedAndHelpfulDialogIndex ?? 0].questions ?? []).map(
              (question, index) => {
                return [<Typography key={`mostasked-dialog-${index}`}>{question}</Typography>]
              },
            )}
          />
        )}
      </Dialog>
      <Dialog
        id='analytics-events-dialog'
        size='medium'
        open={customAnalyticsEventsDialogOpen}
        closable={true}
        aria-describedby='analytics-events-dialog'
        onClose={(): void => setCustomAnalyticsEventsDialogOpen(false)}
        // primaryActionButton={
        //   <Button size='small' type='normal' onClick={onUpgradeConfirm}>
        //     Update
        //   </Button>
        // }
        // secondaryActionText='Abbrechen'
        // onSecondaryActionClick={onUpgradeCancel}
      >
        <Typography>
          Die ist eine Übersicht über alle angelegten benutzerdefinerten Analytic Events. Die Anzahl gibt Ihnen einen
          Überblick wie häufig das jeweilige Event in Dialogen vorkommt.
        </Typography>
        {customAnalyticsEventsDialogOpen && typeof customAnalyticsEvents !== 'undefined' && (
          <Table
            headers={['Event Name', '# Blöcke', '']}
            cellAlignPattern={['left', 'right', 'right']}
            width={['auto', '150px', '75px']}
            padding='medium'
            disablePagination={true}
            rows={Object.values(customAnalyticsEvents).map((event, index) => {
              return [
                <Typography key={`customAnalyticsEvent-name-${index}`}>
                  {transformEventName(event.eventName)}
                </Typography>,
                <Typography key={`customAnalyticsEvent-count-${index}`}>{event.nodeIds.length}</Typography>,
                <>
                  {event.nodeIds.length !== 0 && (
                    <CustomizedTooltip
                      key={`open-analytics-event-${index}`}
                      content={<Typography>Event im Dialog Designer suchen.</Typography>}
                      elements={
                        <IconButton
                          className={classes.iconButton + ' ' + classes.iconButtonStandard}
                          aria-label='Open'
                          onClick={(): void => openCustomEvent(event.eventName)}
                          disabled={event.nodeIds.length === 0}
                        >
                          <i className={'ri-arrow-right-line ' + classes.icon} />
                        </IconButton>
                      }
                      placement='top'
                      disableInteractive
                    />
                  )}
                </>,
              ]
            })}
          />
        )}
      </Dialog>
    </>
  )
}
