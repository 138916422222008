import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { cloneDeep } from 'lodash'
// @mui/material
import { StyledEngineProvider } from '@mui/material'
import Paper from '@mui/material/Paper'
import { makeStyles } from 'tss-react/mui'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
// custom components
import PDFViewer from '../../components/PDFViewer/PDFViewer'
import PDFToolbar from '../../components/Toolbars/PDFToolbar'
import { IngestPDFResult } from '../../classes/Ingest'
import HorizontalStepper from '../../components/Stepper/HorizontalStepper'
// views
import InfotextsView from './views/InfotextsView'
import SectionsView from './views/SectionsView'
import FieldsView from './views/FieldsView'
import GenerationView from './views/GenerationView'
import { Signature, SyncedSignature } from '../../@types/Ingest/types'
import { useBotContext } from 'hooks/contexts/bot-context'
import { BotInfos } from '../../@types/BotInformation/types'
import { ROUTE_BOTID, ROUTE_BOTID_ANALYTICS, ROUTE_BOTS } from 'utils/constants'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    // maxWidth: '100%',
    width: '100%',
    // maxHeight: 'calc(100vh - 90px)',
    // height: 'calc(100vh - 90px)',
    margin: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    maxHeight: 'inherit',
    overflow: 'hidden',
    display: 'flex',
    height: '100%',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    flexDirection: 'column',
  },
  toolbarPaper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    maxHeight: 'inherit',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  pdfViewerContainer: {
    height: '100%',
    maxHeight: 'inherit',
    flex: '48',
  },
  pdfToolbarContainer: {
    // height: '100%',
    flex: '4',
  },
  pdfIngestContainer: {
    height: '100%',
    flex: '48',
  },
}))

type buttonProps = {
  active: boolean
  disabled: boolean
  enforced: boolean
}

type IngestProps = {}

function getInitialPDFIngestResult(): IngestPDFResult {
  const result = new IngestPDFResult()
  // TODO: all all fields
  // FIXME: check if field references in the result file need to be updated somewhere
  // result.addSection(new IngestPDFSection('section', { x: 0, y: 0, w: 500, h: 800 }, [], ['subsection'], 'Section 1'))
  // result.addSubSection(
  //   new IngestPDFSubSection('subsection', { x: 25, y: 25, w: 450, h: 750 }, [], 1, ['section'], 'Subsection 1')
  // )
  return result
}

// TODO: be able to skip uplaod even after "upload" was choosen in the first dialog - means have a way to go back to that decision
// TODO: Handle window resize event -> must update all synced coordinates & dimensions in ingestPDFResult - should we handle this here or in PDFViewer? ~ Stefan 11.11.2020
function IngestPage(): React.ReactElement {
  const { classes } = useStyles()
  const navigate = useNavigate()
  const { bot } = useBotContext() as { bot: BotInfos } // explicit casting because if this component is shown, the bot is set in the context
  const botId = bot?.id

  const [stage, setStage] = useState('sections')
  const [step, setStep] = useState<number>(0)
  // file
  const [file, setFile] = useState<File | null>(null)
  const [signature, setSignature] = useState<Signature | null>(null)
  const [syncedSignature, setSyncedSignature] = useState<SyncedSignature | null>(null)
  // Toolbar buttons
  const [selectionState, setSelectionState] = useState<buttonProps>({ active: false, disabled: true, enforced: false })
  const [areaState, setAreaState] = useState<buttonProps>({ active: false, disabled: true, enforced: false })
  const [textState, setTextState] = useState<buttonProps>({ active: false, disabled: true, enforced: false })
  const [groupState, setGroupState] = useState<buttonProps>({ active: false, disabled: true, enforced: false })
  // IngestPDFResult
  const [ingestPDFResult, _setIngestPDFResult] = useState<IngestPDFResult>(getInitialPDFIngestResult())
  const [hasFile, setHasFile] = useState<boolean>(false)
  // Step callbacks
  const [isSectionDone, setIsSectionDone] = useState<number>(0)
  const [isInfoTextDone, setIsInfotextDone] = useState<number>(0)
  const [isFieldDone, setIsFieldDone] = useState<number>(0)

  const [generatingFlow, setGeneratingFlow] = useState<number>(0)
  const [skipUploadDialog, setSkipUploadDialog] = useState<boolean>(true)

  function setToolbarToStep(step: string): void {
    if (file !== null && (step === 'sections' || step === 'subsections')) {
      setSelectionState({ active: false, disabled: true, enforced: false })
      setAreaState({ active: true, disabled: false, enforced: true })
      setTextState({ active: false, disabled: true, enforced: false })
      setGroupState({ active: false, disabled: true, enforced: false })
    } else if (step === 'infotexts') {
      setSelectionState({ active: false, disabled: true, enforced: false })
      setAreaState({ active: false, disabled: true, enforced: false })
      setTextState({ active: true, disabled: false, enforced: true })
      setGroupState({ active: false, disabled: true, enforced: false })
    }
  }

  // _____ USE EFFECTS _____

  useEffect(() => {
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_BRANCH === 'development' && console.info(ingestPDFResult)
  }, [ingestPDFResult])

  useEffect(() => {
    switch (stage) {
      case 'sections':
      case 'subsections':
        setStep(0)
        break
      case 'infotexts':
        setStep(1)
        break
      case 'fields':
        setStep(2)
        break
      case 'generation':
        setStep(3)
        break
      default:
        break
    }
    setToolbarToStep(stage)
  }, [stage])

  // ____ TOOLBAR ____
  function onChangeToolBar(elementString: string): void {
    // simplest and prop most naive solution
    switch (elementString) {
      case 'selection':
        setSelectionState(
          (prevState): buttonProps => ({ active: !prevState.active, disabled: prevState.disabled, enforced: false }),
        )
        setAreaState((prevState): buttonProps => ({ active: false, disabled: prevState.disabled, enforced: false }))
        setTextState((prevState): buttonProps => ({ active: false, disabled: prevState.disabled, enforced: false }))
        setGroupState((prevState): buttonProps => ({ active: false, disabled: prevState.disabled, enforced: false }))
        break
      case 'area':
        setSelectionState(
          (prevState): buttonProps => ({ active: false, disabled: prevState.disabled, enforced: false }),
        )
        setAreaState(
          (prevState): buttonProps => ({ active: !prevState.active, disabled: prevState.disabled, enforced: false }),
        )
        setTextState((prevState): buttonProps => ({ active: false, disabled: prevState.disabled, enforced: false }))
        setGroupState((prevState): buttonProps => ({ active: false, disabled: prevState.disabled, enforced: false }))
        break
      case 'text':
        setSelectionState(
          (prevState): buttonProps => ({ active: false, disabled: prevState.disabled, enforced: false }),
        )
        setAreaState((prevState): buttonProps => ({ active: false, disabled: prevState.disabled, enforced: false }))
        setTextState(
          (prevState): buttonProps => ({ active: !prevState.active, disabled: prevState.disabled, enforced: false }),
        )
        setGroupState((prevState): buttonProps => ({ active: false, disabled: prevState.disabled, enforced: false }))
        break
      case 'group':
        setSelectionState(
          (prevState): buttonProps => ({ active: false, disabled: prevState.disabled, enforced: false }),
        )
        setAreaState((prevState): buttonProps => ({ active: false, disabled: prevState.disabled, enforced: false }))
        setTextState((prevState): buttonProps => ({ active: false, disabled: prevState.disabled, enforced: false }))
        setGroupState(
          (prevState): buttonProps => ({ active: !prevState.active, disabled: prevState.disabled, enforced: false }),
        )
        break
      default:
        break
    }
  }

  function disableToolbar(elementString?: string): void {
    // simplest and prop most naive solution for "exclusiveness" of the toggle buttons
    switch (elementString) {
      case 'selection':
        setSelectionState({ active: false, disabled: true, enforced: false })
        break
      case 'area':
        setAreaState({ active: false, disabled: true, enforced: false })
        break
      case 'text':
        setTextState({ active: false, disabled: true, enforced: false })
        break
      case 'group':
        setGroupState({ active: false, disabled: true, enforced: false })
        break
      default:
        setSelectionState({ active: false, disabled: true, enforced: false })
        setAreaState({ active: false, disabled: true, enforced: false })
        setTextState({ active: false, disabled: true, enforced: false })
        setGroupState({ active: false, disabled: true, enforced: false })
        break
    }
  }

  // Toolbar should stay deactivated until an pdf is uplaoded
  function activateToolbar(elementString?: string): void {
    switch (elementString) {
      case 'selection':
        setSelectionState({ active: false, disabled: false, enforced: false })
        break
      case 'area':
        setAreaState({ active: false, disabled: false, enforced: false })
        break
      case 'text':
        setTextState({ active: false, disabled: false, enforced: false })
        break
      case 'group':
        setGroupState({ active: false, disabled: false, enforced: false })
        break
      default:
        setToolbarToStep(stage)
        break
    }
  }

  function getActiveToolbar(): 'selection' | 'area' | 'text' | 'group' | 'none' {
    if (selectionState.active) {
      return 'selection'
    } else if (areaState.active) {
      return 'area'
    } else if (textState.active) {
      return 'text'
    } else if (groupState.active) {
      return 'group'
    } else {
      return 'none'
    }
  }

  // ____ HANDLER ____
  const steps = ['Bereiche', 'Infotexte & URLs', 'Felder', 'Abschluss']
  // const steps = ['Bereiche', 'Infotexte & URLs', 'Felder', 'Step 4', 'Step 5', 'Step 6', 'Step 7']

  // async function generateFlowchart(): Promise<void> {
  //   setGeneratingFlow(1)
  //   let chart = await generateFlow(ingestPDFResult, botId, false)
  //   if (!chart) {
  //     console.error('Generate flow failed.')
  //     setTimeout(async () => {
  //       // one retry after 5 seconds
  //       console.error('Retry: Generate flow...')
  //       chart = await generateFlow(ingestPDFResult, botId, false)
  //       if (!chart) {
  //         setGeneratingFlow(3)
  //         alert('Erstellen des Assistenten fehlgeschlagen')
  //       } else {
  //         setGeneratingFlow(2)
  //         navigate(`${ROUTE_BOTS}/${bot.id}${ROUTE_BOTID_ANALYTICS}`)
  //       }
  //     }, 5000)
  //   } else {
  //     setGeneratingFlow(2)
  //     navigate(`${ROUTE_BOTS}/${bot.id}${ROUTE_BOTID_ANALYTICS}`)
  //   }
  // }

  /**
   * Proxy function for setState of ingestPDFResult.
   * This creates a new object in order to properly trigger the useEffect hooks in all children.
   * Using this, we don't have to manually clone the object in the children before we pass it to the callback.
   * @param ingestPDFResult
   */
  function setIngestPDFResult(ingestPDFResult: IngestPDFResult): void {
    const newIngestPDFResult = cloneDeep(ingestPDFResult)
    _setIngestPDFResult(newIngestPDFResult)
  }

  // _____ RENDER _____

  // render dialogs
  function renderDialogs(): React.ReactElement {
    return (
      <Dialog
        fullWidth
        maxWidth='sm'
        open={skipUploadDialog}
        onClose={(event, reason): void => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            setSkipUploadDialog(false)
          }
        }}
        aria-labelledby='dialog-title'
      >
        <DialogTitle id='dialog-title'>{'Dokumenten Import'}</DialogTitle>
        <DialogContent>
          {generatingFlow === 0 && (
            <DialogContentText>
              Möchten Sie ein Dokument hochladen, auf dessen Basis der Konversationsfluss erstellt wird?
              <br />
              <br />
              Wenn Sie kein Dokument hochladen wird ein leerer Konversationsfluss erstellt.
            </DialogContentText>
          )}
          {generatingFlow !== 0 && (
            <div style={{ display: 'flex' }}>
              <CircularProgress size={'1.5rem'} />
              <DialogContentText style={{ marginTop: 'auto', marginBottom: 'auto', paddingLeft: '15px' }}>
                Assistent wird generiert ...
              </DialogContentText>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={generatingFlow !== 0}
            onClick={(): void => {
              navigate(`${ROUTE_BOTS}/${bot.id}${ROUTE_BOTID_ANALYTICS}`)
            }}
          >
            Kein Dokument hochladen
          </Button>
          <Button disabled={generatingFlow !== 0} onClick={(): void => setSkipUploadDialog(false)}>
            Dokument hochladen
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <StyledEngineProvider injectFirst>
      <div className={classes.root}>
        <div className={classes.pdfViewerContainer}>
          <Paper className={classes.paper}>
            <PDFViewer
              stage={stage}
              activateToolbar={activateToolbar}
              disableToolbar={disableToolbar}
              activeTool={getActiveToolbar()}
              setFile={setFile}
              setSignature={setSignature}
              setSyncedSignature={setSyncedSignature}
              ingestPDFResult={ingestPDFResult}
              setIngestPDFResult={setIngestPDFResult}
              setStage={(_stage): void => {
                // eslint-disable-next-line no-undef
                process.env.REACT_APP_BRANCH === 'development' && console.info('Ingest: Setting Stage: ', _stage)
                setStage(_stage)
              }}
              setHasFile={(_hasFile: boolean): void => setHasFile(_hasFile)}
              isSectionDone={isSectionDone}
              isInfoTextDone={isInfoTextDone}
              isFieldDone={isFieldDone}
              setIsInfoTextDone={(done: number): void => setIsInfotextDone(done)}
              setIsSectionDone={(done: number): void => setIsSectionDone(done)}
              setIsFieldDone={(done: number): void => setIsFieldDone(done)}
            />
          </Paper>
        </div>
        <div className={classes.pdfToolbarContainer}>
          <Paper className={classes.toolbarPaper}>
            <PDFToolbar
              selection={selectionState}
              area={areaState}
              text={textState}
              group={groupState}
              onChange={onChangeToolBar}
            />
          </Paper>
        </div>
        <div className={classes.pdfIngestContainer}>
          <Paper className={classes.paper}>
            <HorizontalStepper activeStep={step} steps={steps} />
            {step === 0 && (
              <SectionsView
                hasFile={hasFile}
                stage={stage}
                setIsSectionDone={(done: number): void => setIsSectionDone(done)}
                ingestPDFResult={ingestPDFResult}
                setIngestPDFResult={setIngestPDFResult}
              />
            )}
            {step === 1 && (
              <InfotextsView
                setIsInfotextDone={(done: number): void => setIsInfotextDone(done)}
                ingestPDFResult={ingestPDFResult}
                setIngestPDFResult={setIngestPDFResult}
              />
            )}
            {step === 2 && syncedSignature !== null && (
              <FieldsView
                syncedSignature={syncedSignature}
                setIsFieldDone={(done: number): void => setIsFieldDone(done)}
                ingestPDFResult={ingestPDFResult}
                setIngestPDFResult={setIngestPDFResult}
              />
            )}
            {step === 3 && file !== null && signature !== null && syncedSignature !== null && (
              <GenerationView
                file={file}
                signature={signature}
                syncedSignature={syncedSignature}
                ingestPDFResult={ingestPDFResult}
                setIngestPDFResult={setIngestPDFResult}
              />
            )}
          </Paper>
        </div>
        {/* {renderDialogs()} */}
      </div>{' '}
    </StyledEngineProvider>
  )
}

IngestPage.defaultProps = {}

export default IngestPage
