import React from 'react'

import { Dropdown, Option } from '../../Dropdown/Dropdown'
import { PopupContentBlock } from '../popupBuildingBlocks/PopupContentBlock'
export type DropdownOption = Option

type PopupDropdownProps = {
  title: string
  options: Option[]
  onChange: (event: object, option: Option[], reason: string) => void
  placeholder: string
  multiple?: boolean
  disabled?: boolean
  disableClearable?: boolean
  selectedValues?: DropdownOption[]
}

export function PopupDropdown({
  title,
  options,
  placeholder,
  multiple,
  disabled,
  disableClearable,
  onChange,
  selectedValues,
}: PopupDropdownProps): React.ReactElement {
  // define dropdown
  const DropdownSelect = (
    <Dropdown
      selectedValues={selectedValues}
      placeholder={placeholder}
      options={options}
      onChange={onChange}
      disabled={disabled}
      multiple={multiple}
      filterSelectedOptions
      disableClearable={disableClearable}
    />
  )

  return <PopupContentBlock title={title} content={DropdownSelect} />
}
