import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'

import { v4 as uuid } from 'uuid'
import { isEmpty } from 'lodash'

// @mui/material components
import { TextField, Button, Grid, Typography } from '@mui/material'
import VariablesAutosuggestSelect, { OnChangeOptions } from '../Variables/VariablesAutosuggestSelect'
import { Dropdown } from '../../../../../components/Dropdown/Dropdown'
import ReactSelectDropdown, { ChangeValue, ActionType } from '../../../../../components/Dropdown/ReactSelectDropdown'

import {
  getAllVariablesForSelect,
  prepareDisplayVariableOptions,
  replaceVarIdWithDisplayName,
  replaceVarDisplayNameWithId,
} from '../../../../../utils/chartUtils'

import { Chart, DisplayVariableOption } from '../../../../../@types/Flowchart/types'

const useStyles = makeStyles()({
  selectedDialogContainer: {},
  marginTopLarge: { marginTop: '25px' },
  marginTopMedium: { marginTop: '15px' },
  marginTopSmall: { marginTop: '10px' },
  centerContent: { textAlign: 'center' },
})

type VariableCondition = {
  [variableId: string]: {
    variableId: string
    conditionId: string
  }
}

function generateConditionId(): string {
  return uuid()
}

/**
 * Gets and prepares variable used by conditions of node.
 * Returns {
 *  varId: {
 *    conditionId: "",
 *    varId: varId
 *  },
 *  ...
 * }
 * @param {Object} chart
 */
export function getConditionVariables(chart: Chart): VariableCondition {
  const selectedId = chart.selected?.id
  if (typeof selectedId === 'undefined') return {}
  const selectedNode = chart.nodes[selectedId]
  const returnValue = {}

  if (typeof selectedNode.properties.conditions !== 'undefined') {
    for (const conditionId of Object.keys(selectedNode.properties.conditions)) {
      const varIds = Object.keys(selectedNode.properties.conditions[conditionId])
      varIds.forEach((id) => {
        returnValue[id] = {
          variableId: id,
          conditionId: conditionId,
        }
      })
    }
  }
  return returnValue
}

type SIfElseProps = {
  chart: Chart
  setStateCallback: (chart: Chart) => void
}

export default function SIfElse({ chart, setStateCallback }: SIfElseProps): React.ReactElement {
  const { classes } = useStyles()
  const [description, setDescription] = useState<string>('')
  const [conditionText, setConditionText] = useState<string>('')
  const [varIds, setVarIds] = useState<VariableCondition>({}) // holds variables that should be checked
  const [selectedVarsDisplayOptions, setSelectedVarsDisplayOptions] = useState<DisplayVariableOption[]>([])
  const [allVarOptions, setAllVarOptions] = useState<DisplayVariableOption[]>([])
  const [tmpVarOption, setTmpVarOption] = useState<DisplayVariableOption>() // hold tmp variable that can be added to value textfield

  function setSelectedVariables(varIds: VariableCondition): void {
    // build selected variable options
    const variableIds = Object.keys(varIds)
    const selectedVariableOptions = prepareDisplayVariableOptions(chart, variableIds)

    setVarIds(varIds)
    setSelectedVarsDisplayOptions(selectedVariableOptions)
  }

  // ======= LOGIC ========

  /**
   * Prepares variable value text that is displayed in the textfield.
   *
   * - replaces variable ids with their display name for better usability
   * - IDEA: we could remove all ', ", + etc. that are needed to construct a valid JS string and add them again when setting the text in the chart
   *
   * @param {Chart} chart
   */
  function prepareDisplayValueText(chart: Chart, conditionVariable: VariableCondition): string {
    // const { varIds, loopVarIds } = this.state
    const selectedId = chart.selected?.id
    if (typeof selectedId === 'undefined') return ''
    const selectedNode = chart.nodes[selectedId]

    let value

    const variableIds = Object.keys(conditionVariable)
    // for now this only supports one condition
    if (variableIds.length > 0) {
      const varId = variableIds[0]
      const conditionId = conditionVariable[varId].conditionId

      if (typeof selectedNode.properties.conditions !== 'undefined' && typeof conditionId === 'string') {
        value = selectedNode.properties.conditions[conditionId][varId]
        value = replaceVarIdWithDisplayName(chart, value)
      }
    } else {
      value = ''
    }

    return value
  }

  /**
   * Takes the displayed text and transforms it into the correct format for the flowchart.
   *
   * - replaces variable display name with variable id
   * - sets the condition value in chart
   * @param {string} iter
   */
  function setValueInChart(
    event?: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    newConditionText?: string,
  ): void {
    const selectedId = chart.selected?.id
    if (typeof selectedId === 'undefined' || typeof conditionText === 'undefined' || typeof varIds === 'undefined')
      return

    const conditions = chart.nodes[selectedId].properties.conditions || {}
    const displayValue = typeof newConditionText !== 'undefined' ? newConditionText : conditionText
    if (typeof displayValue === 'string') {
      const value = replaceVarDisplayNameWithId(chart, displayValue)
      const variableIds = Object.keys(varIds || {})
      for (const varId of variableIds) {
        const conditionId = varIds[varId].conditionId
        conditions[conditionId][varId] = value
      }
    }
    chart.nodes[selectedId].properties.conditions = conditions
    setStateCallback(chart)
  }

  /**
   * This function deals with setting the variable under condition in the chart.
   * Variable is registered in the chart by the selection component.
   *
   * @param chart
   * @param variableIds list of ids of the selected variable
   * @param prevSelectedVariableIds
   * @param  options
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function handleSetVar(
    chart: Chart,
    variableIds: string[],
    prevSelectedVariableIds: string[],
    options: OnChangeOptions,
  ): Chart {
    const selectedId = chart.selected?.id
    if (
      typeof selectedId === 'undefined' ||
      typeof varIds === 'undefined' ||
      typeof conditionText === 'undefined' ||
      variableIds.length === 0
    )
      return chart

    // variable ids that have been replaced by new selection
    let oldVarId
    let conditionId
    const replacedVarIds = prevSelectedVariableIds.filter((varId) => !variableIds.includes(varId))
    if (replacedVarIds.length > 0) {
      // we replace existing variable of existing condition
      oldVarId = replacedVarIds[0]
      conditionId = varIds[oldVarId]?.conditionId ?? generateConditionId()
    } else {
      // we add new variable without having a previous one
      conditionId = generateConditionId()
    }

    const newVarId = variableIds[0]
    const selectedNode = chart.nodes[selectedId]

    selectedNode.properties.conditions = selectedNode.properties.conditions || {}

    // get conditionId of variable
    // const variables = Object.keys(varIds)
    // let conditionId
    // if (variables.includes(varId)) {
    //   conditionId = varIds[varId].conditionId
    // } else {
    //   conditionId = generateConditionId()
    // }

    if (!selectedNode.properties.conditions[conditionId]) selectedNode.properties.conditions[conditionId] = {}

    // add value that should be assigned to variable, if already exists
    selectedNode.properties.conditions[conditionId][newVarId] =
      oldVarId && selectedNode.properties.conditions[conditionId][oldVarId]
        ? selectedNode.properties.conditions[conditionId][oldVarId]
        : conditionText ?? ''
    if (oldVarId) {
      delete selectedNode.properties.conditions[conditionId][oldVarId]
    }

    varIds[newVarId] = {
      variableId: newVarId,
      conditionId,
    }
    delete varIds[oldVarId]

    chart.nodes[selectedId] = selectedNode

    setSelectedVariables(varIds)
    return chart
  }

  /**
   * Callback function for variable removal.
   * Removes variable from condition.
   *
   * @param {Chart} chart
   * @param {string} varId id of the removed variable
   * @param {OnChangeOptions} options
   */
  function handleRemoveVar(chart: Chart, varId: string, options: OnChangeOptions): Chart {
    const selectedId = chart.selected?.id
    if (typeof selectedId === 'undefined' || typeof varIds === 'undefined' || typeof conditionText === 'undefined')
      return chart

    let newConditionText = conditionText
    const selectedNode = chart.nodes[selectedId]

    const conditionId = varIds[varId].conditionId
    if (typeof selectedNode.properties.conditions !== 'undefined' && typeof conditionId === 'string') {
      delete selectedNode.properties.conditions[conditionId][varId]
      if (isEmpty(selectedNode.properties.conditions[conditionId]))
        delete selectedNode.properties.conditions[conditionId]
    }
    delete varIds[varId]
    if (options.clear) {
      // clear conditionText if clear
      newConditionText = ''
    }

    chart.nodes[selectedId] = selectedNode
    setConditionText(newConditionText)
    setSelectedVariables(varIds)
    return chart
  }

  // ======= CALLBACK FUNCTIONS ========

  /**
   * Adds selected tmp variable to value text.
   *
   * @param {string} iter loop iteration
   */
  function handleAddTmpVariableToTextfield(): void {
    if (typeof tmpVarOption === 'undefined') return

    let newConditionText = conditionText

    let value = ''
    if (newConditionText) value += newConditionText + " + ', ' + "
    value += `%${chart.variables[tmpVarOption.value].displayName}'`
    newConditionText = value

    // TODO: check if we need useState with callback here (was the case in the class component implementation)
    // this.setState({ loopConditionText: loopConditionText, loopTmpVarId: loopTmpVarId }, () => {
    // this.setVariableValueTextInChart(iter)
    // })
    setConditionText(newConditionText)
    setTmpVarOption(undefined)
    setValueInChart(undefined, newConditionText)
  }

  /**
   * Callback function for variable selection component.
   * @param {Chart} chart
   * @param {string[]} prevSelectedVarIds
   * @param {string[]} selectedVarIds
   * @param {OnChangeOptions} options
   */
  function onVariableChange(
    chart: Chart,
    prevSelectedVarIds: string[],
    selectedVarIds: string[],
    options: OnChangeOptions,
  ): void {
    if (selectedVarIds.length >= prevSelectedVarIds.length) {
      // variable was selected
      chart = handleSetVar(chart, selectedVarIds, prevSelectedVarIds, { iter: options.iter })
    } else {
      // variable was removed
      // find removed varIds
      const removedVarIds = prevSelectedVarIds.filter((varId) => !selectedVarIds.includes(varId))
      for (const varid of removedVarIds) {
        chart = handleRemoveVar(chart, varid, { iter: options.iter, clear: selectedVarIds.length === 0 })
      }
    }
    setStateCallback(chart)
  }

  /**
   * Handles condition change event.
   * @param {React.ChangeEvent<HTMLInputElement>} event
   */
  function onConditionChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setConditionText(event.target.value)
  }

  /**
   * Handles description change event
   * @param {React.ChangeEvent<HTMLInputElement>} event
   */
  function onDescriptionChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setDescription(event.target.value)
  }

  function onDescriptionBlur(): void {
    const selectedId = chart.selected?.id
    if (typeof selectedId === 'undefined') return
    chart.nodes[selectedId].properties.text = description
    setStateCallback(chart)
  }

  /**
   * Handles change in tmp variable dropdown.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function onTmpVariableChange(newValue: ChangeValue, action: ActionType): void {
    // console.log('onTmpVariableChange', newValue)
    if (!newValue)
      // remove
      setTmpVarOption(undefined)
    // select
    else setTmpVarOption(newValue as DisplayVariableOption)
  }

  useEffect(function () {
    const selectedId = chart.selected?.id
    if (typeof selectedId === 'undefined') return

    // might be undefined - that's okay
    const description = chart.nodes[selectedId].properties.text || ''

    // get all used variables
    const selectedVariables = getConditionVariables(chart)

    // prepare variable options
    let allVarOptions = getAllVariablesForSelect(chart)
    allVarOptions = allVarOptions.filter(
      (variable) => !Object.values(selectedVariables).some((v) => v.variableId === variable.value),
    )

    // prepare condition text (replace varid with display name)
    const conditionText = prepareDisplayValueText(chart, selectedVariables)

    setAllVarOptions(allVarOptions)
    setSelectedVariables(selectedVariables)
    setDescription(description)
    setConditionText(conditionText)
  }, [])

  return (
    <div className={classes.selectedDialogContainer}>
      <div>
        <div style={{ marginBottom: '20px' }}>
          <Typography>Beschreibung dieser If-Else Node</Typography>
          <TextField
            id='if-else-description'
            label='Beschreibung'
            value={description}
            fullWidth
            onBlur={onDescriptionBlur}
            onChange={onDescriptionChange}
            margin='normal'
          />
        </div>
        <div className={classes.marginTopLarge}>
          <Typography>Variable deren Bedingung überprüft werden soll auswählen oder erstellen</Typography>
        </div>
        <div className={classes.marginTopSmall}>
          <VariablesAutosuggestSelect chart={chart} onChange={onVariableChange} usageType='consume' />
        </div>
        {Object.keys(varIds).length > 0 && (
          <>
            <div className={classes.marginTopMedium}>
              <Typography>
                Bedingung auf die die ausgewählte Variable geprüft werden soll. Variablen können mit <em>%varId</em>{' '}
                referenziert werden.
              </Typography>
            </div>
            <TextField
              id='if-else-condition'
              label='Bedingung'
              value={conditionText}
              fullWidth
              maxRows={5}
              margin='normal'
              onChange={onConditionChange}
              onBlur={setValueInChart}
            />
            <Grid container direction='row' justifyContent='space-evenly' alignItems='center'>
              <Grid item xs={10}>
                {/* <Dropdown
                  onChange={onTmpVariableChange}
                  options={allVarOptions}
                  selectedValues={typeof tmpVarOption !== 'undefined' ? [tmpVarOption] : []}
                  multiple={false}
                  creatable={false}
                  placeholder='Variable auswählen'
                /> */}
                <ReactSelectDropdown
                  onChange={onTmpVariableChange}
                  options={allVarOptions}
                  selectedOptions={typeof tmpVarOption !== 'undefined' ? [tmpVarOption] : []}
                  isMulti={false}
                  isCreatable={false}
                  isClearable
                  placeholder='Variable auswählen'
                />
              </Grid>
              <Grid item xs={2}>
                <div className={classes.centerContent}>
                  <Button
                    disabled={typeof tmpVarOption === 'undefined'}
                    onClick={handleAddTmpVariableToTextfield}
                    variant='outlined'
                  >
                    Hinzufügen
                  </Button>
                </div>
              </Grid>
            </Grid>
          </>
        )}
      </div>
    </div>
  )
}
