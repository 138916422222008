import { Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material'
import RadioButtons from 'components/RadioButtons/RadioButtons'
import React, { useEffect } from 'react'
import { RAGWebsiteSyncSchedule } from '../../../../../@types/Knowledge/RAG/types'

export type SyncPeriod = 'daily' | 'weekly' | 'bi-weekly' | 'monthly'

type SyncScheduleProps = {
  syncSchedule?: RAGWebsiteSyncSchedule
  setSyncSchedule: (schedule: RAGWebsiteSyncSchedule) => void
}

export default function SyncSchedule({ syncSchedule, setSyncSchedule }: SyncScheduleProps): React.ReactElement {
  const [syncEnabled, setSyncEnabled] = React.useState(false)
  const [radioButtonValue, setRadioButtonValue] = React.useState<SyncPeriod>('monthly')

  function handleSyncEnabledChange(event: React.ChangeEvent<HTMLInputElement>): void {
    if (syncSchedule) setSyncSchedule({ ...syncSchedule, enabled: event.target.checked })
    setSyncEnabled(event.target.checked)
  }

  /**
   * Handles sync schedule change.
   * Depending on the new value, updates the sync schedule accordingly.
   * @param newValue
   */
  function handleScheduleChange(newValue: SyncPeriod): void {
    // get timezone
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    switch (newValue) {
      case 'monthly': {
        const schedule: RAGWebsiteSyncSchedule = {
          enabled: syncEnabled,
          timezone,
          schedule: [
            {
              type: 'monthly',
              days: [1],
              times: ['05:00'],
            },
          ],
        }
        setRadioButtonValue('monthly')
        setSyncSchedule(schedule) // callback
        break
      }
      case 'bi-weekly': {
        const schedule: RAGWebsiteSyncSchedule = {
          enabled: syncEnabled,
          timezone,
          schedule: [
            {
              type: 'monthly',
              days: [1],
              times: ['05:00'],
            },
            {
              type: 'monthly',
              days: [15],
              times: ['05:00'],
            },
          ],
        }
        setRadioButtonValue('monthly')
        setSyncSchedule(schedule) // callback
        break
      }
      case 'weekly': {
        const schedule: RAGWebsiteSyncSchedule = {
          enabled: syncEnabled,
          timezone,
          schedule: [
            {
              type: 'weekly',
              days: ['Sunday'],
              times: ['05:00'],
            },
          ],
        }
        setRadioButtonValue('weekly')
        setSyncSchedule(schedule) // callback
        break
      }
      case 'daily': {
        const schedule: RAGWebsiteSyncSchedule = {
          enabled: syncEnabled,
          timezone,
          schedule: [
            {
              type: 'daily',
              times: ['05:00'],
            },
          ],
        }
        setRadioButtonValue('weekly')
        setSyncSchedule(schedule) // callback
        break
      }
      default:
        throw new Error('unknown sync period')
    }
  }

  useEffect(
    function initSchedule() {
      if (!syncSchedule) {
        // init to monthly
        handleScheduleChange('monthly')
      } else {
        // exists, we set the local state based on the sync schedule
        setSyncEnabled(syncSchedule.enabled)
        if (syncSchedule.schedule[0].type === 'monthly' && syncSchedule.schedule.length == 2) {
          // bi-weekly
          setRadioButtonValue('bi-weekly')
        } else if (syncSchedule.schedule[0].type === 'monthly') {
          // monthly
          setRadioButtonValue('monthly')
        } else if (syncSchedule.schedule[0].type === 'weekly') {
          // weekly
          setRadioButtonValue('weekly')
        } else {
          // daily
          setRadioButtonValue('daily')
        }
      }
    },
    [syncSchedule],
  )

  return (
    <>
      <Typography>
        Diese Website automatisch aktuell halten? Sie können einen regelmäßigen Sync konfigurieren, der Änderungen an
        der Website automatisch in die Wissendatenbank übernimmt.
        <br />
        <br />
        <b>Achtung:</b> Änderungen, die Sie manuell an dem Inhalt der Website vorgenommen haben werden durch die
        automatischen Updates überschrieben! Falls Sie die volle Kontrolle über den Inhalt des Dokuments benötigen,
        deaktivieren Sie den automatischen Sync.
      </Typography>
      <div>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox onChange={handleSyncEnabledChange} />}
            label='Inhalt automatisch aktuell halten'
          />
        </FormGroup>
      </div>

      {syncEnabled ? (
        <RadioButtons
          options={[
            { label: 'Täglich (jeden Tag um 05:00 Uhr)', value: 'daily' },
            { label: 'Wöchentlich (jeden Sonntag um 05:00 Uhr)', value: 'weekly' },
            { label: 'Zweiwöchentlich (jeden zweiten Sonntag um 05:00 Uhr)', value: 'bi-weekly' },
            { label: 'Monatlich (jeden 1. Tag im Monat um 05:00 Uhr)', value: 'monthly' },
          ]}
          selected={radioButtonValue}
          onChange={(event, value) => {
            handleScheduleChange(value as SyncPeriod)
          }}
        />
      ) : null}
    </>
  )
}
