import React, { useEffect, useState } from 'react'

// @mui/material components
import { makeStyles } from 'tss-react/mui'

import { Typography } from '@mui/material'

import { Chart } from '../../../../../@types/Flowchart/types'
import { useBotContext } from 'hooks/contexts/bot-context'
import { Option, SelectDropdown } from 'components/Dropdown'
import VariablesAutosuggestSelect from '../Variables/VariablesAutosuggestSelect'

const useStyles = makeStyles()({
  marginTopMedium: { marginTop: '15px' },
})

type SPDFTextsProps = {
  chart: Chart
  setIsSaveDisabled: (isSaveDisabled: boolean) => void
  setStateCallback: (chart: Chart) => void
}

export default function SPDFForm({ chart, setIsSaveDisabled, setStateCallback }: SPDFTextsProps): React.ReactElement {
  const { classes } = useStyles()
  const { bot } = useBotContext()

  const [selectedVariableId, setSelectedVariableId] = useState<string>()
  const [pdfDocOptions, setPdfDocOptions] = useState<Option[]>([])
  const [selectedOption, setSelectedOption] = useState<string>()

  function onDocumentChange(newValue: Option): void {
    if (!chart.selected.id) return
    chart.nodes[chart.selected.id].properties.documentId = newValue.value
    setSelectedOption(newValue.value)
    setStateCallback({ ...chart })
  }

  function onVariableChange(chart: Chart, prevSelectedVarIds: string[], selectedVarIds: string[]): void {
    if (!chart.selected.id) return

    if (selectedVarIds.length === 0) {
      delete chart.nodes[chart.selected.id].properties.pdfUrlVariable
    } else {
      const varId = selectedVarIds[0]
      chart.nodes[chart.selected.id].properties.pdfUrlVariable = varId
    }
    setStateCallback(chart)
  }

  useEffect(
    function () {
      if (!chart.selected.id || !bot) return

      // prepare options for pdf doc dropdown
      const options: Option[] = []
      for (const doc of Object.values(bot.documentInfos).filter((document) => document.documentType === 'pdf')) {
        options.push({
          value: doc.documentId,
          label: doc.documentTitle,
          sublabel: doc.documentDescription,
        })
      }
      setPdfDocOptions(options)

      if (chart.nodes[chart.selected.id].properties.documentId)
        setSelectedOption(chart.nodes[chart.selected.id].properties.documentId)

      if (chart.nodes[chart.selected.id].properties.pdfUrlVariable)
        setSelectedVariableId(chart.nodes[chart.selected.id].properties.pdfUrlVariable)

      if (
        chart.nodes[chart.selected.id].properties.pdfUrlVariable &&
        chart.nodes[chart.selected.id].properties.documentId
      )
        setIsSaveDisabled(false)
      else setIsSaveDisabled(true)
    },
    [chart],
  )

  return (
    <React.Fragment>
      <Typography className={classes.marginTopMedium}>PDF Dokument wählen</Typography>

      <SelectDropdown
        onChange={onDocumentChange}
        options={pdfDocOptions}
        selected={selectedOption}
        placeholder='PDF Dokument auswählen'
        width='100%'
      />

      <Typography className={classes.marginTopMedium}>Variable zum Speichern der Download-URL</Typography>

      <VariablesAutosuggestSelect
        chart={chart}
        usageType='set'
        isCreatable
        selectedVariableIds={selectedVariableId ? [selectedVariableId] : []}
        onChange={onVariableChange}
        isMulti={false}
      />
    </React.Fragment>
  )
}
