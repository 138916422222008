import { Checkbox, FormControlLabel, FormGroup, Typography, useTheme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import ErrorComponent from '../../../../../../components/Error/Error'
import React, { useEffect, useState } from 'react'
import { SimilarIntents } from '../../../../../../@types/Knowledge/types'
import { getNLUSimilarIntents } from '../../../../../../api/StudioBackend'
import CircularLoading from '../../../../../../components/Loading/CircularLoading'
import Table from '../../../../../../components/Table/Table'
import { useBotContext } from '../../../../../../hooks/contexts/bot-context'
import { useErrorContext } from '../../../../../../hooks/contexts/errorContext'
import { SimilarAnswerState } from '../CreateAnswerDialog'
import Checkmark from '../../../../../../assets/animations/Checkmark'
import CheckImage from '../../../../../assets/img/knowledge/check2.svg'

const useStyles = makeStyles()((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  similarAnswers: { height: '100%', display: 'flex', flexDirection: 'column' },
  similarAnswersTable: {
    marginTop: theme.spacing(3),
  },
  noSimilarAnswers: {
    textAlign: 'center',
    // marginTop: 'auto',
    // marginBottom: 'auto',
    height: '100%',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
  },
  acknowledgeContainer: {
    marginTop: 'auto',
  },
  noSimilarAnswersGraphic: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius:
      typeof theme.shape.borderRadius === 'number' ? theme.shape.borderRadius * 2 : theme.shape.borderRadius,
    padding: theme.spacing(3),
  },
  noSimilarAnswersGraphicText: {
    padding: theme.spacing(3, 3, 0, 3),
  },
  warning: {
    fontSize: '3rem',
    color: theme.palette.warning.main,
    cursor: 'default',
    marginRight: theme.spacing(1),
  },
}))

type SimilarAnswersProps = {
  similarIntents: SimilarIntents
}

function SimilarAnswers({ similarIntents }: SimilarAnswersProps): React.ReactElement {
  return (
    <Table
      headers={['Titel', 'Typ', 'Score']}
      rows={similarIntents
        .slice(0, 5) // show max 5 similar answers
        .map((intent, index) => [
          <Typography key={`similar-answer-title-${index}`}>{intent.answer.title}</Typography>,
          <Typography key={`similar-answer-type-${index}`}>
            {intent.answer.answerType === 'answer' ? 'Antwort' : 'Trigger'}
          </Typography>,
          <Typography key={`similar-answer-score-${index}`}>{intent.score}</Typography>,
        ])}
      cellAlignPattern={['left', 'right', 'right']}
      disablePagination
    ></Table>
  )
}

type StepSimilarAnswersProps = {
  utterances?: string[]
  setSimilarAnswerState: (state: SimilarAnswerState) => void
  continueAnyway?: boolean // if true, check checkbox and continue even though similar answer
}

export default function StepSimilarAnswers({
  utterances: propsUtterances = [],
  setSimilarAnswerState,
  continueAnyway,
}: StepSimilarAnswersProps): React.ReactElement {
  const { classes } = useStyles()
  const theme = useTheme()
  const { bot: botInfos } = useBotContext()

  const [state, setState] = useState<'loading' | 'loaded' | 'errorChecking'>()
  const [similarIntents, setSimilarIntents] = useState<SimilarIntents>()

  /**
   * Queries the provided utterance against NLU and sets state depending on result.
   * A answer is similiar if it's score is above 0.75.
   */
  async function checkForSimilarAnswers(retry = false): Promise<void> {
    if (botInfos === null || propsUtterances.length === 0) return
    try {
      setState('loading')
      const similarIntents = await getNLUSimilarIntents(botInfos.id, 'specific', propsUtterances[0])
      if (similarIntents === null) throw new Error('Similar intents are null')
      setSimilarIntents(similarIntents)
      setState('loaded')
    } catch (err) {
      // error checking for similar intents
      if (!retry) {
        // try again
        await checkForSimilarAnswers(true)
      } else {
        // set error
        // setError(
        //   'Knowledge.specific.createAnswer.checkSimilar',
        //   'Fehler beim Überprüfen auf ähnliche Antworten.',
        //   'Erneut Überprüfen',
        //   checkForSimilarAnswers
        // )
        console.error('Error checking for similar answers: ', err)
        setState('errorChecking')
      }
    }
  }

  /**
   * Handles checkbox state
   * @param event
   */
  function onToggleCheckbox(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void {
    if (checked) setSimilarAnswerState('overwrite')
    else setSimilarAnswerState('similarExists')
  }

  useEffect(function () {
    checkForSimilarAnswers()
  }, [])

  useEffect(
    function () {
      if (similarIntents) {
        if (similarIntents.length === 0) setSimilarAnswerState('ok')
        else setSimilarAnswerState(continueAnyway ? 'overwrite' : 'similarExists')
      }
    },
    [similarIntents],
  )

  return (
    <div className={classes.container}>
      {state === 'loading' ? (
        <CircularLoading text='Überprüfe auf ähnliche Antworten ...' size='medium' />
      ) : state === 'errorChecking' ? (
        <>
          <div className={classes.similarAnswers}>
            <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
              <div style={{ textAlign: 'center', marginBottom: theme.spacing(1) }}>
                <i className={'ri-error-warning-line ' + classes.warning} />
                <Typography fontWeight='bold'>Fehler beim Überprüfen auf ähnliche Antworten</Typography>
              </div>
              <Typography>
                Sie können ohne Überprüfung fortfahren. Dies kann allerdings die Performance des Modells negativ
                beeinflussen, falls bereits ähnliche Antworten existieren.
              </Typography>
            </div>
          </div>
          <div className={classes.acknowledgeContainer}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox onChange={onToggleCheckbox} checked={continueAnyway} color='primary' />}
                label='Trotzdem fortfahren. Dies kann die Qualität des Assistenten beeinträchtigen.'
              />
            </FormGroup>
          </div>
        </>
      ) : (
        <ErrorComponent errorCode='Knowledge.specific.createAnswer.checkSimilar'>
          {typeof similarIntents !== 'undefined' && similarIntents.length > 0 ? (
            // there are similar answers
            <div className={classes.similarAnswers}>
              <Typography>Es existieren ähnliche Antworten:</Typography>
              <div className={classes.similarAnswersTable}>
                <SimilarAnswers similarIntents={similarIntents} />
              </div>
              <div className={classes.acknowledgeContainer}>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox onChange={onToggleCheckbox} checked={continueAnyway} color='primary' />}
                    label='Trotzdem fortfahren. Dies kann die Qualität des Assistenten beeinträchtigen.'
                  />
                </FormGroup>
              </div>
            </div>
          ) : (
            <div className={classes.noSimilarAnswers}>
              <div className={classes.noSimilarAnswersGraphic}>
                <Checkmark />
                <Typography className={classes.noSimilarAnswersGraphicText}>
                  Sehr gut! Es existieren keine ähnlichen Antworten.
                </Typography>
              </div>
              {/* <div style={{ position: 'absolute', top: 0, left: 0 }}> */}
              {/* <img src={CheckImage} />
              {/* </div> */}
              {/*<Typography>Sehr gut! Es existieren keine ähnlichen Antworten.</Typography> */}
            </div>
          )}
        </ErrorComponent>
      )}
    </div>
  )
}
