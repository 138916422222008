import { AutoAwesomeRounded, QuestionAnswer } from '@mui/icons-material'
import React, { ReactElement } from 'react'
import EditorSidebarItem from '../editorSidebarItem'
import { FLOWDESIGNER_NODE_COLOR_MODULE } from 'utils/constants'

export default function qnaGetAnswer(): ReactElement {
  return (
    <EditorSidebarItem
      type='module/llm-task'
      ports={{
        port1: {
          id: 'port1',
          type: 'left',
          position: { x: 0, y: 0 },
          properties: {
            type: 'incoming',
          },
        },
        port2: {
          id: 'port2',
          type: 'right',
          position: { x: 0, y: 0 },
          properties: {
            type: 'outgoing',
          },
        },
      }}
      properties={{
        typeText: 'Sprachmodel nutzen',
        text: 'Nicht konfiguriert',
        llmTask: {
          systemPrompt: '',
          userPrompt: '',
          displayTextFirstTurn: '',
          displayTextLaterTurns: '',
          buttonTextFinish: '',
          buttonTextTweak: '',
          historyEnabled: false,
        },
        dialog: '',
      }}
      Icon={AutoAwesomeRounded}
      color={FLOWDESIGNER_NODE_COLOR_MODULE}
    />
  )
}
