import React, { useState } from 'react'
import { useParams, useNavigate, useResolvedPath } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Fuse from 'fuse.js'
import * as FileSaver from 'file-saver'

// Material UI
import { makeStyles } from 'tss-react/mui'

import { Grid, TextField, Typography } from '@mui/material'
import { Delete } from '@mui/icons-material'
// Custom Components
import { ContentPageHeader } from 'components/Page/ContentPage'
import { Textfield } from 'components/TextInput/Textfield'
import DocumentCard from 'components/Cards/DocumentCard'
import { Button } from 'components/Buttons'
import { Dialog } from 'components/Dialogs'
import IconCard from 'components/Cards/IconCard'
import CircularLoading from 'components/Loading/CircularLoading'
import { Can } from 'components/Can/Can'
import ContentContainer from 'components/Dialogs/ContentContainer'
// constants
import {
  APP_TITLE,
  CUSTOM_NOTIFICATION_CARD_HEIGHT,
  ROUTE_BOTID_DESIGNER,
  ROUTE_BOTID_DOCUMENTS_PDFINGEST,
  ROUTE_BOTS,
  SNACKBAR_NOTIFICATIONS_DEFAULT_TIMEOUT,
} from '../../utils/constants'
// hooks
import { useBotContext } from 'hooks/contexts/bot-context'
import { useLockingContext } from 'hooks/contexts/locking-context'
import { useStudioNotificationContext } from 'hooks/contexts/studio-notification-context'
// types
import { DocumentInfos } from '../../@types/BotInformation/types'
// assets
import fillPdf from 'assets/img/pdf/fill-pdf.svg'
// api
import { getDocumentDownloadURL, deleteDocument as deleteDocumentAPI, updateDocumentInfos } from 'api/StudioBackend'

const useMenuStyles = makeStyles()({
  root: {},
})

export function DocumentsOverview(): React.ReactElement {
  const { classes } = useMenuStyles()
  const navigate = useNavigate()
  const url = useResolvedPath('').pathname
  const { botId } = useParams() as { botId: string } // can cast here because component would not be shown if undefined
  const { bot, setBot } = useBotContext()
  const { lockState } = useLockingContext()
  const { setNotification } = useStudioNotificationContext()

  const [searchString, setSearchString] = useState<string>()
  const [deleteDocumentId, setDeleteDocumentId] = useState<string>()
  const [deletedVariables, setDeletedVariables] = useState<number>()
  const [deletingDocument, setDeletingDocument] = useState<boolean>(false) // boolean is enough here, we do not need a state machine
  const [editDocumentId, setEditDocumentId] = useState<string>()
  const [editDocumentDescriptionTmp, setEditDocumentDescriptionTmp] = useState<string>()
  const [editDocumentTitleTmp, setEditDocumentTitleTmp] = useState<string>()
  const [updatingDocumentInfos, setUpdatingDocumentInfos] = useState<boolean>(false) // boolean is enough here, we do not need a state machine

  /**
   * Handles textinput into search field.
   * Updates search string in state.
   * @param event
   */
  function onSearchStringChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setSearchString(event.target.value)
  }

  /**
   * Searches notifications for search string and returns result.
   */
  function filterDocuments(documents: DocumentInfos): DocumentInfos[string][] {
    if (!searchString) return Object.values(documents)

    const fuseOptions = {
      shouldSort: true,
      threshold: 0.4,
      minMatchCharLength: 1,
      keys: ['documentId', 'documentTitletitle', 'documentDescription'],
    }
    const fuse = new Fuse(Object.values(documents) ?? [], fuseOptions)
    return fuse.search(searchString).map((result) => result.item)
  }

  function onEdit(documentId: string): void {
    // push history (see edit answer)
    navigate(url + `/${documentId}`)
  }

  async function downloadDocument(documentId: string): Promise<void> {
    if (bot) {
      const url = await getDocumentDownloadURL(botId, documentId)
      switch (bot.documentInfos[documentId].documentType) {
        case 'pdf':
          FileSaver.saveAs(url, `${bot.documentInfos[documentId].documentTitle}.pdf`) // name of file is overwritten by downloaded file name - maybe we can fix this in the future if necessary
          break
        default:
          console.log('[DocumentsOverview] downloadDocument - unknown document type')
          break
      }
    }
  }

  async function deleteDocument(documentId: string): Promise<void> {
    if (bot) {
      setDeletingDocument(true)
      const result = await deleteDocumentAPI(botId, documentId)
      if (result) {
        const { botInfos, removedVariables } = result
        setBot(botInfos)
        if (removedVariables) {
          setDeletedVariables(Object.entries(removedVariables).length)
          setNotification('success', 'Dokument gelöscht', SNACKBAR_NOTIFICATIONS_DEFAULT_TIMEOUT, true)
        }
        // reset the state that stores the do be deleted document id
        setDeleteDocumentId(undefined)
        setDeletingDocument(false)
      } else {
        setDeleteDocumentId(undefined)
        setNotification('error', 'Dokument konnte nicht gelöscht werden', SNACKBAR_NOTIFICATIONS_DEFAULT_TIMEOUT, true)
        setDeletingDocument(false)
      }
    }
  }

  async function updateInfos(documentId: string): Promise<void> {
    if (bot) {
      setUpdatingDocumentInfos(true)
      const botInfos = await updateDocumentInfos(
        botId,
        documentId,
        false,
        editDocumentTitleTmp ?? undefined,
        editDocumentDescriptionTmp ?? undefined,
      )
      if (botInfos) {
        setBot(botInfos)
        setNotification('success', 'Dokument aktualisiert', SNACKBAR_NOTIFICATIONS_DEFAULT_TIMEOUT, true)
        setUpdatingDocumentInfos(false)
      } else {
        // error occured
        setNotification(
          'error',
          'Dokument konnte nicht aktualisiert werden',
          SNACKBAR_NOTIFICATIONS_DEFAULT_TIMEOUT,
          true,
        )
        setUpdatingDocumentInfos(false)
      }
    }
    setEditDocumentId(undefined)
    setEditDocumentTitleTmp(undefined)
    setEditDocumentDescriptionTmp(undefined)
  }

  // ===== ACTIONS ======
  const SearchField = <Textfield value={searchString} placeholder='Suchen' onChange={onSearchStringChange} />

  return (
    <div>
      <Helmet>
        <title>{APP_TITLE} - Dokumente</title>
      </Helmet>
      <ContentPageHeader title='Dokumente' actions={[SearchField]} />
      {typeof bot === 'undefined' ? (
        <CircularLoading text='Dokumente werden geladen. Bitte warten...' size='medium' />
      ) : (
        <Grid container spacing={6}>
          {bot &&
            bot.documentInfos &&
            filterDocuments(bot.documentInfos).map((documentInfo, index) => {
              return (
                <Grid key={`document-${index}`} item xs={12} md={4}>
                  {documentInfo && (
                    <DocumentCard
                      documentId={documentInfo.documentId}
                      document={documentInfo}
                      onEditProperties={(): void => {
                        setEditDocumentId(documentInfo.documentId)
                        setEditDocumentDescriptionTmp(documentInfo.documentDescription)
                        setEditDocumentTitleTmp(documentInfo.documentTitle)
                      }}
                      onDownload={(): Promise<void> => downloadDocument(documentInfo.documentId)}
                      onReplace={(): void => console.log('onEditProperties', documentInfo.documentId)}
                      onDelete={(): void => setDeleteDocumentId(documentInfo.documentId)}
                      onView={(): void => console.log('onEditProperties', documentInfo.documentId)}
                      inputs={'PDF'}
                    />
                  )}
                </Grid>
              )
            })}
          <Can I='create' a='documents_pdf'>
            <Grid key={`add-document`} item xs={12} md={4}>
              <IconCard
                footerText='Neues Dokument importieren'
                icon={<i className={`ri-add-line`} />}
                onClick={(): void => navigate(`${url}` + ROUTE_BOTID_DOCUMENTS_PDFINGEST)}
                height={CUSTOM_NOTIFICATION_CARD_HEIGHT}
                disabled={lockState !== 'canEdit'}
              />
            </Grid>
          </Can>
        </Grid>
      )}
      {typeof deleteDocumentId !== 'undefined' && typeof bot?.documentInfos[deleteDocumentId] !== 'undefined' && (
        <Dialog
          id='delete-document-confirm-dialog'
          size='small'
          open={typeof deleteDocumentId === 'string'}
          closable={!deletingDocument}
          onClose={(): void => setDeleteDocumentId(undefined)}
          title='Dokument löschen'
          primaryActionButton={
            <Button
              size='small'
              type='danger'
              icon={<Delete />}
              onClick={(): Promise<void> => deleteDocument(deleteDocumentId)}
              disabled={deletingDocument}
            >
              {deletingDocument ? 'Löschen...' : 'Löschen'}
            </Button>
          }
          secondaryActionText={'Abbrechen'}
          onSecondaryActionClick={(): void => {
            setDeleteDocumentId(undefined)
          }}
          isSecondaryActionDisabled={deletingDocument}
        >
          <Typography>
            Möchten Sie dieses Dokoument wirklich löschen? Dies kann nicht rückgängig gemacht werden und alle Variabeln,
            die aus Eingaben in dem Dokument stammen, werden aus den Dialogen entfernt!
          </Typography>
        </Dialog>
      )}
      {typeof deletedVariables !== 'undefined' && deletedVariables > 0 && (
        <Dialog
          id='delete-document-deletedVariables-dialog'
          size='small'
          open={deletedVariables > 0}
          closable
          onClose={(): void => setDeletedVariables(undefined)}
          title='Wichtiger Hinweis'
          primaryActionButton={
            <Button
              size='small'
              type='success'
              icon='arrow-right-line'
              iconType='remix'
              onClick={(): void => navigate(ROUTE_BOTS + `/${botId}` + ROUTE_BOTID_DESIGNER)}
            >
              Dialog Designer öffnen
            </Button>
          }
          secondaryActionText={'Hinweis schließen'}
          onSecondaryActionClick={(): void => setDeletedVariables(undefined)}
        >
          <Typography>
            Das Dokument wurde erfolgreich gelöscht. Es wurden <strong>{deletedVariables} Variabeln</strong> aus den
            Dialogen entfernt. Bitte beachten Sie die Dialoge Ihres Assistenten zu überprüfen und neu zu veröffentlichen
            nachdem Sie die notwendigen Anpassungen getätigt haben.
          </Typography>
        </Dialog>
      )}
      {typeof editDocumentId !== 'undefined' && typeof bot?.documentInfos[editDocumentId] !== 'undefined' && (
        <Dialog
          id='edit-document-properties-dialog'
          open={typeof editDocumentId !== 'undefined'}
          size='large'
          title='Dokumenteigenschaften'
          closable={!updatingDocumentInfos}
          onClose={(): void => {
            setEditDocumentId(undefined)
            setEditDocumentDescriptionTmp(undefined)
            setEditDocumentTitleTmp(undefined)
          }}
          primaryActionButton={
            <Button
              size='small'
              type='success'
              icon='save-line'
              iconType='remix'
              onClick={(): void => {
                updateInfos(editDocumentId)
              }}
              disabled={updatingDocumentInfos}
            >
              Änderung speichern
            </Button>
          }
          secondaryActionText={'Abbrechen'}
          onSecondaryActionClick={(): void => {
            setEditDocumentId(undefined)
            setEditDocumentDescriptionTmp(undefined)
            setEditDocumentTitleTmp(undefined)
          }}
          isSecondaryActionDisabled={updatingDocumentInfos}
        >
          <ContentContainer
            headerText='Eigenschaften des Dokumentes bearbeiten'
            // descriptionText='Die mit dem gewählten PDF zusammenhängende Variablen werden verwendet, um die Eingabefelder des PDFs zu füllen. Nutzer*innen können das ausgefüllte PDF herunterladen, kontrollieren und auch weiterhin bearbeiten.Sie haben die Wahl eine Vorlage für die Chat-Nachrichten zu wählen oder die Download-Url des PDFs in einer Variable zu speichern und selber die Nachrichten des Assistenten für den Download zu gestalten.'
            svg={fillPdf}
          >
            <Typography>Titel des Dokumentes</Typography>
            <TextField
              id='title'
              label='Titel des Dokumentes'
              fullWidth
              defaultValue={bot?.documentInfos[editDocumentId].documentTitle ?? ''}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                setEditDocumentTitleTmp(event.target.value)
              }}
              margin='normal'
            />

            <Typography>Beschreibung des Dokumentes</Typography>
            <TextField
              id='description'
              label='Beschreibung des Dokumentes'
              multiline
              fullWidth
              maxRows={15}
              defaultValue={bot?.documentInfos[editDocumentId].documentDescription ?? ''}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                setEditDocumentDescriptionTmp(event.target.value)
              }}
              margin='normal'
            />
          </ContentContainer>
        </Dialog>
      )}
      {/* {showNewDialog && <CreateCustomNotificationDialog onClose={onCloseNewNotificationDialog} />} */}
    </div>
  )
}
