import React, { memo, useEffect, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography, useTheme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import EditableTypography from 'components/TextInput/EditableTypography'
import { Answer, buildAnswerObject } from 'classes/Knowledge'
import RMEEditor from 'components/TextEditors/editor-mdx-markdown'
import CustomizedTooltip from 'components/Tooltips/CustomContentTooltip'
import EditAnswerUtterances from './Components/EditAnswerUtterances'
import EditAnswerTranslations from './Components/EditAnswerTranslations'
import { simplifyText as simplifyTextAPI } from 'api/StudioBackend'
import TextSimplificationDialog from './Components/TextSimplificationDialog'
import { Can } from 'components/Can/Can'
import AnswerPreview from 'components/Previews/answerPreview'
import BaseCard from 'components/Cards/BaseCard'
import { AnswerAction, AnswerTemplateData } from '../../../../../@types/Knowledge/types'
import { Button } from 'components/Buttons'
import { useAnswers } from '../../../../../hooks/contexts/answers-context'
import { useBotContext } from '../../../../../hooks/contexts/bot-context'
import { useLockingContext } from 'hooks/contexts/locking-context'
import EditAnswerActions from './Components/EditAnswerActions'
import { useDatamanagementContext } from 'hooks/contexts/datamanagement-context'
import { fillAnswerTemplate } from 'utils/answerUtils'
import GoodAnswersTips from './Components/GoodAnswersTips'

const useStyles = makeStyles()((theme) => ({
  root: { display: 'flex', flexDirection: 'row', height: '100%', marginBottom: theme.spacing(4) },
  title: { display: 'flex', width: 'fit-content' },
  titleIconButton: { marginLeft: 'auto' },
  tooltip: { width: 'fit-content' },
  flexContainer: { display: 'flex' },
  editAnswerContainer: {
    // width: '100%',
    // marginTop: theme.spacing(2),
    // height: 'fit-content',
    height: '100%',
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
  },
  editAnswerItem: {
    // height: '380px',
    display: 'flex',
    flexDirection: 'column',
  },
  editAnswerTextfield: {
    // flex: 1,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
  },

  answerPreviewContainer: {
    height: '100%',
    overflow: 'auto',
  },
  answerPreviewTitle: {
    marginTop: theme.spacing(4),
  },
  answerPreviewCard: {
    marginRight: theme.spacing(8),
  },
  answerEditMenu: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginBottom: theme.spacing(4),
    '& > div:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
  answerEdit: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  questionContainer: { marginTop: theme.spacing(4) },
  questions: { display: 'flex', flexDirection: 'column', width: '100%', height: '100%' },
  suggestions: { display: 'flex', flexDirection: 'column', height: '100%' },
  suggestionsContent: {
    // marginTop: theme.spacing(3)
  },
  suggestionChip: { marginRight: theme.spacing(2) },
  suggestionsInput: { marginTop: 'auto' },
  textfield: { width: '100%' },

  markdown: { '& p': { margin: '0px !important' } },
  actionContainer: {
    display: 'flex',
    width: '100%',
    maxHeight: '200px',
    overflow: 'auto',
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius:
      typeof theme.shape.borderRadius === 'number' ? theme.shape.borderRadius * 2 : theme.shape.borderRadius,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  actionContainerRed: {
    color: theme.palette.error.main,
  },
  action: {
    width: '100%',
  },
  removeButton: {
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
  },
  removeActionTooltip: {
    display: 'inline-block',
    textAlign: 'right',
  },
  addActionContainer: {
    display: 'flex',
    width: '100%',
    maxHeight: '200px',
    overflow: 'auto',
    border: `1px solid ${theme.palette.grey[500]}`,
    cursor: 'pointer',
    borderRadius:
      typeof theme.shape.borderRadius === 'number' ? theme.shape.borderRadius * 2 : theme.shape.borderRadius,
    padding: `calc( ${theme.spacing(2)} - 1px)`,
    marginTop: 'auto',
    '&:hover, :active': {
      background: theme.palette.grey[100],
    },
  },
  translationContainer: {},
  markdownEditorTextfieldLabel: {
    marginTop: theme.spacing(1),
  },
  markdownEditorTextfieldInputContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  userHintIcon: {
    fontSize: '1.5rem',
    marginRight: theme.spacing(1),
    color: theme.palette.error.main,
  },
  tipsContainer: {},
  iconButton: {
    marginLeft: theme.spacing(1),
    marginBottom: 'auto',
    marginTop: 'auto',
    padding: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  iconButtonContainer: {
    display: 'flex',
  },
  inputHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
}))

type UserHint = {
  answerTemplate?: string
}

/** NOTE: we could have the tab menu set a qeury parameter so each menu has an url base on this: https://medium.com/@kate.pavlova/how-to-set-or-get-query-parameters-in-react-router-7d6e2440ede8
 *  Ist aber mit unnötig viel Aufwand für wenig Mehrwert verbunden -> kann man iwann mal machen
 */

type EditKnowledgeAnswerProps = {
  answer: Answer
  onEditAnswerCallback: (answer: Answer) => void
  botId: string
}

// TODO: lock state

export default memo(function EditKnowledgeAnswer({
  answer: propsAnswer,
  onEditAnswerCallback,
}: EditKnowledgeAnswerProps): React.ReactElement {
  const { classes } = useStyles()
  const theme = useTheme()
  const { answerTemplateData, doAllVariablesInTextExist } = useDatamanagementContext()
  const { getAnswerAction, updateAnswer, hasAnswerChanged, canIEditAnswer } = useAnswers()
  const { bot, getLanguages } = useBotContext()
  const { lockState } = useLockingContext()
  const [answer, setAnswer] = useState<Answer>(propsAnswer)
  // const [answerTemplate, setAnswerTemplate] = useState<string>(propsAnswer.answerTemplate)
  // const [answerTranslations, setAnswerTranslations] = useState<{ [lang: string]: AnswerTranslation }>({})
  const [title, setTitle] = useState<string>(propsAnswer.title)
  // const [editActions, setEditActions] = useState<boolean>(false)
  const [tab, setTab] = useState<'edit-text' | 'edit-actions' | 'edit-questions' | 'edit-translations'>('edit-text')
  // text simplification
  const [showTextSimplificationDialog, setShowTextSimplificationDialog] = useState<boolean>(false)
  const [simplifiedAnswerTemplate, setSimplifiedAnswerTemplate] = useState<string>('') // holds result of simplification API
  const [simplificationStatus, setSimplificationStatus] = useState<'loading' | 'complete' | 'error'>()
  // text translation
  const [isTranslating, setIsTranslating] = useState<boolean>(false)
  const [userHint, _setUserHint] = useState<UserHint>({})
  // const [tmpTextFieldState, setTmpTextFieldState] = useState<string | undefined>()

  /**
   * Proxy for state update of user hint.
   * @param key
   * @param hint
   */
  function setUserHint(key: string, hint: string | undefined): void {
    const newUserHint = { ...(userHint ?? {}), [key]: hint }
    _setUserHint(newUserHint)
  }

  /**
   * Handles title change.
   * @param event
   */
  function onTitleChange(newTitle: string): void {
    if (!canIEditAnswer(answer?.answerId)) return
    setTitle(newTitle)
    updateAnswerInParent(undefined, undefined, undefined, newTitle)
  }

  /**
   * Creates a new answer object from the values in state and sets it to parent via the callback function.
   * @param utterances can receive utterances via props, to prevent async state update problem.
   */
  function updateAnswerInParent(
    answerTemp?: string,
    utterances?: string[],
    localActions?: AnswerAction[],
    titleLocal?: string,
  ): void {
    if (!canIEditAnswer(answer?.answerId)) return
    const newAnswer = buildAnswerObject(answer)
    const _actions = getAnswerAction(answer.answerId)
    newAnswer.setActions(_actions)
    newAnswer.setLabels(utterances ?? answer.labels)
    newAnswer.setTitle(titleLocal ?? title ?? answer.title)
    if (answerTemp) {
      // answer template update
      // check if all referenced variables do exist.
      const checkResult = doAllVariablesInTextExist(answerTemp)
      if (!checkResult.doExist) {
        // build string for notifying user
        const invalidVariables = Object.keys(checkResult.detailed)
          .filter((variable) => !checkResult.detailed[variable])
          .map((variable) => `"${variable}"`)

        const message = `Ungültige Variable(n) in Text: ${invalidVariables.join(
          ', ',
        )}. Korrigieren oder beheben Sie das Problem. Nutzer*innen sehen sonst die falsche Variablereferenz und keinen Wert im Chat!`

        setUserHint('answerTemplate', message)
      } else {
        setUserHint('answerTemplate', undefined) // reset if no error
      }
    }
    newAnswer.setAnswerTemplate(answerTemp ?? answer.answerTemplate)
    newAnswer.setTopic(answer.topic)
    // we need a check for changes heres
    if (hasAnswerChanged(newAnswer)) {
      updateAnswer(newAnswer, 'modified')
    }
    onEditAnswerCallback(newAnswer)
  }

  /**
   * Callback function for utterances change.
   * @param newUtterances
   */
  function onUtterancesChangeCallback(newUtterances: string[]): void {
    if (!canIEditAnswer(answer?.answerId)) return
    updateAnswerInParent(answer.answerTemplate, newUtterances)
  }

  /**
   * Performs text simplification request.
   *
   * @param text
   */
  async function simplifyText(text: string): Promise<void> {
    try {
      if (!canIEditAnswer(answer?.answerId) || !bot) return

      setSimplificationStatus('loading')
      setShowTextSimplificationDialog(true)
      const result = await simplifyTextAPI(bot.id, text)
      if (result === null) {
        setSimplificationStatus('error')
        return
      }
      const simplifiedText = result.simplifiedText
      setSimplifiedAnswerTemplate(simplifiedText)
      setSimplificationStatus('complete')
    } catch (err) {
      // we could add automatic retry here
      setSimplificationStatus('error')
    }
  }

  /**
   * Confirm callback of simplification dialog.
   * @param simplifiedText
   */
  function onSimplificationDialogConfirm(simplifiedText: string): void {
    // onAnswerTemplateChange(simplifiedText)
    updateAnswerInParent(simplifiedText)
    setShowTextSimplificationDialog(false)
    setSimplificationStatus(undefined)
  }

  /**
   * Close callback of simplification dialog.
   */
  function onSimplificationDialogClose(): void {
    setShowTextSimplificationDialog(false)
    setSimplificationStatus(undefined)
  }

  useEffect(() => {
    setAnswer(propsAnswer)
  }, [propsAnswer])

  return (
    <div id='edit-answer' className={classes.root}>
      <BaseCard width='450px' height='100%' minHeight='70vh' className={classes.answerPreviewCard}>
        <div className={classes.title}>
          <EditableTypography
            value={title}
            onChange={onTitleChange}
            maxWidth={'500px'}
            disabled={!canIEditAnswer(answer?.answerId)}
          />
        </div>
        <Typography className={classes.answerPreviewTitle}>
          <strong>Vorschau</strong>
        </Typography>
        <div className={classes.answerPreviewContainer}>
          <AnswerPreview
            answer={answer.answerTemplate ? fillAnswerTemplate(answer.answerTemplate, answerTemplateData) : ''}
            question={answer.labels && answer.labels.length > 0 ? answer.labels[0] : ''}
            displayFeedback={false}
            actions={getAnswerAction(answer.answerId)
              .filter((action) => typeof action.action === 'string')
              .map((action) => {
                return action.action as string
              })}
          />
        </div>
      </BaseCard>
      <div id='edit-answer-editing' className={classes.answerEdit}>
        <div id='edit-answer-menu' className={classes.answerEditMenu}>
          <CustomizedTooltip
            placement='bottom'
            disableInteractive
            content={<Typography variant='body1'>Antworttext bearbeiten.</Typography>}
            elements={
              <div>
                <Button
                  size='normal'
                  type='normal'
                  icon='edit-box-line'
                  iconType='remix'
                  onClick={(): void => setTab('edit-text')}
                  variant={tab === 'edit-text' ? 'primary' : 'tertiary'}
                >
                  Antworttext
                </Button>
              </div>
            }
          />
          {bot?.webchatVersion !== 'v4' && (
            <CustomizedTooltip
              placement='bottom'
              disableInteractive
              content={<Typography variant='body1'>Folgefragen bearbeiten.</Typography>}
              elements={
                <div>
                  <Button
                    size='normal'
                    type='normal'
                    icon='reply-line'
                    iconType='remix'
                    onClick={(): void => setTab('edit-actions')}
                    variant={tab === 'edit-actions' ? 'primary' : 'tertiary'}
                  >
                    Folgefragen
                  </Button>
                </div>
              }
            />
          )}
          <CustomizedTooltip
            placement='bottom'
            disableInteractive
            content={<Typography variant='body1'>Zugeordnete Fragen bearbeiten und neue hinzufügen.</Typography>}
            elements={
              <div>
                <Button
                  size='normal'
                  type='normal'
                  icon='survey-line'
                  iconType='remix'
                  onClick={(): void => setTab('edit-questions')}
                  variant={tab === 'edit-questions' ? 'primary' : 'tertiary'}
                >
                  Zugeordnete Fragen
                </Button>
              </div>
            }
          />
          <Can I='read' a='translations' passThrough>
            {(can: boolean): React.ReactElement => (
              <CustomizedTooltip
                placement='bottom'
                disableInteractive
                content={<Typography variant='body1'>Übersetzungen der Antwort bearbeiten.</Typography>}
                elements={
                  <div>
                    <Button
                      size='normal'
                      type='normal'
                      icon='translate'
                      iconType='remix'
                      disabled={!can || getLanguages().length === 1}
                      onClick={(): void => setTab('edit-translations')}
                      variant={tab === 'edit-translations' ? 'primary' : 'tertiary'}
                    >
                      Übersetzungen
                    </Button>
                  </div>
                }
              />
            )}
          </Can>
        </div>
        {tab === 'edit-text' && (
          <BaseCard width='100%' height='100%'>
            <div className={classes.editAnswerContainer}>
              <div className={classes.inputHeader}>
                <Typography>
                  <strong>Antwort bearbeiten</strong>
                </Typography>
                <Can I='use' a='feature_textsimplification' passThrough>
                  {(can: boolean): React.ReactElement => {
                    return (
                      <Button
                        size='small'
                        type='normal'
                        icon='magic-line'
                        iconType='remix'
                        disabled={!can || lockState !== 'canEdit' || !canIEditAnswer(answer?.answerId)}
                        onClick={(): void => {
                          if (!canIEditAnswer(answer?.answerId)) return
                          simplifyText(answer.answerTemplate)
                          setShowTextSimplificationDialog(true)
                        }}
                        variant='secondary'
                      >
                        Text Vereinfachen
                      </Button>
                    )
                  }}
                </Can>
              </div>
              <div className={classes.editAnswerTextfield}>
                <RMEEditor
                  input={answer.answerTemplate || ''}
                  // autoFocus={true} // NOTE: I think this caused problems with the buttons of the editor ~ Jakob
                  onChangeCallback={(markdown): void => {
                    updateAnswerInParent(markdown)
                  }}
                  placeholder='Ihr Antworttext...'
                  readOnly={lockState !== 'canEdit' || !canIEditAnswer(answer?.answerId)}
                />
                {userHint && userHint?.answerTemplate && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <i className={`ri-error-warning-line ${classes.userHintIcon}`} />
                    <Typography variant='body1' style={{ color: theme.palette.error.main }}>
                      {userHint?.answerTemplate}
                    </Typography>
                  </div>
                )}
              </div>
              <GoodAnswersTips />
            </div>
          </BaseCard>
        )}
        {tab === 'edit-actions' && (
          <BaseCard width='100%' height='100%'>
            <Typography>
              <strong>Folgefragen bearbeiten</strong>
            </Typography>
            <Typography>
              Folgefragen werden Nutzer*innen unter einer Antwort angezeigt, sodass diese schnell an weitere
              Informationen zu einem Thema gelangen können. So können Nutzer*innen zu Themen geführt werden, die für sie
              von Interesse sind. Folgefragen sind optional, es können maximal 4 Folgefragen angelegt werden und sie
              dürfen für eine gute Benutzerfreundlichkeit maximal 50 Zeichen lang sein.
            </Typography>
            <EditAnswerActions answerId={answer.answerId} />
          </BaseCard>
        )}
        {tab === 'edit-questions' && (
          <BaseCard width='100%' height='100%'>
            <Typography>
              <strong>Zugeordnete Fragen bearbeiten</strong>
            </Typography>
            <div className={classes.questions}>
              <EditAnswerUtterances
                answer={answer}
                onUtterancesChangeCallback={onUtterancesChangeCallback}
                rowsPerPage={10}
                allowDisplayAsDialog={false}
                displayType='table'
                generateUtterancesOption
                showTitle={false}
              />
            </div>
          </BaseCard>
        )}
        {tab === 'edit-translations' && (
          <BaseCard width='100%' height='auto'>
            <Typography>
              <strong>Übersetzungen bearbeiten</strong>
            </Typography>
            <EditAnswerTranslations
              answerId={answer.answerId}
              isTranslating={isTranslating}
              setIsTranslating={setIsTranslating}
            />
          </BaseCard>
        )}
      </div>
      {showTextSimplificationDialog && (
        <TextSimplificationDialog
          open={showTextSimplificationDialog}
          simplificationStatus={simplificationStatus}
          originalText={answer.answerTemplate}
          simplifiedText={simplifiedAnswerTemplate}
          onClose={onSimplificationDialogClose}
          onSubmit={onSimplificationDialogConfirm}
        />
      )}
    </div>
  )
})
