import { getAccount } from 'components/AuthProvider/PublicClientApp'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'

import { useBotContext } from '../../hooks/contexts/bot-context'

import {
  deleteBot as deleteBotApi,
  getBotSettings as getBotSettingsApi,
  reloadBot as reloadBotApi,
  saveBotSettings as saveBotSettingsApi,
} from '../../api/StudioBackend'

import Button from '../../components/Buttons/Button'
import ContentPage, { ContentPageHeader } from '../../components/Page/ContentPage'
import { clearCyclicStructure } from '../../utils/chartUtils'
import { APP_TITLE, CONVAISE_CUSTOMER_ID, ROUTE_BOTS } from '../../utils/constants'

import { Typography, useTheme } from '@mui/material'
import { Can } from 'components/Can/Can'
import { Dialog } from 'components/Dialogs'
import CircularLoading from 'components/Loading/CircularLoading'
import { useChartContext } from 'hooks/contexts/chart-context'
import { useTranslationsContext } from 'hooks/contexts/translations-context'
import { makeStyles } from 'tss-react/mui'
import { BotSettings, EmailFallbackSettings, EmailFallbackTexts } from '../../@types/Settings/types'
import GeneralSettings from './GeneralSettings'
import QnASettings from './QnASettings'
import StylingSettings from './StylingSettings'
import WebchatSettings from './WebchatSettings'
import { useLockingContext } from 'hooks/contexts/locking-context'
import BaseCard from 'components/Cards/BaseCard'
import { isValidEmail } from 'utils/stringUtils'
import { useStudioNotificationContext } from 'hooks/contexts/studio-notification-context'
import LegalSettings from './LegalSettings'
import IntegrationChannelSettings from './IntegrationChannelSettings'

const useStyles = makeStyles()((theme) => ({
  content: { paddingBottom: '100px', minHeight: '100%' },
  buttons: { display: 'flex', marginTop: theme.spacing(4) },
  button: { width: '180px', margin: `0 ${theme.spacing(1)}` },
  heading: { margin: `${theme.spacing(2)} 0`, marginTop: theme.spacing(3) },
  row: { display: 'flex' },
  section: { marginTop: theme.spacing(4), borderTop: '1px solid rgba(0,0,0,0.1)' },
  keyTypography: { margin: 'auto 0' },
  stylesContainer: { marginTop: theme.spacing(5), borderTop: '1px solid black' },
  avatarContainer: { marginTop: theme.spacing(2), width: '100%', marginBottom: theme.spacing(2) },
  colorsContainer: {
    maxWidht: '200px',
    maxHeight: '200px',
  },
  avatarUpload: {
    height: '200px',
    width: '200px',
    border: '3px dotted grey',
    display: 'flex',
  },
  avatarImage: {
    width: '100%',
    height: 'fit-content',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  colorPreview: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: theme.spacing(1),
    width: '40px',
    height: '40px',
    borderRadius: '50%',
  },
}))

function SettingsPage(): React.ReactElement {
  const theme = useTheme()
  const account = getAccount()
  const customerIdOfCurrentUser = account?.idTokenClaims ? account?.idTokenClaims['extension_CustomerID'] : undefined
  const { classes } = useStyles()
  const { bot, setBot } = useBotContext()
  const isInitialized = useRef(false)
  const { chart, loadingChart } = useChartContext()
  const { lockState } = useLockingContext()
  const { setNotification } = useStudioNotificationContext()
  // const { translationFile, loadingTranslationFile } = useTranslationsContext()
  const [loadingSettings, setLoadingSettings] = useState<'loading' | 'saving' | 'savingError'>()
  const [settings, setSettings] = useState<BotSettings>()
  const [origSettings, setOrigSettings] = useState<BotSettings>() // settings before changes
  const [hasChangedSettings, setHasChangedSettings] = useState<boolean>(false)
  const navigate = useNavigate()

  const [deletingState, setDeletingState] = useState<'deleting'>()
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState<boolean>()
  const [showSaveConfirmDialog, setShowSaveConfirmDialog] = useState<boolean>()

  /**
   * Load settings
   */
  async function loadSettings(showLoadingIndicator = true, retry = false): Promise<void> {
    if (!bot) return
    try {
      if (showLoadingIndicator) setLoadingSettings('loading')
      const settings = await getBotSettingsApi(bot.id)
      if (settings !== null) {
        setSettings(settings)
        setOrigSettings(settings)
      } else {
        throw new Error('load settings result is null')
      }
      isInitialized.current = true
      setLoadingSettings(undefined)
    } catch (err) {
      if (!retry) {
        // try again a second time
        console.info('Retry: Load settings')
        await loadSettings(true, true)
      } else {
        // set error
        console.info('Error. Could not load settings. ', err)
      }
    }
  }

  async function saveSettingsAndPublish(retry = false): Promise<void> {
    if (!bot || lockState !== 'canEdit') return
    try {
      if (!settings || !origSettings || !hasChangedSettings) return
      // determine changed individual settings with deep comparison
      const changedSettings = Object.keys(settings).reduce((acc, key) => {
        if (JSON.stringify(settings[key]) !== JSON.stringify(origSettings[key])) {
          acc[key] = settings[key]
        }
        return acc
      }, {} as BotSettings)
      if (Object.keys(changedSettings).length === 0) {
        // no changes
        return
      }

      setLoadingSettings('saving')
      const updatedBotInfos = await saveBotSettingsApi(bot.id, changedSettings)
      if (updatedBotInfos === null) {
        throw new Error('Saving botinfos failed')
      }

      const reloadResult = await reloadBotApi(bot.id, 'production', true, true)

      if (reloadResult !== null) {
        setBot(reloadResult.botInfos)
        await loadSettings(false)
      } else {
        throw new Error('Error reloading bot')
      }

      setShowSaveConfirmDialog(false)
      setLoadingSettings(undefined)
      setNotification('success', 'Einstellungen gespeichert', 3000)
    } catch (err) {
      if (!retry) {
        // try again a second time
        console.info('Retry: Save settings')
        await saveSettingsAndPublish(true)
      } else {
        // set error

        console.info('Error. Could not save settings. ', err)
        setLoadingSettings('savingError')
      }
    }
  }

  /**
   * Downloads chart as json file.
   */
  function onDownloadChart(): void {
    if (!chart || !bot) return
    const filename = `${bot.name}-${bot.id}-chart.json`
    const contentType = 'application/json;chartset=utf-8;'
    if (window.navigator && typeof (window.navigator as any).msSaveOrOpenBlob !== 'undefined') {
      const blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify(clearCyclicStructure(chart))))], {
        type: contentType,
      })
      // navigator.msSaveOrOpenBlob(blob, filename)
      if ((navigator as any).msSaveOrOpenBlob.msSaveOrOpenBlob) {
        ;(navigator as any).msSaveOrOpenBlob.msSaveOrOpenBlob(blob, filename)
      } else {
        // Handle non-IE browsers here
      }
    } else {
      const a = document.createElement('a')
      a.download = filename
      a.href = 'data:' + contentType + ',' + encodeURIComponent(JSON.stringify(clearCyclicStructure(chart)))
      a.target = '_blank'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
  }

  function onDeleteBot(): void {
    setShowDeleteConfirmDialog(true)
  }

  async function onDeleteBotConfirm(retry?: boolean): Promise<void> {
    if (!bot) return
    try {
      setDeletingState('deleting')
      await deleteBotApi(bot)
      navigate(ROUTE_BOTS)
    } catch (err) {
      if (!retry) {
        // try again a second time
        console.info('Retry: Delete bot.')
        await onDeleteBotConfirm(true)
      } else {
        // set error
        console.info('Error. Could not delete bot. ', err)
      }
    }
  }

  /**
   * Downloads chart as json file.
   */
  // function onDownloadTranslations(): void {
  //   if (!translationFile || !bot) return
  //   const filename = `${bot.name}-${bot.id}-translations.json`
  //   const contentType = 'application/json;chartset=utf-8;'
  //   if (window.navigator && typeof (window.navigator as any).msSaveOrOpenBlob !== 'undefined') {
  //     const blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify(translationFile)))], {
  //       type: contentType,
  //     })
  //     // navigator.msSaveOrOpenBlob(blob, filename)
  //     if ((navigator as any).msSaveOrOpenBlob.msSaveOrOpenBlob) {
  //       ;(navigator as any).msSaveOrOpenBlob.msSaveOrOpenBlob(blob, filename)
  //     } else {
  //       // Handle non-IE browsers here
  //     }
  //   } else {
  //     const a = document.createElement('a')
  //     a.download = filename
  //     a.href = 'data:' + contentType + ',' + encodeURIComponent(JSON.stringify(translationFile))
  //     a.target = '_blank'
  //     document.body.appendChild(a)
  //     a.click()
  //     document.body.removeChild(a)
  //   }
  // }

  /**
   * Validates the email fallback settings configuration.
   *
   * @param emailFallbackSettings - The email fallback settings object to validate
   * @returns true if:
   *  - No email fallback settings exist (same as if email fallback is disabled)
   *  - Email fallback is disabled
   *  - Email fallback is enabled and has:
   *    - Message threshold >= 1
   *    - At least one valid department with:
   *      - Non-empty department name
   *      - Valid recipient email address
   *      - Non-empty fallback subject
   * @returns false if the enabled settings are invalid
   */
  function isValidEmailFallbackSettings(emailFallbackSettings?: EmailFallbackSettings): boolean {
    if (!emailFallbackSettings) return true

    if (!emailFallbackSettings.emailFallbackEnabled) return true

    if (
      !(
        typeof emailFallbackSettings.emailFallbackMessageThreshold === 'number' &&
        emailFallbackSettings.emailFallbackMessageThreshold >= 1
      )
    ) {
      return false
    }

    // check if all departments are valid
    const isValidDepartments =
      (emailFallbackSettings.emailFallbackDepartments ?? []).length > 0 &&
      (emailFallbackSettings.emailFallbackDepartments ?? []).every((department) => {
        return (
          typeof department.departmentName === 'string' &&
          department.departmentName !== '' &&
          typeof department.departmentRecipientAdress === 'string' &&
          department.departmentRecipientAdress !== '' &&
          isValidEmail(department.departmentRecipientAdress) &&
          typeof department.departmentFallbackSubject === 'string' &&
          department.departmentFallbackSubject !== '' &&
          typeof department.departmentEmailIntroText === 'string' &&
          department.departmentEmailIntroText !== ''
        )
      })
    return isValidDepartments
  }

  /**
   * Checks if all email fallback texts are configured.
   * @param emailFallbackTexts
   * @returns
   */
  function isValidEmailFallbackTexts(emailFallbackTexts?: EmailFallbackTexts): boolean {
    if (!emailFallbackTexts) return true

    return !!(
      emailFallbackTexts.emailFallbackChatPromptText &&
      emailFallbackTexts.emailFallbackChatCompletedText &&
      emailFallbackTexts.emailFallbackOverlayTitle &&
      emailFallbackTexts.emailFallbackOverlayDepartmentText &&
      emailFallbackTexts.emailFallbackOverlayEmailText &&
      emailFallbackTexts.emailFallbackOverlayEmailPlaceholderText &&
      emailFallbackTexts.emailFallbackOverlayContentText &&
      emailFallbackTexts.emailFallbackOverlayAdditionalTextPlaceholder &&
      emailFallbackTexts.emailFallbackOverlaySendButtonText
    )
  }

  function areQnASettingsValid(qnaSettings: BotSettings['qnaSettings']): boolean {
    return true
  }

  function areWebchatSettingsValid(settings?: BotSettings): boolean {
    if (!settings) return false

    const emailSettings = settings.webchatSettings?.emailFallbackSettings

    const hasValidEmailFallbackSettings = isValidEmailFallbackSettings(emailSettings)

    const areEmailFallbackTextsValid = settings.webchatSettings?.emailFallbackSettings?.emailFallbackEnabled
      ? isValidEmailFallbackTexts(settings.webchatSettings?.webchatTexts?.emailFallbackTexts)
      : true

    return areEmailFallbackTextsValid && hasValidEmailFallbackSettings
  }

  /**
   * Checks if settings are valid and complete.
   */
  const isValidCompleteSettings = useMemo(() => {
    // check qna settings
    return areQnASettingsValid(settings?.qnaSettings) && areWebchatSettingsValid(settings)
  }, [settings])

  useEffect(
    function load() {
      if (!isInitialized.current) loadSettings()
    },
    [bot],
  )

  useEffect(
    function hasChangedSettings() {
      setHasChangedSettings(JSON.stringify(settings) !== JSON.stringify(origSettings))
    },
    [settings, origSettings],
  )

  const actions =
    bot?.webchatVersion === 'v4'
      ? [
          <Button
            key='save-button'
            variant='primary'
            type='success'
            onClick={() => setShowSaveConfirmDialog(true)}
            disabled={
              loadingSettings === 'saving' ||
              loadingSettings === 'loading' ||
              !hasChangedSettings ||
              !isValidCompleteSettings ||
              lockState !== 'canEdit'
            }
            loading={loadingSettings === 'saving'}
          >
            Speichern
          </Button>,
        ]
      : []

  const IS_CURRENT_USER_CONVAISE_ADMIN = customerIdOfCurrentUser === CONVAISE_CUSTOMER_ID

  return (
    <>
      <ContentPageHeader title='Einstellungen' actions={actions} />
      <BaseCard width={'100%'} height={'100%'} minHeight={'80vh'} contentScrollable>
        <div className={classes.content}>
          {loadingSettings === 'loading' ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                width: '100%',
                height: '100%',
              }}
            >
              <CircularLoading text='Einstellungen werden geladen...' />
            </div>
          ) : (
            settings && (
              <>
                {IS_CURRENT_USER_CONVAISE_ADMIN && (
                  <div style={{ marginTop: '32px' }}>
                    {bot?.botUrl ? (
                      <Typography>
                        Bot URL:{' '}
                        <a href={bot.botUrl} target='_blank' rel='noreferrer'>
                          {bot.botUrl}
                        </a>
                      </Typography>
                    ) : (
                      <Typography>No bot set</Typography>
                    )}
                  </div>
                )}
                {IS_CURRENT_USER_CONVAISE_ADMIN && (
                  <div id='general' className={classes.section}>
                    <GeneralSettings
                      generalSettings={settings?.general ?? { botName: '', botDescription: '' }}
                      onGeneralSettingsChange={(newGeneralSettings) => {
                        setSettings({ ...settings, general: newGeneralSettings })
                      }}
                    />
                  </div>
                )}
                <div id='legal' className={classes.section}>
                  <LegalSettings
                    legalSettings={settings?.legalTexts}
                    onLegalSettingsChange={(newLegalSettings) => {
                      setSettings({ ...settings, legalTexts: newLegalSettings })
                    }}
                  />
                </div>
                <div id='styles' className={classes.section}>
                  {/* Custom styles */}
                  {bot?.webchatVersion === 'v4' ? (
                    <StylingSettings
                      styleSettings={settings['styles']}
                      onStyleSettingsChange={(newStyleSettigs: BotSettings['styles']) => {
                        setSettings({ ...settings, styles: newStyleSettigs })
                      }}
                    />
                  ) : null}
                </div>
                {IS_CURRENT_USER_CONVAISE_ADMIN && (
                  <div id='webchat' className={classes.section}>
                    <WebchatSettings
                      webchatSettings={settings.webchatSettings}
                      onWebchatSettingsChange={(webchatSettings: BotSettings['webchatSettings']) => {
                        setSettings({ ...settings, webchatSettings })
                      }}
                    />
                  </div>
                )}

                <div id='qna' className={classes.section}>
                  <QnASettings
                    qnaSettings={settings.qnaSettings}
                    onQnaSettingsChange={(qnaSettings: BotSettings['qnaSettings']) => {
                      setSettings({ ...settings, qnaSettings })
                    }}
                    webchatSettings={settings.webchatSettings}
                    onWebchatSettingsChange={(webchatSettings: BotSettings['webchatSettings']) => {
                      setSettings({ ...settings, webchatSettings })
                    }}
                  />
                </div>

                <div id='integration' className={classes.section}>
                  <IntegrationChannelSettings
                    integrationChannelSettings={settings.integrationChannels}
                    onIntegrationChannelSettingsChange={(integrationChannels) => {
                      setSettings({ ...settings, integrationChannels })
                    }}
                  />
                </div>

                {IS_CURRENT_USER_CONVAISE_ADMIN && (
                  <div className={classes.section}>
                    <Typography className={classes.heading} variant='h2'>
                      Admin Interaktionen
                    </Typography>
                    <div className={classes.buttons}>
                      <Button
                        className={classes.button}
                        type='normal'
                        size='small'
                        onClick={onDownloadChart}
                        disabled={!chart}
                        loading={loadingChart === 'loading'}
                      >
                        Download Chart
                      </Button>

                      <Can I='delete' a='bot'>
                        <Button className={classes.button} type='normal' size='small' onClick={onDeleteBot}>
                          Assistent löschen
                        </Button>
                      </Can>
                    </div>
                    <div style={{ height: '200px' }} />
                  </div>
                )}
              </>
            )
          )}

          {showSaveConfirmDialog && (
            <Dialog
              id='save-publish-dialog'
              size='medium'
              title='Einstellungen speichern'
              open={showSaveConfirmDialog}
              closable={loadingSettings !== 'saving'}
              onClose={(): void => {
                setLoadingSettings(undefined)
                setShowSaveConfirmDialog(undefined)
              }}
              primaryActionButton={
                <Button
                  size='small'
                  type='success'
                  loading={loadingSettings === 'saving'}
                  onClick={saveSettingsAndPublish}
                >
                  Speichern und Veröffentlichen
                </Button>
              }
            >
              {loadingSettings === 'saving' ? (
                <CircularLoading size='medium' text='Einstellungen werden gespeichert...' />
              ) : loadingSettings === 'savingError' ? (
                <Typography>Fehler beim Speichern der Einstellungen.</Typography>
              ) : (
                <>
                  <Typography>
                    Um die Einstellungen zu speichern und zu übernehmen muss der Assistent neu geladen werden. Dadurch
                    werden der Konversationfluss und die aktuellen Übersetzungen veröffentlicht. Sofern der Assistente
                    mehrere Sprachen unterstützt, sollte nach dem Speichern sichergestellt werden, dass alle Texte
                    übersetzt sind.
                  </Typography>
                  <br />
                  <Typography>Möchten Sie speichern und den Assistenten neuladen?</Typography>
                </>
              )}
            </Dialog>
          )}

          {showDeleteConfirmDialog && (
            <Dialog
              id='delete-bot-dialog'
              size='small'
              open={showDeleteConfirmDialog}
              closable={deletingState !== 'deleting'}
              aria-describedby='delete-bot-dialog'
              primaryActionButton={
                <Button size='small' type='danger' loading={deletingState === 'deleting'} onClick={onDeleteBotConfirm}>
                  Löschen
                </Button>
              }
              onClose={(): void => setShowDeleteConfirmDialog(undefined)}
            >
              {deletingState === 'deleting' ? (
                <>
                  <CircularLoading />
                  <Typography>Assistent wird gelöscht...</Typography>
                </>
              ) : (
                <Typography>Möchten Sie den Assistenten wirklich vollständig löschen?</Typography>
              )}
            </Dialog>
          )}
        </div>
      </BaseCard>
    </>
  )
}

export default function Settings(): React.ReactElement {
  const { pathname: path } = useLocation()

  return (
    <>
      <Helmet>{APP_TITLE} - Einstellungen</Helmet>
      <ContentPage>
        <Routes>
          <Route path={''} element={<SettingsPage />} />
          <Route path='*' element={<Navigate to={path} />} />
        </Routes>
      </ContentPage>
    </>
  )
}
