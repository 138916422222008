import { InquiriesOutsideOpeningHours, Stat } from '../../@types/Analytics/types'

/**
 * Prepares different stat objects for populating the inquiries outside opening hours stat chart.
 * Uses the special analytics data format for that.
 * {
    title: string;
    titleValue: string;
    titleValueChar: string;
    switchTitleValueAndTitleValueChar?: boolean | undefined;
    scope: string;
}
 * @param inquiriesOutsideOpeningHoursData
 */
export function prepareInquiriesOutsideOpeningHoursScopeStatChart(
  inquiriesOutsideOpeningHoursData: InquiriesOutsideOpeningHours,
): Stat[] {
  const stats: Stat[] = []

  // main stat
  const mainTitleValue =
    inquiriesOutsideOpeningHoursData.totalInquirires > 0
      ? `${(
          (inquiriesOutsideOpeningHoursData.totalInquiriesOutsideOperatingHours /
            inquiriesOutsideOpeningHoursData.totalInquirires) *
          100
        ).toFixed(1)}`
      : '-'

  const mainStat: Stat = {
    title: 'Anfragen außerhalb Betriebszeiten',
    titleValue: mainTitleValue,
    titleValueChar: '%',
    switchTitleValueAndTitleValueChar: true,
    scope: '',
  }
  stats.push(mainStat)

  // total inquirires stat
  const totalStat: Stat = {
    title: 'Alle Anfragen',
    titleValue: `${inquiriesOutsideOpeningHoursData.totalInquirires}`,
    titleValueChar: '#',
    switchTitleValueAndTitleValueChar: false,
    scope: 'all_inquiries',
  }
  stats.push(totalStat)

  // inquiries work days
  const workdayStat: Stat = {
    title: 'Montag - Freitag',
    titleValue: `${inquiriesOutsideOpeningHoursData.totalInquiriesOnWorkdays}`,
    titleValueChar: '#',
    switchTitleValueAndTitleValueChar: false,
    scope: '',
  }
  stats.push(workdayStat)

  // inquiries during hours
  const duringHoursStat: Stat = {
    title: 'Während Betriebszeit (Mo. - Fr.)',
    titleValue: `${
      inquiriesOutsideOpeningHoursData.totalInquiriesOnWorkdays -
      inquiriesOutsideOpeningHoursData.totalInquiriesOnWorkdaysOutsideOperatingHours
    }`,
    titleValueChar: '#',
    switchTitleValueAndTitleValueChar: false,
    scope: '',
  }
  stats.push(duringHoursStat)

  // inquiries outside hours
  const outsideStat: Stat = {
    title: 'außerhalb Betriebszeit (Mo. - Fr.)',
    titleValue: `${inquiriesOutsideOpeningHoursData.totalInquiriesOnWorkdaysOutsideOperatingHours}`,
    titleValueChar: '#',
    switchTitleValueAndTitleValueChar: false,
    scope: '',
  }
  stats.push(outsideStat)

  // inquiries weekend
  const weekendStat: Stat = {
    title: 'Wochenende',
    titleValue: `${inquiriesOutsideOpeningHoursData.totalInquiriesOnWeekends}`,
    titleValueChar: '#',
    switchTitleValueAndTitleValueChar: false,
    scope: '',
  }
  stats.push(weekendStat)

  return stats
}
