import { makeStyles } from 'tss-react/mui'
import { FLOWDESIGNER_NODE_WIDTH } from 'utils/constants'

type StyleProps = {
  selected?: boolean
  hasError?: boolean
}

export const useFlowDesignerNodeStyles = makeStyles<StyleProps>()((theme, props) => ({
  error: {
    // border: '5px solid red',
    // borderRadius: '5px',
  },
  noError: {},
  errorTextContainer: {
    background: `${theme.palette.error.main}`,
    color: '#FFF',
    width: `${FLOWDESIGNER_NODE_WIDTH}px`,
    justifyContent: 'center',
    display: 'flex',
    borderRadius: '0px 0px 5px 5px',
    padding: '10px 5px',
  },
  errorIconContainer: {
    display: 'inline-flex',
    verticalAlign: 'middle',
    height: '100%',
    color: 'white',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  errorText: { marginTop: 'auto', marginBottom: 'auto', marginLeft: '5px' },
  nodeContainer: { position: 'relative', display: 'flex' },
  outerNode: {
    fontSize: '14px',
    cursor: 'move',
    wordWrap: 'normal',
    minWidth: `${FLOWDESIGNER_NODE_WIDTH}px`,
    maxWidth: `${FLOWDESIGNER_NODE_WIDTH}px`,
    width: `${FLOWDESIGNER_NODE_WIDTH}px`,
    display: 'flex',
    flexDirection: 'column',
    border: props?.selected
      ? `2px solid ${theme.palette.primary.main}`
      : props?.hasError
      ? `2px solid ${theme.palette.error.main}`
      : '2px solid rgba(0,0,0,0)',
    borderRadius: props?.hasError ? `7px 7px 0px 0px` : `7px`,
    height: '100%',
  },
  headerContainer: {
    textAlign: 'center',
  },
  header: {
    background: '#3c4858',
    padding: '6px 13px 6px',
    borderRadius: '5px 5px 0 0',
    color: 'rgba(255, 255, 255, 0.9)',
    textAlign: 'center',
    margin: '0px',
  },
  headerCardIcon: {
    margin: '0 0 0px 8px',
    fontSize: 18,
    color: 'rgba(255,255,255,0.9)',
    verticalAlign: 'middle',
  },
  nodeContentContainer: {},
  contentContainer: {
    borderRadius: '0 0 4px 4px',
    justifyContent: 'start',
    boxSizing: 'border-box',
    backgroundColor: '#fff',
    padding: '0px 17px',
    letterSpacing: '-0.3px',
    border: '2px solid transparent',
    cursor: 'pointer',
    minHeight: '56px',
    height: '100%',
    flex: 1, // to fill available space in outerNode container
    display: 'flex',
    flexDirection: 'column',
  },
  portContainerLeft: {
    left: 0,
    flexDirection: 'column',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
  },
  portContainerRight: {
    right: 0,
    flexDirection: 'column',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
  },
}))
