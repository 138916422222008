import { useRAGContext } from 'hooks/contexts/rag-context'
import React from 'react'
import { useParams } from 'react-router-dom'
import RAGUpdateTextDocument from './RAGUpdateTextDocument'
import RAGUpdateFile from './RAGUpdateFile'

export default function RAGEditPage(): React.ReactElement {
  const { documentId } = useParams()
  const { documents } = useRAGContext()

  return (
    <>
      {documentId && documents ? (
        documents[documentId]?.type === 'string' ? (
          <RAGUpdateTextDocument documentId={documentId} />
        ) : ['txt', 'pdf', 'docx'].includes(documents[documentId]?.type) ? (
          <RAGUpdateFile documentId={documentId} />
        ) : null
      ) : null}
    </>
  )
}
