import React from 'react'
import { makeStyles } from 'tss-react/mui'
import CustomizedTooltip from '../../../../components/Tooltips/CustomContentTooltip'

// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. ArrowFunctionExpression in CSS prop.
const useStyles = makeStyles<WarningProps>()((theme, props) => ({
  warning: {
    fontSize: '1rem',
    color: theme.palette.warning.main,
    cursor: props?.tooltip ? 'pointer' : 'default',
    marginRight: theme.spacing(1),
  },
}))

type WarningProps = {
  tooltip?: string | React.ReactElement
}

export default function Warning({ tooltip }: WarningProps): React.ReactElement {
  const { classes } = useStyles({ tooltip })
  if (typeof tooltip !== 'undefined') {
    return (
      <CustomizedTooltip
        disableInteractive
        elements={<i className={'ri-error-warning-line ' + classes.warning} />}
        content={<>{tooltip}</>}
        placement='top'
      />
    )
  } else {
    return <i className={'ri-error-warning-line ' + classes.warning} />
  }
}
