import React, { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'

import { TextField, Grid, IconButton } from '@mui/material'
import { Delete } from '@mui/icons-material'
import VariablesAutosuggestSelect, { OnChangeOptions } from '../Variables/VariablesAutosuggestSelect'

import { removeVariableFromNode } from '../../../../../utils/chartUtils'

import { Chart } from '../../../../../@types/Flowchart/types'

/**
 * Returns access pattern for saveConcig
 * @param {Chart} chart
 * @param {string} saveConfigId
 * @param {string} iter
 */
function getAccessPattern(chart: Chart, saveConfigId: string, iter?: string): string {
  const selectedId = chart.selected?.id
  if (typeof selectedId === 'undefined') return ''
  const api = chart.nodes[selectedId].properties.api
  if (typeof api === 'undefined') return ''

  const accessPattern =
    api.response.saveConfig &&
    api.response.saveConfig[saveConfigId] &&
    api.response.saveConfig[saveConfigId].accessPattern !== undefined
      ? api.response.saveConfig[saveConfigId].accessPattern
      : ''

  return accessPattern
}

type ResponseConfigProps = {
  chart: Chart
  saveConfigId: string
  deleteSaveConfigCallback: (configId: string) => void
  setStateCallback: (chart: Chart) => void
}

export default function ResponseConfig({
  chart,
  setStateCallback,
  deleteSaveConfigCallback,
  saveConfigId,
}: ResponseConfigProps): React.ReactElement {
  const [accessPattern, setAccessPattern] = useState<string>(getAccessPattern(chart, saveConfigId))

  useEffect(
    function () {
      // update displayed accessPattern if chart changes (e.g. after one response config was removed)
      setAccessPattern(getAccessPattern(chart, saveConfigId))
    },
    [chart, saveConfigId],
  )

  /**
   * Adds variables to saveConfig object and returns changed chart.
   * @param {Object} chart
   * @param {string[]} variableIds
   */
  function onVariableSelection(chart: Chart, variableIds: string[]): Chart {
    const selectedId = chart.selected?.id
    if (typeof selectedId === 'undefined') return chart
    const api = chart.nodes[selectedId].properties.api
    if (typeof api === 'undefined') return chart

    // init saveConfig object if not already initialized
    api.response.saveConfig = api.response.saveConfig || {}

    // get existing saveConfig object or create new one. If new one init with empty accessPattern string
    const saveConfig = api.response.saveConfig[saveConfigId] || {
      configId: saveConfigId,
      accessPattern: '',
    }
    saveConfig.variables = variableIds
    api.response.saveConfig[saveConfigId] = saveConfig

    chart.nodes[selectedId].properties.api = api
    return chart
  }

  /**
   * Handles removal of SINGLE variable.
   *
   * // IDEA: should we remove saveConfig if last variable is removed and accesspattern is empty?
   * @param {Chart} chart
   * @param {string} variableId
   */
  function handleVariableRemoval(chart: Chart, variableId: string): Chart {
    const selectedId = chart.selected?.id
    if (typeof selectedId === 'undefined') return chart
    const api = chart.nodes[selectedId].properties.api
    if (typeof api === 'undefined') return chart

    if (typeof api.response.saveConfig !== 'undefined') {
      const saveConfig = api.response.saveConfig[saveConfigId]
      saveConfig.variables = saveConfig.variables.filter((id: string) => id !== variableId)

      api.response.saveConfig[saveConfigId] = saveConfig
    }

    chart.nodes[selectedId].properties.api = api
    return chart
  }

  /**
   * Callback function for variable selection component.
   * @param {Chart} chart
   * @param {string[]} prevSelectedVarIds
   * @param {string[]} selectedVarIds
   * @param {OnChangeOptions} options
   */
  function onVariableChange(
    chart: Chart,
    prevSelectedVarIds: string[],
    selectedVarIds: string[],
    options: OnChangeOptions,
  ): void {
    if (selectedVarIds >= prevSelectedVarIds) {
      // creation or selection
      chart = onVariableSelection(chart, selectedVarIds)
    } else {
      // at least one variable has been removed
      const removedVarIds = prevSelectedVarIds.filter((varId) => !selectedVarIds.includes(varId))
      removedVarIds.forEach((varId: string) => {
        chart = handleVariableRemoval(chart, varId)
      })
    }

    setStateCallback(chart)
  }

  /**
   * Handles access pattern textfield change.
   * @param {React.ChangeEvent<HTMLInputField>} event
   */
  function onAccessPatternChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const selectedId = chart.selected?.id
    if (typeof selectedId === 'undefined') return
    const api = chart.nodes[selectedId].properties.api
    if (typeof api === 'undefined') return

    // init saveConfig object if not already initialized
    api.response.saveConfig = api.response.saveConfig || {}

    // get existing saveConfig object or create new one. If new one init without variables
    const saveConfig = api.response.saveConfig[saveConfigId] || {
      configId: saveConfigId,
      variables: [],
    }
    // set changed access pattern
    saveConfig.accessPattern = event.target.value
    // set saveConfig back in chart
    api.response.saveConfig[saveConfigId] = saveConfig

    chart.nodes[selectedId].properties.api = api

    setAccessPattern(event.target.value)
    setStateCallback(chart)
  }

  /**
   * Handles deletion of this saveConfig.
   *
   * Removes all variables used by this saveConfig from chart.
   * Removes saveConfig from chart.
   */
  function onSaveConfigDelete(): void {
    const selectedId = chart.selected?.id
    if (typeof selectedId === 'undefined') return
    const api = chart.nodes[selectedId].properties.api
    if (typeof api === 'undefined') return

    // remove variables
    if (
      typeof api.response.saveConfig !== 'undefined' &&
      typeof api.response.saveConfig[saveConfigId] !== 'undefined'
    ) {
      for (const varId of api.response.saveConfig[saveConfigId].variables) {
        chart = removeVariableFromNode(chart, 'set', varId, selectedId)
      }

      // remove saveConfig object
      delete api.response.saveConfig[saveConfigId]
      if (isEmpty(api.response.saveConfig)) delete api.response.saveConfig
    }

    deleteSaveConfigCallback(saveConfigId)

    chart.nodes[selectedId].properties.api
    setStateCallback(chart)
  }

  return (
    <Grid container direction='row' justifyContent='space-evenly' alignItems='center'>
      <Grid item xs={11} style={{ height: '100%' }}>
        <TextField
          fullWidth
          onChange={onAccessPatternChange}
          placeholder={`result.list[0].isValid`}
          label={`Zugriffsmuster`}
          value={accessPattern}
          style={{ marginBottom: '10px' }}
        />

        <VariablesAutosuggestSelect
          chart={chart}
          isMulti={true}
          usageType='set'
          apiConfigId={saveConfigId}
          onChange={onVariableChange}
          isResult={false}
        />
      </Grid>
      <Grid item xs={1} style={{ height: '100%', textAlign: 'center' }} alignItems='center'>
        <IconButton onClick={onSaveConfigDelete} color='secondary'>
          <Delete />
        </IconButton>
      </Grid>
    </Grid>
  )
}
