import React, { createContext, useContext, useReducer, useCallback, useEffect } from 'react'

import { useBotContext } from './bot-context'
import { useLockingContext } from './locking-context'
import { useStudioNotificationContext } from './studio-notification-context'
import {
  AnswerTemplateData,
  EndpointUtterance,
  EndpointUtteranceAnswered,
  EndpointUtteranceUnanswered,
  LoadingState,
} from '../../@types/Knowledge/types'
import {
  getEndpointUtterances as getEndpointUtterancesApi,
  addUtteranceToNLUIntent as addUtteranceToNLUIntentApi,
  removeEndpointUtterances as removeEndpointUtteranceApi,
} from '../../api/StudioBackend'
import { useErrorContext } from './errorContext'
import ErrorComponent from 'components/Error/Error'
import { useAnswers } from './answers-context'
import { BotInfos } from '../../@types/BotInformation/types'

// ----- TYPE DEFINITIONS -----

// Maps Actions to Payloads - Standard does not need customization
type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key
      }
    : {
        type: Key
        payload: M[Key]
      }
}

type Range = {
  answeredFrom: number
  answeredTo: number
  unansweredFrom: number
  unansweredTo: number
}

type LoadEndpointUtterancesResult = {
  answeredEndpointUtterances?: EndpointUtteranceAnswered[]
  unansweredEndpointUtterances?: EndpointUtteranceUnanswered[]
  totalAnswered?: number
  totalUnanswered?: number
} | null

type TmpUtterance = {
  utterance: EndpointUtterance
  position: number
}

const LOAD_BATCH_SIZE = 24

type ApiLoadMode = 'answered' | 'unanswered' | 'all'

type ApiSort = 'oldest' | 'newest'

enum Types {
  SetInitialized = 'SET_INITIALIZED',
  SetLoading = 'SET_LOADING',
  SetRange = 'SET_RANGE',
  SetEndpointUtterances = 'SET_ENDPOINT_UTTERANCES',
  ResetState = 'RESET',
  ResetModeState = 'RESET_MODE',
}

type ModellmanagementContextPayload = {
  [Types.ResetState]: {}
  [Types.ResetModeState]: { mode: ApiLoadMode }
  [Types.SetLoading]: {
    loading: LoadingState
  }
  [Types.SetInitialized]: {
    isInitialized: boolean
  }
  [Types.SetRange]: {
    answeredFrom?: number
    answeredTo?: number
    unansweredFrom?: number
    unansweredTo?: number
  }
  [Types.SetEndpointUtterances]: {
    replace?: boolean
    totalAnswered?: number
    totalUnanswered?: number
    answered?: EndpointUtteranceAnswered[]
    unanswered?: EndpointUtteranceUnanswered[]
  }
}

type Action = ActionMap<ModellmanagementContextPayload>[keyof ActionMap<ModellmanagementContextPayload>]
type State = {
  loading: LoadingState | null
  isInitialized: boolean
  range: Range
  endpointUtterances: {
    totalAnswered: number | null
    totalUnanswered: number | null
    answered: EndpointUtteranceAnswered[] | null
    unanswered: EndpointUtteranceUnanswered[] | null
  }
}

type Dispatch = (action: Action) => void
type ModellmanagementContextProviderProps = { children: React.ReactNode }

/**
 * ----- CONTEXT -----
 * ModellmanagementContext is a local context used for storing everything needed to manage modell / endpoint utterances
 *
 * To access this context:
 * import { useModellmanagementContext } from '<path>/hooks/contexts/modellmanagement-context.tsx'
 *
 * // in a function
 * const {data, loading, resetDatamanagementContext} = useModellmanagementContext()
 *
 */
const ModellmanagementContext = createContext<
  | {
      state: State
      dispatch: Dispatch
      setLoading: (isLoading: LoadingState) => void
      loadEndpointUtterances: (
        mode: ApiLoadMode,
        sort: ApiSort,
        from?: number,
        to?: number,
        isRetry?: boolean,
      ) => Promise<LoadEndpointUtterancesResult>
    }
  | undefined
>(undefined)

function datamanagementReducer(state: State, action: Action): State {
  switch (action.type) {
    case Types.ResetState:
      return {
        ...state,
        loading: null,
        isInitialized: false,
      }
    case Types.ResetModeState: {
      const newState = { ...state }
      if (action.payload.mode === 'all' || action.payload.mode === 'answered') {
        // reset answered
        newState.endpointUtterances.answered = null
        newState.endpointUtterances.totalAnswered = null
        newState.range.answeredFrom = 0
        newState.range.answeredTo = LOAD_BATCH_SIZE
      }
      if (action.payload.mode === 'all' || action.payload.mode === 'unanswered') {
        // reset answered
        newState.endpointUtterances.unanswered = null
        newState.endpointUtterances.totalUnanswered = null
        newState.range.unansweredFrom = 0
        newState.range.unansweredTo = LOAD_BATCH_SIZE
      }
      return newState
    }
    case Types.SetRange:
      return {
        ...state,
        range: {
          ...state.range,
          ...action.payload,
        },
      }
    case Types.SetLoading: {
      return {
        ...state,
        loading: action.payload.loading,
      }
    }
    case Types.SetInitialized: {
      return {
        ...state,
        isInitialized: action.payload.isInitialized,
      }
    }
    case Types.SetEndpointUtterances: {
      const newEndpointUtts = { ...state.endpointUtterances }
      const replace = !!action.payload.replace
      if (action.payload.answered) {
        newEndpointUtts.answered = replace
          ? action.payload.answered
          : [...(newEndpointUtts.answered ?? []), ...action.payload.answered]
      }
      if (action.payload.unanswered) {
        newEndpointUtts.unanswered = replace
          ? action.payload.unanswered
          : [...(newEndpointUtts.unanswered ?? []), ...action.payload.unanswered]
      }
      if (typeof action.payload.totalAnswered !== 'undefined')
        newEndpointUtts.totalAnswered = action.payload.totalAnswered
      if (typeof action.payload.totalUnanswered !== 'undefined')
        newEndpointUtts.totalUnanswered = action.payload.totalUnanswered
      return {
        ...state,
        endpointUtterances: newEndpointUtts,
      }
    }
    default: {
      // helps us avoid typos!
      throw new Error(`[DatamanagementContext-Reducer] Unhandled action type: ${(action as any).type}`)
    }
  }
}

export function ModellmanagementContextProvider({ children }: ModellmanagementContextProviderProps): JSX.Element {
  const { setError } = useErrorContext()
  const { bot } = useBotContext()
  const { loading: answersLoading } = useAnswers()
  const [state, dispatch] = useReducer(datamanagementReducer, {
    isInitialized: false,
    loading: 'loading',
    range: {
      answeredFrom: 0,
      answeredTo: LOAD_BATCH_SIZE,
      unansweredFrom: 0,
      unansweredTo: LOAD_BATCH_SIZE,
    },
    endpointUtterances: {
      totalAnswered: null,
      totalUnanswered: null,
      answered: null,
      unanswered: null,
    },
  })

  // simple wrapper around setloading dispatch
  function setLoading(loading: LoadingState): void {
    dispatch({ type: Types.SetLoading, payload: { loading } })
  }

  // simple wrapper around setInitialized dispatch
  function setInitialized(isInitialized: boolean): void {
    dispatch({ type: Types.SetInitialized, payload: { isInitialized } })
  }

  /**
   * Loads endpoint utterances.
   * If from and to are specified uses them, if not uses values from state.
   * @param mode
   * @param from
   * @param to
   * @param isRetry
   * @returns
   */
  async function loadEndpointUtterances(
    mode: ApiLoadMode,
    sort: ApiSort,
    from?: number,
    to?: number,
    isRetry = false,
  ): Promise<LoadEndpointUtterancesResult> {
    try {
      if (!bot?.id) return null
      setLoading('loading')

      const result = await getEndpointUtterancesApi(
        bot.id,
        'specific',
        false,
        mode,
        from ?? state.range.answeredFrom,
        to ?? state.range.answeredTo,
        from ?? state.range.unansweredFrom,
        to ?? state.range.unansweredTo,
        sort,
      )

      if (!result) throw new Error('Load EndpointUtterances Error')
      setLoading(undefined)
      return result
    } catch (err) {
      if (!isRetry) {
        // try again a second time
        loadEndpointUtterances(mode, sort, from, to, true)
      } else {
        // set error
        setError(
          'Knowledge.specific.modelmanagment',
          'Das Nutzerfragen konnten nicht geladen werden. Bitte versuchen Sie es erneut.',
          'Erneut versuchen',
          () => loadEndpointUtterances(mode, sort, from, to),
          'Fehler beim Laden der Nutzerfragen',
        )
        setLoading(undefined)
      }
    }
    return null
  }

  async function init(): Promise<void> {
    const result = await loadEndpointUtterances('all', 'newest')
    if (result === null) return
    // we can put undefined or non-present values here. reduces takes care of correct handling
    dispatch({
      type: Types.SetEndpointUtterances,
      payload: {
        totalAnswered: result.totalAnswered,
        totalUnanswered: result.totalUnanswered,
        answered: result.answeredEndpointUtterances,
        unanswered: result.unansweredEndpointUtterances,
        replace: true,
      },
    })
    if (!state.isInitialized) setInitialized(true)
  }

  useEffect(function () {
    init()
    return () => {
      dispatch({ type: Types.ResetState, payload: {} })
    }
  }, [])

  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  // const value = useMemo(() => [state, dispatch], [state])
  const value = { state, dispatch, loadEndpointUtterances, setLoading }
  return (
    <ErrorComponent errorCode='Knowledge.specific.infomanagement'>
      <ModellmanagementContext.Provider value={value}>{children}</ModellmanagementContext.Provider>
    </ErrorComponent>
  )
}

/**
 * Consume only for now.
 * @returns
 */
export function useModellmanagementContext(): {
  loading: LoadingState
  isInitialized: boolean
  range: Range
  endpointUtterances: {
    totalAnswered: number | null
    totalUnanswered: number | null
    answered: EndpointUtteranceAnswered[] | null
    unanswered: EndpointUtteranceUnanswered[] | null
  }
  addUtteranceToAnswer: (utterance: EndpointUtterance, intent: string, mode: ApiLoadMode) => Promise<void>
  deleteUtterance: (utterance: EndpointUtterance, mode: ApiLoadMode) => Promise<void>
  loadNextUtterances: (mode: ApiLoadMode, sort: ApiSort) => Promise<void>
  resetCompleteMode: (mode: ApiLoadMode) => void // resets range for utterance fetching (e.g. when switching between sort modes)
} {
  const { bot } = useBotContext() as { bot: BotInfos } // can cast here as botinfos have to be loaded for this to be initialied
  const context = useContext(ModellmanagementContext)
  if (context === undefined) {
    throw new Error(
      '[useModellmanagementContext] useModellmanagementContext must be used within a ModellmanagementContextProvider',
    )
  }
  const { state, dispatch, setLoading, loadEndpointUtterances } = context
  const { range, endpointUtterances } = state

  const { flaggedAnswers: answers, setModelStatus, loading: answersLoading } = useAnswers()
  const { setNotification } = useStudioNotificationContext()

  /**
   * Loads next batch of utterances based on already loaded ones (determined via range)
   * and mode.
   */
  const loadNextUtterances = useCallback(
    async (mode: ApiLoadMode, sort: ApiSort): Promise<void> => {
      const promises: Promise<LoadEndpointUtterancesResult>[] = []
      if (mode === 'all' || mode === 'answered') {
        const newFrom = range.answeredFrom + LOAD_BATCH_SIZE
        const newTo = range.answeredTo + LOAD_BATCH_SIZE
        promises.push(loadEndpointUtterances(mode, sort, newFrom, newTo))
        if (mode === 'answered') {
          // push dummy promise for unanswered
          promises.push(
            new Promise<LoadEndpointUtterancesResult>((resolve) => {
              resolve(null)
            }),
          )
        }
        dispatch({ type: Types.SetRange, payload: { answeredTo: newTo } })
      }
      if (mode === 'all' || mode === 'unanswered') {
        if (mode === 'unanswered') {
          // push dummy promise
          promises.push(
            new Promise<LoadEndpointUtterancesResult>((resolve) => {
              resolve(null)
            }),
          )
        }
        const newFrom = range.unansweredFrom + LOAD_BATCH_SIZE
        const newTo = range.unansweredTo + LOAD_BATCH_SIZE
        promises.push(loadEndpointUtterances(mode, sort, newFrom, newTo))
        dispatch({ type: Types.SetRange, payload: { unansweredTo: newTo } })
      }
      const [answered, unanswered] = await Promise.all(promises)
      dispatch({
        type: Types.SetEndpointUtterances,
        payload: {
          answered: answered !== null ? answered.answeredEndpointUtterances : [],
          unanswered: unanswered !== null ? unanswered.unansweredEndpointUtterances : [],
          totalAnswered: answered !== null ? answered.totalAnswered : undefined,
          totalUnanswered: unanswered !== null ? unanswered.totalUnanswered : undefined,
        },
      })
    },
    [range],
  )

  /**
   * Adds utterance to answer.
   * - Removes utterance from endpoint list
   * - makes API call to add utterance to answer
   * - TODO: check if we need to update answer locally as well - do we need the new utterance in the loacl context
   * - if call fails, re-adds utterance to list at same position
   */
  const addUtteranceToAnswer = useCallback(
    async (utterance: EndpointUtterance, intent: string, mode: ApiLoadMode) => {
      // build tempUtteranceObj to re-add utterance to list if add call fails
      const allUtts =
        mode === 'answered' ? endpointUtterances.answered : mode === 'unanswered' ? endpointUtterances.unanswered : []
      const position = allUtts ? allUtts.findIndex((utt) => utt.text === utterance.text) : -1

      // remove utterance from list
      // we update visible things first and make the api call in the background
      const payload: ModellmanagementContextPayload[Types.SetEndpointUtterances] = { replace: true }
      if (allUtts) allUtts.splice(position, 1) // inplace - remove utterance
      if (mode === 'answered') {
        payload.answered = allUtts as EndpointUtteranceAnswered[]
        if (endpointUtterances.totalAnswered !== null) payload.totalAnswered = endpointUtterances.totalAnswered - 1
      } else if (mode === 'unanswered') {
        payload.unanswered = allUtts as EndpointUtteranceUnanswered[]
        if (endpointUtterances.totalUnanswered !== null)
          payload.totalUnanswered = endpointUtterances.totalUnanswered - 1
      }
      dispatch({
        type: Types.SetEndpointUtterances,
        payload,
      })

      // api call
      try {
        const res = await addUtteranceToNLUIntentApi(bot.id, intent, 'specific', [utterance.text])
        // res should be undefined
        if (res === null) throw new Error()
        // success, update the model status to enable training button
        setModelStatus({ trainingStatus: 'NeedsTraining' })
      } catch (err) {
        // api call failed, readd the utterance to the list
        const payload: ModellmanagementContextPayload[Types.SetEndpointUtterances] = { replace: true }
        if (allUtts) allUtts.splice(position, 0, utterance) // inplace - add utterance
        if (mode === 'answered') {
          payload.answered = allUtts as EndpointUtteranceAnswered[]
          if (endpointUtterances.totalAnswered !== null) payload.totalAnswered = endpointUtterances.totalAnswered - 1
        } else if (mode === 'unanswered') {
          payload.unanswered = allUtts as EndpointUtteranceUnanswered[]
          if (endpointUtterances.totalUnanswered !== null)
            payload.totalUnanswered = endpointUtterances.totalUnanswered - 1
        }
        dispatch({
          type: Types.SetEndpointUtterances,
          payload,
        })
        setNotification('error', 'Fehler beim Übernehmen der Zuordnung. Bitte versuchen Sie es später erneut.')
      }
    },
    [endpointUtterances, answers],
  )

  /**
   * Deletes utterance.
   * - Removes utterance from endpoint list
   * - makes API call to add utterance to answer
   * - if call fails, re-adds utterance to list at same position
   */
  const deleteUtterance = useCallback(
    async (utterance: EndpointUtterance, mode: ApiLoadMode) => {
      // build tempUtteranceObj to re-add utterance to list if add call fails
      const allUtts =
        mode === 'answered' ? endpointUtterances.answered : mode === 'unanswered' ? endpointUtterances.unanswered : []
      const position = allUtts ? allUtts.findIndex((utt) => utt.text === utterance.text) : -1

      // remove utterance from list
      // we update visible things first and make the api call in the background
      const payload: ModellmanagementContextPayload[Types.SetEndpointUtterances] = { replace: true }
      if (allUtts) allUtts.splice(position, 1) // inplace - remove utterance
      if (mode === 'answered') {
        payload.answered = allUtts as EndpointUtteranceAnswered[]
        if (endpointUtterances.totalAnswered !== null) payload.totalAnswered = endpointUtterances.totalAnswered - 1
      } else if (mode === 'unanswered') {
        payload.unanswered = allUtts as EndpointUtteranceUnanswered[]
        if (endpointUtterances.totalUnanswered !== null)
          payload.totalUnanswered = endpointUtterances.totalUnanswered - 1
      }
      dispatch({
        type: Types.SetEndpointUtterances,
        payload,
      })

      // api call
      try {
        const res = await removeEndpointUtteranceApi(bot.id, 'specific', [utterance.text])
        // res should be undefined
        if (res === null) throw new Error()
      } catch (err) {
        // api call failed, readd the utterance to the list
        const payload: ModellmanagementContextPayload[Types.SetEndpointUtterances] = { replace: true }
        if (allUtts) allUtts.splice(position, 0, utterance) // inplace - add utterance
        if (mode === 'answered') {
          payload.answered = allUtts as EndpointUtteranceAnswered[]
          if (endpointUtterances.totalAnswered !== null) payload.totalAnswered = endpointUtterances.totalAnswered - 1
        } else if (mode === 'unanswered') {
          payload.unanswered = allUtts as EndpointUtteranceUnanswered[]
          if (endpointUtterances.totalUnanswered !== null)
            payload.totalUnanswered = endpointUtterances.totalUnanswered - 1
        }
        dispatch({
          type: Types.SetEndpointUtterances,
          payload,
        })
        setNotification('error', 'Fehler beim Entfernen der Nutzerfrage. Bitte versuchen Sie es später erneut.')
      }
    },
    [endpointUtterances],
  )

  /**
   * Use this to reset when switching between sorts.
   */
  const resetCompleteMode = useCallback((mode: ApiLoadMode): void => {
    dispatch({ type: Types.ResetModeState, payload: { mode } })
  }, [])

  const { loading, isInitialized } = state

  return {
    loading: loading ?? answersLoading === 'loading' ? 'loading' : undefined,
    range,
    endpointUtterances,
    isInitialized,
    addUtteranceToAnswer,
    deleteUtterance,
    loadNextUtterances,
    resetCompleteMode,
  }
}
