import Fuse from 'fuse.js'
import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate, useParams, useResolvedPath } from 'react-router-dom'

import { cloneDeep, isEmpty } from 'lodash'
import { v4 as uuid } from 'uuid'
import { useBotContext } from '../../../../hooks/contexts/bot-context'
import { useErrorContext } from '../../../../hooks/contexts/errorContext'

import { translate as translateApi, translateBatch as translateBatchApi } from '../../../../api/StudioBackend'

// Custom Components
import ContentPage, { ContentPageHeader } from '../../../../components/Page/ContentPage'

import * as chartUtils from 'utils/chartUtils'
import { canBeTranslatedAutomatically, isSupportedLangauge } from 'utils/languageUtils'
import * as translationsUtils from 'utils/translationsUtils'

// constants
import { APP_TITLE, LANGUAGE_DICTIONARY_DE } from '../../../../utils/constants'

import { Delete } from '@mui/icons-material'
import { Chip, IconButton, Typography, useTheme } from '@mui/material'
import { Button } from 'components/Buttons'
import { Can } from 'components/Can/Can'
import BaseCard from 'components/Cards/BaseCard'
import { Dialog } from 'components/Dialogs'
import CircularLoading from 'components/Loading/CircularLoading'
import Table from 'components/Table/Table'
import { Textfield } from 'components/TextInput/Textfield'
import CustomizedTooltip from 'components/Tooltips/CustomContentTooltip'
import { useAnswers } from 'hooks/contexts/answers-context'
import { useChartContext } from 'hooks/contexts/chart-context'
import { useDatamanagementContext } from 'hooks/contexts/datamanagement-context'
import { useLockingContext } from 'hooks/contexts/locking-context'
import { useStudioNotificationContext } from 'hooks/contexts/studio-notification-context'
import { makeStyles } from 'tss-react/mui'
import { BotInfos } from '../../../../@types/BotInformation/types'
import { Answers, IntentTriggerButton } from '../../../../@types/Knowledge/types'
import getAnswerTranslationTableRow from './AnswerTranslationRow'
import { areAllTextsOfAnswersTranslated } from 'utils/answerUtils'

type Sort = 'missingFirst' | 'onlyAlphabetical' | 'byType'

type TranslationAction = {
  actionIndex: number // index of action in action array of answer; required to set translation to correct action
}

type TranslationIntentTriggerButton = {
  button: IntentTriggerButton
}

type TranslationType =
  | { type: 'answerTemplate'; translation: { orig: string; trans: string } }
  | { type: 'action'; translation: { orig: string; trans: string } & TranslationAction }
  | { type: 'intentTriggerButton'; translation: { orig: string; trans: string } & TranslationIntentTriggerButton }

export type Translation = {
  localTransId: string
  answerId: string
} & TranslationType

type TranslationContent = {
  [localTransId: string]: Translation
}

const useRowStyles = makeStyles()((theme) => ({
  actionContainer: { display: 'flex' },
  iconButton: {
    marginLeft: theme.spacing(1),
    marginBottom: 'auto',
    marginTop: 'auto',
    padding: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  icon: { fontSize: '1.2rem' },
}))

const useStyles = makeStyles()((theme) => ({
  actionContainer: { display: 'flex' },
  rowTranslationContainer: {
    display: 'flex',
    width: '100%',
  },
  iconButton: {
    marginLeft: theme.spacing(1),
    marginBottom: 'auto',
    marginTop: 'auto',
    padding: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  icon: { fontSize: '1.2rem' },
  missingTranslationWarning: {
    marginBottom: 'auto',
    marginTop: 'auto',
  },
}))

export default function AnswerTranslations(): React.ReactElement {
  const { classes } = useStyles()
  const theme = useTheme()
  const url = useResolvedPath('').pathname
  const { setNotification } = useStudioNotificationContext()
  const { bot } = useBotContext() as { bot: BotInfos }
  const { botId, langCode: languageCode } = useParams() as { botId: string; langCode: string }
  const navigate = useNavigate()
  // lock state
  const { lockState, lockedBy, lockInitialized } = useLockingContext()
  const { chart } = useChartContext()
  const {
    flaggedAnswers: answers,
    updateMultipleAnswersTranslationsBatch,
    loading: loadingAnswers,
    hasChanges: hasChangedAnswers,
    saveChangedAnswers,
    discardChanges,
    getAnswer,
  } = useAnswers()
  const prevLoadingAnswersRef = useRef(loadingAnswers)
  const { doAllVariablesInTextExist } = useDatamanagementContext()

  // search
  const [searchString, setSearchString] = useState<string>()
  const [sort, setSort] = useState<Sort>('missingFirst')
  // table content
  const [currentEditedLocalTransId, setCurrentEditedLocalTransId] = useState<string>()
  const [tableContent, setTableContent] = useState<TranslationContent>({}) // complete content
  const [displayedTableContent, setDisplayedTableContent] = useState<TranslationContent>({}) // filtered (searched) content (displayed)
  const [showDiscardDialog, setShowDiscardDialog] = useState<boolean>()
  // auto translations
  const [translating, setTranslating] = useState<'translatingBatch' | string>() // string is localTransId if single string is translated
  // error / invalid values for tranlation
  const [errors, setErrors] = useState<{ [transId: string]: string }>({})
  const [showingErrorNotification, setShowingErrorNotification] = useState<boolean>(false)

  // notifications
  // const [notificationStatus, setNotificationStatus] = useState<'success' | 'error'>() // we do not need to specify in more detail because we have error message
  // const [showNotification, setShowNotification] = useState<boolean>()
  // const [notificationMessage, setNotificationMessage] = useState<string>()
  const { setError } = useErrorContext()

  /**
   * Sorts translation content.
   * @param translationContent
   * @param sort
   * @returns
   */
  function sortTranslationContent(translationContent: TranslationContent, sort: Sort): TranslationContent {
    let sortedTranslationContent = translationContent
    if (sort === 'missingFirst') {
      // sort lists alphabetically based on primary language and put missing translations first
      const translatedList: Translation[] = []
      const missingTranslationList: Translation[] = []

      for (const trans of Object.values(translationContent)) {
        if (trans.translation.trans !== '') {
          // translation exists
          translatedList.push(trans)
        } else {
          missingTranslationList.push(trans)
        }
      }

      const sortedTranslatedList = translatedList.sort((a, b) => {
        if (a.translation.orig < b.translation.orig) return -1
        if (a.translation.orig > b.translation.orig) return 1
        return 0
      })
      const sortedMissingTransList = missingTranslationList.sort((a, b) => {
        if (a.translation.orig < b.translation.orig) return -1
        if (a.translation.orig > b.translation.orig) return 1
        return 0
      })

      sortedTranslationContent = [...sortedMissingTransList, ...sortedTranslatedList].reduce(
        (acc, item) => ({ ...acc, [item.localTransId]: item }),
        {},
      )
    } else if (sort === 'byType') {
      // sort: action, answerTemplate, intentTriggerButton
      // TODO implement
    } else {
      // sort alphabetically only
      sortedTranslationContent = Object.values(translationContent)
        .sort((a, b) => {
          if (a.translation.orig < b.translation.orig) return -1
          if (a.translation.orig > b.translation.orig) return 1
          return 0
        })
        .reduce((acc, item) => ({ ...acc, [item.localTransId]: item }), {})
    }

    return sortedTranslationContent
  }

  /**
   * Prepares content for the table based on the translation file.
   * @param translations
   */
  function prepareTranslationTable(answers: Answers, _sort?: Sort): void {
    if (!chart) return
    if (!_sort) _sort = sort
    const { primaryLanguage } = bot

    // build table content by iterating over all answers and extracting all texts that need translation
    const translationContent: TranslationContent = {}
    for (const answer of Object.values(answers[primaryLanguage])) {
      const { answerId, answerTemplate, actions, intentTriggerButtons, answerType } = answer
      const {
        answerTemplate: answerTemplateTrans,
        actions: actionsTrans,
        intentTriggerButtons: intentTriggerButtonsTrans,
      } = answers[languageCode][answerId]

      // add answerTemplate
      const localTransIdAnswerTemplate = uuid()
      translationContent[localTransIdAnswerTemplate] = {
        localTransId: localTransIdAnswerTemplate,
        answerId,
        type: 'answerTemplate',
        translation: {
          orig: answerTemplate ?? '',
          trans: answerTemplateTrans ?? '',
        },
      }

      if (actions && actions.length > 0) {
        for (const [idx, action] of actions.entries()) {
          const transId = uuid()
          translationContent[transId] = {
            answerId,
            localTransId: transId,
            type: 'action',
            translation: {
              actionIndex: idx,
              orig: action.action ?? '',
              trans: (actionsTrans.length >= idx + 1 ? actionsTrans[idx].action : '') ?? '',
            },
          }
        }
      }

      if (intentTriggerButtons) {
        for (const button of Object.keys(intentTriggerButtons) as IntentTriggerButton[]) {
          const transId = uuid()
          translationContent[transId] = {
            localTransId: transId,
            answerId,
            type: 'intentTriggerButton',
            translation: {
              button: button,
              orig: intentTriggerButtons[button] ?? '',
              trans: (intentTriggerButtonsTrans ? intentTriggerButtonsTrans[button] : '') ?? '',
            },
          }
        }
      }
    }

    // filter out empty orig text and replace variable ids with display names
    const filtered = Object.values(translationContent)
      .filter((trans) => translationsUtils.shouldTranslationBeDisplayed(trans.translation.orig))
      .map((trans) => {
        const copy = cloneDeep(trans)

        copy.translation.orig = chartUtils.replaceVarIdWithDisplayName(chart, copy.translation.orig)
        copy.translation.trans = chartUtils.replaceVarIdWithDisplayName(chart, copy.translation.trans)
        return copy
      })
    const filteredTranslationContent: TranslationContent = {}
    for (const trans of filtered) {
      filteredTranslationContent[trans.localTransId] = trans
    }

    // we now have all texts in the list
    // now sort.
    const sortedTranslationContent = sortTranslationContent(translationContent, _sort)

    setDisplayedTableContent(sortedTranslationContent)
    setTableContent(sortedTranslationContent)
  }

  /**
   * Filters content of translation list based on search string and returns filtered content.
   * @param translationContent
   */
  function filterTranslationsForSearch(
    translationContent: TranslationContent,
    searchString?: string,
  ): TranslationContent {
    if (typeof searchString === 'undefined') return translationContent

    const fuseOptions = {
      shouldSort: true,
      threshold: 0.4,
      minMatchCharLength: 1,
      keys: ['translation.orig', 'translation.trans', 'answerId', 'type'],
    }

    const fuse = new Fuse(Object.values(translationContent ?? {}), fuseOptions)
    const searchResults = fuse.search(searchString).map((result) => result.item)
    const result: TranslationContent = searchResults.reduce((acc, item) => ({ ...acc, [item.localTransId]: item }), {})
    return result
  }

  function checkForInvalidVariablesAfterTranslationChange(text: string): string | undefined {
    // check if all referenced variables do exist.
    const checkResult = doAllVariablesInTextExist(text)
    if (!checkResult.doExist) {
      // build string for notifying user
      const invalidVariables = Object.keys(checkResult.detailed)
        .filter((variable) => !checkResult.detailed[variable])
        .map((variable) => `"${variable}"`)

      const message = `Ungültige Variable(n) in Text: ${invalidVariables.join(
        ', ',
      )}. Korrigieren oder beheben Sie das Problem. Nutzer*innen sehen sonst die falsche Variablereferenz und keinen Wert im Chat!`
      return message
    }
  }

  /**
   * Handles user change of translation.
   * Updates local state, but does not yet update answer (this is done in confirm.)
   * @param localTransId
   * @param newTrans
   */
  function onTranslationChange(localTransId: string, newTrans: string, submit: boolean): void {
    const err = checkForInvalidVariablesAfterTranslationChange(newTrans)

    const newTableContent = { ...tableContent }
    newTableContent[localTransId].translation.trans = newTrans
    const newDisplayedContent = { ...displayedTableContent }
    newDisplayedContent[localTransId].translation.trans = newTrans
    setTableContent(newTableContent)
    setDisplayedTableContent(newDisplayedContent)
    const newErrors = { ...errors }
    if (err) newErrors[localTransId] = err
    else delete newErrors[localTransId]
    setErrors(newErrors)
    if (submit) {
      submitTranslationsToContext(newTableContent)
    }
  }

  /**
   * Updates answers in context with new translations.
   * @param translationContent
   */
  function submitTranslationsToContext(translationContent: TranslationContent): void {
    if (!answers) return
    const changedAnswers: Answers = { [languageCode]: {} }
    for (const trans of Object.values(translationContent)) {
      if (!changedAnswers[languageCode][trans.answerId]) {
        changedAnswers[languageCode][trans.answerId] = answers.answers[languageCode][trans.answerId]
      }

      switch (trans.type) {
        case 'answerTemplate': {
          changedAnswers[languageCode][trans.answerId].answerTemplate = trans.translation.trans
          break
        }
        case 'action': {
          const actionidx = trans.translation.actionIndex
          if (!changedAnswers[languageCode][trans.answerId].actions) {
            changedAnswers[languageCode][trans.answerId].actions =
              answers.answers[bot.primaryLanguage][trans.answerId].actions
          }
          if (changedAnswers[languageCode][trans.answerId].actions.length >= actionidx + 1) {
            // can update action at this position
            changedAnswers[languageCode][trans.answerId].actions[actionidx].action = trans.translation.trans
          }
          break
        }
        case 'intentTriggerButton': {
          if (!changedAnswers[languageCode][trans.answerId].intentTriggerButtons) {
            changedAnswers[languageCode][trans.answerId].intentTriggerButtons = {}
          }
          const intentTriggerButtons = changedAnswers[languageCode][trans.answerId].intentTriggerButtons ?? {}
          intentTriggerButtons[trans.translation.button] = trans.translation.trans
          changedAnswers[languageCode][trans.answerId].intentTriggerButtons = intentTriggerButtons
          break
        }
        default:
          console.warn(`[AnswerTranslations] translation type ${(trans as any).type} does not exist`)
      }
    }
    // we do not need to update local state. This already happened.
    updateMultipleAnswersTranslationsBatch(changedAnswers, 'modified')
  }

  /**
   * Translates the given text and updates local state (not the actual answer) with new translation.
   * @param text
   * @param localTransId
   */
  async function translate(text: string, localTransId: string): Promise<void> {
    try {
      setTranslating(localTransId)
      setCurrentEditedLocalTransId(localTransId)

      const translationResult = await translateApi(text, bot.primaryLanguage, languageCode, bot.id, true)
      const translation = translationResult?.translatedText
      if (!translation) throw new Error('Translation is undefined')

      // set translated text in table content to populated field and let user confirm before submitting to context
      onTranslationChange(localTransId, translation, false)
    } catch (err) {
      setNotification(
        'error',
        'Text konnte nicht übersetzt werden. Bitte versuchen Sie es in wenigen Augenblicken erneut.',
      )
    }
    setTranslating(undefined)
  }

  /**
   * Finds and translates all texts that are missing translation.
   * updates local state and also directly commits translation results into answer context.
   */
  async function onTranslateAll(): Promise<void> {
    setSearchString('') // reset search string
    setTranslating('translatingBatch')

    // find missing translations
    const missingTranslations: { text: string; localTransId: string }[] = []
    for (const pair of Object.values(tableContent)) {
      if (!pair.translation.trans) {
        missingTranslations.push({ text: pair.translation.orig, localTransId: pair.localTransId })
      }
    }

    try {
      const translationResult = await translateBatchApi(
        missingTranslations.map((row) => row.text),
        bot.primaryLanguage,
        languageCode,
        botId,
        true,
      )
      if (!translationResult) throw new Error('Translation result is undefined')

      // set translated texts
      const { translatedTexts } = translationResult
      const updatedTableContent = { ...tableContent } // for updating local state
      const changed: TranslationContent = {} // for updatingn only changed answers in answer context
      let newErrors = { ...errors }
      for (const translatedText of translatedTexts) {
        for (const pair of missingTranslations) {
          if (pair.text === translatedText.text) {
            updatedTableContent[pair.localTransId].translation.trans = translatedText.translatedText
            changed[pair.localTransId] = updatedTableContent[pair.localTransId]
            // check if all variables are valid
            const err = checkForInvalidVariablesAfterTranslationChange(translatedText.translatedText)
            if (err) {
              newErrors = { ...errors, [pair.localTransId]: err }
            }
          }
        }
      }

      // reset sort to alphabetical because we now have no missing translations
      setErrors(newErrors)
      setSort('onlyAlphabetical')
      setTableContent(updatedTableContent)
      setDisplayedTableContent(updatedTableContent)
      submitTranslationsToContext(changed)
    } catch (err) {
      setNotification(
        'error',
        'Texte konnten nicht übersetzt werden. Bitte versuchen Sie es in wenigen Augenblicken erneut.',
      )
      console.error(err)
    }

    setTranslating(undefined)
  }

  /**
   * Handles search string change.
   * @param event
   */
  function onSearchStringChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setSearchString(event.target.value)
  }

  useEffect(
    function initialPrepareTableContentFromAnswers() {
      // initial data loading
      if (isEmpty(tableContent) && answers?.answers) {
        prepareTranslationTable(answers.answers, 'onlyAlphabetical')
      }
    },
    [answers, tableContent],
  )

  useEffect(
    function updateSearchResults() {
      if (typeof searchString !== 'undefined') {
        if (searchString === '') setDisplayedTableContent(tableContent)
        else {
          const filteredTranslationContent = filterTranslationsForSearch(tableContent, searchString)
          setDisplayedTableContent(filteredTranslationContent)
        }
      }
    },
    [searchString],
  )

  useEffect(
    function () {
      if (loadingAnswers === 'savingSuccess' && prevLoadingAnswersRef.current !== 'savingSuccess') {
        setNotification('success', 'Antwortübersetzungen erfolgreich gespeichert.')
      } else if (loadingAnswers === 'savingError' && prevLoadingAnswersRef.current !== 'savingError') {
        setNotification('error', 'Fehler beim Speichern der Antwortübersetzungen.')
      }

      prevLoadingAnswersRef.current = loadingAnswers
    },
    [loadingAnswers],
  )

  // Actions
  const SearchField = <Textfield value={searchString} placeholder='Suchen' onChange={onSearchStringChange} />

  const TranslateAllButton = (
    <CustomizedTooltip
      placement='bottom'
      disableInteractive
      content={
        <Typography variant='body1'>
          {!canBeTranslatedAutomatically(languageCode)
            ? 'Diese Sprache kann nicht automatisch übersetzt werden'
            : 'Alle fehlenden Texte automatisch übersetzen'}
        </Typography>
      }
      elements={
        <div>
          <Button
            size='normal'
            type='normal'
            icon='magic-line'
            iconType='remix'
            onClick={onTranslateAll}
            loading={translating === 'translatingBatch'}
            disabled={
              typeof translating !== 'undefined' ||
              typeof loadingAnswers !== 'undefined' || // TODO: probably needs refinement to be enabled if success state
              lockState !== 'canEdit' ||
              !canBeTranslatedAutomatically(languageCode) ||
              !answers ||
              areAllTextsOfAnswersTranslated(answers.answers, bot.primaryLanguage, languageCode).complete
            }
          >
            Alle übersetzen
          </Button>
        </div>
      }
    />
  )

  const isSaveButtonDisabled =
    !hasChangedAnswers ||
    typeof loadingAnswers !== 'undefined' ||
    typeof translating !== 'undefined' ||
    lockState !== 'canEdit' ||
    Object.keys(errors).length > 0

  const SaveButton = (
    <CustomizedTooltip
      placement='bottom'
      disableInteractive
      content={
        <Typography variant='body1'>
          {!isSaveButtonDisabled
            ? 'Änderungen speichern und veröffentlichen'
            : 'Keine Änderungen zum Speichern vorhanden'}
        </Typography>
      }
      elements={
        <div>
          <Can I='update' a='translations' passThrough>
            {(can: boolean): React.ReactElement => (
              <Button
                size='normal'
                type='success'
                icon='refresh-line'
                iconType='remix'
                onClick={saveChangedAnswers}
                loading={loadingAnswers === 'saving'}
                disabled={!can || isSaveButtonDisabled}
              >
                Speichern und veröffentlichen
              </Button>
            )}
          </Can>
        </div>
      }
    />
  )

  const actions = [SearchField, TranslateAllButton, SaveButton]

  const TableHeaderOrig = (
    <div style={{ display: 'flex' }}>
      <Typography style={{ marginRight: theme.spacing(1) }} fontWeight='bold'>
        Text (Primärsprache)
      </Typography>
      <Chip
        style={{ marginRight: theme.spacing(1) }}
        size='small'
        color='primary'
        variant='outlined'
        label={'Antworttyp'}
      />
      <Chip size='small' color='secondary' variant='outlined' label={'Texttyp'} />
    </div>
  )

  const TableHeaderTranslation = (
    <tr>
      <div style={{ display: 'flex' }}>
        <Typography style={{ marginTop: 'auto', marginBottom: 'auto' }} fontWeight='bolder'>
          Übersetzung
        </Typography>

        <CustomizedTooltip
          placement='top'
          disableInteractive
          content={
            <Typography>
              {sort === 'onlyAlphabetical' ? 'Fehlende Übersetzungen nach vorne sortieren.' : 'Alphabetisch sortieren.'}
            </Typography>
          }
          elements={
            <IconButton
              onClick={(): void => {
                if (answers) {
                  // set new sort state and update table content
                  const newSort = sort === 'missingFirst' ? 'onlyAlphabetical' : 'missingFirst'
                  setSort(newSort)
                  prepareTranslationTable(answers.answers, newSort)
                }
              }}
              aria-label='sort'
              className={classes.iconButton}
            >
              <i className={`${sort === 'onlyAlphabetical' ? 'ri-sort-desc' : 'ri-font-size'} ` + classes.icon}></i>
            </IconButton>
          }
        />
      </div>
    </tr>
  )

  return (
    <>
      <Helmet>
        <title>{APP_TITLE} - Übersetzungen</title>
      </Helmet>
      <ContentPage>
        <ContentPageHeader
          title={`${
            isSupportedLangauge(languageCode) ? LANGUAGE_DICTIONARY_DE[languageCode] : 'Sprache nicht unterstützt'
          }`}
          actions={actions}
          previousUrl={url.substring(0, url.lastIndexOf('/'))}
          previousUrlCallback={(): boolean => {
            if (hasChangedAnswers) setShowDiscardDialog(true)
            return !hasChangedAnswers
          }}
        />
        <BaseCard width={'100%'} height={'100%'} minHeight={'80vh'}>
          {loadingAnswers === 'loading' ? (
            <CircularLoading text='Übersetzungen werden geladen...' />
          ) : (
            <>
              {Object.keys(errors).length > 0 && (
                <div
                  style={{
                    background: 'rgba(255,0,0,0.2)',
                    padding: theme.spacing(3),
                    border: '1px solid rbga(255,0,0,0.5)',
                    borderRadius: theme.shape.borderRadius * 2,
                  }}
                >
                  <div style={{ display: 'flex' }}>
                    <i
                      className={`ri-error-warning-line`}
                      style={{ color: theme.palette.error.main, marginRight: theme.spacing(2) }}
                    />
                    <Typography variant='body1' style={{ color: theme.palette.error.main }} fontWeight='bolder'>
                      Es existieren Probleme in den Übersetzungen. Bitte korrigieren Sie diese:
                    </Typography>
                  </div>
                  {Object.values(errors)
                    .slice(0, 3)
                    .map((error, index) => (
                      <div key={`problem-${index}`} style={{ marginLeft: theme.spacing(4) }}>
                        <Typography style={{ color: theme.palette.error.main }}>- {error}</Typography>
                        {index === 2 && Object.values(errors).length > 3 && <Typography>...</Typography>}
                      </div>
                    ))}
                </div>
              )}
              <Table
                headers={[TableHeaderOrig, TableHeaderTranslation, 'Aktionen']}
                cellAlignPattern={['left', 'left', 'right']}
                width={['45%', '45%', '10%']}
                padding='medium'
                rows={Object.values(displayedTableContent).map((row, index) => {
                  return getAnswerTranslationTableRow({
                    classes,
                    index: index,
                    content: row,
                    editActive: currentEditedLocalTransId === row.localTransId,
                    onActivateEdit: (transId: string) => setCurrentEditedLocalTransId(transId),
                    disabled:
                      typeof loadingAnswers !== 'undefined' ||
                      typeof translating !== 'undefined' ||
                      lockState !== 'canEdit',
                    onTranslate: translate,
                    onChange: (newValue: string, localTransId: string, error?: string) => {
                      if (error) setErrors({ ...errors, [localTransId]: error })
                      setCurrentEditedLocalTransId(undefined)
                      onTranslationChange(localTransId, newValue, true)
                    },
                    errorMsg: errors[row.localTransId],
                    answerTitle: getAnswer(row.answerId)?.title,
                    answerType: getAnswer(row.answerId)?.answerType,
                  })
                })}
              />
            </>
          )}
        </BaseCard>
        {/* Discard changes dialog */}
        <Dialog
          id='discard-changes-dialog'
          size='small'
          open={showDiscardDialog}
          closable
          onClose={(): void => setShowDiscardDialog(undefined)}
          title='Ungespeicherte Änderungen'
          primaryActionButton={
            <Button
              size='small'
              type='danger'
              icon={<Delete />}
              onClick={(): void => {
                // discard all changes and go back
                discardChanges()
                navigate(-1)
              }}
            >
              Verwerfen
            </Button>
          }
        >
          <Typography>
            Es existieren ungespeicherte Änderungen. Sind Sie sicher, dass Sie zurückgehen möchten? Die Änderungen
            werden dabei verworfen.
          </Typography>
        </Dialog>
      </ContentPage>
    </>
  )
}
