import React from 'react'

//
import { makeStyles } from 'tss-react/mui'

import { Typography } from '@mui/material'
import { CustomNotification } from '../../../@types/CustomNotifications/types'
import { buildTimeString, buildTurnusString } from 'utils/notificationUtils'
import OverlappingNotificationHint from '../SharedComponents/OverlappingNotificationHint'
import { useCustomNotifications } from 'hooks/contexts/customnotifications-context'
import CircularLoading from 'components/Loading/CircularLoading'

const useStyles = makeStyles()((theme) => ({
  editor: { display: 'flex', flexDirection: 'column', height: '100%' },
  hint: { marginTop: 'auto', marginLeft: 'auto', marginRight: 'auto' },
}))

type StepContentProps = {
  notification: CustomNotification | boolean
  overlappingNotification?: CustomNotification
  onCreateOverlapping: (mode: 'create_active' | 'create_inactive') => void
}

export default function StepSummary({
  notification,
  overlappingNotification,
  onCreateOverlapping,
}: StepContentProps): React.ReactElement {
  const { classes } = useStyles()
  const { loadingState } = useCustomNotifications()

  function onCreateActivated(): void {
    onCreateOverlapping('create_active')
  }

  function onCreateDeactivated(): void {
    onCreateOverlapping('create_inactive')
  }

  return (
    <div className={classes.editor}>
      {typeof notification === 'boolean' ? (
        <Typography>
          Fehler: Notification ist noch nicht vollständig konfiguriert. Bitte konfigurieren Sie alle notwendingen
          Informationen in den vorherigen Schritten.
        </Typography>
      ) : (
        <>
          <Typography>{buildTurnusString(notification)}</Typography>
          <Typography>{buildTimeString(notification)}</Typography>
          {loadingState === 'checkingOverlap' && <CircularLoading text='Überprüfe Notification...' delayDisplay />}
          {overlappingNotification && (
            <div className={classes.hint}>
              <OverlappingNotificationHint
                type='create'
                overlappingNotification={overlappingNotification}
                onCreateActivated={onCreateActivated}
                onCreateDeactivated={onCreateDeactivated}
              />
            </div>
          )}
        </>
      )}
    </div>
  )
}
