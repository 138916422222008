import React, { memo, useEffect, useState } from 'react'
import jp from 'jsonpath'
import { isEqual, cloneDeep } from 'lodash'
import { makeStyles } from 'tss-react/mui'
import { useTheme } from '@mui/material/styles'
// types
import {
  Card,
  CardTypeEnum,
  IContainer,
  ITextBlock,
  InputTypeEnum,
  IAdaptiveCard,
  ICardElement,
} from '../../../../../../../../@types/SmartCards/types'
// components
import BaseCard from 'components/Cards/BaseCard'
import SmartCard from 'components/SmartCard/SmartCard'
import CircularLoading from 'components/Loading/CircularLoading'
// utils
import { changeValuesByPath } from 'utils/jsonUtils'
// previews & templates
import { mode1PreviewOptions, mode2PreviewNoStepBack, mode3PreviewNoStepBack } from './previews'
import {
  mode1TemplateOptions,
  mode1TemplateFurtherOptions,
  doubleRowTemplate,
  inputTemplateDE,
  inputTemplateEN,
  mode1TemplateStepbackDE,
  mode1TemplateStepbackEN,
  doubleRowTemplateFurtherOptions,
  tripleRowTemplate,
  tripleRowTemplateFurtherOptions,
} from './templates'
//
import ColorBox from './colorBox'

import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { Button, CustomIconButton } from 'components/Buttons'
import { setActionStyleDefault } from 'utils/smartCardUtils'
import { useBotContext } from 'hooks/contexts/bot-context'

const useStyles = makeStyles()((theme) => ({
  editHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  actionsList: {
    flex: '1 1 auto',
    overflowY: 'auto',
    paddingRight: theme.spacing(1),
  },
  menu: {
    display: 'flex',
    marginTop: theme.spacing(4),
  },
  actionMenuCard: {
    margin: `${theme.spacing(2)} auto`,
  },
  card: {
    border: `1px solid ${theme.palette.grey.A100}`,
    borderRadius: theme.shape.borderRadius,
  },
  addButton: {
    marginTop: theme.spacing(2),
    width: '300px',
  },
  input: {
    marginTop: theme.spacing(2),
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  delete: {
    marginTop: '16px', // to center based to textfield
  },
  menuIcon: {
    color: theme.palette.primary.main,
  },
  menuContainer: {
    width: '50%',
    marginTop: theme.spacing(2),
  },
  row: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
  },
  grid: {
    border: `1px solid ${theme.palette.grey.A100}`,
    borderRadius:
      typeof theme.shape.borderRadius === 'number' ? theme.shape.borderRadius * 2 : theme.shape.borderRadius,
    display: 'flex',
    width: '100%',
  },
  rowInputContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(2),
    width: '100%',
  },
  rowAddButton: {
    // marginTop: theme.spacing(2),
    width: '100%',
  },
  choosenColorBox: {
    marginRight: theme.spacing(1),
  },
  colorInput: {
    marginLeft: theme.spacing(1),
    minWidth: '70px',
  },
}))

type ChoiceActionsProps = {
  actionsContainer: IContainer
  setActionsContainerCallback: (actionsContainer: IContainer) => void
}

export default memo(function ChoiceActions({
  actionsContainer: origActionsContainer,
  setActionsContainerCallback,
}: ChoiceActionsProps): React.ReactElement {
  const { bot } = useBotContext()
  const [mode, setMode] = useState<1 | 2 | 3 | -1 | undefined>(-1) // 1 = only 1 action, 2 = 2 actions per row, 3 = 3 actions per row
  const [actionContainer, setActionContainer] = useState<IContainer>(origActionsContainer)
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)
  const [menuAnchorElColumns, setMenuAnchorElColumns] = useState<any[]>([])
  const [menuWidth, setMenuWidth] = useState(0)
  const { classes } = useStyles()
  const theme = useTheme()

  // Load mode if actions are already set and correct styles if necessary
  useEffect(() => {
    const container = origActionsContainer
    if (container.items && container.items.length === 0) {
      // action container is empty
      setMode(undefined)
    } else if (container.items && container.items.length >= 1) {
      // check what type of action is placed
      if (container.items[0].type === 'ColumnSet') {
        // 2 columns
        if (container.items[0].columns && container.items[0].columns.length === 2) {
          // mode 2
          setMode(2)
        } else if (container.items[0].columns && container.items[0].columns.length === 3) {
          // mode 3
          setMode(3)
        }
      } else if (container.items[0].type === 'ActionSet') {
        // mode 1
        setMode(1)
      } else {
        console.error('Card - CreationProcess - unexpected actions in actions container')
      }
    } else {
      console.error('Card - CreationProcess - unexpected actions in actions container')
    }
    // check if styles are set correctly
    const checkedContainer = setActionStyleDefault(container) as IContainer
    setActionContainer(checkedContainer)
  }, [])

  useEffect(() => {
    setActionsContainerCallback(actionContainer)
  }, [actionContainer])

  function onAddActions(mode: number): void {
    const tmpActionContainer = cloneDeep(actionContainer)
    if (mode === 1) {
      // 1 action per row
      tmpActionContainer.items = []
      tmpActionContainer.items.push(mode1TemplateOptions as ICardElement)
    } else if (mode === 2) {
      // 2 actions per row
      tmpActionContainer.items = []
      tmpActionContainer.items.push(doubleRowTemplate as ICardElement)
    } else if (mode === 3) {
      // 3 actions per row
      tmpActionContainer.items = []
      tmpActionContainer.items.push(tripleRowTemplate as ICardElement)
    }
    setActionContainer(tmpActionContainer)
  }

  // only mode 1
  function onAddActionSingleColumn(type: 'stepback' | 'option'): void {
    const tmpActionContainer = cloneDeep(actionContainer)
    if (tmpActionContainer.items && tmpActionContainer.items.length > 0) {
      if (type === 'stepback') {
        const tmpAction = cloneDeep(
          (bot?.primaryLanguage !== 'en' ? mode1TemplateStepbackDE : mode1TemplateStepbackEN) as ICardElement,
        )
        tmpActionContainer.items.push(tmpAction)
      } else if (type === 'option') {
        const tmpAction = cloneDeep(mode1TemplateFurtherOptions) as ICardElement
        tmpActionContainer.items.push(tmpAction)
      }
    }
    setActionContainer(tmpActionContainer)
  }

  // only mode 2
  function onAddActionDoubleColumn(): void {
    const tmpActionContainer = cloneDeep(actionContainer)
    if (tmpActionContainer.items && tmpActionContainer.items.length > 0) {
      tmpActionContainer.items.push(doubleRowTemplateFurtherOptions)
    }
    setActionContainer(tmpActionContainer)
  }

  // only mode 2
  function onAddActionDoubleColumnAction(rowIndex, columnIndex, type: 'stepback' | 'option'): void {
    const tmpActionContainer = cloneDeep(actionContainer)
    if (tmpActionContainer.items && tmpActionContainer.items.length > 0) {
      if (type === 'stepback') {
        const tmpAction = cloneDeep(
          bot?.primaryLanguage !== 'en' ? mode1TemplateStepbackDE : mode1TemplateStepbackEN,
        ) as ICardElement
        tmpActionContainer.items[rowIndex].columns[columnIndex].items.push(tmpAction)
      } else if (type === 'option') {
        const tmpAction = cloneDeep(mode1TemplateFurtherOptions) as ICardElement
        tmpActionContainer.items[rowIndex].columns[columnIndex].items.push(tmpAction)
      }
    }
    setActionContainer(tmpActionContainer)
  }

  // only mode 3
  function onAddActionTripleColumn(): void {
    const tmpActionContainer = cloneDeep(actionContainer)
    if (tmpActionContainer.items && tmpActionContainer.items.length > 0) {
      tmpActionContainer.items.push(tripleRowTemplateFurtherOptions)
    }
    setActionContainer(tmpActionContainer)
  }

  // only mode 3
  function onAddActionTripleColumnAction(rowIndex, columnIndex, type: 'stepback' | 'option'): void {
    const tmpActionContainer = cloneDeep(actionContainer)
    if (tmpActionContainer.items && tmpActionContainer.items.length > 0) {
      if (type === 'stepback') {
        const tmpAction = cloneDeep(
          bot?.primaryLanguage !== 'en' ? mode1TemplateStepbackDE : mode1TemplateStepbackEN,
        ) as ICardElement
        tmpActionContainer.items[rowIndex].columns[columnIndex].items.push(tmpAction)
      } else if (type === 'option') {
        const tmpAction = cloneDeep(mode1TemplateFurtherOptions) as ICardElement
        tmpActionContainer.items[rowIndex].columns[columnIndex].items.push(tmpAction)
      }
    }
    setActionContainer(tmpActionContainer)
  }

  function onRemoveActions(index1, index2?): void {
    if (typeof index1 === 'undefined') {
      // remove all actions
      const tmpActionContainer = cloneDeep(actionContainer)
      tmpActionContainer.items = []
      setActionContainer(tmpActionContainer)
    } else {
      if (mode === 1) {
        // remove action in actionset
        const tmpActionContainer = cloneDeep(actionContainer)
        if (tmpActionContainer.items) tmpActionContainer.items.splice(index1, 1)
        setActionContainer(tmpActionContainer)
      } else if (mode === 2 && typeof index2 !== 'undefined') {
        // remove action in 2 column construct
        const tmpActionContainer = cloneDeep(actionContainer)
        if (tmpActionContainer.items && tmpActionContainer.items[index1].columns[index2].items)
          tmpActionContainer.items[index1].columns[index2].items = []
        setActionContainer(tmpActionContainer)
      } else if (mode === 3 && typeof index2 !== 'undefined') {
        // remove action in 3 column construct
        const tmpActionContainer = cloneDeep(actionContainer)
        if (tmpActionContainer.items && tmpActionContainer.items[index1].columns[index2].items)
          tmpActionContainer.items[index1].columns[index2].items = []
        setActionContainer(tmpActionContainer)
      }
    }
  }

  function onRemoveRow(index): void {
    const tmpActionContainer = cloneDeep(actionContainer)
    if (tmpActionContainer.items && tmpActionContainer.items[index]) tmpActionContainer.items.splice(index, 1)
    setActionContainer(tmpActionContainer)
  }

  function renderMenu(): React.ReactElement {
    return (
      <>
        <Typography style={{ marginTop: '32px' }}>
          Möchten Sie eine, zwei oder drei Aktionen pro Reihe? Weitere Reihen können im nächsten Schritt hinzugefügt
          werden.
        </Typography>
        <div className={classes.menu}>
          <Grid container>
            <Grid item sm={12} md={6}>
              <BaseCard
                title='1 Aktion'
                onClick={(): void => {
                  onAddActions(1)
                  setMode(1)
                }}
                titleVariant='h5'
                shadowWidth={10}
                shadowBlur={10}
                width='300px'
                height='250px'
                className={classes.actionMenuCard}
              >
                <SmartCard className={classes.card} card={mode1PreviewOptions as IAdaptiveCard} />
              </BaseCard>
            </Grid>
            <Grid item sm={12} md={6}>
              <BaseCard
                title='2 Aktionen'
                onClick={(): void => {
                  onAddActions(2)
                  setMode(2)
                }}
                titleVariant='h5'
                shadowWidth={10}
                shadowBlur={10}
                width='300px'
                height='250px'
                className={classes.actionMenuCard}
              >
                <SmartCard className={classes.card} card={mode2PreviewNoStepBack as IAdaptiveCard} />
              </BaseCard>
            </Grid>
            <Grid item md={12}>
              <BaseCard
                title='3 Aktionen'
                onClick={(): void => {
                  onAddActions(3)
                  setMode(3)
                }}
                titleVariant='h5'
                shadowWidth={10}
                shadowBlur={10}
                width='400px'
                height='250px'
                className={classes.actionMenuCard}
              >
                <SmartCard className={classes.card} card={mode3PreviewNoStepBack as IAdaptiveCard} />
              </BaseCard>
            </Grid>
          </Grid>
        </div>
      </>
    )
  }

  /** DROPDOWN MENU HANDLING **/

  function handleClick(event): void {
    setMenuWidth(event.currentTarget.clientWidth)
    setMenuAnchorEl(event.currentTarget)
  }
  function handleClose(): void {
    setMenuAnchorEl(null)
  }

  function handleClickColumns(event, index): void {
    setMenuWidth(event.currentTarget.clientWidth)
    const tmpMenuAnchorElColumns = [...menuAnchorElColumns]
    tmpMenuAnchorElColumns['anchor' + index] = event.currentTarget
    setMenuAnchorElColumns(tmpMenuAnchorElColumns)
  }
  function handleCloseColumns(index): void {
    // const tmpMenuAnchorElColumns = [...menuAnchorElColumns]
    // tmpMenuAnchorElColumns['anchor' + index] = null
    setMenuAnchorElColumns([])
  }

  const menu = typeof mode === 'undefined' ? renderMenu() : <></>
  // const mode1 = mode === 1 ? renderEditMode1() : <></>
  // const mode2 = mode === 2 ? renderEditMode2() : <></>

  return (
    <>
      {mode === -1 && <CircularLoading text='Lade Aktionen' />}
      {typeof mode === 'undefined' && menu}
      {mode === 1 && (
        <>
          <div className={classes.editHeader}>
            <IconButton
              onClick={(): void => {
                onRemoveActions(undefined)
                setMode(undefined)
              }}
            >
              <i className={`ri-arrow-left-line`} />
            </IconButton>
            {/* "Headline" for the current screen - no description for the back button */}
            <Typography>Aktionen definieren</Typography>
          </div>
          <div className={classes.actionsList}>
            {actionContainer.items &&
              actionContainer.items.map((action, index) => {
                return (
                  <div key={`container-${index}`} className={classes.inputContainer}>
                    <TextField
                      key={`input-${index}`}
                      fullWidth
                      className={classes.input}
                      label={`Option ${index + 1}`}
                      value={action.actions[0].title}
                      disabled={typeof action.actions[0].data['.stepBack'] !== 'undefined'}
                      onChange={(event): void => {
                        const tmpActionContainer = cloneDeep(actionContainer)
                        if (tmpActionContainer.items) {
                          tmpActionContainer.items[index].actions[0].title = event.target.value
                          tmpActionContainer.items[index].actions[0].data = event.target.value
                        }
                        setActionContainer(tmpActionContainer)
                        // changeTitleAndData({ ...actionContainer }, index, event.target.value)
                      }}
                    />
                    {!Object.prototype.hasOwnProperty.call(action.actions[0].data, '.stepBack') && (
                      <FormControl className={classes.input + ' ' + classes.colorInput}>
                        <InputLabel id={`action-color-label-${action.actions[0].title}`}>Farbe</InputLabel>
                        <Select
                          labelId={`action-color-label-${action.actions[0].title}`}
                          id={`action-color-${action.actions[0].title}`}
                          value={action.actions[0].style ?? 'default'}
                          label='Farbe'
                          onChange={(event): void => {
                            const tmpActionContainer = cloneDeep(actionContainer)
                            if (tmpActionContainer.items) {
                              tmpActionContainer.items[index].actions[0].style = event.target.value
                            }
                            setActionContainer(tmpActionContainer)
                          }}
                          renderValue={(selected): React.ReactNode => {
                            return <ColorBox color={selected} className={classes.choosenColorBox} />
                          }}
                        >
                          <MenuItem value={'default'}>
                            <ColorBox color={'default'} />
                          </MenuItem>
                          <MenuItem value={'positive'}>
                            <ColorBox color={'positive'} />
                          </MenuItem>
                          <MenuItem value={'destructive'}>
                            <ColorBox color={'destructive'} />
                          </MenuItem>
                        </Select>
                      </FormControl>
                    )}
                    <CustomIconButton
                      key={`delete-${index}`}
                      type='decline'
                      tooltip={index === 0 ? undefined : 'Aktion entfernen'}
                      onClick={(): void => {
                        onRemoveActions(index)
                      }}
                      disabled={actionContainer.items?.length === 1}
                      className={classes.delete}
                    />
                  </div>
                )
              })}
            {actionContainer.items && actionContainer.items.length > 4 && (
              <Typography style={{ marginTop: theme.spacing(2) }}>
                Sie haben mehr als 4 Aktionen in einer Spalte. Bitte bedenken Sie, dass zu viele mögliche Optionen die
                Benutzerfreundlichkeit für Nutzer*innen reduziert.
              </Typography>
            )}
            <Button
              aria-controls='menu'
              aria-haspopup='true'
              onClick={handleClick}
              icon={'add-line'}
              iconType='remix'
              className={classes.addButton}
              variant='secondary'
            >
              Aktion hinzufügen
            </Button>
            <Menu
              id='menu'
              anchorEl={menuAnchorEl}
              keepMounted
              open={Boolean(menuAnchorEl)}
              onClose={handleClose}
              elevation={2}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              PaperProps={{
                style: {
                  width: menuWidth,
                },
              }}
            >
              <MenuItem
                onClick={(): void => {
                  onAddActionSingleColumn('option')
                  setMenuAnchorEl(null)
                }}
              >
                <ListItemIcon className={classes.menuIcon}>
                  <i className='ri-chat-check-line'></i>
                </ListItemIcon>
                <ListItemText primary='Option hinzufügen' />
              </MenuItem>
              <MenuItem
                onClick={(): void => {
                  onAddActionSingleColumn('stepback')
                  setMenuAnchorEl(null)
                }}
              >
                <ListItemIcon className={classes.menuIcon}>
                  <i className='ri-chat-upload-line'></i>
                </ListItemIcon>
                <ListItemText primary='Zurück-Button hinzufügen' />
              </MenuItem>
            </Menu>
          </div>
        </>
      )}
      {mode === 2 && (
        <>
          <div className={classes.editHeader}>
            <IconButton
              onClick={(): void => {
                onRemoveActions(undefined)
                setMode(undefined)
              }}
            >
              <i className={`ri-arrow-left-line`} />
            </IconButton>
            <Typography>Aktionen definieren</Typography>
          </div>
          <div className={classes.actionsList}>
            {actionContainer.items &&
              actionContainer.items.map((columnSet, index) => {
                return (
                  <div key={`row-${index}`} className={classes.row}>
                    {/* row: index, column: 0 */}
                    <div key={`grid-${index}`} className={classes.grid}>
                      <div key={`container-${index}-1`} className={classes.rowInputContainer}>
                        {/* Check if filled - if not show button to add action */}
                        {columnSet.columns[0] &&
                        columnSet.columns[0].items[0] &&
                        columnSet.columns[0].items[0].actions[0] ? (
                          <>
                            <TextField
                              key={`input-${index}-1`}
                              fullWidth
                              label={`Option ${2 * index + 1}`}
                              value={columnSet.columns[0].items[0].actions[0].title}
                              disabled={
                                typeof columnSet.columns[0].items[0].actions[0].data['.stepBack'] !== 'undefined'
                              }
                              onChange={(event): void => {
                                const tmpActionContainer = cloneDeep(actionContainer)
                                if (tmpActionContainer.items) {
                                  tmpActionContainer.items[index].columns[0].items[0].actions[0].title =
                                    event.target.value
                                  tmpActionContainer.items[index].columns[0].items[0].actions[0].data =
                                    event.target.value
                                }
                                setActionContainer(tmpActionContainer)
                              }}
                            />
                            {!Object.prototype.hasOwnProperty.call(
                              columnSet.columns[0].items[0].actions[0].data,
                              '.stepBack',
                            ) && (
                              <FormControl className={classes.colorInput}>
                                <InputLabel id={`action-color-label-${columnSet.columns[0].items[0].actions[0].title}`}>
                                  Farbe
                                </InputLabel>
                                <Select
                                  labelId={`action-color-label-${columnSet.columns[0].items[0].actions[0].title}`}
                                  id={`action-color-${columnSet.columns[0].items[0].actions[0].title}`}
                                  value={columnSet.columns[0].items[0].actions[0].style ?? 'default'}
                                  label='Farbe'
                                  onChange={(event): void => {
                                    const tmpActionContainer = cloneDeep(actionContainer)
                                    if (tmpActionContainer.items) {
                                      tmpActionContainer.items[index].columns[0].items[0].actions[0].style =
                                        event.target.value
                                    }
                                    setActionContainer(tmpActionContainer)
                                  }}
                                  renderValue={(selected): React.ReactNode => {
                                    return <ColorBox color={selected} className={classes.choosenColorBox} />
                                  }}
                                >
                                  <MenuItem value={'default'}>
                                    <ColorBox color={'default'} />
                                  </MenuItem>
                                  <MenuItem value={'positive'}>
                                    <ColorBox color={'positive'} />
                                  </MenuItem>
                                  <MenuItem value={'destructive'}>
                                    <ColorBox color={'destructive'} />
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            )}
                            <CustomIconButton
                              key={`delete-${index}-1`}
                              type='decline'
                              tooltip={'Aktion entfernen'}
                              onClick={(): void => {
                                onRemoveActions(index, 0)
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <Button
                              key={`add-action-${index}-1`}
                              aria-controls={`add-action-menu-${index}-1`}
                              aria-haspopup='true'
                              onClick={(event): void => handleClickColumns(event, 2 * index)}
                              icon={'add-line'}
                              iconType='remix'
                              className={classes.rowAddButton}
                              variant='secondary'
                            >
                              Aktion hinzufügen
                            </Button>
                            <Menu
                              key={`add-action-menu-${index}-1`}
                              id={`add-action-menu-${index}-1`}
                              anchorEl={menuAnchorElColumns['anchor' + 2 * index]}
                              keepMounted
                              open={Boolean(menuAnchorElColumns['anchor' + 2 * index])}
                              onClose={(): void => handleCloseColumns(2 * index)}
                              elevation={2}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                              }}
                              PaperProps={{
                                style: {
                                  width: menuWidth,
                                },
                              }}
                            >
                              <MenuItem
                                onClick={(): void => {
                                  onAddActionDoubleColumnAction(index, 0, 'option')
                                  handleCloseColumns(2 * index)
                                }}
                              >
                                <ListItemIcon className={classes.menuIcon}>
                                  <i className='ri-chat-check-line'></i>
                                </ListItemIcon>
                                <ListItemText primary='Option hinzufügen' />
                              </MenuItem>
                              <MenuItem
                                onClick={(): void => {
                                  onAddActionDoubleColumnAction(index, 0, 'stepback')
                                  handleCloseColumns(2 * index)
                                }}
                              >
                                <ListItemIcon className={classes.menuIcon}>
                                  <i className='ri-chat-upload-line'></i>
                                </ListItemIcon>
                                <ListItemText primary='Zurück-Button hinzufügen' />
                              </MenuItem>
                            </Menu>
                          </>
                        )}
                      </div>
                      {/* row: index, column: 1 */}
                      <div key={`container-${index}-2`} className={classes.rowInputContainer}>
                        {/* Check if filled - if not show button to add action */}
                        {columnSet.columns[1] &&
                        columnSet.columns[1].items[0] &&
                        columnSet.columns[1].items[0].actions[0] ? (
                          <>
                            <TextField
                              key={`input-${index}-2`}
                              fullWidth
                              label={`Option ${2 * index + 2}`}
                              value={columnSet.columns[1].items[0].actions[0].title}
                              disabled={
                                typeof columnSet.columns[1].items[0].actions[0].data['.stepBack'] !== 'undefined'
                              }
                              onChange={(event): void => {
                                const tmpActionContainer = cloneDeep(actionContainer)
                                if (tmpActionContainer.items) {
                                  tmpActionContainer.items[index].columns[1].items[0].actions[0].title =
                                    event.target.value
                                  tmpActionContainer.items[index].columns[1].items[0].actions[0].data =
                                    event.target.value
                                }
                                setActionContainer(tmpActionContainer)
                              }}
                            />
                            {!Object.prototype.hasOwnProperty.call(
                              columnSet.columns[1].items[0].actions[0].data,
                              '.stepBack',
                            ) && (
                              <FormControl className={classes.colorInput}>
                                <InputLabel id={`action-color-label-${columnSet.columns[1].items[0].actions[0].title}`}>
                                  Farbe
                                </InputLabel>
                                <Select
                                  labelId={`action-color-label-${columnSet.columns[1].items[0].actions[0].title}`}
                                  id={`action-color-${columnSet.columns[1].items[0].actions[0].title}`}
                                  value={columnSet.columns[1].items[0].actions[0].style ?? 'default'}
                                  label='Farbe'
                                  onChange={(event): void => {
                                    const tmpActionContainer = cloneDeep(actionContainer)
                                    if (tmpActionContainer.items) {
                                      tmpActionContainer.items[index].columns[1].items[0].actions[0].style =
                                        event.target.value
                                    }
                                    setActionContainer(tmpActionContainer)
                                  }}
                                  renderValue={(selected): React.ReactNode => {
                                    return <ColorBox color={selected} className={classes.choosenColorBox} />
                                  }}
                                >
                                  <MenuItem value={'default'}>
                                    <ColorBox color={'default'} />
                                  </MenuItem>
                                  <MenuItem value={'positive'}>
                                    <ColorBox color={'positive'} />
                                  </MenuItem>
                                  <MenuItem value={'destructive'}>
                                    <ColorBox color={'destructive'} />
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            )}
                            <CustomIconButton
                              key={`delete-${index}-2`}
                              type='decline'
                              tooltip={'Aktion entfernen'}
                              onClick={(): void => {
                                onRemoveActions(index, 1)
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <Button
                              key={`add-action-${index}-2`}
                              aria-controls={`add-action-menu-${index}-2`}
                              aria-haspopup='true'
                              onClick={(event): void => handleClickColumns(event, 2 * index + 1)}
                              icon={'add-line'}
                              iconType='remix'
                              className={classes.rowAddButton}
                              variant='secondary'
                            >
                              Aktion hinzufügen
                            </Button>
                            <Menu
                              key={`add-action-menu-${index}-2`}
                              id={`add-action-menu-${index}-2`}
                              anchorEl={menuAnchorElColumns['anchor' + (2 * index + 1)]}
                              keepMounted
                              open={Boolean(menuAnchorElColumns['anchor' + (2 * index + 1)])}
                              onClose={(): void => handleCloseColumns(2 * index + 1)}
                              elevation={2}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                              }}
                              PaperProps={{
                                style: {
                                  width: menuWidth,
                                },
                              }}
                            >
                              <MenuItem
                                onClick={(): void => {
                                  onAddActionDoubleColumnAction(index, 1, 'option')
                                  handleCloseColumns(2 * index + 1)
                                }}
                              >
                                <ListItemIcon className={classes.menuIcon}>
                                  <i className='ri-chat-check-line'></i>
                                </ListItemIcon>
                                <ListItemText primary='Option hinzufügen' />
                              </MenuItem>
                              <MenuItem
                                onClick={(): void => {
                                  onAddActionDoubleColumnAction(index, 1, 'stepback')
                                  handleCloseColumns(2 * index + 1)
                                }}
                              >
                                <ListItemIcon className={classes.menuIcon}>
                                  <i className='ri-chat-upload-line'></i>
                                </ListItemIcon>
                                <ListItemText primary='Zurück-Button hinzufügen' />
                              </MenuItem>
                            </Menu>
                          </>
                        )}
                      </div>
                    </div>
                    <CustomIconButton
                      key={`delete-row-${index}`}
                      type='decline'
                      tooltip={index === 0 ? undefined : 'Zeile entfernen'}
                      onClick={(): void => {
                        onRemoveRow(index)
                      }}
                      disabled={index === 0}
                    />
                  </div>
                )
              })}
            {actionContainer.items && actionContainer.items.length > 4 && (
              <Typography style={{ marginTop: theme.spacing(2) }}>
                Sie haben mehr als 4 Zeilen mit Optionen. Bitte bedenken Sie, dass zu viele mögliche Optionen die
                Benutzerfreundlichkeit für Nutzer*innen reduziert.
              </Typography>
            )}
            <Button
              onClick={onAddActionDoubleColumn}
              icon={'add-line'}
              iconType='remix'
              className={classes.addButton}
              variant='secondary'
              // TODO: check if previous row has empty slots -> disable?
            >
              Zeile hinzufügen
            </Button>
          </div>
        </>
      )}
      {mode === 3 && (
        <>
          <div className={classes.editHeader}>
            <IconButton
              onClick={(): void => {
                onRemoveActions(undefined)
                setMode(undefined)
              }}
            >
              <i className={`ri-arrow-left-line`} />
            </IconButton>
            <Typography>Aktionen definieren</Typography>
          </div>
          <div className={classes.actionsList}>
            {actionContainer.items &&
              actionContainer.items.map((columnSet, index) => {
                return (
                  <div key={`row-${index}`} className={classes.row}>
                    <div key={`grid-${index}`} className={classes.grid} style={{ flexWrap: 'wrap' }}>
                      {/* row: index, column: 0 */}
                      <div key={`container-${index}-1`} className={classes.rowInputContainer}>
                        {/* Check if filled - if not show button to add action */}
                        {columnSet.columns[0] &&
                        columnSet.columns[0].items[0] &&
                        columnSet.columns[0].items[0].actions[0] ? (
                          <>
                            <TextField
                              key={`input-${index}-1`}
                              fullWidth
                              label={`Option ${2 * index + 1}`}
                              value={columnSet.columns[0].items[0].actions[0].title}
                              disabled={
                                typeof columnSet.columns[0].items[0].actions[0].data['.stepBack'] !== 'undefined'
                              }
                              onChange={(event): void => {
                                const tmpActionContainer = cloneDeep(actionContainer)
                                if (tmpActionContainer.items) {
                                  tmpActionContainer.items[index].columns[0].items[0].actions[0].title =
                                    event.target.value
                                  tmpActionContainer.items[index].columns[0].items[0].actions[0].data =
                                    event.target.value
                                }
                                setActionContainer(tmpActionContainer)
                              }}
                            />
                            {!Object.prototype.hasOwnProperty.call(
                              columnSet.columns[0].items[0].actions[0].data,
                              '.stepBack',
                            ) && (
                              <FormControl className={classes.colorInput}>
                                <InputLabel id={`action-color-label-${columnSet.columns[0].items[0].actions[0].title}`}>
                                  Farbe
                                </InputLabel>
                                <Select
                                  labelId={`action-color-label-${columnSet.columns[0].items[0].actions[0].title}`}
                                  id={`action-color-${columnSet.columns[0].items[0].actions[0].title}`}
                                  value={columnSet.columns[0].items[0].actions[0].style ?? 'default'}
                                  label='Farbe'
                                  onChange={(event): void => {
                                    const tmpActionContainer = cloneDeep(actionContainer)
                                    if (tmpActionContainer.items) {
                                      tmpActionContainer.items[index].columns[0].items[0].actions[0].style =
                                        event.target.value
                                    }
                                    setActionContainer(tmpActionContainer)
                                  }}
                                  renderValue={(selected): React.ReactNode => {
                                    return <ColorBox color={selected} className={classes.choosenColorBox} />
                                  }}
                                >
                                  <MenuItem value={'default'}>
                                    <ColorBox color={'default'} />
                                  </MenuItem>
                                  <MenuItem value={'positive'}>
                                    <ColorBox color={'positive'} />
                                  </MenuItem>
                                  <MenuItem value={'destructive'}>
                                    <ColorBox color={'destructive'} />
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            )}
                            <CustomIconButton
                              key={`delete-${index}-1`}
                              type='decline'
                              tooltip={'Aktion entfernen'}
                              onClick={(): void => {
                                onRemoveActions(index, 0)
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <Button
                              key={`add-action-${index}-1`}
                              aria-controls={`add-action-menu-${index}-1`}
                              aria-haspopup='true'
                              onClick={(event): void => handleClickColumns(event, 2 * index)}
                              icon={'add-line'}
                              iconType='remix'
                              className={classes.rowAddButton}
                              variant='secondary'
                            >
                              Aktion hinzufügen
                            </Button>
                            <Menu
                              key={`add-action-menu-${index}-1`}
                              id={`add-action-menu-${index}-1`}
                              anchorEl={menuAnchorElColumns['anchor' + 2 * index]}
                              keepMounted
                              open={Boolean(menuAnchorElColumns['anchor' + 2 * index])}
                              onClose={(): void => handleCloseColumns(2 * index)}
                              elevation={2}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                              }}
                              PaperProps={{
                                style: {
                                  width: menuWidth,
                                },
                              }}
                            >
                              <MenuItem
                                onClick={(): void => {
                                  onAddActionTripleColumnAction(index, 0, 'option')
                                  handleCloseColumns(2 * index)
                                }}
                              >
                                <ListItemIcon className={classes.menuIcon}>
                                  <i className='ri-chat-check-line'></i>
                                </ListItemIcon>
                                <ListItemText primary='Option hinzufügen' />
                              </MenuItem>
                              <MenuItem
                                onClick={(): void => {
                                  onAddActionTripleColumnAction(index, 0, 'stepback')
                                  handleCloseColumns(2 * index)
                                }}
                              >
                                <ListItemIcon className={classes.menuIcon}>
                                  <i className='ri-chat-upload-line'></i>
                                </ListItemIcon>
                                <ListItemText primary='Zurück-Button hinzufügen' />
                              </MenuItem>
                            </Menu>
                          </>
                        )}
                      </div>
                      {/* row: index, column: 1 */}
                      <div key={`container-${index}-2`} className={classes.rowInputContainer}>
                        {/* Check if filled - if not show button to add action */}
                        {columnSet.columns[1] &&
                        columnSet.columns[1].items[0] &&
                        columnSet.columns[1].items[0].actions[0] ? (
                          <>
                            <TextField
                              key={`input-${index}-2`}
                              fullWidth
                              label={`Option ${2 * index + 2}`}
                              value={columnSet.columns[1].items[0].actions[0].title}
                              disabled={
                                typeof columnSet.columns[1].items[0].actions[0].data['.stepBack'] !== 'undefined'
                              }
                              onChange={(event): void => {
                                const tmpActionContainer = cloneDeep(actionContainer)
                                if (tmpActionContainer.items) {
                                  tmpActionContainer.items[index].columns[1].items[0].actions[0].title =
                                    event.target.value
                                  tmpActionContainer.items[index].columns[1].items[0].actions[0].data =
                                    event.target.value
                                }
                                setActionContainer(tmpActionContainer)
                              }}
                            />
                            {!Object.prototype.hasOwnProperty.call(
                              columnSet.columns[1].items[0].actions[0].data,
                              '.stepBack',
                            ) && (
                              <FormControl className={classes.colorInput}>
                                <InputLabel id={`action-color-label-${columnSet.columns[1].items[0].actions[0].title}`}>
                                  Farbe
                                </InputLabel>
                                <Select
                                  labelId={`action-color-label-${columnSet.columns[1].items[0].actions[0].title}`}
                                  id={`action-color-${columnSet.columns[1].items[0].actions[0].title}`}
                                  value={columnSet.columns[1].items[0].actions[0].style ?? 'default'}
                                  label='Farbe'
                                  onChange={(event): void => {
                                    const tmpActionContainer = cloneDeep(actionContainer)
                                    if (tmpActionContainer.items) {
                                      tmpActionContainer.items[index].columns[1].items[0].actions[0].style =
                                        event.target.value
                                    }
                                    setActionContainer(tmpActionContainer)
                                  }}
                                  renderValue={(selected): React.ReactNode => {
                                    return <ColorBox color={selected} className={classes.choosenColorBox} />
                                  }}
                                >
                                  <MenuItem value={'default'}>
                                    <ColorBox color={'default'} />
                                  </MenuItem>
                                  <MenuItem value={'positive'}>
                                    <ColorBox color={'positive'} />
                                  </MenuItem>
                                  <MenuItem value={'destructive'}>
                                    <ColorBox color={'destructive'} />
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            )}
                            <CustomIconButton
                              key={`delete-${index}-2`}
                              type='decline'
                              tooltip={'Aktion entfernen'}
                              onClick={(): void => {
                                onRemoveActions(index, 1)
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <Button
                              key={`add-action-${index}-2`}
                              aria-controls={`add-action-menu-${index}-2`}
                              aria-haspopup='true'
                              onClick={(event): void => handleClickColumns(event, 2 * index + 1)}
                              icon={'add-line'}
                              iconType='remix'
                              className={classes.rowAddButton}
                              variant='secondary'
                            >
                              Aktion hinzufügen
                            </Button>
                            <Menu
                              key={`add-action-menu-${index}-2`}
                              id={`add-action-menu-${index}-2`}
                              anchorEl={menuAnchorElColumns['anchor' + (2 * index + 1)]}
                              keepMounted
                              open={Boolean(menuAnchorElColumns['anchor' + (2 * index + 1)])}
                              onClose={(): void => handleCloseColumns(2 * index + 1)}
                              elevation={2}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                              }}
                              PaperProps={{
                                style: {
                                  width: menuWidth,
                                },
                              }}
                            >
                              <MenuItem
                                onClick={(): void => {
                                  onAddActionTripleColumnAction(index, 1, 'option')
                                  handleCloseColumns(2 * index + 1)
                                }}
                              >
                                <ListItemIcon className={classes.menuIcon}>
                                  <i className='ri-chat-check-line'></i>
                                </ListItemIcon>
                                <ListItemText primary='Option hinzufügen' />
                              </MenuItem>
                              <MenuItem
                                onClick={(): void => {
                                  onAddActionTripleColumnAction(index, 1, 'stepback')
                                  handleCloseColumns(2 * index + 1)
                                }}
                              >
                                <ListItemIcon className={classes.menuIcon}>
                                  <i className='ri-chat-upload-line'></i>
                                </ListItemIcon>
                                <ListItemText primary='Zurück-Button hinzufügen' />
                              </MenuItem>
                            </Menu>
                          </>
                        )}
                      </div>
                      {/* row: index, column: 2 */}
                      <div key={`container-${index}-3`} className={classes.rowInputContainer}>
                        {/* Check if filled - if not show button to add action */}
                        {columnSet.columns[2] &&
                        columnSet.columns[2].items[0] &&
                        columnSet.columns[2].items[0].actions[0] ? (
                          <>
                            <TextField
                              key={`input-${index}-3`}
                              fullWidth
                              label={`Option ${3 * index + 3}`}
                              value={columnSet.columns[2].items[0].actions[0].title}
                              disabled={
                                typeof columnSet.columns[2].items[0].actions[0].data['.stepBack'] !== 'undefined'
                              }
                              onChange={(event): void => {
                                const tmpActionContainer = cloneDeep(actionContainer)
                                if (tmpActionContainer.items) {
                                  tmpActionContainer.items[index].columns[2].items[0].actions[0].title =
                                    event.target.value
                                  tmpActionContainer.items[index].columns[2].items[0].actions[0].data =
                                    event.target.value
                                }
                                setActionContainer(tmpActionContainer)
                              }}
                            />
                            {!Object.prototype.hasOwnProperty.call(
                              columnSet.columns[2].items[0].actions[0].data,
                              '.stepBack',
                            ) && (
                              <FormControl className={classes.colorInput}>
                                <InputLabel id={`action-color-label-${columnSet.columns[2].items[0].actions[0].title}`}>
                                  Farbe
                                </InputLabel>
                                <Select
                                  labelId={`action-color-label-${columnSet.columns[2].items[0].actions[0].title}`}
                                  id={`action-color-${columnSet.columns[2].items[0].actions[0].title}`}
                                  value={columnSet.columns[2].items[0].actions[0].style ?? 'default'}
                                  label='Farbe'
                                  onChange={(event): void => {
                                    const tmpActionContainer = cloneDeep(actionContainer)
                                    if (tmpActionContainer.items) {
                                      tmpActionContainer.items[index].columns[2].items[0].actions[0].style =
                                        event.target.value
                                    }
                                    setActionContainer(tmpActionContainer)
                                  }}
                                  renderValue={(selected): React.ReactNode => {
                                    return <ColorBox color={selected} className={classes.choosenColorBox} />
                                  }}
                                >
                                  <MenuItem value={'default'}>
                                    <ColorBox color={'default'} />
                                  </MenuItem>
                                  <MenuItem value={'positive'}>
                                    <ColorBox color={'positive'} />
                                  </MenuItem>
                                  <MenuItem value={'destructive'}>
                                    <ColorBox color={'destructive'} />
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            )}
                            <CustomIconButton
                              key={`delete-${index}-3`}
                              type='decline'
                              tooltip={'Aktion entfernen'}
                              onClick={(): void => {
                                onRemoveActions(index, 2)
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <Button
                              key={`add-action-${index}-3`}
                              aria-controls={`add-action-menu-${index}-3`}
                              aria-haspopup='true'
                              onClick={(event): void => handleClickColumns(event, 2 * index + 2)}
                              icon={'add-line'}
                              iconType='remix'
                              className={classes.rowAddButton}
                              variant='secondary'
                            >
                              Aktion hinzufügen
                            </Button>
                            <Menu
                              key={`add-action-menu-${index}-3`}
                              id={`add-action-menu-${index}-3`}
                              anchorEl={menuAnchorElColumns['anchor' + (2 * index + 2)]}
                              keepMounted
                              open={Boolean(menuAnchorElColumns['anchor' + (2 * index + 2)])}
                              onClose={(): void => handleCloseColumns(2 * index + 2)}
                              elevation={2}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                              }}
                              PaperProps={{
                                style: {
                                  width: menuWidth,
                                },
                              }}
                            >
                              <MenuItem
                                onClick={(): void => {
                                  onAddActionTripleColumnAction(index, 2, 'option')
                                  handleCloseColumns(2 * index + 2)
                                }}
                              >
                                <ListItemIcon className={classes.menuIcon}>
                                  <i className='ri-chat-check-line'></i>
                                </ListItemIcon>
                                <ListItemText primary='Option hinzufügen' />
                              </MenuItem>
                              <MenuItem
                                onClick={(): void => {
                                  onAddActionTripleColumnAction(index, 2, 'stepback')
                                  handleCloseColumns(2 * index + 2)
                                }}
                              >
                                <ListItemIcon className={classes.menuIcon}>
                                  <i className='ri-chat-upload-line'></i>
                                </ListItemIcon>
                                <ListItemText primary='Zurück-Button hinzufügen' />
                              </MenuItem>
                            </Menu>
                          </>
                        )}
                      </div>
                    </div>
                    <CustomIconButton
                      key={`delete-row-${index}`}
                      type='decline'
                      tooltip={index === 0 ? undefined : 'Zeile entfernen'}
                      onClick={(): void => {
                        onRemoveRow(index)
                      }}
                      disabled={index === 0}
                    />
                  </div>
                )
              })}
            {actionContainer.items && actionContainer.items.length > 2 && (
              <Typography style={{ marginTop: theme.spacing(2) }}>
                Sie haben mehr als 2 Zeilen mit Optionen. Bitte bedenken Sie, dass zu viele mögliche Optionen die
                Benutzerfreundlichkeit für Nutzer*innen reduziert.
              </Typography>
            )}
            <Button
              onClick={onAddActionTripleColumn}
              icon={'add-line'}
              iconType='remix'
              className={classes.addButton}
              variant='secondary'
              // TODO: check if previous row has empty slots -> disable?
            >
              Zeile hinzufügen
            </Button>
          </div>
        </>
      )}
    </>
  )
}, isEqual)
