import React, { memo } from 'react'
import { makeStyles } from 'tss-react/mui'
import { isEqual } from 'lodash'
import { Typography } from '@mui/material'
import DOMPurify from 'dompurify'

const useStyles = makeStyles()((theme) => ({
  previewContainer: {
    // border: `1px solid ${theme.palette.grey[500]}`,
    // borderRadius:
    //   typeof theme.shape.borderRadius === 'number' ? theme.shape.borderRadius * 2 : theme.shape.borderRadius,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
  },
  popupButton: {
    backgroundColor: theme.palette.primary.main,
    height: '72px',
    borderRadius: '100px',
    width: '100%',
    color: theme.palette.common.white,
    paddingLeft: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: `${theme.spacing(4)} 0px`,
  },
  notificationContainer: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius:
      typeof theme.shape.borderRadius === 'number' ? theme.shape.borderRadius * 2 : theme.shape.borderRadius,
    // height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    padding: '24px',
  },
  previewButton: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '100px',
    width: 'fit-content',
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: `${theme.spacing(2)} 0px`,
  },
  content: {
    marginTop: theme.spacing(2),
    fontSize: '0.8rem',
  },
}))

type NotificationPreviewProps = {
  title: string
  subtitle: string
  content: string
}

export default memo(function NotificationPreview({
  title,
  subtitle,
  content,
}: NotificationPreviewProps): React.ReactElement {
  const { classes } = useStyles()
  const cleanContent = DOMPurify.sanitize(content, { USE_PROFILES: { html: true } })

  return (
    <div className={classes.previewContainer}>
      <div className={classes.popupButton}>
        <Typography>
          <strong>{title}</strong>
        </Typography>
        <Typography>{subtitle}</Typography>
      </div>
      <div className={classes.notificationContainer}>
        <Typography variant='h3'>{title}</Typography>
        <Typography className={classes.content} dangerouslySetInnerHTML={{ __html: cleanContent }}></Typography>
        <div className={classes.previewButton}>
          <Typography>Weiter zum Chat</Typography>
        </div>
      </div>
    </div>
  )
}, isEqual)
