/* eslint-disable react/no-unescaped-entities */
import { Typography, useTheme } from '@mui/material'
import React from 'react'
import { isRouteErrorResponse, useNavigate, useRouteError } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'
import logo from 'assets/img/logos/convaise-logo-v8.svg'
import { Button } from 'components/Buttons'

const useStyles = makeStyles()((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
  },
  content: {
    margin: 'auto',
    textAlign: 'center',
  },
}))

export function RootBoundary() {
  const { classes } = useStyles()
  const theme = useTheme()
  const navigate = useNavigate()
  const error = useRouteError()

  let errorMsg = 'Entschuldigung! Es ist ein Fehler aufgetreten'
  if (isRouteErrorResponse(error)) {
    switch (error.status) {
      case 404:
        errorMsg += ': Diese Seite existiert nicht.'
        break
      case 401:
        errorMsg += ': Zugriff verweigert'
        break
      case 500:
        errorMsg += ': API Fehler.'
        break
      default:
        errorMsg += '.'
        break
    }
  } else {
    errorMsg += '.'
  }

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <img src={logo} alt='Convaise Logo' width='200px' />
        <Typography variant='h4' style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}>
          {errorMsg}
        </Typography>
        <Button
          onClick={() => {
            navigate('https://studio.convaise.com')
          }}
          color='primary'
        >
          Zu Convaise Studio
        </Button>
      </div>
    </div>
  )
}
