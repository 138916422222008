import React, { ReactElement, useState, useEffect } from 'react'

import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()({
  toggleButton: {
    width: '50%',
    height: '30px',
    border: '1px solid #3c4858',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
    willChange: 'box-shadow, transform',
    cursor: 'pointer',
    background: 'transparent',
    '&:disabled': {
      backgroundColor: '#3c4858',
      color: 'rgba(255,255,255,0.9)',
    },
    '&:hover': {
      boxShadow:
        '0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)',
    },
  },
  left: {
    borderRadius: '15px 0 0 15px',
    // textAlign: 'right',
    textAlign: 'center',
  },

  right: {
    borderRadius: '0 15px 15px 0',
    // textAlign: 'left',
    textAlign: 'center',
  },

  center: {
    textAlign: 'center',
  },
})

type ToggleButtonProps = {
  onClick: (name: 'simple' | 'advanced') => void
  name: 'simple' | 'advanced'
  disabled: boolean
}

export default function ToggleButton({ onClick, name, disabled }: ToggleButtonProps): ReactElement {
  const { classes } = useStyles()
  const [isDisabled, setDisabled] = useState<boolean>()

  function handleClick(): void {
    onClick(name)
  }

  useEffect(
    function () {
      setDisabled(disabled)
    },
    [disabled],
  )

  return (
    <button
      disabled={typeof isDisabled === 'undefined' ? name === 'simple' : isDisabled}
      className={classes.toggleButton + ' ' + (name === 'simple' ? classes.left : classes.right)}
      onClick={handleClick}
    >
      {name === 'simple' ? 'Einfach' : 'Erweitert'}
      {/* {name.charAt(0).toUpperCase() + name.slice(1)} // NOTE: this is the dynamic case - but do we want it do be dynamic and if so, how? since the `name` value is also used in the flowchart ~ Jakob 07.04.21*/}
    </button>
  )
}
