import { Timeline } from '@mui/icons-material'
import React, { ReactElement } from 'react'
import EditorSidebarItem from '../editorSidebarItem'
import { FLOWDESIGNER_NODE_COLOR_TRIGGER } from 'utils/constants'

export default function analyticsTrigger(): ReactElement {
  return (
    <EditorSidebarItem
      type='trigger/analytics'
      ports={{
        port1: {
          id: 'port1',
          type: 'left',
          position: { x: 0, y: 0 },
          properties: {
            custom: 'incoming',
          },
        },
        port2: {
          id: 'port2',
          type: 'right',
          position: { x: 0, y: 0 },
          properties: {
            custom: 'outgoing',
          },
        },
      }}
      properties={{
        typeText: 'Analytics Trigger',
        analyticsEvent: undefined, // init undefined
        dialog: '',
        text: 'Nicht konfiguriert',
      }}
      Icon={Timeline}
      color={FLOWDESIGNER_NODE_COLOR_TRIGGER}
    />
  )
}
