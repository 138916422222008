import React, { useState, useEffect } from 'react'

// @mui/material components
import { makeStyles } from 'tss-react/mui'

import { TextField, Typography } from '@mui/material'
// Types
import { Chart } from '../../../../../@types/Flowchart/types'

const useStyles = makeStyles()((theme) => ({
  selectedDialogContainer: {},
  title: {},
  description: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}))

type SNoteProps = {
  chart: Chart
  setStateCallback: (chart: Chart) => void
}

export default function SNote({ chart, setStateCallback }: SNoteProps): React.ReactElement {
  const { classes } = useStyles()

  const [note, setNote] = useState<string>()

  /**
   * Handles textfield text change
   * @param event
   */
  function onTextChange(value: string): void {
    const selectedId = chart.selected?.id
    if (typeof selectedId === 'undefined') return

    chart.nodes[selectedId].properties.note = value
    setStateCallback({ ...chart })
  }

  useEffect(
    function () {
      const selectedId = chart.selected?.id
      if (typeof selectedId === 'undefined') return

      const note = chart.nodes[selectedId].properties.note || ''

      setNote(note)
    },
    [chart],
  )

  return (
    <div className={classes.selectedDialogContainer}>
      <Typography className={classes.description}>
        Hier können Sie interne Notizen hinzufügen. Dieser Block lässt sich nicht mit anderen Blöcken verbinden.
      </Typography>
      <TextField
        label='Notiz'
        fullWidth
        multiline
        minRows={5}
        value={note ?? ''}
        onChange={(event): void => onTextChange(event.target.value)}
      />
    </div>
  )
}
