import { Dictionary } from '../@types/Knowledge/Dictionaries/types'

/**
 * Checks if all technical terms of a language have translation.
 * Returns if this is the case and also total number of terms and number of translations.
 * @param terms
 * @param language
 */
export function areAllTechnicalTermsTranslated(
  terms: Dictionary,
  language: string,
): { complete: boolean; totalTerms: number; translatedTerms: number } {
  const result: { complete: boolean; totalTerms: number; translatedTerms: number } = {
    complete: true,
    totalTerms: 0,
    translatedTerms: 0,
  }

  for (const term of Object.values(terms)) {
    result.totalTerms += 1
    if (term.entries[language] && term.entries[language] !== '') result.translatedTerms += 1
  }

  return result
}
