import React, { useState, useReducer } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useTheme } from '@mui/material/styles'
import { Card } from '../../@types/SmartCards/types'
import { Grid, IconButton, Typography } from '@mui/material'
import Fuse from 'fuse.js'
import { Searchfield } from 'components/TextInput/Searchfield'
import MenuSmartCard from 'components/Cards/MenuSmartCard'
import BaseCard from 'components/Cards/BaseCard'
import CustomizedTooltip from 'components/Tooltips/CustomContentTooltip'

// Component Styles
const useStyles = makeStyles()((theme) => ({
  smartCardsContainer: {
    // display: 'flex',
    height: '100%',
    // flexDirection: 'column',
  },
  cardGrid: {
    // marginBottom: theme.spacing(6),
    // flex: '1 0 auto',
    // margin: theme.spacing(6),
  },
  search: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    // height: '100%',
  },
  backButton: {
    margin: `auto ${theme.spacing(1)} auto 0`,
  },
  moreIcon: {
    fontSize: '6rem',
    margin: 'auto',
  },
  deleteCardButton: {},
}))

const fuseOptions = {
  shouldSort: true,
  threshold: 0.4,
  minMatchCharLength: 1,
  keys: ['id'],
}

type SmartCardListProps = {
  cardsInUse: string[]
  cards: Card[]
  choseCard: (card: string) => void
  onBackClick?: () => void
  onDeleteExistingCard: (cardId: string) => void
}

// Amount of displayed cards
const initialCardAmount = 12
const amountReducer = (state, action): any => {
  switch (action) {
    case 'increment':
      return state + 6 // increment in 6 bots steps
    default:
      throw new Error('[Flowdesigner] amountReducer: Unexpected action')
  }
}

export default function SmartCardList({
  cardsInUse,
  cards,
  choseCard,
  onBackClick,
  onDeleteExistingCard,
}: SmartCardListProps): React.ReactElement {
  const { classes } = useStyles()
  const theme = useTheme()
  const [searchString, setSearchString] = useState('')
  const [cardCount, dispatch] = useReducer(amountReducer, initialCardAmount)

  // Search
  const fuse = new Fuse(cards, fuseOptions)
  const filteredCards = searchString
    ? fuse.search(searchString).map((result) => {
        return result.item
      })
    : cards

  return (
    <div className={classes.smartCardsContainer}>
      <div className={classes.search}>
        {!!onBackClick && (
          <IconButton className={classes.backButton} onClick={onBackClick}>
            <i className={`ri-arrow-left-line`} />
          </IconButton>
        )}
        <Searchfield
          key='search-field'
          autoFocus
          value={searchString}
          onChange={(event): void => setSearchString(event.target.value)}
        />
      </div>
      <Grid container direction='row' alignItems='flex-start' spacing={5} className={classes.cardGrid}>
        {filteredCards.slice(0, cardCount).map((card, index) => {
          const isCardInUse = cardsInUse.includes(card.id)
          return (
            <Grid item xs={12} sm={12} md={6} lg={6} key={`card-${index}-${card.id}`}>
              <MenuSmartCard
                card={card}
                onClick={(cardId: string): void => choseCard(cardId)}
                deleteButton={
                  <CustomizedTooltip
                    elements={
                      <IconButton
                        onClick={(e: React.MouseEvent): void => {
                          onDeleteExistingCard(card.id)
                          e.preventDefault()
                          e.stopPropagation()
                        }}
                        disabled={isCardInUse}
                        className={classes.deleteCardButton}
                      >
                        <i className='ri-delete-bin-4-line'></i>
                      </IconButton>
                    }
                    content={
                      <Typography>
                        {isCardInUse ? 'Karte wird verwendet und kann nicht gelöscht werden.' : 'Karte löschen'}
                      </Typography>
                    }
                  />
                }
              />
            </Grid>
          )
        })}
        {cardCount < filteredCards.length && (
          <Grid item xs={12} sm={12} md={6} lg={6} key={`load-more-cards`}>
            <BaseCard
              footerText='Weitere Cards laden'
              onClick={(): void => dispatch('increment')}
              footerColor={theme.palette.primary.main}
              height='380px'
            >
              <i className={`ri-more-line ${classes.moreIcon}`} />
            </BaseCard>
          </Grid>
        )}
      </Grid>
    </div>
  )
}
