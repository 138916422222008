import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'

import { makeStyles } from 'tss-react/mui'
import { Typography } from '@mui/material'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
// Custom Components
import ContentPage, { ContentPageHeader } from 'components/Page/ContentPage'
import ErrorComponent from 'components/Error/Error'
import CircularLoading from 'components/Loading/CircularLoading'
import Table from 'components/Table/Table'
import CustomizedTooltip from 'components/Tooltips/CustomContentTooltip'
import BaseCard from 'components/Cards/BaseCard'
import { Button } from 'components/Buttons'
import CustomIconButton from 'components/Buttons/CustomIconButton'
// Assets
import Happy from 'assets/img/knowledge/smileys/happy'
import Sad from 'assets/img/knowledge/smileys/sad'
import Neutral from 'assets/img/knowledge/smileys/neutral'
import Warning from 'assets/img/knowledge/icons/warning'

import CreateAnswerDialog from '../../ConversationManagement/AnswerCreation/CreateAnswerDialog'
import AssignUtteranceDialog from './AssignUtteranceDialog/AssignUtteranceDialog'

import { Answer, AnswerWithoutIntent, TriggerAnswerWithoutIntent } from 'classes/Knowledge'
import { EndpointUtterance, EndpointUtteranceAnswered } from '../../../../../@types/Knowledge/types'

// constants
import { ROUTE_BOTS, ROUTE_BOTID_KNOWLEDGE, ROUTE_BOTID_KNOWLEDGE_NLU_MODELMANAGEMENT } from 'utils/constants'

import svgNoData from 'assets/img/general_noData.svg'
import { useLockingContext } from 'hooks/contexts/locking-context'
import { useAnswers } from 'hooks/contexts/answers-context'
import { useModellmanagementContext } from 'hooks/contexts/modellmanagement-context'

const useStyles = makeStyles()((theme) => ({
  iconButton: {
    marginLeft: theme.spacing(1),
    marginBottom: 'auto',
    marginTop: 'auto',
    padding: theme.spacing(1),
    color: theme.palette.common.white,
  },
  iconButtonDelete: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
    '&:disabled': {
      backgroundColor: theme.palette.grey[400],
      color: theme.palette.common.white,
    },
  },
  iconButtonAccept: {
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.light,
    },
    '&:disabled': {
      backgroundColor: theme.palette.grey[400],
      color: theme.palette.common.white,
    },
  },
  iconButtonEdit: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    '&:disabled': {
      backgroundColor: theme.palette.grey[400],
      color: theme.palette.common.white,
    },
  },
  icon: { fontSize: '1.2rem' },
  noDataContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  noDataImage: {
    width: '300px',
  },
  noDataText: {
    maxWidth: '350px',
    paddingTop: theme.spacing(2),
    textAlign: 'center',
  },
  markdown: {
    fontSize: '1rem',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical',
    '& p': {
      margin: 0,
    },
  },
  placeholder: {
    height: '32px',
    width: '32px',
  },
  loadMoreButtonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(2),
  },
  loadMoreIndicator: {
    position: 'absolute',
    right: theme.spacing(6),
  },
}))

// TODO: add update function, so people do not need to refresh the page
export default function Answered(): React.ReactElement {
  const { classes } = useStyles()
  const { lockState } = useLockingContext()
  const {
    canIEditAnswer,
    modelStatus,
    isModelTrainingButtonDisabled,
    trainModel,
    flaggedAnswers: answers,
    answersArrayPrimaryLang,
    createAnswer,
    primaryLang,
  } = useAnswers()
  const { resetCompleteMode, loadNextUtterances, endpointUtterances, loading, addUtteranceToAnswer, deleteUtterance } =
    useModellmanagementContext()

  const { botId, moduleConfigId } = useParams() as { botId: string; moduleConfigId: string }

  // interaction

  const [selectedUtterance, setSelectedUtterance] = useState<EndpointUtteranceAnswered>() // holds utterance for which dialog is displayed
  const [displayEditSuggestionDialog, setDisplayEditSuggestionDialog] = useState<boolean>()
  const [displayCreateAnswerDialog, setDisplayCreateAnswerDialog] = useState<boolean>()
  // data
  const [endpointQuestions, setEndpointQuestions] = useState<EndpointUtteranceAnswered[]>()
  const [sort, setSort] = useState<'oldest' | 'newest'>('newest')
  const prevSortRef = useRef<'oldest' | 'newest'>('newest')
  const [totalAnswered, setTotalAnswered] = useState<number>()
  const [initialized, setInitialilzed] = useState<boolean>(false)

  // ============= API ==============

  /**
   * Removes utterance without assigning it to intent.
   * @param utterance
   */
  async function onDeleteUtterance(utterance: EndpointUtterance): Promise<void> {
    if (lockState !== 'canEdit') return
    if (selectedUtterance || displayEditSuggestionDialog) {
      setSelectedUtterance(undefined)
      setDisplayEditSuggestionDialog(false)
    }

    await deleteUtterance(utterance, 'answered')
  }

  /**
   * Adds utterance to intent.
   * Removes utterance from displayed list and re-adds it if the API call fails.
   * @param utterance
   * @param intentName
   */
  async function onAddUtterance(utterance: EndpointUtteranceAnswered, intentName: string): Promise<void> {
    if (lockState !== 'canEdit') return
    if (displayEditSuggestionDialog) setDisplayEditSuggestionDialog(false)
    await addUtteranceToAnswer(utterance, intentName, 'answered')
  }

  /**
   * Creates answer.
   * @param answer
   */
  async function onCreateAnswer(answer: AnswerWithoutIntent | TriggerAnswerWithoutIntent): Promise<void> {
    if (lockState !== 'canEdit') return
    // close dialog
    setDisplayCreateAnswerDialog(false)
    await createAnswer(answer)
  }

  /**
   * Selects utterance for editing in the edit dialog. Sets utterance in state.
   * @param utterance
   */
  function onEditUtterance(utterance: EndpointUtteranceAnswered): void {
    setSelectedUtterance(utterance)
    setDisplayEditSuggestionDialog(true)
  }

  /**
   * Handles dialog confirm click.
   * Finds correct intent based on answerId and adds utterance.
   * @param utterance
   * @param answer
   */
  function onEditDialogConfirm(utterance: EndpointUtterance, answer: Answer): void {
    const { intent: intentName } = answer
    onAddUtterance(utterance as EndpointUtteranceAnswered, intentName)
  }

  /**
   * Handles create answer button click in edit dialog.
   * Hides edit dialog and displays create answer dialog instead.
   */
  function onEditDialogCreateAnswer(): void {
    setDisplayCreateAnswerDialog(true)
    setDisplayEditSuggestionDialog(false)
  }

  function onLoadMoreUtterances(): void {
    loadNextUtterances('answered', sort)
  }

  function onSortChange(event, newValue): void {
    if (newValue !== null && newValue !== sort) {
      setSort(newValue)
    }
  }

  // ============ USE EFFECTS =================

  useEffect(() => {
    // sort switch
    // reset new sort state (if any) and trigger load
    if (sort !== prevSortRef.current) {
      // sort has changed
      resetCompleteMode('answered')
      loadNextUtterances('answered', sort)
      prevSortRef.current = sort
    }
  }, [sort, prevSortRef, loadNextUtterances])

  useEffect(() => {
    if (endpointUtterances.answered) setEndpointQuestions(endpointUtterances.answered)
    if (endpointUtterances.totalAnswered !== null) setTotalAnswered(endpointUtterances.totalAnswered)
  }, [endpointUtterances])

  useEffect(() => {
    if (endpointQuestions && !initialized) setInitialilzed(true)
  }, [endpointQuestions, initialized])

  // ================ RENDER + COMPONENTS =================

  const TrainButton = (
    <CustomizedTooltip
      placement='top'
      disableInteractive
      content={
        modelStatus?.trainingStatus === 'Trained' && modelStatus?.publishingStatus === 'Published' ? (
          <Typography variant='body1'>Keine Änderungen zum Trainieren</Typography>
        ) : modelStatus?.trainingStatus !== 'InProgress' ? null : (
          <Typography variant='body1'>Modell wird trainiert</Typography>
        )
      }
      elements={
        <div>
          <Button
            size='normal'
            type='normal'
            icon='refresh-line'
            iconType='remix'
            onClick={trainModel}
            loading={modelStatus?.trainingStatus === 'InProgress' || modelStatus?.publishingStatus === 'InProgress'}
            disabled={isModelTrainingButtonDisabled}
          >
            Trainieren
          </Button>
        </div>
      }
    />
  )

  const Filter = (
    <ToggleButtonGroup value={sort} exclusive onChange={onSortChange} aria-label='Sortierung' size='large'>
      <ToggleButton value='oldest' aria-label='left aligned' disabled={loading === 'loading'}>
        Älteste zuerst
      </ToggleButton>
      <ToggleButton value='newest' aria-label='centered' disabled={loading === 'loading'}>
        Neueste zuerst
      </ToggleButton>
    </ToggleButtonGroup>
  )

  return (
    <>
      <ContentPage>
        <ContentPageHeader
          title={`Beantwortete Fragen ${typeof totalAnswered !== 'undefined' ? '(' + totalAnswered + ')' : ''}`}
          actions={[Filter, TrainButton]}
          previousUrl={
            ROUTE_BOTS +
            '/' +
            botId +
            ROUTE_BOTID_KNOWLEDGE +
            `/${moduleConfigId}` +
            ROUTE_BOTID_KNOWLEDGE_NLU_MODELMANAGEMENT
          }
        />
        <BaseCard width='100%' height='auto'>
          <ErrorComponent errorCode='Knowledge.specific.modelmanagment'>
            {(loading === 'loading' && !initialized) || !answers?.answers || !endpointQuestions ? (
              // initial load
              <CircularLoading text='Nutzerfragen werden geladen...' size='medium' />
            ) : (
              <>
                {endpointQuestions.length > 0 && primaryLang ? (
                  <Table
                    headers={['Nutzerfrage', 'Gegebene Antwort', '', 'Score', 'Aktionen']}
                    cellAlignPattern={['left', 'left', 'right', 'right', 'right']}
                    width={['auto', '50%', '32px', '100px', '190px']}
                    padding='medium'
                    disablePagination
                    rows={(endpointQuestions ?? []).map((utterance, idx) => {
                      let suggestion: any = <em>Kein Vorschlag</em>
                      let answerTitle: any = <em>-</em>
                      if (
                        utterance.intentPredictions.length > 0 &&
                        utterance.intentPredictions[0].answerId &&
                        answers.answers[primaryLang][utterance.intentPredictions[0].answerId]
                      ) {
                        suggestion = answers.answers[primaryLang][utterance.intentPredictions[0].answerId].answer
                        answerTitle = answers.answers[primaryLang][utterance.intentPredictions[0].answerId].title

                        if (
                          !suggestion &&
                          answers.answers[primaryLang][utterance.intentPredictions[0].answerId].intent === 'None'
                        )
                          suggestion = <em>Keiner Antwort zuordnen</em>
                      }

                      return [
                        <Typography key={`utt-text-${idx}`}>{utterance.text}</Typography>,
                        <div key={`utt-given-answer-${idx}`} style={{ display: 'flex', alignItems: 'center' }}>
                          {utterance.intentPredictions.length > 0 &&
                            utterance.intentPredictions[0].answerId &&
                            utterance.givenAnswerId !== utterance.intentPredictions[0].answerId && (
                              <Warning
                                tooltip={
                                  <Typography>
                                    Die gegebene Antwort unterscheidet sich von der Antwort, die aktuell vom Assistenten
                                    gegeben werden würde.
                                  </Typography>
                                }
                              />
                            )}
                          <div>
                            <Typography variant='caption'>
                              <b>{answerTitle}</b>
                            </Typography>
                            <div className={classes.markdown}>
                              <ReactMarkdown>{utterance.givenAnswerText}</ReactMarkdown>
                            </div>
                          </div>
                        </div>,
                        <>
                          {typeof utterance.helpfulRatio === 'number' && utterance.helpfulRatio >= 0 ? (
                            utterance.helpfulRatio < 0.2 ? (
                              <Sad key={`utt-helpful-${idx}`} />
                            ) : utterance.helpfulRatio < 0.6 ? (
                              <Neutral key={`utt-helpful-${idx}`} />
                            ) : (
                              <Happy key={`utt-helpful-${idx}`} />
                            )
                          ) : (
                            <></>
                          )}
                        </>, // TODO: check if answerText is still the same
                        <Typography key={`utt-score-${idx}`}>
                          {utterance.intentPredictions.length > 0 &&
                          utterance.intentPredictions[0].answerId &&
                          utterance.givenAnswerId === utterance.intentPredictions[0].answerId &&
                          utterance.intentPredictions.length > 0
                            ? `${Math.round(utterance.intentPredictions[0].score * 1000) / 10} %`
                            : '-'}
                        </Typography>,
                        <div key={`utt-actions-${idx}`} style={{ display: 'flex' }}>
                          <div style={{ marginLeft: 'auto' }}>
                            <CustomIconButton
                              type='decline'
                              tooltip='Nutzerfrage löschen'
                              onClick={(): Promise<void> => onDeleteUtterance(utterance)}
                              disabled={
                                lockState === 'isBlocked' || !canIEditAnswer(utterance.intentPredictions[0]?.answerId)
                              }
                            />
                          </div>
                          <div style={{ marginLeft: '16px' }}>
                            <CustomIconButton
                              type='accept'
                              tooltip='Vorschlag übernehmen'
                              onClick={(): Promise<void> =>
                                onAddUtterance(utterance, utterance.intentPredictions[0]?.intent)
                              }
                              disabled={
                                lockState === 'isBlocked' || utterance.intentPredictions.length > 0
                                  ? !canIEditAnswer(utterance.intentPredictions[0]?.answerId)
                                  : false
                              }
                            />
                          </div>
                          <div style={{ marginLeft: '16px' }}>
                            <CustomIconButton
                              tooltip='Anpassen'
                              type='default'
                              onClick={(): void => onEditUtterance(utterance)}
                              disabled={lockState === 'isBlocked'}
                              icon='ri-pencil-line'
                            />
                          </div>
                        </div>,
                      ]
                    })}
                  />
                ) : (
                  <div className={classes.noDataContainer}>
                    <img className={classes.noDataImage} src={svgNoData} alt='Grafik: Clip Board mit leeren Seiten' />
                    <Typography variant='body1' className={classes.noDataText}>
                      Aktuell liegen keine Fragen aus dem Live Betrieb vor, die zum Trainieren des Modelles verwendet
                      werden können.
                    </Typography>
                  </div>
                )}

                <div className={classes.loadMoreButtonContainer}>
                  {totalAnswered && endpointQuestions && endpointQuestions.length < totalAnswered && (
                    <Button
                      loading={loading === 'loading'}
                      icon='arrow-down-s-line'
                      iconType='remix'
                      onClick={onLoadMoreUtterances}
                    >
                      Weitere Fragen laden
                    </Button>
                  )}
                  {endpointQuestions.length > 0 && (
                    <Typography className={classes.loadMoreIndicator} variant='subtitle2'>
                      {`${endpointQuestions.length} / ${totalAnswered}`}
                    </Typography>
                  )}
                </div>
              </>
            )}
          </ErrorComponent>
        </BaseCard>
        {displayEditSuggestionDialog && selectedUtterance && answers?.answers && primaryLang && (
          <AssignUtteranceDialog
            utterance={selectedUtterance}
            answers={answers.answers[primaryLang] as { [answerId: string]: Answer }}
            onCreateAnswer={onEditDialogCreateAnswer}
            onClose={(): void => {
              setDisplayEditSuggestionDialog(false)
              setSelectedUtterance(undefined)
            }}
            onRemove={onDeleteUtterance}
            onConfirm={onEditDialogConfirm}
            endpointType='answered'
          />
        )}
        {displayCreateAnswerDialog && selectedUtterance && (
          <CreateAnswerDialog
            answers={answersArrayPrimaryLang}
            existingUtterance={selectedUtterance.text}
            onClose={(): void => {
              setDisplayCreateAnswerDialog(false)
              setSelectedUtterance(undefined)
            }}
            onCreateAnswer={onCreateAnswer}
            botId={botId}
          />
        )}
      </ContentPage>
    </>
  )
}
