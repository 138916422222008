import { isEmpty } from 'lodash'
import { Chart, Datacheck, DisplayDatacheckOption } from '../@types/Flowchart/types'
import { reduceVariableUsageCount } from './chartUtils'

/**
 * Returns list of ids of datachecks that are used by a certain node.
 * Does not return mandatory datacheck.
 *
 * @param chart
 * @param nodeId
 */
export function getDatachecksOfNode(chart: Chart, nodeId: string): string[] {
  return Object.keys(chart.nodes[nodeId].properties.datachecks || {})
}

/**
 * Returns name (label) and id (value) of all complete datachecks for usage in the AutosuggestSelect component.
 * Only returns array of predefined and/or completely configured datachecks.
 * Does not return a datacheck if the check is already used in the selected node.
 *
 * @param chart
 */
export function getDatachecks(chart: Chart): DisplayDatacheckOption[] {
  if (typeof chart.selected.id === 'undefined') return []
  const selectedId = chart.selected.id

  const checks: Datacheck[] = []
  const checksKeys = Object.keys(chart.datachecks || {})
  checksKeys.forEach((key) => {
    if (
      !Object.keys(chart.datachecks[key].nodes).includes(selectedId) &&
      chart.datachecks[key].predefined &&
      chart.datachecks[key].type !== 'mandatory'
    )
      checks.push(chart.datachecks[key])
  })

  const returnValue = checks.map((check) => ({
    value: check.id,
    label: check.name,
  }))

  return returnValue
}

/**
 * Removes datacheck from node.
 * Reduces variable usage count for all variables that datacheck checks.
 *
 * @param chart
 * @param nodeId
 * @param datacheckId
 * @param updateVariableUsage
 */
export function removeDatacheckFromNode(
  chart: Chart,
  nodeId: string,
  datacheckId: string,
  updateVariableUsage = false,
): Chart {
  const node = chart.nodes[nodeId]
  if (typeof node === 'undefined') return chart
  const nodeDatachecks = node.properties.datachecks
  if (typeof nodeDatachecks === 'undefined') return chart

  // reduce consume usage count of all variables that datacheck checks
  if (updateVariableUsage) {
    const varIds = chart.datachecks[datacheckId].nodes[nodeId]
    for (const varId of varIds) {
      // NOTE: this is probably not needed, because we re-count the consume usage when saving a node.
      chart = reduceVariableUsageCount(chart, nodeId, varId, 'consume', 1)
    }
  }

  const datacheck = chart.datachecks[datacheckId]
  // if (typeof nodeDatacheck === 'undefined') return chart
  const usageCountNode = nodeDatachecks[datacheckId].usageCountNode || 0

  // remove datacheck from node
  delete nodeDatachecks[datacheckId]
  node.properties.datachecks = nodeDatachecks

  // remove node from global datacheck and delete datachech if its global usage is 0 and its not predefined.
  datacheck.usageCount -= usageCountNode
  delete datacheck.nodes[nodeId]

  chart.nodes[nodeId] = node

  if (datacheck.usageCount === 0 && !datacheck.predefined) {
    delete chart.datachecks[datacheckId]
  } else {
    chart.datachecks[datacheckId] = datacheck
  }

  return chart
}

/**
 * Removes datachecks from selected node.
 * If datacheck is not used anywhere else and not predefined, it gets deleted.
 * @param chart
 */
export function removeAllDatachecksFromNode(chart: Chart, nodeId?: string, updateVariableUsage = false): Chart {
  if (typeof chart.selected.id === 'undefined') return chart
  nodeId = nodeId || chart.selected.id

  const nodeDatachecks = chart.nodes[nodeId].properties.datachecks || {}
  const checks = Object.keys(nodeDatachecks)

  // remove node from global datacheck object
  for (const checkId of checks) {
    chart = removeDatacheckFromNode(chart, nodeId, checkId, updateVariableUsage)
  }

  return chart
}

/**
 * Remove unused datachecks from selected node and deletes it if its global usage is 0.
 * @param  chart
 */
export function removeUnusedDatachecks(chart: Chart): Chart {
  const selectedId = chart.selected?.id
  if (typeof selectedId === 'undefined') return chart

  const nodeDatachecks = chart.nodes[selectedId].properties.datachecks || {}
  const checks = Object.keys(nodeDatachecks)

  for (const checkId of checks) {
    // remove unused datacheck from node
    if (
      (typeof nodeDatachecks[checkId] !== 'undefined' || isEmpty(nodeDatachecks)) &&
      (typeof chart.datachecks[checkId].nodes[selectedId] === 'undefined' ||
        chart.datachecks[checkId].nodes[selectedId].length === 0)
    ) {
      chart = removeDatacheckFromNode(chart, selectedId, checkId, false)
    }
  }

  return chart
}

// =====================================================================================
// DATACHECK & VARIABLES
// =====================================================================================

// /**
//  * Removes variable from datacheck if variable is no longer used in node.
//  * @param chart
//  * @param nodeId
//  * @param varId
//  */
// export function removeVariableFromDatacheck(chart: Chart, nodeId: string, varId: string): Chart {
//   const node = chart.nodes[nodeId]
//   if (typeof node === 'undefined') return chart

//   const nodeVariables = node.properties.variables
//   if (typeof nodeVariables === 'undefined') return chart

//   const nodeVariable = nodeVariables[varId]

//   // remove only if variable is no longer set by the node
//   if (nodeVariable && nodeVariable.set.usageCount === 0 && node.properties.datachecks) {
//     const nodeDatachecks = node.properties.datachecks

//     // check all datachecks that this node uses
//     Object.keys(nodeDatachecks).forEach((datacheckId) => {
//       // count length of datacheck usage for this node prior to variable removal
//       const countPrior = chart.datachecks[datacheckId].nodes[nodeId].length
//       chart.datachecks[datacheckId].nodes[nodeId] = chart.datachecks[datacheckId].nodes[nodeId].filter(
//         (variableId) => variableId !== varId
//       )
//       // count again after variable removal
//       const countAfter = chart.datachecks[datacheckId].nodes[nodeId].length

//       // difference
//       const diff = countPrior - countAfter
//       nodeDatachecks[datacheckId].usageCountNode -= diff
//       node.properties.datachecks = nodeDatachecks

//       // globally
//       chart.datachecks[datacheckId].usageCount -= diff
//       if (node.properties.datachecks[datacheckId].usageCountNode === 0) {
//         // remove datacheck from node if it is no longer used and remove node from datacheck
//         delete node.properties.datachecks[datacheckId]
//         delete chart.datachecks[datacheckId].nodes[nodeId]
//       }
//     })

//     chart.nodes[nodeId] = node
//   }

//   return chart
// }
