import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'

import ContentPage, { ContentPageHeader } from 'components/Page/ContentPage'
import { APP_TITLE } from 'utils/constants'
import { makeStyles } from 'tss-react/mui'

import {
  Checkbox,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Link,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'

import BaseCard from 'components/Cards/BaseCard'
import { Button } from 'components/Buttons'

import { getMetadataAlephAlpha, setRssFeedDataAlephAlpha } from 'api/StudioBackend'
import { useBotContext } from 'hooks/contexts/bot-context'
import { useErrorContext } from 'hooks/contexts/errorContext'
import ErrorComponent from 'components/Error/Error'
import CircularLoading from 'components/Loading/CircularLoading'
import UpdateDataDialog from './UpdateDataDialog'
import { useStudioNotificationContext } from 'hooks/contexts/studio-notification-context'
import { ArrowDropDown, ArrowDropUp, MoreVert } from '@mui/icons-material'
import { getCompleteDateTimeString } from 'utils/dateUtils'
import CustomizedTooltip from 'components/Tooltips/CustomContentTooltip'
import { useLockingContext } from 'hooks/contexts/locking-context'

const useStyles = makeStyles()((theme) => ({
  root: {
    height: '100%',
  },
  actionContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  actionContainerContent: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  loadingContainer: {
    width: '100%',
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  cardMenuButton: {
    // fontSize: '1rem',
    // color: theme.palette.success.main,
    // cursor: (props: { tooltip?: string | React.ReactElement }): string => (props?.tooltip ? 'pointer' : 'default'),
    // marginRight: theme.spacing(1),
  },
}))

const useMenuStyles = makeStyles()((theme) => ({
  popupMenu: {
    padding: 0,
  },
  popupMenuList: {
    padding: 0,
  },
  popupMenuRedText: {
    color: theme.palette.error.main,
  },
}))

type RssFeedCardMenuProps = {
  onEdit: () => void
  onDelete: () => void
  open: boolean
  onClose: () => void
  anchorElement: Element
}

/**
 * Menu component for menu button of card.
 */
function RssFeedCardMenu({ onEdit, onDelete, open, onClose, anchorElement }: RssFeedCardMenuProps): React.ReactElement {
  const { classes } = useMenuStyles()
  return (
    <Menu
      open={open}
      onClose={onClose}
      anchorEl={anchorElement}
      classes={{ list: classes.popupMenuList }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        style: {
          width: '150px',
        },
      }}
    >
      {/* <Paper sx={{ width: 320, maxWidth: '100%' }}> */}
      <MenuList>
        <MenuItem onClick={onEdit}>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={onDelete} disabled>
          <ListItemText classes={{ primary: classes.popupMenuRedText }} primary='Remove feed' />
        </MenuItem>
      </MenuList>
      {/* </Paper> */}
    </Menu>
  )
}

enum LoadingState {
  LOADING,
  LOADED,
  SAVING,
}

export default function AADatenManagement(): React.ReactElement {
  const { classes } = useStyles()
  const theme = useTheme()
  const { bot } = useBotContext()
  const { setError } = useErrorContext()
  const { setNotification } = useStudioNotificationContext()
  const { lockState, lockedBy, lockInitialized } = useLockingContext()

  let botId = ''
  const params = useParams()
  if (bot) {
    botId = bot.id
  } else {
    botId = params.botId as string
  }
  // advanced config options
  const [showAdvanced, setShowAdvanced] = useState<boolean>(false)
  const [doesDataExist, setDoesDataExist] = useState<boolean>()
  const [loadingState, setLoadingState] = useState<LoadingState>()
  const [feedUrl, setFeedUrl] = useState('')
  const [lastFetchTimestamp, setLastFetchTimestamp] = useState<Date>()
  const [lastFeedUpdate, setLastFeedUpdate] = useState<Date>()
  const [nrFeedItems, setNrFeedItems] = useState<number>()
  const [contextProperty, setContextProperty] = useState<string>()
  const [titleProperty, setTitleProperty] = useState<string>()
  const [addSummary, setAddSummary] = useState<boolean>(false)
  const [searchThreshold, setSearchThreshold] = useState<number>(0.5)
  // dialog
  const [showUpdateDataDialog, setShowUpdateDataDialog] = useState<boolean>(false)
  // menu
  const [anchorElementForMenu, setAnchorElementForMenu] = useState<HTMLElement | null>(null)
  const menuOpen = Boolean(anchorElementForMenu)

  function handleMenuClose(): void {
    setAnchorElementForMenu(null)
  }

  function handleMenuClick(event: React.MouseEvent<HTMLButtonElement>): void {
    event.stopPropagation()
    if (menuOpen) {
      handleMenuClose()
    } else {
      setAnchorElementForMenu(event.currentTarget)
    }
  }

  /**
   * Loads metadata and sets state if successful and if data already exists.
   */
  async function loadData(): Promise<void> {
    setLoadingState(LoadingState.LOADING)
    try {
      const metadata = await getMetadataAlephAlpha(botId)
      if (!metadata || metadata === null) {
        // request failed
        throw new Error("[Knowledge] Could not load metadata for bot '" + botId + "'")
      }

      const content = metadata.metadata

      if (content === null) {
        // no data exists yet
        setDoesDataExist(false)
      } else {
        // we have data
        setDoesDataExist(true)
        setFeedUrl(content.url)
        setLastFetchTimestamp(content.fetch_timestamp)
        setLastFeedUpdate(content.last_update)
        setNrFeedItems(metadata.metadata?.nr_entries_in_feed ?? metadata.metadata?.nr_documents ?? undefined)
        setContextProperty(content.context_property)
        setTitleProperty(content.title_property)
        setAddSummary(content.add_summary)
        setSearchThreshold(content.search_threshold)
      }
      setLoadingState(undefined)
    } catch (err) {
      console.error('[Knowledge] Error loading metadata', err)
      setError('Knowledge.alephalpha.loadingError', 'Error loading current data.', 'Retry', loadData)
    }
  }

  /**
   * Updates data basis via the API.
   * @param feedUrl
   * @param contextProperty
   * @param titleProperty
   * @param createSummary
   */
  async function onSubmit(
    newFeedUrl: string,
    newContextProperty: string,
    newTitleProperty: string,
    addSummary: boolean,
    searchThreshold: number,
  ): Promise<void> {
    setShowUpdateDataDialog(false)

    const prevUrl = feedUrl
    const prevContextProperty = contextProperty
    const prevTitleProperty = titleProperty
    const prevAddSummary = addSummary
    const prevThreshold = searchThreshold

    setFeedUrl(newFeedUrl)
    setContextProperty(newContextProperty)
    setTitleProperty(newTitleProperty)

    setLoadingState(LoadingState.SAVING)

    const metadata = await setRssFeedDataAlephAlpha(
      botId,
      newFeedUrl,
      newContextProperty,
      newTitleProperty,
      addSummary,
      searchThreshold,
    )

    if (!metadata || metadata === null) {
      // failed
      setNotification('error', 'Error updating data. Try again later or contact support.')

      // reset to last state
      setFeedUrl(prevUrl)
      setContextProperty(prevContextProperty)
      setTitleProperty(prevTitleProperty)
      setAddSummary(prevAddSummary)
      setSearchThreshold(prevThreshold)
    } else {
      // success
      setNotification('success', 'Successfully updated data.')
      setLastFetchTimestamp(metadata.metadata?.fetch_timestamp)
      setLastFeedUpdate(metadata.metadata?.last_update)
      setNrFeedItems(metadata.metadata?.nr_entries_in_feed ?? metadata.metadata?.nr_documents ?? undefined)
      setContextProperty(metadata.metadata?.context_property)
      setTitleProperty(metadata.metadata?.title_property)
      setAddSummary(!!metadata.metadata?.add_summary)
      setSearchThreshold(metadata.metadata?.search_threshold ?? 0.5)
      setDoesDataExist(true)
    }
    setLoadingState(undefined)
  }

  function onCloseUpdateDataDialog(): void {
    setShowUpdateDataDialog(false)
  }

  useEffect(() => {
    // load metadata on page load
    loadData()
  }, [])

  const UpdateAllButton = (
    <CustomizedTooltip
      placement='top'
      disableInteractive
      content={<Typography>Update all RSS-Feeds</Typography>}
      elements={
        <div>
          <Button
            size='normal'
            type='normal'
            icon='refresh-line'
            iconType='remix'
            onClick={(): Promise<void> =>
              onSubmit(
                feedUrl,
                contextProperty ?? 'description',
                titleProperty ?? 'title',
                !!addSummary,
                searchThreshold,
              )
            }
            loading={loadingState === LoadingState.SAVING}
            disabled={!doesDataExist || (typeof loadingState !== 'undefined' && loadingState !== LoadingState.LOADED)}
          >
            Update all
          </Button>
        </div>
      }
    ></CustomizedTooltip>
  )

  const AddFeedButton = (
    // <CustomizedTooltip
    //   placement='top'
    //   disableInteractive
    //   content={<Typography></Typography>}
    //   elements={
    //     <div>
    <Button
      size='normal'
      type='normal'
      icon='add-circle-line'
      iconType='remix'
      onClick={(): void => {
        return
      }}
      loading={false}
      disabled={true}
    >
      Add RSS feed
    </Button>
    //     </div>
    //   }
    // ></CustomizedTooltip>
  )

  const UpdateSingleFeedButton = (
    <CustomizedTooltip
      content={<Typography>Update feed</Typography>}
      elements={
        <IconButton
          onClick={(): Promise<void> =>
            onSubmit(feedUrl, contextProperty ?? 'description', titleProperty ?? 'title', !!addSummary, searchThreshold)
          }
          disabled={typeof loadingState !== 'undefined' && loadingState !== LoadingState.LOADED}
          // loading={loadingState === LoadingState.SAVING}
        >
          <i className={'ri-refresh-line ' + classes.cardMenuButton} />
        </IconButton>
      }
    />
  )

  const CardButtons = (
    <div style={{ display: 'flex' }}>
      <div>{UpdateSingleFeedButton}</div>
      <div>
        <IconButton
          onClick={handleMenuClick}
          disabled={
            lockState !== 'canEdit' || (typeof loadingState !== 'undefined' && loadingState !== LoadingState.LOADED)
          }
          aria-controls={menuOpen ? 'basic-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={menuOpen ? 'true' : undefined}
        >
          <MoreVert />
        </IconButton>
      </div>
    </div>
  )

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{APP_TITLE} - Data Management</title>
      </Helmet>
      <ContentPageHeader title='Management (aleph-alpha-lumi)' actions={[AddFeedButton, UpdateAllButton]} />
      <ContentPage>
        <BaseCard
          width='100%'
          minHeight={'100vh'}
          height='auto'
          topRightActionButton={doesDataExist ? CardButtons : null}
        >
          <ErrorComponent errorCode='Knowledge.alephalpha.loadingError'>
            {/* this div ensures that min height is set */}
            {loadingState === LoadingState.LOADING ? (
              <CircularLoading text='Daten werden geladen. Bitte warten ...' size='medium' />
            ) : doesDataExist ? (
              <Grid container spacing={2} justifyContent='flex-start'>
                {/* URL */}
                <Grid item xs={3}>
                  <Typography fontWeight='bolder'>RSS-Feed URL:</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Link href={feedUrl}>{feedUrl}</Link>
                </Grid>
                {/* number entries */}
                <Grid item xs={3}>
                  <Typography fontWeight='bolder'>Number entries:</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography>{nrFeedItems}</Typography>
                </Grid>
                {/* last fetch */}
                <Grid item xs={3}>
                  <Typography fontWeight='bolder'>Last updated:</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography>{lastFetchTimestamp ? getCompleteDateTimeString(lastFetchTimestamp) : null}</Typography>
                </Grid>
                {/* last feed update */}
                {/* IDEA: we could include this by checking the feed everytime we fetch the metadata. This way we can find out if the data needs to be updated and can show a notification to the user */}
                {/* <Grid item xs={3}>
                  <Typography fontWeight='bolder'>Letzte Feedänderung:</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography>{lastFeedUpdate ? getCompleteDateTimeString(lastFeedUpdate) : null}</Typography>
                </Grid> */}
                <Grid item xs={12}>
                  <div style={{ display: 'flex' }}>
                    <Tooltip
                      id='toggle-response-details'
                      title={showAdvanced ? 'Hide advanced config options' : 'Show advanced config options'}
                      placement='top'
                    >
                      <IconButton onClick={(): void => setShowAdvanced(!showAdvanced)}>
                        {showAdvanced ? <ArrowDropUp /> : <ArrowDropDown />}
                      </IconButton>
                    </Tooltip>
                    <Typography style={{ marginTop: 'auto', marginBottom: 'auto' }}>Advanced config</Typography>
                  </div>
                  <Collapse in={showAdvanced}>
                    <div style={{ marginLeft: theme.spacing(2) }}>
                      <Grid container spacing={2} justifyContent='flex-start'>
                        <Grid item xs={3}>
                          <Typography fontWeight='bolder' fontStyle='italic'>
                            Has summary:
                          </Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <Checkbox checked={!!addSummary} disabled />
                        </Grid>
                        <Grid item xs={3}>
                          <Typography fontWeight='bolder' fontStyle='italic'>
                            Search threshold:
                          </Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <Typography fontStyle={'italic'}>{searchThreshold}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography fontWeight='bolder' fontStyle='italic'>
                            Content property:
                          </Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <Typography fontStyle={'italic'}>{contextProperty}</Typography>
                        </Grid>
                        {/* Title property */}
                        <Grid item xs={3}>
                          <Typography fontWeight='bolder' fontStyle={'italic'}>
                            Title property:
                          </Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <Typography fontStyle={'italic'}>{titleProperty}</Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </Collapse>
                </Grid>
              </Grid>
            ) : (
              typeof loadingState === 'undefined' && (
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                  <Typography>No RSS feed added. Add a new feed to get started.</Typography>
                  <div style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: theme.spacing(2) }}>
                    <Button type='normal' onClick={(): void => setShowUpdateDataDialog(true)}>
                      Add RSS feed
                    </Button>
                  </div>
                </div>
              )
            )}
            {loadingState === LoadingState.SAVING && (
              <div className={classes.loadingContainer}>
                {/* <div className={}> */}
                <CircularLoading
                  size='small'
                  text={
                    doesDataExist
                      ? 'Updating. This can take up to 120 seconds!'
                      : 'Adding feed. This can take up to 120 seconds!'
                  }
                  fontStyle='italic'
                  direction='row'
                />
              </div>
            )}
          </ErrorComponent>
        </BaseCard>
      </ContentPage>
      {showUpdateDataDialog && (
        <UpdateDataDialog
          feedUrl={feedUrl}
          contextProperty={contextProperty}
          titleProperty={titleProperty}
          addSummary={addSummary}
          searchThreshold={searchThreshold}
          onSubmit={onSubmit}
          onClose={onCloseUpdateDataDialog}
        />
      )}
      {anchorElementForMenu && doesDataExist && (
        <RssFeedCardMenu
          open={menuOpen}
          onClose={handleMenuClose}
          onDelete={(): void => {
            handleMenuClose()
          }}
          onEdit={(): void => {
            handleMenuClose()
            setShowUpdateDataDialog(true)
          }}
          anchorElement={anchorElementForMenu}
        />
      )}
    </div>
  )
}
