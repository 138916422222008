import React, { useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import * as ExcelJS from 'exceljs'
import * as FileSaver from 'file-saver'
// Components
import CustomizedTooltip from 'components/Tooltips/CustomContentTooltip'
import ErrorComponent from 'components/Error/Error'
import { Button } from './index'
// API
import { getExportKnowledgeDb } from 'api/StudioBackend'
import { useErrorContext } from '../../hooks/contexts/errorContext'
import { useBotContext } from '../../hooks/contexts/bot-context'
import NotificationSnackbar from 'components/NotificationSnackbar/NotificationSnackbar'
import { MODULE_ID_CONVAISE_NLU } from 'utils/constants'
import { NLUModule } from '../../@types/BotInformation/types'

export default function ExportKnowledgeDbButton(): React.ReactElement {
  const [loadingAnswers, setLoadingAnswers] = useState(0) // 0 = not loaded, 1 = loading, 2 = loaded, 3 = generating Excel, 4 = error
  const [answers, setAnswers] = useState<string[][] | undefined>()
  const [showNotification, setShowNotification] = useState<boolean>(false)
  const [notificationMessage, setNotificationMessage] = useState<string | undefined>()
  const [notificationSeverity, setNotificationSeverity] = useState<'success' | 'error' | undefined>()
  const { bot, getLanguages } = useBotContext()

  useEffect(() => {
    if (typeof answers !== 'undefined') {
      createAndDownloadExcel()
    }
  }, [answers])

  /**
   * Loads all answers from api.
   */
  async function loadAnswers(retry?: boolean): Promise<void> {
    setLoadingAnswers(1)
    if (bot && typeof bot.id !== 'undefined') {
      try {
        const getExportKnowledgeDbResult = await getExportKnowledgeDb(bot?.id, 'specific', getLanguages())
        if (
          getExportKnowledgeDbResult !== null &&
          typeof getExportKnowledgeDbResult !== 'undefined' &&
          typeof getExportKnowledgeDbResult.answers !== 'undefined' &&
          Array.isArray(getExportKnowledgeDbResult.answers)
        ) {
          setAnswers(getExportKnowledgeDbResult.answers)
          setLoadingAnswers(2)
        } else {
          throw new Error('getExportKnowledgeDb result is null')
        }
      } catch (err) {
        if (!retry) {
          // try again a second time
          console.info('Retry: Load Answers')
          loadAnswers(true)
        } else {
          // set error
          setNotification('error', 'Wissensdatenbank konnte nicht für den Export geladen werden.')
          setLoadingAnswers(0)
        }
      }
    }
  }

  async function createAndDownloadExcel(): Promise<void> {
    try {
      setLoadingAnswers(3)
      if (answers && Array.isArray(answers)) {
        const workbook = new ExcelJS.Workbook()
        workbook.creator = 'Convaise'
        workbook.created = new Date()
        // create a sheet with the first row and column frozen
        const sheet = workbook.addWorksheet('Export-Wissensdatenbank', {
          views: [{ state: 'frozen', ySplit: 2 }],
        })

        // adding infos
        const cell1 = sheet.getCell('A1')
        cell1.value = `Assistent: ${bot?.name}\nWissensdatenbank: ${(bot?.modules[MODULE_ID_CONVAISE_NLU] as NLUModule)
          ?.knowledgeDbName}`
        cell1.alignment = { wrapText: true }
        cell1.font = { bold: true }
        const cell2 = sheet.getCell('B1')
        cell2.value = `Export-Datum: ${new Date()}`
        cell2.alignment = { wrapText: true }
        cell2.font = { bold: true }

        // adding table
        sheet.addTable({
          name: 'Wissensdatenbank',
          ref: 'A2',
          headerRow: true,
          style: {
            theme: 'TableStyleLight9',
            showRowStripes: true,
            showFirstColumn: true,
          },
          columns: [
            { name: 'ID', filterButton: true },
            { name: 'Sprache der Antwort', filterButton: true },
            { name: 'Titel der Antwort', filterButton: true },
            { name: 'Antworttext (markdown formatiert)', filterButton: true },
            { name: 'Lesbare ID (Nutzer*innen definiert)', filterButton: true },
            { name: 'Folgefragen', filterButton: true },
            {
              name: 'Antwort-Pfad (unter welchem Pfad in der Mind-Map die Antwort gespeichert ist)',
              filterButton: true,
            },
            { name: 'Anzahl hinterlegter Fragen', filterButton: true },
            { name: 'Im Modell hinterlegte Fragen', filterButton: true },
          ],
          rows: answers,
        })

        // add data
        // table.commit()

        const table = sheet.getTable('Wissensdatenbank') as any
        // console.log(table)
        if (table) {
          for (let i = 1; i <= table.table.columns.length + 1; i++) {
            sheet.getColumn(i).alignment = {
              ...sheet.getColumn(i).alignment,
              wrapText: true,
            }
            // set column width depending on index - this is manually set based on experience - numbers are pixel - hover over colum maker whilst changing size to see the column width in an excel
            switch (i) {
              case 1:
                sheet.getColumn(i).width = 40
                break
              case 2:
                sheet.getColumn(i).width = 35
                break
              case 3:
                sheet.getColumn(i).width = 35
                break
              case 4:
                sheet.getColumn(i).width = 160
                break
              case 5:
                sheet.getColumn(i).width = 40
                break
              case 6:
                sheet.getColumn(i).width = 50
                break
              case 7:
                sheet.getColumn(i).width = 55
                break
              case 8:
                sheet.getColumn(i).width = 35
                break
              case 9:
                sheet.getColumn(i).width = 350
                break
              default:
                break
            }
          }

          // write to a new buffer
          const buffer = await workbook.xlsx.writeBuffer()
          const data = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
          })
          FileSaver.saveAs(data, `export-wissensdatenbank-${bot?.name}-${bot?.id}-${Date.now()}.xlsx`) // name of file
          setLoadingAnswers(0) // resetting
        } else {
          throw new Error('No Table found')
        }
      }
    } catch (err) {
      console.error(err)
      // set error
      setNotification('error', 'Es konnte keine Excel für den Export der Wissensdatenbank erstellt werden.')
      setLoadingAnswers(0) // resetting instead of error so user can try again
    }
  }

  function setNotification(severity: 'success' | 'error', message: string): void {
    setNotificationSeverity(severity)
    setNotificationMessage(message)
    setShowNotification(true)
    setTimeout(function () {
      setNotificationSeverity(undefined)
      setNotificationMessage(undefined)
      setShowNotification(false)
    }, 5000)
  }

  return (
    <>
      <CustomizedTooltip
        placement='top'
        disableInteractive
        content={<Typography variant='body1'>Wissensdatenbank exportieren und als Excel herunterladen.</Typography>}
        elements={
          <div>
            <Button
              size='normal'
              type='normal'
              icon='download-line'
              iconType='remix'
              onClick={loadAnswers}
              loading={loadingAnswers === 1 || loadingAnswers === 2 || loadingAnswers === 3}
              disabled={
                loadingAnswers === 1 ||
                loadingAnswers === 2 ||
                loadingAnswers === 3 ||
                bot === null ||
                typeof bot.id === 'undefined' ||
                typeof bot?.modules[MODULE_ID_CONVAISE_NLU] === 'undefined' ||
                typeof (bot?.modules[MODULE_ID_CONVAISE_NLU] as NLUModule)?.knowledgeDbId !== 'string'
              }
            >
              Exportieren
            </Button>
          </div>
        }
      ></CustomizedTooltip>
      <NotificationSnackbar
        position='top'
        severity={notificationSeverity === 'error' ? 'error' : 'success'}
        open={!!showNotification}
        message={notificationMessage ?? ''}
      />
    </>
  )
}
