import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { AnswerAction } from '../../../../../../@types/Knowledge/types'
import React from 'react'
import Table from 'components/Table/Table'
import AnswerPreview from 'components/Previews/answerPreview'

const useStyles = makeStyles()(() => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  tableContainer: {
    overflow: 'auto',
  },
}))

type StepAnswerSummaryProps = {
  title?: string
  topic?: string
  answerText?: string
  followUpQuestions?: AnswerAction[]
}

export default function StepAnswerSummary({
  title,
  topic,
  answerText,
  followUpQuestions,
}: StepAnswerSummaryProps): React.ReactElement {
  const { classes } = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.tableContainer}>
        <Table
          rows={[
            [<Typography key='title-1'>Titel</Typography>, <Typography key='title-value'>{title}</Typography>],
            [
              <Typography key='topic-1'>Thema</Typography>,
              <Typography key='topic-value'>{topic === 'NoTopic' ? 'Kein Thema' : topic}</Typography>,
            ],
            [
              <Typography key='answer-1'>Antwort</Typography>,
              <AnswerPreview key='answer-value' actions={[]} answer={answerText ?? ''} noContainer />,
            ],
            // [
            //   <Typography key='followup-1'>Folgethemen</Typography>,
            //   <Typography key='followup-values'>{(followUpQuestions ?? []).map((question) => question)}</Typography>,
            // ],
          ]}
          cellAlignPattern={['left', 'left']}
          width={['20%', '80%']}
          disablePagination
          padding='medium'
        />
      </div>
    </div>
  )
}
