import { useEffect, useRef } from 'react'

/**
 * This hook sets up an interval and clears it after unmounting.
 * It acts as an abstraction to circumvent some of the sideffects of setInterval (e.g. callback function has stale state etc.).
 * (see here: https://overreacted.io/making-setinterval-declarative-with-react-hooks/)
 * @param callback
 * @param delay
 */
export function useInterval(callback: () => void, delay: number): void {
  const savedCallback = useRef<any>()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick(): void {
      savedCallback.current()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return (): void => clearInterval(id)
    }
  }, [delay])
}
