import React, { useCallback, useEffect, useRef, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import Chart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import { cloneDeep, isEqual, values } from 'lodash'
import { getLinechartOptions } from '../chartOptions'
import { IconButton, LinearProgress, Typography } from '@mui/material'
import { getDateLabel, padZero } from '../../../utils/dateUtils'
import { Granularity, LinechartSeriesData, LinechartSeriesDataPoint, ScaleType } from '../../../@types/Analytics/types'
import { TimeseriesLinechartMenu } from './TimeseriesLinechartMenu'

const useStyles = makeStyles()({
  chartContainer: {
    height: '100%',
    width: '100%',
  },
  errorContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  linearProgressBarContainer: {
    display: 'flex',
    height: '100%',
  },
  linearProgressBar: {
    width: '80%',
    margin: 'auto',
  },
  menuContainer: { width: '100%', display: 'flex', height: '24px', position: 'absolute', top: '67px' },
  menuButton: { marginLeft: 'auto' },
  menuPopup: {
    position: 'absolute',
  },
})

// data format for props
export type DataSeries = {
  name: string
  // data: (number & null)[]
  data: LinechartSeriesData
  color: string
}

// series and data format for chart
type ChartDataPoint = { x: Date; color: string } & LinechartSeriesDataPoint
type ChartSeries = { name: string; data: ChartDataPoint[] }

/**
 * Formats date for csv.
 * If detail level is 'day': Returns 'dd.mm.yyyy' '19.06.2023'
 * If detail level is 'hour': Returns 'dd.mm.yyyy hh.mm' '19.06.2023 14:00'
 * @param date
 * @param detailLevel
 */
function formatDateForCSV(date: Date, detailLevel: 'hour' | 'day'): string {
  if (detailLevel === 'day') {
    return `${padZero(date.getDate())}.${padZero(date.getMonth() + 1)}.${date.getFullYear()}`
  } else {
    return `${padZero(date.getDate())}.${padZero(date.getMonth() + 1)}.${date.getFullYear()} ${padZero(
      date.getHours(),
    )}:${padZero(date.getMinutes())}`
  }
}

/**
 * Builds csv string from chart data.
 * Assumes that all series always have same number of elements.
 * @param series
 */
function buildCsvStringFromChartSeries(
  series: ChartSeries[] | null,
  detailLevel: 'hour' | 'day',
  delimiter = ';',
): string {
  if (series === null || series.length === 0 || !series) return ''

  const seriesNames = series.map((s) => s.name).join(delimiter)

  // initialize csv string with header row: Timestamp, series1name, series2name, etc.
  let csv = `Timestamp${delimiter}${seriesNames}\n`
  for (let i = 0; i < series[0].data.length; i += 1) {
    // date is the same for all series
    const date = series[0].data[i].x
    csv += `${formatDateForCSV(date, detailLevel)}`
    // iterate over all series and set value for that date
    for (const s of series) {
      const value = s.data[i].y
      csv += `${delimiter}${value}`
    }
    csv += '\n'
  }
  return csv
}

type TimeseriesLinechartProps = {
  linechartId: string
  isLoading: boolean
  xAxisLabel: string
  yAxisLabel: string
  xValues?: Date[]
  yValues?: DataSeries[]
  type?: ScaleType
  detailLevel: 'hour' | 'day'
  maxNumberXLabels?: number
  formatTooltipValue?: (val: number, opts?: any) => string // optional custom tooltip formatter function
}

// we use React.memo to prevent useless re-renders if props have not changed
// rendering the chart is very heavy.
export default React.memo(function TimeseriesLinechart({
  linechartId,
  isLoading: propIsLoading,
  xAxisLabel: xAxisTitle,
  yAxisLabel: yAxisTitle,
  xValues,
  yValues,
  detailLevel: propDetailLevel,
  formatTooltipValue: propFormatTooltipValue,
  maxNumberXLabels = 24,
  type = 'count',
}: TimeseriesLinechartProps) {
  // NOTE: we need to create new options each times because ApexChart sets properties indicating changes it performed (e.g. convert type category to numeric)
  // which is necessary if xAxis ticks are placed "on" (https://github.com/apexcharts/apexcharts.js/issues/472)
  // if we do not recreate the options, these properties are already set and Apex does not perform the conversion leading to a broken chart because values
  // are not in the required type / format.
  const origOptions = getLinechartOptions(
    linechartId,
    formatXAxisLabels,
    formatYAxisLabel,
    formatTooltipTitle,
    formatToolTipValue,
    'category',
    xAxisTitle,
    yAxisTitle,
  )

  const { classes } = useStyles()
  const [error, setError] = useState<'dataerror' | undefined>()
  const [isLoading, setIsLoading] = useState<boolean>(propIsLoading)
  const optionsRef = useRef<ApexOptions>({ ...origOptions })

  const [anchorElementForMenu, setAnchorElementForMenu] = useState<HTMLElement | null>(null)
  const menuOpen = Boolean(anchorElementForMenu)

  // const optionsRef = useRef<ApexOptions>({ ...origOptions })

  // NOTE: We have to store data, detaillevel and label mask in state + in a ref because the formatter functions are passed to ApexCharts
  //       They only work if they use the ref. If they were using the state, they would work potentially on stale state (the one of their creation time)
  const dataRef = useRef<ChartSeries[] | null>(null)
  // to enforce a rerender after dataRef is updated
  const [dataUpdatedFlag, setDataUpdatedFlag] = useState<number>(0)
  const labelMaskRef = useRef<number[] | null>(null)
  const detailLevelRef = useRef<Granularity | null>(propDetailLevel)

  // ======= DATA PREPARATION ========

  /**
   * Prepares data for visualization in the chart.
   * Processes yValues from props into the required series format for the chart.
   */
  function prepareSeriesData(
    xValues: Date[],
    yValues: DataSeries[],
  ): { data: ChartSeries[]; options: ApexOptions } | undefined {
    if (typeof xValues === 'undefined' || typeof yValues === 'undefined') return
    // const newOptions = cloneDeep(optionsRef.current)
    const newOptions = cloneDeep(origOptions)

    // ensure data is in right format (i.e. all arrays in yValues have same length)
    yValues = yValues.filter((series) => (series.data ? series.data.length === xValues.length : false))
    const isLegal = yValues.every((y, index, yValues) => y.data.length === xValues.length)
    if (!isLegal) {
      setError('dataerror')
      return
    }

    const series: ChartSeries[] = []
    const colors: string[] = []
    let maxValue = 0 // holds max value - used for scaling the yAxis
    for (let i = 0; i < yValues.length; i += 1) {
      // iterate over each series
      const seriesData = yValues[i]
      const { name: seriesName, color: seriesColor } = seriesData

      const chartSeries: ChartSeries = { name: seriesName, data: [] }
      const seriesValues = seriesData.data
      const seriesMaxValue = Math.max(
        ...seriesValues.map((seriesValue) => seriesValue.y || 0).filter((value) => value !== null),
      )
      if (seriesMaxValue > maxValue) maxValue = seriesMaxValue

      for (let j = 0; j < xValues.length; j += 1) {
        const xValue = xValues[j]
        chartSeries.data.push({ x: xValue, color: seriesColor, ...seriesValues[j] })
        // chartSeries.data.push({ x: xValue, y: seriesValues[j] })
      }
      series.push(chartSeries)
      colors.push(seriesColor)
    }

    // add 10% margin ontop of the max value or set to 1 if max value is 0
    if (maxValue === 0) maxValue = 1
    else maxValue = type === 'count' ? Math.ceil((maxValue *= 1.05)) : 100

    // set max yTicks
    let maxYTickAmount = 10 // default to 10
    if (maxValue < maxYTickAmount) maxYTickAmount = maxValue // if maxValue is smaller than 10, use that

    // update config
    if (!Array.isArray(newOptions.yaxis)) {
      if (typeof newOptions.yaxis === 'undefined') newOptions.yaxis = {}
      newOptions.yaxis.max = maxValue
      newOptions.yaxis.tickAmount = maxYTickAmount
    }
    newOptions.colors = colors
    return { data: series, options: newOptions }
  }

  /**
   * Prepares CSV data and downloads csv
   */
  function downloadCsv(): void {
    const csvString = buildCsvStringFromChartSeries(dataRef.current, propDetailLevel, ';')
    const csvContent = 'data:text/csv;charset=utf-8,' + csvString
    const encodedUri = encodeURI(csvContent)
    const link = document.createElement('a')
    link.download = `${linechartId}.csv`
    link.href = encodedUri
    link.click()
  }

  // ======= FORMATTER =========

  /**
   * Calculates a label mask for displaying an equally distributed number of labels <= the max number of labels.
   * Returns array of indexes for the xAxis values whose labels should be shown.
   * @param nrDataPoints
   */
  function calculateLabelMask(nrDataPoints: number): number[] {
    // calculate step size (#datapoints between labels)
    const stepSize = Math.ceil(nrDataPoints / maxNumberXLabels)
    const mask = [0]
    for (let i = 0; i < nrDataPoints; i += 1) {
      if (mask[mask.length - 1] + stepSize === i) mask.push(i)
    }
    return mask
  }

  /**
   * Formats the label for a single date value.
   * @param index
   * @param seriesInfo
   * @param short
   */
  function formatSingleDateValue(
    data: ChartSeries[],
    index: number,
    seriesInfo: { seriesIndex: number; dataPointIndex: number },
    short = false,
  ): string[] {
    if (data.length === 0) return []
    const { dataPointIndex, seriesIndex } = seriesInfo

    const date = new Date(data[seriesIndex].data[dataPointIndex].x)

    const d = date.getDate()
    const m = date.getMonth()
    const sDate = d < 10 ? '0' + d : d
    const sMonth = m + 1 < 10 ? '0' + (m + 1) : m + 1
    if (detailLevelRef.current === 'hour') {
      // hourly
      const h = date.getHours()
      const min = date.getMinutes()
      const sHour = h < 10 ? '0' + h : h
      const sMin = min < 10 ? '0' + min : min

      return short
        ? [`${sDate}.${sMonth}.`, `${sHour}:${sMin}`]
        : // : [`${getDateLabel(date.getDay())}. ${sDate}.${sMonth}.`, `${sHour}:${sMin} Uhr`]
          [`${sDate}.${sMonth}.`, `${sHour}:${sMin}`]
    } else {
      // daily
      return [`${getDateLabel(date.getDay())}`, `${sDate}.${sMonth}.`]
    }
  }

  /**
   * Formats x axis label.
   * @param value
   * @param timestamp
   * @param opts
   */
  function formatXAxisLabels(value: string, timestamp?: number, opts?: any): string | string[] {
    const data = dataRef.current
    const labelMask = labelMaskRef.current

    if (data === null || typeof data[0] === 'undefined' || labelMask === null) return ['']
    const date = new Date(value)
    const series = data[0].data
    const index = series.findIndex((dp) => new Date(dp.x).getTime() === date.getTime())

    if (typeof labelMask !== 'undefined' && labelMask.includes(index)) {
      const seriesInfos = { seriesIndex: 0, dataPointIndex: index }
      const value = formatSingleDateValue(data, index, seriesInfos)
      return value
    } else {
      return ['']
    }
  }

  /**
   * Formats yTicks.
   *
   * Rounds to integer
   * @param {number} value
   */
  function formatYAxisLabel(value: number): string {
    return Math.round(value).toString()
  }

  /**
   * Formats the title of the tooltip.
   * Uses xValues (date) of current datapoint.
   * @param value
   * @param opts
   */
  function formatTooltipTitle(val: number, opts?: any): string {
    // formats value to display
    if (typeof opts === 'undefined' || typeof opts.dataPointIndex === 'undefined' || dataRef.current === null) {
      return type === 'percent' ? `${val.toString()} %` : val.toString()
    }

    const { seriesIndex, dataPointIndex } = opts
    const series = dataRef.current[seriesIndex]
    const date = series.data[dataPointIndex].x

    if (typeof date === 'undefined') return ''
    const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
    const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    const year = date.getFullYear()

    return detailLevelRef.current === 'day'
      ? `${getDateLabel(date.getDay())} ${day}.${month}.${year}`
      : `${getDateLabel(date.getDay())} ${day}.${month},<br />${hours}:${minutes} Uhr`
    // NOTE: you can use <br /> to force a line break in the formatter
  }

  /**
   * Formats tooltip value. Uses yValue.
   * @param val
   * @param opts
   */
  function formatToolTipValue(val: number, opts?: any): string {
    // use custom formatter function if provided
    if (propFormatTooltipValue) {
      return propFormatTooltipValue(val, opts)
    }

    return type === 'percent' ? `${val.toString()} %` : val.toString()
  }

  function closeMenu(): void {
    setAnchorElementForMenu(null)
  }

  function openMenu(event: React.MouseEvent<HTMLButtonElement>): void {
    event.stopPropagation()
    if (menuOpen) {
      closeMenu()
    } else {
      setAnchorElementForMenu(event.currentTarget)
    }
  }

  useEffect(
    function () {
      if (typeof xValues === 'undefined' || typeof yValues === 'undefined') return
      const labelMask = calculateLabelMask(xValues.length)
      labelMaskRef.current = labelMask
      const seriesPrepResult = prepareSeriesData(xValues, yValues)
      if (typeof seriesPrepResult !== 'undefined') {
        const { data, options } = seriesPrepResult
        dataRef.current = data
        // to enforce a rerender
        const updatedNumber = dataUpdatedFlag + 1
        setDataUpdatedFlag(updatedNumber)
        optionsRef.current = options
        // setIsLoading(propIsLoading)
      }
    },
    [xValues, yValues],
  )

  useEffect(
    function () {
      detailLevelRef.current = propDetailLevel
    },
    [propDetailLevel],
  )

  useEffect(
    function () {
      setIsLoading(propIsLoading)
    },
    [propIsLoading],
  )

  // console.log('Linechart Re-render: ', linechartId)

  return typeof error === 'undefined' ? (
    <div className={classes.chartContainer}>
      {!isLoading ? (
        <>
          <Chart
            options={optionsRef.current}
            series={dataRef.current !== null ? dataRef.current : []}
            type='line'
            height='100%'
            width='100%'
          />
          <div className={classes.menuContainer}>
            <IconButton onClick={openMenu} className={classes.menuButton} aria-label='download-menu' size={'small'}>
              <i className={'ri-menu-line '} />
            </IconButton>
          </div>
          {/* <div className={classes.menuPopup}>
            <Typography>Menu open</Typography>
          </div> */}
          {anchorElementForMenu && (
            <TimeseriesLinechartMenu
              open={menuOpen}
              onClose={closeMenu}
              onDownloadCsv={downloadCsv}
              anchorElement={anchorElementForMenu}
            />
          )}
        </>
      ) : (
        <div className={classes.linearProgressBarContainer}>
          <LinearProgress className={classes.linearProgressBar} />
        </div>
      )}
    </div>
  ) : (
    <div className={classes.errorContainer}>Datenfehler. Diagramm kann nicht angezeigt werden.</div>
  )
}, isEqual)

// export default TimeseriesLinechart
