import React from 'react'

// Material UI
import { makeStyles } from 'tss-react/mui'

import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
// Const
import { LINK_DATAPROTECTION, LINK_IMPRESSUM } from '../../utils/constants'

type StyleProps = { smallWidth: boolean }

const useStyles = makeStyles<StyleProps>()((theme, props) => ({
  container: {
    height: theme.spacing(6),
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: props.smallWidth ? '800px' : 'auto',
    margin: props.smallWidth ? '0 auto 0 auto' : '0',
  },
}))

type FooterProps = {
  smallWidth?: boolean
}

function Footer({ smallWidth = false }: FooterProps): React.ReactElement {
  const { classes } = useStyles({ smallWidth })
  return (
    <footer className={classes.container}>
      <Typography variant='subtitle2'>
        &copy; Convaise 2024 -{' '}
        {
          // eslint-disable-next-line no-undef
          process.env.REACT_APP_VERSION &&
            // eslint-disable-next-line no-undef
            `${process.env.REACT_APP_VERSION.replace('"', '')}`
        }
      </Typography>
      <Typography variant='subtitle2' style={{ marginLeft: 'auto' }}>
        <Link target='_blank' rel='noopener noreferrer' href={LINK_DATAPROTECTION} color='inherit'>
          Datenschutzerklärung
        </Link>
        {' | '}
        <Link target='_blank' rel='noopener noreferrer' href={LINK_IMPRESSUM} color='inherit'>
          Impressum
        </Link>
      </Typography>
    </footer>
  )
}

export default Footer
