import React from 'react'
import { makeStyles } from 'tss-react/mui'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles()({
  container: {
    width: '100%',
    maxWidth: '1400px',
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
})

const useHeaderStyles = makeStyles()((theme) => ({
  header: {
    display: 'flex',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    width: '100%',
  },
  title: {
    // margin: `${theme.spacing(4)} 0 ${theme.spacing(8)} ${theme.spacing(8)}`,
  },
  actions: {
    display: 'flex',
    marginLeft: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  backButton: {
    margin: `auto ${theme.spacing(1)} auto 0`,
  },
}))

type ContentPageHeaderProps = {
  title: string | React.ReactElement
  actions?: React.ReactElement[]
  previousUrl?: string
  previousUrlCallback?: () => boolean // url that is called when the user clicks on the previous url arrow button. If the callback return true, goBack is triggered, else nothign happens.
}

// TODO: add previousUrl callback to enable an unsaved changes dialog
export function ContentPageHeader({
  title,
  actions = [],
  previousUrl = undefined,
  previousUrlCallback,
}: ContentPageHeaderProps): React.ReactElement {
  const { classes } = useHeaderStyles()
  const navigate = useNavigate()

  return (
    <div className={classes.header}>
      {previousUrl && (
        <IconButton
          className={classes.backButton}
          onClick={(): void => {
            if (!previousUrlCallback) navigate(previousUrl)
            const goBack = typeof previousUrlCallback === 'function' ? previousUrlCallback() : true
            if (goBack) navigate(previousUrl)
          }}
        >
          <i className={`ri-arrow-left-line`} />
        </IconButton>
      )}
      {/* Allows the title to be string or an react element - string is the default - allows for easy use of imgs as title */}
      <Typography variant='h1' className={classes.title}>
        {title}
      </Typography>
      <div className={classes.actions}>
        {actions.map((action, index) => (
          <div key={`action-${index}`} style={{ marginLeft: '22px' }}>
            {action}
          </div>
        ))}
      </div>
    </div>
  )
}

type ContentPageProps = {
  children?: React.ReactNode
}

export default function ContentPage({ children }: ContentPageProps): React.ReactElement {
  const { classes } = useStyles()

  return <div className={classes.container}>{children}</div>
}
