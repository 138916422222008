import React, { CSSProperties } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useTheme } from '@mui/material/styles'
import { CheckCircle } from '@mui/icons-material'
import Typography from '@mui/material/Typography'

type StyleProps = {
  height?: string
  minHeight?: string
  width?: string
  footerColor?: string
  hasTitle?: boolean
  titleVariant?: string
  disabled?: boolean
  isSelected?: boolean
  paddingTop?: string
  paddingBottom?: string
  paddingSide?: string
  contentMarginTop?: string
  selectedBorderColor?: string
  clickable?: boolean
  shadowWidth?: number
  shadowBlur?: number
  contentScrollable?: boolean
  hasTopRightActionButton?: boolean
}
export const useCardStyles = makeStyles<StyleProps>()((theme, props, classes) => ({
  ensureMinHeight: {
    height: props?.height || 'auto',
    maxHeight: props?.height,
    minHeight: props?.minHeight,
    // width: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    alignItems: 'center',
  },
  container: {
    // boxSizing: 'border-box',
    cursor: props?.clickable ? 'pointer' : undefined,
    transition: props?.clickable ? 'box-shadow 0.3s' : undefined,
    display: 'flex',
    flexDirection: 'column',
    // boxSizing: 'border-box',
    position: 'relative',
    borderRadius: `${
      typeof theme.shape.borderRadius === 'string'
        ? parseInt(theme.shape.borderRadius) * 3
        : theme.shape.borderRadius * 3
    }px`,
    background: !props?.disabled ? theme.palette.background.paper : theme.palette.grey[100],
    // height: props?.height || '100px',
    flexGrow: 1,
    alignItems: 'flex-start',
    minHeight: 0,
    width: props?.width || '100px',
    border: props?.isSelected
      ? props?.selectedBorderColor
        ? `3px solid ${props.selectedBorderColor}`
        : `3px solid ${theme.palette.success.main}`
      : '0',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: props?.shadowWidth ? `-${props.shadowWidth}px` : '-30px',
      left: props?.shadowWidth ? `-${props.shadowWidth}px` : '-30px',
      right: props?.shadowWidth ? `-${props.shadowWidth}px` : '-30px',
      bottom: props?.shadowWidth ? `-${props.shadowWidth}px` : '-30px',
      zIndex: -1,
      background: theme.palette.grey.A100,
      // background: '#ffffe0',
      opacity: 0.6,
      WebkitFilter: props?.shadowBlur ? `blur(${props.shadowBlur}px)` : 'blur(90px)',
      filter: props?.shadowBlur ? `blur(${props.shadowBlur}px)` : 'blur(90px)',
      borderRadius: `${
        typeof theme.shape.borderRadius === 'string'
          ? parseInt(theme.shape.borderRadius) * 3
          : theme.shape.borderRadius * 3
      }px`,
      height: props?.height
        ? props.shadowWidth
          ? `calc(${props.height} + ${props.shadowWidth}px)`
          : `calc(${props.height} + 30px)`
        : 'auto', // if height is a number -> remove either shadowWidht or the default 30 px shadowWidht
    },
    '&:hover, focus': {
      boxShadow: props?.clickable
        ? '0 14px 26px -12px rgb(0 0 0 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)' // '0px 2px 10px 2px rgba(0,0,0, 0.25)'
        : undefined,
    },
  },
  containerContent: {
    // boxSizing: 'border-box',

    // height: props?.height || '100px',
    flexGrow: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0,
  },
  selectedCheckIcon: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    color: '#FFFFFF',
    // background: theme.palette.success.main,
  },
  topRightActionButtonContainer: {
    position: 'absolute',
    top: '15px',
    right: '15px',
  },
  titleContainer: {
    display: 'flex',
    width: '100%',
    padding: `${props?.paddingTop ?? theme.spacing(3)} ${props?.paddingSide ?? theme.spacing(3)} 0 ${
      props?.paddingSide ?? theme.spacing(3)
    }`,
  },
  title: {
    maxHeight:
      props && props.titleVariant
        ? `${theme.typography[props.titleVariant].lineHeight * 2}em`
        : theme.typography?.h3?.lineHeight && typeof theme.typography.h3.lineHeight === 'number'
        ? `${(theme.typography.h3.lineHeight as number) * 2}em`
        : `2.4em`,
    marginRight: props && props.hasTopRightActionButton ? theme.spacing(4) : 0,
    // padding: theme.spacing(2),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    wordWrap: 'break-word',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    flex: '0 1 auto',
  },
  cardContent: {
    flex: '1',
    padding: `${props?.paddingTop ?? theme.spacing(0)} ${props?.paddingSide ?? theme.spacing(3)}  ${
      props?.paddingBottom ?? theme.spacing(3)
    } ${props?.paddingSide ?? theme.spacing(3)}`,
    // overflow: 'hidden',
    textOverflow: 'ellipsis',
    // boxSizing: 'border-box',
    // padding: theme.spacing(4),
    // paddingTop: props?.paddingTop || props.hasTitle ? undefined : theme.spacing(4),
    // paddingBottom: props?.paddingBottom || theme.spacing(4) || undefined,
    // paddingLeft: props?.paddingSide || theme.spacing(4) || undefined,
    // paddingRight: props?.paddingSide || theme.spacing(4) || undefined,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: props && props.contentScrollable ? 'auto' : 'hidden',
    marginTop: props && props.contentMarginTop ? props.contentMarginTop : theme.spacing(1),
  },
  cardFooter: {
    // position: 'absolute',
    width: '100%',
    height: '36px',
    // left: '0px',
    // bottom: '0px',
    background: props && props.footerColor ? props.footerColor : '#4D75CB',
    opacity: props?.disabled ? '0.65' : '1',
    borderRadius: '0px 0px 24px 24px',
    display: 'flex',
  },
  footerText: {
    color:
      props && props.footerColor
        ? props.footerColor === theme.palette.success.main
          ? theme.palette.background.paper
          : theme.palette.getContrastText(props.footerColor)
        : theme.palette.background.paper,
    margin: 'auto',
    opacity: props?.disabled ? '0.65' : '1',
    lineHeight: '19.07px',
    textSize: '14px',
    fontWeight: 400,
  },
}))

type CardProps = {
  children?: React.ReactNode
  title?: string | React.ReactNode
  height?: string
  minHeight?: string
  width?: string
  paddingTop?: string
  paddingBottom?: string
  paddingSide?: string
  contentMarginTop?: string
  footerText?: string
  footerColor?: string
  selectedBorderColor?: string
  className?: string
  isSelected?: boolean
  onClick?: (event) => void
  topRightActionButton?: React.ReactNode
  titleVariant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2' | 'inherit'
  disabled?: boolean
  shadowWidth?: number
  shadowBlur?: number
  contentScrollable?: boolean
}

/**
 * Base card for all cards in Studio 2.0.
 */
export default function BaseCard({
  children,
  title,
  footerText,
  footerColor,
  height = '310px',
  width = '520px',
  minHeight,
  paddingTop,
  paddingBottom,
  paddingSide,
  contentMarginTop,
  selectedBorderColor,
  className,
  onClick,
  topRightActionButton,
  isSelected = false,
  titleVariant = 'h2',
  disabled = false,
  shadowWidth,
  shadowBlur,
  contentScrollable = false,
}: CardProps): React.ReactElement {
  const theme = useTheme()
  const { classes } = useCardStyles({
    height,
    minHeight,
    width,
    hasTitle: !!title,
    paddingTop,
    paddingBottom,
    contentMarginTop,
    selectedBorderColor,
    paddingSide,
    footerColor,
    titleVariant,
    disabled,
    isSelected,
    clickable: !!onClick && !disabled,
    shadowWidth,
    shadowBlur,
    contentScrollable,
    hasTopRightActionButton: !!topRightActionButton,
  })

  return (
    <div className={classes.ensureMinHeight}>
      <div
        className={className ? `${classes.container} ${className}` : classes.container}
        onClick={(event): void => {
          if (typeof onClick !== 'undefined' && !disabled) onClick(event)
        }}
      >
        <div className={classes.containerContent}>
          {isSelected && !topRightActionButton && (
            // only show selected icon if there is no top right action button (like delete set)
            <div className={classes.selectedCheckIcon}>
              <CheckCircle style={{ fill: theme.palette.success.main }} />
            </div>
          )}
          {!!topRightActionButton && (
            <div className={classes.topRightActionButtonContainer}>{topRightActionButton}</div>
          )}
          {!!title && (
            <div className={classes.titleContainer}>
              {typeof title === 'string' ? (
                <Typography className={classes.title} variant={titleVariant} gutterBottom>
                  {title}
                </Typography>
              ) : (
                title
              )}
            </div>
          )}
          <div className={classes.cardContent}>{children}</div>
        </div>
        {!!footerText && (
          <div className={classes.cardFooter}>
            <div className={classes.footerText}>
              <Typography variant='subtitle1'>{footerText}</Typography>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
