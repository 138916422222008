import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from 'tss-react/mui'
import { v4 as uuid } from 'uuid'

import { useBotContext } from '../../../hooks/contexts/bot-context'
import { SelectDropdown, Option, ActionMeta } from 'components/Dropdown'
import { BotEnvironment } from '../../../@types/BotInformation/types'
import { CustomIconButton } from 'components/Buttons'
import { Slide } from '@mui/material'

// Types
type AssistantIFrameProps = {
  sourceUrl: string
  iFrameKey: string
}

function AssistantIFrame({ iFrameKey, sourceUrl }: AssistantIFrameProps): React.ReactElement {
  const iframeRef = useRef<HTMLIFrameElement>(null)

  return (
    <>
      <iframe
        ref={iframeRef}
        id='convaise-assistant-iframe'
        key={iFrameKey}
        data-src={sourceUrl}
        src={sourceUrl}
        width='100%'
        height='100%'
        style={{ border: 0 }}
      />
    </>
  )
}

const useStyles = makeStyles()((theme) => ({
  assistantContainer: {
    position: 'absolute',
    borderRadius: theme.shape.borderRadius,
    border: '1px solid rgba(0,0,0,0.1)',
    top: 0,
    bottom: 0,
    right: 0,
    width: '400px',
    background: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    // flexShrink: 0,
    // overflowX: 'hidden',
    // padding: '10px',
    boxShadow:
      'rgba(0, 0, 0, 0.2) 0px 2px 4px -2px, rgba(0, 0, 0, 0.14) 0px 4px 5px -4px, rgba(0, 0, 0, 0.12) 0px 1px 10px -3px',
    zIndex: 4,
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: theme.spacing(1),
    height: '800px',
  },
  iframeContainer: {
    width: '100%',
    height: '100%',
  },
  header: {
    display: 'flex',
    padding: theme.spacing(1),
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  iconButton: {
    width: '55px',
    height: '55px',
  },
  closeButton: {
    marginLeft: 'auto',
  },
}))

const selectOptions: Option[] = [
  { label: 'Test', value: 'staging', disabled: true, disabledText: 'Keine Test-Umgebung konfiguriert' },
  { label: 'Production', value: 'production' },
]

type AssistantProps = {
  onClose: () => void
}

export default function Assistant({ onClose }: AssistantProps): React.ReactElement {
  const { classes } = useStyles()
  const { bot: botInfos } = useBotContext()
  const [hasStaging, setHasStaging] = useState<boolean>(false)
  const [deployEnvOptions, setDeployEnvOptions] = useState<Option[]>(selectOptions)
  const [selectedEnv, setSelectedEnv] = useState<BotEnvironment>()
  const [iFrameRandomKey, setIFrameRandomKey] = useState<string>(uuid())
  const [iFrameUrl, setiFrameUrl] = useState<string>()

  function reload(): void {
    setIFrameRandomKey(uuid())
  }

  function buildIframeUrl(): void {
    if (!botInfos) return
    // console.log('building iframe url')
    if (selectedEnv === 'production') {
      // because we have some legacy webchat v4 botwhich have a hardcoded url based on the name, we have to hardcode them here
      let url = `https://assistants.convaise.com/${botInfos.id}/iframe`
      if (botInfos.id === 'ddupk5dcldn1mq87')
        url = `https://assistants.convaise.com/bw-wimi/foerderungsassistent/iframe` // BW Wimi KIVO
      if (botInfos.id === '1c6w84i4lbxgbjj6') url = `https://assistants.convaise.com/regio-it/solingen/iframe` // NOT SURE IF DEPLOYED dienstleistungsfinder Solingen
      if (botInfos.id === 'ddupk52sle31qwg7') url = `https://assistants.convaise.com/demo/munales/civento-iframe` // Civento / Munales Demoassistent integrated as popup into fictive city Oberniederbergdorf
      setiFrameUrl(`${url}?botId=${botInfos.id}`)
    } else if (selectedEnv === 'staging') {
      setiFrameUrl(`https://assistants.convaise.com/${botInfos.id}-staging/iframe?botId=${botInfos.id}`)
    }
  }

  useEffect(
    function () {
      if (
        botInfos?.integrationChannels.staging &&
        botInfos.integrationChannels.staging.hostUrl &&
        (botInfos.integrationChannels.staging.courier !== null ||
          botInfos.integrationChannels.staging.directline !== null)
      ) {
        setHasStaging(true)
        selectOptions[0].disabled = false
        setDeployEnvOptions([...selectOptions])
        setSelectedEnv('staging')
      } else {
        setHasStaging(false)
        setSelectedEnv('production')
      }
      buildIframeUrl()
    },
    [botInfos],
  )

  useEffect(
    function () {
      buildIframeUrl()
    },
    [selectedEnv],
  )

  return (
    <Slide direction='left' in={true} timeout={300}>
      <div className={classes.assistantContainer}>
        <div className={classes.header}>
          <CustomIconButton
            onClick={(): void => reload()}
            icon='ri-refresh-line'
            className={classes.iconButton}
            tooltip='Konversation neustarten'
          />
          <SelectDropdown
            options={deployEnvOptions}
            selected={selectedEnv}
            onChange={function (newValue: Option, action: ActionMeta<Option>): void {
              setSelectedEnv(newValue.value)
            }}
            isClearable={false}
            isCreatable={false}
            isSearchable={false}
            isDisabled={!hasStaging}
          />
          <CustomIconButton
            onClick={(): void => onClose()}
            icon='ri-close-line'
            className={`${classes.iconButton} ${classes.closeButton}`}
            tooltip='Schließen'
          />
        </div>
        <div className={classes.iframeContainer}>
          {iFrameUrl && <AssistantIFrame sourceUrl={iFrameUrl} iFrameKey={iFrameRandomKey} />}
        </div>
      </div>
    </Slide>
  )
}
