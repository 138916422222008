import React, { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useTheme } from '@mui/material/styles'
// Material ui
import Typography from '@mui/material/Typography'
// Custom Componets
import BaseCard from './BaseCard'
import { CustomNotifications } from '../../@types/CustomNotifications/types'
import { Divider, IconButton, ListItemText, MenuItem, MenuList, Menu } from '@mui/material'
import { useLockingContext } from 'hooks/contexts/locking-context'
import { MoreVert } from '@mui/icons-material'
import { buildTimeString, buildTurnusString, isNotificationExpired } from 'utils/notificationUtils'
import { CUSTOM_NOTIFICATION_CARD_HEIGHT } from 'utils/constants'
import { Can } from 'components/Can/Can'
import { NotificationActivatingStatus, useCustomNotifications } from 'hooks/contexts/customnotifications-context'

const useStyles = makeStyles()((theme) => ({
  card: {
    transition: 'box-shadow 0.3s',
    cursor: 'pointer',
    '&:hover': {
      boxShadow:
        '0 14px 26px -12px rgb(0 0 0 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)', // '0px 2px 10px 2px rgba(0,0,0, 0.25)'
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '80%',
  },
  popupMenu: {
    padding: 0,
  },
  popupMenuList: {
    padding: 0,
  },
  popupMenuRedText: {
    color: theme.palette.error.main,
  },
}))

type CustomNotificationCardMenuProps = {
  isActive: boolean
  onChangeActiveState: () => void
  onDuplicate: () => void
  onEdit: () => void
  onDelete: () => void
  open: boolean
  onClose: () => void
  anchorElement: Element
}

/**
 * Menu component for menu button of card.
 */
function CustomNotificationCardMenu({
  isActive,
  onChangeActiveState,
  onDuplicate,
  onEdit,
  onDelete,
  open,
  onClose,
  anchorElement,
}: CustomNotificationCardMenuProps): React.ReactElement {
  const theme = useTheme()
  const { classes } = useStyles()
  return (
    <Menu
      open={open}
      onClose={onClose}
      anchorEl={anchorElement}
      classes={{ list: classes.popupMenuList }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{
        style: {
          width: '150px',
        },
      }}
    >
      {/* <Paper sx={{ width: 320, maxWidth: '100%' }}> */}
      <MenuList>
        <MenuItem onClick={onChangeActiveState}>
          <ListItemText>{isActive ? 'Deaktivieren' : 'Aktivieren'}</ListItemText>
          {/* <Typography variant="body2" color="text.secondary">
                ⌘X
              </Typography> */}
        </MenuItem>
        <MenuItem onClick={onDuplicate}>
          <ListItemText>Duplizieren</ListItemText>
        </MenuItem>
        <MenuItem onClick={onEdit}>
          <ListItemText>Bearbeiten</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={onDelete}>
          <ListItemText classes={{ primary: classes.popupMenuRedText }} primary='Löschen' />
        </MenuItem>
      </MenuList>
      {/* </Paper> */}
    </Menu>
  )
}

type CardProps = {
  notificationId: string
  notifications: CustomNotifications
  overlappingNotificationIds: string[]
  onEdit: (notificationId: string) => void
  onChangeActiveState: (notificationId: string) => void
  onDuplicate: (notificationId: string) => void
  onDelete: (notificationId: string) => void
  height?: string
}

/**
 * Card for Custom Notification
 */
export default function NotificationCard({
  notificationId,
  notifications,
  overlappingNotificationIds,
  onEdit,
  onChangeActiveState,
  onDuplicate,
  onDelete,
  height = CUSTOM_NOTIFICATION_CARD_HEIGHT,
}: CardProps): React.ReactElement {
  const theme = useTheme()
  const { classes } = useStyles()
  const { lockState } = useLockingContext()
  const { notificationActivatingState } = useCustomNotifications()
  const [anchorElementForMenu, setAnchorElementForMenu] = useState<HTMLElement | null>(null)
  const menuOpen = Boolean(anchorElementForMenu)
  const notification = notifications[notificationId]

  function getFooterText(): string {
    if (notificationActivatingState && notificationActivatingState.notificationIds.includes(notificationId)) {
      if (notificationActivatingState.status === NotificationActivatingStatus.Activating) return 'Wird aktiviert...'
      else if (notificationActivatingState.status === NotificationActivatingStatus.Deactivating)
        return 'Wird deaktiviert...'
    }

    if (isNotificationExpired(notification)) return 'Abgelaufen'
    else if (overlappingNotificationIds && overlappingNotificationIds.length > 0) return 'Überlappend'
    else if (notification.isActive) return 'Aktiv'
    else return 'Deaktiviert'
  }

  function getFooterColor(): string {
    if (notificationActivatingState && notificationActivatingState.notificationIds.includes(notificationId)) {
      return theme.palette.grey['500']
    }

    if (isNotificationExpired(notification)) return theme.palette.primary.main
    else if (overlappingNotificationIds && overlappingNotificationIds.length > 0) return theme.palette.error.main
    else if (notification.isActive) return theme.palette.success.main
    else return theme.palette.grey['500']
  }

  function handleMenuClose(): void {
    setAnchorElementForMenu(null)
  }

  function handleMenuClick(event: React.MouseEvent<HTMLButtonElement>): void {
    event.stopPropagation()
    if (menuOpen) {
      handleMenuClose()
    } else {
      setAnchorElementForMenu(event.currentTarget)
    }
  }

  return (
    <Can I='update' a='notifications' passThrough>
      {(can): React.ReactElement => (
        <>
          <BaseCard
            title={notification.name}
            footerText={getFooterText()}
            footerColor={getFooterColor()}
            height={height}
            width='100%'
            className={classes.card}
            onClick={(): void => {
              onEdit(notification.notificationId)
            }}
            titleVariant='h3'
            paddingBottom='0px'
            topRightActionButton={
              can ? (
                <IconButton
                  onClick={handleMenuClick}
                  disabled={lockState !== 'canEdit'}
                  aria-controls={menuOpen ? 'basic-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={menuOpen ? 'true' : undefined}
                >
                  <MoreVert />
                </IconButton>
              ) : null
            }
          >
            <div className={classes.content}>
              <Typography variant='body2'>{buildTurnusString(notification)}</Typography>
              <Typography variant='body2'>{buildTimeString(notification)}</Typography>
              <Typography variant='body2' mt='auto' fontWeight='bolder'>
                {notification.title}
              </Typography>
              <Typography variant='body2' mb={0}>
                {notification.subtitle}
              </Typography>
            </div>
          </BaseCard>
          {anchorElementForMenu && (
            <CustomNotificationCardMenu
              open={menuOpen}
              isActive={notification.isActive}
              onChangeActiveState={(): void => {
                handleMenuClose()
                onChangeActiveState(notification.notificationId)
              }}
              onClose={handleMenuClose}
              onDelete={(): void => {
                handleMenuClose()
                onDelete(notification.notificationId)
              }}
              onDuplicate={(): void => {
                handleMenuClose()
                onDuplicate(notification.notificationId)
              }}
              onEdit={(): void => {
                handleMenuClose()
                onEdit(notification.notificationId)
              }}
              anchorElement={anchorElementForMenu}
            />
          )}
        </>
      )}
    </Can>
  )
}
