import React, { useEffect, useState } from 'react'

// @mui/material components
import { makeStyles } from 'tss-react/mui'

import { TextField, Typography } from '@mui/material'
// Types
import { Chart } from '../../../../../@types/Flowchart/types'

const useStyles = makeStyles()((theme) => ({
  selectedDialogContainer: {},
  description: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}))

type SQnAProps = {
  chart: Chart
  setStateCallback: (chart: Chart) => void
}

export default function SQnATrigger({ chart, setStateCallback }: SQnAProps): React.ReactElement {
  const { classes } = useStyles()

  // TODO: IDEA: eine Start QnA Mode Node und eine End QnA Mode Node?

  const [text, setText] = useState<string>()

  /**
   * Handles textfield text change
   * @param event
   */
  function onTextChange(value: string): void {
    const selectedId = chart.selected?.id
    if (typeof selectedId === 'undefined') return

    if (value.trim() === '' || value.trim().length === 0) {
      chart.nodes[selectedId].properties.text = undefined
    } else {
      chart.nodes[selectedId].properties.text = value
    }
    setStateCallback({ ...chart })
  }

  useEffect(
    function () {
      const selectedId = chart.selected?.id
      if (typeof selectedId === 'undefined') return

      const text = chart.nodes[selectedId].properties.text || ''

      setText(text)
    },
    [chart],
  )

  return (
    <div className={classes.selectedDialogContainer}>
      <Typography className={classes.description}>
        {`Mit dem "QnA Modus Trigger" starten Sie den reinen QnA Modus für den Assistenten. Das heißt es wird nach der
        Beantwortung einer Frage nicht zum vorherigen Block im Konversationsfluss zurückgekehrt, sondern einfach auf die
        nächste Frage der Nutzer*innen gewartet. Durch die Verwendung von 'Intent Dialog Triggern' können Sie den QnA
        Modus temporär zum Durchlaufen eines Dialoges verlassen. Nachdem der gestartete Dialog durchlaufen wurde, wird
        zu dem QnA Modus zurückgekehrt.`}
      </Typography>
      <Typography className={classes.description}>
        {`(Optional) Sie können einen Text festlegen, der nach dem Benutzerfeedback vom Assistenten gesendet werden soll.
        Das könnte zum Beispiel "Wie kann ich Ihnen sonst helfen?" sein. Dieser Text ist optional. Wenn kein Text
        gesetzt ist, wartet der Assistent einfach darauf, dass die nächste Frage gestellt wird.`}
      </Typography>
      {/* TODO: Vorschau der Nachricht */}
      <TextField
        label='Folge-Text'
        fullWidth
        multiline
        minRows={2}
        value={text ?? ''}
        onChange={(event): void => onTextChange(event.target.value)}
      />
    </div>
  )
}
