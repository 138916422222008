import React from 'react'
import { Helmet } from 'react-helmet'
// Custom Components
import ContentPage from '../../components/Page/ContentPage'
import Insights from './Insights'
// Constants
import { APP_TITLE } from '../../utils/constants'

export default function Analytics(): React.ReactElement {
  return (
    <ContentPage>
      <Helmet>
        <title>{APP_TITLE} - Analytics</title>
      </Helmet>
      <Insights />
      {/* <Routes> */}
      {/* <Route path={path + '/*'} element={<Insights />} /> */}
      {/* <Route path={path} element={<p>Analytics</p>} /> */}
      {/* <SubpageCard
            graphic={svgInsights}
            title='Nutzungseinblicke'
            description='Hier werden Daten und Informationen zur Nutzung aufbereitet und dargestellt. Es können Rückschlüsse über die Verwendung des digitalen Assistenten gezogen werden.'
            onClick={(): void => navigate(url + ROUTE_BOTID_ANALYTICS_INSIGHTS)}
            disabled={false}
          /> */}
      {/* <Route path={path + ROUTE_BOTID_ANALYTICS_INSIGHTS}>
        </Route> */}
      {/* <Route path='*' element={<Navigate to={path} />} /> */}
      {/* </Routes> */}
    </ContentPage>
  )
}
