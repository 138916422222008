import { Grid, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { Button } from 'components/Buttons'
import { useCustomNotifications } from 'hooks/contexts/customnotifications-context'
import { usePrevious } from 'hooks/usePrevious/usePrevious'
import React, { useEffect } from 'react'
import { buildTimeString, buildTurnusString } from 'utils/notificationUtils'
import { CustomNotification } from '../../../@types/CustomNotifications/types'

const useStyles = makeStyles()((theme) => ({
  container: {
    background: '#faecd7',
    // height: '300px',
    // width: '600px',
    minWidth: '400px',
    borderRadius:
      typeof theme.shape.borderRadius === 'string'
        ? parseInt(theme.shape.borderRadius) * 2
        : theme.shape.borderRadius * 2,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  overlapActionsContainer: {},
  primaryButton: {
    // marginLeft: theme.spacing(2),
    width: '100%',
  },
}))

type OverlappingNotificationHintProps = {
  type: 'create' | 'edit' // configures if component is used in create notification or edit notification mode
  overlappingNotification: CustomNotification
  onCreateDeactivated?: () => void
  onCreateActivated?: () => void
}

export default function OverlappingNotificationHint({
  type,
  overlappingNotification,
  onCreateDeactivated,
  onCreateActivated,
}: OverlappingNotificationHintProps): React.ReactElement {
  const { classes } = useStyles()
  const { loadingState } = useCustomNotifications()
  const prevLoadingState = usePrevious(loadingState)

  const [whichDecision, setWhichDecision] = React.useState<'create_active' | 'create_inactive'>()

  useEffect(
    function () {
      if (prevLoadingState && prevLoadingState === 'saving' && loadingState !== 'saving') {
        // loading state has changed from saving to something else
        // reset decision
        setWhichDecision(undefined)
      }
    },
    [loadingState],
  )

  return (
    <div className={classes.container}>
      <Typography fontWeight='bold'>
        Es existiert bereits eine aktive Notification, die sich zeitlich mit dieser überschneidet:
      </Typography>
      <Typography>Name: {overlappingNotification.name}</Typography>
      <Typography>Turnus: {buildTurnusString(overlappingNotification)}</Typography>
      {/* <Typography>Uhrzeit: {buildTimeString(overlappingNotification)}</Typography> */}
      <Typography>Titel: {overlappingNotification.title}</Typography>
      <Typography>{overlappingNotification.subtitle}</Typography>
      {type === 'create' && onCreateActivated && onCreateDeactivated ? (
        <>
          <Typography fontWeight='bold'>
            <br />
            Sie haben folgende Optionen:
          </Typography>
          <Typography>
            <ol>
              <li>
                Sie können die neue Notification deaktiviert erstellen. Um die neue Notification zu aktivieren, müssen
                Sie erst die existierende Notification deaktivieren und anschließend die neu erstellte Notification
                aktivieren.
              </li>

              <li>
                Alternativ können Sie die überlappende Notification deaktivieren und die neue Notification direkt
                aktivieren.
              </li>
            </ol>
            Falls Sie sich nicht sicher sind, erstellen Sie die neue Notification deaktiviert.
            <br />
          </Typography>
          <div className={classes.overlapActionsContainer}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  onClick={(): void => {
                    setWhichDecision('create_inactive')
                    onCreateDeactivated()
                  }}
                  size='small'
                  type='success'
                  disabled={loadingState === 'saving'}
                  loading={loadingState === 'saving' && whichDecision === 'create_inactive'}
                  className={classes.primaryButton}
                >
                  1. Deaktiviert erstellen
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  onClick={(): void => {
                    setWhichDecision('create_active')
                    onCreateActivated()
                  }}
                  size='small'
                  type='warning'
                  disabled={loadingState === 'saving'}
                  loading={loadingState === 'saving' && whichDecision === 'create_active'}
                  className={classes.primaryButton}
                >
                  2. Aktiviert erstellen & andere deaktivieren
                </Button>
              </Grid>
            </Grid>
          </div>
        </>
      ) : (
        // edit notification
        <Typography>
          Wenn Sie die bearbeitete Notification speichern, wird diese automatisch deaktiviert, damit sie sich nicht mit
          der bereits aktiven Notification überschneidet. Um die bearbeitete Notification zu aktivieren, müssen Sie die
          bestehende Notification erst deaktivieren.
        </Typography>
      )}
    </div>
  )
}
