import React, { useState, useEffect, useCallback, memo } from 'react'
import jp from 'jsonpath'
import { cloneDeep, isEqual } from 'lodash'

// @mui/material components
import { makeStyles } from 'tss-react/mui'

import { useBotContext } from '../../../../hooks/contexts/bot-context'

// components
import NodeDialog from '../../../../components/Dialogs/NodeDialog'
import { Button } from '../../../../components/Buttons'
import TabDialog from '../../../../components/Dialogs/TabDialog'
// helper
import { setOutgoingPortsForCard } from '../../../../utils/chartUtils'

// nodes
import SStart from './SelectedStartNode/SStart'
import SBMTexts from './SelectedBasicMessage/SBMTexts'
import SQBMTexts from './SelectedQuestionButtonMessage/SQBMTexts'
import SQBMVariables from './SelectedQuestionButtonMessage/SQBMVariables'
import SQFMTexts from './SelectedQuestionFreeMessage/SQFMTexts'
import SQFMVariables from './SelectedQuestionFreeMessage/SQFMVariables'
import SPTexts from './SelectedPicture/SPTexts'
import SPVariables from './SelectedPicture/SPVariables'
// import SLoop from './SelectedLoop/SLoop'
// import SLoopClose from './SelectedLoop/SLoopClose'
import SIfElse from './SelectedIfElse/SIfElse'
import SSwitchOld from './SelectedSwitch/SSwitchOld'
import SJump from './SelectedJump/SJump'
import SSetVariable from './SelectedVariableOld/SSetVariable'
import SSetVariables from './SelectedSetVariables/SSetVariables'
import SPdf from './SelectedPDF/SPDF'
import SAPIRequest from './SelectedAPI/SAPIRequest'
import SAPIResponse from './SelectedAPI/SAPIResponse'
import STrigger from './SelectedTrigger/STrigger'
import SEventTrigger from './SelectedEventTrigger/SEventTrigger'
import SQnATrigger from './SelectedQnATrigger/SQnATrigger'
import SQnAAnswer from './SelectedQnAAnswer/SQnAAnswer'
import SStartDialog from './SelectedStartDialog/SStartDialog'
import SAnalyticsTrigger from './SelectedAnalyticsTrigger/SAnalyticsTrigger'
import SelectedSmartCard from './SelectedSmartCard/SSelectedSmartCard'
import SNote from './SelectedNote/SNote'
import SFileUpload from './SelectedFileUpload/SFileUpload'
// datacheck
import DataCheckTabContent from './DataChecks/DataCheckTabContent'

import { Chart, Node } from '../../../../@types/Flowchart/types'
import { TranslationFile } from '../../../../@types/Translations/types'
import { BotInfos } from '../../../../@types/BotInformation/types'
import { Card, IAdaptiveCard } from '../../../../@types/SmartCards/types'
import SXzufiGetAnswerOld from './SelectedModuleXzufiGetAnswerOld/SXzufiGetAnswerOld'
import SXzufiTrackSelectedAnswer from './SelectedModuleXzufiTrackSelectedAnswer/SXzufiTrackSelectedAnswer'
import SXzufiTrackHelpful from './SelectedModuleXzufiTrackHelpful/SXzufiTrackHelpful'
import SQnAStartTriggerDialog from './SelectedModuleQnAStartTriggerDialog/SQnAStartTriggerDialog'
import SQnAGetAnswer from './SelectedModuleQnAGetAnswer/SQnAGetAnswer'
import SQnATrackHelpful from './SelectedModuleQnATrackHelpful/SQnATrackHelpful'
import SAlephAlphaGetAnswer from './SelectedModuleAlephAlphaGetAnswer/SAlephAlphaGetAnswer'
import SAlephAlphaTrackHelpful from './SelectedModuleAlephAlphaTrackHelpful/SAlephAlphaTrackHelpful'
import SSwitch from './SelectedSwitch/SSwitch'
import { useFlowdesignerContext } from 'hooks/contexts/flowdesigner-context'
import SXzufiGetAnswer from './SelectedModuleXzufiGetAnswer/SXzufiGetAnswer'
import SLLMTask from './SelectedLLMTask/SelectedLLMTask'

// // Styles for all selected components can be set here
// const style = {
//   message: {},
//   sidebarH5style: {},
// }

const useStyles = makeStyles()({
  selectedDialogContainer: {
    marginBottom: '10px',
    padding: '10px',
    borderRadius: '5px',
  },
  button: {
    color: '#FFF',
    border: 'none',
    cursor: 'pointer',
    margin: '.3125rem 1px',
    padding: '12px 30px',
    position: 'relative',
    fontSize: '12px',
    minHeight: 'auto',
    minWidth: 'auto',
    maxWidth: '100px',
    textAlign: 'center',
    transition: 'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
    fontWeight: 400,
    textTransform: 'uppercase',
  },
  confirmButton: {
    backgroundColor: '#4caf05',
    '&:hover,&:focus': {
      color: '#FFF',
      backgroundColor: '#4caf05',
      boxShadow: '0 14px 26px -12px rgba(76, 175, 5, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px',
    },
    '&:disabled': {
      color: '#FFF',
      backgroundColor: '#96c574',
    },
  },
  deleteButton: {
    backgroundColor: '#f44336',
    '&:hover,&:focus': {
      color: '#FFF',
      backgroundColor: '#f44336',
      boxShadow: '0 14px 26px -12px rgba(244, 67, 54, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px',
    },
    '&:disabled': {
      color: '#FFF',
      backgroundColor: '#ff8a82',
    },
  },
  closeButton: {
    backgroundColor: '#3c4858',
    '&:hover,&:focus': {
      color: '#FFF',
      backgroundColor: '#3c4858',
      boxShadow: '0 14px 26px -12px rgba(60, 72, 88, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px',
    },
  },
})

/**
 * Returns text that is displayed in node in Flowdesigner.
 *
 * @param node
 */
function getDisplayTextNode(chart: Chart, node: Node): string {
  if (!node) return ''
  switch (node.type) {
    case 'logic/setVar':
      if (node.properties.setVarValues && Object.keys(node.properties.setVarValues).length > 0) {
        const variableId = Object.keys(node.properties.setVarValues)[0]
        const varDisplayName = chart.variables[variableId]?.displayName
        if (varDisplayName) {
          return `Setze %${varDisplayName}`
        }
      }
      break
    case 'trigger/analytics':
      if (node.properties.analyticsEvent?.eventName) {
        // delete text property which is set by default
        delete node.properties.text
        return `Analytics Event: ${node.properties.analyticsEvent.eventName.replace(/^(customer\/)/, '')}`
      }
  }
  return node.properties.text || ''
}

type SelectedDialogProps = {
  selectedNodeId: string
  chart: Chart
  translations: TranslationFile
  // stateActions: { [key: string]: (...args: any) => Chart }
  onCreateCardCallback: (chart: Chart, cardId: string, callback: () => void) => Promise<void>
  onEditCardCallback: (chart: Chart, cardId: string) => void
  // setChartCallback: (chart: Chart) => void
  onClose: () => void
  onSave: (chart: Chart, nodeIdChanged: string) => void
  onDelete: (nodeId: string) => void
  setTranslationsCallback: (translations: TranslationFile) => void
  setTranslationNeedSavingCallback: (needsSaving: boolean) => void
}

/**
 * THIS IS THE "ROOT" COMPONENT OF A NODE SELECTION.
 * Holds the state of any changes that are done to a node and only sets it to the global state once the save button is pressed.
 * This is done to prevent performance problems caused by otherwise constant re-rendering of the complete chart.<
 */
export default memo(function SelectedDialog({
  selectedNodeId,
  chart: origChart,
  translations,
  // stateActions,
  onCreateCardCallback,
  onEditCardCallback,
  // setChartCallback,
  onClose,
  onSave: onSaveProps,
  onDelete: onDeleteProps,
  setTranslationsCallback,
  setTranslationNeedSavingCallback,
}: SelectedDialogProps): React.ReactElement {
  const { classes } = useStyles()
  const { bot } = useBotContext() as { bot: BotInfos }
  const { canIEditFlowdesigner } = useFlowdesignerContext()

  const [chart, setChart] = useState<Chart>(cloneDeep(origChart))
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(false)
  const [cardOnLoad, setCardOnLoad] = useState<Card>()

  useEffect(() => {
    const cardId = chart.nodes[selectedNodeId].properties.card
    if (cardId) {
      const cardTranslation = translations.ac[translations.primaryLanguage][cardId]
      if (cardTranslation) setCardOnLoad(cloneDeep(cardTranslation))
    }
  }, [])

  // ========== CALLBACKS ============

  /**
   * Callback function for components that need to update this.state
   * @param {Chart} newChart - new chart that should be set
   */
  function onSetState(newChart: Chart): void {
    setChart(newChart)
  }

  /**
   * Handles save button disabling.
   */
  function onSetIsSaveDisabled(disabled: boolean): void {
    if (typeof disabled === 'boolean' && disabled !== isSaveDisabled) setIsSaveDisabled(disabled)
  }

  /**
   * Handles node deletion.
   */
  function onDelete(): void {
    if (!canIEditFlowdesigner) return
    const selectedId = selectedNodeId
    if (selectedId) onDeleteProps(selectedId)
    else onClose()
  }

  /**
   * Handles save.
   * Removes unused datachecks and resets selected property of chart.
   */
  function onSaveChart(chart: Chart): void {
    if (!canIEditFlowdesigner) return
    let newChart = { ...chart }
    const node = chart.nodes[selectedNodeId]
    if (
      node.type === 'basic/adaptiveCard' &&
      node.properties &&
      typeof node.properties.card === 'string' &&
      node.properties.card.length > 0
    ) {
      const cardId = node.properties.card
      // check if ports changed compared to previous version of the node
      // we also check paths to see if order has changed
      const origNode = origChart.nodes[selectedNodeId]
      const cardOnLoadData = cardOnLoad?.data
      const oldActionPaths = typeof cardOnLoadData !== 'undefined' ? jp.paths(cardOnLoadData, '$..actions') : []
      const newActionsPaths = jp.paths(translations.ac[translations.primaryLanguage][cardId].data, '$..actions')
      const oldActions = typeof cardOnLoadData !== 'undefined' ? jp.query(cardOnLoadData, '$..actions') : []
      const newActions = jp.query(translations.ac[translations.primaryLanguage][cardId].data, '$..actions')
      // reset ports if either the actions changed or the ports changed
      if (
        !isEqual(node.ports, origNode.ports) ||
        !isEqual(oldActionPaths, newActionsPaths) ||
        !isEqual(oldActions, newActions)
      ) {
        newChart = setOutgoingPortsForCard(chart, translations.ac[translations.primaryLanguage][cardId].data)
      }
    }
    setChart(newChart)
    onSaveProps(newChart, selectedNodeId)
  }

  /**
   * Handles save.
   * Removes unused datachecks and resets selected property of chart.
   */
  function onSave(): void {
    if (!canIEditFlowdesigner) return
    // get display text for node and set it in chart
    if (chart.nodes[selectedNodeId]) {
      const text = getDisplayTextNode(chart, chart.nodes[selectedNodeId])
      chart.nodes[selectedNodeId].properties.text = text
    }
    onSaveProps(chart, selectedNodeId)
  }

  // ========== USE EFFECT && EVENT LISTENER ==========

  useEffect(
    function () {
      setChart(cloneDeep(origChart))
    },
    [origChart],
  )

  // ========== DIALOG CONTENT (COMPONENTS) ===========

  /**
   * Returns component for display in the dialog.
   */
  function getComponents(): { label: string; component: React.ReactElement }[] {
    if (typeof selectedNodeId === 'undefined') return []
    if (typeof chart.nodes[selectedNodeId] === 'undefined') return []
    const selectedId = selectedNodeId
    const adaptiveCards = translations.ac[bot.primaryLanguage]

    switch (chart.nodes[selectedId].type) {
      case 'start':
        return [
          {
            label: 'Überblick',
            component: <SStart key={selectedId} chart={chart} />,
          },
        ]
      case 'basic/message':
        return [
          {
            label: 'Texte',
            component: <SBMTexts key={selectedId} chart={chart} setStateCallback={onSetState} />,
          },
        ]
      case 'basic/question_button':
        return [
          {
            label: 'Texte',
            component: <SQBMTexts key={selectedId} chart={chart} setStateCallback={onSetState} />,
          },
          {
            label: 'Variablen',
            component: <SQBMVariables key={selectedId} chart={chart} setStateCallback={onSetState} />,
          },
        ]
      case 'basic/question_free':
        return [
          {
            label: 'Texte',
            component: (
              <SQFMTexts
                key={selectedId}
                chart={chart}
                //  classes={classes}
                setStateCallback={onSetState}
              />
            ),
          },
          {
            label: 'Variablen',
            component: <SQFMVariables key={selectedId} chart={chart} setStateCallback={onSetState} />,
          },
          {
            label: 'Data Checks',
            component: <DataCheckTabContent chart={chart} setStateCallback={onSetState} />,
          },
        ]
      case 'basic/api':
        return [
          {
            label: 'Request',
            component: <SAPIRequest key={selectedId} chart={chart} setStateCallback={onSetState} />,
          },
          {
            label: 'Response',
            component: <SAPIResponse key={selectedId} chart={chart} setStateCallback={onSetState} />,
          },
        ]
      case 'basic/picture':
        return [
          {
            label: 'Texte',
            component: <SPTexts key={selectedId} chart={chart} setStateCallback={onSetState} />,
          },
          {
            label: 'Variablen',
            component: <SPVariables key={selectedId} chart={chart} setStateCallback={onSetState} />,
          },
        ]
      case 'basic/fileUpload':
        return [
          {
            label: 'Datei Upload',
            component: (
              <SFileUpload
                key={selectedId}
                chart={chart}
                setStateCallback={onSetState}
                setIsSaveDisabled={onSetIsSaveDisabled}
              />
            ),
          },
        ]
      // case 'basic/pdf':
      //   return [
      //     {
      //       label: 'Texte',
      //       component: <SPdf key={selectedId} chart={chart} setStateCallback={onSetState} />,
      //     },
      //   ]
      case 'logic/ifElse':
        return [
          {
            label: 'If-Else Konfiguration',
            component: <SIfElse key={selectedId} chart={chart} setStateCallback={onSetState} />,
          },
        ]
      case 'logic/switch':
        return [
          {
            label: 'Switch Konfiguration',
            component: (
              <SSwitchOld
                key={selectedId}
                chart={chart}
                setStateCallback={onSetState}
                setIsSaveDisabledCallback={onSetIsSaveDisabled}
              />
            ),
          },
        ]
      case 'logic/switchCondition':
        return [
          {
            label: 'Switch Konfiguration',
            component: (
              <SSwitch
                key={selectedId}
                chart={chart}
                setStateCallback={onSetState}
                setIsSaveDisabledCallback={onSetIsSaveDisabled}
              />
            ),
          },
        ]
      case 'logic/jump':
        return [
          {
            label: 'Konfiguration',
            component: (
              <SJump
                key={selectedId}
                chart={chart}
                translationFile={translations}
                setSaveDisabled={onSetIsSaveDisabled}
                setStateCallback={onSetState}
              />
            ),
          },
        ]
      case 'logic/startDialog':
        return [
          {
            label: 'Dialog',
            component: <SStartDialog key={selectedId} chart={chart} setStateCallback={onSetState} />,
          },
        ]
      case 'logic/setVar':
        return [
          {
            label: 'Konfiguration',
            component: <SSetVariable key={selectedId} chart={chart} setStateCallback={onSetState} />,
          },
        ]
      case 'logic/setVariables':
        return [
          {
            label: 'Konfiguration',
            component: (
              <SSetVariables
                key={selectedId}
                chart={chart}
                setStateCallback={onSetState}
                setIsSaveDisabled={onSetIsSaveDisabled}
              />
            ),
          },
        ]
      case 'trigger/intent':
        return [
          {
            label: 'Trigger Konfiguration',
            component: <STrigger key={selectedId} chart={chart} setStateCallback={onSetState} />,
          },
        ]
      case 'trigger/event':
        return [
          {
            label: 'Trigger Konfiguration',
            component: (
              <SEventTrigger
                key={selectedId}
                chart={chart}
                setStateCallback={onSetState}
                setIsSaveDisabledCallback={onSetIsSaveDisabled}
              />
            ),
          },
        ]
      case 'trigger/qna':
        return [
          {
            label: 'Info',
            component: <SQnATrigger key={selectedId} chart={chart} setStateCallback={onSetState} />,
          },
        ]
      case 'logic/qna_answer':
        return [
          {
            label: 'Konfiguration',
            component: <SQnAAnswer key={selectedId} chart={chart} setStateCallback={onSetState} />,
          },
        ]
      case 'basic/note':
        return [
          {
            label: 'Konfiguration',
            component: <SNote key={selectedId} chart={chart} setStateCallback={onSetState} />,
          },
        ]
      default:
        return []
    }
  }

  /**
   * Gets "display name" of node based on typeText
   * @param {string} nodeType
   */
  function getNodeNames(nodeType: string): string {
    switch (nodeType) {
      case 'start':
        return 'Start Node'
      case 'basic/message':
        return 'Textnachricht'
      case 'basic/question_button':
        return 'Frage (mit Buttons)'
      case 'basic/question_free':
        return 'Frage (mit Text)'
      case 'basic/adaptiveCard':
        return 'Smart Card'
      case 'basic/api':
        return 'API'
      case 'basic/picture':
        return 'Bild'
      case 'basic/fileUpload':
        return 'Datei Upload'
      case 'basic/pdf':
        return 'PDF Dokument'
      case 'logic/ifElse':
        return 'Entscheidung'
      case 'logic/switch':
        return 'Fallunterscheidung'
      case 'logic/jump':
        return 'Sprung'
      case 'logic/startDialog':
        return 'Dialogstart'
      case 'logic/setVar':
        return 'Variable setzen'
      case 'trigger/event':
        return 'Event Trigger'
      case 'trigger/intent':
        return 'Intent Dialog Trigger'
      case 'trigger/qna':
        return 'QnA Modus Trigger'
      case 'logic/qna_answer':
        return 'Frage beantworten Trigger'
      case 'trigger/analytics':
        return 'Analytics Trigger'
      case 'basic/note':
        return 'Notiz'
      case 'module/qna-getAnswer':
        return 'QnA Modul: Antwort finden'
      case 'module/qna-startTriggerDialog':
        return 'QnA Modul: Trigger Dialog starten'
      case 'module/qna-trackHelpful':
        return 'QnA Modul: Antwort bewerten'
      case 'module/xzufi-getAnswer':
        return 'XZuFi Leistung / Antwort suchen'
      case 'module/aleph-alpha-getAnswer':
        return 'Aleph Alpha Modul: Frage beantworten'
      case 'module/aleph-alpha-trackHelpful':
        return 'Aleph Alpha Module: Antwort bewerten'
      default:
        return ''
    }
  }

  const tabComponents = getComponents()
  const contents: React.ReactElement[] = []
  const labels: string[] = []
  tabComponents.forEach((tabContent: { label: string; component: React.ReactElement }) => {
    contents.push(tabContent.component)
    labels.push(tabContent.label)
  })

  const DISABLE_SAVE_BUTTON = isSaveDisabled || !canIEditFlowdesigner

  return typeof selectedNodeId !== 'undefined' && typeof chart.nodes[selectedNodeId] !== 'undefined' ? (
    chart.nodes[selectedNodeId].type === 'basic/adaptiveCard' ? (
      <NodeDialog
        id='selected-node-dialog'
        dialogTitle={`Block: ${getNodeNames(chart.nodes[selectedNodeId].type)}`}
        nodeId={selectedNodeId}
        handleClose={onClose}
        closable
        content={
          <SelectedSmartCard
            chart={chart}
            nodeId={selectedNodeId}
            translations={translations}
            setTranslationsCallback={setTranslationsCallback}
            setTranslationNeedSavingCallback={setTranslationNeedSavingCallback}
            onDelete={onDelete}
            cardIdOnLoad={cardOnLoad?.id}
            onSaveChart={onSaveChart}
          />
        }
        isSecondaryActionDisabled={!canIEditFlowdesigner}
      />
    ) : chart.nodes[selectedNodeId].type === 'trigger/analytics' ? (
      <NodeDialog
        id='selected-node-dialog'
        dialogTitle={`Block: ${getNodeNames(chart.nodes[selectedNodeId].type)}`}
        nodeId={selectedNodeId}
        handleClose={onClose}
        closable
        content={
          <SAnalyticsTrigger chart={chart} setStateCallback={onSetState} setIsSaveDisabled={setIsSaveDisabled} />
        }
        primaryActionButton={
          <Button onClick={onSave} size='small' disabled={DISABLE_SAVE_BUTTON} type='success'>
            Speichern
          </Button>
        }
        secondaryActionText='Löschen'
        isSecondaryActionDisabled={!canIEditFlowdesigner}
        onSecondaryActionClick={onDelete}
      />
    ) : chart.nodes[selectedNodeId].type === 'module/xzufi-getAnswer' ? (
      <NodeDialog
        id='selected-node-dialog'
        dialogTitle={`Block: ${getNodeNames(chart.nodes[selectedNodeId].type)}`}
        nodeId={selectedNodeId}
        handleClose={onClose}
        closable
        content={
          <SXzufiGetAnswerOld chart={chart} setStateCallback={onSetState} setIsSaveDisabled={setIsSaveDisabled} />
        }
        primaryActionButton={
          <Button onClick={onSave} size='small' disabled={DISABLE_SAVE_BUTTON} type='success'>
            Speichern
          </Button>
        }
        secondaryActionText='Löschen'
        isSecondaryActionDisabled={!canIEditFlowdesigner}
        onSecondaryActionClick={onDelete}
      />
    ) : chart.nodes[selectedNodeId].type === 'module/xzufi-getAnswerNew' ? (
      <NodeDialog
        id='selected-node-dialog'
        dialogTitle={`Block: ${getNodeNames(chart.nodes[selectedNodeId].type)}`}
        nodeId={selectedNodeId}
        handleClose={onClose}
        closable
        content={<SXzufiGetAnswer chart={chart} setStateCallback={onSetState} setIsSaveDisabled={setIsSaveDisabled} />}
        primaryActionButton={
          <Button onClick={onSave} size='small' disabled={DISABLE_SAVE_BUTTON} type='success'>
            Speichern
          </Button>
        }
        secondaryActionText='Löschen'
        isSecondaryActionDisabled={!canIEditFlowdesigner}
        onSecondaryActionClick={onDelete}
      />
    ) : chart.nodes[selectedNodeId].type === 'module/xzufi-trackSelectedAnswer' ? (
      <NodeDialog
        id='selected-node-dialog'
        dialogTitle={`Block: ${getNodeNames(chart.nodes[selectedNodeId].type)}`}
        nodeId={selectedNodeId}
        handleClose={onClose}
        closable
        content={
          <SXzufiTrackSelectedAnswer
            chart={chart}
            setStateCallback={onSetState}
            setIsSaveDisabled={setIsSaveDisabled}
          />
        }
        primaryActionButton={
          <Button onClick={onSave} size='small' disabled={DISABLE_SAVE_BUTTON} type='success'>
            Speichern
          </Button>
        }
        secondaryActionText='Löschen'
        isSecondaryActionDisabled={!canIEditFlowdesigner}
        onSecondaryActionClick={onDelete}
      />
    ) : chart.nodes[selectedNodeId].type === 'module/xzufi-trackHelpful' ? (
      <NodeDialog
        id='selected-node-dialog'
        dialogTitle={`Block: ${getNodeNames(chart.nodes[selectedNodeId].type)}`}
        nodeId={selectedNodeId}
        handleClose={onClose}
        closable
        content={
          <SXzufiTrackHelpful chart={chart} setStateCallback={onSetState} setIsSaveDisabled={setIsSaveDisabled} />
        }
        primaryActionButton={
          <Button onClick={onSave} size='small' disabled={DISABLE_SAVE_BUTTON} type='success'>
            Speichern
          </Button>
        }
        secondaryActionText='Löschen'
        isSecondaryActionDisabled={!canIEditFlowdesigner}
        onSecondaryActionClick={onDelete}
      />
    ) : chart.nodes[selectedNodeId].type === 'module/qna-getAnswer' ? (
      <NodeDialog
        id='selected-node-dialog'
        dialogTitle={`Block: ${getNodeNames(chart.nodes[selectedNodeId].type)}`}
        nodeId={selectedNodeId}
        handleClose={onClose}
        closable
        content={<SQnAGetAnswer chart={chart} setStateCallback={onSetState} setIsSaveDisabled={setIsSaveDisabled} />}
        primaryActionButton={
          <Button onClick={onSave} size='small' disabled={DISABLE_SAVE_BUTTON} type='success'>
            Speichern
          </Button>
        }
        secondaryActionText='Löschen'
        isSecondaryActionDisabled={!canIEditFlowdesigner}
        onSecondaryActionClick={onDelete}
      />
    ) : chart.nodes[selectedNodeId].type === 'module/qna-startTriggerDialog' ? (
      <NodeDialog
        id='selected-node-dialog'
        dialogTitle={`Block: ${getNodeNames(chart.nodes[selectedNodeId].type)}`}
        nodeId={selectedNodeId}
        handleClose={onClose}
        closable
        content={
          <SQnAStartTriggerDialog chart={chart} setStateCallback={onSetState} setIsSaveDisabled={setIsSaveDisabled} />
        }
        primaryActionButton={
          <Button onClick={onSave} size='small' disabled={DISABLE_SAVE_BUTTON} type='success'>
            Speichern
          </Button>
        }
        secondaryActionText='Löschen'
        isSecondaryActionDisabled={!canIEditFlowdesigner}
        onSecondaryActionClick={onDelete}
      />
    ) : chart.nodes[selectedNodeId].type === 'module/qna-trackHelpful' ? (
      <NodeDialog
        id='selected-node-dialog'
        dialogTitle={`Block: ${getNodeNames(chart.nodes[selectedNodeId].type)}`}
        nodeId={selectedNodeId}
        handleClose={onClose}
        closable
        content={<SQnATrackHelpful chart={chart} setStateCallback={onSetState} setIsSaveDisabled={setIsSaveDisabled} />}
        primaryActionButton={
          <Button onClick={onSave} size='small' disabled={DISABLE_SAVE_BUTTON} type='success'>
            Speichern
          </Button>
        }
        secondaryActionText='Löschen'
        isSecondaryActionDisabled={!canIEditFlowdesigner}
        onSecondaryActionClick={onDelete}
      />
    ) : chart.nodes[selectedNodeId].type === 'module/aleph-alpha-getAnswer' ? (
      <NodeDialog
        id='selected-node-dialog'
        dialogTitle={`Block: ${getNodeNames(chart.nodes[selectedNodeId].type)}`}
        nodeId={selectedNodeId}
        handleClose={onClose}
        closable
        content={
          <SAlephAlphaGetAnswer chart={chart} setStateCallback={onSetState} setIsSaveDisabled={setIsSaveDisabled} />
        }
        primaryActionButton={
          <Button onClick={onSave} size='small' disabled={DISABLE_SAVE_BUTTON} type='success'>
            Speichern
          </Button>
        }
        secondaryActionText='Löschen'
        isSecondaryActionDisabled={!canIEditFlowdesigner}
        onSecondaryActionClick={onDelete}
      />
    ) : chart.nodes[selectedNodeId].type === 'module/aleph-alpha-trackHelpful' ? (
      <NodeDialog
        id='selected-node-dialog'
        dialogTitle={`Block: ${getNodeNames(chart.nodes[selectedNodeId].type)}`}
        nodeId={selectedNodeId}
        handleClose={onClose}
        closable
        content={
          <SAlephAlphaTrackHelpful chart={chart} setStateCallback={onSetState} setIsSaveDisabled={setIsSaveDisabled} />
        }
        primaryActionButton={
          <Button onClick={onSave} size='small' disabled={DISABLE_SAVE_BUTTON} type='success'>
            Speichern
          </Button>
        }
        secondaryActionText='Löschen'
        isSecondaryActionDisabled={!canIEditFlowdesigner}
        onSecondaryActionClick={onDelete}
      />
    ) : chart.nodes[selectedNodeId].type === 'module/llm-task' ? (
      <NodeDialog
        id='selected-node-dialog'
        dialogTitle={`Block: ${getNodeNames(chart.nodes[selectedNodeId].type)}`}
        nodeId={selectedNodeId}
        handleClose={onClose}
        closable
        content={<SLLMTask chart={chart} setStateCallback={onSetState} setIsSaveDisabled={setIsSaveDisabled} />}
        primaryActionButton={
          <Button onClick={onSave} size='small' disabled={DISABLE_SAVE_BUTTON} type='success'>
            Speichern
          </Button>
        }
        secondaryActionText='Löschen'
        isSecondaryActionDisabled={!canIEditFlowdesigner}
        onSecondaryActionClick={onDelete}
      />
    ) : chart.nodes[selectedNodeId].type === 'basic/pdf' ? (
      <NodeDialog
        id='selected-node-dialog'
        dialogTitle={`Block: ${getNodeNames(chart.nodes[selectedNodeId].type)}`}
        nodeId={selectedNodeId}
        handleClose={onClose}
        closable
        content={<SPdf chart={chart} setStateCallback={onSetState} setIsSaveDisabled={setIsSaveDisabled} />}
        primaryActionButton={
          <Button onClick={onSave} size='small' disabled={DISABLE_SAVE_BUTTON} type='success'>
            Speichern
          </Button>
        }
        secondaryActionText='Löschen'
        isSecondaryActionDisabled={!canIEditFlowdesigner}
        onSecondaryActionClick={onDelete}
      />
    ) : (
      <TabDialog
        id='selected-node-dialog'
        dialogTitle={`Block: ${getNodeNames(chart.nodes[selectedNodeId].type)}`}
        nodeId={selectedNodeId}
        tabLabels={labels}
        tabContents={contents}
        handleClose={onClose}
        closable
        primaryActionButton={
          chart.nodes[selectedNodeId].type !== 'start' ? (
            <Button onClick={onSave} size='small' disabled={DISABLE_SAVE_BUTTON} type='success'>
              Speichern
            </Button>
          ) : undefined
        }
        secondaryActionText='Löschen'
        onSecondaryActionClick={onDelete}
        isSecondaryActionDisabled={
          !canIEditFlowdesigner ||
          (typeof selectedNodeId !== 'undefined' && chart.nodes[selectedNodeId].type === 'start')
        }
      />
    )
  ) : (
    <div />
  )
}, isEqual)
