import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import Fuse from 'fuse.js'

import { useRAGContext } from 'hooks/contexts/rag-context'
import {
  APP_TITLE,
  ROUTE_BOTID_KNOWLEDGE_RAG_ADD_FILE,
  ROUTE_BOTID_KNOWLEDGE_RAG_ADD_TXT_DOCUMENT,
  ROUTE_BOTID_KNOWLEDGE_RAG_ADD_WEBSITE,
} from 'utils/constants'
import ContentPage, { ContentPageHeader } from 'components/Page/ContentPage'
import BaseCard from 'components/Cards/BaseCard'
import CircularLoading from 'components/Loading/CircularLoading'
import Table from 'components/Table/Table'
import getKnowledgeRagDocumentRow from './KnowledgeRAGRow'
import { makeStyles } from 'tss-react/mui'
import { useNavigate, useResolvedPath } from 'react-router-dom'
import { Button } from 'components/Buttons'
import { Abc, Add } from '@mui/icons-material'
import { Dialog } from 'components/Dialogs'
import { Grid, Typography, useTheme } from '@mui/material'
import IconCard from 'components/Cards/IconCard'
import { useLockingContext } from 'hooks/contexts/locking-context'
import StringIcon from 'assets/img/fileIcons/string.svg'
import FilesIcon from 'assets/img/fileIcons/files.svg'
import WebsiteIcon from 'assets/img/fileIcons/html.svg'
import { RAGCurrentlyIngestingDocument, RAGDocument } from '../../../../@types/Knowledge/RAG/types'
import { Searchfield } from 'components/TextInput/Searchfield'

const useStyles = makeStyles()((theme, props, classes) => {
  return {
    deleteCardButton: { marginLeft: 'auto' },
  }
})

export default function KnowledgeRAGOverview(): React.ReactElement {
  const { classes } = useStyles()
  const theme = useTheme()
  const navigate = useNavigate()
  const url = useResolvedPath('').pathname
  const { lockState } = useLockingContext()
  const {
    documents,
    documentsCurrentlyImporting,
    documentsWithImportError,
    deleteDocument,
    updateStringDocument: updateTextDocument,
    loading,
  } = useRAGContext()
  const [searchString, setSearchString] = useState<string>('')
  const [displayedDocuments, setDisplayedDocuments] = useState<(RAGDocument | RAGCurrentlyIngestingDocument)[]>(
    Object.values(documents),
  )
  const [showAddDialog, setShowAddDialog] = useState<boolean>(false)
  const [documentToDelete, setDocumentToDelete] = useState<string>()

  function onDeleteDocument(documentId: string): void {
    setDocumentToDelete(documentId)
  }

  function onAddDocument(type: 'txt' | 'files' | 'website'): void {
    switch (type) {
      case 'files':
        navigate(url + ROUTE_BOTID_KNOWLEDGE_RAG_ADD_FILE)
        break
      case 'website':
        navigate(url + ROUTE_BOTID_KNOWLEDGE_RAG_ADD_WEBSITE)
        break
      case 'txt':
      default:
        navigate(url + ROUTE_BOTID_KNOWLEDGE_RAG_ADD_TXT_DOCUMENT)
    }
  }

  function onEditDocument(documentId: string): void {
    navigate(url + `/${documentId}`)
  }

  async function onDeleteConfirm(): Promise<void> {
    if (!documentToDelete) return
    await deleteDocument(documentToDelete)
    setDocumentToDelete(undefined)
  }

  /**
   * Searches answers for search string and returns result.
   */
  function filterDocuments(
    documents: (RAGDocument | RAGCurrentlyIngestingDocument)[],
  ): (RAGDocument | RAGCurrentlyIngestingDocument)[] {
    if (!searchString) return documents
    const fuseOptions = {
      shouldSort: true,
      threshold: 0.4,
      minMatchCharLength: 1,
      keys: ['documentId', 'type', 'title', 'sourceUrl', 'xzufi.xzufi_portal_url', 'website.website_url'],
    }
    const fuse = new Fuse(documents ?? [], fuseOptions)
    return fuse.search(searchString).map((result) => result.item)
  }

  useEffect(() => {
    const searchResults = filterDocuments(Object.values(documents))
    setDisplayedDocuments(searchResults)
  }, [searchString, documents])

  return (
    <>
      <Helmet>
        <title>{APP_TITLE} - Wissensdatenbank</title>
      </Helmet>
      <ContentPage>
        <ContentPageHeader
          title={'Wissensdatenbank'}
          actions={[
            <Searchfield
              key='searchfield'
              value={searchString}
              placeholder='Suchen'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchString(e.target.value)}
              autoFocus
            />,
            <Button
              key='add-button'
              onClick={() => setShowAddDialog(true)}
              icon={<Add />}
              disabled={lockState !== 'canEdit'}
            >
              Neuen Inhalt hinzufügen
            </Button>,
          ]}
        />
        <BaseCard width={'100%'} height={'100%'} minHeight={'80vh'}>
          {/* <div style={{ width: '100%', height: '100%', textAlign: 'center', display: 'flex', alignItems: 'center' }}>
            <Typography style={{ margin: 'auto' }}>
              Wissensmanagement steht vorübergehend nicht zur Verfügung.
            </Typography>
          </div> */}
          {loading === 'loading' ? (
            <CircularLoading text='Inhalte werden geladen...' />
          ) : (
            <>
              {documentsCurrentlyImporting.length > 0 && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    padding: '16px',
                    backgroundColor: theme.palette.warning.main,
                    borderRadius: theme.shape.borderRadius * 2,
                  }}
                >
                  <i className='ri-error-warning-line' style={{ marginRight: '8px' }} />
                  <Typography>Aktuell werden {documentsCurrentlyImporting.length} Inhalte importiert.</Typography>
                </div>
              )}
              {documentsWithImportError.length > 0 && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    padding: '16px',
                    backgroundColor: theme.palette.error.main,
                    color: '#ffffff',
                    borderRadius: theme.shape.borderRadius * 2,
                  }}
                >
                  <i className='ri-error-warning-line' style={{ color: 'white', marginRight: '8px' }} />
                  <Typography>{documentsWithImportError.length} Importe fehlgeschlagen!</Typography>
                </div>
              )}
              <Table
                headers={['Status', 'Typ', 'Titel', '', 'Zuletzt geändert', 'Aktionen']}
                cellAlignPattern={['center', 'left', 'left', 'left', 'left', 'right']}
                width={['5%', '5%', '55%', '5%', '15%', '15%']}
                padding='medium'
                searchString={searchString}
                rowsPerPage={25}
                rows={displayedDocuments.map((doc, index) => {
                  return getKnowledgeRagDocumentRow({
                    classes,
                    index: index,
                    document: doc,
                    errorMsg: undefined,
                    onDelete: onDeleteDocument,
                    onEdit: onEditDocument,
                    disableInteraction:
                      lockState === 'isBlocked' ||
                      loading === 'deleting' ||
                      (doc.type === 'ingest-job' && doc.status === 'running') ||
                      !!(doc.type !== 'ingest-job' && doc.updateStatus === 'running'),
                  })
                })}
              />
            </>
          )}
        </BaseCard>
        {/* Add document dialog */}
        {showAddDialog && (
          <Dialog
            id='discard-changes-dialog'
            size='medium-large'
            open={showAddDialog}
            closable
            onClose={(): void => setShowAddDialog(false)}
            title='Inhalt hinzufügen'
          >
            <Typography>Welche Art von Inhalt möchten Sie hinzufügen?</Typography>
            <Grid
              container
              justifyContent='center'
              spacing={2}
              style={{ marginTop: theme.spacing(2), padding: theme.spacing(2) }}
            >
              <Grid item xs={12} md={6}>
                <IconCard
                  footerText={'Datei'}
                  iconSize={100}
                  icon={<img src={FilesIcon} style={{ height: '100%', width: '100%' }} />}
                  onClick={() => onAddDocument('files')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <IconCard
                  footerText={'Webseite'}
                  iconSize={100}
                  icon={<img src={WebsiteIcon} style={{ height: '100%', width: '100%' }} />}
                  onClick={() => onAddDocument('website')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <IconCard
                  footerText={'Text'}
                  icon={<img src={StringIcon} style={{ height: '100%', width: '100%' }} />}
                  iconSize={80}
                  onClick={() => onAddDocument('txt')}
                />
              </Grid>
            </Grid>
          </Dialog>
        )}
        {documentToDelete && (
          <Dialog
            id='delete-document-dialog'
            size='small'
            open={!!documentToDelete}
            closable
            onClose={(): void => setDocumentToDelete(undefined)}
            title='Inhalt löschen'
            primaryActionButton={
              <Button onClick={() => onDeleteConfirm()} disabled={loading === 'deleting'} type='danger'>
                Löschen
              </Button>
            }
            secondaryActionText='Abbrechen'
            onSecondaryActionClick={(): void => setDocumentToDelete(undefined)}
          >
            {loading === 'deleting' ? (
              <CircularLoading text='Inhalt wird gelöscht...' />
            ) : (
              <Typography>
                Möchten Sie den Inhalt mit dem Titel <em>{documents[documentToDelete].title}</em> wirklich löschen?
              </Typography>
            )}
          </Dialog>
        )}
      </ContentPage>
    </>
  )
}
