import React from 'react'
import BaseCard from 'components/Cards/BaseCard'
import CustomizedTooltip from 'components/Tooltips/CustomContentTooltip'
import { makeStyles } from 'tss-react/mui'
import { Chip, Typography } from '@mui/material'
import ReactMarkdown from 'react-markdown'
import { Answer } from 'classes/Knowledge'

const useStyles = makeStyles()((theme) => ({
  markdown: {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical',
    '& p': {
      margin: 0,
    },
    fontSize: '12px',
  },
  answerText: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '120px',
      wordBreak: 'break-word',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '300px',
      wordBreak: 'break-word',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '500px',
      wordBreak: 'break-word',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '800px',
      wordBreak: 'break-word',
    },
    height: '60px',
  },
  answerTitles: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'auto',
    maxHeight: '120px',
  },
  chip: { marginRight: theme.spacing(1), marginBottom: theme.spacing(1), overflow: 'auto' },
  hover: {
    transition: 'box-shadow 0.3s',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0 7px 13px -6px rgb(0 0 0 / 42%), 0 2px 11px 0px rgb(0 0 0 / 12%), 0 4px 5px -3px rgb(0 0 0 / 20%)',
    },
  },
}))

type TopicCardProps = {
  topicPath: string // topic path (part before last '/' of topic property)
  topic: string // topic (part after last '/' of topic property)
  answersOfTopic: Answer[]
  onClick: (topic: string) => void
}

export default function TopicCard({
  topicPath = '',
  topic,
  answersOfTopic,
  onClick,
}: TopicCardProps): React.ReactElement {
  const { classes } = useStyles()

  return (
    <BaseCard
      title={
        <div>
          <Typography variant='caption'>{topicPath}/</Typography>
          <Typography variant='h4'>{topic}</Typography>
        </div>
      }
      height={'233px'}
      width={'390px'}
      paddingTop='12px'
      paddingSide='16px'
      paddingBottom='12px'
      onClick={(): void => onClick(topicPath !== '' ? topicPath + '/' : '' + topic)}
      shadowWidth={10}
      shadowBlur={15}
      className={classes.hover}
    >
      <>
        <Typography variant='body1'>Antworten</Typography>
        <div className={classes.answerTitles}>
          {answersOfTopic.map((answer, index) => (
            <div key={`answer-${index}`}>
              <CustomizedTooltip
                disableInteractive
                content={<ReactMarkdown className={classes.markdown}>{answer.answer}</ReactMarkdown>}
                elements={<Chip className={classes.chip} label={answer.title || answer.answer} />}
              />
            </div>
          ))}
        </div>
      </>
    </BaseCard>
  )
}
