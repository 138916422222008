import React, { useState, useEffect } from 'react'
import { makeStyles } from 'tss-react/mui'

// @mui/material components
import { Grid } from '@mui/material'

import { SelectDropdown, Option, ActionMeta } from 'components/Dropdown'
import { Searchfield } from 'components/TextInput/Searchfield'

import { Chart, Node } from '../../../../../@types/Flowchart/types'
import NodeSearchResult from 'pages/FlowDesigner/NodeSearch/NodeSearchResult'
import { useNodeSearch } from 'hooks/search/useNodeSearch'
import { TranslationFile } from '../../../../../@types/Translations/types'

const useStyles = makeStyles()((theme) => ({
  selectedDialogContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  title: {},
  searchResultContainer: {
    marginTop: theme.spacing(2),
  },
}))

type SJumpNodeSearchProps = {
  chart: Chart
  translationFile: TranslationFile
  onNodeSelection: (nodeId: string) => void
}

export default function SJumpNodeSearch({
  chart,
  translationFile,
  onNodeSelection,
}: SJumpNodeSearchProps): React.ReactElement {
  const { classes } = useStyles()
  const [options, setOptions] = useState<Option[]>([])
  const [selectedDialog, setSelectedDialog] = useState<Option>()
  const [allNodes, setAllNodes] = useState<Node[]>([])
  const [displayedNodes, setDisplayedNodes] = useState<Node[]>([])
  const [selectedNodeId, setSelectedNodeId] = useState<string>()
  const [searchString, _setSearchString] = useState<string>()
  const { hits: searchResults, onSearch: _updateSearchString } = useNodeSearch(displayedNodes)

  function setSearchString(text: string): void {
    _setSearchString(text)
    _updateSearchString(text)
  }

  /**
   * Handles dropdown change.
   * @param newValue
   * @param action
   */
  function onDialogDropdownChange(newValue: Option, action: ActionMeta<Option>): void {
    setSelectedDialog(newValue)
    if (newValue === null) {
      // clear
      setSearchString('')
    }
  }

  function onSearchStringChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const newSearchString = event.target.value
    setSearchString(newSearchString)
  }

  function onNodeClick(nodeId: string): void {
    setSelectedNodeId(nodeId)
    onNodeSelection(nodeId)
  }

  useEffect(
    function () {
      if (!chart.selected?.id) return

      // prepare dropdown actions
      const dialogs = Object.values(chart.dialogs)
      const options: Option[] = []
      for (const dialog of dialogs) {
        options.push({
          value: dialog.id,
          label: dialog.name,
          sublabel: dialog.description || undefined,
        })
      }
      setOptions(options)
      const nodes = Object.values(chart.nodes).filter((node) => node.type !== 'basic/note') // filter note nodes
      setAllNodes(nodes)

      // if a node is already configured, set it
      const targetNodeId = chart.nodes[chart.selected.id].properties.targetNode
      if (targetNodeId) {
        const dialogOfTargetNode = chart.nodes[targetNodeId].properties.dialog
        const selectedDialogOption = options.find((option) => option.value === dialogOfTargetNode)
        setSelectedDialog(selectedDialogOption)
        setSearchString(targetNodeId)
      }
    },
    [chart],
  )

  useEffect(
    function () {
      const dialogId = selectedDialog?.value
      if (!dialogId) setDisplayedNodes([])
      else {
        const dialogNodes = chart.dialogs[dialogId].nodes
        const nodesOfDialog = allNodes.filter((node) => dialogNodes.includes(node.id))
        setDisplayedNodes(nodesOfDialog)
      }
    },
    [selectedDialog],
  )

  return (
    <div className={classes.selectedDialogContainer}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <SelectDropdown
            onChange={onDialogDropdownChange}
            options={options}
            selected={selectedDialog}
            placeholder='Dialog auswählen'
            isSearchable
            isClearable
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {selectedDialog && (
            <Searchfield key='search-field' autoFocus value={searchString} onChange={onSearchStringChange} fullWidth />
          )}
        </Grid>
      </Grid>
      {selectedDialog && (
        <div className={classes.searchResultContainer} style={{ overflow: 'auto' }}>
          <NodeSearchResult
            chart={chart}
            translationFile={translationFile}
            nodes={searchResults}
            onNodeClick={onNodeClick}
            selectedNodeId={selectedNodeId}
          />
        </div>
      )}
    </div>
  )
}
