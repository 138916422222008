/*********************************************************************************************
 * THIS CONTEXT HANDLES OUR CUSTOM NOTIFICATION FEATURE (NOT THE INBUILT NOTIFICATIONS FOR STUDIO USER,
 * BUT RATHER NOTIFICATIONS THAT ARE SHOWN TO THE USERS OF THE ASSISTANTS DURING SPECIFIC TIMES)
 **********************************************************************************************/
import React, { createContext, useContext, useReducer, useCallback, useEffect } from 'react'
import { cloneDeep, isEmpty, isEqual, omit } from 'lodash'
import { v4 as uuid } from 'uuid'
import {
  getCustomNotifications as getCustomNotificationsAPI,
  saveCustomNotifications as saveCustomNotificationsAPI,
  deleteCustomNotifications as deleteCustomNotificationsAPI,
  checkOverlapOfCustomNotification as checkOverlapOfCustomNotificationAPI,
} from 'api/StudioBackend'
import {
  CustomNotification,
  CustomNotifications,
  OverlappingCustomNotifications,
} from '../../@types/CustomNotifications/types'
import { useBotContext } from './bot-context'
import { duplicateNotification, isNotificationExpired } from 'utils/notificationUtils'
import { SetNotificationType, useStudioNotificationContext } from './studio-notification-context'

// ----- TYPE DEFINITIONS -----

// Maps Actions to Payloads - Standard does not need customization
type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key
      }
    : {
        type: Key
        payload: M[Key]
      }
}

type LoadingState = 'loading' | 'saving' | 'deleting' | 'checkingOverlap' | undefined

export enum NotificationActivatingStatus {
  Activating = 'activating',
  Deactivating = 'deactivating',
}
type NotificationActivatingState = {
  status: NotificationActivatingStatus // state of notification activation
  notificationIds: string[] // ids of notifications that are being activated or deactivated
} | null

type SnackbarNotification = {
  type: 'success' | 'error'
  message: string
} | null

// Possible Actions
enum Types {
  // Setters for state
  SetNotification = 'SET_NOTIFICATION', // single notification
  SetNotifications = 'SET_NOTIFICATIONS', // multiple notifications
  DeleteNotifications = 'DELETE_NOTIFICATIONS',
  ActivateNotification = 'SET_NOTIFICATION_ACTIVE',
  DeactivateNotification = 'SET_NOTIFICATION_INACTIVE',
  ActivateAllNotifications = 'SET_ALL_NOTIFICATIONS_ACTIVE',
  DeactivateAllNotifications = 'SET_ALL_NOTIFICATIONS_INACTIVE',
  DuplicateNotification = 'DUPLICATE_NOTIFICATION',
  LoadNotifications = 'LOAD_NOTIFICATIONS',
  SetLoading = 'SET_LOADING',
  SetNotificationActivatingState = 'SET_NOTIFICATION_ACTIVATING_STATE',
  SetOverlappingNotifications = 'SET_OVERLAPPING',
  ResetSingleNotification = 'RESET_SINGLE_NOTIFICATION',
  ResetState = 'RESET_STATE',
}

// Payloads for each action
type NotificationContextPayload = {
  // Setters
  [Types.SetNotification]: {
    notification: CustomNotification
  }
  [Types.SetNotifications]: {
    notifications: CustomNotifications
    origNotifications?: CustomNotifications
  }
  [Types.DeleteNotifications]: {
    notificationIds: string[]
  }
  [Types.ActivateNotification]: {
    notificationId: string
  }
  [Types.DeactivateNotification]: {
    notificationId: string
  }
  [Types.DeactivateAllNotifications]: {}
  [Types.ActivateAllNotifications]: {}
  [Types.DuplicateNotification]: {
    notificationId: string
  }
  [Types.SetLoading]: {
    loadingState: LoadingState
  }
  [Types.SetNotificationActivatingState]: {
    status: NotificationActivatingStatus
    notificationIds: string[]
  } | null
  [Types.SetOverlappingNotifications]: {
    overlappingNotifications: OverlappingCustomNotifications
  }
  [Types.ResetSingleNotification]: {
    notificationId: string
  }
  [Types.ResetState]: {}
}

type Action = ActionMap<NotificationContextPayload>[keyof ActionMap<NotificationContextPayload>]
type State = {
  notifications: CustomNotifications | null
  loadingState?: LoadingState
  notificationActivatingState: NotificationActivatingState | null
  snackbarNotification: SnackbarNotification | null
  overlappingNotifications: OverlappingCustomNotifications | null
  origNotifications?: CustomNotifications // to compare for changes, is not returned by the hook
}

type Dispatch = (action: Action) => void
type CustomNotificationProviderProps = { children: React.ReactNode }

/**
 * ----- CONTEXT -----
 * CustomNotificationContext Context is a local context used for storing everything needed to manage custom notifications.
 *
 * To access this context:
 * import { useCustomNotificationsContext } from '<path>/hooks/contexts/customnotifications-context.tsx'
 *
 * // in a function
 * const {notifications, deleteNotification, setNotificationActive, setNotifications, saveNotifications} = useCustomNotificationsContext()
 */
const CustomNotificationsContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined)

// TODO: maybe add something like an init action

function notificationsReducer(state: State, action: Action): State {
  switch (action.type) {
    // SETTERS
    case Types.SetNotification: {
      // make sure to spread that state just in case!
      if (action.payload.notification) {
        const notification = action.payload.notification
        return { ...state, notifications: { ...state.notifications, [notification.notificationId]: notification } }
      }
      return { ...state }
    }
    case Types.SetNotifications: {
      // make sure to spread that state just in case!
      if (action.payload.notifications) {
        return {
          ...state,
          notifications: { ...state.notifications, ...action.payload.notifications },
          origNotifications: action.payload.origNotifications
            ? { ...state.origNotifications, ...cloneDeep(action.payload.origNotifications) }
            : state.origNotifications,
        }
      }
      return { ...state }
    }
    case Types.DeleteNotifications: {
      // make sure to spread that state just in case!
      if (action.payload.notificationIds) {
        const newNotifications = cloneDeep(state.notifications)
        const newOrigNotifications = cloneDeep(state.origNotifications)
        if (newNotifications) {
          for (const id of action.payload.notificationIds) {
            delete newNotifications[id]
            if (newOrigNotifications) delete newOrigNotifications[id]
          }
        }
        return { ...state, notifications: newNotifications, origNotifications: newOrigNotifications }
      }
      return { ...state }
    }

    case Types.ActivateNotification: {
      // make sure to spread that state just in case!
      const notificationid = action.payload.notificationId
      if (notificationid && state.notifications && state.notifications[notificationid]) {
        state.notifications[notificationid].isActive = true
      }
      return { ...state }
    }
    case Types.DeactivateNotification: {
      // make sure to spread that state just in case!
      const notificationid = action.payload.notificationId
      if (notificationid && state.notifications && state.notifications[notificationid]) {
        state.notifications[notificationid].isActive = false
      }
      return { ...state }
    }
    case Types.ActivateAllNotifications: {
      if (state.notifications) {
        const newNotifications = cloneDeep(state.notifications)
        for (const notification of Object.values(newNotifications)) {
          notification.isActive = true
        }
        return { ...state, notifications: newNotifications }
      }
      return { ...state }
    }
    case Types.DeactivateAllNotifications: {
      if (state.notifications) {
        const newNotifications = cloneDeep(state.notifications)
        for (const notification of Object.values(newNotifications)) {
          notification.isActive = false
        }
        return { ...state, notifications: newNotifications }
      }
      return { ...state }
    }
    case Types.DuplicateNotification: {
      const notificationId = action.payload.notificationId
      if (state.notifications && notificationId && state.notifications[notificationId]) {
        const newNotifications = cloneDeep(state.notifications)
        const newNotification = duplicateNotification(
          state.notifications[notificationId],
          Object.values(state.notifications),
        )
        newNotifications[newNotification.notificationId] = newNotification
        return { ...state, notifications: newNotifications }
      }
      return { ...state }
    }
    case Types.SetLoading: {
      return { ...state, loadingState: action.payload.loadingState }
    }
    case Types.SetNotificationActivatingState: {
      return {
        ...state,
        notificationActivatingState: action.payload
          ? {
              status: action.payload.status,
              notificationIds: action.payload.notificationIds,
            }
          : null,
      }
    }
    case Types.SetOverlappingNotifications: {
      if (action.payload.overlappingNotifications) {
        return { ...state, overlappingNotifications: action.payload.overlappingNotifications }
      }
      return { ...state }
    }
    case Types.ResetSingleNotification: {
      const notificationId = action.payload.notificationId
      if (notificationId && state.origNotifications) {
        const origNotification = state.origNotifications[notificationId]
        if (origNotification) {
          const newNotifications = { ...state.notifications, [notificationId]: cloneDeep(origNotification) }
          return { ...state, notifications: newNotifications }
        }
      }
      return { ...state }
    }
    case Types.ResetState: {
      return {
        notifications: null,
        snackbarNotification: null,
        origNotifications: undefined,
        overlappingNotifications: null,
        loadingState: undefined,
        notificationActivatingState: null,
      }
    }
    default: {
      // helps us avoid typos!
      throw new Error(`[CustomNotifications-Reducer] Unhandled action type: ${(action as any).type}`)
    }
  }
}

/**
 * Context provider for providing CustomNotificationsContext
 * @returns
 */
function CustomNotificationsContextProvider({ children }: CustomNotificationProviderProps): JSX.Element {
  const { bot } = useBotContext()

  const [state, dispatch] = useReducer(notificationsReducer, {
    notifications: null,
    overlappingNotifications: null,
    snackbarNotification: null,
    loadingState: 'loading',
    notificationActivatingState: null,
  })

  useEffect(
    function () {
      if (bot && state.notifications === null) {
        // initial load of notifications
        loadNotifications(dispatch, bot.id)
      }
    },
    [bot],
  )

  useEffect(function () {
    return (): void => {
      // reset state if notification context is left
      dispatch({ type: Types.ResetState, payload: {} })
    }
  }, [])

  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  // const value = useMemo(() => [state, dispatch], [state])
  const value = { state, dispatch }
  return <CustomNotificationsContext.Provider value={value}>{children}</CustomNotificationsContext.Provider>
}

/**
 * Hook for accessing and manipulating the CustomNotificationsContext state
 * @returns
 *
 * To access this context:
 * import { useCustomNotificationsContext, loadNotications, saveNotifications, deleteNotification, activateNotification, deactivateNotification } from '<path>/hooks/contexts/answers-context.tsx'
 *
 * // in a function
 * const {notifications, setNotification, setNotifications} = useAnswers()
 *
 */
function useCustomNotifications(): {
  notifications: CustomNotifications | null
  overlappingNotifications: OverlappingCustomNotifications | null
  setNotification: (notification: CustomNotification) => void
  setNotifications: (notifications: CustomNotifications) => void
  // activateNotification: (notificationId: string) => void
  // activateAllNotifications: () => void
  // deactivateAllNotifications: () => void
  // deactivateNotification: (notificationId: string) => void
  // deleteNotificationsInContext: (notificationIds: string[]) => void // NOTE: we only return API operation because we don't delete only in context.
  duplicateNotification: (notificationId: string) => void
  getNotification: (notificationId: string) => void
  resetSingleNotification: (notificationId: string) => void
  // setError: (error: string | null) => void
  resetState: () => void
  // error: string | null
  loadingState: LoadingState
  setNotificationActivatingState: (notificationActivatingState: NotificationActivatingState) => void
  notificationActivatingState: NotificationActivatingState // gives information about which notification(s) are being activated or deactivated
  hasChanges: boolean
  dispatch: Dispatch
} {
  const context = useContext(CustomNotificationsContext)
  if (context === undefined) {
    throw new Error(
      '[useCustomNotifications] useCustomNotificationsContext must be used within a CustomNotificationsContextProvider',
    )
  }

  // splitting it up, allows us to add additional api calls
  const { state, dispatch } = context

  // ---- API ----
  // To not let anything work on the context state directly and to have an easy to use API

  // State that should be readable
  const { notifications, loadingState, origNotifications, overlappingNotifications, notificationActivatingState } =
    state

  // compare current state to original notifications as they were loaded from the API
  const hasChanges = origNotifications ? !isEqual(notifications, origNotifications) : false

  // Functions
  // Setters
  const setNotification = useCallback(
    (notification: CustomNotification) => dispatch({ type: Types.SetNotification, payload: { notification } }),
    [],
  )
  const setNotifications = useCallback(
    (notifications: CustomNotifications) => dispatch({ type: Types.SetNotifications, payload: { notifications } }),
    [],
  )
  const activateNotification = useCallback(
    (notificationId: string) => dispatch({ type: Types.ActivateNotification, payload: { notificationId } }),
    [],
  )
  const activateAllNotifications = useCallback(
    () => dispatch({ type: Types.ActivateAllNotifications, payload: {} }),
    [],
  )
  const deactivateNotification = useCallback(
    (notificationId: string) => dispatch({ type: Types.DeactivateNotification, payload: { notificationId } }),
    [],
  )
  const deactivateAllNotifications = useCallback(
    () => dispatch({ type: Types.DeactivateAllNotifications, payload: {} }),
    [],
  )
  const deleteNotificationsInContext = useCallback((notificationIds: string[]) => {
    dispatch({ type: Types.DeleteNotifications, payload: { notificationIds } })
  }, [])
  const duplicateNotification = useCallback(
    (notificationId: string) => dispatch({ type: Types.DuplicateNotification, payload: { notificationId } }),
    [],
  )

  const setNotificationActivatingState = useCallback((notificationActivatingState: NotificationActivatingState) => {
    dispatch({ type: Types.SetNotificationActivatingState, payload: notificationActivatingState })
  }, [])

  const resetSingleNotification = useCallback(
    (notificationId: string) => dispatch({ type: Types.ResetSingleNotification, payload: { notificationId } }),
    [],
  )

  // Custom getters
  const getNotification = useCallback(
    (notificationId: string) => {
      if (notifications) return notifications[notificationId] ?? null
      return null
    },
    [notifications],
  )

  // Obligatory local reset
  const resetState = useCallback(() => dispatch({ type: Types.ResetState, payload: {} }), [])

  // Controll what is returned
  return {
    notifications,
    overlappingNotifications,
    setNotification,
    setNotifications,
    // activateNotification,
    // deactivateNotification,
    duplicateNotification,
    getNotification,
    // activateAllNotifications,
    // deactivateAllNotifications,
    resetSingleNotification,
    resetState,
    dispatch,
    hasChanges,
    loadingState,
    setNotificationActivatingState,
    notificationActivatingState,
  }
}

// === ASYNC FUNCTIONS ===
// connected to api
// NOTE: These functions need to return the new answer state, since we need to track answer changes and we cannot update local states as soon as answers changes
//       Running the dispatch does not imediatly update answers in consumers and therefore if we do not give back the new answers, we might work on old data

// Example from https://kentcdodds.com/blog/how-to-use-react-context-effectively
// async function updateUser(dispatch, user, updates) {
//   dispatch({type: 'start update', updates})
//   try {
//     const updatedUser = await userClient.updateUser(user, updates)
//     dispatch({type: 'finish update', updatedUser})
//   } catch (error) {
//     dispatch({type: 'fail update', error})
//   }
// }

// const [{user, status, error}, userDispatch] = useUser()

//   function handleSubmit(event) {
//     event.preventDefault()
//     updateUser(userDispatch, user, formState)
//   }

/**
 * Checks if a notification is overlapping with other notifications.
 * Returns ids of notifications this notification overlaps with.
 * @param dispatch
 * @param botId
 * @param notificationToCheck
 */
async function checkOverlapOfCustomNotification(
  dispatch: Dispatch,
  botId: string,
  notificationToCheck: CustomNotification,
): Promise<string[] | null> {
  try {
    dispatch({
      type: Types.SetLoading,
      payload: { loadingState: 'checkingOverlap' },
    })

    // if notification is in creation process, it does not have an id yet, hence we add one for the time being
    // essentially we build a complete custom notification for the check.
    const notification: CustomNotification = {
      ...cloneDeep(notificationToCheck),
      createdTimestamp: new Date(),
      botId,
      notificationId: (notificationToCheck as any).notificationId ?? uuid(),
    }

    // we create a tmp id because notification might not have one (if it is in creation process)
    const response = await checkOverlapOfCustomNotificationAPI(botId, notification)

    if (!response) {
      // error
      console.error('[customnotifications-context][checkOverlapOfCustomNotification] response is not expected')
      throw new Error('Error checking overlap of notification')
    }

    const overlappingNotificationIds = response[notification.notificationId] ?? []

    dispatch({
      type: Types.SetLoading,
      payload: { loadingState: undefined },
    })
    return overlappingNotificationIds
  } catch (err) {
    dispatch({
      type: Types.SetLoading,
      payload: { loadingState: undefined },
    })
    return null
  }
}

/**
 * Loads all notifications and sets them in the context
 * @param dispatch answersDispatch for async calls
 * @param botId The botId the notifications should be loaded for
 */
async function loadNotifications(dispatch: Dispatch, botId: string): Promise<{ response: CustomNotifications | null }> {
  try {
    dispatch({ type: Types.SetLoading, payload: { loadingState: 'loading' } })
    const response = await getCustomNotificationsAPI(botId)
    if (response && response.customNotifications) {
      // decode notifications
      for (const notificationId of Object.keys(response.customNotifications)) {
        response.customNotifications[notificationId].content = decodeURIComponent(
          response.customNotifications[notificationId].content,
        )
      }

      dispatch({
        type: Types.SetNotifications,
        payload: { notifications: response.customNotifications, origNotifications: response.customNotifications },
      })
      dispatch({
        type: Types.SetLoading,
        payload: { loadingState: undefined },
      })
      dispatch({
        type: Types.SetOverlappingNotifications,
        payload: { overlappingNotifications: response.overlappingCustomNotifications ?? null },
      })
      return { response: response.customNotifications }
    } else {
      console.error('[customnotifications-context][loadNotifications] response is not expected')
      dispatch({
        type: Types.SetLoading,
        payload: { loadingState: undefined },
      })
      return { response: null }
    }
  } catch (err) {
    console.error('[customnotifications-context][loadNotifications] ', err)
    return { response: null }
  }
}

/**
 * Creates a notification.
 * Adds properties like creationTimestamp, notificationId and botId and performs API call to store the notification.
 * @param dispatch
 * @param botId
 * @param notification
 * @returns true if successful, false else
 */
async function createNotification(
  dispatch: Dispatch,
  botId: string,
  showSnackbarNotification: SetNotificationType,
  notification: CustomNotification,
): Promise<{
  status: boolean
  notifications?: CustomNotifications
}> {
  const newNotification: CustomNotification = {
    ...notification,
    notificationId: notification.notificationId ?? uuid(),
    botId,
  }

  return saveNotifications(
    dispatch,
    botId,
    { [newNotification.notificationId]: newNotification },
    showSnackbarNotification,
    true,
  )
}

/**
 * Saves notifications via API. Once API call is complete, sets notificaitons in context.
 * @param dispatch
 * @param botId
 * @param notifications
 * @param showSnackbarNotification
 * @param isCreate
 * @param showNotification
 * @returns true if successful, false else
 */
async function saveNotifications(
  dispatch: Dispatch,
  botId: string,
  notifications: CustomNotifications,
  showSnackbarNotification: SetNotificationType,
  isCreate = false,
  showNotification = true,
): Promise<{
  status: boolean
  notifications?: CustomNotifications
}> {
  try {
    dispatch({
      type: Types.SetLoading,
      payload: { loadingState: 'saving' },
    })

    // encode each content string of notification
    const encodedNotifications: CustomNotification[] = Object.values(notifications).map((notification) => {
      const encoded = cloneDeep(notification)
      encoded.content = encodeURI(notification.content)
      return encoded
    })

    const response = await saveCustomNotificationsAPI(botId, encodedNotifications)
    if (response === null) {
      // error
      console.error('[customnotifications-context][saveNotifications] response is not expected')
      throw new Error('Error saving notifications')
    }

    const overlappingNotifications = response.overlappingCustomNotifications ?? {}

    //success
    dispatch({ type: Types.SetNotifications, payload: { notifications, origNotifications: cloneDeep(notifications) } })
    dispatch({ type: Types.SetOverlappingNotifications, payload: { overlappingNotifications } })
    dispatch({ type: Types.SetLoading, payload: { loadingState: undefined } })
    if (showNotification) {
      showSnackbarNotification(
        'success',
        isCreate ? 'Notification erfolgreich erstellt.' : 'Speichern erfolgreich.',
        3000,
      )
    }
    return {
      status: true,
      notifications,
    }
  } catch (err) {
    console.error('[customnotifications-context][saveNotifications] ', err)
    if (showNotification) {
      showSnackbarNotification(
        'error',
        isCreate
          ? 'Fehler beim Erstellen der Notification. Notification konnten nicht erstellt werden.'
          : 'Fehler beim Speichern der Notifications. Notifications konnten nicht gespeichert werden.',
      )
    }
    dispatch({ type: Types.SetLoading, payload: { loadingState: undefined } })
    return {
      status: false,
    }
  }
}

/**
 * Activates a notification.
 * Saves the notification in the API and also in context via saveNotifications.
 * Attention! Checking if notification can be set active should be done before calling this function!!
 * The check in the function is only to prevent errors.
 * @param dispatch
 * @param botId
 * @param notifications
 * @param showSnackbarNotification
 */
async function activateNotification(
  dispatch: Dispatch,
  botId: string,
  notification: CustomNotification,
  showSnackbarNotification: SetNotificationType,
): Promise<void> {
  // check overlap. Only sets active if this check returns no overlap
  const activatedNotification = { ...notification, isActive: true }
  const overlap = await checkOverlapOfCustomNotification(dispatch, botId, activatedNotification)

  if (!overlap || (overlap && overlap.length > 0)) {
    // at least 1 overlapping notification. Do not activate the notification.
    console.log('[activateNotification] Overlap check failed or returned overlap. Not activating notification.')
    return
  }

  dispatch({
    type: Types.SetNotificationActivatingState,
    payload: { status: NotificationActivatingStatus.Activating, notificationIds: [notification.notificationId] },
  })
  const notifications = { [notification.notificationId]: activatedNotification }
  const result = await saveNotifications(dispatch, botId, notifications, showSnackbarNotification, false, false)
  if (result.status === true) {
    // showSnackbarNotification('success', 'Notification aktiviert.', 3000)
  } else {
    showSnackbarNotification('error', 'Fehler beim Aktivieren der Notification.', 3000)
  }
  dispatch({
    type: Types.SetNotificationActivatingState,
    payload: null,
  })
}

/**
 * Deactivates a notification.
 * Saves the notification in the API and also in context via saveNotifications.
 * @param dispatch
 * @param botId
 * @param notifications
 * @param showSnackbarNotification
 */
async function deactivateNotification(
  dispatch: Dispatch,
  botId: string,
  notification: CustomNotification,
  showSnackbarNotification: SetNotificationType,
): Promise<void> {
  const deactivatedNotification = { ...notification, isActive: false }

  dispatch({
    type: Types.SetNotificationActivatingState,
    payload: { status: NotificationActivatingStatus.Deactivating, notificationIds: [notification.notificationId] },
  })
  const result = await saveNotifications(
    dispatch,
    botId,
    { [notification.notificationId]: deactivatedNotification },
    showSnackbarNotification,
    false,
    false,
  )
  if (result.status === true) {
    // showSnackbarNotification('success', 'Notification deaktiviert.', 3000)
  } else {
    showSnackbarNotification('error', 'Fehler beim Deaktiveren der Notification.', 3000)
  }
  dispatch({
    type: Types.SetNotificationActivatingState,
    payload: null,
  })
}

/**
 * Activates all notifications. Saves the deactivated notifications via the API and also in context via saveNotifications.
 * @param dispatch
 * @param botId
 * @param notifications
 * @param showSnackbarNotification
 */
async function activateAllNotifications(
  dispatch: Dispatch,
  botId: string,
  notifications: CustomNotifications,
  showSnackbarNotification: SetNotificationType,
): Promise<void> {
  // check if there are overlapping notifications. To check all, we set the check to consider all (not only active) notifications.
  const response = await checkOverlapOfCustomNotificationAPI(botId, undefined, false)

  if (!response) {
    console.log('[activateNotification] Overlap check failed')
    return
  }

  if (!isEmpty(response)) {
    console.log('[activateNotification] Overlap check returned overlap. Not activating notifications.')
    showSnackbarNotification(
      'warning',
      'Es können nicht alle Notifications aktiviert werden, weil sich einige Notifications überschneiden. Aktivieren Sie die Notifications manuell oder beheben Sie die Überschneidungen.',
      5000,
    )
    return
  }

  dispatch({
    type: Types.SetNotificationActivatingState,
    payload: {
      status: NotificationActivatingStatus.Activating,
      notificationIds: Object.values(notifications).map((notification) => notification.notificationId),
    },
  })

  for (const notification of Object.values(notifications)) {
    notification.isActive = true
  }

  const result = await saveNotifications(dispatch, botId, notifications, showSnackbarNotification, false, false)
  if (result.status === true) {
    showSnackbarNotification('success', 'Notifications aktiviert.', 3000)
  } else {
    showSnackbarNotification('error', 'Fehler beim Aktivieren der Notifications.', 3000)
  }

  dispatch({
    type: Types.SetNotificationActivatingState,
    payload: null,
  })
}

/**
 * Deactivates all notifications. Saves the deactivated notifications via the API and also in context via saveNotifications.
 * @param dispatch
 * @param botId
 * @param notifications
 * @param showSnackbarNotification
 */
async function deactivateAllNotifications(
  dispatch: Dispatch,
  botId: string,
  notifications: CustomNotifications,
  showSnackbarNotification: SetNotificationType,
): Promise<void> {
  for (const notification of Object.values(notifications)) {
    notification.isActive = false
  }

  dispatch({
    type: Types.SetNotificationActivatingState,
    payload: {
      status: NotificationActivatingStatus.Deactivating,
      notificationIds: Object.values(notifications).map((notification) => notification.notificationId),
    },
  })

  const result = await saveNotifications(dispatch, botId, notifications, showSnackbarNotification, false, false)
  if (result.status === true) {
    showSnackbarNotification('success', 'Notifications deaktiviert.', 3000)
  } else {
    showSnackbarNotification('error', 'Fehler beim Deaktiveren der Notifications.', 3000)
  }

  dispatch({
    type: Types.SetNotificationActivatingState,
    payload: null,
  })
}

/**
 * Saves notifications via API. Once API call is complete, deletes notifications in context.
 * @param dispatch
 * @param botId
 * @param notifications
 * @param showSnackbarNotification
 * @returns true if successful, false else
 */
async function deleteNotifications(
  dispatch: Dispatch,
  botId: string,
  notificationIds: string[],
  showSnackbarNotification: SetNotificationType,
): Promise<boolean> {
  try {
    dispatch({
      type: Types.SetLoading,
      payload: { loadingState: 'deleting' },
    })
    const response = await deleteCustomNotificationsAPI(botId, notificationIds)
    if (response === null) {
      // error
      console.error('[customnotifications-context][deleteNotifications] response is not expected')
      throw new Error('Error deleting notification')
    }

    const overlappingNotifications = response.overlappingCustomNotifications ?? {}

    //success
    dispatch({ type: Types.DeleteNotifications, payload: { notificationIds } })
    dispatch({ type: Types.SetOverlappingNotifications, payload: { overlappingNotifications } })
    dispatch({ type: Types.SetLoading, payload: { loadingState: undefined } })
    showSnackbarNotification('success', 'Löschen erfolgreich.', 3000)
    return true
  } catch (err) {
    console.error('[customnotifications-context][deleteNotifications] ', err)
    showSnackbarNotification(
      'error',
      'Fehler beim Löschen der Notification(s). Notification(s) konnte(n) nicht gelöscht werden.',
      3000,
    )

    dispatch({ type: Types.SetLoading, payload: { loadingState: undefined } })
    return false
  }
}

/**
 * Finds and deletes all expired notifications.
 * @param dispatch
 * @param botId
 * @param allNotifications
 * @param showSnackbarNotification
 */
async function deleteExpiredNotifications(
  dispatch: Dispatch,
  botId: string,
  allNotifications: CustomNotifications,
  showSnackbarNotification: SetNotificationType,
): Promise<void> {
  // find expired notifications
  const expiredNotifiationIds = Object.values(allNotifications)
    .filter((notification) => isNotificationExpired(notification))
    .map((notification) => notification.notificationId)

  await deleteNotifications(dispatch, botId, expiredNotifiationIds, showSnackbarNotification)
}

export {
  CustomNotificationsContextProvider,
  useCustomNotifications,
  loadNotifications,
  saveNotifications,
  deleteNotifications,
  deleteExpiredNotifications,
  checkOverlapOfCustomNotification,
  createNotification,
  activateNotification,
  activateAllNotifications,
  deactivateNotification,
  deactivateAllNotifications,
}
