import { CircularProgress, IconButton, Typography, useTheme } from '@mui/material'
import { Button } from 'components/Buttons'
import { ContentPageHeader } from 'components/Page/ContentPage'
import CustomizedTooltip from 'components/Tooltips/CustomContentTooltip'
import { useLockingContext } from 'hooks/contexts/locking-context'
import { useTermTranslationsContext } from 'hooks/contexts/term-translations-context'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { Delete } from '@mui/icons-material'
import { translate as translateApi, translateBatch as translateBatchApi } from 'api/StudioBackend'
import BaseCard from 'components/Cards/BaseCard'
import CircularLoading from 'components/Loading/CircularLoading'
import Table from 'components/Table/Table'
import EditableTypography from 'components/TextInput/EditableTypography'
import { Textfield } from 'components/TextInput/Textfield'
import { ROUTE_BOTID_TRANSLATIONS, ROUTE_BOTID_TRANSLATIONS_TERMS, ROUTE_BOTS } from 'utils/constants'

import Warning from 'assets/img/knowledge/icons/warning'
import { Dialog } from 'components/Dialogs'
import Fuse from 'fuse.js'
import { useBotContext } from 'hooks/contexts/bot-context'
import { canBeTranslatedAutomatically } from 'utils/languageUtils'
import { Dictionary, DictionaryEntry } from '../../../../@types/Knowledge/Dictionaries/types'

const useStyles = makeStyles()((theme) => ({
  rowActionContainer: {
    display: 'flex',
  },
  iconButton: {
    marginLeft: theme.spacing(1),
    marginBottom: 'auto',
    marginTop: 'auto',
    padding: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  icon: { fontSize: '1.2rem' },
  rowTranslationContainer: {
    display: 'flex',
    width: '100%',
  },
  missingTranslationWarning: {
    marginBottom: 'auto',
    marginTop: 'auto',
  },
  inputContainer: { display: 'flex', alignContent: 'center', marginLeft: 'auto' },
  inputTextfield: { width: '100%' },
}))

type Sort = 'missingFirst' | 'onlyAlphabetical'

/**
 * Sorts list of dictioanry entries alphabetically
 * @param entries
 */
function sortEntries(entries: DictionaryEntry[]): DictionaryEntry[] {
  const sortedList = entries.sort((a, b) => {
    if (a.term < b.term) return -1
    if (a.term > b.term) return 1
    return 0
  })

  return sortedList
}

export default function TermTranslationSecondaryLanguage(): React.ReactElement {
  const { classes } = useStyles()
  const theme = useTheme()
  const navigate = useNavigate()
  const { botId, langCode } = useParams() as { botId: string; langCode: string }
  const { bot } = useBotContext()
  const {
    termTranslations,
    saveTermTranslations,
    loading,
    hasChanges,
    setEntryForLanguage,
    setEntryForLanguageBatch,
    setNotification,
    discardChanges,
  } = useTermTranslationsContext()
  const { lockState } = useLockingContext()

  const [editTermId, setEditTermId] = useState<string>()
  // Search & Sort
  const [searchString, setSearchString] = useState<string>('')
  const [sort, setSort] = useState<Sort>('missingFirst')
  const [sortedTerms, setSortedTerms] = useState<DictionaryEntry[]>([])
  const [displayedTerms, setDisplayedTerms] = useState<DictionaryEntry[]>([])
  // Translating
  const [hasMissingTranslations, setHasMissingTranslations] = useState<boolean>()
  const [translating, setTranslating] = useState<Set<string>>(new Set([])) // holds term ids that are currently being translated
  const [translatingAll, setTranslatingAll] = useState<boolean>()
  // Dialog
  const [displayDialog, setDisplayDialog] = useState<'discard'>()

  /**
   * Prepares dictionary for display.
   * Sorts alphabetically and sets sorted dictionary in state.
   * @param dictionary
   */
  function sortDictionary(dictionary: Dictionary, sortMode?: Sort): DictionaryEntry[] {
    if (!sortMode) sortMode = sort
    const list: DictionaryEntry[] = Object.values(dictionary)

    if (sortMode === 'missingFirst') {
      // sort missing first (and missing and not missing alphabetically)
      const entriesWithMissingTranslation: DictionaryEntry[] = []
      const entriesWithTranslation: DictionaryEntry[] = []

      for (const entry of list) {
        if (entry.entries[langCode]) {
          // translation present
          entriesWithTranslation.push(entry)
        } else {
          entriesWithMissingTranslation.push(entry)
        }
      }

      const missingSorted = sortEntries(entriesWithMissingTranslation)
      const presentSorted = sortEntries(entriesWithTranslation)
      return [...missingSorted, ...presentSorted]
    } else {
      // sort alphabetical only
      return sortEntries(list)
    }
  }

  /**
   * Sorts entries, filters them based on search string and sets them for display
   * @param dictionary
   * @param sort
   */
  function sortAndSetDisplayedEntries(dictionary: Dictionary, sort: Sort): void {
    const sorted = sortDictionary(dictionary, sort)
    setSortedTerms(sorted)
    const displayed = filterForSearch(searchString, sorted)
    setDisplayedTerms(displayed)
  }

  /**
   * Filters displayedTerms
   * @param searchString
   */
  function filterForSearch(searchString: string, dictionaryEntries?: DictionaryEntry[]): DictionaryEntry[] {
    if (!dictionaryEntries) dictionaryEntries = sortedTerms

    if (searchString) {
      const fuseOptions: Fuse.IFuseOptions<DictionaryEntry> = {
        shouldSort: true,
        threshold: 0.4,
        minMatchCharLength: 1,
        keys: ['term', ['entries', langCode]],
      }

      const fuse = new Fuse(dictionaryEntries, fuseOptions)
      const searchResults = fuse.search(searchString).map((result) => result.item)
      return searchResults
    } else {
      // no search string
      return dictionaryEntries
    }
  }

  /**
   * Handles search string change.
   * @param event
   */
  function onSearchStringChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setSearchString(event.target.value)
  }

  /**
   * Translates the given text and updates dictionary with translation.
   * @param text
   * @param termId
   */
  async function translate(text: string, termId: string): Promise<void> {
    try {
      setTranslating(new Set([termId, ...translating]))

      const translationResult = await translateApi(text, bot?.primaryLanguage || 'de', langCode, botId, false)
      const translation = translationResult?.translatedText
      if (!translation) throw new Error('Translation is undefined')

      setEntryForLanguage(termId, langCode, translation)
    } catch (err) {
      setNotification({
        type: 'error',
        message: 'Text konnte nicht übersetzt werden. Bitte versuchen Sie es in wenigen Augenblicken erneut.',
      })
    }
    translating.delete(termId)
    setTranslating(new Set(Array.from(translating)))
  }

  /**
   * Translates all entries that have no translation set.
   */
  async function translateAll(): Promise<void> {
    const entriesThatNeedTranslation = sortedTerms.filter((entry) => !entry.entries[langCode])
    if (entriesThatNeedTranslation.length === 0) return

    setTranslatingAll(true)
    setTranslating(new Set(entriesThatNeedTranslation.map((entry) => entry.dictionaryTermId)))

    const primaryLanguage = entriesThatNeedTranslation[0].primaryLanguage

    try {
      const translationResult = await translateBatchApi(
        entriesThatNeedTranslation.map((entry) => entry.entries[entry.primaryLanguage]),
        primaryLanguage,
        langCode,
        botId,
        true,
      )

      if (!translationResult) throw new Error('Translation result is undefined')

      const { translatedTexts } = translationResult

      const batch: { termId: string; language: string; text: string }[] = []

      for (const translatedText of translatedTexts) {
        // find matching entry
        for (const entry of entriesThatNeedTranslation) {
          if (entry.entries[entry.primaryLanguage] === translatedText.text) {
            batch.push({
              termId: entry.dictionaryTermId,
              text: translatedText.translatedText,
              language: langCode,
            })
            break
          }
        }
      }

      setTranslating(new Set())
      setTranslatingAll(undefined)
      setEntryForLanguageBatch(batch)
    } catch (err) {
      setNotification({
        type: 'error',
        message: 'Texte konnten nicht übersetzt werden. Bitte versuchen Sie es in wenigen Augenblicken erneut.',
      })
    }
  }

  useEffect(
    function () {
      if (termTranslations) {
        sortAndSetDisplayedEntries(termTranslations, sort)
      }
    },
    [searchString],
  )

  useEffect(
    function () {
      if (termTranslations) {
        let hasMissingTranslations = false
        for (const value of Object.values(termTranslations)) {
          if (!value.entries[langCode]) {
            hasMissingTranslations = true
            break
          }
        }
        setHasMissingTranslations(hasMissingTranslations)
        sortAndSetDisplayedEntries(termTranslations, sort)
      }
    },
    [termTranslations],
  )

  const SearchField = <Textfield value={searchString} placeholder='Suchen' onChange={onSearchStringChange} />

  const TranslateAll = (
    <CustomizedTooltip
      placement='bottom'
      disableInteractive
      content={
        <Typography variant='body1'>
          {!canBeTranslatedAutomatically(langCode) ? 'Diese Sprache kann nicht automatisch übersetzt werden' : ''}
          Alle fehlenden Begriffe automatisch übersetzen
        </Typography>
      }
      elements={
        <div>
          <Button
            size='normal'
            type='normal'
            icon='magic-line'
            iconType='remix'
            onClick={translateAll}
            loading={translatingAll}
            disabled={!hasMissingTranslations || translatingAll || lockState !== 'canEdit'}
          >
            Alle übersetzen
          </Button>
        </div>
      }
    ></CustomizedTooltip>
  )

  const Save = (
    <CustomizedTooltip
      placement='bottom'
      disableInteractive
      content={<Typography variant='body1'>Wörterbuch speichern</Typography>}
      elements={
        <div>
          <Button
            size='normal'
            type='success'
            icon='refresh-line'
            iconType='remix'
            onClick={saveTermTranslations}
            loading={loading === 'saving'}
            disabled={!hasChanges || typeof loading !== 'undefined' || lockState !== 'canEdit'}
          >
            Speichern
          </Button>
        </div>
      }
    ></CustomizedTooltip>
  )

  const actions = [SearchField, TranslateAll, Save]

  const TranslationHeader = (
    <tr>
      <div style={{ display: 'flex' }}>
        <Typography style={{ marginTop: 'auto', marginBottom: 'auto' }} fontWeight='bolder'>
          Übersetzung
        </Typography>

        <CustomizedTooltip
          placement='top'
          disableInteractive
          content={
            <Typography>
              {sort === 'onlyAlphabetical' ? 'Fehlende Übersetzungen nach vorne sortieren.' : 'Alphabetisch sortieren.'}
            </Typography>
          }
          elements={
            <IconButton
              onClick={(): void => {
                if (termTranslations) {
                  const newSort = sort === 'missingFirst' ? 'onlyAlphabetical' : 'missingFirst'
                  setSort(newSort)
                  sortAndSetDisplayedEntries(termTranslations, newSort)
                }
              }}
              aria-label='sort'
              className={classes.iconButton}
            >
              <i className={`${sort === 'onlyAlphabetical' ? 'ri-sort-desc' : 'ri-font-size'} ` + classes.icon}></i>
            </IconButton>
          }
        />
      </div>
    </tr>
  )

  return (
    <>
      <ContentPageHeader
        title='Wörterbuch für Fachbegriffe'
        actions={actions}
        previousUrl={ROUTE_BOTS + '/' + botId + ROUTE_BOTID_TRANSLATIONS + `/${langCode}`}
        previousUrlCallback={(): boolean => {
          if (hasChanges) setDisplayDialog('discard')
          return !hasChanges
        }}
      />
      <BaseCard width={'100%'} height={'100%'} minHeight={'80vh'}>
        {!termTranslations ? (
          <CircularLoading text='Wörterbuch wird geladen...' />
        ) : (
          <Table
            headers={['Fachbegriff', TranslationHeader, 'Aktionen']}
            cellAlignPattern={['left', 'left', 'right']}
            width={['45%', '45%', '10%']}
            padding='medium'
            rows={displayedTerms.map((dictionaryTerm, index) => {
              const termId = dictionaryTerm.dictionaryTermId
              const textPrimaryLanguage = dictionaryTerm.term
              const textSecondaryLanguage = dictionaryTerm.entries[langCode]
              const isInEditMode = termId === editTermId

              return [
                <Typography key={`text-${textPrimaryLanguage}`}>{textPrimaryLanguage}</Typography>,

                <div key={`translation-${textPrimaryLanguage}`} className={classes.rowTranslationContainer}>
                  {/* Show warning icon if translation is missing */}
                  {(typeof textSecondaryLanguage === 'undefined' || textSecondaryLanguage === '') && !isInEditMode && (
                    <div className={classes.missingTranslationWarning}>
                      <Warning
                        tooltip={<Typography>Es existiert noch keine Übersetzung für diesen Text.</Typography>}
                      />
                    </div>
                  )}
                  <EditableTypography
                    value={textSecondaryLanguage}
                    variant='body1'
                    onChange={(newValue: string): void => {
                      setEditTermId(undefined)
                      setEntryForLanguage(termId, langCode, newValue)
                    }}
                    hideEditButton
                    editMode={isInEditMode}
                    height='auto'
                    width='100%'
                    maxWidth='100%'
                    placeholder={'Keine Übersetzung vorhanden'}
                  />
                </div>,

                <div className={classes.rowActionContainer} key={`actions-${index}`}>
                  {/* Translate button*/}
                  <div style={{ marginLeft: 'auto' }}>
                    {translating.has(termId) ? (
                      <CircularProgress size={20} />
                    ) : (
                      <CustomizedTooltip
                        placement='top'
                        disableInteractive
                        content={<Typography>Übersetzen</Typography>}
                        elements={
                          <IconButton
                            onClick={(): Promise<void> => translate(textPrimaryLanguage, termId)}
                            aria-label='translate'
                            className={classes.iconButton}
                            disabled={lockState !== 'canEdit' || !canBeTranslatedAutomatically(langCode)}
                          >
                            <i className={'ri-magic-line ' + classes.icon}></i>
                          </IconButton>
                        }
                      />
                    )}
                  </div>
                  {/* Edit button */}
                  <div style={{ marginLeft: '16px' }}>
                    <CustomizedTooltip
                      placement='top'
                      disableInteractive
                      content={<Typography>Bearbeiten</Typography>}
                      elements={
                        <>
                          <IconButton
                            onClick={(): void => {
                              if (isInEditMode) {
                                // disable edit mode
                                setEditTermId(undefined)
                              } else {
                                setEditTermId(termId)
                              }
                            }}
                            aria-label='edit'
                            className={classes.iconButton}
                            disabled={lockState !== 'canEdit'}
                          >
                            <i className={'ri-pencil-fill ' + classes.icon}></i>
                          </IconButton>
                        </>
                      }
                    />
                  </div>
                </div>,
              ]
            })}
          />
        )}
      </BaseCard>
      {/* Discard changes dialog */}
      <Dialog
        id='discard-changes-dialog'
        size='small'
        open={displayDialog === 'discard'}
        closable
        onClose={(): void => setDisplayDialog(undefined)}
        title='Ungespeicherte Änderungen'
        primaryActionButton={
          <Button
            size='small'
            type='danger'
            icon={<Delete />}
            onClick={(): void => {
              // discard all changes and go back
              discardChanges()
              navigate(-1)
            }}
          >
            Verwerfen
          </Button>
        }
      >
        <Typography>
          Es existieren ungespeicherte Änderungen. Sind Sie sicher, dass Sie zurückgehen möchten? Die Änderungen werden
          dabei verworfen.
        </Typography>
      </Dialog>
    </>
  )
}
