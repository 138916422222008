import React from 'react'
import { Helmet } from 'react-helmet'
// Custom Components
import ContentPage, { ContentPageHeader } from '../../components/Page/ContentPage'
import NotionDocumentation from '../../components/NotionDocumentation/NotionDocumentation'
// Constants
import { APP_TITLE } from '../../utils/constants'

export default function Analytics(): React.ReactElement {
  return (
    <ContentPage>
      <Helmet>
        <title>{APP_TITLE} - Hilfe</title>
      </Helmet>
      <ContentPageHeader title='Hilfe' />
      <NotionDocumentation />
    </ContentPage>
  )
}
