import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Fuse from 'fuse.js'
import { makeStyles } from 'tss-react/mui'
import { Grid } from '@mui/material'

import ContentPage, { ContentPageHeader } from '../../../components/Page/ContentPage'
import DialogCard from '../../../components/Cards/DialogCard'
import IconCard from '../../../components/Cards/IconCard'
import { Searchfield } from '../../../components/TextInput/Searchfield'
import { Can } from '../../../components/Can/Can'
import CreateSubdialogModal from './CreateDialog'
import DeleteSubdialogModal from './DeleteDialog'
import EditSubdialogModal from './EditDialog'

import { createDialog, deleteDialog } from '../../../utils/chartUtils'
import { validateChart } from '../../../utils/chartValidator'

import { Chart, Dialog } from '../../../@types/Flowchart/types'
import { TranslationFile } from '../../../@types/Translations/types'
import { ROUTE_BOTID_DESIGNER, ROUTE_BOTS } from 'utils/constants'
import { LockState } from '../../../@types/Locking/types'
import CreateProcessTemplateDialog from './Templates/CreateProcessTemplateDialog'
import { useFlowdesignerContext } from 'hooks/contexts/flowdesigner-context'

const useStyles = makeStyles()((theme) => ({
  dialogGrid: {
    marginBottom: theme.spacing(6),
  },
}))

const fuseOptions = {
  shouldSort: true,
  threshold: 0.4,
  minMatchCharLength: 3,
  keys: ['dialog.name', 'dialog.description'],
}

type DialogOverviewProps = {
  chart?: Chart
  lockState: LockState
  translations: TranslationFile
  onDialogSelection: (dialogId: string) => void
  onUpdateChart: (newChart: Chart) => void // handles all actions besides selection of dialog
}

export default function DialogOverview({
  lockState,
  chart: propsChart,
  translations,
  onDialogSelection,
  onUpdateChart,
}: DialogOverviewProps): React.ReactElement {
  const { classes } = useStyles()

  const navigate = useNavigate()
  const { botId } = useParams() as { botId: string }
  const { canIEditFlowdesigner } = useFlowdesignerContext()

  const [showDialog, setShowDialog] = useState<'create-dialog' | 'delete-dialog' | 'edit-dialog' | 'create-template'>()
  const [dialogToDelete, setDialogToDelete] = useState<string>()
  const [dialogToEdit, setDialogToEdit] = useState<string>()
  const [dialogToCreateTemplateFrom, setDialogToCreateTemplateFrom] = useState<string>()
  const [chart, setChart] = useState<Chart | undefined>(propsChart)
  const [dialogs, setDialogs] = useState<Dialog[]>([])
  const [searchString, setSearchString] = useState<string>('')

  /**
   * Handles dialog selection.
   * Sets selected dialog in chart
   */
  function onSelectDialog(dialogId: string): void {
    onDialogSelection(dialogId)
    navigate(ROUTE_BOTS + `/${botId}` + ROUTE_BOTID_DESIGNER)
  }

  /**
   * Handles dialog creation, after user confirmed in the dialog/modal.
   * @param dialogName
   * @param dialogDescription
   */
  function onCreateDialog(dialogName: string, dialogDescription: string): void {
    if (!canIEditFlowdesigner) return

    if (!chart) return
    // add new dialog entry to chart
    // add start node for dialog to chart
    const { chart: newChart, dialogId } = createDialog(chart, dialogName, dialogDescription)
    newChart.activeDialog = dialogId
    setShowDialog(undefined)
    onUpdateChart(newChart)
  }

  /**
   * Deletes dialog.
   * @param dialogId
   */
  function onDeleteDialog(dialogId: string): void {
    if (!canIEditFlowdesigner) return

    if (!chart) return
    // delete dialog
    let newChart = deleteDialog(chart, dialogId)
    if (newChart.activeDialog === dialogId) newChart.activeDialog = newChart.mainDialog
    newChart = validateChart(chart, translations, botId)
    setShowDialog(undefined)
    setDialogToDelete(undefined)
    onUpdateChart(newChart)
  }

  /**
   * Handles dialog name change
   * @param dialogId
   * @param newDialogName
   */
  function onDialogNameChange(dialogId: string, newDialogName: string): void {
    if (!canIEditFlowdesigner) return

    if (!chart) return
    chart.dialogs[dialogId].name = newDialogName
    onUpdateChart(chart)
  }

  /**
   * Handles dialog description change
   * @param dialogId
   * @param newDialogDesc
   */
  function onDialogDescChange(dialogId: string, newDialogDesc: string): void {
    if (!canIEditFlowdesigner) return

    if (!chart) return
    chart.dialogs[dialogId].description = newDialogDesc
    onUpdateChart(chart)
  }

  /**
   * Handles dialog edit, after user confirmed in edit dialog modal.
   * @param newDialogName
   * @param newDialogDescription
   */
  function onEditDialog(dialogId: string, newDialogName: string, newDialogDescription: string): void {
    if (!canIEditFlowdesigner) return

    if (!chart) return
    chart.dialogs[dialogId].name = newDialogName
    chart.dialogs[dialogId].description = newDialogDescription
    setShowDialog(undefined)
    setDialogToEdit(undefined)
    onUpdateChart(chart)
  }

  /**
   * Handles create template click.
   * @param dialogId
   * @returns
   */
  async function onCreateTemplate(dialogId: string): Promise<void> {
    if (!canIEditFlowdesigner) return

    setDialogToCreateTemplateFrom(dialogId)
    setShowDialog('create-template')
  }

  useEffect(
    function () {
      if (typeof propsChart === 'undefined') {
        // if chart is undefined, redirect to flowdesigner
        // TODO
        return
      }

      setChart(propsChart)
      const dialogs = Object.values(propsChart.dialogs ?? {})
      setDialogs(dialogs)
    },
    [propsChart],
  )

  // Search
  const fuse = new Fuse(dialogs, fuseOptions)
  const filteredDialogs = searchString
    ? fuse.search(searchString).map((result) => {
        return result.item
      })
    : dialogs

  return (
    <ContentPage>
      <ContentPageHeader
        title='Dialogübersicht'
        actions={[
          <Searchfield
            key='search-field'
            autoFocus
            value={searchString}
            onChange={(event): void => setSearchString(event.target.value)}
          />,
        ]}
        previousUrl={ROUTE_BOTS + '/' + botId + ROUTE_BOTID_DESIGNER}
      />
      {/* <BaseCard width='100%' height='fit-content' minHeight='70vh'> */}
      <Grid container direction='row' alignItems='flex-start' spacing={5} className={classes.dialogGrid}>
        {filteredDialogs.map((dialog) => (
          <Grid item xs={12} sm={6} md={4} lg={4} key={`dialog-${dialog.id}`}>
            <DialogCard
              isMainDialog={dialog.id === chart?.mainDialog}
              dialogName={dialog.name}
              dialogDescription={dialog.description}
              dialogId={dialog.id}
              active={!!chart?.activeDialog && dialog.id === chart.activeDialog}
              onClick={(): void => onSelectDialog(dialog.id)}
              onDelete={(dialogId: string): void => {
                if (!canIEditFlowdesigner) return
                setShowDialog('delete-dialog')
                setDialogToDelete(dialogId)
              }}
              onEdit={(dialogId: string): void => {
                if (!canIEditFlowdesigner) return
                setDialogToEdit(dialogId)
                setShowDialog('edit-dialog')
              }}
              onCreateTemplate={onCreateTemplate}
              onDialogDescChange={onDialogDescChange}
              onDialogNameChange={onDialogNameChange}
              disableButtons={lockState !== 'canEdit'}
            />
          </Grid>
        ))}
        {/* only show create dialog if user is allowed to edit flowchart */}
        <Can I='update' a='floweditor'>
          <Grid item xs={12} sm={6} md={4} lg={4} key={`dialog-new`}>
            <IconCard
              footerText='Neuen Subdialog erstellen'
              icon={<i className={`ri-add-line`} />}
              onClick={(): void => setShowDialog('create-dialog')}
              disabled={lockState !== 'canEdit'}
            />
          </Grid>
        </Can>
      </Grid>
      {showDialog === 'create-dialog' && chart && (
        <CreateSubdialogModal
          chart={chart}
          onClose={(): void => setShowDialog(undefined)}
          onCreateDialog={onCreateDialog}
        />
      )}
      {showDialog === 'delete-dialog' && dialogToDelete && (
        <DeleteSubdialogModal
          dialogId={dialogToDelete}
          onClose={(): void => {
            setDialogToDelete(undefined)
            setShowDialog(undefined)
          }}
          onDelete={onDeleteDialog}
        />
      )}
      {showDialog === 'edit-dialog' && dialogToEdit && chart && (
        <EditSubdialogModal
          dialogId={dialogToEdit}
          chart={chart}
          onClose={(): void => {
            setDialogToEdit(undefined)
            setShowDialog(undefined)
          }}
          onEdit={onEditDialog}
        />
      )}
      {showDialog === 'create-template' && dialogToCreateTemplateFrom && chart && translations && (
        <CreateProcessTemplateDialog
          dialogIdForTemplate={dialogToCreateTemplateFrom}
          chart={chart}
          translationFile={translations}
          onClose={(): void => setShowDialog(undefined)}
        />
      )}
    </ContentPage>
  )
}
