/**
 * Utils file for creating SVG paths.
 * Path functions are heavily inspired / copied from react-flow/src/components/Edges/SmoothStepEdge.tsx
 */

import { Position } from 'reactflow'

// These are some helper methods for drawing the round corners (one 90° corner)
// The name indicates the direction of the path. "bottomLeftCorner" goes
// from bottom to the left and "leftBottomCorner" goes from left to the bottom.
// We have to consider the direction of the paths because of the animated lines.
const bottomLeftCorner = (x: number, y: number, size: number): string =>
  `L ${x},${y - size}Q ${x},${y} ${x + size},${y}`
const leftBottomCorner = (x: number, y: number, size: number): string =>
  `L ${x + size},${y}Q ${x},${y} ${x},${y - size}`
const bottomRightCorner = (x: number, y: number, size: number): string =>
  `L ${x},${y - size}Q ${x},${y} ${x - size},${y}`
const rightBottomCorner = (x: number, y: number, size: number): string =>
  `L ${x - size},${y}Q ${x},${y} ${x},${y - size}`
const leftTopCorner = (x: number, y: number, size: number): string => `L ${x + size},${y}Q ${x},${y} ${x},${y + size}`
const topLeftCorner = (x: number, y: number, size: number): string => `L ${x},${y + size}Q ${x},${y} ${x + size},${y}`
const topRightCorner = (x: number, y: number, size: number): string => `L ${x},${y + size}Q ${x},${y} ${x - size},${y}`
const rightTopCorner = (x: number, y: number, size: number): string => `L ${x - size},${y}Q ${x},${y} ${x},${y + size}`

interface GetBendingPointParams {
  sourceX: number
  sourceY: number
  targetX: number
  targetY: number
  sourcePosition?: Position
  targetPosition?: Position
}

/**
 * This function returns bending point for edges.
 * Inspired by the "getCenter" function of react-flow, but instead of bending an edge in the center between two ports,
 * this function determines the bending point near the source to allow for a less confusing edge layout in the flow.
 */
function getBendingPoint({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition = Position.Right,
  targetPosition = Position.Left,
}: GetBendingPointParams): [number, number, number, number] {
  const LeftOrRight = [Position.Left, Position.Right]

  const sourceIsLeftOrRight = LeftOrRight.includes(sourcePosition)
  const targetIsLeftOrRight = LeftOrRight.includes(targetPosition)

  // we expect flows to be horizontal or vertical (all handles left or right respectively top or bottom)
  // a mixed edge is when one the source is on the left and the target is on the top for example.
  // If we have a mixed edge, we simply return the center
  const mixedEdge = (sourceIsLeftOrRight && !targetIsLeftOrRight) || (targetIsLeftOrRight && !sourceIsLeftOrRight)
  if (mixedEdge) {
    const xOffset = sourceIsLeftOrRight ? Math.abs(targetX - sourceX) : 0
    const centerX = sourceX > targetX ? sourceX - xOffset : sourceX + xOffset

    const yOffset = sourceIsLeftOrRight ? 0 : Math.abs(targetY - sourceY)
    const centerY = sourceY < targetY ? sourceY + yOffset : sourceY - yOffset

    return [centerX, centerY, xOffset, yOffset]
  }

  const OFFSET = 100

  const xOffset = Math.abs(targetX - sourceX) <= 2 * OFFSET ? Math.abs(targetX - sourceX) / 2 : OFFSET // we fix this to 100. Bend is always at this position.
  // const centerX = sourceX > targetX ? sourceX - xOffset : sourceX + xOffset
  const centerX = sourceX + xOffset

  // const yOffset = Math.abs(targetY - sourceY) / 2
  const yOffset = Math.abs(targetY - sourceY) <= 2 * OFFSET ? Math.abs(targetY - sourceY) / 2 : OFFSET
  const centerY = targetY > sourceY ? sourceY + yOffset : sourceY - yOffset

  return [centerX, centerY, xOffset, yOffset]
}

interface GetSmoothStepPathWithPaddingParams {
  sourceX: number
  sourceY: number
  sourcePosition?: Position
  targetX: number
  targetY: number
  targetPosition?: Position
  borderRadius?: number
  centerX?: number
  centerY?: number
}

/**
 * Generates a smooth step path with padding
 */
export function getSmoothStepPathWithPadding({
  sourceX,
  sourceY,
  sourcePosition = Position.Bottom,
  targetX,
  targetY,
  targetPosition = Position.Top,
  borderRadius = 5,
  centerX,
  centerY,
}: GetSmoothStepPathWithPaddingParams): string {
  const padding = 100 // length of straight edge in and out of a port
  const halfPadding = padding / 2

  const [_centerX, _centerY, offsetX, offsetY] = getBendingPoint({ sourceX, sourceY, targetX, targetY })
  const cornerWidth = Math.min(borderRadius, Math.abs(targetX - sourceX))
  const cornerHeight = Math.min(borderRadius, Math.abs(targetY - sourceY))
  const cornerSize = Math.min(cornerWidth, cornerHeight, offsetX > 0 ? offsetX : 10, offsetY > 0 ? offsetY : 10)
  const leftAndRight = [Position.Left, Position.Right]
  const cX = typeof centerX !== 'undefined' ? centerX : _centerX
  const cY = typeof centerY !== 'undefined' ? centerY : _centerY

  let firstCornerPath: string | null = null
  let secondCornerPath: string | null = null

  let sourceHPadding = ''
  let targetHPadding = ''

  if (sourceX + padding <= targetX) {
    firstCornerPath =
      sourceY <= targetY ? bottomLeftCorner(sourceX, cY, cornerSize) : topLeftCorner(sourceX, cY, cornerSize)
    secondCornerPath =
      sourceY <= targetY ? rightTopCorner(targetX, cY, cornerSize) : rightBottomCorner(targetX, cY, cornerSize)
  } else {
    // here is the case specifically where new corners need introduced
    const targetXPadded =
      Math.abs(sourceX - targetX) < padding ? targetX - Math.abs(sourceX - targetX) / 2 : targetX - halfPadding

    const sourceXPadded =
      Math.abs(sourceX - targetX) < padding ? sourceX + Math.abs(sourceX - targetX) / 2 : sourceX + halfPadding

    const renderCorner = (targetX - sourceX > 0 && targetX - sourceX > padding) || targetX < sourceX
    sourceHPadding = `${
      sourceY <= targetY
        ? rightTopCorner(sourceXPadded, sourceY, cornerSize)
        : rightBottomCorner(sourceXPadded, sourceY, cornerSize)
    }`
    firstCornerPath =
      sourceY < targetY
        ? bottomRightCorner(sourceXPadded, cY, renderCorner ? cornerSize : 0)
        : topRightCorner(sourceXPadded, cY, renderCorner ? cornerSize : 0)
    targetHPadding = `${
      sourceY < targetY
        ? bottomLeftCorner(targetXPadded, targetY, cornerSize)
        : topLeftCorner(targetXPadded, targetY, cornerSize)
    }`
    secondCornerPath =
      sourceY < targetY
        ? leftTopCorner(targetXPadded, cY, renderCorner ? cornerSize : 0)
        : leftBottomCorner(targetXPadded, cY, renderCorner ? cornerSize : 0)
  }

  if (leftAndRight.includes(sourcePosition) && leftAndRight.includes(targetPosition)) {
    if (sourceX + padding <= targetX) {
      firstCornerPath =
        sourceY <= targetY ? rightTopCorner(cX, sourceY, cornerSize) : rightBottomCorner(cX, sourceY, cornerSize)
      secondCornerPath =
        sourceY <= targetY ? bottomLeftCorner(cX, targetY, cornerSize) : topLeftCorner(cX, targetY, cornerSize)
    }
  } else if (leftAndRight.includes(sourcePosition) && !leftAndRight.includes(targetPosition)) {
    if (sourceX + padding <= targetX) {
      firstCornerPath =
        sourceY <= targetY
          ? rightTopCorner(targetX, sourceY, cornerSize)
          : rightBottomCorner(targetX, sourceY, cornerSize)
    } else {
      firstCornerPath =
        sourceY <= targetY
          ? bottomRightCorner(sourceX, targetY, cornerSize)
          : topRightCorner(sourceX, targetY, cornerSize)
    }
    secondCornerPath = ''
  } else if (!leftAndRight.includes(sourcePosition) && leftAndRight.includes(targetPosition)) {
    if (sourceX + padding <= targetX) {
      firstCornerPath =
        sourceY <= targetY
          ? bottomLeftCorner(sourceX, targetY, cornerSize)
          : topLeftCorner(sourceX, targetY, cornerSize)
    } else {
      firstCornerPath =
        sourceY <= targetY
          ? bottomRightCorner(sourceX, targetY, cornerSize)
          : topRightCorner(sourceX, targetY, cornerSize)
    }
    secondCornerPath = ''
  }

  return `M ${sourceX},${sourceY}${sourceHPadding}${firstCornerPath}${secondCornerPath}${targetHPadding}L ${targetX},${targetY}`
}
