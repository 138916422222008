import React from 'react'
import { ActionMeta, GroupBase, Options, OnChangeValue } from 'react-select'

import { default as ReactSelectCreatable } from 'react-select/creatable'

// for styling of the select component see here: https://react-select.com/styles
const reactSelectStyles = {
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      borderRadius: '10px',
    }
  },
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    ':hover': {
      ...styles[':hover'],
      borderRadius: '2px 10px 10px 2px', // top left, top right, bottom right, bottom left
    },
  }),
  // container: (styles, { data }) => ({
  //   height: '56px',
  // }),
  option: (styles, state) => ({
    ...styles,
    backgroundColor: 'none',
    fontWeight: state.isFocused ? '600' : '400',
    cursor: 'pointer',
  }),
  menu: (styles, state) => ({
    ...styles,
    borderRadius: '16px',
  }),
  menuPortal: (styles, state) => ({
    ...styles,
    borderRadius: '16px',
    // zIndex: '3000',
  }),
  menuList: (styles, state) => ({
    ...styles,
    marginTop: '16px',
    marginBottom: '16px',
  }),
  valueContainer: (styles, state) => ({
    ...styles,
    paddingLeft: '12px',
    color: '#003454',
    fontSize: '1rem',
  }),
  control: (styles, state) => ({
    ...styles,
    height: '56px',
    borderRadius: '16px', // TODO: NOTE: this is not connected to the material ui theme!
    borderColor: state.isSelected ? '#003454' : 'hsl(0, 0%, 80%)',
    ':hover': {
      ...styles[':hover'],
      borderColor: '#003454',
    },
  }),
}

type DropdownOption = {
  label: string
  value: string
}

export type ChangeValue = OnChangeValue<DropdownOption, boolean>
export type ActionType = ActionMeta<DropdownOption>

type CreatableSelectProps<T extends DropdownOption> = {
  onChange: (newValue: OnChangeValue<T, boolean>, action: ActionMeta<T>) => void
  options: T[]
  isMulti?: boolean
  isCreatable?: boolean
  isClearable?: boolean
  selectedOptions?: T | T[]
  placeholder?: string
  noOptionsMessage?: string
  createOptionText?: (inputValue: string) => string
  allowedChars?: string // string of all allowed chars
}

/**
 * This component is an abstraction for the react-select/creatable component (https://react-select.com/creatable).
 * It offers better usability than the MUI Autocomplete component (e.g. does not behave like textfield when selecting single value).
 * This component is the basis for e.g. the VariablesAutosuggestSelect component that handles all variable related stuff.
 * @deprecated use MultiSelectDropdown or SelectDropdown instead
 */
export default function CreatableSelect({
  onChange,
  options,
  isMulti = false,
  isCreatable = false,
  isClearable = true,
  selectedOptions = [],
  placeholder,
  noOptionsMessage,
  createOptionText,
  allowedChars,
}: CreatableSelectProps<DropdownOption>): React.ReactElement {
  /**
   * Returns the label for an option
   * @param {DropdownOption} option
   */
  function getOptionLabel(option: DropdownOption): string {
    return option.label
  }

  function getOptionValue(option: DropdownOption): string {
    return option.value
  }

  /**
   * Checks if the value that should be created is allowed.
   * @param inputValue
   * @param value
   * @param options
   * @returns
   */
  function isValidNewOption(
    inputValue: string,
    value: ReadonlyArray<DropdownOption>,
    options: ReadonlyArray<DropdownOption | GroupBase<DropdownOption>>,
  ): boolean {
    if (inputValue.trim().length === 0) return false

    if (typeof allowedChars !== 'undefined') {
      // check if each char of the input value is a allowed char
      for (const char of inputValue) {
        if (!allowedChars.includes(char)) return false
      }
    }

    return true
  }

  return (
    <ReactSelectCreatable
      isMulti={isMulti}
      isSearchable={isCreatable}
      isClearable={isClearable}
      onChange={onChange}
      isValidNewOption={isValidNewOption}
      options={options}
      value={selectedOptions}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      styles={reactSelectStyles}
      placeholder={placeholder}
      noOptionsMessage={typeof noOptionsMessage !== 'undefined' ? () => noOptionsMessage : undefined}
      formatCreateLabel={createOptionText}
    />
  )
}
