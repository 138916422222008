import React, { memo, useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
// Components
import { FabButton } from 'components/Buttons'
// Types
import { AnswerAction } from '../../../../../../@types/Knowledge/types'
// Hooks
import { useAnswers } from 'hooks/contexts/answers-context'
import { useLockingContext } from 'hooks/contexts/locking-context'
import ActionCard from 'components/Cards/ActionCard'
import EditAnswerActionsDialog from './EditAnswerActionsDialog'
import { Can } from 'components/Can/Can'
import { Textfield } from 'components/TextInput/Textfield'

const useStyles = makeStyles()((theme) => ({
  content: {
    display: 'flex',
    zIndex: 200,
    padding: theme.spacing(6),
  },
}))

type Props = {
  answerId: string
  onDialogNameChange: (name: string) => void
}

// Trigger answer only
export default memo(function EditAnswerDialogName({ answerId, onDialogNameChange }: Props): React.ReactElement {
  const { classes } = useStyles()
  const { getAnswer, canIEditAnswer } = useAnswers()
  const { lockState } = useLockingContext()

  const [dialogName, setDialogName] = useState<string>(getAnswer(answerId)?.triggerDialogName ?? '')

  return (
    <Can I='update' a='knowledge' passThrough>
      {(can): React.ReactElement => (
        <div className={classes.content}>
          <Textfield
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (can && canIEditAnswer(answerId)) {
                setDialogName(event.target.value)
                onDialogNameChange(event.target.value)
              }
            }}
            disabled={lockState !== 'canEdit'}
            value={dialogName}
            fullWidth
            label='Dialogname'
            placeholder='Dialogname'
          />
        </div>
      )}
    </Can>
  )
})
