import React, { memo, useEffect, useState } from 'react'
import jp from 'jsonpath'
import { cloneDeep, isEqual } from 'lodash'
import { makeStyles } from 'tss-react/mui'
// types
import {
  Card,
  CardTypeEnum,
  IContainer,
  ITextBlock,
  InputTypeEnum,
  IAdaptiveCard,
  ICardElement,
} from '../../../../../../../../@types/SmartCards/types'
// components
import BaseCard from 'components/Cards/BaseCard'
import SmartCard from 'components/SmartCard/SmartCard'
import CircularLoading from 'components/Loading/CircularLoading'
// utils
import { changeValuesByPath } from 'utils/jsonUtils'
// previews & templates
import { mode1Preview, mode2Preview } from './previews'
import { mode1TemplateDE, mode1TemplateEN, mode2TemplateDE, mode2TemplateEN } from './templates'
import ColorBox from './colorBox'
import { FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { setActionStyleDefault, updateCardProperties } from 'utils/smartCardUtils'
import { useBotContext } from 'hooks/contexts/bot-context'

const useStyles = makeStyles()((theme) => ({
  editHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  menu: {
    display: 'flex',
    marginTop: theme.spacing(4),
    justifyContent: 'space-evenly',
    marginBottom: theme.spacing(4),
  },
  card: {
    border: `1px solid ${theme.palette.grey.A100}`,
    borderRadius: theme.shape.borderRadius,
  },
  choosenColorBox: {
    marginRight: theme.spacing(1),
  },
  colorInput: {
    marginLeft: theme.spacing(1),
    minWidth: '70px',
  },
  input: {
    display: 'flex',
  },
}))

type ContentAndInputActionsProps = {
  cardType: CardTypeEnum
  actionsContainer: IContainer
  setActionsContainerCallback: (actionsContainer: IContainer) => void
}

export default memo(function ContentAndInputActions({
  cardType,
  actionsContainer: origActionsContainer,
  setActionsContainerCallback,
}: ContentAndInputActionsProps): React.ReactElement {
  const { bot } = useBotContext()
  const [mode, setMode] = useState<1 | 2 | -1 | undefined>(-1) // 1 = only submit action with no data, 2 = submit action with no data and stepback action
  const [actionContainer, setActionContainer] = useState<IContainer>(origActionsContainer)
  const { classes } = useStyles()

  useEffect(() => {
    const container = origActionsContainer
    if (container.items && container.items.length === 0) {
      // action container is empty
      setMode(undefined)
    } else if (container.items && container.items.length >= 1) {
      // check what type of action is placed
      if (container.items[0].type === 'ColumnSet') {
        // mode 2
        setMode(2)
      } else if (container.items[0].type === 'ActionSet') {
        // mode 1
        setMode(1)
      } else {
        console.error('Card - CreationProcess - unexpected actions in actions container')
      }
    } else {
      console.error('Card - CreationProcess - unexpected actions in actions container')
    }
    // check if styles are set correctly
    const checkedContainer = setActionStyleDefault(container) as IContainer
    setActionContainer(checkedContainer)
  }, [])

  useEffect(() => {
    setActionsContainerCallback(actionContainer)
  }, [actionContainer])

  function onAddActions(mode: number): void {
    const tmpActionContainer = { ...actionContainer }
    if (mode === 1) {
      tmpActionContainer.items = []
      tmpActionContainer.items.push((bot?.primaryLanguage !== 'en' ? mode1TemplateDE : mode1TemplateEN) as ICardElement)
    } else if (mode === 2) {
      tmpActionContainer.items = []
      tmpActionContainer.items.push((bot?.primaryLanguage !== 'en' ? mode2TemplateDE : mode2TemplateEN) as ICardElement)
    }
    setActionContainer(tmpActionContainer)
  }

  function onRemoveActions(): void {
    const tmpActionContainer = { ...actionContainer }
    tmpActionContainer.items = []
    setActionContainer(tmpActionContainer)
  }

  function renderMenu(): React.ReactElement {
    return (
      <>
        <Typography style={{ marginTop: '32px' }}>
          Bitte wählen Sie aus, welche Aktionen Sie der Card hinzufügen möchten.
        </Typography>
        <div className={classes.menu}>
          <BaseCard
            title='Bestätigen'
            onClick={(): void => {
              onAddActions(1)
              setMode(1)
            }}
            titleVariant='h5'
            shadowWidth={10}
            shadowBlur={10}
            width='300px'
            height='250px'
          >
            <SmartCard className={classes.card} card={mode1Preview as IAdaptiveCard} />
          </BaseCard>
          <BaseCard
            title='Zurück & Bestätigen'
            onClick={(): void => {
              onAddActions(2)
              setMode(2)
            }}
            titleVariant='h5'
            shadowWidth={10}
            shadowBlur={10}
            width='300px'
            height='250px'
          >
            <SmartCard className={classes.card} card={mode2Preview as IAdaptiveCard} />
          </BaseCard>
        </div>
      </>
    )
  }

  function renderEditMode1(): React.ReactElement {
    return (
      <div>
        <div className={classes.editHeader}>
          <IconButton
            onClick={(): void => {
              onRemoveActions()
              setMode(undefined)
            }}
          >
            <i className={`ri-arrow-left-line`} />
          </IconButton>
          <Typography>Bitte geben Sie einen Text an, der in der Aktion stehen soll.</Typography>
        </div>
        <div className={classes.input}>
          <TextField
            autoFocus
            fullWidth
            label='Aktion'
            value={jp.query(actionContainer, '$..title')}
            onChange={(event): void => {
              if (cardType === CardTypeEnum.ContentCard) {
                // for content cards, the action's title = action's data. This way the action is handled just like the action of an action card.
                // we have to do this so that the bot can handle it!
                const newActionContainer = cloneDeep(actionContainer)
                if (newActionContainer.items) {
                  newActionContainer.items[0].actions[0].title = event.target.value
                  newActionContainer.items[0].actions[0].data = event.target.value
                }
                setActionContainer(newActionContainer)
              } else {
                // input card
                const newActionContainer = updateCardProperties(
                  { ...actionContainer },
                  '$..title',
                  'title',
                  event.target.value,
                )
                setActionContainer(newActionContainer)
              }
            }}
          />
          <FormControl className={classes.colorInput}>
            <InputLabel id={`action-color-label`}>Farbe</InputLabel>
            <Select
              labelId={`action-color-label`}
              id={`action-color`}
              value={jp.query(actionContainer, '$..actions[0]..style')[0] ?? 'default'}
              label='Farbe'
              onChange={(event): void => {
                const newActionContainer = updateCardProperties(
                  { ...actionContainer },
                  '$..actions[0]..style',
                  'style',
                  event.target.value,
                )
                setActionContainer(newActionContainer)
              }}
              renderValue={(selected): React.ReactNode => {
                return <ColorBox color={selected} className={classes.choosenColorBox} />
              }}
            >
              <MenuItem value={'default'}>
                <ColorBox color={'default'} />
              </MenuItem>
              <MenuItem value={'positive'}>
                <ColorBox color={'positive'} />
              </MenuItem>
              <MenuItem value={'destructive'}>
                <ColorBox color={'destructive'} />
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
    )
  }

  function renderEditMode2(): React.ReactElement {
    return (
      <div>
        <div className={classes.editHeader}>
          <IconButton
            onClick={(): void => {
              onRemoveActions()
              setMode(undefined)
            }}
          >
            <i className={`ri-arrow-left-line`} />
          </IconButton>
          <Typography>Bitte geben Sie einen Text an, der in der Aktion stehen soll.</Typography>
        </div>
        <Grid container spacing={2}>
          <Grid sm={6} xs={12} item>
            <TextField fullWidth label='Aktion' value={jp.query(actionContainer, '$..title')[0]} disabled />
          </Grid>
          <Grid sm={6} xs={12} item className={classes.input}>
            <TextField
              autoFocus
              fullWidth
              label='Aktion'
              value={jp.query(actionContainer, '$..title')[1]}
              onChange={(event): void => {
                const newActionContainer = updateCardProperties(
                  { ...actionContainer },
                  '$..columns[1]..title',
                  'title',
                  event.target.value,
                )
                setActionContainer(newActionContainer)
              }}
            />
            <FormControl className={classes.colorInput}>
              <InputLabel id={`action-color-label`}>Farbe</InputLabel>
              <Select
                labelId={`action-color-label`}
                id={`action-color`}
                value={jp.query(actionContainer, '$..columns[1]..style')[0] ?? 'default'}
                label='Farbe'
                onChange={(event): void => {
                  const newActionContainer = updateCardProperties(
                    { ...actionContainer },
                    '$..columns[1]..style',
                    'style',
                    event.target.value,
                  )
                  setActionContainer(newActionContainer)
                }}
                renderValue={(selected): React.ReactNode => {
                  return <ColorBox color={selected} className={classes.choosenColorBox} />
                }}
              >
                <MenuItem value={'default'}>
                  <ColorBox color={'default'} />
                </MenuItem>
                <MenuItem value={'positive'}>
                  <ColorBox color={'positive'} />
                </MenuItem>
                <MenuItem value={'destructive'}>
                  <ColorBox color={'destructive'} />
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </div>
    )
  }

  const menu = typeof mode === 'undefined' ? renderMenu() : <></>
  const mode1 = mode === 1 ? renderEditMode1() : <></>
  const mode2 = mode === 2 ? renderEditMode2() : <></>

  return (
    <>
      {mode === -1 && <CircularLoading text='Lade Aktionen' />}
      {typeof mode === 'undefined' && menu}
      {mode === 1 && mode1}
      {mode === 2 && mode2}
    </>
  )
}, isEqual)
