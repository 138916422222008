import React, { ReactElement } from 'react'
import { makeStyles } from 'tss-react/mui'

import { IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'

import { useDraggableContextDispatch } from '../../../hooks/contexts/useDraggableContext'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  icon: {
    marginLeft: 'auto',
  },
  iconButton: {
    fontSize: '1rem',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 20,
    cursor: 'default',
  },
}))

type PopupTitlebarProps = {
  title: string
  onClose: () => void
}

export const PopupTitlebar = ({ title, onClose }: PopupTitlebarProps): ReactElement => {
  const { classes } = useStyles()
  const draggableContextDispatch = useDraggableContextDispatch()

  return (
    <div className={classes.root}>
      <div
        className={classes.title}
        onMouseEnter={(): void => draggableContextDispatch({ type: false })}
        onMouseLeave={(): void => draggableContextDispatch({ type: true })}
      >
        {title}
      </div>
      <div
        className={classes.icon}
        onMouseEnter={(): void => draggableContextDispatch({ type: false })}
        onMouseLeave={(): void => draggableContextDispatch({ type: true })}
      >
        <IconButton className={classes.iconButton} onClick={onClose} size='small' color='primary' component='span'>
          <Close />
        </IconButton>
      </div>
    </div>
  )
}
