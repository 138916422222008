import { Redo } from '@mui/icons-material'
import React, { ReactElement } from 'react'
import EditorSidebarItem from '../editorSidebarItem'
import { FLOWDESIGNER_NODE_COLOR_LOGIC } from 'utils/constants'

export default function ifElse(): ReactElement {
  return (
    <EditorSidebarItem
      type='logic/jump'
      ports={{
        port1: {
          id: 'port1',
          type: 'left',
          // properties: {
          //   type: 'incoming',
          // },
        },
      }}
      properties={{
        typeText: 'Springe zu',
        targetNode: '',
        dialog: '',
      }}
      Icon={Redo}
      color={FLOWDESIGNER_NODE_COLOR_LOGIC}
    />
  )
}
