import React, { memo, useEffect, useState } from 'react'
import jp from 'jsonpath'

//
import { makeStyles } from 'tss-react/mui'

// Types
import { Chart } from '../../../../../../../@types/Flowchart/types'
import { Card, CardInputFields, CardTypeEnum } from '../../../../../../../@types/SmartCards/types'
import { findValuesInputFields } from 'utils/chartUtils'
import { Grid, Typography } from '@mui/material'
import VariablesAutosuggestSelect, { OnChangeOptions } from '../../../Variables/VariablesAutosuggestSelect'
import AcChoiceValueInput from '../../components/ACChoiceValueInput'
import { Button } from 'components/Buttons'
import { useFlowdesignerContext } from 'hooks/contexts/flowdesigner-context'

const useStyles = makeStyles()((theme) => ({
  editor: {
    flexGrow: 1,
    overflowY: 'auto',
  },
  editButton: {
    marginTop: theme.spacing(4),
  },
}))

type CardOverviewProps = {
  card: Card
  chart: Chart
  setChartCallback: (chart: Chart) => void
  editCard: () => void
}

export default memo(function CardOverview({
  card: origCard,
  chart,
  setChartCallback,
  editCard,
}: CardOverviewProps): React.ReactElement {
  const { classes } = useStyles()
  const { canIEditFlowdesigner } = useFlowdesignerContext()
  const [card, setCard] = useState<Card>(origCard)
  const [inputFields, setInputFields] = useState<CardInputFields>([])

  useEffect(() => {
    const inputFields = findValuesInputFields(card.data, 'type')
    setInputFields(inputFields)
  }, [])

  /**
   * Callback function for selecting result variable
   * @param {Chart} chart
   * @param {string[]} prevSelectedVarIds
   * @param {string[]} selectedVarIds
   */
  function onResultVariableChange(chart: Chart, prevSelectedVarIds: string[], selectedVarIds: string[]): void {
    setChartCallback(chart)
  }

  /**
   * Callback function for variable selection component.
   * @param {Chart} chart
   * @param {string[]} prevSelectedVarIds
   * @param {string[]} selectedVarIds
   * @param {OnChangeOptions} options
   */
  function onVariableChange(
    chart: Chart,
    prevSelectedVarIds: string[],
    selectedVarIds: string[],
    options: OnChangeOptions,
  ): void {
    // TODO: trigger mandatory datacheck stuff here, this is skipped for now (see commented code)
    setChartCallback(chart)
  }

  return (
    <div className={classes.editor}>
      {/* Input Fields exist */}
      {inputFields.map((inputField, idx) => (
        <div key={idx} style={{ padding: '10px' }}>
          <div
            style={{
              display: 'inline-block',
              margin: 0,
              fontSize: '1rem',
              width: '100%',
            }}
          >
            {
              <Grid container direction='column' alignItems='baseline'>
                <Grid item xs={12} style={{ width: '100%' }}>
                  <Grid container direction='row' alignItems='center'>
                    <Grid item xs={3} style={{ height: '100%' }}>
                      <Typography style={{ marginRight: '15px' }}>Feld ID:</Typography>
                    </Grid>
                    <Grid item xs={9} style={{ height: '100%' }}>
                      <div style={{ alignSelf: 'center' }}>
                        <b>
                          <i>{inputField.id}</i>
                        </b>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{ width: '100%' }}>
                  {/* If object has choices, it is a choiceSet */}
                  {inputField.choices ? (
                    inputField.choices.map((choice, index) => {
                      return (
                        <Grid
                          key={`choice-${choice.id}-${index}`}
                          container
                          direction='column'
                          style={{ width: '100%' }}
                        >
                          <Grid item xs={12} style={{ width: '100%' }}>
                            <Grid container direction='column' alignItems='baseline'>
                              <Grid item xs={3} style={{ height: '100%', marginRight: '15px' }}>
                                <Typography>Auswahl Titel:</Typography>
                              </Grid>

                              <Grid item xs={9} style={{ height: '100%' }}>
                                <div style={{ alignSelf: 'center' }}>
                                  <b>
                                    <i>{choice.acValue ? choice.acValue : 'Kein Titel festgelegt'}</i>
                                  </b>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} style={{ width: '100%' }}>
                            <Grid container direction='row' justifyContent='space-evenly' alignItems='center'>
                              <Grid item xs={3} style={{ height: '100%' }}>
                                <div style={{ marginRight: '15px' }}>
                                  <Typography>
                                    Variable & Wert der Variablen, falls eine Auswahl gewählt wurde.
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={9} style={{ height: '100%' }}>
                                <div style={{ width: '100%', marginBottom: '25px' }}>
                                  <AcChoiceValueInput
                                    chart={chart}
                                    acChoiceName={choice.acValue}
                                    setStateCallback={setChartCallback}
                                    setVariablesCallback={onVariableChange}
                                    choiceSetId={inputField.id}
                                    acFieldType={inputField.type}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                    })
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <Grid item xs={3} style={{ height: '100%' }}>
                        <div style={{ marginRight: '15px' }}>
                          <Typography>Variablen</Typography>
                        </div>
                      </Grid>
                      <Grid item xs={9} style={{ height: '100%' }}>
                        <div style={{ width: '100%' }}>
                          <VariablesAutosuggestSelect
                            chart={chart}
                            onChange={onVariableChange}
                            usageType='set'
                            acFieldId={inputField.id}
                            acFieldType={inputField.type}
                            isResult={false}
                          />
                        </div>
                      </Grid>
                    </div>
                  )}
                </Grid>
              </Grid>
            }
          </div>
          {idx < inputFields.length - 1 ? <hr style={{ marginTop: '15px' }} /> : null}
        </div>
      ))}
      {/* no Input Fields but actions - TODO: better jp query for Submit Actions with data only*/}
      {inputFields.length === 0 && jp.paths(card, `$..actions`).length > 0 && (
        <div style={{ marginBottom: '16px' }}>
          <Typography>(Optional) Variable zum Speichern der Aktions Auswahl setzen.</Typography>

          <VariablesAutosuggestSelect chart={chart} usageType='set' onChange={onResultVariableChange} isResult={true} />
        </div>
      )}
      {/* neither input fields nor actions and card type custom -> basically a freetext option -> if no inputs and actions but varname, wait for an answer, do not trigger qna and store the answer in the var, then proceed. */}
      {inputFields.length === 0 &&
        jp.paths(card, `$..actions`).length === 0 &&
        card.cardType === CardTypeEnum.CustomCard && (
          <div style={{ marginBottom: '16px' }}>
            <Typography>
              Wenn Sie eine Freitext Antwort vom Nutzer erhalten wollen und dafür keine Eingabe-Felder nutzen wollen,
              können Sie einfach hier eine Variable angeben, in der Antwort der Nutzer*innen gespeichert wird. Die
              Antworten der Nutzer*innen werden in diesem Fall für diesen Block keine Abfrage aus der Wissensdatenbank
              auslösen!
            </Typography>

            <VariablesAutosuggestSelect
              chart={chart}
              usageType='set'
              onChange={onResultVariableChange}
              isResult={true}
            />
          </div>
        )}
      <Button
        className={classes.editButton}
        iconType='remix'
        icon='pencil-line'
        onClick={editCard}
        variant='secondary'
        disabled={!canIEditFlowdesigner}
      >
        Smart Card bearbeiten
      </Button>
    </div>
  )
})
