import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { Typography } from '@mui/material'

type StyleProps = {
  height?: string
  width?: string
  footerColor?: string
}

export const useAnalyticsCardStyles = makeStyles<StyleProps>()((theme, props) => ({
  container: {
    boxSizing: 'content-box',
    position: 'relative',
    borderRadius: `${
      typeof theme.shape.borderRadius === 'string'
        ? parseInt(theme.shape.borderRadius) * 3
        : theme.shape.borderRadius * 3
    }px`,
    background: theme.palette.background.paper,
    height: props?.height || '100px',
    maxWidth: props?.width || '100px',
    paddingTop: `${theme.spacing(2)}`,
    display: 'flex',
    flexDirection: 'column',
  },
  cardContent: { flexGrow: 1 },
}))

type AnalyticsCardProps = {
  children?: React.ReactNode
  title?: string
  height?: string
  width?: string
}

/**
 * Base card for all cards in Studio 2.0.
 */
export default function AnalyticsCard({
  children,
  title,
  height = '310px',
  width = '100%',
}: AnalyticsCardProps): React.ReactElement {
  const { classes } = useAnalyticsCardStyles({ height, width })
  return (
    <div className={classes.container}>
      {!!title && <Typography variant='h2'>{title}</Typography>}
      <div className={classes.cardContent}>{children}</div>
    </div>
  )
}
