import React from 'react'
import { Translation } from './AnswerTranslations'
import ReactMarkdown from 'react-markdown'
import EditableMarkdownText from 'components/TextInput/EditableMarkdownText'
import { Chip, IconButton, Typography } from '@mui/material'
import { theme } from 'theme/StudioTheme'
import EditableTypography from 'components/TextInput/EditableTypography'
import CustomizedTooltip from 'components/Tooltips/CustomContentTooltip'
import { AnswerType } from '../../../../@types/Knowledge/types'

type AnswerTranslationTableRowProps = {
  classes: any
  content: Translation
  onChange: (newValue: string, localTransId: string, error?: string) => void
  onTranslate: (text: string, localTransId: string) => Promise<void>
  onActivateEdit: (localTransId: string) => void
  disabled: boolean
  index: number
  editActive: boolean
  errorMsg?: string
  answerTitle?: string
  answerType?: AnswerType
}

// we cannot have a React component as row of our Table so we just use a simple function.
// this means we have to provide everything "reacty" via props as we cannot use contexts for example.
export default function getAnswerTranslationTableRow({
  classes,
  content,
  index,
  editActive,
  onChange: onChangeProps,
  onTranslate,
  onActivateEdit,
  disabled,
  errorMsg,
  answerTitle,
  answerType,
}: AnswerTranslationTableRowProps): React.ReactNode[] {
  function onChange(newValue: string): void {
    onChangeProps(newValue, content.localTransId)
  }

  return [
    <div key={`row-${index}-orig`}>
      <div style={{ display: 'flex', marginBottom: theme.spacing(1) }}>
        <div style={{ marginRight: theme.spacing(1) }}>
          <Typography variant='body2'>{answerTitle ?? '-'}</Typography>
        </div>
        <div style={{ marginRight: theme.spacing(1) }}>
          <Chip
            size='small'
            color='primary'
            variant='outlined'
            label={answerType === 'answer' ? 'Antwort' : 'Triggerantwort'}
          />
        </div>
        <div>
          <Chip
            size='small'
            color='secondary'
            variant='outlined'
            label={
              content.type === 'answerTemplate'
                ? 'Antworttext'
                : content.type === 'intentTriggerButton'
                ? 'Trigger Button'
                : 'Folgefrage'
            }
          />
        </div>
      </div>

      {content.type === 'answerTemplate' ? (
        <ReactMarkdown>{content.translation.orig}</ReactMarkdown>
      ) : (
        <Typography>{content.translation.orig}</Typography>
      )}
    </div>,
    <div key={`row-${index}-trans`}>
      {content.type === 'answerTemplate' ? (
        <div>
          <EditableMarkdownText
            value={content.translation.trans}
            onChange={onChange}
            placeholder='Keine Übersetzung vorhanden'
            editMode={editActive}
          />
          {errorMsg && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <i
                className={`ri-error-warning-line ${classes.userHintIcon}`}
                style={{ color: theme.palette.error.main }}
              />
              <Typography variant='body1' style={{ color: theme.palette.error.main }}>
                {errorMsg}
              </Typography>
            </div>
          )}
        </div>
      ) : (
        <EditableTypography
          value={content.translation.trans}
          variant='body1'
          onChange={onChange}
          hideEditButton
          editMode={editActive}
          height='auto'
          width='100%'
          maxWidth='100%'
          placeholder='Keine Übersetzung vorhanden'
        />
      )}
    </div>,
    <div key={`row-${index}-actions`} style={{ display: 'flex' }}>
      <div style={{ marginLeft: 'auto' }}>
        <CustomizedTooltip
          placement='top'
          disableInteractive
          content={<Typography>Übersetzen</Typography>}
          elements={
            <IconButton
              onClick={(): Promise<void> => onTranslate(content.translation.orig, content.localTransId)}
              aria-label='translate'
              className={classes.iconButton}
              disabled={disabled}
            >
              <i className={'ri-magic-line ' + classes.icon}></i>
            </IconButton>
          }
        />
      </div>
      {/* Edit button */}
      <div style={{ marginLeft: '16px' }}>
        <CustomizedTooltip
          placement='top'
          disableInteractive
          content={<Typography>Bearbeiten</Typography>}
          elements={
            <IconButton
              onClick={(): void => onActivateEdit(content.localTransId)}
              aria-label='edit'
              className={classes.iconButton}
              disabled={disabled}
            >
              <i className={'ri-pencil-fill ' + classes.icon}></i>
            </IconButton>
          }
        />
      </div>
    </div>,
  ]
}
