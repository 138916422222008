import { Typography } from '@mui/material'
import { Textfield } from 'components/TextInput/Textfield'
import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { BotSettings } from '../../@types/Settings/types'
import { useLockingContext } from 'hooks/contexts/locking-context'

type Colors = {
  primaryColor?: string
  primaryColorText?: string
  disabledColor?: string
}

const useStyles = makeStyles()((theme) => ({
  content: {},
  buttons: { display: 'flex' },
  button: { width: '180px', margin: `0 ${theme.spacing(1)}` },
  heading: { margin: `${theme.spacing(2)} 0`, marginBottom: theme.spacing(3) },
  row: { display: 'flex' },
  keyTypography: { margin: 'auto 0' },
  generalContainer: { marginTop: theme.spacing(5) },
  stylesContainerContent: { display: 'flex', flexDirection: 'column' },
  avatarContainer: { marginTop: theme.spacing(2), width: '100%', marginBottom: theme.spacing(2) },
  settingWrapperWithMargin: { marginTop: theme.spacing(2) },
  colorsContainer: {
    maxWidht: '200px',
    maxHeight: '200px',
  },
  avatarUpload: {
    height: '200px',
    width: '200px',
    border: '3px dotted grey',
    display: 'flex',
  },
  avatarImage: {
    width: '100%',
    height: 'fit-content',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  colorPreview: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: theme.spacing(1),
    width: '40px',
    height: '40px',
    borderRadius: '50%',
  },
}))

type GeneralSettingsProps = {
  generalSettings: BotSettings['general']
  onGeneralSettingsChange: (newGeneralSettings: BotSettings['general']) => void
}

export default function GeneralSettings({
  generalSettings,
  onGeneralSettingsChange,
}: GeneralSettingsProps): React.ReactElement {
  const { classes } = useStyles()
  const { lockState } = useLockingContext()

  // Custom styles
  const [botName, setBotName] = useState<string>()
  const [botDescription, setBotDescription] = useState<string>()
  const [botUrl, setBotUrl] = useState<string>()

  function onBotNameChange(e: React.ChangeEvent<HTMLInputElement>): void {
    setBotName(e.target.value)
    const newGeneralSettings: BotSettings['general'] = {
      ...(generalSettings ?? { botDescription: '' }),
      botName: e.target.value,
    }
    onGeneralSettingsChange(newGeneralSettings)
  }

  function onBotDescriptionChange(e: React.ChangeEvent<HTMLInputElement>): void {
    setBotDescription(e.target.value)
    const newGeneralSettings: BotSettings['general'] = {
      ...(generalSettings ?? { botName: '' }),
      botDescription: e.target.value,
    }
    onGeneralSettingsChange(newGeneralSettings)
  }

  function onBotUrlChange(e: React.ChangeEvent<HTMLInputElement>): void {
    setBotUrl(e.target.value)
    const newGeneralSettings: BotSettings['general'] = {
      ...(generalSettings ?? { botDescription: '', botName: '' }),
      botUrl: e.target.value,
    }
    onGeneralSettingsChange(newGeneralSettings)
  }

  useEffect(() => {
    if (botName !== generalSettings?.botName) setBotName(generalSettings.botName)
    if (botDescription !== generalSettings?.botDescription) setBotDescription(generalSettings.botDescription)
    if (botUrl !== generalSettings?.botUrl) setBotUrl(generalSettings.botUrl)
  }, [generalSettings])

  return (
    <div id='general-settings' className={classes.generalContainer}>
      <Typography className={classes.heading} variant='h2'>
        Allgemeine Einstellungen
      </Typography>
      <div className={classes.content}>
        <div className={classes.settingWrapperWithMargin}>
          <Textfield
            label='Bot Name'
            value={botName}
            onChange={onBotNameChange}
            placeholder='Bot Name'
            fullWidth
            disabled={lockState !== 'canEdit'}
          />
        </div>
        <div className={classes.settingWrapperWithMargin}>
          <Textfield
            label='Bot Beschreibung'
            value={botDescription}
            onChange={onBotDescriptionChange}
            placeholder='Bot Beschreibung'
            fullWidth
            disabled={lockState !== 'canEdit'}
          />
        </div>
        <div className={classes.settingWrapperWithMargin}>
          <Textfield
            label='Bot URL'
            value={botUrl}
            onChange={onBotUrlChange}
            placeholder='Bot URL'
            fullWidth
            disabled={lockState !== 'canEdit'}
          />
        </div>
      </div>
    </div>
  )
}
