import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { useTheme } from '@mui/material/styles'
// Material ui
import Typography from '@mui/material/Typography'
// Custom Componets
import BaseCard from './BaseCard'

const useStyles = makeStyles()(() => ({
  botCard: {
    transition: 'box-shadow 0.3s',
    cursor: 'pointer',
    '&:hover': {
      boxShadow:
        '0 14px 26px -12px rgb(0 0 0 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)', // '0px 2px 10px 2px rgba(0,0,0, 0.25)'
    },
  },
}))

type CardProps = {
  botName?: string
  botId?: string
  active?: boolean
  botDescription?: string
  onClick?: (event: any) => void
}

/**
 * Base card for all cards in Studio 2.0.
 */
export default function BotCard({
  botName,
  botId,
  botDescription,
  active = false,
  onClick,
}: CardProps): React.ReactElement {
  const theme = useTheme()
  const { classes } = useStyles()
  return (
    <BaseCard
      title={botName}
      footerText={botId}
      footerColor={active ? theme.palette.success.main : theme.palette.grey['500']}
      height='220px'
      width='100%'
      className={classes.botCard}
      onClick={(event): void => {
        if (typeof onClick !== 'undefined') onClick(event)
      }}
      titleVariant='h3'
    >
      <Typography variant='body1'>{botDescription}</Typography>
    </BaseCard>
  )
}
