import { Typography, useTheme } from '@mui/material'
import CustomizedTooltip from 'components/Tooltips/CustomContentTooltip'
import React from 'react'

type TrafficLightProps = {
  size?: string
  color: 'red' | 'green' | 'yellow' | 'off' | string
  tooltips?: {
    red?: string
    green?: string
    yellow?: string
    off?: string
  }
}

export default function TrafficLight({ size = '24px', color, tooltips }: TrafficLightProps): React.ReactElement {
  const theme = useTheme()
  const lightsColor =
    color === 'red'
      ? theme.palette.error.main
      : color === 'green'
      ? theme.palette.success.main
      : color === 'yellow'
      ? theme.palette.warning.main
      : color === 'off'
      ? theme.palette.grey[200]
      : color
  const tooltipText = tooltips ? tooltips[color] : null

  return tooltipText ? (
    <CustomizedTooltip
      placement='top'
      content={<Typography>{tooltipText}</Typography>}
      elements={<div style={{ width: size, height: size, borderRadius: '50%', backgroundColor: lightsColor }} />}
    />
  ) : (
    <div style={{ width: size, height: size, borderRadius: '50%', backgroundColor: lightsColor }} />
  )
}
