// We disable no-explicit-any because the MUI autocomplete component requires any type
/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react'
import { makeStyles } from 'tss-react/mui'

import { Autocomplete, FilterOptionsState } from '@mui/material'
import { Textfield as TextField } from '../TextInput/Textfield'

type StyleProps = {
  disabled?: boolean
}

// Arrow function has parameter type of Identifier instead of ObjectPattern (e.g. `(props) => ({...})` instead of `({color}) => ({...})`).
const useStyles = makeStyles<StyleProps>()((theme, props) => ({
  // NOTE: input (textfield) has the same style as our textfield component
  inputRoot: {
    flexWrap: 'wrap',
    color: 'red',
    '&:hover': {},
  },
  root: {
    background: `${props.disabled ? 'rgba(0, 0, 0, 0.05)' : undefined}`,
  },
  option: {
    color: theme.palette.primary.main,
    fontSize: 12,
    paddingTop: 12,
    paddingBottom: 12,
    fontWeight: 200,
    '&:hover': {
      color: 'green',
    },
  },
  noOptions: {
    textAlign: 'center',
    fontStyle: 'italic',
    fontSize: 12,
  },
  tag: {
    border: 'none',
    height: '20px',
    borderRadius: '10px',
    '& .MuiChip-label': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      fontSize: '12px',
      paddingLeft: '7px',
      paddingRight: '7px',
      textOverflow: 'ellipsis',
    },
    '& .MuiChip-deleteIcon': {
      width: '15px',
      height: '15px',
      cursor: 'pointer',
      marginLeft: '-5px',
      marginRight: '5px',
      '-webkit-tap-highlight-color': 'transparent',
    },
  },
}))

export interface Option {
  label: string
  value: any
  disabled?: boolean
}

type DropdownProps<T> = {
  onChange: (event: object, options: T[], reason: string) => void // always returns options as list, if only single select list has one item
  options: T[]
  placeholder: string
  selectedValues?: T[]
  multiple?: boolean
  onClose?: (event: object, reason: string) => void
  disableClearable?: boolean
  filterOptions?: (options: T[], state: FilterOptionsState<T>) => T[]
  filterSelectedOptions?: boolean // hides selected options from dropdown,
  creatable?: boolean
  disabled?: boolean
  disableCloseOnSelect?: boolean
}

/**
 * @since 0.0.1
 * IDEA: This component could be extended to group options in the dropdown menu (see: MUI Autocomplete component)
 * TODO: We could replace this component with the react-select component which offers better usability than the MUI Autocomplete component
 * @deprecated
 */
export function Dropdown({
  onChange,
  options,
  placeholder,
  selectedValues,
  multiple,
  onClose,
  disableClearable,
  filterSelectedOptions,
  creatable,
  disabled,
  disableCloseOnSelect,
}: DropdownProps<Option>): React.ReactElement {
  const { classes } = useStyles({ disabled: !!disabled })

  /**
   * We provide the actual option object(s) in the onChange callback, not just the value of the textfield (i.e. option title).
   * If a option has no value, we set the title as value.
   *
   * @param event The event source of the callback.
   * @param value The new value of the text input. Array of objects is multi selection, else only object
   * @param reason Can be: "input" (user input), "reset" (programmatic change), "clear"
   */
  function onInputChange(event: object, value: any, reason: string): void {
    if (!value) {
      // value is null if single select and value is removed
      onChange(event, [], reason)
    } else {
      if (multiple && Array.isArray(value)) {
        onChange(event, value, reason)
      } else {
        onChange(event, [value], reason)
      }
    }
  }

  /**
   * Returns label of option for display in the dropdown menu.
   * NOTE: We have to differentiate if option is array of option objects or option object.
   * If multiple is true: option is a single option object, if only single select we have an array with one Option object.
   * Not quite sure why that's the case probably because the Autocomplete component delivers its complete value if multiple is false.
   * For now this works. ~ Stefan
   * @param option
   */
  function getOptionsLabel(option: any): string {
    if (Array.isArray(option) && option.length > 0) {
      return option[0].label
    } else {
      return option.label ?? ''
    }
  }

  function isOptionEqualToValue(option: Option, value: any): boolean {
    if (Array.isArray(value) && value.length > 0) {
      return value[0].value === option.value
    } else {
      return value.value === option.value
    }
  }

  function getOptionsDisabled(option: Option): boolean {
    return option.disabled === true
  }

  function renderInput(params: object): React.ReactNode {
    // eslint-disable-next-line react/prop-types
    return <TextField {...params} disabled={true} placeholder={placeholder} />
  }

  return (
    <Autocomplete
      // id='dropdown'
      value={selectedValues || []}
      classes={classes}
      multiple={multiple}
      options={options}
      getOptionLabel={getOptionsLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionDisabled={getOptionsDisabled}
      onChange={onInputChange}
      renderInput={renderInput}
      onClose={onClose}
      disableClearable={
        disableClearable !== undefined ? disableClearable : !selectedValues || selectedValues.length === 0
      }
      disabled={disabled}
      filterSelectedOptions={filterSelectedOptions}
      freeSolo={creatable}
      closeText={'Close'}
      openText={'Open'}
      clearText={'Clear'}
      disableCloseOnSelect={disableCloseOnSelect}
    />
  )
}
