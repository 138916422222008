import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useTheme } from '@mui/material/styles'
import Fuse from 'fuse.js'
import ReactMarkdown from 'react-markdown'
import CustomizedTooltip from 'components/Tooltips/CustomContentTooltip'
import { Answer } from 'classes/Knowledge'

import Table from 'components/Table/Table'
import { IconButton, Typography } from '@mui/material'
import { AssignUtteranceDialogScreen } from './AssignUtteranceDialog'
import Message from 'components/Webchat/Message'
import { useAnswers } from 'hooks/contexts/answers-context'
import { useLockingContext } from 'hooks/contexts/locking-context'

const useStyles = makeStyles()((theme) => ({
  listViewContainer: {
    height: '100%',
    marginBottom: '50px', // add margin to prevent FAB button from floating above controls
  },
  answerText: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '120px',
      wordBreak: 'break-word',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '300px',
      wordBreak: 'break-word',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '500px',
      wordBreak: 'break-word',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '800px',
      wordBreak: 'break-word',
    },
  },
  markdown: {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical',
  },
  iconButton: {
    marginLeft: theme.spacing(1),
    marginBottom: 'auto',
    marginTop: 'auto',
    padding: theme.spacing(1),
    color: theme.palette.common.white,

    '&:disabled': {
      backgroundColor: theme.palette.grey[400],
      color: theme.palette.common.white,
    },
  },
  iconButtonDetails: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  iconButtonSelect: {
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.light,
    },
  },
  icon: { fontSize: '1.2rem' },
  detailsViewContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
  },
  detailsViewContent: {
    marginTop: 'auto',
    marginBottom: 'auto',
    display: 'flex',
  },
  containerLeft: {
    width: '50%',
    marginTop: 'auto',
    marginBottom: 'auto',
    paddingRight: theme.spacing(1),
  },
  containerRight: {
    width: '50%',
    marginLeft: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
    paddingLeft: theme.spacing(1),
  },
}))

type AssignUtteranceDialogTopicAnswersProps = {
  answers: { [answerId: string]: Answer } // all answers
  selectedTopic: string // selected topic for which answers should be shown
  onSelectAnswer: (answerId: string, selectedFrom: AssignUtteranceDialogScreen) => void
  onShowDetailsView: (answersForDetails: Answer, onBackClick: () => void) => void // callback to indicate that details view for answer is shown
}

export default function AssignUtteranceDialogTopicAnswers({
  answers: answersProps,
  selectedTopic,
  onSelectAnswer: onSelectAnswerCallback,
  onShowDetailsView,
}: AssignUtteranceDialogTopicAnswersProps): React.ReactElement {
  const { classes } = useStyles()
  const { lockState } = useLockingContext()
  const { canIEditAnswer, canIDeleteAnswer } = useAnswers()
  const [view, setView] = useState<'list' | 'details'>('list')
  const [answers, setAnswers] = useState<Answer[]>()
  // list
  const [displayedAnswers, setDisplayedAnswers] = useState<Answer[]>([])
  // details
  const [answerForDetails, setAnswerForDetails] = useState<Answer>()

  /**
   * Handles back click from details view
   */
  function onDetailsViewBackClick(): void {
    setAnswerForDetails(undefined)
    setView('list')
  }

  /**
   * Sets details view for a specific answer
   */
  function onClickDetailsView(answer: Answer): void {
    setAnswerForDetails(answer)
    setView('details')
    // callback to let parent component know details view is shown (needed for title, actions and backbutton behaviour)
    // gives callback function that parent component should run when back button is clicked
    onShowDetailsView(answer, onDetailsViewBackClick)
  }

  /**
   * Handles answer selection
   * @param answerId
   */
  function onSelectAnswer(answerId: string): void {
    onSelectAnswerCallback(answerId, 'answersOfTopic')
  }

  useEffect(
    function () {
      const answers = Object.values(answersProps)
      const answersToShow = answers.filter((answer) => answer.topic === selectedTopic)
      setDisplayedAnswers(answersToShow)
      setAnswers(answers)
    },
    [selectedTopic, answersProps],
  )

  return view === 'list' ? (
    <div className={classes.listViewContainer}>
      <Table
        headers={['Titel', 'Antwort', 'Score', 'Aktionen']}
        width={['20%', '50%', '10%', '20%']}
        padding='medium'
        rows={displayedAnswers.map((answer, idx) => {
          return [
            <>
              {/* <Typography key={`answer-topic-${idx}`} variant='small'>
                  {answer.topic ?? ''}
                </Typography> */}
              <Typography key={`answer-title-${idx}`}>
                {answer.answerType === 'answer' ? answer.title : answer.triggerDialogName}
              </Typography>
            </>,
            <Typography key={`answer-text-${idx}`} className={classes.answerText}>
              <ReactMarkdown className={classes.markdown}>{answer.answer}</ReactMarkdown>
              {/* {answer.answer.length > 160 ? answer.answer.substring(0, 160) + '...' :  */}
              {/* } */}
            </Typography>,
            <Typography key={`answer-score-${idx}`}>
              {answer.score ? `${Math.round(answer.score * 1000) / 10} %` : '-'}
            </Typography>,
            <div key={`answer-details-${idx}`}>
              <CustomizedTooltip
                placement='top'
                disableInteractive
                content={<Typography>Details anzeigen</Typography>}
                elements={
                  <IconButton
                    onClick={(): void => onClickDetailsView(answer)}
                    aria-label='details'
                    className={classes.iconButton + ' ' + classes.iconButtonDetails}
                  >
                    <i className={'ri-search-eye-line ' + classes.icon}></i>
                  </IconButton>
                }
              />
              <CustomizedTooltip
                placement='top'
                disableInteractive
                content={<Typography>Auswählen</Typography>}
                elements={
                  <IconButton
                    onClick={(): void => onSelectAnswer(answer.answerId)}
                    aria-label='confirm'
                    className={classes.iconButton + ' ' + classes.iconButtonSelect}
                    disabled={lockState !== 'canEdit' || !canIEditAnswer(answer)}
                  >
                    <i className={'ri-check-line ' + classes.icon}></i>
                  </IconButton>
                }
              />
            </div>,
          ]
        })}
        cellAlignPattern={['left', 'left', 'right', 'right']}
        rowsPerPage={5}
      />
    </div>
  ) : (
    <div className={classes.detailsViewContainer}>
      <div className={classes.detailsViewContent}>
        <div className={classes.containerLeft}>
          <Message
            markdownString={answerForDetails?.answer ?? answerForDetails?.answerTemplate ?? '*Keine Antwort vorhanden*'}
          />
        </div>
        <div className={classes.containerRight}>
          <Table
            headers={['Fragen']}
            width={['100%']}
            padding='medium'
            rows={(answerForDetails?.labels ?? []).map((utterance, idx) => {
              return [<Typography key={`answer-label-${idx}`}>{utterance}</Typography>]
            })}
            cellAlignPattern={['left']}
            rowsPerPage={5}
          />
        </div>
      </div>
    </div>
  )
}
