import React, { useState, useEffect } from 'react'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  TextField,
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  AutocompleteRenderInputParams,
} from '@mui/material'

import { Chart } from '../../../../../../@types/Flowchart/types'

type SAPIRequestVariableSelectionDialogProps = {
  open: boolean
  chart: Chart
  onClose: () => void
  onConfirm: (varName: string) => void
}

export default function SAPIRequestVariableSelectionDialog({
  open,
  chart,
  onClose,
  onConfirm,
}: SAPIRequestVariableSelectionDialogProps): React.ReactElement {
  const [selectedVariableDisplayName, setSelectedVariableDisplayName] = useState<string | null>(null)
  const [variableDisplayNameOptions, setVariableDisplayNameOptions] = useState<string[]>([])
  const [show, setShow] = useState(false)

  function onDialogClose(): void {
    setShow(false)
    onClose()
  }

  /**
   * Handles dialog confirm button click.
   * Calls confirm callback with selected variable name.
   */
  function onDialogConfirm(): void {
    if (!selectedVariableDisplayName) return

    onConfirm(selectedVariableDisplayName)
  }

  function onVariableSelect(
    event: React.ChangeEvent<{}>,
    newValue: string | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails,
  ): void {
    setSelectedVariableDisplayName(newValue)
  }

  useEffect(function () {
    // prepare variable options
    // use all variables, except loop indexes
    const varDisplayNames: string[] = []
    Object.values(chart.variables).forEach((variable) => {
      if (typeof variable.loopId === 'undefined') {
        varDisplayNames.push(variable.displayName)
      }
    })
    setVariableDisplayNameOptions(varDisplayNames)
  }, [])

  useEffect(
    function () {
      setShow(open)
    },
    [open],
  )

  return (
    <Dialog fullWidth onClose={onDialogClose} maxWidth={'md'} open={show} disableEscapeKeyDown={false}>
      <DialogTitle>Variable auswählen</DialogTitle>
      <DialogContent>
        <Typography>Wählen Sie die Variable aus, die in den Code Editor eingefügt werden soll.</Typography>
        <Autocomplete
          value={selectedVariableDisplayName}
          options={variableDisplayNameOptions}
          onChange={onVariableSelect}
          renderInput={(params: AutocompleteRenderInputParams): React.ReactElement => (
            <TextField {...params} label={'Variable'} />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onDialogConfirm}>Hinzufügen</Button>
      </DialogActions>
    </Dialog>
  )
}
