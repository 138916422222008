import { Typography } from '@mui/material'
import { Textfield } from 'components/TextInput/Textfield'
import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { BotSettings } from '../../@types/Settings/types'
import { useLockingContext } from 'hooks/contexts/locking-context'
import { getAccount } from 'components/AuthProvider/PublicClientApp'
import { CONVAISE_CUSTOMER_ID } from 'utils/constants'

type Colors = {
  primaryColor?: string
  primaryColorText?: string
  disabledColor?: string
}

const useStyles = makeStyles()((theme) => ({
  content: {},
  buttons: { display: 'flex' },
  button: { width: '180px', margin: `0 ${theme.spacing(1)}` },
  heading: { margin: `${theme.spacing(2)} 0`, marginBottom: theme.spacing(3) },
  row: { display: 'flex' },
  keyTypography: { margin: 'auto 0' },
  legalContainer: { marginTop: theme.spacing(5) },
  stylesContainerContent: { display: 'flex', flexDirection: 'column' },
  avatarContainer: { marginTop: theme.spacing(2), width: '100%', marginBottom: theme.spacing(2) },
  settingWrapperWithMargin: { marginTop: theme.spacing(2) },
  colorsContainer: {
    maxWidht: '200px',
    maxHeight: '200px',
  },
  avatarUpload: {
    height: '200px',
    width: '200px',
    border: '3px dotted grey',
    display: 'flex',
  },
  avatarImage: {
    width: '100%',
    height: 'fit-content',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  colorPreview: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: theme.spacing(1),
    width: '40px',
    height: '40px',
    borderRadius: '50%',
  },
}))

type LegalSettingsProps = {
  legalSettings: BotSettings['legalTexts']
  onLegalSettingsChange: (newLegalSettings: BotSettings['legalTexts']) => void
}

export default function LegalSettings({
  legalSettings,
  onLegalSettingsChange,
}: LegalSettingsProps): React.ReactElement {
  const { classes } = useStyles()
  const { lockState } = useLockingContext()
  const account = getAccount()
  const customerIdOfCurrentUser = account?.idTokenClaims ? account?.idTokenClaims['extension_CustomerID'] : undefined
  const IS_CURRENT_USER_CONVAISE_ADMIN = customerIdOfCurrentUser === CONVAISE_CUSTOMER_ID

  // Custom styles
  const [dataprotectionUrl, setDataprotectionUrl] = useState<string>('')
  const [termsOfUseSubpath, setTermsOfUseSubpath] = useState<string>('')
  const [imprintUrl, setImprintUrl] = useState<string>('')

  function onDataprotectionUrlChange(e: React.ChangeEvent<HTMLInputElement>): void {
    setDataprotectionUrl(e.target.value)
    const newLegalSettings: BotSettings['legalTexts'] = {
      ...(legalSettings ?? { dataprotectionUrl: '' }),
      dataprotectionUrl: e.target.value,
    }
    onLegalSettingsChange(newLegalSettings)
  }

  function onTermsOfUseSubpathChange(e: React.ChangeEvent<HTMLInputElement>): void {
    setTermsOfUseSubpath(e.target.value)
    const newLegalSettings: BotSettings['legalTexts'] = {
      ...(legalSettings ?? { termsOfUseSubpath: '' }),
      termsOfUseSubpath: e.target.value,
    }
    onLegalSettingsChange(newLegalSettings)
  }

  function onImprintUrlChange(e: React.ChangeEvent<HTMLInputElement>): void {
    setImprintUrl(e.target.value)
    const newLegalSettings: BotSettings['legalTexts'] = {
      ...(legalSettings ?? { imprintUrl: '' }),
      imprintUrl: e.target.value,
    }
    onLegalSettingsChange(newLegalSettings)
  }

  useEffect(() => {
    if (dataprotectionUrl !== legalSettings?.dataprotectionUrl)
      setDataprotectionUrl(legalSettings?.dataprotectionUrl ?? 'https://convaise.com/datenschutzhinweise')
    if (termsOfUseSubpath !== legalSettings?.termsOfUseSubpath)
      setTermsOfUseSubpath(legalSettings?.termsOfUseSubpath ?? '')
    if (imprintUrl !== legalSettings?.imprintUrl)
      setImprintUrl(legalSettings?.imprintUrl ?? 'https://convaise.com/impressum')
  }, [legalSettings])

  return (
    <div id='legal-settings' className={classes.legalContainer}>
      <Typography className={classes.heading} variant='h2'>
        Rechtliche Einstellungen
      </Typography>
      <div className={classes.content}>
        <div className={classes.settingWrapperWithMargin}>
          <Textfield
            label='Datenschutzerklärung URL'
            value={dataprotectionUrl}
            onChange={onDataprotectionUrlChange}
            placeholder='Datenschutz URL'
            fullWidth
            disabled={lockState !== 'canEdit'}
            hint='URL der für den Assistenten gültigen Datenschutzerklärung. Leitet die Nutzer*innen bei Klick auf "Datenschutz" im Chatinterface auf die verlinkte Seite.'
          />
        </div>
        <div className={classes.settingWrapperWithMargin}>
          <Textfield
            label='Impressum URL'
            value={imprintUrl}
            onChange={onImprintUrlChange}
            placeholder='Impressum URL'
            fullWidth
            disabled={lockState !== 'canEdit'}
            hint='URL des für den Assistenten gültigen Impressums. Leitet die Nutzer*innen bei Klick auf "Impressum" im Chatinterface auf die verlinkte Seite.'
          />
        </div>
        {IS_CURRENT_USER_CONVAISE_ADMIN && (
          <div className={classes.settingWrapperWithMargin}>
            <Textfield
              label='Nutzungsbedingungen (Subpfad)'
              value={termsOfUseSubpath}
              onChange={onTermsOfUseSubpathChange}
              placeholder='Pfad der Nutzungsbedingungen'
              fullWidth
              disabled={lockState !== 'canEdit'}
              hint='Pfad der für den Assistenten gültigen Nutzungsbedingungen. Leitet die Nutzer*innen bei Klick auf "Nutzungsbedingungen" im Chatinterface auf die verlinkte Seite. ACHTUNG: AKTUELL MÜSSEN DIE NUTZUNGSBEDINGUNGEN MANUELL VON CONVAISE ANGELEGT WERDEN BEVOR AUF SIE VERLINKT WERDEN KANN.'
            />
          </div>
        )}
      </div>
    </div>
  )
}
