import { Typography } from '@mui/material'
import { LLMNLUEndpointUtterance } from '../../../../../@types/Knowledge/types'
import Table from 'components/Table/Table'
import TrafficLight from 'components/TrafficLight/TrafficLight'
import { useAnswers } from 'hooks/contexts/answers-context'
import React, { useEffect, useState } from 'react'
import { SelectDropdown, Option } from 'components/Dropdown'
import { useBotContext } from 'hooks/contexts/bot-context'
import { CustomIconButton } from 'components/Buttons'
import { useLockingContext } from 'hooks/contexts/locking-context'
import { Answer } from 'classes/Knowledge'

type AnswerSuggestionDrowdownProps = {
  endpointUtterance: LLMNLUEndpointUtterance
  onSelectSuggestion: (utterance: LLMNLUEndpointUtterance, selectedAnswer: Answer) => void
}

function AnswerSuggestionDropdown({
  endpointUtterance,
  onSelectSuggestion,
}: AnswerSuggestionDrowdownProps): React.ReactElement {
  const { bot } = useBotContext()
  const { flaggedAnswers } = useAnswers()
  const [options, setOptions] = useState<Option[]>([])
  const [selected, setSelected] = useState<string>()

  function onSelect(newValue: Option, action: any): void {
    if (!bot || !flaggedAnswers) return
    const selectedAnswerId = newValue.value
    const answer = flaggedAnswers.answers[bot.primaryLanguage][selectedAnswerId]
    onSelectSuggestion(endpointUtterance, answer as Answer)
  }

  useEffect(
    function prepareOptionsAndInitialSelected() {
      if (!flaggedAnswers || !bot) return
      const options: Option[] = []
      for (const pred of endpointUtterance.topPredictions) {
        const answerId = pred.answerId
        const answer = flaggedAnswers.answers[bot.primaryLanguage][answerId]
        if (answer) {
          options.push({
            label: `${answer.topic} - ${answer.title}`,
            sublabel: answer.answer ?? undefined,
            value: answerId,
          })
        }
      }
      setSelected(endpointUtterance.suggestedAnswer?.answerId)
      setOptions(options)
    },
    [endpointUtterance, flaggedAnswers, bot],
  )

  return (
    <SelectDropdown
      options={options}
      selected={selected}
      onChange={onSelect}
      isClearable={false}
      isCreatable={false}
      isDisabled={false}
      placeholder='Keine vorgeschlagene Antwort'
      sublabelType='markdown'
      allowLargeOptions
    />
  )
}

type ModellManagementLLMNLUTableProps = {
  endpointQuestions: LLMNLUEndpointUtterance[]
  onSelectSuggestion: (endpointUtterance: LLMNLUEndpointUtterance, selectedAnswerSuggestion: Answer) => void
  onDeleteUtterance: (endpointUtterance: LLMNLUEndpointUtterance) => void
  onEditUtterance: (endpointUtterance: LLMNLUEndpointUtterance) => void
  onAddUtteranceToSuggestedAnswer: (endpointUtterance: LLMNLUEndpointUtterance) => void
}

export default function ModellManagementLLMNLUTable({
  endpointQuestions,
  onSelectSuggestion,
  onDeleteUtterance,
  onEditUtterance,
  onAddUtteranceToSuggestedAnswer,
}: ModellManagementLLMNLUTableProps): React.ReactElement {
  const { lockState } = useLockingContext('knowledgeNlu')
  const { canIEditAnswer } = useAnswers()

  return (
    <Table
      headers={['', 'Frage', 'Vorschlag', 'Aktionen']}
      cellAlignPattern={['left', 'left', 'left', 'right']}
      width={['2%', '45%', '45px', '8%']}
      padding='medium'
      disablePagination
      rows={(endpointQuestions ?? []).map((utterance, idx) => {
        return [
          <TrafficLight
            key={`utt-tl-${idx}`}
            size='16px'
            color={utterance.suggestionChangedByUser ? 'off' : utterance.trafficLightsColor}
            tooltips={{
              red: 'Das Modell ist sich sehr unsicher und kann keine Antwort auf diese Frage finden.',
              yellow: 'Das Modell hat mehrere mögliche Antworten, kann aber keine Antwort mit großer Sicherheit geben.',
              green: 'Das Modell ist sich sehr sicher und würde mit der vorgeschlagenen Antwort antworten.',
              off: 'Der Vorschlag wurde manuell geändert und unterscheidet sich möglicherweise vom Vorschlag des Modells.',
            }}
          />,
          <Typography key={`utt-text-${idx}`}>{utterance.endpointUtterance}</Typography>,
          <AnswerSuggestionDropdown
            key={`utt-sugg-${idx}`}
            endpointUtterance={utterance}
            onSelectSuggestion={onSelectSuggestion}
          />,
          <div key={`utt-actions-${idx}`} style={{ display: 'flex' }}>
            <div style={{ marginLeft: 'auto' }}>
              <CustomIconButton
                type='decline'
                tooltip='Nutzerfrage löschen'
                onClick={(): void => onDeleteUtterance(utterance)}
                disabled={lockState === 'isBlocked'}
              />
            </div>
            <div style={{ marginLeft: '16px' }}>
              <CustomIconButton
                type='accept'
                tooltip='Vorschlag übernehmen'
                onClick={(): void => onAddUtteranceToSuggestedAnswer(utterance)}
                disabled={
                  lockState === 'isBlocked' ||
                  !utterance.suggestedAnswer ||
                  (utterance.suggestedAnswer ? !canIEditAnswer(utterance.suggestedAnswer?.answerId) : false)
                }
              />
            </div>
            <div style={{ marginLeft: '16px' }}>
              <CustomIconButton
                tooltip='Anpassen'
                type='default'
                fill={true}
                onClick={(): void => onEditUtterance(utterance)}
                disabled={lockState === 'isBlocked'}
                icon='ri-pencil-line'
              />
            </div>
          </div>,
        ]
      })}
    />
  )
}
