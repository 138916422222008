import React from 'react'
import CustomizedTooltip from '../Tooltips/CustomContentTooltip'
import { makeStyles } from 'tss-react/mui'
import ToggleButton from '@mui/material/ToggleButton'

const useStyles = makeStyles()((theme) => ({
  root: {
    marginBlockEnd: theme.spacing(2),
    width: '62px',
    height: '62px',
  },
  selected: {
    border: `1px solid ${theme.palette.primary}`,
  },
}))

type StandaloneToggleButtonProps = {
  value: string
  selected: boolean
  onChange: any // TODO: fix type
  icon: React.ReactElement
  tooltip?: React.ReactElement
  disabled?: boolean
}

function StandaloneToggleButton(props: StandaloneToggleButtonProps): React.ReactElement {
  const { classes } = useStyles()

  return props.tooltip != null ? (
    <CustomizedTooltip
      content={props.tooltip}
      elements={
        <ToggleButton
          classes={classes}
          disabled={props.disabled}
          value={props.value}
          selected={props.selected}
          onChange={(e): void => props.onChange(e)}
        >
          {props.icon}
        </ToggleButton>
      }
    />
  ) : (
    <ToggleButton
      classes={classes}
      disabled={props.disabled}
      value={props.value}
      selected={props.selected}
      onChange={(e): void => props.onChange(e)}
    >
      {props.icon}
    </ToggleButton>
  )
}

StandaloneToggleButton.defaultProps = {
  selected: false,
  disabled: false,
}

export default StandaloneToggleButton
