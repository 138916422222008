import React, { useState, useEffect } from 'react'
import { makeStyles } from 'tss-react/mui'

import ToggleButtonGroup from './ToggleButton/ToggleButtonGroup'

import PerfectScrollbar from 'react-perfect-scrollbar'

import {
  BasicAdaptiveCard,
  BasicApi,
  LogicIfElse,
  LogicSwitch,
  LogicJump,
  LogicSetVariables,
  LogicStartDialog,
  BasicPDFGeneration,
  BasicFileUpload,
  Trigger,
  EventTrigger,
  QnATrigger,
  AnalyticsTrigger,
  Note,
  XzufiGetAnswer,
  XzufiTrackHelpful,
  XzufiTrackSelectedAnswer,
  QnAGetAnswer,
  QnAStartTriggerDialog,
  QnATrackHelpful,
  AlephAlphaGetAnswer,
  AlephAlphaTrackHelpful,
  LLMTask,
} from './SidebarNodes'
import { useBotContext } from '../../../hooks/contexts/bot-context'

// Types
import { Chart } from '../../../@types/Flowchart/types'
import { TranslationFile } from '../../../@types/Translations/types'

import { MODULE_TYPE_ALEPHALPHA, MODULE_TYPE_LLM, MODULE_TYPE_NLU, MODULE_TYPE_XZUFI } from 'utils/constants'

const useStyles = makeStyles()((theme) => ({
  sidebarContainer: {
    position: 'absolute',
    borderRadius: theme.shape.borderRadius,
    border: '1px solid rgba(0,0,0,0.1)',
    top: 0,
    bottom: 0,
    left: 0,
    width: '250px',
    background: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    // overflowX: 'hidden',
    // padding: '10px',
    boxShadow:
      'rgba(0, 0, 0, 0.2) 0px 2px 4px -2px, rgba(0, 0, 0, 0.14) 0px 4px 5px -4px, rgba(0, 0, 0, 0.12) 0px 1px 10px -3px',
    zIndex: 4,
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: theme.spacing(1),
    height: '700px',
  },
  actionsHeader: { margin: '0px', padding: '10px' },
  circularSpinner: {
    color: '#FFF',
    textAlign: 'center',
    margin: '5px',
  },
  toggleButtonContainer: {
    width: '100%',
    alignContent: 'center',
    marginTop: '20px',
    // padding: '10px',
  },
  elementsContainer: { padding: '10px' },
}))

function SimpleView(): React.ReactElement {
  const { bot } = useBotContext()
  const [showFileUploadNode, setShowFileUploadNode] = useState<boolean>(false)

  useEffect(
    function () {
      if (bot) {
        if (bot.webchatVersion === 'v4') {
          setShowFileUploadNode(true)
        }
      }
    },
    [bot],
  )

  return (
    <>
      {/* <BasicMessage /> */}
      {/* <BasicQuestionButton /> */}
      {/* <BasicQuestionFree /> */}
      <BasicAdaptiveCard />
      <Note />
      <LogicSwitch />
      <LogicIfElse />
      {/* <LogicSetVar /> */}
      <LogicSetVariables />
      <LogicStartDialog />
      <LogicJump />
      {showFileUploadNode && <BasicFileUpload />}
    </>
  )
}

function AdvancedView(): React.ReactElement {
  const { bot, hasModule } = useBotContext()
  const [showXzufiNodes, setShowXzufiNodes] = useState<boolean>(false)
  const [showQnANodes, setShowQnANodes] = useState<boolean>(false)
  const [showAlephAlphaNodes, setShowAlephAlphaNodes] = useState<boolean>(false)
  const [showTriggerQnAMode, setShowTriggerQnAMode] = useState<boolean>(true)
  const [showLLMNodes, setShowLLMNode] = useState<boolean>(false)

  useEffect(
    function () {
      if (bot) {
        // const moduleTypes = Object.values(bot.modules).map((module) => module.type)
        if (hasModule(MODULE_TYPE_XZUFI)) {
          setShowXzufiNodes(true)
        } else {
          setShowXzufiNodes(false)
        }
        if (hasModule(MODULE_TYPE_NLU)) {
          setShowQnANodes(true)
        } else {
          setShowQnANodes(false)
        }
        if (hasModule(MODULE_TYPE_ALEPHALPHA)) {
          setShowAlephAlphaNodes(true)
        } else {
          setShowAlephAlphaNodes(false)
        }
        if (hasModule(MODULE_TYPE_LLM)) {
          setShowLLMNode(true)
        } else {
          setShowLLMNode(false)
        }
        if (bot.webchatVersion === 'v4') {
          // trigger qna mode is only supported in the old webchat where process and qna shared a view
          setShowTriggerQnAMode(false)
        }
      }
    },
    [bot],
  )

  return (
    <>
      <BasicApi />
      {/* {bot.nlu && bot.nlu.primary !== null && <QnAAnswer />}  */}
      {showQnANodes && <Trigger />}
      <EventTrigger />
      {showQnANodes && showTriggerQnAMode && <QnATrigger />}
      <AnalyticsTrigger />
      {showLLMNodes && <LLMTask />}
      {showQnANodes && <QnAGetAnswer />}
      {showQnANodes && <QnAStartTriggerDialog />}
      {showQnANodes && <QnATrackHelpful />}
      {/* {showXzufiNodes && <XzufiGetAnswerOld />} */}
      {showXzufiNodes && <XzufiGetAnswer />}
      {showXzufiNodes && <XzufiTrackSelectedAnswer />}
      {showXzufiNodes && <XzufiTrackHelpful />}
      {showAlephAlphaNodes && <AlephAlphaGetAnswer />}
      {showAlephAlphaNodes && <AlephAlphaTrackHelpful />}
      {/* No check for document type - if we add futher document types we should check if we combine them in the BasicPDFGeneration node (renaming it) */}
      {bot && Object.values(bot.documentInfos).length > 0 && <BasicPDFGeneration />}
      {/* <BasicPicture /> */}
    </>
  )
}

type ItemSidebarProps = {
  // setStateCallback: (Chart: Chart) => void
  chart: Chart
  translationFile: TranslationFile
  saveTranslations: boolean
  resetSaveTranslationsCallback: () => void
}

export default function ItemSidebar(props: ItemSidebarProps): React.ReactElement {
  const { saveTranslations, resetSaveTranslationsCallback } = props
  const { classes } = useStyles()
  const [chart, setChart] = useState<Chart>()
  const [loading, setLoading] = useState<
    'saving' | 'saved' | 'savingError' | 'publishing' | 'published' | 'publishError' | undefined
  >()

  /**
   *
   * Replacer function that removes object that cause cyclic structure.
   * Removes properties that are added once a node has been moved in the
   */
  function replacer(k: string, val: string): string | undefined {
    switch (k) {
      case 'node':
      case 'deltaX':
      case 'deltaY':
      case 'lastX':
      case 'lastY':
        return undefined
      default:
        return val
    }
  }

  /**
   * Toggles toggle button. Switches between simple and advanced view
   * @param view
   */
  function onToggleButtonClick(view: 'simple' | 'advanced'): void {
    if (typeof chart === 'undefined') return
    chart.view = view
    setChart({ ...chart })
  }

  // ======== USE EFFECTS =========
  // useEffect(
  //   function () {
  //     setChart(cloneDeep(props.chart))
  //   },
  //   [props.chart]
  // )

  useEffect(function () {
    props.chart.view = 'simple'
    setChart(props.chart)
  }, [])

  // ======== RENDER =========

  return (
    <div className={classes.sidebarContainer}>
      <div className={classes.toggleButtonContainer}>
        <ToggleButtonGroup onChange={onToggleButtonClick} initialState={chart?.view || 'simple'} />
      </div>
      <PerfectScrollbar style={{ paddingTop: '3rem' }} options={{ suppressScrollX: true, minScrollbarLength: 20 }}>
        <div className={classes.elementsContainer}>
          {!chart || chart.view === 'simple' ? <SimpleView /> : <AdvancedView />}
        </div>
      </PerfectScrollbar>
    </div>
  )
}
