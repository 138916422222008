import React, { useEffect, useRef } from 'react'
import 'chart.js/auto'
import { Chart as ChartJS, registerables } from 'chart.js/auto'
import { WordCloudChart, WordElement, WordCloudController } from 'chartjs-chart-wordcloud'
import { makeStyles } from 'tss-react/mui'
import { WordcloudData } from '../../../@types/Analytics/types'
import { isEmpty } from 'lodash'
import { LinearProgress, Typography } from '@mui/material'
WordElement.defaults.minRotation = 0
WordElement.defaults.maxRotation = 0
ChartJS.register(...registerables, WordCloudChart, WordCloudController, WordElement)

const useStyles = makeStyles()((props) => {
  return {
    container: { width: '100%', height: '100%' },
    linearProgressBarContainer: {
      display: 'flex',
      height: '100%',
    },
    linearProgressBar: {
      width: '80%',
      margin: 'auto',
    },
  }
})

interface WordCloudProps {
  data?: WordcloudData
  minFontSize?: number
  maxFontSize?: number
}

function WordCloudContent({ data, minFontSize = 12, maxFontSize = 50 }: WordCloudProps): React.ReactElement {
  const { classes } = useStyles()
  const isInitializedRef = useRef<boolean>(false)

  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const chartRef = useRef<any | null>(null)

  /**
   * Scales the value of a
   * @param minFontSize
   * @param maxFontSize
   * @param maxNumberInValues
   * @param currentValue
   */
  function scale(maxNumberInValues: number, currentValue: number, useLog?: boolean): number {
    if (currentValue === 1) return minFontSize

    if (!useLog) {
      // lineare scaling
      if (currentValue === 1) return minFontSize
      return (currentValue / maxNumberInValues) * (maxFontSize - minFontSize) + minFontSize
    } else {
      const scalingFactor = 1
      // logarithmic scaling
      const scaledRatio =
        Math.log10(currentValue * scalingFactor + 1) / Math.log10(maxNumberInValues * scalingFactor + 1)
      return scaledRatio * (maxFontSize - minFontSize) + minFontSize
    }
  }

  useEffect(() => {
    if (!canvasRef?.current || !data || isEmpty(data) || isInitializedRef.current) return

    isInitializedRef.current = true

    const labels = Object.keys(data)
    const values = Object.values(data)

    // sort so that we can use the top
    const combined = labels.map((label, index) => ({ label, value: values[index] }))
    combined.sort((a, b) => b.value - a.value)
    const selected = combined.slice(0, 400)

    const displayValues = selected.map((item) => item.value)
    const displayLabel = selected.map((item) => item.label)

    const maxValue = Math.max(...displayValues)
    const fontSizes = displayValues.map((value) => scale(maxValue, value))

    const canvas: HTMLCanvasElement = canvasRef.current
    const ctx = canvas.getContext('2d', { willReadFrequently: true })

    if (ctx) {
      if (chartRef.current) {
        chartRef.current.destroy()
      }
      chartRef.current = new WordCloudChart(ctx, {
        data: {
          // text
          labels: displayLabel,
          datasets: [
            {
              label: 'words',
              // size in pixel
              data: fontSizes,
            },
          ],
        },
        options: {
          font: {
            minRotation: 0,
          } as any,
          plugins: { tooltip: { enabled: false, filter: () => true }, legend: { display: false } },
          layout: { autoPadding: true, padding: 20 },
          scales: { x: { offset: true }, y: { offset: true } },
          responsive: true,
          maintainAspectRatio: false,
        },
      })
    }
  }, [data])

  return (
    <div className={classes.container}>
      <canvas ref={canvasRef} />
    </div>
  )
}

/**
 * Wordcloud component.
 * Displays up to 400 words to ensure they fit into canvas
 * @param param0
 * @returns
 */
export default function Wordcloud({ data, loading }: WordCloudProps & { loading: boolean }): React.ReactElement {
  const { classes } = useStyles()
  return loading || !data ? (
    <div className={classes.linearProgressBarContainer}>
      <LinearProgress className={classes.linearProgressBar} />
    </div>
  ) : isEmpty(data) ? (
    <div style={{ width: '100%', height: '100%', display: 'flex' }}>
      <Typography fontStyle={'italic'} style={{ margin: 'auto' }}>
        Nicht genug Daten vorhanden
      </Typography>
    </div>
  ) : (
    <div className={classes.container}>
      <WordCloudContent data={data} />
    </div>
  )
}
