import React, { useEffect, useState } from 'react'

import { cloneDeep, isEmpty, isEqual } from 'lodash'

import { makeStyles } from 'tss-react/mui'
import { Button, CircularProgress, Collapse, Grid, IconButton, TextField, Tooltip, Typography } from '@mui/material'

import { callProxyApi } from '../../../../../../api/StudioBackend'

import { findAllVariablesInString, replaceAllVariablesInStringWithValue } from '../../../../../../utils/chartUtils'

import { APITestResult, Chart, Variable } from '../../../../../../@types/Flowchart/types'
import { ArrowDropDown, ArrowDropUp, Cancel, CheckCircle } from '@mui/icons-material'

const useStyles = makeStyles()({
  button: {
    color: '#FFF',
    border: 'none',
    cursor: 'pointer',
    margin: '.3125rem 1px',
    padding: '12px 30px',
    position: 'relative',
    fontSize: '12px',
    minHeight: 'auto',
    minWidth: 'auto',
    maxWidth: '150px',
    textAlign: 'center',
    transition: 'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
    fontWeight: 400,
    textTransform: 'uppercase',
  },
  closeButton: {
    backgroundColor: '#3c4858',
    '&:hover,&:focus': {
      color: '#FFF',
      backgroundColor: '#3c4858',
      boxShadow: '0 14px 26px -12px rgba(60, 72, 88, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px',
    },
    '&:disabled': {
      color: '#FFF',
      backgroundColor: 'rgba(60,72,88,0.5)',
    },
  },
  circularSpinner: {
    color: '#FFF',
    textAlign: 'center',
    margin: '5px',
  },
  container: {},
  mockValuesContainer: {},
  gridContainer: {
    marginTop: '20px',
  },
  columnTitle: {
    fontWeight: 600,
  },
  variableText: {
    width: 'fit-content',
  },
  requestPreviewContainer: {},
  testApiContainer: {
    marginTop: '50px',
    textAlign: 'center',
  },
  apiResultContainer: {
    width: '100%',
    justifyContent: 'center',
  },
  apiResultTopContainer: {
    width: '75%',
    textAlign: 'center',
    marginTop: '25px',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    position: 'relative',
  },
  apiResultStatusContainer: {
    width: 'fit-content',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    left: 0,
    right: 0,
    margin: 'auto',
  },
  apiResultToggleDetails: {
    marginLeft: 'auto',
  },
  // apiResultStatus: {
  //   display: 'flex',
  //   justifyContent: 'center',
  // },
  apiResultDetailsContainer: {
    width: '75%',
    marginTop: '15px',
    display: 'inline-block',
    maxHeight: '500px',
    overflow: 'auto',
  },
  apiResultMessage: {
    padding: '10px',
    borderRadius: '5px',
  },
  apiResultJson: {
    textAlign: 'start',
  },
  apiResultMessageSuccess: {
    background: 'rgba(17, 206, 33, 0.2)',
  },
  apiResultMessageFailure: {
    background: 'rgba(249, 66, 0, 0.2)',
  },
})

type TemplateObject = { [variableId: string]: { variable: Variable; replaceValue: string } }

type TestRequest = {
  url: string
  method: 'get' | 'post' | 'delete' | 'put' | 'patch'
  headers?: { [key: string]: string }
  params?: { [key: string]: string }
  data?: string | object
}

type SAPITestProps = {
  chart: Chart
  setChartCallback: (chart: Chart) => void
}

export default function SAPITest({ chart, setChartCallback }: SAPITestProps): React.ReactElement {
  const { classes } = useStyles()
  const [status, setStatus] = useState<'waiting' | undefined>()
  const [testButtonEnabled, setTestButtonEnabled] = useState<boolean>(false)
  const [templateObject, setTemplateObject] = useState<TemplateObject>({})
  const [displayDetails, setDisplayDetails] = useState<boolean>(false)
  const [testRequest, setTestRequest] = useState<TestRequest>()
  const [apiTestResult, setApiTestResult] = useState<APITestResult>()
  // display key value pairs for all variables that need to be set in url, headers, params, body

  /**
   * Finds all variables that need to be set.
   * Checks url, headers, params and body of request configuration of node.
   * Based on these, the grid will be rendered.
   * @param chart
   */
  function prepareMockVariables(chart: Chart): void {
    const node = chart.nodes[chart.selected.id || '']
    if (typeof chart.selected.id === 'undefined' || typeof node === 'undefined') return
    const request = node.properties.api?.request
    if (typeof request === 'undefined') return

    const requestString = JSON.stringify(request)
    let variables = findAllVariablesInString(chart, requestString)
    variables = Array.from(new Set(variables))

    const vars: TemplateObject = {}
    for (const variable of variables) {
      vars[variable.id] = { variable, replaceValue: '' }
    }

    // fill with existing mock values if there are mock variable values in the chart
    if (typeof node.properties.api?.mockVariables !== 'undefined') {
      const varIds = Object.keys(vars)

      const mockVarIds = Object.keys(node.properties.api.mockVariables)
      for (const varId of mockVarIds) {
        if (varIds.includes(varId))
          // variable is still used in request, set replace value
          vars[varId].replaceValue = node.properties.api.mockVariables[varId]
      }
    }

    chart.nodes[chart.selected.id] = node

    setTemplateObject(vars)
  }

  /**
   * Prepares testReqeust object that is send when testing the API. Sets it into state.
   * If called with chart as parameter, uses chart to build object, else uses templateObject from state.
   * @param chart
   */
  function prepareTestRequestObject(_chart?: Chart): void {
    const localChart = _chart ? _chart : chart
    if (typeof localChart.selected.id === 'undefined') return
    const api = localChart.nodes[localChart.selected.id].properties.api
    if (typeof api === 'undefined') return

    // prepare template object, if chart is provided as param, we use the template in there
    // else we use the one from the state
    let templateObj = {}
    if (typeof _chart !== 'undefined') {
      templateObj = {}
      if (typeof api.mockVariables !== 'undefined') {
        for (const varId of Object.keys(api.mockVariables)) {
          templateObj[varId] = { replaceValue: api.mockVariables[varId] }
        }
      }
    } else {
      templateObj = templateObject
    }

    const request: TestRequest = {
      method: api.request.method,
      url: replaceAllVariablesInStringWithValue(api.request.url, templateObj),
    }

    if (typeof api.request.headers !== 'undefined')
      request.headers = JSON.parse(
        replaceAllVariablesInStringWithValue(JSON.stringify(api.request.headers), templateObj),
      )
    if (typeof api.request.params !== 'undefined')
      request.params = JSON.parse(replaceAllVariablesInStringWithValue(JSON.stringify(api.request.params), templateObj))
    if (typeof api.request.body !== 'undefined') {
      if (typeof api.request.body === 'string') {
        // try if body after settings values is json parsable
        // can be the case that is is a json string, if we reference a number. In this case the variable ref is not wrapped in ""
        // technically making the json invalid. Once we replace the variable with its value, the json becomes valid
        let replacedValue = replaceAllVariablesInStringWithValue(api.request.body, templateObj)
        try {
          const parsed = JSON.parse(replacedValue)
          request.data = parsed
        } catch (err) {
          // we might have newlines and tab chars in the string due to the user friendly jsonlike formatting in the editor
          // so we try to remove them and try to parse again
          replacedValue = replacedValue.replaceAll('\n\t', '')
          try {
            const parsed = JSON.parse(replacedValue)
            request.data = parsed
          } catch (err) {
            // not json parsable, simply use string
            request.data = replacedValue
          }
        }
      } else if (typeof api.request.body === 'object')
        request.data = JSON.parse(replaceAllVariablesInStringWithValue(JSON.stringify(api.request.body), templateObj))
    }

    setTestRequest(request)
  }

  /**
   * Prepares API test result for display.
   * @param chart
   * @returns
   */
  function prepareApiResultView(chart: Chart): void {
    if (typeof chart.selected.id === 'undefined') return
    const api = chart.nodes[chart.selected.id].properties.api
    if (typeof api === 'undefined') return

    const testResult = api.apiTestResult
    if (typeof testResult !== 'undefined') setApiTestResult(testResult)
  }

  /**
   * Handles onBlur event of textfield.
   * Takes value of textfield from state and sets it into chart. Updates chart.
   * @param event
   * @param variable
   */
  function onValueBlur(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void {
    if (typeof chart.selected.id === 'undefined') return
    const api = chart.nodes[chart.selected.id].properties.api
    if (typeof api === 'undefined') return

    const mockVariablesObj = {}
    for (const localMockVariablesObj of Object.values(templateObject)) {
      mockVariablesObj[localMockVariablesObj.variable.id] = localMockVariablesObj.replaceValue
    }
    api.mockVariables = mockVariablesObj
    chart.nodes[chart.selected.id].properties.api = api

    setChartCallback(chart)
  }

  /**
   * Updates value in local state.
   * @param event
   * @param variable
   */
  function onValueChange(event: React.ChangeEvent<HTMLInputElement>, variable: Variable): void {
    const newTemplateObject = cloneDeep(templateObject)
    newTemplateObject[variable.id] = { variable, replaceValue: event.target.value }
    setTemplateObject(newTemplateObject)
  }

  /**
   * Performs test API request.
   */
  async function onTestApi(): Promise<void> {
    if (typeof testRequest === 'undefined' || typeof chart.selected.id === 'undefined') return
    const api = chart.nodes[chart.selected.id].properties.api
    if (typeof api === 'undefined') return

    setStatus('waiting')

    const testResult = await callProxyApi(testRequest)

    let newApiTestResult: APITestResult = {}
    if (testResult !== null && typeof testResult.data === 'string') {
      newApiTestResult.error = testResult.data
    } else if (testResult !== null && typeof testResult.data !== 'string') {
      newApiTestResult = testResult.data
    }

    setStatus(undefined)

    if (!isEmpty(newApiTestResult)) {
      if (newApiTestResult.status) api.response.status = newApiTestResult.status
      api.apiTestResult = newApiTestResult
      chart.nodes[chart.selected.id].properties.api = api
      setDisplayDetails(true)
      setChartCallback(chart)
    }
  }

  /**
   * Toggles response details.
   */
  function onToggleDetails(): void {
    setDisplayDetails(!displayDetails)
  }

  useEffect(
    function () {
      prepareMockVariables(chart)
      prepareTestRequestObject(chart)
      prepareApiResultView(chart)
    },
    [chart],
  )

  useEffect(
    function () {
      // enable or disable test button
      if (typeof chart.selected.id === 'undefined' || typeof chart.nodes[chart.selected.id] === 'undefined') return
      const node = chart.nodes[chart.selected.id]
      const api = node.properties.api
      if (typeof api === 'undefined') return

      let enabled = true
      if (typeof testRequest === 'undefined' || isEmpty(testRequest) || status === 'waiting') enabled = false
      if (!api.request.url) enabled = false

      setTestButtonEnabled(enabled)
    },
    [testRequest, status],
  )

  return (
    <div className={classes.container}>
      <Typography>Sie können die API testen, um die Antwort anzuzeigen.</Typography>
      <div className={classes.mockValuesContainer}>
        {Object.keys(templateObject).length > 0 && (
          <Grid
            container
            className={classes.gridContainer}
            direction='row'
            justifyContent='space-evenly'
            alignItems='center'
          >
            <Grid item xs={3}>
              <Typography className={classes.columnTitle}>Variable</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography className={classes.columnTitle}>Test-Wert</Typography>
            </Grid>
          </Grid>
        )}
        {Object.values(templateObject).map((varObj, index) => {
          const variable = varObj.variable
          const value = varObj.replaceValue
          return (
            <div key={`mock-variable-container-${index}`}>
              <Grid
                container
                className={classes.gridContainer}
                direction='row'
                justifyContent='space-evenly'
                alignItems='center'
              >
                <Grid item xs={3}>
                  {variable.description ? (
                    <Tooltip title={variable.description} placement='top'>
                      <div>
                        <Typography className={classes.variableText}>%{variable.displayName}</Typography>
                      </div>
                    </Tooltip>
                  ) : (
                    <div>
                      <Typography className={classes.variableText}>%{variable.displayName}</Typography>{' '}
                    </div>
                  )}
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    id={`mock-variable-wert-${index}`}
                    value={value}
                    fullWidth
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => onValueChange(event, variable)}
                    onBlur={onValueBlur}
                    label='Wert'
                    placeholder='Testwert für Variable'
                  />
                </Grid>
              </Grid>
            </div>
          )
        })}
      </div>
      <div className={classes.requestPreviewContainer}></div>
      <div className={classes.testApiContainer}>
        <Button
          onClick={onTestApi}
          disabled={!testButtonEnabled}
          className={classes.button + ' ' + classes.closeButton}
        >
          {status === 'waiting' ? <CircularProgress size={20} className={classes.circularSpinner} /> : 'API testen'}
        </Button>

        {typeof apiTestResult !== 'undefined' && (
          <div className={classes.apiResultContainer}>
            <div className={classes.apiResultTopContainer}>
              <div className={classes.apiResultStatusContainer}>
                {typeof apiTestResult.status !== 'undefined' ? (
                  apiTestResult.status >= 200 && apiTestResult.status < 300 ? (
                    // success
                    <React.Fragment>
                      <CheckCircle style={{ color: '#4CAF50', marginRight: '10px' }} /> Status: {apiTestResult.status}
                    </React.Fragment>
                  ) : (
                    //failure
                    <React.Fragment>
                      <Cancel style={{ color: '#CC3333', marginRight: '10px' }} /> Status: {apiTestResult.status}
                    </React.Fragment>
                  )
                ) : (
                  'Kein Statuscode verfügbar'
                )}
              </div>

              <div className={classes.apiResultToggleDetails}>
                <Tooltip
                  id='toggle-response-details'
                  title={displayDetails ? 'Details ausblenden' : 'Details einblenden'}
                  placement='top'
                >
                  <IconButton onClick={onToggleDetails}>
                    {displayDetails ? <ArrowDropUp /> : <ArrowDropDown />}
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <Collapse in={displayDetails}>
              <div className={classes.apiResultDetailsContainer}>
                <Typography align='left'>Details</Typography>
                <div
                  className={
                    classes.apiResultMessage +
                    ' ' +
                    (typeof apiTestResult.status !== 'undefined' &&
                    (apiTestResult.status || 0) >= 200 &&
                    (apiTestResult.status || 999) < 300
                      ? classes.apiResultMessageSuccess
                      : classes.apiResultMessageFailure)
                  }
                >
                  {/* show data of response, else show error, else show message that nothing is available */}
                  {typeof apiTestResult.data !== 'undefined' ? (
                    // if data is object, stringify it and format it
                    typeof apiTestResult.data === 'object' ? (
                      <pre className={classes.apiResultJson}>{JSON.stringify(apiTestResult.data, null, 2)}</pre>
                    ) : (
                      // data is string, simply show string
                      apiTestResult.data
                    )
                  ) : typeof apiTestResult.error !== 'undefined' ? (
                    <p>{apiTestResult.error}</p>
                  ) : (
                    <em>Es sind keine detailierteren Informationen verfügbar</em>
                  )}
                </div>
              </div>
            </Collapse>
          </div>
        )}
      </div>
    </div>
  )
}
