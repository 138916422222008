import React, { memo, useCallback, useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useTheme } from '@mui/material/styles'
import Fuse from 'fuse.js'
import ReactMarkdown from 'react-markdown'

import { Answer } from '../../../../../classes/Knowledge'
import { LoadingState } from '../../../../../@types/Knowledge/types'
import { LockState } from '../../../../../@types/Locking/types'
import { Pagination } from '../../../../../@types/Tables/types'
import Table from '../../../../../components/Table/Table'
import { Typography } from '@mui/material'
import { Button } from '../../../../../components/Buttons'
import CircularLoading from '../../../../../components/Loading/CircularLoading'
import { useLockingContext } from 'hooks/contexts/locking-context'
import { debounce } from 'lodash'
import { useAnswers } from 'hooks/contexts/answers-context'
import Warning from 'assets/img/knowledge/icons/warning'

const useStyles = makeStyles()((theme) => ({
  listViewContainer: {
    height: '100%',
    marginBottom: '50px', // add margin to prevent FAB button from floating above controls
  },
  answerText: {
    fontSize: '1rem',
    lineHeight: 1.5,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '120px',
      wordBreak: 'break-word',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '300px',
      wordBreak: 'break-word',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '500px',
      wordBreak: 'break-word',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '800px',
      wordBreak: 'break-word',
    },
  },
  markdown: {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical',
  },
}))

type ListProps = {
  onEditAnswer: (answer: Answer) => void // callback to trigger editAnswerDialog // TODO: answerId statt answer
  // onAnswersChanged: (changedAnswers: Answer[]) => void // callback for setting changed answers into state of parent component, used when category has changed after drag
  searchString: string
  // loadingState?: LoadingState
  pagination?: Pagination
  updatePagination?: ({ page, rowsPerPage }: Pagination) => void
}

type ListComponentProps = {
  answers: Answer[] // all answers to display in the mindmap
  canIEditAnswer: (answerIdOrAnswer?: string | Answer | undefined) => boolean
  loading: LoadingState | null
  lockState: LockState
}

const ListContent = memo(function ListContent({
  onEditAnswer,
  searchString,
  pagination,
  updatePagination,
  answers,
  canIEditAnswer,
  loading,
  lockState,
}: ListProps & ListComponentProps): React.ReactElement {
  const { classes } = useStyles()

  const [displayedAnswers, setDisplayedAnswers] = useState<Answer[]>([])

  /**
   * Searches answers for search string and returns result.
   */
  function filterAnswers(answers: Answer[]): Answer[] {
    if (!searchString) return answers
    const fuseOptions = {
      shouldSort: true,
      threshold: 0.4,
      minMatchCharLength: 1,
      keys: ['title', 'actions', 'labels', 'answer', 'answerType'],
    }
    const fuse = new Fuse(answers ?? [], fuseOptions)
    return fuse.search(searchString).map((result) => result.item)
  }

  useEffect(
    function () {
      // filter answers whenever search string changes and list view is shown
      const delayedSearch = debounce(() => {
        const result = filterAnswers(answers)
        setDisplayedAnswers(result)
      }, 200)
      delayedSearch()
    },
    [searchString, answers],
  )

  return (
    <div className={classes.listViewContainer}>
      {loading === 'loading' ? (
        <CircularLoading text={'Wissen wird geladen. Bitte warten...'} />
      ) : (
        <Table
          headers={['Titel', 'Antwort', '']}
          width={['20%', '60%', '20%']}
          padding='medium'
          searchString={searchString}
          rows={displayedAnswers.map((answer, idx) => {
            return [
              <div key={`answer-${answer.answerId}`} style={{ display: 'flex' }}>
                {/* Show warning if answer has less than 5 example utterances */}
                {answer.labels.length < 5 && answer.intent !== 'None' ? (
                  <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                    <Warning
                      tooltip={
                        <Typography>
                          Die Antwort hat weniger als die empfohlene Anzahl an zugeordneten Fragen.
                        </Typography>
                      }
                    />
                  </div>
                ) : null}
                {/* Show warning if answer is longer than 700 character */}
                {answer.answer.length > 700 ? (
                  <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                    <Warning
                      tooltip={
                        <Typography>
                          Die Antwort ist sehr lang und kann im Chat unübersichtlich sein. Um Übersichtlichkeit im Chat
                          sicherzustellen empfehlen wir den Text zu kürzen oder die Antwort in mehrere Antworten
                          aufzuteilen.
                        </Typography>
                      }
                    />
                  </div>
                ) : null}
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography key={`answer-topic-${idx}`} variant='small'>
                    {answer.topic ?? ''}
                  </Typography>
                  <Typography key={`answer-title-${idx}`}>
                    {answer.answerType === 'answer' ? answer.title : answer.triggerDialogName}
                  </Typography>
                </div>
              </div>,
              <div key={`answer-text-${idx}`} className={classes.answerText}>
                <ReactMarkdown className={classes.markdown}>
                  {answer.answerType === 'answer' ? answer.answer : `Startet Dialog: **"${answer.triggerDialogName}"**`}
                </ReactMarkdown>
              </div>,
              <Button
                key={`answer-editbutton-${idx}`}
                size='small'
                type='normal'
                icon='pencil-fill'
                iconType='remix'
                onClick={(): void => {
                  onEditAnswer(answer)
                }}
                disabled={lockState !== 'canEdit' || !canIEditAnswer(answer)}
              >
                Bearbeiten
              </Button>,
            ]
          })}
          cellAlignPattern={['left', 'left', 'right']}
          rowsPerPage={pagination?.rowsPerPage}
          page={pagination?.page}
          updatePagination={updatePagination}
        />
      )}
    </div>
  )
})

// Wrapping function for the list. Hook update force rerender of the using component.
// we do not want to re-render the list everytime we perform a lock request
export default function List(props: ListProps): React.ReactElement {
  const { lockState } = useLockingContext()
  const { canIEditAnswer, loading, answersArrayPrimaryLang } = useAnswers()
  return (
    <ListContent
      {...props}
      answers={answersArrayPrimaryLang}
      lockState={lockState}
      canIEditAnswer={canIEditAnswer}
      loading={loading}
    />
  )
}
