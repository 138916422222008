import React, { useEffect, useState } from 'react'
import BaseCard from 'components/Cards/BaseCard'
import { makeStyles } from 'tss-react/mui'
import { Notes, Start } from '@mui/icons-material'
import { Chip, List, ListItem, ListItemText, Typography } from '@mui/material'
import ReactMarkdown from 'react-markdown'
import { Answer } from 'classes/Knowledge'

const useStyles = makeStyles()((theme) => ({
  markdown: {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical',
    '& p': {
      margin: 0,
    },
    fontSize: '12px',
  },
  answerText: {
    maxWidth: '100%',
    wordBreak: 'break-word',
    height: '60px',
  },
  utterances: {
    marginTop: theme.spacing(2),
    borderTop: '1px solid rgba(0,0,0,0.2)',
    overflow: 'hidden',
  },
  utteranceListItem: {
    whiteSpace: 'nowrap',
    padding: 0,
    margin: 0,
    marginTop: `-${theme.spacing(1)}`,
  },
  utteranceListItemText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    margin: 0,
    padding: 0,
  },
  hover: {
    transition: 'box-shadow 0.1s',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0 7px 13px -6px rgb(0 0 0 / 42%), 0 2px 11px 0px rgb(0 0 0 / 12%), 0 4px 5px -3px rgb(0 0 0 / 20%)',
    },
  },
}))

type AnswerCardProps = {
  answer: Answer
  isSelected: boolean
  disabled?: boolean
  onClick: (answerId: string) => void
}

export default React.memo(function AnswerCard({
  answer,
  isSelected: isSelectedProps,
  onClick,
  disabled = false,
}: AnswerCardProps): React.ReactElement {
  const { classes } = useStyles()
  const [isSelected, setIsSelected] = useState<boolean>()

  useEffect(
    function () {
      setIsSelected(isSelectedProps)
    },
    [isSelectedProps],
  )

  return (
    <BaseCard
      title={
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='caption'>{answer.topic}</Typography>
            <Chip
              label={answer.answerType === 'answer' ? 'Antwort' : 'Trigger'}
              style={{ marginLeft: 'auto' }}
              size='small'
              icon={answer.answerType === 'answer' ? <Notes /> : <Start />}
              color={answer.answerType === 'answer' ? 'primary' : 'secondary'}
            />
          </div>
          <Typography variant='h4'>{answer.title ?? ' '}</Typography>
        </div>
      }
      isSelected={isSelected}
      height={'233px'}
      width={'100%'}
      paddingTop='12px'
      paddingSide='18px'
      paddingBottom='12px'
      onClick={(): void => onClick(answer.answerId)}
      shadowWidth={5}
      shadowBlur={15}
      className={classes.hover}
      disabled={disabled}
    >
      <Typography className={classes.answerText}>
        <ReactMarkdown className={classes.markdown}>
          {answer.answerType === 'answer' ? answer.answer : `Startet Dialog: **"${answer.triggerDialogName}"**`}
        </ReactMarkdown>
      </Typography>

      <div className={classes.utterances}>
        <Typography variant='small'>Beispielfragen:</Typography>
        <List dense>
          {answer.labels.slice(0, 3).map((label, index) => (
            <ListItem key={`${answer.answerId}-utt-${index}`} classes={{ root: classes.utteranceListItem }}>
              <ListItemText
                classes={{ root: classes.utteranceListItemText }}
                primary={<Typography variant='caption'>{label}</Typography>}
              />
            </ListItem>
          ))}
        </List>
      </div>
    </BaseCard>
    // </div>
  )
})
