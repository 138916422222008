import React from 'react'
import { makeStyles } from 'tss-react/mui'

import { AddCircleOutline } from '@mui/icons-material'

const useStyles = makeStyles()((theme) => ({
  card: {
    position: 'relative',
    border: `1px dashed ${theme.palette.primary.main}`,
    // borderRadius: theme.shape.borderRadius,
    borderRadius: '10px',
    boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.25)',
    background: '#FFFFFF',
    textAlign: 'left',
    width: '263px',
    height: '166px',
  },
  content: {
    padding: '15px 12px',
  },
  header: {
    '& h1': { fontSize: '18px', color: theme.palette.primary.main, margin: '0px', marginBottom: '13px' },
    '& h2': { fontSize: '12px', color: theme.palette.primary.main, margin: '0px' },
  },
  body: {
    textAlign: 'center',
  },
  icon: {
    width: '50px',
    height: '50px',
    color: theme.palette.primary.main,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
}))

type AddCardProps = {
  title: string
  onClick: () => void
}

export default function AddCard({ title, onClick }: AddCardProps): React.ReactElement {
  const { classes } = useStyles()
  return (
    <div className={classes.card} onClick={onClick}>
      <div className={classes.content}>
        <div className={classes.header}>
          <h1>{title}</h1>
        </div>
        <div className={classes.body}>
          <AddCircleOutline className={classes.icon} />
        </div>
      </div>
    </div>
  )
}
