import React, { useEffect, useState } from 'react'
import { Check } from '@mui/icons-material'
import { makeStyles } from 'tss-react/mui'
import { Typography, useTheme } from '@mui/material'

import { Dialog } from '../../../../components/Dialogs'
import { Textfield } from '../../../../components/TextInput/Textfield'
import { Button } from '../../../../components/Buttons'

import { Chart } from '../../../../@types/Flowchart/types'
import { useFlowdesignerContext } from 'hooks/contexts/flowdesigner-context'
import ProcessTemplateCard from 'components/Cards/ProcessTemplateCard'
import { TranslationFile } from '../../../../@types/Translations/types'
import { useStudioNotificationContext } from 'hooks/contexts/studio-notification-context'
import { usePrevious } from 'hooks/usePrevious/usePrevious'
import { Can } from 'components/Can/Can'

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  dialogContent: {
    display: 'flex',
    marginTop: theme.spacing(4),
    height: '100%',
    width: '100%',
  },
  existingTemplatesContainer: {
    minWidth: '500px',
    maxWidth: '500px',
    marginRight: theme.spacing(3),
    overflowY: 'auto',
    overflowX: 'hidden',
    marginTop: `-${theme.spacing(3)}`, // ensure that shadow is not cut at the top
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3), // ensure card at bottom is not cut
  },
  singleProcessTemplateCardContainer: {
    marginBottom: theme.spacing(2),
  },
  description: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  textfield: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  nameContainer: {},
  descContainer: {
    marginTop: theme.spacing(3),
  },
}))

type CreateProcessTemplateDialogProps = {
  dialogIdForTemplate: string
  chart: Chart
  translationFile: TranslationFile
  onClose: () => void
}

export default function CreateProcessTemplateDialog({
  dialogIdForTemplate,
  chart,
  translationFile,
  onClose,
}: CreateProcessTemplateDialogProps): React.ReactElement {
  const { classes } = useStyles()
  const theme = useTheme()
  const { setNotification } = useStudioNotificationContext()
  const { processTemplates, processTemplateLoadingState, saveProcessTemplate } = useFlowdesignerContext()
  const prevProcessTemplateLoadingState = usePrevious(processTemplateLoadingState)
  const [templateName, setTemplateName] = useState<string>()
  const [templateDescription, setTemplateDescription] = useState<string>()
  const [templateNameError, setTemplateNameError] = useState<string>()
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>() // this is set if the user selects a template from the list. Causes an existing template to be updated instead of creating a new one.
  // const [loadingState, setLoadingState] = useState<'saving'>()

  /**
   * Handles selection of existing template
   * @param templateId
   */
  function onSelectExistingTemplate(templateId: string): void {
    setSelectedTemplateId(templateId)
    setTemplateName(processTemplates[templateId].name)
    setTemplateDescription(processTemplates[templateId].description)
  }

  async function onSaveClick(): Promise<void> {
    if (!templateName) return

    // make api call via context. This also sets new template in context
    await saveProcessTemplate(
      dialogIdForTemplate,
      templateName,
      templateDescription,
      selectedTemplateId,
      chart,
      translationFile,
    )

    onClose()
  }

  function onNameChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setTemplateName(event.target.value)
  }

  function onDescriptionChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setTemplateDescription(event.target.value)
  }

  useEffect(
    function () {
      // check if template name is already in use.
      // set error if the template with the name is not being updated (was not selected by the user.)
      let hasError = false
      for (const template of Object.values(processTemplates)) {
        if (template.name === templateName && template.templateId !== selectedTemplateId) {
          setTemplateNameError('Template name existiert bereits.')
          hasError = true
          break
        }
      }
      if (!hasError) setTemplateNameError(undefined)
    },
    [templateName],
  )

  useEffect(
    function () {
      if (prevProcessTemplateLoadingState === processTemplateLoadingState) return // we only care if the state actually changed

      if (processTemplateLoadingState === 'success') {
        setNotification('success', 'Prozessvorlage wurde erfolgreich gespeichert.', 3000)
        onClose() // close the dialog
      } else if (processTemplateLoadingState === 'error') {
        setNotification('error', 'Speichern fehlgeschlagen. Prozessvorlage konnte nicht gespeichert werden.', 3000)
      }
    },
    [processTemplateLoadingState],
  )

  return (
    <Dialog
      id='create-dialog-modal'
      size='large'
      open
      closable
      onClose={onClose}
      title={'Dialog Vorlagen'}
      primaryActionButton={
        <Can I='update' a='processTemplate' passThrough>
          {(canUpdate): React.ReactElement => (
            <Can I='create' a='processTemplate' passThrough>
              {(canCreate): React.ReactElement => {
                // if an existing template is selected, user needs update permission, otherwise he needs can permission
                const isDisabled = selectedTemplateId ? !canUpdate : !canCreate
                return (
                  <Button
                    type='success'
                    size='small'
                    icon={<Check />}
                    onClick={onSaveClick}
                    disabled={!!templateNameError || !templateName || !templateDescription || isDisabled}
                    loading={processTemplateLoadingState === 'saving'}
                  >
                    {processTemplateLoadingState === 'saving'
                      ? 'Wird gespeichert...'
                      : selectedTemplateId
                      ? 'Vorlage aktualisieren'
                      : 'Vorlage erstellen'}
                  </Button>
                )
              }}
            </Can>
          )}
        </Can>
      }
      secondaryActionText={'Abbrechen'}
      onSecondaryActionClick={onClose}
    >
      <div className={classes.container}>
        <div className={classes.dialogContent}>
          <div className={classes.existingTemplatesContainer}>
            {/* existing templates */}
            {Object.values(processTemplates).map((template) => (
              <div key={template.templateId} className={classes.singleProcessTemplateCardContainer}>
                <ProcessTemplateCard
                  templateName={template.name}
                  templateDescription={template.description}
                  templateId={template.templateId}
                  onClick={onSelectExistingTemplate}
                  isSelected={template.templateId === selectedTemplateId}
                />
              </div>
            ))}
          </div>
          <div>
            {/* template configuration */}
            <Typography variant='h2'>Dialog als Vorlage speichern</Typography>
            <Typography className={classes.description}>
              Sie können Ihre erstellten Dialoge als Vorlagen speichern, um Sie später assistentenübergreifend
              wiederzuverwenden.
            </Typography>
            <Textfield
              className={classes.textfield}
              onChange={onNameChange}
              multiline={false}
              value={templateName}
              error={!!templateNameError}
              label={'Titel'}
              helperText={templateNameError ?? ''}
            />
            <Textfield
              className={classes.textfield}
              onChange={onDescriptionChange}
              multiline={true}
              rows={5}
              value={templateDescription}
              label={'Beschreibung'}
            />
          </div>
        </div>
      </div>
    </Dialog>
  )
}
