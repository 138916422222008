import React, { useState, useEffect } from 'react'
import { cloneDeep, omit } from 'lodash'

import { makeStyles } from 'tss-react/mui'
import { TextField, IconButton, Tooltip, Fab, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'

import VariablesAutosuggestSelect, { OnChangeOptions } from '../Variables/VariablesAutosuggestSelect'

import {
  removeOutgoingPortFromNode,
  replaceVarIdWithDisplayName,
  replaceVarDisplayNameWithId,
} from '../../../../../utils/chartUtils'

import { Chart, DisplayVariableOption, Ports } from '../../../../../@types/Flowchart/types'

const useStyles = makeStyles()((theme) => ({
  selectedDialogContainer: {},
  marginTopLarge: { marginTop: '25px' },
  marginTopMedium: { marginTop: '15px' },
  marginTopSmall: { marginTop: '10px' },
  marginRightSmall: { marginRight: '10px' },
  paddingBottom: { paddingBottom: theme.spacing(2) },
  centerContent: { textAlign: 'center' },
  switchValueContainer: { marginBottom: '30px', display: 'flex' },
  switchValueText: { minWidth: 'fit-content', paddingRight: '10px', paddingTop: '32px' },
  switchValueDeleteTooltip: { display: 'inline-block', textAlign: 'right' },
  iconButtonIcon: { fontSize: '1.5rem' },
  fabButton: { backgroundColor: '#4caf50', color: 'white' },
}))

type SSwitchOldProps = {
  chart: Chart
  setStateCallback: (chart: Chart, portChange?: boolean) => void
  setIsSaveDisabledCallback: (disabled: boolean) => void
}

export default function SSwitchOld({
  chart,
  setStateCallback,
  setIsSaveDisabledCallback,
}: SSwitchOldProps): React.ReactElement {
  const { classes } = useStyles()

  const [description, setDescription] = useState<string>()
  const [cases, setCases] = useState<Ports>()

  /**
   * Increases size of selected node.
   * @param {Chart} chartInput
   */
  function increaseNodeSize(chartInput: Chart): Chart {
    const chart = chartInput
    if (typeof chart.selected.id === 'undefined') return chart
    const node = chart.nodes[chart.selected.id]
    if (typeof node.size === 'undefined') return chart
    const amountOfAdditionalPorts = Object.keys(node.ports).length - 3
    if (amountOfAdditionalPorts > 0) {
      for (let i = 0; i < amountOfAdditionalPorts; i++) {
        node.size.height += 20
      }
    } else {
      node.size.height = 110
    }
    return chart
  }

  /**
   * Checks whether switch value (= port name) is legal.
   * @param switchValue
   */
  function isSwitchValueLegal(switchValue: string): boolean {
    let isLegal = true
    if (typeof switchValue !== 'string') isLegal = false
    if (switchValue.length === 0) isLegal = false
    if (switchValue.trim() === '') isLegal = false

    if (!isLegal) {
      // setIsSaveDisabledCallback(true)
    }
    return isLegal
  }

  // ====== CALLBACKS & HANDLER

  /**
   * Handles description text change. Updates state.
   * @param event
   */
  function onDescriptionChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setDescription(event.target.value)
  }

  /**
   * Handles description textfield blur.
   * Sets new description in chart and sets chart using the callback.
   */
  function onDescriptionBlur(): void {
    const selectedId = chart.selected.id
    if (typeof selectedId === 'undefined') return
    chart.nodes[selectedId].properties.text = description
    setStateCallback(chart)
  }

  /**
   * Callback function for variable selection.
   * Variable is registered in the chart by the selection component.
   * This function deals with setting the variable under condition in the chart.
   *
   * @param {chart} cahrt
   * @param {string[]} variableIds list of ids of the selected variables
   */
  function onSetVariable(chart: Chart, variableIds: string[]): Chart {
    if (variableIds.length === 0 || typeof chart.selected.id === 'undefined') return chart

    const varId = variableIds[0]
    const selectedNode = chart.nodes[chart.selected.id]
    if (typeof selectedNode === 'undefined') return chart
    // set new var
    selectedNode.properties.varname = varId || ''
    chart.nodes[chart.selected.id] = selectedNode

    // setVarId(varId)
    return chart
  }

  /**
   * Callback function for variable removal.
   * Removes variable from condition.
   *
   * @param {Chart} chart
   * @param {string} varId id of the removed variable
   * @param {OnChangeOptions} options
   */
  function onRemoveVariable(chart: Chart, varId: string, options: OnChangeOptions): Chart {
    if (typeof chart.selected.id === 'undefined' || typeof chart.nodes[chart.selected.id] === 'undefined') return chart

    if (chart.nodes[chart.selected.id].properties.varname === varId)
      chart.nodes[chart.selected.id].properties.varname = ''
    return chart
  }

  /**
   * Handles port name change
   */
  function onPortNameChange(event: React.ChangeEvent<HTMLInputElement>, portId: string): void {
    if (typeof cases === 'undefined') return
    const port = cases[portId]
    port.properties = port.properties || { type: 'outgoing' }
    port.properties.name = event.target.value
    cases[portId] = port
    setCases(cloneDeep(cases))
  }

  /**
   * Handles port name textfield blur.
   * Puts temp ports from state into chart and sets chart using callback.
   * @param portId
   */
  function onPortNameBlur(portId: string): void {
    let newChart = cloneDeep(chart)
    if (typeof newChart.selected.id === 'undefined' || typeof cases === 'undefined') return
    newChart.nodes[newChart.selected.id].ports[portId] = cases[portId]
    newChart = increaseNodeSize(newChart)
    setStateCallback(newChart, true)
  }

  /**
   * Handles addition of port.
   */
  function onPortAdd(): void {
    const newCases = cloneDeep(cases)
    if (typeof newCases === 'undefined') return
    const length = Object.keys(newCases).length
    newCases[`port${length + 1}`] = {
      id: `port${length + 1}`,
      type: 'right',
      properties: {
        name: '',
        type: 'outgoing',
      },
    }

    setCases(newCases)
  }

  /**
   * Handles port deletion.
   * Removes port, updates state and sets updated chart using callback.
   * @param portId
   */
  function onPortDelete(portId: string): void {
    const { ports, chart: newChart } = removeOutgoingPortFromNode(chart, portId)
    setCases(ports)
    setStateCallback(newChart, true)
  }

  /**
   * Callback function for variable selection component.
   * @param {Chart} chart
   * @param {string[]} prevSelectedVarIds
   * @param {string[]} selectedVarIds
   * @param {OnChangeOptions} options
   */
  function onVariableChange(
    chart: Chart,
    prevSelectedVarIds: string[],
    selectedVarIds: string[],
    options: OnChangeOptions,
  ): void {
    if (selectedVarIds.length >= prevSelectedVarIds.length) {
      // variable was selected
      chart = onSetVariable(chart, selectedVarIds)
    } else {
      // variable was removed
      // find removed varIds
      const removedVarIds = prevSelectedVarIds.filter((varId) => !selectedVarIds.includes(varId))
      for (const varid of removedVarIds) {
        chart = onRemoveVariable(chart, varid, { clear: options.clear })
      }
    }
    setStateCallback(chart)
  }

  useEffect(function () {
    if (typeof chart.selected.id === 'undefined') return

    // might be undefined - that's okay
    const description = chart.nodes[chart.selected.id].properties.text || ''

    // get clone of ports object to get a working version and not needing to always work on the chart for minor changes
    // chart is updated if changes were made
    const cases = cloneDeep(chart.nodes[chart.selected.id].ports)

    setDescription(description)
    setCases(cases)
  }, [])

  return (
    <div className={classes.selectedDialogContainer}>
      {/* Description */}
      <div>
        <Typography>Beschreibung dieser Switch Node</Typography>
        <TextField
          id='if-else-description'
          label='Beschreibung'
          value={description}
          fullWidth
          onBlur={onDescriptionBlur}
          onChange={onDescriptionChange}
          margin='normal'
        />
      </div>
      {/* Variable Selection */}
      <div className={classes.marginTopLarge}>
        <Typography>Variable deren Wert verglichen werden soll.</Typography>
        <VariablesAutosuggestSelect chart={chart} onChange={onVariableChange} usageType='consume' />
      </div>
      {/* Values to compare against */}
      <div className={classes.marginTopLarge}>
        <Typography>
          Vergleichswerte auf deren Basis entschieden wird, welcher ausgehende Pfad verfolgt wird.
        </Typography>
        {typeof cases !== 'undefined' &&
          Object.keys(omit(cases, ['port1'])).map((portId, index) => {
            return typeof cases[portId] !== 'undefined' && typeof portId !== 'undefined' ? (
              <div className={classes.switchValueContainer} key={portId}>
                <span className={classes.switchValueText}>
                  <strong>Fall {index + 1}:</strong>
                </span>
                <TextField
                  id={'comparison-textfield-' + index}
                  label='Vergleichswert'
                  value={cases[portId].properties?.name || ''}
                  fullWidth
                  margin='normal'
                  InputProps={{ autoComplete: 'off' }}
                  helperText={
                    !isSwitchValueLegal(cases[portId].properties?.name || '') &&
                    'Vergleichswerte dürfen nicht leer sein'
                  }
                  error={!isSwitchValueLegal(cases[portId].properties?.name || '')}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>): void => onPortNameChange(event, portId)}
                  onBlur={(): void => onPortNameBlur(portId)}
                />
                <div id={`delete-${portId}`} className={classes.marginTopMedium}>
                  <Tooltip className={classes.switchValueDeleteTooltip} title='Fall entfernen' placement='top'>
                    <IconButton aria-label='Delete' onClick={(): void => onPortDelete(portId)}>
                      <DeleteIcon className={classes.iconButtonIcon} />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            ) : null
          })}
        {/* Add button */}
        <div className={classes.centerContent + ' ' + classes.marginTopLarge + ' ' + classes.paddingBottom}>
          <Fab
            className={classes.fabButton}
            variant='extended'
            color='inherit'
            aria-label='Neuer Vergleichswert' // TODO I18n
            onClick={onPortAdd}
          >
            <AddIcon className={classes.marginRightSmall} />
            Vergleichswert hinzufügen
          </Fab>
        </div>
      </div>
    </div>
  )
}
