import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { IconButton, Typography } from '@mui/material'

import { ChromeReaderModeOutlined, ErrorOutline, VisibilityOff, Visibility } from '@mui/icons-material'

import SmartCard from '../../../../../components/SmartCard/SmartCard'

import { FLOWDESIGNER_NODE_COLOR_CARD, NODE_NAMES } from 'utils/constants'

import { NodeProperties } from '../../../../../@types/Flowchart/types'
import { TranslationFile } from '../../../../../@types/Translations/types'
import { IAdaptiveCard } from '../../../../../@types/SmartCards/types'

type RenderedCardProps = {
  cardId: string
  translationFile: TranslationFile
}

function RenderedCard({ cardId, translationFile }: RenderedCardProps): React.ReactElement {
  const [card, setCard] = useState<IAdaptiveCard>()

  useEffect(
    function () {
      const card = translationFile.ac[translationFile.primaryLanguage][cardId]
      if (typeof card !== 'undefined') setCard(card.data)
    },
    [cardId, translationFile],
  )

  return <SmartCard card={card} />
}

const useCardNodeStyles = makeStyles()((theme) => ({
  noCardContent: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    minHeight: '52px',
    height: '100%',
  },
  cardNameAndToggleContainer: { display: 'flex' },
  cardNameText: { marginTop: 'auto', marginBottom: 'auto', wordBreak: 'break-word' },
  // cardNameText: { marginTop: 'auto', marginBottom: 'auto', marginLeft: '20px', wordBreak: 'break-word' },
  // cardNameAndToggleContainer: { display: 'flex', alignItems: 'center', height: '65px' },
  cardDisplayToggle: { marginLeft: 'auto' },
  renderedCardContainer: {
    borderTop: '1px solid rgba(0,0,0,0.4)',
    margin: '0 -19px', // this is a workaround to fix a padding of 20px introduced by the card renderer
  },
}))

type CardNodeInnerCustomProps = {
  classes: any
  nodeProperties: NodeProperties
  onResize: () => void
  displayCard?: boolean
  translationFile?: TranslationFile
  isSelected?: boolean
}

export default memo(function CardNodeContent({
  classes: parentClasses,
  nodeProperties,
  onResize,
  translationFile,
  isSelected,
  displayCard: displayCardProps = false,
}: CardNodeInnerCustomProps): React.ReactElement {
  const { classes: styles } = useCardNodeStyles()
  const classes = { ...parentClasses, ...styles }

  const [displayCard, setDisplayCard] = useState<boolean>(displayCardProps)

  // const onContentResize = useCallback(function (width?: number, height?: number) {
  //   console.log('On Card node resize')
  //   onResize(width, height)
  // }, [])
  // const { width, height } = useResizeDetector({
  //   onResize: onContentResize,
  //   targetRef: contentContainerRef,
  // })

  useEffect(
    function () {
      onResize()
    },
    [displayCard],
  )

  /**
   * Toggle display card.
   */
  function onToggleDisplayCard(event: React.MouseEvent<HTMLButtonElement>): void {
    // stop event propagation to prevent node from opening
    event.stopPropagation()
    setDisplayCard(!displayCard)
  }

  useEffect(
    function () {
      setDisplayCard(displayCardProps)
    },
    [displayCardProps],
  )

  return (
    <div className={classes.outerNode}>
      <div className={classes.headerContainer}>
        <h5 className={classes.header} style={{ backgroundColor: FLOWDESIGNER_NODE_COLOR_CARD }}>
          {NODE_NAMES['basic/adaptiveCard']}
          <ChromeReaderModeOutlined className={classes.headerCardIcon} />
        </h5>
      </div>
      {typeof translationFile === 'undefined' ||
      typeof nodeProperties.card === 'undefined' ||
      nodeProperties.card === '' ? (
        <div className={classes.contentContainer}>
          <div className={classes.noCardContent}>
            <Typography variant='body2'>{nodeProperties.text}</Typography>
          </div>
        </div>
      ) : (
        <div className={classes.contentContainer}>
          <div className={classes.cardContent}>
            <div className={classes.cardNameAndToggleContainer}>
              <div className={classes.cardNameText}>
                <Typography variant='body2'>{nodeProperties.card}</Typography>
              </div>
              <div className={classes.cardDisplayToggle}>
                <IconButton onClick={onToggleDisplayCard}>
                  {displayCard ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </div>
            </div>
            {displayCard && (
              <div className={classes.renderedCardContainer}>
                <RenderedCard cardId={nodeProperties.card} translationFile={translationFile} />
              </div>
            )}
            {/* </div> */}
          </div>
        </div>
      )}
      {/* {nodeProperties.validationError ? (
        <div className={classes.errorTextContainer}>
          <div className={classes.errorIconContainer}>
            <ErrorOutline />
          </div>
          <div className={classes.errorText}>{nodeProperties.validationErrorMsg}</div>
        </div>
      ) : null} */}
    </div>
  )
})
