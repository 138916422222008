import React, { useState, useEffect } from 'react'
import BaseDropdown, { Option, ActionMeta, ValueType } from './BaseDropdown'
import { makeStyles } from 'tss-react/mui'

export type { Option, ActionMeta }

type SelectDropdownStyleProps = {
  height?: string
  width?: string
}

const useStyles = makeStyles<SelectDropdownStyleProps>()((theme, props) => ({
  container: {
    width: props.width,
    height: props.height,
  },
}))

type MultiSelectDropdownProps = {
  className?: string
  selected?: Option[] | string[]
  options: Option[]
  onChange: (newValues: Option[], action: ActionMeta<Option>) => void
  isDisabled?: boolean
  isSearchable?: boolean
  isCreatable?: boolean
  isClearable?: boolean
  createOptionText?: (text: string) => string
  noOptionsMessage?: string
  placeholder?: string
  width?: string
  height?: string
  maxDisplayOptions?: number
  allowedChars?: string
  forbiddenLabels?: string[] // strings defined here cannot be used when creating a new option
}

/**
 * USES BASE DROPDOWN -> ONLY ALLOWS MULTIPLE SELECTIONS
 * @returns
 */
export default function MultiSelectDropdown({
  className,
  options,
  selected: selectedOptions,
  onChange,
  isSearchable,
  isDisabled,
  isCreatable = false,
  isClearable = false,
  createOptionText = (text: string): string => `"${text}" erstellen`,
  noOptionsMessage,
  placeholder = 'Bitte auswählen',
  width,
  height,
  maxDisplayOptions,
  allowedChars,
  forbiddenLabels,
}: MultiSelectDropdownProps): React.ReactElement {
  const { classes } = useStyles({ width, height })

  const [selected, setSelected] = useState<Option[]>()

  function onChangeIntercept(
    newValue: ValueType<Option, boolean> | ValueType<Option, boolean>[],
    action: ActionMeta<Option>,
  ): void {
    if (!Array.isArray(newValue)) onChange([newValue] as Option[], action)
    else if (newValue.length > 0) {
      const values = newValue
        .map((value): Option | undefined => {
          if (value) return value[0]
        })
        .filter((value) => typeof value !== 'undefined') as Option[]
      onChange(values, action)
    }
  }

  useEffect(
    function () {
      if (typeof selectedOptions === 'undefined') return
      const newSelectedOptions: Option[] = []
      for (const option of selectedOptions) {
        if (typeof option === 'string') {
          // option is string
          // find option object
          const opt = options.find((o) => o.value === option)
          if (opt) newSelectedOptions.push(opt)
        } else {
          newSelectedOptions.push(option)
        }
      }
      setSelected(newSelectedOptions)
    },
    [selectedOptions],
  )

  return (
    <div className={classes.container}>
      <BaseDropdown
        className={className}
        isMulti={false}
        isClearable={isClearable}
        isSearchable={isSearchable}
        isCreatable={isCreatable}
        createOptionText={createOptionText}
        isDisabled={isDisabled}
        selectedOptions={selected}
        options={options}
        onChange={onChangeIntercept}
        placeholder={placeholder}
        noOptionsMessage={noOptionsMessage}
        maxDisplayOptions={maxDisplayOptions}
        height={height}
        allowedChars={allowedChars}
        forbiddenLabels={forbiddenLabels}
      />
    </div>
  )
}
