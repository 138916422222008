import { useTheme } from '@mui/material'
import BaseCard from 'components/Cards/BaseCard'
import React from 'react'

type CardButtonProps = {
  isSelected: boolean
  onClick: () => void
  children: React.ReactNode
  selectedColor?: string
  disabled?: boolean
  height?: string
  width?: string
  shadowWidth?: number
  shadowBlur?: number
}

export default function CardButton({
  isSelected,
  onClick,
  children,
  selectedColor,
  shadowWidth,
  shadowBlur,
  disabled = false,
  height = '70px',
  width = '180px',
}: CardButtonProps): React.ReactElement {
  const theme = useTheme()
  return (
    <BaseCard
      height={height}
      width={width}
      isSelected={isSelected}
      onClick={onClick}
      paddingTop={theme.spacing(1)}
      paddingBottom={theme.spacing(1)}
      paddingSide={theme.spacing(2)}
      contentMarginTop={'0'}
      selectedBorderColor={selectedColor}
      disabled={disabled}
      shadowWidth={shadowWidth}
      shadowBlur={shadowBlur}
    >
      <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>{children}</div>
    </BaseCard>
  )
}
