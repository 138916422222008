import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import CircularLoading from '../../../../../components/Loading/CircularLoading'
import { Typography } from '@mui/material'
import { Check } from '@mui/icons-material'
import { LoadingState } from '../../../../../@types/Knowledge/types'

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '40px',
  },
  iconContainer: {
    width: '20px',
    height: '20px',
    marginBottom: 'auto',
    marginTop: 'auto',
  },
  textContainer: {
    marginBottom: 'auto',
    marginTop: 'auto',
    marginLeft: theme.spacing(1),
  },
  saveSuccessIcon: {
    color: '#0b2639',
    height: '20px',
    width: '20px',
  },
}))

type MindmapAutoSaveIndicatorProps = {
  saving: LoadingState | null
}

export default function MindmapAutoSaveIndicator({ saving }: MindmapAutoSaveIndicatorProps): React.ReactElement {
  const { classes } = useStyles()
  const [text, setText] = useState<string>('')

  useEffect(
    function () {
      // errors are handled with Studio Notification
      switch (saving) {
        case 'saving':
          setText('Speichern ...')
          break
        case 'savingSuccess':
          setText('Speichern erfolgreich.')
          break
        case 'deleting':
          setText('Löschen ...')
          break
        case 'deletingSuccess':
          setText('Löschen erfolgreich.')
          break
      }
    },
    [saving],
  )

  return (
    <div className={classes.container}>
      <div id='subcontainer' style={{ position: 'relative', display: 'flex' }}>
        <>
          <div className={classes.iconContainer}>
            {(saving === 'saving' || saving === 'deleting') && <CircularLoading size='small' />}
            {(saving === 'savingSuccess' || saving === 'deletingSuccess') && (
              <Check className={classes.saveSuccessIcon} />
            )}
          </div>
          <div className={classes.textContainer}>
            <Typography>{text}</Typography>
          </div>
        </>
      </div>
    </div>
  )
}
