import React, { useState } from 'react'
import BaseCard from 'components/Cards/BaseCard'

import { makeStyles } from 'tss-react/mui'
import { Chip, List, Typography, IconButton } from '@mui/material'
import { Delete } from '@mui/icons-material'
import DeleteTopicConfirmDialog from '../Dialogs/DeleteTopicConfirmDialog'
import CustomizedTooltip from 'components/Tooltips/CustomContentTooltip'

import { AnswerTemplateDataObject } from '../../../../../@types/Knowledge/types'

type StyleProps = {
  isSelected?: boolean
}

const useStyles = makeStyles<StyleProps>()((theme, props) => ({
  topicCard: {
    transition: 'box-shadow 0.3s',
    cursor: !props?.isSelected ? 'pointer' : 'cursor',
    '&:hover': {
      boxShadow: !props?.isSelected
        ? '0 14px 26px -12px rgb(0 0 0 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)' // '0px 2px 10px 2px rgba(0,0,0, 0.25)'
        : '',
    },
    marginBottom: '25px',
  },
  chip: { marginRight: theme.spacing(2) },
  iconButton: {
    marginLeft: theme.spacing(1),
    marginBottom: 'auto',
    marginTop: 'auto',
    // padding: 0,
    color: theme.palette.primary.main,
  },
}))

type TopicCardProps = {
  templateData: AnswerTemplateDataObject
  onClick: (topicName: string) => void
  isSelected: boolean
  onDeleteTopic: (topicName: AnswerTemplateDataObject['topic']['name']) => void
}

// TODO: edit topic name

export default function TopicCard({
  templateData,
  onClick,
  isSelected,
  onDeleteTopic,
}: TopicCardProps): React.ReactElement {
  // confirm delete dialog
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>()
  const { classes } = useStyles({ isSelected })

  /**
   * Deletes variable via callback.
   */
  function onDeleteClick(): void {
    setShowDeleteDialog(true)
  }

  /**
   * Deletes variable using callback and removes confirm dialog.
   */
  function onDeleteConfirm(): void {
    onDeleteTopic(templateData.topic.name)
    setShowDeleteDialog(false)
  }

  return (
    <>
      <BaseCard
        title={
          <>
            <Typography variant='h4'>{templateData.topic.displayName} </Typography>
            <CustomizedTooltip
              placement='top'
              disableInteractive
              content={<Typography>Kategorie löschen</Typography>}
              elements={
                <IconButton size='small' onClick={onDeleteClick} aria-label='delete' className={classes.iconButton}>
                  <Delete fontSize='inherit' />
                </IconButton>
              }
            />
          </>
        }
        onClick={(): void => onClick(templateData.topic.name)}
        paddingBottom='16px'
        paddingTop='16px'
        paddingSide='16px'
        titleVariant='h4'
        isSelected={isSelected}
        minHeight={'150px'}
        height='auto'
        width={'350px'}
        className={classes.topicCard}
      >
        <List>
          {templateData.data.length === 0 && (
            <Typography>
              <em>Es existieren noch keine Variablen.</em>
            </Typography>
          )}
          {templateData.data.length > 0 && (
            <div>
              <Typography>Variablen:</Typography>
              {templateData.data.map((varObject) => {
                return (
                  <Chip key={`${templateData.topic}-${varObject.key}`} className={classes.chip} label={varObject.key} />
                )
              })}
            </div>
          )}
        </List>
      </BaseCard>
      {showDeleteDialog && (
        <DeleteTopicConfirmDialog onClose={(): void => setShowDeleteDialog(false)} onDelete={onDeleteConfirm} />
      )}
    </>
  )
}
