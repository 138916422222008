import React, { useState, useEffect } from 'react'
import { NotionRenderer, BlockMapType } from 'react-notion'
import Fuse from 'fuse.js'
import TextField from '@mui/material/TextField'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { makeStyles } from 'tss-react/mui'
import Skeleton from '@mui/material/Skeleton'
import 'react-notion/src/styles.css'

// ID of the public notion table that contains the references and information of all documentation pages
const tableId = '737829ecb2ac41a2a79e55ed4be9d3fd'

type blogPage = {
  title: string
  id: string
  description: string | undefined
  published: string
  tags: string | undefined
}

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    flexDirection: 'row',
    flexFlow: 'row wrap',
  },
  heading: {
    padding: theme.spacing(2),
  },
  navWrapper: {
    flex: '0 0 400px',
    height: '100%',
  },
  stickyWrapper: {
    position: '-webkit-sticky',
    top: theme.spacing(4),
  },
  stickyWrapperFix: {
    position: 'sticky',
  },
  nav: {
    marginRight: theme.spacing(4),
    height: 'fit-content',
  },
  info: {
    marginRight: theme.spacing(4),
    marginTop: 0,
    padding: theme.spacing(2),
    height: 'fit-content',
  },
  notionWrapper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  notionWrapperWidth800: {
    maxWidth: 1000,
    width: 1000,
  },
  notionWrapperWidthWhole: {
    maxWidth: '100%',
    width: '100%',
  },
  notionPageNotFound: {
    color: theme.palette.warning.main,
    textAlign: 'center',
  },
}))

const fuseOptions = {
  shouldSort: true,
  threshold: 0.4,
  minMatchCharLength: 1,
  keys: ['title', 'description', 'tags'],
}

type NotionDocumentationProps = {
  choosenPage?: string
  showPaper?: boolean
  fullWidth?: boolean
}

// TODO: using splitbee api - host own worker
export default function NotionDocumentation({
  choosenPage,
  showPaper = true,
  fullWidth = false,
}: NotionDocumentationProps): React.ReactElement {
  const { classes } = useStyles()
  const [blockMap, setBlockMap] = useState<BlockMapType | null>(null)
  const [table, setTable] = useState<Array<blogPage>>([])
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [searchString, setSearchString] = useState('')
  const [displayedTable, setDisplayedTable] = useState<Array<blogPage>>([])
  // const [choosenPageNotFound, setChoosenPageNotFound] = useState<boolean | null>(null)

  const fetchAndSetPage = async (id): Promise<void> => {
    const blogData = (await fetch(`https://notion-api.splitbee.io/v1/page/${id}`).then((res) =>
      res.json(),
    )) as BlockMapType
    setBlockMap(blogData)
  }

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      let notionTableData = await fetch(`https://notion-api.splitbee.io/v1/table/${tableId}`).then((res) => res.json())
      notionTableData = notionTableData.filter(function (page) {
        return page.published === 'true'
      })

      setTable(notionTableData)
      setDisplayedTable(notionTableData)
      if (typeof choosenPage === 'undefined') {
        for (const page of notionTableData) {
          if (page.id && page.published && page.published === 'true') {
            fetchAndSetPage(page.id)
            break
          }
        }
      } else {
        for (const page of notionTableData) {
          if (page.id && page.published && page.published === 'true' && page.title === choosenPage) {
            fetchAndSetPage(page.id)
            break
          }
        }
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (table && table.length > selectedIndex && table[selectedIndex]) {
      fetchAndSetPage(table[selectedIndex].id)
    }
  }, [selectedIndex])

  useEffect(() => {
    const fuseSearch = new Fuse(table, fuseOptions)
    const searchedTable = searchString
      ? fuseSearch.search(searchString).map((result) => {
          return result.item
        })
      : table
    setDisplayedTable(searchedTable)
  }, [searchString])

  return (
    <div className={classes.root}>
      {typeof choosenPage === 'undefined' && (
        <div className={classes.navWrapper}>
          <div className={classes.stickyWrapper + ' ' + classes.stickyWrapperFix}>
            <Paper className={classes.nav} elevation={4}>
              <Typography className={classes.heading} variant='h5' component='h2'>
                Dokumentation
              </Typography>
              <TextField
                id='search'
                disabled={table.length === 0}
                label={'Suche'}
                value={searchString}
                InputProps={{
                  autoComplete: 'off',
                  style: { fontWeight: 300 },
                }}
                style={{
                  marginLeft: '16px',
                  marginRight: '16px',
                  width: 'calc(100% - 32px)',
                }}
                onChange={(event): void => {
                  setSearchString(event.target.value)
                }}
              />
              <List component='nav' aria-label='Documentation Page'>
                {displayedTable.map((page, index) => {
                  return (
                    <ListItem
                      button
                      aria-label={`${page.title}`}
                      selected={selectedIndex === index}
                      onClick={(): void => {
                        setSelectedIndex(index)
                        setBlockMap(null)
                      }}
                      key={`Documentation-Navigation-${page.title}`}
                    >
                      <ListItemText primary={page.title} secondary={page.description && page.description} />
                    </ListItem>
                  )
                })}
              </List>
            </Paper>
            <p className={classes.info}>
              Bei weiteren Fragen oder Anmerkungen können Sie sich jederzeit an uns via{' '}
              <a href='mailto:support@convaise.com'>support@convaise.com</a> wenden.
            </p>
          </div>
        </div>
      )}
      {showPaper ? (
        <Paper
          id='notion'
          className={
            classes.notionWrapper +
            ' ' +
            `${fullWidth ? classes.notionWrapperWidthWhole : classes.notionWrapperWidth800}`
          }
          elevation={4}
        >
          {blockMap ? (
            <NotionRenderer blockMap={blockMap} />
          ) : (
            // choosenPageNotFound === true ? (
            //   <h5>Die gesuchte Seite konnte nicht gefunden werden</h5>
            // ) :
            <div>
              <Skeleton width='350px' height='60px' />
              <Skeleton width='450px' />
              <Skeleton width='100%' style={{ marginTop: '30px' }} />
              <Skeleton width='100%' />
              <Skeleton width='450px' />
              <Skeleton variant='rectangular' width='100%' height='500px' style={{ marginTop: '30px' }} />
            </div>
          )}
        </Paper>
      ) : (
        <div
          id='notion'
          className={
            classes.notionWrapper +
            ' ' +
            `${fullWidth ? classes.notionWrapperWidthWhole : classes.notionWrapperWidth800}`
          }
        >
          {blockMap ? (
            <NotionRenderer blockMap={blockMap} />
          ) : (
            // choosenPageNotFound === true ? (
            //   <h4 className={classes.notionPageNotFound}>Die gesuchte Seite konnte nicht gefunden werden.</h4>
            // ) :
            <div>
              <Skeleton width='350px' height='60px' />
              <Skeleton width='450px' />
              <Skeleton width='100%' style={{ marginTop: '30px' }} />
              <Skeleton width='100%' />
              <Skeleton width='450px' />
              <Skeleton variant='rectangular' width='100%' height='500px' style={{ marginTop: '30px' }} />
            </div>
          )}
        </div>
      )}
    </div>
  )
}
