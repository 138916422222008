import { Grid, Typography } from '@mui/material'
import { Button, CustomIconButton } from 'components/Buttons'
import { Textfield } from 'components/TextInput/Textfield'
import DragAndDrop from 'components/Upload/DragAndDrop'
import { useBotContext } from 'hooks/contexts/bot-context'
import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { BotSettings } from '../../@types/Settings/types'
import CircularLoading from 'components/Loading/CircularLoading'
import { useLockingContext } from 'hooks/contexts/locking-context'

type Colors = {
  primaryColor?: string
  primaryColorText?: string
  disabledColor?: string
}

const useStyles = makeStyles()((theme) => ({
  content: {},
  buttons: { display: 'flex' },
  button: { width: '180px', margin: `0 ${theme.spacing(1)}` },
  heading: { margin: `${theme.spacing(2)} 0`, marginBottom: theme.spacing(3) },
  row: { display: 'flex' },
  keyTypography: { margin: 'auto 0' },
  stylesContainer: { marginTop: theme.spacing(5) },
  stylesContainerContent: { display: 'flex', flexDirection: 'column' },
  avatarContainer: { marginTop: theme.spacing(2), width: '100%', marginBottom: theme.spacing(2) },
  colorsContainer: {
    maxWidht: '200px',
    maxHeight: '200px',
  },
  avatarUpload: {
    height: '200px',
    width: '200px',
    border: '3px dotted grey',
    display: 'flex',
  },
  avatarImage: {
    width: 'fit-content',
    height: 'fit-content',
    maxWidth: '100%',
    maxHeight: '100%',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  colorPreview: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: theme.spacing(1),
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    border: '1px solid rgba(0,0,0,0.2)',
  },
}))

type StylingSettingsProps = {
  styleSettings: BotSettings['styles']
  styleSettingsLoading?: 'loading'
  onStyleSettingsChange: (newStyleSettings: BotSettings['styles']) => void
}

export default function StylingSettings({
  styleSettings,
  onStyleSettingsChange,
}: StylingSettingsProps): React.ReactElement {
  const { classes } = useStyles()
  const { lockState } = useLockingContext()

  // Custom styles
  const [avatarImage, setAvatarImage] = useState<string>()
  const [avatarImageType, setAvatarImageType] = useState<string>()
  const [colors, _setColors] = useState<Colors>({})

  function setColors(newColors: Colors): void {
    const newStyleSettings = {
      ...styleSettings,
      ...newColors,
    } as BotSettings['styles']
    onStyleSettingsChange(newStyleSettings)
  }

  function resetAvatar(): void {
    setAvatarImage(undefined)
    const newStyleSettings = {
      ...styleSettings,
      avatarImg: '',
      avatarImageType: 'jpg',
    } as BotSettings['styles']
    onStyleSettingsChange(newStyleSettings)
  }

  /**
   * Converts a ObjectURL string to a base64 string.
   * @param avatarImageDataUrlString
   * @returns
   */
  async function convertImageToBase64String(avatarImageDataUrlString: string): Promise<string | undefined> {
    return new Promise((resolve, reject) => {
      if (!avatarImageDataUrlString) return resolve(undefined)

      // Make a fetch request to get the contents of the object URL as a array buffer
      fetch(avatarImageDataUrlString)
        .then((response) => response.arrayBuffer())
        .then((buffer) => {
          // we convert the array buffer to binary string which we then can convert to base64 later on
          const binaryString = new Uint8Array(buffer).reduce((acc, byte) => acc + String.fromCharCode(byte), '')
          const base64String = btoa(binaryString)
          resolve(base64String)
        })
        .catch((err) => reject(err))
    })
  }

  /**
   * Converts a base64 string of an image to a ObjectURL string that can be displayed.
   */
  function convertBase64StringToImage(imageStringBase64: string, avatarImageType: string): string {
    const binaryString = atob(imageStringBase64)
    const arrayBuffer = new ArrayBuffer(binaryString.length)
    const uint8Array = new Uint8Array(arrayBuffer)
    for (let i = 0; i < binaryString.length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i)
    }
    const mimeType = avatarImageType === 'svg' ? 'image/svg+xml' : avatarImageType === 'png' ? 'image/png' : 'image/jpg'
    const blob = new Blob([arrayBuffer], { type: mimeType })
    return URL.createObjectURL(blob)
  }

  /**
   * Handles image uplaod
   * @param event
   */
  async function onAvatarImageUpload(files: FileList): Promise<void> {
    if (files && files[0]) {
      const fileType = files[0].name.split('.').pop()
      setAvatarImage(URL.createObjectURL(files[0]))
      setAvatarImageType(fileType ?? files[0].type)
      const imageString = await convertImageToBase64String(URL.createObjectURL(files[0]))
      if (imageString) {
        const newStyleSettings = {
          ...styleSettings,
          avatarImg: imageString,
          avatarImageType: fileType,
        } as BotSettings['styles']
        onStyleSettingsChange(newStyleSettings)
      }
    }
  }

  useEffect(() => {
    if (styleSettings?.avatarImg && styleSettings?.avatarImageType) {
      setAvatarImage(convertBase64StringToImage(styleSettings.avatarImg, styleSettings.avatarImageType))
    }
    if (styleSettings) {
      _setColors({
        primaryColor: styleSettings.primaryColor,
        primaryColorText: styleSettings.primaryColorText,
        disabledColor: styleSettings.disabledColor,
      })
    }
  }, [styleSettings])

  return (
    <div id='custom-styles' className={classes.stylesContainer}>
      <Typography className={classes.heading} variant='h2'>
        Design & Gestaltung
      </Typography>
      <div className={classes.stylesContainerContent}>
        <div className={classes.avatarContainer}>
          <Grid container spacing={2}>
            <Grid item xs={2} className={classes.row}>
              <Typography className={classes.keyTypography}>Avatar</Typography>
            </Grid>
            <Grid item xs={10} className={classes.row}>
              <div className={classes.colorPreview}>
                <CustomIconButton
                  disabled={!avatarImage || lockState !== 'canEdit'}
                  icon='ri-arrow-go-back-line'
                  onClick={resetAvatar}
                  tooltip='Avatar zurücksetzen'
                />
              </div>
              <div className={classes.avatarUpload}>
                {avatarImage ? (
                  <img src={avatarImage} alt='assistant-avatar' className={classes.avatarImage} />
                ) : (
                  <DragAndDrop
                    handleDrop={onAvatarImageUpload}
                    allowedFileTypes='.svg, .png, .jpg, .jpeg'
                    uploadButtonText='Avatar hochladen'
                    disableBorder
                  />
                )}
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={classes.colorsContainer}>
          {/* primary color */}
          <Grid container spacing={2}>
            <Grid item xs={2} className={classes.row}>
              <Typography className={classes.keyTypography}>Primär Farbe</Typography>
            </Grid>
            <Grid item xs={10} className={classes.row}>
              <div className={classes.colorPreview} style={{ backgroundColor: colors?.primaryColor }}></div>
              <Textfield
                value={colors?.primaryColor}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  const newColors = { ...colors, primaryColor: e.target.value }
                  setColors(newColors)
                }}
                disabled={lockState !== 'canEdit'}
                hint='Primärfarbe des Assistenten. Bestimmt, welche Farbe Primärbuttons, Titelleiste und Akzente im Chatinterface haben.'
              />
            </Grid>
            {/* primary text color */}
            <Grid item xs={2} className={classes.row}>
              <Typography className={classes.keyTypography}>Primäre Textfarbe</Typography>
            </Grid>
            <Grid item xs={10} className={classes.row}>
              <div className={classes.colorPreview} style={{ backgroundColor: colors?.primaryColorText }} />
              <Textfield
                value={colors?.primaryColorText}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  const newColors = { ...colors, primaryColorText: e.target.value }
                  setColors(newColors)
                }}
                disabled={lockState !== 'canEdit'}
                hint='Textfarbe von Elementen in der Primärfarbe. Sollte möglichst komplementär zur Primärfarbe sein, um einen hohen Kontrast zu erhalten und die Lesbarkeit sicherzustellen.'
              />
            </Grid>
            {/* disabled color */}
            <Grid item xs={2} className={classes.row}>
              <Typography className={classes.keyTypography}>Deaktivierte Farbe</Typography>
            </Grid>
            <Grid item xs={10} className={classes.row}>
              <div className={classes.colorPreview} style={{ backgroundColor: colors?.disabledColor }} />
              <Textfield
                value={colors?.disabledColor}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  const newColors = { ...colors, disabledColor: e.target.value }
                  setColors(newColors)
                }}
                disabled={lockState !== 'canEdit'}
                hint='Farbe von deaktivierten ("nicht klickbaren") Elementen im Chatinterface. Sollte möglichst eine hellere Version der Primärfarbe mit gräulichen Aspekten sein.'
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  )
}
