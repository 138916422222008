import * as stringUtils from './stringUtils'

const REGEX_VAR_DISPLAYNAME_NEW_FORMAT = /\${([a-zöäüÄÖÜß0-9-]*?)}/gim
const REGEX_VAR_ID_OLD_FORMAT = /%([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})/gm

/**
 * Finds and returns all variable display names in a string.
 * Variables in the translation file are referenced with ${displayName}
 * @param text
 */
function findAndReturnAllVariableDisplayNamesInString(text: string): string[] {
  const variableDisplayNames: string[] = []

  let match
  do {
    match = REGEX_VAR_DISPLAYNAME_NEW_FORMAT.exec(text)
    if (match) {
      const m = match[1]
      if (m) variableDisplayNames.push(m)
    }
  } while (match)

  return variableDisplayNames
}

/**
 * Finds and returns all variable ids in a string (old format %uuid)
 * @param text
 */
function findAndReturnAllVariableIdsOldFormatInString(text: string): string[] {
  const variableIds: string[] = []

  let match
  do {
    match = REGEX_VAR_ID_OLD_FORMAT.exec(text)
    if (match) {
      const m = match[1]
      if (m) variableIds.push(m)
    }
  } while (match)

  return variableIds
}

/**
 * Determines whether we display a text in the translations list.
 * Acts as a filter for removing things like the deprecated !skip text of the start node
 * or all texts that don't have any alphabetical characters (besides variable names).
 * We do this because we do not need the user to add the same translations for '== ""' for each language.
 * These translations are added automatically in the saveTranslationsAPI, where the same filter is used to find them.
 *
 * @param text
 */
export function shouldTranslationBeDisplayed(text: string): boolean {
  if (text === '' || text === '!skip') return false

  // remove all variable display names

  // if in new format, they are in translation with displayName in the format ${displayName}
  const variableDisplayNamesInString = findAndReturnAllVariableDisplayNamesInString(text)
  for (const varDisplayName of variableDisplayNamesInString) {
    text = stringUtils.replaceAll(text, varDisplayName, '')
  }
  // if old format, translationfile text translation has variable id in the format %uuid
  const variableIdsInString = findAndReturnAllVariableIdsOldFormatInString(text)
  for (const varId of variableIdsInString) {
    text = stringUtils.replaceAll(text, varId, '')
  }

  // if string has no alphabetic characters after variable names are removed, we do not show it
  if (!stringUtils.hasAlphabeticChar(text)) {
    return false
  }

  return true
}
