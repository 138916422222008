import React, { useEffect, useState } from 'react'

// @mui/material components
import { makeStyles } from 'tss-react/mui'

import { TextField, Typography } from '@mui/material'

import { Chart, NodeProperties } from '../../../../../@types/Flowchart/types'
import { isEqual } from 'lodash'
import { Option, SelectDropdown } from 'components/Dropdown'
import { useBotContext } from 'hooks/contexts/bot-context'

const useStyles = makeStyles()({
  marginTopMedium: { marginTop: '15px' },
})

type SPDFTextsProps = {
  chart: Chart
  setIsSaveDisabled: (isSaveDisabled: boolean) => void
  setStateCallback: (chart: Chart) => void
}

export default function SPDFTexts({ chart, setIsSaveDisabled, setStateCallback }: SPDFTextsProps): React.ReactElement {
  const { classes } = useStyles()
  const { bot } = useBotContext()

  const [generatingText, setGeneratingText] = useState<string>('')
  const [infoText, setInfoText] = useState<string>()
  const [buttonText, setButtonText] = useState<string>()
  const [pdfDocOptions, setPdfDocOptions] = useState<Option[]>([])
  const [selectedOption, setSelectedOption] = useState<string>()

  function onDocumentChange(newValue: Option): void {
    if (!chart.selected.id) return
    chart.nodes[chart.selected.id].properties.documentId = newValue.value
    setSelectedOption(newValue.value)
    setStateCallback({ ...chart })
  }

  function onTextChange(event: React.ChangeEvent<HTMLInputElement>, type: 'generating' | 'info' | 'button'): void {
    if (typeof chart.selected.id === 'undefined') return
    const text = event.target.value

    const pdfTexts: NodeProperties['pdfTexts'] = {
      ...(chart.nodes[chart.selected.id].properties.pdfTexts || {
        generating: '',
        info: '',
        button: '',
      }),
    }

    switch (type) {
      case 'generating':
        setGeneratingText(text)
        pdfTexts.generating = text
        break
      case 'info':
        setInfoText(text)
        pdfTexts.info = text
        break
      case 'button':
        setButtonText(text)
        pdfTexts.button = text
        break
    }
    chart.nodes[chart.selected.id].properties.pdfTexts = pdfTexts
    setStateCallback({ ...chart })
  }

  useEffect(function () {
    if (!chart.selected.id || !bot) return

    // prepare options for pdf doc dropdown
    const options: Option[] = []
    for (const doc of Object.values(bot.documentInfos).filter((document) => document.documentType === 'pdf')) {
      options.push({
        value: doc.documentId,
        label: doc.documentTitle,
        sublabel: doc.documentDescription,
      })
    }
    setPdfDocOptions(options)

    if (chart.nodes[chart.selected.id].properties.documentId)
      setSelectedOption(chart.nodes[chart.selected.id].properties.documentId)

    const values: NodeProperties['pdfTexts'] = {
      generating: chart.nodes[chart.selected.id].properties.pdfTexts?.generating || 'Ihr Dokument wird generiert...',
      info:
        chart.nodes[chart.selected.id].properties.pdfTexts?.info || 'Ihr Dokument kann jetzt heruntergeladen werden.',
      button: chart.nodes[chart.selected.id].properties.pdfTexts?.button || 'Herunterladen',
    }

    setGeneratingText(chart.nodes[chart.selected.id].properties.pdfTexts?.generating || values.generating)
    setInfoText(chart.nodes[chart.selected.id].properties.pdfTexts?.info || values.info)
    setButtonText(chart.nodes[chart.selected.id].properties.pdfTexts?.button || values.button)

    if (!isEqual(chart.nodes[chart.selected.id].properties.pdfTexts, values)) {
      chart.nodes[chart.selected.id].properties.pdfTexts = values
      setStateCallback(chart)
    }
  }, [])

  useEffect(
    function () {
      if (!chart.selected.id || !bot) return

      if (
        chart.nodes[chart.selected.id].properties.documentId &&
        chart.nodes[chart.selected.id].properties.pdfTexts?.generating &&
        chart.nodes[chart.selected.id].properties.pdfTexts?.info &&
        chart.nodes[chart.selected.id].properties.pdfTexts?.button
      )
        setIsSaveDisabled(false)
      else setIsSaveDisabled(true)
    },
    [chart],
  )

  return (
    <React.Fragment>
      <Typography className={classes.marginTopMedium}>PDF Dokument wählen</Typography>
      <SelectDropdown
        onChange={onDocumentChange}
        options={pdfDocOptions}
        selected={selectedOption}
        placeholder='PDF Dokument auswählen'
        width='100%'
      />

      <Typography className={classes.marginTopMedium}>PDF-Dokument wird generiert</Typography>

      <TextField
        id='generating'
        label='Hinweis Generierung'
        fullWidth
        value={generatingText}
        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => onTextChange(event, 'generating')}
        margin='normal'
      />
      <Typography className={classes.marginTopMedium}>Download des PDF-Dokuments</Typography>

      <TextField
        id='info'
        label='Hinweis Generierung abgeschlossen'
        multiline
        fullWidth
        maxRows={15}
        value={infoText}
        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => onTextChange(event, 'info')}
        margin='normal'
      />
      <Typography className={classes.marginTopMedium}>Herunterladen Button</Typography>
      <TextField
        id='button'
        label='Aktionsbutton Text'
        multiline
        fullWidth
        maxRows={15}
        value={buttonText}
        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => onTextChange(event, 'button')}
        margin='normal'
      />
    </React.Fragment>
  )
}
