import React, { memo, useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { IAdaptiveCard } from '../../../../../../../@types/SmartCards/types'
import SmartCard from 'components/SmartCard/SmartCard'
import { isEqual } from 'lodash'
import { useBotContext } from 'hooks/contexts/bot-context'
import { useWebchatV4Styles } from 'hooks/styles/useWebchatV4Styles'

const useStyles = makeStyles()((theme) => ({
  previewContainer: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius:
      typeof theme.shape.borderRadius === 'number' ? theme.shape.borderRadius * 2 : theme.shape.borderRadius,
    height: '570px',
    width: '380px',
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
  },
  userMessage: {
    width: '170px',
    height: '31px',
    borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0px ${theme.shape.borderRadius}px`,
    backgroundColor: theme.palette.primary.main,
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginLeft: 'auto',
  },
  cardMessage: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: 'auto',
    minHeight: '80px',
    width: '80%',
    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.15), 0px 3px 6px rgba(0, 0, 0, 0.1)',
    border: `1px solid ${theme.palette.grey.A100}`,
    borderRadius: `0px ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
    overflowY: 'auto',
  },
  input: {
    marginTop: 'auto',
    marginLeft: 'auto',
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: '30px',
    width: '30px',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
  },
}))

type CardPreviewProps = {
  card: IAdaptiveCard | undefined
}

export default memo(function CardPreview({ card: cardProps }: CardPreviewProps): React.ReactElement {
  const { bot } = useBotContext()
  const [card, setCard] = useState<IAdaptiveCard>()
  const { classes } = useStyles()
  const { classes: classesWebchatV4 } = useWebchatV4Styles()

  useEffect(
    function () {
      setCard(cardProps)
    },
    [cardProps],
  )

  return (
    <div className={classes.previewContainer}>
      <div className={bot?.webchatVersion === 'v4' ? classesWebchatV4.userMessage : classes.userMessage} />
      <div className={bot?.webchatVersion === 'v4' ? classesWebchatV4.cardMessage : classes.cardMessage}>
        <SmartCard card={card} />
      </div>
      {bot?.webchatVersion !== 'v4' && <div className={classes.input} />}
    </div>
  )
}, isEqual)
