import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { generateRandomIdString } from 'utils/stringUtils'

type RadioButtonsProps = {
  title?: string
  options: { label: string; value: string; color?: string }[]
  selected: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void
  disabled?: boolean
}

export default function RadioButtons({
  title,
  options,
  selected,
  onChange,
  disabled,
}: RadioButtonsProps): React.ReactElement {
  const [value, setValue] = useState<string>(selected)

  useEffect(() => {
    setValue(selected)
  }, [selected])

  const id = generateRandomIdString(10)
  return (
    <FormControl>
      {title && <FormLabel id={`radio-group-label-${id}`}>{title}</FormLabel>}
      <RadioGroup
        aria-labelledby={`radio-group-label-${id}`}
        name='controlled-radio-buttons-group'
        value={value}
        onChange={onChange}
        aria-disabled={disabled}
      >
        {options.map((option) => {
          return (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={
                <Radio
                  sx={option.color ? { '&.Mui-checked': { color: option.color } } : undefined}
                  disabled={disabled}
                  aria-disabled={disabled}
                />
              }
              label={option.label}
              // style={{ color: option.color }}
            />
          )
        })}
      </RadioGroup>
    </FormControl>
  )
}
