import React, { ReactElement } from 'react'
import { makeStyles } from 'tss-react/mui'

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'

import { useDraggableContextDispatch } from '../../../hooks/contexts/useDraggableContext'

const useStyles = makeStyles()((theme) => ({
  radioButton: {
    width: '100px',
    height: '80px',
    borderRadius: theme.shape.borderRadius,
    boxShadow: '1px 2px 2px rgba(0,0,0,0.25)',
    padding: '7px',
  },
  radioButtonNotSelected: {
    border: '1px solid rgba(0,0,0,0.03)',
  },
  radioButtonSelected: {
    border: '1px solid',
    borderColor: theme.palette.success.main,
  },
  header: {
    display: 'flex',
    fontSize: '12px',
    maxHeight: '17px',
  },
  alignRight: {
    marginLeft: 'auto',
  },
  icon: {
    fontSize: 'small',
    color: theme.palette.success.main,
  },
  mainTitle: {
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightBold,
  },
  description: {
    fontSize: '10px',
    fontWeight: theme.typography.fontWeightLight,
  },
}))

type PopupRadioButtonProps = {
  id: string
  header: string
  title: string
  description: string
  selected?: boolean
  onClick: (event: React.MouseEvent | React.TouchEvent, id: string) => void
}

export const PopupRadioButton = ({
  id,
  header,
  title,
  description,
  selected,
  onClick,
}: PopupRadioButtonProps): ReactElement => {
  const { classes } = useStyles()
  const draggableContextDispatch = useDraggableContextDispatch()

  const onRadioButtonClick = (event: React.MouseEvent | React.TouchEvent): void => {
    onClick(event, id)
  }

  return (
    <div
      onMouseEnter={(): void => draggableContextDispatch({ type: false })}
      onMouseLeave={(): void => draggableContextDispatch({ type: true })}
      className={[classes.radioButton, selected ? classes.radioButtonSelected : classes.radioButtonNotSelected].join(
        ' ',
      )}
      onClick={onRadioButtonClick}
    >
      {/* Header */}
      <div className={classes.header}>
        <div>{header}</div>
        {selected ? (
          <div className={classes.alignRight}>
            <CheckCircleOutlineIcon className={classes.icon} />
          </div>
        ) : null}
      </div>
      {/* Main Title */}
      <div className={classes.mainTitle}>{title}</div>
      {/* Description Text */}
      <div className={classes.description}>{description}</div>
    </div>
  )
}

PopupRadioButton.defaultProps = {
  selected: false,
}
