import React, { useState, useEffect } from 'react'
import { Snackbar, Alert, Typography } from '@mui/material'

type NotificationSnackbarProps = {
  style?: any
  position: 'top' | 'bottom'
  severity?: 'success' | 'error' | 'warning' | 'info'
  open: boolean
  message: string
  onCloseNotification?: () => void
  disableAutoHide?: boolean
  autoHideDuration?: number
  marginTop?: string
  marginBottom?: string
}

export default function NotificationSnackbar({
  style,
  position,
  severity,
  open,
  message,
  onCloseNotification,
  disableAutoHide,
  marginTop,
  marginBottom,
  autoHideDuration = 5000,
}: NotificationSnackbarProps): React.ReactElement {
  const [isOpen, setIsOpen] = useState<boolean>()

  useEffect(
    function () {
      setIsOpen(open)
    },
    [open],
  )

  useEffect(function () {
    let timeout
    if (!disableAutoHide && onCloseNotification) {
      timeout = setTimeout(function () {
        onCloseNotification()
      }, autoHideDuration)
    }
    return (): void => {
      if (timeout) clearTimeout(timeout)
    }
  }, [])

  return (
    <Snackbar
      style={{ marginTop, marginBottom, ...style }}
      anchorOrigin={{
        horizontal: 'center',
        vertical: position,
      }}
      open={isOpen}
      // onClose={onCloseNotification}
      autoHideDuration={disableAutoHide ? null : autoHideDuration}
    >
      <Alert onClose={onCloseNotification} variant='filled' severity={severity}>
        <Typography style={{ whiteSpace: 'pre-line' }}>{message}</Typography>
      </Alert>
    </Snackbar>
  )
}
