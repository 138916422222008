import React, { useEffect, useRef, useState } from 'react'
import { Delete } from '@mui/icons-material'
import CircularLoading from 'components/Loading/CircularLoading'
import { isEqual } from 'lodash'
import { Route, useParams, useLocation, useNavigate, Routes } from 'react-router-dom'
import { Answer } from '../../../../../classes/Knowledge'
import { Button } from '../../../../../components/Buttons'
import BaseCard from '../../../../../components/Cards/BaseCard'
import { ContentPageHeader } from '../../../../../components/Page/ContentPage'
import {
  ROUTE_BOTID_KNOWLEDGE,
  ROUTE_BOTID_KNOWLEDGE_NLU_CONVMANAGEMENT,
  ROUTE_BOTID_KNOWLEDGE_NLU_CONVMANAGEMENT_LIST,
  ROUTE_BOTID_KNOWLEDGE_NLU_CONVMANAGEMENT_MINDMAP,
  ROUTE_BOTS,
  KNOWLEDGE_MIN_UTTERANCES,
  KNOWLEDGE_NONE_INTENT,
  ROUTE_BOTID_ANALYTICS,
} from '../../../../../utils/constants'
import { useAnswers, saveAnswers as saveAnswersContext } from '../../../../../hooks/contexts/answers-context'
import { SaveAnswersUtterances } from '../../../../../api/StudioBackend'
import { LoadingState, AnswerTemplateData, Answers, AnswerTranslation } from '../../../../../@types/Knowledge/types'
import EditKnowledgeAnswer from './EditKnowledgeAnswer'
import EditTriggerAnswerV2Bots from './EditTriggerAnswerV2Bots'
import { Dialog } from '../../../../../components/Dialogs'
import { Typography } from '@mui/material'
import { useLockingContext } from 'hooks/contexts/locking-context'
import { useStudioNotificationContext } from 'hooks/contexts/studio-notification-context'
import CustomizedTooltip from 'components/Tooltips/CustomContentTooltip'
import { useBotContext } from 'hooks/contexts/bot-context'
import EditTriggerAnswerV4Bots from './EditTriggerAnswerV4Bots'

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search)
}

// type LoadingState = 'saving' | 'savingSuccess' | 'savingError' | 'deleting' | 'deletingSuccess' | 'deletingError'

type EditAnswerProps = {
  loadingState?: LoadingState
  // setNotification: (status: LoadingState, message: string) => void
  selectedView?: string
}

export default function EditAnswer({
  // setNotification,
  loadingState,
  selectedView,
}: EditAnswerProps): React.ReactElement {
  const navigate = useNavigate()
  const { botId, answerId, moduleConfigId } = useParams() as { botId: string; answerId: string; moduleConfigId: string }
  const { bot } = useBotContext()
  const query = useQuery()
  const {
    updateAnswer,
    updateSingleAnswerTranslationsBatch,
    getAnswerTranslations,
    flaggedAnswers,
    getAnswer,
    hasAnswerChanged,
    deleteAnswer,
    saveAnswer,
    canIEditAnswer,
    canIDeleteAnswer,
  } = useAnswers()
  const { lockState } = useLockingContext()

  const initialLoadingStateRef = useRef<LoadingState>(loadingState)
  const origAnswerRef = useRef<Answer | null>() // holds inital answer to compare changes against and to reset to if discard changes
  const origAnswerTranslationsRef = useRef<{
    [lang: string]: Answer
  } | null>() // holds initial answer translations to compare changes against and to reset to if discard changes

  const [answer, setAnswer] = useState<Answer>()
  const [state, setState] = useState<LoadingState>()
  const [displayDialog, setDisplayDialog] = useState<'delete' | 'discard'>()

  function onEditAnswer(changedAnswer: Answer): void {
    if (!isEqual(changedAnswer, answer)) {
      setAnswer(changedAnswer)
    }
  }

  function onSave(): void {
    if (!canIEditAnswer(answer?.answerId)) return
    if (answer) saveAnswer(answer)
  }

  function onDelete(): void {
    if (!canIDeleteAnswer(answer?.answerId)) return
    setDisplayDialog('delete')
  }

  function onDeleteAbort(): void {
    setDisplayDialog(undefined)
  }

  function onDeleteConfirm(): void {
    if (lockState !== 'canEdit') return

    if (answer) {
      // setState('deleting')
      setDisplayDialog(undefined)
      deleteAnswer(answerId, true)
    }
  }

  useEffect(
    function () {
      // to wait until answers are loaded to make sure all data is given to make this decision
      if (flaggedAnswers) {
        const answer = getAnswer(answerId)
        if (answer) {
          setAnswer(answer)
          if (flaggedAnswers.flag === 'original') {
            // soooo this needs to change
            origAnswerRef.current = answer
          }

          // if (answer.answerType === 'answer') {
          const translations = getAnswerTranslations(answerId)
          if (answer && translations) {
            if (flaggedAnswers.flag === 'original') {
              origAnswerTranslationsRef.current = translations
            }
          }
        } else {
          // no valid answer - redirect to mindmap
          // this is used for automatic redirect e.g. when the answer has been deleted from the EditAnswer Screen
          // console.log('No valid answer - redirect')
          navigate(
            ROUTE_BOTS +
              `/${botId}` +
              ROUTE_BOTID_KNOWLEDGE +
              `/${moduleConfigId}` +
              ROUTE_BOTID_KNOWLEDGE_NLU_CONVMANAGEMENT +
              ROUTE_BOTID_KNOWLEDGE_NLU_CONVMANAGEMENT_MINDMAP,
          )
        }
      }
    },
    [flaggedAnswers],
  )

  useEffect(
    function () {
      // go back if saving or deleting was successful
      // if initialLoadingState is also success, the answer is being opened directly after an answer has been safed. If we do not have that ref,
      // this useEffect would directly go back, preventing answer editing as long as the previous answer edit shows success.
      if (
        (initialLoadingStateRef.current !== 'savingSuccess' && state === 'savingSuccess') ||
        (initialLoadingStateRef.current !== 'deletingSuccess' && state === 'deletingSuccess')
      ) {
        navigate(-1)
      }
    },
    [state],
  )

  useEffect(
    function () {
      setState(loadingState)
    },
    [loadingState],
  )

  // ===== ACTIONS ======

  const isSaveButtonDisabled =
    lockState !== 'canEdit' ||
    state === 'saving' ||
    state === 'deleting' ||
    typeof answer === 'undefined' ||
    typeof answer.labels === 'undefined' ||
    !(answer.intent === KNOWLEDGE_NONE_INTENT || answer.labels.length >= KNOWLEDGE_MIN_UTTERANCES) ||
    !hasAnswerChanged(answer) ||
    !canIEditAnswer(answer.answerId)
  const saveButtonDisabledReason =
    answer && !hasAnswerChanged(answer) ? (
      <Typography>Es gibt keine Änderungen</Typography>
    ) : !canIEditAnswer(answer?.answerId) ? (
      <Typography>Sie haben keine Berechtigung, diese Antwort zu bearbeiten.</Typography>
    ) : answer && answer?.labels.length <= KNOWLEDGE_MIN_UTTERANCES ? (
      <Typography>Zu wenig zugeordnete Fragen. Es müssen mindestens 5 Fragen zugeordnet sein.</Typography>
    ) : null

  const SaveButton = (
    <CustomizedTooltip
      placement='top'
      disableInteractive
      content={saveButtonDisabledReason}
      elements={
        <div>
          <Button
            size='normal'
            type='success'
            icon='save-line'
            iconType='remix'
            onClick={(event): void => {
              if (!canIEditAnswer(answer?.answerId)) return
              event?.preventDefault()
              onSave()
            }}
            loading={state === 'saving'}
            disabled={isSaveButtonDisabled}
          >
            Speichern
          </Button>
        </div>
      }
    ></CustomizedTooltip>
  )

  const DeleteButton = (
    <Button
      size='normal'
      type='danger'
      icon={<Delete />}
      onClick={onDelete}
      loading={state === 'deleting'}
      disabled={
        lockState !== 'canEdit' ||
        typeof answer === 'undefined' ||
        state === 'saving' ||
        state === 'deleting' ||
        answer.intent === KNOWLEDGE_NONE_INTENT ||
        !canIDeleteAnswer(answer?.answerId)
      }
    >
      Löschen
    </Button>
  )

  // should we move the switch and route one level up?
  return (
    <>
      <Routes>
        <Route
          path={''}
          element={
            <>
              <ContentPageHeader
                title='Antwort bearbeiten'
                actions={[DeleteButton, SaveButton]}
                // calculate previous url
                // if we know the selected view it means, we know where to go in the conversation management
                // else we most likely came from analytics or directly accessed the page
                previousUrl={
                  typeof selectedView !== 'undefined'
                    ? selectedView === 'list'
                      ? ROUTE_BOTS +
                        '/' +
                        botId +
                        ROUTE_BOTID_KNOWLEDGE +
                        `/${moduleConfigId}` +
                        ROUTE_BOTID_KNOWLEDGE_NLU_CONVMANAGEMENT +
                        ROUTE_BOTID_KNOWLEDGE_NLU_CONVMANAGEMENT_LIST
                      : ROUTE_BOTS +
                        '/' +
                        botId +
                        ROUTE_BOTID_KNOWLEDGE +
                        `/${moduleConfigId}` +
                        ROUTE_BOTID_KNOWLEDGE_NLU_CONVMANAGEMENT +
                        ROUTE_BOTID_KNOWLEDGE_NLU_CONVMANAGEMENT_MINDMAP
                    : query.get('origin') === 'analytics'
                    ? ROUTE_BOTS + '/' + botId + ROUTE_BOTID_ANALYTICS
                    : ROUTE_BOTS +
                      '/' +
                      botId +
                      ROUTE_BOTID_KNOWLEDGE +
                      `/${moduleConfigId}` +
                      ROUTE_BOTID_KNOWLEDGE_NLU_CONVMANAGEMENT
                }
                previousUrlCallback={(): boolean => {
                  if (answer) {
                    const hasChanged = hasAnswerChanged(answer)
                    if (hasChanged) setDisplayDialog('discard')
                    return !hasChanged // if true is returned we complete the callback
                  }
                  return true
                }}
              />
              {answer && answer.answerType === 'answer' ? (
                <EditKnowledgeAnswer answer={answer} onEditAnswerCallback={onEditAnswer} botId={botId} />
              ) : answer && answer.answerType === 'trigger' ? (
                bot !== null ? (
                  bot.webchatVersion === 'v4' ? (
                    <EditTriggerAnswerV4Bots answer={answer} onEditAnswerCallback={onEditAnswer} />
                  ) : (
                    <EditTriggerAnswerV2Bots answer={answer} onEditAnswerCallback={onEditAnswer} />
                  )
                ) : null
              ) : (
                <CircularLoading text='Antwort wird geladen. Bitte warten...' size='medium' />
              )}
              {/* Delete confirm dialog */}
              <Dialog
                id='delete-confirm-dialog'
                size='small'
                open={displayDialog === 'delete'}
                closable
                onClose={onDeleteAbort}
                title='Antwort löschen'
                primaryActionButton={
                  <Button size='small' type='danger' icon={<Delete />} onClick={onDeleteConfirm}>
                    Löschen
                  </Button>
                }
              >
                <Typography>
                  Möchten Sie diese Antwort wirklich löschen? Dies kann nicht rückgängig gemacht werden!
                </Typography>
              </Dialog>
              {/* Discard changes dialog */}
              <Dialog
                id='discard-changes-dialog'
                size='small'
                open={displayDialog === 'discard'}
                closable
                onClose={(): void => {
                  setDisplayDialog(undefined)
                }}
                title='Ungespeicherte Änderungen'
                primaryActionButton={
                  <Button
                    size='small'
                    type='danger'
                    onClick={(): void => {
                      if (origAnswerRef.current) {
                        // reset answer to original value when discarding changes
                        updateAnswer(origAnswerRef.current, 'original')
                      }
                      if (origAnswerTranslationsRef.current) {
                        updateSingleAnswerTranslationsBatch(origAnswerTranslationsRef.current, 'original')
                      }
                      navigate(-1)
                    }}
                  >
                    Bestätigen
                  </Button>
                }
              >
                <Typography>
                  Es existieren ungespeicherte Änderungen. Sind Sie sicher, dass Sie zurückgehen möchten?
                  <br />
                  Ihre Änderungen werden dadurch nicht gespeichert. Sie können Ihre Änderungen aber noch immer
                  bearbeiten und zu einen späteren Zeitpunkt speichern, solange Sie das Wissensmanagement nicht
                  verlassen.
                </Typography>
              </Dialog>
            </>
          }
        ></Route>
      </Routes>
    </>
  )
}
