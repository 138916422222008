import React, { useState, useEffect } from 'react'
import difference from 'lodash/difference'

import Table from '../../../../../../components/Table/Table'
import { Textfield } from '../../../../../../components/TextInput/Textfield'
import CustomizedTooltip from '../../../../../../components/Tooltips/CustomContentTooltip'
import EditAnswerUtterancesDialog from './EditAnswerUtterancesDialog'
import { makeStyles } from 'tss-react/mui'
import { IconButton, Typography, Tooltip } from '@mui/material'
import { Delete, Add, UnfoldMore, CheckCircle } from '@mui/icons-material'
import { Answer } from '../../../../../../classes/Knowledge'
import ErrorComponent from 'components/Error/Error'
import { paraphrase } from 'api/StudioBackend'
import { useErrorContext } from 'hooks/contexts/errorContext'
import CircularLoading from 'components/Loading/CircularLoading'
import { KNOWLEDGE_MIN_UTTERANCES, KNOWLEDGE_NONE_INTENT } from 'utils/constants'
import { useLockingContext } from 'hooks/contexts/locking-context'
import { useAnswers } from 'hooks/contexts/answers-context'
import { useBotContext } from 'hooks/contexts/bot-context'

const useParaphraseListStyles = makeStyles()((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    marginTop: theme.spacing(2),
    marginBottm: theme.spacing(2),
  },
  rowActionContainer: {
    display: 'flex',
  },
  iconButton: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 'auto',
    color: theme.palette.primary.main,
  },
  icon: {
    fontSize: '1.5rem',
  },
  tooltip: {
    display: 'inline-block',
    textAlign: 'right',
  },
}))

type ParaphraseTableRowActionsProps = {
  index: number
  onAddPhrase: (index: number) => void
  onRemovePhrase: (index: number) => void
}

// Special Handling for the None Intent Answer (Entschuldigung Antwort)

// Handles add and remove buttons for paraphrases
function ParaphraseTableRowActions({
  index,
  onAddPhrase,
  onRemovePhrase,
}: ParaphraseTableRowActionsProps): React.ReactElement {
  const classes = { ...useParaphraseListStyles().classes, ...useStyles({}).classes }

  return (
    <div className={classes.flexContainer}>
      <Tooltip className={classes.tooltip} title='Vorschlag hinzufügen' placement='top' disableInteractive>
        <IconButton className={classes.iconButton} aria-label='Add' onClick={(): void => onAddPhrase(index)}>
          <CheckCircle />
        </IconButton>
      </Tooltip>
      <Tooltip className={classes.tooltip} title='Vorschlag entfernen' placement='top' disableInteractive>
        <IconButton className={classes.iconButton} aria-label='Delete' onClick={(): void => onRemovePhrase(index)}>
          <Delete />
        </IconButton>
      </Tooltip>
    </div>
  )
}

type ParaphrasesProps = {
  onAddPhrase: (phrase: string) => void
  utterance: string
  utterances: string[]
}

/**
 * Responsible for getting, displaying and setting paraphrases.
 */
function Paraphrases({ onAddPhrase, utterance, utterances }: ParaphrasesProps): React.ReactElement {
  const { bot } = useBotContext()
  const { classes } = useParaphraseListStyles()
  const { setError } = useErrorContext()

  const [paraphraseStatus, setParaphraseStatus] = useState<'generating' | 'success' | 'error' | undefined>()
  const [paraphrases, setParaphrases] = useState<string[]>([])

  /**
   * Generates paraphrases via API.
   * Currently uses first utterance as basis.
   */
  async function generateParaphrases(): Promise<void> {
    if (utterance.length < 1 || !bot) return
    setParaphraseStatus('generating')
    let generatedParaphrases = await paraphrase(bot.id, utterances.slice(0, 10))

    if (generatedParaphrases === null) {
      setError(
        'Knowledge.specific.createAnswer.paraphraseError',
        'Ähnliche Fragen konnten leider nicht generiert werden. Bitte versuchen Sie es in wenigen Momenten erneut.',
        'Erneut versuchen',
        generateParaphrases,
      )
      setParaphraseStatus('error')
    } else {
      // clean up generatedParaphrases - remove already existing utterances
      generatedParaphrases = difference(generatedParaphrases, utterances)
      // could generate paraphrases, prepend them to existing paraphrases and remove duplicates
      const newParaphrases = Array.from(new Set([...paraphrases, ...generatedParaphrases]))
      setParaphrases(newParaphrases)
      setParaphraseStatus('success')
    }
  }

  /**
   * Removes paraphrase at index from paraphrses list
   */
  function onRemovePhrase(index: number): void {
    const newParaphrases = [...paraphrases]
    newParaphrases.splice(index, 1)
    setParaphrases(newParaphrases)
  }

  /**
   * Handles add of paraphrase.
   * @param index
   */
  function onAddParaphrase(index: number): void {
    onAddPhrase(paraphrases[index])
    onRemovePhrase(index)
  }

  useEffect(() => {
    if (paraphraseStatus !== 'generating') {
      setParaphrases([])
      generateParaphrases()
    }
  }, [utterance])

  return (
    <div className={classes.container}>
      {/* List */}
      <ErrorComponent errorCode='Knowledge.specific.createAnswer.paraphraseError'>
        {(paraphraseStatus === 'success' || paraphraseStatus === 'generating') && paraphrases.length > 0 && (
          <div>
            <Typography>
              <strong>Ähnliche Fragen</strong>
            </Typography>
            <Table
              // title='Ähnliche Fragen'
              rows={paraphrases.map((phrase, index) => [
                <Typography key={`paraphrases-phrase-${index}`}>{phrase}</Typography>,
                <ParaphraseTableRowActions
                  key={`paraphrases-actions-${index}`}
                  index={index}
                  onAddPhrase={onAddParaphrase}
                  onRemovePhrase={onRemovePhrase}
                />,
              ])}
              width={['auto', '100px']}
              cellAlignPattern={['left', 'right']}
              padding='none'
            />
          </div>
        )}
        {paraphraseStatus === 'generating' && paraphrases.length === 0 && (
          <CircularLoading text='Ähnliche Fragen werden generiert...' />
        )}
      </ErrorComponent>
    </div>
  )
}

type useStylesProps = {
  hasUtterances?: boolean
}

// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. ArrowFunctionExpression in CSS prop.
const useStyles = makeStyles<useStylesProps>()((theme, props, classes) => ({
  headingContainer: { display: 'flex' },
  headingErrorText: { color: theme.palette.error.main, marginLeft: theme.spacing(2) },
  headingExpandButtonContainer: { marginLeft: 'auto' },
  flexContainer: { display: 'flex', alignContent: 'center', marginLeft: 'auto' },
  questionText: {
    // maxHeight: `${theme.typography.body1.lineHeight}em`,
    // textOverflow: 'ellipsis',
    // overflow: 'hidden',
    wordWrap: 'break-word',
    width: 'calc(100%)',
  },
  questionsInput: {
    marginTop: props?.hasUtterances ? 'auto' : theme.spacing(3),
  },
  textfield: { width: '100%' },
  iconButton: {
    margin: 'auto',
  },
  icon: {
    fontSize: '1.5rem',
  },
  container: { height: '100%', display: 'flex', flexDirection: 'column' },
  overflowContainer: {
    // overflow: 'auto'
  },
  listContainer: { marginBottom: theme.spacing(1) },
  listRow: { display: 'flex', width: '100%', alignItems: 'center' },
  listAlignRight: { marginLeft: 'auto', display: 'flex' },
}))

type EditAnswerUtterancesProps = {
  answer: Answer
  onUtterancesChangeCallback: (newUtterances: string[]) => void
  rowsPerPage?: number
  allowDisplayAsDialog?: boolean
  showTitle?: boolean
  displayType?: 'table' | 'list'
  generateUtterancesOption?: boolean
}

export default function EditAnswerUtterances({
  answer,
  onUtterancesChangeCallback,
  rowsPerPage = 5,
  allowDisplayAsDialog = false,
  showTitle = true,
  displayType = 'table',
  generateUtterancesOption = false,
}: EditAnswerUtterancesProps): React.ReactElement {
  const { lockState } = useLockingContext()
  const { answersArrayPrimaryLang, canIEditAnswer } = useAnswers()

  const { classes } = useStyles({ hasUtterances: answer.labels.length > 0 })
  const [utterances, setUtterances] = useState<string[]>(answer.labels)
  const [tmpUtterance, setTmpUtterance] = useState<string>() // holds tmp utterance from text input
  const [tmpUtteranceError, setTmpUtteranceError] = useState<string>()
  const [showUtterancesDialog, setShowUtterancesDialog] = useState<boolean>(false)
  const [paraphraseUtt, setParaphraseUtt] = useState<string>('')

  /**
   * Removes utterance at index.
   * @param index
   */
  function onDeleteUtterance(index: number): void {
    if (!canIEditAnswer(answer?.answerId)) return
    const newUtterances = [...utterances]
    newUtterances.splice(index, 1)
    setUtterances(newUtterances)
    onUtterancesChangeCallback(newUtterances)
  }

  /**
   * Handles user typing in create utterance textfield.
   * @param event
   */
  function onCreateUtteranceTextfieldChange(event: React.ChangeEvent<HTMLInputElement>): void {
    if (!canIEditAnswer(answer?.answerId)) return
    setTmpUtteranceError(undefined)
    setTmpUtterance(event.target.value)
  }

  /**
   * Handles form submit of textfield for utterance input.
   * Sets tmpUtterance to utterances and resets it.
   * @param event
   */
  function onCreateUtteranceTextfieldSubmit(event?: React.FormEvent, utterance?: string): void {
    if (!canIEditAnswer(answer?.answerId)) return
    if (event) event.preventDefault()
    if ((tmpUtterance && tmpUtterance.trim()) || (utterance && utterance.trim())) {
      const trimmedTmpUtterance = utterance ? utterance.trim() : tmpUtterance ? tmpUtterance.trim() : '' // prioritize utterance, if it is set we do not want the tmp from the text input
      // check case insensitive if the entered utterance already exists
      const utteranceExists =
        utterances.filter((utterance) => utterance.trim().toLowerCase() === trimmedTmpUtterance.toLowerCase()).length >
        0

      if (utteranceExists) {
        setTmpUtteranceError('Die Frage existiert bereits')
        return
      }

      // check if utterance exists in any answer
      let doesUtteranceExistInOtherAnswer = false
      for (const answer of answersArrayPrimaryLang) {
        const isExisting = answer.labels
          ? answer.labels.filter((label) => label.trim().toLowerCase() === trimmedTmpUtterance ?? []).length > 0
          : []
        if (isExisting) {
          doesUtteranceExistInOtherAnswer = true
          setTmpUtteranceError(`Die Frage existiert bereits in der Antwort "${answer.title}".`)
          return
        }
      }

      if (!doesUtteranceExistInOtherAnswer) {
        const newUtterances = [...utterances, trimmedTmpUtterance]
        setUtterances(newUtterances)
        onUtterancesChangeCallback(newUtterances)
        setTmpUtterance(undefined)
      }
    }
  }

  /**
   * Utterance change callback for dialog.
   * Intercepts callback and updates state.
   */
  function onUtteranceChangeInDialog(newUtterances: string[]): void {
    if (!canIEditAnswer(answer?.answerId)) return
    setUtterances(newUtterances)
    onUtterancesChangeCallback(newUtterances)
  }

  return (
    <div className={classes.container}>
      <div className={classes.headingContainer}>
        {showTitle && (
          <Typography>
            <strong>Fragen</strong>
            {answer.intent === KNOWLEDGE_NONE_INTENT &&
              ' (dieser System-Antwort können keine Fragen hinzugefügt werden)'}
          </Typography>
        )}
        {utterances.length > 0 && allowDisplayAsDialog && (
          <div className={classes.headingExpandButtonContainer}>
            <CustomizedTooltip
              content={<Typography>Mehr anzeigen</Typography>}
              elements={
                <IconButton
                  className={classes.iconButton}
                  aria-label='Delete'
                  onClick={(event): void => {
                    event.preventDefault()
                    setShowUtterancesDialog(true)
                  }}
                >
                  <UnfoldMore classes={{ root: classes.icon }} />
                </IconButton>
              }
              placement='top'
              disableInteractive
            />
          </div>
        )}
        {utterances.length < KNOWLEDGE_MIN_UTTERANCES && answer.intent !== KNOWLEDGE_NONE_INTENT ? (
          <div className={classes.headingErrorText}>
            <Typography>
              <em>Es müssen mindestens {KNOWLEDGE_MIN_UTTERANCES} Fragen angegeben werden!</em>
            </Typography>
          </div>
        ) : null}
      </div>
      <div className={classes.overflowContainer}>
        {displayType === 'table' && (
          <Table
            rows={utterances.map((utterance, index) => [
              <Typography key={`utterance-${index}`} className={classes.questionText}>
                {utterance}
              </Typography>,
              <>
                {generateUtterancesOption && (
                  <CustomizedTooltip
                    key={`utterance-${index}-generate`}
                    content={<Typography>Fragen ähnlich zu dieser generieren</Typography>}
                    elements={
                      <IconButton
                        className={classes.iconButton}
                        aria-label='Generate'
                        onClick={(event): void => {
                          event.preventDefault()
                          setParaphraseUtt(utterance)
                        }}
                        disabled={lockState !== 'canEdit' || !canIEditAnswer(answer?.answerId)}
                      >
                        <i className={'ri-a-b ' + classes.icon} />
                      </IconButton>
                    }
                    placement='top'
                    disableInteractive
                  />
                )}
                <CustomizedTooltip
                  key={`utterance-${index}-delete`}
                  content={<Typography>Frage entfernen</Typography>}
                  elements={
                    <IconButton
                      className={classes.iconButton}
                      aria-label='Delete'
                      onClick={(event): void => {
                        event.preventDefault()
                        onDeleteUtterance(index)
                      }}
                      disabled={utterances.length < 5 || lockState !== 'canEdit' || !canIEditAnswer(answer?.answerId)}
                    >
                      <Delete classes={{ root: classes.icon }} />
                    </IconButton>
                  }
                  placement='top'
                  disableInteractive
                />
              </>,
            ])}
            rowsPerPage={rowsPerPage}
            disableRowsPerPageSelection
            cellAlignPattern={['left', 'right']}
            width={['90%', '10%']}
            padding='none'
          />
        )}
        {displayType === 'list' && (
          <div className={classes.listContainer}>
            {utterances.map((utterance, index) => (
              <div
                key={`utterance-${index}`}
                className={classes.listRow}
                style={{ borderTop: index > 0 ? '1px solid rgba(224, 224, 224, 1)' : undefined }}
              >
                <CustomizedTooltip
                  key={`utterance-${index}`}
                  content={<Typography>{utterance}</Typography>}
                  elements={<Typography className={classes.questionText}>{utterance}</Typography>}
                  placement='top'
                />
                <div className={classes.listAlignRight}>
                  {generateUtterancesOption && (
                    <CustomizedTooltip
                      key={`utterance-${index}-generate`}
                      content={<Typography>Fragen ähnlich zu dieser generieren</Typography>}
                      elements={
                        <IconButton
                          className={classes.iconButton}
                          aria-label='Generate'
                          onClick={(event): void => {
                            event.preventDefault()
                            setParaphraseUtt(utterance)
                          }}
                          disabled={lockState !== 'canEdit' || !canIEditAnswer(answer?.answerId)}
                        >
                          <i className={'ri-a-b ' + classes.icon} />
                        </IconButton>
                      }
                      placement='top'
                      disableInteractive
                    />
                  )}
                  <CustomizedTooltip
                    key={`utterance-${index}-delete`}
                    content={<Typography>Frage entfernen</Typography>}
                    elements={
                      <IconButton
                        className={classes.iconButton}
                        aria-label='Delete'
                        onClick={(event): void => {
                          event.preventDefault()
                          onDeleteUtterance(index)
                        }}
                        disabled={utterances.length < 5 || lockState !== 'canEdit' || !canIEditAnswer(answer?.answerId)}
                      >
                        <Delete classes={{ root: classes.icon }} />
                      </IconButton>
                    }
                    placement='top'
                    disableInteractive
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {generateUtterancesOption && paraphraseUtt.length > 0 && (
        <Paraphrases
          utterance={paraphraseUtt}
          onAddPhrase={(phrase): void => {
            onCreateUtteranceTextfieldSubmit(undefined, phrase)
          }}
          utterances={utterances}
        />
      )}
      <div className={classes.questionsInput}>
        <form
          onSubmit={(event): void => {
            if (lockState !== 'canEdit' || !canIEditAnswer(answer?.answerId)) return

            event.preventDefault()
            onCreateUtteranceTextfieldSubmit(event)
          }}
        >
          <div className={classes.flexContainer}>
            <Textfield
              onChange={onCreateUtteranceTextfieldChange}
              value={tmpUtterance}
              placeholder='Frage'
              InputProps={{ autoComplete: 'off' }}
              label={tmpUtteranceError || 'Frage hinzufügen'}
              className={classes.textfield}
              error={!!tmpUtteranceError}
              disabled={!canIEditAnswer(answer?.answerId) || answer.intent === KNOWLEDGE_NONE_INTENT}
            />
            <CustomizedTooltip
              content={<Typography>Frage hinzufügen</Typography>}
              elements={
                <IconButton
                  type='submit'
                  aria-label='Add'
                  className={classes.iconButton}
                  disabled={
                    lockState !== 'canEdit' ||
                    !!tmpUtteranceError ||
                    !tmpUtterance ||
                    tmpUtterance.trim() === '' ||
                    answer.intent === KNOWLEDGE_NONE_INTENT ||
                    !canIEditAnswer(answer?.answerId)
                  }
                >
                  <Add className={classes.icon} />
                </IconButton>
              }
              placement='top'
            />
          </div>
        </form>
      </div>
      {showUtterancesDialog && (
        <EditAnswerUtterancesDialog
          open={showUtterancesDialog}
          answer={answer}
          onUtterancesChangeCallback={onUtteranceChangeInDialog}
          onClose={(): void => setShowUtterancesDialog(false)}
        />
      )}
    </div>
  )
}
