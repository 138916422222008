import React from 'react'
import { Route, Navigate, Routes, useLocation } from 'react-router-dom'

// Material UI
import { makeStyles } from 'tss-react/mui'

// Custom Components
// Assets
// hooks
// constants
import { CustomNotificationOverview } from './Overview/CustomNotificationOverview'
import ContentPage from 'components/Page/ContentPage'
import ErrorComponent from 'components/Error/Error'
import { ROUTE_BOTID_NOTIFICATIONS, ROUTE_BOTID_NOTIFICATIONS_EDITNOTIFICATION } from 'utils/constants'
import EditCustomNotification from './EditCustomNotification/EditCustomNotification'

const useStyles = makeStyles()({})

export default function CustomNotifications(): React.ReactElement {
  const { classes } = useStyles()
  const { pathname: path } = useLocation()

  return (
    <ContentPage>
      {/* Routes immer in Switch */}
      <ErrorComponent errorCode='CustomNotifications.loadNotificationsError'>
        <Routes>
          <Route path={'/*'} element={<CustomNotificationOverview />} />
          {/* <Route path={''} element={<p>Notification</p>} /> */}
          <Route path={ROUTE_BOTID_NOTIFICATIONS_EDITNOTIFICATION + '/*'} element={<EditCustomNotification />} />
          <Route path='*' element={<Navigate to={path} />} />
        </Routes>
      </ErrorComponent>
    </ContentPage>
  )
}
