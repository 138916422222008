import React, { memo } from 'react'
import { Controls, ControlButton } from 'reactflow'
import CustomizedTooltip from 'components/Tooltips/CustomContentTooltip'
import { makeStyles } from 'tss-react/mui'
import { Typography } from '@mui/material'

const useStyles = makeStyles()((theme) => ({
  controls: {
    left: 'auto !important',
    right: '10px',
    display: 'flex',
  },
}))

type EditorControlsProps = {
  onZoomIn: () => void
  onZoomOut: () => void
  onFitView: () => void
  onDisplaySmartCardsChange: () => void
  onAlignToGrid: () => void
  displaySmartCards: boolean
}

export default memo(function EditorControls({
  onZoomIn,
  onZoomOut,
  onFitView,
  displaySmartCards,
  onAlignToGrid,
  onDisplaySmartCardsChange,
}: EditorControlsProps): React.ReactElement {
  const { classes } = useStyles()
  return (
    <Controls className={classes.controls} showZoom={false} showFitView={false} showInteractive={false}>
      <CustomizedTooltip
        content={<Typography variant='caption'>Vergrößern</Typography>}
        elements={
          <div>
            <ControlButton onClick={onZoomIn}>
              <i className={'ri-zoom-in-line'} />
            </ControlButton>
          </div>
        }
        placement='top'
      />
      <CustomizedTooltip
        content={<Typography variant='caption'>Verkleinern</Typography>}
        elements={
          <div>
            <ControlButton onClick={onZoomOut}>
              <i className={'ri-zoom-out-line'} />
            </ControlButton>
          </div>
        }
        placement='top'
      />
      <CustomizedTooltip
        content={
          <Typography variant='caption'>
            {displaySmartCards ? 'Smart Cards ausblenden' : 'Smart Cards anzeigen'}
          </Typography>
        }
        elements={
          <div>
            <ControlButton onClick={onDisplaySmartCardsChange}>
              {displaySmartCards ? <i className={'ri-eye-off-line'} /> : <i className={'ri-eye-line'} />}
            </ControlButton>
          </div>
        }
        placement='top'
      />
      <CustomizedTooltip
        content={<Typography variant='caption'>Anzeige anpassen</Typography>}
        elements={
          <div>
            <ControlButton onClick={onFitView}>
              <i className={'ri-fullscreen-line'} />
            </ControlButton>
          </div>
        }
        placement='top'
      />
      <CustomizedTooltip
        content={<Typography variant='caption'>An Raster ausrichten</Typography>}
        elements={
          <div>
            <ControlButton onClick={onAlignToGrid}>
              <i className={'ri-align-vertically'} />
            </ControlButton>
          </div>
        }
        placement='top'
      />
    </Controls>
  )
})
