import { useEffect, useContext } from 'react'
import {
  PublicClientApplication,
  EventType,
  AuthenticationResult,
  InteractionRequiredAuthError,
} from '@azure/msal-browser'
import authConfig from '../../config/authProvider.config'
import { AbilityContext } from '../Can/Can'
import { updatePermissions, resetPermissions } from '../Can/ability'

type AccountInfo = {
  homeAccountId: string
  environment: string
  tenantId: string
  username: string
  localAccountId: string
  name?: string
  idTokenClaims?: {
    oid?: string
    given_name?: string
    family_name?: string
    extension_Role?: string
    extension_CustomerID?: string
  }
}

// MSAL configuration
const configuration = {
  auth: {
    clientId: authConfig.clientId,
    authority: authConfig.authority,
    redirectUri: authConfig.redirectUri,
    knownAuthorities: authConfig.knownAuthorities,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
}

// Scopes of the LoginRequest
export const loginRequest = {
  scopes: authConfig.scopes,
}

/**
 * The Public Client Application used for user authentification and validation
 */
export const msalInstance = new PublicClientApplication(configuration)

/**
 * Gets the currently logged in account
 * @returns The current account or null if none was found
 * @since v0.2.14
 */
export function getAccount(): AccountInfo | null {
  const activeAccount = msalInstance.getActiveAccount() // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const accounts = msalInstance.getAllAccounts()
  return activeAccount || accounts[0] || null
}

/**
 * Returns a promise that resolves in an accessToken for the current user using PublicClientApplication.acquireTokenSilent
 * @param accountInput AccountInfo if existent, else will trigger getAccount()
 * @returns A valid accessToken for the user or null if no accessToken could be retrieved.
 * @since v0.2.14
 */
export function getSilentToken(accountInput: AccountInfo | null | undefined): Promise<string | null> {
  return new Promise((resolve) => {
    const account = accountInput || getAccount()
    if (account !== null) {
      msalInstance
        .acquireTokenSilent({
          scopes: loginRequest.scopes,
          account,
        })
        .then((response) => {
          if (response.accessToken) {
            resolve(response.accessToken)
          } else {
            resolve(null)
          }
        })
        .catch(function (error) {
          // call acquireTokenPopup in case of acquireTokenSilent failure
          // due to consent or interaction required
          // reference: https://docs.microsoft.com/de-de/azure/active-directory/develop/msal-error-handling-js#errors-that-require-interaction
          // SPA refresh tokens always have a max lifetime of 24h. Beyond that the user has to sign in again.
          // If the app is open longer than 24 hours, the user will be prompted to sign in again.
          // We can do this either via popup or by redirecting. Popup can cause issues with popup blockers, so we redirect to the login page.
          // Redirecting does not give a direct result. The resulting promise is handled via the AuthEventsHandler.
          if (error instanceof InteractionRequiredAuthError) {
            // if (
            //   error.errorCode === 'consent_required' ||
            //   error.errorCode === 'interaction_required' ||
            //   error.errorCode === 'login_required'
            // ) {
            // do redirect instead of popup for reauthentication
            msalInstance.acquireTokenRedirect({
              scopes: loginRequest.scopes,
              account,
            })
            // THIS IS THE POPUP FLOW WITH DIRECT RESPONSE
            // PCA.acquireTokenPopup({
            //   scopes: loginRequest.scopes,
            //   account,
            // })
            //   .then(function (response) {
            //     if (response.accessToken) {
            //       resolve(response.accessToken)
            //     } else {
            //       resolve(null)
            //     }
            //   })
            //   .catch(function (error) {
            //     console.error(error)
            //     resolve(null)
            //   })
            // }
          }
        })
    } else {
      resolve(null)
    }
  })
}

/**
 * This handler listens to all auth events and acts on them if needed.
 * Currently it only sets the user that logged in as the active user in case more than one account are logged in.
 *
 * Needs a MsalProvider up the tree!
 * @returns null - just add logic
 * @since v0.2.14
 */
export function AuthEventsHandler(): null {
  const ability = useContext(AbilityContext)

  useEffect(() => {
    // This will be run on component mount
    const callbackId = msalInstance.addEventCallback((message) => {
      // This will be run every time an event is emitted after registering this callback
      if (message.eventType) {
        switch (message.eventType) {
          case EventType.LOGIN_SUCCESS:
          case EventType.ACQUIRE_TOKEN_SUCCESS:
            if (message.payload) {
              const result = message.payload as AuthenticationResult
              if (result && result.account !== undefined && result.account !== null) {
                msalInstance.setActiveAccount(result.account)
                // Update CASL permissions
                updatePermissions(ability, result.account.idTokenClaims)
              }
            }
            break
          // case EventType.HANDLE_REDIRECT_END:
          //   const account = PCA.getAccount()
          //   break
          case EventType.LOGOUT_SUCCESS:
            // Reset CASL Permissions
            resetPermissions(ability)
            break
          default:
            break
        }
      }
    })

    return (): void => {
      // This will be run on component unmount
      if (callbackId) {
        msalInstance.removeEventCallback(callbackId)
      }
    }
  }, [])

  return null
}
