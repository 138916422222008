import React, { ReactElement } from 'react'
import EditorSidebarItem from '../editorSidebarItem'
import { FLOWDESIGNER_NODE_COLOR_NOTE } from 'utils/constants'
import { Note } from '@mui/icons-material'

export default function note(): ReactElement {
  return (
    <EditorSidebarItem
      type='basic/note'
      ports={{}}
      properties={{
        typeText: 'Notiz',
        text: '',
        note: '',
        dialog: '',
      }}
      Icon={Note}
      color={FLOWDESIGNER_NODE_COLOR_NOTE}
    />
  )
}
