import React from 'react'
import { makeStyles } from 'tss-react/mui'

import { Chart } from '../../../../../@types/Flowchart/types'
import { Typography } from '@mui/material'

const useStyles = makeStyles()((theme) => ({
  selectedDialogContainer: {},
  hintContainer: { marginTop: theme.spacing(4), display: 'flex' },
}))

type SStartProps = {
  chart: Chart
}

export default function SStart({ chart }: SStartProps): React.ReactElement {
  const { classes } = useStyles()

  return (
    <div className={classes.selectedDialogContainer}>
      <div>
        <Typography>
          Startblock für Dialog{chart.selected.id ? ': ' : ''}
          <em>
            <b>{chart.selected.id ? `${chart.dialogs[chart.nodes[chart.selected.id].properties.dialog].name}` : ''}</b>
          </em>
        </Typography>
      </div>

      <div className={classes.hintContainer}>
        <Typography variant='caption'>
          Hinweis: <em>Dies ist ein automatisch vom System erstellter Block, der nicht bearbeitet werden kann.</em>
        </Typography>
      </div>
    </div>
  )
}
