import { CompareArrows } from '@mui/icons-material'
import React, { ReactElement } from 'react'
import EditorSidebarItem from '../editorSidebarItem'
import { FLOWDESIGNER_NODE_COLOR_LOGIC } from 'utils/constants'

export default function api(): ReactElement {
  return (
    <EditorSidebarItem
      type='basic/api'
      ports={{
        port1: {
          id: 'port1',
          type: 'left',
          position: { x: 0, y: 0 },
          properties: {
            type: 'incoming',
          },
        },
        port2: {
          id: 'port2',
          type: 'right',
          position: { x: 0, y: 0 },
          properties: {
            type: 'outgoing',
            cond: false,
            name: 'Erfolgreich',
          },
        },
        port3: {
          id: 'port3',
          type: 'right',
          position: { x: 0, y: 0 },
          properties: {
            type: 'outgoing',
            cond: false,
            name: 'Fehlgeschlagen',
          },
        },
      }}
      properties={{
        typeText: 'API',
        text: 'API',
        api: {
          request: {
            url: '',
            method: 'get',
          },
          response: {
            saveConfig: {},
          },
        },
        dialog: '',
      }}
      Icon={CompareArrows}
      color={FLOWDESIGNER_NODE_COLOR_LOGIC}
    />
  )
}
