import React, { useState, useEffect } from 'react'

import { IAdaptiveCard, Card } from '../../../../../../@types/SmartCards/types'

// @mui/material components
import { makeStyles } from 'tss-react/mui'

import { useTheme } from '@mui/material/styles'

// custom components
import BaseCard from 'components/Cards/BaseCard'
import SmartCardList from 'components/CardList/SmartCardList'
import Button from 'components/Buttons/Button'
// assets
import svgChooseCardMenuImage from 'assets/img/flowDesigner/chooseCardMenuImage.svg'
import svgCreateCardMenuImage from 'assets/img/flowDesigner/createCardMenuImage.svg'

const useStyles = makeStyles()({
  menuContainer: {
    display: 'flex',
    height: 'calc(100% - 60px)',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  menuImage1: {
    width: '50%',
    margin: 'auto',
  },
  builderActions: {
    height: '60px',
    marginTop: 'auto',
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'end',
  },
  card: {
    alignItems: 'center',
    // transition: 'box-shadow 0.3s',
    // '&:hover': {
    //   boxShadow:
    //     '0 14px 26px -12px rgb(0 0 0 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)', // '0px 2px 10px 2px rgba(0,0,0, 0.25)'
    // },
  },
})

type ChooseCardOrCreateCardMenu = {
  cards: () => Card[]
  usedCards: string[] // list of all card (ids) that are currently used by nodes
  setChoosenCard: (card: string) => void
  setNext: (mode: 'choosen' | 'create') => void
  onDelete: () => void
  onDeleteExistingCard: (cardId: string) => void // deletes an existing card
}

/**
 * Component that returns a choice of choosing an existing card or creating a new one
 * @param props
 * @returns The components for the Smart Card dialog
 */
export default function ChooseCardOrCreateCardMenu({
  cards: cardsProps,
  usedCards,
  setChoosenCard,
  setNext,
  onDelete,
  onDeleteExistingCard,
}: ChooseCardOrCreateCardMenu): React.ReactElement {
  const { classes } = useStyles()
  const theme = useTheme()
  const [mode, setMode] = useState<'choosen' | 'create' | undefined>()

  return typeof mode === 'undefined' ? (
    <>
      <div className={classes.menuContainer}>
        {/* 1st Card to choose an existing card */}
        <BaseCard
          footerText='Card auswählen'
          footerColor={theme.palette.success.main}
          onClick={(): void => setMode('choosen')}
          shadowWidth={20}
          shadowBlur={20}
          className={classes.card}
        >
          <img
            className={classes.menuImage1}
            src={svgChooseCardMenuImage}
            alt={'Darstellung Auswahl einer existierenden Card'}
          />
        </BaseCard>
        {/* 2st Card to create a new Card */}
        <BaseCard
          footerText='Neue Card erstellen'
          footerColor={theme.palette.warning.main}
          onClick={(): void => setNext('create')}
          shadowWidth={20}
          shadowBlur={20}
          className={classes.card}
        >
          <img
            className={classes.menuImage1}
            src={svgCreateCardMenuImage}
            alt={'Darstellung Erstellung einer neuen Card'}
          />
        </BaseCard>
      </div>
      <div className={classes.builderActions}>
        <Button size='small' variant='tertiary' onClick={onDelete}>
          Löschen
        </Button>
      </div>
    </>
  ) : (
    <SmartCardList
      cards={cardsProps()}
      cardsInUse={usedCards}
      onDeleteExistingCard={onDeleteExistingCard}
      onBackClick={(): void => setMode(undefined)}
      choseCard={(cardName): void => setChoosenCard(cardName)}
    />
  )
}
