import React, { memo } from 'react'
import { makeStyles } from 'tss-react/mui'
import { IAdaptiveCard } from '../../@types/SmartCards/types'
import SmartCard from 'components/SmartCard/SmartCard'
import { isEqual } from 'lodash'

const useStyles = makeStyles()((theme) => ({
  previewContainer: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius:
      typeof theme.shape.borderRadius === 'number' ? theme.shape.borderRadius * 2 : theme.shape.borderRadius,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
  },
  userMessage: {
    minHeight: '31px',
    borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0px ${theme.shape.borderRadius}px`,
    backgroundColor: theme.palette.primary.main,
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginLeft: 'auto',
    height: 'auto',
    width: 'auto',
    maxWidth: '300px',
    color: theme.palette.common.white,
    textAlign: 'right',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    overflow: 'auto',
  },
  cardMessage: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    maxHeight: '500px',
    marginRight: 'auto',
    marginBottom: 'auto',
    minHeight: '80px',
    width: '80%',
    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.15), 0px 3px 6px rgba(0, 0, 0, 0.1)',
    border: `1px solid ${theme.palette.grey.A100}`,
    borderRadius: `0px ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
    overflowY: 'auto',
  },
  input: {
    marginTop: 'auto',
    marginLeft: 'auto',
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: '30px',
    width: '30px',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
  },
  actions: {
    marginTop: theme.spacing(1),
    minHeight: '31px',
    borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0px ${theme.shape.borderRadius}px`,
    backgroundColor: theme.palette.primary.main,
    marginRight: theme.spacing(2),
    marginLeft: 'auto',
    height: 'auto',
    width: 'auto',
    maxWidth: '300px',
    color: theme.palette.common.white,
    textAlign: 'right',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  actionsContainer: {
    '& div:last-child': {
      marginBottom: theme.spacing(2),
    },
    display: 'flex',
    flexDirection: 'column',
  },
}))

type AnswerPreviewProps = {
  answer: string
  question?: string
  actions: string[]
  noContainer?: boolean
  displayFeedback?: boolean
}

export default memo(function AnswerPreview({
  answer,
  question,
  actions = [],
  noContainer = false,
  displayFeedback = true,
}: AnswerPreviewProps): React.ReactElement {
  const { classes } = useStyles()

  const card = {
    type: 'AdaptiveCard',
    body: [
      {
        type: 'TextBlock',
        text: answer,
        wrap: true,
        spacing: 'Medium',
        separator: true,
      },
      displayFeedback
        ? {
            type: 'Container',
            spacing: 'Medium',
            items: [
              {
                type: 'TextBlock',
                wrap: true,
                text: 'Hat dies Ihre Frage beantwortet?',
              },
            ],
          }
        : undefined,
      displayFeedback
        ? {
            type: 'ColumnSet',
            separator: true,
            spacing: 'Medium',
            columns: [
              {
                type: 'Column',
                width: 'stretch',
                items: [
                  {
                    type: 'ActionSet',
                    actions: [
                      {
                        type: 'Action.Submit',
                        title: 'Ja',
                        data: 'Ja',
                        id: 'answered',
                        style: 'positive',
                      },
                    ],
                  },
                ],
              },
              {
                type: 'Column',
                width: 'stretch',
                items: [
                  {
                    type: 'ActionSet',
                    actions: [
                      {
                        type: 'Action.Submit',
                        title: 'Nein',
                        id: 'notanswered',
                        data: 'Nein',
                        style: 'destructive',
                      },
                    ],
                  },
                ],
              },
            ],
          }
        : undefined,
    ],
    $schema: 'https://adaptivecards.io/schemas/adaptive-card.json',
    version: '1.3',
    $data: '${$root.variables}',
  } as unknown as IAdaptiveCard

  return (
    <div className={noContainer ? '' : classes.previewContainer}>
      {typeof question === 'string' && <div className={classes.userMessage}>{question}</div>}
      <div className={classes.cardMessage}>
        <SmartCard card={card} />
      </div>
      <div className={classes.actionsContainer}>
        {actions.map((action, index) => (
          <div key={index + action.substring(0, 3)} className={classes.actions}>
            {action}
          </div>
        ))}
      </div>
    </div>
  )
}, isEqual)
