import React, { memo, useRef, useState, useEffect, FC, ReactNode, CSSProperties, HTMLAttributes } from 'react'
import { Rect } from 'reactflow'

interface EdgeLabelProps extends HTMLAttributes<SVGElement> {
  x: number
  y: number
  label?: string | ReactNode
  labelStyle?: CSSProperties
  labelShowBg?: boolean
  labelBgStyle?: CSSProperties
  labelBgPadding?: [number, number]
  labelBgBorderRadius?: number
  selected?: boolean
}

const EdgeLabel: FC<EdgeLabelProps> = ({
  x,
  y,
  label,
  labelStyle = {},
  labelShowBg = true,
  labelBgStyle = { fill: 'white' },
  labelBgPadding = [2, 4],
  labelBgBorderRadius = 2,
  children,
  className,
  selected,
  ...rest
}: EdgeLabelProps) => {
  const edgeRef = useRef<SVGTextElement>(null)
  const [edgeTextBbox, setEdgeTextBbox] = useState<Rect>({ x: 0, y: 0, width: 0, height: 0 })

  useEffect(() => {
    if (edgeRef.current) {
      const textBbox = edgeRef.current.getBBox()

      setEdgeTextBbox({
        x: textBbox.x,
        y: textBbox.y,
        width: textBbox.width,
        height: textBbox.height,
      })
    }
  }, [label])

  if (typeof label === 'undefined' || !label) {
    return null
  }

  return (
    <g
      transform={`translate(${x} ${y - edgeTextBbox.height / 2})`}
      // className={edgeTextClasses}
      {...rest}
    >
      {labelShowBg && (
        <rect
          width={edgeTextBbox.width + 2 * labelBgPadding[0]}
          x={-labelBgPadding[0]}
          y={-labelBgPadding[1]}
          height={edgeTextBbox.height + 2 * labelBgPadding[1]}
          // className='react-flow__edge-textbg'
          style={labelBgStyle}
          rx={labelBgBorderRadius}
          ry={labelBgBorderRadius}
        />
      )}
      <text
        y={edgeTextBbox.height / 2}
        textAnchor='start'
        dy='0.3em'
        ref={edgeRef}
        style={{ ...labelStyle, fontWeight: selected ? 'bolder' : 'normal' }}
      >
        {label}
      </text>
      {children}
    </g>
  )
}
export default memo(EdgeLabel)
