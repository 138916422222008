import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Typography } from '@mui/material'

import SelectDropdown, { ActionMeta, Option } from '../../../../../../components/Dropdown/SelectDropdown'

import { Answer } from '../../../../../../classes/Knowledge'
import { useAnswers } from 'hooks/contexts/answers-context'

const useStyles = makeStyles()((theme) => ({
  buttonContainer: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '80%',
  },
  topicDropdown: {
    marginTop: theme.spacing(3),
  },
}))

type Step1TopicProps = {
  answers: Answer[] // all answers to find all topics
  selectedTopic?: string
  onSetTopic: (topic: string) => void
  topicsWithoutAnswers: string[] // topics that have no answers need to be collected since they cannot be extracted from the answers but should be available in the choose topic screen
}

export default function StepTopic({
  answers,
  selectedTopic,
  onSetTopic,
  topicsWithoutAnswers,
}: Step1TopicProps): React.ReactElement {
  const { classes } = useStyles()
  const { canICreateTopicAndItsAnswers } = useAnswers()

  const [topic, setTopic] = useState<Option>()
  const [topics, setTopics] = useState<Option[]>([])

  /**
   * Retrieves all topics from answers and sets them in state.
   * @param answers
   */
  function getAllTopics(answers: Answer[]): Option[] {
    const topics: Option[] = canICreateTopicAndItsAnswers('*') ? [{ label: 'Kein Thema', value: 'Kein Thema' }] : []
    let topicNames: string[] = []
    answers.forEach((answer) => {
      if (answer.answerType === 'answer') answer = answer as Answer
      if (answer.topic && !topicNames.includes(answer.topic)) {
        topicNames.push(answer.topic)
      }
    })

    // if the given topic (selectedTopic) is a new topic without any answers, it would not show in the list, but in the mindmap
    // this means we have to check if the selected topic (from props) is part of topics and if not we have to add it as well
    if (typeof selectedTopic === 'string' && selectedTopic.length !== 0) {
      const result = topicNames.find((topic) => topic === selectedTopic)
      if (!result) topicNames.push(selectedTopic)
    }
    // Add remaining topics that have no answers or are not the selectedTopic
    for (const topicWithoutAnswer of topicsWithoutAnswers) {
      const result = topicNames.find((topic) => topic === topicWithoutAnswer)
      if (!result) topicNames.push(topicWithoutAnswer)
    }

    // go through all topics once more an check if parent topics like "Test/Subtest" -> "Test" are also in the list. If not add them.
    for (const topic of topicNames) {
      const list = topic.split('/') // get all parent folders as list of seperated strings and go through them
      if (list && list.length > 1) {
        // check only if the topic has a parent that needs to be added, the topic itself does not need to be added
        for (let i = 0; i < list.length - 1; i++) {
          // do this to also get middle topics
          // example: "topic1/topic2/topic3" -> we need to add "topic1", "topic1/topic2" and "topic1/topic2/topic3"
          const tmp = list.slice(0, i)
          const searchTopic = tmp.join('/')
          // for each check if it exists as single topic
          const result = topicNames.find((topicName) => topicName === searchTopic)
          // if not, add it to the array of topics
          if (!result && !!searchTopic) topicNames.push(searchTopic)
        }
      }
    }

    topicNames.sort()
    // filter trigger from topic
    topicNames = topicNames.filter((topic) => topic !== 'Trigger')
    topicNames.forEach((topic) => {
      const canICreate = canICreateTopicAndItsAnswers(topic)
      topics.push({
        label: `${topic}${!canICreate ? ' (Keine Berechtigung)' : ''}`,
        value: topic,
        disabled: !canICreate,
      })
    })

    return topics
  }

  /**
   * Handles topic change in Dropdown.
   *
   * @param newValue
   * @param action
   */
  function onTopicChange(newValue: Option, action: ActionMeta<Option>): void {
    setTopic(newValue)
    onSetTopic(newValue.value)
  }

  useEffect(function () {
    const topics = getAllTopics(answers)
    setTopics(topics)
    if (selectedTopic) {
      // find selected option and set it
      const result = topics.find((topic) => topic.value === selectedTopic)
      if (result) setTopic(result)
    }
  }, [])

  return (
    <div>
      <Typography>
        Neue Antworten müssen einem Thema zugeordnet werden. Themen gruppieren Antworten zu gleichen bzw. ähnlichen
        Themenschwerpunkten und vereinfachen so die Wissensverwaltung.
      </Typography>
      <div className={classes.topicDropdown}>
        <SelectDropdown
          selected={topic}
          options={topics}
          onChange={onTopicChange}
          isCreatable={canICreateTopicAndItsAnswers('*')}
        />
      </div>
    </div>
  )
}
