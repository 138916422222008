import React from 'react'
import { useParams } from 'react-router-dom'

import KnowledgeNlu from './Nlu/KnowledgeNlu'
import AADatenManagement from './AlephAlpha/AADatenManagement'

import { MODULE_TYPE_ALEPHALPHA, MODULE_TYPE_NLU, MODULE_TYPE_RAG } from 'utils/constants'
import { useBotContext } from 'hooks/contexts/bot-context'
import ContentPage from 'components/Page/ContentPage'
import { LockingContextProvider } from 'hooks/contexts/locking-context'
import KnowledgeRag from './RAG/KnowledgeRAG'

// TODO: Fix params
export default function Knowledge(): React.ReactElement {
  const { bot } = useBotContext()
  const { moduleConfigId } = useParams() as { moduleConfigId: string }

  return (
    <ContentPage>
      {bot?.modules[moduleConfigId].type === MODULE_TYPE_NLU ? (
        // Locking Context Provider lives in the component because we differentiate
        <KnowledgeNlu />
      ) : bot?.modules[moduleConfigId].type === MODULE_TYPE_RAG ? (
        <KnowledgeRag />
      ) : bot?.modules[moduleConfigId].type === MODULE_TYPE_ALEPHALPHA ? (
        <LockingContextProvider lockScopes={['aleph-alpha-lumi']}>
          <AADatenManagement />
        </LockingContextProvider>
      ) : null}
    </ContentPage>
  )
}
