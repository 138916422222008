import { makeStyles } from 'tss-react/mui'
import { Typography } from '@mui/material'
import { Check } from '@mui/icons-material'
import { Answer } from 'classes/Knowledge'
import React, { useRef, useState } from 'react'
import {
  AnswerTranslation,
  EndpointType,
  EndpointUtteranceAnswered,
  EndpointUtteranceUnanswered,
} from '../../../../../../@types/Knowledge/types'
import { Button } from 'components/Buttons'
import { Dialog } from 'components/Dialogs'
import AssignUtteranceDialogMainScreen from './AssignUtteranceDialogMainScreen'
import AssignUtteranceDialogTopicsScreen from './AssignUtteranceDialogTopicsScreen'
import AssignUtteranceDialogTopicAnswers from './AssignUtteranceDialogTopicAnswers'

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  quickSelectContainer: {},
  searchContainer: {
    marginTop: theme.spacing(2),
  },
  searchField: {
    marginTop: theme.spacing(2),
  },
  searchResults: { marginTop: theme.spacing(2), overflow: 'auto' },
  searchShowMoreButton: { marginTop: theme.spacing(1), width: '100%', textAlign: 'center' },
  subtitle: { marginTop: theme.spacing(1), marginBottom: theme.spacing(1) },

  questionContainer: {
    width: '100%',
  },
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    width: '100%',
    height: '100%',
  },
  flexContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  containerLeft: {
    width: '50%',
    height: '100%',
    // borderRight: `1px solid ${theme.palette.primary.main}`,
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  containerRight: {
    marginLeft: 'auto',
    width: '50%',
    paddingLeft: theme.spacing(2),
  },
  containerNewAnswer: {
    marginTop: theme.spacing(4),
    justifyContent: 'center',
    display: 'flex',
    width: '100%',
  },
  suggestionContainer: {
    marginTop: theme.spacing(2),
  },
  suggestionTextfield: {
    width: '100%',
    marginRight: theme.spacing(1),
    cursor: 'pointer',
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `2px solid ${theme.palette.success.main}`,
      width: '100%',
    },
    '& label.Mui-focused': {
      color: `${theme.palette.success.main}`,
    },
    '& input': {
      cursor: 'pointer',
    },
  },
  iconButton: { padding: theme.spacing(1), margin: 'auto' },
  icon: { fontSize: '1.2rem', color: theme.palette.primary.main },
  iconSelected: { fontSize: '1.2rem', color: theme.palette.success.main },
  selectAnswerDropdown: {
    marginTop: theme.spacing(2),
    width: 'calc(100% - 35px)',
  },
  previewContainer: {
    maxHeight: '286px',
    overflowY: 'scroll',
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius:
      typeof theme.shape.borderRadius === 'number' ? theme.shape.borderRadius * 2 : theme.shape.borderRadius,
    // flex: 1,
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    // overflow: 'hidden',
    // marginBottom: '60px',
  },
  previewContent: {
    overflow: 'auto',
    height: '100%',
  },
  markdown: { '& p': { margin: '0px !important' } },
  exampleQuestionsContainer: {
    height: 'auto',
    overflow: 'auto',
  },
}))

type EditSuggestionDialogProps = {
  utterance: EndpointUtteranceAnswered | EndpointUtteranceUnanswered
  answers: { [answerId: string]: Answer }
  onClose: () => void
  onRemove: (utterance: EndpointUtteranceAnswered | EndpointUtteranceUnanswered) => void
  onCreateAnswer: () => void
  onConfirm: (utterance: EndpointUtteranceAnswered | EndpointUtteranceUnanswered, answer: Answer) => void
  endpointType: EndpointType
}

export type AssignUtteranceDialogScreen = 'main' | 'topics' | 'answersOfTopic' | 'summary'

export default function EditSuggestionAnsweredDialog({
  utterance,
  answers,
  onClose,
  onRemove,
  onCreateAnswer,
  onConfirm: onConfirmCallback,
  endpointType,
}: EditSuggestionDialogProps): React.ReactElement {
  const { classes } = useStyles()
  const [currentScreen, setCurrentScreen] = useState<AssignUtteranceDialogScreen>('main')
  const [selectedAnswerId, setSelectedAnswerId] = useState<string>()
  const [selectedTopic, setSelectedTopic] = useState<string>()
  // answers of topic view can have two views (list and details)
  const [answersOfTopicView, setAnswersOfTopicView] = useState<'list' | 'details'>('list')
  const answersOfTopicViewDetailsOnBackCallbackRef = useRef<(() => void) | null>(null)
  const [answerForDetails, setAnswerForDetails] = useState<Answer>()

  /**
   * Returns primary action button depending on screen
   * @param screen
   */
  function getPrimaryActionButtonForScreen(): React.ReactNode | undefined {
    switch (currentScreen) {
      case 'main':
      case 'summary':
        return (
          <Button
            type='success'
            size='small'
            icon={<Check />}
            onClick={(): void => {
              if (selectedAnswerId) onConfirmCallback(utterance, answers[selectedAnswerId])
            }}
            disabled={!selectedAnswerId}
          >
            Speichern
          </Button>
        )
      case 'answersOfTopic': {
        if (answersOfTopicView === 'details') {
          return (
            <Button
              type='success'
              size='small'
              icon={<Check />}
              onClick={(): void => {
                setSelectedAnswerId(answerForDetails?.answerId)
                setCurrentScreen('summary')
              }}
              disabled={!answerForDetails}
            >
              Auswählen
            </Button>
          )
        } else {
          return undefined
        }
      }
      case 'topics':
      default:
        return undefined
    }
  }

  /**
   * Returns additional action buttons depending on screen.
   */
  function getAdditionalActionButtonsForScreen(): React.ReactNode[] | undefined {
    switch (currentScreen) {
      case 'main':
        return [
          <Button key='create-answer-button' type='normal' size='small' onClick={onCreateAnswer}>
            Neue Antwort erstellen
          </Button>,
          <Button
            key='topic-overview-button'
            type='normal'
            size='small'
            onClick={(): void => setCurrentScreen('topics')}
          >
            Themen Übersicht öffnen
          </Button>,
        ]
      case 'topics':
      default:
        return undefined
    }
  }

  /**
   * Returns title of dialog based on screen
   */
  function getTitleForScreen(): React.ReactNode | string {
    switch (currentScreen) {
      case 'answersOfTopic': {
        if (answersOfTopicView === 'list') {
          return (
            <div style={{ display: 'flex' }}>
              <Typography variant='h4' style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                {selectedTopic}
              </Typography>
              <Typography variant='h5' style={{ marginTop: 'auto', marginBottom: 'auto', marginLeft: '8px' }}>
                (Frage: {utterance.text})
              </Typography>
            </div>
          )
        } else if (answersOfTopicView === 'details') {
          return (
            <div style={{ display: 'flex' }}>
              <Typography variant='h4' style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                {answerForDetails?.title ?? `Frage: ${utterance.text}`}
              </Typography>
              {answerForDetails?.title && (
                <Typography variant='h5' style={{ marginTop: 'auto', marginBottom: 'auto', marginLeft: '8px' }}>
                  (Frage: {utterance.text})
                </Typography>
              )}
            </div>
          )
        }
        break
      }
      case 'main':
      default:
        return `Frage: ${utterance.text}`
    }
  }

  /**
   * Returns whether back button should be displayed based on current screen
   */
  function shouldShowBackButton(): boolean {
    switch (currentScreen) {
      case 'main':
        return false
      case 'topics':
      case 'answersOfTopic':
      case 'summary':
        return true
      default:
        return false
    }
  }

  /**
   * Handles back button click of dialog.
   */
  function onBackClick(): void {
    switch (currentScreen) {
      case 'topics':
        setCurrentScreen('main')
        break
      case 'answersOfTopic': {
        if (answersOfTopicView === 'list') {
          // normal list view, not details, simply go back to topics
          setCurrentScreen('topics')
        } else if (answersOfTopicView === 'details') {
          // go back to list view of answersOfTopic and reset callback function
          setAnswersOfTopicView('list')
          setAnswerForDetails(undefined)
          if (answersOfTopicViewDetailsOnBackCallbackRef.current !== null)
            answersOfTopicViewDetailsOnBackCallbackRef.current()
          answersOfTopicViewDetailsOnBackCallbackRef.current = null
        }
        break
      }
      case 'summary':
        setCurrentScreen('answersOfTopic')
        setAnswersOfTopicView('list')
        setSelectedAnswerId(undefined)
        break
      default:
        return
    }
  }

  /**
   * Handles topic selection in topics screen.
   * Sets selected topic and sets answers of topics screen.
   * @param topic
   */
  function onSelectTopic(topic: string): void {
    setSelectedTopic(topic)
    setCurrentScreen('answersOfTopic')
  }

  /**
   * Callback for showing details view in topic answers dialog.
   * We have this function here to know if the details view if open, because the answersOfTopic screen
   * has different actions (buttons) depending on what is shown (details view has select button, list view not)
   * @param answerForDetails answer for which details are shown
   * @param onBackClick callback function in AssignUtteranceDialogTopicAnswers component to let the component know if back button was clicked
   */
  function onSelectDetailsViewOfTopicAnswerChange(answerForDetails: Answer, onBackClick: () => void): void {
    // register callback
    answersOfTopicViewDetailsOnBackCallbackRef.current = onBackClick
    setAnswersOfTopicView('details')
    setAnswerForDetails(answerForDetails)
  }

  /**
   * Handles answer selection.
   * If selectedFrom is not the main screen, shows summary screen after selection.
   * @param answerId
   * @param selectedFrom
   */
  function onSelectAnswer(answerId: string, selectedFrom: AssignUtteranceDialogScreen): void {
    if (answerId === selectedAnswerId) setSelectedAnswerId(undefined)
    else setSelectedAnswerId(answerId)
    if (selectedFrom === 'answersOfTopic') setCurrentScreen('summary')
  }

  return (
    <Dialog
      id='edit-dialog'
      size='extra-large'
      open
      closable
      onClose={onClose}
      title={getTitleForScreen()}
      showBackButton={shouldShowBackButton()}
      onBackClick={onBackClick}
      primaryActionButton={getPrimaryActionButtonForScreen()}
      secondaryActionText={'Frage löschen'}
      onSecondaryActionClick={(): void => onRemove(utterance)}
      additionalActionButtons={getAdditionalActionButtonsForScreen()}
    >
      <div className={classes.root}>
        {currentScreen === 'main' ? (
          <AssignUtteranceDialogMainScreen
            utterance={utterance}
            answers={answers}
            selectedAnswerId={selectedAnswerId}
            onSelectAnswer={onSelectAnswer}
            type='main'
            endpointType={endpointType}
          />
        ) : currentScreen === 'topics' ? (
          <AssignUtteranceDialogTopicsScreen answers={answers} onSelectTopic={onSelectTopic} />
        ) : currentScreen === 'answersOfTopic' && typeof selectedTopic !== 'undefined' ? (
          <AssignUtteranceDialogTopicAnswers
            answers={answers}
            selectedTopic={selectedTopic}
            onSelectAnswer={onSelectAnswer}
            onShowDetailsView={onSelectDetailsViewOfTopicAnswerChange}
          />
        ) : currentScreen === 'summary' ? (
          <AssignUtteranceDialogMainScreen
            utterance={utterance}
            answers={answers}
            selectedAnswerId={selectedAnswerId}
            onSelectAnswer={onSelectAnswer}
            endpointType={endpointType}
            type='summary'
          />
        ) : null}
      </div>
    </Dialog>
  )
}
