import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { Typography } from '@mui/material'
import BaseCard from './BaseCard'
import { useTheme } from '@mui/material/styles'

type StyleProps = {
  isSelected?: boolean
}

// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. ArrowFunctionExpression in CSS prop.
const useStyles = makeStyles<StyleProps>()((theme, props) => ({
  card: {
    cursor: 'pointer',
    border: `2px solid ${props.isSelected ? theme.palette.success.main : 'transparent'}`,
  },
}))

type ProcessTemplateCardProps = {
  templateName: string
  templateDescription: string
  templateId: string
  isSelected?: boolean
  onClick: (templateId: string) => void
}

export default function ProcessTemplateCard({
  templateName,
  templateDescription,
  templateId,
  isSelected = false,
  onClick,
}: ProcessTemplateCardProps): React.ReactElement {
  const { classes } = useStyles({ isSelected })
  const theme = useTheme()

  return (
    <>
      <BaseCard
        title={templateName}
        titleVariant='h4'
        onClick={(): void => onClick(templateId)}
        height='130px'
        width='450px'
        shadowWidth={10}
        shadowBlur={10}
        className={classes.card}
      >
        <Typography>{templateDescription}</Typography>
      </BaseCard>
    </>
  )
}
