import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { Fab as MUIFabButton, Icon, CircularProgress } from '@mui/material'

type ButtonType = 'normal' | 'danger' | 'success'

type StyleProps = { type: ButtonType; size: 'normal' | 'small' }

const useStyles = makeStyles<StyleProps>()((theme, props) => ({
  button: {
    background:
      props.type === 'normal'
        ? theme.palette.primary.main
        : props.type === 'success'
        ? theme.palette.success.main
        : theme.palette.error.main,
    height: props.size === 'normal' ? '56px' : '40px',
    verticalAlign: 'center',
    color: '#F9FAFB',
    borderRadius:
      typeof theme.shape.borderRadius === 'number' ? theme.shape.borderRadius * 2 : theme.shape.borderRadius,
    '&:hover': {
      backgroundColor:
        props.type === 'normal'
          ? theme.palette.primary.light
          : props.type === 'success'
          ? theme.palette.success.light
          : theme.palette.error.light,
    },
    '&:disabled': {
      backgroundColor: theme.palette.grey[400],
      color: theme.palette.common.white,
    },
  },
  iconContainer: {
    height: '25px',
    width: '25px',
    color: '#F9FAFB',
    marginRight: theme.spacing(1),
    // fontSize: '25px',
  },
  startIcon: {
    fontSize: '25px',
    display: 'flex',
  },
}))

type FabButtonProps = {
  children?: React.ReactNode
  size?: 'small' | 'normal'
  type?: ButtonType
  icon?: React.ReactNode | string
  iconType?: 'mui' | 'remix'
  disabled?: boolean
  onClick: (event: any) => void
  loading?: boolean
}

function SpinnerAdornment(): React.ReactElement {
  return <CircularProgress size={25} />
}

export default function FabButton({
  size = 'normal',
  type = 'normal',
  icon,
  iconType = 'mui',
  onClick,
  children,
  disabled,
  loading,
}: FabButtonProps): React.ReactElement {
  const { classes } = useStyles({ type, size })

  return (
    <MUIFabButton
      disabled={disabled || loading}
      onClick={onClick}
      classes={{
        root: classes.button,
      }}
      variant='extended'
    >
      <div style={{ display: 'flex' }}>
        {loading ? (
          <SpinnerAdornment />
        ) : typeof icon !== 'undefined' ? (
          iconType === 'mui' && typeof icon !== 'string' ? (
            <div className={classes.iconContainer}>{icon}</div>
          ) : (
            // Remix icon
            <div className={classes.startIcon}>
              <i className={`ri-${icon}-line`} />
            </div>
          )
        ) : null}
        <div>{children}</div>
      </div>
    </MUIFabButton>
  )
}
