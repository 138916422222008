import React, { memo, useEffect, useState } from 'react'
import jp from 'jsonpath'
// Types
import { Chart } from '../../../../../../../@types/Flowchart/types'
import { Card, CardInputFields } from '../../../../../../../@types/SmartCards/types'
import { findValuesInputFields } from 'utils/chartUtils'
import { Grid, Typography } from '@mui/material'
import VariablesAutosuggestSelect, { OnChangeOptions } from '../../../Variables/VariablesAutosuggestSelect'
import AcChoiceValueInput from '../../components/ACChoiceValueInput'

type CardCustomVarsProps = {
  card: Card
  chart: Chart
  setChartCallback: (chart: Chart) => void
}

export default memo(function CardCustomVars({
  card,
  chart,
  setChartCallback,
}: CardCustomVarsProps): React.ReactElement {
  const [inputFields, setInputFields] = useState<CardInputFields>([])

  useEffect(() => {
    const inputFields = findValuesInputFields(card.data, 'type')
    setInputFields(inputFields)
  }, [])

  return (
    <div id='editor'>
      {inputFields.length > 0 && card ? (
        <Typography>
          Bitte ordnen Sie den Eingabefeldern der Smart Card Variablen zu in denen die Ergebnisse gespeichert werden.
        </Typography>
      ) : null}
      {inputFields.map((inputField, idx) => (
        <div key={idx} style={{ padding: '10px' }}>
          <div
            style={{
              display: 'inline-block',
              margin: 0,
              fontSize: '1rem',
              width: '100%',
            }}
          >
            {
              <Grid container direction='column' justifyContent='space-evenly' alignItems='baseline'>
                <Grid item xs={12} style={{ width: '100%' }}>
                  <Grid container direction='row' justifyContent='space-evenly' alignItems='center'>
                    <Grid item xs={2} style={{ height: '100%' }}>
                      <Typography style={{ marginRight: '15px' }}>Feld ID:</Typography>
                    </Grid>
                    <Grid item xs={8} style={{ height: '100%' }}>
                      <div style={{ alignSelf: 'center' }}>
                        <b>
                          <i>{inputField.id}</i>
                        </b>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{ width: '100%' }}>
                  {/* If object has choices, it is a choiceSet */}
                  {inputField.choices ? (
                    inputField.choices.map((choice, index) => {
                      return (
                        <div
                          key={`choice-${choice.id}-${index}`}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          <Grid container direction='column' style={{ width: '100%' }}>
                            <Grid item xs={12} style={{ width: '100%' }}>
                              <Grid container direction='row' justifyContent='space-evenly' alignItems='center'>
                                <Grid
                                  item
                                  xs={2}
                                  style={{ height: '100%', marginRight: '15px', fontWeight: 'lighter' }}
                                >
                                  <Typography>Auswahl Titel:</Typography>
                                </Grid>

                                <Grid item xs={10} style={{ height: '100%' }}>
                                  <div style={{ alignSelf: 'center' }}>
                                    <b>
                                      <i>{choice.acValue ? choice.acValue : 'Kein Titel festgelegt'}</i>
                                    </b>
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={12} style={{ width: '100%' }}>
                              <Grid container direction='row' justifyContent='space-evenly' alignItems='center'>
                                <Grid item xs={2} style={{ height: '100%' }}>
                                  <div style={{ marginRight: '15px' }}>
                                    <Typography>
                                      Variable & Wert der Variablen, falls eine Auswahl gewählt wurde.
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid item xs={10} style={{ height: '100%' }}>
                                  <div style={{ width: '100%', marginBottom: '25px' }}>
                                    <AcChoiceValueInput
                                      chart={chart}
                                      acChoiceName={choice.acValue}
                                      setStateCallback={setChartCallback}
                                      setVariablesCallback={(
                                        chart: Chart,
                                        prevSelectedVarIds: string[],
                                        selectedVarIds: string[],
                                        options: OnChangeOptions,
                                      ): void => setChartCallback(chart)}
                                      choiceSetId={inputField.id}
                                      acFieldType={inputField.type}
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                      )
                    })
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <Grid item xs={2} style={{ height: '100%' }}>
                        <div style={{ marginRight: '15px' }}>
                          <Typography>Variablen</Typography>
                        </div>
                      </Grid>
                      <Grid item xs={10} style={{ height: '100%' }}>
                        <div style={{ width: '100%' }}>
                          <VariablesAutosuggestSelect
                            chart={chart}
                            onChange={(
                              chart: Chart,
                              prevSelectedVarIds: string[],
                              selectedVarIds: string[],
                              options: OnChangeOptions,
                            ): void => setChartCallback(chart)}
                            usageType='set'
                            acFieldId={inputField.id}
                            acFieldType={inputField.type}
                            isResult={false}
                          />
                        </div>
                      </Grid>
                    </div>
                  )}
                </Grid>
              </Grid>
            }
          </div>
          {idx < inputFields.length - 1 ? <hr style={{ marginTop: '15px' }} /> : null}
        </div>
      ))}
      {/* Keine Inputs - für allgemeine actions - TODO: better jp query for Submit Actions with data only */}
      {inputFields.length === 0 && jp.paths(card, `$..actions`).length > 0 && (
        <div>
          <Typography>Variable zum Speichern der Aktions Auswahl setzen.</Typography>

          <VariablesAutosuggestSelect
            chart={chart}
            usageType='set'
            onChange={(chart: Chart, prevSelectedVarIds: string[], selectedVarIds: string[]): void => {
              setChartCallback(chart)
            }}
            isResult={true}
          />
        </div>
      )}
    </div>
  )
})
