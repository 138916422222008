import React, { useRef } from 'react'

//
import { makeStyles } from 'tss-react/mui'

import RMEEditor from 'components/TextEditors/editor-mdx-markdown'

const useStyles = makeStyles()((theme) => ({
  editor: {},
}))

type StepContentProps = {
  content: string
  setContentCallback: (content: string) => void
}

export default function StepContent({ content: _content, setContentCallback }: StepContentProps): React.ReactElement {
  const { classes } = useStyles()

  const initRef = useRef<string>(_content)

  return (
    <div className={classes.editor}>
      <RMEEditor
        input={initRef.current}
        // autoFocus={true} // NOTE: I think this caused problems with the buttons of the editor ~ Jakob
        type='html'
        onChangeCallback={(markdown): void => {
          setContentCallback(markdown)
        }}
        // onBlur={(): void => setContentCallback(content)}
        // placeholder='Ihr Nachrichteninhalt...'
      />
      {/* <div>
        <Typography fontWeight='bold'>Tipps für gute Notifications: TODO</Typography>
      </div> */}
    </div>
  )
}
