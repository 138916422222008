import React, { useEffect, useState, memo } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Pagination, TableRow as TableRowType, CellAlignPattern, TablePadding } from '../../@types/Tables/types'

import {
  LabelDisplayedRowsArgs,
  Table as MUITable,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TableContainer,
  LinearProgress,
  TableFooter,
} from '@mui/material'

type StylesProps = {
  padding: TablePadding
}

// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. ArrowFunctionExpression in CSS prop.
const useStyles = makeStyles<StylesProps>()((theme, props, classes) => ({
  table: {
    // marginTop: theme.spacing(2),
  },
  tableHead: {
    height: '30px',
    textAlign: 'left',
    fontWeight: theme.typography.fontWeightRegular,
  },
  tableRow: {
    // padding: (props: StylesProps): string => (props.padding === 'small' ? theme.spacing(0.5) : '0px'),
  },
  tableCell: {
    padding: props.padding === 'small' ? theme.spacing(0.5) : props.padding === 'medium' ? theme.spacing(2) : '0px',
  },
  pagination: {
    borderBottom: 0,
  },
  linearProgressBarContainer: {
    display: 'flex',
    height: '100%',
    minHeight: '300px',
  },
  linearProgressBar: {
    width: '80%',
    margin: 'auto',
  },
}))

type TableProps = {
  title?: string
  headers?: TableRowType
  rows: TableRowType[]
  emptyMessage?: string
  searchString?: string
  cellAlignPattern?: CellAlignPattern // should match the number of elements per row. Controls how cell for each node in row is aligned
  rowsPerPage?: number
  page?: number
  disablePagination?: boolean
  disableRowsPerPageSelection?: boolean
  padding?: TablePadding
  width?: string[] // should match the number of elements per row. Controls how wide each cell for each node in row is
  scrollTop?: boolean
  isLoading?: boolean
  updatePagination?: ({ page, rowsPerPage }: Pagination) => void
}

export default memo(function Table({
  title,
  headers,
  rows,
  emptyMessage,
  rowsPerPage = 10,
  page = 0,
  searchString,
  cellAlignPattern,
  disablePagination = false,
  disableRowsPerPageSelection = false,
  padding = 'small',
  width,
  scrollTop = true,
  isLoading = false,
  updatePagination,
}: TableProps): React.ReactElement {
  const { classes } = useStyles({ padding })
  const [pagination, setPagination] = useState<Pagination>({ page, rowsPerPage })

  function onPageChange(event: any, page: number): void {
    // scroll to top on page change
    if (scrollTop) window.scrollTo(0, 0)
    setPagination({
      page,
      rowsPerPage: pagination.rowsPerPage,
    })
    if (typeof updatePagination !== 'undefined') {
      updatePagination({ page, rowsPerPage: pagination.rowsPerPage })
    }
  }

  function onRowsPerPageChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const newRowsPerPage = parseInt(event.target.value, 10)
    setPagination({
      page: pagination.page,
      rowsPerPage: newRowsPerPage,
    })
    if (typeof updatePagination !== 'undefined') {
      updatePagination({ page: pagination.page, rowsPerPage: newRowsPerPage })
    }
  }

  useEffect(() => {
    if (searchString) {
      // scroll to top on page change
      if (scrollTop) window.scrollTo(0, 0)
      setPagination({
        page: 0,
        rowsPerPage: pagination.rowsPerPage,
      })
    }
  }, [searchString])

  useEffect(() => {
    setPagination({
      page,
      rowsPerPage: pagination.rowsPerPage,
    })
  }, [page])

  useEffect(() => {
    setPagination({
      page: pagination.page,
      rowsPerPage,
    })
  }, [rowsPerPage])

  // prepare rows
  const preparedRows = !disablePagination
    ? rows.slice(
        pagination.page * pagination.rowsPerPage,
        pagination.page * pagination.rowsPerPage + pagination.rowsPerPage,
      )
    : rows

  return (
    <>
      {!isLoading ? (
        <TableContainer>
          <MUITable className={classes.table} size='small'>
            {title && <TableHead className={classes.tableHead}>{title}</TableHead>}
            {headers && (
              <TableHead className={classes.tableHead}>
                <TableRow>
                  {headers.map((cell, cellIndex) => (
                    <TableCell
                      key={`header-${cellIndex}`}
                      className={classes.tableCell}
                      width={width && width.length === headers.length ? width[cellIndex] : undefined}
                      align={
                        cellAlignPattern && cellAlignPattern.length === headers.length
                          ? cellAlignPattern[cellIndex]
                          : undefined
                      }
                    >
                      {typeof cell === 'string' ? <Typography fontWeight='bolder'>{cell}</Typography> : <tr>{cell}</tr>}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {preparedRows.length > 0 ? (
                preparedRows.map((row, index) => (
                  <TableRow key={`row-${index}`} className={classes.tableRow}>
                    {row.map((cell, cellIndex) => (
                      <TableCell
                        key={`row-${index}-cell-${cellIndex}`}
                        className={classes.tableCell}
                        width={width && width.length === row.length ? width[cellIndex] : undefined}
                        align={
                          cellAlignPattern && cellAlignPattern.length === row.length
                            ? cellAlignPattern[cellIndex]
                            : undefined
                        }
                      >
                        {cell}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                // else show empty message
                <TableRow key={`row-empty`} className={classes.tableRow}>
                  <TableCell key={'row-empty-cell'} className={classes.tableCell} width={'100%'} align={'center'}>
                    {emptyMessage ?? 'Keine Daten'}
                  </TableCell>
                  <TableCell key={'row-empty-cell-date'} className={classes.tableCell}></TableCell>
                </TableRow>
              )}
            </TableBody>
            {!disablePagination && rows.length > 0 && (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={disableRowsPerPageSelection ? [] : [10, 25, 50, 100]}
                    labelRowsPerPage='Zeilen pro Seite'
                    className={classes.pagination}
                    count={rows.length}
                    rowsPerPage={pagination.rowsPerPage}
                    page={pagination.page}
                    backIconButtonProps={{ 'aria-label': 'Vorherige Seite' }}
                    nextIconButtonProps={{ 'aria-label': 'Nächste Seite' }}
                    onPageChange={onPageChange}
                    onRowsPerPageChange={onRowsPerPageChange}
                    labelDisplayedRows={(paginationInfo: LabelDisplayedRowsArgs): string =>
                      `${paginationInfo.from}-${paginationInfo.to} von ${
                        paginationInfo.count !== -1 ? paginationInfo.count : `mehr als ${paginationInfo.to}`
                      }`
                    }
                  />
                </TableRow>
              </TableFooter>
            )}
          </MUITable>
        </TableContainer>
      ) : (
        <div className={classes.linearProgressBarContainer}>
          <LinearProgress className={classes.linearProgressBar} />
        </div>
      )}
    </>
  )
})
