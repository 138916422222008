import React from 'react'

// @mui/material
import { makeStyles } from 'tss-react/mui'

import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
// @mui/icons-material
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

type Props = {
  onNext: () => void
  onPrev: () => void
  numPages: number
  page: number
  startPage: number
}

const useStyles = makeStyles()((theme) => ({
  paginatioWrapper: {
    flexDirection: 'row',
    display: 'flex',
    cursor: 'default',
  },
  paginationText: {
    paddingLeft: '10px',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
}))

export default function Pagination({ onNext, onPrev, numPages, page, startPage }: Props): React.ReactElement {
  const { classes } = useStyles()

  return (
    <Paper id='pagination' elevation={3} className={classes.paginatioWrapper}>
      {/* TODO: I18n */}
      <p className={classes.paginationText}>{`${page} von ${numPages}`}</p>
      <IconButton disabled={page === startPage} onClick={(): void => onPrev()} aria-label='previous page'>
        <ChevronLeftIcon fontSize='small' />
      </IconButton>
      <IconButton disabled={page === numPages} onClick={(): void => onNext()} aria-label='next page'>
        <ChevronRightIcon fontSize='small' />
      </IconButton>
    </Paper>
  )
}
