import { TranslationFile } from '../@types/Translations/types'
import { cloneDeep } from 'lodash'

/**
 * Deletes a smartcard from the translation file.
 * @param translations
 * @param cardId
 */
export function deleteSmartCard(translations: TranslationFile, cardId: string): TranslationFile {
  const newTranslations = cloneDeep(translations)
  for (const lang of Object.keys(translations.ac)) {
    delete newTranslations.ac[lang][cardId]
  }

  delete newTranslations.acTranslations[cardId]

  return newTranslations
}
