import React from 'react'
import { v4 as uuidv4 } from 'uuid'

export type Point = {
  x: number
  y: number
}

export type Box = {
  left: number
  top: number
  width: number
  height: number
}

type Props = {
  disabled?: boolean
  target: HTMLElement
  onDraw?: (selectionBox: Box) => void
  onSelectionChange?: (elements: Array<number>) => void
  onHighlightChange?: (elements: Array<number>) => void
  returnBox?: (element: { id: string; box: Box; selected: number[] }) => void
  elements: Array<HTMLElement>
  // eslint-disable-next-line react/no-unused-prop-types
  offset?: {
    // eslint-disable-next-line react/no-unused-prop-types
    top: number
    // eslint-disable-next-line react/no-unused-prop-types
    left: number
  }
  style?: any
  zoom?: number
  ignoreTargets?: Array<string>
}

type State = {
  mouseDown: boolean
  startPoint: Point | null
  endPoint: Point | null
  selectionBox: Box | null
  offset: {
    top: number
    left: number
  }
  containerCoordinates: {
    top: number
    left: number
    right: number
    bottom: number
    width: number
    height: number
  }
  zoom: number
}

type Coordinates = {
  top: number
  left: number
  right: number
  bottom: number
  width: number
  height: number
}

// instead of getOffset function -> better functionality
function getContainerCoordinates(props: Props): Coordinates {
  const coordinates = { top: 0, left: 0, right: 0, bottom: 0, width: 0, height: 0 }
  if (props.offset) {
    coordinates.top = props.offset.top
    coordinates.left = props.offset.left
  } else if (props.target) {
    const boundingBox = props.target.getBoundingClientRect()
    coordinates.top = boundingBox.top + window.scrollY
    coordinates.left = boundingBox.left + window.scrollX
    coordinates.right = boundingBox.right + window.scrollX
    coordinates.bottom = boundingBox.bottom + window.scrollY
    coordinates.width = boundingBox.width
    coordinates.height = boundingBox.height
    // eslint-disable-next-line no-undef
    // if (process.env.REACT_APP_BRANCH && process.env.REACT_APP_BRANCH === 'development') {
    //   console.info('[Selection] coordinates: ', boundingBox)
    //   console.info('[Selection] target: ', props.target)
    // }
  }

  return coordinates
}

/**
 * TODO: remove touch listener? no support for ipad needed? => just keep it and look into passive event listeners
 */
export default class Selection extends React.PureComponent<Props, State> {
  // eslint-disable-line react/prefer-stateless-function
  state: State
  selectedChildren: number[]
  highlightedChildren: number[]

  constructor(props: Props) {
    super(props)

    this.state = {
      mouseDown: false,
      startPoint: null,
      endPoint: null,
      selectionBox: null,
      offset: {
        top: 0,
        left: 0,
      },
      containerCoordinates: getContainerCoordinates(props), // instead of offset
      zoom: props.zoom || 1,
    }

    this.selectedChildren = []
    this.highlightedChildren = []
  }

  static getDerivedStateFromProps(nextProps: Props): { containerCoordinates: Coordinates } {
    return {
      containerCoordinates: getContainerCoordinates(nextProps), // instead of offset
    }
  }

  componentDidMount(): void {
    this.reset()
    this.bind()
  }

  componentDidUpdate(): void {
    this.reset()
    this.bind()
    if (this.state.mouseDown && this.state.selectionBox) {
      this.updateCollidingChildren(this.state.selectionBox)
    }
  }

  componentWillUnmount(): void {
    this.reset()
    window.document.removeEventListener('mousemove', this.onMouseMove)
    window.document.removeEventListener('mouseup', this.onMouseUp)
  }

  bind = (): void => {
    this.props.target.addEventListener('mousedown', this.onMouseDown)
    this.props.target.addEventListener('touchstart', this.onTouchStart)
  }

  reset = (): void => {
    if (this.props.target) {
      this.props.target.removeEventListener('mousedown', this.onMouseDown)
    }
  }

  init = (e: Event, x: number, y: number): boolean => {
    this.setState({ containerCoordinates: getContainerCoordinates(this.props) })
    if (this.props.ignoreTargets) {
      // const Target = e.target
      // if (Target && !Target.matches) {
      //   // polyfill matches
      //   const defaultMatches = (s: string) => [].indexOf.call(window.document.querySelectorAll(s), this) !== -1
      //   Target.matches =
      //     Target.matchesSelector ||
      //     Target.mozMatchesSelector ||
      //     Target.msMatchesSelector ||
      //     Target.oMatchesSelector ||
      //     Target.webkitMatchesSelector ||
      //     defaultMatches
      // }
      // if (Target.matches && Target.matches(this.props.ignoreTargets.join(','))) {
      //   return false
      // }
    }

    const nextState = {
      mouseDown: true,
      startPoint: {
        x: (x - this.state.containerCoordinates.left) / this.state.zoom,
        y: (y - this.state.containerCoordinates.top) / this.state.zoom,
      },
    }
    if (this.props.onSelectionChange) {
      this.props.onSelectionChange([])
    }
    if (this.props.onHighlightChange) {
      this.props.onHighlightChange([])
    }

    this.setState(nextState)
    return true
  }

  /**
   * On root element mouse down
   * The event should be a MouseEvent | TouchEvent, but flow won't get it...
   * @private
   */
  onMouseDown = (e: MouseEvent | any): void => {
    if (this.props.disabled || e.button === 2 || (e.nativeEvent && e.nativeEvent.which === 2)) {
      return
    }

    if (this.init(e, e.pageX, e.pageY)) {
      window.document.addEventListener('mousemove', this.onMouseMove)
      window.document.addEventListener('mouseup', this.onMouseUp)
    }
  }

  // Warning in console: [Violation] Added non-passive event listener to a scroll-blocking 'touchstart' event. Consider marking event handler as 'passive' to make the page more responsive. See https://www.chromestatus.com/feature/5745543795965952
  onTouchStart = (e: TouchEvent): void => {
    if (this.props.disabled || !e.touches || !e.touches[0] || e.touches.length > 1) {
      return
    }

    if (this.init(e, e.touches[0].pageX, e.touches[0].pageY)) {
      window.document.addEventListener('touchmove', this.onTouchMove)
      window.document.addEventListener('touchend', this.onMouseUp)
    }
  }

  /**
   * On document element mouse up
   * @private
   */
  onMouseUp = (): void => {
    const { selectionBox } = this.state

    window.document.removeEventListener('touchmove', this.onTouchMove)
    window.document.removeEventListener('mousemove', this.onMouseMove)
    window.document.removeEventListener('mouseup', this.onMouseUp)
    window.document.removeEventListener('touchend', this.onMouseUp)

    // Return functions via props
    if (this.props.onDraw && selectionBox) {
      this.props.onDraw(selectionBox)
    }

    if (this.props.returnBox && selectionBox) {
      this.props.returnBox({ id: uuidv4(), box: selectionBox, selected: this.selectedChildren })
    }

    if (this.props.onSelectionChange) {
      this.props.onSelectionChange(this.selectedChildren)
    }

    if (this.props.onHighlightChange) {
      this.highlightedChildren = []
      this.props.onHighlightChange(this.highlightedChildren)
    }
    this.selectedChildren = []

    // reset
    this.setState({
      mouseDown: false,
      startPoint: null,
      endPoint: null,
      selectionBox: null,
    })
  }

  /**
   * On document element mouse move
   * @private
   */
  onMouseMove = (e: MouseEvent): void => {
    e.preventDefault()
    if (this.state.mouseDown) {
      // ensure that selection box is only within dimensions of parent element
      let x = (e.pageX - this.state.containerCoordinates.left) / this.state.zoom
      if (x > this.state.containerCoordinates.width / this.state.zoom) {
        x = (this.state.containerCoordinates.right - this.state.containerCoordinates.left) / this.state.zoom
      }
      if (x < 0) x = 0

      let y = (e.pageY - this.state.containerCoordinates.top) / this.state.zoom
      if (y > this.state.containerCoordinates.height / this.state.zoom)
        y = this.state.containerCoordinates.height / this.state.zoom
      if (y < 0) y = 0

      const endPoint: Point = {
        x,
        y,
      }

      this.setState({
        endPoint,
        selectionBox: this.calculateSelectionBox(
          this.state.startPoint ? this.state.startPoint : { x: 0, y: 0 },
          endPoint,
        ),
      })
    }
  }

  onTouchMove = (e: TouchEvent): void => {
    e.preventDefault()
    if (this.state.mouseDown) {
      // ensure that selection box is only within dimensions of parent element
      let x = (e.touches[0].pageX - this.state.containerCoordinates.left) / this.state.zoom
      if (x > this.state.containerCoordinates.width / this.state.zoom) {
        x = (this.state.containerCoordinates.right - this.state.containerCoordinates.left) / this.state.zoom
      }
      if (x < 0) x = 0

      let y = (e.touches[0].pageY - this.state.containerCoordinates.top) / this.state.zoom
      if (y > this.state.containerCoordinates.height / this.state.zoom)
        y = this.state.containerCoordinates.height / this.state.zoom
      if (y < 0) y = 0

      const endPoint: Point = {
        x,
        y,
      }

      this.setState({
        endPoint,
        selectionBox: this.calculateSelectionBox(
          this.state.startPoint ? this.state.startPoint : { x: 0, y: 0 },
          endPoint,
        ),
      })
    }
  }

  /**
   * Calculate if two segments overlap in 1D
   * @param lineA [min, max]
   * @param lineB [min, max]
   */
  lineIntersects = (lineA: [number, number], lineB: [number, number]): boolean =>
    lineA[1] >= lineB[0] && lineB[1] >= lineA[0]

  /**
   * Detect 2D box intersection - the two boxes will intersect
   * if their projections to both axis overlap
   * @private
   */
  boxIntersects = (boxA: Box, boxB: Box): boolean => {
    // calculate coordinates of all points
    const boxAProjection: { x: [number, number]; y: [number, number] } = {
      x: [boxA.left, boxA.left + boxA.width],
      y: [boxA.top, boxA.top + boxA.height],
    }

    const boxBProjection: { x: [number, number]; y: [number, number] } = {
      x: [boxB.left, boxB.left + boxB.width],
      y: [boxB.top, boxB.top + boxB.height],
    }

    // eslint-disable-next-line no-undef
    // if (process.env.REACT_APP_BRANCH && process.env.REACT_APP_BRANCH === 'development') {
    //   console.info(
    //     'LineIntersects X? ',
    //     this.lineIntersects(boxAProjection.x, boxBProjection.x),
    //     `${boxAProjection.x[1]} >= ${boxBProjection.x[0]} && ${boxAProjection.x[0]} <= ${boxBProjection.x[1]}`,
    //     ' LineInteracts Y? ',
    //     this.lineIntersects(boxAProjection.y, boxBProjection.y),
    //     `${boxAProjection.y[1]} >= ${boxBProjection.y[0]} && ${boxAProjection.y[0]} <= ${boxBProjection.y[1]}`
    //   )
    // }

    return (
      this.lineIntersects(boxAProjection.x, boxBProjection.x) && this.lineIntersects(boxAProjection.y, boxBProjection.y)
    )
  }

  /**
   * Updates the selected items based on the
   * collisions with selectionBox,
   * also updates the highlighted items if they have changed
   * @private
   */
  updateCollidingChildren = (selectionBox: Box): void => {
    this.selectedChildren = []
    if (this.props.elements) {
      this.props.elements.forEach((ref, $index) => {
        if (ref) {
          const refBox = ref.getBoundingClientRect()
          const tmpBox = {
            top: (refBox.top - this.state.containerCoordinates.top + window.scrollY) / this.state.zoom,
            left: (refBox.left - this.state.containerCoordinates.left + window.scrollX) / this.state.zoom,
            width: ref.clientWidth,
            height: ref.clientHeight,
          }

          if (this.boxIntersects(selectionBox, tmpBox)) {
            this.selectedChildren.push($index)
          }
        }
      })
    }
    if (
      this.props.onHighlightChange &&
      JSON.stringify(this.highlightedChildren) !== JSON.stringify(this.selectedChildren)
    ) {
      const { onHighlightChange } = this.props
      this.highlightedChildren = [...this.selectedChildren]
      if (window.requestAnimationFrame) {
        window.requestAnimationFrame(() => {
          onHighlightChange(this.highlightedChildren)
        })
      } else {
        onHighlightChange(this.highlightedChildren)
      }
    }
  }

  /**
   * Calculate selection box dimensions
   * @private
   */
  calculateSelectionBox = (
    startPoint: Point,
    endPoint: Point,
  ): { left: number; top: number; width: number; height: number } | null => {
    if (!this.state.mouseDown || !startPoint || !endPoint) {
      return null
    }

    // eslint-disable-next-line no-undef
    // if (process.env.REACT_APP_BRANCH && process.env.REACT_APP_BRANCH === 'development') {
    //   console.info('startPoint: ', startPoint, ' Endpoint: ', endPoint)
    // }

    // The extra 1 pixel is to ensure that the mouse is on top
    // of the selection box and avoids triggering clicks on the target.
    // FIXME: Stefan 27.07.2020: Does not work properly (only if dragging in one direction)
    const left = Math.min(startPoint.x, endPoint.x) - 1
    const top = Math.min(startPoint.y, endPoint.y) - 1
    const width = Math.abs(startPoint.x - endPoint.x) + 1
    const height = Math.abs(startPoint.y - endPoint.y) + 1

    return {
      left,
      top,
      width,
      height,
    }
  }

  /**
   * Render
   */
  render() {
    let style: any = {
      position: 'absolute',
      background: 'rgba(159, 217, 255, 0.3)',
      border: 'solid 1px rgba(123, 123, 123, 0.61)',
      zIndex: 9,
      cursor: 'crosshair',
      ...this.props.style,
    }

    if (this.state.selectionBox) {
      style = {
        ...style,
        ...this.state.selectionBox,
      }
    }

    if (!this.state.mouseDown || !this.state.endPoint || !this.state.startPoint) {
      return null
    }
    // eslint-disable-next-line react/jsx-curly-spacing
    return <div className='react-ds-border' style={style} />
  }
}
