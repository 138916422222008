import React, { memo, useEffect, useState } from 'react'
import { cloneDeep } from 'lodash'

// @material-ui
import { makeStyles } from 'tss-react/mui'

// Custom Components
import {
  SmartCardContentContainer,
  SmartCardContentBlock,
  SmartCardTitleContainer,
} from 'components/SmartCard/smartCardModules'
import RMEEditor from 'components/TextEditors/editor-mdx-markdown'
// Types
import { Card, IContainer, ITextBlock } from '../../../../../../../@types/SmartCards/types'
import { Chart } from '../../../../../../../@types/Flowchart/types'

const useStyles = makeStyles()((theme) => ({
  editor: {},
}))

type CardTitleProps = {
  card: Card
  chart: Chart
  setCardCallback: (card: Card) => void
}
// NOTE: not using onBlur to trigger setCardCallback - because we want a live preview
export default memo(function CardContent({
  card: origCard,
  setCardCallback,
  chart,
}: CardTitleProps): React.ReactElement {
  const { classes } = useStyles()
  const [card, setCard] = useState<Card>(origCard)
  const [content, setContent] = useState<string>(loadContent(origCard))

  function loadContent(inputCard): string {
    if (inputCard.data.body) {
      const contentIndex = inputCard.data.body?.findIndex((block) => block.id === SmartCardContentContainer.id)
      if (typeof contentIndex !== 'undefined' && contentIndex >= 0) {
        // Has Title Container - Check for Title block and edit
        const contentContainer = inputCard.data.body[contentIndex] as IContainer
        const blockIndex = contentContainer.items?.findIndex((block) => block.id === SmartCardContentBlock.id)
        if (typeof blockIndex !== 'undefined' && blockIndex >= 0) {
          const contentBlock = contentContainer.items?.[blockIndex]
          if (contentBlock) return contentBlock.text
        }
      }
    }
    return ''
  }

  useEffect(() => {
    // correctly fill card body contentContainer with updated content and update card
    if (card.data.body) {
      // Check if content container is set and edit it, else create one with title
      const contentIndex = card.data.body?.findIndex((block) => block.id === SmartCardContentContainer.id)
      if (typeof contentIndex !== 'undefined' && contentIndex >= 0) {
        // Has content Container - Check for Title block and edit
        const contentContainer = card.data.body[contentIndex] as IContainer
        const blockIndex = contentContainer.items?.findIndex((block, index) => block.id === SmartCardContentBlock.id)
        // Title Block exists?
        if (typeof blockIndex !== 'undefined' && blockIndex >= 0) {
          // title block exists -> edit and setCard
          const contentBlock = contentContainer.items?.[blockIndex]
          if (contentBlock) {
            contentBlock.text = content
            contentContainer.items?.splice(blockIndex, 1, contentBlock)
          }
        } else {
          // set new title block, edit and setCard
          const contentBlock = { ...SmartCardContentBlock } as ITextBlock
          contentBlock.text = content
          contentContainer.items?.push(contentBlock)
        }

        const cardTmp = { ...card } // create copy so the useEffect on the card triggers
        // exchange the title container with the new one
        cardTmp.data.body?.splice(contentIndex, 1, contentContainer)
        cardTmp.data.speak = content
        setCard(cardTmp)
      } else {
        // Has no content Container
        // Create content Container and content block
        const contentContainer = cloneDeep(SmartCardContentContainer) as IContainer
        const contentBlock = cloneDeep(SmartCardContentBlock) as ITextBlock
        contentBlock.text = content
        contentContainer.items?.push(contentBlock)
        // Add contentContainer to the card
        const cardTmp = { ...card } // create copy so the useEffect on the card triggers
        // Check where the title index is and set it the index aftert - else set at top and push rest
        const titleIndex = card.data.body?.findIndex((block, index) => block.id === SmartCardTitleContainer.id)
        if (typeof titleIndex !== 'undefined' && titleIndex >= 0) {
          cardTmp.data.body?.splice(titleIndex + 1, 0, contentContainer) // NOTE: set content after title
        } else {
          cardTmp.data.body?.splice(0, 0, contentContainer) // NOTE: set content at 0 and push rest if no title is set
        }
        cardTmp.data.speak = content
        setCard(cardTmp)
      }
    }
  }, [content])

  useEffect(() => {
    // for live preview
    setCardCallback(card)
  }, [card])

  return (
    <div className={classes.editor}>
      <RMEEditor
        // defaultInput={content || ''}
        input={content || ''}
        onChangeCallback={(markdown): void => {
          setContent(markdown)
        }}
        placeholder='Ihr Text...'
      />
    </div>
  )
})
