/* eslint-disable no-constant-condition */
import { Delete } from '@mui/icons-material'
import { IconButton, Typography, useTheme } from '@mui/material'
import { Button } from 'components/Buttons'
import BaseCard from 'components/Cards/BaseCard'
import { Dialog } from 'components/Dialogs'
import CircularLoading from 'components/Loading/CircularLoading'
import ContentPage, { ContentPageHeader } from 'components/Page/ContentPage'
import { Textfield } from 'components/TextInput/Textfield'
import DragAndDrop from 'components/Upload/DragAndDrop'
import { LoadingState, useRAGContext } from 'hooks/contexts/rag-context'
import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation, useNavigate, useResolvedPath } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'
import { APP_TITLE } from 'utils/constants'
import { isValidUrl } from 'utils/stringUtils'
import PDFIcon from 'assets/img/fileIcons/pdf.svg'
import WordIcon from 'assets/img/fileIcons/word.svg'
import TxtIcon from 'assets/img/fileIcons/txt.svg'
import CustomizedTooltip from 'components/Tooltips/CustomContentTooltip'
import { RAGPDFDoc, RAGTxtDoc, RAGWordDoc } from '../../../../@types/Knowledge/RAG/types'

function getIcon(fileName: string): string {
  const ext = fileName.split('.').pop()
  switch (ext) {
    case 'pdf':
      return PDFIcon
    case 'doc':
    case 'docx':
      return WordIcon
    case 'txt':
    default:
      return TxtIcon
  }
}

const useStyles = makeStyles()((theme, props, classes) => {
  return {
    titleContainer: {},
    fileUploadContainer: { marginTop: theme.spacing(2), maxHeight: '300px' },
    fileContainer: { display: 'flex', flexDirection: 'column', marginBottom: theme.spacing(2) },
    file: { display: 'flex', alignItems: 'center' },
    fileIcon: { height: '32px', width: '32px', marginRight: theme.spacing(2) },
    fileName: { marginRight: theme.spacing(2) },
    fileDelete: {},
    fileDeleteButton: { color: theme.palette.error.main },
    contentContainer: { marginTop: theme.spacing(2) },
    urlContainer: { marginTop: theme.spacing(2) },
    actionsContainer: { display: 'flex', width: '100%', marginTop: theme.spacing(2), justifyContent: 'center' },
  }
})

type RAGUpdateFileProps = {
  documentId: string
}

export default function RAGUpdateFile({ documentId }: RAGUpdateFileProps): React.ReactElement {
  const { classes } = useStyles()
  const theme = useTheme()
  const { pathname: path } = useLocation()
  const navigate = useNavigate()
  const url = useResolvedPath('').pathname
  const { updateFileDocument, parseDocument, getDocumentParsingJobStatus, loadContentOfDocument, loading, documents } =
    useRAGContext()
  const prevLoadingRef = useRef<LoadingState>(loading)
  const origDocRef = useRef<RAGPDFDoc | RAGTxtDoc | RAGWordDoc>()

  const [showDialog, setShowDialog] = useState<'discard' | 'updating'>()
  const [title, setTitle] = useState<string>('')
  const [fileName, setFileName] = useState<string>('')
  const [file, setFile] = useState<File>()
  const [filePath, setFilePath] = useState<string>('')
  const [hasFileBeenChanged, setHasFileBeenChanged] = useState<boolean>(false)

  const [content, setContent] = useState<string>('')
  const [sourceUrl, setSourceUrl] = useState<string>('')
  const [urlError, setUrlError] = useState<string>('')

  function hasChanges(): boolean {
    return !(
      origDocRef.current?.title === title &&
      origDocRef.current?.content === content &&
      origDocRef.current?.sourceUrl === sourceUrl
    )
  }

  function onTitleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setTitle(event.target.value)
  }

  async function onUploadFile(files: FileList): Promise<void> {
    setFile(files[0])
    const fileName = files[0].name
    const parseJob = await parseDocument(files[0])
    if (parseJob) {
      const jobId = parseJob.jobId
      // Check parsing job status every 5 seconds until completed
      const checkJobStatus = async () => {
        const result = await getDocumentParsingJobStatus(jobId)
        if (result && result.status === 'completed') {
          if (result.parsedContent) {
            setContent(result.parsedContent)
          }
          if (!title) {
            setTitle(fileName)
          }
        } else if (result && result.status !== 'error') {
          // If not completed and not error, check again in 5 seconds
          setTimeout(checkJobStatus, 5000)
        }
      }

      checkJobStatus()
    }
  }

  function onDeleteFile(): void {
    setFile(undefined)
    setFileName('')
    setHasFileBeenChanged(true)
    setContent('')
  }

  function onContentChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setContent(event.target.value)
  }

  function onUrlChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setSourceUrl(event.target.value)
    const isValid = isValidUrl(event.target.value)
    if (!isValid && !urlError && event.target.value.length > 0) {
      setUrlError('Die URL ist ungültig.')
    } else if ((isValid && urlError) || event.target.value.length === 0) {
      setUrlError('')
    }
  }

  async function onUpdate(): Promise<void> {
    if (!title || !content) return
    const type = documents[documentId].type
    if (type !== 'ingest-job') {
      // can only update if document is not currently ingesting
      await updateFileDocument(type, documentId, title, content, filePath, file, sourceUrl)
    }
  }

  async function loadContent(): Promise<void> {
    const result = await loadContentOfDocument(documentId)
    if (result?.content) {
      // we also need to add this to the origDocRef
      if (origDocRef.current) {
        origDocRef.current.content = result.content
        setTitle(origDocRef.current.title)
        setSourceUrl(origDocRef.current.sourceUrl ?? '')
        setFilePath(origDocRef.current.filePath ?? '')
        setFileName(`${origDocRef.current.title}.${origDocRef.current.filePath?.split('.').pop()}`)
      }
      setContent(result.content)
    }
  }

  useEffect(() => {
    // set orig doc to compare against when checking for changes.
    origDocRef.current = documents[documentId] as RAGTxtDoc
    loadContent()
  }, [])

  useEffect(
    function navigateBackAfterSuccess() {
      if (prevLoadingRef.current === 'updating' && loading === 'updatingSuccess') {
        // successfully created - navigate back to overview
        navigate(url.substring(0, url.lastIndexOf('/')))
      }
      prevLoadingRef.current = loading
    },
    [loading],
  )

  return (
    <>
      <Helmet>
        <title>{APP_TITLE} - Wissensdatenbank</title>
      </Helmet>
      <ContentPage>
        <ContentPageHeader
          title={'Datei aktualisieren'}
          actions={[
            <Button
              key='add-button'
              onClick={onUpdate}
              disabled={
                !title ||
                !content ||
                !!urlError ||
                loading === 'updating' ||
                loading === 'parsing' ||
                (hasFileBeenChanged ? !file : false)
              }
              type='success'
              loading={loading === 'updating'}
            >
              Speichern
            </Button>,
          ]}
          previousUrl={url.substring(0, url.lastIndexOf('/'))}
          previousUrlCallback={(): boolean => {
            const changes = hasChanges()
            if (changes) setShowDialog('discard')
            return !changes
          }}
        />
        <BaseCard width={'100%'} height={'100%'} minHeight={'80vh'} contentScrollable>
          <div className={classes.titleContainer}>
            <Typography style={{ marginBottom: theme.spacing(1) }}>
              Geben Sie dem Inhalt einen aussagekräftigen Titel.
            </Typography>
            <Textfield
              label='Titel'
              placeholder='Titel des neuen Inhalts'
              value={title}
              onChange={onTitleChange}
              fullWidth
              multiline
              rowsMax={3}
            />
          </div>

          <div className={classes.fileUploadContainer}>
            {fileName ? (
              <div className={classes.fileContainer}>
                <h5>Hochgeladene Datei</h5>
                <div className={classes.file}>
                  <div className={classes.fileIcon}>
                    <img src={getIcon(fileName)} style={{ height: '100%', width: '100%' }} />
                  </div>
                  <div className={classes.fileName}>{fileName}</div>
                  <div className={classes.fileDelete}>
                    <CustomizedTooltip
                      elements={
                        <IconButton
                          onClick={(e: React.MouseEvent): void => {
                            onDeleteFile()
                            e.preventDefault()
                            e.stopPropagation()
                          }}
                          disabled={loading === 'creating' || loading === 'parsing'}
                          className={classes.fileDeleteButton}
                        >
                          <i className='ri-delete-bin-4-line'></i>
                        </IconButton>
                      }
                      content={<Typography>Datei entfernen</Typography>}
                    />
                  </div>
                </div>
              </div>
            ) : !file ? (
              <DragAndDrop
                handleDrop={onUploadFile}
                optionalText='Sie können die Datei auch hierhin ziehen.'
                optionalTextHint='Unterstützte Dateiformate: .pdf, .doc, .docx, .txt'
                allowedFileTypes='.pdf,.doc,.docx,.txt'
              />
            ) : null}
          </div>

          <div className={classes.contentContainer}>
            {loading === 'parsing' ? (
              <div style={{ height: '400px' }}>
                <CircularLoading text='Wird verarbeitet...' />
              </div>
            ) : (
              <>
                <Typography style={{ marginBottom: theme.spacing(1) }}>
                  Dieser Text wurde aus der Datei extrahiert und wird zur Wissensdatenbank hinzugefügt. Sie haben die
                  Möglichkeit, den Text zu bearbeiten bevor Sie ihn hinzufügen.
                </Typography>
                <Textfield
                  label='Inhalt'
                  placeholder='Text, der zur Wissensdatenbank hinzugefügt werden soll...'
                  value={content}
                  onChange={onContentChange}
                  multiline
                  // rows={15}
                  rowsMin={15}
                  rowsMax={25}
                  fullWidth
                />
              </>
            )}
          </div>
          <div className={classes.urlContainer}>
            <Typography style={{ marginBottom: theme.spacing(1) }}>
              Falls die Datei online verfügbar ist, können Sie hier die URL einfügen. Die URL wird in der Konversation
              als Quelle angezeigt.
            </Typography>
            <Textfield
              label='URL (optional)'
              placeholder='Link zur Website des Textes'
              value={sourceUrl}
              onChange={onUrlChange}
              fullWidth
              error={!!urlError}
              helperText={urlError}
            />
          </div>
          <div className={classes.actionsContainer}></div>
        </BaseCard>
        {loading === 'updating' && (
          <Dialog id='updating-document-dialog' open={loading === 'updating'} closable={false} size='small'>
            <CircularLoading text='Wird aktualisiert...' />
          </Dialog>
        )}
        {showDialog === 'discard' && (
          <Dialog
            id='discard-changes-dialog'
            size='small'
            open={showDialog === 'discard'}
            closable
            onClose={() => setShowDialog(undefined)}
            title='Ungespeicherte Änderungen'
            primaryActionButton={
              <Button
                size='small'
                type='danger'
                icon={<Delete />}
                onClick={(): void => {
                  navigate(-1)
                }}
              >
                Verwerfen
              </Button>
            }
          >
            <Typography>
              Es existieren ungespeicherte Änderungen. Sind Sie sicher, dass Sie zurückgehen möchten? Die Änderungen
              werden dabei verworfen.
            </Typography>
          </Dialog>
        )}
      </ContentPage>
    </>
  )
}
