export function padZero(num: number): string {
  return num < 10 ? `0${num}` : `${num}`
}

/**
 * Formats a given date to am/pm time string
 * e.g. 12:03am
 * @param date
 * @returns
 */
function formatTimeAmPM(date: Date): string {
  let hours = date.getHours()
  const minutes = date.getMinutes()
  const ampm = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  const strTime = hours + ':' + padZero(minutes) + ' ' + ampm
  return strTime
}

/**
 * Helper function that returns abbrevation of day of week.
 * TODO Stefan 14.04.21: Make this multilingual
 * @param {number} value day of the week starting from 0
 */
export function getDateLabel(value: number): string {
  switch (value) {
    case 0:
      return 'So.'
    case 1:
      return 'Mo.'
    case 2:
      return 'Di.'
    case 3:
      return 'Mi.'
    case 4:
      return 'Do.'
    case 5:
      return 'Fr.'
    case 6:
      return 'Sa.'
    default:
      return '-'
  }
}

/**
 * Returns full weekday for abbreviation.
 * TODO: Make this multilanguage!
 * @param {string} weekday
 */
export function getFullWeekday(weekday: string): string {
  switch (weekday) {
    case 'Mo.':
      return 'Montag'
    case 'Di.':
      return 'Dienstag'
    case 'Mi.':
      return 'Mittwoch'
    case 'Do.':
      return 'Donnerstag'
    case 'Fr.':
      return 'Freitag'
    case 'Sa.':
      return 'Samstag'
    case 'So.':
      return 'Sonntag'
    default:
      return ''
  }
}

/**
 * Returns time string (HH:MM) in the local time from a date obejct.
 * @param date
 */
export function getTimeString(date: Date): string {
  if (!date) return ''
  const minutes = padZero(date.getMinutes())
  const hours = padZero(date.getHours())
  return `${hours}:${minutes}`
}

/**
 * Returns a complete string with date and time.
 * Format: MM.dd.yyyy HH:mm:ss
 * @param date
 */
export function getCompleteDateTimeString(date: Date): string {
  date = new Date(date)
  const day = padZero(date.getDate())
  const month = padZero(date.getMonth() + 1) // months start at 0
  const year = date.getFullYear()
  // const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
  // const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
  // const seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()

  return `${month}.${day}.${year} ${formatTimeAmPM(date)}`
  // return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`
}

/**
 * Returns a timestring in the format dd.mm.yyyy HH:mm:ss
 * @param date
 */
export function getEuropeanDateTimeString(date: Date): string {
  // check if date is of type Date
  if (!(date instanceof Date)) {
    date = new Date(date)
  }

  return `${padZero(date.getDate())}.${padZero(date.getMonth() + 1)}.${date.getFullYear()} ${padZero(
    date.getHours(),
  )}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())} Uhr`
}

/**
 * Subtracts the given number of days from a date object and returns the new date.
 * @param date
 * @param numberOfDaysToSubtract
 */
export function subtractDaysFromDate(date: Date, numberOfDaysToSubtract: number): Date {
  const ts = date.getTime()
  const targetTs = ts - numberOfDaysToSubtract * 24 * 60 * 60 * 1000
  return new Date(targetTs)
}

/**
 * Adds the given number of days to a date object and returns the new date.
 * @param date
 * @param numberOfDaysToAdd
 */
export function addDaysToDate(date: Date, numberOfDaysToAdd: number): Date {
  const ts = date.getTime()
  const targetTs = ts + numberOfDaysToAdd * 24 * 60 * 60 * 1000
  return new Date(targetTs)
}
