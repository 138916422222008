import React, { useState } from 'react'

// @mui/material
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Typography } from '@mui/material'
import DatePicker from '../../components/DatePicker/DatePicker'
import { TimespanOptionValue } from '../../@types/Analytics/types'

type SelectDayDialogProps = {
  selectedDate?: Date
  timespanOptionValue: TimespanOptionValue
  onClose: () => void
  onSelectDate: (date: Date, timespanOptionValue: TimespanOptionValue) => void
}

function SelectDayDialog({
  selectedDate,
  onClose,
  onSelectDate,
  timespanOptionValue,
}: SelectDayDialogProps): React.ReactElement {
  const [date, setDate] = useState<Date | null>(selectedDate || null)

  function onDateChange(newDate: Date | null): void {
    setDate(newDate)
  }

  function onSave(): void {
    if (date !== null) onSelectDate(date, timespanOptionValue)
  }

  return (
    <Dialog open={true}>
      <DialogTitle>
        <Typography>Tag auswählen</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography>Bitte wählen Sie den Tag für den die Analytics angezeigt werden sollen.</Typography>
        </DialogContentText>
        <DatePicker locale='de' label='Tag' value={date} onSelect={onDateChange} disableFuture disableHighlightToday />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Schließen</Button>
        <Button onClick={onSave} disabled={!(date instanceof Date)}>
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SelectDayDialog
