import React, { useState, useEffect } from 'react'
import { makeStyles } from 'tss-react/mui'

// @mui/material components
import TextField from '@mui/material/TextField'

import { Chart } from '../../../../../@types/Flowchart/types'

const useStyles = makeStyles()({
  selectedDialogContainer: {},
  title: {},
})

type SQFMTextsProps = {
  chart: Chart
  setStateCallback: (chart: Chart) => void
}

export default function SQFMTexts({ chart, setStateCallback }: SQFMTextsProps): React.ReactElement {
  const { classes } = useStyles()
  const [text, setText] = useState<string>()

  function onTextChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setText(event.target.value)
  }

  /**
   * Callback for textfield blur.
   * Sets the text into the chart.
   */
  function onTextBlur(): void {
    if (typeof chart.selected?.id === 'undefined') return
    chart.nodes[chart.selected.id].properties.text = text
    setStateCallback(chart)
  }

  useEffect(function () {
    if (typeof chart.selected?.id === 'undefined') return
    setText(chart.nodes[chart.selected.id].properties.text)
  }, [])

  return (
    <div className={classes.selectedDialogContainer}>
      <h5 className={classes.title}>
        {typeof chart.selected?.id !== 'undefined' ? chart.nodes[chart.selected.id].properties.typeText : ''}
      </h5>

      <div>
        <TextField
          id='question-textfield'
          label='Frage'
          multiline
          fullWidth
          maxRows={5}
          value={text}
          onChange={onTextChange}
          onBlur={onTextBlur}
          margin='normal'
        />
      </div>
    </div>
  )
}
