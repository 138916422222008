import React, { useState, useEffect, useRef, ReactElement } from 'react'
import * as monaco from 'monaco-editor'
import MarkdownIt from 'markdown-it'
import * as ACDesigner from 'adaptivecards-designer'
import * as Adaptive from 'adaptivecards'
import { cloneDeep } from 'lodash'
import { makeStyles } from 'tss-react/mui'
// import 'adaptivecards-designer/dist/adaptivecards-defaulthost.css'
import 'adaptivecards-designer/dist/adaptivecards-designer.css'
// import '../../assets/css/smartCardStylesOld.css'
import '../../assets/css/smartCardStylesv4.css'

import NotionDocumentation from '../../components/NotionDocumentation/NotionDocumentation'

//@material-ui/components
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

// types
import { Card, IAdaptiveCard } from '../../@types/SmartCards/types'
import { Variable } from '../../@types/Flowchart/types'

// Snippets
import YesNoDE from './snippets/YesNoDE.json'
import YesNoEN from './snippets/YesNoEN.json'
import NextDE from './snippets/NextDE.json'
import NextEN from './snippets/NextEN.json'
import ThreeInputs from './snippets/ThreeInputs.json'
import BackAndNextButtonsDE from './snippets/BackAndNextButtonsDE.json'
import BackAndNextButtonsEN from './snippets/BackAndNextButtonsEN.json'
import BackButtonDE from './snippets/BackButtonDE.json'
import BackButtonEN from './snippets/BackButtonEN.json'
import ConvaiseLicensePlateDE from './snippets/ConvaiseLicensePlateDE.json'
import InfoBlock from './snippets/InfoBlock.json'
import { SmartCardBase } from 'components/SmartCard/smartCardModules'
import { useBotContext } from 'hooks/contexts/bot-context'

// Custom Elements
// import CustomSubmitAction from './customElements/customSubmitAction'

const useStyles = makeStyles()((theme) => ({
  designerRootContainer: { borderTop: '1px solid #D2D2D2' },
  dialogContent: { textAlign: 'center' },
  progressBarCircle: { height: '50px', width: '50px', margin: '5px' },
  container: {
    flex: 1,
    flexGrow: 1,
    display: 'flex',
    maxWidth: '100vw',
    flexDirection: 'row',
    position: 'relative',
    height: '100%',
  },
  titleBroad: {
    paddingTop: '6px',
    position: 'absolute',
    margin: '0px',
    width: '100%',
    textAlign: 'center',
    pointerEvents: 'none',
  },
  titleNarrow: {
    paddingTop: '6px',
    position: 'absolute',
    margin: '0px',
    width: '100%',
    textAlign: 'right',
    pointerEvents: 'none',
    paddingRight: '10px',
  },
  dialogCloseButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  helpDialog: {
    minHeight: '800px',
    maxHeight: '800px',
  },
}))

type SimpleCardDesingerProps = {
  card: Card
  backCallback: () => void
  saveCallback: (card: IAdaptiveCard) => void
  variables: { [varId: string]: Variable }
  primaryLanguage: string
}

/**
 * This simple card designer just takes a card, lets the user edit it and returns the edited card - logic based on variables etc. needs to be done in the parents. This has the advantage that the logic is not spread to far
 * @param param0
 * @returns
 */
export default function SimpleCardDesinger({
  card,
  backCallback,
  saveCallback,
  variables,
  primaryLanguage,
}: SimpleCardDesingerProps): ReactElement {
  const { classes } = useStyles()
  const { bot, customWebchatStyles } = useBotContext()
  const [designer, setDesigner] = useState<ACDesigner.CardDesigner | null>(null)
  const [cardOnLoad, setCardOnLoad] = useState<IAdaptiveCard | null>(null)
  const cardOnLoadRef = useRef<IAdaptiveCard | null>(null)
  const [helpOpen, setHelpOpen] = useState<boolean>(false)
  const [sampleData, setSampleData] = useState<object>({
    variables: {},
    convaiseFlowLanguage: primaryLanguage,
  })

  function prepareSampleData(): void {
    const data = sampleData
    for (const [key, value] of Object.entries(variables)) {
      data['variables'][value.displayName] = `Beispiel Wert von ${value.displayName}`
    }
    setSampleData(data)
  }
  useEffect(
    function () {
      cardOnLoadRef.current = cardOnLoad
    },
    [cardOnLoad],
  )

  useEffect(function () {
    prepareSampleData()
    ACDesigner.CardDesigner.onProcessMarkdown = (text, result): void => {
      result.outputHtml = new MarkdownIt().render(text)
      result.didProcess = true
    }

    // Turn general support for data binding (templating) on or off. When set to false, this flag overrides showDataStructureToolbox and showSampleDataEditorToolbox.
    ACDesigner.GlobalSettings.enableDataBindingSupport = true
    ACDesigner.GlobalSettings.showDataStructureToolbox = false
    // Controls whether a warning message should be displayed when the selected target version is greater than the version supported by the selected host application. This warning is meant to inform the user that not all features they're designing their card with will work in the target host.
    ACDesigner.GlobalSettings.showTargetVersionMismatchWarning = false
    // Show or hide the "Sample Data" toolbox.
    ACDesigner.GlobalSettings.showSampleDataEditorToolbox = true

    /* Modify the Element toolbox (BEFORE calling attachTo) */
    Adaptive.GlobalRegistry.elements.unregister('RichTextBlock')
    ACDesigner.CardDesignerSurface.cardElementPeerRegistry.unregisterPeer(Adaptive.RichTextBlock)

    /* Configure toolbox titles */
    ACDesigner.Strings.toolboxes.cardEditor.title = 'Karten Code Editor'
    ACDesigner.Strings.toolboxes.cardStructure.title = 'Karten Struktur'
    ACDesigner.Strings.toolboxes.dataStructure.title = 'Datenstruktur'
    ACDesigner.Strings.toolboxes.propertySheet.title = 'Element Eigenschaften'
    ACDesigner.Strings.toolboxes.sampleDataEditor.title = 'Beispiel Variablen'
    ACDesigner.Strings.toolboxes.toolPalette.title = 'Design Elemente'

    const designer = new ACDesigner.CardDesigner([])

    /* Toggle visibility of toolbar elemts*/
    // designer.toolbar.getElementById(ACDesigner.CardDesigner.ToolbarCommands.OpenPayload).isVisible = false
    // designer.toolbar.getElementById(ACDesigner.CardDesigner.ToolbarCommands.FullScreen).isVisible = false
    designer.toolbar.getElementById(ACDesigner.CardDesigner.ToolbarCommands.Redo).isVisible = true
    designer.toolbar.getElementById(ACDesigner.CardDesigner.ToolbarCommands.Redo).label = 'Zurück'
    designer.toolbar.getElementById(ACDesigner.CardDesigner.ToolbarCommands.Undo).isVisible = true
    designer.toolbar.getElementById(ACDesigner.CardDesigner.ToolbarCommands.Undo).label = 'Wiederholen'
    // designer.toolbar.getElementById(ACDesigner.CardDesigner.ToolbarCommands.HostAppPicker).isVisible = false
    // designer.toolbar.getElementById(ACDesigner.CardDesigner.ToolbarCommands.TogglePreview).isVisible = false
    designer.toolbar.getElementById(ACDesigner.CardDesigner.ToolbarCommands.CopyJSON).isVisible = false
    // designer.toolbar.getElementById(ACDesigner.CardDesigner.ToolbarCommands.CopyJSON).separator = true
    // designer.toolbar.getElementById(ACDesigner.CardDesigner.ToolbarCommands.CopyJSON).label = 'Karten JSON kopieren'
    designer.toolbar.getElementById(ACDesigner.CardDesigner.ToolbarCommands.Help).isVisible = false
    designer.toolbar.getElementById(ACDesigner.CardDesigner.ToolbarCommands.NewCard).isVisible = false

    /* Modify the toolbar */
    /* Save Button - buttons use already in the designer css existing icons*/
    const saveButton = new ACDesigner.ToolbarButton(
      'save',
      'Speichern',
      'acd-icon-actionSubmit',
      // <Save />,
      () => saveCallback(designer.getCard() as IAdaptiveCard),
    )
    saveButton.separator = true

    /* Back Button*/
    const backButton = new ACDesigner.ToolbarButton(
      'back',
      'Verwerfen',
      'acd-icon-remove',
      // <Save />,
      () => backCallback(),
    )
    backButton.separator = true

    /* Help Button */
    const helpButton = new ACDesigner.ToolbarButton(
      'help',
      'Hilfe',
      'acd-icon-help',
      // <Save />,
      () => setHelpOpen(true),
    )
    helpButton.separator = true

    // insert new buttons
    designer.toolbar.insertElementBefore(backButton, ACDesigner.CardDesigner.ToolbarCommands.Undo)
    designer.toolbar.insertElementBefore(saveButton, ACDesigner.CardDesigner.ToolbarCommands.Undo)
    designer.toolbar.insertElementAfter(helpButton, ACDesigner.CardDesigner.ToolbarCommands.Redo)

    const designerDiv = document.getElementById('designerRootHost')
    if (designerDiv) {
      // ____ ATTACH TO _____
      designer.attachTo(designerDiv)

      /* Collapse certain panes by default (AFTER calling attachTo)	*/
      designer.treeViewToolbox.collapse()
      designer.jsonEditorToolbox.collapse()

      // Custom palette items
      const yesNoSnippet = new ACDesigner.SnippetPaletteItem('Vorlagen', 'Ja Nein Wahl')
      yesNoSnippet.snippet = bot?.primaryLanguage === 'en' ? YesNoEN : YesNoDE

      const nextSnippet = new ACDesigner.SnippetPaletteItem('Vorlagen', 'Weiter Button')
      nextSnippet.snippet = bot?.primaryLanguage === 'en' ? NextEN : NextDE

      const threeInputSnippet = new ACDesigner.SnippetPaletteItem('Vorlagen', '3 Eingaben')
      threeInputSnippet.snippet = ThreeInputs

      const backAndNextButtonSnippet = new ACDesigner.SnippetPaletteItem('Vorlagen', 'Zurück und Weiter')
      backAndNextButtonSnippet.snippet = bot?.primaryLanguage === 'en' ? BackAndNextButtonsEN : BackAndNextButtonsDE

      const backButtonSnippet = new ACDesigner.SnippetPaletteItem('Vorlagen', 'Zurück Button')
      backButtonSnippet.snippet = bot?.primaryLanguage === 'en' ? BackButtonEN : BackButtonDE

      const infoBlock = new ACDesigner.SnippetPaletteItem('Vorlagen', 'Info Block')
      infoBlock.snippet = infoBlock

      const licensePlateDE = new ACDesigner.SnippetPaletteItem('Vorlagen', 'Auto Kennzeichen DE')
      licensePlateDE.snippet = ConvaiseLicensePlateDE

      designer.customPaletteItems = [
        yesNoSnippet,
        nextSnippet,
        backButtonSnippet,
        backAndNextButtonSnippet,
        threeInputSnippet,
        infoBlock,
        licensePlateDE,
      ]

      designer.monacoModuleLoaded(monaco)

      // check if a new card was created
      if (card.data) {
        // eslint-disable-next-line no-undef
        if (process.env.NODE_ENV === 'development') {
          console.info('[ACDesigner] Card has data')
        }
        designer.setCard(cloneDeep(card.data))
        setCardOnLoad(cloneDeep(card.data))
      } else {
        // eslint-disable-next-line no-undef
        if (process.env.NODE_ENV === 'development') {
          console.info('[ACDesigner] Card has no data and fills data with the template')
        }
        const card = SmartCardBase
        designer.setCard(card)
        setCardOnLoad(cloneDeep(card))
      }
      designer.sampleData = sampleData
      setDesigner(designer)
    }
  }, [])

  return (
    <div
      className={`${classes.container} ${bot?.webchatVersion === 'v4' ? 'convaise-webchat-v4' : 'legacy-webchat'}`}
      style={customWebchatStyles}
    >
      {window.innerWidth >= 1200 ? (
        <h4 className={classes.titleBroad}>{card.id}</h4>
      ) : (
        <h4 className={classes.titleNarrow}>{card.id}</h4>
      )}

      <div id='designerRootHost' className={classes.designerRootContainer} />
      {/* Help Dialog */}
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        onClose={(): void => setHelpOpen(false)}
        aria-labelledby='help-dialog-title'
        open={helpOpen}
        className={classes.helpDialog}
      >
        <DialogTitle id='help-dialog-title'>
          Hilfe{' '}
          <IconButton aria-label='close' className={classes.dialogCloseButton} onClick={(): void => setHelpOpen(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <NotionDocumentation showPaper={false} choosenPage='Card Designer' fullWidth={true} />
        </DialogContent>
      </Dialog>
    </div>
  )
}
