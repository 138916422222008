/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { isEqual } from 'lodash'
import { FC, memo, useEffect, useRef, useState } from 'react'

import { useFlowDesignerNodeStyles } from './useFlowDesignerNodeStyles'

// @mui/material components

import { ErrorOutline } from '@mui/icons-material'

import NodeContent from './NodeContent'

import { replaceVarIdWithDisplayName } from 'utils/chartUtils'

import {
  FLOWDESIGNER_NODE_MIN_HEIGHT,
  FLOWDESIGNER_NODE_PORT_DIAMETER,
  FLOWDESIGNER_SPACE_PER_PORT,
} from 'utils/constants'

import { Handle, NodeProps, Position, useStore } from 'reactflow'
import { Port } from '../../../../@types/Flowchart/types'
import { FlowdesignerNodeData } from '../../../../@types/Flowdesigner/types'

/**
 * Calculates port position offset based on number of ports.
 * Returns array with offset values from top.
 * @param numberPorts
 */
function determinePortPositioning(numberPorts: number): string[] {
  const step = 100 / (numberPorts + 1)

  const percentageValues: string[] = []
  for (let i = 0; i < numberPorts; i += 1) {
    percentageValues.push(`${i * step + step}%`)
  }

  return percentageValues
}

const FlowDesignerNode: FC<NodeProps> = ({
  id,
  type,
  data,
  selected,
  isConnectable,
  xPos,
  yPos,
  targetPosition,
  sourcePosition, // isDragging,
}: NodeProps<FlowdesignerNodeData>) => {
  const { chart, translationFile, node } = data
  const nodeProperties = node.properties
  const nodeDivRef = useRef<HTMLDivElement>(null)
  const [portsLeft, setPortsLeft] = useState<Port[]>(Object.values(node.ports).filter((port) => port.type === 'left'))
  const [portsRight, setPortsRight] = useState<Port[]>(
    Object.values(node.ports).filter((port) => port.type === 'right'),
  )

  const portOffsetLeft = determinePortPositioning(portsLeft.length)
  const portOffsetRight = determinePortPositioning(portsRight.length)

  // we keep isSelected in state (even if it is not used) to trigger node re-render if that state changes
  const [isSelected, setIsSelected] = useState<boolean>(selected)
  // simple object which we use to trigger a re-render if the content size changes
  const [contentContainerSize, setContentContainerSize] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  })
  const [displayText, setDisplayText] = useState<string>(nodeProperties.text || '')
  const [isInvalid, setIsInvalid] = useState<boolean>(false)

  // action for updating the nodedimension inside react-flow to ensure that ports and edges still fit
  // const updateNodeDimensions = useStoreActions((actions) => actions.updateNodeDimensions)
  const updateNodeDimensions = useStore((actions) => actions.updateNodeDimensions)

  // styles need isInnalid, therefore init them after state variables
  const { classes } = useFlowDesignerNodeStyles({ selected: isSelected, hasError: isInvalid })

  /**
   * This determines the required height for a node depending on the number of ports.
   * If
   * Each port is assumed to require "heightPerPort" pixel in height.
   * @param portsRight
   */
  function calculateNodeHeight(portsRight: Port[]): void {
    if (nodeDivRef.current) {
      const portContainerHeight = portsRight.length * FLOWDESIGNER_SPACE_PER_PORT
      const nodeHeight = nodeDivRef.current.getBoundingClientRect().height
      const newHeight = Math.max(portContainerHeight, FLOWDESIGNER_NODE_MIN_HEIGHT)

      if (newHeight !== FLOWDESIGNER_NODE_MIN_HEIGHT && newHeight >= nodeHeight) {
        // if ports require more height than the current node height or the min node height, we set the height fixed as pixel value
        nodeDivRef.current.style.height = `${newHeight}px`
      } else {
        // in all other cases, we set the height to 100% do allow for the content to expand and to shrink the node (e.g. when ports are removed or a card node is collapsed)
        nodeDivRef.current.style.height = '100%'
      }

      // we need to update node dimensions for the edges to connect to the right ports
      // (the right position of the ports, after the node size has changed due to port changes)
      updateNodeDimensions([
        {
          id: id,
          nodeElement: nodeDivRef.current,
          forceUpdate: true,
        },
      ])
    }
  }

  /**
   * Callback function for when node content size changes.
   * This is for example needed if a card display is toggled or not.
   * We trigger a state update to make this component re-render and re-calculate its height via the useEffect.
   */
  function onNodeContentResize(width?: number, height?: number): void {
    setContentContainerSize({ width: width || 0, height: height || 0 })
  }

  useEffect(
    function () {
      if (typeof chart !== 'undefined' && nodeProperties.text) {
        const displayText = replaceVarIdWithDisplayName(chart, nodeProperties.text)
        setDisplayText(displayText)
      }
    },
    [chart],
  )

  useEffect(
    function () {
      if (data?.node?.properties?.validationError) {
        setIsInvalid(true)
      } else {
        setIsInvalid(false)
      }
    },
    [data],
  )

  useEffect(
    /**
     * Updates ports if ports have changed
     */
    function () {
      const newPortsRight: Port[] = []
      const newPortsLeft: Port[] = []

      const ports = Object.values(data.node.ports)
      for (let i = 0; i < ports.length; i += 1) {
        if (ports[i].type === 'right') newPortsRight.push(ports[i])
        if (ports[i].type === 'left') newPortsLeft.push(ports[i])
      }
      if (!isEqual(newPortsRight, portsRight) || !isEqual(newPortsLeft, portsLeft)) {
        setPortsRight(newPortsRight)
        setPortsLeft(newPortsLeft)
      }
    },
    [data.node],
  )

  useEffect(
    function () {
      calculateNodeHeight(portsRight)
    },
    [portsRight, contentContainerSize],
  )

  useEffect(
    function () {
      setIsSelected(selected)
    },
    [selected],
  )

  return (
    <>
      <div ref={nodeDivRef} className={classes.nodeContainer}>
        {/* render content */}
        {/* <div id='resizeContainer' className={classes.nodeContentContainer}> */}
        {/* {renderNodeSpecificContent()} */}
        <NodeContent
          chart={chart}
          translationFile={translationFile}
          node={node}
          hasError={isInvalid}
          onResize={onNodeContentResize}
          displayCard={data.displayCard}
          isSelected={selected}
        />
        {/* </div> */}
        {/* render left ports */}
        <div className={classes.portContainerLeft}>
          {portsLeft.map((port, index) => {
            if (!(port.type === 'left' || port.type === 'right')) return null
            const type = 'target'
            const position = Position.Left

            return (
              <Handle
                key={port.id}
                id={port.id}
                type={type}
                position={position}
                isConnectable={isConnectable}
                style={{
                  width: `${FLOWDESIGNER_NODE_PORT_DIAMETER}px`,
                  height: `${FLOWDESIGNER_NODE_PORT_DIAMETER}px`,
                  top: portOffsetLeft[index],
                  cursor: type === 'target' ? 'default' : 'move',
                }}
              />
            )
          })}
        </div>
        {/* render right ports */}
        <div className={classes.portContainerRight}>
          {portsRight.map((port, index) => {
            if (!(port.type === 'left' || port.type === 'right')) return null
            const type = 'source'
            const position = Position.Right

            return (
              <Handle
                key={port.id}
                id={port.id}
                type={type}
                position={position}
                isConnectable={isConnectable}
                style={{
                  width: `${FLOWDESIGNER_NODE_PORT_DIAMETER}px`,
                  height: `${FLOWDESIGNER_NODE_PORT_DIAMETER}px`,
                  top: portOffsetRight[index],
                }}
              />
            )
          })}
        </div>
      </div>
      {nodeProperties.validationError ? (
        <div className={classes.errorTextContainer}>
          <div className={classes.errorIconContainer}>
            <ErrorOutline />
          </div>
          <div className={classes.errorText}>{nodeProperties.validationErrorMsg}</div>
        </div>
      ) : null}
    </>
  )
}

export default memo(FlowDesignerNode)
