import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { CheckCircleOutline } from '@mui/icons-material'

// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. Unsupported arrow function syntax.
// Arrow function has parameter type of Identifier instead of ObjectPattern (e.g. `(props) => ({...})` instead of `({color}) => ({...})`).
// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. Unsupported arrow function syntax.
// Arrow function has parameter type of Identifier instead of ObjectPattern (e.g. `(props) => ({...})` instead of `({color}) => ({...})`).
const useStyles = makeStyles<CardProps>()((theme, props) => ({
  root: {
    position: 'relative',
    width: '263px',
  },
  card: {
    position: 'relative',
    border: `${props.selected ? '2px solid #43A047' : '1px solid rgba(0, 0, 0, 0.03)'}`,
    padding: `${props.selected ? '0px' : '1px'}`,
    // borderRadius: theme.shape.borderRadius,
    borderRadius: '10px',
    boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.25)',
    background: '#FFFFFF',
    textAlign: 'left',
    width: '100%',
    height: '166px',
    cursor: 'pointer',
  },
  content: {
    padding: '15px 12px',
  },
  header: {
    display: 'flex',
    '& h1': {
      fontSize: '18px',
      color: theme.palette.primary.main,
      margin: '0px',
      marginBottom: '5px',
      height: '50px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& h2': { fontSize: '12px', color: theme.palette.primary.main, margin: '0px' },
  },
  selectedIcon: {
    marginLeft: 'auto',
    color: '#43A047',
  },
  text: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '15px', // TODO should this go into theme?
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: '70px',
  },
  color: {
    height: '30px',
    width: '100%',
    borderRadius: '0px 0px 10px 10px',
    background: props.color || 'green',
    position: 'absolute',
    bottom: '0px',
  },
}))

export type CardProps = {
  title: string
  subtitle?: string
  selected?: boolean
  text: string
  color?: string
  onClick: () => void
}

export default function Card({ title, subtitle, selected, text, color, onClick }: CardProps): React.ReactElement {
  // const { title, subtitle, selected, text, color } = props
  const { classes } = useStyles({ title, subtitle, selected, text, color, onClick })

  return (
    <div className={classes.root}>
      <div className={classes.card} onClick={onClick}>
        <div className={classes.content}>
          <div className={classes.header}>
            <div>
              <h2>{subtitle || ' '}</h2>
              <h1>{title}</h1>
            </div>
            {selected && <CheckCircleOutline className={classes.selectedIcon} />}
          </div>
          <div className={classes.text}>{text}</div>
        </div>
      </div>
      <div className={classes.color} />
    </div>
  )
}
