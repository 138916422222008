/* eslint-disable react/prop-types */
import React, { useState, useEffect, Fragment, useCallback } from 'react'
// types
import { Coordinates, Dimensions, SyncedSignature, IngestPDFFieldDescription } from '../../@types/Ingest/types'
import { IngestPDFField, IngestPDFResult } from '../../classes/Ingest'

// import IngestPDFSubSection from '../Classes/IngestPDFSubSection'
// @mui/material
import { makeStyles } from 'tss-react/mui'

import { DropdownOption } from '../Popups/popupElements/PopupDropdown'
import FieldPopup, { PopupField } from '../Popups/FieldPopup'

const CONTAINER_PADDING = 5

const useStyles = makeStyles()((theme) => ({
  fieldHightlight: {
    cursor: 'pointer',
    position: 'absolute',
    borderRadius: theme.shape.borderRadius,
  },
}))

/** Returns field type options array */
function getFieldTypeOptions(): DropdownOption[] {
  const types = ['Text', 'Checkbox', 'RadioButton', 'Choice', 'Signature']
  return types.map((type) => {
    return { label: type, value: type }
  })
}

type Props = {
  target: HTMLDivElement
  pageNumber: number
  syncedSignature: SyncedSignature
  ingestPDFResult: IngestPDFResult
  setIngestPDFResult: (result: IngestPDFResult) => void
}

// TODO: different colors for field types
// TODO: Display field type as small text
// TODO: check for double names

function FieldHighlights(props: Props): React.ReactElement {
  // eslint-disable-next-line no-undef
  process.env.REACT_APP_BRANCH === 'development' &&
    console.info('FieldHighlights.render props.ingestPDFResult: ', props.ingestPDFResult)
  const [fieldHighlightsRef, setFieldHighlightsRef] = useState<HTMLElement[]>([]) // all visible fields

  /** currently selected field */
  const [selectedField, setSelectedField] = useState<IngestPDFField | null>(null)
  /** popup state */
  const [popupHidden, setPopupHidden] = useState<boolean>(false) // used to hide popup if field description is being selected

  /** Popup values */
  const [popUpStartingPoint, setPopUpStartingPoint] = useState({ x: 0, y: 0 })

  const fieldTypeOptions = getFieldTypeOptions()
  const [selectedFieldType, setSelectedFieldType] = useState<DropdownOption>()
  const [subSectionOptions, setSubSectionOptions] = useState<DropdownOption[]>([])
  const [selectedSubSection, setSelectedSubSection] = useState<DropdownOption>()

  const { classes } = useStyles()

  /**
   * Removes browser text highlighting of selection.
   */
  function clearBrowserSelection(): void {
    const selection = window.getSelection ? window.getSelection() : null
    if (selection) selection.empty ? selection.empty() : selection.removeAllRanges()
  }

  /**
   * Prepares dropdowns for popup.
   * - builds options array for populating dropdown.
   * - sets selected option.
   * Prepares subsection and fieldType options.
   * Sets prepared values in state.
   */
  function preparePopupSelections(ingestPDFResult: IngestPDFResult): void {
    const subsections = Object.values(ingestPDFResult.regions.subsections)
    const subsectionOptions = subsections.map((subsection) => {
      return {
        label: subsection.name,
        value: subsection.id,
      }
    })

    let selectedFieldType, selectedSubsection
    if (ingestPDFResult.selectedId) {
      const type = ingestPDFResult.fields[ingestPDFResult.selectedId].type
      selectedFieldType = fieldTypeOptions.find((option) => option.label === type)

      for (const subsection of subsections) {
        if (subsection.fields.includes(ingestPDFResult.selectedId)) {
          selectedSubsection = subsectionOptions.find((option) => option.value === subsection.id)
          break
        }
      }
    }

    setSubSectionOptions(subsectionOptions)
    setSelectedSubSection(selectedSubsection)
    setSelectedFieldType(selectedFieldType)
  }

  /**
   * Returns original PDF page dimensions of current page number
   * Iterates over fields until first field's page number matches current page.
   *
   * // TODO: This might be a problem if a page has no fields.
   * //       For now we then use the dimens of some fields,
   * //       but PDFs with pages that have different dimensions could be problematic.
   */
  function getPDFPageDimens(): Dimensions | null {
    for (const field of props.syncedSignature) {
      if (field.page === props.pageNumber) {
        return { w: field.pageDimens.original.w, h: field.pageDimens.original.h }
      }
    }
    // no field on this page found
    // get first field and return its page dimensions
    if (props.syncedSignature && props.syncedSignature.length > 0) {
      return { w: props.syncedSignature[0].pageDimens.original.w, h: props.syncedSignature[0].pageDimens.original.h }
    }

    return null
  }

  /**
   * Calculates where the PopUp should be opended
   * // TODO: Ensure popup is not cutoff at right or bottom side
   * // TODO: Ensure popup is not shown over field
   * @param containerCoords Coordinates of the connected Container
   */
  function calculateStartPositionPopUp(containerCoords: Coordinates): { x: number; y: number } {
    // For now very simple RuleSet
    if (containerCoords.y + containerCoords.h > 450) {
      if (containerCoords.y - 200 >= 0) {
        return { x: containerCoords.x, y: containerCoords.y - 200 }
      } else if (containerCoords.y - 100 >= 0) {
        return { x: containerCoords.x, y: containerCoords.y - 100 }
      } else {
        return { x: containerCoords.x, y: containerCoords.y }
      }
    } else {
      return { x: containerCoords.x, y: containerCoords.y + containerCoords.h + CONTAINER_PADDING }
    }
  }

  /**
   * Calculates coordinates of text selection relative to PDF.
   * In other words: The exact position in the actual PDF.
   * Scales to PDF.
   */
  function calculateOriginalCoordsInPDF(boundingRect: Coordinates): Coordinates {
    const _boundingBox = props.target.getBoundingClientRect()
    const syncCoords = { h: _boundingBox.height, w: _boundingBox.width, x: _boundingBox.x, y: _boundingBox.y }

    // calculate x and y ratio of PDFViewer dimens <-> PDF dimens
    const pdfDimens = getPDFPageDimens()
    if (!pdfDimens) {
      // if we can not determine PDF page dimensions, we return coordinates relative to PDFViewer
      return { x: syncCoords.x, y: syncCoords.y, w: syncCoords.w, h: syncCoords.h }
    }

    // adjust position of selection using calculated ratios
    const xRatio = syncCoords.w / pdfDimens.w
    const yRatio = syncCoords.h / pdfDimens.h
    const xOriginal = Math.round(boundingRect.x / xRatio)
    const hOriginal = Math.round(boundingRect.h / yRatio)
    const wOriginal = Math.round(boundingRect.w / xRatio)
    // first calculate left lower y value of sync box, then adjust to original PDF dimensions
    const yOriginal = (syncCoords.h - (boundingRect.y + boundingRect.h)) / yRatio

    return { x: xOriginal, y: yOriginal, h: hOriginal, w: wOriginal }
  }

  /**
   * Returns the style of a field depending if selected, hover etc.
   * @param field field object
   */
  function getStyle(fieldId: string, coords: Coordinates | null, isConfigured: boolean | undefined): object {
    const configuredColor = 'rgba(53, 222, 61, 0.25)'
    const unconfiguredColor = 'rgba(240, 59, 50, 0.25)'

    if (!coords) {
      return {
        display: 'none',
      }
    }

    if (selectedField && selectedField.id === fieldId) {
      // Selected state
      return {
        top: coords.y,

        left: coords.x,

        width: coords.w,

        height: coords.h,
        border: 'solid 3px red',
        background: isConfigured ? configuredColor : unconfiguredColor,
        color: 'white',
      }
    }

    if (!isConfigured) {
      // unconfigured and not selected
      return {
        top: coords.y,

        left: coords.x,

        width: coords.w,

        height: coords.h,
        background: unconfiguredColor,
        // borderColor: '#2185d0',
        color: 'white',
      }
    }

    if (isConfigured) {
      return {
        top: coords.y,

        left: coords.x,

        width: coords.w,

        height: coords.h,
        background: configuredColor,
        // borderColor: '#2185d0',
        color: 'white',
      }
    }

    return {
      top: coords.y,

      left: coords.x,

      width: coords.w,

      height: coords.h,
      background: unconfiguredColor,
    }
  }

  /**
   * Add the field highlight div reference to the `fieldHighlightsRef` state
   * If field is selected field, set selected index.
   * @param ref Reference of the field highlight div
   */
  function addFieldHighlightRef(ref: HTMLDivElement | null): void {
    const tmp = fieldHighlightsRef
    if (ref !== null && tmp.indexOf(ref) === -1) {
      tmp.push(ref)
    }
    setFieldHighlightsRef(tmp)
  }

  // ======= USER INTERACTION =========
  /**
   * Register mouse up event listener to listen for mouse click & drag selection.
   * Also registers onKeyDown listener to listen for esc key cancellation.
   */
  function registerDescriptionSelectionEventListener(): void {
    const _target = props.target
    if (_target)
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      _target.addEventListener('mouseup', onMouseUp, true)
  }

  function removeDescriptionSelectionEventListener(): void {
    const _target = props.target
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    if (_target) _target.removeEventListener('mouseup', onMouseUp, true)
  }

  /**
   * Activates select field description mode in ingestPDFResult and calls callback.
   */
  function enableSelectFieldDescriptionMode(): void {
    const ingestPDFResult = props.ingestPDFResult
    ingestPDFResult.setSelectFieldDescriptionActive(true)
    props.setIngestPDFResult(ingestPDFResult)
  }

  /**
   * Activates select field description mode in ingestPDFResult and calls callback.
   */
  function disableSelectFieldDescriptionMode(): void {
    const ingestPDFResult = props.ingestPDFResult
    ingestPDFResult.setSelectFieldDescriptionActive(false)
    props.setIngestPDFResult(ingestPDFResult)
  }

  /** Cancels description selection and shows popup again */
  function cancelSelectFieldDescription(): void {
    removeDescriptionSelectionEventListener()
    // setPopupHidden(false)
    disableSelectFieldDescriptionMode()
  }

  /**
   * Activates "description selection mode"
   * Hides Popup.
   */
  function onSelectFieldDescription(): void {
    registerDescriptionSelectionEventListener()
    // setPopupHidden(true)
    enableSelectFieldDescriptionMode()
  }

  function onSelectFieldDescriptionComplete(description: IngestPDFFieldDescription): void {
    // remove mouse up event listener
    removeDescriptionSelectionEventListener()

    // set description object in selectedField
    if (selectedField && description) {
      description.isConfigured = true
      selectedField.setDescription(description)
      const ingestPDFResult = props.ingestPDFResult
      ingestPDFResult.fields[selectedField.id] = selectedField
      props.setIngestPDFResult(ingestPDFResult)
    }

    // setPopupHidden(false)
    disableSelectFieldDescriptionMode()
  }

  /**
   * Handles click on field in PDF.
   * Finds field by index and sets selectedId in IngestPDFResult.
   * @param field
   */
  function onFieldSelect(field: IngestPDFField): void {
    const ingestPDFResult = props.ingestPDFResult
    ingestPDFResult.setSelectedId(field.id)
    props.setIngestPDFResult(ingestPDFResult)
  }

  /**
   * Update field with values from Popup in IngestPDFResult
   * De-select field as popup closes with confirm.
   */
  function onConfirm(field: PopupField): void {
    if (selectedField) {
      const ingestPDFResult = props.ingestPDFResult

      selectedField.setDisplayName(field.displayName)
      selectedField.setType(field.fieldType?.value || selectedField.type)
      selectedField.setDescriptionText(field.fieldDescription)
      selectedField.setIsConfigured(true)

      ingestPDFResult.fields[selectedField.id] = selectedField
      ingestPDFResult.removeSelectedId()

      props.setIngestPDFResult(ingestPDFResult)
    }
  }

  /** Does not actually delete field, but sets it to unconfigured and de-selects it */
  function onDelete(): void {
    const ingestPDFResult = props.ingestPDFResult
    if (selectedField) {
      // TODO: should we remove/reset configured description? Currently it is kept, but its isConfigured boolean is set to false.
      // Funciton already has reset parameter implemented that can be used
      selectedField?.setIsDescriptionConfigured(false)
      selectedField?.setIsConfigured(false)
      ingestPDFResult.fields[selectedField.id] = selectedField
    }
    ingestPDFResult.removeSelectedId()
    props.setIngestPDFResult(ingestPDFResult)
  }

  /** Does not change anything except de-selecting selected field */
  function onClose(): void {
    const ingestPDFResult = props.ingestPDFResult
    ingestPDFResult.removeSelectedId()
    ingestPDFResult.setAutoFieldSelectionDisabled(true)
    props.setIngestPDFResult(ingestPDFResult)
  }

  // ============= EVENT LISTENER CALLBACKS ==============

  /**
   * MouseUp event handler for handling text selection.
   * Sets selected text, gets bounding rect coordinates of selection, adjusts them to PDF coordinates and sets them.
   * @param event mouse up event.
   */
  function onMouseUp(event: MouseEvent): void {
    const selection = document.getSelection()
    let selectionObj
    if (selection && selection.rangeCount > 0 && props.target) {
      // everything present, we can move on
      const selectedText = selection.toString()
      if (!selectedText) return
      const boundingRect = selection.getRangeAt(0).getBoundingClientRect()
      const parentCoords = props.target.getBoundingClientRect()
      const adjustedCoords = {
        x: boundingRect.x - window.scrollX - parentCoords.x,
        y: boundingRect.y - window.scrollY - parentCoords.y,
        w: boundingRect.width,
        h: boundingRect.height,
      }

      // clear browser selection
      clearBrowserSelection()

      selectionObj = {
        text: selectedText,
        coords: {
          sync: adjustedCoords,
          original: calculateOriginalCoordsInPDF(adjustedCoords),
        },
      }
    }
    // call selection complete callback
    onSelectFieldDescriptionComplete(selectionObj)
    event.preventDefault()
  }

  /**
   * Callback function for onKeyDown eventlistener
   * We need to use useCallback to memoize the function so that we can properly remove the event listener when the popup is open.
   * Depends on ingestPDFResult, because called functions need current ingestPDFResult, not the one of the first creation.
   */
  const onKeyDown = useCallback(
    function _onKeyDown(event: KeyboardEvent): void {
      if (event.key === 'Escape') {
        // differentiate between popup displayed and description selection
        // if (popupHidden) {
        if (props.ingestPDFResult.selectFieldDescriptionActive) {
          // description selection stage, only cancel selection
          cancelSelectFieldDescription()
        } else {
          // popup is shown, cancel popup (close it)
          onClose()
        }
        event.preventDefault()
      }
    },
    [props.ingestPDFResult],
  )

  function registerOnKeyDownEventListener(): void {
    window.addEventListener('keydown', onKeyDown, true)
  }

  function removeOnKeyDownEventListener(): void {
    window.removeEventListener('keydown', onKeyDown, true)
  }

  // _____ EFFECTS ______
  useEffect(() => {
    registerOnKeyDownEventListener()
    return (): void => {
      // component will unmount
      // remove event listeners
      removeDescriptionSelectionEventListener()
      removeOnKeyDownEventListener()
    }
  }, [onKeyDown])

  /** SelectedID change */
  useEffect(() => {
    // Deselect event: if selectedId is undefined
    if (!props.ingestPDFResult.selectedId) {
      // onClose for existing
      setPopUpStartingPoint({ x: 0, y: 0 })
      setSelectedField(null)
    } else {
      const selectedField = props.ingestPDFResult.fields[props.ingestPDFResult.selectedId]
      preparePopupSelections(props.ingestPDFResult)
      setPopUpStartingPoint(calculateStartPositionPopUp(selectedField.coords.sync))
      // minor delay so the user does not see a jump by the popUp
      // this also ensures, that the popup is displayed at the calculated position
      setTimeout(() => {
        setSelectedField(selectedField)
      }, 50)
    }
  }, [props.ingestPDFResult])

  // _____ RENDER _____
  function renderFields(): React.ReactElement {
    return (
      <Fragment>
        {Object.values(props.ingestPDFResult.fields)
          .filter((field) => field.page === props.pageNumber)
          .map((field, index) => {
            return (
              <Fragment key={index}>
                <div
                  id={`field_desc_highlight_${index}`}
                  key={`${index}-desc`}
                  ref={addFieldHighlightRef}
                  className={classes.fieldHightlight}
                  style={getStyle(
                    field.id,
                    field.description.isConfigured ? field.description.coords.sync : null,
                    field.description.isConfigured,
                  )}
                  onClick={(event): void => {
                    onFieldSelect(field)
                    event.preventDefault()
                  }}
                />
                <div
                  id={`field_highlight_${index}`}
                  key={`${index}-field`}
                  ref={addFieldHighlightRef}
                  className={classes.fieldHightlight}
                  style={getStyle(field.id, field.coords.sync, field.isConfigured)}
                  onClick={(event): void => {
                    onFieldSelect(field)
                    event.preventDefault()
                  }}
                />
              </Fragment>
            )
          })}
      </Fragment>
    )
  }

  return (
    <Fragment>
      <div id='fields'>{renderFields()}</div>
      {selectedField !== null && !props.ingestPDFResult.selectFieldDescriptionActive && (
        <FieldPopup
          parent={props.target}
          startPosition={popUpStartingPoint}
          title={'Feld'}
          fieldType={selectedFieldType}
          fieldTypeOptions={fieldTypeOptions}
          selectedSection={selectedSubSection}
          sectionOptions={subSectionOptions}
          fieldName={selectedField?.displayName}
          fieldDescription={selectedField?.description.text}
          fieldMandatory={selectedField?.required}
          isDescriptionConfigured={selectedField?.description.isConfigured}
          onSelectFieldDescription={(): void => {
            onSelectFieldDescription()
          }}
          onConfirm={onConfirm}
          onClose={onClose}
          onDelete={onDelete}
        />
      )}
    </Fragment>
  )
}

FieldHighlights.defaultProps = {
  pageNumber: 0,
}

export default FieldHighlights
