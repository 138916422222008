/* eslint-disable no-undef */
export default {
  clientId: process.env.REACT_APP_AAD_CLIENTID ?? '',
  authority: process.env.REACT_APP_AAD_AUTHORITY ?? '',
  redirectUri: process.env.REACT_APP_AAD_REDIRECTURL ?? '',
  knownAuthorities: [
    'adb2cconvaisedev.b2clogin.com',
    'aadconvaisecustomer.b2clogin.com',
    'aadconvaisecustomer.onmicrosoft.com',
    'adb2cconvaisedev.onmicrosoft.com',
  ],
  scopes: ['openid', process.env.REACT_APP_AAD_SCOPE ?? ''],
}
