import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useTheme } from '@mui/material/styles'
import React, { useEffect, useState } from 'react'

// assets
import svgAnalyticsDiagram from 'assets/img/analytics/analytics_diagram.svg'

import ContentContainer from '../../ContentContainer'
import { AnalyticsEventOrigin, Chart } from '../../../../../../@types/Flowchart/types'
import { CONVAISE_ANALYTICS_EVENTS } from '../SAnalyticsTrigger'

import { Button } from 'components/Buttons'
import Table from 'components/Table/Table'

const useStyles = makeStyles()((theme) => ({
  container: {
    height: '100%',
    marginTop: theme.spacing(4),
  },
  buttonContainer: {
    marginTop: theme.spacing(4),
    width: '100%',
    textAlign: 'center',
  },
}))

type EventOverviewProps = {
  chart: Chart
  headerText: string
  descriptionText: string
  onBackClick: () => void
  onEditClick: () => void
  origin: AnalyticsEventOrigin
}

/**
 * Finds display name of convaise event
 * @param event
 */
function getDisplayNameOfConvaiseEvent(eventName: string): string {
  for (const event of CONVAISE_ANALYTICS_EVENTS) {
    if (event.value === eventName) return event.label
  }
  return eventName
}

export default function EventOverview({
  chart,
  headerText,
  descriptionText,
  onBackClick,
  onEditClick,
  origin,
}: EventOverviewProps): React.ReactElement {
  const { classes } = useStyles()
  const theme = useTheme()

  const [eventName, setEventName] = useState<string>('')
  const [eventDescription, setEventDescription] = useState<string>('')

  useEffect(
    function () {
      // build dropdown options and set selected if event is already selected
      const selectedId = chart.selected.id
      if (!selectedId) return
      const eventName = chart.nodes[selectedId].properties.analyticsEvent?.eventName ?? ''
      const eventDescription = chart.nodes[selectedId].properties.analyticsEvent?.customEvent?.description ?? ''
      setEventName(eventName)
      setEventDescription(eventDescription)
    },
    [chart],
  )

  const tableRows = [
    [
      <Typography key='event-name'>Event Name</Typography>,
      <Typography key='event-name-value'>
        {origin === 'customer' ? eventName.replace(/^(customer\/)/, '') : getDisplayNameOfConvaiseEvent(eventName)}
      </Typography>,
    ],
  ]
  if (origin === 'customer')
    tableRows.push([
      <Typography key='event-description'>Event Beschreibung</Typography>,
      <Typography key='event-description-value'>{eventDescription}</Typography>,
    ])

  return (
    <ContentContainer
      headerText={headerText}
      descriptionText={descriptionText}
      onBackClick={onBackClick}
      svg={svgAnalyticsDiagram}
    >
      <div className={classes.container}>
        <Table
          rows={tableRows}
          cellAlignPattern={['left', 'left']}
          width={['20%', '80%']}
          disablePagination
          padding='medium'
        />
        <div className={classes.buttonContainer}>
          <Button onClick={onEditClick} icon='pencil-line' iconType='remix'>
            Bearbeiten
          </Button>
        </div>
      </div>
    </ContentContainer>
  )
}
