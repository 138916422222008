import { makeStyles } from 'tss-react/mui'
import { Typography } from '@mui/material'
import CustomContentTooltip from 'components/Tooltips/CustomContentTooltip'
import { useBotContext } from 'hooks/contexts/bot-context'
import React, { useEffect, useState } from 'react'
import { MODULE_TYPE_ALEPHALPHA } from 'utils/constants'
import { AlephAlphaLumiConversationalModule } from '../../../../../@types/BotInformation/types'
import { Chart } from '../../../../../@types/Flowchart/types'
import SelectDropdown, { Option } from '../../../../../components/Dropdown/SelectDropdown'
import VariablesAutosuggestSelect from '../Variables/VariablesAutosuggestSelect'
// @mui/material components

const useStyles = makeStyles()((theme) => ({
  container: { overflow: 'auto', height: '100%' },
  inputWithInfo: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  inputInfoIcon: {
    marginLeft: theme.spacing(1),
    height: '20px',
    color: theme.palette.grey[700],
  },
  moduleSelection: {
    marginTop: theme.spacing(4),
  },
  variables: {
    marginTop: theme.spacing(4),
  },
  varSelectionContainer: {
    marginTop: theme.spacing(2),
  },
}))

enum VariableSelectionPurpose {
  USER_INPUT,
  SEARCH_MODE,
  CORRELATION_ID,
  CONVERSATION_HISTORY,
  DATASET,
  REPLY,
  OPTION_TEXTS,
  // SOURCE_TITLE,
  // SOURCE_LINK,
  DOCUMENTS,
  IS_ANSWERED,
  IS_SMALLTALK,
  USED_SEARCH_MODE,
  VOICE_OR_TEXT_MODE,
}

type GetAlephAlphaGetAnswerBasicViewProps = {
  chart: Chart
  setIsSaveDisabled: (isSaveDisabled: boolean) => void
  setStateCallback: (chart: Chart) => void
}

export default function GetAlephAlphaGetAnswerBasicView({
  chart,
  setIsSaveDisabled,
  setStateCallback,
}: GetAlephAlphaGetAnswerBasicViewProps): React.ReactElement {
  const { classes } = useStyles()
  const { bot } = useBotContext()

  // module select
  const [moduleOptions, setModuleOptions] = useState<Option[]>([])
  const [selectedModuleId, setSelectedModuleId] = useState<string>()
  const [datasetOptions, setDatasetOptions] = useState<Option[]>([])
  const [selectedDataset, setSelectedDataset] = useState<string>()
  const [varIdConversationHistory, setVarIdConversationHistory] = useState<string>()
  // variables select for setting values
  const [varIdReply, setVarIdReply] = useState<string>() // id of variable for storing the reply generated by the Aleph Alpha Converational API
  const [varIdOptionTexts, setVarIdOptionTexts] = useState<string>() // id of variable for storing the options presented to the user (if any) (same as the document titles)
  const [varIdDataset, setVarIdDataset] = useState<string>() // id of variable for storing the dataset that should be used
  const [varIdDocuments, setVarIdDocuments] = useState<string>() // id of variable for storing the documents the user can chose from (if any)
  const [varIdUsedSearchMode, setVarIdUsedSearchMode] = useState<string>() // id of variable that is updated with the used search mode from the AA module
  const [varIdCorrId, setVarIdCorrId] = useState<string>() // id of variable for storing the correlation id of the answer
  // const [varIdSourceTitle, setVarIdSourceTitle] = useState<string>() // id of variable for storing the title of the source document
  // const [varIdSourceUrl, setVarIdSourceUrl] = useState<string>() // id of variable for storing the source URL to the source website
  const [varIdIsAnswered, setVarIdIsAnswered] = useState<string>() // id of variable for storing the boolean indicating if the reply is an actual answer
  const [varIdIsSmalltalk, setVarIdIsSmalltalk] = useState<string>() // id of variable for storing the boolean indicating if the reply is smalltalk

  // variables select for using values (values have been set elsewhere)
  const [varIdUserInput, setVarIdUserInput] = useState<string>() // id of the variable that holds the user's input
  const [varIdSearchMode, setVarIdSearchMode] = useState<string>() // id of the variable that holds the current search mode
  const [varIdVoiceOrTextMode, setVarIdVoiceOrTextMode] = useState<string>() // if of variable for storing current mode (voice or text)

  /**
   * Reads all aleph alpha modules configured for this bot and prepares them for the dropdown.
   * If only one aleph alpha module exists, sets it.
   */
  function prepareModuleOptions(): void {
    if (!bot) return

    const alephAlphaModules = Object.values(bot.modules).filter(
      (module) =>
        module.type === MODULE_TYPE_ALEPHALPHA && module.moduleConfigId === '32f8a697-f9c8-4636-9622-a125ada6e73d', // right now this node only supports the conversational LUMI module
    )
    const moduleOptions = alephAlphaModules.map((module) => {
      const { type, moduleConfigId } = module as AlephAlphaLumiConversationalModule
      return {
        label: type,
        value: moduleConfigId,
      }
    })

    setModuleOptions(moduleOptions)
    if (moduleOptions.length === 1) {
      // only 1 possible module -> select it
      onModuleSelect({ label: moduleOptions[0].label, value: moduleOptions[0].value })
    }
  }

  /**
   * Reads all aleph alpha datasets configured for this bot and prepares them for the dropdown.
   * If only one aleph alpha dataset exists, sets it.
   */
  function prepareDatasetOptions(): void {
    if (!bot) return

    const alephAlphaModules = Object.values(bot.modules).filter(
      (module) =>
        module.type === MODULE_TYPE_ALEPHALPHA && module.moduleConfigId === '32f8a697-f9c8-4636-9622-a125ada6e73d', // right now this node only supports the conversational LUMI module
    )

    if (alephAlphaModules.length === 1) {
      const options = (alephAlphaModules[0] as AlephAlphaLumiConversationalModule).availableDatasets.map((dataset) => {
        return {
          label: dataset,
          value: dataset,
        }
      })

      setDatasetOptions(options)

      if (options.length === 1) {
        // only 1 possible module -> select it
        onDatasetSelect({ label: options[0].label, value: options[0].value })
      }
    }
  }

  // ========= HANDLER ============

  /**
   * Handles module selection
   * @param newValue
   */
  function onModuleSelect(newValue: Option): void {
    const selectedId = chart.selected?.id
    if (typeof selectedId === 'undefined') return
    const node = chart.nodes[selectedId]
    if (typeof node === 'undefined') return

    const moduleId = newValue.value

    node.properties.moduleConfigId = moduleId

    // also set new text for display in dialog designer
    const module = bot?.modules[moduleId] as AlephAlphaLumiConversationalModule
    if (module) {
      node.properties.text = `${module.type === 'aleph-alpha' ? 'Aleph Alpha -' : ''} Antwort suchen`
    }
    if (module) setSelectedModuleId(moduleId)

    chart.nodes[selectedId] = node
    setStateCallback(chart)
  }

  /**
   * Handles dataset selection
   * @param newValue
   */
  function onDatasetSelect(newValue: Option): void {
    const selectedId = chart.selected?.id
    if (typeof selectedId === 'undefined') return
    const node = chart.nodes[selectedId]
    if (typeof node === 'undefined') return

    const dataset = newValue.value

    if (!node.properties.alephalpha) node.properties.alephalpha = {}
    node.properties.alephalpha.dataset = dataset

    setSelectedDataset(dataset)

    chart.nodes[selectedId] = node
    setStateCallback(chart)
  }

  /**
   * Sets the variable in the node properties and updates the chart
   * @param selectedVarId
   * @param purpose
   */
  function onVariableSelect(newChart: Chart, purpose: VariableSelectionPurpose, selectedVarId?: string | null): void {
    const selectedId = newChart.selected?.id
    if (typeof selectedId === 'undefined') return
    const node = newChart.nodes[selectedId]
    if (typeof node === 'undefined') return

    if (!node.properties.alephalpha) node.properties.alephalpha = {}

    switch (purpose) {
      case VariableSelectionPurpose.USER_INPUT:
        if (selectedVarId) node.properties.alephalpha.userInput = selectedVarId
        break
      case VariableSelectionPurpose.CONVERSATION_HISTORY:
        if (selectedVarId) node.properties.alephalpha.conversationHistory = selectedVarId
        break
      case VariableSelectionPurpose.CORRELATION_ID:
        if (selectedVarId) node.properties.alephalpha.corrId = selectedVarId
        break
      case VariableSelectionPurpose.OPTION_TEXTS:
        if (selectedVarId) node.properties.alephalpha.optionTexts = selectedVarId
        break
      case VariableSelectionPurpose.REPLY:
        if (selectedVarId) node.properties.alephalpha.reply = selectedVarId
        break
      case VariableSelectionPurpose.DOCUMENTS:
        if (selectedVarId) node.properties.alephalpha.documents = selectedVarId
        break
      case VariableSelectionPurpose.SEARCH_MODE:
        node.properties.alephalpha.searchMode = selectedVarId ?? undefined
        break
      case VariableSelectionPurpose.USED_SEARCH_MODE:
        node.properties.alephalpha.usedSearchMode = selectedVarId ?? undefined
        break
      case VariableSelectionPurpose.IS_ANSWERED:
        node.properties.alephalpha.isAnswered = selectedVarId ?? undefined
        break
      case VariableSelectionPurpose.IS_SMALLTALK:
        node.properties.alephalpha.isSmalltalk = selectedVarId ?? undefined
        break
      case VariableSelectionPurpose.VOICE_OR_TEXT_MODE:
        node.properties.alephalpha.voiceOrTextMode = selectedVarId ?? undefined
        break
    }

    newChart.nodes[selectedId] = node
    setStateCallback(newChart)
  }

  /**
   * Handles variable selection for user input
   * @param newChart
   * @param prevSelectedVarIds
   * @param selectedVarIds
   */
  function onVariableForUserInputSelect(newChart: Chart, prevSelectedVarIds: string[], selectedVarIds: string[]): void {
    const selectedVariableId = selectedVarIds.length > 0 ? selectedVarIds[0] : undefined
    onVariableSelect(newChart, VariableSelectionPurpose.USER_INPUT, selectedVariableId)
  }

  /**
   * Handles variable selection for option texts actions
   * @param newChart
   * @param prevSelectedVarIds
   * @param selectedVarIds
   */
  function onVariableForOptionTextsSelect(
    newChart: Chart,
    prevSelectedVarIds: string[],
    selectedVarIds: string[],
  ): void {
    const selectedVariableId = selectedVarIds.length > 0 ? selectedVarIds[0] : undefined
    onVariableSelect(newChart, VariableSelectionPurpose.OPTION_TEXTS, selectedVariableId)
  }

  /**
   * Handles variable selection for reply
   * @param newChart
   * @param prevSelectedVarIds
   * @param selectedVarIds
   */
  function onVariableForReplySelect(newChart: Chart, prevSelectedVarIds: string[], selectedVarIds: string[]): void {
    const selectedVariableId = selectedVarIds.length > 0 ? selectedVarIds[0] : undefined
    onVariableSelect(newChart, VariableSelectionPurpose.REPLY, selectedVariableId)
  }

  /**
   * Handles variable selection for correlation id
   * @param newChart
   * @param prevSelectedVarIds
   * @param selectedVarIds
   */
  function onVariableForCorrIdSelect(newChart: Chart, prevSelectedVarIds: string[], selectedVarIds: string[]): void {
    const selectedVariableId = selectedVarIds.length > 0 ? selectedVarIds[0] : undefined
    onVariableSelect(newChart, VariableSelectionPurpose.CORRELATION_ID, selectedVariableId)
  }

  /**
   * Handles variable selection for source link
   * @param newChart
   * @param prevSelectedVarIds
   * @param selectedVarIds
   */
  function onVariableForDocumentsSelect(newChart: Chart, prevSelectedVarIds: string[], selectedVarIds: string[]): void {
    const selectedVariableId = selectedVarIds.length > 0 ? selectedVarIds[0] : undefined
    onVariableSelect(newChart, VariableSelectionPurpose.DOCUMENTS, selectedVariableId)
  }

  /**
   * Handles variable selection for current search mode
   * @param newChart
   * @param prevSelectedVarIds
   * @param selectedVarIds
   */
  function onVariableForCurrentSearchModeSelect(
    newChart: Chart,
    prevSelectedVarIds: string[],
    selectedVarIds: string[],
  ): void {
    const selectedVariableId = selectedVarIds.length > 0 ? selectedVarIds[0] : undefined
    onVariableSelect(newChart, VariableSelectionPurpose.SEARCH_MODE, selectedVariableId)
  }

  /**
   * Handles variable selection for new search mode
   * @param newChart
   * @param prevSelectedVarIds
   * @param selectedVarIds
   */
  function onVariableForUsedSearchModeSelect(
    newChart: Chart,
    prevSelectedVarIds: string[],
    selectedVarIds: string[],
  ): void {
    const selectedVariableId = selectedVarIds.length > 0 ? selectedVarIds[0] : undefined
    onVariableSelect(newChart, VariableSelectionPurpose.USED_SEARCH_MODE, selectedVariableId)
  }

  /**
   * Handles variable selection for conversation history
   * @param newChart
   * @param prevSelectedVarIds
   * @param selectedVarIds
   */
  function onVariableForConversationHistorySelect(
    newChart: Chart,
    prevSelectedVarIds: string[],
    selectedVarIds: string[],
  ): void {
    const selectedVariableId = selectedVarIds.length > 0 ? selectedVarIds[0] : undefined
    onVariableSelect(newChart, VariableSelectionPurpose.CONVERSATION_HISTORY, selectedVariableId)
  }

  /**
   * Handles variable selection for isAnswered
   * @param newChart
   * @param prevSelectedVarIds
   * @param selectedVarIds
   */
  function onVariableForIsAnsweredSelect(
    newChart: Chart,
    prevSelectedVarIds: string[],
    selectedVarIds: string[],
  ): void {
    const selectedVariableId = selectedVarIds.length > 0 ? selectedVarIds[0] : undefined
    onVariableSelect(newChart, VariableSelectionPurpose.IS_ANSWERED, selectedVariableId)
  }

  /**
   * Handles variable selection for isSmalltalk
   * @param newChart
   * @param prevSelectedVarIds
   * @param selectedVarIds
   */
  function onVariableForIsSmalltalkSelect(
    newChart: Chart,
    prevSelectedVarIds: string[],
    selectedVarIds: string[],
  ): void {
    const selectedVariableId = selectedVarIds.length > 0 ? selectedVarIds[0] : undefined
    onVariableSelect(newChart, VariableSelectionPurpose.IS_SMALLTALK, selectedVariableId)
  }

  /**
   * Handles variable selection for voiceOrtextMode
   * @param newChart
   * @param prevSelectedVarIds
   * @param selectedVarIds
   */
  function onVariableForVoiceOrTextModeSelect(
    newChart: Chart,
    prevSelectedVarIds: string[],
    selectedVarIds: string[],
  ): void {
    const selectedVariableId = selectedVarIds.length > 0 ? selectedVarIds[0] : undefined
    onVariableSelect(newChart, VariableSelectionPurpose.VOICE_OR_TEXT_MODE, selectedVariableId)
  }

  useEffect(
    function () {
      const selectedId = chart.selected?.id
      if (typeof selectedId === 'undefined') return
      // get already selected Event
      const node = chart.nodes[selectedId]

      prepareModuleOptions()
      prepareDatasetOptions()
      setVarIdUserInput(node.properties.alephalpha?.userInput)
      setVarIdCorrId(node.properties.alephalpha?.corrId)
      setVarIdOptionTexts(node.properties.alephalpha?.optionTexts)
      setVarIdReply(node.properties.alephalpha?.reply)
      setVarIdDocuments(node.properties.alephalpha?.documents)
      setVarIdSearchMode(node.properties.alephalpha?.searchMode)
      setVarIdConversationHistory(node.properties.alephalpha?.conversationHistory)
      setVarIdUsedSearchMode(node.properties.alephalpha?.usedSearchMode)
      setVarIdIsAnswered(node.properties.alephalpha?.isAnswered)
      setVarIdIsSmalltalk(node.properties.alephalpha?.isSmalltalk)
      setVarIdVoiceOrTextMode(node.properties.alephalpha?.voiceOrTextMode)
    },
    [chart, bot],
  )

  useEffect(
    function () {
      if (
        selectedModuleId &&
        selectedDataset &&
        varIdCorrId &&
        varIdOptionTexts &&
        varIdReply &&
        varIdDocuments &&
        varIdSearchMode &&
        varIdUsedSearchMode &&
        varIdConversationHistory &&
        varIdIsAnswered &&
        varIdIsSmalltalk &&
        varIdUserInput &&
        varIdVoiceOrTextMode
      ) {
        setIsSaveDisabled(false)
      } else {
        setIsSaveDisabled(true)
      }
    },
    [
      selectedModuleId,
      selectedDataset,
      varIdCorrId,
      varIdOptionTexts,
      varIdReply,
      varIdDocuments,
      varIdSearchMode,
      varIdUsedSearchMode,
      varIdConversationHistory,
      varIdIsAnswered,
      varIdIsSmalltalk,
      varIdUserInput,
      varIdVoiceOrTextMode,
    ],
  )

  return (
    <div>
      <div className={classes.moduleSelection}>
        <div className={classes.inputWithInfo}>
          <Typography>Aleph Alpha Modul</Typography>
          <CustomContentTooltip
            content={<Typography>Bitte wählen Sie das Aleph Alpha Modul Ihres Assistenten.</Typography>}
            elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
          />
        </div>
        <SelectDropdown
          onChange={onModuleSelect}
          options={moduleOptions}
          selected={selectedModuleId}
          placeholder='Aleph Alpha Modul auswählen'
          width='100%'
        />
      </div>

      <div className={classes.moduleSelection}>
        <div className={classes.inputWithInfo}>
          <Typography>Aleph Alpha Dataset</Typography>
          <CustomContentTooltip
            content={<Typography>Bitte wählen Sie das Aleph Alpha Dataset Ihres Assistenten.</Typography>}
            elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
          />
        </div>
        <SelectDropdown
          onChange={onDatasetSelect}
          options={datasetOptions}
          selected={selectedDataset}
          placeholder='Aleph Alpha Dataset auswählen'
          width='100%'
        />
      </div>

      <div className={classes.variables}>
        <div className={classes.varSelectionContainer}>
          <div className={classes.inputWithInfo}>
            <Typography>Variable mit Frage</Typography>
            <CustomContentTooltip
              content={
                <Typography>
                  Bite wählen sie eine Variable, in der die Frage der Nutzer*innen gespeichert ist.
                </Typography>
              }
              elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
            />
          </div>
          <VariablesAutosuggestSelect
            chart={chart}
            onChange={onVariableForUserInputSelect}
            usageType='consume'
            isMulti={false}
            isCreatable
            isClearable
            selectedVariableIds={varIdUserInput ? [varIdUserInput] : []}
          />
        </div>
        <div className={classes.varSelectionContainer}>
          <div className={classes.inputWithInfo}>
            <Typography>Variable Inputmodus (Voice oder Text)</Typography>
            <CustomContentTooltip
              content={
                <Typography>
                  Bite wählen sie eine Variable, in der der aktuelle Inputmodus (Voice oder Text) gespeichert ist.
                </Typography>
              }
              elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
            />
          </div>
          <VariablesAutosuggestSelect
            chart={chart}
            onChange={onVariableForVoiceOrTextModeSelect}
            usageType='consume'
            isMulti={false}
            isCreatable
            isClearable
            selectedVariableIds={varIdVoiceOrTextMode ? [varIdVoiceOrTextMode] : []}
          />
        </div>
        <div className={classes.varSelectionContainer}>
          <div className={classes.inputWithInfo}>
            <Typography>Variable zum Speichern der Konversations Historie</Typography>
            <CustomContentTooltip
              content={
                <Typography>
                  Bitte wählen sie eine Variable, in der die Konversations Historie gespeichert ist. Diese wird bei der
                  Antwortfindung verwendet.
                </Typography>
              }
              elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
            />
          </div>
          <VariablesAutosuggestSelect
            chart={chart}
            onChange={onVariableForConversationHistorySelect}
            usageType='consume'
            isMulti={false}
            isCreatable
            isClearable
            selectedVariableIds={varIdConversationHistory ? [varIdConversationHistory] : []}
          />
        </div>

        <div className={classes.varSelectionContainer}>
          <div className={classes.inputWithInfo}>
            <Typography>Variable mit dem aktuellen Suchmodus</Typography>
            <CustomContentTooltip
              content={
                <Typography>
                  Bitte wählen sie eine Variable, in der der aktuelle Suchmodus (&quot;general&quot; oder
                  &quot;document&quot;) gespeichert ist.
                </Typography>
              }
              elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
            />
          </div>
          <VariablesAutosuggestSelect
            chart={chart}
            onChange={onVariableForCurrentSearchModeSelect}
            usageType='consume'
            isMulti={false}
            isCreatable
            isClearable
            selectedVariableIds={varIdSearchMode ? [varIdSearchMode] : []}
          />
        </div>
        <div className={classes.varSelectionContainer}>
          <div className={classes.inputWithInfo}>
            <Typography>Variable in die der bei der Suche festgestellte Suchmodus gespeichert werden soll.</Typography>
            <CustomContentTooltip
              content={
                <Typography>
                  Bitte wählen sie eine Variable, in die der bei der Suche festgestellte Suchmodus (&quot;general&quot;
                  oder &quot;document&quot;) gespeichert werden soll.
                </Typography>
              }
              elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
            />
          </div>
          <VariablesAutosuggestSelect
            chart={chart}
            onChange={onVariableForUsedSearchModeSelect}
            usageType='set'
            isMulti={false}
            isCreatable
            isClearable
            selectedVariableIds={varIdUsedSearchMode ? [varIdUsedSearchMode] : []}
          />
        </div>

        <div className={classes.varSelectionContainer}>
          <div className={classes.inputWithInfo}>
            <Typography>Variable zum Speichern der Antwort</Typography>
            <CustomContentTooltip
              content={
                <Typography>
                  Bite wählen sie eine Variable, in der die Antwort des Aleph Alpha Service gespeichert werden soll.
                </Typography>
              }
              elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
            />
          </div>
          <VariablesAutosuggestSelect
            chart={chart}
            onChange={onVariableForReplySelect}
            usageType='set'
            isMulti={false}
            isCreatable
            isClearable
            selectedVariableIds={varIdReply ? [varIdReply] : []}
          />
        </div>
        <div className={classes.varSelectionContainer}>
          <div className={classes.inputWithInfo}>
            <Typography>Variable zum Speichern der Optionen</Typography>
            <CustomContentTooltip
              content={
                <Typography>
                  Bitte wählen Sie eine Variable, in der die möglichen Optionen zum Verfeinern der Anfrage gespeichert
                  werden sollen.
                </Typography>
              }
              elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
            />
          </div>
          <VariablesAutosuggestSelect
            chart={chart}
            onChange={onVariableForOptionTextsSelect}
            usageType='set'
            isMulti={false}
            isCreatable
            isClearable
            selectedVariableIds={varIdOptionTexts ? [varIdOptionTexts] : []}
          />
        </div>

        <div className={classes.varSelectionContainer}>
          <div className={classes.inputWithInfo}>
            <Typography>Variable zum Speichern der Dokumentobjecte</Typography>
            <CustomContentTooltip
              content={
                <Typography>
                  Bitte wählen sie eine Variable, in der die möglichen Dokumentobjecte (Titel + Link) gespeichert werden
                  soll.
                </Typography>
              }
              elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
            />
          </div>
          <VariablesAutosuggestSelect
            chart={chart}
            onChange={onVariableForDocumentsSelect}
            usageType='set'
            isMulti={false}
            isCreatable
            isClearable
            selectedVariableIds={varIdDocuments ? [varIdDocuments] : []}
          />
        </div>
        <div className={classes.varSelectionContainer}>
          <div className={classes.inputWithInfo}>
            <Typography>
              Variable zum Speichern der <i>isAnswered</i>-Flag
            </Typography>
            <CustomContentTooltip
              content={
                <Typography>
                  Bitte wählen sie eine Variable, in der die <i>isAnswered</i>-Flag gespeichert werden soll. Die
                  <i>isAnswered</i>-Flag zeigt an, ob die Antwort des Aleph Alpha Service eine tatsächliche Antwort oder
                  nur eine Rückfrage ist.
                </Typography>
              }
              elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
            />
          </div>
          <VariablesAutosuggestSelect
            chart={chart}
            onChange={onVariableForIsAnsweredSelect}
            usageType='set'
            isMulti={false}
            isCreatable
            isClearable
            selectedVariableIds={varIdIsAnswered ? [varIdIsAnswered] : []}
          />
        </div>
        <div className={classes.varSelectionContainer}>
          <div className={classes.inputWithInfo}>
            <Typography>
              Variable zum Speichern der <i>isSmalltalk</i>-Flag
            </Typography>
            <CustomContentTooltip
              content={
                <Typography>
                  Bitte wählen sie eine Variable, in der die <i>isSmalltalk</i>-Flag gespeichert werden soll. Die
                  <i>isSmalltalk</i>-Flag zeigt an, ob es sich bei der Interaktion um Smalltalk oder eine Anfrage
                  handelt.
                </Typography>
              }
              elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
            />
          </div>
          <VariablesAutosuggestSelect
            chart={chart}
            onChange={onVariableForIsSmalltalkSelect}
            usageType='set'
            isMulti={false}
            isCreatable
            isClearable
            selectedVariableIds={varIdIsSmalltalk ? [varIdIsSmalltalk] : []}
          />
        </div>
        <div className={classes.varSelectionContainer}>
          <div className={classes.inputWithInfo}>
            <Typography>Variable zum Speichern der Correlation ID</Typography>
            <CustomContentTooltip
              content={
                <Typography>
                  Bitte wählen sie eine Variable, in der die Feedback-ID gespeichert werden soll. Die Feedback-ID wird
                  verwendet, um gegebenen Antworten Feedback zuordnen zu können.
                </Typography>
              }
              elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
            />
          </div>
          <VariablesAutosuggestSelect
            chart={chart}
            onChange={onVariableForCorrIdSelect}
            usageType='set'
            isMulti={false}
            isCreatable
            isClearable
            selectedVariableIds={varIdCorrId ? [varIdCorrId] : []}
          />
        </div>
      </div>
    </div>
  )
}
