import React, { useState, useEffect } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'

import { Searchfield } from 'components/TextInput/Searchfield'
import ContentPage, { ContentPageHeader } from 'components/Page/ContentPage'

import { Chart, Node } from '../../../@types/Flowchart/types'
import { NODE_NAMES, CARD_TYPE_NAMES, ROUTE_BOTS, ROUTE_BOTID_DESIGNER } from 'utils/constants'
import { TranslationFile } from '../../../@types/Translations/types'

import { cloneDeep } from 'lodash'
import { useFlowdesignerContext } from 'hooks/contexts/flowdesigner-context'
import { useNodeSearch } from 'hooks/search/useNodeSearch'
import NodeSearchResult from './NodeSearchResult'

// extend Node type for better search
type EnhancedNode = Node & {
  cardTypeName?: string
  nodeTypeName?: string
}

/**
 * Enhances nodes for better searchability.
 * Adds aliases for card type and node type.
 * @param nodes
 */
function enhanceNodesForSearch(nodes: Node[]): EnhancedNode[] {
  const enhancedNodes: EnhancedNode[] = []
  for (const node of nodes) {
    const eNode = cloneDeep(node) as EnhancedNode
    eNode.nodeTypeName = NODE_NAMES[eNode.type]
    if (eNode.properties.cardType) eNode.cardTypeName = CARD_TYPE_NAMES[eNode.properties.cardType]
    enhancedNodes.push(eNode)
  }
  return enhancedNodes
}

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search)
}

export type NodeSearchProps = {
  chart: Chart
  translationFile: TranslationFile
  onNodeSelect: (nodeId: string) => void
}

export default function NodeSearch({ chart, translationFile, onNodeSelect }: NodeSearchProps): React.ReactElement {
  const { botId } = useParams()
  const navigate = useNavigate()
  const query = useQuery()
  const { setSearchNodeSelection } = useFlowdesignerContext()

  const [searchString, setSearchString] = useState<string>('')
  const [allNodes, setAllNodes] = useState<EnhancedNode[]>([])

  const { hits: searchResults, onSearch: updateSearchString } = useNodeSearch(allNodes)

  function onSearchStringChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const newSearchString = event.target.value
    updateSearchString(newSearchString)
    setSearchString(newSearchString)
  }

  function onNodeClick(nodeId: string): void {
    const node = chart.nodes[nodeId]
    const hasDialogChanged = chart.activeDialog !== node.properties.dialog
    setSearchNodeSelection(nodeId, hasDialogChanged, node.properties.dialog)
    onNodeSelect(nodeId)
    navigate(ROUTE_BOTS + '/' + botId + ROUTE_BOTID_DESIGNER)
  }

  // if the url query has a search string set
  useEffect(() => {
    const searchQuery = query.get('q')
    if (searchQuery) {
      updateSearchString(decodeURIComponent(searchQuery))
      setSearchString(decodeURIComponent(searchQuery))
    }
  }, [])

  useEffect(
    function () {
      const nodes = Object.values(chart.nodes)
      setAllNodes(nodes)
    },
    [chart],
  )

  return (
    <ContentPage>
      <ContentPageHeader
        title='Blocksuche'
        actions={[<Searchfield key='search-field' autoFocus value={searchString} onChange={onSearchStringChange} />]}
        previousUrl={ROUTE_BOTS + '/' + botId + ROUTE_BOTID_DESIGNER}
      />
      <NodeSearchResult
        chart={chart}
        translationFile={translationFile}
        nodes={searchResults}
        onNodeClick={onNodeClick}
      />
    </ContentPage>
  )
}

// export default function NodeSearch(): React.ReactElement {
//   return <div />
// }
