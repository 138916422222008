import React, { useState, useEffect } from 'react'
import { makeStyles } from 'tss-react/mui'

// @mui/material components
import { TextField, Typography, IconButton } from '@mui/material'
import VariablesAutosuggestSelect from '../Variables/VariablesAutosuggestSelect'

import {
  getAllVariablesForSelect,
  replaceVarDisplayNameWithId,
  replaceVarIdWithDisplayName,
} from '../../../../../utils/chartUtils'

import { Chart } from '../../../../../@types/Flowchart/types'
import { SelectDropdown, ActionMeta, Option } from 'components/Dropdown'

const useStyles = makeStyles()({
  selectedDialogContainer: {},
  title: {},
  marginTopSmall: { marginTop: '10px' },
  tmpVariableContainer: { width: '100%', display: 'flex' },
})

type SQnAAnswerProps = {
  chart: Chart
  setStateCallback: (chart: Chart) => void
}

// IDEA: Stefan 30.01.20: show dropdown menu with all possible variables in textfield when % is entered and set variable
// IDEA: Stefan 30.01.20: Validate Variable names and set error if incorrect variable is referenced
// IDEA: Stefan 30.01.20: Simplify UX by not displaying the chars ', +, etc. but keep them in the chart; make it somewhat dynamic
export default function SQnAAnswer({ chart, setStateCallback }: SQnAAnswerProps): React.ReactElement {
  const { classes } = useStyles()
  const [allVariablesOptions, setAllVariableOptions] = useState<Option[]>([])
  const [question, setQuestion] = useState<string>('') // question string with ids of variables
  const [tmpVariableOption, setTmpVariableOption] = useState<Option>()

  /**
   * Handles variable value textfield change
   */
  function onTextChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setQuestion(event.target.value)
  }

  /**
   * Handles change in tmp variable dropdown.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function onTmpVariableChange(newValue: Option, action: ActionMeta<Option>): void {
    setTmpVariableOption(newValue)
  }

  /**
   * Takes the displayed value and transforms it into the correct form (var ids instead of display name)
   * and puts it into the chart.
   */
  function setValueToChart(): void {
    if (typeof chart.selected?.id === 'undefined') return

    const value = replaceVarDisplayNameWithId(chart, question)

    chart.nodes[chart.selected.id].properties.question = value
    chart.nodes[chart.selected.id].properties.text = 'Frage: ' + question
    setStateCallback(chart)
  }

  function onAddTmpVariableToValue(): void {
    if (typeof tmpVariableOption === 'undefined') return

    let newVariableValueText = question

    let value = ''
    if (newVariableValueText !== '') value += newVariableValueText + "' "
    value += `'%${chart.variables[tmpVariableOption.value].displayName}'`
    newVariableValueText = value

    setQuestion(newVariableValueText)
    setTmpVariableOption(undefined)
    setValueToChart()
  }

  /**
   * Callback function for variable selection.
   */
  function onVariableChange(chart: Chart, prevSelectedVarIds: string[], selectedVarIds: string[]): void {
    if (typeof chart.selected?.id === 'undefined') return

    if (selectedVarIds.length >= prevSelectedVarIds.length) {
      // variable was selected
      // get selected var ids
      const newlySelectedIds = selectedVarIds.filter((id) => !prevSelectedVarIds.includes(id))
      for (const varId of newlySelectedIds) {
        chart.nodes[chart.selected.id].properties.answer = varId
      }
    } else {
      // variable was removed
      // get removed var ids
      const removedIds = prevSelectedVarIds.filter((id) => !selectedVarIds.includes(id))
      for (const varId of removedIds) {
        chart.nodes[chart.selected.id].properties.answer = ''
      }
    }
    setStateCallback(chart)
  }

  useEffect(function () {
    if (!chart.selected?.id) return
    const allVariableOptions = getAllVariablesForSelect(chart)
    setAllVariableOptions(allVariableOptions)

    const value = chart.nodes[chart.selected.id].properties.question
    if (value) {
      const displayValue = replaceVarIdWithDisplayName(chart, value)
      setQuestion(displayValue)
    }

    // const variables = getVariables(chart)
    // setVariables(variables)
    // if (
    //   variables.length > 0 &&
    //   typeof chart.selected?.id !== 'undefined' &&
    //   typeof chart.nodes[chart.selected.id].properties.setVarValues !== 'undefined'
    // ) {
    //   const setVarValues = chart.nodes[chart.selected.id].properties.setVarValues
    //   if (typeof setVarValues !== 'undefined') {
    //     const setVarValue = setVarValues[variables[0].value]
    //     const question = replaceVarIdWithDisplayName(chart, setVarValue)
    //     setVariableValueText(question)
    //   }
    // }
  }, [])

  // useEffect(
  //   function () {
  //     const allVariableOptions = getAllVariablesForSelect(chart).filter(
  //       (varOption) => !variables.some((v: DisplayVariableOption) => v.value === varOption.value)
  //     )
  //     setAllVariableOptions(allVariableOptions)
  //   },
  //   [variables]
  // )

  return (
    <div className={classes.selectedDialogContainer}>
      <Typography>
        {`Mit dem 'Frage beantworten Block' können Sie eine Variable, einen Text oder eine Mischung aus beiden
            nutzen, um einen passenden Eintrag in der Wissensdatenbank für diesen Wert zu finden. Bitte geben Sie die
            Frage an, für den ein passender Eintrag in der Wissensdatenbank gesucht werden soll.`}
      </Typography>
      <TextField
        id='variable-value-textfield'
        label='Frage'
        value={question}
        fullWidth
        maxRows={5}
        onChange={onTextChange}
        onBlur={setValueToChart}
        margin='normal'
      />
      <div className={classes.tmpVariableContainer}>
        <SelectDropdown
          onChange={onTmpVariableChange}
          options={allVariablesOptions}
          selected={tmpVariableOption}
          isSearchable
          placeholder='Variable auswählen'
          width='100%'
        />

        <IconButton disabled={typeof tmpVariableOption === 'undefined'} onClick={onAddTmpVariableToValue}>
          <i className={`ri-add-line`} />
        </IconButton>
      </div>
      <Typography style={{ marginTop: '1rem' }}>Variable in der die Antwort gespeichert werden soll:</Typography>
      <div className={classes.marginTopSmall}>
        <VariablesAutosuggestSelect chart={chart} onChange={onVariableChange} usageType='set' />
      </div>
    </div>
  )
}
