/**
 * ANSWERED QUESTION RATIO CHART
 * Custom tooltip formatter function for showing the total number of question as context to answered ratio
 * @param value
 * @param opts
 */
export function formatAnsweredQuestionRatioChartTooltipValue(val: number, opts?: any): string {
  const totalNumberQuestions = opts?.w.config.series[opts?.seriesIndex].data[opts.dataPointIndex].total_number_questions

  return `${totalNumberQuestions !== 0 ? val.toString() : '-'} % ${
    typeof totalNumberQuestions !== 'undefined'
      ? '(von ' + totalNumberQuestions + (totalNumberQuestions === 1 ? ' Frage' : ' Fragen') + ')'
      : ''
  }`
}

/**
 * HELPFUL ANSWER RATIO CHART
 * Custom tooltip formatter function for showing the total number of answers with feedback as context to helpful ratio
 * @param value
 * @param opts
 */
export function formatHelpfulAnswerRatioChartTooltipValue(val: number, opts?: any): string {
  const totalNumberFeedbackedAnswers =
    opts?.w.config.series[opts?.seriesIndex].data[opts.dataPointIndex].total_feedbacked_answers

  return `${totalNumberFeedbackedAnswers !== 0 ? val.toString() : '-'} % ${
    typeof totalNumberFeedbackedAnswers !== 'undefined'
      ? '(von ' + totalNumberFeedbackedAnswers + (totalNumberFeedbackedAnswers === 1 ? ' Antwort' : ' Antworten') + ')'
      : ''
  }`
}
