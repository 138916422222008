import React from 'react'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  normal: {
    fontSize: '32px',
    color: theme.palette.grey[700],
  },
}))

export default function Neutral(): React.ReactElement {
  const { classes } = useStyles()
  return <i className={'ri-emotion-normal-line ' + classes.normal} />
}
