import React, { useState, useEffect } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useTheme } from '@mui/material/styles'

// @mui/material components
import { TextField, Typography, IconButton, Divider } from '@mui/material'
import VariablesAutosuggestSelect from '../Variables/VariablesAutosuggestSelect'
import { Option } from '../../../../../components/Dropdown/Dropdown'

import {
  getVariables,
  replaceVarDisplayNameWithId,
  replaceVarIdWithDisplayName,
  removeVariableFromNode,
  getAllVariablesForSelect,
} from '../../../../../utils/chartUtils'

import { Chart, DisplayVariableOption } from '../../../../../@types/Flowchart/types'
import { ActionMeta, SelectDropdown } from 'components/Dropdown'
import CustomizedTooltip from 'components/Tooltips/CustomContentTooltip'
import { cloneDeep } from 'lodash'
import { Button } from 'components/Buttons'

const useStyles = makeStyles()((theme) => ({
  selectedDialogContainer: {},
  title: {},
  row: { display: 'flex' },
  divider: { marginTop: theme.spacing(4), marginBottom: theme.spacing(4) },
  marginTopSmall: { marginTop: theme.spacing(1) },
  marginTopLarge: { marginTop: theme.spacing(3) },
  tmpVariableContainer: { width: '100%', display: 'flex' },
  showHideTmpVariableDropdown: {},

  tmpVariableDropdown: {
    display: 'flex',
    // flex: 1,
    overflow: 'hidden',
    width: 0,
    transition: 'width 0.5s ease-in-out',
  },
  slideOut: {
    width: '100%',
  },
  deleteButton: { marginLeft: 'auto', marginTop: 'auto', marginBottom: 'auto' },
}))

type VariableValues = { [varId: string]: string }

type SSetVariableProps = {
  chart: Chart
  setStateCallback: (chart: Chart) => void
  setIsSaveDisabled: (disabled: boolean) => void
}

// TODO: let user change order
export default function SSetVariable({
  chart,
  setStateCallback,
  setIsSaveDisabled,
}: SSetVariableProps): React.ReactElement {
  const { classes } = useStyles()
  const theme = useTheme()
  // const [chart, setChart] = useState<Chart>(_chart)
  const [allVariablesOptions, setAllVariableOptions] = useState<DisplayVariableOption[]>([])
  const [variableValueTexts, _setVariableValueTexts] = useState<VariableValues>({})
  const [order, setOrder] = useState<string[]>([])
  const [tmpVariableOptions, setTmpVariableOptions] = useState<{ [varId: string]: Option | undefined }>({})
  const [showTempVariableDropdownForVarId, setShowTempVariableDropdownForVarId] = useState<string>()

  /**
   * Sets variable values to chart object.
   * Replaces variable display names with their id.
   * @param variableValues
   */
  function setVariableValuesToChart(variableValues: VariableValues, chart: Chart): void {
    if (typeof chart.selected?.id === 'undefined') return
    const valuesForChart = { ...variableValues }
    for (const varId of Object.keys(valuesForChart)) {
      valuesForChart[varId] = replaceVarDisplayNameWithId(chart, valuesForChart[varId])
    }
    chart.nodes[chart.selected.id].properties.setVariables = {
      order: order,
      setVars: valuesForChart,
    }
    setStateCallback(cloneDeep(chart))
  }

  /**
   * Wrapper function around the state update and also the chart update.
   * @param newVariableValueTexts
   */
  function setVariableValueTexts(newVariableValueTexts: VariableValues, chart: Chart): void {
    _setVariableValueTexts(newVariableValueTexts)
    setVariableValuesToChart(newVariableValueTexts, chart)
    if (Object.keys(newVariableValueTexts).length > 0) setIsSaveDisabled(false)
  }

  /**
   * Handles variable value textfield change
   * @param varId id of the variable whose value is changed
   * @param event actual change event
   */
  function onValueChange(varId: string, event: React.ChangeEvent<HTMLInputElement>): void {
    const newValueTexts = { ...variableValueTexts, [varId]: event.target.value }
    setVariableValueTexts(newValueTexts, chart)
  }

  /**
   * Handles change in tmp variable dropdown.
   * @param varId id of the variable whose tmp variable has changed
   * @param newValue new value of selected tmp variable
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function onTmpVariableChange(varId: string, newValue: Option, action: ActionMeta<Option>): void {
    setTmpVariableOptions({ ...tmpVariableOptions, [varId]: newValue })
  }

  /**
   * Appends selected variable to value
   * @param varId id of the variable to whose value the tmp variable should be added
   * @returns
   */
  function onAddTmpVariableToValue(varId: string): void {
    if (typeof tmpVariableOptions[varId] === 'undefined') return

    variableValueTexts[varId] =
      variableValueTexts[varId] + `%${chart.variables[tmpVariableOptions[varId]?.value].displayName}`

    setTmpVariableOptions({ ...tmpVariableOptions, [varId]: undefined })
    setVariableValueTexts({ ...variableValueTexts }, chart)
  }

  /**
   * Handles delete button click.
   * - removes variable from node
   * - removes variable and value from setVarvalues
   * - removes variable from order
   * @param varId
   */
  function onDelete(varId: string): void {
    if (!chart.selected.id) return
    const updatedChart = removeVariableFromNode(chart, 'set', varId, chart.selected.id)
    delete variableValueTexts[varId]
    const newOrder = order.filter((variableId) => varId !== variableId)
    setVariableValueTexts({ ...variableValueTexts }, updatedChart)
    setOrder(newOrder)
  }

  /**
   * Callback function for variable selection.
   */
  function onVariableChange(chart: Chart, prevSelectedVarIds: string[], selectedVarIds: string[]): void {
    // can at most be one
    const removedVarIds = prevSelectedVarIds.filter((varId) => !selectedVarIds.includes(varId))
    const newVarIds = selectedVarIds.filter((varId) => !prevSelectedVarIds.includes(varId))

    const removedVarId = removedVarIds.length > 0 ? removedVarIds[0] : ''
    const newVarId = newVarIds.length > 0 ? newVarIds[0] : ''
    const value = variableValueTexts[removedVarId]

    const newVariableValueTexts = { ...variableValueTexts, [newVarId]: value }
    delete newVariableValueTexts[removedVarId]

    // if initial select (no var was selected beforehand, add it at last position to order)
    if (prevSelectedVarIds.length === 0) {
      order.push(newVarId)
      setOrder([...order])
    }
    setVariableValueTexts(newVariableValueTexts, chart)
  }

  useEffect(function () {
    // initialize everything
    const variables = getAllVariablesForSelect(chart)
    setAllVariableOptions(variables)
    if (
      variables.length > 0 &&
      typeof chart.selected?.id !== 'undefined' &&
      typeof chart.nodes[chart.selected.id].properties.setVariables !== 'undefined'
    ) {
      const setVarValues = chart.nodes[chart.selected.id].properties.setVariables
      if (typeof setVarValues !== 'undefined') {
        const variableValuesTexts: VariableValues = {}
        for (const varId of Object.keys(setVarValues.setVars)) {
          variableValuesTexts[varId] = replaceVarIdWithDisplayName(chart, setVarValues.setVars[varId])
        }
        setVariableValueTexts(variableValuesTexts, chart)
        setOrder(setVarValues.order)
      }
    }
  }, [])

  return (
    <div className={classes.selectedDialogContainer}>
      <div>
        <div style={{ width: '100%', display: 'flex' }}>
          <Typography>
            Erstellen oder wählen Sie die Variablen deren Wert gesetzt werden soll. Beachten Sie dabei, dass die
            Variablen in der Reihenfolge, in der sie hier angegeben werden gesetzt werden.
          </Typography>
        </div>
        {Object.keys(variableValueTexts).map((variableId) => {
          return (
            <>
              <Divider className={classes.divider} />
              <div style={{ display: 'flex' }}>
                <Typography style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                  Variable deren Wert gesetzt werden soll
                </Typography>
                <CustomizedTooltip
                  elements={
                    <IconButton
                      className={classes.deleteButton}
                      onClick={(e: React.MouseEvent): void => {
                        e.preventDefault()
                        e.stopPropagation()
                        onDelete(variableId)
                      }}
                    >
                      <i className='ri-delete-bin-4-line'></i>
                    </IconButton>
                  }
                  content={<Typography>Löschen</Typography>}
                />
              </div>
              <div className={classes.marginTopSmall}>
                <VariablesAutosuggestSelect
                  chart={chart}
                  onChange={(chart: Chart, prevSelectedVarIds: string[], selectedVarIds: string[]): void =>
                    onVariableChange(chart, prevSelectedVarIds, selectedVarIds)
                  }
                  usageType='set'
                  isMulti={false}
                  allowMultiSelectWithinNode={false} // each variable can at most be set one time within the node!
                  selectedVariableIds={[variableId]}
                />
              </div>
              {variableId !== '' && (
                <React.Fragment>
                  <Typography style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                    Legen Sie den Wert fest auf den die gewählte Variable gesetzt werden soll. Werte andere Variablen
                    können mit <em>%varId</em> genutzt werden.
                  </Typography>

                  <TextField
                    id='variable-value-textfield'
                    label='Wert der in der Variablen gespeichert wird'
                    value={variableValueTexts[variableId] ?? ''}
                    fullWidth
                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => onValueChange(variableId, event)}
                    // onBlur={() => {
                    //   console.log('On BLUR')
                    //   setVariableValueTexts(variableValueTexts)
                    // }}
                    margin='normal'
                  />
                  <div className={classes.tmpVariableContainer}>
                    <div className={classes.showHideTmpVariableDropdown}>
                      <div style={{ width: '250px' }}>
                        <Button
                          variant='secondary'
                          onClick={(): void => {
                            if (showTempVariableDropdownForVarId === variableId)
                              setShowTempVariableDropdownForVarId(undefined)
                            else setShowTempVariableDropdownForVarId(variableId)
                          }}
                        >
                          Variable zu Wert hinzufügen
                        </Button>
                      </div>
                    </div>
                    <div
                      className={`${classes.tmpVariableDropdown} ${
                        showTempVariableDropdownForVarId === variableId ? classes.slideOut : ''
                      }`}
                    >
                      <SelectDropdown
                        onChange={(newValue: Option, action: ActionMeta<Option>): void =>
                          onTmpVariableChange(variableId, newValue, action)
                        }
                        options={allVariablesOptions}
                        selected={tmpVariableOptions[variableId]}
                        isSearchable
                        placeholder='Variable auswählen'
                        width='100%'
                      />

                      <IconButton
                        disabled={typeof tmpVariableOptions[variableId] === 'undefined'}
                        onClick={(): void => onAddTmpVariableToValue(variableId)}
                      >
                        <i className={`ri-add-line`} />
                      </IconButton>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </>
          )
        })}
        <div style={{ textAlign: 'center', marginTop: theme.spacing(3) }}>
          <Button
            disabled={Object.keys(setVariableValueTexts).includes('')}
            onClick={(): void => {
              const newVariableValueTexts = { ...variableValueTexts, '': '' }
              setIsSaveDisabled(true)
              setVariableValueTexts(newVariableValueTexts, chart)
            }}
            variant='primary'
          >
            Hinzufügen
          </Button>
        </div>
      </div>
    </div>
  )
}
