import React, { ReactElement } from 'react'

// @mui/material components
import { makeStyles } from 'tss-react/mui'

import { Theme } from '@mui/material/styles'
import Icon from '@mui/material/Icon'

import { NodeType, NodeProperties, Port } from '../../../../@types/Flowchart/types'
import { FLOWDESIGNER_DATA_STRING } from 'utils/constants'
import { SvgIconComponent } from '@mui/icons-material'
import { useFlowdesignerContext } from 'hooks/contexts/flowdesigner-context'
import { useLockingContext } from 'hooks/contexts/locking-context'

type styleProps = {
  color?: string
}

// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. ArrowFunctionExpression in CSS prop.
const useStyles = makeStyles<styleProps>()((theme, props) => ({
  sidebarItemOuter: {
    fontSize: '14px',
    // color: 'white',
    cursor: 'move',
    position: 'relative',
    // margin: '10px',
    marginBottom: '15px',
    // paddingBottom: '1px',
    // border: '1px solid rgba(60,72,88,0.1)',
    // overflow: 'hidden',
    // border: '1px solid black',
    // background: '#3c4858 !important',
    borderRadius: '5px',
    // boxShadow: '2px 4px 4px 0px rgba(0,0,0,0.1)',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '-10px',
      left: '-10px',
      right: '-10px',
      bottom: '-10px',
      zIndex: -1,
      background: theme.palette.grey.A100,
      opacity: 1,
      WebkitFilter: 'blur(15px)',
      filter: 'blur(15px)',
      borderRadius: `${
        typeof theme.shape.borderRadius === 'string'
          ? parseInt(theme.shape.borderRadius) * 3
          : theme.shape.borderRadius * 3
      }px`,
    },
  },
  sidebarItemInner: {
    padding: '10px 18px',
    // margin: '2px !important',
    fontSize: '14px',
    background: theme.palette.background.paper,
    // color: 'rgba(60,72,88,1)',
    // borderRadius: '4px',
    // border: '2px solid transparent',
    alignItems: 'center',
    boxSizing: 'border-box',
    wordWrap: 'break-word',
    borderRadius: `0 0 ${
      typeof theme.shape.borderRadius === 'string' ? parseInt(theme.shape.borderRadius) : theme.shape.borderRadius
    }px ${
      typeof theme.shape.borderRadius === 'string' ? parseInt(theme.shape.borderRadius) : theme.shape.borderRadius
    }px`,
    display: 'flex',
    justifyContent: 'space-between',
  },
  sidebarItemColorBar: {
    width: '100%',
    height: '10px',
    borderRadius: `${
      typeof theme.shape.borderRadius === 'string' ? parseInt(theme.shape.borderRadius) : theme.shape.borderRadius
    }px ${
      typeof theme.shape.borderRadius === 'string' ? parseInt(theme.shape.borderRadius) : theme.shape.borderRadius
    }px 0 0`,
    backgroundColor: props?.color ? props.color : theme.palette.info.main,
  },
  sidebarItemIcon: {
    verticalAlign: 'bottom',
    fontSize: 20,
    display: 'inline-block',
    float: 'right',
    color: '#3c4858',
  },
  sidebarItemContent: {
    height: '100%',
    display: 'inline-block',
  },
}))

type EditorSidebarItemProps = {
  type: NodeType
  ports: {
    [portId: string]: Port
  }
  properties: NodeProperties
  color?: string
  Icon: SvgIconComponent
}

export default function EditorSidebarItem({
  type,
  ports,
  properties,
  color,
  Icon,
}: EditorSidebarItemProps): ReactElement {
  const { canIEditFlowdesigner } = useFlowdesignerContext()
  const { lockState } = useLockingContext()
  const { classes } = useStyles({ color })

  return (
    <div
      className={classes.sidebarItemOuter}
      draggable={lockState === 'canEdit' && canIEditFlowdesigner}
      onDragStart={(event): void => {
        event.dataTransfer.setData(FLOWDESIGNER_DATA_STRING, JSON.stringify({ type, ports, properties }))
        event.dataTransfer.effectAllowed = 'move'
      }}
    >
      <div>
        {typeof color !== 'undefined' && <div className={classes.sidebarItemColorBar} />}

        <div className={classes.sidebarItemInner}>
          <div className={classes.sidebarItemContent}>{properties.typeText}</div>
          <Icon className={classes.sidebarItemIcon} />
        </div>
      </div>
    </div>
  )
}
