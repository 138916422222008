import { Message } from '@mui/icons-material'
import React, { ReactElement } from 'react'
import EditorSidebarItem from '../editorSidebarItem'
import { FLOWDESIGNER_NODE_COLOR_TRIGGER } from 'utils/constants'

export default function trigger(): ReactElement {
  return (
    <EditorSidebarItem
      type='trigger/intent'
      ports={{
        port1: {
          id: 'port1',
          type: 'right',
          // position: { x: 0, y: 0 },
        },
      }}
      properties={{
        typeText: 'Trigger',
        text: 'Nicht konfiguriert',
        dialog: '',
      }}
      Icon={Message}
      color={FLOWDESIGNER_NODE_COLOR_TRIGGER}
    />
  )
}
