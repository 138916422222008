import React, { useState } from 'react'
import { v4 as uuid } from 'uuid'
import { ArrowForward, Check } from '@mui/icons-material'

import { Dialog } from '../../../../../components/Dialogs'
import { Button } from '../../../../../components/Buttons'
import { AnswerAction, AnswerType, IntentTriggerButtons } from '../../../../../@types/Knowledge/types'
import {
  Answer,
  AnswerWithoutIntent,
  // buildTriggerAnswer,
  // TriggerAnswer,
  // TriggerAnswerObject,
  TriggerAnswerWithoutIntent,
} from '../../../../../classes/Knowledge'

import Step0AnswerType from './Step0AnswerType'
import { StepAnswerSummary } from './AnswerCreationSteps'
import { StepTopic, StepTitle, StepUtterances, StepAnswer, StepSimilarAnswers } from './SharedSteps'

import { KNOWLEDGE_MIN_UTTERANCES, MODULE_ID_CONVAISE_NLU } from 'utils/constants'
import { useBotContext } from 'hooks/contexts/bot-context'
import { Step1Dialog, StepTriggerDialogButtons, StepTriggerSummary } from './TriggerCreationSteps'
import { NLUModule } from '../../../../../@types/BotInformation/types'

/**
 * Returns title to display in the dialog base on answerType and stepnumber.
 * @param answerType
 * @param step
 */
function getTitle(answerType: AnswerType, step: number, isWebchatV4Bot: boolean): string {
  if (answerType === 'answer') {
    const nrSteps = 6
    const progress = `(${step}/${nrSteps}) `
    switch (step) {
      case 1:
        return progress + 'Thema auswählen'
      case 2:
        return progress + 'Titel erstellen'
      case 3:
        return progress + 'Beispielfragen erstellen'
      case 4:
        return progress + 'Ähnliche Antworten'
      case 5:
        return progress + 'Antworttext'
      // case 6:
      //   return progress + 'Folgethemen (optional)'
      case 6:
        return progress + 'Zusammenfassung'
    }
  } else if (answerType === 'trigger') {
    const nrSteps = isWebchatV4Bot ? 8 : 5
    let displayStep = step
    if (!isWebchatV4Bot) {
      // we need to adjust displayed step as old v2 bot trigger answers do not have title, answertext and buttontext
      if (step === 4) displayStep = 3
      else if (step === 5) displayStep = 4
      else if (step === 8) displayStep = 5
    }
    const progress = `(${displayStep}/${nrSteps}) `
    switch (step) {
      case 1:
        return progress + 'Thema auswählen'
      case 2:
        return progress + 'Dialog auswählen'
      case 3:
        return progress + 'Titel erstellen'
      case 4:
        return progress + 'Beispieläußerungen erstellen'
      case 5:
        return progress + 'Ähnliche Antworten'
      case 6:
        return progress + 'Antworttext'
      case 7:
        return progress + 'Buttontext'
      case 8:
        return progress + 'Zusammenfassung'
    }
  }
  return ''
}

type CreateAnswerButtonProps = {
  step: number
  finalStep: number
  onFinalClick: () => void
  onClick: () => void
  disabled: boolean
}

function CreateAnswerButton({
  step,
  finalStep,
  onFinalClick,
  onClick,
  disabled,
}: CreateAnswerButtonProps): React.ReactElement {
  return step < finalStep ? (
    <Button type='normal' size='small' icon={<ArrowForward />} onClick={onClick} disabled={disabled}>
      Weiter
    </Button>
  ) : (
    <Button type='success' size='small' icon={<Check />} onClick={onFinalClick} disabled={disabled}>
      Erstellen
    </Button>
  )
}

export type SimilarAnswerState = 'ok' | 'overwrite' | 'similarExists'

type CreateAnswerDialogProps = {
  answers: Answer[]
  existingUtterance?: string
  onClose: () => void
  onCreateAnswer: (answer: AnswerWithoutIntent | TriggerAnswerWithoutIntent) => void
  topicPath?: string
  topicsWithoutAnswers?: string[] // topics that have no answers need to be collected since they cannot be extracted from the answers but should be available in the choose topic screen
  botId: string
}

export default function CreateAnswerDialog({
  answers,
  existingUtterance,
  onClose,
  onCreateAnswer,
  topicPath,
  topicsWithoutAnswers = [],
  botId,
}: CreateAnswerDialogProps): React.ReactElement {
  const { bot } = useBotContext()
  const finalStepAnswer = 6
  const finalStepTrigger = 8
  const [step, setStep] = useState<number>(topicPath === 'Trigger' ? 1 : topicPath ? 2 : 0) // if a topic path is given, the answer type is also given

  // step 0
  const [answerType, setAnswerType] = useState<AnswerType>(
    typeof topicPath !== undefined && topicPath !== ''
      ? topicPath?.includes('Trigger')
        ? 'trigger'
        : 'answer'
      : 'answer',
  ) // if a given topicPaht inlcudes Trigger we know that the type is trigger
  // data
  // answer step 1: topic
  const [topic, setTopic] = useState<string>(topicPath ?? '')
  const [dialogName, setDialogName] = useState<string>('') // only trigger answer
  const [intentTriggerButtons, setIntentTriggerButtons] = useState<IntentTriggerButtons>({
    startDialogButton: '',
  }) // only v4 trigger answer
  const [title, setTitle] = useState<string>('')
  const [utterances, setUtterances] = useState<string[]>(existingUtterance ? [existingUtterance] : [])
  const [similarAnswersState, setSimilarAnswersState] = useState<SimilarAnswerState>() // ok: no similiar answer, overwrite: user wants to continue anyway
  const [answerText, setAnswerText] = useState<string>('')
  // step 6 - follow-up questions
  const [followUpQuestions, setFollowUpQuestions] = useState<AnswerAction[]>([])

  // this controls if the answer gets a title and text
  // in webchat v4 trigger answers also have text, in v2 not.
  const hasContent = answerType === 'answer' || bot?.webchatVersion === 'v4'

  /**
   * Used to determine state of the next button.
   * Implements logic for each step, i.e. which values have to be set to continue to next step.
   * @returns
   */
  function isNextButtonDisabled(): boolean {
    if (answerType === 'answer') {
      switch (step) {
        case 1:
          return !topic
        case 2:
          return !title
        case 3:
          return utterances.length < KNOWLEDGE_MIN_UTTERANCES
        case 4:
          return !(similarAnswersState === 'ok' || similarAnswersState === 'overwrite')
        case 5:
          return !answerText
        // case 6:
        //   return false
        case 6:
          !(topic && title && utterances.length >= KNOWLEDGE_MIN_UTTERANCES && answerText)
      }
    } else if (answerType === 'trigger') {
      switch (step) {
        case 1:
          return !topic
        case 2:
          return !dialogName
        case 3:
          return !title
        case 4:
          return utterances.length < KNOWLEDGE_MIN_UTTERANCES
        case 5:
          return !(similarAnswersState === 'ok' || similarAnswersState === 'overwrite')
        case 6:
          return !answerText
        case 7:
          return !intentTriggerButtons
        case 8: {
          if (bot?.webchatVersion === 'v4') {
            return !(
              dialogName &&
              utterances.length >= KNOWLEDGE_MIN_UTTERANCES &&
              dialogName &&
              title &&
              intentTriggerButtons
            )
          } else {
            // only v2 bot
            return !(dialogName && utterances.length >= KNOWLEDGE_MIN_UTTERANCES)
          }
        }
      }
    }
    return false
  }

  /**
   * Handles back button click. Goes to previous step.
   */
  function onPreviousStep(): void {
    if (answerType === 'trigger' && bot?.webchatVersion !== 'v4') {
      // trigger answers of old bots do not have some properties. These need to be skipped
      // steps to skip: 3, 6, 7
      if (step === 4) setStep(2)
      else if (step === 8) setStep(5)
      else if (step > 0) setStep(step - 1)
    } else {
      if (step > 0) {
        setStep(step - 1)
      }
    }
  }

  /**
   * Handles button click to go to next step.
   */
  function onNextStep(): void {
    if (answerType === 'trigger' && bot?.webchatVersion !== 'v4') {
      // trigger answers of old bots do not have some properties. These need to be skipped
      // steps to skip: 3, 6, 7
      if (step === 2) setStep(4)
      else if (step === 5) setStep(8)
      else setStep(step + 1)
    } else {
      setStep(step + 1)
    }
  }

  /**
   * Handles answer type selection.
   * Sets answer type in state and increases step.
   * @param type
   */
  function onSetAnswerType(type: AnswerType): void {
    setAnswerType(type)
    onNextStep()
  }

  /**
   * Handles final button click to create answer.
   */
  function onCreateAnswerClick(): void {
    if (answerType === 'answer') {
      if (title && topic && answerText && utterances.length >= KNOWLEDGE_MIN_UTTERANCES) {
        const answerObj: AnswerWithoutIntent = {
          answerId: uuid(),
          title: title ?? '',
          topic: topic ?? '',
          labels: utterances,
          answerType,
          actions: followUpQuestions,
          answerTemplate: answerText ?? '',
          answer: answerText ?? '',
          language: bot?.primaryLanguage || 'de',
          knowledgeDbId: (bot?.modules[MODULE_ID_CONVAISE_NLU] as NLUModule)?.knowledgeDbId ?? '',
          customerId: bot?.customer,
        }
        onCreateAnswer(answerObj)
      }
    } else if (answerType === 'trigger') {
      if (dialogName && utterances.length >= 3) {
        // const answerObj: TriggerAnswerObject = {
        // answerId: uuid(),
        // dialogName,
        // labels: utterances,
        // }
        const answerId = uuid()
        const answer = new TriggerAnswerWithoutIntent(
          dialogName,
          answerId,
          utterances,
          answerText,
          answerText,
          intentTriggerButtons,
          title,
          topic,
        )
        onCreateAnswer(answer)
      }
    }
  }

  return (
    <Dialog
      id='create-answer-dialog'
      size='medium-large'
      open
      closable
      disableBackdropClick
      onClose={onClose}
      title={
        step === 0 || typeof answerType === 'undefined' ? 'Antwort erstellen' : getTitle(answerType, step, hasContent)
      }
      primaryActionButton={
        step > 0 ? (
          <CreateAnswerButton
            step={step}
            finalStep={answerType === 'answer' ? finalStepAnswer : finalStepTrigger}
            onClick={onNextStep}
            onFinalClick={onCreateAnswerClick}
            disabled={isNextButtonDisabled()}
          />
        ) : undefined
      }
      secondaryActionText={step > 0 ? 'Zurück' : undefined}
      onSecondaryActionClick={onPreviousStep}
    >
      {answerType === 'answer' ? (
        // ANSWER
        step === 0 ? (
          <Step0AnswerType onAnswerTypeSelect={onSetAnswerType} />
        ) : step === 1 ? (
          <StepTopic
            answers={answers}
            selectedTopic={topic}
            onSetTopic={setTopic}
            topicsWithoutAnswers={topicsWithoutAnswers}
          />
        ) : step === 2 && hasContent ? (
          <StepTitle title={title} onSetTitle={setTitle} />
        ) : step === 3 ? (
          <StepUtterances
            utterances={utterances}
            onSetUtterances={setUtterances}
            descriptionText={
              'Bitte fügen Sie mindestens 5 Fragen hinzu, die diese Antwort beantworten soll. Weitere Fragen zur Verbesserung des Modells können nach der Erstellung hinzugefügt werden.'
            }
          />
        ) : step === 4 ? (
          <StepSimilarAnswers utterances={utterances} setSimilarAnswerState={setSimilarAnswersState} />
        ) : step === 5 && hasContent ? (
          // only for answer or triggeranswers of v4 bots
          <StepAnswer answerText={answerText} onSetAnswer={setAnswerText} />
        ) : step === 6 ? (
          <StepAnswerSummary
            title={title}
            topic={topic}
            answerText={answerText}
            followUpQuestions={followUpQuestions}
          />
        ) : null
      ) : answerType === 'trigger' ? (
        // TRIGGER
        step === 1 ? (
          <StepTopic
            answers={answers}
            selectedTopic={topic}
            onSetTopic={setTopic}
            topicsWithoutAnswers={topicsWithoutAnswers}
          />
        ) : step === 2 ? (
          <Step1Dialog dialogName={dialogName} onSetDialogName={setDialogName} />
        ) : step === 3 ? (
          <StepTitle title={title} onSetTitle={setTitle} />
        ) : step === 4 ? (
          <StepUtterances
            utterances={utterances}
            onSetUtterances={setUtterances}
            descriptionText={
              'Bitte fügen Sie mindestens 5 Fragen hinzu, die diese Antwort beantworten soll. Weitere Fragen zur Verbesserung des Modells können nach der Erstellung hinzugefügt werden.'
            }
          />
        ) : step === 5 ? (
          <StepSimilarAnswers utterances={utterances} setSimilarAnswerState={setSimilarAnswersState} />
        ) : step === 6 ? (
          <StepAnswer answerText={answerText} onSetAnswer={setAnswerText} />
        ) : step === 7 ? (
          <StepTriggerDialogButtons
            triggerDialogButtons={intentTriggerButtons}
            onSetTriggerDialogButtons={setIntentTriggerButtons}
          />
        ) : step === 8 ? (
          <StepTriggerSummary
            title={title}
            topic={topic}
            answerText={answerText}
            triggerDialogButtons={intentTriggerButtons}
            dialogName={dialogName}
          />
        ) : null
      ) : null}
    </Dialog>
  )
}
