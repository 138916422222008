import React from 'react'

// @mui/material components
import { makeStyles } from 'tss-react/mui'

import { Typography, TextField, FormControl, Select, MenuItem, SelectChangeEvent } from '@mui/material'

// Types
import { Chart } from '../../../../../@types/Flowchart/types'

const useStyles = makeStyles()({
  typeContainer: { display: 'flex', marginTop: '20px' },
  typeText: { marginTop: 'auto', marginBottom: 'auto', marginRight: '15px' },
  datacheckTextfieldContainer: { marginTop: '20px' },
  datacheckTextfield: { marginTop: '10px' },
})

type DatacheckTextfieldProps = {
  chart: Chart
  type: 'regex' | 'condition'
  dataCheckId: string
  onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

/**
 * Textfield with text for displaying REGEX of datacheck.
 * @param {RegexTextfieldProps} props
 */
function DatacheckTextfield({ chart, type, dataCheckId, onChange }: DatacheckTextfieldProps): React.ReactElement {
  const { classes } = useStyles()
  const value =
    type === 'regex'
      ? chart.datachecks[dataCheckId]?.regex?.pattern || ''
      : chart.datachecks[dataCheckId]?.condition || ''
  const label = type === 'regex' ? 'RegEx String' : 'Bedingung'
  const placeholder = type === 'regex' ? '[0-9]%' : '>= 4'
  return (
    <div className={classes.datacheckTextfieldContainer}>
      <Typography>
        {type === 'regex' ? 'Geben Sie eine Regular Expression (RegEx) ein:' : 'Geben Sie ein Bedinungen ein:'}
      </Typography>
      <div className={classes.datacheckTextfield}>
        <TextField
          id={`datacheck-condition-${dataCheckId.substr(0, 10)}`}
          label={label}
          multiline
          maxRows='3'
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          margin='none'
          fullWidth
          size='small'
        />
      </div>
    </div>
  )
}

function API(): React.ReactElement {
  return (
    <div>
      <i>Coming soon. Please use regex or condition for now.</i>
    </div>
  )
}

type DataCheckAdvancedProps = {
  chart: Chart
  dataCheckId: string
  setStateCallback: (chart: Chart) => void
}

export default function DataCheckAdvanced({
  chart,
  dataCheckId,
  setStateCallback,
}: DataCheckAdvancedProps): React.ReactElement {
  const { classes } = useStyles()

  /**
   * Handles datacheck type change.
   * @param event
   */
  function onTypeChange(
    event: SelectChangeEvent<'regex' | 'condition' | 'API' | 'mandatory'>,
    child?: React.ReactNode,
  ): void {
    const value = event.target.value
    if (typeof value === 'string') {
      // remove properties of other types
      switch (value) {
        case 'condition':
          delete chart.datachecks[dataCheckId].regex
          delete chart.datachecks[dataCheckId].api
          break
        case 'regex':
          delete chart.datachecks[dataCheckId].api
          delete chart.datachecks[dataCheckId].condition
          break
        case 'api':
        default:
          // do nothing yet - not implemented
          break
      }
      if (value === 'condition' || value === 'API' || value === 'regex') {
        if (value) chart.datachecks[dataCheckId].type = value
        setStateCallback(chart)
      }
    }
  }

  /**
   * Handles REGEX textfield change.
   * IDEA: add validation to check if regex is valid.
   * @param event
   */
  function onRegexChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
    const regexObj = chart.datachecks[dataCheckId].regex
    if (typeof regexObj !== 'undefined') {
      regexObj.pattern = event.target.value
      chart.datachecks[dataCheckId].regex = regexObj
    } else {
      chart.datachecks[dataCheckId].regex = { pattern: '' }
    }

    setStateCallback(chart)
  }

  /**
   * Handles condition textfield change.
   * IDEA: add validation to check if condition is valid.
   * @param event
   */
  function onConditionChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
    chart.datachecks[dataCheckId].condition = event.target.value

    setStateCallback(chart)
  }

  /**
   * Renders type dependend components.
   * @param {'condition' | 'regex' | 'API' | 'mandatory'} type
   */
  function renderTypeSwitch(type: 'condition' | 'regex' | 'API' | 'mandatory'): React.ReactElement | string {
    switch (type) {
      case 'condition':
        return <DatacheckTextfield chart={chart} type={type} dataCheckId={dataCheckId} onChange={onConditionChange} />
      case 'regex':
        return <DatacheckTextfield chart={chart} type={type} dataCheckId={dataCheckId} onChange={onRegexChange} />
      case 'API':
        return <API />
      case 'mandatory':
      default:
        return ''
    }
  }

  return (
    <div>
      <div className={classes.typeContainer}>
        <Typography className={classes.typeText}>Wählen Sie einen Typen für den Datencheck:</Typography>

        <FormControl style={{ width: '100px' }}>
          <Select
            value={chart.datachecks[dataCheckId].type}
            onChange={onTypeChange}
            inputProps={{
              name: 'type',
              id: 'type',
            }}
          >
            <MenuItem value={'condition'}>
              <Typography>Bedingung</Typography>
            </MenuItem>
            <MenuItem value={'regex'}>
              <Typography>RegEx</Typography>
            </MenuItem>
          </Select>
        </FormControl>
      </div>
      {/* RENDER TYPE SPECIFIC COMPONENTS */}
      {renderTypeSwitch(chart.datachecks[dataCheckId].type)}
    </div>
  )
}
