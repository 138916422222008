import React, { memo, useEffect, useState } from 'react'
import { Grid, IconButton, Typography, Accordion, AccordionDetails, AccordionSummary, useTheme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { Can } from 'components/Can/Can'
import { useAnswers } from 'hooks/contexts/answers-context'
import { cloneDeep } from 'lodash'
import { translate } from 'api/StudioBackend'
import { LANGUAGE_DICTIONARY_DE } from 'utils/constants'
import RMEEditor from 'components/TextEditors/editor-mdx-markdown'
import CustomizedTooltip from 'components/Tooltips/CustomContentTooltip'
import { AnswerAction, IntentTriggerButton, IntentTriggerButtons } from '../../../../../../@types/Knowledge/types'
import { canBeTranslatedAutomatically } from 'utils/languageUtils'
import { useLockingContext } from 'hooks/contexts/locking-context'
import { Textfield } from 'components/TextInput/Textfield'
import { Answer } from 'classes/Knowledge'
import { useBotContext } from 'hooks/contexts/bot-context'
import { useStudioNotificationContext } from 'hooks/contexts/studio-notification-context'
import { useDatamanagementContext } from 'hooks/contexts/datamanagement-context'
import { BotInfos } from '../../../../../../@types/BotInformation/types'

const useStyles = makeStyles()((theme) => ({
  translationContainer: {
    boxShadow: 'none',
    border: `solid 1px ${theme.palette.grey[500]}`,
    marginTop: `${theme.spacing(2)} !important`,
    padding: theme.spacing(1),
  },
  translationLabel: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  markdownEditorTextfieldInputContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  markdownEditor: { display: 'flex', flexDirection: 'column', flexGrow: 1 },
  userHintIcon: {
    fontSize: '1.5rem',
    marginRight: theme.spacing(1),
    color: theme.palette.error.main,
  },
  iconButton: {
    marginLeft: theme.spacing(1),
    marginBottom: 'auto',
    marginTop: 'auto',
    padding: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  iconButtonContainer: {
    display: 'flex',
  },
  icon: {
    fontSize: '1.5rem',
  },
  triggerContainer: {
    marginTop: theme.spacing(2),
  },
  actionsGridContainer: {
    marginTop: theme.spacing(2),
  },
}))

type UserHint = {
  [lang: string]:
    | {
        answerTemplate?: string
      }
    | undefined
}

type EditAnswerTranslationsProps = {
  answerId: string
  isTranslating: boolean
  setIsTranslating: (isTranslating: boolean) => void
}

// TODO: lock state

export default memo(function EditAnswerTranslations({
  answerId,
  isTranslating,
  setIsTranslating,
}: EditAnswerTranslationsProps): React.ReactElement {
  const { classes } = useStyles()
  const theme = useTheme()
  const { lockState } = useLockingContext()
  const { bot } = useBotContext() as { bot: BotInfos }
  const { setNotification } = useStudioNotificationContext()
  const { getAnswerTranslations, updateSingleAnswerTranslation, primaryLang, flaggedAnswers, canIEditAnswer } =
    useAnswers()
  const { doAllVariablesInTextExist } = useDatamanagementContext()

  const [answerTranslations, setAnswerTranslations] = useState<{ [lang: string]: Answer }>({})
  const [langOpenIndex, setLangOpenIndex] = useState<number | false>(false)
  const [userHint, _setUserHint] = useState<UserHint>({})

  /**
   * Proxy for state update of user hint.
   * @param key
   * @param hint
   */
  function setUserHint(lang: string, key: string, hint: string | undefined): void {
    const newUserHint = { ...(userHint ?? {}) }
    newUserHint[lang] ??= {}
    newUserHint[lang] = { ...newUserHint[lang], [key]: hint }
    _setUserHint(newUserHint)
  }

  const handleAccordionChange =
    (langIndex) =>
    (event, isExpanded): void => {
      setLangOpenIndex(isExpanded ? langIndex : false)
    }

  function updateActionLocally(lang: string, index: number, action: string): void {
    if (!canIEditAnswer(answerId)) return
    const _answerTranslations = cloneDeep({ ...answerTranslations })
    if (action) {
      _answerTranslations[lang].actions[index].action = action
    }
    setAnswerTranslations(_answerTranslations)
  }

  function updateActionTranslation(lang: string, index: number): void {
    if (!canIEditAnswer(answerId)) return

    const _answerTranslations = cloneDeep({ ...answerTranslations })
    updateSingleAnswerTranslation(lang, _answerTranslations[lang], 'modified')
  }

  /**
   *
   * @param lang
   * @param answerTemplate
   * @param answerTitle
   * @returns
   */
  function updateAnswerTranslations(
    lang: string,
    answerTemplate?: string,
    answerTitle?: string,
    buttonTexts?: IntentTriggerButtons,
  ): void {
    if (!canIEditAnswer(answerId) || !lang) return

    const _answerTranslations = cloneDeep({ ...answerTranslations })

    if (typeof answerTemplate !== 'undefined') {
      _answerTranslations[lang].answerTemplate = answerTemplate
      // check if all referenced variables do exist.
      const checkResult = doAllVariablesInTextExist(answerTemplate)
      if (!checkResult.doExist) {
        // build string for notifying user
        const invalidVariables = Object.keys(checkResult.detailed)
          .filter((variable) => !checkResult.detailed[variable])
          .map((variable) => `"${variable}"`)

        const message = `Ungültige Variable(n) in Text: ${invalidVariables.join(
          ', ',
        )}. Korrigieren oder beheben Sie das Problem. Nutzer*innen sehen sonst die falsche Variablereferenz und keinen Wert im Chat!`
        setUserHint(lang, 'answerTemplate', message)
      } else {
        setUserHint(lang, 'answerTemplate', undefined) // reset if no error
      }
    }
    if (typeof answerTitle !== 'undefined') {
      _answerTranslations[lang].title = answerTitle
    }
    if (typeof buttonTexts !== 'undefined') {
      const newButtonTexts = _answerTranslations[lang].intentTriggerButtons ?? {}
      if (typeof buttonTexts.skipButton !== 'undefined') newButtonTexts.skipButton = buttonTexts.skipButton
      if (typeof buttonTexts.startDialogButton !== 'undefined')
        newButtonTexts.startDialogButton = buttonTexts.startDialogButton
      _answerTranslations[lang].intentTriggerButtons = newButtonTexts
    }

    setAnswerTranslations(_answerTranslations) // TODO: do we need this?
    updateSingleAnswerTranslation(lang, _answerTranslations[lang], 'modified')
  }

  async function translateAnswerText(lang: string): Promise<void> {
    if (!canIEditAnswer(answerId)) return

    // NOTE: vereinfachtes deutsch ausnahme hier einfügen?
    if (
      primaryLang &&
      answerId &&
      flaggedAnswers &&
      flaggedAnswers.answers &&
      flaggedAnswers.answers[primaryLang] &&
      flaggedAnswers.answers[primaryLang][answerId] &&
      flaggedAnswers.answers[primaryLang][answerId].answerTemplate &&
      flaggedAnswers.answers[primaryLang][answerId].answerTemplate !== null
    ) {
      setIsTranslating(true)
      try {
        const result = await translate(
          flaggedAnswers.answers[primaryLang][answerId].answerTemplate as string,
          primaryLang,
          lang,
          bot.id,
          true,
        )
        if (result) {
          // TODO: error management

          const translated = result?.translatedText
          if (translated) {
            updateAnswerTranslations(lang, translated, undefined, undefined)
          }
        }
      } catch (err) {
        // error
        setNotification('error', 'Fehler beim automatischen Übersetzen. Der Text konnte nicht übersetzt werden.')
      }

      // setAnswerTranslations(_answerTranslations)
      // updateAnswerTranslation(lang, _answerTranslations[lang], 'modified')
      setIsTranslating(false)
    }
  }

  async function translateIntentTriggerButton(lang: string, button: IntentTriggerButton): Promise<void> {
    if (
      !button ||
      !primaryLang ||
      !flaggedAnswers?.answers[primaryLang] ||
      !flaggedAnswers?.answers[primaryLang][answerId].intentTriggerButtons
    ) {
      return
    }

    const buttons = flaggedAnswers.answers[primaryLang][answerId].intentTriggerButtons
    if (!buttons || !buttons[button]) return

    setIsTranslating(true)
    try {
      const result = await translate(buttons[button] as string, primaryLang, lang, bot.id, true)
      if (result) {
        // TODO: error management

        const translated = result?.translatedText
        if (translated) {
          updateAnswerTranslations(lang, undefined, undefined, { [button]: translated })
        }
      }
    } catch (err) {
      // error
      setNotification('error', 'Fehler beim automatischen Übersetzen. Der Text konnte nicht übersetzt werden.')
    }
    setIsTranslating(false)
  }

  async function translateActionText(lang: string, index: number): Promise<void> {
    if (!canIEditAnswer(answerId)) return

    // NOTE: vereinfachtes deutsch ausnahme hier einfügen?
    if (
      primaryLang &&
      answerId &&
      flaggedAnswers &&
      flaggedAnswers.answers &&
      flaggedAnswers.answers[primaryLang] &&
      flaggedAnswers.answers[primaryLang][answerId] &&
      flaggedAnswers.answers[primaryLang][answerId].actions &&
      Array.isArray(flaggedAnswers.answers[primaryLang][answerId].actions) &&
      (flaggedAnswers.answers[primaryLang][answerId].actions as AnswerAction[])[index] &&
      (flaggedAnswers.answers[primaryLang][answerId].actions as AnswerAction[])[index] !== null &&
      (flaggedAnswers.answers[primaryLang][answerId].actions as AnswerAction[])[index].action
    ) {
      setIsTranslating(true)
      const result = await translate(
        (flaggedAnswers.answers[primaryLang][answerId].actions as AnswerAction[])[index].action as string,
        primaryLang,
        lang,
        bot.id,
        true,
      )
      if (result) {
        // TODO: error management
        const _answerTranslations = cloneDeep({ ...answerTranslations })
        _answerTranslations[lang].actions[index].action = result.translatedText
        setAnswerTranslations(_answerTranslations)
        updateSingleAnswerTranslation(lang, _answerTranslations[lang], 'modified')
      }
      setIsTranslating(false)
    }
  }

  useEffect(() => {
    const trans = getAnswerTranslations(answerId)
    setAnswerTranslations(trans)
  }, [flaggedAnswers])

  return (
    <>
      <Can I='read' a='translations'>
        <Can I='update' a='translations' passThrough>
          {(can: boolean): React.ReactElement => (
            <>
              {answerTranslations &&
                flaggedAnswers !== null &&
                primaryLang !== null &&
                Object.values(answerTranslations).map((translation, index) => {
                  const answerType = translation.answerType
                  return (
                    <div key={`language-${translation.language}-` + index} style={{ overflowX: 'hidden' }}>
                      <Accordion
                        className={classes.translationContainer}
                        elevation={0}
                        expanded={langOpenIndex === index}
                        onChange={handleAccordionChange(index)}
                        disableGutters
                        sx={{
                          '& .MuiAccordionSummary-root': {
                            padding: 0, // Removes padding from the Accordion summary
                          },
                          '& .MuiAccordionDetails-root': {
                            padding: 0, // Removes padding from the Accordion details
                          },
                        }}
                      >
                        <AccordionSummary expandIcon={<i className={'ri-arrow-down-s-line ' + classes.icon} />}>
                          <Typography variant='h4'>
                            {LANGUAGE_DICTIONARY_DE[translation.language]} {'(' + translation.language + ')'}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography className={classes.translationLabel} variant='h5'>
                            Übersetzung des Antworttexts
                          </Typography>

                          {/* Answer template translation */}
                          <div className={classes.markdownEditorTextfieldInputContainer}>
                            <div className={classes.markdownEditor}>
                              <RMEEditor
                                input={answerTranslations[translation.language].answerTemplate}
                                onChangeCallback={(markdown): void => {
                                  updateAnswerTranslations(translation.language, markdown, undefined, undefined)
                                }}
                                placeholder='Übersetzung des Antworttexts...'
                                readOnly={!can || isTranslating || lockState !== 'canEdit' || !canIEditAnswer(answerId)}
                              />
                            </div>
                            <div className={classes.iconButtonContainer}>
                              <CustomizedTooltip
                                placement='top'
                                disableInteractive
                                content={<Typography>Übersetzen</Typography>}
                                elements={
                                  <div style={{ margin: 'auto' }}>
                                    <IconButton
                                      onClick={async (): Promise<void> => {
                                        await translateAnswerText(translation.language)
                                      }} // NOTE: vereinfachtes deutsch ausnahme hier einfügen?
                                      aria-label='translate'
                                      className={classes.iconButton}
                                      disabled={
                                        !can ||
                                        !canIEditAnswer(answerId) ||
                                        lockState !== 'canEdit' ||
                                        isTranslating ||
                                        typeof primaryLang === 'undefined' ||
                                        !canBeTranslatedAutomatically(translation.language)
                                        // || lockState !== 'canEdit'
                                      }
                                    >
                                      <i className={'ri-magic-line ' + classes.icon}></i>
                                    </IconButton>
                                  </div>
                                }
                              />
                            </div>
                          </div>
                          {userHint && userHint[translation.language]?.answerTemplate && (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <i className={`ri-error-warning-line ${classes.userHintIcon}`} />
                              <Typography variant='body1' style={{ color: theme.palette.error.main }}>
                                {userHint[translation.language]?.answerTemplate}
                              </Typography>
                            </div>
                          )}
                          {/* Intent trigger buttons translations */}
                          {bot?.webchatVersion === 'v4' && answerType === 'trigger' && (
                            <div className={classes.triggerContainer}>
                              <Typography className={classes.translationLabel} variant='h5'>
                                Übersetzung der Buttontexte
                              </Typography>
                              <div>
                                <Grid
                                  container
                                  spacing={2}
                                  justifyContent={'center'}
                                  justifyItems={'center'}
                                  style={{ marginTop: theme.spacing(2) }}
                                >
                                  {/* start button */}
                                  <Grid item xs={11} container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                      {/* orig text */}
                                      <Textfield
                                        value={
                                          flaggedAnswers.answers[primaryLang][translation.answerId].intentTriggerButtons
                                            ?.startDialogButton
                                        }
                                        disabled
                                        fullWidth
                                        label={`Dialog starten Button (${primaryLang})`}
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      {/* translation */}
                                      <Textfield
                                        value={
                                          answerTranslations[translation.language].intentTriggerButtons
                                            ?.startDialogButton
                                        }
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                          updateAnswerTranslations(translation.language, undefined, undefined, {
                                            startDialogButton: event.target.value,
                                          })
                                        }}
                                        placeholder='Übersetzter Startbutton Text...'
                                        fullWidth
                                        label={`Dialog starten Button (${translation.language})`}
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={1}>
                                    <div
                                      className={classes.iconButtonContainer}
                                      style={{ width: '100%', height: '100%' }}
                                    >
                                      <CustomizedTooltip
                                        placement='top'
                                        disableInteractive
                                        content={<Typography>Übersetzen</Typography>}
                                        elements={
                                          <div style={{ margin: 'auto' }}>
                                            <IconButton
                                              onClick={async (): Promise<void> => {
                                                await translateIntentTriggerButton(
                                                  translation.language,
                                                  'startDialogButton',
                                                )
                                              }} // NOTE: vereinfachtes deutsch ausnahme hier einfügen?
                                              aria-label='translate'
                                              className={classes.iconButton}
                                              disabled={
                                                !can ||
                                                !canIEditAnswer(answerId) ||
                                                lockState !== 'canEdit' ||
                                                isTranslating ||
                                                typeof primaryLang === 'undefined' ||
                                                !canBeTranslatedAutomatically(translation.language)
                                              }
                                            >
                                              <i className={'ri-magic-line ' + classes.icon}></i>
                                            </IconButton>
                                          </div>
                                        }
                                      />
                                    </div>
                                  </Grid>

                                  {/* skip button */}
                                  <Grid item xs={11} container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                      {/* orig text */}
                                      <Textfield
                                        value={
                                          flaggedAnswers.answers[primaryLang][translation.answerId].intentTriggerButtons
                                            ?.skipButton
                                        }
                                        disabled
                                        fullWidth
                                        label={`Neue Frage (Überspringen) Button (${primaryLang})`}
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      {/* translation */}
                                      <Textfield
                                        value={
                                          answerTranslations[translation.language].intentTriggerButtons?.skipButton
                                        }
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                          updateAnswerTranslations(translation.language, undefined, undefined, {
                                            skipButton: event.target.value,
                                          })
                                        }}
                                        placeholder='Übersetzter Startbutton Text...'
                                        label={`Neue Frage (Überspringen) Button (${translation.language})`}
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={1}>
                                    <div
                                      className={classes.iconButtonContainer}
                                      style={{ width: '100%', height: '100%' }}
                                    >
                                      <CustomizedTooltip
                                        placement='top'
                                        disableInteractive
                                        content={<Typography>Übersetzen</Typography>}
                                        elements={
                                          <div style={{ margin: 'auto' }}>
                                            <IconButton
                                              onClick={async (): Promise<void> => {
                                                await translateIntentTriggerButton(translation.language, 'skipButton')
                                              }} // NOTE: vereinfachtes deutsch ausnahme hier einfügen?
                                              aria-label='translate'
                                              className={classes.iconButton}
                                              disabled={
                                                !can ||
                                                !canIEditAnswer(answerId) ||
                                                lockState !== 'canEdit' ||
                                                isTranslating ||
                                                typeof primaryLang === 'undefined' ||
                                                !canBeTranslatedAutomatically(translation.language)
                                              }
                                            >
                                              <i className={'ri-magic-line ' + classes.icon}></i>
                                            </IconButton>
                                          </div>
                                        }
                                      />
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          )}

                          {/* Actions (Folgefragen) translations */}
                          {bot?.webchatVersion !== 'v4' && answerType === 'answer' && (
                            <Grid container spacing={1} className={classes.actionsGridContainer}>
                              {answerTranslations &&
                                answerTranslations[translation.language].actions.map((action, index) => {
                                  return (
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={4}
                                      key={'language' + translation.language + '-action' + index}
                                    >
                                      <Typography className={classes.translationLabel}>
                                        Folgefrage {index + 1}
                                      </Typography>
                                      <div className={classes.markdownEditorTextfieldInputContainer}>
                                        <Textfield
                                          value={answerTranslations[translation.language].actions[index].action || ''}
                                          // value={tmpTextFieldState}
                                          onChange={(event): void => {
                                            // setTmpTextFieldState(event.target.value)
                                            updateActionLocally(translation.language, index, event.target.value)
                                          }} // change locally
                                          onBlur={(): void => {
                                            updateActionTranslation(translation.language, index)
                                          }} // change in context
                                          disabled={
                                            !can ||
                                            !canIEditAnswer(answerId) ||
                                            isTranslating ||
                                            lockState !== 'canEdit'
                                          }
                                          placeholder='Übersetzung...'
                                        />
                                        {/* <CustomizedTooltip
                                      placement='top'
                                      disableInteractive
                                      content={<Typography>Übersetzen</Typography>}
                                      elements={
                                        <div className={classes.iconButtonContainer}>
                                          <IconButton
                                            onClick={async (): Promise<void> => {
                                              await translateActionText(translation.language, index)
                                            }}
                                            aria-label='translate'
                                            className={classes.iconButton}
                                            disabled={
                                              isTranslating || typeof primaryLang === 'undefined' || !canBeTranslatedAutomatically(translation.language)
                                              // || lockState !== 'canEdit'
                                            }
                                          >
                                            <i className={'ri-magic-line ' + classes.icon}></i>
                                          </IconButton>
                                        </div>
                                      }
                                    /> */}
                                      </div>
                                    </Grid>
                                  )
                                })}
                            </Grid>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  )
                })}
            </>
          )}
        </Can>
      </Can>
    </>
  )
})
