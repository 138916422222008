import { RAGDocumentForInsertion, RAGDocumentForUpdate } from '../@types/Knowledge/RAG/types'

/**
 * Prepare a request to insert a new document into the rag service.
 */
export function prepareInsertionRequest(
  botId: string,
  knowledgeDbId: string,
  documents: RAGDocumentForInsertion[] | RAGDocumentForUpdate[],
  isUpdate: boolean,
): FormData {
  // we set 3 form data fields per document:
  // <tmp-id>-meta: contains stringified json object with metadata (title, type, etc.)
  // <tmp-id>-content: contains the (parsed) content of the document (string)
  // <tmp-id>-file: contains the file object (Blob). We create a file for all types (also .txt files)
  // for HTML support: ensure that the parser returns the raw html together with the parsed content:
  const formData = new FormData()

  formData.append('botId', botId)
  formData.append('knowledgeDbId', knowledgeDbId)
  formData.append('isUpdate', isUpdate.toString())
  const documentIds = documents.map((doc) => doc.documentId)
  formData.append('documentIds', JSON.stringify(documentIds))
  documents.forEach((doc) => {
    switch (doc.type) {
      // string and txt files are handled exactly the same. We set the content as string and send a txt file
      // the API then applies different handling based on the type
      case 'string': {
        // set metadata
        const { title, type, documentId, sourceUrl, filePath } = doc
        formData.append(`${documentId}-meta`, JSON.stringify({ title, type, sourceUrl, documentId, filePath }))

        if (!doc.content) break

        formData.append(`${documentId}-content`, doc.content)

        formData.append(
          `${documentId}-file`,
          new Blob([doc.content], { type: 'text/plain' }),
          `${title.endsWith('.txt') ? title : title + '.txt'}`,
        )
        break
      }
      case 'txt': {
        // set metadata
        const { title, type, documentId, sourceUrl, filePath } = doc
        formData.append(`${documentId}-meta`, JSON.stringify({ title, type, sourceUrl, documentId, filePath }))

        if (!doc.content) break
        formData.append(`${documentId}-content`, doc.content)
        if (doc.file) {
          // if update, file might be undefined
          formData.append(`${documentId}-file`, doc.file, doc.fileName)
        } else {
          // dummy file. We always need to send a file otherwise the API will not be able to process the request
          // this could be fixed in the future if we change the fastify/multipart setting attachFieldsToBody which is currently set to true
          // and always expects a file in the form data.
          formData.append(`${documentId}-file`, new Blob(['']), '')
        }
        break
      }
      case 'pdf': {
        // set metadata
        const { title, type, documentId, sourceUrl, filePath } = doc
        formData.append(`${documentId}-meta`, JSON.stringify({ title, type, sourceUrl, documentId, filePath }))

        if (!doc.content) break
        formData.append(`${documentId}-content`, doc.content)
        if (doc.file) {
          // if update, file might be undefined
          formData.append(`${documentId}-file`, doc.file, doc.fileName)
        } else {
          // dummy file. We always need to send a file otherwise the API will not be able to process the request
          // this could be fixed in the future if we change the fastify/multipart setting attachFieldsToBody which is currently set to true
          // and always expects a file in the form data.
          formData.append(`${documentId}-file`, new Blob(['']), '')
        }
        break
      }
      case 'docx': {
        // set metadata
        const { title, type, documentId, sourceUrl, filePath } = doc
        formData.append(`${documentId}-meta`, JSON.stringify({ title, type, sourceUrl, documentId, filePath }))

        if (!doc.content) break
        formData.append(`${documentId}-content`, doc.content)
        if (doc.file) {
          // if update, file might be undefined
          formData.append(`${documentId}-file`, doc.file, doc.fileName)
        } else {
          // dummy file. We always need to send a file otherwise the API will not be able to process the request
          // this could be fixed in the future if we change the fastify/multipart setting attachFieldsToBody which is currently set to true
          // and always expects a file in the form data.
          formData.append(`${documentId}-file`, new Blob(['']), '')
        }
        break
      }
      case 'website': {
        // set metadata
        const { title, type, documentId, sourceUrl, website, filePath } = doc
        formData.append(`${documentId}-meta`, JSON.stringify({ title, type, sourceUrl, documentId, filePath, website }))

        if (!doc.content) break
        formData.append(`${documentId}-content`, doc.content)
        formData.append(`${documentId}-file`, new Blob(['']), '')

        break
      }
      default:
        throw new Error(`Unsupported document type: ${doc.type}`)
    }
  })

  return formData
}
