import React, { memo, useState } from 'react'
// Types
import { Variable } from '../../../../../../../@types/Flowchart/types'
import { Card } from '../../../../../../../@types/SmartCards/types'
import { Button } from 'components/Buttons'
import SimpleCardDesinger from 'pages/CardDesigner/simpleCardDesigner'
import { Dialog } from '@mui/material'
import { isEqual } from 'lodash'

type CardDesignerProps = {
  card: Card
  setCardCallback: (card: Card) => void
  primaryLanguage: string
  variables: { [varId: string]: Variable }
}

export default memo(function CardDesigner({
  card: origCard,
  primaryLanguage,
  variables,
  setCardCallback,
}: CardDesignerProps): React.ReactElement {
  const [editCard, setEditCard] = useState<boolean>(false)

  return (
    <div id='editor'>
      <Button onClick={(): void => setEditCard(true)} variant='secondary' icon={'draft-line'} iconType='remix'>
        Card Designer öffnen
      </Button>
      <Dialog fullScreen open={editCard} onClose={(): void => setEditCard(false)}>
        <SimpleCardDesinger
          card={origCard}
          backCallback={(): void => setEditCard(false)}
          saveCallback={(newCard): void => {
            // use card from props to update
            const tmpCard = { ...origCard }
            tmpCard.data = newCard
            setCardCallback(tmpCard)
            setEditCard(false)
          }}
          variables={variables}
          primaryLanguage={primaryLanguage}
        />
      </Dialog>
    </div>
  )
}, isEqual)
