import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'

// Material UI
import { makeStyles } from 'tss-react/mui'

import { useTheme } from '@mui/material/styles'
import Link from '@mui/material/Link'
// Custom components
import ContentPage from '../../components/Page/ContentPage'
import SubpageCard from '../../components/Cards/SubpageCard'
import Footer from '../../components/Footer/Footer'
// Auth
import { useMsal } from '@azure/msal-react'
import { InteractionStatus } from '@azure/msal-browser'
// Assets
import logo from '../../assets/img/logos/convaise-logo-v8-icon.svg'
// Const
import { EMAIL_SUBJECT_TROUBLELOGIN, EMAIL_SUPPORT, APP_TITLE } from '../../utils/constants'

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    paddingBottom: theme.spacing(6),
  },
}))

function LoginPage(): React.ReactElement {
  const { instance: PCA, inProgress } = useMsal()
  const [loggingIn, setLoggingIn] = useState(false)
  const { classes } = useStyles()
  const theme = useTheme()

  // Check if the user comes back from the auth redirect and show auth state
  useEffect(() => {
    if (inProgress === InteractionStatus.HandleRedirect || inProgress === InteractionStatus.Login) {
      setLoggingIn(true)
    } else {
      setLoggingIn(false)
    }
  }, [inProgress])

  return (
    <ContentPage>
      <Helmet>
        <title>{APP_TITLE} - Login</title>
      </Helmet>
      <div className={classes.container}>
        <SubpageCard
          title='Login'
          description={
            <>
              <div>
                Willkommen bei Convaise Studio. Bitte loggen Sie sich ein, um Ihre Assistenten verwalten zu können.
              </div>
              <div style={{ marginTop: theme.spacing(2) }}>
                <Link href={`mailto:${EMAIL_SUPPORT}${EMAIL_SUBJECT_TROUBLELOGIN}`}>Problem beim Einloggen?</Link>
              </div>
            </>
          }
          onClick={(): void => {
            setLoggingIn(true)
            PCA.loginRedirect()
          }}
          disabled={false}
          graphic={logo}
          buttonText={loggingIn ? 'Authentifizieren' : 'Einloggen'}
          height='fixed'
          loading={loggingIn}
          width='800'
        />
      </div>
      <Footer smallWidth />
    </ContentPage>
  )
}

export default LoginPage
