import React, { createContext, useContext, useReducer, useCallback } from 'react'
import { v4 as uuid } from 'uuid'

// ----- TYPE DEFINITIONS -----

// Maps Actions to Payloads - Standard does not need customization
type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key
      }
    : {
        type: Key
        payload: M[Key]
      }
}

// Possible Actions
enum Types {
  SetLanguage = 'SET_LANGUAGE',
}

// Payloads for each action
type StudioContextPayload = {
  [Types.SetLanguage]: {
    language: string
  }
}

type Action = ActionMap<StudioContextPayload>[keyof ActionMap<StudioContextPayload>]
type State = { language: string; sessionId: string }

type Dispatch = (action: Action) => void
type StudioProviderProps = { children: React.ReactNode }

/**
 * ----- CONTEXT -----
 * Studio Context is a global context used for storing settings like the choosen language.
 *
 * The logic coul get extended to also store and load for example the language from the Browser's
 * sessionStorage. Example: https://kentcdodds.com/blog/should-i-usestate-or-usereducer
 *
 * To access this context:
 * import useStudioContext from '<path>/hooks/contexts/studio-context.tsx'
 *
 * // in a function
 * const {language, setLanguage} = useStudioContext()
 *
 * setLangauge('en')
 */

const StudioContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined)

function studioReducer(state: State, action: Action): State {
  switch (action.type) {
    case Types.SetLanguage: {
      // make sure to spread that state just in case!
      return { ...state, language: action.payload.language }
    }
    default: {
      // helps us avoid typos!
      throw new Error(`[StudioContext-Reducer] Unhandled action type: ${action.type}`)
    }
  }
}

/**
 * Context provider for providing StudioContext
 * @returns
 */
function StudioContextProvider({ children }: StudioProviderProps): JSX.Element {
  // try to get sessionid from session storage
  let sessionId = window.sessionStorage.getItem('sessionId')
  if (!sessionId) {
    // create new session id and write to session storage
    sessionId = uuid()
    window.sessionStorage.setItem('sessionId', sessionId)
  }

  const [state, dispatch] = useReducer(studioReducer, { language: 'de', sessionId })
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  // const value = useMemo(() => [state, dispatch], [state])
  const value = { state, dispatch }
  return <StudioContext.Provider value={value}>{children}</StudioContext.Provider>
}

/**
 * Hook for accessing and manipulating the StudioContext state
 * @returns
 */
function useStudioContext(): {
  language: string
  setLanguage: (language: any) => void
  sessionId: string
} {
  const context = useContext(StudioContext)
  if (context === undefined) {
    throw new Error('[useStudioContext] useStudioContext must be used within a StudioContextProvider')
  }

  // splitting it up, allows us to add additional api calls
  const { state, dispatch } = context

  // ---- API ----
  // To not let anything work on the context state directly and to have an easy to use API

  // State
  const language = state.language
  const sessionId = state.sessionId

  // Functions
  const setLanguage = useCallback((language) => dispatch({ type: Types.SetLanguage, payload: { language } }), [])

  // Controll what is returned
  return { language, setLanguage, sessionId }
}

export { StudioContextProvider, useStudioContext }
