import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { useTheme } from '@mui/material/styles'
import { Pageview, Input } from '@mui/icons-material'
import { Grid, Typography } from '@mui/material'
import { SJumpSelection } from './SJump'
import BaseCard from 'components/Cards/BaseCard'
// assets
import svgChooseCardMenuImage from 'assets/img/flowDesigner/chooseCardMenuImage.svg'
import svgCreateCardMenuImage from 'assets/img/flowDesigner/createCardMenuImage.svg'

const useStyles = makeStyles()((theme) => ({
  menuContainer: {
    display: 'flex',
    height: 'calc(100% - 60px)',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    justifyContent: 'space-evenly',
  },
  card: {
    alignItems: 'center',
    // transition: 'box-shadow 0.3s',
    // '&:hover': {
    //   boxShadow:
    //     '0 14px 26px -12px rgb(0 0 0 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)', // '0px 2px 10px 2px rgba(0,0,0, 0.25)'
    // },
  },
  menuImage1: {
    marginBottom: '50px',
  },
}))

type SJumpMenuProps = {
  onSelection: (selection: SJumpSelection) => void
}

export default function SJumpMenu({ onSelection }: SJumpMenuProps): React.ReactElement {
  const { classes } = useStyles()
  const theme = useTheme()
  return (
    <>
      <Typography>
        Sie können einen Block suchen oder einen Block über dessen <em>ID</em> auswählen. Bitte wählen Sie.
      </Typography>
      <div className={classes.menuContainer}>
        <BaseCard
          footerText='Block auswählen'
          footerColor={theme.palette.success.main}
          onClick={(): void => onSelection(SJumpSelection.BLOCK_SEARCH)}
          shadowWidth={20}
          shadowBlur={20}
          className={classes.card}
        >
          <img className={classes.menuImage1} src={svgChooseCardMenuImage} alt={'Blockauswahl über Blocksuche'} />
        </BaseCard>
        {/* <button className={classes.button} onClick={(): void => onSelection(SJumpSelection.BLOCK_SEARCH)}>
              <Pageview className={classes.icon} />
              <Typography>Block auswählen</Typography>
            </button> */}

        <BaseCard
          footerText='Block ID eingeben'
          footerColor={theme.palette.warning.main}
          onClick={(): void => onSelection(SJumpSelection.BLOCK_ID)}
          shadowWidth={20}
          shadowBlur={20}
          className={classes.card}
        >
          <img className={classes.menuImage1} src={svgCreateCardMenuImage} alt={'Blockauswahl über Block ID'} />
        </BaseCard>
        {/* <button className={classes.button} onClick={(): void => onSelection(SJumpSelection.BLOCK_ID)}>
              <Input className={classes.icon} />
              <Typography>Trigger</Typography>
            </button> */}
      </div>
    </>
  )
}
