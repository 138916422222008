import React, { useState, useEffect } from 'react'

// @mui/material
import { makeStyles } from 'tss-react/mui'

import Button from '@mui/material/Button'
// @mui/icons-material
import { Check, FastForward, Cancel } from '@mui/icons-material'

import CardList, { CardObject } from '../../../components/CardList/CardList'

import { IngestPDFField, IngestPDFResult } from '../../../classes/Ingest'
import { SyncedSignature } from '../../../@types/Ingest/types'

const useStyles = makeStyles()((theme) => ({
  viewControlls: {
    position: 'absolute',
    display: 'flex',
    bottom: '0%',
    width: '100%',
  },
  resumeButton: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  finalButton: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  actionCancelContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  actionCancelContentContainer: {
    marginTop: 'auto',
    marginBottom: 'auto',
    justifyContent: 'center',
  },
  actionCancelText: {
    marginBottom: '20px',
  },
  actionCancelButton: {
    background: 'red',
    color: 'white',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  view: {
    height: 'calc(100% - 90px)',
    position: 'relative',
    marginTop: theme.spacing(4),
  },
}))

type FieldsViewProps = {
  syncedSignature: SyncedSignature
  ingestPDFResult: IngestPDFResult
  setIngestPDFResult: (ingestPDFResult: IngestPDFResult) => void
  setIsFieldDone: (e: number) => void
}

export default function FieldsView({
  syncedSignature,
  ingestPDFResult,
  setIngestPDFResult,
  setIsFieldDone,
}: FieldsViewProps): React.ReactElement {
  /** Holds all fields that should be displayed */
  const [displayFields, setDisplayedFields] = useState<CardObject[]>([]) // cards for configured fields that are displayed
  const [configuredFields, setConfiguredFields] = useState<IngestPDFField[]>([]) // tracks configured fields
  const [unconfiguredFields, setUnConfiguredFields] = useState<IngestPDFField[]>([]) // tracks all fields that still need to be configured
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>() // used for highlighting active card
  const { classes } = useStyles()

  /**
   * Adds all fields from synced signature to ingestPDFResult if they are not already there.
   * Removes fields from ingestPDFResult, if they are no longer in signature.
   * @param signature
   */
  function addFieldsToIngestPDFResult(syncedSignature: SyncedSignature): void {
    if (syncedSignature) {
      for (const fieldObj of syncedSignature) {
        const fieldId = fieldObj.id
        const displayName = fieldObj.alternativeText || fieldObj.fieldName
        const fieldName = fieldObj.fieldName
        const page = fieldObj.page
        const pageDimens = fieldObj.pageDimens
        const coords = fieldObj.coords
        const description = {
          text: fieldObj.alternativeText || '',
          coords: fieldObj.coords,
          isConfigured: false,
        }
        const required = fieldObj.required ?? true
        const type = fieldObj.type || 'Text'
        const field = new IngestPDFField(
          fieldId,
          fieldName,
          type,
          page,
          pageDimens,
          coords,
          description,
          required,
          displayName,
        )
        if (!Object.keys(ingestPDFResult.fields).includes(fieldId)) ingestPDFResult.fields[fieldId] = field
      }

      // remove fields from ingestPDFResult that are no longer in signature
      for (const fieldId of Object.keys(ingestPDFResult.fields)) {
        if (syncedSignature.findIndex((field) => field.id === fieldId) < 0) delete ingestPDFResult.fields[fieldId]
      }
      setIngestPDFResult(ingestPDFResult)
    }
  }

  /** Prepares field objects for display in CardList. */
  function prepareCardList(fields: IngestPDFField[]): CardObject[] {
    const fieldsList: CardObject[] = []
    fields.forEach((field) => {
      fieldsList.push({
        id: field.id,
        title: field.displayName,
        subtitle: field.type,
        text: field.description.text,
        color: undefined, // TODO: Colors
      })
    })

    return fieldsList
  }

  /** Returns configured and unconfigured fields */
  function getConfiguredAndUnconfiguredFields(ingestPDFResult: IngestPDFResult): {
    configured: IngestPDFField[]
    unconfigured: IngestPDFField[]
  } {
    const fields = Object.values(ingestPDFResult.fields)
    const configured = fields.filter((field) => field.isConfigured === true)
    const unconfigured = fields.filter((field) => !field.isConfigured)

    return { configured, unconfigured }
  }

  /**
   * Selects card.
   * If a card is selected by the user, select that card and field, else select the next, unconfigured field.
   */
  function selectCard(selectedId: string | undefined, displayedFieldCards: CardObject[]): void {
    if (selectedId) {
      // selected
      const selectedIndex = displayedFieldCards.findIndex((card) => card.id === selectedId)
      if (typeof selectedIndex === 'number' && selectedIndex >= 0) {
        setSelectedIndex(selectedIndex)
        return
      }
    }

    // no card is selected or selected card is not already configured
    if (!selectedId && !ingestPDFResult.disableAutoFieldSelection && unconfiguredFields.length > 0) {
      // not selected -> select next unconfigured field by setting the selectedId in the ingestPDFResult
      const nextFieldId = unconfiguredFields[0].id
      ingestPDFResult.selectedId = nextFieldId
      setIngestPDFResult(ingestPDFResult)
    } else if (!selectedId) {
      // no card is selected, setSelectedIndex to undefined
      setSelectedIndex(undefined)
    }
  }

  // ============== USE EFFECTS ================

  /** Add fields to ingestPDFResult on signature change */
  useEffect(() => {
    addFieldsToIngestPDFResult(syncedSignature)
  }, [syncedSignature])

  /** Prepare configured and unconfigured fields */
  useEffect(() => {
    const { configured: configuredFields, unconfigured: unConfiguredFields } =
      getConfiguredAndUnconfiguredFields(ingestPDFResult)
    setConfiguredFields(configuredFields)
    setUnConfiguredFields(unConfiguredFields)
    // if (!ingestPDFResult.selectedId) setSelectedIndex(undefined)
  }, [ingestPDFResult])

  /** Prepare card objects to be displayed and select next card to be configured */
  useEffect(() => {
    const preparedCardList = prepareCardList(configuredFields)
    // find selected card
    setDisplayedFields(preparedCardList)
    selectCard(ingestPDFResult.selectedId, preparedCardList)
  }, [configuredFields, unconfiguredFields])

  // ================ HANDLER ==================

  // handles selection of card
  function onCardClick(card: CardObject, index: number): void {
    if (index !== selectedIndex) {
      // set selected
      ingestPDFResult.setSelectedId(card.id)
      setIngestPDFResult(ingestPDFResult)
      setSelectedIndex(index)
    } else {
      // set unselected
      ingestPDFResult.removeSelectedId()
      setIngestPDFResult(ingestPDFResult)
      setSelectedIndex(undefined)
    }
  }

  /** Sets fields step done in the Ingest process */
  function onCompleteClick(): void {
    setIsFieldDone(1)
  }

  /** Re-enables autofield selection and resumes field iteration process over unconfigured fields */
  function onResumeClick(): void {
    ingestPDFResult.setAutoFieldSelectionDisabled(false)
    setIngestPDFResult(ingestPDFResult)
  }

  /** Disables the field description selection mode */
  function onCancelSelectFieldDescription(): void {
    ingestPDFResult.setSelectFieldDescriptionActive(false)
    setIngestPDFResult(ingestPDFResult)
  }

  // if description select is active, display only cancel button
  // console.log('FieldsView.Render')
  return (
    <div className={classes.view}>
      {ingestPDFResult.selectFieldDescriptionActive ? (
        <div className={classes.actionCancelContainer}>
          <div className={classes.actionCancelContentContainer}>
            <div className={classes.actionCancelText}>
              Markieren Sie den Feldbeschreibungstext des aktuellen Feldes.
            </div>
            <div>
              <Button
                variant='contained'
                className={classes.actionCancelButton}
                startIcon={<Cancel />}
                onClick={onCancelSelectFieldDescription}
              >
                Abbrechen
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <CardList content={displayFields} selectedIndex={selectedIndex} onClick={onCardClick} />
          <div className={classes.viewControlls}>
            {ingestPDFResult.disableAutoFieldSelection && (
              <Button
                variant='contained'
                component='span'
                className={classes.resumeButton}
                startIcon={<FastForward />}
                onClick={onResumeClick}
              >
                Fortsetzen
              </Button>
            )}
            <Button
              variant='contained'
              component='span'
              className={classes.finalButton}
              startIcon={<Check />}
              onClick={onCompleteClick}
            >
              Abschließen
            </Button>
          </div>
        </>
      )}
    </div>
  )
}
