import React from 'react'
import { useDrag, DragSourceMonitor } from 'react-dnd'
import Card, { CardProps } from '../Card'

export interface DraggableCardProps {
  card: CardProps
  id: string
  onDrop: (id: string, newIndex: number) => void
}

interface DropResult {
  allowedDropEffect: string // allowed drop
  dropEffect: string // drop effect of the drag event
  index: number // index of the grid item this draggable card has been dragged onto
  id: string // id of the moved object
}

interface DragItem {
  card: CardProps
  type: string
}

export const DraggableCardItemsTypes = {
  card: 'card',
}

export default function DraggableCard({ card, id, onDrop }: DraggableCardProps): React.ReactElement {
  const item = { card, type: 'card' }
  const [{ opacity }, drag] = useDrag({
    item,
    end(item: unknown, monitor: DragSourceMonitor) {
      const dropResult: DropResult | null = monitor.getDropResult()
      if (item && dropResult) {
        const isDropAllowed =
          dropResult.allowedDropEffect === 'any' || dropResult.allowedDropEffect === dropResult.dropEffect

        if (isDropAllowed) {
          onDrop(id, dropResult.index)
        } else {
          console.error('Drop not allowed.')
        }
      }
    },
    type: 'draggableCard',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    collect: (monitor: any) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  })

  return (
    <div ref={drag} style={{ opacity }}>
      <Card {...card} />
    </div>
  )
}
