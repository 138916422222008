import React, { useState, useEffect } from 'react'
import { Node, Chart } from '../../../../@types/Flowchart/types'
import { TranslationFile } from '../../../../@types/Translations/types'
import { CardNodeContent, DefaultNodeContent } from './Content'
import { replaceVarIdWithDisplayName } from 'utils/chartUtils'

import {
  Message,
  Announcement,
  QuestionAnswer,
  CompareArrows,
  PhotoCamera,
  PictureAsPdf,
  DynamicFeed,
  Redo,
  AddCircleOutline,
  Input,
  CallSplit,
  Timeline,
  ErrorOutline,
  Note,
  ThumbUpOutlined,
  CheckBox,
  InsertDriveFile,
  AutoAwesomeRounded,
} from '@mui/icons-material'
import { useFlowDesignerNodeStyles } from './useFlowDesignerNodeStyles'
import {
  FLOWDESIGNER_NODE_COLOR_DEFAULT,
  FLOWDESIGNER_NODE_COLOR_TRIGGER,
  FLOWDESIGNER_NODE_COLOR_QUESTION,
  FLOWDESIGNER_NODE_COLOR_API,
  FLOWDESIGNER_NODE_COLOR_FILE,
  FLOWDESIGNER_NODE_COLOR_GENERATE,
  FLOWDESIGNER_NODE_COLOR_LOGIC,
  FLOWDESIGNER_NODE_COLOR_NOTE,
  FLOWDESIGNER_NODE_COLOR_MODULE,
} from 'utils/constants'

type NodeContentProps = {
  chart: Chart
  translationFile: TranslationFile
  node: Node
  hasError?: boolean
  onResize: () => void // only used and works for card nodes
  displayCard?: boolean // only for card node
  isSelected?: boolean // atm only for card node
  classes?: any // atm only for styling the node in other places that are not flowdesigner (e.g. Node Search)
}

export default function NodeContent({
  chart,
  translationFile,
  node,
  hasError,
  onResize,
  displayCard,
  isSelected,
  classes: classesProps,
}: NodeContentProps): React.ReactElement {
  // const classes = { ...useFlowDesignerNodeStyles({ selected: isSelected, hasError }), ...classesProps }
  // const { classes: x } = useFlowDesignerNodeStyles({ selected: isSelected, hasError })
  const classes = { ...useFlowDesignerNodeStyles({ selected: isSelected, hasError }).classes, ...classesProps }

  const [displayText, setDisplayText] = useState<string>('')

  /**
   * Turns a string with linebreaks in span with html linebreaks. Turns \n into <br />.
   * @param input String with \n linebreaks
   * @returns Text in span elements with linebreaks as <br />
   */
  function renderLineBreaks(input): React.ReactElement {
    const inputTmp = input.split('\n').map((line, i) => (
      <span key={i}>
        {line}
        <br />
      </span>
    ))
    return inputTmp
  }

  useEffect(
    function () {
      if (typeof chart !== 'undefined' && node.properties.text) {
        const displayText = replaceVarIdWithDisplayName(chart, node.properties.text)
        setDisplayText(displayText)
      }
    },
    [chart, node],
  )

  switch (node.type) {
    case 'start':
    case 'basic/message':
      return (
        <DefaultNodeContent
          classes={classes}
          node={node}
          Icon={Message}
          displayText={displayText}
          color={FLOWDESIGNER_NODE_COLOR_DEFAULT}
          hasError={hasError}
        />
      )
    case 'trigger/qna':
      return (
        <DefaultNodeContent
          classes={classes}
          node={node}
          Icon={Message}
          displayText={displayText.length > 0 ? displayText : 'Startet den reinen QnA Modus'}
          color={FLOWDESIGNER_NODE_COLOR_TRIGGER}
        />
      )
    case 'trigger/intent':
      return (
        <DefaultNodeContent
          classes={classes}
          node={node}
          Icon={Message}
          displayText={displayText}
          color={FLOWDESIGNER_NODE_COLOR_TRIGGER}
          hasError={hasError}
        />
      )
    case 'trigger/event':
      return (
        <DefaultNodeContent
          classes={classes}
          node={node}
          Icon={Announcement}
          displayText={
            typeof node.properties.event !== 'undefined' && node.properties.event.length > 0
              ? node.properties.event
              : 'Nicht konfiguriert'
          }
          color={FLOWDESIGNER_NODE_COLOR_TRIGGER}
          hasError={hasError}
        />
      )
    case 'trigger/analytics':
      return (
        <DefaultNodeContent
          classes={classes}
          node={node}
          Icon={Timeline}
          displayText={
            node.properties.analyticsEvent?.eventName
              ? `Analytics Event: ${node.properties.analyticsEvent.eventName.replace(/^(customer\/)/, '')}`
              : 'Nicht konfiguriert'
          }
          color={FLOWDESIGNER_NODE_COLOR_TRIGGER}
          hasError={hasError}
        />
      )

    case 'basic/question_button':
      return (
        <DefaultNodeContent
          classes={classes}
          node={node}
          Icon={QuestionAnswer}
          displayText={displayText}
          color={FLOWDESIGNER_NODE_COLOR_QUESTION}
          hasError={hasError}
        />
      )

    case 'basic/question_free':
      return (
        <DefaultNodeContent
          classes={classes}
          node={node}
          Icon={Input}
          displayText={displayText}
          color={FLOWDESIGNER_NODE_COLOR_QUESTION}
          hasError={hasError}
        />
      )

    case 'basic/api':
      return (
        <DefaultNodeContent
          classes={classes}
          node={node}
          Icon={CompareArrows}
          displayText={displayText}
          color={FLOWDESIGNER_NODE_COLOR_API}
          hasError={hasError}
        />
      )

    case 'basic/adaptiveCard':
      return (
        <CardNodeContent
          classes={classes}
          nodeProperties={node.properties}
          onResize={onResize}
          translationFile={translationFile}
          displayCard={displayCard}
          isSelected={isSelected}
        />
      )

    case 'basic/picture':
      return (
        <DefaultNodeContent
          classes={classes}
          node={node}
          Icon={PhotoCamera}
          displayText={displayText}
          color={FLOWDESIGNER_NODE_COLOR_FILE}
          hasError={hasError}
        />
      )

    case 'basic/fileUpload':
      return (
        <DefaultNodeContent
          classes={classes}
          node={node}
          Icon={InsertDriveFile}
          displayText={displayText}
          color={FLOWDESIGNER_NODE_COLOR_FILE}
          hasError={hasError}
        />
      )

    case 'basic/pdf':
      return (
        <DefaultNodeContent
          classes={classes}
          node={node}
          Icon={PictureAsPdf}
          displayText={'PDF generieren'}
          color={FLOWDESIGNER_NODE_COLOR_GENERATE}
          hasError={hasError}
        />
      )

    case 'logic/ifElse':
      return (
        <DefaultNodeContent
          classes={classes}
          node={node}
          Icon={CallSplit}
          displayText={displayText}
          color={FLOWDESIGNER_NODE_COLOR_LOGIC}
          hasError={hasError}
        />
      )

    case 'logic/switch':
    case 'logic/switchCondition':
      return (
        <DefaultNodeContent
          classes={classes}
          node={node}
          Icon={DynamicFeed}
          displayText={displayText}
          color={FLOWDESIGNER_NODE_COLOR_LOGIC}
          hasError={hasError}
        />
      )

    case 'logic/jump':
      return (
        <DefaultNodeContent
          classes={classes}
          node={node}
          Icon={Redo}
          displayText={`Node: ${
            node.properties.targetNode && node.properties.targetNode.length > 0
              ? node.properties.targetNode
              : 'nicht definiert'
          }`}
          color={FLOWDESIGNER_NODE_COLOR_LOGIC}
          hasError={hasError}
        />
      )
    case 'logic/setVar':
      return (
        <DefaultNodeContent
          classes={classes}
          node={node}
          Icon={AddCircleOutline}
          displayText={displayText}
          color={FLOWDESIGNER_NODE_COLOR_LOGIC}
          hasError={hasError}
        />
      )
    case 'logic/setVariables':
      return (
        <DefaultNodeContent
          classes={classes}
          node={node}
          Icon={AddCircleOutline}
          displayText={displayText}
          color={FLOWDESIGNER_NODE_COLOR_LOGIC}
          hasError={hasError}
        />
      )
    case 'logic/qna_answer':
      return (
        <DefaultNodeContent
          classes={classes}
          node={node}
          displayText={displayText}
          color={FLOWDESIGNER_NODE_COLOR_LOGIC}
        />
      )
    case 'logic/startDialog':
      return (
        <DefaultNodeContent
          classes={classes}
          node={node}
          displayText={displayText}
          color={FLOWDESIGNER_NODE_COLOR_LOGIC}
        />
      )
    case 'module/xzufi-getAnswer':
      return (
        <DefaultNodeContent
          classes={classes}
          node={node}
          displayText={displayText}
          Icon={QuestionAnswer}
          color={FLOWDESIGNER_NODE_COLOR_MODULE}
          hasError={hasError}
        />
      )
    case 'module/xzufi-getAnswerNew':
      return (
        <DefaultNodeContent
          classes={classes}
          node={node}
          displayText={displayText}
          Icon={QuestionAnswer}
          color={FLOWDESIGNER_NODE_COLOR_MODULE}
          hasError={hasError}
        />
      )
    case 'module/xzufi-trackSelectedAnswer':
      return (
        <DefaultNodeContent
          classes={classes}
          node={node}
          displayText={displayText}
          Icon={CheckBox}
          color={FLOWDESIGNER_NODE_COLOR_MODULE}
          hasError={hasError}
        />
      )
    case 'module/xzufi-trackHelpful':
      return (
        <DefaultNodeContent
          classes={classes}
          node={node}
          displayText={displayText}
          Icon={ThumbUpOutlined}
          color={FLOWDESIGNER_NODE_COLOR_MODULE}
          hasError={hasError}
        />
      )
    case 'module/qna-getAnswer':
      return (
        <DefaultNodeContent
          classes={classes}
          node={node}
          displayText={displayText}
          Icon={QuestionAnswer}
          color={FLOWDESIGNER_NODE_COLOR_MODULE}
          hasError={hasError}
        />
      )
    case 'module/qna-startTriggerDialog':
      return (
        <DefaultNodeContent
          classes={classes}
          node={node}
          displayText={displayText}
          Icon={CheckBox}
          color={FLOWDESIGNER_NODE_COLOR_MODULE}
          hasError={hasError}
        />
      )
    case 'module/qna-trackHelpful':
      return (
        <DefaultNodeContent
          classes={classes}
          node={node}
          displayText={displayText}
          Icon={ThumbUpOutlined}
          color={FLOWDESIGNER_NODE_COLOR_MODULE}
          hasError={hasError}
        />
      )
    case 'module/aleph-alpha-getAnswer':
      return (
        <DefaultNodeContent
          classes={classes}
          node={node}
          displayText={displayText}
          Icon={QuestionAnswer}
          color={FLOWDESIGNER_NODE_COLOR_MODULE}
          hasError={hasError}
        />
      )
    case 'module/aleph-alpha-trackHelpful':
      return (
        <DefaultNodeContent
          classes={classes}
          node={node}
          displayText={displayText}
          Icon={ThumbUpOutlined}
          color={FLOWDESIGNER_NODE_COLOR_MODULE}
          hasError={hasError}
        />
      )
    case 'module/llm-task':
      return (
        <DefaultNodeContent
          classes={classes}
          node={node}
          displayText={displayText}
          Icon={AutoAwesomeRounded}
          color={FLOWDESIGNER_NODE_COLOR_MODULE}
          hasError={hasError}
        />
      )
    case 'basic/note':
      return (
        <DefaultNodeContent
          classes={classes}
          node={node}
          Icon={Note}
          displayText={renderLineBreaks(node.properties.note ?? '')}
          color={FLOWDESIGNER_NODE_COLOR_NOTE}
          hasError={hasError}
        />
      )

    default:
      return (
        <DefaultNodeContent
          classes={classes}
          node={node}
          displayText={displayText}
          color={FLOWDESIGNER_NODE_COLOR_DEFAULT}
        />
      )
  }
}
