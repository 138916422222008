import React, { useEffect, useState } from 'react'
import Fuse from 'fuse.js'
import { makeStyles } from 'tss-react/mui'
import { Grid, Typography } from '@mui/material'
import { Searchfield } from 'components/TextInput/Searchfield'
import Button from 'components/Buttons/Button'

import { Answer } from 'classes/Knowledge'
import TopicCard from 'pages/Knowledge/Nlu/EndpointManagement/oldNLU/AssignUtteranceDialog/Cards/TopicCard'
import { useAnswers } from 'hooks/contexts/answers-context'

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  quickSelectContainer: {},
  searchContainer: {
    marginTop: theme.spacing(2),
  },
  searchField: {
    marginTop: theme.spacing(2),
  },
  searchResults: { marginTop: theme.spacing(2) },
  searchShowMoreButton: { marginTop: theme.spacing(1), width: '100%', textAlign: 'center' },
  subtitle: { marginTop: theme.spacing(1), marginBottom: theme.spacing(1) },

  questionContainer: {
    width: '100%',
  },

  flexContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}))

// categorizes a single topic and all of its answers
type TopicObject = {
  path: string
  topic: string
  answers: Answer[]
}

type AssignUtteranceDialogTopicsScreenProps = {
  answers: { [answerId: string]: Answer }
  onSelectTopic: (topic: string) => void
}

export default function AssignUtteranceDialogTopicsScreen({
  answers: answersProps,
  onSelectTopic,
}: AssignUtteranceDialogTopicsScreenProps): React.ReactElement {
  const { classes } = useStyles()
  const { canIEditAnswer } = useAnswers()

  const [answers, setAnswers] = useState<Answer[]>([])
  const [numberSearchResultsShown, setNumberSearchResultsShown] = useState<number>(6)
  const [searchString, setSearchString] = useState<string>('')
  const [allTopics, setAllTopics] = useState<TopicObject[]>([])
  const [searchResults, setSearchResults] = useState<TopicObject[]>([])

  /**
   * Prepares topic objects based on provided answers.
   *  1. collect all topic by iterating over all answers
   *  2. split each topic into path (part before last '/') and topic (part after last '/')
   * @param answers
   * @returns TopicObject[]
   */
  function buildTopicObjects(answers: Answer[]): TopicObject[] {
    const topicAnswersMap: { [topic: string]: Answer[] } = {}
    for (const answer of answers) {
      if (answer.topic) {
        topicAnswersMap[answer.topic] = topicAnswersMap[answer.topic] || []
        topicAnswersMap[answer.topic].push(answer)
      }
    }

    const topicObjects: TopicObject[] = []
    // topic is last element in split array (part after last '/')
    // path is everything before that
    for (const topicString of Object.keys(topicAnswersMap)) {
      const split = topicString.split('/')
      if (split.length === 1) {
        topicObjects.push({
          topic: split[0],
          path: '',
          answers: topicAnswersMap[topicString],
        })
      } else if (split.length > 1) {
        const topic = split[split.length - 1]
        const path = split.slice(0, split.length - 1).join('/') || ''
        topicObjects.push({
          topic,
          path,
          answers: topicAnswersMap[topicString],
        })
      }
    }

    return topicObjects
  }

  /**
   * Searches answers for search string and returns result.
   */
  function searchAnswers(answers: Answer[], searchString: string): Answer[] {
    if (!searchString) return answers
    const fuseOptions = {
      shouldSort: true,
      threshold: 0.4,
      minMatchCharLength: 1,
      keys: ['title', 'topic', 'labels', 'answer'],
    }
    const fuse = new Fuse(answers ?? [], fuseOptions)
    return fuse.search(searchString).map((result) => result.item)
  }

  /**
   * Handles search string change and searches.
   * @param newSearchString
   */
  function onSearchStringChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const newSearchString = event.target.value
    if (newSearchString !== searchString) {
      setSearchString(newSearchString)
      const filteredAnswerIds = searchAnswers(answers, newSearchString).map((answer) => answer.answerId)
      const filteredTopicObjects = allTopics.filter((topic) =>
        topic.answers.some((answer) => filteredAnswerIds.includes(answer.answerId)),
      )
      setSearchResults(filteredTopicObjects)
    }
  }

  /**
   * Handles show more answers click.
   * Increases shown answers by 6.
   */
  function onShowMoreAnswers(): void {
    setNumberSearchResultsShown(numberSearchResultsShown + 6)
  }

  useEffect(function () {
    const answers = Object.values(answersProps).filter((answer) => canIEditAnswer(answer))
    // prepare topic cards
    const topicObjects = buildTopicObjects(answers)
    setSearchResults([...topicObjects])
    setAllTopics([...topicObjects])
    setAnswers(answers)
  }, [])

  return (
    <>
      <div className={classes.searchContainer}>
        <div className={classes.subtitle}>
          <Typography variant='h4'>Themensuche</Typography>
          <div className={classes.searchField}>
            <Searchfield autoFocus value={searchString} onChange={onSearchStringChange} />
          </div>
          <div className={classes.searchResults}>
            <Grid container spacing={1}>
              {searchResults.slice(0, numberSearchResultsShown).map((topicObj, index) => (
                <Grid item xs={12} md={6} lg={4} key={`topic-${index}`}>
                  <TopicCard
                    topic={topicObj.topic}
                    topicPath={topicObj.path}
                    answersOfTopic={topicObj.answers}
                    onClick={onSelectTopic}
                  />
                </Grid>
              ))}
            </Grid>
            {searchResults && numberSearchResultsShown < searchResults.length && (
              <div className={classes.searchShowMoreButton}>
                <Button size='small' icon='arrow-down-s-line' iconType='remix' onClick={onShowMoreAnswers}>
                  Mehr anzeigen
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
