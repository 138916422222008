import { createTheme } from '@mui/material/styles'
import NotoSansWoff2 from '../assets/fonts/noto-sans-sc-v12-latin-regular.woff2'
import NotoSansWoff from '../assets/fonts/noto-sans-sc-v12-latin-regular.woff'
import React from 'react'

// constants
/* noto-sans-sc-regular - latin */
const notosans = {
  fontFamily: 'Noto Sans',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Noto Sans'),
    url(${NotoSansWoff2}) format('woff2'), /* Super Modern Browsers */
    url(${NotoSansWoff}) format('woff'), /* Modern Browsers */
  `,
}
export const colors = {
  primary: {
    text: 'rgba(16, 44, 60, 1)', // primary text color - dark blue
    main: '#003454', // background for buttons etc.
  },
  grey: {
    textLightGrey: '#667C8A',
    shadow: '#edf3f7',
  },
  white: '#fff',
}

const borderRadiusMd = '16px'

interface CustomPaletteOptions {
  webchatV4: {
    success: string
    successColorText: string
    successColorBackground: string
    primaryColor: string
    primaryColorBackground: string
    primaryColorBackgroundLight: string
    primaryColorDark: string
    primaryColorLight: string
    primaryColorText: string
    disabledColor: string
    disabledColorText: string
  }
  baseCards: {
    green: string
    orange: string
    blue: string
  }
  cardActions: {
    default: string
    positive: string
    destructive: string
  }
}

// declare module '@mui/material/styles' {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   interface PaletteOptions extends CustomPaletteOptions {}
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   // interface TypographyOptions extends CustomTypographyOptions {}
// }

declare module '@mui/material/styles' {
  interface TypographyVariants {
    disabled?: React.CSSProperties
    small?: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    disabled?: React.CSSProperties
    small?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    disabled: true
    small: true
  }
}

declare module '@mui/material/styles/createPalette' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Palette extends CustomPaletteOptions {}

  // allow configuration using `createMuiTheme`
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface PaletteOptions extends CustomPaletteOptions {}
}

export const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      // '100': '#F1F5FE',
      main: colors.primary.main,
    },
    secondary: {
      main: '#F57D05',
    },
    error: {
      main: '#DC3232',
    },
    success: {
      main: '#4CAE4F',
    },

    warning: {
      main: '#ffb118',
    },
    grey: {
      '500': '#ADBDCD',
      A200: colors.grey.textLightGrey,
      A100: colors.grey.shadow,
    },
    background: {
      paper: '#ffffff',
      default: '#ffffff',
    },
    text: {
      primary: colors.primary.text,
      disabled: 'rgb(102, 124, 138)',
    },
    baseCards: {
      green: '#5CD460',
      orange: '#FF9005',
      blue: '#2152BE',
    },
    cardActions: {
      default: '#394D75',
      positive: '#008000',
      destructive: '#FF0000',
    },
    webchatV4: {
      success: '#747b82',
      successColorText: '#f5f7f8',
      successColorBackground: '#747b82',
      primaryColor: '#302a2a',
      primaryColorBackground: '#e1e7ea',
      primaryColorBackgroundLight: '#302a2a',
      primaryColorDark: '#747b82',
      primaryColorLight: '#e1e7ea',
      primaryColorText: '#302a2a',
      disabledColor: '#302a2a',
      disabledColorText: '#ffffff',
    },
  },
  shape: {
    borderRadius: 8, // use multiples of this
  },
  typography: {
    fontFamily: ['Noto Sans', '-apple-system', 'Segoe UI', 'Roboto', 'Ubuntu', 'Cantarell', 'Helvetica Neue'].join(','),
    h1: {
      fontSize: '3rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: '2.25rem',
      fontWeight: 700,
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 700,
      lineHeight: '1.3',
    },
    h4: {
      fontSize: '1.125rem',
      fontWeight: 700,
    },
    h5: {
      fontSize: '0.875rem',
      fontWeight: 700,
    },
    h6: {
      fontSize: '0.875rem',
      fontWeight: 700,
    },
    overline: {
      fontSize: '0.875rem',
      letterSpacing: '0.065em',
      color: colors.grey.textLightGrey,
      textTransform: 'uppercase',
      lineHeight: '1.125',
    },
    disabled: {
      fontSize: '0.875rem',
      color: 'rgb(102, 124, 138)',
      fontWeight: 700,
    },
    small: {
      fontSize: '0.5rem',
      color: colors.grey.textLightGrey,
    },
    fontSize: 14,
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          disabled: 'p',
          small: 'p',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
            html {
               @font-face: ${notosans};
             }
           `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: borderRadiusMd,
          // minWidth: '120px',
          textTransform: 'none',
          padding: '6px 24px',
          minWidth: '59px', // means that the icon would be centered and would have a good looking distance to the border - original 64px
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: borderRadiusMd,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: borderRadiusMd,
          background: colors.white,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: colors.primary.text,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: colors.primary.text,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: borderRadiusMd,
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          padding: '6px 24px',
          borderRadius: borderRadiusMd,
        },
        sizeLarge: {
          height: '56px',
        },
        // label: {
        //   textTransform: 'none',
        // },
      },
    },
  },
})
