import React, { memo, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useTheme } from '@mui/material/styles'
import { getMarkerEnd, EdgeProps, MarkerType } from 'reactflow'
import { FLOWDESIGNER_EDGE_COLOR } from 'utils/constants'
import EdgeLabel from './EdgeLabel'
import { getSmoothStepPathWithPadding } from './pathUtils'

type StyleProps = {
  isHover?: boolean
  selected?: boolean
}

// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. ArrowFunctionExpression in CSS prop.
const useStyles = makeStyles<StyleProps>()((theme, props) => ({
  'react-flow__edge-path': {
    stroke: FLOWDESIGNER_EDGE_COLOR,
    cursor: 'pointer',
    strokeWidth: props?.isHover || props?.selected ? 5 : 2,
  },
  'react-flow__edge-path-selector': {
    fill: 'none',
    stroke: 'transparent',
    zIndex: 5,
    strokeWidth: 10,
    cursor: 'pointer',
  },
}))

export default memo(function SmoothStepEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  selected,
}: EdgeProps): React.ReactElement {
  const theme = useTheme()
  const { label } = data

  const [isHover, setIsHover] = useState<boolean>(false)
  const { classes } = useStyles({ isHover, selected })

  const edgePath = getSmoothStepPathWithPadding({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  })
  const markerEndLarge = getMarkerEnd(MarkerType.Arrow, 'edge-marker-large')
  const markerEnd = getMarkerEnd(MarkerType.Arrow, 'edge-marker')

  return (
    <g>
      {/* we draw the same path twice, one of them (selector) is larger, but transparent. This allows for easier selection */}

      <path
        id={id}
        fill='none'
        className={classes['react-flow__edge-path']}
        d={edgePath}
        markerEnd={isHover || selected ? markerEndLarge : markerEnd}
      />
      <path
        fill='none'
        className={classes['react-flow__edge-path-selector']}
        d={edgePath}
        onMouseEnter={(): void => setIsHover(true)}
        onMouseLeave={(): void => setIsHover(false)}
      />
      {label && (
        <EdgeLabel
          x={sourceX + 15}
          y={sourceY}
          label={label}
          labelStyle={{ fill: theme.palette.primary.main, fontSize: 12 }}
          labelShowBg
          labelBgStyle={{ fill: 'white' }}
          labelBgPadding={[6, 4]}
          labelBgBorderRadius={4}
          selected={selected}
        />
      )}
    </g>
  )
})
