import React, { useState, useEffect } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useTheme } from '@mui/material/styles'

// @mui/material components
import { Typography } from '@mui/material'

import { SelectDropdown, Option as DropdownOption, ActionMeta } from '../../../../../components/Dropdown'

import { Chart, Dialog } from '../../../../../@types/Flowchart/types'

const useStyles = makeStyles()((theme) => ({
  selectedDialogContainer: {},
  title: {},
  marginBottomSmall: { marginBottom: '10px' },
  descContainer: { marginTop: theme.spacing(3) },
}))

type SStartDialogProps = {
  chart: Chart
  setStateCallback: (chart: Chart) => void
}

export default function SStartDialog({ chart: origChart, setStateCallback }: SStartDialogProps): React.ReactElement {
  const { classes } = useStyles()
  const theme = useTheme()
  const [chart, setChart] = useState(origChart)
  const [dialogOptions, setDialogOptions] = useState<DropdownOption[]>([])
  const [selectedDialogOption, setSelectedDialogOption] = useState<DropdownOption>()
  const [selectedDialog, setSelectedDialog] = useState<Dialog>()

  /**
   * Prepares options for select and sets them in state.
   * @param {Chart} chart
   */
  function prepareOptions(chart: Chart): DropdownOption[] {
    if (!chart.selected.id) return []
    const options: DropdownOption[] = []
    if (chart.dialogs) {
      const currentDialog = chart.nodes[chart.selected.id].properties.dialog
      Object.values(chart.dialogs).forEach((dialog) => {
        // do not push dialog of the node
        if (dialog.id !== currentDialog)
          options.push({
            label: dialog.name,
            value: dialog.id,
          })
      })
    }
    return options
  }

  /**
   * Returns selected option based on the target dialog set in node
   * @param chart
   * @returns
   */
  function getSelectedOption(chart: Chart, options: DropdownOption[]): DropdownOption | undefined {
    if (typeof chart.selected.id === 'undefined') return
    const targetDialogId = chart.nodes[chart.selected.id].properties.targetDialog
    return options.find((option) => option.value === targetDialogId)
  }

  useEffect(
    function () {
      const options = prepareOptions(origChart)
      const selected = getSelectedOption(origChart, options)
      setChart(origChart)
      setDialogOptions(options)
      setSelectedDialogOption(selected)
      if (selected) setSelectedDialog(origChart.dialogs[selected.value])
    },
    [origChart],
  )

  /**
   * Handles selection of a dialog.
   * @param {Object} selection object with label and value of selected option
   */
  function onChange(newValue: DropdownOption, action: ActionMeta<DropdownOption>): void {
    if (typeof chart.selected.id === 'undefined') return
    if (newValue) {
      chart.nodes[chart.selected.id].properties.text = newValue.label
      chart.nodes[chart.selected.id].properties.targetDialog = newValue.value
      // find start node of target dialog and set that as well
      chart.nodes[chart.selected.id].properties.targetNode = chart.dialogs[newValue.value].startNode
      setSelectedDialogOption(newValue)
      setSelectedDialog(chart.dialogs[newValue.value])
    } else {
      chart.nodes[chart.selected.id].properties.text = 'Nicht konfiguriert'
      delete chart.nodes[chart.selected.id].properties.targetDialog
      setSelectedDialog(undefined)
    }

    setStateCallback(chart)
  }

  return (
    <div className={classes.selectedDialogContainer}>
      <div className={classes.marginBottomSmall}>
        <Typography>Bitte wählen Sie einen Dialog, der durch diese Node gestartet werden soll.</Typography>
      </div>
      <SelectDropdown options={dialogOptions} selected={selectedDialogOption?.value} onChange={onChange} />
      <div className={classes.descContainer}>
        {selectedDialogOption && (
          <>
            <Typography variant='body1'>
              <b>Dialogbeschreibung</b>
            </Typography>
            <Typography style={{ marginTop: theme.spacing(1) }} variant='body1'>
              {selectedDialog?.description}
            </Typography>
          </>
        )}
      </div>
    </div>
  )
}
