import { Typography, useTheme } from '@mui/material'
import { Textfield } from 'components/TextInput/Textfield'
import { useLockingContext } from 'hooks/contexts/locking-context'
import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { EmailFallbackTexts } from '../../@types/Settings/types'

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  accordion: {
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: theme.shape.borderRadius,
    '&.MuiAccordion-root': {
      marginTop: theme.spacing(2),
      marginBottom: 0,
      '&:before': {
        display: 'none',
      },
    },
  },
}))

type EmailFallbackTextsProps = {
  emailFallbackTexts?: EmailFallbackTexts
  onEmailFallbackTextsChange: (newTexts: EmailFallbackTexts) => void
}

export default function EmailFallbackTextsConfig({
  emailFallbackTexts,
  onEmailFallbackTextsChange,
}: EmailFallbackTextsProps) {
  const { classes } = useStyles()
  const theme = useTheme()
  const { lockState } = useLockingContext()

  // chat
  const [emailFallbackChatPromptText, setEmailFallbackChatPromptText] = useState(
    emailFallbackTexts?.emailFallbackChatPromptText || '',
  )
  const [emailFallbackChatCompletedText, setEmailFallbackChatCompletedText] = useState(
    emailFallbackTexts?.emailFallbackChatCompletedText || '',
  )

  // overlay
  const [emailFallbackOverlayTitle, setEmailFallbackOverlayTitle] = useState(
    emailFallbackTexts?.emailFallbackOverlayTitle ?? '',
  )
  const [emailFallbackOverlayDepartmentText, setEmailFallbackOverlayDepartmentText] = useState(
    emailFallbackTexts?.emailFallbackOverlayDepartmentText || '',
  )
  const [emailFallbackOverlayEmailText, setEmailFallbackOverlayEmailText] = useState(
    emailFallbackTexts?.emailFallbackOverlayEmailText || '',
  )
  const [emailFallbackOverlayEmailPlaceholderText, setEmailFallbackOverlayEmailPlaceholderText] = useState(
    emailFallbackTexts?.emailFallbackOverlayEmailPlaceholderText || '',
  )
  const [emailFallbackOverlayContentText, setEmailFallbackOverlayContentText] = useState(
    emailFallbackTexts?.emailFallbackOverlayContentText || '',
  )

  const [emailFallbackOverlayAdditionalTextPlaceholder, setEmailFallbackOverlayAdditionalTextPlaceholder] = useState(
    emailFallbackTexts?.emailFallbackOverlayAdditionalTextPlaceholder || '',
  )
  const [emailFallbackOverlaySendButtonText, setEmailFallbackOverlaySendButtonText] = useState(
    emailFallbackTexts?.emailFallbackOverlaySendButtonText || '',
  )

  useEffect(() => {
    if (
      emailFallbackChatPromptText !== emailFallbackTexts?.emailFallbackChatPromptText ||
      emailFallbackChatCompletedText !== emailFallbackTexts?.emailFallbackChatCompletedText ||
      emailFallbackOverlayTitle !== emailFallbackTexts?.emailFallbackOverlayTitle ||
      emailFallbackOverlayDepartmentText !== emailFallbackTexts?.emailFallbackOverlayDepartmentText ||
      emailFallbackOverlayEmailText !== emailFallbackTexts?.emailFallbackOverlayEmailText ||
      emailFallbackOverlayEmailPlaceholderText !== emailFallbackTexts?.emailFallbackOverlayEmailPlaceholderText ||
      emailFallbackOverlayContentText !== emailFallbackTexts?.emailFallbackOverlayContentText ||
      emailFallbackOverlayAdditionalTextPlaceholder !==
        emailFallbackTexts?.emailFallbackOverlayAdditionalTextPlaceholder ||
      emailFallbackOverlaySendButtonText !== emailFallbackTexts?.emailFallbackOverlaySendButtonText
    ) {
      // only callback if value changed
      onEmailFallbackTextsChange({
        emailFallbackChatPromptText,
        emailFallbackChatCompletedText,
        emailFallbackOverlayTitle,
        emailFallbackOverlayDepartmentText,
        emailFallbackOverlayEmailText,
        emailFallbackOverlayEmailPlaceholderText,
        emailFallbackOverlayContentText,
        emailFallbackOverlayAdditionalTextPlaceholder,
        emailFallbackOverlaySendButtonText,
      })
    }
  }, [
    emailFallbackChatPromptText,
    emailFallbackChatCompletedText,
    emailFallbackOverlayTitle,
    emailFallbackOverlayDepartmentText,
    emailFallbackOverlayEmailText,
    emailFallbackOverlayEmailPlaceholderText,
    emailFallbackOverlayContentText,
    emailFallbackOverlayAdditionalTextPlaceholder,
    emailFallbackOverlaySendButtonText,
    onEmailFallbackTextsChange,
  ])

  function onChatPromptTextChange(e: React.ChangeEvent<HTMLInputElement>) {
    setEmailFallbackChatPromptText(e.target.value)
  }

  function onChatCompletedTextChange(e: React.ChangeEvent<HTMLInputElement>) {
    setEmailFallbackChatCompletedText(e.target.value)
  }

  function onTitleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setEmailFallbackOverlayTitle(e.target.value)
  }

  function onDepartmentTextChange(e: React.ChangeEvent<HTMLInputElement>) {
    setEmailFallbackOverlayDepartmentText(e.target.value)
  }

  function onEmailTextChange(e: React.ChangeEvent<HTMLInputElement>) {
    setEmailFallbackOverlayEmailText(e.target.value)
  }

  function onEmailPlaceholderTextChange(e: React.ChangeEvent<HTMLInputElement>) {
    setEmailFallbackOverlayEmailPlaceholderText(e.target.value)
  }

  function onContentTextChange(e: React.ChangeEvent<HTMLInputElement>) {
    setEmailFallbackOverlayContentText(e.target.value)
  }

  function onAdditionalTextPlaceholderChange(e: React.ChangeEvent<HTMLInputElement>) {
    setEmailFallbackOverlayAdditionalTextPlaceholder(e.target.value)
  }

  function onSendButtonTextChange(e: React.ChangeEvent<HTMLInputElement>) {
    setEmailFallbackOverlaySendButtonText(e.target.value)
  }

  return (
    <>
      <Typography variant='h5' style={{ marginBottom: theme.spacing(2) }}>
        E-Mail Fallback Texte
      </Typography>
      <div className={classes.container}>
        <Typography>
          Konfigurieren Sie die einzelnen Texte, die im Chat und im Overlay für den E-Mail Fallback angezeigt werden.
        </Typography>

        {/* chat */}
        <Textfield
          label='Hinweis für E-Mail Fallback'
          fullWidth
          value={emailFallbackChatPromptText}
          onChange={onChatPromptTextChange}
          placeholder='Möchten Sie Ihre Frage per E-Mail an die zuständige Stelle senden?'
          disabled={lockState !== 'canEdit'}
          hint='Der Text, der den Nutzer*innen unter einer Nachricht des Assistenten angezeigt wird, wenn dieser die oben konfigurierte Anzahl an Fragen nicht beantworten konnte.'
        />

        <Textfield
          label='Text für abgeschlossenes E-Mail Fallback'
          fullWidth
          value={emailFallbackChatCompletedText}
          onChange={onChatCompletedTextChange}
          placeholder='Ihre E-Mail wurde erfolgreich versendet.'
          disabled={lockState !== 'canEdit'}
          hint='Der Text, der den Nutzer*innen unter einer Nachricht des Assistenten angezeigt wird, wenn diese eine E-Mail versendet haben.'
        />

        {/* overlay */}
        <Textfield
          label='Titel des E-Mail Fallback Overlays'
          fullWidth
          value={emailFallbackOverlayTitle}
          onChange={onTitleChange}
          placeholder='E-Mail senden'
          disabled={lockState !== 'canEdit'}
          hint='Die Überschrift des Overlays, das den Nutzer*innen angezeigt wird, wenn diese eine E-Mail versenden möchten.'
        />

        <Textfield
          label='Aufforderung zur Auswahl der Stelle'
          fullWidth
          multiline
          rowsMax={3}
          value={emailFallbackOverlayDepartmentText}
          onChange={onDepartmentTextChange}
          placeholder='z.B. Bitte wählen Sie die für Ihr Anliegen zuständige Stelle aus.'
          disabled={lockState !== 'canEdit'}
          hint='Aufforderung an die Nutzer*innen, die passende Stelle für ihr Anliegen auszuwählen. Sie können die Stellen in den Einstellungen konfigurieren. Falls nur eine Stelle konfiguriert ist, wird diese automatisch ausgewählt und den Nutzer*innen nicht angezeigt.'
        />

        <Textfield
          label='Aufforderung zur Eingabe der E-Mail Adresse'
          fullWidth
          value={emailFallbackOverlayEmailText}
          onChange={onEmailTextChange}
          placeholder='z.B. Bitte geben Sie hier Ihre E-Mail Adressen an, an die die Antwort gesendet werden soll.'
          disabled={lockState !== 'canEdit'}
          hint='Dieser Text erklärt den Nutzer*innen, warum sie ihre E-Mail Adresse eingeben müssen und fordert sie dazu auf.'
        />

        <Textfield
          label='Platzhalter für das E-Mail Feld'
          fullWidth
          value={emailFallbackOverlayEmailPlaceholderText}
          onChange={onEmailPlaceholderTextChange}
          placeholder='z.B. max.mustermann@email.com'
          disabled={lockState !== 'canEdit'}
          hint='Dieser Text wird als Platzhalter in das E-Mail Feld eingefügt, in das die Nutzer*innen ihre E-Mail Adresse eingeben.'
        />

        <Textfield
          label='Beschreibungstext für den Inhalt der E-Mail'
          fullWidth
          value={emailFallbackOverlayContentText}
          onChange={onContentTextChange}
          rowsMax={3}
          multiline
          placeholder='z.B. Ihre bisherige Konversation inklusive der letzten, unbeantworteten Nachrichten wird automatisch an die E-Mail angehängt.'
          disabled={lockState !== 'canEdit'}
          hint='Dieser Text erklärt den Nutzer*innen, was in der E-Mail enthalten sein soll und fordert sie auf, den Text ggf. mit zusätzlichen Informationen über das Textfeld zu ergänzen.'
        />

        <Textfield
          label='Platzhalter für das Textfeld für zusätzliche Informationen'
          fullWidth
          value={emailFallbackOverlayAdditionalTextPlaceholder}
          onChange={onAdditionalTextPlaceholderChange}
          placeholder='z.B. Hier können Sie zusätzliche Informationen eingeben.'
          disabled={lockState !== 'canEdit'}
          hint='Dieser Text wird als Platzhalter in das Textfeld für zusätzliche Informationen eingefügt, in das die Nutzer*innen ihre zusätzlichen Informationen eingeben können.'
        />

        <Textfield
          label='Text für den Senden Button'
          fullWidth
          value={emailFallbackOverlaySendButtonText}
          onChange={onSendButtonTextChange}
          placeholder='E-Mail senden'
          disabled={lockState !== 'canEdit'}
          hint='Der Text für den Button, mit dem die Nutzer*innen ihre E-Mail abschicken können.'
        />
      </div>
    </>
  )
}
