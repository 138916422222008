import { makeStyles } from 'tss-react/mui'
import { Grid, IconButton, Typography } from '@mui/material'
import { AlephAlphaModuleNodeProperties, Chart } from '../../../../../@types/Flowchart/types'
import React, { useEffect, useState } from 'react'
import { cloneDeep, update } from 'lodash'
import EditableTypography from 'components/TextInput/EditableTypography'
import VariablesAutosuggestSelect from '../Variables/VariablesAutosuggestSelect'
import { Button } from 'components/Buttons'
import CustomizedTooltip from 'components/Tooltips/CustomContentTooltip'

const useStyles = makeStyles()((theme) => ({
  metadataContainer: {
    marginTop: theme.spacing(2),
  },
}))

type GetAlephAlphaGetAnswerMetadataViewProps = {
  chart: Chart
  setIsSaveDisabled: (isSaveDisabled: boolean) => void
  setStateCallback: (chart: Chart) => void
}

export default function GetAlephAlphaGetAnswerMetadataView({
  chart: chartProps,
  setIsSaveDisabled,
  setStateCallback,
}: GetAlephAlphaGetAnswerMetadataViewProps): React.ReactElement {
  const { classes } = useStyles()
  const [metadata, setMetadata] = useState<AlephAlphaModuleNodeProperties['metadataToSend']>({})
  const [chart, setChart] = useState<Chart>(chartProps)

  /**
   * Updates metadata in chart.
   * @param newMetadata
   */
  function updateMetadataInChart(
    newMetadata: AlephAlphaModuleNodeProperties['metadataToSend'],
    newChart?: Chart,
  ): void {
    const _chart = newChart ?? chart

    const selectedId = _chart.selected.id
    if (!selectedId) return
    const aa = _chart.nodes[selectedId].properties.alephalpha
    if (!aa) return
    aa.metadataToSend = newMetadata
    _chart.nodes[selectedId].properties.alephalpha = aa
    setStateCallback(_chart)
  }

  /**
   * Checks if the newly entered key is valid.
   * @param newKey
   */
  function isValidNewMetadataKey(newKey: string): {
    isValid: boolean
    message?: string
  } {
    if (newKey === '') return { isValid: false, message: 'Metadaten-Key darf nicht leer sein' }

    if (Object.keys(metadata ?? {}).includes(newKey)) {
      return { isValid: false, message: 'Metadaten-Key existiert bereits.' }
    }

    return { isValid: true }
  }

  /**
   * Handles key change.
   * @param newKey
   */
  function onKeyChange(origKey: string, newKey: string): void {
    // update key in state
    const value = (metadata ?? {})[origKey]
    const newMetadata = { ...metadata, [newKey]: value }
    if (newKey !== origKey) delete newMetadata[origKey]
    setMetadata(newMetadata)

    // update metadata in chart
    updateMetadataInChart(newMetadata)
  }

  /**
   * Handles value change.
   * @param newChart
   * @param prevSelectedVarIds
   * @param newSelectedVarIds
   */
  function onValueChange(
    dataKey: string,
    newChart: Chart,
    prevSelectedVarIds: string[],
    newSelectedVarIds: string[],
  ): void {
    let newValue = ''
    if (newSelectedVarIds && newSelectedVarIds.length > 0) newValue = `%${newSelectedVarIds[0]}`
    const newMetadata = { ...metadata, [dataKey]: newValue }

    // update value in state
    setMetadata(newMetadata)

    // update chart
    updateMetadataInChart(newMetadata, newChart)
  }

  /**
   * Adds new entry to metdata object
   */
  function addNewMetadata(): void {
    const newMetadata = { ...metadata, '': '' }
    setMetadata(newMetadata)
  }

  /**
   * Deletes metadata with dataKey key
   */
  function onDeleteMetadata(dataKey: string): void {
    const newMetadata = { ...metadata }
    delete newMetadata[dataKey]

    // update value in state
    setMetadata(newMetadata)

    // update chart
    updateMetadataInChart(newMetadata)
  }

  useEffect(
    function () {
      // set metadata in state
      const selectedNodeId = chartProps.selected.id
      if (!selectedNodeId) return

      const metadataFromChart = chartProps.nodes[selectedNodeId].properties.alephalpha?.metadataToSend
      setMetadata(cloneDeep(metadataFromChart))
      setChart(chartProps)
    },
    [chartProps],
  )

  return (
    <div>
      <Typography>
        Metadaten werden dem Request zum Finden der Antwort beigefügt und können verwendet werden, um die Antwortfindung
        zu verbessern. Metadaten werden für jeden Assistenten definiert und müssen exakt wie definiert gesetzt werden.
        Alle Werte der Metadaten werden als <i>Text</i> behandelt.
      </Typography>

      <Grid container spacing={2} className={classes.metadataContainer}>
        <Grid item xs={5}>
          <Typography fontWeight={'bolder'}>Key</Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography fontWeight={'bolder'}>Value</Typography>
        </Grid>
        {Object.keys(metadata ?? {}).map((dataKey: string) => {
          const value = (metadata ?? {})[dataKey]?.replace('%', '')
          return (
            <>
              <Grid item xs={4} key={`key-${dataKey}`}>
                <EditableTypography
                  value={dataKey}
                  onChange={(newKey: string): void => {
                    onKeyChange(dataKey, newKey)
                  }}
                  submitOnBlur
                  isValidNewValue={isValidNewMetadataKey}
                  placeholder='Metadata Key'
                  variant='h4'
                />
              </Grid>
              <Grid item xs={8} key={`value-${dataKey}`}>
                <div style={{ display: 'flex' }}>
                  {/* For now, we only need variable as values. In the future we want to be able to use text and variables (just like in the setVariable node) */}
                  <div style={{ width: '100%' }}>
                    <VariablesAutosuggestSelect
                      selectedVariableIds={value ? [value] : []}
                      chart={chart}
                      usageType='consume'
                      allowMultiSelectWithinNode
                      isMulti={false}
                      isClearable={true}
                      onChange={(newChart: Chart, prevSelectedVarIds: string[], newSelectedVarIds: string[]): void => {
                        onValueChange(dataKey, newChart, prevSelectedVarIds, newSelectedVarIds)
                      }}
                    />
                  </div>
                  <CustomizedTooltip
                    disableInteractive
                    content={<Typography variant='body1'>Metadatenpunkt entfernen</Typography>}
                    elements={
                      <IconButton style={{ marginLeft: '8px' }} onClick={(): void => onDeleteMetadata(dataKey)}>
                        <i className='ri-close-line'></i>
                      </IconButton>
                    }
                  />
                </div>
              </Grid>
            </>
          )
        })}
        <Grid item xs={12} justifyContent={'center'}>
          <Button onClick={addNewMetadata} disabled={Object.keys(metadata ?? {}).includes('')}>
            Hinzufügen
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}
