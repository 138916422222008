import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

type Props = {
  open: boolean
  onCancle: () => void
  onConfirm: () => void
}

export function FieldsDialog(props: Props): React.ReactElement {
  return (
    <Dialog
      open={props.open}
      keepMounted
      onClose={props.onCancle}
      aria-labelledby='alert-dialog-slide-title'
      aria-describedby='alert-dialog-slide-description'
    >
      {/* TODO: I18N */}
      <DialogTitle id='alert-dialog-slide-title'>Warnung</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-slide-description'>
          Nicht alle Felder des PDFs sind einem Bereich oder Unterbereich zugeordnet. Nicht zugeordnete Felder werden
          bei der Generierung des Assistenten ignoriert.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancle}>Zurück</Button>
        <Button onClick={props.onConfirm}>Fortfahren</Button>
      </DialogActions>
    </Dialog>
  )
}

export function DeleteFileDialog(props: Props): React.ReactElement {
  return (
    <Dialog
      open={props.open}
      keepMounted
      onClose={props.onCancle}
      aria-labelledby='alert-dialog-slide-title'
      aria-describedby='alert-dialog-slide-description'
    >
      {/* TODO: I18N */}
      <DialogTitle id='alert-dialog-slide-title'>Warnung</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-slide-description'>
          Sie sind dabei das hochgeladene PDF zu löschen. Wenn Sie das PDF löschen, löschen Sie auch Ihre bisherige
          Bearbeitung des PDFs, können aber ein neues PDF hochladen.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancle}>Zurück</Button>
        <Button onClick={props.onConfirm}>Löschen</Button>
      </DialogActions>
    </Dialog>
  )
}
