import React, { memo, useEffect, useRef, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { TextField, Theme, Typography } from '@mui/material'
import Fuse from 'fuse.js'
// Types
import { Chart, Node } from '../../../../../../../@types/Flowchart/types'
import { TranslationFile } from '../../../../../../../@types/Translations/types'
import { Card } from '../../../../../../../@types/SmartCards/types'
import InfoBox from 'components/InfoBoxes/InfoBox'
import { countSmartCardUsage } from 'utils/smartCardUtils'

const useStyles = makeStyles()((theme: Theme) => ({
  hintContainer: {
    marginBottom: theme.spacing(2),
  },
  similarNamesContainer: {
    marginTop: theme.spacing(2),
  },
}))

type CardNameProps = {
  chart: Chart
  node: Node
  origNode: Node // if not undefined, this card was already created and is now edited
  card: Card
  isClonedCard: boolean // true if the card is already used elsewhere and a clone was created to be used in this node
  translations: TranslationFile
  setNameCallback: (cardName: string) => void
  handleNextStage: () => void // Since this has only one Input press Enter to continue - this is the callback for that
}

export default memo(
  /**
   * translations: for checking if a name already exists - no modifications
   * node: for prefilling an existing name
   * origNode: for checking if the name changed - because otherwise we would have a hit that this name already exists even if it is beacuase of this card/node
   * @returns Editor for the Card Name
   */
  function CardName({
    chart,
    node: origNode,
    origNode: existingNode,
    card,
    isClonedCard,
    translations,
    setNameCallback,
    handleNextStage,
  }: CardNameProps): React.ReactElement {
    const { classes } = useStyles()
    const [origCardName, setOrigCardName] = useState<string>()
    const fuseRef = useRef<Fuse<string>>()
    const [similarCardNames, setSimilarCardNames] = useState<string[]>()
    const [newName, setNewName] = useState<string>(origNode.properties.card ?? '')
    const [error, setError] = useState<string>()

    function hasError(newName: string): boolean {
      const cardUsage = countSmartCardUsage(chart, newName)

      if (cardUsage > 0) {
        setError('Dieser Karten Name existiert bereits.')
        return true
      } else {
        setError(undefined)
        return false
      }
    }

    function onNameChange(event: React.ChangeEvent<HTMLInputElement>): void {
      const newName = event.target.value
      setNewName(newName)
      hasError(newName)
      setNameCallback(newName)
    }

    useEffect(function () {
      setOrigCardName(existingNode.properties.card)
      const existingCardNames = Object.keys(translations.ac[translations.primaryLanguage])
      fuseRef.current = new Fuse(existingCardNames, {
        threshold: 0.2,
      })
    }, [])

    useEffect(
      function () {
        if (!fuseRef.current || !origNode.properties.card || origNode.properties.card.length === 0) return
        // on card name change, search for similar names - result is already sorted
        const similarNames = fuseRef.current
          .search(origNode.properties.card)
          .map((result) => result.item)
          .filter((similarName) => similarName !== origNode.properties.card)
        setSimilarCardNames(similarNames)
      },
      [origNode.properties.card],
    )

    return (
      <div>
        <div id='editor'>
          {isClonedCard && (
            <div id='cloned-card-hint' className={classes.hintContainer}>
              <InfoBox title='Hinweis'>
                <Typography>
                  Die gewählte Smart wird bereits in einer anderen Node verwendet. Um ungewollte Änderungen an der Smart
                  Card zu verhindern, wurde eine Kopie der gewählten Smart Card erstellt und ausgewählt.
                </Typography>
              </InfoBox>
            </div>
          )}

          <form
            onSubmit={(): void => {
              if (!error) {
                setNameCallback(newName)
                handleNextStage()
              }
            }}
          >
            <TextField
              id='create-new-card-name'
              label='Karten Name'
              value={newName}
              fullWidth
              onChange={onNameChange}
              margin='normal'
              autoFocus
              inputProps={{
                autoComplete: 'off',
              }}
              autoComplete='off'
              helperText={<Typography variant='subtitle2'>{error}</Typography>}
              error={typeof error === 'string' ? true : false}
            />
          </form>
        </div>
        {similarCardNames && similarCardNames.length > 0 ? (
          <div className={classes.similarNamesContainer}>
            <Typography>
              <b>Hinweis:</b> Es existieren Karten mit ähnlichen Namen.
            </Typography>
            <ul>
              {similarCardNames.slice(0, 5).map((name, index) => {
                return (
                  <li key={`similar-card-name-${index}`}>
                    <Typography>{name}</Typography>
                  </li>
                )
              })}
            </ul>
          </div>
        ) : null}
      </div>
    )
  },
)
