import React from 'react'
import { Route, Routes, Navigate, useNavigate, useLocation, useResolvedPath, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { makeStyles } from 'tss-react/mui'
import { Grid } from '@mui/material'

// constants
import {
  ROUTE_BOTID_KNOWLEDGE_NLU_CONVMANAGEMENT,
  ROUTE_BOTID_KNOWLEDGE_NLU_INFOMANAGEMENT,
  ROUTE_BOTID_KNOWLEDGE_NLU_TEMPLATES,
  ROUTE_BOTID_KNOWLEDGE_NLU_CONVMANAGEMENT_MINDMAP,
  APP_TITLE,
  ROUTE_BOTID_KNOWLEDGE_NLU_MODELMANAGEMENT,
  MODULE_ID_CONVAISE_NLU,
  MODULE_ID_CONVAISE_LLM_NLU,
} from 'utils/constants'
import { ExportKnowledgeDbButton } from 'components/Buttons'

import { ContentPageHeader } from 'components/Page/ContentPage'
import ConversationManagement from './ConversationManagement/ConversationManagement'
import InformationManagement from './InformationsManagement/InformationManagement'
import EndpointManagement from './EndpointManagement/oldNLU/EndpointManagement'
import SubpageCard from 'components/Cards/SubpageCard'

// Assets
// TODO: Switch these to knowledge specific graphics
import svgInsights from 'assets/img/analytics/analytics_insights.svg'
import svgModelmanagement from 'assets/img/analytics/analytics_modelmanagement.svg'
import svgData from 'assets/img/analytics/analytics_data.svg'
// hooks
import { AnswersContextProvider } from 'hooks/contexts/answers-context'
import { LockingContextProvider } from 'hooks/contexts/locking-context'
import { DatamangementContextProvider } from 'hooks/contexts/datamanagement-context'
import { ModellmanagementContextProvider } from 'hooks/contexts/modellmanagement-context'
import { ModellmanagementLLMNLUContextProvider } from 'hooks/contexts/modelmanagement-llm-context'
import ModellManagementLLMNLU from './EndpointManagement/ModellManagementLLMNLU'

const useMenuStyles = makeStyles()({
  root: {},
})

function KnowledgeMenu(): React.ReactElement {
  const { classes } = useMenuStyles()
  const navigate = useNavigate()
  const url = useResolvedPath('').pathname

  const exportButton = <ExportKnowledgeDbButton />

  return (
    <div>
      <Helmet>
        <title>{APP_TITLE} - Wissensverwaltung</title>
      </Helmet>
      <ContentPageHeader title='Wissensverwaltung' actions={[exportButton]} />
      <Grid container spacing={6}>
        <Grid item>
          <SubpageCard
            graphic={svgInsights}
            graphicAlt='Grafik: Mind Map'
            title='Wissen'
            description='Hier werden Antworten des Assistenten angelegt und verwaltet.'
            onClick={(): void => {
              navigate(
                url + ROUTE_BOTID_KNOWLEDGE_NLU_CONVMANAGEMENT + ROUTE_BOTID_KNOWLEDGE_NLU_CONVMANAGEMENT_MINDMAP,
              )
            }}
            disabled={false}
          />
        </Grid>
        <Grid item>
          <SubpageCard
            graphic={svgData}
            graphicAlt='Grafik: Daten'
            title='Daten'
            description='Hier werden spezifische Informationen, wie z.B. Telefonnummern oder Öffnungszeiten, die in den Antworten genutzt werden können, verwaltet.'
            onClick={(): void => {
              navigate(url + ROUTE_BOTID_KNOWLEDGE_NLU_INFOMANAGEMENT)
            }}
            disabled={false}
          />
        </Grid>
        <Grid item>
          <SubpageCard
            graphic={svgModelmanagement}
            graphicAlt='Grafik: Hinzufügen neuer Texte'
            title='Modellmanagement'
            description='Hier werden Fragen aus dem Live-Betrieb des Assistenten angezeigt und zu Antworten zu geordnet, um das Modell des Assistenten zu verbessern.'
            onClick={(): void => {
              navigate(url + ROUTE_BOTID_KNOWLEDGE_NLU_MODELMANAGEMENT)
            }}
            disabled={false}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default function KnowledgeNlu(): React.ReactElement {
  const { pathname: path } = useLocation()
  const { moduleConfigId } = useParams()

  return (
    <Routes>
      <Route path={''} element={<KnowledgeMenu />} />
      <Route
        path={ROUTE_BOTID_KNOWLEDGE_NLU_CONVMANAGEMENT + '/*'}
        element={
          <LockingContextProvider lockScopes={['knowledgeNlu']}>
            <DatamangementContextProvider>
              <AnswersContextProvider>
                <ConversationManagement />
              </AnswersContextProvider>
            </DatamangementContextProvider>
          </LockingContextProvider>
        }
      />
      {/* Conversations Management */}
      <Route
        path={ROUTE_BOTID_KNOWLEDGE_NLU_INFOMANAGEMENT + '/*'}
        element={
          <LockingContextProvider lockScopes={['dataentry']}>
            <InformationManagement />
          </LockingContextProvider>
        }
      />

      <Route path={ROUTE_BOTID_KNOWLEDGE_NLU_TEMPLATES + '/*'} element={<p> Templates </p>} />
      <Route
        path={ROUTE_BOTID_KNOWLEDGE_NLU_MODELMANAGEMENT + '/*'}
        element={
          /* also locks specific scope because it (can) edit answers */
          <LockingContextProvider lockScopes={['knowledgeNlu']}>
            {/* <DatamangementContextProvider> */}
            <AnswersContextProvider>
              {moduleConfigId === MODULE_ID_CONVAISE_NLU ? (
                <ModellmanagementContextProvider>
                  <EndpointManagement />
                </ModellmanagementContextProvider>
              ) : moduleConfigId === MODULE_ID_CONVAISE_LLM_NLU ? (
                <ModellmanagementLLMNLUContextProvider>
                  <ModellManagementLLMNLU />
                </ModellmanagementLLMNLUContextProvider>
              ) : null}
            </AnswersContextProvider>
            {/* </DatamangementContextProvider> */}
          </LockingContextProvider>
        }
      />
      {/* <Redirect to={path} /> */}
      <Route path='*' element={<Navigate to={path} />} />
    </Routes>
  )
}
