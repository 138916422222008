import React, { useState, useEffect } from 'react'
import { cloneDeep } from 'lodash'
import { v4 as uuid } from 'uuid'

import CreatableSelect, { ChangeValue, ActionType } from '../../../../../components/Dropdown/ReactSelectDropdown'

// Types
import { Chart, DisplayVariableOption, Variable, VariableType } from '../../../../../@types/Flowchart/types'

import {
  ALLOWEDCHARSFORVARNAME,
  removeVariableFromNode,
  getResultVariable,
  getSelectedVariables,
  getAllVariableNamesOfNode,
  assignACFieldToVariable,
  removeVariableFromACField,
} from '../../../../../utils/chartUtils'
import { MultiSelectDropdown, SelectDropdown, ActionMeta } from 'components/Dropdown'

export type OnChangeOptions = {
  acFieldId?: string
  acFieldType?: string
  isChoiceSet?: boolean
  acChoice?: string
  apiConfigId?: string
  iter?: string
  clear?: boolean
}

type VariablesAutosuggestSelectProps = {
  chart: Chart
  onChange: (chart: Chart, prevSelectedVarIds: string[], selectedVarIds: string[], options: OnChangeOptions) => void // callback function to let parent know of change
  usageType: 'consume' | 'set' // usagetype of the variable, set if variable gets set, consume if the variable value is read
  // props
  isResult?: boolean // indicates if select specifies variable for interaction with AC action, ONLY USED ONCE IN AC SELECTED
  isMulti?: boolean // allows selection of multiple variables
  isClearable?: boolean
  isCreatable?: boolean
  selectedVariableIds?: string[] // this makes the component controlled. can be used to provide the selected variable. If undefined, the component finds the selected variables by itself.
  allowMultiSelectWithinNode?: boolean // if true, different VariablesAutosuggestSelect components within a single node can select the same variable. In most cases you probably do not want this.
  // API node
  apiConfigId?: string
  // smart card node
  acFieldId?: string // id of the input field
  acFieldType?: string // type of the AC field
  isChoiceSet?: boolean // indicates whether field is a choiceset
  acChoice?: string // individual choice of choiceset (if field is a choiceset)
}

export default function VariablesAutosuggestSelect({
  chart: originalChart,
  usageType,
  isResult = false,
  isMulti = false,
  isClearable = true,
  isCreatable = true,
  allowMultiSelectWithinNode = false,
  selectedVariableIds,
  apiConfigId,
  acFieldId,
  acFieldType,
  isChoiceSet,
  acChoice,
  onChange: onChangeCallback,
}: VariablesAutosuggestSelectProps): React.ReactElement {
  const [localChart, setChart] = useState<Chart>(originalChart)
  const [forbiddenVariableNames, setForbiddenVariableNames] = useState<string[]>([]) // holds all variable names that are forbidden (e.g. because they are already used in the node)
  const [allOptions, setAllOptions] = useState<DisplayVariableOption[]>([]) // holds all possible options that populate Autocomplete component
  const [selectedOptions, setSelectedOptions] = useState<DisplayVariableOption[]>([])

  // ========== VARIABLE OPERATIONS =============

  /**
   * Retrieves display name of all variables from the chart.
   * Used to populate Autosuggest with existing variables.
   * Filters options depending on _allowMultiSelectWithinNode_ parameter.
   * By default, variables cannot be selected in more than 1 VariablesAutosuggestSelect dropdown.
   * @param chart
   * @param selectedVarIds
   */
  function getAllVariables(chart: Chart, selectedVarIds: string[]): DisplayVariableOption[] {
    const selectedId = chart.selected?.id
    if (typeof selectedId === 'undefined') return []
    const node = chart.nodes[selectedId]
    if (typeof node === 'undefined') return []

    const variableIdsOfNode = Object.keys(node.properties.variables ?? {})
    const variables: Variable[] = []
    const varKeys = Object.keys(chart.variables)

    varKeys.forEach((key) => {
      // check if variable is already used in node (and if so, if it is not the current select). If it is already used somewhere else and multi select is not allowed, do not add it
      // also check if variable is a system variable that can only be consumed, not set. Only display if usageType is consume
      if (
        allowMultiSelectWithinNode ||
        (!allowMultiSelectWithinNode &&
          (!variableIdsOfNode.includes(key) || (variableIdsOfNode.includes(key) && selectedVarIds.includes(key))) &&
          (chart.variables[key].type === VariableType.System ? usageType === 'consume' : true))
      ) {
        const variable = chart.variables[key]
        variables.push(variable)
      }
    })

    const returnValue = variables.map((variable) => ({
      value: variable.id,
      label: variable.displayName,
    }))

    return returnValue
  }

  /**
   * Finds all variables in chart that have to be displayed in the current field.
   * @param {Chart} chart
   */
  function getSelected(chart: Chart): DisplayVariableOption[] {
    if (isResult) {
      return getResultVariable(chart)
    }

    const options = {
      acFieldId,
      acChoice,
      apiConfigId,
      selectedVariableIds,
    }

    return getSelectedVariables(chart, options)
  }

  // ========== HELPER ============

  /**
   * Finds and returns currently displayed options.
   * This is needed because the {@material-ui/lab/Autocomplete} component returns different values
   * and we need to 'create' the correct type.
   * @param {string | DisplayVariableOption | (string | DisplayVariableOption)[]} newValue
   */
  function getDisplayedVariableOptionArray(
    newValue: DisplayVariableOption | DisplayVariableOption[] | null,
  ): DisplayVariableOption[] {
    const value: DisplayVariableOption[] = []
    if (newValue === null) return []
    if (Array.isArray(newValue) && newValue.length > 0) {
      // string[] or DisplayVariableOption[]
      for (const v of newValue) {
        if (typeof v === 'string') {
          // find option for each value string
          const option = allOptions.find((option) => option.value === v)
          if (typeof option !== 'undefined') value.push(option)
        } else {
          value.push(v)
        }
      }
    } else if (!Array.isArray(newValue)) {
      // string or DisplayVariableOption
      if (typeof newValue === 'string') {
        const option = allOptions.find((option) => option.value === newValue)
        if (typeof option !== 'undefined') value.push(option)
      } else {
        value.push(newValue)
      }
    }
    return value
  }

  // ========== HANDLER =============

  /**
   * Handles variable de-select.
   * @param {Chart} origChart
   * @param {string} varId
   */
  function onRemoveValue(origChart: Chart, varId: string): Chart {
    let chart = cloneDeep(origChart)
    const nodeId = chart.selected?.id
    if (typeof nodeId === 'undefined') return chart
    const node = chart.nodes[nodeId]
    if (typeof node === 'undefined') return chart
    let variable = chart.variables[varId]
    if (typeof variable === 'undefined') return chart

    if (acFieldId) {
      variable = removeVariableFromACField(chart, variable, acFieldId, acChoice)
    }
    chart.variables[varId] = variable

    chart = removeVariableFromNode(chart, usageType, varId, nodeId)

    if (isResult) {
      delete node.properties.varname
      chart.nodes[nodeId] = node
    }

    return chart
  }

  /**
   * Handles clear. Removes all variables' associations with the current select field.
   * @param {Chart} origChart
   */
  function onClear(origChart: Chart): Chart {
    let chart = cloneDeep(origChart)

    for (const variable of selectedOptions) {
      const varId = variable.value
      chart = onRemoveValue(chart, varId)
    }

    if (typeof chart.selected.id === 'undefined') return chart
    if (isResult) delete chart.nodes[chart.selected.id].properties.varname

    return chart
  }

  /**
   * Handle select action.
   * @param {Chart} origChart
   * @param {DisplayVariableOption[]} values all selected values (incl. new ones)
   */
  function onSelect(origChart: Chart, values: DisplayVariableOption[]): Chart {
    let chart = cloneDeep(origChart)

    // remove old variables if no multi select
    if (!isMulti) {
      selectedOptions.forEach((option) => {
        chart = onRemoveValue(chart, option.value)
      })
    }

    if (typeof chart.selected.id === 'undefined' || typeof chart.nodes[chart.selected.id] === 'undefined') return chart
    const nodeId = chart.selected.id
    const node = chart.nodes[nodeId]

    // determine new values by finding diff
    const newValues = values.filter((value) => !selectedOptions.some((v) => v.value === value.value))

    for (const newVariable of newValues) {
      const varId = newVariable.value
      let variable = chart.variables[varId]
      if (!variable.usage) variable.usage = {}

      // differentiate between AC field and "normal field"
      if (acFieldId && acFieldType && node.type === 'basic/adaptiveCard') {
        // ---- AC ----
        if (!variable.usage[nodeId])
          variable.usage[nodeId] = { nodeType: 'AC', set: { usageCount: 0 }, consume: { usageCount: 0 } }
        variable = assignACFieldToVariable(chart, variable, acFieldId, acFieldType, acChoice)
      } else {
        // ---- normal field -----
        if (!variable.usage[nodeId])
          variable.usage[nodeId] = { nodeType: 'default', set: { usageCount: 0 }, consume: { usageCount: 0 } }
      }

      // increase usageCount
      variable.usageCount += 1
      if (usageType === 'set') variable.usage[nodeId].set.usageCount += 1
      else variable.usage[nodeId].consume.usageCount += 1

      // add variable to global
      chart.variables[varId] = variable

      // add variable to node if not yet added
      if (!node.properties.variables) node.properties.variables = {}
      if (!Object.keys(node.properties.variables).includes(varId)) {
        // variable does not already exist on node
        node.properties.variables[varId] = {
          id: varId,
          set: { usageCount: 0 },
          consume: { usageCount: 0 },
        }
      }

      // increase usage count of node variable object
      if (usageType === 'set') node.properties.variables[varId].set.usageCount += 1
      else node.properties.variables[varId].consume.usageCount += 1

      // RESULT OF INTERACTION WITH ADAPTIVE CARD
      // if variable stores result of interaction with Action.Submit of Adaptive Card, set options and varname in node.properties
      if (isResult) node.properties.varname = varId
    }

    chart.nodes[nodeId] = node

    return chart
  }

  /**
   * Handles variable creation.
   * @param {Chart} origChart
   * @param {string} id
   * @param {string} displayName
   */
  function onCreate(origChart: Chart, id: string, displayName: string): Chart {
    let chart = cloneDeep(origChart)

    const nodeId = chart.selected?.id
    if (typeof nodeId === 'undefined') return chart
    const node = chart.nodes[nodeId]
    if (typeof node === 'undefined') return chart

    // remove old variables if no multi select
    if (!isMulti) {
      selectedOptions.forEach((option) => {
        chart = onRemoveValue(chart, option.value)
      })
    }

    const nodeType = typeof acFieldId !== 'undefined' ? 'AC' : 'default'
    let variable: Variable = {
      id,
      displayName,
      usageCount: 1,
      type: VariableType.User,
      usage: {
        [nodeId]: { nodeType, set: { usageCount: 0 }, consume: { usageCount: 0 } },
      },
    }

    if (usageType === 'set') variable.usage[nodeId].set.usageCount += 1
    else variable.usage[nodeId].consume.usageCount += 1

    if (nodeType === 'AC' && acFieldId && acFieldType) {
      // handle Input fields and acChoices
      variable = assignACFieldToVariable(chart, variable, acFieldId, acFieldType, acChoice)
    }

    // add variable to global
    chart.variables[id] = variable

    // Add variable to node
    if (typeof node.properties.variables === 'undefined') node.properties.variables = {}
    if (!Object.keys(node.properties.variables).includes(id)) {
      node.properties.variables[id] = {
        id,
        set: { usageCount: variable.usage[nodeId].set.usageCount },
        consume: { usageCount: variable.usage[nodeId].consume.usageCount },
      }
    }

    if (isResult) node.properties.varname = id

    chart.nodes[nodeId] = node
    return chart
  }

  /**
   * Callback function for changes in the Autocomplete field.
   * @param {ValueType<DisplayVariableOption, boolean>} newValue boolean indicates if multi - in this case its an arry
   * @param {ActionMeta<DisplayVariableOption>} action
   */
  function onChange(newValue: DisplayVariableOption | DisplayVariableOption[], action: ActionType): void {
    const prevSelectedOptions = cloneDeep(selectedOptions)

    if (Array.isArray(newValue)) {
      // ensure that no null values are present
      newValue = newValue.filter((value) => value !== null)
    }

    const newSelectedOptions = getDisplayedVariableOptionArray(
      // newValue as DisplayVariableOption | DisplayVariableOption[] | null
      newValue as DisplayVariableOption | DisplayVariableOption[],
    )

    let chart = cloneDeep(localChart)

    switch (action.action) {
      case 'select-option': {
        chart = onSelect(chart, newSelectedOptions)
        break
      }
      case 'remove-value': {
        const removedValues = prevSelectedOptions.filter(
          (option) => !newSelectedOptions.some((o) => option.value === o.value),
        )
        for (const removedValue of removedValues) {
          chart = onRemoveValue(chart, removedValue.value)
        }
        break
      }
      case 'create-option': {
        const createdValues = newSelectedOptions.filter(
          (option) => !prevSelectedOptions.some((o) => o.value === option.value) || option.inputValue,
        )
        // this loop should not really be needed, because there should only be a single newly created value
        // just to be safe
        for (const createdValue of createdValues) {
          // new option -> we need to create id
          createdValue.value = uuid()
          chart = onCreate(chart, createdValue.value, createdValue.label)
        }
        break
      }
      case 'clear': {
        chart = onClear(chart)
        break
      }
      default:
        break
    }

    const allAvailableOptions = getAllVariables(
      chart,
      newSelectedOptions.map((option) => option.value),
    )
    setSelectedOptions(newSelectedOptions)
    setAllOptions(allAvailableOptions)
    const prevSelectedVarIds = prevSelectedOptions.map((option) => option.value)
    const selectedVarIds = newSelectedOptions.map((option) => option.value)

    const callbackOptions = {
      acFieldId,
      acFieldType,
      isChoiceSet,
      acChoice,
      apiConfigId,
    }

    setChart(chart)
    onChangeCallback(cloneDeep(chart), prevSelectedVarIds, selectedVarIds, callbackOptions)
  }

  // ========== USE EFFECT =============

  useEffect(
    function () {
      const selected = getSelected(originalChart)
      const forbiddenVariableNames = getAllVariableNamesOfNode(originalChart)
      const allOptions = getAllVariables(
        originalChart,
        selected.map((option) => option.value),
      )
      setForbiddenVariableNames(forbiddenVariableNames)
      setAllOptions(allOptions)
      setSelectedOptions(selected)
      setChart({ ...originalChart })
    },
    [originalChart, selectedVariableIds],
  )

  // ========== RENDER =============

  return isMulti && !isResult ? (
    <MultiSelectDropdown
      isCreatable={isCreatable}
      isClearable={isClearable}
      onChange={onChange}
      options={allOptions}
      selected={selectedOptions}
      placeholder='Variable(n)'
      noOptionsMessage='Keine Variablen'
      createOptionText={(inputValue): string => `Variable "${inputValue}" erstellen`}
      allowedChars={ALLOWEDCHARSFORVARNAME}
      forbiddenLabels={forbiddenVariableNames}
    />
  ) : (
    <SelectDropdown
      isCreatable={isCreatable}
      isClearable={isClearable}
      onChange={onChange}
      options={allOptions}
      selected={selectedOptions[0] ?? undefined}
      placeholder='Variable'
      noOptionsMessage='Keine Variablen'
      createOptionText={(inputValue): string => `Variable "${inputValue}" erstellen`}
      allowedChars={ALLOWEDCHARSFORVARNAME}
      forbiddenLabels={forbiddenVariableNames}
    />
  )

  // <CreatableSelect
  //   isMulti={isMulti && !isResult}
  //   isCreatable={isCreatable}
  //   isClearable={isClearable}
  //   onChange={onChange}
  //   options={allOptions}
  //   selectedOptions={selectedOptions}
  //   placeholder={isMulti ? 'Variable(n)' : 'Variable'}
  //   noOptionsMessage={'Keine Variablen'}
  //   createOptionText={(inputValue) => `Variable "${inputValue}" erstellen`}
  //   allowedChars={ALLOWEDCHARSFORVARNAME}
  // />
}
