import React, { memo } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Node } from '../../../../../@types/Flowchart/types'
import { Typography } from '@mui/material'
import { NODE_NAMES } from 'utils/constants'
import { SvgIconComponent } from '@mui/icons-material'

type StyleProps = {
  hasError?: boolean
}

// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. Unsupported arrow function syntax.
// Arrow function has parameter type of Identifier instead of ObjectPattern (e.g. `(props) => ({...})` instead of `({color}) => ({...})`).
const useStyles = makeStyles<StyleProps>()((theme, props) => ({
  header: {
    background: '#3c4858',
    padding: '6px 13px 6px',
    borderRadius: !props.hasError ? '5px 5px 0 0' : '0px',
    color: 'rgba(255, 255, 255, 0.9)',
    textAlign: 'center',
    margin: '0px',
  },
  content: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
}))

type DefaultNodeContentProps = {
  classes: any
  hasError?: boolean
  Icon?: SvgIconComponent
  displayText: string | React.ReactElement
  node: Node
  color: string
}

export default memo(function DefaultNodeContent({
  classes: parentClasses,
  node,
  Icon,
  color,
  displayText,
  hasError,
}: DefaultNodeContentProps): React.ReactElement {
  const { classes: styles } = useStyles({ hasError })
  const classes = { ...parentClasses, ...styles }

  return (
    <div className={classes.outerNode}>
      <div className={classes.headerContainer}>
        <h5 className={classes.header} style={{ backgroundColor: color }}>
          {NODE_NAMES[node.type]}
          {Icon && <Icon className={classes.headerCardIcon} />}
        </h5>
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.content}>
          {node.type === 'start' ? (
            <Typography variant='body2'>
              <em>Start</em>
            </Typography>
          ) : (
            <Typography variant='body2'>{displayText}</Typography>
          )}
        </div>
      </div>
    </div>
  )
})
