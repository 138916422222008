import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { Button as MUIButton, Icon, CircularProgress } from '@mui/material'
import clsx from 'clsx'

type ButtonType = 'normal' | 'danger' | 'success' | 'warning'

type StyleProps = { type: ButtonType; size: 'normal' | 'small' }

const useStyles = makeStyles<StyleProps>()((theme, props) => ({
  button: {
    // background: (props: StyleProps): string =>
    //   props.type === 'normal'
    //     ? 'inherit' // theme.palette.primary.main -> else it would override the material ui color params which causes problems for normal styling
    //     : props.type === 'success'
    //     ? theme.palette.success.main
    //     : theme.palette.error.main,
    height: props.size === 'normal' ? '56px' : '40px',
    verticalAlign: 'center',
  },
  colorSuccess: {
    background: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.light,
    },
  },
  colorDanger: {
    background: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
  },
  colorWarning: {
    background: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.light,
    },
  },
  startIconContainer: {
    height: '25px',
    width: '25px',
    // color: '#F9FAFB',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 400, // otherwise the label class of the button would set it to 700
  },
  startIconOnlyIcon: {
    marginRight: '0px',
  },
  startIcon: {
    fontSize: '25px',
    display: 'flex',
  },
}))

type ButtonProps = {
  children?: React.ReactNode
  size?: 'small' | 'normal'
  type?: ButtonType
  color?: 'primary' | 'secondary' | undefined
  icon?: string | React.ReactNode
  iconType?: 'mui' | 'remix'
  disabled?: boolean
  onClick: (event: any) => void
  loading?: boolean
  variant?: 'primary' | 'secondary' | 'tertiary'
  className?: string | undefined
}

function SpinnerAdornment(): React.ReactElement {
  return <CircularProgress size={25} />
}

/**
 *
 * @param param0
 * @returns
 */
export default function Button({
  size = 'normal',
  type = 'normal',
  color = 'primary',
  icon,
  iconType = 'mui',
  onClick,
  children,
  disabled,
  loading,
  variant = 'primary',
  className,
}: ButtonProps): React.ReactElement {
  const { classes } = useStyles({ type, size })

  return (
    <MUIButton
      variant={variant === 'primary' ? 'contained' : variant === 'secondary' ? 'outlined' : 'text'}
      disabled={disabled || loading}
      color={color}
      // don't know why, but settings fontSize via classNames does not work
      startIcon={
        loading ? (
          <SpinnerAdornment />
        ) : typeof icon !== 'undefined' ? (
          iconType === 'mui' && typeof icon !== 'string' ? (
            // Mui icon
            // <Icon style={{ fontSize: 25 }}>{icon}</Icon>
            <div className={classes.startIcon}>{icon}</div>
          ) : (
            // Remix icon
            // <div style={{ fontSize: 25 }}>
            <i className={`ri-${icon}`} />
            // </div>
          )
        ) : undefined
      }
      className={className}
      onClick={onClick}
      classes={{
        root: clsx(classes.button, {
          [classes.colorSuccess]: type === 'success',
          [classes.colorDanger]: type === 'danger',
          [classes.colorWarning]: type === 'warning',
        }),
        startIcon: clsx(classes.startIconContainer, {
          [classes.startIconOnlyIcon]: !children,
        }),
      }}
      disableElevation
    >
      {children}
    </MUIButton>
  )
}
