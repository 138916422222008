import React, { useEffect, useState } from 'react'

// @mui/material components
import fillPdf from 'assets/img/pdf/fill-pdf.svg'
import { makeStyles } from 'tss-react/mui'
import { useTheme } from '@mui/material/styles'
import { Grid, TextField, Typography } from '@mui/material'

import { Chart, NodeProperties } from '../../../../../@types/Flowchart/types'
import ContentContainer from '../ContentContainer'
import CardButton from 'components/Buttons/CardButton'
import SPDFForm from './SPDFForm'
import { useBotContext } from 'hooks/contexts/bot-context'
import SPDFTexts from './SPDFTexts'
import CustomContentTooltip from 'components/Tooltips/CustomContentTooltip'

const useStyles = makeStyles()((theme) => ({
  selectedDialogContainer: {},
  title: {},
  container: {
    // height: '100%',
    overflow: 'auto',
  },
  inputWithInfo: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  inputInfoIcon: {
    marginLeft: theme.spacing(1),
    height: '20px',
    color: theme.palette.grey[700],
  },
  marginTopMedium: { marginTop: '15px' },
  trackHelpfulButtonContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  viewContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 158px)',
    overflow: 'auto',
    paddingLeft: '1px',
    paddingRight: '1px',
    paddingBottom: '1px',
  },
}))

type SPDFTextsProps = {
  chart: Chart
  setIsSaveDisabled: (isSaveDisabled: boolean) => void
  setStateCallback: (chart: Chart) => void
}

export default function SPdf({ chart, setIsSaveDisabled, setStateCallback }: SPDFTextsProps): React.ReactElement {
  const { classes } = useStyles()
  const theme = useTheme()
  const { bot } = useBotContext()

  const [currentView, setCurrentView] = useState<'useTemplate' | 'getDownloadUri'>()
  const [isPDFURLButtonDisabled, setIsPDFURLButtonDisabled] = useState<boolean>()
  const [isPDFTemplateButtonDisabled, setisPDFTemplateButtonDisabled] = useState<boolean>()

  /**
   * Sets view to template view.
   * Resets saveUrl view variables in node properties
   */
  function setViewuseTemplate(): void {
    if (!chart.selected.id) return

    delete chart.nodes[chart.selected.id].properties.pdfUrlVariable
    setStateCallback(chart)
    setCurrentView('useTemplate')
  }

  function setViewUrl(): void {
    if (!chart.selected.id) return
    delete chart.nodes[chart.selected.id].properties.pdfTexts
    setStateCallback(chart)
    setCurrentView('getDownloadUri')
  }

  useEffect(function () {
    if (bot) {
      // check if there are any pdf documents, if not disable buttons
      if (Object.values(bot.documentInfos).filter((doc) => doc.documentType === 'pdf').length === 0) {
        setIsPDFURLButtonDisabled(true)
        setisPDFTemplateButtonDisabled(true)
      }
      // node is already setUp - choose view based on set properties
      if (chart.selected.id && chart.nodes[chart.selected.id].properties.documentId) {
        if (chart.nodes[chart.selected.id].properties.pdfUrlVariable) {
          setCurrentView('getDownloadUri')
        } else {
          setCurrentView('useTemplate')
        }
      }
    }
  }, [])

  return (
    // <div className={classes.container}>
    <ContentContainer
      headerText='PDF generieren'
      descriptionText='Die mit dem gewählten PDF zusammenhängende Variablen werden verwendet, um die Eingabefelder des PDFs zu füllen. Nutzer*innen können das ausgefüllte PDF herunterladen, kontrollieren und auch weiterhin bearbeiten.Sie haben die Wahl eine Vorlage für die Chat-Nachrichten zu wählen oder die Download-Url des PDFs in einer Variable zu speichern und selber die Nachrichten des Assistenten für den Download zu gestalten.'
      svg={fillPdf}
    >
      <div className={classes.container}>
        <Grid container justifyContent={'center'} spacing={2} className={classes.trackHelpfulButtonContainer}>
          <Grid item xs={12}>
            <div className={classes.inputWithInfo}>
              <Typography>Welchen PDF Modus möchten Sie nutzen?</Typography>
              <CustomContentTooltip
                content={
                  <Typography>
                    Beide Option füllen das gewählte PDF Dokument mit den Eingaben der Nutzer*innen. &quot;Vorlage
                    nutzen&quot; nutzt vordefinierte Nachrichten, um den Nutzer*innen das fertige PDF zum Herunterladen
                    zur Verfügung zu stellen.
                    <br />
                    &quot;Formular ausfüllen&quot; erlaubt den Nutzer*innen das PDF Dokument zu füllen und das Ergebnis
                    in einer Variable zu speichern.
                  </Typography>
                }
                elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
              />
            </div>
          </Grid>

          <Grid item xs={6}>
            <CardButton
              isSelected={currentView === 'useTemplate'}
              onClick={setViewuseTemplate}
              selectedColor={theme.palette.success.main}
              width='100%'
              shadowBlur={3}
              shadowWidth={3}
              disabled={isPDFTemplateButtonDisabled}
            >
              <div style={{ display: 'flex' }}>
                <Typography>Vorlage nutzen</Typography>
                {'  '}
                <i
                  className='ri-bookmark-fill'
                  style={{
                    marginLeft: theme.spacing(1),
                    color: currentView === 'useTemplate' ? theme.palette.success.main : undefined,
                  }}
                />
              </div>
            </CardButton>
          </Grid>

          <Grid item xs={6}>
            <CardButton
              isSelected={currentView === 'getDownloadUri'}
              onClick={setViewUrl}
              selectedColor={theme.palette.success.main}
              disabled={isPDFURLButtonDisabled}
              width='100%'
              shadowBlur={3}
              shadowWidth={3}
            >
              <div style={{ display: 'flex' }}>
                <Typography>URL speichern</Typography>
                {'  '}
                <i
                  className='ri-attachment-line'
                  style={{
                    marginLeft: theme.spacing(1),
                    color: currentView === 'getDownloadUri' ? theme.palette.success.main : undefined,
                  }}
                />
              </div>
            </CardButton>
          </Grid>
        </Grid>

        <div className={classes.viewContainer}>
          {currentView === 'useTemplate' && (
            <SPDFTexts chart={chart} setIsSaveDisabled={setIsSaveDisabled} setStateCallback={setStateCallback} />
          )}
          {currentView === 'getDownloadUri' && (
            <SPDFForm chart={chart} setIsSaveDisabled={setIsSaveDisabled} setStateCallback={setStateCallback} />
          )}
        </div>
      </div>
    </ContentContainer>
    // </div>
  )
}
