/* eslint-disable react/prop-types */
import React, { useState, useEffect, Fragment } from 'react'
// types
import { Field, Coordinates, Container, SyncedCoordinates } from '../../@types/Ingest/types'
import { IngestPDFResult, IngestPDFSection } from '../../classes/Ingest'
// custom components
import Selection from '../Selection/Selection'
import SelectionPopup from '../Popups/SelectionPopup'
import { DeleteFileDialog, FieldsDialog } from './Dialogs'

// import IngestPDFSubSection from '../Classes/IngestPDFSubSection'
// @mui/material
import { makeStyles } from 'tss-react/mui'

const CONTAINER_PADDING = 5

const useStyles = makeStyles()((theme) => ({
  containerHighlight: {
    position: 'absolute',
    background: 'rgba(0, 0, 0, 0.1)',
    cursor: 'pointer',
    borderRadius: theme.shape.borderRadius,
  },
  containerText: {
    position: 'absolute',
    top: '0.5rem',
    left: CONTAINER_PADDING + 'px',
    margin: '0px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    fontSize: 'small',
    paddingRight: '5px',
    paddingLeft: '5px',
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
    borderRadius: '3px',
  },
  fieldHightlight: {
    position: 'absolute',
    borderRadius: theme.shape.borderRadius,
  },
}))

type Props = {
  syncedSignature: Field[]
  target: HTMLDivElement
  pageNumber: number
  onSyncedSignatureChange: (field: Field[]) => void
  ingestPDFResult: IngestPDFResult
  setIngestPDFResult: (result: IngestPDFResult) => void
  setStage: (stage: string) => void
  containers: Container[]
  setContainers: (containers: Container[]) => void
  containerId: string | null
  setContainerId: (id: string | null) => void
  sectionCheck: boolean
  setSectionCheck: (check: boolean) => void
  isSectionDone: number
  setIsInfoTextDone: (e: number) => void
  setIsSectionDone: (e: number) => void
}

// TODO: Styling via classes not inline styles
// TODO: Colors for Containers
// TODO: different colors for field types
// TODO: Display field type as small text
// TODO: check for double names

function SectionHightlights(props: Props): React.ReactElement {
  const [sig, setSig] = useState<Field[]>(props.syncedSignature)
  // const [targetDimensions, setTargetDimensions] = useState<Dimensions>({ h: 0, w: 0 })
  const [fieldHighlightsRef, setFieldHighlightsRef] = useState<HTMLElement[]>([])
  const [selectedIndex, setSelectedIndex] = useState<number[]>([])
  const [hightlightedIndex, setHighlightedIndex] = useState<number[]>([])
  // const [containers, setContainers] = useState<Container[]>([])
  const [selectedContainer, setSelectedContainer] = useState<number | null>(null)
  const [selectorDisabled, setSelectorDisabled] = useState<boolean>(false)
  const [popUpStartingPoint, setPopUpStartingPoint] = useState({ x: 0, y: 0 })
  const [warningOpen, setWarningOpen] = useState<boolean>(false)
  const { classes } = useStyles()

  // _____ HANDLERS _____

  /**
   * Calculates where the PopUp should be opended
   * @param containerCoords Coordinates of the connected Container
   */
  function calculateStartPositionPopUp(containerCoords: Coordinates): { x: number; y: number } {
    // For now very simple RuleSet
    if (containerCoords.y + containerCoords.h > 450) {
      if (containerCoords.y - 200 >= 0) {
        return { x: containerCoords.x, y: containerCoords.y - 200 }
      } else if (containerCoords.y - 100 >= 0) {
        return { x: containerCoords.x, y: containerCoords.y - 100 }
      } else {
        return { x: containerCoords.x, y: containerCoords.y }
      }
    } else {
      return { x: containerCoords.x, y: containerCoords.y + containerCoords.h + CONTAINER_PADDING }
    }
  }

  /**
   * Returns the style of a field depending if selected, hover etc.
   * @param index Index of the field ref
   * @param coord Coordinates of the field
   */
  function getStyle(index: number, coord: Coordinates, enabled: boolean): object {
    if (!enabled) {
      return {
        top: coord.y,

        left: coord.x,

        width: coord.w,

        height: coord.h,
        background: 'rgba(0, 0, 0, 0.3)',
        borderColor: '#2185d0',
        color: 'white',
      }
    }
    // eslint-disable-line no-undef
    if (selectedIndex.indexOf(index) > -1) {
      // Selected state
      return {
        top: coord.y,

        left: coord.x,

        width: coord.w,

        height: coord.h,
        background: 'rgba(255, 255, 0, 0.4)',
        borderColor: '#2185d0',
        color: 'white',
      }
    } else if (hightlightedIndex.indexOf(index) > -1) {
      // Hover state
      return {
        top: coord.y,

        left: coord.x,

        width: coord.w,

        height: coord.h,
        background: 'rgba(160, 160, 160, 0.4)',
        border: 'solid 5px red',
        color: 'white',
      }
    }
    return {
      top: coord.y,

      left: coord.x,

      width: coord.w,

      height: coord.h,
      background: 'rgba(255, 0, 0, 0.4)',
    }
  }

  /**
   * Add the field highlight div reference to the `fieldHighlightsRef` state
   * @param ref Reference of the field highlight div
   */
  function addFieldHighlightRef(ref: HTMLDivElement | null): void {
    const tmp = fieldHighlightsRef
    // console.log(ref?.getBoundingClientRect())
    if (ref !== null && tmp.indexOf(ref) === -1) {
      tmp.push(ref)
    }
    setFieldHighlightsRef(tmp)
  }

  /**
   * Determines coordinates and dimension of box.
   * Returns sync (adjusted) coordinates and original coordinates in the PDF.
   * NOTE: For the sync coordinates (0,0) is top left, for the original coordinates (0,0) is bottom left!
   *       -> We have to adjust accordingly.
   * @param fields fields inside the box, used to determine box dimensions and coordinates
   * @param padding padding that is applied to box (increases box dimensions)
   */
  function getOuterBoxSyncedCoordinates(fields: Field[], padding = 0): SyncedCoordinates {
    let xSync = 0,
      ySync = 0,
      hSync = 0,
      wSync = 0,
      xOriginal = 0,
      yOriginal = 0,
      hOriginal = 0,
      wOriginal = 0

    for (let i = 0, l = fields.length; i < l; i += 1) {
      // Check x - search for smallest (left most point of section)
      if (i === 0) {
        xSync = fields[i].coords.sync.x
        xOriginal = fields[i].coords.original.x
      } else {
        if (fields[i].coords.sync.x < xSync) {
          xSync = fields[i].coords.sync.x
          xOriginal = fields[i].coords.original.x
        }
      }
      // Check y - search for smallest (= top most point of section)
      if (i === 0) {
        ySync = fields[i].coords.sync.y
        yOriginal = fields[i].coords.original.y + fields[i].coords.original.h // adjusted to switched coordinate system
      } else {
        if (fields[i].coords.sync.y < ySync) {
          ySync = fields[i].coords.sync.y
          yOriginal = fields[i].coords.original.y + fields[i].coords.original.h // adjusted to switched coordinate system
        }
      }

      // Check h - search for highest (height of section)
      // this is the yValue of the highest (= most bottom) point of the section
      if (i === 0) {
        // h+y ("highest" because 0,0 is top left)
        hSync = fields[i].coords.sync.y + fields[i].coords.sync.h
        hOriginal = fields[i].coords.original.y // no need to subtract height because in PDF ycoords are from bottom
      } else {
        if (fields[i].coords.sync.y + fields[i].coords.sync.h > hSync) {
          hSync = fields[i].coords.sync.y + fields[i].coords.sync.h
          hOriginal = fields[i].coords.original.y // no need to subtract height because in PDF ycoords are from bottom
        }
      }
      // Check w - search for widest point (width of section)
      if (i === 0) {
        wSync = fields[i].coords.sync.x + fields[i].coords.sync.w
        wOriginal = fields[i].coords.sync.x + fields[i].coords.original.w
      } else {
        if (fields[i].coords.sync.x + fields[i].coords.sync.w > wSync) {
          wSync = fields[i].coords.sync.x + fields[i].coords.sync.w
          wOriginal = fields[i].coords.original.x + fields[i].coords.original.w
        }
      }
    }

    // NOTE: original does also have padding
    return {
      sync: { x: xSync - padding, y: ySync - padding, h: hSync - ySync + 2 * padding, w: wSync - xSync + 2 * padding },
      original: {
        x: xOriginal - padding,
        y: hOriginal - padding, // this is lowest point
        h: yOriginal - hOriginal + 2 * padding,
        w: wOriginal - xOriginal + 2 * padding,
      },
    }
  }

  function createContainerBox(
    container: {
      id: string
      box: {
        left: number
        top: number
        width: number
        height: number
      }
      selected: number[]
    },
    page: number,
  ): void {
    const maxPage = Math.max(...sig.map((f) => f.page))
    const sigOfPage = sig.filter((field) => field.page === page)
    const sigOfOtherPage = sig.filter((field) => field.page !== page)

    // check if a container should be created
    // filter disabled fields
    const _selected: number[] = []
    for (let i = 0, l = container.selected.length; i < l; i += 1) {
      if (sigOfPage[container.selected[i]].selection.enabled) {
        _selected.push(container.selected[i])
      }
    }
    // check if fields are selected
    if (_selected.length === 0) return

    // match selected indexes with fields and collect them
    const fields: Field[] = []
    for (let i = 0, l = _selected.length; i < l; i += 1) {
      fields.push(sigOfPage[_selected[i]])
    }

    // disable selected field
    // const _sig = sig
    for (let i = 0, l = _selected.length; i < l; i += 1) {
      sigOfPage[_selected[i]].selection.enabled = false
    }

    // rebuild signature
    // we need to re-insert the fields of the current page at the right position -> hence this complicated approach
    let newSignature: Field[] = []
    for (let i = 0; i <= maxPage; i++) {
      let fieldsOfPage = sigOfOtherPage.filter((field) => field.page === i)
      if (fieldsOfPage.length > 0) {
        newSignature = [...newSignature, ...fieldsOfPage]
        continue
      }

      // no fields for this page, we check the fields of this page
      fieldsOfPage = sigOfPage.filter((field) => field.page === i)
      if (fieldsOfPage.length > 0) {
        newSignature = [...newSignature, ...fieldsOfPage]
      }
    }

    setSig(newSignature)

    // set box coordinates based on the selected fields
    const syncedCoordinates = getOuterBoxSyncedCoordinates(fields, CONTAINER_PADDING)
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_BRANCH === 'development' && console.info('SyncedCoordinates Section: ', syncedCoordinates)

    // define new container
    const _container: Container = {
      id: container.id,
      box: syncedCoordinates,
      page,
      selectedFields: fields,
      selectedHightlights: container.selected,
    }

    // Add new container
    const _containers = props.containers
    _containers.push(_container)

    // Open PopUp for new container
    // Define Start Position of PopUp
    setPopUpStartingPoint(calculateStartPositionPopUp(_container.box.sync))
    // minor delay so the user does not see a jump by the popUp
    setTimeout(() => {
      setSelectedContainer(_containers.length - 1)
      setSelectorDisabled(true)
    }, 50)
    // console.log(_containers)
    props.setContainers(_containers)
  }

  /**
   * Removes a visual container by index - does not remove from result
   */
  function removeContainer(index?: number): void {
    // not using if(index) because 0 is falsy but a valid value for our logic
    if (typeof index === 'number' && index >= 0) {
      const _containers = props.containers

      // activate disabled fields again
      const _sig = sig
      const selectedFields = _containers[index].selectedFields || []
      for (let i = 0, l = selectedFields.length; i < l; i += 1) {
        // smarter way than double loop? - no time atm ~ Jakob 19.10.2020
        // remove from sig
        for (let j = 0, l = _sig.length; j < l; j += 1) {
          if (_sig[j].fieldName === selectedFields[i].fieldName) {
            _sig[j].selection.enabled = true
          }
        }
      }
      setSig(_sig)

      // reset selected index - state can be replaced
      setSelectedIndex([])
      // reset highlighted index
      setHighlightedIndex([])

      // reset container ID
      props.setContainerId(null)

      _containers.splice(index, 1)
      props.setContainers(_containers)
    }
  }

  function init(signature: Field[]): void {
    // collect selected fields and set to enabled
    const selectedFields: string[] = []
    props.containers.forEach((container) => {
      if (container.selectedFields)
        container.selectedFields.forEach((field) => {
          selectedFields.push(field.fieldName)
        })
    })
    const _sig = signature
    for (let i = 0, l = _sig.length; i < l; i += 1) {
      if (selectedFields.includes(_sig[i].fieldName)) {
        _sig[i].selection.enabled = false
      }
    }
    setSig(_sig)
  }

  function hasUnsetFields(): boolean {
    const subsections = props.ingestPDFResult.regions.subsections
    const subSectionKeys = Object.keys(subsections)
    const missingFields: string[] = []
    let usedFields: string[] = []
    for (let i = 0, l = subSectionKeys.length; i < l; i += 1) {
      usedFields = usedFields.concat(subsections[subSectionKeys[i]].fields)
    }
    sig.forEach((field) => {
      // NOTE: using fieldName as identifier
      if (!usedFields.includes(field.fieldName)) {
        missingFields.push(field.fieldName)
      }
    })

    if (missingFields.length > 0) {
      return true
    }
    return false
  }

  function runSectionChecks(): void {
    if (hasUnsetFields()) {
      setWarningOpen(true)
      return
    }
    const result = props.ingestPDFResult
    result.removeSelectedId()
    props.setIngestPDFResult(result)
    props.setSectionCheck(false)
    props.setStage('infotexts')
  }

  // _____ EFFECTS ______

  // Run at start
  useEffect(() => {
    // Remove because this is top level and no id should be selected at the start of the render
    // const ingestPDFResult = props.ingestPDFResult
    // newIngestPDFResult.removeSelectedId()
    // props.setIngestPDFResult(newIngestPDFResult)
  }, [])

  // SelectedId is set from SectionView
  useEffect(() => {
    // Deselect event: if selectedId is undefined
    if (typeof props.ingestPDFResult.selectedId === 'undefined') {
      // onClose for existing
      setPopUpStartingPoint({ x: 0, y: 0 })
      // check if the visual selectedContainer needs to be removed
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      if (typeof selectedContainer === 'number' && !isSection(props.containers[selectedContainer].id)) {
        // remove visual container
        removeContainer(selectedContainer)
      }
      setSelectedContainer(null)
      setSelectorDisabled(false)
    } else {
      // Select event: if selectedId is string
      // get index of selectedId
      const index = props.containers.map((container) => container.id).indexOf(props.ingestPDFResult.selectedId)
      // check if id even exists -> has to but we want to throw an error just in case
      if (index >= 0) {
        // check if id is already selected
        if (index !== selectedContainer) {
          // onClose for existing selection
          setPopUpStartingPoint({ x: 0, y: 0 })
          // check if the visual selectedContainer needs to be removed
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          if (typeof selectedContainer === 'number' && !isSection(props.containers[selectedContainer].id)) {
            // remove visual container
            removeContainer(selectedContainer)
          }
          setSelectedContainer(null)
          setSelectorDisabled(false)
          // set new container as selected and open popup
          // Open PopUp for new container
          // Define Start Position of PopUp
          setPopUpStartingPoint(calculateStartPositionPopUp(props.containers[index].box.sync))
          // minor delay so the user does not see a jump by the popUp
          setTimeout(() => {
            setSelectedContainer(index)
            setSelectorDisabled(true)
          }, 50)
        }
      } else {
        console.warn(
          `[Ingest][SectionHighlights] selectedId (${props.ingestPDFResult.selectedId}) is not found in containers!`,
        )
      }
    }
  }, [props.ingestPDFResult.selectedId])

  // Run final checks to display warning if needed
  useEffect(() => {
    if (props.sectionCheck) {
      runSectionChecks()
    }
  }, [props.sectionCheck])

  useEffect(() => {
    setSig(props.syncedSignature)
  }, [props.syncedSignature])

  // // As soon as a signature exists, calculate target dimensions
  // useEffect(() => {
  //   setTargetDimensions(getTargetDimensions())
  // }, [props.signature])

  // // As soon as target dimensions are calculated, calculate the modified sig
  // useEffect(() => {
  //   init(calculateSig())
  // }, [targetDimensions])

  // Handle Signature changes
  useEffect(() => {
    init(sig)
    if (props.onSyncedSignatureChange) {
      props.onSyncedSignatureChange(sig)
    }
  }, [sig])

  // _____ RESULT FILE MANAGEMENT _____

  function createSection(id: string, syncedCoordinates: SyncedCoordinates, name: string, page: number): void {
    const section = new IngestPDFSection(id, syncedCoordinates, page, undefined, name)
    const result = props.ingestPDFResult
    result.addSection(section)
    result.setSelectedId(id)
    const sectionOrder = result.regions.sectionOrder
    sectionOrder.push(id)
    result.setSectionOrder(sectionOrder)
    props.setIngestPDFResult(result)
  }

  function updateSection(id: string, newName: string): void {
    const result = props.ingestPDFResult
    const section = result.getSection(id)
    if (section) {
      section.setName(newName)
      result.updateSection(section)
      result.setSelectedId(section.id)
      props.setIngestPDFResult(result)
    } else {
      console.warn(`No section found with the id: ${id}`)
    }
  }

  function removeSection(id: string): void {
    const result = props.ingestPDFResult
    result.removeSection(id)
    result.removeSelectedId()
    result.setSectionOrder(result.regions.sectionOrder.filter((sectionId) => sectionId !== id))
    props.setIngestPDFResult(result)
  }

  function isSection(id: string): boolean {
    const result = props.ingestPDFResult
    const section = result.getSection(id)
    if (section) {
      return true
    } else {
      return false
    }
  }

  function getName(id: string): string {
    const result = props.ingestPDFResult
    const section = result.getSection(id)
    if (section) return section.name
    else return ''
  }

  // _____ RENDER _____
  function renderFields(): React.ReactElement {
    // console.log('RenderFields: ', sig)
    // console.log('RenerFields: props', props)
    return (
      <Fragment>
        {sig

          .filter((field) => field.page === props.pageNumber)
          .map((field, index) => {
            return (
              <div
                id={`field_highlight_${index}`}
                key={index}
                ref={addFieldHighlightRef}
                className={classes.fieldHightlight}
                style={getStyle(
                  index,
                  {
                    y: field.coords.sync.y,
                    x: field.coords.sync.x,
                    w: field.coords.sync.w,
                    h: field.coords.sync.h,
                  },
                  field.selection.enabled,
                )}
              />
            )
          })}
      </Fragment>
    )
  }

  function renderContainers(): React.ReactElement {
    return (
      <Fragment>
        {/* Display overlay containers */}
        {
          // TODO: show warning wenn kein Name gesetzt und nicht ausgewählt
          // TODO: show subsection name

          props.containers

            .filter((container) => container.page === props.pageNumber)
            .map((container, index) => {
              return (
                <div
                  id={`container_highlight_${index}`}
                  key={container.id}
                  className={classes.containerHighlight}
                  style={{
                    top: container.box.sync.y,
                    left: container.box.sync.x,
                    width: container.box.sync.w,
                    height: container.box.sync.h,
                  }}
                  onClick={(): void => {
                    // check if PopUp needs to be opened or closed
                    if (typeof selectedContainer === 'number' && index === selectedContainer) {
                      setPopUpStartingPoint({ x: 0, y: 0 })
                      // check if the visual selectedContainer needs to be removed
                      // eslint-disable-next-line @typescript-eslint/no-use-before-define
                      if (typeof selectedContainer === 'number' && !isSection(container.id)) {
                        // remove visual container
                        removeContainer(selectedContainer)
                      }
                      setSelectedContainer(null)
                      setSelectorDisabled(false)
                      const result = props.ingestPDFResult
                      result.removeSelectedId()
                      props.setIngestPDFResult(result)
                    } else {
                      setPopUpStartingPoint(calculateStartPositionPopUp(container.box.sync))
                      setTimeout(() => {
                        setSelectedContainer(index)
                        setSelectorDisabled(true)
                        const ingestPDFResult = props.ingestPDFResult
                        ingestPDFResult.setSelectedId(container.id)
                        props.setIngestPDFResult(ingestPDFResult)
                      }, 50)
                    }
                  }}
                >
                  <p className={classes.containerText}>{props.ingestPDFResult.getSection(container.id)?.name}</p>
                </div>
              )
            })
        }
      </Fragment>
    )
  }

  return (
    <Fragment>
      <div id='fields'>
        {renderFields()}
        {renderContainers()}
      </div>
      {selectedContainer !== null &&
        (isSection(props.containers[selectedContainer].id) ? (
          <SelectionPopup
            parent={props.target}
            startPosition={popUpStartingPoint}
            selectionName={getName(props.containers[selectedContainer].id)}
            title={'Bereich'} // TODO: what is the title supposed to be? - I18n
            onConfirm={(name: string): void => {
              setSelectedContainer(null)
              setSelectorDisabled(false)
              // Update result
              updateSection(props.containers[selectedContainer].id, name)
              props.setContainerId(props.containers[selectedContainer].id)
              props.setStage('subsections')
            }}
            onClose={(): void => {
              // reset starting point
              setPopUpStartingPoint({ x: 0, y: 0 })
              setSelectedContainer(null)
              setSelectorDisabled(false)
              const ingestPDFResult = props.ingestPDFResult
              ingestPDFResult.removeSelectedId()
              props.setIngestPDFResult(ingestPDFResult)
            }}
            onDelete={(): void => {
              // remove from results
              removeSection(props.containers[selectedContainer].id)
              setPopUpStartingPoint({ x: 0, y: 0 })
              // remove visual container
              removeContainer(selectedContainer)
              setSelectedContainer(null)
              setSelectorDisabled(false)
            }}
          />
        ) : (
          <SelectionPopup
            parent={props.target}
            startPosition={popUpStartingPoint}
            title={'Bereich'} // TODO: what is the title supposed to be? - I18n
            onConfirm={(name: string): void => {
              setSelectedContainer(null)
              setSelectorDisabled(false)
              // Create result
              const section = props.containers[selectedContainer]
              createSection(section.id, section.box, name, section.page)
              props.setContainerId(section.id)
              props.setStage('subsections')
            }}
            onClose={(): void => {
              // reset starting point
              setPopUpStartingPoint({ x: 0, y: 0 })
              // remove visual container
              removeContainer(selectedContainer)
              setSelectedContainer(null)
              setSelectorDisabled(false)
              const ingestPDFResult = props.ingestPDFResult
              ingestPDFResult.removeSelectedId()
              props.setIngestPDFResult(ingestPDFResult)
            }}
          />
        ))}
      <div id='container_controls'></div>
      <div id='selection'>
        <Selection
          target={props.target}
          onDraw={(e: unknown): void => {
            // eslint-disable-next-line no-undef
            process.env.REACT_APP_BRANCH === 'development' && console.info(e)
          }}
          returnBox={(e: {
            id: string
            box: {
              left: number
              top: number
              width: number
              height: number
            }
            selected: number[]
          }): void => createContainerBox(e, props.pageNumber)}
          elements={fieldHighlightsRef}
          disabled={selectorDisabled}
          onSelectionChange={(indexes: number[]): void => setSelectedIndex(indexes)}
          onHighlightChange={(indexes: number[]): void => setHighlightedIndex(indexes)}
        />
      </div>
      <FieldsDialog
        open={warningOpen}
        onCancle={(): void => {
          setWarningOpen(false)
          props.setIsSectionDone(0)
        }}
        onConfirm={(): void => {
          props.setStage('infotexts')
          props.setIsSectionDone(0)
        }}
      />
    </Fragment>
  )
}

SectionHightlights.defaultProps = {
  pageNumber: 0,
  containers: [],
}

export default SectionHightlights
