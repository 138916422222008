import { cloneDeep, isEqual } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
// Material UI
import { Typography } from '@mui/material'

import { Button } from '../../components/Buttons'
import Dialog from '../../components/Dialogs/Dialog'
import CircularLoading from '../../components/Loading/CircularLoading'
import DialogOverview from './DialogOverview/DialogOverview'

// APIs
import {
  getAnswers,
  getFlow,
  getProcessTemplates as getProcessTemplatesApi,
  getTranslations,
  saveTranslationFile as saveTranslationFileApi,
  upgradeChart,
  upgradeTranslations,
} from '../../api/StudioBackend'
import { selectDialog, updateChartOnSmartCardEdit } from '../../utils/chartUtils'
// import studio styles
import '../../assets/css/studioStyles.css'
import { Answer } from '../../classes/Knowledge'
// Types
import { BotInfos } from '../../@types/BotInformation/types'
import { Chart } from '../../@types/Flowchart/types'
import { CardType, IAdaptiveCard } from '../../@types/SmartCards/types'
import { TranslationFile } from '../../@types/Translations/types'
// Custom Components
import FlowEditor from './Editor'
// Context
import { useBotContext } from 'hooks/contexts/bot-context'
// Constants
import Warning from 'assets/img/knowledge/icons/warning'
import { useFlowdesignerContext } from 'hooks/contexts/flowdesigner-context'
import { useLockingContext } from 'hooks/contexts/locking-context'
import { usePrevious } from 'hooks/usePrevious/usePrevious'
import { ReactFlowProvider } from 'reactflow'
import 'reactflow/dist/style.css'
import { makeStyles } from 'tss-react/mui'
import { validateChart } from 'utils/chartValidator'
import {
  APP_TITLE,
  MODULE_TYPE_NLU,
  ROUTE_BOTID_DESIGNER_DIALOGOVERVIEW,
  ROUTE_BOTID_DESIGNER_NODESEARCH,
} from 'utils/constants'
import { Templates } from '../../@types/Templates/types'
import NodeSearch from './NodeSearch/NodeSearch'

const useDialogStyles = makeStyles()((theme) => {
  return {
    dialogContent: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    errorIconContainer: {
      marginLeft: 'auto',
      marginRight: 'auto',
      height: '4rem',
      // width: '100%',
      fontSize: '3rem',
      // color: '#F9FAFB',
      // display: 'flex',
      // justifyContent: 'center',
      // alignItems: 'center',
      fontWeight: 400, // otherwise the label class of the button would set it to 700
      marginBottom: theme.spacing(2),
    },
  }
})

function CreateCardDialog(): React.ReactElement {
  return (
    <Dialog
      id='create-card-dialog'
      size='small'
      open={true}
      closable={false}
      aria-describedby='card-creation-waiting-dialog'
    >
      <CircularLoading text='Karte wird erstellt...' />
    </Dialog>
  )
}

function LoadingDialog({ text }: { text?: string }): React.ReactElement {
  return (
    <Dialog
      id='loading-dialog'
      size='small'
      open={true}
      closable={false}
      aria-describedby='card-creation-waiting-dialog'
    >
      <CircularLoading text={'Lade Konversationsfluss...'} />
    </Dialog>
  )
}

type LoadingErrorDialogProps = {
  onRetry: () => void
  onCancel: () => void
}

function LoadingErrorDialog({ onRetry, onCancel }: LoadingErrorDialogProps): React.ReactElement {
  const { classes } = useDialogStyles()
  return (
    <Dialog
      id='loading-error-dialog'
      size='small'
      open={true}
      closable={false}
      aria-describedby='loading-error-dialog'
      primaryActionButton={
        <Button onClick={() => onRetry()} variant='primary' type='success'>
          Erneut versuchen
        </Button>
      }
      secondaryActionText='Zurück'
      onSecondaryActionClick={onCancel}
    >
      <div className={classes.dialogContent}>
        <div className={classes.errorIconContainer}>
          <Warning />
        </div>
        <Typography>
          Der Dialog Designer konnte nicht geladen werden! Bitte überprüfen Sie Ihre Internetverbindung und versuchen
          Sie es in wenigen Momenten erneut.
        </Typography>
      </div>
    </Dialog>
  )
}

type UpgradeDialogProps = {
  onUpgradeCancel: () => void
  onUpgradeConfirm: () => void
}

function UpgradeDialog({ onUpgradeCancel, onUpgradeConfirm }: UpgradeDialogProps): React.ReactElement {
  return (
    <Dialog
      id='upgrade-dialog'
      size='small'
      open={true}
      closable={true}
      aria-describedby='upgrade-dialog'
      primaryActionButton={
        <Button size='small' type='normal' onClick={onUpgradeConfirm}>
          Update
        </Button>
      }
      secondaryActionText='Abbrechen'
      onSecondaryActionClick={onUpgradeCancel}
    >
      <Typography>
        Der Konversationsfluss dieses Assistenten ist veraltet. Möchten Sie den Konversationfluss auf die neues Version
        updaten?
      </Typography>
    </Dialog>
  )
}

type FlowDesignerProps = {
  sidebarOpen: boolean
}

export default function FlowDesigner({ sidebarOpen }: FlowDesignerProps): React.ReactElement {
  const { bot } = useBotContext() as { bot: BotInfos }
  const navigate = useNavigate()
  const botId = bot?.id
  const { hasModule } = useBotContext()
  const botRef = useRef<BotInfos>() // keep botinfos also in ref to be able to check them for changes

  const { pathname: path } = useLocation()

  const {
    // nodeSearchSelectedNodeId,
    // setNodeSearchSelectedNodeId,
    // selectedDialogId,
    setProcessTemplates,
    setSelectedDialogId,
  } = useFlowdesignerContext()
  const [status, setStatus] = useState<'loading' | 'upgrade' | 'creatingCard' | 'loadingError' | undefined>('loading')
  // lock state

  const { lockState, alreadyHadLockBefore } = useLockingContext()
  const prevLockState = usePrevious(lockState)

  const [chart, setChart] = useState<Chart>()
  const tmpChartRef = useRef<Chart | null>(null) // holds tmp chart when card designer is used

  const [translations, setTranslations] = useState<TranslationFile>()
  // const translationNeedSavingRef = useRef(false)
  const [translationNeedSaving, setTranslationNeedSaving] = useState<boolean>(false) // indicate whether translations are unsafed and only stored in this state (without triggering re-render)
  const [chosenCard, setChosenCard] = useState<{ cardId: string; cardIndex: number; card: IAdaptiveCard }>()
  // error handling

  // ======= DATA LOADING =========

  async function upgradeChartVersion(): Promise<{ chart: Chart; version: string } | undefined> {
    if (typeof chart === 'undefined' || typeof translations === 'undefined') return

    // first upgrade translation file, then upgrade chart
    const upgradeTranslationResult = await upgradeTranslations(translations)
    if (!(typeof upgradeTranslationResult !== 'undefined' && upgradeTranslationResult !== null)) {
      console.error('[FlowDesigner] Error upgrading translations. Will not upgrade flow.')
      return
    }
    const newTranslations = upgradeTranslationResult.translations
    const haveTranslationsChanged = !isEqual(translations, newTranslations)
    const upgradeResult = await upgradeChart(chart, newTranslations)

    if (typeof upgradeResult !== 'undefined' && upgradeResult !== null) {
      const newChart = upgradeResult.chart
      if (haveTranslationsChanged) {
        setTranslations(newTranslations)
        setTranslationNeedSaving(true)
      }
      setChart(newChart)
      setStatus(undefined)
    }
  }

  // TODO: we should move all loading into the FlowDesigner Context and not do it in this component. Then simply return loading state from context

  /**
   * Loads chart using the BackendAPI.
   * Inits oldChart and sets chart in state.
   */
  async function loadChart(): Promise<Chart | undefined> {
    setStatus('loading')
    const chartResult = await getFlow(bot.id).catch((err) => console.error(err))
    if (typeof chartResult === 'undefined') {
      console.error('[StudioAPI] getChart returned undefined')
      return
    }

    if (chartResult === null) {
      console.error('[StudioAPI] Chart is null')
      setStatus('loadingError')
      return
    }

    if (typeof chartResult === 'object') {
      if (typeof chartResult.chart.version === 'undefined') chartResult.chart.version = '1.4.0'
      if (!chartResult.chart.activeDialog) chartResult.chart.activeDialog = chartResult.chart.mainDialog
      return chartResult.chart
    } else {
      console.error('[StudioAPI] Chart result is void')
      return
    }
  }

  /**
   * Loads process templates from the StudioAPI.
   * Sets templates in state.
   */
  async function loadProcessTemplates(): Promise<Templates | undefined> {
    const templates = await getProcessTemplatesApi().catch((err) => console.error(err))
    if (typeof templates === 'undefined') {
      console.error('[StudioAPI] getProcessTemplates returned undefined')
      return
    }

    if (templates === null) {
      console.error('[StudioAPI] Process templates are null')
      setStatus('loadingError')
      return
    }

    if (typeof templates === 'object') {
      return templates
    } else {
      console.error('[StudioAPI] Process templates result is void')
      setStatus('loadingError')
      return
    }
  }

  /**
   * Loads translations using BackendAPI.
   * Sets translations into state.
   */
  async function loadTranslations(): Promise<TranslationFile | undefined> {
    const translations = await getTranslations(bot.id).catch((err) => {
      console.error(err)
    })
    if (typeof translations === 'undefined') return

    if (translations === null) {
      setStatus('loadingError')
      console.error('[StudioAPI] TranslationFile is null')
      return
    }

    return translations
  }

  /**
   * Loads customer specific knowledge base in order to find all trigger that initialize a QnA Dialog.
   */
  async function loadTriggerAnswers(): Promise<Answer[]> {
    // check if nlu instances are connected to the bot
    if (hasModule(MODULE_TYPE_NLU)) {
      const getAnswerResult = await getAnswers(bot.id, 'specific').catch((err) => console.error(err))
      if (typeof getAnswerResult === 'undefined') return []

      if (getAnswerResult === null) {
        return []
      }

      const triggerAnswers: Answer[] = []
      if (typeof getAnswerResult.answers === 'object') {
        Object.values(getAnswerResult.answers).forEach((answer: Answer) => {
          if (typeof answer.answerType !== 'undefined' && answer.answerType === 'trigger' && answer.triggerDialogName)
            triggerAnswers.push(answer)
        })
      }
      return triggerAnswers
    } else {
      return []
    }
  }

  /**
   * Coordinates loading of chart, translations and trigger intents and sets them in state.
   */
  async function load(): Promise<void> {
    const [translations, chart, processTemplates] = await Promise.all([
      loadTranslations(),
      loadChart(),
      loadProcessTemplates(),
    ])
    // load trigger answers after we have chart
    const triggerAnswers = await loadTriggerAnswers()

    if (
      typeof chart !== 'undefined' &&
      typeof translations !== 'undefined' &&
      typeof processTemplates !== 'undefined'
    ) {
      chart.triggerAnswers = triggerAnswers
      setChart(chart)
      setTranslations(translations)
      setProcessTemplates(processTemplates)

      // check if chart has old version
      if (
        !chart.version ||
        chart.version === '1.1.0' ||
        chart.version === '1.2.0' ||
        chart.version === '1.3.0' ||
        chart.version === '1.3.1' ||
        chart.version === '1.4.0'
      )
        setStatus('upgrade')
      else {
        setStatus(undefined)
      }
    } else {
      setStatus('loadingError')
    }
  }

  // ======= Handler & Callbacks ========

  function onSetChartCallback(newChart: Chart): void {
    let chartToSet = newChart
    if (translations && newChart.hasError) {
      // if chart is not valid, run validation again to check whether error still persists
      chartToSet = validateChart(newChart, translations, botId)
    }
    if (chart?.triggerAnswers) chartToSet.triggerAnswers = chart.triggerAnswers // we need to re-populate the trigger answers (e.g. if saveChart returns chart they are missing)
    setChart(cloneDeep(chartToSet))
  }

  function resetTranslationsNeedSaving(): void {
    setTranslationNeedSaving(false)
  }

  /**
   * Callback for card editing from a selected node dialog.
   * Searches and sets the cardId, card, and cardIndex into the state.
   * Note: This function can take translations as a parameter and uses those if provided, else it takes the ones in the state.
   * Reason for this: Smart card creation
   * @param cardId
   * @param trans
   */
  function onEditSmartCard(chart: Chart, cardId: string, trans?: TranslationFile): void {
    const translationFile = typeof trans !== 'undefined' ? trans : translations

    if (typeof translationFile === 'undefined') return
    const { primaryLanguage } = translationFile
    const cards = translationFile.ac[primaryLanguage] || {}
    const cardObj = cards[cardId]
    if (typeof cardObj === 'undefined' || typeof cardObj.data === 'undefined') return
    const card = cardObj.data
    // find card index
    let cardIndex = -1
    const cardKeys = Object.keys(cards)
    for (const [idx, key] of cardKeys.entries()) {
      if (cards[key].id === cardId) {
        cardIndex = idx
        break
      }
    }
    if (cardIndex === -1) return

    tmpChartRef.current = { ...chart }
    setChosenCard({
      cardId,
      cardIndex,
      card,
    })
  }

  /**
   * Creates a new smart card, adds it to translations, sets translations using the Studio API,
   * triggers Card designer by calling editSmartCardCallback and also triggering callback provided by Selected AC node to
   * update that component as well.
   * @param cardId
   * @param callback
   * @returns
   */
  async function onCreateSmartCard(chart: Chart, cardId: string, callback: () => void): Promise<void> {
    if (typeof translations === 'undefined') return
    setStatus('creatingCard')
    const cards = translations.ac[translations.primaryLanguage]
    let doesCardExist = false
    for (const key of Object.keys(cards)) {
      if (cards[key].id === cardId) {
        doesCardExist = true
        break
      }
    }
    if (!doesCardExist) {
      const cardTmp = {
        data: {} as IAdaptiveCard,
        cardType: 'card/custom' as CardType,
        id: cardId,
      }
      cards[cardTmp.id] = cardTmp
      translations.ac[translations.primaryLanguage] = cards

      try {
        // TODO: Saving translation on creation of new card could be problematic if we have translations in the state
        // that need saving, because this would save them without the user being aware
        const response = await saveTranslationFileApi(bot.id, translations, 'major', chart)

        if (typeof response === 'undefined' || response === null)
          throw new Error('StudioAPI.setTranslation response is undefined')

        setStatus(undefined)
        setTranslations(response.translations)
        onEditSmartCard(chart, cardId, response.translations)
        callback()
      } catch (err) {
        console.error(err)
        setStatus(undefined)
      }
    } else {
      alert('Es existiert bereits eine Smart Card mit diesem Namen!')
      setStatus(undefined)
    }
  }

  /**
   * Callback for Card Designer back button click without saving.
   * Unsets chosen card from state.
   */
  function onSmartCardDesignerBackClick(): void {
    setChosenCard(undefined)
  }

  /**
   * Callback for smart card designer save button click.
   * Sets new translation file to state.
   * @param newTranslations
   */
  function onSmartCardDesignerSaveClick(newTranslations: TranslationFile): void {
    // if smart card was edited, we have to make sure to remove all references to fields that no longer exist
    // (-> update variables + datachecks)
    if (tmpChartRef.current !== null) {
      tmpChartRef.current = updateChartOnSmartCardEdit(tmpChartRef.current, newTranslations)
    }
    setTranslationNeedSaving(true)
    setTranslations(newTranslations)
    setChosenCard(undefined)
  }
  /**
   * Handles dialog selection from dialog overview.
   * @param dialogId
   */
  function onDialogSelection(dialogId: string): void {
    if (!chart) return
    if (dialogId !== chart.activeDialog) {
      const newChart = selectDialog(chart, dialogId)
      setSelectedDialogId(dialogId)
      setChart(newChart)
    }
  }
  /**
   * Handles chart upgrade.
   * Triggered in chart upgrade dialog.
   */
  function onUpgradeChart(): void {
    upgradeChartVersion()
  }

  /**
   * Cancels chart upgrade and removes upgrade dialog.
   */
  function onUpgradeCancel(): void {
    alert(
      'Der Konversationfluss muss aktualisiert werden. Ohne eine Aktualisierung ist die weitere Benutzung nicht möglich.',
    )
  }

  useEffect(
    function () {
      /**
       * Loads chart and sets it in state.
       * This function is only used as useEffect callback.
       */
      async function loadAndSetChartInState(): Promise<void> {
        const chart = await loadChart()
        if (typeof chart !== 'undefined') {
          setChart(chart)
        }
        setStatus(undefined)
      }

      if (lockState === 'canEdit' && prevLockState === 'isBlocked' && !alreadyHadLockBefore) {
        // lock state was locked and is now canEdit and the current user did not hold the previous lock
        // we re-fetch most recent chart version to ensure that changes made by other locking person are present
        loadAndSetChartInState()
      }
    },
    [lockState, alreadyHadLockBefore],
  )

  useEffect(
    function () {
      // load chart, translations and trigger answers on first render
      if (bot !== null && !botRef.current) {
        load()
        botRef.current = bot
      }
    },
    [bot],
  )

  return (
    <>
      <Helmet>
        <title>{APP_TITLE} - Designer</title>
      </Helmet>
      <Routes>
        <Route
          path={''}
          element={
            typeof chart !== 'undefined' && typeof translations !== 'undefined' && typeof status === 'undefined' ? (
              // Render AC or Editor
              <ReactFlowProvider>
                <FlowEditor
                  lockState={lockState}
                  sidebarOpen={sidebarOpen}
                  chart={chart}
                  translations={translations}
                  needTranslationsSaving={translationNeedSaving}
                  resetNeedTranslationsSavingCallback={resetTranslationsNeedSaving}
                  onCreateSmartCard={onCreateSmartCard}
                  onEditAdaptiveCard={onEditSmartCard}
                  setChartCallback={onSetChartCallback}
                  setTranslationsCallback={setTranslations}
                  setTranslationNeedSavingCallback={setTranslationNeedSaving}
                />
                {/* <NotificationSnackbar
                  position='top'
                  severity='info'
                  open={lockState === 'isBlocked'}
                  message={`Bearbeitung gesperrt. Ein anderer Nutzer bearbeitet aktuell den Konversationsfluss.${
                    lockedBy ? '\n\nGesperrt durch: ' + lockedBy : ''
                  }`}
                /> */}
              </ReactFlowProvider>
            ) : status === 'loading' ? (
              <LoadingDialog />
            ) : status === 'upgrade' ? (
              <UpgradeDialog onUpgradeCancel={onUpgradeCancel} onUpgradeConfirm={onUpgradeChart} />
            ) : status === 'creatingCard' ? (
              <CreateCardDialog />
            ) : status === 'loadingError' ? (
              <LoadingErrorDialog
                onRetry={() => {
                  load()
                }}
                onCancel={(): void => {
                  navigate(-1)
                }}
              />
            ) : (
              <LoadingDialog />
            )
            //  render loading dialog //  render upgrade dialog // unexpected state, render loading dialog to display something
            // TODO: we should display something else than the loading dialog if there is an unexpected state!
          }
        />
        <Route
          path={ROUTE_BOTID_DESIGNER_DIALOGOVERVIEW + '/*'}
          element={
            translations && (
              <DialogOverview
                chart={chart}
                translations={translations}
                onDialogSelection={onDialogSelection}
                onUpdateChart={onSetChartCallback}
                lockState={lockState}
              />
            )
          }
        />
        <Route
          path={ROUTE_BOTID_DESIGNER_NODESEARCH + '/*'}
          element={
            chart &&
            translations && (
              // <div />
              <NodeSearch
                chart={chart}
                translationFile={translations}
                onNodeSelect={(nodeId: string): void => {
                  // we trigger a re-render by shallow copy the chart and also update the active dialog
                  const newChart = selectDialog(chart, chart.nodes[nodeId].properties.dialog)
                  onSetChartCallback(newChart)
                }}
              />
            )
          }
        />
        <Route path='*' element={<Navigate to={path} />} />
      </Routes>
    </>
  )
}
