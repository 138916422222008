import { Delete } from '@mui/icons-material'
import { Typography, useTheme } from '@mui/material'
import { RAGTxtDoc } from '../../../../@types/Knowledge/RAG/types'
import { Button } from 'components/Buttons'
import BaseCard from 'components/Cards/BaseCard'
import { Dialog } from 'components/Dialogs'
import CircularLoading from 'components/Loading/CircularLoading'
import ContentPage, { ContentPageHeader } from 'components/Page/ContentPage'
import { Textfield } from 'components/TextInput/Textfield'
import { LoadingState, useRAGContext } from 'hooks/contexts/rag-context'
import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Navigate, Route, Routes, useLocation, useNavigate, useResolvedPath } from 'react-router-dom'
import { theme } from 'theme/StudioTheme'
import { makeStyles } from 'tss-react/mui'
import { APP_TITLE } from 'utils/constants'
import { isValidUrl } from 'utils/stringUtils'

const useStyles = makeStyles()((theme, props, classes) => {
  return {
    titleContainer: {},
    contentContainer: { marginTop: theme.spacing(2) },
    urlContainer: { marginTop: theme.spacing(2) },
    actionsContainer: { display: 'flex', width: '100%', marginTop: theme.spacing(2), justifyContent: 'center' },
  }
})

type RAGUpdateTextDocumentProps = {
  documentId: string
}

export default function RAGUpdateTextDocument({ documentId }: RAGUpdateTextDocumentProps): React.ReactElement {
  const { classes } = useStyles()
  const theme = useTheme()
  const { pathname: path } = useLocation()
  const navigate = useNavigate()
  const url = useResolvedPath('').pathname
  const { documents, updateStringDocument, loadContentOfDocument, loading } = useRAGContext()
  const prevLoadingRef = useRef<LoadingState>(loading)
  const origDocRef = useRef<RAGTxtDoc>()

  const [showDialog, setShowDialog] = useState<'discard' | 'updating'>()
  const [title, setTitle] = useState<string>()
  const [content, setContent] = useState<string>()
  const [sourceUrl, setSourceUrl] = useState<string>()
  const [urlError, setUrlError] = useState<string>()

  function hasChanges(): boolean {
    return (
      title !== origDocRef.current?.title ||
      content !== origDocRef.current?.content ||
      sourceUrl !== origDocRef.current?.sourceUrl
    )
  }

  function onTitleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setTitle(event.target.value)
  }

  function onContentChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setContent(event.target.value)
  }

  function onUrlChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setSourceUrl(event.target.value)
    const isValid = isValidUrl(event.target.value)
    if (!isValid && !urlError && event.target.value.length > 0) {
      setUrlError('Die URL ist ungültig.')
    } else if ((isValid && urlError) || event.target.value.length === 0) {
      setUrlError('')
    }
  }

  async function onUpdate(): Promise<void> {
    if (!title || !content) return
    await updateStringDocument(title, content, documentId, sourceUrl)
  }

  async function loadContent(): Promise<void> {
    const result = await loadContentOfDocument(documentId)
    if (result?.content) {
      // we also need to add this to the origDocRef
      if (origDocRef.current) {
        origDocRef.current.content = result.content
        setTitle(origDocRef.current.title)
        setSourceUrl(origDocRef.current.sourceUrl)
      }
      setContent(result.content)
    }
  }

  useEffect(() => {
    // set orig doc to compare against when checking for changes.
    origDocRef.current = documents[documentId] as RAGTxtDoc
    loadContent()
  }, [])

  useEffect(
    function navigateBackAfterSuccess() {
      if (prevLoadingRef.current === 'updating' && loading === 'updatingSuccess') {
        // successfully created - navigate back to overview
        navigate(url.substring(0, url.lastIndexOf('/')))
      }
      prevLoadingRef.current = loading
    },
    [loading],
  )

  return (
    <>
      <Helmet>
        <title>{APP_TITLE} - Wissensdatenbank</title>
      </Helmet>
      <ContentPage>
        <ContentPageHeader
          title={'Textinhalt bearbeiten'}
          actions={[
            <Button
              key='update-button'
              onClick={onUpdate}
              disabled={!title || !content || !!urlError || loading === 'updating' || !hasChanges()}
            >
              Aktualisieren
            </Button>,
          ]}
          previousUrl={url.substring(0, url.lastIndexOf('/'))}
          previousUrlCallback={(): boolean => {
            const changes = hasChanges()
            if (changes) setShowDialog('discard')
            return !changes
          }}
        />
        <BaseCard width={'100%'} height={'100%'} minHeight={'80vh'}>
          {loading === 'loading' ? (
            <CircularLoading text='Wird geladen...' />
          ) : (
            <>
              <div className={classes.titleContainer}>
                <Typography style={{ marginBottom: theme.spacing(1) }}>
                  Geben Sie dem neuen Inhalt einen aussagekräftigen Titel.
                </Typography>
                <Textfield
                  label='Titel'
                  placeholder='Titel des neuen Inhalts'
                  value={title}
                  onChange={onTitleChange}
                  fullWidth
                  multiline
                  rowsMax={3}
                />
              </div>
              <div className={classes.contentContainer}>
                <Typography style={{ marginBottom: theme.spacing(1) }}>
                  Fügen Sie hier den Text ein, den Sie zur Wissensdatenbank hinzufügen wollen.
                </Typography>
                <Textfield
                  label='Inhalt'
                  placeholder='Text, der zur Wissensdatenbank hinzugefügt werden soll...'
                  value={content}
                  onChange={onContentChange}
                  multiline
                  rowsMax={25}
                  rowsMin={15}
                  fullWidth
                />
              </div>
              <div className={classes.urlContainer}>
                <Typography style={{ marginBottom: theme.spacing(1) }}>
                  Falls der Text online verfügbar ist, können Sie hier die URL einfügen. Die URL wird in der
                  Konversation als Quelle angezeigt.
                </Typography>
                <Textfield
                  label='URL (optional)'
                  placeholder='Link zur Website des Textes'
                  value={sourceUrl}
                  onChange={onUrlChange}
                  fullWidth
                  error={!!urlError}
                  helperText={urlError}
                />
              </div>
              {/* <div className={classes.actionsContainer}>
                
              </div> */}
            </>
          )}
        </BaseCard>
        {loading === 'updating' && (
          <Dialog id='creating-document-dialog' open={loading === 'updating'} closable={false} size='small'>
            <CircularLoading text='Text wird aktualisiert...' />
          </Dialog>
        )}
        {showDialog === 'discard' && (
          <Dialog
            id='discard-changes-dialog'
            size='small'
            open={showDialog === 'discard'}
            closable
            onClose={() => setShowDialog(undefined)}
            title='Ungespeicherte Änderungen'
            primaryActionButton={
              <Button
                size='small'
                type='danger'
                icon={<Delete />}
                onClick={(): void => {
                  navigate(-1)
                }}
              >
                Verwerfen
              </Button>
            }
          >
            <Typography>
              Es existieren ungespeicherte Änderungen. Sind Sie sicher, dass Sie zurückgehen möchten? Die Änderungen
              werden dabei verworfen.
            </Typography>
          </Dialog>
        )}
      </ContentPage>
    </>
  )
}
