import { CheckBox } from '@mui/icons-material'
import React, { ReactElement } from 'react'
import EditorSidebarItem from '../editorSidebarItem'
import { FLOWDESIGNER_NODE_COLOR_MODULE } from 'utils/constants'

export default function xzufiTrackHelpful(): ReactElement {
  return (
    <EditorSidebarItem
      type='module/xzufi-trackSelectedAnswer'
      ports={{
        port1: {
          id: 'port1',
          type: 'left',
          position: { x: 0, y: 0 },
          properties: {
            type: 'incoming',
          },
        },
        port2: {
          id: 'port2',
          type: 'right',
          position: { x: 0, y: 0 },
          properties: {
            type: 'outgoing',
          },
        },
      }}
      properties={{
        typeText: 'XZuFi Ergebnis tracken',
        text: 'Nicht konfiguriert',
        xzufi: {},
        dialog: '',
      }}
      Icon={CheckBox}
      color={FLOWDESIGNER_NODE_COLOR_MODULE}
    />
  )
}
