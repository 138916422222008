import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { useTheme } from '@mui/material/styles'
// Custom Componets
import BaseCard from './BaseCard'

type StyleProps = {
  disabled?: boolean
  iconSize?: number
}

const useStyles = makeStyles<StyleProps>()((theme, props) => ({
  botCard: {
    transition: 'box-shadow 0.3s',
    cursor: props?.disabled ? 'auto' : 'pointer',
    opacity: props?.disabled ? 0.7 : 1,
    '&:hover': {
      boxShadow: props?.disabled
        ? '0px 2px 5px 0 rgba(0,0,0, 0.14)'
        : '0 7px 13px -12px rgb(0 0 0 / 42%), 0 1px 13px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)', // '0px 2px 10px 2px rgba(0,0,0, 0.25)'
    },
  },
  icon: {
    margin: 'auto',
    height: props?.iconSize ? `${props.iconSize}px` : '80px',
    width: props?.iconSize ? `${props.iconSize}px` : '80px',
    fontSize: props?.iconSize ? `${props.iconSize}px` : '80px',
    color: theme.palette.primary.main,
    lineHeight: '1',
  },
}))

type CardProps = {
  footerText: string
  icon: React.ReactElement
  onClick?: (event: any) => void
  disabled?: boolean
  width?: string
  height?: string
  minHeight?: string
  iconSize?: number
  isSelected?: boolean
}

/**
 * Base card for all cards in Studio 2.0.
 */
export default function IconCard({
  footerText,
  icon,
  onClick,
  minHeight,
  width = '100%',
  height = '220px',
  disabled = false,
  isSelected = false,
  iconSize = 80,
}: CardProps): React.ReactElement {
  const theme = useTheme()
  const { classes } = useStyles({ disabled, iconSize })
  return (
    <BaseCard
      footerText={footerText}
      footerColor={theme.palette.primary.main}
      paddingTop={theme.spacing(2)}
      height={height}
      width={width}
      isSelected={isSelected}
      className={classes.botCard}
      onClick={(event): void => {
        if (typeof onClick !== 'undefined' && !disabled) onClick(event)
      }}
      minHeight={minHeight}
    >
      <div className={classes.icon}>{icon}</div>
    </BaseCard>
  )
}
