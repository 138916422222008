/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef } from 'react'
import { DraggableContextProvider, useDraggableContextState } from '../../hooks/contexts/useDraggableContext'

/**
 * This function throttles function executions
 * @param f
 */
const throttle = (f: Function): any => {
  let token: number | null = null
  let lastArgs: any[] | null = null
  const invoke = (): void => {
    if (lastArgs) {
      f(...lastArgs)
      token = null
    }
  }
  const result = (...args: any[]): void => {
    lastArgs = args
    if (!token) {
      token = requestAnimationFrame(invoke)
    }
  }
  result.cancel = (): any => token && cancelAnimationFrame(token)
  return result
}

type DraggableComponentProps = {
  startPosition: { x: number; y: number }
  parent: HTMLDivElement
  content: React.ReactElement
}

function DraggableComponent({ startPosition, parent, content }: DraggableComponentProps): React.ReactElement {
  const { isDraggable } = useDraggableContextState()

  const draggableDimens = useRef<DOMRect | null>()
  const position = useRef<{ x: number; y: number }>(startPosition)

  const _ref = useRef<HTMLDivElement | null>(null)
  const parentDimens = parent.getBoundingClientRect()

  /**
   * Update: Re-calculate draggable dimensions and move to new position.
   */
  const _update = throttle(() => {
    if (_ref && _ref.current && position && position.current) {
      draggableDimens.current = _ref.current.getBoundingClientRect()
      _ref.current.style.transform = `translate(${position.current.x}px, ${position.current.y}px)`
    }
  })

  function _onMouseMove(event: MouseEvent): void {
    if (
      position &&
      position.current &&
      parentDimens &&
      draggableDimens &&
      draggableDimens.current &&
      (event.movementX !== 0 || event.movementY !== 0)
    ) {
      let { x, y } = position.current

      // x-axis
      if (x + event.movementX <= parentDimens.width - draggableDimens.current.width && x + event.movementX >= 0) {
        // within bounds
        x = x + event.movementX
      } else if (x + event.movementX > parentDimens.width - draggableDimens.current.width) {
        // right bound

        x = parentDimens.width - draggableDimens.current.width
      } else if (x + event.movementX < 0) {
        // left bound
        x = 0
      }

      // y-axis
      if (y + event.movementY <= parentDimens.height - draggableDimens.current.height && y + event.movementY >= 0) {
        // within bounds
        y += event.movementY
      } else if (y + event.movementY > parentDimens.height - draggableDimens.current.height) {
        // lower bound
        y = parentDimens.height - draggableDimens.current.height
      } else if (y + event.movementY < 0) {
        // upper bound
        y = 0
      }
      position.current = { x, y }
      _update()
    }
    // event.preventDefault()
  }

  function _onMouseUp(event: MouseEvent): void {
    document.removeEventListener('mousemove', _onMouseMove)
    document.removeEventListener('mouseup', _onMouseUp)
    event.preventDefault()
  }

  function _onMouseDown(event: MouseEvent): void {
    if (isDraggable) {
      if (event.button !== 0) {
        return
      }

      if (_ref && _ref.current) {
        document.addEventListener('mousemove', _onMouseMove)
        document.addEventListener('mouseup', _onMouseUp)
        event.preventDefault()
      }
    }
  }

  useEffect(() => {
    if (_ref && _ref.current) {
      _ref.current.addEventListener('mousedown', _onMouseDown)
      _update()
    }
    return (): void => {
      // remove eventlistener when component will unmount
      if (_ref && _ref.current) {
        _ref.current.removeEventListener('mousedown', _onMouseDown)
      }
      _update.cancel()
    }
  })

  useEffect(() => {
    if (_ref && _ref.current) {
      _ref.current.addEventListener('mousedown', _onMouseDown)
      _update()
    }
    return (): void => {
      // remove eventlistener when component will unmount
      if (_ref && _ref.current) {
        _ref.current.removeEventListener('mousedown', _onMouseDown)
      }
      _update.cancel()
    }
  }, [])

  return (
    <div style={{ position: 'absolute', top: '0px', zIndex: 5 }} id='draggable' ref={_ref}>
      {content}
    </div>
  )
}

export function Draggable({ startPosition, parent, content }: DraggableComponentProps): React.ReactElement {
  return (
    <DraggableContextProvider>
      <DraggableComponent startPosition={startPosition} parent={parent} content={content} />
    </DraggableContextProvider>
  )
}
