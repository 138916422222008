// https://learn.microsoft.com/de-de/adaptive-cards/rendering-cards/host-config#schema-actionsconfig
export const hostConfig = {
  containerStyles: {
    default: {
      foregroundColors: {
        default: {
          default: '#000',
          subtle: '#eee',
        },
        accent: {
          default: '#333333',
          subtle: '#0078D4',
        },
        attention: {
          default: '#D13438',
          subtle: '#A4262C',
        },
        dark: {
          default: '#000000',
          subtle: '#646464',
        },
        good: {
          default: '#0B6A0B',
          subtle: '#028A02',
        },
        light: {
          default: '#FFFFFF',
          subtle: '#FFFFFF',
        },
        warning: {
          default: '#B75C00',
          subtle: '#986F0B',
        },
      },
    },
    emphasis: {
      backgroundColor: '#f9f9f9',
      foregroundColors: {
        default: {
          default: '#000000',
          subtle: '#484644',
        },
      },
    },
    accent: {
      backgroundColor: '#C7DEF9',
      foregroundColors: {
        default: {
          default: '#333333',
          subtle: '#484644',
        },
      },
    },
    good: {
      backgroundColor: '#CCFFCC',
      foregroundColors: {
        default: {
          default: '#333333',
          subtle: '#484644',
        },
      },
    },
    attention: {
      backgroundColor: '#FFC5B2',
      foregroundColors: {
        default: {
          default: '#333333',
          subtle: '#484644',
        },
      },
    },
    warning: {
      backgroundColor: '#FFE2B2',
      foregroundColors: {
        default: {
          default: '#333333',
          subtle: '#484644',
        },
      },
    },
  },
  supportsInteractivity: true,
  fontFamily: 'Segoe UI, Helvetica Neue, sans-serif',
  imageSizes: {
    small: 40,
    medium: 80,
    large: 160,
  },
  actions: {
    // actionAlignment: 'flex-end',
    actionAlignment: 'right',
    actionsOrientation: 'vertical',
    buttonSpacing: 8,
    maxActions: 100,
    showCard: {
      actionMode: 'inline',
      inlineTopMargin: 8,
    },
    spacing: 'default',
  },
  adaptiveCard: {
    allowCustomStyle: false,
  },
  imageSet: {
    imageSize: 'medium',
    maxImageHeight: 100,
  },
  separator: {
    lineThickness: 0,
    lineColor: '#fff',
  },
  factSet: {
    title: {
      color: 'default',
      size: 'default',
      isSubtle: false,
      weight: 'bolder',
      wrap: true,
      maxWidth: 150,
    },
    value: {
      color: 'default',
      size: 'default',
      isSubtle: false,
      weight: 'default',
      wrap: true,
    },
    spacing: 8,
  },
  textBlock: {
    headingLevel: 1,
  },
}

export const exampleHostConfig = {
  spacing: {
    small: 3,
    default: 8,
    medium: 20,
    large: 30,
    extraLarge: 40,
    padding: 1,
  },
  separator: {
    lineThickness: 0,
    lineColor: '#fff',
  },
  supportsInteractivity: true,
  fontTypes: {
    default: {
      fontFamily:
        "'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
      fontSizes: {
        small: 20,
        default: 24,
        medium: 28,
        large: 30,
        extraLarge: 32,
      },
      fontWeights: {
        lighter: 200,
        default: 400,
        bolder: 600,
      },
    },
    monospace: {
      fontFamily: "'Courier New', Courier, monospace",
      fontSizes: {
        small: 12,
        default: 14,
        medium: 17,
        large: 21,
        extraLarge: 26,
      },
      fontWeights: {
        lighter: 200,
        default: 400,
        bolder: 600,
      },
    },
  },
  containerStyles: {
    default: {
      backgroundColor: '#FFFFFF',
      foregroundColors: {
        default: {
          default: '#293753',
          subtle: '#767676',
        },
        accent: {
          default: '#0063B1',
          subtle: '#0063B1',
        },
        attention: {
          default: '#FF0000',
          subtle: '#DDFF0000',
        },
        good: {
          default: '#54a254',
          subtle: '#DD54a254',
        },
        warning: {
          default: '#c3ab23',
          subtle: '#DDc3ab23',
        },
      },
    },
    emphasis: {
      backgroundColor: '#F0F0F0',
      foregroundColors: {
        default: {
          default: '#000000',
          subtle: '#767676',
        },
        accent: {
          default: '#2E89FC',
          subtle: '#882E89FC',
        },
        attention: {
          default: '#FF0000',
          subtle: '#DDFF0000',
        },
        good: {
          default: '#54a254',
          subtle: '#DD54a254',
        },
        warning: {
          default: '#c3ab23',
          subtle: '#DDc3ab23',
        },
      },
    },
    accent: {
      backgroundColor: '#C7DEF9',
      foregroundColors: {
        default: {
          default: '#333333',
          subtle: '#EE333333',
        },
        dark: {
          default: '#000000',
          subtle: '#66000000',
        },
        light: {
          default: '#FFFFFF',
          subtle: '#33000000',
        },
        accent: {
          default: '#2E89FC',
          subtle: '#882E89FC',
        },
        attention: {
          default: '#cc3300',
          subtle: '#DDcc3300',
        },
        good: {
          default: '#54a254',
          subtle: '#DD54a254',
        },
        warning: {
          default: '#e69500',
          subtle: '#DDe69500',
        },
      },
    },
    good: {
      backgroundColor: '#CCFFCC',
      foregroundColors: {
        default: {
          default: '#333333',
          subtle: '#EE333333',
        },
        dark: {
          default: '#000000',
          subtle: '#66000000',
        },
        light: {
          default: '#FFFFFF',
          subtle: '#33000000',
        },
        accent: {
          default: '#2E89FC',
          subtle: '#882E89FC',
        },
        attention: {
          default: '#cc3300',
          subtle: '#DDcc3300',
        },
        good: {
          default: '#54a254',
          subtle: '#DD54a254',
        },
        warning: {
          default: '#e69500',
          subtle: '#DDe69500',
        },
      },
    },
    attention: {
      backgroundColor: '#FFC5B2',
      foregroundColors: {
        default: {
          default: '#333333',
          subtle: '#EE333333',
        },
        dark: {
          default: '#000000',
          subtle: '#66000000',
        },
        light: {
          default: '#FFFFFF',
          subtle: '#33000000',
        },
        accent: {
          default: '#2E89FC',
          subtle: '#882E89FC',
        },
        attention: {
          default: '#cc3300',
          subtle: '#DDcc3300',
        },
        good: {
          default: '#54a254',
          subtle: '#DD54a254',
        },
        warning: {
          default: '#e69500',
          subtle: '#DDe69500',
        },
      },
    },
    warning: {
      backgroundColor: '#FFE2B2',
      foregroundColors: {
        default: {
          default: '#333333',
          subtle: '#EE333333',
        },
        dark: {
          default: '#000000',
          subtle: '#66000000',
        },
        light: {
          default: '#FFFFFF',
          subtle: '#33000000',
        },
        accent: {
          default: '#2E89FC',
          subtle: '#882E89FC',
        },
        attention: {
          default: '#cc3300',
          subtle: '#DDcc3300',
        },
        good: {
          default: '#54a254',
          subtle: '#DD54a254',
        },
        warning: {
          default: '#e69500',
          subtle: '#DDe69500',
        },
      },
    },
  },
  imageSizes: {
    small: 40,
    medium: 80,
    large: 160,
  },
  actions: {
    maxActions: 5,
    spacing: 'default',
    buttonSpacing: 8,
    showCard: {
      actionMode: 'inline',
      inlineTopMargin: 8,
    },
    actionsOrientation: 'vertical',
    actionAlignment: 'right',
  },
  adaptiveCard: {
    allowCustomStyle: false,
  },
  imageSet: {
    imageSize: 'medium',
    maxImageHeight: 100,
  },
  factSet: {
    title: {
      color: 'default',
      size: 'default',
      isSubtle: false,
      weight: 'bolder',
      wrap: true,
      maxWidth: 150,
    },
    value: {
      color: 'default',
      size: 'default',
      isSubtle: false,
      weight: 'default',
      wrap: true,
    },
    spacing: 8,
  },
}
