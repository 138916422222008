import React, { useState } from 'react'

import { makeStyles } from 'tss-react/mui'
import { IconButton, Typography } from '@mui/material'

import CollapsibleCard from 'components/Cards/CollapsibleCard'
import CustomizedTooltip from 'components/Tooltips/CustomContentTooltip'
import { Textfield } from 'components/TextInput/Textfield'

const useStyles = makeStyles()((theme) => ({
  collapsedContainer: {
    width: '100%',
    display: 'flex',
    cursor: 'pointer',
  },
  textfieldContainer: {
    width: '100%',
  },
  textfield: {
    width: '100%',
  },
  flexContainer: {
    display: 'flex',
  },
  iconButton: {},
  icon: {
    fontSize: '1.5rem',
  },
  addIcon: {
    marginRight: theme.spacing(1),
    fontSize: '1rem',
  },
}))

type AddVariableCardProps = {
  isValidVariableName: (newDisplayName: string) => { isValid: boolean; message?: string }
  onCreateVariable: (variableName: string) => void
}

export default function AddVariableCard({
  isValidVariableName,
  onCreateVariable: onCreateVariableCallback,
}: AddVariableCardProps): React.ReactElement {
  const { classes } = useStyles()

  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const [variableName, setVariableName] = useState<string>()
  const [error, setError] = useState<string>()

  /**
   * Handles variable name change.
   * Intercepts change event and adds required additional infos for the callback.
   * @param event
   */
  function onVariableNameChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setVariableName(event.target.value)
    const validCheck = isValidVariableName(event.target.value)
    if (!validCheck.isValid) {
      setError(validCheck.message ?? 'Es existiert bereits eine Variable mit diesem Namen!')
    } else {
      setError(undefined)
    }
  }

  /**
   * Handles submit.
   * Creates variable using callback.
   * @param event
   */
  function onCreateVariable(event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>): void {
    event.preventDefault()
    if (error || !variableName) return

    setIsExpanded(false)
    setVariableName(undefined)

    onCreateVariableCallback(variableName)
  }

  return (
    <CollapsibleCard
      title={
        !isExpanded ? (
          <div className={classes.collapsedContainer}>
            <i className={'ri-add-line ' + classes.addIcon} />
            <Typography variant='h4'>Variable hinzufügen</Typography>
          </div>
        ) : (
          <Typography variant='h4'>Namen der neuen Variablen festlegen:</Typography>
        )
      }
      heightExpanded='auto'
      heightCollapsed='auto'
      minHeightCollapsed={'65px'}
      width={'100%'}
      isCollapsed={!isExpanded}
      onExpandChange={setIsExpanded}
    >
      <div className={classes.textfieldContainer}>
        <form onSubmit={onCreateVariable}>
          <div className={classes.flexContainer}>
            <Textfield
              onChange={onVariableNameChange}
              value={variableName}
              placeholder='Bitte eingeben'
              InputProps={{ autoComplete: 'off' }}
              label='Variablenname'
              className={classes.textfield}
              error={!!error}
              helperText={error ?? ''}
              autoFocus
            />
            <CustomizedTooltip
              placement='top'
              disableInteractive
              content={<Typography>Variable erstellen</Typography>}
              elements={
                <IconButton
                  type='submit'
                  onClick={onCreateVariable}
                  aria-label='Add'
                  className={classes.iconButton}
                  disabled={!!error || !variableName}
                >
                  <i className={'ri-add-line ' + classes.icon} />
                </IconButton>
              }
            />
          </div>
        </form>
      </div>
    </CollapsibleCard>
  )
}
