import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import Chart from 'react-apexcharts'
import { getHeatmapOptions } from '../chartOptions'
import { LinearProgress } from '@mui/material'
import { getDateLabel, getFullWeekday } from '../../../utils/dateUtils'
import { DetailedCountResult, DetailedCountResultPerTime } from '../../../@types/Analytics/types'

const useStyles = makeStyles()({
  chartContainer: {
    height: '100%',
    width: '100%',
  },
  errorContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  linearProgressBarContainer: {
    display: 'flex',
    height: '100%',
  },
  linearProgressBar: {
    width: '80%',
    margin: 'auto',
  },
})

type DayOfWeekHeatmapProps = {
  heatmapId: string
  isLoading: boolean
  data?: DetailedCountResultPerTime
}

type HeatmapData = { name: string; data: number[] }[]

/**
 * Heatmap with days of week as y axis, and 24 hours as xAxis
 */
export default React.memo(function DayOfWeekHeatmap({
  heatmapId,
  isLoading: propIsLoading,
  data,
}: DayOfWeekHeatmapProps): React.ReactElement {
  const { classes } = useStyles()
  const options = getHeatmapOptions(heatmapId, 'hour', 'dayOfWeek', getHourRange)

  const [isLoading, setIsLoading] = useState<boolean>(propIsLoading)
  const dataRef = useRef<HeatmapData | null>(null)

  // ======= DATA PREPARATION ========
  /**
   * Prepares data in the right format for the dow-heatmap.
   * Returns array of weekdays, each weekday is array of hours, each hour has value.
   * [[0,0,...,0] // sunday
   *  ,...,
   *  [0,0,...,0] // saturday
   * ]
   * @param data
   * @returns
   */
  function prepareData(data: DetailedCountResultPerTime): HeatmapData {
    const heatmapData: HeatmapData = []
    for (let i = 0; i < 7; i += 1) heatmapData.push({ name: getDateLabel(i), data: Array(24).fill(0) })

    for (const entryDate of Object.keys(data)) {
      const date = new Date(entryDate)
      const value = data[entryDate]

      const day = date.getDay() // returns between 0 and 6, 0 being Sunday
      const hour = date.getHours()

      heatmapData[day].data[hour] += value.value || 0
    }
    return heatmapData
  }

  /**
   * Returns range string given hour.
   * E.g. value = 11: returns 10:00 - 11:00 Uhr
   * @param value
   * @param opts holds all properties of the chart
   */
  function getHourRange(value: number, opts: any): string {
    const seriesIndex = opts.seriesIndex
    let seriesName = ''
    try {
      seriesName = opts.w.config.series[seriesIndex].name
    } catch (e) {
      // could not retrieve series name
      // do nothing
      console.info('Heatmap: Could not retrieve series name')
    }

    const weekday = getFullWeekday(seriesName)
    value -= 1
    return `${weekday ? weekday + ': ' : ''} ${value < 10 ? '0' + value : value}:00 - ${
      value + 1 < 10 ? '0' + (value + 1) : value + 1
    }:00 Uhr`
  }

  useEffect(
    function () {
      if (typeof data === 'undefined') return

      const preparedData = prepareData(data)
      dataRef.current = preparedData
      // setIsLoading(propIsLoading)
    },
    [data],
  )

  useEffect(
    function () {
      setIsLoading(propIsLoading)
    },
    [propIsLoading],
  )

  return (
    <div className={classes.chartContainer}>
      {dataRef.current !== null && !isLoading ? (
        <Chart options={options} series={dataRef.current} type='heatmap' height='100%' width='100%' />
      ) : (
        <div className={classes.linearProgressBarContainer}>
          <LinearProgress className={classes.linearProgressBar} />
        </div>
      )}
    </div>
  )
})
