import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation, useResolvedPath } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Fuse from 'fuse.js'

// Material UI
import { makeStyles } from 'tss-react/mui'

import { Grid, Typography } from '@mui/material'
// Custom Components
import { ContentPageHeader } from 'components/Page/ContentPage'
import { Textfield } from 'components/TextInput/Textfield'
// constants
import { APP_TITLE, CUSTOM_NOTIFICATION_CARD_HEIGHT } from '../../../utils/constants'
// hooks
import {
  useCustomNotifications,
  saveNotifications,
  deleteNotifications,
  deleteExpiredNotifications,
  deactivateNotification,
  activateNotification,
  deactivateAllNotifications,
  activateAllNotifications,
  checkOverlapOfCustomNotification,
  NotificationActivatingStatus,
} from 'hooks/contexts/customnotifications-context'
// types
import { CustomNotification } from '../../../@types/CustomNotifications/types'
import NotificationCard from 'components/Cards/NotificationCard'
import { Button } from 'components/Buttons'
import { Delete } from '@mui/icons-material'
import { Dialog } from 'components/Dialogs'
import { useBotContext } from 'hooks/contexts/bot-context'
import { useLockingContext } from 'hooks/contexts/locking-context'
import NotificationSnackbar from 'components/NotificationSnackbar/NotificationSnackbar'
import IconCard from 'components/Cards/IconCard'
import { Option, SelectDropdown } from 'components/Dropdown'
import CircularLoading from 'components/Loading/CircularLoading'
import CreateCustomNotificationDialog from '../CreateCustomNotification/CreateCustomNotificationDialog'
import { fi } from 'date-fns/locale'
import { Can } from 'components/Can/Can'
import { useStudioNotificationContext } from 'hooks/contexts/studio-notification-context'

const useMenuStyles = makeStyles()({
  root: {},
})

type OptionsFilter = {
  showInactive: boolean
  showOnlyOverlapping: boolean
  // allNotificationsAction: 'deactivate' | 'activate'
}

enum DropdownAction {
  HIDE_DEACTIVATED = 'hide_deactivated_notifications',
  SHOW_DEACTIVATED = 'show_deactivated_notifications',
  HIDE_NON_OVERLAPPING = 'hide_non_overlapping_notifications',
  SHOW_NON_OVERLAPPING = 'show_non_overlapping_notifications',
  DELETE_EXPIRED_NOTIFICATIONS = 'delete_expired_notifications',
  DEACTIVATE_ALL_NOTIFICATIONS = 'deactivate_all_notifications',
  ACTIVATE_ALL_NOTIFICATIONS = 'activate_all_notifications',
}

export function CustomNotificationOverview(): React.ReactElement {
  const { classes } = useMenuStyles()
  const navigate = useNavigate()
  const url = useResolvedPath('').pathname
  useLocation()
  const { botId } = useParams() as { botId: string } // can cast here because component would not be shown if undefined
  const { bot } = useBotContext()
  const {
    dispatch: notificationsDispatch,
    notifications,
    overlappingNotifications,
    loadingState,
    duplicateNotification,
    setNotificationActivatingState,
    hasChanges: hasNotificationChanges,
  } = useCustomNotifications()
  const { lockState } = useLockingContext()
  const { setNotification: setSnackbarNotification } = useStudioNotificationContext()

  const [searchString, setSearchString] = useState<string>()
  const [displayedNotifications, setDisplayedNotifications] = useState<CustomNotification[]>([])
  // delete confirm dialog
  const [deleteNotificationId, setDeleteNotificationId] = useState<string>() // holds notification id
  // filter is used to manage dropdown actions
  const [filter, setFilter] = useState<OptionsFilter>({
    showInactive: true,
    showOnlyOverlapping: false,
    // allNotificationsAction: 'deactivate',
  })
  const [showNewDialog, setShowNewDialog] = useState<boolean>()

  const options: Option[] = [
    {
      label: filter.showInactive ? 'Deaktivierte Notifications ausblenden' : 'Deaktivierte Notifications einblenden',
      value: filter.showInactive ? DropdownAction.HIDE_DEACTIVATED : DropdownAction.SHOW_DEACTIVATED,
    },
    {
      label: !filter.showOnlyOverlapping ? 'Nur überlappende Notifications anzeigen' : 'Alle Notifications anzeigen',
      value: !filter.showOnlyOverlapping ? DropdownAction.HIDE_NON_OVERLAPPING : DropdownAction.SHOW_NON_OVERLAPPING,
    },
    {
      label: 'Abgelaufene Notifications löschen',
      value: DropdownAction.DELETE_EXPIRED_NOTIFICATIONS,
      disabled: lockState !== 'canEdit',
    },
    {
      label: 'Alle Notifications aktivieren',
      value: DropdownAction.ACTIVATE_ALL_NOTIFICATIONS,
      disabled: lockState !== 'canEdit',
    },
    {
      label: 'Alle Notifications deaktivieren',
      value: DropdownAction.DEACTIVATE_ALL_NOTIFICATIONS,
      disabled: lockState !== 'canEdit',
    },
  ]

  /**
   * Searches notifications for search string and returns result.
   */
  function filterNotifications(notifications: CustomNotification[]): CustomNotification[] {
    const prefiltered = notifications
      .filter((notification) => {
        // hide deactivated notification is filter is set
        if (!filter.showInactive && !notification.isActive) return false
        return true
      })
      .filter((notification) => {
        // hide non-overlapping notifications is filter is set
        // find all overlapping notificationIds
        if (filter.showOnlyOverlapping) {
          const overlappingIds = new Array(...new Set(Object.values(overlappingNotifications ?? {}).flat()))
          if (overlappingIds.length === 0) return true
          if (!overlappingIds.includes(notification.notificationId)) return false
        }
        return true
      })

    if (!searchString) return prefiltered

    const fuseOptions = {
      shouldSort: true,
      threshold: 0.4,
      minMatchCharLength: 1,
      keys: ['name', 'title', 'subtitle', 'content'],
    }
    const fuse = new Fuse(prefiltered ?? [], fuseOptions)
    return fuse.search(searchString).map((result) => result.item)
  }

  /**
   * Handles textinput into search field.
   * Updates search string in state.
   * @param event
   */
  function onSearchStringChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setSearchString(event.target.value)
  }

  /**
   * Checks if notification overlaps
   * @param newNotification
   */
  async function doesOverlap(newNotification: CustomNotification): Promise<boolean> {
    const overlappingIds = await checkOverlapOfCustomNotification(notificationsDispatch, botId, newNotification)
    if (notifications && overlappingIds && overlappingIds.length > 0) {
      return true
    } else {
      return false
    }
  }

  /**
   * Checks if notification overlaps exist
   */
  function hasOverlappingNotifications(): boolean {
    const overlaps = Object.values(overlappingNotifications ?? {}).flat()
    return overlaps.length > 0
  }

  /**
   * Handles dropdown option change.
   */
  async function onOptionDropdownChange(selection: Option): Promise<void> {
    switch (selection.value) {
      case DropdownAction.ACTIVATE_ALL_NOTIFICATIONS: {
        if (notifications) {
          activateAllNotifications(notificationsDispatch, botId, notifications, setSnackbarNotification)
          // setFilter({ ...filter, allNotificationsAction: 'deactivate' })
        }
        break
      }
      case DropdownAction.DEACTIVATE_ALL_NOTIFICATIONS: {
        if (notifications) {
          deactivateAllNotifications(notificationsDispatch, botId, notifications, setSnackbarNotification)
          // setFilter({ ...filter, allNotificationsAction: 'activate' })
        }
        break
      }
      case DropdownAction.DELETE_EXPIRED_NOTIFICATIONS: {
        if (!bot || !notifications) return
        await deleteExpiredNotifications(notificationsDispatch, bot.id, notifications, setSnackbarNotification)
        break
      }
      case DropdownAction.HIDE_DEACTIVATED: {
        setFilter({ ...filter, showInactive: false })
        break
      }
      case DropdownAction.SHOW_DEACTIVATED: {
        setFilter({ ...filter, showInactive: true })
        break
      }
      case DropdownAction.HIDE_NON_OVERLAPPING: {
        setFilter({ ...filter, showOnlyOverlapping: true })
        break
      }
      case DropdownAction.SHOW_NON_OVERLAPPING: {
        setFilter({ ...filter, showOnlyOverlapping: false })
        break
      }
      default: {
        // do nothing
      }
    }
  }

  function onEdit(notificationId: string): void {
    // push history (see edit answer)
    navigate(url + `/${notificationId}`)
  }

  /**
   * Changes active state in notification.-
   * @param notificationId
   */
  async function onChangeActiveState(notificationId: string): Promise<void> {
    if (!notifications) return
    if (notifications[notificationId].isActive) {
      deactivateNotification(notificationsDispatch, botId, notifications[notificationId], setSnackbarNotification)
    } else {
      setNotificationActivatingState({
        status: NotificationActivatingStatus.Activating,
        notificationIds: [notificationId],
      })
      const notification = { ...notifications[notificationId], isActive: true }
      const isOverlapping = await doesOverlap(notification)
      if (!isOverlapping) {
        await activateNotification(notificationsDispatch, botId, notification, setSnackbarNotification)
      } else {
        // cannot activate because overlap. Show notification hint.
        setSnackbarNotification(
          'warning',
          'Notification konnte nicht aktiviert werden, weil eine andere Notification in diesem Zeitraum aktiv ist. Deaktivieren Sie die andere Notification und versuchen Sie es erneut.',
          5000,
          true,
        )
        setNotificationActivatingState(null)
      }
    }
  }

  function onCloseNewNotificationDialog(): void {
    setShowNewDialog(undefined)
  }

  /**
   * Handles delete confirm dialog click.
   * Deletes notification.
   */
  async function onDeleteNotificationConfirm(): Promise<void> {
    if (!deleteNotificationId || !bot) return
    await deleteNotifications(notificationsDispatch, bot.id, [deleteNotificationId], setSnackbarNotification)
    setDeleteNotificationId(undefined)
  }

  useEffect(
    function () {
      if (notifications !== null) {
        const filteredNotifications = filterNotifications(Object.values(notifications))
        setDisplayedNotifications(filteredNotifications)
      }
    },
    [notifications, searchString, filter],
  )

  // ===== ACTIONS ======
  const SearchField = <Textfield value={searchString} placeholder='Suchen' onChange={onSearchStringChange} />

  const OptionsDropdown = (
    <SelectDropdown
      options={options}
      selected={undefined}
      placeholder='Optionen'
      onChange={onOptionDropdownChange}
      maxDisplayOptions={10}
      width={'220px'}
    />
  )

  const SaveButton = (
    <Can I='update' a='notifications' passThrough>
      {(can: boolean): React.ReactElement => (
        <Button
          size='normal'
          type='success'
          icon='save-line'
          iconType='remix'
          onClick={(event): void => {
            event?.preventDefault()
            if (!bot || !notifications) return
            saveNotifications(notificationsDispatch, bot.id, notifications, setSnackbarNotification)
          }}
          loading={loadingState === 'saving'}
          // disabled={lockState !== 'canEdit' || typeof loadingState !== 'undefined' || !hasNotificationChanges}
          disabled={!can || lockState !== 'canEdit' || !hasNotificationChanges || hasOverlappingNotifications()}
        >
          Speichern
        </Button>
      )}
    </Can>
  )

  return (
    <div>
      <Helmet>
        <title>{APP_TITLE} - Notifications</title>
      </Helmet>
      <ContentPageHeader title='Notifications' actions={[SearchField, OptionsDropdown, SaveButton]} />
      {loadingState === 'loading' ? (
        <CircularLoading text='Notifications werden geladen. Bitte warten...' size='medium' />
      ) : (
        <Grid container spacing={6}>
          {displayedNotifications.map((notification, index) => {
            return (
              <Grid key={`custom-notification-${index}`} item xs={12} md={4}>
                {notifications && notifications[notification.notificationId] && (
                  <NotificationCard
                    notificationId={notification.notificationId}
                    notifications={notifications}
                    overlappingNotificationIds={
                      overlappingNotifications ? overlappingNotifications[notification.notificationId] ?? [] : []
                    }
                    onEdit={onEdit}
                    onDelete={setDeleteNotificationId}
                    onDuplicate={duplicateNotification}
                    onChangeActiveState={onChangeActiveState}
                  />
                )}
              </Grid>
            )
          })}
          <Can I='create' a='notifications'>
            <Grid key={`add-custom-notification`} item xs={12} md={4}>
              <IconCard
                footerText='Neue Notification erstellen'
                icon={<i className={`ri-add-line`} />}
                onClick={(): void => setShowNewDialog(true)}
                height={CUSTOM_NOTIFICATION_CARD_HEIGHT}
                disabled={lockState !== 'canEdit'}
              />
            </Grid>
          </Can>
        </Grid>
      )}
      {deleteNotificationId && (
        <Dialog
          id='delete-notification-confirm-dialog'
          size='small'
          open={typeof deleteNotificationId === 'string'}
          closable
          onClose={(): void => setDeleteNotificationId(undefined)}
          title='Notification löschen'
          primaryActionButton={
            <Button size='small' type='danger' icon={<Delete />} onClick={onDeleteNotificationConfirm}>
              Löschen
            </Button>
          }
        >
          <Typography>
            Möchten Sie diese Notification wirklich löschen? Dies kann nicht rückgängig gemacht werden!
          </Typography>
        </Dialog>
      )}
      {showNewDialog && <CreateCustomNotificationDialog onClose={onCloseNewNotificationDialog} />}
    </div>
  )
}
