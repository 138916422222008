import React, { useEffect, useState } from 'react'
import Button from '../../../../components/Buttons/Button'
import Dialog from '../../../../components/Dialogs/Dialog'
import { SelectDropdown, Option } from '../../../../components/Dropdown'

import { LANGUAGE_DICTIONARY_DE } from '../../../../utils/constants'
import CircularLoading from 'components/Loading/CircularLoading'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()({
  loadingContainer: {
    height: '100%',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
})

type AddLanguageDialogProps = {
  open: boolean
  existingLanguages: string[] // language codes of languages that are already set in assistant
  onAddLanguage: (langCode: string) => void
  loading: string | undefined
  onClose: () => void
}

export default function AddLanguageDialog({
  open,
  existingLanguages,
  loading: _loading,
  onAddLanguage,
  onClose,
}: AddLanguageDialogProps): React.ReactElement {
  const { classes } = useStyles()
  const [options, setOptions] = useState<Option[]>([])
  const [selectedOption, setSelectedOption] = useState<Option>()
  const [loading, setLoading] = useState<string>()

  useEffect(function () {
    // prepare selection options
    const newOptions: Option[] = []
    for (const code of Object.keys(LANGUAGE_DICTIONARY_DE)) {
      if (!existingLanguages.includes(code))
        newOptions.push({
          label: LANGUAGE_DICTIONARY_DE[code],
          value: code,
        })
    }
    setOptions(newOptions)
  }, [])

  useEffect(
    function () {
      setLoading(_loading)
    },
    [_loading],
  )

  return (
    <Dialog
      id='add-language-dialog'
      size='small'
      open={open}
      closable
      onClose={onClose}
      title='Sprache hinzufügen'
      primaryActionButton={
        <Button
          size='small'
          type='normal'
          iconType='remix'
          icon='add-line'
          disabled={!selectedOption}
          onClick={(): void => {
            if (selectedOption) onAddLanguage(selectedOption.value)
          }}
        >
          Hinzufügen
        </Button>
      }
    >
      {loading === 'creating' || loading === 'addingLanguage' ? (
        <div className={classes.loadingContainer}>
          <CircularLoading text={'Sprache wird hinzugefügt...'} />
        </div>
      ) : (
        <div>
          <SelectDropdown
            options={options}
            selected={selectedOption}
            isSearchable
            placeholder='Sprache auswählen'
            noOptionsMessage='Keine Sprache verfügbar'
            onChange={(newValue: Option): void => setSelectedOption(newValue)}
          />
        </div>
      )}
    </Dialog>
  )
}
