import React, { useState, useEffect, memo } from 'react'
import { cloneDeep, isEqual } from 'lodash'
// Custom Components
import ChooseCardOrCreateCardMenu from './ChooseCardOrCreateCardMenu'
import ChooseCardTypeTemplate from './SelectCardType'
import CardBuilder from './CardBuilder/CardBuilder'
// Types
import { Card, Cards, CardType, CardTypeEnum } from '../../../../../../@types/SmartCards/types'
import { Node, Chart } from '../../../../../../@types/Flowchart/types'
import { TranslationFile } from '../../../../../../@types/Translations/types'
// Utils
import { templateCardsIds } from 'utils/templateCardsIds'
import { countSmartCardUsage } from 'utils/smartCardUtils'
import { deleteSmartCard } from 'utils/translationFileUtils'

enum CreationStage {
  MenuChooseMode, // Select existing card or create a new one
  ChooseCardType, // Select Card type for new card
  CardBuilder, // Build the card
}

type CreationProcessProps = {
  nodeOnLoad: Node
  translations: TranslationFile
  chart: Chart
  cardIdOnLoad?: string
  onDelete: () => void
  onSaveChart: (chart: Chart) => void
  setTranslationsCallback: (translations: TranslationFile) => void
  setTranslationNeedSavingCallback: (needsSaving: boolean) => void
}

export default memo(function CreationProcess({
  nodeOnLoad, // origNode to compare changes against
  translations,
  chart: origChart,
  cardIdOnLoad,
  onDelete,
  onSaveChart,
  setTranslationsCallback,
  setTranslationNeedSavingCallback,
}: CreationProcessProps): React.ReactElement {
  const [stage, setStage] = useState<CreationStage>(0)
  const [cardType, setCardType] = useState<CardTypeEnum | undefined>()
  const [card, setCard] = useState<Card | undefined>() // only needed once oneway for children - not updated by builder - card in state to make sure the type is corrently controlled and on change triggeres an update of the translationfile~ prob not necessary to have card here in state
  const [isClonedCard, setIsClonedCard] = useState<boolean>() // set to true if an existing card is selected, that is already used in another card.
  const [clonedCardOrigName, setClonedCardOrigName] = useState<string>() // holds the automatically generated name (= id) of the card that is cloned (if a card is cloned)
  // const [node, setNode] = useState<Node>({ ...origNode }) // - not updated by builder - node in state to make sure the type and name is corrently controlled ~ prob not necessary to have node here in state
  const [chart, setChart] = useState<Chart>(cloneDeep(origChart))
  const [usedCardIds, setUsedCardIds] = useState<string[]>([])
  const node = cloneDeep(chart.nodes[nodeOnLoad.id]) // for convenience
  // const [nodeOnLoad, setNodeOnLoad] = useState<Node>(cloneDeep(origNode))
  const [mode, setMode] = useState<'create' | 'manage'>('manage') // tells the builder if a card is new in the creation or existing

  // Checks if a card exists on the node and updates the cardType in the node accordingly if necessary
  useEffect(() => {
    if (!node) return
    const updatedNode = { ...node }
    // check if a card exists on the Node
    if (updatedNode && typeof updatedNode.properties.card === 'string' && updatedNode.properties.card.length > 0) {
      // check if the referenced card exists
      if (typeof translations.ac[translations.primaryLanguage][updatedNode.properties.card] !== 'undefined') {
        const existingCard = { ...translations.ac[translations.primaryLanguage][updatedNode.properties.card] }
        // if the type of the existing card is undefined set it to custom before setting in state
        if (typeof existingCard.cardType === 'undefined') {
          existingCard.cardType = CardTypeEnum.CustomCard
          updatedNode.properties.cardType = CardTypeEnum.CustomCard
        }
        chart.nodes[node.id] = updatedNode
        setCard(existingCard)
        setChart({ ...chart })
        if (existingCard.cardType) setCardType(existingCard.cardType as CardTypeEnum)
        setStage(CreationStage.CardBuilder)
      } else {
        // referenced card does not exist so remove it from the card prop
        updatedNode.properties.card = ''
        updatedNode.properties.text = 'Karte wählen'
        chart.nodes[node.id] = updatedNode
        setChart({ ...chart })
      }
    }
  }, [])

  useEffect(() => {
    // find cardids that are currently used
    const usedSmartCards = Object.values(chart.nodes)
      .filter((node) => node.type === 'basic/adaptiveCard')
      .map((node) => node.properties.card ?? '')
      .filter((cardId: string): boolean => cardId !== '')
    setUsedCardIds(usedSmartCards)
  }, [chart])

  /**
   * Updates translation file.
   * Ensure changes (esp. card-renames) are properly set in the translation file.
   * Checks if the card of the node has been cloned and re-named by the user.
   * If so, removes the "original" clone (with "-Kopie" in the name) from the translation file.
   * @param card
   * @returns
   */
  function updateTranslationFile(card): void {
    if (!node || !card) return
    // did the node exist before this dialog opened with a referenced card?
    // if the name of the card was changed and the card (with its old name) is not used in any node - remove it
    // else if the card with the old name is used, this basically creates a clone with the new name

    const tmpTranslations = { ...translations }

    if (cardIdOnLoad || (isClonedCard && clonedCardOrigName)) {
      // CASE: card was already selected and renamed OR card was cloned and renamed
      let oldCardName
      if (cardIdOnLoad && cardIdOnLoad !== card.id) {
        // card was renamed
        oldCardName = cardIdOnLoad
      } else if (isClonedCard && clonedCardOrigName && clonedCardOrigName !== card.id) {
        // card was cloned and renamed
        oldCardName = clonedCardOrigName
      }

      if (oldCardName) {
        // user has changed the name of the card, remove the "old" cloned card from the translation file
        // card with user changed name is also present in the translation file!
        if (typeof tmpTranslations.ac[translations.primaryLanguage][oldCardName] !== 'undefined') {
          // check if any other nodes use the old card id (should never be the case as the card is freshly cloned, but just to be safe.)
          let foundNodeWithName = false
          for (const nodeId of Object.keys(chart.nodes)) {
            if (
              nodeId !== node.id &&
              typeof chart.nodes[nodeId].properties.card !== 'undefined' &&
              chart.nodes[nodeId].properties.card === oldCardName
            ) {
              // found other node that also has the card referenced
              foundNodeWithName = true
            }
          }
          if (!foundNodeWithName) {
            // no node with the old card name found - remove old card (for all languages!)
            for (const lang of Object.keys(tmpTranslations.ac)) {
              delete tmpTranslations.ac[lang][oldCardName]
            }
          }
        }
      }
    }
    // CASE: Node had no card selected and the selected / created card has not been cloned
    tmpTranslations.ac[translations.primaryLanguage][card.id] = card
    setTranslationsCallback(tmpTranslations)
    setTranslationNeedSavingCallback(true)
  }

  /**
   * Save callback.
   * Updates the translation file and saves the chart.
   * Ensures that the right card is set in the node.
   * @param chart
   * @param card
   */
  function onSave(chart: Chart, card: Card): void {
    // ensure node uses the correct card id
    if (!chart.nodes[node.id]) {
      // should never be the case
      chart.nodes[node.id] = node
    }
    chart.nodes[node.id].properties.card = card.id
    updateTranslationFile(card)
    onSaveChart(chart)
  }

  /**
   * Filters card.
   * Removes template cards.
   * @param cards
   */
  function loadCards(): Card[] {
    // Prepare Cards by removing template cards
    const filteredCards: Card[] = []
    // get all cards from translations
    const nonTemplateCardNames = Object.keys(translations.ac[translations.primaryLanguage]).filter(
      (key) => !templateCardsIds.includes(key),
    )
    for (const cardName of nonTemplateCardNames) {
      filteredCards.push(translations.ac[translations.primaryLanguage][cardName])
    }

    return filteredCards
  }

  /**
   * Handles selection of existing card.
   * Checks if the card is already in use. If so, creates a clone of the card (appends "-Kopie" to the card name)
   * and adds clone in the translation file.
   * @param cardName
   */
  function onSelectCard(cardName: string): void {
    const isCardInUse = countSmartCardUsage(chart, cardName) > 0

    let selectedCardName = cardName
    if (isCardInUse) {
      // card is already in use - create a clone
      const clonedCard = cloneDeep(translations.ac[translations.primaryLanguage][cardName])
      const existingCardNames = Object.keys(translations.ac[translations.primaryLanguage])
      let newCardName = `${cardName}-Kopie`
      while (existingCardNames.includes(newCardName)) {
        // ensure new name is unique
        newCardName = `${newCardName}-Kopie`
      }
      clonedCard.id = newCardName
      translations.ac[translations.primaryLanguage][newCardName] = clonedCard
      selectedCardName = newCardName
      setIsClonedCard(true)
      setClonedCardOrigName(newCardName)
    }

    setCard(translations.ac[translations.primaryLanguage][selectedCardName])
    // set card in current node
    const nodeNew = { ...node }
    nodeNew.properties.card = selectedCardName
    nodeNew.properties.text = selectedCardName
    nodeNew.properties.cardType =
      translations.ac[translations.primaryLanguage][selectedCardName].cardType ?? CardTypeEnum.CustomCard
    chart.nodes[node.id] = nodeNew
    setChart({ ...chart })
    setCardType(
      (translations.ac[translations.primaryLanguage][selectedCardName].cardType as CardTypeEnum) ??
        CardTypeEnum.CustomCard,
    )
    setStage(CreationStage.CardBuilder)
  }

  /**
   * Checks if card is used by any node and deletes it from translations that is not the case.
   * @param cardId
   */
  function onDeleteCard(cardId: string): void {
    const cardIdsInUse = Object.values(chart.nodes)
      .filter((node) => node.type === 'basic/adaptiveCard')
      .map((node) => node.properties.card ?? '')
      .filter((cardId: string): boolean => cardId !== '')

    if (!cardIdsInUse.includes(cardId)) {
      const newTranslations = deleteSmartCard(translations, cardId)
      setTranslationsCallback(newTranslations)
      setTranslationNeedSavingCallback(true)
    }
  }

  function getComponent(): React.ReactElement {
    switch (stage) {
      case CreationStage.ChooseCardType:
        return (
          <ChooseCardTypeTemplate
            setNext={(type): void => {
              setCardType(type)
              setStage(CreationStage.CardBuilder)
              setMode('create')
            }}
            onBackClick={(): void => {
              setStage(CreationStage.MenuChooseMode)
            }}
          />
        )
      case CreationStage.CardBuilder:
        return (
          <CardBuilder
            type={cardType ? cardType : CardTypeEnum.CustomCard}
            nodeOnLoad={nodeOnLoad}
            card={card}
            isClonedCard={isClonedCard}
            mode={mode}
            translations={translations}
            chart={chart}
            onBackClick={(): void => {
              // Lets the user move to the Chose Type stage even if the card already exists
              // in that case reset the card
              // TODO: WHAT HAPPENS HERE?
              setStage(CreationStage.ChooseCardType)
              setCard(undefined)
            }}
            onDelete={onDelete}
            onSave={onSave}
          />
        )
      case CreationStage.MenuChooseMode:
      default:
        return (
          <ChooseCardOrCreateCardMenu
            cards={loadCards}
            usedCards={usedCardIds}
            setChoosenCard={onSelectCard}
            setNext={(mode): void => {
              mode === 'create' ? setStage(CreationStage.ChooseCardType) : setStage(CreationStage.CardBuilder)
            }}
            onDelete={onDelete}
            onDeleteExistingCard={onDeleteCard}
          />
        )
    }
  }

  const component = getComponent()

  return <>{component}</>
}, isEqual)
