import React, { createContext, useContext, useReducer, useCallback, useEffect, useMemo } from 'react'
import { cloneDeep, omit, isEqual, isEmpty } from 'lodash'
// Types
import {
  Answers,
  AnswerTemplateData,
  AnswerTranslation,
  KnowledgeType,
  SyncFlag,
  FlaggedAnswers,
  ModelStatus,
  AnswerAction,
  LoadingState,
} from '../../@types/Knowledge/types'
import { Answer, AnswerWithoutIntent, buildAnswerObject, TriggerAnswerWithoutIntent } from '../../classes/Knowledge'
import {
  getAnswers,
  saveAnswers as saveAnswersApi,
  createAnswer as createAnswerApi,
  deleteAnswerOrAnswers as deleteAnswersApi,
  SaveAnswersUtterances,
  trainAndPublishNLUModel as trainAndPublishNluModelApi,
  getNLUStatus,
} from 'api/StudioBackend'
import { useBotContext } from './bot-context'
import { useLockingContext } from './locking-context'
import { useStudioNotificationContext } from './studio-notification-context'
import { useErrorContext } from './errorContext'
import { AuthorizationEntry } from '../../@types/Authorization/types'
import { BotInfos } from '../../@types/BotInformation/types'

// ----- TYPE DEFINITIONS -----

// Maps Actions to Payloads - Standard does not need customization
type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key
      }
    : {
        type: Key
        payload: M[Key]
      }
}

// Possible Actions
enum Types {
  SetLoading = 'SET_LOADING',
  // Setters for state
  SetAnswers = 'SET_ANSWERS',
  SetPrimaryLang = 'SET_PRIMARY_LANG',
  SetBotId = 'SET_BOTID',
  SetKnowledgeType = 'SET_KNOWLEDGETYPE',
  SetError = 'SET_ERROR',
  SetModelStatus = 'SET_MODELSTATUS',
  SetAnswersOrig = 'SET_ANSWERS_ORIG',
  // Needed?
  UpdateAnswer = 'UPDATE_ANSWER',
  UpdateSingleAnswerTranslatino = 'UPDATE_ANSWER_TRANSLATION',
  UpdateAnswersBatch = 'UPDATE_ANSWERS_BATCH',
  UpdateSingleAnswerTranslationsBatch = 'UPDATE_ANSWER_TRANSLATIONS_BATCH',
  SaveAnswers = 'SAVE_ANSWERS',
  DeleteAnswer = 'DELETE_ANSWER',
  CreateAnswer = 'CREATE_ANSWER',
  ResetState = 'RESET_STATE',
  LoadAnswers = 'LOAD_ANSWERS',
}

// Payloads for each action
type AnswersContextPayload = {
  // Setters
  [Types.SetAnswers]: {
    answers: Answers | null
    flag: SyncFlag
  }
  [Types.SetAnswersOrig]: {
    answersOrig: Answers | null
    flag: SyncFlag
  }
  [Types.SetPrimaryLang]: {
    primaryLang: string
  }
  [Types.SetBotId]: {
    botId: string
  }
  [Types.SetKnowledgeType]: {
    knowledgeType: KnowledgeType
  }
  [Types.SetError]: {
    error: string
  }
  [Types.UpdateAnswer]: {
    answer: Answer
    flag: SyncFlag
  }
  [Types.UpdateSingleAnswerTranslatino]: {
    answer: AnswerTranslation
    lang: string //TODO: langauge type
    flag: SyncFlag
  }
  [Types.UpdateAnswersBatch]: {
    answers: Answers
    flag: SyncFlag
    orig?: boolean
  }
  [Types.UpdateSingleAnswerTranslationsBatch]: {
    answers: { [lang: string]: AnswerTranslation }
    flag: SyncFlag
  }
  [Types.ResetState]: {}
  [Types.DeleteAnswer]: {
    answerId: string
  }
  [Types.SetModelStatus]: {
    modelStatus: {
      trainingStatus?: ModelStatus['trainingStatus']
      publishingStatus?: ModelStatus['publishingStatus']
      trainingError?: ModelStatus['trainingError']
    }
  }
  [Types.SetLoading]: {
    loading: LoadingState
  }
}

type Action = ActionMap<AnswersContextPayload>[keyof ActionMap<AnswersContextPayload>]
type State = {
  flaggedAnswers: FlaggedAnswers | null
  flaggedAnswersOrig: FlaggedAnswers | null // orginal answers -> always set on save or load -> should always have version equal to the storage
  primaryLang: string | null //TODO: langauge type
  botId: string | null
  knowledgeType: KnowledgeType | null
  error: string | null //TODO: to be defined
  modelStatus: ModelStatus | null
  loading: LoadingState | null
}

type Dispatch = (action: Action) => void
type AnswersProviderProps = { children: React.ReactNode }

/**
 * ----- CONTEXT -----
 * Answers Context is a local context used for storing everything needed to manage answers
 *
 * To access this context:
 * import { useAnswersContext } from '<path>/hooks/contexts/answers-context.tsx'
 *
 * // in a function
 * const {bot, setBot, resetBot} = useAnswersContext()
 *
 * setBot(botInfos)
 */
const AnswersContext = createContext<
  { state: State; dispatch: Dispatch; reloadAnswersFromAPI: () => Promise<void> } | undefined
>(undefined)

// TODO: maybe add something like an init action

function answersReducer(state: State, action: Action): State {
  switch (action.type) {
    // SETTERS
    case Types.SetAnswers: {
      // make sure to spread that state just in case!
      if (action.payload.answers) {
        // clone deep to prevent accidental references to the flaggedAnswersOrig (if they are set at the same time)
        return { ...state, flaggedAnswers: { answers: cloneDeep(action.payload.answers), flag: action.payload.flag } }
      }
      return { ...state }
    }
    case Types.SetAnswersOrig: {
      // make sure to spread that state just in case!
      if (action.payload.answersOrig) {
        return {
          ...state,
          // clone deep to prevent accidental references to the flaggedAnswer (if they are set at the same time)
          flaggedAnswersOrig: { answers: cloneDeep(action.payload.answersOrig), flag: action.payload.flag },
        }
      }
      return { ...state }
    }
    case Types.SetPrimaryLang: {
      // make sure to spread that state just in case!
      return { ...state, primaryLang: action.payload.primaryLang }
    }
    case Types.SetBotId: {
      // make sure to spread that state just in case!
      return { ...state, botId: action.payload.botId }
    }
    case Types.SetKnowledgeType: {
      // make sure to spread that state just in case!
      return { ...state, knowledgeType: action.payload.knowledgeType }
    }
    case Types.SetError: {
      // make sure to spread that state just in case!
      return { ...state, error: action.payload.error }
    }
    case Types.SetModelStatus: {
      // make sure to spread that state just in case!
      // only update if it has changed
      if (
        state.modelStatus?.publishingStatus !== action.payload.modelStatus.publishingStatus ||
        state.modelStatus?.trainingStatus !== action.payload.modelStatus.trainingStatus
      ) {
        return {
          ...state,
          modelStatus: {
            trainingStatus:
              action.payload.modelStatus.trainingStatus ?? state.modelStatus?.trainingStatus ?? 'NeedsTraining',
            publishingStatus:
              action.payload.modelStatus.publishingStatus ?? state.modelStatus?.publishingStatus ?? 'NeedsPublishing',
            trainingError: action.payload.modelStatus.trainingError,
          },
        }
      } else {
        return state
      }
    }
    case Types.UpdateAnswer: {
      // make sure to spread that state just in case!
      if (state.flaggedAnswers?.answers) {
        let newAnswers = cloneDeep(state.flaggedAnswers.answers)
        if (state.flaggedAnswers && state.primaryLang) {
          newAnswers = addOrReplaceAnswer(action.payload.answer, state.flaggedAnswers.answers, state.primaryLang)
        }
        return { ...state, flaggedAnswers: { answers: newAnswers, flag: action.payload.flag } }
      }
      return { ...state }
    }
    case Types.UpdateSingleAnswerTranslatino: {
      // make sure to spread that state just in case!
      if (state.flaggedAnswers?.answers) {
        let newAnswers = cloneDeep(state.flaggedAnswers.answers)
        if (state.flaggedAnswers) {
          newAnswers = addOrReplaceAnswer(action.payload.answer, state.flaggedAnswers.answers, action.payload.lang)
        }
        return { ...state, flaggedAnswers: { answers: newAnswers, flag: action.payload.flag } }
      }
      return { ...state }
    }
    case Types.UpdateAnswersBatch: {
      if (action.payload.orig) {
        // make sure to spread that state just in case!
        if (state.flaggedAnswersOrig?.answers) {
          let newAnswers = cloneDeep(state.flaggedAnswersOrig.answers)
          if (state.flaggedAnswersOrig && state.primaryLang) {
            // clone deep to prevent accidental references to the flaggedAnswers (if they are set at the same time)

            newAnswers = addOrReplaceAnswersBatch(cloneDeep(action.payload.answers), newAnswers)
          }
          return { ...state, flaggedAnswersOrig: { answers: newAnswers, flag: action.payload.flag } }
        }
      } else {
        // make sure to spread that state just in case!
        if (state.flaggedAnswers?.answers) {
          let newAnswers = cloneDeep(state.flaggedAnswers.answers)
          if (state.flaggedAnswers && state.primaryLang) {
            // clone deep to prevent accidental references to the flaggedAnswersOrig (if they are set at the same time)
            newAnswers = addOrReplaceAnswersBatch(cloneDeep(action.payload.answers), newAnswers)
          }
          return { ...state, flaggedAnswers: { answers: newAnswers, flag: action.payload.flag } }
        }
      }
      return { ...state }
    }
    case Types.UpdateSingleAnswerTranslationsBatch: {
      // make sure to spread that state just in case!
      if (state.flaggedAnswers?.answers) {
        let newAnswers = cloneDeep(state.flaggedAnswers.answers)
        const _newAnswerTranslations: Answers = {}
        for (const lang of Object.keys(action.payload.answers)) {
          // init language
          if (typeof _newAnswerTranslations[lang] === 'undefined') {
            _newAnswerTranslations[lang] = {}
          }
          // add translated answer
          _newAnswerTranslations[lang][action.payload.answers[lang].answerId] = action.payload.answers[lang]
        }

        if (state.flaggedAnswers && state.primaryLang) {
          newAnswers = addOrReplaceAnswersBatch(_newAnswerTranslations, state.flaggedAnswers.answers)
        }
        return { ...state, flaggedAnswers: { answers: newAnswers, flag: action.payload.flag } }
      }
      return { ...state }
    }
    case Types.SetLoading:
      return { ...state, loading: action.payload.loading }
    case Types.ResetState: {
      // make sure to spread that state just in case!
      return {
        ...state,
        flaggedAnswers: null,
        flaggedAnswersOrig: null,
        knowledgeType: null,
        botId: null,
        primaryLang: null,
        error: null,
      }
    }
    default: {
      // helps us avoid typos!
      throw new Error(`[AnswersContext-Reducer] Unhandled action type: ${action.type}`)
    }
  }
}

/**
 * Context provider for providing AnswersContext
 * @returns
 */
function AnswersContextProvider({ children }: AnswersProviderProps): JSX.Element {
  const { bot, hasNLUKnowledgeDB, hasOldClassicNLU } = useBotContext() as {
    bot: BotInfos
    hasNLUKnowledgeDB: boolean
    hasOldClassicNLU: boolean
  } // bot exists here. We can parse.
  const { setNotification } = useStudioNotificationContext()
  const { setError } = useErrorContext()
  // const {
  //   loading: answerDataLoading,
  //   answerTemplateData,
  //   isInitialized: answerDataInitialized,
  // } = useDatamanagementContext()
  const [state, dispatch] = useReducer(answersReducer, {
    flaggedAnswers: null,
    flaggedAnswersOrig: null,
    primaryLang: bot.primaryLanguage,
    botId: bot.id,
    knowledgeType: null,
    error: null,
    modelStatus: null,
    loading: null,
  })

  const { loading, modelStatus } = state

  // simple wrapper around setloading dispatch
  function setLoading(loading: LoadingState): void {
    dispatch({ type: Types.SetLoading, payload: { loading } })
  }
  // simple wrapper around setloading dispatch
  function setModelStatus(modelStatus: ModelStatus): void {
    dispatch({ type: Types.SetModelStatus, payload: { modelStatus } })
  }

  /**
   * Checks training status and sets it in state.
   */
  async function getModelStatus(): Promise<void> {
    try {
      if (!bot.id) return
      const oldModelStatus = modelStatus
      const oldPublishingStatus = oldModelStatus?.publishingStatus

      const newModelStatus = await getNLUStatus(bot.id, 'specific')
      if (newModelStatus === null) {
        // dispatch({ type: Types.SetModelStatus, payload: { modelStatus: null } })
        return
      } else if (newModelStatus.trainingError && !isEqual(oldModelStatus, newModelStatus)) {
        // there was an error during the last training
        // find all answers that caused errors
        const answers: Answer[] = []
        // newModelStatus.trainingError.brokenIntents.forEach((intent) => {
        //   const answer = getAnswerByIntent(intent) // FIXME: this always returns null!!
        //   if (answer) answers.push(answer)
        // })

        const errorReason =
          newModelStatus.trainingError.reason === 'FewLabels'
            ? 'Zu wenig zugeordnete Fragen'
            : newModelStatus.trainingError.reason

        const answerTitles = answers.map((answer) => answer.title)

        let message = `Fehler beim letzten Training des Models: ${errorReason}.`
        if (answers.length > 0) message += ` Antworten mit Fehlern: ${answerTitles}`

        setNotification('error', message, 3000)
        setModelStatus(newModelStatus)
      } else {
        if (
          modelStatus &&
          newModelStatus.publishingStatus === 'Published' &&
          newModelStatus.trainingStatus === 'Trained' &&
          typeof oldPublishingStatus !== 'undefined' &&
          newModelStatus.publishingStatus !== oldPublishingStatus
          // (oldPublishingStatus === 'NeedsPublishing' ||
          //   oldTrainingStatus === 'InProgress' ||
          //   oldTrainingStatus === 'NeedsTraining')
        ) {
          setNotification('success', 'Modell erfolgreich trainiert und veröffentlicht.', 3000)
        }
        if (!isEqual(modelStatus, newModelStatus)) {
          setModelStatus(newModelStatus)
        }
      }
    } catch (err) {
      // if the call fails, do nothing
      // automatically retried by interval
    }
  }

  /**
   * Loads all answers from api and laods them in the context
   * Loads answerData
   * TODO: put this in context.
   */
  async function loadAnswersFromAPI(retry?: boolean): Promise<void> {
    setLoading('loading')
    try {
      if (!bot) return
      // const getAnswerResult = await loadAnswers(dispatch, bot.id, 'specific', 'answers', false, bot.primaryLanguage)
      const answerResponse = await getAnswers(bot.id, 'specific', false)
      if (answerResponse && answerResponse?.translatedAnswers) {
        // make answer class objects for further use
        for (const lang of Object.keys(answerResponse.translatedAnswers)) {
          for (const answerId of Object.keys(answerResponse.translatedAnswers[lang])) {
            const answer = buildAnswerObject(answerResponse.translatedAnswers[lang][answerId] as any)
            answerResponse.translatedAnswers[lang][answerId] = answer
          }
        }

        // ensure we have strings as answer texts. If answer text is only a number it is not a string after json parsing :)
        const answers = answerResponse.translatedAnswers
        for (const lang of Object.keys(answers)) {
          for (const answerId of Object.keys(answers[lang])) {
            answers[lang][answerId].answerTemplate = `${answers[lang][answerId].answerTemplate}`
            answers[lang][answerId].answer = `${answers[lang][answerId].answer}`
          }
        }
        dispatch({ type: Types.SetAnswers, payload: { answers, flag: 'original' } })
        dispatch({ type: Types.SetAnswersOrig, payload: { answersOrig: answers, flag: 'original' } })
      } else {
        throw new Error('getAnswer result is null')
      }
    } catch (err) {
      if (!retry) {
        // try again a second time
        console.info('Retry: Load Answers')
        loadAnswersFromAPI(true)
      } else {
        // set error
        setError(
          'Knowledge.specific.loadAnswersError',
          'Das Wissen des Assistenten konnte nicht geladen werden. Bitte versuchen Sie es erneut.',
          'Erneut versuchen',
          loadAnswersFromAPI,
          'Fehler beim Laden des Wissens',
        )
      }
    }
    setLoading(undefined)
  }

  // load answers from DB
  // should be only place where this happens
  useEffect(function () {
    if (!hasNLUKnowledgeDB) return
    loadAnswersFromAPI()
  }, [])

  /**
   * POPUPLATE CONTEXT AND START INTERVALS.
   * - sets modelstatus interval to poll model status
   * - initial load of answers to populate context
   */
  useEffect(function () {
    let modelStatusInterval
    if (hasOldClassicNLU) {
      getModelStatus()
      modelStatusInterval = setInterval(function () {
        getModelStatus()
      }, 20 * 1000)
    }

    return (): void => {
      if (hasOldClassicNLU && modelStatusInterval) {
        clearInterval(modelStatusInterval)
        // reset context state on unmount
        dispatch({ type: Types.ResetState, payload: {} })
      }
    }
  }, [])

  useEffect(
    function () {
      if (!state.primaryLang && bot) {
        dispatch({ type: Types.SetPrimaryLang, payload: { primaryLang: bot.primaryLanguage } })
      }
    },
    [bot],
  )

  useEffect(() => {
    // reset loading state after time sensitive states
    let timeout
    if (
      loading === 'savingSuccess' ||
      loading === 'creatingSuccess' ||
      loading === 'publishSuccess' ||
      loading === 'deletingSuccess' ||
      loading === 'savingError' ||
      loading === 'creatingError' ||
      loading === 'publishError' ||
      loading === 'deletingError'
    ) {
      timeout = setTimeout(() => {
        dispatch({ type: Types.SetLoading, payload: { loading: undefined } })
      }, 3000)
    }
    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [loading])

  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context   DevSkim: ignore DS137138
  // const value = useMemo(() => [state, dispatch], [state])
  const value = { state, dispatch, reloadAnswersFromAPI: loadAnswersFromAPI }
  return <AnswersContext.Provider value={value}>{children}</AnswersContext.Provider>
}

/**
 * Helper Function.
 * Finds newly added and removed utterances by comparing the answer in the state to the original answer.
 * NOTHING TO DO WITH THE CONTEXT.
 */
function findChangedUtterances(
  answer: Answer,
  originalAnswer: Answer,
): { newUtterances: SaveAnswersUtterances; deletedUtterances: SaveAnswersUtterances } {
  const newUtterances: string[] = []
  const deletedUtterances: string[] = []
  for (const utterance of answer.labels) {
    if (!originalAnswer.labels.includes(utterance)) newUtterances.push(utterance)
  }
  for (const utterance of originalAnswer.labels) {
    if (!answer.labels.includes(utterance)) deletedUtterances.push(utterance)
  }
  return {
    newUtterances: { [answer.answerId]: newUtterances },
    deletedUtterances: { [answer.answerId]: deletedUtterances },
  }
}

/**
 * Hook for accessing and manipulating the AnswersContext state
 * @returns
 *
 * To access this context:
 * import { useAnswers, loadAnswers } from '<path>/hooks/contexts/answers-context.tsx'
 *
 * // in a function
 * const {answers, setAnswers} = useAnswers()
 *
 */
function useAnswers(): {
  answersArrayPrimaryLang: Answer[] // list of answers in primary language
  flaggedAnswers: FlaggedAnswers | null
  flaggedAnswersOrig: FlaggedAnswers | null
  primaryLang: string | null //TODO: langauge type
  botId: string | null
  knowledgeType: KnowledgeType | null
  error: string | null //TODO: to be defined
  loading: LoadingState | null
  // model
  modelStatus: ModelStatus | null
  isModelTrainingButtonDisabled: boolean
  trainModel: () => void
  // answers
  setAnswers: (answers: Answers, flag: SyncFlag) => void // used for updating translations
  setKnowledgeType: (knowledgeType: KnowledgeType) => void
  setModelStatus: (modelStatus: {
    trainingStatus?: ModelStatus['trainingStatus']
    publishingStatus?: ModelStatus['publishingStatus']
    trainingError?: ModelStatus['trainingError']
  }) => void
  setError: (error: string | null) => void
  updateAnswer: (answer: Answer, flag: SyncFlag) => void
  updateAnswerAction: (answerId: string, action: AnswerAction, index: number | null) => void
  updateSingleAnswerTranslation: (lang: string, answer: Answer, flag: SyncFlag) => void
  updateSingleAnswerTranslationsBatch: (answers: { [lang: string]: Answer }, flag: SyncFlag) => void
  updateMultipleAnswersTranslationsBatch: (answers: Answers, flag: SyncFlag) => void
  getAnswer: (answerId: string) => Answer | null
  getAnswerAction: (answerId: string) => AnswerAction[]
  getAnswerTranslations: (answerId: string) => { [lang: string]: Answer }
  getAnswersArray: () => Answer[]
  getAnswerByIntent: (intent: string) => Answer | null
  getTriggerAnswersArray: () => Answer[]
  // getModelStatus: () => ModelStatus | null
  createAnswer: (answer: AnswerWithoutIntent | TriggerAnswerWithoutIntent) => Promise<void>
  saveAnswer: (answer: Answer) => Promise<void>
  saveChangedAnswers: () => Promise<void>
  deleteAnswers: (answerIds: string[], showSuccessNotification?: boolean) => Promise<void>
  deleteAnswer: (answerId: string, showSuccessNotification?: boolean) => Promise<void>
  resetState: () => void
  reloadAnswersFromAPI: () => Promise<void> // reload answers from API and completely resets local state
  discardChanges: () => void
  dispatch: Dispatch
  hasChanges: boolean
  hasAnswerChanged: (answer: Answer) => boolean
  // granular permissions
  canIEditAnyAnswer: boolean
  canIViewAnswer: (answerIdOrAnswer?: string | Answer) => boolean
  canIEditAnswer: (answerIdOrAnswer?: string | Answer) => boolean
  canICreateAnswer: (topic: string) => boolean
  canIDeleteAnswer: (answerIdOrAnswer?: string | Answer) => boolean
  canIEditTopicAndItsAnswers: (topic?: string) => boolean
  canICreateTopicAndItsAnswers: (topic?: string) => boolean
  canIDeleteTopicAndItsAnswers: (topic?: string) => boolean
  // complex operations - proxies for state multiple state manipulations and storage operations
  onChangedAnswers: (
    changedAnswers: Answer[],
    newUtterances?: SaveAnswersUtterances,
    deletedUtterances?: SaveAnswersUtterances,
    showNotification?: boolean,
  ) => void
} {
  const context = useContext(AnswersContext)
  const { bot, granularKnowledgePermissions } = useBotContext() as {
    bot: BotInfos
    granularKnowledgePermissions: AuthorizationEntry | null
  } // bot exists here. We can parse.
  const { lockState } = useLockingContext()
  const { setNotification } = useStudioNotificationContext()

  if (context === undefined) {
    throw new Error('[useAnswersContext] useAnswersContext must be used within a AnswersContextProvider')
  }

  // splitting it up, allows us to add additional api calls
  const { state, dispatch, reloadAnswersFromAPI } = context

  // ---- API ----
  // To not let anything work on the context state directly and to have an easy to use API

  // State that should be readable
  const { flaggedAnswers, flaggedAnswersOrig, knowledgeType, modelStatus, error, loading } = state
  // we get botId and primary language from botinfos.
  // set them into state if not yet happened for ease of use (also in provider)
  const { id: botId, primaryLanguage: primaryLang } = bot

  // ==============
  // GRANULAR PERMISSION CHECKS
  // have them here on top so that other functions can use them
  const canIEditAnyAnswer = (granularKnowledgePermissions?.update || []).length > 0

  const canIViewAnswer = useCallback(
    (answerOrAnswerId: string | Answer | undefined) => {
      if (!answerOrAnswerId) return false
      const answer = typeof answerOrAnswerId === 'string' ? getAnswer(answerOrAnswerId) : answerOrAnswerId
      if (!answer) return false
      if (!granularKnowledgePermissions) return true
      if (granularKnowledgePermissions.read.includes('*')) return true
      if (answer.topic && granularKnowledgePermissions.read.some((topic) => answer.topic?.startsWith(topic)))
        return true
      return false
    },
    [flaggedAnswers, primaryLang, granularKnowledgePermissions],
  )

  const canIEditAnswer = useCallback(
    (answerOrAnswerId: string | Answer | undefined) => {
      if (!answerOrAnswerId) return false
      const answer = typeof answerOrAnswerId === 'string' ? getAnswer(answerOrAnswerId) : answerOrAnswerId
      if (!answer) return false
      if (!granularKnowledgePermissions) return true
      if (granularKnowledgePermissions.update.includes('*')) return true
      if (answer.topic && granularKnowledgePermissions.update.some((topic) => answer.topic?.startsWith(topic)))
        return true
      return false
    },
    [flaggedAnswers, primaryLang, granularKnowledgePermissions],
  )

  const canIDeleteAnswer = useCallback(
    (answerOrAnswerId: string | Answer | undefined) => {
      if (!answerOrAnswerId) return false
      const answer = typeof answerOrAnswerId === 'string' ? getAnswer(answerOrAnswerId) : answerOrAnswerId
      if (!answer || answer.intent === 'None') return false
      if (!granularKnowledgePermissions) return true
      if (granularKnowledgePermissions.delete.includes('*')) return true
      if (answer.topic && granularKnowledgePermissions.delete.some((topic) => answer.topic?.startsWith(topic)))
        return true
      return false
    },
    [flaggedAnswers, primaryLang, granularKnowledgePermissions],
  )

  const canICreateAnswer = useCallback(
    (topic: string | undefined) => {
      if (!topic) return false
      if (!granularKnowledgePermissions) return true
      if (granularKnowledgePermissions.create.includes('*')) return true
      if (topic && granularKnowledgePermissions.create.some((topic) => topic?.startsWith(topic))) return true
      return false
    },
    [flaggedAnswers, primaryLang, granularKnowledgePermissions],
  )

  const canIEditTopicAndItsAnswers = useCallback(
    (topic: string | undefined) => {
      if (!topic) return false
      if (!granularKnowledgePermissions) return true
      if (granularKnowledgePermissions.update.includes('*')) return true
      if (granularKnowledgePermissions.update.some((allowedTopic) => topic?.startsWith(allowedTopic))) return true
      return false
    },
    [flaggedAnswers, primaryLang, granularKnowledgePermissions],
  )

  const canICreateTopicAndItsAnswers = useCallback(
    (topic: string | undefined) => {
      if (!topic) return false
      if (!granularKnowledgePermissions) return true
      if (granularKnowledgePermissions.create.includes('*')) return true
      if (granularKnowledgePermissions.create.some((allowedTopic) => topic?.startsWith(allowedTopic))) return true
      return false
    },
    [flaggedAnswers, primaryLang, granularKnowledgePermissions],
  )

  const canIDeleteTopicAndItsAnswers = useCallback(
    (topic: string | undefined) => {
      if (!topic) return false
      if (!granularKnowledgePermissions) return true
      if (granularKnowledgePermissions.delete.includes('*')) return true
      if (granularKnowledgePermissions.delete.some((allowedTopic) => topic?.startsWith(allowedTopic))) return true
      return false
    },
    [flaggedAnswers, primaryLang, granularKnowledgePermissions],
  )

  // Functions
  // SETTERS (CONTEXT)
  const setLoading = useCallback(
    (loading: LoadingState) => dispatch({ type: Types.SetLoading, payload: { loading } }),
    [],
  )

  const setAnswers = useCallback(
    (newAnswers, flag) => dispatch({ type: Types.SetAnswers, payload: { answers: newAnswers, flag } }),
    [],
  )
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const setAnswersOrig = useCallback(
    (newAnswers, flag) => dispatch({ type: Types.SetAnswersOrig, payload: { answersOrig: newAnswers, flag } }),
    [],
  )
  const setKnowledgeType = useCallback(
    (newKnowledgeType) => dispatch({ type: Types.SetKnowledgeType, payload: { knowledgeType: newKnowledgeType } }),
    [],
  )
  const setModelStatus = useCallback(
    (newModelStatus: {
      trainingStatus?: ModelStatus['trainingStatus']
      publishingStatus?: ModelStatus['publishingStatus']
      trainingError?: ModelStatus['trainingError']
    }) => dispatch({ type: Types.SetModelStatus, payload: { modelStatus: newModelStatus } }),
    [],
  )

  const isModelTrainingButtonDisabled = useMemo(() => {
    return (
      loading === 'loading' ||
      lockState !== 'canEdit' ||
      modelStatus?.trainingStatus === 'InProgress' ||
      modelStatus?.publishingStatus === 'InProgress' ||
      (modelStatus?.trainingStatus === 'Trained' && modelStatus?.publishingStatus === 'Published')
    )
  }, [loading, lockState, modelStatus])

  const trainModel = useCallback(async () => {
    if (isModelTrainingButtonDisabled) {
      // do not start training
      return
    }

    setModelStatus({
      publishingStatus: 'NeedsPublishing',
      trainingStatus: 'InProgress',
    })
    const trainResult = await trainAndPublishNluModelApi(botId, 'specific')
    if (trainResult === null) {
      // something went wrong during training initialization. This does not consider the actual model training process!
      setNotification('error', 'Fehler beim Trainieren des Modells.', 3000)
    }
  }, [isModelTrainingButtonDisabled, setModelStatus])

  const setError = useCallback((newError) => dispatch({ type: Types.SetError, payload: { error: newError } }), [])

  // Combined functionality
  const updateAnswer = useCallback(
    (answer, flag) => dispatch({ type: Types.UpdateAnswer, payload: { answer, flag } }),
    [],
  )

  const updateAnswerAction = useCallback((answerId, action, index) => {
    if (flaggedAnswers && flaggedAnswers.answers && primaryLang && flaggedAnswers.answers[primaryLang]) {
      const answer = getAnswer(answerId)
      const translations = getAnswerTranslations(answerId)
      // if index is a number, the action already existed
      if (typeof index === 'number' && answer && answer.actions) {
        // update in answer
        answer.actions[index] = action
        updateAnswer(answer, 'modified')
        // update in translations -> change answerId at the index
        // loop through all languages
        for (const lang of Object.keys(translations)) {
          translations[lang].actions[index].answerId = action.answerId
          // NOTE: we do not reset the action translation here - but may need to consider it
        }
        updateSingleAnswerTranslationsBatch(translations, 'modified')
      } else if (answer) {
        // create action in answer and in translations
        // check if actions is an array
        if (answer.actions && Array.isArray(answer.actions)) {
          // add action to array
          answer.actions.push(action)
          updateAnswer(answer, 'modified')
          // add action to translation array
          // loop through all languages
          for (const lang of Object.keys(translations)) {
            translations[lang].actions.push({ action: null, answerId: action.answerId })
          }
          updateSingleAnswerTranslationsBatch(translations, 'modified')
        } else {
          // actions is not an array
          // create actions array with the new action
          answer.actions = [action]
          updateAnswer(answer, 'modified')
          // add actions translation and add action to it
          // loop through all languages
          for (const lang of Object.keys(translations)) {
            translations[lang].actions = [{ action: null, answerId: action.answerId }]
          }
          updateSingleAnswerTranslationsBatch(translations, 'modified')
        }
      }
      // dispatch({ type: Types.UpdateAnswer, payload: { answer, flag } })
    }
  }, [])

  /**
   * Updates single translation of single answer
   */
  const updateSingleAnswerTranslation = useCallback(
    (lang, answer, flag) => dispatch({ type: Types.UpdateSingleAnswerTranslatino, payload: { lang, answer, flag } }),
    [],
  )

  /**
   * Updates multiple translations of a single answer at the same time
   */
  const updateSingleAnswerTranslationsBatch = useCallback(
    (answers, flag) => dispatch({ type: Types.UpdateSingleAnswerTranslationsBatch, payload: { answers, flag } }),
    [],
  )

  /**
   * Updates multiple translations of multiple answers at same time.
   */
  const updateMultipleAnswersTranslationsBatch = useCallback((answers, flag) => {
    dispatch({ type: Types.UpdateAnswersBatch, payload: { answers, flag } })
  }, [])

  // GETTERS
  const getAnswer = useCallback(
    (answerId: string) => {
      if (flaggedAnswers && flaggedAnswers.answers && primaryLang && flaggedAnswers.answers[primaryLang]) {
        // ensure we return a new answer object to prevent accidental reference problems
        // this enforces explicit setting of the answer in the context
        const answer = (flaggedAnswers.answers[primaryLang][answerId] as Answer)?.clone()
        return answer ?? null
      }
      return null
    },
    [flaggedAnswers, primaryLang],
  )

  const getAnswerAction = useCallback(
    (answerId: string) => {
      if (
        flaggedAnswers &&
        flaggedAnswers.answers &&
        primaryLang &&
        flaggedAnswers.answers[primaryLang] &&
        flaggedAnswers.answers[primaryLang][answerId]
      ) {
        // ensure we return a new answer object to prevent accidental reference problems
        // this enforces explicit setting of the answer in the context
        return cloneDeep(flaggedAnswers.answers[primaryLang][answerId].actions ?? [])
      }
      return []
    },
    [flaggedAnswers, primaryLang],
  )

  const getAnswerTranslations = useCallback(
    (answerId: string) => {
      const answerTranslations: { [lang: string]: Answer } = {}
      if (flaggedAnswers && flaggedAnswers.answers && primaryLang) {
        // omit primary language since wie are only interested in the other langauges
        // const allAnswerTranslations = omit(flaggedAnswers.answers, primaryLang)
        const allAnswerTranslations = flaggedAnswers.answers
        // go through all languages and get the wanted answer
        for (const lang of Object.keys(allAnswerTranslations)) {
          if (lang === primaryLang) continue
          // ensure we return a new answer object to prevent accidental reference problems
          // this enforces explicit setting of the answer in the context
          if (allAnswerTranslations[lang][answerId]) {
            answerTranslations[lang] = cloneDeep(allAnswerTranslations[lang][answerId]) as Answer // FIXME: should not type cast here
          } else {
            // answer has no translation for language yet: clone primary language answer and change language
            // this should not be the case. Fallback
            answerTranslations[lang] = cloneDeep(flaggedAnswers.answers[primaryLang][answerId]) as Answer // FIXME: should not type cast here
            answerTranslations[lang].answerTemplate = ''
            answerTranslations[lang].language = lang
          }
        }
      }
      return answerTranslations
    },
    [flaggedAnswers, primaryLang],
  )

  const getAnswerByIntent = useCallback(
    (intent: string) => {
      if (flaggedAnswers && flaggedAnswers.answers && primaryLang) {
        // just primaryLang answers
        for (const answerId of Object.keys(flaggedAnswers.answers[primaryLang])) {
          if ((flaggedAnswers.answers[primaryLang][answerId] as Answer).intent === intent) {
            // ensure we return a new answer object to prevent accidental reference problems
            // this enforces explicit setting of the answer in the context
            return (flaggedAnswers.answers[primaryLang][answerId] as Answer).clone()
          }
        }
        // Optionally we could always give back the answer for the None intent
        return null
      }
      return null
    },
    [flaggedAnswers, primaryLang],
  )

  // TODO: have same structure as everywhere based on Answers
  const getTriggerAnswersArray = useCallback(() => {
    if (flaggedAnswers && flaggedAnswers.answers && primaryLang) {
      const _triggerAnswers: Answer[] = []
      // just primaryLang answers
      for (const answerId of Object.keys(flaggedAnswers.answers[primaryLang])) {
        if ((flaggedAnswers.answers[primaryLang][answerId] as Answer).answerType === 'trigger') {
          _triggerAnswers.push(flaggedAnswers.answers[primaryLang][answerId] as Answer)
        }
      }
      // ensure we return a new answer object to prevent accidental reference problems
      // this enforces explicit setting of the answer in the context
      // Optionally we could always give back the answer for the None intent
      return _triggerAnswers.map((answer) => answer.clone())
    }
    return []
  }, [flaggedAnswers, primaryLang])

  /**
   * Creates answer.
   * - creates answer via API
   * - sets new answer in context
   * @param answer
   */
  const createAnswer = useCallback(
    async (answer: AnswerWithoutIntent | TriggerAnswerWithoutIntent): Promise<void> => {
      if (lockState !== 'canEdit' || !canICreateAnswer(answer.topic ?? undefined)) return
      try {
        setLoading('creating')
        // make api call
        const answerResponse = await createAnswerApi(botId, answer, 'specific')
        if (answerResponse && answerResponse.createdAnswer) {
          const _answers: Answers = {}
          const translatedAnswers = answerResponse.createdAnswer.translatedAnswers
          for (const lang of Object.keys(translatedAnswers)) {
            // create an Answers Object for new answer
            _answers[lang] = {}
            // set answer class object, not just JSON object to allow us to use class methods
            _answers[lang][answerResponse.createdAnswer.answerId] = buildAnswerObject(translatedAnswers[lang])
          }
          // add new answer objects

          dispatch({ type: Types.UpdateAnswersBatch, payload: { answers: cloneDeep(_answers), flag: 'original' } })
          // also update orig reference
          dispatch({
            type: Types.UpdateAnswersBatch,
            payload: { answers: cloneDeep(_answers), flag: 'original', orig: true },
          })
        } else if (answerResponse === null) throw new Error('createAnswer result is null')

        setLoading('creatingSuccess')
        // const modelStatus = answerResponse.modelStatus
        setModelStatus({
          ...modelStatus,
          trainingStatus: answerResponse.modelStatus,
          publishingStatus: modelStatus?.publishingStatus ?? 'NeedsPublishing',
        })
        setNotification('success', 'Antwort erfolgreich erstellt.', 3000)
      } catch (err) {
        // set error
        console.error('Antwort konnte nicht erstellt werden. ', err)
        setNotification('error', 'Fehler beim Erstellen der Antwort. Antwort konnten nicht erstellt werden.', 5000)
        setLoading('creatingError')
      }
    },
    [lockState, modelStatus, canICreateAnswer],
  )

  /**
   * Saves answer in API and context.
   * @param answer
   */
  const saveAnswer = useCallback(
    async (answer: Answer): Promise<void> => {
      try {
        if (
          lockState !== 'canEdit' ||
          !answer ||
          !flaggedAnswersOrig ||
          !flaggedAnswers ||
          !canIEditAnswer(answer.answerId)
        )
          return

        if (isEqual(flaggedAnswersOrig[answer.answerId], answer)) {
          // nothing has changed
          return
        }

        setLoading('saving')
        const { newUtterances, deletedUtterances } = findChangedUtterances(
          answer,
          flaggedAnswersOrig.answers[primaryLang][answer.answerId] as Answer,
        )

        // get translations to merge for saving
        const _answers: Answers = {}
        _answers[primaryLang] = {}
        _answers[primaryLang][answer.answerId] = answer

        const translations = getAnswerTranslations(answer.answerId)
        for (const lang of Object.keys(translations)) {
          _answers[lang] = {}
          _answers[lang][answer.answerId] = translations[lang]
        }

        const answerResponse = await saveAnswersApi(botId, 'specific', _answers, newUtterances, deletedUtterances)
        if (answerResponse) {
          // we need to make sure that the labels of the saved answer are also present in the returned answer
          // the returned answer does not have the labels attached so we re-add them
          for (const lang of Object.keys(answerResponse)) {
            if (flaggedAnswers.answers[lang]) {
              const answerResponseIds = Object.keys(answerResponse[lang])
              for (const answerId of answerResponseIds) {
                if (flaggedAnswers.answers[lang][answerId] && flaggedAnswers.answers[lang][answerId].labels) {
                  answerResponse[lang][answerId].labels = flaggedAnswers.answers[lang][answerId].labels
                }
              }
            }
          }
          const newAnswers = addOrReplaceAnswersBatch(answerResponse, flaggedAnswers.answers)

          dispatch({ type: Types.UpdateAnswersBatch, payload: { answers: cloneDeep(newAnswers), flag: 'original' } })
          dispatch({
            type: Types.UpdateAnswersBatch,
            payload: { answers: cloneDeep(newAnswers), flag: 'original', orig: true },
          })
          setLoading('savingSuccess')
          setNotification('success', 'Antwort gespeichert.', 3000)
          let hasAnyUtteranceChanged = false
          if (newUtterances) {
            for (const utts of Object.values(newUtterances)) {
              if (utts.length > 0) {
                hasAnyUtteranceChanged = true
                break
              }
            }
          }
          if (deletedUtterances && !hasAnyUtteranceChanged) {
            // check if utterance(s) were deleted, only runs if we have not already found a changed utterance
            for (const utts of Object.values(deletedUtterances)) {
              if (utts.length > 0) {
                hasAnyUtteranceChanged = true
                break
              }
            }
          }

          if (hasAnyUtteranceChanged) {
            // only set model status to needs training if utterances of an answer have changed
            setModelStatus({
              ...modelStatus,
              trainingStatus: 'NeedsTraining',
              publishingStatus: modelStatus?.publishingStatus ?? 'NeedsPublishing',
            })
          }
        }
      } catch (err) {
        console.error('Antwort konnten nicht gespeichert werden. ', err)
        setNotification('error', `Fehler beim Speichern der Antwort.`)
        setLoading('savingError')
      }
    },
    [lockState, flaggedAnswers, flaggedAnswersOrig, modelStatus, canIEditAnswer],
  )

  // delete - means prev saved therefore based on orig
  const deleteAnswerFromContext = useCallback(
    (answerId: string, flag: SyncFlag) => {
      if (flaggedAnswers && flaggedAnswers.answers) {
        const _answers = cloneDeep(flaggedAnswers.answers)
        if (_answers) {
          // go through all languages and omit the to be removed answer
          for (const lang of Object.keys(flaggedAnswers.answers)) {
            _answers[lang] = omit(flaggedAnswers.answers[lang], answerId)
          }
        }
        dispatch({ type: Types.SetAnswers, payload: { answers: _answers, flag } })
      }
      if (flaggedAnswersOrig && flaggedAnswersOrig.answers) {
        const _answers = cloneDeep(flaggedAnswersOrig.answers)
        if (_answers) {
          // go through all languages and omit the to be removed answer
          for (const lang of Object.keys(flaggedAnswersOrig.answers)) {
            _answers[lang] = omit(flaggedAnswersOrig.answers[lang], answerId)
          }
        }
        dispatch({ type: Types.SetAnswersOrig, payload: { answersOrig: _answers, flag } })
      }
    },
    [flaggedAnswers, flaggedAnswersOrig],
  )

  /**
   * Deletes answers.
   * Removes deleted answers from knowledge db.
   * Removes answers from context.
   * Retries once, then shows error notification.
   * @param answerIdsToDelete
   * @param showSuccessNotification
   * @param retry
   */
  const deleteAnswers = useCallback(
    async (answerIdsToDelete: string[], showSuccessNotification = true): Promise<void> => {
      try {
        if (answerIdsToDelete && lockState === 'canEdit') {
          // setHasSomethingChanged(true)
          // delete answer via API
          if (answerIdsToDelete.length > 0) {
            setLoading('deleting')
            // can be 0 if an empty topic is deleted
            const result = await deleteAnswersApi(botId, 'specific', answerIdsToDelete)
            if (result === null) throw new Error('Error deleting answer')

            // delete answer in context after API success
            answerIdsToDelete.forEach((answerId) => {
              deleteAnswerFromContext(answerId, 'original')
            })

            // activate model training button
            setModelStatus({
              publishingStatus: modelStatus?.publishingStatus || 'Published',
              trainingStatus: 'NeedsTraining',
            })

            if (showSuccessNotification) {
              setNotification('success', 'Antwort erfolgreich gelöscht.')
            }
            setLoading('deletingSuccess')
          }
        }
      } catch (err) {
        console.error('Antwort(en) konnte nicht gelöscht werden. ', err)
        setNotification('error', 'Fehler beim Löschen der Antwort. Antwort konnte nicht gelöscht werden.')
        setLoading('deletingError')
      }
    },
    [flaggedAnswers, flaggedAnswersOrig, lockState],
  )

  /**
   * Deletes an
   * @param answerId
   * @param showSuccessNotification
   * @returns
   */
  const deleteAnswer = async (answerId: string, showSuccessNotification = true): Promise<void> => {
    return deleteAnswers([answerId], showSuccessNotification)
  }

  // Obligatory local reset
  const resetState = useCallback(() => {
    dispatch({ type: Types.ResetState, payload: {} })
  }, [])

  const _hasAnswerChanged = useCallback((oldAnswer: Answer, newAnswer: Answer) => {
    const old = omit(cloneDeep(oldAnswer), ['open', 'score', 'timestamp', 'hash'])
    const n = omit(cloneDeep(newAnswer), ['open', 'score', 'timestamp', 'hash'])

    // answerTemplate can have some strange escaping going on (possibly a relict from a markdown editor bug).
    // we replace it for comparison
    old.answerTemplate = `${old.answerTemplate}`?.replace('\\', '')
    n.answerTemplate = `${n.answerTemplate}`?.replace('\\', '')

    return !isEqual(old, n)
  }, [])

  /**
   * false if answer is equal, true if changed
   */
  const hasAnswerChanged = useCallback(
    (answer: Answer) => {
      if (
        answer &&
        flaggedAnswers &&
        flaggedAnswers.answers &&
        flaggedAnswersOrig &&
        flaggedAnswersOrig.answers &&
        primaryLang &&
        answer.answerId
      ) {
        for (const lang of Object.keys(flaggedAnswersOrig.answers)) {
          const newAnswer = lang === primaryLang ? answer : (flaggedAnswers.answers[lang][answer.answerId] as Answer)
          const oldAnswer = flaggedAnswersOrig.answers[lang][answer.answerId]

          const changed = _hasAnswerChanged(oldAnswer as Answer, newAnswer)
          if (changed) {
            return true
          }
        }

        return false
      }
      return true
    },
    [flaggedAnswersOrig, flaggedAnswers],
  )

  // Callback functions for more complex operations - proxies for some state operations
  /**
   * Callback function for when answers in the MindMap have changed. E.g. because they have been moved to another topic.
   * @param changedAnswers
   * @param retry
   */
  async function onChangedAnswers(
    changedAnswers: Answer[],
    newUtterances?: SaveAnswersUtterances,
    deletedUtterances?: SaveAnswersUtterances,
    showNotification = true,
    retry = false,
  ): Promise<void> {
    try {
      if (lockState !== 'canEdit' || !botId) return
      const answersArray = getAnswersArray()

      if (lockState === 'canEdit' && flaggedAnswers && answersArray && changedAnswers && changedAnswers.length > 0) {
        // if (newUtterances || deletedUtterances) setHasSomethingChanged(true)
        setLoading('saving')

        // We can assume that only changes in the primary languages are made
        const answersToSave = answerArrayToAnswers(changedAnswers, bot?.primaryLanguage ?? 'de') // FIXME: not ideal to default back to 'de' - bot should exist and be enforded ~ Jakob 01.2022
        // make api call
        const result = await saveAnswers(
          dispatch,
          botId,
          'specific',
          flaggedAnswers.answers,
          answersToSave,
          bot?.primaryLanguage ?? 'de',
          'array',
          newUtterances,
          deletedUtterances,
        ) // FIXME: not ideal to default back to 'de' - bot should exist and be enforded ~ Jakob 01.2022

        if (typeof result === 'undefined' || result.response === null) throw new Error('Saving answer(s) failed')

        if (showNotification) {
          setNotification('success', 'Antwort erfolgreich gespeichert.', 3000)
        }
        setLoading('savingSuccess')
      }
    } catch (err) {
      if (!retry) {
        // try again silently
        await onChangedAnswers(changedAnswers, newUtterances, deletedUtterances, showNotification, true)
      } else {
        // set error after two tries
        console.error('Antwort(en) konnten nicht gespeichert werden. ', err)
        setNotification(
          'error',
          `Fehler beim Speichern der ${changedAnswers.length === 1 ? 'Antwort' : 'Antworten'}. ${
            changedAnswers.length === 1 ? 'Antwort konnte' : 'Antworten konnten'
          } nicht gespeichert werden.`,
        )
        setLoading('savingError')
      }
    }
  }

  /**
   * Saves all answer objects that differ from the orig answers in the context.
   * Iterates over all languages and answers to find them.
   */
  const saveChangedAnswers = useCallback(async (): Promise<void> => {
    if (!bot || !flaggedAnswers || !flaggedAnswersOrig) return
    setLoading('saving')
    const answers = flaggedAnswers.answers
    const origAnswers = flaggedAnswersOrig.answers

    const changedAnswers: Answers = {}
    for (const lang of Object.keys(answers)) {
      for (const answer of Object.values(answers[lang])) {
        if (!isEqual(answer, origAnswers[lang][answer.answerId])) {
          // has changed
          if (!changedAnswers[lang]) changedAnswers[lang] = {}
          changedAnswers[lang][answer.answerId] = answer
        }
      }
    }

    if (!isEmpty(changedAnswers)) {
      try {
        // make api call
        const result = await saveAnswers(
          dispatch,
          botId,
          'specific',
          flaggedAnswers.answers,
          changedAnswers,
          bot.primaryLanguage,
          'answers',
        )

        if (typeof result === 'undefined' || result.response === null) throw new Error('Saving answer(s) failed')

        // set result in context
        const newAnswers = addOrReplaceAnswersBatch(result.response as Answers, flaggedAnswersOrig.answers)
        dispatch({ type: Types.UpdateAnswersBatch, payload: { answers: cloneDeep(newAnswers), flag: 'original' } })
        // also update orig reference
        dispatch({
          type: Types.UpdateAnswersBatch,
          payload: { answers: cloneDeep(newAnswers), flag: 'original', orig: true },
        })
        setLoading('savingSuccess')
      } catch (err) {
        // set error after two tries
        console.error('Antwort(en) konnten nicht gespeichert werden. ', err)
        setNotification('error', `Fehler beim Speichern der Antworten Antworten konnten nicht gespeichert werden.`)
        setLoading('savingError')
      }
    }
  }, [flaggedAnswers, flaggedAnswersOrig, bot])

  /**
   * Resets local state by overwriting modifiedAnswers with origAnswers.
   */
  const discardChanges = useCallback(() => {
    if (flaggedAnswersOrig) {
      dispatch({ type: Types.SetAnswers, payload: { answers: flaggedAnswersOrig.answers, flag: 'modified' } })
    }
  }, [flaggedAnswers, flaggedAnswersOrig])

  // helper and conveninece
  const getAnswersArray = useCallback(() => {
    const answerArray: Answer[] = []
    if (flaggedAnswers && flaggedAnswers.answers && primaryLang && flaggedAnswers.answers[primaryLang]) {
      // go through all languages and get the wanted answer
      for (const answerId of Object.keys(flaggedAnswers.answers[primaryLang])) {
        answerArray.push(flaggedAnswers.answers[primaryLang][answerId] as Answer)
      }
    }
    // ensure we return a new answer object to prevent accidental reference problems
    // this enforces explicit setting of the answer in the context
    return answerArray.map((answer) => answer.clone()).filter((answer) => canIViewAnswer(answer))
  }, [flaggedAnswers, primaryLang])

  // list of all answers in primary language
  // must be after we initialize permission checks
  const answersArrayPrimaryLang = useMemo(() => {
    return getAnswersArray()
  }, [flaggedAnswers, getAnswersArray])

  /**
   * True if modified answers and orig answers are not identical
   */
  const hasChanges = useMemo(() => {
    return !isEqual(flaggedAnswers?.answers, flaggedAnswersOrig?.answers)
  }, [flaggedAnswers, flaggedAnswersOrig])

  useEffect(
    function () {
      if (!state.primaryLang) dispatch({ type: Types.SetPrimaryLang, payload: { primaryLang: bot.primaryLanguage } })
    },
    [bot],
  )

  // Controll what is returned
  return {
    answersArrayPrimaryLang,
    flaggedAnswers,
    flaggedAnswersOrig,
    primaryLang,
    botId,
    knowledgeType,
    modelStatus,
    error,
    loading,
    isModelTrainingButtonDisabled,
    setAnswers,
    setKnowledgeType,
    trainModel,
    setModelStatus,
    setError,
    updateAnswer,
    updateAnswerAction,
    updateSingleAnswerTranslation,
    updateSingleAnswerTranslationsBatch,
    updateMultipleAnswersTranslationsBatch,
    getAnswer,
    getAnswerAction,
    getAnswerTranslations,
    getAnswersArray,
    getAnswerByIntent,
    getTriggerAnswersArray,
    createAnswer,
    saveAnswer,
    saveChangedAnswers,
    deleteAnswer,
    deleteAnswers,
    resetState,
    reloadAnswersFromAPI,
    discardChanges,
    dispatch,
    hasChanges,
    hasAnswerChanged,
    canIEditAnyAnswer,
    canICreateAnswer,
    canIDeleteAnswer,
    canIEditAnswer,
    canIEditTopicAndItsAnswers,
    canIDeleteTopicAndItsAnswers,
    canICreateTopicAndItsAnswers,
    canIViewAnswer,
    onChangedAnswers,
  }
}

/**
 * Loads all answers and sets them in the context
 * @param dispatch answersDispatch for async calls
 * @param botId The botId the answers should be loaded for
 * @param knowledgeType The knowledgeType of the wanted answers
 * @param getModelStatus Should the modelStatus be updated?
 */
async function saveAnswers(
  dispatch: Dispatch,
  botId: string,
  knowledgeType: KnowledgeType,
  originalAnswers: Answers,
  updatedAnswers: Answers,
  primaryLang: string,
  returnType: 'array' | 'answers',
  newUtterances?: SaveAnswersUtterances,
  deletedUtterances?: SaveAnswersUtterances,
  answerTemplateData?: AnswerTemplateData,
): Promise<{ response: Answers | Answer[] | null }> {
  try {
    dispatch({ type: Types.SetLoading, payload: { loading: 'saving' } })

    // check if all answers have the same amount of actions for the translations
    const _syncedUpdatedAnswers = { ...updatedAnswers }

    const answerResponse = await saveAnswersApi(
      botId,
      knowledgeType,
      _syncedUpdatedAnswers,
      newUtterances,
      deletedUtterances,
      answerTemplateData,
    )
    if (answerResponse) {
      // we need to make sure that the labels of the saved answer are also present in the returned answer!!
      for (const lang of Object.keys(answerResponse)) {
        if (originalAnswers[lang]) {
          const answerResponseIds = Object.keys(answerResponse[lang])
          for (const answerId of answerResponseIds) {
            if (originalAnswers[lang][answerId] && originalAnswers[lang][answerId].labels) {
              answerResponse[lang][answerId].labels = originalAnswers[lang][answerId].labels
            }
          }
        }
      }

      const newAnswers = addOrReplaceAnswersBatch(answerResponse, originalAnswers)

      // clone deep to prevent accidental cross references between answers and origAnswers
      dispatch({ type: Types.UpdateAnswersBatch, payload: { answers: cloneDeep(newAnswers), flag: 'original' } })
      // also update orig reference
      dispatch({
        type: Types.UpdateAnswersBatch,
        payload: { answers: cloneDeep(newAnswers), flag: 'original', orig: true },
      })
      dispatch({ type: Types.SetLoading, payload: { loading: 'savingSuccess' } })
      // find if any answer has changed utterance(s)
      let hasAnyUtteranceChanged = false
      if (newUtterances) {
        for (const utts of Object.values(newUtterances)) {
          if (utts.length > 0) {
            hasAnyUtteranceChanged = true
            break
          }
        }
      }
      if (deletedUtterances && !hasAnyUtteranceChanged) {
        // check if utterance(s) were deleted, only runs if we have not already found a changed utterance
        for (const utts of Object.values(deletedUtterances)) {
          if (utts.length > 0) {
            hasAnyUtteranceChanged = true
            break
          }
        }
      }

      if (hasAnyUtteranceChanged) {
        // only set model status to needs training if utterances of an answer have changed
        dispatch({
          type: Types.SetModelStatus,
          payload: {
            modelStatus: {
              trainingStatus: 'NeedsTraining',
            },
          },
        })
      }

      if (returnType) {
        if (returnType === 'array' && primaryLang) {
          return { response: answersToAnswerArray(newAnswers, primaryLang) }
        }
      }
      return { response: newAnswers }
    } else {
      console.error('[answers-context][saveAnswers] response is not expected')
      return { response: null }
    }
  } catch (err) {
    console.error('[answers-context][saveAnswers] ', err)
    dispatch({ type: Types.SetLoading, payload: { loading: 'savingError' } })
    return { response: null }
  }
}

// === HELPERS ===
/**
 * Takes an updated answer (or translation) and sets the changes in the answers object
 *
 * @param answer Answer Object in primary language that has the updated
 * @param answers All Answers
 * @param lang language to merge into the corrent answer object
 * @param isAnswerClass boolean that defines if the given answer is an Answer or AnswerTranslation
 * @static
 */
function addOrReplaceAnswer(answer: Answer | AnswerTranslation, answers: Answers, lang: string): Answers {
  const _answers = cloneDeep(answers)
  const _answerId = answer.answerId
  if (_answers[lang] && _answerId) {
    _answers[lang][_answerId] = buildAnswerObject(answer as Answer)
  }
  return _answers
}

/**
 * Takes a batch of updated answers (and translations) and sets the changes in the answers object
 * Also build the correct objects / classes
 * @param answer Answer Object in primary language that has the updated
 * @param answers All Answers
 * @param primaryLang Primary language to merge into the corrent answer object
 * @static
 */
function addOrReplaceAnswersBatch(answerUpdates: Answers, answers: Answers): Answers {
  const _answers = cloneDeep(answers)

  // run through languages and check each for answers that need to be set
  for (const lang of Object.keys(answerUpdates)) {
    // init language in target object if it does not exists
    if (typeof _answers[lang] === 'undefined') {
      _answers[lang] = {}
    }
    for (const answerId of Object.keys(answerUpdates[lang])) {
      _answers[lang][answerId] = buildAnswerObject(answerUpdates[lang][answerId] as Answer)
    }
  }
  return _answers
}

/**
 * Takes an array of answer objects and a language.
 * Returns all answer objects in the Answers structure for the given language
 * @param answers Answer Array like the mind map uses
 * @param lang Language all given answers should be in
 * @returns
 */
function answerArrayToAnswers(answers: Answer[], lang: string): Answers {
  const _answers: Answers = {}
  _answers[lang] = {}
  answers.forEach((answer) => {
    _answers[lang][answer.answerId] = answer
  })
  return _answers
}

/**
 * Takes the Answers object and a language.
 * Returns all answers for the given language in an array
 * @param answers Answers object
 * @param lang Language all given answers should be in
 * @returns
 */
function answersToAnswerArray(answers: Answers, lang: string): Answer[] {
  const answerArray: Answer[] = []
  if (answers && lang && answers[lang]) {
    // go through all languages and get the wanted answer
    for (const answerId of Object.keys(answers[lang])) {
      answerArray.push(answers[lang][answerId] as Answer)
    }
  }
  return answerArray
}

export { AnswersContextProvider, useAnswers, saveAnswers, answerArrayToAnswers }
