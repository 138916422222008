import { useState, useLayoutEffect, MutableRefObject } from 'react'
import { DebouncedFunc, throttle } from 'lodash'

/**
 * Returns the width of the referenced element even if it changes by window resize
 * @param ref React Reference created with useRef
 */

const useRefOffsetWidth = (ref: MutableRefObject<HTMLDivElement | null>): number => {
  const [width, setWidth] = useState(0)
  useLayoutEffect(() => {
    function updateWidth(): void {
      setWidth(ref !== null && ref.current !== null ? ref.current.offsetWidth : 0)
    }

    const throttledUpdateWidth = (): DebouncedFunc<() => void> => throttle(updateWidth, 500)

    window.addEventListener('resize', throttledUpdateWidth)
    updateWidth()
    return (): void => window.removeEventListener('resize', throttledUpdateWidth)
  }, [])
  return width
}

export default useRefOffsetWidth
