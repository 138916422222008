import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { useEffect, useState } from 'react'
import { getDialogNameFromAnswerText } from '../../../../../utils/stringUtils'
import { Answer, buildAnswerObject } from '../../../../../classes/Knowledge'
import { Textfield } from '../../../../../components/TextInput/Textfield'
import EditAnswerUtterances from './Components/EditAnswerUtterances'
import { useAnswers } from 'hooks/contexts/answers-context'
import { useLockingContext } from 'hooks/contexts/locking-context'
import BaseCard from 'components/Cards/BaseCard'

const useStyles = makeStyles()((theme) => ({
  root: { display: 'flex', flexDirection: 'column', height: '100%' },
  flexContainer: { display: 'flex' },
  dialogNameContainer: {},
  questions: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flex: 1,
  },
  questionsList: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  questionText: {
    maxHeight: `${theme.typography.body1.lineHeight}em`,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    wordWrap: 'break-word',
    width: 'calc(100%)',
  },
  questionsInput: { marginTop: 'auto' },
  tooltip: { width: 'fit-content' },
  textfield: { width: '100%', marginTop: theme.spacing(2) },
  icon: {
    fontSize: '1.5rem',
  },
  iconButton: {},
}))

type EditTriggerAnswerProps = {
  answer: Answer
  onEditAnswerCallback: (answer: Answer) => void
}

/**
 * Trigger answer editing for old bots with v2 webchat. Does not support answer texts, buttons and answer title.
 * v4 bots should use @EditTriggerAnswerV4Bots instead.
 */
export default function EditTriggerAnswerV2Bots({
  answer: propsAnswer,
  onEditAnswerCallback,
}: EditTriggerAnswerProps): React.ReactElement {
  const { classes } = useStyles()
  const { lockState } = useLockingContext()

  const { flaggedAnswers, getTriggerAnswersArray } = useAnswers()
  const [dialogName, setDialogName] = useState<string>(propsAnswer.triggerDialogName || '')
  const [dialogNameError, setDialogNameError] = useState<string>()
  const [answer, setAnswer] = useState<Answer>(propsAnswer)
  const [existingTriggerDialogNames, setExistingTriggerDialogNames] = useState<string[]>([])

  /**
   * Creates a new answer object from the values in state and sets it to parent via the callback function.
   * @param localUtterances can receive utterances via props, to prevent async state update problem.
   */
  function updateAnswerInParent(localUtterances?: string[]): void {
    const newAnswer = buildAnswerObject(answer)
    newAnswer.setLabels(localUtterances ?? answer.labels)
    newAnswer.triggerDialogName = dialogName
    onEditAnswerCallback(newAnswer)
  }

  /**
   * Handles user input in dialog name textfield.
   * Changes dialogname in state.
   * @param event
   */
  function onDialogNameChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const newDialogName = event.target.value
    if (!newDialogName) setDialogNameError('Dialogname darf nicht leer sein.')
    else if (existingTriggerDialogNames.includes(newDialogName))
      setDialogNameError('Dieser Dialogname existiert bereits')
    else setDialogNameError(undefined)
    setDialogName(event.target.value)
  }

  /**
   * Handles blur event of dialogname textfield.
   * Adds dialogName from state to answer and sets answer in
   */
  function onDialogNameBlur(): void {
    if (!dialogNameError) updateAnswerInParent()
  }

  useEffect(
    function () {
      setAnswer(propsAnswer)
      const dialogName = propsAnswer.triggerDialogName
      setDialogName(dialogName ?? '')
    },
    [propsAnswer],
  )

  useEffect(
    function () {
      const triggerAnswers = getTriggerAnswersArray()
      const triggerDialogNames: string[] = []
      triggerAnswers.forEach((answer) => {
        if (answer.triggerDialogName) triggerDialogNames.push(answer.triggerDialogName)
      })

      setExistingTriggerDialogNames(triggerDialogNames)
    },
    [flaggedAnswers],
  )

  return (
    <div className={classes.root}>
      {/* <div className={classes.dialogNameContainer}>
        <Typography>Name des Dialoges, der durch diese Äußerungen gestartet werden soll:</Typography>
        <Textfield
          className={classes.textfield}
          value={dialogName}
          onChange={onDialogNameChange}
          onBlur={onDialogNameBlur}
          placeholder='Dialogname'
          label={dialogNameError ?? 'Dialogname'}
          error={!!dialogNameError}
          disabled={lockState !== 'canEdit'}
        />
      </div> */}
      <BaseCard width='100%' height='fit-content' minHeight='70vh'>
        <div className={classes.questions}>
          <div className={classes.questionsList}>
            <EditAnswerUtterances answer={answer} onUtterancesChangeCallback={updateAnswerInParent} rowsPerPage={7} />
          </div>
        </div>
      </BaseCard>
    </div>
  )
}
