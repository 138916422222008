import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useTheme } from '@mui/material/styles'
// Material ui
import Typography from '@mui/material/Typography'
// Custom Componets
import BaseCard from './BaseCard'
import { Divider, IconButton, ListItemText, Menu, MenuItem, MenuList } from '@mui/material'
import { MoreVert } from '@mui/icons-material'
import { useLockingContext } from '../../hooks/contexts/locking-context'
import { Can } from 'components/Can/Can'
import { useFlowdesignerContext } from 'hooks/contexts/flowdesigner-context'

const useStyles = makeStyles()((theme) => ({
  card: {
    transition: 'box-shadow 0.3s',
    cursor: 'pointer',
    '&:hover': {
      boxShadow:
        '0 14px 26px -12px rgb(0 0 0 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)', // '0px 2px 10px 2px rgba(0,0,0, 0.25)'
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '80%',
  },
  popupMenu: {
    padding: 0,
  },
  popupMenuList: {
    padding: 0,
  },
  popupMenuRedText: {
    color: theme.palette.error.main,
  },
}))

type DialogCardMenuProps = {
  isMainDialog: boolean
  onOpenDialog: () => void
  onEditDialog: () => void
  onCreateTemplate: () => void
  onDeleteDialog: () => void
  open: boolean
  onClose: () => void
  anchorElement: Element
}

/**
 * Menu component for menu button of card.
 */
function DialogCardMenu({
  isMainDialog,
  onCreateTemplate,
  onDeleteDialog,
  onEditDialog,
  onOpenDialog,
  open,
  onClose,
  anchorElement,
}: DialogCardMenuProps): React.ReactElement {
  const theme = useTheme()
  const { canIEditFlowdesigner } = useFlowdesignerContext()
  const { classes } = useStyles()
  return (
    <Menu
      open={open}
      onClose={onClose}
      anchorEl={anchorElement}
      classes={{ list: classes.popupMenuList }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{
        style: {
          width: '200px',
        },
      }}
    >
      {/* <Paper sx={{ width: 320, maxWidth: '100%' }}> */}
      <MenuList>
        <MenuItem onClick={onOpenDialog}>
          <ListItemText>Öffnen</ListItemText>
        </MenuItem>
        <MenuItem onClick={onEditDialog} disabled={!canIEditFlowdesigner}>
          <ListItemText>Dialog Infos bearbeiten</ListItemText>
        </MenuItem>

        <Can I='update' a='processTemplate' passThrough>
          {(canUpdate): React.ReactElement => (
            <Can I='create' a='processTemplate' passThrough>
              {(canCreate): React.ReactElement => {
                if (canUpdate || canCreate) {
                  return (
                    <MenuItem onClick={onCreateTemplate}>
                      <ListItemText>Als Vorlage speichern</ListItemText>
                    </MenuItem>
                  )
                } else {
                  return <></>
                }
              }}
            </Can>
          )}
        </Can>

        {!isMainDialog && (
          <>
            <Divider />
            <MenuItem onClick={onDeleteDialog} disabled={!canIEditFlowdesigner}>
              <ListItemText classes={{ primary: classes.popupMenuRedText }} primary='Löschen' />
            </MenuItem>
          </>
        )}
      </MenuList>
      {/* </Paper> */}
    </Menu>
  )
}

type CardProps = {
  dialogName: string
  isMainDialog?: boolean
  dialogId: string
  active?: boolean
  dialogDescription?: string
  onClick: () => void
  onDelete: (dialogId: string) => void
  onDialogNameChange: (dialogId: string, newName: string) => void
  onDialogDescChange: (dialogId: string, newDesc: string) => void
  disableButtons?: boolean
  onEdit: (dialogId: string) => void
  onCreateTemplate: (dialogId: string) => void
}

/**
 * Card for displaying dialog of chart (name + description).
 */
export default function DialogCard({
  dialogName,
  dialogId,
  dialogDescription,
  active = false,
  disableButtons = false,
  isMainDialog = false,
  onClick,
  onDelete,
  onDialogDescChange,
  onDialogNameChange,
  onEdit,
  onCreateTemplate,
}: CardProps): React.ReactElement {
  const theme = useTheme()
  const { classes } = useStyles()
  const { lockState } = useLockingContext()
  const [title, setTitle] = useState<string>(dialogName)
  const [description, setDescription] = useState<string | undefined>(dialogDescription)
  const [anchorElementForMenu, setAnchorElementForMenu] = useState<HTMLElement | null>(null)
  const menuOpen = Boolean(anchorElementForMenu)

  function onNameChange(newName: string): void {
    onDialogNameChange(dialogId, newName)
  }

  function onDescChange(newDesc: string): void {
    onDialogDescChange(dialogId, newDesc)
  }

  function handleMenuClose(): void {
    setAnchorElementForMenu(null)
  }

  function handleMenuClick(event: React.MouseEvent<HTMLButtonElement>): void {
    event.stopPropagation()
    if (menuOpen) {
      handleMenuClose()
    } else {
      setAnchorElementForMenu(event.currentTarget)
    }
  }

  useEffect(
    function () {
      setTitle(dialogName)
    },
    [dialogName],
  )

  useEffect(
    function () {
      setDescription(dialogDescription)
    },
    [dialogDescription],
  )

  return (
    <>
      <BaseCard
        title={title}
        titleVariant='h4'
        footerText={active ? 'Ausgewählt' : ' '}
        footerColor={active ? theme.palette.success.main : theme.palette.grey['500']}
        height='220px'
        width='100%'
        className={classes.card}
        onClick={(event): void => {
          if (typeof onClick !== 'undefined' && event.target?.type !== 'textarea') onClick()
        }}
        topRightActionButton={
          <>
            <IconButton
              onClick={handleMenuClick}
              disabled={lockState !== 'canEdit'}
              aria-controls={menuOpen ? 'basic-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={menuOpen ? 'true' : undefined}
            >
              <MoreVert />
            </IconButton>
          </>
        }
      >
        <Typography variant='body1'>{description || ''}</Typography>
      </BaseCard>
      {anchorElementForMenu && (
        <DialogCardMenu
          open={menuOpen}
          isMainDialog={isMainDialog}
          onOpenDialog={onClick}
          onEditDialog={(): void => {
            handleMenuClose()
            onEdit(dialogId)
          }}
          onClose={handleMenuClose}
          onDeleteDialog={(): void => {
            handleMenuClose()
            onDelete(dialogId)
          }}
          onCreateTemplate={(): void => {
            handleMenuClose()
            onCreateTemplate(dialogId)
          }}
          anchorElement={anchorElementForMenu}
        />
      )}
    </>
  )
}
