import { Textfield } from 'components/TextInput/Textfield'
import { EmailFallbackDepartment } from '../../@types/Settings/types'
import React, { useState, useEffect } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useLockingContext } from 'hooks/contexts/locking-context'
import { CustomIconButton } from 'components/Buttons'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  accordion: {
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: theme.shape.borderRadius,
    position: 'relative',
    '&.MuiAccordion-root': {
      marginTop: theme.spacing(2),
      marginBottom: 0,
      '&:before': {
        display: 'none',
      },
      '&.Mui-expanded': {
        marginTop: theme.spacing(2),
      },
    },
  },
}))

type EmailFallbackDepartmentConfigurationProps = {
  id: string
  departmentIndex: number
  deletable: boolean
  departmentName: string
  departmentDescription: string
  departmentEmailIntroText: string
  departmentRecipientAdress: string
  departmentFallbackSubject: string
  onDepartmentConfigChange: (newDepartmentConfig: EmailFallbackDepartment) => void
  onDeleteDepartment: (departmentId: string) => void
}

export default function EmailFallbackDepartmentConfiguration({
  departmentIndex,
  deletable,
  id,
  departmentName: initialDepartmentName,
  departmentDescription: initialDepartmentDescription,
  departmentRecipientAdress: initialDepartmentRecipientAdress,
  departmentFallbackSubject: initialDepartmentFallbackSubject,
  departmentEmailIntroText: initialDepartmentEmailIntroText,
  onDepartmentConfigChange,
  onDeleteDepartment,
}: EmailFallbackDepartmentConfigurationProps) {
  const { classes } = useStyles()
  const { lockState } = useLockingContext()
  const [expanded, setExpanded] = useState(false)
  const [departmentName, setDepartmentName] = useState(initialDepartmentName)
  const [departmentDescription, setDepartmentDescription] = useState(initialDepartmentDescription)
  const [departmentEmailIntroText, setDepartmentEmailIntroText] = useState(initialDepartmentEmailIntroText)
  const [departmentRecipientAdress, setDepartmentRecipientAdress] = useState(initialDepartmentRecipientAdress)
  const [departmentFallbackSubject, setDepartmentFallbackSubject] = useState(initialDepartmentFallbackSubject)

  useEffect(() => {
    if (departmentIndex === 0) {
      setExpanded(true)
    }
  }, [])

  useEffect(() => {
    onDepartmentConfigChange({
      id,
      departmentName,
      departmentDescription,
      departmentRecipientAdress,
      departmentFallbackSubject,
      departmentEmailIntroText,
    })
  }, [
    id,
    departmentName,
    departmentDescription,
    departmentRecipientAdress,
    departmentFallbackSubject,
    departmentEmailIntroText,
  ])

  function handleDepartmentNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    setDepartmentName(event.target.value)
  }

  function handleDepartmentDescriptionChange(event: React.ChangeEvent<HTMLInputElement>) {
    setDepartmentDescription(event.target.value)
  }

  function handleDepartmentRecipientAdressChange(event: React.ChangeEvent<HTMLInputElement>) {
    setDepartmentRecipientAdress(event.target.value)
  }

  function handleDepartmentFallbackSubjectChange(event: React.ChangeEvent<HTMLInputElement>) {
    setDepartmentFallbackSubject(event.target.value)
  }

  function handleDepartmentEmailIntroTextChange(event: React.ChangeEvent<HTMLInputElement>) {
    setDepartmentEmailIntroText(event.target.value)
  }

  return (
    <>
      <Accordion
        elevation={0}
        className={classes.accordion}
        expanded={expanded}
        onChange={(_, expanded) => setExpanded(expanded)}
      >
        <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
          <Typography variant='h5'>
            Stelle {departmentIndex + 1}: {departmentName}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.container}>
            <Textfield
              label='Stellenname'
              fullWidth
              value={departmentName}
              onChange={handleDepartmentNameChange}
              placeholder='Stellenname z.B. Kundensupport'
              disabled={lockState !== 'canEdit'}
              hint='Der Name der Stelle, an die die Nutzer*innen ihre Fragen und den bisherigen Gesprächsverlauf per E-Mail senden können. Hilfreich, wenn Sie mehrere zuständige Stellen haben, die verschiedene Themenbereiche abdecken.'
            />
            <Textfield
              label='Beschreibung der Stelle'
              fullWidth
              multiline
              rowsMax={3}
              value={departmentDescription}
              onChange={handleDepartmentDescriptionChange}
              placeholder='Stellenbeschreibung z.B. Kundensupport für Kunden aus Deutschland'
              disabled={lockState !== 'canEdit'}
              hint='Eine kurze Beschreibung der Stelle, die den Nutzer*innen hilft, zu erkennen, welche Stelle die richtige für ihr Anliegen ist.'
            />
            <Textfield
              label='E-Mail Adresse der Stelle'
              fullWidth
              type='email'
              value={departmentRecipientAdress}
              onChange={handleDepartmentRecipientAdressChange}
              placeholder='kundensupport@example.com'
              disabled={lockState !== 'canEdit'}
              hint='Die E-Mail Adresse der Stelle, an die die Nutzer*innen ihre Fragen und den bisherigen Gesprächsverlauf per E-Mail senden können.'
            />
            <Textfield
              label='Betreff für E-Mails an diese Stelle'
              fullWidth
              value={departmentFallbackSubject}
              onChange={handleDepartmentFallbackSubjectChange}
              placeholder='Kundensupport'
              disabled={lockState !== 'canEdit'}
              hint='Der Betreff für E-Mails an diese Stelle. Dieser wird als Standardbetreff in der E-Mail an die zuständige Stelle gesetzt und kann helfen, die E-Mails mit den Nutzeranfragen besser zuzuordnen.'
            />
            <Textfield
              label='Einführungstext für E-Mails an diese Stelle'
              fullWidth
              multiline
              rowsMax={3}
              value={departmentEmailIntroText}
              onChange={handleDepartmentEmailIntroTextChange}
              placeholder='Hallo, neue Anfrage über den Assistenten.'
              disabled={lockState !== 'canEdit'}
              hint='Ein kurzer Einführungstext für die E-Mails an diese Stelle. Dieser wird als Standardtext in der E-Mail an die zuständige Stelle gesetzt und kann helfen, die E-Mails mit den Nutzeranfragen besser zuzuordnen.'
            />
            {deletable && (
              <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <CustomIconButton
                  type='decline'
                  tooltip='Stelle löschen'
                  onClick={(): void => onDeleteDepartment(id)}
                  disabled={lockState === 'isBlocked'}
                />
              </div>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  )
}
