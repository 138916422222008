import React, { useState } from 'react'

// @mui/material
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Typography } from '@mui/material'
import DatePicker from '../../components/DatePicker/DatePicker'
import { TimespanOptionValue } from '../../@types/Analytics/types'

type SelectDateRangeDialogProps = {
  fromDate?: Date
  toDate?: Date
  timespanOptionValue: TimespanOptionValue
  onClose: () => void
  onSelectDateRange: (from: Date, to: Date, timespanOptionValue: TimespanOptionValue) => void
}

function SelectDateRangeDialog({
  fromDate: fromDateProps,
  toDate: toDateProps,
  onClose,
  onSelectDateRange,
  timespanOptionValue,
}: SelectDateRangeDialogProps): React.ReactElement {
  const [fromDate, setFromDate] = useState<Date | null>(fromDateProps || null)
  const [toDate, setToDate] = useState<Date | null>(toDateProps || null)

  function onFromDateChange(newDate: Date | null): void {
    setFromDate(newDate)
  }

  function onToDateChange(newDate: Date | null): void {
    setToDate(newDate)
  }

  function onSave(): void {
    if (fromDate !== null && toDate !== null) onSelectDateRange(fromDate, toDate, timespanOptionValue)
  }

  return (
    <Dialog open={true}>
      <DialogTitle>
        <Typography>Spezifischen Zeitraum auswählen</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography>Bitte wählen Sie den gewünschten Zeitraum.</Typography>
        </DialogContentText>
        <DatePicker
          locale='de'
          label='Von'
          value={fromDate}
          onSelect={onFromDateChange}
          disableFuture
          disableHighlightToday
        />
        <DialogContentText>
          <Typography></Typography>
        </DialogContentText>
        <DatePicker
          locale='de'
          label='Bis'
          value={toDate}
          onSelect={onToDateChange}
          disableFuture
          disableHighlightToday
          minDate={fromDate || undefined}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Schließen</Button>
        <Button onClick={onSave} disabled={!(fromDate instanceof Date) || !(toDate instanceof Date)}>
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SelectDateRangeDialog
