import React, { useState, useEffect } from 'react'

import { makeStyles } from 'tss-react/mui'
import { Checkbox, FormControlLabel, FormGroup, IconButton, Tooltip, Typography } from '@mui/material'
import { Delete } from '@mui/icons-material'

import DataCheckVariableSelect from './DataCheckVariableSelect'
import DataCheckAdvanced from './DataCheckAdvanced'

import { removeDatacheckFromNode } from '../../../../../utils/datacheckUtils'

// Types
import { Chart } from '../../../../../@types/Flowchart/types'

const useStyles = makeStyles()({
  container: { marginTop: '15px', border: '0.02px solid rgba(0,0,0,0.1)', borderRadius: '10px', padding: '10px' },
  headerContainer: { display: 'flex' },
  removeDatacheckButton: { marginLeft: 'auto' },
  variableSelectionContainer: { marginTop: '10px' },
})

type DataCheckProps = {
  chart: Chart
  dataCheckId: string
  setStateCallback: (chart: Chart) => void
}

export default function DataCheck({ chart, dataCheckId, setStateCallback }: DataCheckProps): React.ReactElement {
  const { classes } = useStyles()
  const [vars, setVars] = useState<string[]>([])
  const [advancedView, setAdvancedView] = useState<boolean>(false)

  /**
   * Toggles individual datacheck between simple and advanced view.
   */
  function onToggleDatacheckView(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void {
    chart.datachecks[dataCheckId].advanced = checked
    setAdvancedView(checked)
    setStateCallback(chart)
  }

  /**
   * Handles variable selection change event.
   * Sets new selected variables in state and executes callback.
   * @param {Chart} chart
   * @param {string[]} prevSelectedVarIds
   * @param {string[]} selectedVarIds
   */
  function onVariableSelect(chart: Chart, prevSelectedVarIds: string[], selectedVarIds: string[]): void {
    setVars(selectedVarIds)
    setStateCallback(chart)
  }

  /**
   * Handles datacheck removal from node.
   */
  function onRemoveDatacheck(): void {
    const nodeId = chart.selected?.id
    if (typeof nodeId === 'undefined') return
    chart = removeDatacheckFromNode(chart, nodeId, dataCheckId, true)
    setStateCallback(chart)
  }

  useEffect(
    function () {
      // find vars
      const selectedId = chart.selected.id
      if (typeof selectedId === 'undefined') return
      const vars = chart.datachecks[dataCheckId].nodes[selectedId] || []
      setVars(vars)
      // set advanced view or not
      const advanced = !!chart.datachecks[dataCheckId].advanced
      setAdvancedView(advanced)
    },
    [chart],
  )

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div>
          <Typography>
            Datencheck:{' '}
            <b>
              {chart.datachecks[dataCheckId].name +
                (!chart.datachecks[dataCheckId].predefined ? ' (benutzerdefiniert)' : '')}
            </b>
          </Typography>
        </div>
        <div className={classes.removeDatacheckButton}>
          <Tooltip id='removeDataCheck-tooltip' title={'Datencheck von dieser Node entfernen'} placement='top'>
            <IconButton onClick={onRemoveDatacheck} style={{ color: 'red' }}>
              <Delete />
            </IconButton>
          </Tooltip>
        </div>
      </div>

      {!chart.datachecks[dataCheckId].predefined && (
        <FormGroup row>
          <FormControlLabel
            control={<Checkbox checked={advancedView} onChange={onToggleDatacheckView} style={{ color: '#0b2639' }} />}
            label='Erweitert'
          />
        </FormGroup>
      )}

      <div className={classes.variableSelectionContainer}>
        <Typography>Wählen Sie die Variable(n) die überprüft werden soll(en).</Typography>
        <DataCheckVariableSelect chart={chart} onChangeCallback={onVariableSelect} dataCheckId={dataCheckId} />
      </div>
      {chart.datachecks[dataCheckId] && chart.datachecks[dataCheckId].advanced && vars.length > 0 && (
        <DataCheckAdvanced dataCheckId={dataCheckId} setStateCallback={setStateCallback} chart={chart} />
      )}
    </div>
  )
}
