import React, { createContext, useContext, useReducer, useCallback, useMemo } from 'react'
import {
  MODULE_TYPE_ALEPHALPHA,
  MODULE_TYPE_NLU,
  MODULE_TYPE_XZUFI,
  MODULE_ID_CONVAISE_NLU,
  MODULE_TYPE_RAG,
  MODULE_TYPE_LLM,
  MODULE_ID_CONVAISE_LLM_NLU,
} from 'utils/constants'
import { getAccount, getSilentToken } from 'components/AuthProvider/PublicClientApp'
// Types
import { BotInfos, NLUModule } from '../../@types/BotInformation/types'
import { AuthorizationEntry } from '../../@types/Authorization/types'
import { toJSON } from 'utils/jsonUtils'

// ----- TYPE DEFINITIONS -----

// Maps Actions to Payloads - Standard does not need customization
type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key
      }
    : {
        type: Key
        payload: M[Key]
      }
}

// Possible Actions
enum Types {
  SetBot = 'SET_BOT',
}

// Payloads for each action
type BotContextPayload = {
  [Types.SetBot]: {
    bot: BotInfos | null
    granularPermissions: AuthorizationEntry[] | null
  }
}

type Action = ActionMap<BotContextPayload>[keyof ActionMap<BotContextPayload>]
type State = { bot: BotInfos | null; granularPermissions: AuthorizationEntry[] | null }

type Dispatch = (action: Action) => void
type BotProviderProps = { children: React.ReactNode }

/**
 * ----- CONTEXT -----
 * Bot Context is a local context used for storing bot information like the id, name of flowchart, endpoint etc.
 *
 * To access this context:
 * import { useBotContext } from '<path>/hooks/contexts/bot-context.tsx'
 *
 * // in a function
 * const {bot, setBot, resetBot} = useBotContext()
 *
 * setBot(botInfos)
 */

const BotContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined)

function botReducer(state: State, action: Action): State {
  switch (action.type) {
    case Types.SetBot: {
      // ensure that all date values are actually date objects (and not strings)
      const botInfos = action.payload.bot
      const granularPermissions = action.payload.granularPermissions
        ? action.payload.granularPermissions
        : state.granularPermissions ?? null
      if (botInfos?.chartVersions)
        Object.values(botInfos.chartVersions).forEach((version) => (version.timestamp = new Date(version.timestamp)))
      if (botInfos?.minorChartVersion)
        botInfos.minorChartVersion.timestamp = new Date(botInfos.minorChartVersion.timestamp)
      if (botInfos?.translationFileVersions)
        Object.values(botInfos.translationFileVersions).forEach(
          (version) => (version.timestamp = new Date(version.timestamp)),
        )
      if (botInfos?.minorTranslationFileVersion)
        botInfos.minorTranslationFileVersion.timestamp = new Date(botInfos.minorTranslationFileVersion.timestamp)

      // make sure to spread that state just in case!
      return { ...state, bot: botInfos, granularPermissions: granularPermissions }
    }
    default: {
      // helps us avoid typos!
      throw new Error(`[BotContext-Reducer] Unhandled action type: ${action.type}`)
    }
  }
}

/**
 * Context provider for providing StudioContext
 * @returns
 */
function BotContextProvider({ children }: BotProviderProps): JSX.Element {
  const [state, dispatch] = useReducer(botReducer, { bot: null, granularPermissions: null })
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  // const value = useMemo(() => [state, dispatch], [state])
  const value = { state, dispatch }
  return <BotContext.Provider value={value}>{children}</BotContext.Provider>
}

/**
 * Hook for accessing and manipulating the StudioContext state
 * @returns
 *
 * To access this context:
 * import { useBotContext } from '<path>/hooks/contexts/bot-context.tsx'
 *
 * // in a function
 * const {bot, setBot, resetBot} = useStudioContext()
 *
 * setBot(botInfos)
 */
function useBotContext(): {
  bot: BotInfos | null
  granularPermissions: AuthorizationEntry[] | null
  granularKnowledgePermissions: AuthorizationEntry | null
  setBot: (bot: BotInfos, granularPermissions?: AuthorizationEntry[] | null) => void
  resetBot: () => void
  getPublishedLanguages: () => string[]
  getLanguages: () => string[]
  botHasUnpublishedTranslations: () => boolean
  hasModule: (
    moduleType:
      | typeof MODULE_TYPE_NLU
      | typeof MODULE_TYPE_XZUFI
      | typeof MODULE_TYPE_ALEPHALPHA
      | typeof MODULE_TYPE_RAG
      | typeof MODULE_TYPE_LLM,
  ) => boolean
  customWebchatStyles: { [varName: string]: string }
  // permissions in auth token
  permissionsFlowdesigner: string[]
  hasNLUKnowledgeDB: boolean
  hasOldClassicNLU: boolean // indicates if bot does not use the new LLM based nlu approach and needs training for changes to the knowledge db.
} {
  const context = useContext(BotContext)
  if (context === undefined) {
    throw new Error('[useBotContext] useBotContext must be used within a BotContextProvider')
  }

  // splitting it up, allows us to add additional api calls
  const { state, dispatch } = context

  // permissions from IAM (AAD B2C) token
  const account = getAccount()
  const tokenClaims = account?.idTokenClaims ?? {}
  const permissionsFlowdesigner = toJSON(tokenClaims['extension_per_floweditor']) as string[]

  // ---- API ----
  // To not let anything work on the context state directly and to have an easy to use API

  // State
  const { bot, granularPermissions } = state
  const granularKnowledgePermissions =
    granularPermissions && granularPermissions.some((permission) => permission.area === 'knowledge')
      ? granularPermissions.filter((permission) => permission.area === 'knowledge')[0]
      : null

  // Functions
  const setBot = useCallback(
    (bot, granularPermissions) => dispatch({ type: Types.SetBot, payload: { bot, granularPermissions } }),
    [],
  )
  const resetBot = useCallback(
    () => dispatch({ type: Types.SetBot, payload: { bot: null, granularPermissions: null } }),
    [],
  )
  const getPublishedLanguages = useCallback((): string[] => {
    if (bot) {
      const translationFileVersions = Object.values(bot.translationFileVersions).map(
        (versionObj) => versionObj.versionNr,
      )
      const latestTranslationFileVersion = Math.max(...translationFileVersions)
      const publishedLanguages = bot.translationFileVersions[`${latestTranslationFileVersion}`].publishedLanguages
      return publishedLanguages
    } else {
      return []
    }
  }, [bot])
  const getLanguages = useCallback((): string[] => {
    if (bot) {
      const translationFileVersions = Object.values(bot.translationFileVersions).map(
        (versionObj) => versionObj.versionNr,
      )
      const latestTranslationFileVersion = Math.max(...translationFileVersions)
      const languages = bot.translationFileVersions[`${latestTranslationFileVersion}`].languages
      return languages
    } else {
      return []
    }
  }, [bot])

  // returns true if the published translation file is not the same with the last translation file version
  const botHasUnpublishedTranslations = useCallback((): boolean => {
    if (bot) {
      const translationFileVersions = Object.values(bot.translationFileVersions).map(
        (versionObj) => versionObj.versionNr,
      )
      const latestTranslationFileVersion = Math.max(...translationFileVersions)
      if (bot?.publishedTranslationFileVersion !== latestTranslationFileVersion) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }, [bot])

  // Checks if the bot has a module of a certain type
  const hasModule = useCallback(
    (
      moduleType:
        | typeof MODULE_TYPE_NLU
        | typeof MODULE_TYPE_XZUFI
        | typeof MODULE_TYPE_ALEPHALPHA
        | typeof MODULE_TYPE_RAG
        | typeof MODULE_TYPE_LLM,
    ): boolean => {
      if (bot && bot.modules && Object.values(bot.modules).length > 0) {
        const moduleTypes = Object.values(bot.modules).map((module) => module.type)
        return moduleTypes.includes(moduleType)
      } else {
        return false
      }
    },
    [bot],
  )

  const customWebchatStyles = useCallback((): { [varName: string]: string } => {
    const customStyles = {}
    if (bot?.customStyles) {
      if (bot?.customStyles['primaryColor']) customStyles['--primary-color'] = bot?.customStyles['primaryColor']
      if (bot?.customStyles['primaryColorText'])
        customStyles['--primary-color-text'] = bot?.customStyles['primaryColorText']
      if (bot?.customStyles['disabledColor'])
        // this is the deprecated old name. We just keep it here as it gets overwritten if the new name exists
        customStyles['--primary-color-disabled'] = bot?.customStyles['disabledColor']
      if (bot?.customStyles['primaryColorDisabled'])
        customStyles['--primary-color-disabled'] = bot?.customStyles['primaryColorDisabled']
    }
    return customStyles
  }, [bot])()

  const hasNLUKnowledgeDB = useMemo((): boolean => {
    return !!(
      (hasModule('nlu') && (bot?.modules[MODULE_ID_CONVAISE_NLU] as NLUModule)?.knowledgeDbId) ||
      (bot?.modules[MODULE_ID_CONVAISE_LLM_NLU] as NLUModule)?.knowledgeDbId
    )
  }, [bot, hasModule])

  const hasOldClassicNLU = useMemo((): boolean => {
    return !!(hasModule('nlu') && (bot?.modules[MODULE_ID_CONVAISE_NLU] as NLUModule)?.knowledgeDbId)
  }, [bot, hasModule])

  // Controll what is returned
  return {
    bot,
    granularPermissions,
    granularKnowledgePermissions,
    setBot,
    resetBot,
    getPublishedLanguages,
    getLanguages,
    botHasUnpublishedTranslations,
    hasModule,
    customWebchatStyles,
    permissionsFlowdesigner,
    hasNLUKnowledgeDB,
    hasOldClassicNLU,
  }
}

export { BotContextProvider, useBotContext }
