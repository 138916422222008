import React, { Fragment, useRef, useState, useEffect } from 'react'
import { v4 as uuid } from 'uuid'
// import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack5'
import { Document, Page, pdfjs } from 'react-pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

import PerfectScrollbar from 'react-perfect-scrollbar'

// @mui/material
import { makeStyles } from 'tss-react/mui'

import Button from '@mui/material/Button'
import Fab from '@mui/material/Fab'
// @mui/icons-material
import DeleteIcon from '@mui/icons-material/Delete'
// I18n
// import { Trans } from '@lingui/macro'
// Custom Components
import DragAndDrop from '../Upload/DragAndDrop'
import { IngestPDFResult } from '../../classes/Ingest'
import SectionHighlights from './SectionHighlights'
import InfotextHighlights from './InfotextHighlights'
import SubSectionHighlights from './SubSectionHighlights'
import FieldHighlighs from './FieldHighlights'
import {
  Loading,
  LoadingDocument,
  LoadingPage,
  LoadingError,
  ErrorNoFileSpecified,
  ErrorTooManyFiles,
  ErrorWrongFileType,
  ErrorTooLargeFile,
  ErrorConnectionFailed,
  AnalyzingDocument,
} from './Warnings'
// TODO: add Warning
import { DeleteFileDialog } from './Dialogs'
import Pagination from './Pagination'
// Hooks
import useRefOffsetWidth from '../../hooks/useSizes/useRefOffsetWidth'
// types
import { Signature, Container, Coordinates, Dimensions, SyncedSignature } from '../../@types/Ingest/types'
// API
import { getPDFSignature } from '../../api/StudioBackend'

const useStyles = makeStyles()((theme) => ({
  pdfWrapper: {
    position: 'relative',
    height: '100%',
  },
  pdfDocument: {
    overflow: 'hidden',
    position: 'relative',
  },
  infoWrapper: {
    height: '100%',
    width: '80%',
    display: 'relative',
    paddingTop: '50%',
  },
  pdfProgress: {
    color: theme.palette.text.primary,
    width: 'auto',
  },
  warningButton: {
    backgroundColor: '#ff9800',
    '& .MuiButton-label': {
      textTransform: 'uppercase',
    },
  },
  pageControlsLeft: {
    position: 'absolute',
    bottom: '0%',
    left: '0%',
    transition: 'opacity ease-in-out 0.2s',
    opacity: 1,
  },
  pageControlsRight: {
    position: 'absolute',
    bottom: '0%',
    right: '0%',
    transition: 'opacity ease-in-out 0.2s',
    opacity: 1,
  },
  pageControlsMiddle: {
    position: 'absolute',
    bottom: '0%',
    left: '40%',
    transition: 'opacity ease-in-out 0.2s',
    opacity: 1,
  },
  // pageControlsButtonGroup: {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   alignItems: 'center',
  //   '& > *': {
  //     margin: theme.spacing(1),
  //   },
  // },
}))

// Interfaces
type PDFViewerProps = {
  file?: File
  setFile: (file: File) => void
  stage: 'sections' | 'subsections' | 'infotexts' | 'fields' | string
  activateToolbar: Function
  disableToolbar: Function
  activeTool?: 'selection' | 'area' | 'text' | 'group' | string | null
  setSignature: (e: Signature | null) => void
  setSyncedSignature: (e: SyncedSignature | null) => void
  ingestPDFResult: IngestPDFResult
  setIngestPDFResult: (e: IngestPDFResult) => void
  setStage: (stage: string) => void
  setHasFile: (e: boolean) => void
  isSectionDone: number
  isInfoTextDone: number
  isFieldDone: number
  setIsInfoTextDone: (e: number) => void
  setIsSectionDone: (e: number) => void
  setIsFieldDone: (e: number) => void
}

/**
 * TODO: Update
 * TODO: delete Warning
 * TODO: Mobile (Tablet friendly)
 */
function PDFViewer(props: PDFViewerProps): React.ReactElement {
  const pdfWrapper = useRef<HTMLDivElement | null>(null)
  const width = useRefOffsetWidth(pdfWrapper)
  const documentRef = useRef<HTMLDivElement | null>(null)
  const { classes } = useStyles()
  // States
  // FILE STATE
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [refreshCount, setRefreshcount] = useState(0) // used in case of an error to rerender the component
  const [uploadedFile, setFile] = useState<File | null>(props.file || null)
  const [loading, setLoading] = useState<string>('unloaded')
  const [errorState, setErrorState] = useState<number>(0) // 0: no error, 1: too many files, 2: wrong file types, 3: too large file, 4: file upload failed
  // PDF STATE
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [numPages, setNumPages] = useState<number | null>(null)
  const [page, setPage] = useState<number>(1)
  const [signature, setSignature] = useState<Signature | null>(null)
  const [targetDimensions, setTargetDimensions] = useState<Dimensions>({ h: 0, w: 0 })
  const [syncedSignature, setSyncedSignature] = useState<SyncedSignature | null>(null)
  const stage = props.stage
  // PROCESS STATE
  // containers are managed here because we need them in SectionHighlights and SubsectionHighlights
  const [containers, setContainers] = useState<Container[]>([])
  const [containerId, setContainerId] = useState<string | null>(null)
  const [sectionCheck, setSectionCheck] = useState<boolean>(false)
  const [warning, setWarning] = useState<number>(0) // 0: no dialog, 1: delete dialog

  /**
   * Returns the height and width of the target prop based on getBoundingClientRect()
   */
  function getTargetDimensions(): Dimensions {
    if (documentRef && documentRef.current) {
      const _boundingBox = documentRef.current.getBoundingClientRect()
      return { h: _boundingBox.height, w: _boundingBox.width }
    }
    return { h: 0, w: 0 }
  }

  /**
   * Adjusts a fields coordinates to the pdfWrapper view dimensions
   * @param pageDimensions Original dimensions of the pdf page
   * @param coordinates PDF coordinates of the field
   */
  function calculateAdjustedFieldCoordinates(pageDimensions: Dimensions, coordinates: Coordinates): Coordinates {
    const xRatio = targetDimensions.w / pageDimensions.w
    const yRatio = targetDimensions.h / pageDimensions.h
    // eslint-disable-next-line no-undef
    const x = Math.round(coordinates.x * xRatio)
    // we need to adjust y because y coords in original pdf are from bottom to top, here they are from top to bottom
    // additionally we need to subtract the height
    const y = Math.round((coordinates.y - pageDimensions.h) * -1 * yRatio - coordinates.h * yRatio)
    const w = Math.round(coordinates.w * xRatio)
    const h = Math.round(coordinates.h * yRatio)
    return { x, y, w, h }
  }

  /**
   * Calculates adjusted pageDimensions.
   * @param pageDimensions
   */
  function calculateAdjustedDimensions(pageDimensions: Dimensions): Dimensions {
    const xRatio = targetDimensions.w / pageDimensions.w
    const yRatio = targetDimensions.h / pageDimensions.h

    return { w: Math.round(pageDimensions.w * xRatio), h: Math.round(pageDimensions.h * yRatio) }
  }

  /**
   * Calculates modified signature sclaed to the target, based on the prop signature
   */
  function calculateSig(): SyncedSignature {
    if (signature && signature.fields && signature.fields.length > 0) {
      const _sig = signature.fields,
        _modSig: SyncedSignature = []
      for (let i = 0, l = _sig.length; i < l; i += 1) {
        const _field = _sig[i].field
        _modSig.push({
          id: uuid(),
          page: _sig[i].page,
          pageDimens: {
            original: { w: _sig[i].pageW, h: _sig[i].pageH },
            sync: calculateAdjustedDimensions({ w: _sig[i].pageW, h: _sig[i].pageH }),
          },
          fieldName: _field.fieldName,
          alternativeText: _field.alternativeText,
          mappingName: _field.mappingName,
          partialName: _field.partialName,
          required: _field.required,
          pdfFieldType: _field.pdfFieldType,
          type: _field.type ? _field.type : undefined,
          options: _field.options ? _field.options : undefined,
          optionsDisplayValues: _field.optionsDisplayValues ? _field.optionsDisplayValues : undefined,
          coords: {
            original: { x: _field.coords.x, y: _field.coords.y, w: _field.coords.w, h: _field.coords.h },
            sync: calculateAdjustedFieldCoordinates(
              { w: _sig[i].pageW, h: _sig[i].pageH },
              { x: _field.coords.x, y: _field.coords.y, w: _field.coords.w, h: _field.coords.h },
            ),
          },
          selection: {
            enabled: true, // initially all fields can be selected
          },
        })
      }
      return _modSig
    } else {
      return []
    }
  }

  // _____ HANDLERS ______

  async function loadSignature(file: File, tryNr = 0): Promise<void> {
    // eslint-disable-next-line no-undef
    if (file) {
      setFile(file)
      props.setFile(file)
      // eslint-disable-next-line no-undef
      const signature: Signature | null = await getPDFSignature(file)
      if (signature !== null) {
        props.setSignature(signature)
        setSignature(signature)
        setLoading('loaded')
      } else {
        console.warn('getSignature is null')
        // 3 retries
        if (tryNr < 3) {
          await loadSignature(file, tryNr + 1)
        } else {
          // if 3 retries throw error
          setErrorState(4)
        }
      }
    }
  }

  /**
   * Listens to the document input. Triggered as soon as a document / file is chosen
   * @param {FileList} files array of files
   */
  async function onChange(files: FileList): Promise<void> {
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_BRANCH === 'development' &&
      console.info(
        '[Ingest] Upload: ',
        files[0].type,
        Math.round(files[0].size / 1024) + 'Kb',
        ` - Filesize is ${Math.round(files[0].size / 1024) < 10000 ? 'ok' : 'too large'} `,
      )
    // File Validation
    if (files.length > 1) {
      // More than one File
      setErrorState(1)
      // setTimeout(function () {
      //   setErrorState(0)
      // }, 5000)
    } else if (files[0].type === 'application/json') {
      // await this.importChart(files[0])
    } else if (files[0].type !== 'application/pdf') {
      // False file type
      setErrorState(2)
      // setTimeout(function () {
      //   setErrorState(0)
      // }, 5000)
    } else if (Math.round(files[0].size / 1024) >= 10000) {
      // File too large
      setErrorState(3)
      // setTimeout(function () {
      //   setErrorState(0)
      // }, 5000)
    } else {
      // await this.upload(files[0])
      await loadSignature(files[0])
    }
  }

  async function onDocumentLoadSuccess({ numPages }: { numPages: number }): Promise<void> {
    setNumPages(numPages)
    if (numPages > 0) {
      setPage(1)
    }
    props.setHasFile(true)
  }

  /**
   * Resets all states to the start for a new file uplaod
   */
  function reset(): void {
    // NOTE: DO NOT FORGET to reset all states that you add to this file ;) ~ Jakob 20.10.2020
    setWarning(0)
    setLoading('loading')
    setFile(null)
    setLoading('unloaded')
    setContainers([])
    setContainerId(null)
    setSignature(null)
    setSyncedSignature(null)
    setNumPages(null)
    setErrorState(0)
    props.setHasFile(false)
    props.setStage('sections')
    props.setIngestPDFResult(new IngestPDFResult())
    props.setSyncedSignature(null)
    props.setIsSectionDone(0)
    props.setIsInfoTextDone(0)
  }

  // _____ useEffect _____

  useEffect(() => {
    if (uploadedFile !== null && signature !== null) {
      props.activateToolbar()
    } else {
      props.disableToolbar()
    }
  }, [uploadedFile, signature])

  // update target dimensions once documentRef is set
  useEffect(() => {
    if (documentRef && documentRef.current !== null && loading === 'Page loaded') {
      setTargetDimensions(getTargetDimensions())
    }
  }, [documentRef, documentRef.current, loading])

  // As soon as target dimensions are calculated, calculate the modified sig
  // also add all fields to ingestPDFResult
  useEffect(() => {
    const syncedSignature = calculateSig()
    setSyncedSignature(syncedSignature)
    props.setSyncedSignature(syncedSignature)
  }, [targetDimensions])

  useEffect(() => {
    if (props.isSectionDone === 1) {
      setSectionCheck(true)
      props.setIsSectionDone(0)
      props.setIsInfoTextDone(0)
      props.setIsFieldDone(0)
    }
    if (props.isInfoTextDone === 1) {
      props.setStage('fields')
    }
    if (props.isFieldDone === 1) {
      props.setStage('generation')
    }
  }, [props.isSectionDone, props.isInfoTextDone, props.isFieldDone])

  // useEffect(() => {
  //   if (props.stage === 'fields') {
  //     props.disableToolbar()
  //   }
  // }, [props.stage])
  // _____ MINI COMPONENTS _____

  const reuploadButton = (): React.ReactNode => {
    return (
      <Button className={classes.warningButton} color='inherit' size='small' onClick={(): void => setErrorState(0)}>
        {/* <Trans>Reupload</Trans> */}
        Re-Upload
      </Button>
    )
  }

  const retryUploadButton = (): React.ReactNode => {
    return (
      <Button
        className={classes.warningButton}
        color='inherit'
        size='small'
        onClick={async (): Promise<void> => {
          if (uploadedFile !== null) {
            setErrorState(0)
            await loadSignature(uploadedFile)
          } else {
            setErrorState(0)
          }
        }}
      >
        Erneut versuchen
        {/* <Trans>Retry</Trans> */}
      </Button>
    )
  }

  const retryButton = (): React.ReactNode => {
    return (
      <Button
        className={classes.warningButton}
        color='inherit'
        size='small'
        onClick={(): void => setRefreshcount((prev) => prev + 1)}
      >
        Erneut versuchen
        {/* <Trans>Retry</Trans> */}
      </Button>
    )
  }

  // _____ RENDER _____

  function renderUpload(): React.ReactNode {
    return (
      <Fragment>
        <DragAndDrop
          handleDrop={onChange}
          allowedFileTypes='application/pdf, .json'
          uploadButtonText='Datei hochladen'
          optionalText='Sie können auch einfach eine Datei in diesen Bereich ziehen.'
        />
      </Fragment>
    )
  }

  function renderPDFView(): React.ReactNode {
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_BRANCH === 'development' && console.info('PDFViewer Stage: ', stage)
    return (
      <Fragment>
        {signature !== null ? (
          <>
            <PerfectScrollbar options={{ suppressScrollX: true, minScrollbarLength: 20 }}>
              <Document
                inputRef={documentRef} // throws error that function is expected, but object delivered - that is a false positive error, see github issue of package ~ Jakob 02.10.2020
                className={classes.pdfDocument}
                file={uploadedFile}
                loading={<LoadingDocument />}
                error={<LoadingError bttn={retryButton()} />}
                noData={<ErrorNoFileSpecified bttn={reuploadButton()} />}
                onLoadSuccess={onDocumentLoadSuccess}
                // style={
                //   { cursor: 'crosshair' }
                //   // eslint-disable-next-line react/prop-types
                //   // typeof props.activeTool === 'string' && props.activeTool === 'area'
                //   //   ? { cursor: 'crosshair' }
                //   //   : { cursor: 'default' }
                // }
              >
                {/* {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                width={width}
                // height={'100%'}
                onLoadProgress={renderLoadingPage}
                error={renderLoadingError}
                noData={renderNoFileSpecified}
              />
            ))} */}
                <Page
                  pageNumber={page}
                  width={width}
                  // height={'100%'}
                  onLoadProgress={<LoadingPage />}
                  onLoadSuccess={(): void => {
                    setLoading('Page loaded')
                  }}
                  error={<LoadingError bttn={retryButton()} />}
                  noData={<ErrorNoFileSpecified bttn={reuploadButton()} />}
                  style={
                    { cursor: 'crosshair' }
                    // eslint-disable-next-line react/prop-types
                    // typeof props.activeTool === 'string' && props.activeTool === 'area'
                    //   ? { cursor: 'crosshair' }
                    //   : { cursor: 'default' }
                  }
                />
                {documentRef && documentRef.current && loading === 'Page loaded' && syncedSignature !== null && (
                  <Fragment>
                    {stage === 'sections' && (
                      <SectionHighlights
                        target={documentRef.current}
                        syncedSignature={syncedSignature}
                        onSyncedSignatureChange={(e: unknown): void => {
                          return
                        }}
                        pageNumber={page - 1} // minus 1, because the <Page /> pagenumber starts from 1 and the fields start from 0
                        // eslint-disable-next-line react/prop-types
                        ingestPDFResult={props.ingestPDFResult}
                        // eslint-disable-next-line react/prop-types
                        setIngestPDFResult={(result): void => {
                          // eslint-disable-next-line no-undef
                          process.env.REACT_APP_BRANCH === 'development' && console.info('PDFVIEWER RESULT: ', result)
                          // eslint-disable-next-line react/prop-types
                          props.setIngestPDFResult(result)
                        }}
                        // eslint-disable-next-line react/prop-types
                        setStage={(stage): void => props.setStage(stage)}
                        containers={containers}
                        setContainers={(_containers): void => {
                          setContainers(_containers)
                          // eslint-disable-next-line no-undef
                          process.env.REACT_APP_BRANCH === 'development' &&
                            console.info('PDFVIEWER CONTAINERS:', _containers)
                        }}
                        containerId={containerId}
                        setContainerId={(id): void => setContainerId(id)}
                        sectionCheck={sectionCheck}
                        setSectionCheck={(check): void => setSectionCheck(check)}
                        // eslint-disable-next-line react/prop-types
                        isSectionDone={props.isSectionDone}
                        // eslint-disable-next-line react/prop-types
                        setIsInfoTextDone={(done: number): void => props.setIsInfoTextDone(done)}
                        // eslint-disable-next-line react/prop-types
                        setIsSectionDone={(done: number): void => props.setIsSectionDone(done)}
                      />
                    )}
                    {stage === 'subsections' && containerId && (
                      <SubSectionHighlights
                        target={documentRef.current}
                        syncedSignature={syncedSignature}
                        containers={containers}
                        pageNumber={page - 1} // minus 1, because the <Page /> pagenumber starts from 1 and the fields start from 0
                        // eslint-disable-next-line react/prop-types
                        ingestPDFResult={props.ingestPDFResult}
                        // eslint-disable-next-line react/prop-types
                        setIngestPDFResult={(result): void => {
                          // eslint-disable-next-line no-undef
                          process.env.REACT_APP_BRANCH === 'development' && console.info('PDFVIEWER RESULT: ', result)
                          // eslint-disable-next-line react/prop-types
                          props.setIngestPDFResult(result)
                        }}
                        // eslint-disable-next-line react/prop-types
                        setStage={(stage): void => props.setStage(stage)}
                        containerId={containerId}
                        setContainerId={(id): void => setContainerId(id)}
                      />
                    )}
                    {stage === 'infotexts' && (
                      <InfotextHighlights
                        target={documentRef.current}
                        syncedSignature={syncedSignature}
                        onSignatureChange={(e: unknown): void => {
                          // eslint-disable-next-line no-undef
                          process.env.REACT_APP_BRANCH === 'development' && console.info(e)
                        }}
                        pageNumber={page - 1} // minus 1, because the <Page /> pagenumber starts from 1 and the fields start from 0
                        // eslint-disable-next-line react/prop-types
                        ingestPDFResult={props.ingestPDFResult}
                        // eslint-disable-next-line react/prop-types
                        setIngestPDFResult={(result): void => props.setIngestPDFResult(result)}
                      />
                    )}
                    {stage === 'fields' && (
                      <FieldHighlighs
                        target={documentRef.current}
                        pageNumber={page - 1} // minus 1, because the <Page /> pagenumber starts from 1 and the fields start from 0
                        syncedSignature={syncedSignature}
                        // eslint-disable-next-line react/prop-types
                        ingestPDFResult={props.ingestPDFResult}
                        // eslint-disable-next-line react/prop-types
                        setIngestPDFResult={(result): void => props.setIngestPDFResult(result)}
                      />
                    )}
                  </Fragment>
                )}
              </Document>
            </PerfectScrollbar>
            <div id='pageControls'>
              {/* Remove file to upload new file */}
              <Fab
                size='small'
                color='primary'
                aria-label='delete'
                onClick={(): void => {
                  setWarning(1)
                }}
                className={classes.pageControlsLeft}
              >
                <DeleteIcon />
              </Fab>
              {numPages && (
                <div className={classes.pageControlsRight}>
                  {/* FIXME: Do a real center */}
                  <Pagination
                    onNext={(): void => setPage(page + 1)}
                    onPrev={(): void => setPage(page - 1)}
                    numPages={numPages}
                    page={page}
                    startPage={1}
                  />
                </div>
              )}
              {/* {stage === 'sections' && (
                <Fab
                  size='small'
                  color='primary'
                  aria-label='save'
                  onClick={(): void => {
                    setSectionCheck(true)
                  }}
                  className={classes.pageControlsRight}
                >
                  <SaveIcon />
                </Fab>
              )} */}
            </div>
            <DeleteFileDialog
              open={warning === 1}
              onCancle={(): void => setWarning(0)}
              onConfirm={(): void => reset()}
            />
          </>
        ) : (
          <AnalyzingDocument />
        )}
      </Fragment>
    )
  }

  function renderWarningsWrapper(children: React.ReactNode): React.ReactElement {
    return (
      <Fragment>
        {errorState === 1 && <ErrorTooManyFiles bttn={reuploadButton()} />}
        {errorState === 2 && <ErrorWrongFileType bttn={reuploadButton()} />}
        {errorState === 3 && <ErrorTooLargeFile bttn={reuploadButton()} />}
        {errorState === 4 && <ErrorConnectionFailed bttn={retryUploadButton()} />}
        {errorState === 0 && children}
        {/* Loading */}
        {loading && <Loading />}
      </Fragment>
    )
  }

  // _____ RETURN _____

  return (
    <div
      className={classes.pdfWrapper}
      ref={pdfWrapper}
      style={{
        cursor: uploadedFile
          ? props.activeTool === 'area' && stage === 'sections'
            ? 'crosshair'
            : props.activeTool === 'area' && stage === 'subsections'
            ? 'not-allowed'
            : 'auto'
          : 'auto',
      }}
    >
      {renderWarningsWrapper(uploadedFile ? renderPDFView() : renderUpload())}
    </div>
  )
}

export default PDFViewer
