import React, { useState, useEffect, useCallback } from 'react'

// @mui/material components
import xzufiSearchLeistung from 'assets/img/modules/xzufi_search_leistung.svg'
import { makeStyles } from 'tss-react/mui'
import { Chart } from '../../../../../@types/Flowchart/types'
import SelectDropdown, { Option, ActionMeta } from '../../../../../components/Dropdown/SelectDropdown'
import { FormControlLabel, FormGroup, Switch, Tab, Tabs, Typography } from '@mui/material'
import ContentContainer from '../ContentContainer'
import { useBotContext } from 'hooks/contexts/bot-context'
import { XzufiModule } from '../../../../../@types/BotInformation/types'
import CustomContentTooltip from 'components/Tooltips/CustomContentTooltip'
import VariablesAutosuggestSelect from '../Variables/VariablesAutosuggestSelect'
import { MODULE_TYPE_XZUFI } from 'utils/constants'
import { cloneDeep } from 'lodash'

const useStyles = makeStyles()((theme) => ({
  container: { height: '100%' },
  inputWithInfo: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  inputInfoIcon: {
    marginLeft: theme.spacing(1),
    height: '20px',
    color: theme.palette.grey[700],
  },
  moduleSelection: {
    marginTop: theme.spacing(4),
  },
  addQnAModuleContainer: {
    marginTop: theme.spacing(4),
  },
  variables: {
    marginTop: theme.spacing(4),
  },
  varSelectionContainer: {
    marginTop: theme.spacing(2),
  },
}))

enum VariableSelectionPurpose {
  SEARCH_RESULT,
  LENGTH_SEARCH_RESULT,
  CORRELATION_ID,
  USER_QUERY,
  QNA_ANSWER,
  QNA_ACTIONS,
  QNA_TRIGGERINTENT,
}

type SXzufiGetAnswerProps = {
  chart: Chart
  setIsSaveDisabled: (isSaveDisabled: boolean) => void
  setStateCallback: (chart: Chart) => void
}

export default function SXzufiGetAnswer({
  chart,
  setIsSaveDisabled,
  setStateCallback,
}: SXzufiGetAnswerProps): React.ReactElement {
  const { classes } = useStyles()
  const { bot } = useBotContext()
  // only show toggle to add "normal" qna to answer search if bot has knowledge db and both modules set.
  const showAddQnAToggleFn = useCallback((): boolean => {
    if (bot === null) return false
    const modules = Object.values(bot.modules)
    return !!(
      typeof modules.find((module) => module.type === 'xzufi') !== 'undefined' &&
      typeof modules.find((module) => module.type === 'nlu') !== 'undefined'
    )
  }, [bot])

  // view
  const [currentView, setCurrentView] = useState<'xzufi' | 'qna'>('xzufi')

  // module select
  const [moduleOptions, setModuleOptions] = useState<Option[]>([])
  const [selectedModuleId, setSelectedModuleId] = useState<string>()
  const [addQnaToggleState, setAddQnaToggleState] = useState<boolean>()
  // variables select
  const [varIdLeistungen, setVarIdLeistungen] = useState<string>() // id of variable for storing the retrieved leistungen
  const [varIdNumberLeistungen, setVarIdNumberLeistungen] = useState<string>() // id of variable for storing the number of retrieved leistungen
  const [varIdCorrId, setVarIdCorrId] = useState<string>() // id of variable for storing the correlation id of the answer (for bot qna and xzufi)
  const [varIdQuery, setVarIdQuery] = useState<string>() // id of variable for storing the user query
  // qna answer
  const [varIdQnAAnswer, setVarIdQnAAnswer] = useState<string>() // id of variable for storing the retrieved answer
  const [varIdQnAActions, setVarIdQnAActions] = useState<string>() // id of variable for storing the actions of the retrieved answer (if any)
  const [varIdQnATriggerIntent, setVarIdQnATriggerIntent] = useState<string>() // id of variable for storing intent of the trigger answer

  /**
   * Reads all xzufi modules configured for this bot and prepares them for the dropdown.
   * If only one xzufi module exists, sets it.
   */
  function prepareModuleOptions(): void {
    if (!bot) return

    const xzufiModules = Object.values(bot.modules).filter((module) => module.type === MODULE_TYPE_XZUFI)
    const moduleOptions = xzufiModules.map((module) => {
      const { name, domain, moduleConfigId } = module as XzufiModule
      return {
        label: `${name} - ${domain}`,
        value: moduleConfigId,
      }
    })

    setModuleOptions(moduleOptions)
    if (moduleOptions.length === 1) {
      // only 1 possible module -> select it
      onModuleSelect({ label: '', value: moduleOptions[0].value })
    }
  }

  // ========= HANDLER ============

  function handleViewChange(event: any, newValue: string) {
    setCurrentView(newValue === 'qna' ? 'qna' : 'xzufi')
  }

  /**
   * Handles module selection
   * @param newValue
   */
  function onModuleSelect(newValue: Option): void {
    const selectedId = chart.selected?.id
    if (typeof selectedId === 'undefined') return
    const node = chart.nodes[selectedId]
    if (typeof node === 'undefined' || typeof node.properties.xzufi === 'undefined') return

    const moduleId = newValue.value

    node.properties.xzufi.xZufiModuleConfigId = moduleId

    // also set new text for display in dialog designer
    const module = bot?.modules[moduleId] as XzufiModule
    if (module) {
      node.properties.text = `${module.type} - ${module.name} - ${module.domain}`
    }
    if (module) setSelectedModuleId(moduleId)

    chart.nodes[selectedId] = node
    setStateCallback(chart)
  }

  /**
   * Handles add qna switch button.
   * If checked, adds a new outgoing port to the node for when a knowledge db answer is detected (= nlu-module found an answer).
   * If unchecked, removes the outgoing port for the knowledge db answer.
   * @param event
   * @param checked
   */
  function onAddQnAChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void {
    const selectedId = chart.selected?.id
    if (typeof selectedId === 'undefined') return
    const node = cloneDeep(chart.nodes[selectedId])
    if (typeof node === 'undefined') return

    // update state
    setAddQnaToggleState(checked)

    // configure port
    if (checked) {
      node.properties.text += `\n+ QnA Wissensdatenbank`

      // add ports
      node.ports = {
        port1: {
          id: 'port1',
          type: 'left',
          position: { x: 0, y: 0 },
          properties: {
            type: 'incoming',
          },
        },
        portXzufiResults: {
          id: 'portXzufiResults',
          type: 'right',
          position: { x: 0, y: 0 },
          properties: {
            type: 'outgoing',
            name: 'Leistungen gefunden',
          },
        },
        portQnAAnswer: {
          id: 'portQnAAnswer',
          type: 'right',
          position: { x: 0, y: 0 },
          properties: {
            type: 'outgoing',
            name: 'QnA Antwort gefunden',
          },
        },
        portQnADialogTrigger: {
          id: 'portQnADialogTrigger',
          type: 'right',
          position: { x: 0, y: 0 },
          properties: {
            type: 'outgoing',
            name: 'Dialog Trigger gefunden',
          },
        },
        portNoResults: {
          id: 'portNoResults',
          type: 'right',
          position: { x: 0, y: 0 },
          properties: {
            type: 'outgoing',
            name: 'Keine Ergebnisse',
          },
        },
      }
      node.properties.xzufi = {
        ...node.properties.xzufi,
        qnaModuleConfigId: 'fa83d136-406a-4525-b2d6-c3f75b02f434',
        addConvaiseNlu: true,
      }
    } else {
      // remove ports
      node.properties.text = node.properties.text?.split('\n')[0]
      delete node.ports['portQnAAnswer']
      delete node.ports['portQnADialogTrigger']
      node.properties.xzufi = {
        ...node.properties.xzufi,
        qnaModuleConfigId: undefined,
        addConvaiseNlu: false,
      }
    }
    chart.nodes[selectedId] = node
    setStateCallback(chart)
  }

  /**
   * Sets the variable in the node properties and updates the chart
   * @param selectedVarId
   * @param purpose
   */
  function onVariableSelect(newChart: Chart, purpose: VariableSelectionPurpose, selectedVarId?: string): void {
    const selectedId = newChart.selected?.id
    if (typeof selectedId === 'undefined') return
    const node = newChart.nodes[selectedId]
    if (typeof node === 'undefined') return

    if (!node.properties.xzufi) node.properties.xzufi = {}

    switch (purpose) {
      case VariableSelectionPurpose.SEARCH_RESULT:
        node.properties.xzufi.xZufiLeistungen = selectedVarId
        break
      case VariableSelectionPurpose.LENGTH_SEARCH_RESULT:
        node.properties.xzufi.xZufiLeistungenLength = selectedVarId
        break
      case VariableSelectionPurpose.CORRELATION_ID:
        node.properties.xzufi.corrId = selectedVarId
        break
      case VariableSelectionPurpose.USER_QUERY:
        node.properties.xzufi.xZufiQuery = selectedVarId
        break
      case VariableSelectionPurpose.QNA_ACTIONS:
        node.properties.xzufi.qnaActions = selectedVarId
        break
      case VariableSelectionPurpose.QNA_ANSWER:
        node.properties.xzufi.qnaAnswer = selectedVarId
        break
      case VariableSelectionPurpose.QNA_TRIGGERINTENT:
        node.properties.xzufi.qnaTriggerIntent = selectedVarId
        break
    }

    newChart.nodes[selectedId] = node
    setStateCallback(newChart)
  }

  /**
   * Handles variable selection for search result (found leistungen)
   * @param newChart
   * @param prevSelectedVarIds
   * @param selectedVarIds
   */
  function onVariableForSearchResultSelect(
    newChart: Chart,
    prevSelectedVarIds: string[],
    selectedVarIds: string[],
  ): void {
    const selectedVariableId = selectedVarIds.length > 0 ? selectedVarIds[0] : undefined
    onVariableSelect(newChart, VariableSelectionPurpose.SEARCH_RESULT, selectedVariableId)
  }

  /**
   * Handles variable selection for search result (found leistungen)
   * @param newChart
   * @param prevSelectedVarIds
   * @param selectedVarIds
   */
  function onVariableForSearchResultLengthSelect(
    newChart: Chart,
    prevSelectedVarIds: string[],
    selectedVarIds: string[],
  ): void {
    const selectedVariableId = selectedVarIds.length > 0 ? selectedVarIds[0] : undefined
    onVariableSelect(newChart, VariableSelectionPurpose.LENGTH_SEARCH_RESULT, selectedVariableId)
  }

  /**
   * Handles variable selection for correlation id
   * @param newChart
   * @param prevSelectedVarIds
   * @param selectedVarIds
   */
  function onVariableForCorrIdSelect(newChart: Chart, prevSelectedVarIds: string[], selectedVarIds: string[]): void {
    const selectedVariableId = selectedVarIds.length > 0 ? selectedVarIds[0] : undefined
    onVariableSelect(newChart, VariableSelectionPurpose.CORRELATION_ID, selectedVariableId)
  }

  /**
   * Handles variable selection for user query
   * @param newChart
   * @param prevSelectedVarIds
   * @param selectedVarIds
   */
  function onVariableForQuerySelect(newChart: Chart, prevSelectedVarIds: string[], selectedVarIds: string[]): void {
    const selectedVariableId = selectedVarIds.length > 0 ? selectedVarIds[0] : undefined
    onVariableSelect(newChart, VariableSelectionPurpose.USER_QUERY, selectedVariableId)
  }

  /**
   * Handles variable selection for qna answer
   * @param newChart
   * @param prevSelectedVarIds
   * @param selectedVarIds
   */
  function onVariableForQnAAnswerSelect(newChart: Chart, prevSelectedVarIds: string[], selectedVarIds: string[]): void {
    const selectedVariableId = selectedVarIds.length > 0 ? selectedVarIds[0] : undefined
    onVariableSelect(newChart, VariableSelectionPurpose.QNA_ANSWER, selectedVariableId)
  }

  /**
   * Handles variable selection for qna actions
   * @param newChart
   * @param prevSelectedVarIds
   * @param selectedVarIds
   */
  function onVariableForQnAActionsSelect(
    newChart: Chart,
    prevSelectedVarIds: string[],
    selectedVarIds: string[],
  ): void {
    const selectedVariableId = selectedVarIds.length > 0 ? selectedVarIds[0] : undefined
    onVariableSelect(newChart, VariableSelectionPurpose.QNA_ACTIONS, selectedVariableId)
  }

  /**
   * Handles variable selection for qna actions
   * @param newChart
   * @param prevSelectedVarIds
   * @param selectedVarIds
   */
  function onVariableForQnATriggerSelect(
    newChart: Chart,
    prevSelectedVarIds: string[],
    selectedVarIds: string[],
  ): void {
    const selectedVariableId = selectedVarIds.length > 0 ? selectedVarIds[0] : undefined
    onVariableSelect(newChart, VariableSelectionPurpose.QNA_TRIGGERINTENT, selectedVariableId)
  }

  // function onSelectEvent(event): void {
  //   const selectedId = chart.selected?.id
  //   if (typeof selectedId === 'undefined') return
  //   const node = chart.nodes[selectedId]
  //   if (typeof node === 'undefined') return
  //   // set event property with AnalyticsEvent value
  //   node.properties.event = event.value
  //   chart.nodes[selectedId] = node
  //   // setSelectedEvent(event)
  //   setStateCallback(chart)
  // }

  useEffect(
    function () {
      const selectedId = chart.selected?.id
      if (typeof selectedId === 'undefined') return
      // get already selected Event
      const node = chart.nodes[selectedId]

      prepareModuleOptions()
      setAddQnaToggleState(node.properties.xzufi?.addConvaiseNlu)
      setVarIdLeistungen(node.properties.xzufi?.xZufiLeistungen)
      setVarIdNumberLeistungen(node.properties.xzufi?.xZufiLeistungenLength)
      setVarIdCorrId(node.properties.xzufi?.corrId)
      setVarIdQuery(node.properties.xzufi?.xZufiQuery)
      setVarIdQnAAnswer(node.properties.xzufi?.qnaAnswer)
      setVarIdQnAActions(node.properties.xzufi?.qnaActions)
      setVarIdQnATriggerIntent(node.properties.xzufi?.qnaTriggerIntent)
    },
    [chart, bot],
  )

  useEffect(
    function () {
      const isXzufiComplete = selectedModuleId && varIdLeistungen && varIdCorrId && varIdNumberLeistungen && varIdQuery
      const isQnAComplete = addQnaToggleState
        ? varIdCorrId && varIdQuery && varIdQnAActions && varIdQnAAnswer && varIdQnATriggerIntent
        : true

      if (isXzufiComplete && isQnAComplete) {
        // xzufi is complete - check
        setIsSaveDisabled(false)
      } else {
        setIsSaveDisabled(true)
      }
    },
    [
      selectedModuleId,
      varIdLeistungen,
      varIdCorrId,
      varIdNumberLeistungen,
      varIdQuery,
      varIdQnAAnswer,
      varIdQnAActions,
      varIdQnATriggerIntent,
      addQnaToggleState,
    ],
  )

  const XzufiContent = (
    <div>
      <div className={classes.moduleSelection}>
        <div className={classes.inputWithInfo}>
          <Typography>XZuFi Modul</Typography>
          <CustomContentTooltip
            content={
              <Typography>
                Bitte wählen Sie das XZuFi Modul Ihres Assistenten, das nach einer passenden Leistung durchsucht werden
                soll.
              </Typography>
            }
            elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
          />
        </div>
        <SelectDropdown
          onChange={onModuleSelect}
          options={moduleOptions}
          selected={selectedModuleId}
          placeholder='XZuFi Modul auswählen'
          width='100%'
        />
      </div>
      {/* {showAddQnAToggleFn() && (
        <div className={classes.addQnAModuleContainer}>
          <FormGroup>
            <FormControlLabel
              label='Wissensdatenbank berücksichtigen'
              control={<Switch checked={addQnaToggleState} onChange={onAddQnAChange} />}
            />
          </FormGroup>
        </div>
      )} */}
      <div className={classes.variables}>
        <div className={classes.varSelectionContainer}>
          <div className={classes.inputWithInfo}>
            <Typography>Variable zum Laden der Nutzerfrage</Typography>
            <CustomContentTooltip
              content={
                <Typography>
                  Bite wählen sie eine Variable, in der die Anfrage des/der Nutzer*in gespeichert ist, die beantwortet
                  werden soll.
                </Typography>
              }
              elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
            />
          </div>
          <VariablesAutosuggestSelect
            chart={chart}
            onChange={onVariableForQuerySelect}
            usageType='set'
            isMulti={false}
            isCreatable
            isClearable
            selectedVariableIds={varIdQuery ? [varIdQuery] : []}
          />
        </div>
        <div className={classes.varSelectionContainer}>
          <div className={classes.inputWithInfo}>
            <Typography>Variable zum Speichern des Suchergebnisses</Typography>
            <CustomContentTooltip
              content={
                <Typography>
                  Bitte wählen Sie eine Variable, in der die gefundenen Leistungen gespeichert werden sollen.
                </Typography>
              }
              elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
            />
          </div>
          <VariablesAutosuggestSelect
            chart={chart}
            onChange={onVariableForSearchResultSelect}
            usageType='set'
            isMulti={false}
            isCreatable
            isClearable
            selectedVariableIds={varIdLeistungen ? [varIdLeistungen] : []}
          />
        </div>
        <div className={classes.varSelectionContainer}>
          <div className={classes.inputWithInfo}>
            <Typography>Variable zum Speichern der Anzahl an Suchergebnissen</Typography>
            <CustomContentTooltip
              content={
                <Typography>
                  Bitte wählen sie eine Variable, in der die Anzahl der gefundenen Leistungen gespeichert werden soll.
                </Typography>
              }
              elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
            />
          </div>
          <VariablesAutosuggestSelect
            chart={chart}
            onChange={onVariableForSearchResultLengthSelect}
            usageType='set'
            isMulti={false}
            isCreatable
            isClearable
            selectedVariableIds={varIdNumberLeistungen ? [varIdNumberLeistungen] : []}
          />
        </div>
        <div className={classes.varSelectionContainer}>
          <div className={classes.inputWithInfo}>
            <Typography>Variable zum Speichern der Feedback-ID</Typography>
            <CustomContentTooltip
              content={
                <Typography>
                  Bitte wählen Sie eine Variable, in der die Feedback-ID der Suchanfrage gespeichert werden soll. Die
                  Feedback-ID wird verwendet, um bei der Bewertung der Suchergebnisse die korrekte Zuordnung zu der
                  Suche zu ermöglichen.
                </Typography>
              }
              elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
            />
          </div>
          <VariablesAutosuggestSelect
            chart={chart}
            onChange={onVariableForCorrIdSelect}
            usageType='set'
            isMulti={false}
            isCreatable
            isClearable
            selectedVariableIds={varIdCorrId ? [varIdCorrId] : []}
          />
        </div>
      </div>
    </div>
  )

  const QnAContent = (
    <div>
      {showAddQnAToggleFn() && (
        <div className={classes.addQnAModuleContainer}>
          <FormGroup>
            <FormControlLabel
              label='Wissensdatenbank berücksichtigen'
              control={<Switch checked={!!addQnaToggleState} onChange={onAddQnAChange} />}
            />
          </FormGroup>
        </div>
      )}
      {addQnaToggleState ? (
        <div>
          <div className={classes.varSelectionContainer}>
            <div className={classes.inputWithInfo}>
              <Typography>Variable zum Speichern des Antworttextes</Typography>
              <CustomContentTooltip
                content={
                  <Typography>
                    Bitte wählen Sie eine Variable, in der die gegebene Antwort gespeichert werden sollen.
                  </Typography>
                }
                elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
              />
            </div>
            <VariablesAutosuggestSelect
              chart={chart}
              onChange={onVariableForQnAAnswerSelect}
              usageType='set'
              isMulti={false}
              isCreatable
              isClearable
              selectedVariableIds={varIdQnAAnswer ? [varIdQnAAnswer] : []}
            />
          </div>
          <div className={classes.varSelectionContainer}>
            <div className={classes.inputWithInfo}>
              <Typography>Variable zum Speichern der Folgefragen der gegebenen Antwort</Typography>
              <CustomContentTooltip
                content={
                  <Typography>
                    Bitte wählen sie eine Variable, in der die Folgefragen der gegebenen Antwort gespeichert werden
                    sollen.
                  </Typography>
                }
                elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
              />
            </div>
            <VariablesAutosuggestSelect
              chart={chart}
              onChange={onVariableForQnAActionsSelect}
              usageType='set'
              isMulti={false}
              isCreatable
              isClearable
              selectedVariableIds={varIdQnAActions ? [varIdQnAActions] : []}
            />
          </div>
          <div className={classes.varSelectionContainer}>
            <div className={classes.inputWithInfo}>
              <Typography>Variable zum Speichern des gefundenen Trigger Dialogs</Typography>
              <CustomContentTooltip
                content={
                  <Typography>
                    Bitte wählen sie eine Variable, in der der zu triggernde Dialog gespeichert werden soll.
                  </Typography>
                }
                elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
              />
            </div>
            <VariablesAutosuggestSelect
              chart={chart}
              onChange={onVariableForQnATriggerSelect}
              usageType='set'
              isMulti={false}
              isCreatable
              isClearable
              selectedVariableIds={varIdQnATriggerIntent ? [varIdQnATriggerIntent] : []}
            />
          </div>
        </div>
      ) : null}
    </div>
  )

  return (
    <div className={classes.container}>
      <ContentContainer headerText='XZuFi Modul: Leistung suchen' descriptionText='' svg={xzufiSearchLeistung}>
        {showAddQnAToggleFn() ? (
          // show tabs for xzufi and qna
          <>
            <Tabs value={currentView} onChange={handleViewChange} aria-label='Viewtabs' centered>
              <Tab label='XZuFi' value='xzufi' />
              <Tab label='QnA' value='qna' />
            </Tabs>
            {currentView === 'xzufi' ? XzufiContent : QnAContent}
          </>
        ) : (
          // only show Xzufi config
          XzufiContent
        )}
      </ContentContainer>
    </div>
  )
}
