import { DynamicFeed } from '@mui/icons-material'
import React, { ReactElement } from 'react'
import EditorSidebarItem from '../editorSidebarItem'
import { FLOWDESIGNER_NODE_COLOR_LOGIC } from 'utils/constants'

export default function switchNode(): ReactElement {
  return (
    <EditorSidebarItem
      type='logic/switchCondition'
      ports={{
        port1: {
          id: 'port1',
          type: 'left',
        },
        port2: {
          id: 'port2',
          type: 'right',
          position: { x: 0, y: 0 },
          properties: {
            name: 'Default',
            type: 'outgoing',
          },
        },
      }}
      properties={{
        typeText: 'Switch',
        text: 'Nicht definiert',
        switchConditions: {
          variableIdToCheck: '',
          conditions: {},
        },
        dialog: '',
      }}
      Icon={DynamicFeed}
      color={FLOWDESIGNER_NODE_COLOR_LOGIC}
    />
  )
}
