import { ChromeReaderModeOutlined } from '@mui/icons-material'
import React, { ReactElement } from 'react'
import EditorSidebarItem from '../editorSidebarItem'
import { FLOWDESIGNER_NODE_COLOR_CARD } from 'utils/constants'

function adaptiveCard(): ReactElement {
  return (
    <EditorSidebarItem
      type='basic/adaptiveCard'
      ports={{
        port1: {
          id: 'port1',
          type: 'left',
          position: { x: 0, y: 0 },
          properties: {
            custom: 'incoming',
          },
        },
        port2: {
          id: 'port2',
          type: 'right',
          position: { x: 0, y: 0 },
          properties: {
            custom: 'outgoing',
          },
        },
      }}
      properties={{
        typeText: 'Smart Card',
        text: 'Karte wählen',
        stepbackAllowed: false,
        card: '',
        dialog: '',
      }}
      Icon={ChromeReaderModeOutlined}
      color={FLOWDESIGNER_NODE_COLOR_CARD}
    />
  )
}

export default adaptiveCard
