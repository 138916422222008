import React from 'react'
import ReactDOM from 'react-dom/client'
// import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import * as serviceWorker from './serviceWorker'
import { StudioNotificationContextProvider } from 'hooks/contexts/studio-notification-context'
import 'react-perfect-scrollbar/dist/css/styles.css'
import './assets/css/app.css'
import 'remixicon/fonts/remixicon.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <StudioNotificationContextProvider>
      <App />
    </StudioNotificationContextProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV === 'production') {
  console.log = (): void => {
    // overwrite for prod
  }
  console.debug = (): void => {
    // overwrite for prod
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
