import { AddCircleOutline } from '@mui/icons-material'
import React, { ReactElement } from 'react'
import EditorSidebarItem from '../editorSidebarItem'
import { FLOWDESIGNER_NODE_COLOR_LOGIC } from 'utils/constants'

export default function setVar(): ReactElement {
  return (
    <EditorSidebarItem
      type='logic/setVariables'
      ports={{
        port1: {
          id: 'port1',
          type: 'left',
          position: { x: 0, y: 0 },
          properties: {
            custom: 'incoming',
          },
        },
        port2: {
          id: 'port2',
          type: 'right',
          position: { x: 0, y: 0 },
          properties: {
            custom: 'outgoing',
          },
        },
      }}
      properties={{
        typeText: 'Variablen',
        text: 'Werte in Variablen setzen',
        varname: '',
        setVariables: {
          order: [],
          setVars: {},
        },
        dialog: '',
      }}
      Icon={AddCircleOutline}
      color={FLOWDESIGNER_NODE_COLOR_LOGIC}
    />
  )
}
