import React, {
  useState,
  useEffect,
  useRef,
  MouseEvent as ReactMouseEvent,
  DragEvent,
  useCallback,
  SetStateAction,
  Dispatch,
} from 'react'
import { useParams } from 'react-router'
import { Prompt } from 'hooks/routing/usePrompt'
import { v4 as uuid } from 'uuid'
import { isEqual, cloneDeep } from 'lodash'
import { makeStyles } from 'tss-react/mui'

import EditorControls from './EditorControls'
import SelectedDialog from './Editor/SelectedNodes/SelectedDialog'
import ItemSidebar from './EditorSidebar/ItemSidebar'
import EditorTopbar from './EditorTopbar/EditorTopbar'
import { FlowDesignerNode } from './Editor/Nodes'
import { MarkerDefinition, SmoothStepEdge, DraggingSmoothStepEdge } from './Editor/Links'

import { createNewNode, removeNodeFromChart, alignNodesOnGrid, selectDialog } from '../../utils/chartUtils'
import { saveFlow as saveFlowchartApi } from 'api/StudioBackend'

import { Chart, Link, NodeType } from '../../@types/Flowchart/types'
import { EdgeType } from '../../@types/Flowdesigner/types'
import { TranslationFile } from '../../@types/Translations/types'

import ReactFlow, {
  Connection,
  Edge,
  Node,
  MarkerType,
  getConnectedEdges,
  Background,
  BackgroundVariant,
  NodeRemoveChange,
  NodeChange,
  EdgeChange,
  ReactFlowInstance,
  Viewport,
  useNodesState,
  useEdgesState,
} from 'reactflow'
import { FlowdesignerNodeData } from '../../@types/Flowdesigner/types'
import {
  FLOWDESIGNER_DATA_STRING,
  FLOWDESIGNER_EDGE_COLOR,
  FLOWDESIGNER_GRID_SIZE,
  FLOWDESIGNER_NODE_WIDTH,
  FLOWDESIGNER_NODE_MIN_HEIGHT,
} from 'utils/constants'
import { validateChart } from 'utils/chartValidator'
import { LockState } from '../../@types/Locking/types'
import { useFlowdesignerContext } from 'hooks/contexts/flowdesigner-context'
import { useBotContext } from 'hooks/contexts/bot-context'
import Assistant from './TestAssistent/Assistent'
import { Theme } from '@mui/material'

type StyleProps = {
  sidebarOpen?: boolean
}

const useStyles = makeStyles<StyleProps>()((theme: Theme, props: StyleProps, classes: Record<string, string>) => ({
  message: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
  sidebar: {
    width: '300px',
    background: 'white',
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    overflowX: 'hidden',
    padding: theme.spacing(2),
    boxShadow:
      'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px',
    zIndex: 1,
  },
  button: {
    color: '#FFF',
    border: 'none',
    cursor: 'pointer',
    margin: '.3125rem 1px',
    padding: '12px 30px',
    position: 'relative',
    fontSize: '12px',
    minHeight: 'auto',
    minWidth: 'auto',
    maxWidth: '100px',
    textAlign: 'center',
    transition: 'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
    fontWeight: 400,
    textTransform: 'uppercase',
  },
  confirmButton: {
    backgroundColor: '#4caf05',
    '&:hover,&:focus': {
      color: '#FFF',
      backgroundColor: '#4caf05',
      boxShadow: '0 14px 26px -12px rgba(76, 175, 5, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px',
    },
    '&:disabled': {
      color: '#FFF',
      backgroundColor: '#96c574',
    },
  },
  deleteButton: {
    backgroundColor: '#f44336',
    '&:hover,&:focus': {
      color: '#FFF',
      backgroundColor: '#f44336',
      boxShadow: '0 14px 26px -12px rgba(244, 67, 54, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px',
    },
    '&:disabled': {
      color: '#FFF',
      backgroundColor: '#ff8a82',
    },
  },
  closeButton: {
    backgroundColor: '#3c4858',
    '&:hover,&:focus': {
      color: '#FFF',
      backgroundColor: '#3c4858',
      boxShadow: '0 14px 26px -12px rgba(60, 72, 88, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px',
    },
  },
  // Complete View (Editor + Sidebar)
  flowDesignerContainer: {
    display: 'flex',
    maxWidth: props?.sidebarOpen ? 'calc(100% - 260px)' : 'calc(100% - 92px)',
    width: props?.sidebarOpen ? 'calc(100% - 260px)' : 'calc(100% - 92px)',
    height: '100%',
    flexDirection: 'column',
    // flexDirection: 'row',
    // flex: 1,
    // maxWidth: '200vw', // max width of flow editor
    // maxHeight: 'calc(100vh - 70px)', // max height of flow editor
  },
  // Editor View
  editorOuterContainer: {
    // container of react-flow provider
    overflowX: 'hidden', // hidden to prevent horizontal scrollbar caused by show / hide assistant animation
    position: 'relative',
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
  },
  editorInnerContainer: {
    // container of react-flow
    height: '100%',
    flexGrow: 1,
  },
  edge: {
    stroke: '#7D7D7D',
    strokeWidth: 2,
    '&:hover': {
      background: 'red',
    },
  },
}))

const reactFlowStyles = {
  background: 'rgba(0,0,0,0.05)',
}

/**
 * Takes link object from flowchart object and builds and returns edge for the react-flow graph
 */
function buildEdgeElement(chart: Chart, link: Link): Edge {
  const fromPort = chart.nodes[link.from.nodeId].ports[link.from.portId]

  const element: Edge = {
    id: link.id,
    source: link.from.nodeId,
    sourceHandle: link.from.portId,
    target: link.to.nodeId,
    targetHandle: link.to.portId,
    markerEnd: MarkerType.ArrowClosed,
    type: 'SmoothStepEdge',
    data: {
      label: fromPort.properties?.name,
    },
  }
  return element
}

type FlowEditorProps = {
  chart: Chart
  translations: TranslationFile
  needTranslationsSaving: boolean
  resetNeedTranslationsSavingCallback: () => void
  onEditAdaptiveCard: (chart: Chart, cardId: string) => void
  onCreateSmartCard: (chart: Chart, cardId: string, callback: () => void) => Promise<void>
  setChartCallback: (chart: Chart, portChange?: boolean) => void
  lockState: LockState
  sidebarOpen?: boolean
  setTranslationsCallback: (translations: TranslationFile) => void
  setTranslationNeedSavingCallback: (needsSaving: boolean) => void
}

// node types to let react-flow know what nodes to expect
const nodeTypes: {
  [nodeType in NodeType]: typeof FlowDesignerNode
} = {
  start: FlowDesignerNode,
  'basic/adaptiveCard': FlowDesignerNode,
  'basic/api': FlowDesignerNode,
  'basic/message': FlowDesignerNode,
  'basic/pdf': FlowDesignerNode,
  'basic/question_button': FlowDesignerNode,
  'basic/question_free': FlowDesignerNode,
  'basic/picture': FlowDesignerNode,
  'basic/fileUpload': FlowDesignerNode,
  'logic/ifElse': FlowDesignerNode,
  'logic/switch': FlowDesignerNode,
  'logic/switchCondition': FlowDesignerNode,
  'logic/jump': FlowDesignerNode,
  'logic/loop': FlowDesignerNode,
  'logic/loopClose': FlowDesignerNode,
  'logic/setVar': FlowDesignerNode,
  'logic/setVariables': FlowDesignerNode,
  'logic/yesNo': FlowDesignerNode,
  'logic/startDialog': FlowDesignerNode,
  'trigger/intent': FlowDesignerNode,
  'trigger/event': FlowDesignerNode,
  'trigger/qna': FlowDesignerNode,
  'trigger/analytics': FlowDesignerNode,
  'logic/qna_answer': FlowDesignerNode,
  'basic/note': FlowDesignerNode,
  'module/xzufi-getAnswer': FlowDesignerNode,
  'module/xzufi-getAnswerNew': FlowDesignerNode,
  'module/xzufi-trackSelectedAnswer': FlowDesignerNode,
  'module/xzufi-trackHelpful': FlowDesignerNode,
  'module/qna-getAnswer': FlowDesignerNode,
  'module/qna-startTriggerDialog': FlowDesignerNode,
  'module/qna-trackHelpful': FlowDesignerNode,
  'module/aleph-alpha-getAnswer': FlowDesignerNode,
  'module/aleph-alpha-trackHelpful': FlowDesignerNode,
  'module/llm-task': FlowDesignerNode,
}

const edgeTypes: {
  [edgeType in EdgeType]: typeof SmoothStepEdge
} = {
  SmoothStepEdge: SmoothStepEdge,
}

export default function FlowEditor({
  chart: chartProps,
  translations,
  needTranslationsSaving,
  resetNeedTranslationsSavingCallback,
  onEditAdaptiveCard,
  onCreateSmartCard,
  setChartCallback,
  lockState: lockStateProps = 'isBlocked',
  sidebarOpen = false,
  setTranslationsCallback,
  setTranslationNeedSavingCallback,
}: FlowEditorProps): React.ReactElement {
  const { classes } = useStyles({ sidebarOpen })
  const { botId } = useParams() as { botId: string }
  const { bot, setBot } = useBotContext()
  const {
    searchNodeSelection,
    resetSearchNodeSelection,
    selectedDialogId,
    setSelectedDialogId,
    resetSelectedDialogId,
  } = useFlowdesignerContext()
  const editorContainerRef = useRef<HTMLDivElement>(null)

  const [lockState, setLockState] = useState<LockState>(lockStateProps)

  const chartRef = useRef(chartProps) // we keep chart only as ref, to prevent unneccessary re-renders
  const origChartRef = useRef(chartProps)
  const translationRef = useRef(translations)
  const translationNeedSavingRef = useRef(needTranslationsSaving)
  const [selectedNodeId, setSelectedNodeId] = useState<string>()

  // new react-flow
  const reactFlowContainerRef = useRef<HTMLDivElement | null>(null)
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance>()
  const [nodes, setNodes, applyNodeChanges] = useNodesState<Node[]>([]) as [
    Node[],
    Dispatch<SetStateAction<Node[]>>,
    (changes: NodeChange[], nodes: Node[]) => void,
  ]
  const [edges, setEdges, applyEdgeChanges] = useEdgesState<Edge[]>([])

  // interaction locking
  // this tracks whether interactions with the flow are possible (via the lock icon in the designer)
  const [isInteractionLocked, setIsInteractionLocked] = useState<boolean>(false)
  const isInteractionLockedPrevStateRef = useRef<boolean>(false)

  // usaved changes check
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false)

  // display smart cards in node
  const [displaySmartCards, setDisplaySmartCards] = useState<boolean>()

  // display assistant window
  const [showAssistent, setShowAssistent] = useState<boolean>(false)

  /**
   * Determines and returns offset of editor container (to account for left sidebar).
   */
  function getEditorOffset(): { x: number; y: number } {
    const el: HTMLDivElement | null = reactFlowContainerRef.current
    const editorOffset = { x: 0, y: 0 }
    if (el && el !== null) {
      const container = el.getBoundingClientRect()
      editorOffset.x = container.left
      editorOffset.y = container.top
    }
    return editorOffset
  }

  /**
   * Prepares react-flow elements from the chart object
   * @param chart
   * @param hasDialogChanged
   * @param forceRebuild
   */
  function buildElementsFromChart(chart: Chart, forceRebuild = false): { nodes: Node[]; edges: Edge[] } {
    // const nodeElementsInFlow: string[] = elements.filter((element) => isNode(element)).map((element) => element.id)
    const nodeElementsInFlow: string[] = nodes.map((node) => node.id)

    // find nodes and links that have changed
    // if we force a rebuild, every node is handled as if it changed
    const changedNodes = forceRebuild
      ? nodeElementsInFlow
      : Object.values(chart.nodes)
          .filter((newChartNode) => {
            const oldNode = chartRef.current.nodes[newChartNode.id]

            if (newChartNode.type === 'basic/adaptiveCard' && newChartNode.properties.card) {
              // we re-build all card nodes
              // this is to ensure that all changes made to the content of a card (not the node itself) are actually shown in the dialog designer
              // otherwise the node would show the old state
              return true
            }

            if (!isEqual(oldNode, newChartNode)) {
              // node in chart has changed
              return true
            }

            return false
          })
          .map((node) => node.id)

    // if dialog is set, only get nodes of that dialog, otherwise get all nodes
    const allNodeIds = chart.activeDialog ? chart.dialogs[chart.activeDialog].nodes : Object.keys(chart.nodes)
    // only build links that are in current dialog
    const allLinkIds = Object.keys(chart.links)
    const linkIdsToRender: string[] = []
    for (let i = 0; i < allLinkIds.length; i += 1) {
      if (
        allNodeIds.indexOf(chart.links[allLinkIds[i]].from.nodeId) !== -1 &&
        allNodeIds.indexOf(chart.links[allLinkIds[i]].to.nodeId) !== -1
      ) {
        // from and to node of link are in current dialog
        linkIdsToRender.push(allLinkIds[i])
      }
    }

    const newNodes: Node[] = []
    const newEdges: Edge[] = []

    // find newly added nodes (if there are any)
    // const handledSet = new Set(handledNodeIds)
    const newNodeIds = allNodeIds.filter((id) => !nodeElementsInFlow.includes(id))

    for (const nodeId of newNodeIds) {
      const node = chart.nodes[nodeId]
      // build a new element
      const newElement: Node<FlowdesignerNodeData> = {
        id: nodeId,
        type: node.type,
        position: { x: node.position.x, y: node.position.y },
        data: {
          translationFile: translationRef.current,
          chart,
          node,
          displayCard: displaySmartCards,
        },
      }
      newNodes.push(newElement)
    }

    for (const node of nodes) {
      let newNode
      {
        // element is node
        // update element
        if (changedNodes.includes(node.id)) {
          // node has changed
          newNode = cloneDeep(node) as Node<FlowdesignerNodeData>
          newNode.position = cloneDeep(chart.nodes[node.id].position)
          newNode.data.chart = chart
          newNode.data.node = cloneDeep(chart.nodes[node.id])
          newNode.data.translationFile = translationRef.current
          newNode.data.displayCard = displaySmartCards
        } else {
          newNode = node as Node<FlowdesignerNodeData>
        }

        // only add element if it exists in chart object
        if (newNode && allNodeIds.includes(newNode.id)) newNodes.push(newNode)
      }
    }
    // at this point we only have nodes in the elements array
    // we rebuild edges completely because it can happen, that links are removed in the chart (e.g. when deleting a node from )
    for (let i = 0; i < linkIdsToRender.length; i += 1) {
      const linkId = linkIdsToRender[i]
      const link = chart.links[linkId]
      const edge = buildEdgeElement(chart, link)
      newEdges.push(edge)
    }
    return { nodes: newNodes, edges: newEdges }
  }

  /**
   * Resets selected node.
   */
  function resetNodeSelect(): void {
    chartRef.current.selected.id = undefined
    setSelectedNodeId(undefined)
  }
  /**
   * =====================================================================
   * API: For saving minor version
   */

  /**
   * Performs silent/hidden (to the user) flowchart save. Creates a new minor version.
   * Fails silently. This is okay, because the user loses at most the last few changes.
   *
   * Sends the translation file with the flow if the changed node is a smart card node.
   * This is required because changes in the node might have changed the translations as well (e.g. renaming a card).
   *
   * Waits for the API response and updates botInfos in the context.
   * NOTE: We do not set the received chart in the state, as we usually do when saving!
   * This works as long as there are no functional changes to the chart made by the API!
   *
   * @param chart
   * @param savedNodeType id of node that has been saved
   */
  async function saveMinorFlowchartVersion(chart: Chart, nodeIdOfChangedNode: string): Promise<void> {
    try {
      const savedNodeType = chart.nodes[nodeIdOfChangedNode].type
      const response = await saveFlowchartApi(
        botId,
        chart,
        'minor',
        savedNodeType === 'basic/adaptiveCard' ? translations : undefined,
      )
      if (!response || response.data === null) throw new Error('Save chart API response is null.')
      const { botInfos } = response.data
      if (botInfos) {
        // update bot infos in context
        setBot(botInfos)
        console.info('[DialogDesigner] Saved minor version of chart.')
      }
    } catch (err) {
      console.error('[DialogDesigner] Error saving minor version of chart.', err)
    }
  }

  /**
   * =====================================================================
   * CALLBACKS FOR: SELECTED-NODE DIALOG, REACT-FLOW
   */

  // ============== FLOW CHART DATAFORMAT CALLBACKS =============
  // These are callbacks for making changes in the actual flow chart file (not just the displayed flow)

  /**
   * Deletes node from chart.
   * Sets updated chart via callback.
   * @param nodeId
   */
  function onNodeDelete(nodeId: string): void {
    const chart = chartRef.current
    const newChart = removeNodeFromChart(chart, nodeId, true, true)
    resetNodeSelect()
    setChartCallback(newChart)
  }

  /**
   * Handles close button click of selected node dialog.
   * Performs chart validation and sets validated chart.
   */
  function onCloseSelectedNodeDialog(): void {
    resetNodeSelect()
  }

  /**
   * Handles save button click of selected node dialog.
   * Removes unused datachecks and re-counts variable usage of node that was open.
   * Validates and sets new chart.
   * @param {Chart} chart new chart object
   * @param {string} nodeIdChanged id of changed node
   */
  function onSaveSelectedNodeDialog(chart: Chart, nodeIdChanged: string): void {
    resetNodeSelect()

    // validate chart to display error if node is incompletely configured
    const validatedChart = validateChart(chart, translationRef.current, botId)

    // we need to "clone" the changed element in the element list to trigger insteand re-render of that node in chart
    // alternative would be to deep clone the elements, but I think just cloning the element is faster
    const index = nodes.findIndex((node) => node.id === nodeIdChanged)
    const newNodes = [...nodes]
    newNodes[index] = cloneDeep(newNodes[index])
    setNodes(newNodes)

    // perform silent minor save, no waiting
    saveMinorFlowchartVersion(validatedChart, nodeIdChanged)

    setChartCallback(validatedChart)
  }

  // ============== REACT-FLOW CALLBACKS ===============
  // These are callbacks for the react-flow (which visualizes the flow)

  /**
   * On load callback
   * @param _reactFlowInstance
   */
  function onLoad(_reactFlowInstance: ReactFlowInstance): void {
    const flowPosition = chartRef.current.offset
    _reactFlowInstance.setViewport({ x: flowPosition.x, y: flowPosition.y, zoom: chartRef.current.zoom || 1 })
    // _reactFlowInstance.setTransform({ x: flowPosition.x, y: flowPosition.y, zoom: chartRef.current.zoom || 1 })
    setReactFlowInstance(_reactFlowInstance)
  }

  /**
   * Handles creation of new node through drag and drop onto the flow editor.
   * Builds node for chart, updates chart via callback and adds new element to state.
   * @param event
   */
  function onNodeCreate(event: DragEvent): void {
    event.preventDefault()
    if (lockState !== 'canEdit') {
      // do nothing if editing is locked
      return
    }

    if (reactFlowInstance) {
      const dataString = event.dataTransfer.getData(FLOWDESIGNER_DATA_STRING)
      let data
      try {
        data = JSON.parse(dataString)
      } catch (err) {
        console.error('Could not create new node. ', err)
        return
      }

      const editorOffset = getEditorOffset()
      const { type, ports, properties } = data
      const position = reactFlowInstance.project({
        x: event.clientX - editorOffset.x,
        y: event.clientY - editorOffset.y,
      })

      // create and add new node object to chart
      const { chart } = createNewNode(chartRef.current, type, ports, properties, position)

      setChartCallback(chart)
    }
  }

  function onDragOver(event: DragEvent): void {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'
  }

  /**
   * Handles node drag (position change).
   * Sets new position in chart object.
   */
  function onNodeDragStop(event: React.MouseEvent<Element, MouseEvent>, node: Node<FlowdesignerNodeData>): void {
    const chart = chartRef.current
    chart.nodes[node.id].position = node.position
    setChartCallback(chart)
  }

  function setViewportInChart(viewport: Viewport): void {
    const chart = chartRef.current
    // update offset (used for current viewport)
    chart.offset = {
      x: viewport.x,
      y: viewport.y,
    }
    chart.zoom = viewport.zoom
    // update offset for dialog
    if (selectedDialogId)
      chart.dialogs[selectedDialogId].offset = {
        ...chart.offset,
        zoom: chart.zoom,
      }
    setChartCallback(chart)
  }

  /**
   * Handles drag of pane.
   * Sets new offset in chart object.
   * @param viewport
   */
  function onMovePaneEnd(event: MouseEvent | TouchEvent, viewport: Viewport): void {
    if (!viewport) return
    if (reactFlowInstance) reactFlowInstance.setViewport(viewport)
    setViewportInChart(viewport)
  }

  /**
   * Callback function for connecting two nodes.
   * @param connection
   */
  function onConnect(connection: Connection | Edge): void {
    const chart = chartRef.current
    const { source, sourceHandle, target, targetHandle } = connection

    if (source && sourceHandle && target && targetHandle) {
      // check if connection goes from source to target port
      if (
        chart.nodes[source]?.ports[sourceHandle]?.type !== 'right' ||
        chart.nodes[target]?.ports[targetHandle]?.type !== 'left'
      )
        return

      // check if source port already has a connection
      // this also prevents creation if connection already exists
      for (const link of Object.values(chart.links)) {
        if (
          link.from.nodeId === source &&
          // link.to.nodeId === target &&
          link.from.portId === sourceHandle
          // link.to.portId === targetHandle
        ) {
          return
        }
      }

      // create new link in chart and set chart in parent component
      const newLinkId = uuid()
      // new link object for flowchart
      const newLinkObj: Link = {
        id: newLinkId,
        from: {
          nodeId: source,
          portId: sourceHandle,
        },
        to: {
          nodeId: target,
          portId: targetHandle,
        },
      }
      chart.links[newLinkId] = newLinkObj
      // new edge for flow graph
      const edge = buildEdgeElement(chart, newLinkObj)
      setEdges([...edges, edge])
      setChartCallback(chart)
    }
  }

  /**
   * Callback function for single clicking an element (node or link)
   * @param event
   * @param element
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function onElementClick(event: React.MouseEvent<Element, MouseEvent>, element: Node<any> | Edge<any>): void {
    // not implemented
  }

  /**
   * Callback function for double clicking a node.
   * "Opens" a node by settings the node's id in state to display the selected node dialog.
   * @param event
   * @param node
   */
  function onNodeDoubleClick(event: ReactMouseEvent, node: Node): void {
    chartRef.current.selected.id = node.id
    setSelectedNodeId(node.id)
  }

  const handleNodeRemovals = useCallback((changes: NodeRemoveChange[], chart: Chart): Chart => {
    const nodesToRemove: string[] = []
    for (const change of changes) {
      if (change.type === 'remove' && chart.nodes[change.id].type !== 'start') {
        nodesToRemove.push(change.id)
        // remove node and links from chart
        chart = removeNodeFromChart(chart, change.id, true, true)
      }
    }

    if (nodesToRemove.length > 0) {
      // delete links connected to deleted node
      const connectedEdgesIds = getConnectedEdges(
        nodes.filter((node) => nodesToRemove.includes(node.id)),
        edges,
      ).map((edge) => edge.id)

      // TODO check if we need to update the state here; we do a setChartCallback and rebuild the elements in the useEffect anyway?
      // remove edges
      setEdges((edges) => edges.filter((edge) => !connectedEdgesIds.includes(edge.id)))
      // remove node
      setNodes((nodes) => nodes.filter((node) => !nodesToRemove.includes(node.id)))
    }
    return chart
  }, [])

  // Handles node change event
  // NOTE: scroll / zoom events are also NodeChanges
  const onNodesChange = useCallback(
    (changes: NodeChange[]): void => {
      const origChart = cloneDeep(chartRef.current)
      let chart = cloneDeep(origChart)

      const generalChanges: NodeChange[] = []

      const removeChanges: NodeRemoveChange[] = []
      for (const change of changes) {
        switch (change.type) {
          case 'remove': {
            if (change.id !== selectedNodeId) {
              // only remove if not currently selected and opened
              removeChanges.push(change)
            }
            break
          }
          default:
            generalChanges.push(change)
        }
      }

      if (removeChanges.length > 0) {
        chart = handleNodeRemovals(removeChanges, chart)
      }

      if (generalChanges.length > 0) {
        applyNodeChanges(changes, nodes)
      }

      if (!isEqual(origChart, chart)) setChartCallback(chart)
    },
    [nodes, edges],
  )

  // hanldes edge change event
  const onEdgesChange = useCallback(
    (changes: EdgeChange[]): void => {
      const origChart = cloneDeep(chartRef.current)
      const chart = cloneDeep(origChart)
      const edgeIds = edges.map((edge) => edge.id)

      const edgeIdsToRemove: string[] = []
      for (const change of changes) {
        if (change.type === 'remove') {
          if (edgeIds.includes(change.id)) {
            // delete link from chart
            delete chart.links[change.id]
            edgeIdsToRemove.push(change.id)
          }
        }
      }

      applyEdgeChanges(changes)

      // TODO check if we need to update the state here; we do a setChartCallback and rebuild the elements in the useEffect anyway?
      // setEdges((edges) => edges.filter((edge) => !edgeIdsToRemove.includes(edge.id)))

      if (!isEqual(origChart, chart)) setChartCallback(chart)
    },
    [edges],
  )

  /**
   * Aligns nodes to grid.
   * This is required because in earlier versions nodes could be completely freely positioned as we did not have a grid.
   * Those nodes respect the grid, but the allowed positions are calculated relative to the nodes position (basically own grid layout per node),
   * leading to missaligned nodes which renders the grid useless.
   * This fixes this by moving all nodes by a few pixel to the next position that lays on the grid, allowing to align them as expected.
   */
  function onAlignToGrid(): void {
    const adjustedChart = alignNodesOnGrid(chartRef.current)
    setChartCallback(adjustedChart)
  }

  /**
   * Fits chart to current view.
   * Zooms out until all nodes are visible.
   */
  function fitView(): void {
    reactFlowInstance?.fitView()
    const viewPort = reactFlowInstance?.getViewport()
    if (viewPort) setViewportInChart(viewPort)
  }

  /**
   * Handles zoom in click.
   */
  function onZoomIn(): void {
    if (reactFlowInstance) reactFlowInstance.zoomIn()
  }

  /**
   * Handles zoom out click.
   */
  function onZoomOut(): void {
    if (reactFlowInstance) reactFlowInstance.zoomOut()
  }

  /**
   * Moves view onto node and centers node in view.
   * Returns new offset (view) that centers node.
   * @param nodeId
   */
  function moveViewOntoNode(nodeId: string): Viewport {
    const chart = chartRef.current
    const node = chart.nodes[nodeId]

    const dimens = reactFlowContainerRef.current?.getBoundingClientRect()

    const offset: Viewport = {
      // offset.x is basically the "space left of a node" (for a node with (0,0) that means half of the flowdesigner container width (positive!) if that node should be centered)
      x: node.position.x * -1 + (dimens?.width || 1860) / 2 - FLOWDESIGNER_NODE_WIDTH / 2,
      y: node.position.y * -1 + (dimens?.height || 900) / 2 - FLOWDESIGNER_NODE_MIN_HEIGHT / 2,
      zoom: 1,
    }

    return offset
  }

  function onDisplaySmartCardsChange(): void {
    setDisplaySmartCards(!displaySmartCards)
  }

  function onDialogSelection(dialogId: string): void {
    const newChart = selectDialog(chartRef.current, dialogId)
    setChartCallback(newChart)
    setSelectedDialogId(dialogId)
    // const targetOffset = { ...newChart.dialogs[dialogId]?.offset, zoom: 1 }
    // if (reactFlowInstance) reactFlowInstance.setViewport(targetOffset)
  }

  function onToggleDisplayAssistant(show: boolean): void {
    setShowAssistent(show)
  }

  // Checks if the origChartRef and chartRef have equal nodes and links to warn users if that is not the case
  function checkUnsavedChanges(): void {
    if (
      !isEqual(chartRef.current.nodes, origChartRef.current.nodes) ||
      !isEqual(chartRef.current.links, origChartRef.current.links)
    ) {
      setUnsavedChanges(true)
    } else {
      setUnsavedChanges(false)
    }
  }

  useEffect(
    function () {
      setLockState(lockStateProps)
      if (lockStateProps === 'isBlocked') {
        // save before interaction lock state to set it back to that, once the lock state allows edit
        // this prevents accidentially enabling interactions, even though interaction was manually locked by the user
        isInteractionLockedPrevStateRef.current = isInteractionLocked
        setIsInteractionLocked(true)
      } else if (lockStateProps === 'canEdit') {
        if (!isInteractionLockedPrevStateRef.current) {
          // interaction was not locked previously, hence we re-enable it
          setIsInteractionLocked(false)
        }
      }
    },
    [lockStateProps],
  )

  // useEffect(function () {
  //   const newElements = buildElementsFromChart(chartProps)
  //   setElements(newElements)
  // }, [])

  useEffect(
    function () {
      const { nodes, edges } = buildElementsFromChart(chartProps)
      // setElements(newElements)
      setNodes(nodes)
      setEdges(edges)
      chartRef.current = chartProps
    },
    [chartProps],
  )

  useEffect(
    // we need to keep reactFlowInstance in dependency array because it can happen, that it is undefined (still being initialized)
    // causing us to have to re-run this useEffect in order to be able to run fitView.
    function () {
      if (!reactFlowInstance) return

      if (selectedDialogId && (nodes.length > 0 || edges.length > 0)) {
        // reason for new elements: dialog has been changed
        resetSelectedDialogId()
        reactFlowInstance.fitView()
      } else if (searchNodeSelection && (nodes.length > 0 || edges.length > 0)) {
        // reason for new elements: user has searched for and clicked on node
        // move onto view
        const targetOffset = moveViewOntoNode(searchNodeSelection.nodeId)
        resetSearchNodeSelection()
        reactFlowInstance.setViewport(targetOffset)
      } else {
        // no specific reason; move view to offset
        // IDEA: we could check here if there are any nodes visible with the offset and if not fit the view
        // logic for check if nodes are visible already exists. WE NEED TO BE CAREFUL TO NOT FITVIEW IF USER e.g.
        // ZOOMS IN AND NO NODES ARE VISIBLE AS THIS LEADS TO UNWANTED BEHAVIOUR (because of this we removed this feature for now)
        // const viewDimens = reactFlowContainerRef.current?.getBoundingClientRect() || { width: 1850, height: 950 }
        // const nodesInView = findNodesInView(chartRef.current, viewDimens)

        reactFlowInstance.setViewport({
          x: chartRef.current.offset.x,
          y: chartRef.current.offset.y,
          zoom: chartRef.current.zoom || 1,
        })
      }
    },
    [reactFlowInstance],
  )

  useEffect(
    function () {
      translationRef.current = translations
    },
    [translations],
  )

  useEffect(
    function () {
      translationNeedSavingRef.current = needTranslationsSaving
    },
    [needTranslationsSaving],
  )

  useEffect(
    function () {
      if (typeof displaySmartCards !== 'undefined') {
        // rebuild all elements if display cards is toggled
        const { nodes, edges } = buildElementsFromChart(chartProps, true)
        // setElements(newElements)
        setNodes(nodes)
        setEdges(edges)
      }
    },
    [displaySmartCards],
  )

  useEffect(
    function () {
      // set view to correct offset of specific dialog
      if (reactFlowInstance && selectedDialogId) {
        const dialogOffset = chartRef.current.dialogs[selectedDialogId].offset
        reactFlowInstance.setViewport({ ...dialogOffset, zoom: dialogOffset?.zoom ?? 1 })
      }
    },
    [selectedDialogId],
  )

  return (
    <div ref={editorContainerRef} className={classes.flowDesignerContainer}>
      <EditorTopbar
        lockState={lockState}
        chart={chartRef.current}
        translationFile={translationRef.current}
        reactFlowInstance={reactFlowInstance}
        saveTranslations={translationNeedSavingRef.current}
        resetSaveTranslationsCallback={resetNeedTranslationsSavingCallback}
        setStateCallback={setChartCallback}
        onDialogSelection={onDialogSelection}
        setOrigChartRefCallback={(chart: Chart): void => {
          origChartRef.current = cloneDeep(chart)
          checkUnsavedChanges() // to trigger a rerender for the prompt to be loaded with the new origChartRef
        }}
        displayAssistantToTest={showAssistent}
        onToggleDisplayAssistant={onToggleDisplayAssistant}
      />

      <div className={classes.editorOuterContainer}>
        <div ref={reactFlowContainerRef} className={classes.editorInnerContainer}>
          <ReactFlow
            onInit={onLoad}
            style={reactFlowStyles}
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            connectionLineComponent={DraggingSmoothStepEdge}
            onlyRenderVisibleElements
            onMoveEnd={onMovePaneEnd}
            onConnect={onConnect}
            onNodeDoubleClick={onNodeDoubleClick}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onDragOver={onDragOver}
            onDrop={onNodeCreate}
            onNodeDragStop={onNodeDragStop}
            // multiSelectionKeyCode={'17'}
            minZoom={0.2}
            snapToGrid
            snapGrid={[FLOWDESIGNER_GRID_SIZE, FLOWDESIGNER_GRID_SIZE]}
            // interaction locking
            nodesConnectable={!isInteractionLocked}
            elementsSelectable={!isInteractionLocked}
            nodesDraggable={!isInteractionLocked}
          >
            <Background variant={BackgroundVariant.Dots} gap={FLOWDESIGNER_GRID_SIZE} />
            {/* customer arrow heads for hover and normal state */}
            <MarkerDefinition id='edge-marker-large' color={FLOWDESIGNER_EDGE_COLOR} strokeWidth={5} />
            <MarkerDefinition id='edge-marker' color={FLOWDESIGNER_EDGE_COLOR} strokeWidth={2} />
            {/* <div style={{ width: '100%', display: 'flex' }}> */}
            {/* Custom controls panel - custom to use custom icons for buttons */}
            <EditorControls
              onZoomIn={onZoomIn}
              onZoomOut={onZoomOut}
              onFitView={fitView}
              onAlignToGrid={onAlignToGrid}
              displaySmartCards={!!displaySmartCards}
              onDisplaySmartCardsChange={onDisplaySmartCardsChange}
            />
            {/* </div> */}
          </ReactFlow>
        </div>
        <ItemSidebar
          chart={chartRef.current}
          translationFile={translationRef.current}
          saveTranslations={translationNeedSavingRef.current}
          resetSaveTranslationsCallback={resetNeedTranslationsSavingCallback}
        />
        {showAssistent ? <Assistant onClose={(): void => setShowAssistent(false)} /> : null}
      </div>
      {typeof selectedNodeId !== 'undefined' ? (
        <SelectedDialog
          selectedNodeId={selectedNodeId}
          chart={chartRef.current}
          translations={translations}
          onCreateCardCallback={onCreateSmartCard}
          onEditCardCallback={onEditAdaptiveCard}
          onClose={onCloseSelectedNodeDialog}
          onSave={onSaveSelectedNodeDialog}
          onDelete={onNodeDelete}
          setTranslationsCallback={setTranslationsCallback}
          setTranslationNeedSavingCallback={setTranslationNeedSavingCallback}
        />
      ) : null}
      <Prompt
        when={unsavedChanges}
        message={`Es gibt ungespeicherte Änderungen. Wenn Sie diese Seite verlassen werden diese Änderungen verworfen. Sind Sie sicher, dass sie die Seiter verlassen wollen?`}
      />
    </div>
  )
}
