import React, { useState, useEffect } from 'react'
import { cloneDeep, omit, omitBy } from 'lodash'
import { v4 as uuid } from 'uuid'

import { makeStyles } from 'tss-react/mui'
import { TextField, IconButton, Tooltip, Fab, Typography, Grid } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'

import VariablesAutosuggestSelect, { OnChangeOptions } from '../Variables/VariablesAutosuggestSelect'

import {
  removeOutgoingPortFromNode,
  replaceVarIdWithDisplayName,
  replaceVarDisplayNameWithId,
} from '../../../../../utils/chartUtils'

import { Chart, DisplayVariableOption, Port, Ports, SwitchConditions } from '../../../../../@types/Flowchart/types'
import { Separator } from '../Separator'

const useStyles = makeStyles()((theme) => ({
  selectedDialogContainer: {},
  marginTopLarge: { marginTop: '25px' },
  marginTopMedium: { marginTop: '15px' },
  marginTopSmall: { marginTop: '10px' },
  marginRightSmall: { marginRight: '10px' },
  paddingBottom: { paddingBottom: theme.spacing(2) },
  centerContent: { textAlign: 'center' },
  conditionContainer: { marginTop: theme.spacing(4) },
  switchValueContainer: { marginBottom: theme.spacing(5), display: 'flex', marginLeft: theme.spacing(3) },
  variablePreCondition: {
    marginTop: 'auto',
    marginBottom: 'auto',
    paddingTop: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  switchValueText: { minWidth: 'fit-content', paddingRight: '10px', paddingTop: theme.spacing(4) },
  switchValueDeleteTooltip: { display: 'inline-block', textAlign: 'right' },
  iconButtonIcon: { fontSize: '1.5rem' },
  fabButton: { backgroundColor: '#4caf50', color: 'white' },
  removeButtonContainer: { marginTop: theme.spacing(2), display: 'flex' },
  removeButton: { marginLeft: 'auto', marginRight: 'auto' },
  addButtonContainer: { marginTop: theme.spacing(4) },
}))

function generateConditionId(): string {
  return uuid()
}

type SSwitchProps = {
  chart: Chart
  setStateCallback: (chart: Chart, portChange?: boolean) => void
  setIsSaveDisabledCallback: (disabled: boolean) => void
}

export default function SSwitch({
  chart,
  setStateCallback,
  setIsSaveDisabledCallback,
}: SSwitchProps): React.ReactElement {
  const { classes } = useStyles()

  const [description, setDescription] = useState<string>()
  const [varDisplayName, setVarDisplayName] = useState<string>()
  const [ports, setPorts] = useState<Ports>()
  const [conditions, setConditions] = useState<SwitchConditions['conditions']>()

  function getPortIdFromConditionIndex(index: number): string {
    return `port${index + 3}` // + 3 because  we want to number ports from 1, not 0 (+1), port1 is reserved for the incoming port (+1) and port2 is reserved for the default port (+1)
  }

  /**
   * Prepares chart representation of conditions with display representation.
   * Replaces all variable ids with their display value
   * @param conditions
   */
  function prepareConditionDisplayTexts(conditions: SwitchConditions['conditions']): SwitchConditions['conditions'] {
    const displayedSwitchConditions = cloneDeep(conditions)
    for (const key of Object.keys(conditions)) {
      displayedSwitchConditions[key].condition = replaceVarIdWithDisplayName(chart, conditions[key].condition)
    }
    return displayedSwitchConditions
  }

  /**
   * Increases size of selected node.
   * Sets node size to default size and then adds constant height for each additional port.
   * @param {Chart} chartInput
   */
  function adjustNodeSize(chartInput: Chart): Chart {
    const chart = chartInput
    if (typeof chart.selected.id === 'undefined') return chart
    const node = chart.nodes[chart.selected.id]
    if (typeof node.size === 'undefined') return chart
    const amountOfAdditionalPorts = Object.keys(node.ports).length - 3
    node.size.height = 110
    if (amountOfAdditionalPorts > 0) {
      for (let i = 0; i < amountOfAdditionalPorts; i++) {
        node.size.height += 20
      }
    }
    return chart
  }

  /**
   * Checks whether switch value (= port name) is legal.
   * @param switchValue
   */
  function isSwitchValueLegal(switchValue: string): boolean {
    let isLegal = true
    if (typeof switchValue !== 'string') isLegal = false
    if (switchValue.length === 0) isLegal = false
    if (switchValue.trim() === '') isLegal = false

    if (!isLegal) {
      // setIsSaveDisabledCallback(true)
    }
    return isLegal
  }

  // ====== CALLBACKS & HANDLER

  /**
   * Handles description text change. Updates state.
   * @param event
   */
  function onDescriptionChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setDescription(event.target.value)
  }

  /**
   * Handles description textfield blur.
   * Sets new description in chart and sets chart using the callback.
   */
  function onDescriptionBlur(): void {
    const selectedId = chart.selected.id
    if (typeof selectedId === 'undefined') return
    chart.nodes[selectedId].properties.text = description
    setStateCallback(chart)
  }

  /**
   * Callback function for variable selection.
   * Variable is registered in the chart by the selection component.
   * This function deals with setting the variable under condition in the chart.
   *
   * @param {chart} cahrt
   * @param {string[]} variableIds list of ids of the selected variables
   */
  function onSetVariable(chart: Chart, variableIds: string[]): Chart {
    if (variableIds.length === 0 || typeof chart.selected.id === 'undefined') return chart

    const varId = variableIds[0]
    const selectedNode = chart.nodes[chart.selected.id]
    if (typeof selectedNode === 'undefined') return chart
    // set new var
    if (!selectedNode.properties.switchConditions) {
      // init conditions object if it does not exist
      selectedNode.properties.switchConditions = { variableIdToCheck: '', conditions: {} }
    }
    selectedNode.properties.switchConditions.variableIdToCheck = varId || ''
    chart.nodes[chart.selected.id] = selectedNode

    setVarDisplayName(chart.variables[varId]?.displayName)
    return chart
  }

  /**
   * Callback function for variable removal.
   * Removes variable from condition.
   *
   * @param {Chart} chart
   * @param {string} varId id of the removed variable
   * @param {OnChangeOptions} options
   */
  function onRemoveVariable(chart: Chart, varId: string, options: OnChangeOptions): Chart {
    if (typeof chart.selected.id === 'undefined' || typeof chart.nodes[chart.selected.id] === 'undefined') return chart

    const switchConditions = chart.nodes[chart.selected.id].properties.switchConditions
    if (switchConditions && switchConditions.variableIdToCheck === varId) {
      switchConditions.variableIdToCheck = ''
      chart.nodes[chart.selected.id].properties.switchConditions = switchConditions
    }
    setVarDisplayName(undefined)
    return chart
  }

  /**
   * Handles condition change
   */
  function onConditionChange(event: React.ChangeEvent<HTMLInputElement>, conditionId: string): void {
    if (typeof ports === 'undefined' || typeof conditions === 'undefined') return

    const newValue = event.target.value

    const newConditions = { ...conditions, [conditionId]: { ...conditions[conditionId], condition: newValue } }

    setConditions(newConditions)
  }

  /**
   * Handles label change of a condition
   * @param event
   * @param conditionId
   * @param conditionIndex
   */
  function onLabelChange(
    event: React.ChangeEvent<HTMLInputElement>,
    conditionId: string,
    conditionIndex: number,
  ): void {
    if (typeof ports === 'undefined' || typeof conditions === 'undefined') return

    const newValue = event.target.value

    const portId = getPortIdFromConditionIndex(conditionIndex)

    // update label in condition object and also update port name
    const newConditions = { ...conditions, [conditionId]: { ...conditions[conditionId], label: newValue } }

    const port = ports[portId]
    const newPort: Port = { ...port, properties: { ...port.properties, type: 'outgoing', name: newValue } }

    const newPorts = {
      ...ports,
      [portId]: newPort,
    }

    setPorts(newPorts)
    setConditions(newConditions)
  }

  /**
   * Handles condition textfield blur.
   * Puts temp ports and conditions from state into chart and sets chart using callback.
   * @param portId
   */
  function onConditionOrLabelBlur(conditionId: string, conditionIndex: number): void {
    let newChart = cloneDeep(chart)
    if (
      typeof newChart.selected.id === 'undefined' ||
      typeof ports === 'undefined' ||
      typeof conditions === 'undefined' ||
      !newChart.nodes[newChart.selected.id].properties.switchConditions
    )
      return
    const portId = getPortIdFromConditionIndex(conditionIndex)
    newChart.nodes[newChart.selected.id].ports[portId] = ports[portId]
    const switchConditions = newChart.nodes[newChart.selected.id].properties.switchConditions
    if (!switchConditions) return

    // replace variable display names in condition with their ids
    const conditionWithVarIds = cloneDeep(conditions[conditionId])
    conditionWithVarIds.condition = replaceVarDisplayNameWithId(newChart, conditionWithVarIds.condition)
    switchConditions.conditions[conditionId] = conditionWithVarIds

    newChart.nodes[newChart.selected.id].properties.switchConditions = switchConditions
    newChart = adjustNodeSize(newChart)
    setStateCallback(newChart, true)
  }

  /**
   * Handles addition of a new condition.
   */
  function onConditionAdd(): void {
    if (!chart.selected.id) return
    const switchConditions = chart.nodes[chart.selected.id].properties.switchConditions
    if (!switchConditions) return
    const conditionId = generateConditionId()

    const newPorts = cloneDeep(ports)
    const newConditions = cloneDeep(conditions)
    if (typeof newPorts === 'undefined' || typeof newConditions === 'undefined') return

    const nrExistingConditions = Object.keys(newConditions).length
    const newPortId = getPortIdFromConditionIndex(nrExistingConditions)
    const defaultLabel = `Fall ${nrExistingConditions + 1}`

    newPorts[newPortId] = {
      id: newPortId,
      type: 'right',
      properties: {
        type: 'outgoing',
        name: defaultLabel,
      },
    }

    newConditions[conditionId] = {
      label: defaultLabel,
      condition: '',
    }

    chart.nodes[chart.selected.id].ports = newPorts
    switchConditions.conditions = newConditions
    chart.nodes[chart.selected.id].properties.switchConditions = switchConditions

    setPorts(newPorts)
    setConditions(newConditions)
    setStateCallback(chart, true)
  }

  /**
   * Handles port deletion.
   * Removes port, updates state and sets updated chart using callback.
   * @param conditionid
   * @param conditionIndex
   */
  function onConditionDelete(conditionId: string, conditionIndex: number): void {
    if (
      !chart.selected.id ||
      !chart.nodes[chart.selected.id] ||
      !chart.nodes[chart.selected.id].properties.switchConditions
    )
      return

    const portId = getPortIdFromConditionIndex(conditionIndex)

    let newChart = chart
    let newPorts = ports
    if (conditions) {
      const { ports: _ports, chart: _newChart } = removeOutgoingPortFromNode(chart, portId)
      newChart = _newChart
      newPorts = _ports
    }

    const newConditions = { ...conditions }
    delete newConditions[conditionId]

    const switchConditions = newChart.nodes[chart.selected.id].properties.switchConditions
    if (!switchConditions) {
      return
    }
    switchConditions.conditions = newConditions
    newChart.nodes[chart.selected.id].properties.switchConditions = switchConditions

    setPorts(newPorts)
    setConditions(newConditions)
    setStateCallback(newChart, true)
  }

  /**
   * Callback function for variable selection component.
   * @param {Chart} chart
   * @param {string[]} prevSelectedVarIds
   * @param {string[]} selectedVarIds
   * @param {OnChangeOptions} options
   */
  function onVariableChange(
    chart: Chart,
    prevSelectedVarIds: string[],
    selectedVarIds: string[],
    options: OnChangeOptions,
  ): void {
    if (selectedVarIds.length >= prevSelectedVarIds.length) {
      // variable was selected
      chart = onSetVariable(chart, selectedVarIds)
    } else {
      // variable was removed
      // find removed varIds
      const removedVarIds = prevSelectedVarIds.filter((varId) => !selectedVarIds.includes(varId))
      for (const varid of removedVarIds) {
        chart = onRemoveVariable(chart, varid, { clear: options.clear })
      }
    }
    setStateCallback(chart)
  }

  useEffect(function () {
    if (typeof chart.selected.id === 'undefined') return

    // might be undefined - that's okay
    const description = chart.nodes[chart.selected.id].properties.text || ''

    // get clone of ports object to get a working version and not needing to always work on the chart for minor changes
    // chart is updated if changes were made
    const ports = cloneDeep(chart.nodes[chart.selected.id].ports)
    const conditions = cloneDeep(chart.nodes[chart.selected.id].properties.switchConditions?.conditions)
    const varId = cloneDeep(chart.nodes[chart.selected.id].properties.switchConditions?.variableIdToCheck)

    setDescription(description)
    setPorts(ports)
    if (varId) setVarDisplayName(chart.variables[varId]?.displayName)

    const conditionsWithVarDisplayNames = prepareConditionDisplayTexts(conditions ?? {})
    setConditions(conditionsWithVarDisplayNames)
  }, [])

  return (
    <div className={classes.selectedDialogContainer}>
      {/* Description */}
      <div>
        <Typography>Beschreibung dieser Switch Node</Typography>
        <TextField
          id='switch-description'
          label='Beschreibung'
          value={description}
          fullWidth
          onBlur={onDescriptionBlur}
          onChange={onDescriptionChange}
          margin='normal'
        />
      </div>
      {/* Variable Selection */}
      <div className={classes.marginTopLarge}>
        <Typography>Variable deren Wert verglichen werden soll.</Typography>
        <VariablesAutosuggestSelect chart={chart} onChange={onVariableChange} usageType='consume' isMulti={false} />
      </div>
      {/* Values to compare against */}
      <div className={classes.marginTopLarge}>
        <Typography>
          Vergleichswerte auf deren Basis entschieden wird, welcher ausgehende Pfad verfolgt wird.
        </Typography>
        {typeof conditions !== 'undefined' &&
          Object.keys(conditions).map((conditionId, index) => {
            return typeof conditionId !== 'undefined' && typeof conditions[conditionId] !== 'undefined' ? (
              <div className={classes.conditionContainer}>
                <Grid container>
                  <Grid item xs={1}>
                    <Typography className={classes.switchValueText} fontWeight='bold'>
                      Fall {index + 1}
                    </Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      id={'label-textfield-' + index}
                      label='Beschreibung (Portname)'
                      value={conditions[conditionId].label || ''}
                      fullWidth
                      margin='normal'
                      InputProps={{ autoComplete: 'off' }}
                      // helperText={                       }
                      error={!conditions[conditionId].label}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                        onLabelChange(event, conditionId, index)
                      }
                      onBlur={(): void => onConditionOrLabelBlur(conditionId, index)}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <div id={`delete-condition-${index}`} className={classes.removeButtonContainer}>
                      <Tooltip className={classes.switchValueDeleteTooltip} title='Bedingung entfernen' placement='top'>
                        <IconButton
                          aria-label='Delete'
                          onClick={(): void => onConditionDelete(conditionId, index)}
                          className={classes.removeButton}
                        >
                          <DeleteIcon className={classes.iconButtonIcon} />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </Grid>
                </Grid>
                <div className={classes.switchValueContainer} key={`condition-${index}`}>
                  <Typography className={classes.variablePreCondition}>{varDisplayName}</Typography>
                  <TextField
                    id={'comparison-textfield-' + index}
                    label='Bedingung'
                    value={conditions[conditionId].condition || ''}
                    fullWidth
                    margin='normal'
                    InputProps={{ autoComplete: 'off' }}
                    helperText={
                      !isSwitchValueLegal(conditions[conditionId].condition || '') && 'Bedingung darf nicht leer sein!'
                    }
                    error={!isSwitchValueLegal(conditions[conditionId].condition || '')}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                      onConditionChange(event, conditionId)
                    }}
                    onBlur={(): void => onConditionOrLabelBlur(conditionId, index)}
                  />
                </div>
              </div>
            ) : null
          })}
        {/* Add button */}
        <div
          className={
            classes.centerContent +
            // ' ' +
            // classes.marginTopLarge +
            ' ' +
            classes.paddingBottom +
            ' ' +
            classes.addButtonContainer
          }
        >
          <Fab
            className={classes.fabButton}
            variant='extended'
            color='inherit'
            aria-label='Neuer Vergleichswert'
            onClick={onConditionAdd}
          >
            <AddIcon className={classes.marginRightSmall} />
            Vergleichswert hinzufügen
          </Fab>
        </div>
      </div>
    </div>
  )
}
