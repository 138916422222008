import React, { ReactElement, useState, useEffect } from 'react'
import { makeStyles } from 'tss-react/mui'

import ToggleButton from './ToggleButton'

const useStyles = makeStyles()({
  container: {
    paddingLeft: '10px',
    paddingRight: '10px',
    // marginBottom: '2em',
  },
  buttonContainer: {
    textAlign: 'center',
  },
})

type ToggleButtonGroupProps = {
  onChange: (buttonName: 'simple' | 'advanced') => void
  initialState: 'simple' | 'advanced'
}

export default function ToggleButtonGroup({ onChange, initialState }: ToggleButtonGroupProps): ReactElement {
  const { classes } = useStyles()
  const [activeButton, setActiveButton] = useState<'simple' | 'advanced'>(initialState)

  function handleButtonClick(buttonName: 'simple' | 'advanced'): void {
    setActiveButton(buttonName)
    onChange(buttonName)
  }

  useEffect(
    function () {
      setActiveButton(initialState)
    },
    [initialState],
  )

  return (
    <div className={classes.container}>
      <div className={classes.buttonContainer}>
        <ToggleButton name={'simple'} disabled={activeButton === 'simple'} onClick={handleButtonClick} />
        <ToggleButton name={'advanced'} disabled={activeButton === 'advanced'} onClick={handleButtonClick} />
      </div>
    </div>
  )
}
