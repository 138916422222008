import { LANGUAGE_DICTIONARY_DE } from './constants'

/**
 * Returns true if the language can be translated by the backend
 * @param langId
 */
export function canBeTranslatedAutomatically(langId: string): boolean {
  // pretty simple solution
  switch (langId) {
    case 'de-leicht':
      return false
    default:
      return true
  }
}

/**
 * Checks if a language id is supported by Convaise. Returns true if supported.
 * @param langId Language ID
 */
export function isSupportedLangauge(langId: string): boolean {
  return Object.keys(LANGUAGE_DICTIONARY_DE).includes(langId)
}

/**
 * Function that maps a custom language code to a language code that can be automatically translated if possible
 *
 * For example `de-at` is Austrian-German, but for the translation service it is just German so `de`.
 *
 * If no mapping exists, the input lanugage is just returned.
 *
 * @param langId The language we want to check.
 * @returns
 */
export function mapLanguageCodeToAutomaticallyTranslateText(langId: string): string {
  switch (langId) {
    case 'de-at':
      return 'de'
    default:
      return langId
  }
}
