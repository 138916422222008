import React, { useEffect, useState, memo, useRef } from 'react'

//
import { makeStyles } from 'tss-react/mui'

import { useTheme } from '@mui/material/styles'
import { Grid, IconButton, TextField, Typography } from '@mui/material'
import { CustomNotification } from '../../../@types/CustomNotifications/types'
import { SelectDropdown, Option } from 'components/Dropdown'
import DatePicker from 'components/DatePicker/DatePicker'
import WeekdayPicker from 'components/DatePicker/WeekdaySelector'
import { getTimeString } from 'utils/dateUtils'
import { useLockingContext } from 'hooks/contexts/locking-context'
import { Button, FabButton } from 'components/Buttons'
import CustomizedTooltip from 'components/Tooltips/CustomContentTooltip'
import { Add, Delete } from '@mui/icons-material'
import { cloneDeep } from 'lodash'

const useStyles = makeStyles()((theme) => ({
  editor: {},
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  dateContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
  },
  timeContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  timeTextfield: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  timeContainerAdd: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(1),
  },
  icon: {
    fontSize: '1.5rem',
  },
}))

enum TurnusValue {
  Once = 'once',
  Daily = 'daily',
  Weekly = 'weekly',
}

function TimeTextfield({
  value,
  onChange,
  onBlur,
  label,
}: {
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur: () => void
  label: string
}): React.ReactElement {
  const { classes } = useStyles()
  const { lockState } = useLockingContext()

  return (
    <TextField
      // id={`time-from-${index}`}
      // key={`time-from-${index}`}
      style={{ width: '100%' }}
      label={label}
      type='time'
      // defaultValue='07:30'
      defaultValue={value}
      // className={classes.textField}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        step: 300, // 5 min
      }}
      onBlur={onBlur}
      onChange={onChange}
      className={classes.timeTextfield}
      disabled={lockState !== 'canEdit'}
    />
  )
}

type StepTurnusProps = {
  turnus?: CustomNotification['type']
  setTurnusCallback: (turnus: CustomNotification['type']) => void
  startDate?: Date
  endDate?: Date
  // startTime?: Date
  // endTime?: Date
  times?: CustomNotification['times']
  days?: number[]
  setStartDateCallback: (date: Date) => void
  setEndDateCallback: (date: Date) => void
  // setStartTimeCallback: (date: Date) => void
  // setEndTimeCallback: (date: Date) => void
  setTimesCallback: (times: CustomNotification['times']) => void
  setDaysCallback: (days: number[]) => void
}

export default function StepTurnus({
  turnus: _turnus,
  startDate: _startDate,
  endDate: _endDate,
  times: _times,
  // startTime: _startTime,
  // endTime: _endTime,
  days: _days,
  setStartDateCallback,
  // setStartTimeCallback,
  setEndDateCallback,
  setTimesCallback,
  // setEndTimeCallback,
  setDaysCallback,
  setTurnusCallback,
}: StepTurnusProps): React.ReactElement {
  const { classes } = useStyles()
  const theme = useTheme()
  const { lockState } = useLockingContext()
  const [turnus, setTurnus] = useState<CustomNotification['type'] | undefined>(_turnus)
  const [startDate, setStartDate] = useState<Date | null>(_startDate ?? null)
  const [endDate, setEndDate] = useState<Date | null>(_endDate ?? null)
  // const [startTime, setStartTime] = useState<Date>(_startTime ?? new Date(2000, 0, 1, 7, 30, 0, 0))
  // const [endTime, setEndTime] = useState<Date>(_endTime ?? new Date(2000, 0, 1, 12, 0, 0, 0))
  const [times, setTimes] = useState<CustomNotification['times']>([])
  // console.log('times: ', times)
  const [days, setDays] = useState<number[]>([]) // only if turnus is weekly

  const [endTimeError, setEndTimeError] = useState<{ index: number; error: string }>()

  const turnusOptions: Option[] = [
    {
      label: 'Einmalig',
      value: TurnusValue.Once,
    },
    {
      label: 'Täglich',
      value: TurnusValue.Daily,
    },
    {
      label: 'Wöchentlich',
      value: TurnusValue.Weekly,
    },
  ]

  /**
   * Handles start date selection.
   * If turnus is once, this also sets the enddate as we only have start date field in that case
   * @param date
   */
  function onSetStartDate(date: Date | null): void {
    if (!date) return

    date.setHours(0, 0, 0, 0)

    setStartDateCallback(date)
    // if (turnus === TurnusValue.Once) setEndDateCallback(date)
  }

  function onSetEndDate(date: Date | null): void {
    if (!date) return

    date.setHours(23, 59, 59, 999)
    setEndDateCallback(date)
  }

  /**
   * Adds new timespan to notification
   */
  function addNewTimespan(): void {
    const newTimespan = { startTime: new Date(2000, 0, 1, 9, 30, 0, 0), endTime: new Date(2000, 0, 1, 14, 0, 0, 0) }
    setTimesCallback([...times, newTimespan])
  }

  /**
   * Removes timespan
   * @param index
   */
  function removeTimespan(index: number): void {
    times.splice(index, 1)
    setTimesCallback([...times])
  }

  /**
   * Sets start time.
   * Checks if end time is after start time, else sets error.
   * Sets date, month and year to 1.1.2000 as we only care for the time (we cannot set this to 1970 as this is the lowest possible date, so we just use 2000.)
   * @param event
   */
  function onSetStartTime(event: React.ChangeEvent<HTMLInputElement>, index: number): void {
    const startTimeString = event.target.value
    const [shour, sminute] = startTimeString.split(':')
    if (!shour || !shour) return
    const hour = parseInt(shour)
    let minute = parseInt(sminute)
    if (hour === 23 && minute > 55) minute = 55 // ensure that maximum start time is 23:55

    const startTime = new Date(2000, 0, 1)
    startTime.setHours(hour, minute, 0, 0)

    if (times[index].endTime && startTime > times[index].endTime)
      setEndTimeError({ index: index, error: 'Endzeit muss nach der Startzeit liegen.' })
    else {
      if (endTimeError?.index === index) setEndTimeError(undefined)
    }
    times[index].startTime = startTime
    setTimes(times)
  }

  /**
   * Sets end time.
   * Checks if end time is after start time, else sets error.
   * Sets date, month and year to 1.1.2000 as we only care for the time (we cannot set this to 1970 as this is the lowest possible date, so we just use 2000.).
   * @param event
   */
  function onSetEndTime(event: React.ChangeEvent<HTMLInputElement>, index: number): void {
    const endTimeString = event.target.value
    const [hour, minute] = endTimeString.split(':')
    if (!hour || !minute) return
    const endTime = new Date(2000, 0, 1)
    endTime.setHours(parseInt(hour), parseInt(minute), 0, 0)

    if (times[index].startTime && times[index].startTime > endTime) {
      setEndTimeError({ index: index, error: 'Endzeit muss nach der Startzeit liegen.' })
    } else {
      if (endTimeError?.index === index) setEndTimeError(undefined)
      times[index].endTime = endTime
    }
    setTimes(times)
  }

  function onBlurTime(): void {
    setTimesCallback([...times])
  }

  function isAddTimespanButtonDisabled(): boolean {
    return !endDate || new Date() > endDate
  }

  useEffect(
    function () {
      setTurnus(_turnus)
    },
    [_turnus],
  )
  useEffect(
    function () {
      if (_startDate) setStartDate(_startDate)
    },
    [_startDate],
  )
  useEffect(
    function () {
      if (_endDate) setEndDate(_endDate)
    },
    [_endDate],
  )
  useEffect(
    function () {
      if (_times?.length && _times.length > 0) {
        // console.log('Set times: ', _times)
        const times = _times.map((time) => {
          return {
            startTime: new Date(
              time.startTime.getFullYear(),
              time.startTime.getMonth(),
              time.startTime.getDate(),
              // time.startTime.getUTCHours(),
              time.startTime.getHours(),
              time.startTime.getMinutes(),
              0,
              0,
            ),
            endTime: new Date(
              time.endTime.getFullYear(),
              time.endTime.getMonth(),
              time.endTime.getDate(),
              time.endTime.getHours(),
              time.endTime.getMinutes(),
              0,
              0,
            ),
          }
        })
        setTimes(times)
      } else if (times.length === 0) {
        // not initialized
        setTimesCallback([{ startTime: new Date(2000, 0, 1, 9, 30, 0, 0), endTime: new Date(2000, 0, 1, 14, 0, 0, 0) }])
      }
    },
    [_times],
  )
  useEffect(
    function () {
      if (_days) setDays(_days)
    },
    [_days],
  )

  return (
    <div className={classes.editor}>
      {/* we use 2 column grid */}
      <Grid container spacing={3}>
        <Grid item md={12} xs={12} className={classes.column}>
          {/* left column */}
          <Typography fontWeight='bold' mb={theme.spacing(1)}>
            Turnus
          </Typography>
          <SelectDropdown
            selected={turnus}
            options={turnusOptions}
            isDisabled={lockState !== 'canEdit'}
            onChange={(newValue: Option): void => setTurnusCallback(newValue.value)}
          />
          {turnus === TurnusValue.Weekly && (
            <>
              <Typography fontWeight='bold' mt={theme.spacing(2)} mb={theme.spacing(1)}>
                Wochentage
              </Typography>
              <WeekdayPicker selected={days} onSelect={setDaysCallback} disabled={lockState !== 'canEdit'} />
            </>
          )}
        </Grid>
        {turnus && (
          <Grid item md={12} xs={12} className={classes.column}>
            {/* right column */}
            <Typography fontWeight='bold' mb={theme.spacing(1)}>
              Zeitraum
            </Typography>
            <div className={classes.dateContainer}>
              <Typography mb={theme.spacing(1)}>
                {turnus !== TurnusValue.Once ? 'Start- und Enddatum' : 'Datum'}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <DatePicker
                    label={turnus !== TurnusValue.Once ? 'Startdatum' : 'Datum'}
                    placeholder={turnus !== TurnusValue.Once ? 'Startdatum' : 'Datum'}
                    value={startDate}
                    onSelect={onSetStartDate}
                    disabled={lockState !== 'canEdit'}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} width={'100%'}>
                  {turnus !== TurnusValue.Once && (
                    <DatePicker
                      label={'Enddatum'}
                      placeholder='Enddatum'
                      minDate={startDate ?? undefined}
                      value={endDate}
                      onSelect={onSetEndDate}
                      // margin={`${theme.spacing(2)} 0 0 0`}
                      disabled={lockState !== 'canEdit'}
                      fullWidth
                    />
                  )}
                </Grid>
              </Grid>
            </div>

            <div className={classes.timeContainer}>
              <Typography mb={theme.spacing(1)}>Tägliche Uhrzeit(en)</Typography>
              {times.map((timespan, index) => {
                return (
                  <div key={`time-${index}`} style={{ display: 'flex', width: '100%' }}>
                    <Grid container spacing={2}>
                      <Grid item xs={5.5}>
                        {/* <TextField
                          id={`time-from-${index}`}
                          key={`time-from-${index}`}
                          style={{ width: '100%' }}
                          label='von'
                          type='time'
                          // defaultValue='07:30'
                          value={getTimeString(timespan.startTime)}
                          // className={classes.textField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            step: 300, // 5 min
                          }}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => onSetStartTime(event, index)}
                          className={classes.timeTextfield}
                          disabled={lockState !== 'canEdit'}
                        /> */}
                        <TimeTextfield
                          value={getTimeString(timespan.startTime)}
                          onChange={(event) => onSetStartTime(event, index)}
                          onBlur={onBlurTime}
                          key={`time-from-${index}`}
                          label='von'
                        />
                      </Grid>
                      <Grid item xs={5.5}>
                        <TimeTextfield
                          value={getTimeString(timespan.endTime)}
                          onChange={(event) => onSetEndTime(event, index)}
                          onBlur={onBlurTime}
                          key={`time-to-${index}`}
                          label='bis'
                        />
                        {/* <TextField
                          id={`time-to-${index}`}
                          key={`time-to-${index}`}
                          style={{ width: '100%' }}
                          label='bis'
                          type='time'
                          // defaultValue='12:00'
                          // defaultValue={getTimeString(timespan.endTime)}
                          value={getTimeString(timespan.endTime)}
                          // className={classes.textField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            step: 300, // 5 min
                          }}
                          error={endTimeError?.index === index}
                          helperText={endTimeError?.index === index ? endTimeError?.error : undefined}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => onSetEndTime(event, index)}
                          className={classes.timeTextfield}
                          disabled={lockState !== 'canEdit'}
                        /> */}
                      </Grid>
                      <Grid item xs={1} style={{ display: 'flex' }}>
                        <IconButton
                          onClick={(event: React.MouseEvent<HTMLButtonElement>): void => removeTimespan(index)}
                          color='secondary'
                          style={{ marginTop: 'auto', marginBottom: 'auto', paddingTop: theme.spacing(2) }}
                          disabled={times.length === 1}
                        >
                          <Delete />
                        </IconButton>
                      </Grid>
                    </Grid>

                    <div></div>
                  </div>
                )
              })}
              <div className={classes.timeContainerAdd}>
                <CustomizedTooltip
                  content={
                    !isAddTimespanButtonDisabled() ? (
                      <Typography>Zeitraum hinzufügen</Typography>
                    ) : (
                      <Typography>
                        Abgelaufenen Notifications kann kein weiterer Zeitraum hinzugefügt werden.
                      </Typography>
                    )
                  }
                  disableInteractive
                  elements={
                    <div>
                      <Button onClick={addNewTimespan} aria-label='Add' disabled={isAddTimespanButtonDisabled()}>
                        Zeitraum hinzufügen
                      </Button>
                    </div>
                  }
                  placement='bottom'
                />
              </div>
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  )
}
