import React, { useState, useEffect } from 'react'
import { cloneDeep } from 'lodash'

import CreatableSelect, { ChangeValue, ActionType } from '../../../../../components/Dropdown/ReactSelectDropdown'

import { getAllVarsOfNode, reduceVariableUsageCount } from '../../../../../utils/chartUtils'

import { Chart, DisplayVariableOption } from '../../../../../@types/Flowchart/types'

/**
 * Prepares array of variables for displaying them in a select component.
 * I.e. creates array of objects that have value and label.
 * Label is used as display name (= display name of variable), value as underlying value (= id of variable)
 * @param {Chart} chart
 * @param {string[]} varIds
 */
function prepareVarsForSelect(chart: Chart, varIds: string[]): DisplayVariableOption[] {
  const vars: DisplayVariableOption[] = []
  varIds.forEach((id) => {
    vars.push({
      label: chart.variables[id].displayName,
      value: id,
    })
  })
  return vars
}

// /**
//  * Increases usage count of variable by 1.
//  * Because the variable is used in this datacheck, it is consumed -> we always increase the consume property!
//  * @param chart
//  * @param nodeId
//  * @param varId
//  */
// function increaseVariableUsageCount(chart: Chart, nodeId: string, varId: string): Chart {
//   if (typeof chart.nodes[nodeId] === 'undefined') return chart

//   // we can assume that the variable object exists in the node, otherwise the variable could not have been chosen
//   const nodeVariables = chart.nodes[nodeId].properties.variables
//   if (typeof nodeVariables !== 'undefined' && typeof nodeVariables[varId] !== 'undefined') {
//     // increment local (node) usage
//     nodeVariables[varId].consume.usageCount += 1

//     // increment global usage count
//     chart.variables[varId].usage[nodeId].consume.usageCount += 1
//     chart.variables[varId].usageCount += 1

//     chart.nodes[nodeId].properties.variables = nodeVariables
//   }
//   return chart
// }

type DataCheckVariableSelectProps = {
  chart: Chart
  dataCheckId: string
  setVariableCallback?: (variables: DisplayVariableOption[]) => void
  onChangeCallback: (chart: Chart, prevSelectedVarIds: string[], selectedVarIds: string[]) => void
}

export default function DataCheckVariableSelect({
  chart: origChart,
  dataCheckId,
  onChangeCallback,
}: DataCheckVariableSelectProps): React.ReactElement {
  const [localChart, setLocalChart] = useState<Chart>(origChart)
  const [allOptions, setAllOptions] = useState<DisplayVariableOption[]>([])
  const [selectedVars, setSelectedVars] = useState<DisplayVariableOption[]>([])

  /**
   * Finds and returns all variables of this node that are associated with the dataCheck with ID as in props
   */
  function getNodeVars(): DisplayVariableOption[] {
    const current = localChart.selected?.id
    if (typeof current === 'undefined') return []

    // find variables of this node that use this datacheck with id props.datacheckid
    const checkVars =
      localChart.datachecks[dataCheckId] && localChart.datachecks[dataCheckId].nodes[current]
        ? localChart.datachecks[dataCheckId].nodes[current]
        : []
    return prepareVarsForSelect(localChart, checkVars)
  }

  /**
   * Handles variable selection for existing variables.
   * Pushes selected variable (ID) and node - if not already present to the global datacheck object
   *
   * @param {String} varId - variable id
   *
   * TODO&IDEA:  this implementation currently assumes that a variable is part of only one datacheck.
   *             I.e. If the variable selection is removed, the variable is completely removed from the datacheck,
   *             even if it is used in another datacheck.
   *             By implementing a method comparable to usageCount for the datachecks themselves this could be solved.
   */
  function onSelect(origChart: Chart, selectedValues: DisplayVariableOption[]): Chart {
    const chart = cloneDeep(origChart)
    const selectedId = chart.selected?.id
    if (typeof selectedId === 'undefined') return chart

    // TODO we should be able to remove this once the check selection for a node is implemented because that selection handles this
    chart.datachecks[dataCheckId].nodes[selectedId] = chart.datachecks[dataCheckId].nodes[selectedId] || []

    // find newly selected variables
    const newValues = selectedValues.filter((value) => !selectedVars.some((v) => v.value === value.value))

    const nodeDatachecks = chart.nodes[selectedId]?.properties.datachecks
    if (typeof nodeDatachecks === 'undefined') return chart

    for (const newVariable of newValues) {
      const varId = newVariable.value
      // Add variable to datacheck
      if (!chart.datachecks[dataCheckId].nodes[selectedId].includes(varId))
        chart.datachecks[dataCheckId].nodes[selectedId].push(varId)

      // increase datacheck usage counts
      chart.datachecks[dataCheckId].usageCount += 1
      nodeDatachecks[dataCheckId].usageCountNode += 1

      // increase variable usageCount
      // chart = increaseVariableUsageCount(chart, selectedId, varId)
    }
    chart.nodes[selectedId].properties.datachecks = nodeDatachecks

    return chart
  }

  /**
   * Handles variable de-select.
   * Removes variable from datacheck variable list of node.
   * Removes variable from global datacheck object and deletes datacheck if it was the last variable of the last node.
   * @param {String} value - variable id
   */
  function onRemoveValue(origChart: Chart, varId: string): Chart {
    const chart = cloneDeep(origChart)
    const selectedId = chart.selected?.id
    if (typeof selectedId === 'undefined') return origChart

    // remove variable from global datacheck object
    chart.datachecks[dataCheckId].nodes[selectedId] = chart.datachecks[dataCheckId].nodes[selectedId].filter(
      (value) => {
        return varId !== value
      },
    )

    // reduce node usage count for this datacheck and this node
    const nodeDatachecks = chart.nodes[selectedId]?.properties.datachecks
    if (typeof nodeDatachecks === 'undefined') return chart
    nodeDatachecks[dataCheckId].usageCountNode -= 1

    // if usage count node is 0, remove node from datacheck
    if (nodeDatachecks[dataCheckId].usageCountNode < 1) delete chart.datachecks[dataCheckId].nodes[selectedId]

    // if usage count for datacheck is 0 on save, the datacheck is deleted completely
    chart.datachecks[dataCheckId].usageCount -= 1
    chart.nodes[selectedId].properties.datachecks = nodeDatachecks

    // decrease variable usage count
    // chart = reduceVariableUsageCount(chart, selectedId, varId, 'consume', 1)

    return chart
  }

  /**
   * Handles clear.
   * All variables' assiciations with the current datacheck removed.
   */
  function onClear(origChart: Chart): Chart {
    const vars = getNodeVars()
    let chart = cloneDeep(origChart)
    vars.forEach((variable) => {
      chart = onRemoveValue(chart, variable.value)
    })
    return chart
  }

  /**
   * Handles changes in the select field.
   * Change depends on ActionType:
   * ActionTypes =
   * | 'select-option'
   * | 'deselect-option'
   * | 'remove-value'
   * | 'pop-value'
   * | 'set-value'
   * | 'clear'
   * | 'create-option';
   *
   * Updates state after change has been completed.
   *
   * @param {ChangeValue} newValue
   * @param {ActionType} action
   */
  function onChange(newValue: ChangeValue, action: ActionType): void {
    const previouslySelectedVars = cloneDeep(selectedVars)
    let chart = cloneDeep(localChart)

    const newValues = newValue as DisplayVariableOption[]

    switch (action.action) {
      case 'select-option':
        // let selectValue = Array.isArray(newValue) ? newValue[newValue.length - 1].value : newValue.value
        // this.handleSelect(selectValue)
        if (newValues !== null) chart = onSelect(chart, newValues)
        break
      case 'remove-value': {
        const removedValues =
          newValue === null
            ? previouslySelectedVars
            : previouslySelectedVars.filter((option) => !newValues.some((o) => option.value === o.value))
        for (const removedValue of removedValues) {
          chart = onRemoveValue(chart, removedValue.value)
        }
        break
      }
      case 'clear':
        chart = onClear(chart)
        break
      default:
        break
    }

    const allNodeVarIds = getAllVarsOfNode(origChart, chart.selected.id)
    const allOptions = prepareVarsForSelect(origChart, allNodeVarIds)

    setAllOptions(allOptions)
    setSelectedVars(newValues)
    setLocalChart(chart)

    const prevSelectedVarIds = previouslySelectedVars.map((value) => value.value)
    const newSelectedVarIds = newValues === null ? [] : newValues.map((value) => value.value)
    onChangeCallback(chart, prevSelectedVarIds, newSelectedVarIds)
  }

  useEffect(
    function () {
      // find selected variables that should be displayed
      const vars = getNodeVars()
      const allNodeVarIds = getAllVarsOfNode(origChart)
      const allOptions = prepareVarsForSelect(origChart, allNodeVarIds)
      setAllOptions(allOptions)
      setSelectedVars(vars)
      setLocalChart({ ...origChart })
    },
    [origChart],
  )

  // ========== RENDER =============

  return (
    <CreatableSelect
      isMulti
      isCreatable={false}
      isClearable
      onChange={onChange}
      options={allOptions}
      selectedOptions={selectedVars}
      placeholder={'Variable(n)'}
      noOptionsMessage={'Keine Variablen'}
      createOptionText={(inputValue) => `Variable "${inputValue}" erstellen`}
    />
  )
}
