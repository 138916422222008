import React from 'react'
import { makeStyles } from 'tss-react/mui'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Grid } from '@mui/material'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import DraggableGridItem from './DraggableCardList/DraggableGridItem'
import DraggableCard, { DraggableCardItemsTypes } from './DraggableCardList/DraggableCard'
import Card from './Card'
import AddCard from './AddCard'

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
    height: '100%',
  },
}))

export type CardObject = {
  id: string
  title: string
  subtitle?: string
  text: string
  color?: string
}

export type CardListProps = {
  content: CardObject[]
  selectedIndex?: number // index of selected object
  onAdd?: () => void // callback function for adding new element to list
  onClick: (card: CardObject, index: number) => void // callback function for card click
  draggable?: boolean
  onDrop?: (id: string, newIndex: number) => void
}

export default function CardList({
  content,
  selectedIndex,
  onAdd,
  onClick,
  draggable,
  onDrop,
}: CardListProps): React.ReactElement {
  const { classes } = useStyles()

  function renderLargeCardList(): React.ReactElement {
    return (
      <PerfectScrollbar options={{ suppressScrollX: true, minScrollbarLength: 20 }}>
        <Grid container spacing={2}>
          {/* eslint-disable-next-line react/prop-types */}
          {content.map((obj, index) => {
            return (
              <Grid item key={obj.title}>
                <Card
                  title={obj.title}
                  subtitle={obj.subtitle}
                  text={obj.text}
                  selected={index === selectedIndex}
                  color={obj.color}
                  onClick={(): void => onClick(obj, index)}
                />
              </Grid>
            )
          })}
          {onAdd !== undefined && (
            <Grid item key={'add'}>
              <AddCard title={'Hinzufügen'} onClick={onAdd} />
            </Grid>
          )}
        </Grid>
      </PerfectScrollbar>
    )
  }

  function renderDraggableLargeCardList(): React.ReactElement {
    return (
      <PerfectScrollbar options={{ suppressScrollX: true, minScrollbarLength: 20 }}>
        <DndProvider backend={HTML5Backend}>
          <Grid container spacing={2}>
            {/* eslint-disable-next-line react/prop-types */}
            {content.map((obj, index) => {
              return onDrop ? (
                <Grid item key={obj.title}>
                  <DraggableGridItem
                    key={obj.title}
                    index={index}
                    acceptItemType={DraggableCardItemsTypes.card}
                    allowedDropEffect={'move'}
                    card={obj}
                    item={
                      <DraggableCard
                        card={{
                          title: obj.title,
                          subtitle: obj.subtitle,
                          text: obj.text,
                          selected: index === selectedIndex,
                          color: obj.color,
                          onClick: (): void => onClick(obj, index),
                        }}
                        id={obj.id}
                        onDrop={onDrop}
                      />
                    }
                  />
                </Grid>
              ) : null
            })}
          </Grid>
        </DndProvider>
      </PerfectScrollbar>
    )
  }

  return (
    <div className={classes.root}>{draggable && onDrop ? renderDraggableLargeCardList() : renderLargeCardList()}</div>
  )
}
