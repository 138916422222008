import React, { useEffect, useState } from 'react'
import { Check } from '@mui/icons-material'
import { makeStyles } from 'tss-react/mui'
import { Typography } from '@mui/material'

import { Dialog } from '../../../components/Dialogs'
import { Textfield } from '../../../components/TextInput/Textfield'
import { Button } from '../../../components/Buttons'

import { Chart } from '../../../@types/Flowchart/types'

const useStyles = makeStyles()((theme) => ({
  textfield: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  nameContainer: {},
  descContainer: {
    marginTop: theme.spacing(3),
  },
}))

type CreateDialogModalProps = {
  chart: Chart
  onClose: () => void
  onCreateDialog: (dialogName: string, dialogDescription: string) => void
}

export default function CreateSubdialogModal({
  chart,
  onClose,
  onCreateDialog,
}: CreateDialogModalProps): React.ReactElement {
  const { classes } = useStyles()
  const [existingDialogNames, setExistingDialogNames] = useState<string[]>([])
  const [dialogName, setDialogName] = useState<string>()
  const [dialogNameError, setDialogNameError] = useState<string>()
  const [dialogDescription, setDialogDescription] = useState<string>()

  /**
   * Handles name change event.
   * @param event
   */
  function onNameChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setDialogName(event.target.value)
    if (dialogNameError) setDialogNameError(undefined)
  }

  /**
   * Handles onBlur event of name textfield.
   * Validates whether name is valid and does not exist yet.
   */
  function onNameBlur(): void {
    if (!dialogName) return
    if (existingDialogNames.includes(dialogName))
      setDialogNameError('Es existiert bereits ein Dialog mit diesem Namen!')
    else setDialogNameError(undefined)
  }

  /**
   * Handles description change event.
   * @param event
   */
  function onDescriptionChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setDialogDescription(event.target.value)
  }

  /**
   * Handles final button click to create dialog.
   */
  function onCreateDialogClick(): void {
    if (dialogName && dialogDescription) onCreateDialog(dialogName, dialogDescription)
  }

  useEffect(
    function () {
      // find existing dialog names
      const dialogNames = Object.values(chart.dialogs ?? {}).map((dialog) => dialog.name)
      setExistingDialogNames(dialogNames)
    },
    [chart],
  )

  return (
    <Dialog
      id='create-dialog-modal'
      size='medium'
      open
      closable
      onClose={onClose}
      title={'Neuen Subdialog erstellen'}
      primaryActionButton={
        <Button
          type='success'
          size='small'
          icon={<Check />}
          onClick={onCreateDialogClick}
          disabled={!!dialogNameError || !dialogName || !dialogDescription}
        >
          Erstellen
        </Button>
      }
      secondaryActionText={'Abbrechen'}
      onSecondaryActionClick={onClose}
    >
      <>
        <div className={classes.nameContainer}>
          <Typography>Bitte geben Sie dem neuen Dialog einen aussagekräftigen Namen:</Typography>
          <Textfield
            onChange={onNameChange}
            onBlur={onNameBlur}
            multiline={false}
            value={dialogName}
            error={!!dialogNameError}
            label={dialogNameError ?? 'Name des Dialoges'}
            className={classes.textfield}
          />
        </div>
        <div className={classes.descContainer}>
          <Typography>Bitte beschreiben Sie den Dialog mit einigen Worten:</Typography>
          <Textfield
            onChange={onDescriptionChange}
            multiline
            rowsMax={3}
            value={dialogDescription}
            label={'Beschreibung'}
            className={classes.textfield}
          />
        </div>
      </>
    </Dialog>
  )
}
