import React, { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Typography } from '@mui/material'

import { Textfield } from '../../../../../../components/TextInput/Textfield'
import { IntentTriggerButtons } from '../../../../../../@types/Knowledge/types'

const useStyles = makeStyles()((theme) => ({
  textField: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
}))

type Step1DialogNameProps = {
  triggerDialogButtons?: IntentTriggerButtons
  onSetTriggerDialogButtons: (buttons: IntentTriggerButtons) => void
}

export default function StepTriggerDialogButtons({
  triggerDialogButtons,
  onSetTriggerDialogButtons,
}: Step1DialogNameProps): React.ReactElement {
  const { classes } = useStyles()

  const [startDialogButtonText, setStartDialogButtonText] = useState<string>(
    triggerDialogButtons?.startDialogButton ?? '',
  )

  function onStartDialogButtonTextChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setStartDialogButtonText(event.target.value)
    onSetTriggerDialogButtons({ ...(triggerDialogButtons ?? {}), startDialogButton: event.target.value })
  }

  return (
    <div>
      <Typography>
        Legen Sie den Text des Buttons fest, der den durch diese <em>Trigger</em>-Antwort gewählten Dialog starten soll.
      </Typography>
      <Textfield
        onChange={onStartDialogButtonTextChange}
        value={startDialogButtonText}
        multiline={false}
        placeholder='Dialog starten'
        className={classes.textField}
        label={startDialogButtonText && startDialogButtonText.length > 49 ? 'Text zu lang' : 'Start Dialog Buttontext'}
        helperText={`${startDialogButtonText ? startDialogButtonText.length : 0}/49`}
        inputProps={{ maxLength: 49 }}
        InputProps={{ autoComplete: 'off' }}
      />
    </div>
  )
}
