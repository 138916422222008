import { AnswerTranslation, AnswerType, AnswerAction, IntentTriggerButtons } from '../@types/Knowledge/types'

export type TriggerAnswerObject = {
  dialogName: string
  labels: string[]
  answerId: string
}

// export class TriggerAnswer {
//   answer: string
//   answerTemplate: string
//   labels: string[]
//   answerType: AnswerType
//   answerId: string
//   topic: string
//   title: string
//   intentId:
//   constructor(
//     dialogName: string,
//     labels: string[],
//     answerId: string,
//     intent?: string,
//     intentId?: string,
//     topic = 'Trigger'
//   ) {
//     this.setDialogName(dialogName)
//     this.answer = ''
//     this.answerTemplate = ''
//     this.labels = labels
//     this.answerId = answerId
//     this.answerType = 'trigger'
//     this.topic = topic
//     this.title = ''
//   }

//   setTopic(topic: string): void {
//     this.setTopic(topic)
//   }

//   setDialogName(dialogName: string): void {
//     // check if dialogName is only name or full answerText by trying to extract dialog name from string
//     // if this is null, the provided string is only the name, not the full answer
//     const name = getDialogNameFromAnswerText(dialogName) || dialogName
//     const answerText = name
//     this.setAnswer(answerText)
//     this.setAnswerTemplate(answerText)
//   }

//   private setAnswer(answer: string): void {
//     this.answer = answer
//   }

//   private setAnswerTemplate(answerTemplate: string): void {
//     this.answerTemplate = answerTemplate
//   }
// }

export type AnswerWithoutIntent = {
  answer: string
  answerTemplate?: string
  answerId: string
  title?: string
  actions?: AnswerAction[]
  labels: string[]
  topic?: string | null
  answerType: AnswerType
  language: string
  knowledgeDbId: string
  customerId?: string
  triggerDialogName?: string
  intentTriggerButtons?: IntentTriggerButtons
}

export class TriggerAnswerWithoutIntent {
  answer: string
  answerTemplate?: string
  answerId: string
  labels: string[]
  triggerDialogName?: string
  topic?: string | null
  answerType?: AnswerType
  intentTriggerButtons?: IntentTriggerButtons
  title?: string

  constructor(
    dialogName: string,
    answerId: string,
    labels: string[],
    answerTemplate?: string,
    answer?: string,
    intentTriggerButtons?: IntentTriggerButtons,
    title?: string, // webchat v4
    topic?: string,
  ) {
    this.answer = answer ?? ''
    this.answerTemplate = answerTemplate
    this.triggerDialogName = dialogName
    this.answerId = answerId
    this.labels = labels
    this.answerType = 'trigger'
    this.topic = topic ?? 'Trigger'
    this.intentTriggerButtons = intentTriggerButtons
    this.title = title
  }

  setAnswer(text: string): void {
    this.answer = text
  }

  setAnswerTemplate(text: string): void {
    this.answerTemplate = text
  }

  clone(): TriggerAnswerWithoutIntent {
    const clone = new TriggerAnswerWithoutIntent('', this.answerId, this.labels)

    clone.answer = this.answer
    clone.answerTemplate = this.answerTemplate
    clone.topic = this.topic
    clone.answerType = this.answerType
    clone.triggerDialogName = this.triggerDialogName
    clone.labels = this.labels
    clone.intentTriggerButtons = this.intentTriggerButtons
    clone.title = this.title

    return clone
  }
}

export class Answer {
  intent: string
  labels: string[]
  answer: string
  answerTemplate: string
  triggerDialogName?: string
  answerId: string
  title: string
  open?: boolean
  actions: AnswerAction[]
  topic: string | null
  answerType: AnswerType
  language: string
  knowledgeDbId: string
  customerId?: string
  intentTriggerButtons?: IntentTriggerButtons // buttons for the intent trigger answer
  score?: number // NLU prediction score for this answer
  constructor(
    answerId: string,
    intent: string,
    answer: string,
    answerTemplate: string | undefined,
    answerType: AnswerType,
    topic: string | null = null,
    labels: string[] = [],
    language: string,
    knowledgeDbId: string,
    actions?: AnswerAction[],
    title?: string,
    customerId?: string,
    triggerDialogName?: string,
    intentTriggerButtons?: IntentTriggerButtons,
  ) {
    this.intent = intent ? intent : ''
    this.labels = labels
    this.answer = answer || ''
    this.answerTemplate = answerTemplate || ''
    this.answerId = answerId
    this.title = title ?? ''
    this.open = false
    this.actions = []
    this.topic = topic
    this.actions = actions ?? []
    this.answerType = answerType
    this.language = language
    // if (answerType === 'trigger') {
    //   this.setTriggerDialogName(answer)
    // }
    this.triggerDialogName = triggerDialogName
    this.language = language
    this.knowledgeDbId = knowledgeDbId
    this.customerId = customerId
    this.intentTriggerButtons = intentTriggerButtons
  }

  getTriggerDialogName(): string | null {
    if (this.answerType !== 'trigger') return null
    return this.triggerDialogName ?? null
  }

  setIntent(intent: string): void {
    this.intent = intent
  }

  setLabels(labels: string[]): void {
    this.labels = labels
  }

  addLabel(text: string): void {
    this.labels.push(text)
  }

  setAnswer(text: string): void {
    this.answer = text
  }

  // setDialogName(dialogName: string): void {
  //   if (this.answerType === 'trigger') {
  //     const answerText = `convaise.intentTrigger("${dialogName}")`
  //     this.setAnswer(answerText)
  //     this.setAnswerTemplate(answerText)
  //   }
  // }

  setAnswerTemplate(text: string): void {
    this.answerTemplate = text
  }

  setAnswerId(text: string): void {
    this.answerId = text
  }

  setOpen(bool: boolean): void {
    this.open = bool
  }

  setActions(array: AnswerAction[]): void {
    this.actions = array
  }

  setTitle(title: string): void {
    this.title = title
  }

  setTopic(topic: string | null): void {
    this.topic = topic
  }

  setAnswerType(type: AnswerType): void {
    this.answerType = type
  }

  setScore(score: number): void {
    this.score = score
  }

  setTriggerDialogName(name: string): void {
    this.triggerDialogName = name
  }

  setIntentTriggerButtons(buttons: IntentTriggerButtons): void {
    this.intentTriggerButtons = buttons
  }

  clone(): Answer {
    const clone = new Answer(
      this.answerId,
      this.intent,
      this.answer,
      this.answerTemplate,
      this.answerType,
      this.topic,
      this.labels,
      this.language,
      this.knowledgeDbId,
      this.actions,
      this.title,
      this.customerId,
      this.triggerDialogName,
      this.intentTriggerButtons,
    )

    // manually set property just to be safe
    clone.intent = this.intent
    clone.labels = this.labels
    clone.answer = this.answer
    clone.answerTemplate = this.answerTemplate
    clone.answerId = this.answerId
    clone.title = this.title
    clone.open = this.open
    clone.actions = this.actions
    clone.topic = this.topic
    clone.answerType = this.answerType
    clone.language = this.language
    clone.triggerDialogName = this.triggerDialogName
    clone.intentTriggerButtons = this.intentTriggerButtons
    clone.knowledgeDbId = this.knowledgeDbId
    clone.customerId = this.customerId
    clone.score = this.score
    return clone
  }
}

type AnswerObject = AnswerWithoutIntent & {
  intent: string
}

/**
 * Builds instance of TriggerAnswer from triggeranswer object
 * @param triggerAnswerObject
 */
// export function buildTriggerAnswer(triggerAnswerObject: TriggerAnswerObject): Answer {
//   return new Answer()
//   return new TriggerAnswer(triggerAnswerObject.dialogName, triggerAnswerObject.labels, triggerAnswerObject.answerId)
// }

/**
 * Builds instance of Answer class from answer object
 * @param {object} answer answer json
 */
export function buildAnswerObject(answer: AnswerObject): Answer {
  const newAnswer = new Answer(
    answer.answerId,
    answer.intent,
    answer.answer,
    answer.answerTemplate,
    answer.answerType,
    answer.topic,
    answer.labels,
    answer.language,
    answer.knowledgeDbId,
    answer.actions,
    answer.title,
    answer.customerId,
    answer.triggerDialogName,
    answer.intentTriggerButtons,
  )

  // newAnswer.setAnswer(answer.answer)
  // newAnswer.setAnswerId(answer.answerId)
  // !!answer.answerTemplate && newAnswer.setAnswerTemplate(answer.answerTemplate)
  // !!answer.title && newAnswer.setTitle(answer.title)
  // !!answer.actions && newAnswer.setActions(answer.actions)
  // !!answer.labels && newAnswer.setLabels(answer.labels)
  // !!answer.topic && newAnswer.setTopic(answer.topic)

  return newAnswer
}

/**
 * Builds answerTranslation object from answer object
 * @param {object} answer answer json
 */
export function buildAnswerTranslationObject(answer: AnswerTranslation): AnswerTranslation {
  const newAnswer = {
    answerId: answer.answerId,
    answer: answer.answer,
    answerTemplate: answer.answerTemplate,
    answerType: answer.answerType,
    topic: answer.topic,
    actions: answer.actions,
    title: answer.title,
    language: answer.language,
    knowledgeDbId: answer.knowledgeDbId,
    customerId: answer.customerId,
    intent: answer.intent,
    intentTrigger: answer.intentTriggerButtons,
  }

  return newAnswer
}
