import React, { Fragment, useState } from 'react'

// @mui/material
import { makeStyles } from 'tss-react/mui'

import Fab from '@mui/material/Fab'
// custom components
import StandaloneToggleButton from '../Buttons/ToolbarToggleButton'
import CustomizedTooltip from '../Tooltips/CustomContentTooltip'
// @mui/icons-material
import TouchAppIcon from '@mui/icons-material/TouchApp'
import CropIcon from '@mui/icons-material/Crop'
import TextRotationNoneIcon from '@mui/icons-material/TextRotationNone'
import TabUnselectedIcon from '@mui/icons-material/TabUnselected'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

// I18n
// import { Trans } from '@lingui/macro'

const useStyles = makeStyles()(() => ({
  h4: {
    marginTop: '0px',
  },
  helpFab: {
    marginTop: 'auto',
    boxShadow: 'none',
  },
}))

type PDFToolbarProps = {
  selection: { active: boolean; disabled: boolean; enforced: boolean }
  area: { active: boolean; disabled: boolean; enforced: boolean }
  text: { active: boolean; disabled: boolean; enforced: boolean }
  group: { active: boolean; disabled: boolean; enforced: boolean }
  onChange: Function
}

function PDFToolbar(props: PDFToolbarProps): React.ReactElement {
  const { classes } = useStyles()

  return (
    <Fragment>
      <h4 className={classes.h4}>
        Tools
        {/* <Trans>Tools</Trans> */}
      </h4>
      {/* Selection */}
      <StandaloneToggleButton
        value='selection'
        disabled={props.selection.disabled}
        selected={props.selection.active}
        onChange={(): void => {
          if (!props.selection.enforced) props.onChange('selection')
        }}
        icon={<TouchAppIcon />}
        tooltip={
          <p>
            <strong>Selection</strong>
            <br />
            This does nothing, but you can get more information <a href='convaise.com'>here</a>.
          </p>
        }
      />
      {/* Area */}
      <StandaloneToggleButton
        value='area'
        disabled={props.area.disabled}
        selected={props.area.active}
        onChange={(): void => {
          if (!props.area.enforced) props.onChange('area')
        }}
        icon={<CropIcon />}
        tooltip={
          <p>
            <strong>Area</strong>
            <br />
            Mit diesem Tool können Sie im Dokument Felder für Bereiche auswählen, indem Sie beim gedrückt halten der
            linken Maustaste eine Auswahl ziehen.
          </p>
        }
      />
      {/* Text */}
      <StandaloneToggleButton
        value='text'
        disabled={props.text.disabled}
        selected={props.text.active}
        onChange={(): void => {
          if (!props.text.enforced) props.onChange('text')
        }}
        icon={<TextRotationNoneIcon />}
        tooltip={
          <p>
            <strong>Text</strong>
            <br />
            Mit diesem Tool können Sie im Dokument wie gewohnt Texte makieren, welche dann für das Anlegen neuer
            Infotexte oder URLs verwendet werden.
          </p>
        }
      />
      {/* Group */}
      <StandaloneToggleButton
        value='group'
        disabled={props.group.disabled}
        selected={props.group.active}
        onChange={(): void => {
          if (!props.group.enforced) props.onChange('group')
        }}
        icon={<TabUnselectedIcon />}
        tooltip={
          <p>
            <strong>Group</strong>
            <br />
            This does nothing, but you can get more information <a href='convaise.com'>here</a>.
          </p>
        }
      />
      <CustomizedTooltip
        content={
          <p>
            <strong>Help</strong>
            <br />
            Hier erhalten Sie weitere Antworten, Informationen, Tutorials und unseren Support.
          </p>
        }
        elements={
          <Fab color='primary' size='small' aria-label='help' className={classes.helpFab}>
            <HelpOutlineIcon />
          </Fab>
        }
      />
    </Fragment>
  )
}

PDFToolbar.defaultProps = {
  buttonState: {
    selection: { active: false, disabled: true },
    area: { active: false, disabled: true },
    text: { active: false, disabled: true },
    group: { active: false, disabled: true },
  },
}

export default PDFToolbar
