import { Typography } from '@mui/material'
import React from 'react'
import Button from 'components/Buttons/Button'
import Dialog from 'components/Dialogs/Dialog'

type DeleteVariableConfirmDialogProps = {
  onClose: () => void
  onDelete: () => void
}

export default function DeleteVariableConfirmDialog({
  onClose,
  onDelete,
}: DeleteVariableConfirmDialogProps): React.ReactElement {
  return (
    <Dialog
      id='delete-variable-modal'
      size='small'
      open
      title='Variable löschen'
      closable
      onClose={onClose}
      primaryActionButton={
        <Button size='small' type='danger' onClick={onDelete} icon={'delete-bin-2-line'} iconType='remix'>
          Variable löschen
        </Button>
      }
    >
      <Typography>
        Möchten Sie diese Variable wirklich löschen? Dies kann nicht rückgängig gemacht werden!
        <br />
        Bitte beachten Sie die Antworttexte anzupassen in denen Sie die Variable verwendet haben.
      </Typography>
    </Dialog>
  )
}
