import React, { useState, useEffect, memo } from 'react'
import { cloneDeep, isEqual } from 'lodash'
import jp from 'jsonpath'
// @material-ui
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import { IconButton, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
// Custom Components
import CardPreview from './cardPreview'
import { Button } from 'components/Buttons'
import CardName from './cardName'
import CardTitle from './cardTitle'
import CardContent from './cardContent'
import CardInput from './cardInput'
import CardActions from './cardActions'
import CardDesigner from './cardDesigner'
import CardCustomVars from './cardCustomVars'
import CardOverview from './cardOverview'
// Types
import { Card, CardTypeEnum, InputTypeEnum } from '../../../../../../../@types/SmartCards/types'
import { Node, Chart } from '../../../../../../../@types/Flowchart/types'
import { TranslationFile } from '../../../../../../../@types/Translations/types'
// Smart Card modules
import {
  SmartCardBase,
  SmartCardInputContainer,
  SmartCardActionsContainer,
  SmartCardContentContainer,
} from 'components/SmartCard/smartCardModules'
import { useFlowdesignerContext } from 'hooks/contexts/flowdesigner-context'
import { countSmartCardUsage } from 'utils/smartCardUtils'

const useStyles = makeStyles()((theme) => ({
  backButton: {
    margin: `auto ${theme.spacing(1)} auto 0`,
  },
  stepperContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  stepper: {
    width: '70%',
    margin: '0 calc(10% - 56px) 0 10%',
  },
  builderContent: {
    display: 'flex',
    alignItems: 'flex-end',
    marginTop: 'auto',
    overflow: 'hidden',
  },
  cardBuilder: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
  },
  builderActions: {
    height: '60px',
    marginTop: theme.spacing(1),
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'end',
  },
  primaryButton: {
    marginLeft: theme.spacing(2),
    width: '100px',
  },
  builderOptions: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(5),
    paddingTop: theme.spacing(2),
    // alignSelf: 'flex-start',
    height: '100%',
  },
  description: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))

enum BuilderStages {
  Name,
  Title,
  Content,
  Inputs,
  Actions,
  CustomCardDesign,
  CustomVars,
  Overview,
}

type Stage = {
  label: string
  stage: BuilderStages
}

type CardBuilderProps = {
  type: CardTypeEnum
  // node: Node
  nodeOnLoad: Node
  translations: TranslationFile
  chart: Chart
  card?: Card
  isClonedCard?: boolean
  mode: 'create' | 'manage'
  onBackClick: () => void
  onDelete: () => void
  onSave: (chart: Chart, card: Card, node: Node) => void
}

function getStageDescription(stage: BuilderStages, type: CardTypeEnum): string {
  switch (stage) {
    case BuilderStages.Name:
      return 'Der Card Name wird nur in Convaise Studio angezeigt und hilft Ihnen bestehende Cards wiederzuerkenenn und zu verwalten.'
    case BuilderStages.Title:
      return 'Card Titel sind optional und werden vor allem in komplexen Prozessen genutzt, um es den Nutzer*innen des Assistenten zu vereinfachen, den aktuellen Schritt im Prozess einzuordnen.'
    case BuilderStages.Content:
      return 'Definieren Sie den Text, der in der Karte angezeigt wird. Ihnen stehen dafür neben verschiedenen Formatierungsmöglichkeiten, auch Optionen zur Integration von Bildern und Variablen aus dem Konversationsfluss zur Verfügung.'
    case BuilderStages.Inputs:
      return 'Sie können einer Card verschiedenste Formularfelder hinzufügen. Dafür können Sie entweder aus unseren Vorlagen auswählen oder selber eine Eingabe erstellen.'
    case BuilderStages.Actions: {
      if (type === CardTypeEnum.ActionChoiceCard) {
        return 'Einer Karte können mehrere Aktionen hinzugefügt werden. Für die Aktions Karten stellen diese Auswahl-Möglichkeiten, wie zum Beispiel bei einem Menü, dar.'
      } else {
        return 'Einer Karte können mehrere Aktionen hinzugefügt werden. Wenn Sie Eingaben in der Karte haben, werden diese nur über eine Eingaben bestätigen Aktion an den Assistenten gesendet.'
      }
    }
    case BuilderStages.CustomCardDesign:
      return 'Im Card Designer können Sie die Karten ganz nach Ihren Wünschen gestalten.'
    case BuilderStages.CustomVars:
      return 'Hier können Sie Eingaben, die im Card Designer gesetzt wurden bearbeiten.'
    case BuilderStages.Overview:
      return 'In dieser Übersicht können Sie schnell Variablen von Eingaben anpassen oder den Bearbeitungsmodus für die Smart Card starten.'
    default:
      return ''
  }
}

function stageNumberToBuilderStage(stage: number, type: CardTypeEnum): BuilderStages {
  switch (type) {
    case CardTypeEnum.ActionChoiceCard: {
      switch (stage) {
        case -1:
          return BuilderStages.Overview
        case 0:
          return BuilderStages.Name
        case 1:
          return BuilderStages.Title
        case 2:
          return BuilderStages.Content
        case 3:
          return BuilderStages.Actions
        default:
          console.error('[stageNumberToBuilderStage] not expected stage number for card type')
          return BuilderStages.Name
      }
    }
    case CardTypeEnum.ContentCard:
      switch (stage) {
        case -1:
          return BuilderStages.Overview
        case 0:
          return BuilderStages.Name
        case 1:
          return BuilderStages.Title
        case 2:
          return BuilderStages.Content
        case 3:
          return BuilderStages.Actions
        default:
          console.error('[stageNumberToBuilderStage] not expected stage number for card type')
          return BuilderStages.Name
      }
    case CardTypeEnum.InputCard:
      switch (stage) {
        case -1:
          return BuilderStages.Overview
        case 0:
          return BuilderStages.Name
        case 1:
          return BuilderStages.Title
        case 2:
          return BuilderStages.Content
        case 3:
          return BuilderStages.Inputs
        case 4:
          return BuilderStages.Actions
        default:
          console.error('[stageNumberToBuilderStage] not expected stage number for card type')
          return BuilderStages.Name
      }
    case CardTypeEnum.CustomCard: {
      switch (stage) {
        case -1:
          return BuilderStages.Overview
        case 0:
          return BuilderStages.Name
        case 1:
          return BuilderStages.CustomCardDesign
        case 2:
          return BuilderStages.CustomVars
        default:
          console.error('[stageNumberToBuilderStage] not expected stage number for card type')
          return BuilderStages.Name
      }
    }
    default:
      console.error('[stageNumberToBuilderStage] not expected card type')
      return BuilderStages.Name
  }
}

/**
 * TODOs:
 * - on save set translations for card -> using the card name / id stored in the node.properties.card
 */

export default memo(function CardBuilder({
  type,
  // node: origNode,
  nodeOnLoad,
  translations: origTranslations,
  chart: origChart,
  mode,
  onBackClick,
  card: origCard,
  isClonedCard,
  onSave,
  onDelete,
}: CardBuilderProps): React.ReactElement {
  const { classes } = useStyles()
  const { canIEditFlowdesigner } = useFlowdesignerContext()
  // Stage management
  const [stage, setStage] = useState<number>(0)
  const [completedStages, setCompletedStages] = useState<{ [stage: number]: boolean }>({})
  const [stages, setStages] = useState(stepsForType(type))
  // Part of chart etc. that is getting updated
  const [card, setCard] = useState<Card>(origCard ?? { id: '', data: SmartCardBase, cardType: type }) // places default card if none is given, and sets the cardType.
  // const [node, setNode] = useState<Node>(origNode) // to manage card name
  const [chart, setChart] = useState<Chart>(cloneDeep(origChart))
  const node = chart.nodes[nodeOnLoad.id] // for convenience
  const [blockNextButton, setBlockNextButton] = useState<boolean>(false)

  // function setChart(chart: Chart): void {
  //   const updatedNode = { ...node }
  //   // we need to merge node properties
  //   // node from state contains changes to card and name property, node in chart changes from variables
  //   // would we not merge them, either card and name changes or variable changes would get lost!
  //   // TODO: This is really ugly atm because we effectively manage the same node in two different places!
  //   updatedNode.properties = { ...chart.nodes[node.id].properties, ...node.properties }
  //   setNode(updatedNode)<
  //   _setChart(chart)
  // }

  useEffect(() => {
    // Check if we need the card overview or the editor
    if (origCard && !isClonedCard) {
      // only show overview if the card is not cloned.
      setStage(-1)
    } else {
      // cloned card, show first stage of editor (Name)
      // the user probably wants to change the name and we show a hint that the card is cloned
      setStage(0)
    }
  }, [])

  function getStageLabels(stage: BuilderStages): Stage {
    switch (stage) {
      case BuilderStages.Name:
        return { stage: BuilderStages.Name, label: 'Card Name' }
      case BuilderStages.Title:
        return { stage: BuilderStages.Title, label: 'Titel (Optional)' }
      case BuilderStages.Content:
        return { stage: BuilderStages.Content, label: 'Inhalt' }
      case BuilderStages.Inputs:
        return { stage: BuilderStages.Inputs, label: 'Eingaben' }
      case BuilderStages.Actions:
        return { stage: BuilderStages.Actions, label: 'Aktionen' }
      case BuilderStages.CustomCardDesign:
        return { stage: BuilderStages.CustomCardDesign, label: 'Card Designer' }
      case BuilderStages.CustomVars:
        return { stage: BuilderStages.CustomVars, label: 'Variablen' }
      case BuilderStages.Overview:
        return { stage: BuilderStages.CustomVars, label: `Übersicht ${card?.id ?? ''}` } // WARNING not used/reached code
      default:
        return { stage: BuilderStages.Name, label: 'Card Name' }
    }
  }

  function stepsForType(type: CardTypeEnum): Stage[] {
    switch (type) {
      case CardTypeEnum.ActionChoiceCard:
        return [
          getStageLabels(BuilderStages.Name),
          getStageLabels(BuilderStages.Title),
          getStageLabels(BuilderStages.Content),
          getStageLabels(BuilderStages.Actions),
        ]
      case CardTypeEnum.ContentCard:
        return [
          getStageLabels(BuilderStages.Name),
          getStageLabels(BuilderStages.Title),
          getStageLabels(BuilderStages.Content),
          getStageLabels(BuilderStages.Actions),
        ]
      case CardTypeEnum.InputCard:
        return [
          getStageLabels(BuilderStages.Name),
          getStageLabels(BuilderStages.Title),
          getStageLabels(BuilderStages.Content),
          getStageLabels(BuilderStages.Inputs),
          getStageLabels(BuilderStages.Actions),
        ]
      case CardTypeEnum.CustomCard:
        return [
          getStageLabels(BuilderStages.Name),
          getStageLabels(BuilderStages.CustomCardDesign),
          getStageLabels(BuilderStages.CustomVars),
        ]
      default:
        return []
    }
  }

  // Stepper Handling
  function allStepsCompleted(): boolean {
    return Object.keys(completedStages).length === stages.length
  }

  function isLastStep(): boolean {
    return stage === stages.length - 1
  }

  function handleNextStage(): void {
    // check if stage is complete
    if (checkCorrectEditorSettings(stage, node, card).complete) {
      handleComplete()
    }
    const newActiveStage =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          stages.findIndex((stage, i) => !(i in completedStages))
        : stage + 1
    setStage(newActiveStage)
  }

  function handleBack(): void {
    setStage((prevActiveStage) => prevActiveStage - 1)
  }

  // TODO: Might be good to do some validation
  const handleClickedStage = (stage) => (): void => {
    setStage(stage)
  }

  // Run if stage is done and filled out correctly without errors - also moves to the next step
  function handleComplete(): void {
    const newCompleted = { ...completedStages }
    newCompleted[stage] = true
    setCompletedStages(newCompleted)
    // handleNextStage()
  }

  // ----- EDITOR COMPONENTS -----
  function getStageEditor(stage: BuilderStages): React.ReactElement {
    switch (stage) {
      case BuilderStages.Title:
        return (
          <CardTitle
            card={card}
            setCardCallback={(cardNew: Card): void => {
              setCard(cloneDeep(cardNew))
            }}
            handleNextStage={handleNextStage}
          />
        )
      case BuilderStages.Content:
        return (
          <CardContent
            card={card}
            chart={chart}
            setCardCallback={(cardNew: Card): void => {
              setCard(cloneDeep(cardNew))
            }}
          />
        )
      case BuilderStages.Inputs:
        return (
          <CardInput
            card={card}
            chart={chart}
            setChartCallback={setChart}
            setBlockNextButtonCallback={(block): void => setBlockNextButton(block)}
            setCardCallback={(cardNew: Card): void => {
              setCard(cloneDeep(cardNew))
            }}
          />
        )
      case BuilderStages.Actions:
        return (
          <CardActions
            card={card}
            cardType={type}
            setCardCallback={(cardNew: Card): void => {
              setCard(cloneDeep(cardNew))
            }}
          />
        )
      case BuilderStages.CustomCardDesign:
        return (
          <CardDesigner
            card={card}
            primaryLanguage={origTranslations.primaryLanguage}
            variables={origChart.variables}
            setCardCallback={(cardNew: Card): void => {
              cardNew.data.version = '1.3'
              setCard(cloneDeep(cardNew))
            }}
          />
        )
      case BuilderStages.CustomVars:
        return <CardCustomVars card={card} chart={chart} setChartCallback={setChart} />
      case BuilderStages.Name:
      default:
        return (
          <CardName
            chart={chart}
            node={node}
            origNode={nodeOnLoad}
            translations={origTranslations}
            card={card}
            isClonedCard={!!isClonedCard}
            setNameCallback={(cardName): void => {
              // update/set card name / id in node and card
              const nodeTmp = cloneDeep(node)
              nodeTmp.properties.card = cardName
              nodeTmp.properties.text = cardName
              // setting/updating the cardType in the node here, might not be the best place though, because it only updates if the name updates ~ Jakob
              nodeTmp.properties.cardType = type
              chart.nodes[nodeTmp.id] = nodeTmp
              // setNode(nodeTmp)
              setChart(cloneDeep(chart))
              const cardTmp = cloneDeep(card)
              cardTmp.id = cardName
              setCard(cardTmp)
            }}
            handleNextStage={handleNextStage}
          />
        )
    }
  }

  function checkCorrectEditorSettings(
    stage: BuilderStages,
    node: Node,
    card: Card,
  ): { complete: boolean; next: boolean } {
    const builderStage = stageNumberToBuilderStage(
      stage,
      card.cardType as CardTypeEnum,
      // ? CardTypeEnum[card.cardType] : CardTypeEnum.CustomCard
    )
    switch (builderStage) {
      case BuilderStages.Name: {
        // check if card property is set and if the cardname staid the same
        const currentNode = chart.nodes[node.id]
        if (
          currentNode.properties.card &&
          typeof currentNode.properties.card === 'string' &&
          currentNode.properties.card.length > 0 &&
          currentNode.properties.card === nodeOnLoad.properties.card
        ) {
          // means name is the original name
          return { complete: true, next: true }
        }

        const cardUsage = countSmartCardUsage(chart, currentNode.properties.card ?? '')
        if (cardUsage === 1) {
          // only this node uses card with this name - name is valid
          return { complete: true, next: true }
        }
        return { complete: false, next: false }

        // // this means the card name is NOT the original name. This only is relevant if the card has not been cloned
        // if (
        //   !isClonedCard &&
        //   currentNode.properties.card &&
        //   typeof currentNode.properties.card === 'string' &&
        //   currentNode.properties.card.length > 0 &&
        //   typeof origTranslations.ac[origTranslations.primaryLanguage][currentNode.properties.card.trim()] !==
        //     'undefined'
        // ) {
        //   // means this card name already exists in the translations
        //   return { complete: false, next: false }
        // }
        // return { complete: true, next: true }
      }
      case BuilderStages.Title:
        return { complete: true, next: true }
      case BuilderStages.Content: {
        const contentContainer = jp.query(card, `$..body[?(@.id=="${SmartCardContentContainer.id}")]`)
        if (
          contentContainer.length > 0 &&
          contentContainer[0] &&
          contentContainer[0].items &&
          contentContainer[0].items.length > 0 &&
          contentContainer[0].items[0].text
        ) {
          const text = contentContainer[0].items[0].text
          if (text && text.length > 0) {
            return { complete: true, next: true }
          }
        }
        return { complete: false, next: false }
      }
      case BuilderStages.Inputs: {
        const inputContainer = jp.paths(card, `$..body[?(@.id=="${SmartCardInputContainer.id}")]`)
        if (inputContainer.length > 0) {
          const inputs = jp.paths(
            card,
            `$..items[?(@.type=="${InputTypeEnum['Input.Text']}" || @.type=="${InputTypeEnum['Input.Date']}" || @.type=="${InputTypeEnum['Input.Time']}" || @.type=="${InputTypeEnum['Input.Number']}" || @.type=="${InputTypeEnum['Input.ChoiceSet']}" || @.type=="${InputTypeEnum['Input.Toggle']}")]`,
          )
          if (inputs.length > 0) {
            return { complete: true, next: true }
          } else {
            // depending if new card or management of existing card we allow/disallow to move forward
            if (mode === 'create') {
              return { complete: false, next: false }
            }
            return { complete: false, next: true }
          }
        } else {
          return { complete: false, next: false }
        }
      }
      case BuilderStages.Actions: {
        const actionsContainer = jp.paths(card, `$..body[?(@.id=="${SmartCardActionsContainer.id}")]`)
        if (actionsContainer.length > 0) {
          const actions = jp.paths(card, `$..actions`) // NOTE: do we need additional checks here?
          if (actions.length > 0) {
            return { complete: true, next: true }
          } else {
            // depending if new card or management of existing card we allow/disallow to move forward
            if (mode === 'create') {
              return { complete: false, next: false }
            }
            return { complete: false, next: true }
          }
        } else {
          return { complete: false, next: false }
        }
      }
      case BuilderStages.CustomCardDesign: {
        if (card) {
          return { complete: true, next: true }
        } else {
          return { complete: false, next: false }
        }
      }
      case BuilderStages.CustomVars: {
        return { complete: true, next: true }
      }
      default:
        return { complete: false, next: false }
    }
  }

  function handleCompleteEditor(): void {
    // if (!isClonedCard) {
    //   // card is cloned
    //   onSave()
    // } else {
    // new card, so we show the overview at the end of the creation process
    setStage(-1)
    // }
  }

  // Stateful
  function isNextButtonDisabled(): boolean {
    const check = checkCorrectEditorSettings(stage, node, card)
    // either editorSettings are false or a stage component blocks the next button
    if (blockNextButton) {
      return true
    } else if (!check.next) {
      return true
    }
    return false
    // return blockNextButton ?? !check.next // "broken" returned false for !check.next when it is true, not sure why
  }

  const description = getStageDescription(stageNumberToBuilderStage(stage, type), type)
  const editor = getStageEditor(stageNumberToBuilderStage(stage, type))

  // TODO: do a completed check that always runs
  // TODO: show in the stepper which step has errors
  return (
    <div className={classes.cardBuilder}>
      <div className={classes.stepperContainer}>
        {/* TODO: reset card to not mess up logic */}
        {stage !== -1 && mode === 'create' && (
          <IconButton
            className={classes.backButton}
            onClick={(): void => {
              onBackClick()
            }}
          >
            <i className={`ri-arrow-left-line`} />
          </IconButton>
        )}
        {/* {stage === -1 && <Typography>Karte zurücksetzen und neu erstellen.</Typography>} */}
        {/* Stepper */}
        {/* Only show stepper if the overview is not open */}
        {stage !== -1 && (
          <Stepper
            nonLinear={mode === 'manage'} // true means jump around, false means do not jump aroung
            // nonLinear if card was given, else be able to jump around
            activeStep={stage}
            className={classes.stepper}
          >
            {stages.map((stage, index) => (
              <Step key={stage.label} completed={completedStages[index]}>
                <StepButton color='primary' onClick={handleClickedStage(index)}>
                  {stage.label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        )}
      </div>
      {/* Step */}
      {/* Card Preview & Step Config*/}
      <div className={classes.builderContent}>
        <CardPreview card={cloneDeep(card.data)} />
        <div className={classes.builderOptions}>
          <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflow: 'hidden' }}>
            <Typography variant='h4'>
              {stageNumberToBuilderStage(stage, type) !== BuilderStages.Overview
                ? stages[stage].label
                : `Übersicht ${card?.id ? '(' + card.id + ')' : ''}`}
            </Typography>
            <Typography className={classes.description}>{description}</Typography>
            {stageNumberToBuilderStage(stage, type) !== BuilderStages.Overview ? (
              editor
            ) : (
              <CardOverview
                card={card}
                chart={chart}
                setChartCallback={setChart}
                editCard={(): void => {
                  setStages(stepsForType(type))
                  setStage(0)
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div className={classes.builderActions}>
        <Button
          size='small'
          variant='tertiary'
          onClick={(): void => {
            if (stageNumberToBuilderStage(stage, type) === BuilderStages.Overview) {
              onDelete()
            } else {
              handleBack()
            }
          }}
          disabled={stage === 0 || !canIEditFlowdesigner}
        >
          {/* TODO: logic to disable buttons based on set properties and missing stuff etc. */}
          {stageNumberToBuilderStage(stage, type) === BuilderStages.Overview ? 'Löschen' : 'Zurück'}
        </Button>
        <Button
          onClick={(): void => {
            if (stageNumberToBuilderStage(stage, type) === BuilderStages.Overview) {
              onSave(chart, card, node)
            } else {
              // TODO: Check for complete
              isLastStep() ? handleCompleteEditor() : handleNextStage()
            }
          }}
          size='small'
          disabled={
            !canIEditFlowdesigner ||
            (stageNumberToBuilderStage(stage, type) === BuilderStages.Overview
              ? false
              : isNextButtonDisabled() ?? false)
          }
          type='success'
          className={classes.primaryButton}
        >
          {stageNumberToBuilderStage(stage, type) === BuilderStages.Overview
            ? 'Speichern'
            : isLastStep()
            ? 'Fertig'
            : 'Weiter'}
        </Button>
      </div>
    </div>
  )
}, isEqual)
