import React, { useState, useEffect } from 'react'

// @mui/material components
import { makeStyles } from 'tss-react/mui'

import { AnalyticsEventOrigin, Chart } from '../../../../../@types/Flowchart/types'
import Overview from './SelectedAnalyticsTriggerViews/Overview'
import SelectConvaiseEvent from './SelectedAnalyticsTriggerViews/SelectConvaiseEvent'
import EventOverview from './SelectedAnalyticsTriggerViews/EventOverview'
import CreateEditCustomEvent from './SelectedAnalyticsTriggerViews/CreateEditCustomEvent'
import SelectCustomEvent from './SelectedAnalyticsTriggerViews/SelectCustomEvent'

const useStyles = makeStyles()({
  container: { height: '100%' },
})

type SEventTriggerProps = {
  chart: Chart
  setIsSaveDisabled: (isSaveDisabled: boolean) => void
  setStateCallback: (chart: Chart) => void
}

export enum VIEW {
  OVERVIEW,
  CHOOSE_CUSTOM_EVENT,
  CREATE_CUSTOM_EVENT,
  EDIT_CUSTOM_EVENT,
  SELECT_CONVAISE_EVENT,
  EVENT_OVERVIEW,
}

// these are our predefined convaise analytics events
export const CONVAISE_ANALYTICS_EVENTS = [
  {
    label: 'PDF generiert',
    value: 'pdf-generated',
  },
  {
    label: 'Nachricht gesendet vom Assistenten',
    value: 'message-sent-assistant',
  },
  {
    label: 'Nachricht gesendet vom Nutzer',
    value: 'message-sent-user',
  },
  {
    label: 'Konversation gestartet',
    value: 'dialog-startet',
  },
  {
    label: 'Konversation beendet',
    value: 'dialog-ended',
  },
]

export default function SAnalyticsTrigger({
  chart,
  setIsSaveDisabled,
  setStateCallback,
}: SEventTriggerProps): React.ReactElement {
  const { classes } = useStyles()

  const [eventType, setEventType] = useState<AnalyticsEventOrigin>()
  const [currentView, _setCurrentView] = useState<VIEW>(VIEW.OVERVIEW)
  // const [selectedEvent, setSelectedEvent] = useState<DisplayVariableOption>()

  // ========= HANDLER ============

  // function onSelectEvent(event): void {
  //   const selectedId = chart.selected?.id
  //   if (typeof selectedId === 'undefined') return
  //   const node = chart.nodes[selectedId]
  //   if (typeof node === 'undefined') return
  //   // set event property with AnalyticsEvent value
  //   node.properties.event = event.value
  //   chart.nodes[selectedId] = node
  //   // setSelectedEvent(event)
  //   setStateCallback(chart)
  // }

  /**
   * Set view callback.
   * Checks if there is already an event selected.
   * If so, show event overview screen (differentiate between Convaise event and customer event), else shows select / create event view.
   * @param view
   */
  function setView(): void {
    // const selectedId = chart.selected?.id
    // if (!selectedId) return
    // if (chart.nodes[selectedId].properties.event?.eventName) {
    //   // event exists
    //   if view === VIEW.SELECT_CONVAISE_EVENT
    // }
  }

  useEffect(function () {
    setIsSaveDisabled(true)

    const selectedId = chart.selected?.id
    if (typeof selectedId === 'undefined') return
    // get already selected Event
    const node = chart.nodes[selectedId]
    if (node.properties.analyticsEvent?.eventName) {
      // event is selected show event screen
      _setCurrentView(VIEW.EVENT_OVERVIEW)
    } else {
      // no event is selected
      _setCurrentView(VIEW.OVERVIEW)
    }
    // setSelectedEvent(event[0])
  }, [])

  useEffect(
    function () {
      const selectedId = chart.selected?.id
      if (typeof selectedId === 'undefined') return
      // get already selected Event
      const node = chart.nodes[selectedId]
      if (node.properties.analyticsEvent?.eventName) {
        // event is selected show event screen
        setIsSaveDisabled(false)
      }
      if (node.properties.analyticsEvent?.origin === 'convaise') {
        setEventType('convaise')
      } else if (node.properties.analyticsEvent?.origin === 'customer') {
        setEventType('customer')
      }
    },
    [chart],
  )

  function getComponent(): React.ReactElement | null {
    switch (currentView) {
      case VIEW.OVERVIEW:
        return <Overview onSelect={_setCurrentView} />
      case VIEW.CHOOSE_CUSTOM_EVENT:
        return (
          <SelectCustomEvent
            chart={chart}
            onSelectEvent={(chart: Chart): void => {
              setStateCallback(chart)
              _setCurrentView(VIEW.EVENT_OVERVIEW)
              setIsSaveDisabled(false)
            }}
            descriptionText='Benutzerdefinierte Analytics Events können einfach von Ihnen erstellt werden. Sie erscheinen wie die vordefinierten Events in den Analytics.'
            onCreateNewEvent={(): void => {
              setIsSaveDisabled(true)
              _setCurrentView(VIEW.CREATE_CUSTOM_EVENT)
            }}
            onBackClick={(): void => _setCurrentView(VIEW.OVERVIEW)}
          />
        )
      case VIEW.CREATE_CUSTOM_EVENT:
        return (
          <CreateEditCustomEvent
            chart={chart}
            mode='create'
            descriptionText='Benutzerdefinierte Analytics Events können einfach von Ihnen erstellt werden. Sie erscheinen wie die vordefinierten Events in den Analytics.'
            onBackClick={(): void => _setCurrentView(VIEW.CHOOSE_CUSTOM_EVENT)}
            onCreateOrEditEvent={(chart: Chart): void => {
              setStateCallback(chart)
              _setCurrentView(VIEW.EVENT_OVERVIEW)
              setIsSaveDisabled(false)
            }}
          />
        )
      case VIEW.EDIT_CUSTOM_EVENT:
        return (
          <CreateEditCustomEvent
            chart={chart}
            mode='edit'
            descriptionText='Benutzerdefinierte Analytics Events können einfach von Ihnen erstellt werden. Sie erscheinen wie die vordefinierten Events in den Analytics.'
            onBackClick={(): void => _setCurrentView(VIEW.CHOOSE_CUSTOM_EVENT)}
            onCreateOrEditEvent={(chart: Chart): void => {
              setStateCallback(chart)
              _setCurrentView(VIEW.EVENT_OVERVIEW)
              setIsSaveDisabled(false)
            }}
          />
        )
      case VIEW.SELECT_CONVAISE_EVENT:
        return (
          <SelectConvaiseEvent
            chart={chart}
            onSelectEvent={(chart: Chart): void => {
              setStateCallback(chart)
              _setCurrentView(VIEW.EVENT_OVERVIEW)
              setIsSaveDisabled(false)
            }}
            onBackClick={(): void => _setCurrentView(VIEW.OVERVIEW)}
          />
        )
      case VIEW.EVENT_OVERVIEW:
        return eventType === 'convaise' ? (
          <EventOverview
            chart={chart}
            onBackClick={(): void => _setCurrentView(VIEW.SELECT_CONVAISE_EVENT)}
            onEditClick={(): void => _setCurrentView(VIEW.SELECT_CONVAISE_EVENT)}
            origin='convaise'
            headerText='Convaise Analytics'
            descriptionText='Vordefinierte Convaise Events. Diese Events sind bereits in den Analytics sichtbar.'
          />
        ) : (
          <EventOverview
            chart={chart}
            onBackClick={(): void => {
              // setIsSaveDisabled(true)
              _setCurrentView(VIEW.CHOOSE_CUSTOM_EVENT)
            }}
            onEditClick={(): void => {
              setIsSaveDisabled(true)
              _setCurrentView(VIEW.EDIT_CUSTOM_EVENT)
            }}
            origin='customer'
            headerText='Benutzerdefinierte Analytics'
            descriptionText='Benutzerdefinierte Analytics Events können einfach von Ihnen erstellt werden. Sie erscheinen wie die vordefinierten Events in den Analytics.'
          />
        )
      default:
        return null
    }
  }

  const component = getComponent()
  return <div className={classes.container}>{component}</div>
}
