import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { IconButton, Tooltip, Typography } from '@mui/material'
import { Add, Delete, Check } from '@mui/icons-material'
import { makeStyles } from 'tss-react/mui'
import { Answer } from 'classes/Knowledge'
import { Textfield } from 'components/TextInput/Textfield'
import { Button } from 'components/Buttons'
import { KNOWLEDGE_MAX_ACTIONS } from 'utils/constants'
import { predictAnswer } from 'api/StudioBackend'
import CircularLoading from 'components/Loading/CircularLoading'
import Warning from 'assets/img/knowledge/icons/warning'
import { KNOWLEDGE_NONE_INTENT } from 'utils/constants'
import { AnswerAction } from '../../../../../../@types/Knowledge/types'

const useStyles = makeStyles()((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  flexContainer: { display: 'flex' },
  actionsContainer: {
    marginTop: theme.spacing(3),
    overflow: 'auto',
  },
  actionsListContainer: {
    marginBottom: theme.spacing(1),
  },
  actionContainer: {
    marginTop: theme.spacing(1),
    display: 'flex',
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius:
      typeof theme.shape.borderRadius === 'number' ? theme.shape.borderRadius * 2 : theme.shape.borderRadius,
  },
  actionContainerRed: {
    color: theme.palette.error.main,
  },
  removeActionTooltip: {
    display: 'inline-block',
    textAlign: 'right',
  },
  tooltip: {
    display: 'inline-block',
    textAlign: 'right',
  },
  textfieldContainer: { marginTop: 'auto', paddingTop: theme.spacing(3) },
  textfield: { width: '100%' },
  addIconButtonContainer: {
    paddingBottom: '19px',
    marginLeft: 'auto',
    marginBottom: 'auto',
    marginTop: 'auto',
  },
  iconButton: {
    color: theme.palette.primary.main,
  },
  icon: {
    fontSize: '1.5rem',
  },
  action: {
    width: '100%',
    maxHeight: '200px',
    overflow: 'auto',
    padding: theme.spacing(2),
  },
  markdown: {
    '& p': { margin: '0px !important' },
  },
  removeButton: {
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
  },
}))

async function loadActionPrediction(
  answers,
  botId,
  action,
): Promise<{ answer: string | null; title: string | null; score: number }> {
  const prediction = await predictAnswer(botId, 'specific', action, 2)
  if (prediction) {
    let answerForIntent = answers.filter((answer) => answer.intent === prediction.topScoringIntent.intent)[0]
    // if predictions and database do not fit togerther - choose None intent
    if (typeof answerForIntent === 'undefined') {
      answerForIntent = answers.filter((answer) => answer.intent === KNOWLEDGE_NONE_INTENT)
      console.error(
        '[Step6FollowUp] prediction for an answer was an intent that does not exist in answers. Fallback to None intent answers. This can happen if the NLU model is not trained after answers were deleted.',
      )
    }
    return {
      answer: answerForIntent.answer,
      title:
        (answerForIntent.topic !== '' ? answerForIntent.topic + '/' : '') +
        answerForIntent.title +
        ` (${Math.round(prediction.topScoringIntent.score * 1000) / 10}%) ${
          answerForIntent.labels[0] ? `- "${answerForIntent.labels[0]}"` : ''
        }`,
      score: prediction.topScoringIntent.score,
    }
  }
  return { answer: null, title: null, score: 0 }
}

type Step6FollowUpProps = {
  actions?: AnswerAction[]
  onSetActions: (actions: AnswerAction[]) => void
  answers: Answer[]
  botId: string
}

export default function StepFollowUpQuestions({
  actions: propsActions = [],
  onSetActions,
  answers,
  botId,
}: Step6FollowUpProps): React.ReactElement {
  const { classes } = useStyles()

  const [actions, setActions] = useState<{ action: AnswerAction; title: string; answer: string; score: number }[]>([])
  const [tmpAction, setTmpAction] = useState<string>('')
  const [actionError, setActionError] = useState<string>()
  const [loadActions, setLoadActions] = useState<string>('notLoaded')

  function onTextChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const trimmedTmpAction = event.target.value.trim()
    // check case insensitive if the entered suggestion already exists
    const suggestionExists =
      actions.filter((action) => action.action.action?.trim().toLowerCase() === trimmedTmpAction.toLowerCase()).length >
      0

    setTmpAction(event.target.value)
    if (suggestionExists) {
      setActionError('Folgefrage existiert für diese Frage bereits.')
    } else {
      setActionError(undefined)
    }
  }

  async function loadActionPredictions(): Promise<void> {
    setLoadActions('loading')
    const _actions: { action: AnswerAction; title: string; answer: string; score: number }[] = []
    if (propsActions && propsActions.length > 0) {
      for (const action of propsActions) {
        // FIXME: we currently do not have this endpoint because at the time we switched to LLM NLU, we had no actual follow up questions in the new webchat
        const prediction = await predictAnswer(botId, 'specific', action.action ?? '', 2)
        if (prediction) {
          const answerForIntent = answers.filter((answer) => answer.intent === prediction.topScoringIntent.intent)[0]
          _actions.push({
            action,
            answer: answerForIntent.answer,
            title:
              (answerForIntent.topic !== '' ? answerForIntent.topic + '/' : '') +
              answerForIntent.title +
              ` (${Math.round(prediction.topScoringIntent.score * 1000) / 10}%) ${
                answerForIntent.labels[0] ? `- "${answerForIntent.labels[0]}"` : ''
              }`,
            score: prediction.topScoringIntent.score,
          })
        }
      }
    }
    setLoadActions('loaded')
    setActions(_actions)
  }

  async function onCreateSuggestionTextfieldSubmit(event): Promise<void> {
    event?.preventDefault()
    const _actions = [...actions]
    const newAction: { action: AnswerAction; title: string; answer: string; score: number } = {
      action: { action: tmpAction, answerId: null },
      title: '',
      answer: '',
      score: 0,
    }

    const { answer, title, score } = await loadActionPrediction(answers, botId, tmpAction)

    newAction.title = title || ''
    newAction.answer = answer || ''
    newAction.score = score

    _actions.push(newAction)
    setActions(_actions)
    setTmpAction('')

    const stringActions: AnswerAction[] = []
    _actions.forEach((action) => stringActions.push(action.action))
    onSetActions(stringActions)
  }

  /**
   * Handles change of existing action
   * @param event
   */
  // function onActionChange(event: React.ChangeEvent<HTMLInputElement>, index: number): void {
  //   const newAction = event.target.value
  //   if (newAction.trim()) {
  //     actions[index] = newAction
  //     const newActions = [...actions]
  //     setActions(newActions)
  //     onSetActions(newActions)
  //   }
  // }

  /**
   * Handles removal of utterance
   * @param index
   */
  function onActionDelete(index: number): void {
    const _actions = [...actions]
    _actions.splice(index, 1)
    setActions(_actions)

    const stringActions: AnswerAction[] = []
    _actions.forEach((action) => stringActions.push(action.action))
    onSetActions(stringActions)
  }

  /**
   * Adds action to state and parent.
   * @param action
   */
  // function onAddAction(action: string): void {
  //   const newAction = action.trim()
  //   const newActions = [...actions, newAction]
  //   setActions(newActions)
  //   onSetActions(newActions)
  // }

  /**
   * Handles form submit of textfield for action input.
   * Adds tmpAction to allActions and resets it.
   * @param event
   */
  // function onCreateActionTextfieldSubmit(event: React.FormEvent): void {
  //   event.preventDefault()
  //   if (tmpAction && !actions.includes(tmpAction.trim())) {
  //     onAddAction(tmpAction)
  //     setTmpAction(undefined)
  //   } else {
  //     setTmpActionError('Dieses Folgethema existiert bereits')
  //   }
  // }

  /**
   * Handles user typing in create action textfield.
   * @param event
   */
  // function onCreateActionTextfieldChange(event: React.ChangeEvent<HTMLInputElement>): void {
  //   setTmpActionError(undefined)
  //   setTmpAction(event.target.value)
  // }

  useEffect(() => {
    const _actions = actions
    if (propsActions.length > 0) {
      propsActions.forEach((action) => _actions.push({ action, title: '', answer: '', score: 0 }))
    }
    setActions(_actions)
    loadActionPredictions()
  }, [])

  return (
    <div className={classes.container}>
      <Typography>
        Folgefragen werden Nutzer:Innen unter einer Antwort angezeigt, sodass diese schnell an weitere Informationen zu
        einem Thema gelangen können. So können Nutzer:Innen zu Themen geführt werden, die für sie von Interesse sind.
        Diese sind optional, es können maximal {KNOWLEDGE_MAX_ACTIONS} Folgefragen angelegt werden und diese dürfen für
        eine gute Benutzerfreundlichkeit maximal 50 Zeichen lang sein.
      </Typography>
      <div className={classes.actionsContainer}>
        {actions.length > 0 && (
          <div className={classes.actionsListContainer}>
            <Typography>Folgefragen</Typography>
            {loadActions === 'loading' ? (
              <CircularLoading text='Folgefragen werden analysiert...' />
            ) : (
              actions.map((action, index) => {
                return (
                  <div key={`action-${index}`} className={classes.actionContainer}>
                    <div className={classes.action}>
                      <Typography variant='h4'>{action.action.action}</Typography>
                      <div style={{ display: 'flex', paddingTop: '12px' }}>
                        {action.score < 0.7 && (
                          <Warning
                            tooltip={
                              <Typography>
                                Der Score für diese Folgefrage liegt unter 70%. Dies bedeutet, dass die Antwort dieser
                                Frage nicht angezeigt wird, wenn Nutzer*innen diese Folgefrage auswählen.
                              </Typography>
                            }
                          />
                        )}
                        <Typography className={action.score < 0.7 ? classes.actionContainerRed : ''}>
                          <strong>Antwort: {action.title !== '' ? action.title : ' -'}</strong>
                        </Typography>
                      </div>
                      <Typography className={classes.markdown}>
                        <ReactMarkdown>{action.answer !== '' ? action.answer : 'Keine Antwort gefunden'}</ReactMarkdown>
                      </Typography>
                    </div>
                    <div className={classes.removeButton}>
                      <Tooltip
                        className={classes.removeActionTooltip}
                        title='Folgefrage entfernen'
                        placement='top'
                        disableInteractive
                      >
                        <IconButton aria-label='Delete' onClick={(): void => onActionDelete(index)}>
                          <Delete classes={{ root: classes.icon }} />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                )
              })
            )}
          </div>
        )}
      </div>
      {/* Textfield for manual addition of paraphrase */}
      <div className={classes.textfieldContainer}>
        <form onSubmit={onCreateSuggestionTextfieldSubmit}>
          <div className={classes.flexContainer}>
            <Textfield
              onChange={onTextChange}
              value={tmpAction}
              placeholder='Frage'
              InputProps={{ autoComplete: 'off' }}
              label={'Folgefrage hinzufügen'}
              helperText={`${actionError ? actionError + ' ' : ''}${
                actions.length >= KNOWLEDGE_MAX_ACTIONS
                  ? 'Sie können maximal ' + KNOWLEDGE_MAX_ACTIONS + ' Folgefragen hinzufügen. '
                  : ''
              }${tmpAction ? tmpAction.length : 0}/50`}
              inputProps={{ maxLength: 50 }}
              className={classes.textfield}
              error={!!actionError || tmpAction?.length > 50}
              disabled={actions.length === KNOWLEDGE_MAX_ACTIONS}
            />
            <div className={classes.addIconButtonContainer}>
              <Tooltip className={classes.tooltip} title='Folgefrage hinzufügen' placement='top' disableInteractive>
                <IconButton
                  type='submit'
                  aria-label='Add'
                  className={classes.iconButton}
                  disabled={
                    actions.length === KNOWLEDGE_MAX_ACTIONS || !!actionError || !tmpAction || tmpAction.trim() === ''
                  }
                >
                  <Add className={classes.icon} />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
