import { ApexOptions } from 'apexcharts'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()({
  xAxisLabels: {
    fontSize: '12px',
  },
})

/**
 * Returns options for linechart
 * @param linechartId
 * @param xAxisTitle
 * @param yAxisTitle
 * @param formatXAxisLabels
 * @param formatYAxisLabel
 * @param formatTooltipTitle
 * @param type
 * @returns
 */
export function getLinechartOptions(
  linechartId: string,
  formatXAxisLabels: (value: string, timestamp?: number, opts?: any) => string | string[],
  formatYAxisLabel: (value: number) => string,
  formatTooltipTitle: (val: number, opts?: any) => string,
  formatTooltipValue: (val: number, opts?: any) => string,
  type: 'category' | 'datetime' | 'numeric' = 'category',
  xAxisTitle?: string,
  yAxisTitle?: string,
): ApexOptions {
  const { classes } = useStyles()
  return {
    colors: [],
    chart: {
      id: linechartId,
      // offsetX: -15,
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: [],
        },
      },
      animations: {
        enabled: false,
        speed: 200,
        easing: 'easeinout',
      },
    },
    grid: {
      show: true,
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    xaxis: {
      title: {
        text: xAxisTitle,
        // style: { cssClass: classes.xAxisTitle },
      },
      axisTicks: {
        show: true,
      },
      labels: {
        show: true,
        hideOverlappingLabels: true,
        minHeight: 40,
        rotate: -90,
        trim: false,
        offsetX: -1.5,
        formatter: formatXAxisLabels,
        style: {
          cssClass: classes.xAxisLabels,
        },
      },
      type: 'category',
      tooltip: {
        enabled: false,
      },
    },
    legend: {
      show: false,
    },
    yaxis: {
      title: { text: yAxisTitle },
      labels: {
        formatter: formatYAxisLabel,
      },
      min: 0,
    },
    stroke: {
      show: true,
      curve: 'smooth',
      width: 3,
    },
    tooltip: {
      enabled: true,
      x: {
        show: true,
        formatter: formatTooltipTitle,
      },
      y: {
        formatter: formatTooltipValue,
      },
      onDatasetHover: {
        highlightDataSeries: true,
      },
    },
    noData: {
      text: 'Keine Daten oder nichts ausgewählt.',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: '14px',
        fontFamily: undefined,
      },
    },
  }
}

/**
 * Returns chart options for Apex Heatmap Chart
 * @returns
 */
export function getHeatmapOptions(
  linechartId: string,
  xType: 'hour',
  yType: 'dayOfWeek',
  tooltipXFormatter: (val: number, opts?: any) => string,
  tooltipYFormatter?: (val: number, opts?: any) => string,
  xAxisTitle?: string,
  yAxisTitle?: string,
  valueDescription?: string, // description of value, is used instead of tooltipYFormatter if provided
): ApexOptions {
  return {
    chart: {
      id: linechartId,
      offsetX: -15,
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: [],
        },
      },
      animations: {
        enabled: true,
        speed: 200,
        easing: 'easein',
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ['#0b2639'],
    xaxis: {
      title: { text: xAxisTitle, offsetY: 15, style: { fontWeight: 300 } },
      axisTicks: {
        show: true,
      },
      tickAmount: 24,
      range: 24,
      labels: {
        show: true,
      },
      type: 'category',
      tickPlacement: 'between',
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      title: { text: yAxisTitle, style: { fontWeight: 300 } },
    },
    stroke: {
      show: true,
    },
    tooltip: {
      enabled: true,
      x: {
        show: true,
        formatter: xType === 'hour' ? tooltipXFormatter : undefined,
      },
      y: {
        formatter: valueDescription ? () => valueDescription : yType === 'dayOfWeek' ? tooltipYFormatter : undefined,
      },
    },
  }
}
