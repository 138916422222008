import React from 'react'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(() => ({
  checkmarkCircle: {
    // 'stroke-dasharray': '5 3',
    'stroke-dashoffset': 216,
    'stroke-width': 2,
    'stroke-miterlimit': 10,
    stroke: '#4CAE4F',
    fill: 'none',
    animation: '$stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards',
  },
  checkmark: {
    width: '106px',
    height: '106px',
    borderRadius: '50%',
    // display: 'block',
    'stroke-width': 4,
    'stroke-linecap': 'round',
    stroke: '#4CAE4F',
    'stroke-miterlimit': 10,
    boxShadow: 'inset 0px 0px 0px #4CAE4F',
    animation: '$fill .4s ease-in-out .4s forwards, $scale .3s ease-in-out .9s both',
  },
  checkmarkCheck: {
    'transform-origin': '50% 50%',
    // 'stroke-dasharray': '5 3',
    'stroke-dashoffset': 98,
    animation: '$stroke 1s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards',
  },
  '@keyframes stroke': {
    '100%': {
      'stroke-dashoffset': 0,
    },
  },
  '@keyframes scale': {
    '0%, 100%': {
      transform: 'none',
    },
    '50%': {
      transform: 'scale3d(1.1, 1.1, 1)',
    },
  },
  '@keyframes fill': {
    '100%': {
      boxShadow: 'inset 0px 0px 0px 80px #4CAE4F',
    },
  },
}))

export default function Checkmark(): React.ReactElement {
  const { classes } = useStyles()

  return (
    <svg className={classes.checkmark} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52 52'>
      <circle className={classes.checkmarkCircle} cx='26' cy='26' r='25' fill='none' />
      <path className={classes.checkmarkCheck} fill='none' d='M14.1 27.2l7.1 7.2 16.7-16.8' />
      {/*// </svg>
    // <svg width='762' height='391' viewBox='0 0 762 391' fill='none' xmlns='http://www.w3.org/2000/svg'>
    //   <g className={classes.checkmark}> */}
      {/* <circle className={classes.checkmarkCircle} cx='349.94348' cy='149.9137' r='56.800583' fill='#008000' />
      <path className={classes.checkmarkCheck} d='m 322.725,152.2 15.975,16.2 37.575,-37.8' fill='#c4c4c4' /> */}
      {/* </g> */}
      {/* <path fill='none' d='m 14.1,27.2 7.1,7.2 16.7,-16.8' id='path835' />
      <path
        d='M 158.679,47.3901 C 164.014,37.3195 164.895,24.2029 158.261,14.936 154.313,9.41971 148.147,5.79867 141.678,3.75624 135.209,1.71381 128.385,1.11349 121.619,0.61714 114.922,0.1258 108.105,-0.267493 101.563,1.24727 95.0206,2.76203 88.6835,6.46256 85.4965,12.3735 80.1125,22.3589 85.017,35.1629 81.0626,45.7958 77.4336,55.5536 67.1053,61.2583 56.9523,63.5604 46.7992,65.8626 36.2021,65.6176 26.044,67.8974 15.8859,70.1773 5.54042,75.8291 1.84711,85.5627 c -2.670749,7.0387 -1.343799,15.1463 2.17543,21.8013 3.51923,6.655 9.03086,12.043 14.88126,16.781 8.7277,7.069 18.9387,13.122 30.153,13.735 11.8163,0.646 23.3701,-4.979 31.8748,-13.208 8.5048,-8.229 14.2948,-18.862 18.9207,-29.7546 2.9347,-6.91 5.7077,-14.3465 11.5887,-19.0128 4.321,-3.4287 9.817,-4.9308 15.148,-6.3516 l 6.036,-1.6088 c 5.469,-1.431 10.588,-3.966 15.041,-7.449 4.453,-3.483 8.146,-7.8403 10.853,-12.8039 0.054,-0.1001 0.107,-0.2005 0.16,-0.3012 z'
        fill='#2f2e41'
        id='path849'
      />
      <path d='M 111.079,378.665 H 98.8198 l -5.833,-47.288 h 18.0952 z' fill='#ffb8b8' id='path851' />
      <path
        d='m 90.0626,375.162 h 23.6444 v 14.887 H 75.1758 c 0,-1.955 0.385,-3.891 1.1332,-5.697 0.7481,-1.807 1.8447,-3.448 3.227,-4.83 1.3824,-1.382 3.0235,-2.479 4.8297,-3.227 1.8061,-0.748 3.742,-1.133 5.6969,-1.133 z'
        fill='#2f2e41'
        id='path853'
      />
      <path d='M 213.079,378.665 H 200.82 l -5.833,-47.288 h 18.095 z' fill='#ffb8b8' id='path855' />
      <path
        d='m 192.063,375.162 h 23.644 v 14.887 h -38.531 c 0,-1.955 0.385,-3.891 1.133,-5.697 0.748,-1.807 1.845,-3.448 3.227,-4.83 1.382,-1.382 3.024,-2.479 4.83,-3.227 1.806,-0.748 3.742,-1.133 5.697,-1.133 z'
        fill='#2f2e41'
        id='path857'
      />
      <path
        d='m 134.646,341.08 c -0.171,0 -0.342,-0.009 -0.512,-0.027 l -49.8776,-5.345 c -0.6624,-0.07 -1.3021,-0.282 -1.8765,-0.619 -0.5744,-0.338 -1.0704,-0.794 -1.4548,-1.338 -0.3844,-0.544 -0.6485,-1.164 -0.7747,-1.818 -0.1262,-0.654 -0.1117,-1.328 0.0428,-1.976 l 44.1628,-167.97 c 0.206,-0.855 0.648,-1.634 1.274,-2.252 0.627,-0.617 1.413,-1.046 2.271,-1.24 l 40.968,-11.42 c 0.821,-0.181 1.676,-0.139 2.476,0.122 0.8,0.26 1.515,0.73 2.073,1.36 26.34,29.71 46.19,95.571 61.227,178.585 0.113,0.626 0.098,1.269 -0.045,1.889 -0.143,0.621 -0.411,1.206 -0.787,1.719 -0.377,0.514 -0.853,0.945 -1.402,1.269 -0.548,0.323 -1.157,0.532 -1.788,0.612 l -40.868,5.218 c -1.159,0.151 -2.333,-0.138 -3.29,-0.809 -0.957,-0.671 -1.628,-1.676 -1.881,-2.818 l -19.883,-88.648 c -0.183,-0.816 -0.641,-1.544 -1.297,-2.063 -0.655,-0.519 -1.469,-0.797 -2.306,-0.787 -0.836,0.01 -1.643,0.306 -2.287,0.84 -0.643,0.534 -1.084,1.273 -1.248,2.093 l -18.33,91.651 c -0.208,1.063 -0.779,2.021 -1.616,2.71 -0.837,0.688 -1.887,1.064 -2.971,1.062 z'
        fill='#2f2e41'
        id='path859'
      />
      <path
        d='m 127.059,70.4551 c 13.565,0 24.561,-10.9964 24.561,-24.5611 0,-13.5646 -10.996,-24.561 -24.561,-24.561 -13.565,0 -24.561,10.9964 -24.561,24.561 0,13.5647 10.996,24.5611 24.561,24.5611 z'
        fill='#ffb8b8'
        id='path861'
      />
      <path
        d='m 126.373,162.459 c -1.032,0 -2.035,-0.343 -2.853,-0.973 -0.818,-0.631 -1.404,-1.514 -1.668,-2.512 l -14.776,-55.51 c -1.066,-3.9882 -0.625,-8.2301 1.241,-11.9135 1.865,-3.6834 5.023,-6.5498 8.869,-8.0506 11.894,-4.6427 23.226,-6.3651 33.681,-5.1212 9.292,1.1075 20.754,56.1183 19.996,57.2923 l 3.667,10.999 c 0.208,0.624 0.282,1.285 0.218,1.939 -0.064,0.655 -0.264,1.288 -0.589,1.86 -0.324,0.572 -0.766,1.069 -1.295,1.46 -0.529,0.39 -1.134,0.665 -1.776,0.806 l -43.695,9.612 c -0.335,0.075 -0.677,0.112 -1.02,0.112 z'
        fill='#cccccc'
        id='path863'
      />
      <path
        d='m 139.615,219.226 c 0.211,-1.092 0.179,-2.218 -0.092,-3.297 -0.272,-1.079 -0.777,-2.085 -1.479,-2.948 -0.702,-0.862 -1.586,-1.561 -2.587,-2.045 -1.002,-0.485 -2.097,-0.744 -3.21,-0.759 l -8.513,-25.67 -8.7,11.037 9.418,22.684 c 0.098,1.871 0.879,3.64 2.194,4.974 1.316,1.333 3.075,2.137 4.944,2.26 1.869,0.123 3.719,-0.443 5.198,-1.593 1.479,-1.149 2.485,-2.801 2.827,-4.643 z'
        fill='#ffb8b8'
        id='path865'
      />
      <path
        d='m 249.063,157.534 c -0.51,-0.989 -1.23,-1.854 -2.11,-2.535 -0.88,-0.68 -1.899,-1.16 -2.985,-1.404 -1.085,-0.244 -2.211,-0.247 -3.298,-0.009 -1.087,0.238 -2.108,0.711 -2.992,1.386 l -22.556,-14.921 -0.02,14.053 21.422,12.016 c 1.233,1.41 2.941,2.319 4.799,2.554 1.858,0.235 3.738,-0.219 5.284,-1.278 1.546,-1.058 2.649,-2.647 3.102,-4.464 0.452,-1.818 0.223,-3.738 -0.646,-5.398 z'
        fill='#ffb8b8'
        id='path867'
      />
      <path
        d='m 121.768,207.366 c -0.987,10e-4 -1.948,-0.31 -2.748,-0.888 -0.8,-0.578 -1.397,-1.393 -1.706,-2.329 L 97.3568,161.932 c -0.8185,-2.474 -1.2353,-5.063 -1.2347,-7.669 l 5.9999,-54.8712 c 0,-3.0722 1.172,-6.0287 3.278,-8.2658 2.105,-2.2372 4.985,-3.5864 8.052,-3.7721 3.066,-0.1858 6.088,0.8059 8.449,2.7725 2.36,1.9667 3.88,4.76 4.251,7.8097 l -9.18,62.6309 14.649,38.44 c 0.232,1.023 0.114,2.094 -0.336,3.041 -0.449,0.948 -1.204,1.717 -2.142,2.184 l -5.278,2.64 c -0.651,0.325 -1.369,0.495 -2.097,0.494 z'
        fill='#cccccc'
        id='path869'
      />
      <path
        d='m 228.352,163.076 c -0.735,0.001 -1.46,-0.172 -2.115,-0.503 l -39.688,-20.047 c -2.327,-1.175 -4.45,-2.714 -6.289,-4.561 L 141.523,99.1028 c -2.169,-2.1758 -3.425,-5.0973 -3.513,-8.1682 -0.088,-3.0708 0.999,-6.0593 3.04,-8.3556 2.041,-2.2962 4.881,-3.727 7.941,-4.0002 3.06,-0.2732 6.109,0.6318 8.524,2.5301 l 41.963,46.6031 33.263,21.12 c 0.886,0.56 1.558,1.402 1.909,2.39 0.35,0.989 0.359,2.066 0.024,3.06 l -1.875,5.595 c -0.313,0.932 -0.91,1.742 -1.708,2.316 -0.798,0.574 -1.756,0.883 -2.739,0.883 z'
        fill='#cccccc'
        id='path871'
      />
      <path
        d='m 99.1221,33.1956 c 4.1719,5.4579 10.1689,9.2317 16.8949,10.6313 6.818,1.4088 13.913,0.3922 20.06,-2.8741 4.947,-2.6727 8.905,-6.6841 12.782,-10.6334 1.618,-1.6482 3.342,-3.549 3.26,-5.7975 -0.126,-3.4836 -4.314,-5.3882 -7.908,-6.148 -11.799,-2.4613 -24.076,-1.0807 -35.033,3.9398 -4.88,2.2668 -9.9645,6.3679 -9.2318,11.4658'
        fill='#2f2e41'
        id='path873'
      />
      <path
        d='m 744.122,39.4453 h -482 c -4.507,0.0064 -8.827,1.7995 -12.014,4.9862 -3.186,3.1868 -4.98,7.5071 -4.986,12.0138 V 242.445 c 0.006,4.507 1.8,8.827 4.986,12.014 3.187,3.187 7.507,4.98 12.014,4.986 h 482 c 4.507,-0.006 8.827,-1.799 12.014,-4.986 3.187,-3.187 4.98,-7.507 4.986,-12.014 V 56.4453 c -0.006,-4.5067 -1.799,-8.827 -4.986,-12.0138 -3.187,-3.1867 -7.507,-4.9798 -12.014,-4.9862 z m 15,202.9997 c -0.005,3.977 -1.587,7.79 -4.399,10.602 -2.812,2.812 -6.624,4.393 -10.601,4.398 h -482 c -3.977,-0.005 -7.789,-1.586 -10.601,-4.398 -2.812,-2.812 -4.394,-6.625 -4.399,-10.602 V 56.4453 c 0.005,-3.9767 1.587,-7.7892 4.399,-10.6012 2.812,-2.812 6.624,-4.3939 10.601,-4.3988 h 482 c 3.977,0.0049 7.789,1.5868 10.601,4.3988 2.812,2.812 4.394,6.6245 4.399,10.6012 z'
        fill='#3f3d56'
        id='path875'
      />
      <path
        d='m 349.636,207.445 c -11.472,0 -22.685,-3.401 -32.223,-9.774 -9.538,-6.374 -16.972,-15.432 -21.362,-26.03 -4.39,-10.598 -5.539,-22.26 -3.301,-33.511 2.238,-11.251 7.762,-21.585 15.874,-29.697 8.111,-8.111 18.446,-13.6353 29.697,-15.8732 11.25,-2.238 22.912,-1.0894 33.51,3.3005 10.599,4.3897 19.657,11.8237 26.03,21.3617 6.373,9.538 9.775,20.752 9.775,32.223 -0.018,15.378 -6.134,30.12 -17.007,40.993 -10.874,10.874 -25.616,16.99 -40.993,17.007 z m 0,-113.9996 c -11.076,0 -21.903,3.2843 -31.112,9.4376 -9.209,6.153 -16.387,14.899 -20.625,25.132 -4.239,10.233 -5.348,21.492 -3.187,32.355 2.161,10.863 7.494,20.842 15.326,28.673 7.832,7.832 17.81,13.166 28.673,15.326 10.863,2.161 22.122,1.052 32.355,-3.186 10.233,-4.239 18.979,-11.417 25.132,-20.626 6.153,-9.209 9.438,-20.036 9.438,-31.112 -0.017,-14.847 -5.922,-29.081 -16.421,-39.579 C 378.717,99.3675 364.483,93.4622 349.636,93.4454 Z'
        fill='#3f3d56'
        id='path877'
      />
      <path
        d='m 701.108,112.445 h -231 c -3.229,0.002 -6.331,1.254 -8.658,3.493 -2.326,2.238 -3.696,5.291 -3.822,8.517 -0.016,0.163 -0.023,0.327 -0.02,0.49 0.005,3.314 1.323,6.491 3.667,8.834 2.343,2.343 5.519,3.662 8.833,3.666 h 231 c 3.315,0 6.495,-1.317 8.839,-3.661 2.344,-2.344 3.661,-5.523 3.661,-8.839 0,-3.315 -1.317,-6.494 -3.661,-8.839 -2.344,-2.344 -5.524,-3.661 -8.839,-3.661 z'
        fill='#4cae4f'
        id='path879'
      />
      <path
        d='m 701.108,161.445 h -231 c -3.229,0.002 -6.331,1.254 -8.658,3.493 -2.326,2.238 -3.696,5.291 -3.822,8.517 -0.016,0.163 -0.023,0.327 -0.02,0.49 0.005,3.314 1.323,6.491 3.667,8.834 2.343,2.343 5.519,3.662 8.833,3.666 h 231 c 3.315,0 6.495,-1.317 8.839,-3.661 2.344,-2.344 3.661,-5.523 3.661,-8.839 0,-3.315 -1.317,-6.494 -3.661,-8.839 -2.344,-2.344 -5.524,-3.661 -8.839,-3.661 z'
        fill='#4cae4f'
        id='path881'
      /> */}
    </svg>
  )
}
