import React, { useEffect, useState } from 'react'
import { Route, useNavigate, useParams, Link, useLocation, Routes, Navigate } from 'react-router-dom'
// Material ui
import Drawer from '@mui/material/Drawer'
import Typography from '@mui/material/Typography'
import { makeStyles } from 'tss-react/mui'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import Skeleton from '@mui/material/Skeleton'
// Custom Components
import CustomizedTooltip from 'components/Tooltips/CustomContentTooltip'
import Button from 'components/Buttons/Button'
import { Can } from 'components/Can/Can'
// Context Providers
import { useBotContext } from 'hooks/contexts/bot-context'
// Constants
import {
  ROUTE_BOTID_ANALYTICS,
  ROUTE_BOTID_CARDS,
  ROUTE_BOTID_DESIGNER,
  ROUTE_BOTID_KNOWLEDGE,
  ROUTE_BOTID_KNOWLEDGE_MODULE,
  ROUTE_BOTID_DOCUMENTS_PDFINGEST,
  ROUTE_BOTID_DOCUMENTS,
  ROUTE_BOTID_SETTINGS,
  ROUTE_BOTID_TRANSLATIONS,
  ROUTE_BOTS,
  ROUTE_CREATEBOT,
  ROUTE_HELP,
  ROUTE_BOTID_NOTIFICATIONS,
  MODULE_TYPE_NLU,
  MODULE_TYPE_ALEPHALPHA,
  MODULE_NAME_CONVAISE_NLU,
  MODULE_NAME_ALEPH_ALPHA_LUMI,
  MODULE_NAME_CONVAISE_RAG,
  MODULE_TYPE_RAG,
} from 'utils/constants'
const drawerWidth = {
  open: 260,
  close: 92, // smallest possible because of paddigng + min width of buttons
}
// APIs
import { getBotInformation } from 'api/StudioBackend'
// Auth
import { useMsal } from '@azure/msal-react'
// Assets
import logo from 'assets/img/logos/convaise-logo-v8.svg'
import logoIcon from 'assets/img/logos/convaise-logo-v8-icon.svg'
// Pages
import Analytics from 'pages/Analytics/Analytics'
import FlowDesigner from 'pages/FlowDesigner/FlowDesigner'
import Knowledge from 'pages/Knowledge/Knowledge'
import Help from 'pages/Help/Help'
import Settings from 'pages/Settings/Settings'
import { FlowdesignerContextProvider } from 'hooks/contexts/flowdesigner-context'
// import { Ingest } from 'index-playgrounds'
import CustomNotifications from 'pages/CustomNotifications/CustomNotifications'
import Translations from 'pages/Translations/Translations'
import { LockingContextProvider } from 'hooks/contexts/locking-context'
import { CustomNotificationsContextProvider } from 'hooks/contexts/customnotifications-context'
import Documents from 'pages/Documents/Documents'
import Insights from 'pages/Analytics/Insights'
import { ChartContextProvider } from 'hooks/contexts/chart-context'
import { TranslationsContextProvider } from 'hooks/contexts/translations-context'
import { AnswersContextProvider } from 'hooks/contexts/answers-context'

const useStyles = makeStyles()((theme) => ({
  // root: {
  //   display: 'flex',
  // },
  drawer: {
    width: drawerWidth.open,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(0, 2, 2, 2),
    overflowX: 'hidden',
  },
  drawerOpen: {
    width: drawerWidth.open,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
    overflowX: 'hidden',
    width: drawerWidth.close,
    // [theme.breakpoints.up('sm')]: {
    //   width: theme.spacing(9),
    // },
  },
  sideBarLogoContainer: {
    width: '100%',
    // height: '140px',
    marginTop: theme.spacing(6),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    '& img': {
      maxWidth: '100%',
      height: '32px',
      // transition: theme.transitions.create('all', {
      //   easing: theme.transitions.easing.,
      //   duration: theme.transitions.duration.leavingScreen,
      // }),
    },
  },
  sideBarContentContainer: {
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
  },
  botName: {
    textAlign: 'center',
    // WebkitBoxOrient: 'vertical',
    // WebkitLineClamp: 2,
    // display: '-webkit-box',
    overflow: 'hidden',
    wordWrap: 'break-word',
    textOverflow: 'ellipsis',
    marginBottom: theme.spacing(6),
  },
  botNameIcon: {
    // height: theme.typography.h4.fontSize,
    // width: theme.typography.h4.fontSize,
    fontSize: theme.typography.h4.fontSize,
    textAlign: 'center',
    marginBottom: theme.spacing(6),
    maxHeight: '22px',
    color: theme.palette.grey.A200,
    fontWeight: 400,
    '& :hover': {
      color: theme.palette.text.primary,
    },
  },
  navButton: {
    marginTop: theme.spacing(1),
    justifyContent: 'left',
    paddingLeft: '22px',
    width: '100%',
  },
  navButtonNotActive: {
    color: theme.palette.grey.A200,
    '& :hover': {
      color: theme.palette.text.primary,
    },
  },
  navButtonToolTipWrapper: {
    width: '100%',
  },
  navButtonBottom: {
    marginTop: 'auto',
  },
  sidebarButton: {
    width: '100%',
    color: theme.palette.grey.A200,
    '& :hover': {
      color: theme.palette.text.primary,
    },
    marginTop: theme.spacing(1),
    justifyContent: 'left',
    paddingLeft: '22px',
  },
}))

/**
 *
 * @returns React.ReactElement
 */
function BotLayout(): React.ReactElement {
  const { pathname: path } = useLocation()
  const { botId } = useParams()
  const { bot, setBot } = useBotContext()
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false)
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)
  const navigate = useNavigate()
  const location = useLocation()
  const { classes, cx } = useStyles()
  const { instance: PCA } = useMsal()

  function redirectToAgents(): void {
    navigate(ROUTE_BOTS)
  }

  // Check bot context
  useEffect((): void => {
    async function fetchData(): Promise<void> {
      if (!botId) return
      const data = await getBotInformation(botId)
      if (data) {
        // user was able to get the BotInformation so update the context
        setBot(data.botInfos, data.granularPermissions)
      } else {
        // No bot given in context and bot was not able to load
        // -> maybe user has no access to the given bot
        // Redirect to the bots overview page
        redirectToAgents()
      }
    }

    // check botId from url with botId in context and load information if necessary
    if (bot === null || (bot && bot.id !== botId)) {
      fetchData()
    }
  }, [bot, botId])

  // Account Menu Handling
  const handleClick = (event): void => {
    setMenuAnchorEl(event.currentTarget)
  }
  const handleClose = (): void => {
    setMenuAnchorEl(null)
  }

  // check whether knowledge management should be displayed
  const moduleNames = Object.values(bot?.modules ?? {}).map((module) => module.name)
  const displayKnowledge = !bot
    ? true // if bot infos are not loaded yet, we display the knowledge option. Most bots have knowledge, so we decided to "make it jump" for only bots that don't have knowledge.
    : !!(
        moduleNames.includes(MODULE_NAME_CONVAISE_NLU) ||
        moduleNames.includes(MODULE_NAME_ALEPH_ALPHA_LUMI) ||
        moduleNames.includes(MODULE_NAME_CONVAISE_RAG)
      )

  return (
    <>
      {/* <div className={classes.root}> */}
      {/* Sidebar */}
      <Drawer
        variant='permanent'
        className={cx(classes.drawer, {
          [classes.drawerOpen]: sidebarOpen,
          [classes.drawerClose]: !sidebarOpen,
        })}
        classes={{
          paper: cx(classes.paper, {
            [classes.drawerOpen]: sidebarOpen,
            [classes.drawerClose]: !sidebarOpen,
          }),
        }}
      >
        {/* Convaise Logo which is also a link to agents */}
        <div className={classes.sideBarLogoContainer}>
          <Link to={ROUTE_BOTS}>
            {sidebarOpen ? <img src={logo} alt='Convaise Logo' /> : <img src={logoIcon} alt='Convaise Logo' />}
          </Link>
        </div>
        {/* Sidebar Main Content */}
        <div className={classes.sideBarContentContainer}>
          {/* Show the Assistants name */}
          <CustomizedTooltip
            content={
              !sidebarOpen || (bot && bot.name && bot.name.length > 21) ? (
                <Typography variant='body1'>{bot && bot.name}</Typography>
              ) : null
            }
            elements={
              sidebarOpen ? (
                <Typography variant='h4' className={classes.botName}>
                  {bot && bot.name ? bot.name : <Skeleton width='180px' height='22px' style={{ margin: '0 auto' }} />}
                </Typography>
              ) : (
                <i className={'ri-information-line' + ' ' + classes.botNameIcon} />
              )
            }
          />
          <Can I='read' a='analytics'>
            <CustomizedTooltip
              disableInteractive
              content={sidebarOpen ? null : <Typography variant='body1'>Analytics</Typography>}
              elements={
                <div className={classes.navButtonToolTipWrapper}>
                  <Button
                    className={cx(classes.navButton, {
                      [classes.navButtonNotActive]: !location.pathname.includes(ROUTE_BOTID_ANALYTICS),
                    })}
                    variant={location.pathname.includes(ROUTE_BOTID_ANALYTICS) ? 'primary' : 'tertiary'}
                    onClick={(): void => navigate(ROUTE_BOTS + `/${botId}` + ROUTE_BOTID_ANALYTICS)}
                    icon='pie-chart-line'
                    iconType='remix'
                  >
                    {sidebarOpen && 'Analytics'}
                  </Button>
                </div>
              }
            />
          </Can>
          {/* Routes - are checked if they can be accessed, show a tooltip if minified sidebar */}
          <Can I='read' a='floweditor'>
            <CustomizedTooltip
              disableInteractive
              content={sidebarOpen ? null : <Typography variant='body1'>Dialog Designer</Typography>}
              elements={
                <div className={classes.navButtonToolTipWrapper}>
                  <Button
                    className={cx(classes.navButton, {
                      [classes.navButtonNotActive]: !location.pathname.includes(ROUTE_BOTID_DESIGNER),
                    })}
                    variant={location.pathname.includes(ROUTE_BOTID_DESIGNER) ? 'primary' : 'tertiary'}
                    onClick={(): void => navigate(ROUTE_BOTS + `/${botId}` + ROUTE_BOTID_DESIGNER)}
                    icon='mind-map'
                    iconType='remix'
                  >
                    {sidebarOpen && 'Dialog Designer'}
                  </Button>
                </div>
              }
            />
          </Can>
          {displayKnowledge && (
            <Can I='read' a='knowledge'>
              <CustomizedTooltip
                disableInteractive
                content={sidebarOpen ? null : <Typography variant='body1'>Wissen</Typography>}
                elements={
                  <div className={classes.navButtonToolTipWrapper}>
                    <Button
                      className={cx(classes.navButton, {
                        [classes.navButtonNotActive]: !location.pathname.includes(ROUTE_BOTID_KNOWLEDGE),
                      })}
                      variant={location.pathname.includes(ROUTE_BOTID_KNOWLEDGE) ? 'primary' : 'tertiary'}
                      onClick={(): void => {
                        const modules = Object.values(bot?.modules ?? {})

                        if (modules.map((module) => module.type).includes(MODULE_TYPE_NLU)) {
                          // NLU knowledge
                          const moduleConfigId = modules.find((module) => module.type === MODULE_TYPE_NLU)
                            ?.moduleConfigId
                          navigate(ROUTE_BOTS + `/${botId}` + ROUTE_BOTID_KNOWLEDGE + `/${moduleConfigId}`)
                        } else if (modules.map((module) => module.type).includes('rag')) {
                          const moduleConfigId = modules.find((module) => module.type === MODULE_TYPE_RAG)
                            ?.moduleConfigId
                          navigate(ROUTE_BOTS + `/${botId}` + ROUTE_BOTID_KNOWLEDGE + `/${moduleConfigId}`)
                        } else if (modules.map((module) => module.type).includes(MODULE_TYPE_ALEPHALPHA)) {
                          // Aleph alpha knowledge / data management
                          const moduleConfigId = modules.find((module) => module.type === MODULE_TYPE_ALEPHALPHA)
                            ?.moduleConfigId
                          navigate(ROUTE_BOTS + `/${botId}` + ROUTE_BOTID_KNOWLEDGE + `/${moduleConfigId}`)
                        }
                      }}
                      icon='chat-smile-3-line'
                      iconType='remix'
                    >
                      {sidebarOpen && 'Wissen'}
                    </Button>
                  </div>
                }
              />
            </Can>
          )}

          {/* <Can I='read' a='smartcards'>
          <CustomizedTooltip
            disableInteractive
            content={sidebarOpen ? null : <Typography variant='body1'>Cards</Typography>}
            elements={
              <div className={classes.navButtonToolTipWrapper}>
                <Button
                  className={clsx(classes.navButton, {
                    [classes.navButtonNotActive]: !location.pathname.includes(ROUTE_BOTID_CARDS),
                  })}
                  variant={location.pathname.includes(ROUTE_BOTID_CARDS) ? 'primary' : 'tertiary'}
                  onClick={(): void => navigate(ROUTE_BOTS + `/${botId}` + ROUTE_BOTID_CARDS)}
                  icon='draft-line'
                  iconType='remix'
                >
                  {sidebarOpen && 'Cards'}
                </Button>
              </div>
            }
          />
        </Can> */}
          <Can I='read' a='translations'>
            <CustomizedTooltip
              disableInteractive
              content={sidebarOpen ? null : <Typography variant='body1'>Sprachen & Übersetzungen</Typography>}
              elements={
                <div className={classes.navButtonToolTipWrapper}>
                  <Button
                    className={cx(classes.navButton, {
                      [classes.navButtonNotActive]: !location.pathname.includes(ROUTE_BOTID_TRANSLATIONS),
                    })}
                    variant={location.pathname.includes(ROUTE_BOTID_TRANSLATIONS) ? 'primary' : 'tertiary'}
                    onClick={(): void => navigate(ROUTE_BOTS + `/${botId}` + ROUTE_BOTID_TRANSLATIONS)}
                    icon='translate'
                    iconType='remix'
                  >
                    {sidebarOpen && 'Sprachen & Übersetzungen'}
                  </Button>
                </div>
              }
            />
          </Can>
          {/* TODO: Permissions for notifications */}
          {bot?.customNotifications?.notificationsEnabled ? (
            <Can I='read' a='notifications'>
              <CustomizedTooltip
                disableInteractive
                content={sidebarOpen ? null : <Typography variant='body1'>Notifications</Typography>}
                elements={
                  <div className={classes.navButtonToolTipWrapper}>
                    <Button
                      className={cx(classes.navButton, {
                        [classes.navButtonNotActive]: !location.pathname.includes(ROUTE_BOTID_NOTIFICATIONS),
                      })}
                      variant={location.pathname.includes(ROUTE_BOTID_NOTIFICATIONS) ? 'primary' : 'tertiary'}
                      onClick={(): void => navigate(ROUTE_BOTS + `/${botId}` + ROUTE_BOTID_NOTIFICATIONS)}
                      icon='notification-4-line'
                      iconType='remix'
                    >
                      {sidebarOpen && 'Notifications'}
                    </Button>
                  </div>
                }
              />
            </Can>
          ) : null}
          <Can I='read' a='documents_pdf'>
            <CustomizedTooltip
              disableInteractive
              content={sidebarOpen ? null : <Typography variant='body1'>Dokumente</Typography>}
              elements={
                <div className={classes.navButtonToolTipWrapper}>
                  <Button
                    className={cx(classes.navButton, {
                      [classes.navButtonNotActive]: !location.pathname.includes(ROUTE_BOTID_DOCUMENTS),
                    })}
                    variant={location.pathname.includes(ROUTE_BOTID_DOCUMENTS) ? 'primary' : 'tertiary'}
                    onClick={(): void => navigate(ROUTE_BOTS + `/${botId}` + ROUTE_BOTID_DOCUMENTS)}
                    icon='folder-open-line'
                    iconType='remix'
                  >
                    {sidebarOpen && 'Dokumente'}
                  </Button>
                </div>
              }
            />
          </Can>
          <Can I='update' a='bot'>
            <CustomizedTooltip
              disableInteractive
              content={sidebarOpen ? null : <Typography variant='body1'>Einstellungen</Typography>}
              elements={
                <div className={classes.navButtonToolTipWrapper}>
                  <Button
                    className={cx(classes.navButton, {
                      [classes.navButtonNotActive]: !location.pathname.includes(ROUTE_BOTID_SETTINGS),
                    })}
                    variant={location.pathname.includes(ROUTE_BOTID_SETTINGS) ? 'primary' : 'tertiary'}
                    onClick={(): void => navigate(ROUTE_BOTS + `/${botId}` + ROUTE_BOTID_SETTINGS)}
                    icon='settings-4-line'
                    iconType='remix'
                  >
                    {sidebarOpen && 'Einstellungen'}
                  </Button>
                </div>
              }
            />
          </Can>
          <CustomizedTooltip
            disableInteractive
            content={sidebarOpen ? null : <Typography variant='body1'>Hilfe & Feedback</Typography>}
            elements={
              <div className={classes.navButtonToolTipWrapper + ' ' + classes.navButtonBottom}>
                <Button
                  className={cx(classes.navButton, {
                    [classes.navButtonNotActive]: !location.pathname.includes(ROUTE_HELP),
                  })}
                  variant={location.pathname.includes(ROUTE_HELP) ? 'primary' : 'tertiary'}
                  onClick={(): void => navigate(ROUTE_BOTS + `/${botId}` + ROUTE_HELP)}
                  icon='article-line'
                  iconType='remix'
                >
                  {sidebarOpen && 'Hilfe & Feedback'}
                </Button>
              </div>
            }
          />
          <CustomizedTooltip
            disableInteractive
            content={sidebarOpen ? null : <Typography variant='body1'>Account</Typography>}
            elements={
              <div className={classes.navButtonToolTipWrapper}>
                <Button
                  className={cx(classes.navButton, {
                    [classes.navButtonNotActive]: !menuAnchorEl,
                  })}
                  variant={menuAnchorEl ? 'primary' : 'tertiary'}
                  aria-controls='menu'
                  aria-haspopup='true'
                  onClick={handleClick}
                  icon='account-circle-line'
                  iconType='remix'
                >
                  {sidebarOpen && 'Account'}
                </Button>
              </div>
            }
          />
          <Menu
            id='menu'
            anchorEl={menuAnchorEl}
            keepMounted
            open={Boolean(menuAnchorEl)}
            onClose={handleClose}
            elevation={2}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MenuItem onClick={(): void => redirectToAgents()}>
              <ListItemText primary='Assistenten' />
            </MenuItem>
            <MenuItem onClick={(): Promise<void> => PCA.logoutRedirect()}>
              <ListItemText primary='Ausloggen' />
            </MenuItem>
          </Menu>
          {/* Show full or minified sidebar */}
          <CustomizedTooltip
            disableInteractive
            content={sidebarOpen ? null : <Typography variant='body1'>Ausklappen</Typography>}
            elements={
              <div className={classes.navButtonToolTipWrapper}>
                <Button
                  className={classes.sidebarButton}
                  variant={'tertiary'}
                  onClick={(): void => setSidebarOpen(!sidebarOpen)}
                  icon={sidebarOpen ? 'arrow-left-s-line' : 'arrow-right-s-line'}
                  iconType='remix'
                >
                  {sidebarOpen && 'Einklappen'}
                </Button>
              </div>
            }
          />
          <Typography variant='small' style={{ textAlign: 'center' }}>
            {sidebarOpen &&
              // eslint-disable-next-line no-undef
              process.env.REACT_APP_VERSION &&
              // eslint-disable-next-line no-undef
              `${process.env.REACT_APP_VERSION.replace('"', '')}`}
          </Typography>
        </div>
      </Drawer>
      {/* Routes for the main Page Content */}
      <Routes>
        {/* <Route path={path + ROUTE_CREATEBOT}>
        <BotCreation />
      </Route> */}
        <Route
          path={ROUTE_BOTID_DOCUMENTS + '/*'}
          element={
            <LockingContextProvider lockScopes={['flowdesigner-and-translations']}>
              <Documents />
            </LockingContextProvider>
          }
        />
        {/* <Route path={ROUTE_BOTID_ANALYTICS + '/*'} element={<Analytics />} />*/}
        <Route path={ROUTE_BOTID_ANALYTICS + '/*'} element={<Analytics />} />

        {/* <Route path={ROUTE_BOTID_CARDS}></Route> */}
        <Route
          path={ROUTE_BOTID_DESIGNER + '/*'}
          element={
            <FlowdesignerContextProvider>
              <LockingContextProvider lockScopes={['flowdesigner-and-translations']}>
                <FlowDesigner sidebarOpen={sidebarOpen} />
              </LockingContextProvider>
            </FlowdesignerContextProvider>
          }
        />
        <Route path={ROUTE_BOTID_KNOWLEDGE + ROUTE_BOTID_KNOWLEDGE_MODULE + '/*'} element={<Knowledge />} />
        <Route path={ROUTE_BOTID_TRANSLATIONS + '/*'} element={<Translations />} />
        <Route
          path={ROUTE_BOTID_NOTIFICATIONS + '/*'}
          element={
            <CustomNotificationsContextProvider>
              <LockingContextProvider lockScopes={['customNotifications']}>
                <CustomNotifications />
              </LockingContextProvider>
            </CustomNotificationsContextProvider>
          }
        />
        <Route
          path={ROUTE_BOTID_SETTINGS + '/*'}
          element={
            <LockingContextProvider lockScopes={['settings', 'flowdesigner-and-translations']}>
              <ChartContextProvider>
                <TranslationsContextProvider>
                  <Settings />
                </TranslationsContextProvider>
              </ChartContextProvider>
            </LockingContextProvider>
          }
        />
        <Route path={ROUTE_HELP + '/*'} element={<Help />} />
        {/* <Can I='read' a='floweditor'> */}
        <Route path='*' element={<Navigate to={path + '/analytics'} />} />
        {/* </Can> */}
        {/* <Can I='read' a='knowledge'>
        <Redirect to={path + ROUTE_BOTID_KNOWLEDGE} />
      </Can> */}
      </Routes>
      {/* </div> */}
    </>
  )
}

export default BotLayout
