import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { IContextMenuOption } from '@convaise/react-ace-editor'

const useStyles = makeStyles()({
  menuContainer: {
    padding: '10px',
    width: '200px',
    background: '#FCFCFC',
    border: '0.5px solid #cccccc',
  },
  sectionName: {
    fontSize: '12px',
    color: 'rgba(0,0,0,0.7)',
  },
  sectionDivider: {
    display: 'block',
    height: '1px',
    borderTop: '1px solid #ccc',
  },
  menuOption: {
    color: 'rgba(0,0,0,0.85)',
    paddingTop: '5px',
    paddingBottom: '5px',
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(0,0,0,0.05)',
      paddingLeft: '10px',
      marginLeft: '-10px',
      paddingRight: '10px',
      marginRight: '-10px',
    },
  },
})

export type MenuProps = {
  sections: IContextMenuOption[]
}

type Sections = {
  [sectionNumber: number]: { sectionNumber: number; sectionName: string; options: IContextMenuOption[] }
}

/**
 * Menu that is displayed as context menu in the code editor.
 */
export default function Menu({ sections: menuSections }: MenuProps): React.ReactElement {
  const { classes } = useStyles()
  const [sections, setSections] = useState<Sections>({})

  useEffect(
    function () {
      const newSections = {}
      for (const sec of menuSections) {
        if (!Object.keys(newSections).includes(sec.sectionNumber.toString())) {
          newSections[sec.sectionName] = {
            sectionNumber: sec.sectionNumber,
            sectionName: sec.sectionName,
            options: [sec],
          }
        } else {
          newSections[sec.sectionName].options.push(sec)
        }
      }
      setSections(newSections)
    },
    [menuSections],
  )

  return (
    <div className={classes.menuContainer}>
      <>
        {Object.keys(sections).map((sectionNumber, index) => {
          // divide menu into section as specified by its menu options
          // each section can have multiple options
          return (
            <div key={`menu-section-${sectionNumber}`}>
              {index > 0 ? <hr className={classes.sectionDivider} /> : null}
              <div className={classes.sectionName}>{sections[sectionNumber].sectionName}</div>
              <div>
                {sections[sectionNumber].options.map((menuOption, index) => {
                  // all options of the section
                  return (
                    <div key={`menu-section-${sectionNumber}-option-${index}`}>
                      <div
                        className={classes.menuOption}
                        onClick={(): void => {
                          const callbackFn = menuOption.type === 'text-insert' ? menuOption.onTextInsert : null
                          menuOption.onClick({ onTextInsert: callbackFn })
                        }}
                      >
                        {menuOption.label}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}
      </>
    </div>
  )
}
