import React, { useState, useEffect } from 'react'
import { makeStyles } from 'tss-react/mui'
import Dialog from '../../../../../components/Dialogs/Dialog'
import Button from '../../../../../components/Buttons/Button'
import { TextField, Typography } from '@mui/material'
import { Check } from '@mui/icons-material'
import { TreeGraph } from '@antv/g6'

const useStyles = makeStyles()((theme) => ({
  textfield: { width: '100%', margin: `${theme.spacing(1)} 0 ${theme.spacing(1)} 0` },
}))

type CreateTopicDialogProps = {
  onClose: () => void
  onCreate: (topicName: string) => void
  graph: TreeGraph
}

export default function CreateTopicDialog({ onClose, onCreate, graph }: CreateTopicDialogProps): React.ReactElement {
  const { classes } = useStyles()
  const [newTopicName, setNewTopicName] = useState<string>('')
  const [error, setError] = useState<'leadingSlash' | 'topicExists' | 'trailingSpace' | undefined>()
  const [errorText, setErrorText] = useState<string>()

  function onCreateClick(): void {
    if (newTopicName && newTopicName.length >= 3) {
      onCreate(newTopicName)
    }
  }

  /**
   * Updates state with new entry and checks for legality.
   * @param event
   */
  function onTextChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const string = event.target.value
    if (string.substring(0, 1) === '/') {
      setError('leadingSlash')
      setErrorText('Ein Thema darf nicht mit einem / beginnen')
    } else if (string.length > 0 && /\s+$/.test(string)) {
      setError('trailingSpace')
      setErrorText('Ein Thema darf nicht mit einem Leerzeichen enden')
    } else {
      setError(undefined)
      setErrorText(undefined)
    }
    setNewTopicName(string)
  }

  /**
   * Checks for validity of input.
   * Determines if topic already exists by searching the graph.
   * NOTE: Done in onBlur because costly. -> need to be done on change else no guarantee that this is checked
   * TODO: Themen dürfen sich doppeln, solange sie nicht in der selben Ebene sind
   */
  function onBlur(): void {
    const hasTopic = graph.findDataById(`topic/${newTopicName}`)
    if (hasTopic !== null) {
      setError('topicExists')
      setErrorText('Dieses Thema existiert bereits')
    }
  }

  useEffect(() => {
    // see note of onBlur
    onBlur()
  }, [newTopicName])

  return (
    <Dialog
      id='create-topic-dialog'
      size='small'
      open
      title='Neues Thema erstellen'
      closable
      onClose={onClose}
      primaryActionButton={
        <Button
          size='small'
          type='success'
          disabled={!!error || !newTopicName || newTopicName.length < 3}
          onClick={onCreateClick}
          icon={<Check />}
        >
          Thema erstellen
        </Button>
      }
    >
      <Typography>
        Geben Sie den Namen des neuen Themas an. Dem Thema können später Antworten zugeordnet werden.
      </Typography>
      <form
        onSubmit={(event): void => {
          if (event) event.preventDefault()
          onCreateClick()
        }}
      >
        <TextField
          id='create-topic-text-input'
          className={classes.textfield}
          placeholder='Thema Name'
          value={newTopicName}
          error={!!error}
          helperText={errorText}
          onChange={onTextChange}
          onBlur={onBlur}
          InputProps={{ autoComplete: 'off' }}
        />
      </form>
    </Dialog>
  )
}
