/* eslint-disable no-constant-condition */
import { Delete } from '@mui/icons-material'
import { IconButton, Typography, useTheme } from '@mui/material'
import PDFIcon from 'assets/img/fileIcons/pdf.svg'
import TxtIcon from 'assets/img/fileIcons/txt.svg'
import WordIcon from 'assets/img/fileIcons/word.svg'
import { Button } from 'components/Buttons'
import BaseCard from 'components/Cards/BaseCard'
import { Dialog } from 'components/Dialogs'
import CircularLoading from 'components/Loading/CircularLoading'
import ContentPage, { ContentPageHeader } from 'components/Page/ContentPage'
import { Textfield } from 'components/TextInput/Textfield'
import CustomizedTooltip from 'components/Tooltips/CustomContentTooltip'
import DragAndDrop from 'components/Upload/DragAndDrop'
import { LoadingState, useRAGContext } from 'hooks/contexts/rag-context'
import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation, useNavigate, useResolvedPath } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'
import { APP_TITLE } from 'utils/constants'
import { isValidUrl } from 'utils/stringUtils'
import Content from './Components/Content'
import { Keywords } from './Components/Keywords'

function getIcon(fileName: string): string {
  const ext = fileName.split('.').pop()
  switch (ext) {
    case 'pdf':
      return PDFIcon
    case 'doc':
    case 'docx':
      return WordIcon
    case 'txt':
    default:
      return TxtIcon
  }
}

const useStyles = makeStyles()((theme) => {
  return {
    sectionHeading: {
      marginBottom: theme.spacing(2),
    },
    titleContainer: {},
    fileUploadContainer: { marginTop: theme.spacing(2), maxHeight: '300px' },
    fileContainer: { display: 'flex', flexDirection: 'column' },
    file: { display: 'flex', alignItems: 'center' },
    fileIcon: { height: '32px', width: '32px', marginRight: theme.spacing(2) },
    fileName: { marginRight: theme.spacing(2) },
    fileDelete: {},
    fileDeleteButton: { color: theme.palette.error.main },
    contentContainer: { marginTop: theme.spacing(4) },
    keywordsContainer: { marginTop: theme.spacing(4) },
    urlContainer: { marginTop: theme.spacing(4) },
    actionsContainer: { display: 'flex', width: '100%', marginTop: theme.spacing(2), justifyContent: 'center' },
  }
})

export default function RAGAddFile(): React.ReactElement {
  const { classes } = useStyles()
  const theme = useTheme()
  const { pathname: path } = useLocation()
  const navigate = useNavigate()
  const url = useResolvedPath('').pathname
  const { addNewFileDocument, parseDocument, getDocumentParsingJobStatus, loading } = useRAGContext()
  const prevLoadingRef = useRef<LoadingState>(loading)

  const [showDialog, setShowDialog] = useState<'discard' | 'creating'>()
  const [title, setTitle] = useState<string>('')
  const [file, setFile] = useState<File>()
  const [content, setContent] = useState<string>('')
  const [keywords, setKeywords] = useState<string[]>([])
  const [sourceUrl, setSourceUrl] = useState<string>('')
  const [urlError, setUrlError] = useState<string>('')

  function hasChanges(): boolean {
    return !!(title || content)
  }

  function onTitleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setTitle(event.target.value)
  }

  async function onUploadFile(files: FileList): Promise<void> {
    setFile(files[0])
    const fileName = files[0].name
    const parseJob = await parseDocument(files[0])
    if (parseJob) {
      const jobId = parseJob.jobId
      // Check parsing job status every 5 seconds until completed
      const checkJobStatus = async () => {
        const result = await getDocumentParsingJobStatus(jobId)
        if (result && result.status === 'completed') {
          if (result.parsedContent) {
            setContent(result.parsedContent)
          }
          if (!title) {
            setTitle(fileName)
          }
        } else if (result && result.status !== 'error') {
          // If not completed and not error, check again in 5 seconds
          setTimeout(checkJobStatus, 2500)
        }
      }

      checkJobStatus()
    }
  }

  function onDeleteFile(): void {
    setFile(undefined)
    setContent('')
  }

  function onUrlChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setSourceUrl(event.target.value)
    const isValid = isValidUrl(event.target.value)
    if (!isValid && !urlError && event.target.value.length > 0) {
      setUrlError('Die URL ist ungültig.')
    } else if ((isValid && urlError) || event.target.value.length === 0) {
      setUrlError('')
    }
  }

  async function onAdd(): Promise<void> {
    if (!title || !content || !file) return
    await addNewFileDocument(title, content, file, sourceUrl)
  }

  useEffect(
    function navigateBackAfterSuccess() {
      if (prevLoadingRef.current === 'creating' && loading === 'creatingSuccess') {
        // successfully created - navigate back to overview
        navigate(url.substring(0, url.lastIndexOf('/')))
      }
      prevLoadingRef.current = loading
    },
    [loading],
  )

  return (
    <>
      <Helmet>
        <title>{APP_TITLE} - Wissensdatenbank</title>
      </Helmet>
      <ContentPage>
        <ContentPageHeader
          title={'Datei importieren'}
          actions={[
            <Button
              key='add-button'
              onClick={onAdd}
              disabled={
                !title ||
                !content ||
                !!urlError ||
                loading === 'creating' ||
                loading === 'parsing' ||
                loading === 'parsingError'
              }
              type='success'
              loading={loading === 'creating'}
            >
              Hinzufügen
            </Button>,
          ]}
          previousUrl={url.substring(0, url.lastIndexOf('/'))}
          previousUrlCallback={(): boolean => {
            const changes = hasChanges()
            if (changes) setShowDialog('discard')
            return !changes
          }}
        />
        <BaseCard width={'100%'} height={'100%'} minHeight={'80vh'} contentScrollable>
          <div className={classes.titleContainer}>
            <Typography variant='h4' className={classes.sectionHeading}>
              Titel
            </Typography>
            <Typography style={{ marginBottom: theme.spacing(1) }}>
              Titel des neuen Inhalts. Beispielsweise der Titel der Datei oder ein kurzer Text, der den Inhalt der Datei
              beschreibt.
            </Typography>
            <Textfield
              label='Titel'
              placeholder='Titel des neuen Inhalts'
              value={title}
              onChange={onTitleChange}
              fullWidth
              multiline
              rowsMax={3}
            />
          </div>

          <div className={classes.fileUploadContainer}>
            <Typography variant='h4' className={classes.sectionHeading}>
              Datei
            </Typography>
            {!file ? (
              <div className={classes.fileContainer}>
                <DragAndDrop
                  handleDrop={onUploadFile}
                  optionalText='Sie können die Datei auch hierhin ziehen.'
                  optionalTextHint='Unterstützte Dateiformate: .pdf, .doc, .docx, .txt'
                  allowedFileTypes='.pdf,.doc,.docx,.txt'
                />
              </div>
            ) : (
              <div className={classes.fileContainer}>
                <h5>Hochgeladene Datei</h5>
                <div className={classes.file}>
                  <div className={classes.fileIcon}>
                    <img src={getIcon(file.name)} style={{ height: '100%', width: '100%' }} />
                  </div>
                  <div className={classes.fileName}>{file.name}</div>
                  <div className={classes.fileDelete}>
                    <CustomizedTooltip
                      elements={
                        <IconButton
                          onClick={(e: React.MouseEvent): void => {
                            onDeleteFile()
                            e.preventDefault()
                            e.stopPropagation()
                          }}
                          disabled={loading === 'creating' || loading === 'parsing'}
                          className={classes.fileDeleteButton}
                        >
                          <i className='ri-delete-bin-4-line'></i>
                        </IconButton>
                      }
                      content={<Typography>Datei entfernen</Typography>}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          {loading === 'parsing' ? (
            <div style={{ height: '400px' }}>
              <CircularLoading text='Datei wird verarbeitet. Bei großen Dateien kann dies einige Minuten dauern.' />
            </div>
          ) : file ? (
            <>
              <div className={classes.contentContainer}>
                <Typography variant='h4' className={classes.sectionHeading}>
                  Inhalt
                </Typography>
                <Content
                  descriptionText={
                    <Typography>
                      Inhalt der Datei, die zur Wissensdatenbank hinzugefügt werden soll (Vorschau): <br />
                      <Typography variant='caption'>
                        <b>Tipp:</b> Klick auf den Text öffnet Bearbeitungsdialog.
                      </Typography>
                    </Typography>
                  }
                  content={content}
                  onContentChange={(content: string) => {
                    setContent(content)
                  }}
                />
              </div>
              <div className={classes.keywordsContainer}>
                <Typography variant='h4' className={classes.sectionHeading}>
                  Stichwörter
                </Typography>
                <Keywords
                  descriptionText={
                    <Typography>
                      Sie können passende Stichwörter für den Inhalt hinzufügen. Stichwörter helfen dabei, den Inhalt zu
                      kategorisieren und schneller zu finden.
                    </Typography>
                  }
                  keywords={keywords}
                  onKeywordsChange={setKeywords}
                />
              </div>
            </>
          ) : null}

          <div className={classes.urlContainer}>
            <Typography variant='h4' className={classes.sectionHeading}>
              URL (optional)
            </Typography>
            <Typography style={{ marginBottom: theme.spacing(1) }}>
              Falls die Datei online verfügbar ist, können Sie hier die URL einfügen. Die URL wird in der Konversation
              als Quelle angezeigt.
            </Typography>
            <Textfield
              label='URL (optional)'
              placeholder='Link zur Website des Textes'
              value={sourceUrl}
              onChange={onUrlChange}
              fullWidth
              error={!!urlError}
              helperText={urlError}
            />
          </div>
          <div className={classes.actionsContainer}></div>
        </BaseCard>
        {loading === 'creating' && (
          <Dialog id='creating-document-dialog' open={loading === 'creating'} closable={false} size='small'>
            <CircularLoading text='Datei wird importiert...' />
          </Dialog>
        )}
        {showDialog === 'discard' && (
          <Dialog
            id='discard-changes-dialog'
            size='small'
            open={showDialog === 'discard'}
            closable
            onClose={() => setShowDialog(undefined)}
            title='Ungespeicherte Änderungen'
            primaryActionButton={
              <Button
                size='small'
                type='danger'
                icon={<Delete />}
                onClick={(): void => {
                  navigate(-1)
                }}
              >
                Verwerfen
              </Button>
            }
          >
            <Typography>
              Es existieren ungespeicherte Änderungen. Sind Sie sicher, dass Sie zurückgehen möchten? Die Änderungen
              werden dabei verworfen.
            </Typography>
          </Dialog>
        )}
      </ContentPage>
    </>
  )
}
