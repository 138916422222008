import { Answer, TriggerAnswerObject } from 'classes/Knowledge'
import { CardType } from '../SmartCards/types'
// ========= CHART ==========

export type Position = {
  x: number
  y: number
}

export type Offset = Position & {
  zoom?: number
}

export type Size = {
  width: number
  height: number
}

// we have some nodes that set the port type using key 'custom' and some that use key 'type'
// for now we support both -> use interfaces to allow for OR condition
// 'custom': setVar, adaptiveCard,
// 'type': api, ifElse
// TODO: only use key 'type'. This requires a CONVERSION OF ALL CHARTS TO RENAME PROPERTY 'custom' TO 'type'
interface PortPropertiesBase {
  cond?: boolean
  name?: string
}

interface Port1Interface extends PortPropertiesBase {
  custom: 'incoming' | 'outgoing'
}

interface Port2Interface extends PortPropertiesBase {
  type: 'incoming' | 'outgoing'
}

export type Port = {
  id: string
  type: 'left' | 'right' | 'top' | 'bottom'
  position?: Position // this is optional because if ports have changed that change is handled by framework and only after that we have the position
  properties?: Port1Interface | Port2Interface
}

export type Ports = {
  [portId: string]: Port
}

export type NodeType =
  | 'start'
  | 'basic/adaptiveCard'
  | 'basic/api'
  | 'basic/message'
  | 'basic/pdf'
  | 'basic/question_button'
  | 'basic/question_free'
  | 'basic/picture'
  | 'basic/fileUpload'
  | 'logic/ifElse'
  | 'logic/switch' // old switch node (no condition support)
  | 'logic/switchCondition' // new switch node (with condition support)
  | 'logic/jump'
  | 'logic/loop'
  | 'logic/loopClose'
  | 'logic/setVar' // old set var node (only single)
  | 'logic/setVariables' // new setvar node that supports setting multiple variables at once
  | 'logic/yesNo'
  | 'logic/startDialog'
  | 'logic/qna_answer'
  | 'trigger/intent'
  | 'trigger/event'
  | 'trigger/qna'
  | 'trigger/analytics'
  | 'basic/note'
  | 'module/llm-task'
  | 'module/xzufi-getAnswer'
  | 'module/xzufi-getAnswerNew'
  | 'module/xzufi-trackHelpful'
  | 'module/xzufi-trackSelectedAnswer'
  | 'module/qna-getAnswer'
  | 'module/qna-trackHelpful'
  | 'module/qna-startTriggerDialog'
  | 'module/aleph-alpha-getAnswer'
  | 'module/aleph-alpha-trackHelpful'

export type RequestMethod = 'post' | 'put' | 'get' | 'delete' | 'patch'

export type KeyValueObject = {
  [key: string]: string
}

export type RequestHeader = KeyValueObject

export type RequestParams = KeyValueObject

export type RequestBody = string | { [iter: string]: string }

export type RequestType = 'application/xml' | 'application/json' | 'application/pdf'

export type APITestResult = {
  // this is the response of the proxy api
  status?: number
  headers?: { [key: string]: string }
  data?: any
  error?: boolean | string
}

export type APIProperties = {
  request: {
    params?: RequestParams
    body?: RequestBody
    headers?: RequestHeader
    method: RequestMethod
    url: string
    xmlType?: 'xml' | 'xöv'
  }
  response: {
    saveConfig?: {
      [saveConfigId: string]: {
        variables: string[]
        accessPattern: string
      }
    }
    status?: number
    successful?: boolean
  }
  mockVariables?: {
    // stores values that are used as variables for testing the API
    [varId: string]: string
  }
  apiTestResult?: APITestResult
}

// export type Loop = {
//   m: number // max iterations
//   loopId: string
//   mapping: {
//     [loopIter: string]:
//       | {
//           // "result variables"
//           varname: string
//         }
//       | string[] // "normal variables"
//   }
// }

export type Conditions = {
  [conditionId: string]: {
    [variableId: string]: string
  }
}

export type SwitchConditions = {
  variableIdToCheck: string // variable on which the switch should be run
  conditions: {
    [conditionId: string]: {
      label: string // set by user. Used to name the outgoing port
      condition: string // string (the " < 10" or "!= 'true'" part of the complete condition; complete condition would be "%variable1 < 10")
    }
  }
}

export type FileUploadFileType = 'pdf' | 'jpg' | 'png'

type FileUploadConfig = {
  fileUploadConfigId: string
  fileName: string
  fileDescription: string
  messageText: string
  buttonText: string
  fileTypes: FileUploadFileType[]
  fileDownloadUrlVarId?: string
}

export type FileUploadNodeProperties = {
  [configId: string]: FileUploadConfig
}

export type XZufiProperties = {
  addConvaiseNlu?: boolean // if true, also takes knowledge db into consideration when trying to find an answer
  xZufiModuleConfigId?: string // xzufi module id
  xZufiLeistungen?: string // variable id for storing retrieved xzufi leistungen (getAnswer node)
  xZufiLeistungenLength?: string // variable id for storing number of retrieved leistungen (get Answer node)
  xZufiQuery?: string // variable id for storing user query used to retrieve answers (getAnswer node)
  corrId?: string // uuid v4 id - correlation id for feedbacking the given answer (getAnswer + trackHelpful node)
  xZufiChosenLeistungsId?: string // variable id for storing the user selected leistung for feedbacking (trackHelpful node)
  helpful?: 'helpful' | 'notHelpful' | 'noAnswerFound' // for feedback tracking (trackHelpful node)
  textFeedback?: string | null // variable id for storing the user's text feedback (trackHelpful node). null if no variable is selected
  qnaAnswer?: string // ONLY IF COMBINED XZUFI AND QNA: variable id for storing retrieved qnaAnswer
  qnaTriggerIntent?: string // // ONLY IF COMBINED XZUFI AND QNA: variable id for storing detected trigger intent
  qnaActions?: string // ONLY IF COMBINED XZUFI AND QNA: variable id for storing actions of detected answer
  qnaModuleConfigId?: string // ONLY IF COMBINED XZUFI AND QNA: module config id of the qna module
}

export type QnAModuleNodeProperties = {
  corrId?: string // uuid v4 id - correlation id for feedbacking the given answer (getAnswer + trackHelpful node)
  helpful?: 'helpful' | 'notHelpful' // for feedback tracking (trackHelpful node)
  intent?: string // uuid v4 variable id for storing the intent to find the correct trigger node (startTriggerDialog node + getAnswer node)
  answer?: string // uuid v4 variable id for storing the given answer to the users question (getAnswer node)
  actions?: string // uuid v4 variable id for storing the actions of the answer (if exists) (getAnswer node)
  question?: string // uuid v4 variable id for storing the question  (getAnswer node)
  textFeedback?: string | null // uuid v4 variable id for storing the user's text feedback (trackHelpful node). null if nothing is selected
  potentialAnswers?: string | null // uuid v4 variable id for storing the potential answers (getAnswer node). null if nothing is selected
}

export type AlephAlphaModuleNodeProperties = {
  dataset?: string // uuid v4 id - variable id for storing the dataset that should be used for the search
  corrId?: string // uuid v4 id - correlation id for feedbacking the given answer (getAnswer + trackHelpful node)
  helpful?: 'helpful' | 'notHelpful' // for feedback tracking (trackHelpful node)
  textFeedback?: string | null // uuid v4 variable id for storing the user's text feedback (trackHelpful node). null if nothing is selected
  reply?: string // uuid v4 variable id for storing the given reply by the AA conv API (getAnswer node)
  documents?: string // uuid v4 variable id for storing the documents for refining the inquiry (document titles = optionTexts)
  optionTexts?: string // uuid v4 variable id for storing the given options for refining the inquiry
  // linkToSource?: string // uuid v4 variable id for storing the link to the source website (if source is a website)
  // sourceDocumentTitle?: string // uuid v4 variable id for storing the title of the source document (if source exists and answer has been given)
  isAnswered?: string // uuid v4 variable id for storing the isAnswered flag that indicates if reply is actual answer
  userInput?: string // uuid v4 variable id for storing the userInput (e.g. question)  (getAnswer node)
  voiceOrTextMode?: string // uuid v4 variable id for storing current mode (voice or text)
  searchMode?: string // uuid v4 variable id for storing the current search mode (document or general)
  usedSearchMode?: string // uuid v4 variable id for storing the search mode used during search. This is determined by the model and can differ from the specified searchmode dependig on the query!
  isSmalltalk?: string // uuid v4 variable id for storing a boolean indicating if the inquiry and response are smalltalk
  conversationHistory?: string // uuid v4 variable id for storing the conversation history as string
  metadataToSend?: {
    // metadata that is send together with the user query
    [key: string]: string // dynamic keys. Have to be defined for each project and then set as defined using the node. Value is always a string
  }
}

export type LLMTaskNodeProperties = {
  systemPrompt: string
  userPrompt: string
  displayTextFirstTurn: string
  displayTextLaterTurns: string
  historyEnabled: boolean
  buttonTextFinish: string
  buttonTextTweak: string
}

export type NodeProperties = {
  typeText: string
  text?: string
  stepbackAllowed?: boolean
  validationError?: boolean // indicates whether node is missconfigured triggeres node highlight and disables save button
  validationErrorMsg?: string
  variables?: {
    [varId: string]: {
      id: string
      set: { usageCount: number }
      consume: { usageCount: number }
    }
  }
  datachecks?: {
    [datacheckId: string]: {
      id: string
      usageCountNode: number // counts how many variables in the node are assigned to this datacheck; should match length of the nodeId array in the global datacheck object
    }
  }
  mandatoryInputFields?: string[]
  card?: string // card name, not card id
  hasCardActions?: boolean
  dialog: string // id of dialog this node belongs to - has to be unique
  options?: string[] // card action values (value of data property of Action.Submit buttons)
  conditions?: Conditions // ifElse
  switchConditions?: SwitchConditions // switch - conditions and their labels for switch node. labels are used for outgoing ports of switch node
  // loop?: Loop
  api?: APIProperties
  varname?: string // switchOld; not used by new switch
  setVarValues?: { [varId: string]: string } // setVar (old set Var node)
  setVariables?: {
    // new setVariables node
    order: string[] // order in which variables are filled
    setVars: {
      [varId: string]: string // variable (key) value is set to value
    }
  }
  answers?: string[] // questionButton
  event?: string // trigger/event
  value?: string // trigger/event
  analyticsEvent?: {
    origin: AnalyticsEventOrigin // convaise indicates predefined event, customer indicates custom event by
    eventName: string // must always be prepended with "customer/" if custom event, in case of convaise event, no prepending
    customEvent?: {
      // NOTE: we decided against giving convaise events a description! only custom events can get a description. We did this to prevent having to update all charts whenever the description of a (hardcoded) convaies event changes
      eventName: CustomAnalyticsEvent['eventName']
      description: CustomAnalyticsEvent['description']
      origin: CustomAnalyticsEvent['origin']
      eventId: string
    } // if origin is customer, structured like the flow chart customEvents event object
  } // trigger/analytics
  triggerIntent?: string // intentTrigger
  pdfUrlVariable?: string // pdf node: variable id for storing the url
  documentId?: string // pdf node: fill existing document
  pdfTexts?: {
    generating: string
    info: string
    button: string
  } // pdf node: generate pdf with user defined texts
  targetNode?: string // jump node
  question?: string // QnA Answer Trigger ~ stores the QnA input and can be a mix of varIds and string
  answer?: string // QnA Answer Trigger ~ stores the answer of the QnA in for example a variable
  targetDialog?: string // start dialog node
  cardType?: CardType
  note?: string // for the note node, to exclude the note text from the translations
  moduleConfigId?: string // id to module in modules property of bot infos; only used in qna and xzufi nodes
  xzufi?: XZufiProperties // module/xzufi-getAnswer node & module/xzufi-trackHelpful node
  qna?: QnAModuleNodeProperties // module/qna nodes
  alephalpha?: AlephAlphaModuleNodeProperties // module/aleph-alpha.. nodes
  fileUpload?: FileUploadNodeProperties // configuration for file upload node
  llmTask?: LLMTaskNodeProperties // configuration for the llm task node
}

export type Node = {
  id: string
  position: Position
  orientation: number
  type: NodeType
  ports: Ports
  properties: NodeProperties
  size?: Size
}

export type Nodes = {
  [nodeId: string]: Node
}

export type Link = {
  id: string
  from: {
    nodeId: string
    portId: string
  }
  to: {
    nodeId: string
    portId: string
  }
  incomplete?: boolean
  properties?: any
}

export enum VariableType {
  System = 'system',
  User = 'user',
  Pdf = 'pdf',
}

export type Variable = {
  id: string
  displayName: string
  usageCount: number
  usage: {
    [nodeId: string]: {
      nodeType: 'AC' | 'default' // TODO: Refine (should be AC and something else)
      set: { usageCount: number }
      consume: { usageCount: number }
      ac?: {
        acFieldIds: {
          [acFieldId: string]: {
            acFieldId: string
            acFieldType: 'Input.ChoiceSet' | 'Input.Text' | string // TODO refine this and remove string
            count: number
          }
        }
        acChoices?: {
          [fieldId: string]: {
            choice: string
            value?: string
            count: number
          }[]
        }
        // acFieldType: string
        // count: number
      }
    }
  }
  documentRefId?: string // pdf - id of document if this variable is associated with a document field
  type: VariableType
  description?: string
  fieldName?: string // pdf
  loopId?: string // deprecated
}

export type Datacheck = {
  id: string
  name: string
  type: 'mandatory' | 'regex' | 'condition' | 'API'
  condition?: string // condition for type condition
  regex?: {
    // regex pattern for type regex
    pattern: string
  }
  api?: object // not yet implemented - will hold api config
  advanced?: boolean // determines whether datacheck is display in advanced or simple view
  nodes: {
    [nodeId: string]: string[] // each nodeId has list of variables that have to be checked using this datacheck
  }
  predefined: boolean // if true, datacheck cannot be deleted
  usageCount: number // counts how often this datacheck is used in the chart
}

export type AnalyticsEventOrigin = 'convaise' | 'customer'

export type CustomAnalyticsEvent = {
  eventName: string // for custom events each event name is automatically preprended with "customer/" e.g. "customer/pdfSubmitted
  description: string
  origin: 'customer' // indicates that it is custom event by customer
  eventId: string
  nodeIds: string[] // ids of trigger/analytics nodes that use this event; we include this here because we have to calculate the usage count for each event anyways
}

export type SelectedOrHovered = {
  type?: 'link' | 'node' | 'port'
  id?: string
  hasBeenDragged?: boolean
}

export type Dialog = {
  id: string
  name: string
  description: string
  startNode: string // id of start node
  nodes: string[] // ids of nodes
  offset: Offset // view offset of this dialog, we keep one for each dialog to prevent strange jumping
}

export type Dialogs = {
  [id: string]: Dialog
}

export type Chart = {
  version: string
  scale: number
  offset: Position
  nodes: Nodes
  hasError?: boolean // indicates whether chart has errors
  nodesWithErrors?: string[] // holdes nodeIds of erroneous nodes
  dialogs: Dialogs
  mainDialog: string
  activeDialog?: string
  customAnalyticsEvents: {
    [eventId: string]: CustomAnalyticsEvent
  }
  links: {
    [linkId: string]: Link
  }
  variables: {
    [varId: string]: Variable
  }
  datachecks: {
    [datacheckId: string]: Datacheck
  }
  selected: {
    id?: string
    type?: 'node' | 'multipleNodes' | 'link' | 'port'
    hasBeenDragged?: boolean
    multipleSelectedNodes?: string[]
  }
  triggerAnswers?: Answer[]
  hovered?: SelectedOrHovered
  view: 'simple' | 'advanced'
  loading?: boolean
  upgradeDialog?: boolean
  properties?: any
  zoom?: number
}

// =========== SPECIFIC NODES ===========
export type StartNode = Node & {
  type: 'start'
}

// =========== VARIABLES =============

// variable option (used in VariableAutosuggestSelect)
export type DisplayVariableOption = {
  label: string
  value: string
  inputValue?: string // used for adding new option to the input
}

// =========== Datachecks =============

// datacheck option (used in DatacheckAutosuggestSelect)
export type DisplayDatacheckOption = {
  label: string
  value: string
  inputValue?: string // used for adding new option to the input
}

// =========== FRAMEWORK ==============

export interface EditorStateActions {
  [key: string]: (...args: any) => Chart
}

export interface FrameworkStateActions {
  [key: string]: (...args: any) => void
}
