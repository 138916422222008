import { Typography } from '@mui/material'
import React from 'react'
import Button from 'components/Buttons/Button'
import Dialog from 'components/Dialogs/Dialog'

type DeleteVariableConfirmDialogProps = {
  onClose: () => void
  onDelete: () => void
  title?: string
  primaryActionButtonText?: string
  text?: React.ReactNode | string
}

export default function DeleteVariableConfirmDialog({
  onClose,
  onDelete,
  title,
  primaryActionButtonText,
  text,
}: DeleteVariableConfirmDialogProps): React.ReactElement {
  return (
    <Dialog
      id='delete-modal'
      size='small'
      open
      title={title ?? 'Löschen bestätigen'}
      closable
      onClose={onClose}
      primaryActionButton={
        <Button size='small' type='danger' onClick={onDelete} icon={'delete-bin-2-line'} iconType='remix'>
          {primaryActionButtonText ?? 'Löschen'}
        </Button>
      }
    >
      {text && typeof text === 'string' ? (
        <Typography>{text}</Typography>
      ) : text ? (
        text
      ) : (
        <Typography>Möchten Sie den Löschvorgang durchführen?</Typography>
      )}
    </Dialog>
  )
}
