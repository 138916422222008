import React, { useState } from 'react'

// @mui/material components
import { makeStyles } from 'tss-react/mui'

import { Typography } from '@mui/material'
import Dialog from './Dialog'

const useStyles = makeStyles()({
  smallCopyButton: {
    cursor: 'pointer',
    fontSize: '0.8rem',
    position: 'relative',
    top: '2px',
  },
})

type NodeDialogProps = {
  id: string
  nodeId: string
  content: React.ReactElement
  primaryActionButton?: React.ReactNode
  onSecondaryActionClick?: () => void
  secondaryActionText?: string
  isSecondaryActionDisabled?: boolean
  dialogTitle?: string
  closable?: boolean
  handleClose: () => void
}

export default function NodeDialog(props: NodeDialogProps): React.ReactElement {
  const { classes } = useStyles()
  const [copyState, setCopyState] = useState(false)

  /**
   * Dialog for card nodes in the flow designer
   * Allows to copy the node id
   */
  return (
    <Dialog
      id={props.id}
      size='large'
      open={true}
      onClose={props.handleClose}
      primaryActionButton={props.primaryActionButton}
      secondaryActionText={props.secondaryActionText}
      onSecondaryActionClick={props.onSecondaryActionClick}
      isSecondaryActionDisabled={props.isSecondaryActionDisabled}
      title={
        <>
          <Typography variant='h4'>{props.dialogTitle}</Typography>
          {props.nodeId && (
            <Typography variant='caption'>
              {props.nodeId}{' '}
              <i
                className={`${copyState ? 'ri-check-line' : 'ri-clipboard-line'} ${classes.smallCopyButton}`}
                onClick={(): void => {
                  // Copy nodeId to clipboard
                  navigator.clipboard.writeText(props.nodeId)
                  setCopyState(true)
                  setTimeout(() => {
                    setCopyState(false)
                  }, 2000)
                }}
              />
            </Typography>
          )}
        </>
      }
      closable={props.closable}
    >
      {props.content}
    </Dialog>
  )
}
