import { IconButton, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useTheme } from '@mui/material/styles'
import React from 'react'

// assets
import { Button } from 'components/Buttons'

const useStyles = makeStyles()((theme) => ({
  container: {
    width: '100%',
    position: 'relative',
    height: '100%',
    display: 'flex',
    // background: theme.palette.background.paper,
    // // marginBottom: theme.spacing(8),
    // '&:before': {
    //   content: '""',
    //   position: 'absolute',
    //   top: '-30px',
    //   left: '-30px',
    //   right: '-30px',
    //   bottom: '-30px',
    //   zIndex: -1,
    //   background: theme.palette.grey.A100,
    //   opacity: 1,
    //   WebkitFilter: 'blur(90px)',
    //   filter: 'blur(90px)',
    //   borderRadius: `${
    //     typeof theme.shape.borderRadius === 'string'
    //       ? parseInt(theme.shape.borderRadius) * 3
    //       : theme.shape.borderRadius * 3
    //   }px`,
    // },
  },
  graphicContainer: {
    background: theme.palette.primary[100],
    width: '50%',
    // minWidth: '170px',
    borderRadius: `${
      typeof theme.shape.borderRadius === 'string'
        ? parseInt(theme.shape.borderRadius) * 3
        : theme.shape.borderRadius * 3
    }px 0px 0px ${
      typeof theme.shape.borderRadius === 'string'
        ? parseInt(theme.shape.borderRadius) * 3
        : theme.shape.borderRadius * 3
    }px`,
    marginLeft: '-30px',
    display: 'flex',
    verticalAlign: 'middle',
    position: 'relative',
    padding: theme.spacing(2),
  },
  graphic: {
    width: '100%',
  },
  content: { display: 'flex', flexDirection: 'column', margin: theme.spacing(4), marginTop: 0, width: '100%' },
  header: {
    display: 'flex',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    width: '100%',
  },
  backButton: {
    // margin: `auto ${theme.spacing(1)} auto 0`,
  },
  disabledText: {
    marginRight: theme.spacing(2),
  },
}))

type ContentContainerProps = {
  headerText: string
  descriptionText?: string
  onBackClick?: () => void
  svg: string
  children: React.ReactNode
  svgAlt?: string
}

export default function ContentContainer({
  headerText,
  descriptionText,
  onBackClick,
  svg,
  children,
  svgAlt = '',
}: ContentContainerProps): React.ReactElement {
  const { classes } = useStyles()
  const theme = useTheme()
  return (
    <div className={classes.container}>
      <div className={classes.graphicContainer}>
        <img className={classes.graphic} src={svg} alt={svgAlt} />
      </div>
      <div className={classes.content}>
        <Typography variant='h2' marginBottom={theme.spacing(1)}>
          {headerText}
        </Typography>
        {typeof descriptionText !== 'undefined' && <Typography variant={'subtitle1'}>{descriptionText}</Typography>}
        {typeof onBackClick !== 'undefined' && (
          <div className={classes.header}>
            <Button
              onClick={onBackClick}
              className={classes.backButton}
              variant='tertiary'
              iconType='remix'
              icon='arrow-left-line'
            >
              Zurück
            </Button>
          </div>
        )}
        {children}
      </div>
    </div>
  )
}
