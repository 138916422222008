import React, { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Typography } from '@mui/material'

import { Textfield } from '../../../../../../components/TextInput/Textfield'

const useStyles = makeStyles()((theme) => ({
  textField: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
}))

type Step1TopicProps = {
  title?: string
  onSetTitle: (title: string) => void
}

export default function StepTitle({ title: propsTitle = '', onSetTitle }: Step1TopicProps): React.ReactElement {
  const { classes } = useStyles()

  const [title, setTitle] = useState<string>(propsTitle)

  function onChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setTitle(event.target.value)
    onSetTitle(event.target.value)
  }

  return (
    <div>
      <Typography>
        Bitte vergeben Sie einen Titel mit dem Sie diese Antwort in Zukunft leicht wieder erkennen können.
      </Typography>
      <Textfield
        onChange={onChange}
        value={title}
        multiline={false}
        placeholder='Titel'
        className={classes.textField}
        label={title && title.length > 49 ? 'Titel zu lang' : 'Titel'}
        helperText={`${title ? title.length : 0}/49`}
        inputProps={{ maxLength: 49 }}
        InputProps={{ autoComplete: 'off' }}
      />
    </div>
  )
}
