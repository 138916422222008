import React from 'react'
import { Route, useParams, useNavigate, useResolvedPath, Navigate, Routes, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
// Material UI
import { Grid } from '@mui/material'
// Custom Components
import ContentPage, { ContentPageHeader } from 'components/Page/ContentPage'
import Answered from './Answered'
import Unanswered from './Unanswered'
import SubpageCard from 'components/Cards/SubpageCard'
// Assets
// TODO: Switch these to knowledge specific graphics
import svgInsights from 'assets/img/analytics/analytics_insights.svg'
import svgData from 'assets/img/analytics/analytics_data.svg'

// constants
import {
  APP_TITLE,
  ROUTE_BOTS,
  ROUTE_BOTID_KNOWLEDGE,
  ROUTE_BOTID_KNOWLEDGE_NLU_MODELMANAGEMENT_ANSWERED,
  ROUTE_BOTID_KNOWLEDGE_NLU_MODELMANAGEMENT_UNANSWERED,
} from 'utils/constants'

function KnowledgeMenu(): React.ReactElement {
  const navigate = useNavigate()
  const url = useResolvedPath('').pathname
  const { botId, moduleConfigId } = useParams()

  return (
    <div>
      <Helmet>
        <title>{APP_TITLE} - Modellmanagement</title>
      </Helmet>
      <ContentPageHeader
        title='Modellmanagement'
        previousUrl={ROUTE_BOTS + '/' + botId + ROUTE_BOTID_KNOWLEDGE + `/${moduleConfigId}`}
      />
      <Grid container spacing={6}>
        <Grid item>
          <SubpageCard
            graphic={svgInsights}
            graphicAlt='Grafik: Beantwortete Fragen'
            title='Beantwortet'
            description='Hier werden Ihnen beantwortete Fragen aus der Live Nutzung angezeigt, die so aber noch nicht in der Wissensdatenbank vorkommen.'
            onClick={(): void => {
              navigate(url + ROUTE_BOTID_KNOWLEDGE_NLU_MODELMANAGEMENT_ANSWERED)
            }}
            disabled={false}
          />
        </Grid>
        <Grid item>
          <SubpageCard
            graphic={svgData}
            graphicAlt='Grafik: Unbeantwortete Fragen'
            title='Unbeantwortet'
            description='Hier werden Ihnen unbeantwortete Fragen aus der Live Nutzung angezeigt. So finden Sie heraus, welche Antworten den Nutzer*innen noch fehlen.'
            onClick={(): void => {
              navigate(url + ROUTE_BOTID_KNOWLEDGE_NLU_MODELMANAGEMENT_UNANSWERED)
            }}
            disabled={false}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default function Knowledge(): React.ReactElement {
  const { pathname: path } = useLocation()

  return (
    <ContentPage>
      <Routes>
        <Route path={''} element={<KnowledgeMenu />} />
        <Route path={ROUTE_BOTID_KNOWLEDGE_NLU_MODELMANAGEMENT_ANSWERED} element={<Answered />} />
        <Route path={ROUTE_BOTID_KNOWLEDGE_NLU_MODELMANAGEMENT_UNANSWERED} element={<Unanswered />} />
        <Route path='*' element={<Navigate to={path} />} />
      </Routes>
    </ContentPage>
  )
}
