import React, { useState, useEffect } from 'react'
import { arrayMove } from '../../../utils/utils'

// @mui/material
import { makeStyles } from 'tss-react/mui'

import Button from '@mui/material/Button'
// @mui/icons-material
import CheckIcon from '@mui/icons-material/Check'

import CardList, { CardObject } from '../../../components/CardList/CardList'

import { IngestPDFResult } from '../../../classes/Ingest'

const useStyles = makeStyles()((theme) => ({
  viewControlls: {
    position: 'absolute',
    bottom: '0%',
    width: '100%',
  },
  finalButton: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  view: {
    height: 'calc(100% - 90px)',
    position: 'relative',
    marginTop: theme.spacing(4),
  },
}))

type SectionsViewProps = {
  ingestPDFResult: IngestPDFResult
  setIngestPDFResult: (ingestPDFResult: IngestPDFResult) => void
  hasFile: boolean
  setIsSectionDone: (e: number) => void
  stage: string
}

export default function SectionsView({
  ingestPDFResult,
  setIngestPDFResult,
  hasFile,
  setIsSectionDone,
  stage,
}: SectionsViewProps): React.ReactElement {
  const [sectionList, setSectionList] = useState<CardObject[]>([])
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>()
  const { classes } = useStyles()

  /**
   * Prepares section object for display in CardList.
   * If sectionOrder is present
   */
  function prepareList(): CardObject[] {
    const sections = ingestPDFResult.regions.sections
    const sectionList: CardObject[] = []
    const sectionOrder = ingestPDFResult.regions.sectionOrder

    sectionOrder.forEach((sectionId) => {
      const section = sections[sectionId]
      const subSectionsNames = section.subsections.map((subsectionId) => {
        const subsection = ingestPDFResult.getSubSection(subsectionId)
        if (subsection) return subsection.name
      })
      let text = 'Unterbereiche:\n'
      subSectionsNames.forEach((name, index) => {
        if (index === 0) {
          text += name
        } else {
          text += ', ' + name
        }
      })
      sectionList.push({
        id: sectionId,
        title: section.name,
        subtitle: 'Bereich', // TODO: I18n
        text: text,
        color: undefined, // TODO: Colors
      })
    })

    return sectionList
  }

  // prepare card list and select card if a card is selected
  useEffect(() => {
    const list = prepareList()
    // find selected card - if one selected
    if (ingestPDFResult.selectedId) {
      const selectedIndex = list.findIndex((card) => card.id === ingestPDFResult.selectedId)
      if (typeof selectedIndex === 'number' && selectedIndex >= 0) setSelectedIndex(selectedIndex)
    } else {
      setSelectedIndex(undefined)
    }
    setSectionList(list)
    // set section ids order in ingestpdfresult
    // const sectionOrder = list.map((section) => section.id)
    // const result = cloneDeep(ingestPDFResult)
    // result.regions.sectionOrder = sectionOrder
    // setIngestPDFResult(result)
  }, [ingestPDFResult.regions.sections, ingestPDFResult.selectedId])

  // handles selection of card
  function onCardClick(card: CardObject, index: number): void {
    if (index !== selectedIndex) {
      // set selected
      ingestPDFResult.setSelectedId(card.id)
      setIngestPDFResult(ingestPDFResult)
      setSelectedIndex(index)
    } else {
      // set unselected
      ingestPDFResult.removeSelectedId()
      setIngestPDFResult(ingestPDFResult)
      setSelectedIndex(undefined)
    }
  }

  /**
   * Handles card drop after drag.
   * Determines new position of card and updates card list.
   * Note:
   * @param id id of the dragged card
   * @param newIndex new index of the dragged card
   */
  function onCardDrop(id: string, newIndex: number): void {
    // move object for display purposes
    const objIndex = sectionList.findIndex((obj) => obj.id === id)
    if (objIndex >= 0) {
      const newSectionList = arrayMove(sectionList, objIndex, newIndex)
      setSectionList(newSectionList)
    }

    const orderIdIndex = ingestPDFResult.regions.sectionOrder.findIndex((sectionId) => sectionId === id)
    const newSectionOrder = arrayMove(ingestPDFResult.regions.sectionOrder, orderIdIndex, newIndex)
    // set ordered property to true
    ingestPDFResult.regions.customOrder = true
    ingestPDFResult.setSectionOrder(newSectionOrder)
    setIngestPDFResult(ingestPDFResult)
  }

  return (
    <div className={classes.view}>
      <CardList
        content={sectionList}
        selectedIndex={selectedIndex}
        onClick={onCardClick}
        draggable={true}
        onDrop={onCardDrop}
      />
      {hasFile && (
        <div className={classes.viewControlls}>
          <Button
            variant='contained'
            component='span'
            className={classes.finalButton}
            startIcon={<CheckIcon />}
            onClick={(): void => setIsSectionDone(1)}
            disabled={stage !== 'sections'}
          >
            Abschließen
          </Button>
        </div>
      )}
    </div>
  )
}
