import React, { memo } from 'react'
import { isEqual } from 'lodash'
import { Chart } from '../../../../../@types/Flowchart/types'
import { TranslationFile } from '../../../../../@types/Translations/types'
import CreationProcess from './CreationProcess/SmartCardCreationProcess'

type SelectedSmartCardProps = {
  chart: Chart
  nodeId: string
  translations: TranslationFile
  cardIdOnLoad?: string
  onDelete: () => void
  onSaveChart: (chart: Chart) => void
  setTranslationsCallback: (translations: TranslationFile) => void
  setTranslationNeedSavingCallback: (needsSaving: boolean) => void
}

/**
 * Wrapper Component for the Creation Process
 *
 * @param props
 * @returns The components for the Smart Card dialog
 */
export default memo(function SelectedSmartCard({
  chart,
  nodeId,
  translations,
  cardIdOnLoad,
  onDelete,
  onSaveChart,
  setTranslationsCallback,
  setTranslationNeedSavingCallback,
}: SelectedSmartCardProps): React.ReactElement {
  return (
    <>
      {chart.nodes[nodeId] && (
        <CreationProcess
          translations={translations}
          nodeOnLoad={chart.nodes[nodeId]}
          chart={chart}
          cardIdOnLoad={cardIdOnLoad}
          onDelete={onDelete}
          onSaveChart={onSaveChart}
          setTranslationsCallback={setTranslationsCallback}
          setTranslationNeedSavingCallback={setTranslationNeedSavingCallback}
        />
      )}
    </>
  )
}, isEqual)
