import React from 'react'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  unhappy: {
    fontSize: '32px',
    color: theme.palette.error.dark,
  },
}))

export default function Sad(): React.ReactElement {
  const { classes } = useStyles()
  return <i className={'ri-emotion-unhappy-line ' + classes.unhappy} />
}
