export const mode2TemplateDE = {
  type: 'ColumnSet',
  columns: [
    {
      type: 'Column',
      width: 'stretch',
      items: [
        {
          type: 'ActionSet',
          actions: [
            {
              type: 'Action.Submit',
              title: 'Zurück',
              data: {
                '.stepBack': 'true',
              },
              associatedInputs: 'none',
              ignoreInputValidation: true,
            },
          ],
        },
      ],
    },
    {
      type: 'Column',
      width: 'stretch',
      items: [
        {
          type: 'ActionSet',
          actions: [
            {
              type: 'Action.Submit',
              title: 'Weiter',
              style: 'default',
            },
          ],
        },
      ],
    },
  ],
  separator: true,
  spacing: 'Medium',
}

export const mode2TemplateEN = {
  type: 'ColumnSet',
  columns: [
    {
      type: 'Column',
      width: 'stretch',
      items: [
        {
          type: 'ActionSet',
          actions: [
            {
              type: 'Action.Submit',
              title: 'Back',
              data: {
                '.stepBack': 'true',
              },
              associatedInputs: 'none',
              ignoreInputValidation: true,
            },
          ],
        },
      ],
    },
    {
      type: 'Column',
      width: 'stretch',
      items: [
        {
          type: 'ActionSet',
          actions: [
            {
              type: 'Action.Submit',
              title: 'Next',
              style: 'default',
            },
          ],
        },
      ],
    },
  ],
  separator: true,
  spacing: 'Medium',
}

export const mode1TemplateDE = {
  type: 'ActionSet',
  actions: [
    {
      type: 'Action.Submit',
      title: 'Weiter',
      style: 'default',
    },
  ],
  separator: true,
  spacing: 'Medium',
}

export const mode1TemplateEN = {
  type: 'ActionSet',
  actions: [
    {
      type: 'Action.Submit',
      title: 'Weiter',
      style: 'default',
    },
  ],
  separator: true,
  spacing: 'Medium',
}

export const mode1TemplateOptions = {
  type: 'ActionSet',
  actions: [
    {
      type: 'Action.Submit',
      title: 'Option',
      data: 'Option',
      style: 'default',
    },
  ],
  separator: true,
  spacing: 'Medium',
}

export const mode1TemplateFurtherOptions = {
  type: 'ActionSet',
  actions: [
    {
      type: 'Action.Submit',
      title: 'Option',
      data: 'Option',
      style: 'default',
    },
  ],
}

export const doubleRowTemplate = {
  type: 'ColumnSet',
  columns: [
    {
      type: 'Column',
      width: 'stretch',
      items: [],
    },
    {
      type: 'Column',
      width: 'stretch',
      items: [],
    },
  ],
  separator: true,
  spacing: 'Medium',
}

export const tripleRowTemplate = {
  type: 'ColumnSet',
  columns: [
    {
      type: 'Column',
      width: 'stretch',
      items: [],
    },
    {
      type: 'Column',
      width: 'stretch',
      items: [],
    },
    {
      type: 'Column',
      width: 'stretch',
      items: [],
    },
  ],
  separator: true,
  spacing: 'Medium',
}

export const doubleRowTemplateFurtherOptions = {
  type: 'ColumnSet',
  columns: [
    {
      type: 'Column',
      width: 'stretch',
      items: [],
    },
    {
      type: 'Column',
      width: 'stretch',
      items: [],
    },
  ],
}

export const tripleRowTemplateFurtherOptions = {
  type: 'ColumnSet',
  columns: [
    {
      type: 'Column',
      width: 'stretch',
      items: [],
    },
    {
      type: 'Column',
      width: 'stretch',
      items: [],
    },
    {
      type: 'Column',
      width: 'stretch',
      items: [],
    },
  ],
}

export const inputTemplateDE = {
  type: 'Action.Submit',
  title: 'Weiter',
  data: 'Weiter',
  style: 'default',
}

export const inputTemplateEN = {
  type: 'Action.Submit',
  title: 'Next',
  data: 'Next',
  style: 'default',
}

export const mode1TemplateStepbackDE = {
  type: 'ActionSet',
  actions: [
    {
      type: 'Action.Submit',
      title: 'Zurück',
      data: {
        '.stepBack': 'true',
      },
      associatedInputs: 'none',
      ignoreInputValidation: true,
      style: 'default',
    },
  ],
}

export const mode1TemplateStepbackEN = {
  type: 'ActionSet',
  actions: [
    {
      type: 'Action.Submit',
      title: 'Back',
      data: {
        '.stepBack': 'true',
      },
      associatedInputs: 'none',
      ignoreInputValidation: true,
      style: 'default',
    },
  ],
}

export const stepBackTemplateDE = {
  type: 'Action.Submit',
  title: 'Zurück',
  data: {
    '.stepBack': 'true',
  },
  associatedInputs: 'none',
  ignoreInputValidation: true,
  style: 'default',
}

export const stepBackTemplateEN = {
  type: 'Action.Submit',
  title: 'Back',
  data: {
    '.stepBack': 'true',
  },
  associatedInputs: 'none',
  ignoreInputValidation: true,
  style: 'default',
}
