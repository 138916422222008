import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { Theme, Typography } from '@mui/material'

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    backgroundColor: 'rgba(255,200,0,0.3)',
    padding: theme.spacing(1),
  },
  iconContainer: {
    height: '100%',
  },
  contentContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(1),
  },
  icon: {
    fontSize: theme.typography.h4.fontSize,
  },
  title: {
    width: '100%',
  },
  text: {
    width: '100%',
  },
}))

type InfoBoxProps = {
  title: string
  children: React.ReactNode
}

export default function InfoBox({ title, children }: InfoBoxProps): React.ReactElement {
  const { classes } = useStyles()
  return (
    <div className={classes.container}>
      <div className={classes.iconContainer}>
        <i className={`ri-information-line ${classes.icon}`} />
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.title}>
          <Typography fontWeight='bold'>{title}</Typography>
        </div>
        <div className={classes.text}>{children}</div>
      </div>
    </div>
  )
}
