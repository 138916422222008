import { Checkbox, FormControlLabel, FormGroup, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'

type WeekdayPickerProps = {
  onSelect: (selectedDays: number[]) => void // ordered list of selected week days
  selected: number[]
  disabled?: boolean
}

/**
 * Weekday picker component.
 * Shows 7 checkboxes for the 7 weekdays and returns the currently selected weekday indices (0 = sunday, 6 = saturday) on each change
 */
export default function WeekdayPicker({
  onSelect,
  selected: _selected,
  disabled = false,
}: WeekdayPickerProps): React.ReactElement {
  const theme = useTheme()
  const [selected, setSelected] = useState<number[]>(_selected ?? [])

  function onChange(index: number, checked: boolean): void {
    if (!checked) {
      // remove from selected array
      const newSelected = selected.filter((i) => i !== index)
      onSelect(newSelected)
    } else {
      // add to selected array
      if (!selected.includes(index)) selected.push(index)
      selected.sort(function (a, b) {
        return a - b
      })
      onSelect([...selected])
    }
  }

  useEffect(
    function () {
      if (_selected) setSelected(_selected)
    },
    [_selected],
  )

  return (
    <div>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={selected.includes(1)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => onChange(1, checked)}
              style={{ color: theme.palette.primary.main }}
              disabled={disabled}
            />
          }
          label='Montag'
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={selected.includes(2)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => onChange(2, checked)}
              style={{ color: theme.palette.primary.main }}
              disabled={disabled}
            />
          }
          label='Dienstag'
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={selected.includes(3)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => onChange(3, checked)}
              style={{ color: theme.palette.primary.main }}
              disabled={disabled}
            />
          }
          label='Mittwoch'
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={selected.includes(4)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => onChange(4, checked)}
              style={{ color: theme.palette.primary.main }}
              disabled={disabled}
            />
          }
          label='Donnerstag'
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={selected.includes(5)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => onChange(5, checked)}
              style={{ color: theme.palette.primary.main }}
              disabled={disabled}
            />
          }
          label='Freitag'
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={selected.includes(6)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => onChange(6, checked)}
              style={{ color: theme.palette.primary.main }}
              disabled={disabled}
            />
          }
          label='Samstag'
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={selected.includes(0)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => onChange(0, checked)}
              style={{ color: theme.palette.primary.main }}
              disabled={disabled}
            />
          }
          label='Sonntag'
        />
      </FormGroup>
    </div>
  )
}
