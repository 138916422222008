import React, { useEffect, useState } from 'react'
import Fuse from 'fuse.js'
import ReactMarkdown from 'react-markdown'
import { makeStyles } from 'tss-react/mui'
import { Collapse, Grid, Typography } from '@mui/material'
import AnswerCard from './Cards/AnswerCard'
import { Searchfield } from 'components/TextInput/Searchfield'
import Button from 'components/Buttons/Button'

import {
  EndpointType,
  EndpointUtterance,
  EndpointUtteranceAnswered,
  EndpointUtteranceUnanswered,
} from '../../../../../../@types/Knowledge/types'
import { Answer } from 'classes/Knowledge'
import { AssignUtteranceDialogScreen } from './AssignUtteranceDialog'
import { useAnswers } from 'hooks/contexts/answers-context'

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  changedAnswerContainer: { width: '100%', marginTop: theme.spacing(2), marginBottom: theme.spacing(2) },
  quickSelectContainer: {},
  chosenCardContainer: {},
  searchContainer: {
    marginTop: theme.spacing(2),
  },
  searchField: {
    marginTop: theme.spacing(2),
  },
  searchResults: { marginTop: theme.spacing(2) },
  searchShowMoreButton: { marginTop: theme.spacing(1), width: '100%', textAlign: 'center' },
  subtitle: { marginTop: theme.spacing(1), marginBottom: theme.spacing(1) },
  questionContainer: {
    width: '100%',
  },
  flexContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  summarySelectedAnswerContainer: { marginTop: theme.spacing(2), marginBottom: theme.spacing(2) },
  markdown: { '& p': { margin: '0px !important' } },
  changedAnswerPreviewContainer: {
    maxHeight: '286px',
    overflowY: 'scroll',
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius:
      typeof theme.shape.borderRadius === 'number' ? theme.shape.borderRadius * 2 : theme.shape.borderRadius,
    // flex: 1,
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    // overflow: 'hidden',
    // marginBottom: '60px',
  },
}))

type AssignUtteranceDialogMainScreenProps = {
  utterance: EndpointUtteranceAnswered | EndpointUtteranceUnanswered
  answers: { [answerId: string]: Answer }
  onSelectAnswer: (answerId: string, selectFrom: AssignUtteranceDialogScreen) => void
  selectedAnswerId?: string
  type: 'main' | 'summary' // main: initial screen with quick select and search; summary summary screen with quickselect at bottom and no search
  endpointType: EndpointType
}

export default function AssignUtteranceDialogMainScreen({
  utterance,
  answers,
  selectedAnswerId,
  onSelectAnswer,
  type = 'main',
  endpointType,
}: AssignUtteranceDialogMainScreenProps): React.ReactElement {
  const { classes } = useStyles()
  const { canIEditAnswer } = useAnswers()
  // if (endpointType === 'answered') utterance = utterance as EndpointUtteranceAnswered
  // else if (endpointType === 'unanswered') utterance = utterance as EndpointUtteranceUnanswered

  const [selectedAnswer, setSelectedAnswer] = useState<Answer>()
  const [predictions, setPredictions] = useState<EndpointUtterance['intentPredictions']>()
  const [suggestions, setSuggestions] = useState<Answer[]>()
  const [numberSearchResultsShown, setNumberSearchResultsShown] = useState<number>(
    type === 'main' && endpointType === 'answered' && utterance.givenAnswerText ? 3 : 6,
  )
  const [searchResults, setSearchResults] = useState<Answer[]>()
  const [searchString, setSearchString] = useState<string>()

  /**
   * Searches answers for search string and returns result.
   */
  function filterAnswers(_answers: Answer[]): Answer[] {
    const answers = _answers.filter((answer) => canIEditAnswer(answer))
    if (!searchString) return answers
    const fuseOptions = {
      shouldSort: true,
      threshold: 0.4,
      minMatchCharLength: 1,
      keys: ['title', 'actions', 'labels', 'answer'],
    }
    const fuse = new Fuse(answers ?? [], fuseOptions)
    return fuse.search(searchString).map((result) => result.item)
  }

  /**
   * Handles show more answers click.
   * Increases shown answers by 6.
   */
  function onShowMoreAnswers(): void {
    setNumberSearchResultsShown(
      numberSearchResultsShown === 3 ? numberSearchResultsShown + 3 : numberSearchResultsShown + 6,
    )
  }

  function onAnswerSelection(answerId: string): void {
    onSelectAnswer(answerId, 'main')
  }

  useEffect(
    function () {
      // order predictions by score
      const predictions = utterance.intentPredictions
      predictions.sort((pred1, pred2) => {
        if (pred1.score >= pred2.score) return -1
        else return 1
      })
      // find answer for each predictions
      const suggestedAnswers: Answer[] = []
      predictions.slice(0, 3).forEach((prediction) => {
        const suggestedAns: Answer | undefined = prediction.answerId
          ? answers[prediction.answerId]
          : Object.values(answers).find((answer) => answer.intent === prediction.intent)

        if (suggestedAns) {
          suggestedAns.score = prediction.score
          suggestedAnswers.push(suggestedAns)
        }
      })
      setPredictions(predictions)
      setSuggestions(suggestedAnswers)
    },
    [utterance],
  )

  useEffect(function () {
    if (type === 'summary' && selectedAnswerId) {
      const answer = answers[selectedAnswerId]
      if (answer) setSelectedAnswer(answer)
    }
  }, [])

  useEffect(
    function () {
      const filteredAnswers = filterAnswers(Object.values(answers))
      filteredAnswers.sort((ans1, ans2) => {
        if (!ans1.score || !ans2.score || ans1.score < ans2.score) return 1
        else return -1
      })
      setNumberSearchResultsShown(type === 'main' && endpointType === 'answered' && utterance.givenAnswerText ? 3 : 6)
      setSearchResults(filteredAnswers)
    },
    [searchString, answers],
  )

  return (
    <>
      {type === 'summary' && (
        <div className={classes.chosenCardContainer}>
          {selectedAnswer && (
            <div className={classes.subtitle}>
              <Typography variant='h4'>Gewählte Antwort</Typography>
              <div className={classes.summarySelectedAnswerContainer}>
                <AnswerCard
                  answer={selectedAnswer}
                  isSelected={selectedAnswer.answerId === selectedAnswerId}
                  disabled={!canIEditAnswer(selectedAnswer)}
                  onClick={onAnswerSelection}
                />
              </div>
            </div>
          )}
        </div>
      )}
      {type === 'main' && endpointType === 'answered' && utterance.givenAnswerText && (
        <div className={classes.changedAnswerContainer}>
          <Typography variant='h4'>Gegebene Antwort</Typography>
          <ReactMarkdown className={classes.markdown + ' ' + classes.changedAnswerPreviewContainer}>
            {utterance.givenAnswerText}
          </ReactMarkdown>
          {utterance.intentPredictions.length > 0 &&
            utterance.intentPredictions[0].answerId &&
            utterance.givenAnswerId !== utterance.intentPredictions[0].answerId && (
              <Typography variant='small'>
                Die gegebene Antwort unterscheidet sich von der Antwort, die aktuell vom Assistenten gegeben werden
                würde.
                {/* [IMPROVEMENT]: https://github.com/convaise/studio/issues/558
                Die zum Zeitpunkt der Frage gegebene Antwort unterscheidet sich von der aktuellen Version der Antwort.
                Bitte beachten Sie dies, wenn Sie die Antwort zuordnen. 
                */}
              </Typography>
            )}
        </div>
      )}
      <div className={classes.quickSelectContainer}>
        <Collapse in={type === 'main' ? !searchString : true}>
          <div className={classes.subtitle}>
            <Typography variant='h4'>Schnellauswahl</Typography>
          </div>
          <div className={classes.flexContainer}>
            <Grid container spacing={2}>
              {suggestions &&
                suggestions
                  .filter((suggestion) => canIEditAnswer(suggestion))
                  .map((suggestion, index) => {
                    if (typeof suggestion.answerId === 'undefined') return null
                    return (
                      <Grid key={`suggestion-${index}`} item xs={6} md={4}>
                        <AnswerCard
                          answer={suggestion}
                          isSelected={selectedAnswerId === suggestion.answerId}
                          onClick={onAnswerSelection}
                          disabled={!canIEditAnswer(suggestion)}
                        />
                      </Grid>
                    )
                  })}
            </Grid>
          </div>
        </Collapse>
      </div>

      {type === 'main' && (
        <div className={classes.searchContainer}>
          <div className={classes.subtitle}>
            <Typography variant='h4'>Suche</Typography>
            <div className={classes.searchField}>
              <Searchfield
                autoFocus
                value={searchString}
                onChange={(event): void => setSearchString(event.target.value)}
              />
            </div>
            <div className={classes.searchResults}>
              <Grid container spacing={1}>
                {searchResults &&
                  searchResults.slice(0, numberSearchResultsShown).map((answer, index) => (
                    <Grid item xs={6} md={4} key={`answer-${index}`} style={{ marginBottom: '8px' }} spacing={2}>
                      <AnswerCard
                        answer={answer}
                        isSelected={selectedAnswerId === answer.answerId}
                        onClick={onAnswerSelection}
                        disabled={!canIEditAnswer(answer)}
                      />
                    </Grid>
                  ))}
              </Grid>
              {searchResults && numberSearchResultsShown < searchResults.length && (
                <div className={classes.searchShowMoreButton}>
                  <Button size='small' icon='arrow-down-s-line' iconType='remix' onClick={onShowMoreAnswers}>
                    Mehr anzeigen
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
