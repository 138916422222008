import { IconButton, Typography } from '@mui/material'
import { Button } from 'components/Buttons'
import { ContentPageHeader } from 'components/Page/ContentPage'
import CustomizedTooltip from 'components/Tooltips/CustomContentTooltip'
import { useLockingContext } from 'hooks/contexts/locking-context'
import { useTermTranslationsContext } from 'hooks/contexts/term-translations-context'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { Add, Delete } from '@mui/icons-material'
import BaseCard from 'components/Cards/BaseCard'
import DeleteConfirmDialog from 'components/Dialogs/DeleteConfirmDialog'
import Dialog from 'components/Dialogs/Dialog'
import CircularLoading from 'components/Loading/CircularLoading'
import Table from 'components/Table/Table'
import EditableTypography from 'components/TextInput/EditableTypography'
import { Textfield } from 'components/TextInput/Textfield'
import Fuse from 'fuse.js'
import { ROUTE_BOTID_TRANSLATIONS, ROUTE_BOTID_TRANSLATIONS_TERMS, ROUTE_BOTS } from 'utils/constants'
import { Dictionary, DictionaryEntry } from '../../../../@types/Knowledge/Dictionaries/types'

const useStyles = makeStyles()((theme) => ({
  rowActionContainer: {
    display: 'flex',
  },
  iconButton: {
    marginLeft: theme.spacing(1),
    marginBottom: 'auto',
    marginTop: 'auto',
    padding: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  icon: { fontSize: '1.2rem' },
  rowTranslationContainer: {
    display: 'flex',
    width: '100%',
  },
  missingTranslationWarning: {
    marginBottom: 'auto',
    marginTop: 'auto',
  },
  inputContainer: { display: 'flex', alignContent: 'center', marginLeft: 'auto' },
  inputTextfield: { width: '100%' },
}))

export default function TermTranslationPrimaryLanguage(): React.ReactElement {
  const { classes } = useStyles()
  const navigate = useNavigate()
  const { botId, langCode } = useParams() as { botId: string; langCode: string }
  const {
    termTranslations,
    saveTermTranslations,
    loading,
    hasChanges,
    setEntryForLanguage,
    addNewEntry,
    deleteEntry,
    discardChanges,
  } = useTermTranslationsContext()
  const { lockState } = useLockingContext()

  const [newValue, setNewValue] = useState<string>()
  const [newValueError, setNewValueError] = useState<string>()
  const [editTermId, setEditTermId] = useState<string>()
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState<boolean>(false)
  // Search & Sort
  const [searchString, setSearchString] = useState<string>('')
  const [sortedTerms, setSortedTerms] = useState<DictionaryEntry[]>([])
  const [displayedTerms, setDisplayedTerms] = useState<DictionaryEntry[]>([])
  // Dialog
  const [displayDialog, setDisplayDialog] = useState<'discard'>()

  /**
   * Prepares dictionary for display.
   * Sorts alphabetically and sets sorted dictionary in state.
   * @param dictionary
   */
  function sortDictionary(dictionary: Dictionary): DictionaryEntry[] {
    // sort alphabetical only
    const list: DictionaryEntry[] = Object.values(dictionary)

    const sortedList = list.sort((a, b) => {
      if (a.term < b.term) return -1
      if (a.term > b.term) return 1
      return 0
    })

    return sortedList
  }

  /**
   * Filters displayedTerms
   * @param searchString
   */
  function filterForSearch(searchString: string, dictionaryEntries?: DictionaryEntry[]): DictionaryEntry[] {
    if (!dictionaryEntries) dictionaryEntries = sortedTerms

    if (searchString) {
      const fuseOptions = {
        shouldSort: true,
        threshold: 0.4,
        minMatchCharLength: 1,
        keys: ['term'],
      }

      const fuse = new Fuse(dictionaryEntries, fuseOptions)
      const searchResults = fuse.search(searchString).map((result) => result.item)
      return searchResults
    } else {
      // no search string
      return dictionaryEntries
    }
  }

  /**
   * Checks whether new value is valid and can be created.
   * This is used to prevent creation of duplicate terms.
   * @param newTerm
   */
  function isValidNewTerm(newTerm: string): { isValid: boolean; message?: string } {
    if (!sortedTerms) return { isValid: true }
    const existingTerms = sortedTerms.map((term) => term.term)
    if (existingTerms.includes(newTerm))
      return {
        isValid: false,
        message: 'Dieser Begriff existiert bereits',
      }
    return {
      isValid: true,
    }
  }

  function onChangeNewValue(event: React.ChangeEvent<HTMLInputElement>): void {
    setNewValue(event.target.value)
    const isValid = isValidNewTerm(event.target.value)
    if (!isValid.isValid) {
      setNewValueError(isValid.message ?? 'Dieser Begriff existiert bereits.')
    } else {
      setNewValueError(undefined)
    }
  }

  /**
   * Creates new entry in dictionary
   * @returns
   */
  function onAddNewValue(): void {
    if (!newValue || newValue.trim() === '') return
    addNewEntry(newValue)
    setNewValue(undefined)
  }

  function onDeleteConfirm(): void {
    if (!editTermId) return
    deleteEntry(editTermId)
    setEditTermId(undefined)
    setShowDeleteConfirmDialog(false)
  }

  /**
   * Handles search string change.
   * @param event
   */
  function onSearchStringChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setSearchString(event.target.value)
  }

  /**
   * Sorts entries, filters them based on search string and sets them for display
   * @param dictionary
   * @param sort
   */
  function sortAndSetDisplayedEntries(dictionary: Dictionary): void {
    const sorted = sortDictionary(dictionary)
    setSortedTerms(sorted)
    const displayed = filterForSearch(searchString, sorted)
    setDisplayedTerms(displayed)
  }

  useEffect(
    function () {
      // const searchedEntries = filterForSearch(searchString)
      // setDisplayedTerms(searchedEntries)
      if (termTranslations) sortAndSetDisplayedEntries(termTranslations)
    },
    [searchString],
  )

  useEffect(
    function () {
      if (termTranslations) {
        sortAndSetDisplayedEntries(termTranslations)
      }
    },
    [termTranslations],
  )

  const SearchField = <Textfield value={searchString} placeholder='Suchen' onChange={onSearchStringChange} />

  const Save = (
    <CustomizedTooltip
      placement='bottom'
      disableInteractive
      content={<Typography variant='body1'>Wörterbuch speichern</Typography>}
      elements={
        <div>
          <Button
            size='normal'
            type='success'
            icon='refresh-line'
            iconType='remix'
            onClick={saveTermTranslations}
            loading={loading === 'saving'}
            disabled={!hasChanges || typeof loading !== 'undefined' || lockState !== 'canEdit'}
          >
            Speichern
          </Button>
        </div>
      }
    ></CustomizedTooltip>
  )

  const actions = [SearchField, Save]

  return (
    <>
      <ContentPageHeader
        title='Wörterbuch für Fachbegriffe'
        actions={actions}
        previousUrl={ROUTE_BOTS + '/' + botId + ROUTE_BOTID_TRANSLATIONS + '/' + langCode}
        previousUrlCallback={(): boolean => {
          if (hasChanges) setDisplayDialog('discard')
          return !hasChanges
        }}
      />
      <BaseCard width={'100%'} height={'100%'} minHeight={'80vh'}>
        {!termTranslations ? (
          <CircularLoading text='Wörterbuch wird geladen...' />
        ) : (
          <Table
            headers={['Fachbegriff', 'Aktionen']}
            cellAlignPattern={['left', 'right']}
            width={['90%', '10%']}
            padding='medium'
            rows={displayedTerms.map((dictionaryTerm, index) => {
              const termId = dictionaryTerm.dictionaryTermId
              const textPrimaryLanguage = dictionaryTerm.entries[langCode]
              const isInEditMode = termId === editTermId

              return [
                <EditableTypography
                  key={`text-${textPrimaryLanguage}`}
                  value={textPrimaryLanguage}
                  submitOnBlur={false}
                  variant='body1'
                  onChange={(newValue: string): void => {
                    setEditTermId(undefined)
                    setEntryForLanguage(termId, langCode, newValue)
                  }}
                  isValidNewValue={isValidNewTerm}
                  hideEditButton
                  editMode={isInEditMode}
                  height='auto'
                  width='100%'
                  maxWidth='100%'
                  placeholder={'Kein Text vorhanden'}
                />,

                <div className={classes.rowActionContainer} key={`actions-${index}`}>
                  {/* Delete button (if in edit mode) */}
                  {isInEditMode && (
                    <CustomizedTooltip
                      placement='top'
                      // disableInteractive
                      content={<Typography>Fachbegriff löschen</Typography>}
                      elements={
                        <IconButton
                          onClick={(): void => {
                            setShowDeleteConfirmDialog(true)
                          }}
                          aria-label='edit'
                          className={classes.iconButton}
                          disabled={lockState !== 'canEdit'}
                        >
                          <i className={'ri-delete-bin-4-fill ' + classes.icon}></i>
                        </IconButton>
                      }
                    />
                  )}

                  {/* Edit button */}
                  <div style={{ marginLeft: 'auto' }}>
                    <CustomizedTooltip
                      placement='top'
                      disableInteractive
                      content={<Typography>Bearbeiten</Typography>}
                      elements={
                        <>
                          <IconButton
                            onClick={(): void => {
                              if (isInEditMode) {
                                // disable edit mode
                                setEditTermId(undefined)
                              } else {
                                setEditTermId(termId)
                              }
                            }}
                            aria-label='edit'
                            className={classes.iconButton}
                            disabled={lockState !== 'canEdit'}
                          >
                            <i className={'ri-pencil-fill ' + classes.icon}></i>
                          </IconButton>
                        </>
                      }
                    />
                  </div>
                </div>,
              ]
            })}
          />
        )}
        <div style={{ width: '100%', marginTop: 'auto' }}>
          <form
            onSubmit={(event): void => {
              event.preventDefault()
              if (lockState !== 'canEdit') return
              if (newValueError) return
              onAddNewValue()
            }}
          >
            <div className={classes.inputContainer}>
              <Textfield
                onChange={onChangeNewValue}
                value={newValue}
                placeholder='Neuer Fachbegriff'
                InputProps={{ autoComplete: 'off' }}
                label={newValueError || 'Fachbegriff hinzufügen'}
                disabled={lockState !== 'canEdit'}
                className={classes.inputTextfield}
                error={!!newValueError}
              />
              <CustomizedTooltip
                content={<Typography>Fachbegriff erstellen</Typography>}
                elements={
                  <>
                    <IconButton
                      type='submit'
                      aria-label='Add'
                      className={classes.iconButton}
                      disabled={!!newValueError || lockState !== 'canEdit' || !newValue || newValue.trim() === ''}
                    >
                      <Add className={classes.icon} />
                    </IconButton>
                  </>
                }
                placement='top'
              />
            </div>
          </form>
        </div>
      </BaseCard>
      {showDeleteConfirmDialog && (
        <DeleteConfirmDialog
          onClose={(): void => setShowDeleteConfirmDialog(false)}
          onDelete={onDeleteConfirm}
          primaryActionButtonText='Fachbegriff löschen'
          text='Möchten Sie diesen Fachbegriff wirklich löschen? Dadurch werden auch alle Übersetzungen des Begriffs gelöscht.'
        />
      )}
      {/* Discard changes dialog */}
      <Dialog
        id='discard-changes-dialog'
        size='small'
        open={displayDialog === 'discard'}
        closable
        onClose={(): void => setDisplayDialog(undefined)}
        title='Ungespeicherte Änderungen'
        primaryActionButton={
          <Button
            size='small'
            type='danger'
            icon={<Delete />}
            onClick={(): void => {
              // discard all changes and go back
              discardChanges()
              navigate(-1)
            }}
          >
            Verwerfen
          </Button>
        }
      >
        <Typography>
          Es existieren ungespeicherte Änderungen. Sind Sie sicher, dass Sie zurückgehen möchten? Die Änderungen werden
          dabei verworfen.
        </Typography>
      </Dialog>
    </>
  )
}
