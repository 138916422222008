export const templateCardsIds = [
  // old
  'template',
  // DE
  'Nachrichten-Vorlage',
  'Fehler-Frage',
  'Fehler-PDF-Generierung',
  'Feedback-Frage',
  'Feedback-Negativ',
  'Feedback-Ende',
  'Keine-Antwort',
  // EN
  'Message-Template',
  'Error-Question',
  'Error-PDF-Generation',
  'Feedback-Question',
  'Feedback-Negative',
  'Feedback-Final',
  'No-Answer',
]
