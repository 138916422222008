import React, { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'
import { Grid, Switch, Button, IconButton, Typography } from '@mui/material'
import { Delete } from '@mui/icons-material'
import { Separator } from '../../Separator'

import { replaceVarDisplayNameInObject } from './apiRequestUtils'

import { Chart, RequestHeader } from '../../../../../../@types/Flowchart/types'
import TextFieldVariablesSuggestions from '../../Variables/TextFieldVariablesSuggestions'

type SAPIRequestHeaders = {
  chart: Chart
  classes: Record<string, string>
  nodeId: string
  headers: RequestHeader
  resetAPIResult: (chart?: Chart) => Chart | undefined
  setUpdatedChartCallback: (chart: Chart) => void
}

export default function SAPIRequestHeaders({
  chart,
  classes: parentClasses,
  headers: chartHeaders,
  resetAPIResult,
  setUpdatedChartCallback,
}: SAPIRequestHeaders): React.ReactElement {
  const classes = parentClasses
  const [headersToggle, setHeadersToggle] = useState<'on' | 'off'>('off')
  const [headers, setHeaders] = useState<RequestHeader>({})

  // ===== HANDLER =====

  /**
   * Replaces variable display names with variable ids and updates chart by calling parent's callback.
   * Triggered on each blur of a headers value or headers key textfield.
   * @param {React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>} event
   */
  function onHeadersBlur(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void {
    const nodeId = chart.selected?.id
    if (typeof nodeId === 'undefined') return
    const node = chart.nodes[nodeId]
    const api = node?.properties.api
    if (typeof node === 'undefined' || typeof api === 'undefined') return

    const headersWithVarIds = replaceVarDisplayNameInObject(chart, headers)

    api.request.headers = headersWithVarIds
    node.properties.api = api
    chart.nodes[nodeId] = node
    const newChart = resetAPIResult(chart)
    if (typeof newChart !== 'undefined') setUpdatedChartCallback(newChart)
  }

  /**
   * Handles switch change of headers switch.
   * Removes headers object(s) if switch gets disabled or creates headers object(s) if switch gets enabled.
   * @param {React.ChangeEvent<HTMLInputElement>} event
   * @param {boolean} checked
   */
  function onheadersSwitch(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void {
    const nodeId = chart.selected?.id
    if (typeof nodeId === 'undefined') return
    const node = chart.nodes[nodeId]
    const api = node?.properties.api
    // const headers = api?.request.headers
    if (typeof node === 'undefined' || typeof api === 'undefined') return

    if (!event.target.checked) {
      api.request.headers = undefined
    } else {
      if (!api.request.headers)
        api.request.headers = {
          '': '',
        }
    }

    node.properties.api = api
    chart.nodes[nodeId] = node
    setHeadersToggle(checked ? 'on' : 'off')
    const newChart = resetAPIResult(chart)
    if (typeof newChart !== 'undefined') setUpdatedChartCallback(newChart)
  }

  /**
   * Handles value change of headers key text input.
   * @param {React.ChangeEvent<HTMLInputElement>} event
   * @param {string} key
   */
  function onHeadersKeyChange(newKey: string, key: string): void {
    function replaceKey(key: string): void {
      const value = headers[key] || ''
      delete headers[key]
      headers[newKey] = value
      setHeaders({ ...headers })
    }

    // FIXME: For debugging: Remove this if statement and compare behaviour when selecting variable with enter and with mouse click
    // in some cases it can be that key is not the most recent one (i.e. misses the last char)
    // this is for example the case if a variable is selected by mouseclick. I'm not sure why that's the case
    // for now we fix this by using this workaround
    if (!Object.prototype.hasOwnProperty.call(headers, key)) {
      const keys = Object.keys(headers)
      for (const k of keys) {
        if (newKey.startsWith(k) && k.includes(key)) {
          replaceKey(k)
          return
        }
      }
    }

    replaceKey(key)
    // resetAPIResult()
  }

  /**
   * Handles value change of headers key text input.
   * @param {React.ChangeEvent<HTMLInputElement>} event
   * @param {string} key
   */
  function onHeadersValueChange(newValue: string, key: string): void {
    headers[key] = newValue

    setHeaders({ ...headers })
    // resetAPIResult()
  }

  /**
   * Handles add headers button click.
   * @param {React.MouseEvent<HTMLAnchorElement, MouseEvent>} event
   */
  function onHeadersAdd(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    const nodeId = chart.selected?.id
    if (typeof nodeId === 'undefined') return
    const node = chart.nodes[nodeId]
    const api = node?.properties.api
    if (typeof node === 'undefined' || typeof api === 'undefined') return

    api.request.headers = api.request.headers || {}
    api.request.headers[''] = api.request.headers[''] || ''
    node.properties.api = api
    chart.nodes[nodeId] = node
    const newChart = resetAPIResult(chart)
    if (typeof newChart !== 'undefined') setUpdatedChartCallback(newChart)
  }

  /**
   * Handles remove headers button click.
   * Removes key-value pair from headers object.
   * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} event
   * @param {string} key
   */
  function onHeadersRemove(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, key?: string): void {
    const nodeId = chart.selected?.id
    if (typeof nodeId === 'undefined') return
    const node = chart.nodes[nodeId]
    const api = node?.properties.api
    if (typeof node === 'undefined' || typeof api === 'undefined') return

    if (typeof key !== 'undefined') {
      // remove existing headers

      delete headers[key]

      api.request.headers = headers
      node.properties.api = api
      chart.nodes[nodeId] = node
      setUpdatedChartCallback(chart)
    }
  }

  useEffect(
    function () {
      if (!isEmpty(chartHeaders)) setHeadersToggle('on')
      setHeaders(chartHeaders)
    },
    [chartHeaders],
  )

  return (
    <div>
      <Grid container direction='row' justifyContent='space-evenly' alignItems='center'>
        <Grid item xs={11} style={{ height: '100%' }}>
          <Typography>Headers</Typography>
        </Grid>
        <Grid item xs={1} style={{ height: '100%', textAlign: 'right' }} alignItems='center'>
          <Switch
            checked={headersToggle === 'on'}
            onChange={onheadersSwitch}
            classes={{ switchBase: classes.switchBase, track: classes.switchTrack }}
            value='headers'
          />
        </Grid>
      </Grid>
      {typeof headers !== 'undefined' && headersToggle === 'on' ? (
        <div>
          {Object.keys(headers).map((key, index) => {
            return (
              <div key={`headers-${index}`}>
                <Grid container direction='row' justifyContent='space-evenly' alignItems='center'>
                  <Grid item xs={11} style={{ height: '100%' }}>
                    <div>
                      <TextFieldVariablesSuggestions
                        id={`request-headers-key-${index}`}
                        chart={chart}
                        label='Key'
                        value={key}
                        fullWidth
                        autoFocus={key.length === 1}
                        maxRows={1}
                        onBlur={onHeadersBlur}
                        onChange={(newValue: string): void => {
                          onHeadersKeyChange(newValue, key)
                        }}
                      />
                    </div>
                    {key !== '' && (
                      <div style={{ marginTop: '10px' }}>
                        <TextFieldVariablesSuggestions
                          id={`request-headers-value-${index}`}
                          chart={chart}
                          label={`Wert`}
                          value={headers[key]}
                          fullWidth
                          maxRows={1}
                          onBlur={onHeadersBlur}
                          onChange={(newValue: string): void => onHeadersValueChange(newValue, key)}
                        />
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={1} style={{ height: '100%', textAlign: 'left' }} alignItems='center'>
                    <IconButton
                      onClick={(event: React.MouseEvent<HTMLButtonElement>): void => onHeadersRemove(event, key)}
                      style={{ marginRight: '7px' }}
                      color='secondary'
                    >
                      <Delete />
                    </IconButton>
                  </Grid>
                </Grid>
                {Object.keys(headers).length > 1 && index < Object.keys(headers).length - 1 && (
                  <Separator style={{ margin: '15px 50px' }} />
                )}
              </div>
            )
          })}

          <div style={{ textAlign: 'center', marginTop: '10px', marginBottom: '10px' }}>
            <Button disabled={Object.keys(headers).includes('')} onClick={onHeadersAdd} variant='outlined'>
              Hinzufügen
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  )
}
