import React, { ReactElement } from 'react'
import { makeStyles } from 'tss-react/mui'

import { FormControlLabel, Checkbox } from '@mui/material/'

import { PopupContentBlock } from '../popupBuildingBlocks/PopupContentBlock'

const useStyles = makeStyles()((theme) => ({
  checkboxRoot: {
    padding: 5,
    margin: 0,
    marginLeft: -7,
    marginTop: 0,
    color: 'rgba(0,0,0,0.2)',
  },
  checkboxChecked: {},
  label: {
    margin: 0,
    fontSize: 12,
    color: theme.palette.primary.main,
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  root: {
    display: 'table',
    margin: 0,
  },
  formControlLabel: {
    display: 'table-cell',
    verticalAlign: 'middle',
  },
}))

type PopupCheckboxProps = {
  title: string
  description: string
  onChange: (event: object, checked: boolean) => void
  disabled?: boolean
  checked?: boolean
}

export const PopupCheckbox = ({
  title,
  checked,
  onChange,
  disabled,
  description,
}: PopupCheckboxProps): ReactElement => {
  const { classes } = useStyles()

  const label = <span className={classes.label}>{description}</span>

  const Content = (
    <FormControlLabel
      classes={{ root: classes.root, label: classes.formControlLabel }}
      control={
        <Checkbox
          classes={{ root: classes.checkboxRoot }}
          size='small'
          color='primary'
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
      }
      label={label}
    />
  )

  return <PopupContentBlock title={title} content={Content} />
}
