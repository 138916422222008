import React from 'react'
import { Typography } from '@mui/material'
import Button from '../../../../../components/Buttons/Button'
import Dialog from '../../../../../components/Dialogs/Dialog'

type DeleteAnswerDialogProps = {
  type: 'answer' | 'topic'
  onClose: () => void
  onDelete: (topicName: string) => void
}

export default function DeleteAnswerDialog({ type, onClose, onDelete }: DeleteAnswerDialogProps): React.ReactElement {
  return (
    <Dialog
      id='delete-answer-modal'
      size='small'
      open
      title={type === 'answer' ? 'Antwort löschen' : 'Thema löschen'}
      closable
      onClose={onClose}
      primaryActionButton={
        <Button size='small' type='danger' onClick={onDelete} icon={'delete-bin-2-line'} iconType='remix'>
          {type === 'answer' ? 'Antwort löschen' : 'Thema löschen'}
        </Button>
      }
    >
      <Typography>
        {type === 'answer'
          ? 'Möchten Sie diese Antwort löschen? Dies kann nicht rückgängig gemacht werden!'
          : 'Möchten Sie dieses Thema und alle dazugehörigen Antworten löschen? Dies kann nicht rückgängig gemacht werden!'}
      </Typography>
    </Dialog>
  )
}
