import React from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

// constants

// Assets
// TODO: Switch these to knowledge specific graphics
// hooks
import { LockingContextProvider } from 'hooks/contexts/locking-context'
import KnowledgeRAGOverview from './Overview/KnowledgeRAGOverview'
import { RAGContextProvider } from 'hooks/contexts/rag-context'
import {
  ROUTE_BOTID_KNOWLEDGE_RAG_ADD_FILE,
  ROUTE_BOTID_KNOWLEDGE_RAG_ADD_TXT_DOCUMENT,
  ROUTE_BOTID_KNOWLEDGE_RAG_ADD_WEBSITE,
} from 'utils/constants'
import RAGAddTextDocument from './Pages/RAGAddTextManual'
import RAGEditPage from './Pages/RAGEditPage'
import RAGAddFile from './Pages/RAGAddFile'
import RAGAddWebsite from './Pages/RAGAddWebsite'

const useMenuStyles = makeStyles()({
  root: {},
})

export default function KnowledgeRag(): React.ReactElement {
  const { pathname: path } = useLocation()

  return (
    <LockingContextProvider lockScopes={['knowledgeRAG']}>
      <RAGContextProvider>
        <Routes>
          <Route path={''} element={<KnowledgeRAGOverview />} />

          <Route path={ROUTE_BOTID_KNOWLEDGE_RAG_ADD_TXT_DOCUMENT + '/*'} element={<RAGAddTextDocument />} />

          <Route path={ROUTE_BOTID_KNOWLEDGE_RAG_ADD_FILE + '/*'} element={<RAGAddFile />} />

          <Route path={ROUTE_BOTID_KNOWLEDGE_RAG_ADD_WEBSITE + '/*'} element={<RAGAddWebsite />} />

          <Route path={'/:documentId'} element={<RAGEditPage />} />

          <Route path='*' element={<Navigate to={path} />} />
        </Routes>
      </RAGContextProvider>
    </LockingContextProvider>
  )
}
