import { cloneDeep, isEmpty, omit } from 'lodash'
import { v4 as uuid } from 'uuid'

import { CardInputField, CardInputFields, IAdaptiveCard, ISubmitAction } from '../@types/SmartCards/types'
import {
  Chart,
  DisplayVariableOption,
  Node,
  NodeProperties,
  NodeType,
  Port,
  Ports,
  Position,
  StartNode,
  Variable,
  CustomAnalyticsEvent,
  VariableType,
} from '../@types/Flowchart/types'
import { TranslationFile } from '../@types/Translations/types'
import { removeAllDatachecksFromNode } from './datacheckUtils'
import { FLOWDESIGNER_GRID_SIZE } from './constants'
import { LiveTv } from '@mui/icons-material'

export const ALLOWEDCHARSFORVARNAME = 'abcdefghijklmnopqrstuvwxyzäöüß' + 'ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÜ' + '0123456789'
export const REGEXVARDISPLAYNAME = /%([a-zA-ZöäüÄÖÜß0-9-_]+)/gim
// matches uuid with leading %
export const REGEXVARID = /%([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})/gm
// matches uuid
const REGEXUUID = /([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})/gm

// ===================================================================================
// DATACHECKS
// -----------------------------------------------------------------------------------

/**
 * Removes variable from datacheck if variable is no longer used in node.
 * @param chart
 * @param nodeId
 * @param varId
 */
export function removeVariableFromDatachecksOfNode(chart: Chart, nodeId: string, varId: string): Chart {
  const node = chart.nodes[nodeId]
  if (
    node.properties.variables &&
    // node.properties.variables[varId].set.usageCount <= 0 &&
    typeof node.properties.datachecks !== 'undefined'
  ) {
    // check all datachecks that this node uses
    Object.keys(node.properties.datachecks).forEach((datacheckId) => {
      if (node.properties.datachecks) {
        // count length of datacheck usage for this node prior to variable removal
        const countPrior = chart.datachecks[datacheckId].nodes[nodeId].length
        chart.datachecks[datacheckId].nodes[nodeId] = chart.datachecks[datacheckId].nodes[nodeId].filter(
          (variableId) => variableId !== varId,
        )
        // count again after variable removal
        const countAfter = chart.datachecks[datacheckId].nodes[nodeId].length

        // difference
        const diff = countPrior - countAfter
        node.properties.datachecks[datacheckId].usageCountNode -= diff

        // globally
        chart.datachecks[datacheckId].usageCount -= diff
        if (node.properties.datachecks[datacheckId].usageCountNode === 0) {
          // remove datacheck from node if it is no longer used and remove node from datacheck
          delete node.properties.datachecks[datacheckId]
          delete chart.datachecks[datacheckId].nodes[nodeId]
        }
      }
    })

    chart.nodes[nodeId] = node
  }

  return chart
}

// ===================================================================================
// VARIABLES
// -----------------------------------------------------------------------------------

/**
 * Finds all variables that are referenced by their id in a string.
 * Returns list of variable objects.
 * @param chart
 * @param stringValue
 */
export function findAllVariablesInString(chart: Chart, stringValue: string): Variable[] {
  const variables: Variable[] = []

  let match
  do {
    match = REGEXVARID.exec(stringValue)
    if (match) {
      const m = match[1]

      const variable = chart.variables[m]
      // only replace if variable exists, otherwise keep varId in displayValue
      if (typeof variable !== 'undefined') variables.push(variable)
    }
  } while (match)

  return variables
}

/**
 * Replaces all variables ids with provided replacement value
 * @param stringValue
 * @param replacements
 */
export function replaceAllVariablesInStringWithValue(
  stringValue: string,
  replacements: { [varId: string]: { replaceValue: string } },
): string {
  let match
  do {
    match = REGEXVARID.exec(stringValue)
    REGEXVARID.lastIndex = 0
    if (match) {
      const m = match[0]
      const varId = match[1]

      const replaceValue = replacements[varId] ? replacements[varId].replaceValue : ''

      stringValue = stringValue.replace(m, replaceValue)
    }
  } while (match)
  return stringValue
}

/**
 * Reduces usageCount of variable by a specified value (default 1).
 * Adjusts node variable object and global variable object.
 * Deletes variable is defined by user and total usage count is 0.
 * @param chart
 * @param nodeId
 * @param varId
 * @param usageType
 * @param reduceBy
 */
export function reduceVariableUsageCount(
  chart: Chart,
  nodeId: string,
  varId: string,
  usageType: 'set' | 'consume',
  reduceBy = 1,
): Chart {
  const node = chart.nodes[nodeId]
  if (typeof node === 'undefined') return chart

  if (node.properties.variables && node.properties.variables[varId]) {
    if (usageType === 'set') node.properties.variables[varId].set.usageCount -= reduceBy
    else node.properties.variables[varId].consume.usageCount -= reduceBy

    // delete from node if no longer used
    let deletedFromNode = false
    if (
      node.properties.variables[varId].consume.usageCount <= 0 &&
      node.properties.variables[varId].set.usageCount <= 0
    ) {
      delete node.properties.variables[varId]
      deletedFromNode = true
    }

    if (deletedFromNode) {
      // if var is deleted from node, we simply delete the usage obj in the global variable
      delete chart.variables[varId].usage[nodeId]
    } else {
      // otherwise we reduce the count as usual
      if (usageType === 'set') chart.variables[varId].usage[nodeId].set.usageCount -= reduceBy
      else chart.variables[varId].usage[nodeId].consume.usageCount -= reduceBy
    }

    // to get the total usage count of the var, we simply count all usages
    // this is probably a safer way than adjusting it
    let totalUsage = 0
    for (const usage of Object.values(chart.variables[varId].usage)) {
      totalUsage += usage.consume.usageCount
      totalUsage += usage.set.usageCount
    }
    chart.variables[varId].usageCount = totalUsage

    // delete if user generated and usage count of 0
    if (chart.variables[varId].usageCount === 0 && chart.variables[varId].type === VariableType.User)
      delete chart.variables[varId]
  }

  chart.nodes[nodeId] = node

  return chart
}

/**
 * Increases usageCount of variable by a specified value (default 1).
 * Adjusts node variable object (or adds it to a node) and global variable object.
 * @param chart
 * @param nodeId
 * @param varId
 * @param usageType
 * @param increaseBy
 */
export function increaseVariableUsageCount(
  chart: Chart,
  nodeId: string,
  varId: string,
  usageType: 'set' | 'consume',
  increaseBy = 1,
): Chart {
  const node = chart.nodes[nodeId]
  if (typeof node === 'undefined' || typeof chart.variables[varId] === 'undefined') return chart

  // adjust node variable object (or add if not present yet)
  node.properties.variables = node.properties.variables || {}
  if (typeof node.properties.variables[varId] === 'undefined')
    node.properties.variables[varId] = {
      id: varId,
      set: { usageCount: 0 },
      consume: { usageCount: 0 },
    }

  if (usageType === 'set') node.properties.variables[varId].set.usageCount += increaseBy
  else node.properties.variables[varId].consume.usageCount += increaseBy

  // add node to global variable, if not present yet
  if (!Object.keys(chart.variables[varId].usage).includes(nodeId)) {
    chart.variables[varId].usage[nodeId] = {
      nodeType: chart.nodes[nodeId].type === 'basic/adaptiveCard' ? 'AC' : 'default',
      set: { usageCount: 0 },
      consume: { usageCount: 0 },
    }
  }
  // increase consume usage count of global variable
  if (usageType === 'set') chart.variables[varId].usage[nodeId].set.usageCount += increaseBy
  else chart.variables[varId].usage[nodeId].consume.usageCount += increaseBy

  // to get the total usage count of the var, we simply count all usages
  // this is probably a safer way than adjusting it
  let totalUsage = 0
  for (const usage of Object.values(chart.variables[varId].usage)) {
    totalUsage += usage.consume.usageCount
    totalUsage += usage.set.usageCount
  }
  chart.variables[varId].usageCount = totalUsage

  chart.nodes[nodeId] = node
  return chart
}

/**
 * Resets the consume usageCount of all variables used by the node.
 * Sets the consume usage count new by counting all variable occurances in the provided string
 * and also looking at all datachecks used by this nodes and the variables they check.
 * @param chart
 * @param nodeId
 * @param value
 */
function configureConsumeVariablesCount(chart: Chart, nodeId: string, value: string): Chart {
  const node = chart.nodes[nodeId]
  if (typeof node === 'undefined') return chart

  let variables: string[] = [] // holds all variables found in the string

  // reset consume usage count of all variables used by this node to 0
  // node
  node.properties.variables = node.properties.variables || {}
  for (const varId of Object.keys(node.properties.variables)) {
    variables.push(varId)
    node.properties.variables[varId].consume.usageCount = 0
  }
  // global
  for (const varId of Object.keys(chart.variables)) {
    if (Object.keys(chart.variables[varId].usage).includes(nodeId)) {
      variables.push(varId)
      chart.variables[varId].usage[nodeId].consume.usageCount = 0
    }
  }

  // for each variable in the provided value, increase its consume usage count in node object and in global variable object
  const allVariableIds = Object.keys(chart.variables)
  let match
  do {
    match = REGEXUUID.exec(value)
    if (match) {
      const id = match[1]
      if (allVariableIds.includes(id)) {
        // id is variable id
        variables.push(id)

        // node variables object
        // ensure variable is present in node properties
        node.properties.variables = node.properties.variables || {}
        node.properties.variables[id] = node.properties.variables[id] || {
          id,
          set: { usageCount: 0 },
          consume: { usageCount: 0 },
        }
        node.properties.variables[id].set = node.properties.variables[id].set || {
          usageCount: 0,
        }
        node.properties.variables[id].consume = node.properties.variables[id].consume || {
          usageCount: 0,
        }
        // increase usage count in node variable object
        node.properties.variables[id].consume.usageCount += 1

        // global variables object
        chart.variables[id].usage[nodeId] = chart.variables[id].usage[nodeId] || {
          nodeType: node.type === 'basic/adaptiveCard' ? 'AC' : 'default',
          set: { usageCount: 0 },
          consume: { usageCount: 0 },
        }
        chart.variables[id].usage[nodeId].set = chart.variables[id].usage[nodeId].set || { usageCount: 0 }
        chart.variables[id].usage[nodeId].consume = chart.variables[id].usage[nodeId].consume || { usageCount: 0 }
        // increase global consume usage count
        chart.variables[id].usage[nodeId].consume.usageCount += 1
      }
    }
  } while (match !== null)

  // datachecks also consume variables
  // iterate over all datachecks to find the ones used by the node
  for (const datacheckId of Object.keys(chart.datachecks)) {
    if (Object.keys(chart.datachecks[datacheckId].nodes).includes(nodeId)) {
      const varsConsumedByDatacheck = chart.datachecks[datacheckId].nodes[nodeId]
      for (const varId of varsConsumedByDatacheck) {
        if (
          typeof node.properties.variables !== 'undefined' &&
          typeof node.properties.variables[varId] !== 'undefined'
        ) {
          node.properties.variables[varId].consume.usageCount += 1
          chart.variables[varId].usage[nodeId].consume.usageCount += 1
        }
      }
    }
  }

  // update usage count of changed variables
  // we added all varIds to list, so we just need to re-count them
  variables = Array.from(new Set(variables))
  for (const varId of variables) {
    let count = 0
    for (const nId of Object.keys(chart.variables[varId].usage)) {
      const nodeSetUsageCount = chart.variables[varId].usage[nId].set.usageCount
      const nodeConsumeUsageCount = chart.variables[varId].usage[nId].consume.usageCount
      count += nodeSetUsageCount
      count += nodeConsumeUsageCount

      // delete node usage from variable if node usage count is 0
      if (nodeSetUsageCount + nodeConsumeUsageCount === 0) {
        delete node.properties.variables[varId]
        delete chart.variables[varId].usage[nId]
      }
    }
    chart.variables[varId].usageCount = count
    if (chart.variables[varId].usageCount === 0 && chart.variables[varId].type === VariableType.User)
      delete chart.variables[varId]
  }

  chart.nodes[nodeId] = node
  return chart
}

/**
 * Finds all variables that are consumed (used) by a node and updates their consume and total usage count.
 * Searches node properties (depending on node type) to find variables that are consumed.
 * Replaces consume count for all variables consumed by the node.
 *
 * Returns chart with updated variable usage.
 * @param chart
 * @param nodeId
 */
export function countConsumeVariablesOfNode(chart: Chart, nodeId: string): Chart {
  const node = chart.nodes[nodeId]
  if (typeof node === 'undefined') return chart

  // the following node types consume variables: api (request), ifElse, switch, setVariable, eventTrigger
  let searchValue = ''
  switch (node.type) {
    case 'basic/message':
      searchValue = node.properties.text || ''
      break
    case 'basic/api':
      // consumed vars are in request object in node properties
      searchValue = JSON.stringify(node.properties.api?.request || {})
      break
    case 'logic/ifElse':
      // consumed vars are in conditions object in node properties
      // Note: there are also conditionIds (also UUIDs), but they are not taken into consideration in the count
      searchValue = JSON.stringify(node.properties.conditions)
      break
    case 'logic/switch':
      // currently, cases do not have variable support -> only variable is the one in the 'varname' property
      searchValue += node.properties.varname || ''
      break
    case 'logic/switchCondition':
      if (typeof node.properties.switchConditions !== 'undefined') {
        for (const conditionId of Object.keys(node.properties.switchConditions.conditions)) {
          searchValue += ` ${node.properties.switchConditions.conditions[conditionId]}`
        }
      }
      break
    case 'logic/setVar':
      if (typeof node.properties.setVarValues !== 'undefined') {
        for (const setVariableId of Object.keys(node.properties.setVarValues)) {
          searchValue += ` ${node.properties.setVarValues[setVariableId]}`
        }
      }
      break
    case 'logic/setVariables':
      if (typeof node.properties.setVariables !== 'undefined') {
        for (const setVariableId of Object.keys(node.properties.setVariables.setVars)) {
          searchValue += ` ${node.properties.setVariables.setVars[setVariableId]}`
        }
      }
      break
    case 'trigger/event':
      searchValue = ''
      break
  }

  chart = configureConsumeVariablesCount(chart, nodeId, searchValue)
  return chart
}

/**
 * Finds and returns all variables of a certain node.
 * Returns an array with the variable ids of that variables.
 * @param {Chart} chart
 * @param {string} nodeId
 */
export function getAllVarsOfNode(chart: Chart, nodeId?: string): string[] {
  if (typeof nodeId === 'undefined') {
    const selectedId = chart.selected?.id
    if (typeof selectedId === 'undefined') return []
    nodeId = selectedId
  }
  const node = chart.nodes[nodeId]
  return Object.keys(node.properties.variables || [])
}

/**
 * Finds and returns the display names of all variables of a certain node.
 * Returns an array with the display names.
 * @param {Chart} chart
 */
export function getAllVariableNamesOfNode(chart: Chart, nodeId?: string): string[] {
  if (typeof nodeId === 'undefined') {
    const selectedId = chart.selected?.id
    if (typeof selectedId === 'undefined') return []
    nodeId = selectedId
  }

  const varIdsOfNode = getAllVarsOfNode(chart, nodeId)

  const displayNames: string[] = []
  for (const varId of varIdsOfNode) {
    const displayName = chart.variables[varId]?.displayName
    if (displayName && !displayNames.includes(displayName)) {
      displayNames.push(displayName)
    }
  }
  return displayNames
}

/**
 * Removes all variables from node.
 * Reduces global usage count of all those variables and deletes them if they are unused.
 * @param chart
 * @param nodeId
 */
export function removeAllVariablesFromNode(chart: Chart, nodeId: string): Chart {
  if (typeof chart.nodes[nodeId] === 'undefined') return chart
  // update global variable objects
  // find all variables that are used by this node
  const varIds = Object.keys(chart.variables)

  for (const varId of varIds) {
    chart = removeVariableFromDatachecksOfNode(chart, nodeId, varId)

    const variable = chart.variables[varId]
    if (Object.keys(variable.usage).includes(nodeId)) {
      // node uses current variable
      const setUsageCount = variable.usage[nodeId].set.usageCount
      const consumeUsageCount = variable.usage[nodeId].consume.usageCount

      chart = reduceVariableUsageCount(chart, nodeId, varId, 'set', setUsageCount)
      chart = reduceVariableUsageCount(chart, nodeId, varId, 'consume', consumeUsageCount)
    }
  }

  return chart
}

/**
 * Removes a single variable occurance from a node.
 * Reduces global usage count and deletes variable if global usage count is 0.
 * Removes variable from datacheck if neccessary.
 * This does not touch the acField specific properties of the variable object. Those are handled separately.
 * @param chart
 * @param varId
 * @param nodeId
 * @param loopIter
 */
export function removeVariableFromNode(
  chart: Chart,
  usageType: 'set' | 'consume',
  varId: string,
  nodeId: string,
): Chart {
  const node = chart.nodes[nodeId]
  if (node.properties.variables && node.properties.variables[varId]) {
    // remove variables from datacheck
    // Note the <= 1 condition: we have not reduced the count yet! Will happen later.
    if (usageType === 'set' && node.properties.variables[varId].set.usageCount <= 1) {
      // remove variable from datacheck if neccessary
      // this is only neccessary if the variable has been set in the node and no longer gets set.
      chart = removeVariableFromDatachecksOfNode(chart, nodeId, varId)
    }

    chart = reduceVariableUsageCount(chart, nodeId, varId, usageType, 1)
  }

  return chart
}

/**
 * Handles variable assignment to input field.
 * NOTE: We do not touch the usageCount of the variable here as this is handled elsewhere
 * @param chart
 * @param origVariable
 * @param acFieldId
 * @param acFieldType
 * @param acChoice optional
 */
export function assignACFieldToVariable(
  chart: Chart,
  origVariable: Variable,
  acFieldId: string,
  acFieldType: string,
  acChoice?: string,
): Variable {
  const variable = cloneDeep(origVariable)

  const nodeId = chart.selected?.id
  if (typeof nodeId === 'undefined') return variable

  if (acFieldId && acFieldType) {
    const acUsageObj = variable.usage[nodeId].ac || { acFieldIds: {} }

    // add acFieldId or increase count if it already exists
    if (!Object.keys(acUsageObj.acFieldIds).includes(acFieldId)) {
      acUsageObj.acFieldIds[acFieldId] = {
        acFieldId: acFieldId,
        acFieldType: acFieldType,
        count: 1,
      }
    } else {
      acUsageObj.acFieldIds[acFieldId].count += 1
    }

    if (acChoice) {
      const choiceObj = {
        choice: acChoice,
        value: 'true',
        count: 1,
      }

      if (typeof acUsageObj.acChoices === 'undefined') acUsageObj.acChoices = {}
      if (acUsageObj.acChoices[acFieldId]) {
        const index = acUsageObj.acChoices[acFieldId].findIndex((choice) => choice.choice === choiceObj.choice)
        if (!index || index === -1) {
          // not already present
          acUsageObj.acChoices[acFieldId].push(choiceObj)
        } else {
          // already present
          // increment usage count and reinsert updated object into array
          const obj = acUsageObj.acChoices[acFieldId][index]
          obj.count += 1
          acUsageObj.acChoices[acFieldId].splice(index, 1, obj)
        }
      } else {
        acUsageObj.acChoices[acFieldId] = [choiceObj]
      }
    }
    variable.usage[nodeId].ac = acUsageObj
  }

  return variable
}

/**
 * Removes variable from AC input field
 * NOTE: We do not touch the usageCount of the variable here as this is handled solely in {@removeVariableFromNode}
 * @param origVariable
 */
export function removeVariableFromACField(
  chart: Chart,
  origVariable: Variable,
  acFieldId: string,
  acChoice?: string,
): Variable {
  const variable = cloneDeep(origVariable)

  const nodeId = chart.selected?.id
  if (typeof nodeId === 'undefined') return variable

  const acUsageObj = variable.usage[nodeId].ac
  if (typeof acUsageObj === 'undefined') return variable

  if (acFieldId && acChoice) {
    // acField is a choiceset -> remove choice from variable if variable only used in single iteration for that choice
    // and if neccessary also remove acField

    if (typeof acUsageObj.acChoices !== 'undefined') {
      // remove choice if its count is 1. If count > 1, then it is used in other loop iteration and should not be deleted, but its count should be reduced by 1
      const index = acUsageObj.acChoices[acFieldId].findIndex((choice) => choice.choice === acChoice)
      acUsageObj.acChoices[acFieldId][index].count -= 1

      if (acUsageObj.acChoices[acFieldId][index].count === 0) {
        // if count is 0, remove that choice object
        acUsageObj.acChoices[acFieldId].splice(index, 1)
      }

      // decrease count of acFieldId
      acUsageObj.acFieldIds[acFieldId].count -= 1

      // cleanup variable object
      if (acUsageObj.acChoices[acFieldId].length === 0) {
        delete acUsageObj.acChoices[acFieldId]

        if (Object.keys(acUsageObj.acChoices).length === 0) delete acUsageObj.acChoices

        // cleanup acFieldIds
        // if count of acField is 0, delete that field
        if (acUsageObj.acFieldIds[acFieldId].count === 0) delete acUsageObj.acFieldIds[acFieldId]
      }
    }
  } else if (acFieldId) {
    // not a choice set
    // cleanup acFieldIds
    // first decrease count of acField, and delete if count is 0
    acUsageObj.acFieldIds[acFieldId].count -= 1
    if (acUsageObj.acFieldIds[acFieldId].count === 0) delete acUsageObj.acFieldIds[acFieldId]
  }

  variable.usage[nodeId].ac = acUsageObj

  return variable
}

type GetSelectedVarOptions = {
  acFieldId?: string
  acChoice?: string
  apiConfigId?: string
  loopIter?: string
  isResult?: boolean
  selectedVariableIds?: string[]
}

/**
 * Helper function for finding variables that belong to variable input field.
 * Differs between node type what variables are returned:
 * - Adaptive Card (Field: ChoiceSet)
 * - Adaptive Card (Field: other)
 * - API Node
 * - other
 *
 * Returns array of variable ready to be display in VariablesAutosuggestSelect component.
 * @param chart
 * @param variableIds
 * @param options
 */
export function prepareDisplayVariableOptions(
  chart: Chart,
  variableIds: string[],
  options: GetSelectedVarOptions = {},
): DisplayVariableOption[] {
  const array: DisplayVariableOption[] = []

  const nodeId = chart.selected?.id
  if (!nodeId) return []

  variableIds.forEach((varId) => {
    const variable = chart.variables[varId]
    if (typeof variable === 'undefined') return

    // Adaptive Card Node
    if (options.acFieldId && !options.isResult) {
      if (!variable.usage[nodeId]) return array
      const nodeUsage = variable.usage[nodeId].ac
      if (options.acChoice && nodeUsage && nodeUsage.acChoices && nodeUsage.acChoices[options.acFieldId]) {
        // adaptive card field is ChoiceSet
        for (const choiceObj of nodeUsage.acChoices[options.acFieldId]) {
          if (choiceObj.choice === options.acChoice) {
            array.push({
              label: variable.displayName,
              value: variable.id,
              // choiceValue: choiceObj.value // TODO: is this still needed?
            })
            break
          }
        }
      } else if (typeof variable.usage[nodeId].ac !== 'undefined') {
        // check if current ac input field belongs to variable
        if (
          variable.usage[nodeId] &&
          Object.keys(variable.usage[nodeId].ac?.acFieldIds || {}).includes(options.acFieldId)
        ) {
          array.push({
            label: variable.displayName,
            value: variable.id,
          })
        }
      }
    } else {
      // other node
      array.push({
        label: variable.displayName,
        value: variable.id,
      })
    }
  })

  return array
}

/**
 * Returns array of all variables for display as options in the CreatableSelect component.
 * @param {Chart} chart
 */
export function getAllVariablesForSelect(chart: Chart): DisplayVariableOption[] {
  const allVarIds = Object.keys(chart.variables)

  const variables: DisplayVariableOption[] = []
  for (const id of allVarIds) {
    variables.push({
      label: chart.variables[id].displayName,
      value: id,
    })
  }

  return variables
}

/**
 * Returns all variables of a node as array to be displayed in VariablesAutosuggestSelect.
 * If selectedVariableIds are provided in the options, those variables are returned, if they are used by the node.
 *
 * Differs between node types:
 * - AdaptiveCard Node: If acFieldId is set in options, only returns variable for that field of the node.
 * - API Node: If apiConfigId is set in options, only returns variables for that response config.
 *             NOTE: We need to filter these ids to only include ids that are also present in the node's variables.
 *             This is because on de-select variables are removed from the node before they are removed from the response config
 *
 * Returns [{
 *  label: displayName,
 *  value: id
 * }, ...]
 *
 * @param chart
 * @param options - [OPTIONAL] object with options options can include [acFieldId, acChoice, apiConfigId]
 */
export function getSelectedVariables(chart: Chart, options: GetSelectedVarOptions = {}): DisplayVariableOption[] {
  if (!chart.selected.id || !chart.nodes[chart.selected.id]) return []

  const node = chart.nodes[chart.selected.id]
  if (typeof node === 'undefined') return []
  if (node.properties.variables) {
    let varIds: string[] = []

    if (!options.selectedVariableIds) {
      // Variable ids are not provided

      // ---- NO LOOP ----
      if (node.type === 'basic/api') {
        // in case of api node we have to differentiate between the different response configs
        if (options.apiConfigId) {
          // in case of API node only get variables for current response config
          // NOTE: We need to filter these ids to only include ids that are also present in the node's variables.
          //       This is because on de-select variables are removed from the node before they are removed from the response config
          try {
            const nodeVariables = Object.keys(node.properties.variables)
            const saveConfig = node.properties.api?.response.saveConfig || {}
            varIds =
              typeof saveConfig[options.apiConfigId] !== 'undefined'
                ? saveConfig[options.apiConfigId].variables || []
                : []
            varIds = varIds.filter((varId) => nodeVariables.includes(varId))
          } catch (e) {
            // do nothing
          }
        } else {
          // do nothing
        }
      } else {
        // get all variables of node
        varIds = Object.keys(node.properties.variables)
      }
    } else {
      // Variable ids are provided. Use them if they are used by the node
      const varIdsOfNode = Object.keys(node.properties.variables)
      options.selectedVariableIds.forEach((selectedVarId) => {
        if (varIdsOfNode.includes(selectedVarId)) varIds.push(selectedVarId)
      })
    }

    // ensure that variable is shown only once
    varIds = Array.from(new Set([...varIds]))
    return prepareDisplayVariableOptions(chart, varIds, options)
  }
  return []
}

/**
 * Gets and prepares selected variable for initial state.
 * @param {Chart} chart
 */
export function getVariables(chart: Chart): DisplayVariableOption[] {
  // let vars = nodeVariablesToArray(chart)
  const vars = getSelectedVariables(chart)
  const varOptions: DisplayVariableOption[] = []
  vars.forEach((variable) => {
    varOptions.push(variable)
  })
  return varOptions
}

type GetResultVariableOptions = {
  isLoop?: boolean
  loopIter?: number | string
}

/**
 * Returns variable that stores result of Action.Submit for currently selected node
 * @param chart
 */
export function getResultVariable(chart: Chart, options: GetResultVariableOptions = {}): DisplayVariableOption[] {
  if (!chart.selected.id || !chart.nodes[chart.selected.id]) return []

  const node = chart.nodes[chart.selected.id]

  if (node.properties.varname && node.properties.variables) {
    // ---- No Loop ----
    const variable = {
      label: chart.variables[node.properties.varname].displayName,
      value: node.properties.varname,
    }
    return [variable]
  }
  return []
}

/**
 * Replaces all variables ids in a string with their corresponding display name.
 * @param {Chart} chart
 * @param {string} value
 */
export function replaceVarIdWithDisplayName(chart: Chart, value: string): string {
  // replace variable ids with display name, but keep the '%' char
  let match
  let displayValue = value
  do {
    // REGEXVARID.lastIndex = 0
    match = REGEXVARID.exec(value)
    if (match) {
      const m = match[1]

      const splitDisplayValue = displayValue.split(m)
      const variable = chart.variables[m]
      // only replace if variable exists, otherwise keep varId in displayValue
      if (typeof variable !== 'undefined') displayValue = splitDisplayValue.join(variable.displayName)
    }
  } while (match)
  return displayValue
}

/**
 * Replaces all variable display names in a string with their corresponding id.
 * @param chart
 * @param displayValue
 */
export function replaceVarDisplayNameWithId(chart: Chart, displayValue: string): string {
  const allVariableNames = Object.values(chart.variables).map((variable) => variable.displayName)

  // checks if a given string is a variable name
  function isStringVariableName(candidate: string): boolean {
    return allVariableNames.includes(candidate)
  }

  // retrieves variable id based on display name or null if no variable exists with that display name
  function getVarIdForDisplayName(displayName: string): string | null {
    for (const variable of Object.values(chart.variables)) {
      if (variable.displayName === displayName) return variable.id
    }
    return null
  }

  let match
  let value = displayValue
  do {
    // do until all display values are matched
    match = REGEXVARDISPLAYNAME.exec(value)
    if (match) {
      let m = match[1] // this is the display name w/o '%'

      if (!isStringVariableName(m)) {
        // m is not a valid variable name
        // this can occur if there is no space after the variable (e.g. %var1 but it is used as %var1test)
        // in this case we remove char by char from the back of m until we have a valid variable name
        do {
          m = m.slice(0, m.length - 1)
        } while (!isStringVariableName(m) && m.length > 0)
      }

      // we need to ensure that we replace the correct display name
      // if variable a is called "Test-1-1" and variable b is called "Test-1-10", we need to ensure
      // that we actually replace "Test-1-10". We ensure this by finding the substring of the match in the value
      // and appending char by char and check for each addition if the newly formed substring is a display name
      // if that is the case, we repeat the process until the substring is not a display name
      // we then proceed and replace the actually identied substring (the built one with the appended chars) with its
      // corresponding variable id.

      // start and end index mark the substring which we need to replace
      // match.index is the position of the '%' character
      // we want to keep the '%' so we add 1
      const startIndex = match.index + 1
      let endIndex = startIndex + m.length

      while (endIndex + 1 < value.length && isStringVariableName(value.substring(startIndex, endIndex + 1))) {
        endIndex += 1
      }

      // find variable id for identified display name
      const displayName = value.substring(startIndex, endIndex)
      const varId = getVarIdForDisplayName(displayName)
      if (varId !== null) {
        // remove displayname from string
        const firstPart = value.slice(0, startIndex)
        const secondPart = value.slice(endIndex)
        value = firstPart + varId + secondPart
      }
    }
  } while (match)
  return value
}

// ===================================================================================
// SMART CARDS
// -----------------------------------------------------------------------------------

/**
 * Helper function for @findValuesInputFields
 * @param obj
 * @param key
 * @param list
 */
function findValuesInputFieldsHelper(obj: any, key: string, list: CardInputFields): CardInputFields {
  if (!obj) return list
  if (obj instanceof Array) {
    for (const i in obj) {
      list = list.concat(findValuesInputFieldsHelper(obj[i], key, []))
    }
    return list
  }
  if (obj[key] && obj[key].match('Input.')) {
    // Element has been found
    const id = obj['id']

    // Differentiate on what has to be returned.
    // ChoiceSet: Return value of each choice instead of only id (return value := id_{choiceValue})
    if (obj[key].match('Input.ChoiceSet')) {
      const choiceset: CardInputField = { id: obj['id'], choices: [], type: obj[key] }

      const choices = obj['choices']
      choices.forEach((choice) => {
        const returnValue = {
          id: id,
          acValue: choice.title ? choice.title : null,
        }
        choiceset.choices = choiceset.choices || []
        choiceset.choices.push(returnValue)
      })
      list.push(choiceset)
    } else {
      list.push({
        id: id,
        type: obj[key],
      })
    }
  }

  if (typeof obj == 'object' && obj !== null) {
    const children = Object.keys(obj)
    if (children.length > 0) {
      for (let i = 0; i < children.length; i += 1) {
        list = list.concat(findValuesInputFieldsHelper(obj[children[i]], key, []))
      }
    }
  }
  return list
}

/**
 * Finds and returns JSON array with all id of all input fields of adaptive card.
 *
 * @param {IAdaptiveCard} card json object in which values of all keys with key={key}  should be found
 * @param {string} key key to search for
 * @returns {JSON} value list - json array that holds id-value pairs {id: "id1"}. Value is id of the input field of the adaptive card
 *
 */
export function findValuesInputFields(card: IAdaptiveCard, key = 'type'): CardInputFields {
  return findValuesInputFieldsHelper(card, key, [])
}

/**
 * Helper function for finding data of all Action.Submit buttons of Actionset.
 * @param obj object that should be searched
 * @param key key that should match the value property
 * @param value value that the key has to match
 * @param returnValueKey key of the property whose value should be returned
 * @param list list that collects all found occurrances
 */
function findValuesSubmitButtonsHelper(obj: any, key: string, value: string, list: ISubmitAction[]): ISubmitAction[] {
  if (!obj) return list
  if (obj instanceof Array) {
    for (const i in obj) {
      list = list.concat(findValuesSubmitButtonsHelper(obj[i], key, value, []))
    }
    return list
  }
  if (obj[key] && obj[key].match(value)) {
    // what should be returned
    list.push(obj)
  }

  if (typeof obj == 'object' && obj !== null) {
    const children = Object.keys(obj)
    if (children.length > 0) {
      for (let i = 0; i < children.length; i += 1) {
        list = list.concat(findValuesSubmitButtonsHelper(obj[children[i]], key, value, []))
      }
    }
  }
  return list
}

// /**
//  * Finds data of all Action.Submit buttons of ActionSet of adaptive card.
//  *
//  * @param {IAdaptiveCard} obj json object in which values of all keys with key={key}  should be found
//  * @param {string} key key to search for
//  * @param {SubmitButtonProperties} returnValueKey
//  * @returns value list - json array that holds all values of "data" property of all occurrances of objects of type {key}
//  */
// export function findValuesSubmitButtons(obj: IAdaptiveCard, key: string): ISubmitAction[] {
//   return findValuesSubmitButtonsHelper(obj, key, 'Action.Submit', [])
// }

/**
 * Returns value of "data" of every Action.Submit in an AC.
 * @param {JSON} ac
 */
export function findAllACActionSubmit(ac: IAdaptiveCard): ISubmitAction[] {
  return findValuesSubmitButtonsHelper(ac, 'type', 'Action.Submit', [])
}

/**
 * Finds fieldIds of card that is / was set in card node.
 * Uses mandatory field ids property and variables that are set by the node to find fieldIds.
 * Returns fieldIds.
 * @param chart
 */
function _findFieldIdsOfPreviousCardInNode(chart: Chart, nodeId: string): string[] {
  if (typeof nodeId === 'undefined' || typeof chart.nodes[nodeId] === 'undefined') return []
  const node = chart.nodes[nodeId]

  // find old fields using mandatory input fields and variables
  let oldFieldIds: string[] = [...(node.properties.mandatoryInputFields || [])]
  for (const varId of Object.keys(node.properties.variables || {})) {
    // iterate over all variables used by node
    const variable = chart.variables[varId]
    if (Object.keys(variable.usage).includes(nodeId)) {
      const varUsageObj = variable.usage[nodeId]
      if (typeof varUsageObj.ac === 'undefined') continue

      for (const fieldId of Object.keys(varUsageObj.ac.acFieldIds)) {
        if (!oldFieldIds.includes(fieldId)) oldFieldIds.push(fieldId)
      }
    }
  }
  // ensure each field id is only once in list
  oldFieldIds = Array.from(new Set(oldFieldIds))
  return oldFieldIds
}

/**
 * Removes usage of fields that no longer exist in card from variables and updates them in node and globally.
 * Returns updated chart and list of varIds that have been updated.
 * @param chart
 * @param deletedFieldIds
 */
function _removeDeletedFieldsFromVariables(
  chart: Chart,
  deletedFieldIds: string[],
  nodeId: string,
): { chart: Chart; affectedVariables: string[] } {
  if (typeof nodeId === 'undefined' || typeof chart.nodes[nodeId] === 'undefined')
    return { chart, affectedVariables: [] }
  const node = chart.nodes[nodeId]

  // update vars used by deleted fields
  const affectedVariables: string[] = []
  for (const delFieldId of deletedFieldIds) {
    for (const varId of Object.keys(node.properties.variables || {})) {
      // update global var object
      const varUsageObj = chart.variables[varId].usage[nodeId]

      if (varUsageObj.ac && Object.keys(varUsageObj.ac.acFieldIds).includes(delFieldId)) {
        // var was assigned to deleted field
        affectedVariables.push(varId)

        const fieldUsageCount = varUsageObj.ac.acFieldIds[delFieldId].count
        const fieldType = varUsageObj.ac.acFieldIds[delFieldId].acFieldType

        if (fieldType === 'Input.ChoiceSet') {
          // delete fieldId obj from acChoices
          if (varUsageObj.ac.acChoices) {
            delete varUsageObj.ac.acChoices[delFieldId]
            // if acChoices is empty, delete it
            if (isEmpty(varUsageObj.ac.acChoices)) delete varUsageObj.ac.acChoices
          }
        }

        delete varUsageObj.ac.acFieldIds[delFieldId]

        // reduce usage count
        varUsageObj.set.usageCount -= fieldUsageCount
        chart.variables[varId].usageCount -= fieldUsageCount

        // update node var object
        if (node.properties.variables && node.properties.variables[varId])
          node.properties.variables[varId].set.usageCount -= fieldUsageCount
      }

      chart.variables[varId].usage[nodeId] = varUsageObj
    }
  }

  chart.nodes[nodeId] = node

  return {
    chart,
    affectedVariables,
  }
}

/**
 * Updates chart if a selected card in the card node is edited and saved.
 * - finds fields of prev card that we might have to update - uses mandatory fieldids and variables used by the node for that
 * - finds fields of card after edit and uses the diff to find fields that no longer exists; we have to handle those
 * -
 * @param chart
 * @param translations
 * @param nodeId - uses selectedId if nodeId is not provided
 */
export function updateChartOnSmartCardEdit(origChart: Chart, translations: TranslationFile, idNode?: string): Chart {
  let chart = cloneDeep(origChart)
  const nodeId = idNode ? idNode : chart.selected.id
  if (typeof nodeId === 'undefined' || typeof chart.nodes[nodeId] === 'undefined') return chart

  let node = chart.nodes[nodeId]
  if (typeof node === 'undefined' || typeof node.properties.card === 'undefined') return chart

  const newCard = translations.ac[translations.primaryLanguage][node.properties.card].data

  // find old fields using mandatory input fields and variables
  const oldFieldIds = _findFieldIdsOfPreviousCardInNode(chart, nodeId)

  // find new fields
  const newFieldIds = findValuesInputFields(newCard).map((inputField) => inputField.id)

  // find deleted fields
  const deletedFields: string[] = oldFieldIds.filter((fieldId) => !newFieldIds.includes(fieldId))

  // remove deleted fields from mandatory field list
  node.properties.mandatoryInputFields = node.properties.mandatoryInputFields?.filter(
    (fieldId) => !deletedFields.includes(fieldId),
  )
  chart.nodes[nodeId] = node

  // updated variables that were used by deleted fields
  const updateVarResult = _removeDeletedFieldsFromVariables(chart, deletedFields, nodeId)
  const affectedVariables = updateVarResult.affectedVariables
  chart = updateVarResult.chart
  node = chart.nodes[nodeId] // node could have been changed, so we set it new

  // at this point the variable objects are done w.r.t to the field usage (set usage)
  // we now need to handle the datachecks used by the node
  for (const datacheckId of Object.keys(node.properties.datachecks || {})) {
    if (
      typeof chart.datachecks[datacheckId] === 'undefined' ||
      !Object.keys(chart.datachecks[datacheckId].nodes).includes(nodeId)
    )
      continue

    const prevDatacheckVars = [...chart.datachecks[datacheckId].nodes[nodeId]]

    chart.datachecks[datacheckId].nodes[nodeId] = chart.datachecks[datacheckId].nodes[nodeId].filter((varId) => {
      // remove a variable if its set usage count for that node is 0
      return (
        !affectedVariables.includes(varId) ||
        (affectedVariables.includes(varId) && chart.variables[varId].usage[nodeId].set.usageCount > 0)
      )
    })

    const afterDatacheckVars = chart.datachecks[datacheckId].nodes[nodeId]
    const fromDatacheckRemovedVars = prevDatacheckVars.filter((varId) => !afterDatacheckVars.includes(varId))

    // reduce consume usage count for each removed var
    for (const varId of fromDatacheckRemovedVars) {
      if (typeof node.properties.variables !== 'undefined' && typeof node.properties.variables[varId] !== 'undefined') {
        node.properties.variables[varId].consume.usageCount -= 1
      }
      if (
        typeof chart.variables[varId] !== 'undefined' &&
        typeof chart.variables[varId].usage[nodeId] !== 'undefined'
      ) {
        chart.variables[varId].usage[nodeId].consume.usageCount -= 1
      }
    }

    const diff = prevDatacheckVars.length - afterDatacheckVars.length
    // update datacheck usage count in node and globally
    chart.datachecks[datacheckId].usageCount -= diff
    if (typeof node.properties.datachecks !== 'undefined')
      node.properties.datachecks[datacheckId].usageCountNode -= diff
  }

  chart.nodes[nodeId] = node

  // finally, update consume usage count and remove stale variables
  chart = countConsumeVariablesOfNode(chart, nodeId)

  return chart
}

// ===================================================================================
// NODES
// -----------------------------------------------------------------------------------

/**
 * Creates and adds a new node to the chart.
 * Adds node to currently active dialog.
 * Returns chart with newly added node and id of new node.
 * @param chart
 */
export function createNewNode(
  chart: Chart,
  type: NodeType,
  ports: Ports,
  properties: NodeProperties,
  position: Position,
): { chart: Chart; nodeId: string } {
  const activeDialog = chart.activeDialog

  const id = uuid()
  const chartNode: Node = {
    id: id,
    type,
    position,
    ports,
    properties,
    orientation: 0,
  }

  chart.nodes[id] = chartNode
  if (activeDialog) {
    chart.nodes[id].properties.dialog = activeDialog
    chart.dialogs[activeDialog].nodes.push(id)
  }

  return { chart, nodeId: id }
}

/**
 * Removes node from chart.
 * - removes all variables from that node to update variable usage
 * - removes all datachecks from that node to update datachecks
 * - removes all links connected to this node
 * Returns chart.
 *
 * @param chart
 * @param nodeId
 * @param updateVariableCounts toggles whether variable usages should be updated
 * @param removeLinks toggles whether links are removed or not.
 *                    This can be disabled for performance reasons.
 *                    Use with caution! Might leave chart in invalid state and
 *                    link deletion has to be handled explicitly!
 */
export function removeNodeFromChart(
  chart: Chart,
  nodeId: string,
  updateVariableCounts = true,
  removeLinks = true,
): Chart {
  // remove variables
  chart = removeAllVariablesFromNode(chart, nodeId)
  // remove datachecks
  chart = removeAllDatachecksFromNode(chart, nodeId, updateVariableCounts)
  // remove node from dialog
  const dialogId = chart.nodes[nodeId].properties.dialog
  chart.dialogs[dialogId].nodes = chart.dialogs[dialogId].nodes.filter((nId) => nId !== nodeId)

  if (removeLinks) {
    // iterate over all links and see if they are connected to node
    for (const link of Object.values(chart.links)) {
      if (link.from.nodeId === nodeId || link.to.nodeId === nodeId) delete chart.links[link.id]
    }
  }

  delete chart.nodes[nodeId]

  return chart
}

// ===================================================================================
// CHART & PORTS
// -----------------------------------------------------------------------------------

/**
 * Removes properties from the chart that may cause cyclic structure crash when parsing the chart.
 * @param chart
 */
export function clearCyclicStructure(chart: Chart): Chart {
  /**
   * Replacer function that removes object that cause cyclic structure.
   * Removes properties that are added once a node has been moved in the
   */
  function _replacer(k: string, val: string): string | undefined {
    switch (k) {
      case 'node':
      case 'deltaX':
      case 'deltaY':
      case 'lastX':
      case 'lastY':
        return undefined
      default:
        return val
    }
  }

  return JSON.parse(JSON.stringify(chart, _replacer))
}

/**
 * Adds a new outgoing port to a node and gives it the specified name.
 * @param chart
 * @param nodeId
 * @param portName
 */
export function addOutgoingPortToNode(chart: Chart, nodeId: string, portName?: string): Chart {
  if (typeof chart.nodes[nodeId] === 'undefined') return chart

  const ports = cloneDeep(chart.nodes[nodeId].ports)
  const length = Object.keys(ports).length
  ports[`port${length + 1}`] = {
    id: `port${length + 1}`,
    type: 'right',
    properties: {
      name: portName,
      type: 'outgoing',
    },
  }
  chart.nodes[nodeId].ports = ports
  return chart
}

/**
 * Removes a given outgoing port from the node and updates the chart.
 * Checks for links and reorders remaining ports to keep a clear and ordered portId structure
 * @param {chart} chartInput chart that is operated on
 * @param {string} portId Id of the outgoing port to be removed
 */
export function removeOutgoingPortFromNode(
  chartInput: Chart,
  portId: string,
): { ports: { [portId: string]: Port }; chart: Chart } {
  const chart = chartInput
  if (typeof chart.selected.id === 'undefined') return { ports: {}, chart }

  const nodeId = chart.selected.id
  let portsOld = cloneDeep(chart.nodes[chart.selected.id].ports)
  const portsNew = {}
  const links = chart.links
  let linkKeys = Object.keys(links)
  const changes: { old: string; new: string }[] = []

  // 1. remove port with the given portId from the node's ports
  portsOld = omit(portsOld, [portId])
  const portKeys = Object.keys(portsOld)

  // 2. reorder ports and port ids - so we wont end up with portIds like port1, port5, port1231
  //    but have always a consistent order of port1, port2, port3
  for (const [portIndex, portKey] of portKeys.entries()) {
    if (portKey === `port${portIndex + 1}`) {
      // If the portId fits to the ports position just copy it in the new ports object
      portsNew[portKey] = portsOld[portKey]
    } else {
      // give port a new Id fitting to its new position and copy its properties
      portsNew[`port${portIndex + 1}`] = {
        id: `port${portIndex + 1}`,
        type: 'right',
        properties: portsOld[portKey].properties,
      }

      // collect changed portIds so we can change the portIds in the affected links in step 4.
      changes.push({ old: portKey, new: `port${portIndex + 1}` })
    }
  }

  // 3. Remove links connected to the removed port
  for (const linkKey of linkKeys) {
    if (links[linkKey].from.nodeId === nodeId && links[linkKey].from.portId === portId) {
      delete links[linkKey]
    }
  }

  // 4. Update links based on the portId changes
  linkKeys = Object.keys(links) // updated linkKey list
  // go through each changed portId and update links that have it as an from port with its new id
  for (const change of changes) {
    for (const linkKey of linkKeys) {
      if (links[linkKey].from.nodeId === nodeId && links[linkKey].from.portId === change.old) {
        links[linkKey].from.portId = change.new
      }
    }
  }
  // 5. update links and ports in the chart
  chart.links = links
  chart.nodes[chart.selected.id].ports = portsNew

  // return new ports and the chart
  return { ports: portsNew, chart }
}

/**
 * Removes all outgoing ports of a specific node.
 * @param chart
 * @param nodeId
 */
export function removeAllOutgoingPortsFromNode(chart: Chart, nodeId: string): Chart {
  if (typeof chart.nodes[nodeId] === 'undefined') return chart
  const newChart = cloneDeep(chart)
  const ports = chart.nodes[nodeId].ports
  const portIds = Object.keys(ports)
  for (const portId of portIds) {
    if (ports[portId].type === 'right') {
      // delete link if connected
      for (const [linkId, link] of Object.entries(newChart.links)) {
        if (link.from.nodeId === nodeId && link.from.portId === portId) delete newChart.links[linkId]
      }
      // delete port
      delete newChart.nodes[nodeId].ports[portId]
    }
  }
  return newChart
}

/**
 * Generates start node object.
 */
function createStartNode(dialogId: string, dialogName: string): StartNode {
  const id = uuid()

  const startNode: StartNode = {
    id,
    type: 'start',
    orientation: 0,
    position: {
      x: 0,
      y: 1000,
    },
    ports: {
      port1: {
        id: 'port1',
        type: 'right',
        properties: {
          type: 'outgoing',
        },
      },
    },
    size: {
      width: 200,
      height: 110,
    },
    properties: {
      text: `!skip`,
      typeText: 'Start Block',
      dialog: dialogId,
    },
  }

  return startNode
}

/**
 * Creates a new dialog.
 * Adds new dialog to chart and inits it with start node.
 * Returns updated chart object.
 * @param chart
 */
export function createDialog(
  chart: Chart,
  dialogName: string,
  dialogDescription: string,
): { chart: Chart; dialogId: string } {
  const dialogId = uuid()
  const startNode = createStartNode(dialogId, dialogName)

  if (!chart.dialogs) chart.dialogs = {}

  // init new dialog object
  chart.dialogs[dialogId] = {
    id: dialogId,
    name: dialogName,
    description: dialogDescription,
    nodes: [startNode.id],
    startNode: startNode.id,
    offset: { x: 400, y: -400 },
  }
  // add new node
  chart.nodes[startNode.id] = startNode

  // set newly created dialog as active dialog
  chart = selectDialog(chart, dialogId)
  return { chart, dialogId }
}

/**
 * Deletes dialog if it is not the main dialog.
 * - removes all nodes of that dialog
 * - removes all links of those nodes
 * - removes dialog from dialogs
 * Returns updated chart.
 *
 * @param chart
 * @param dialogId
 */
export function deleteDialog(chart: Chart, dialogId: string): Chart {
  // return if dialog does not exist or is main dialog
  if (!chart.dialogs[dialogId] || chart.mainDialog === dialogId) return chart

  const nodeIds = chart.dialogs[dialogId].nodes
  nodeIds.forEach((nodeId) => {
    // remove node from chart
    // NOTE: we disable link deletion because we do this separately -> we only need to iterate over all links once, instead of n times
    chart = removeNodeFromChart(chart, nodeId, true, false)
  })

  // remove links
  for (const link of Object.values(chart.links)) {
    if (nodeIds.includes(link.from.nodeId) || nodeIds.includes(link.to.nodeId)) delete chart.links[link.id]
  }

  // remove dialog
  delete chart.dialogs[dialogId]

  return chart
}

/**
 * Selects dialog.
 * Sets active dialog to new dialog id.
 * Updates chart offsets for previous and for current dialog.
 * @param chart
 * @param selectedDialogId
 */
export function selectDialog(chart: Chart, selectedDialogId: string): Chart {
  const newChart = cloneDeep(chart)
  const prevDialogId = chart.activeDialog
  const prevOffset = chart.offset
  // set offset in previous dialog to save it for next time
  if (prevDialogId) chart.dialogs[prevDialogId].offset = prevOffset

  if (chart.dialogs[selectedDialogId].offset) newChart.offset = chart.dialogs[selectedDialogId].offset

  newChart.activeDialog = selectedDialogId

  return newChart
}

/**
 * Finds and removes all links that are connected to a node.
 * @param chart
 * @param nodeId
 */
export function deleteConnectedLinksOfNode(chart: Chart, nodeId: string): Chart {
  const linkIds = Object.values(chart.links)
    .filter((link) => link.from.nodeId === nodeId || link.to.nodeId === nodeId)
    .map((link) => link.id)
  linkIds.forEach((linkId) => delete chart.links[linkId])
  return chart
}

/**
 * Sets all actions.submit buttons of a card as outgoing ports of the node.
 * Portname is the data property of the action.submit button.
 *
 * Removes all existing outgoing ports and adds new ones.
 * @param chart
 * @param card
 */
export function setOutgoingPortsForCard(chart: Chart, card: IAdaptiveCard): Chart {
  const selectedNodeId = chart.selected.id
  if (typeof selectedNodeId === 'undefined') return chart
  // remove all existing outgoing ports and connected links
  let newChart = removeAllOutgoingPortsFromNode(chart, selectedNodeId)

  // find all Action.Submit with data property
  const submitButtons = findAllACActionSubmit(card)

  if (
    submitButtons.length === 0 ||
    (submitButtons.length === 1 && submitButtons[0].data && submitButtons[0]?.data['.stepBack'] === 'true')
  ) {
    // Scenario 1: Card has no action.submit
    // card/content with no submit button
    // OR Scenario 2: Card with single action.submit that is stepback
    // add single default port w/o name
    newChart.nodes[selectedNodeId].properties.hasCardActions = false
    newChart = addOutgoingPortToNode(newChart, selectedNodeId)
  } else {
    // card has actions (scenario 3 & 4)
    // card/action / card/custom -> each submit action is port (except stepback)
    newChart.nodes[selectedNodeId].properties.hasCardActions = true

    const portNames: string[] = []
    submitButtons.forEach((buttonObject) => {
      if (buttonObject.data && buttonObject.data['.stepBack'] === 'true') {
        // do nothing - we ignore "back" button for outgoing ports
      } else {
        // add button as outgoing port
        portNames.push(buttonObject.title || '')
      }
    })

    for (const port of portNames) {
      newChart = addOutgoingPortToNode(newChart, selectedNodeId, port)
    }
  }

  return newChart
}

// ============= LAYOUT =================

/**
 * Aligns nodes to grid.
 * This is required because in earlier versions nodes could be completely freely positioned as we did not have a grid, hence they had "random" positional values.
 * With react-flow, we introduced a grid layout.
 * The framework handles the grid in such a way, that the grid is calculated for each node based on the node's position. If the positions do not follow a consistent pattern like
 * (x: 20, y: 40), (x: 40, y: 40) but rather (x: 21, y: 48), (x: 27, y: 58) this leads to missaligned nodes regardless of the grid.
 * This fixes this by moving all nodes by a few pixel to the next position that lays on the grid, allowing to align them as expected.
 */
export function alignNodesOnGrid(_chart: Chart): Chart {
  const chart = cloneDeep(_chart)
  for (const [nodeId, node] of Object.entries(chart.nodes)) {
    // adjust positions
    // if grid size is 20, move nodes down (or up) to next value with mod 20 = 0
    const adjustedPosition: Position = {
      x:
        node.position.x > 0
          ? node.position.x - (node.position.x % FLOWDESIGNER_GRID_SIZE)
          : node.position.x + (node.position.x % FLOWDESIGNER_GRID_SIZE),
      y:
        node.position.y > 0
          ? node.position.y - (node.position.y % FLOWDESIGNER_GRID_SIZE)
          : node.position.y + (node.position.y % FLOWDESIGNER_GRID_SIZE),
    }
    node.position = adjustedPosition
    chart.nodes[nodeId] = node
  }
  return chart
}

// ============ (CUSTOM) ANALYTICS EVENTS =============

/**
 * Finds eventId of custom analytics event given the event name
 * @param chart
 * @param eventName
 */
export function findEventIdByEventName(chart: Chart, eventName: string): string | undefined {
  let eventId
  for (const customEvent of Object.values(chart.customAnalyticsEvents)) {
    if (customEvent.eventName === eventName) {
      eventId = customEvent.eventId
      break
    }
  }
  return eventId
}

/**
 * De-selects analytics event from node.
 * Removes event from node properties and also removes node ids from global event object.
 * @param _chart
 * @param eventType
 * @param nodeId
 * @param eventName
 * @param eventId
 */
export function removeAnalyticsEventFromNode(
  _chart: Chart,
  eventType: CustomAnalyticsEvent['origin'] | 'convaise',
  nodeId: string,
  eventName: string,
  eventId?: string,
): Chart {
  const chart = cloneDeep(_chart)
  if (eventType === 'customer') {
    if (!eventId) {
      // try to find event id
      for (const customEvent of Object.values(chart.customAnalyticsEvents)) {
        if (customEvent.eventName === eventName) {
          eventId = customEvent.eventId
          break
        }
      }
    }

    // remove old custom event

    // remove from node properties
    delete chart.nodes[nodeId].properties.analyticsEvent

    if (eventId) {
      // remove nodeid from custom event object
      chart.customAnalyticsEvents[eventId].nodeIds = chart.customAnalyticsEvents[eventId].nodeIds.filter(
        (nId) => nId !== nodeId,
      )
    }
  } else {
    // predefined convaise event
    chart.nodes[nodeId].properties.analyticsEvent = {
      origin: 'convaise',
      eventName,
    }
  }

  return chart
}

/**
 * Selects an existing (custom or predefined) event in a trigger/analytics node.
 * Sets node properties and updates global event if custom event.
 * @param chart
 * @param nodeId
 * @param eventId
 */
export function selectAnalyticsEventInNode(
  _chart: Chart,
  eventType: CustomAnalyticsEvent['origin'] | 'convaise',
  nodeId: string,
  eventName: string,
  eventId?: string, // only if custom event because convaise events don't have eventId
): Chart {
  let chart = cloneDeep(_chart)

  if (eventType === 'customer') {
    eventName = eventName.startsWith('customer/') ? eventName : `customer/${eventName}`

    if (!eventId) eventId = findEventIdByEventName(chart, eventName)
    if (!eventId) {
      console.error('Could not find eventId based on eventName')
      return chart
    }

    // remove old custom event
    chart = removeAnalyticsEventFromNode(chart, 'customer', nodeId, eventName, eventId)

    // custom event
    const event = chart.customAnalyticsEvents[eventId]
    // populate node properties
    chart.nodes[nodeId].properties.analyticsEvent = {
      origin: 'customer',
      eventName: eventName,
      customEvent: {
        eventName,
        origin: 'customer',
        description: event.description,
        eventId,
      },
    }

    // add nodeid to custom event object
    if (!chart.customAnalyticsEvents[eventId].nodeIds.includes(nodeId))
      chart.customAnalyticsEvents[eventId].nodeIds.push(nodeId)
  } else {
    // predefined convaise event
    chart.nodes[nodeId].properties.analyticsEvent = {
      origin: 'convaise',
      eventName,
    }
  }

  return chart
}

/**
 * Creates new custom (!) event in the flowchart.
 * Prepends event name with "customer/" if not already happened.
 * @param chart
 * @param eventName
 * @param eventDescription
 * @returns
 */
export function createAndSelectCustomAnalyticsEvent(
  _chart: Chart,
  eventName: string,
  eventDescription: string,
  nodeId: string,
): Chart {
  let chart = cloneDeep(_chart)

  const eventId = uuid()
  const event: CustomAnalyticsEvent = {
    eventName: eventName.startsWith('customer/') ? eventName : `customer/${eventName}`,
    eventId,
    description: eventDescription,
    origin: 'customer',
    nodeIds: [],
  }
  if (!chart.customAnalyticsEvents) chart.customAnalyticsEvents = {}
  chart.customAnalyticsEvents[eventId] = event

  chart = selectAnalyticsEventInNode(chart, 'customer', nodeId, eventName, eventId)

  return chart
}

/**
 * Changes name and/or description of a custom analytics event.
 * This updates the global custom event object and also the node properties of each node that uses this event!
 * (node properties have eventName and description to make them searchable in the node search)
 * @param chart
 * @param eventId
 * @param newDescription
 * @returns
 */
export function changeAnalyticsEventNameAndDescription(
  _chart: Chart,
  eventId: string,
  newName: string,
  newDescription: string,
): Chart {
  if (!eventId) return _chart

  const chart = cloneDeep(_chart)

  // update eventName of customEvent
  chart.customAnalyticsEvents[eventId].eventName = newName
  chart.customAnalyticsEvents[eventId].description = newDescription

  // update eventName in all nodes
  for (const nodeId of chart.customAnalyticsEvents[eventId].nodeIds) {
    const nodeProperties = chart.nodes[nodeId]?.properties
    const customEventOfNode = nodeProperties?.analyticsEvent?.customEvent
    if (nodeProperties && nodeProperties.analyticsEvent && customEventOfNode) {
      nodeProperties.analyticsEvent.eventName = newName
      customEventOfNode.eventName = newName
      customEventOfNode.description = newDescription
      nodeProperties.analyticsEvent.customEvent = customEventOfNode
    }
  }

  return chart
}
