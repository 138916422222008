import { FormControlLabel, FormGroup, Switch, Typography, useTheme } from '@mui/material'
import { Option, SelectDropdown } from 'components/Dropdown'
import { useBotContext } from 'hooks/contexts/bot-context'
import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { BotSettings } from '../../@types/Settings/types'
import { useLockingContext } from 'hooks/contexts/locking-context'
import { Textfield } from 'components/TextInput/Textfield'

type Colors = {
  primaryColor?: string
  primaryColorText?: string
  disabledColor?: string
}

const useStyles = makeStyles()((theme) => ({
  content: {},
  buttons: { display: 'flex' },
  button: { width: '180px', margin: `0 ${theme.spacing(1)}` },
  heading: { margin: `${theme.spacing(2)} 0`, marginBottom: theme.spacing(3) },
  row: { display: 'flex' },
  keyTypography: { margin: 'auto 0' },
  generalContainer: { marginTop: theme.spacing(5) },
  stylesContainerContent: { display: 'flex', flexDirection: 'column' },
  avatarContainer: { marginTop: theme.spacing(2), width: '100%', marginBottom: theme.spacing(2) },
  settingWrapperWithMargin: { marginTop: theme.spacing(2) },
  colorsContainer: {
    maxWidht: '200px',
    maxHeight: '200px',
  },
  avatarUpload: {
    height: '200px',
    width: '200px',
    border: '3px dotted grey',
    display: 'flex',
  },
  avatarImage: {
    width: '100%',
    height: 'fit-content',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  colorPreview: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: theme.spacing(1),
    width: '40px',
    height: '40px',
    borderRadius: '50%',
  },
}))

const channelOptions = [
  { label: 'Directline', value: 'directline' },
  { label: 'Courier', value: 'courier' },
]

const botInstanceOptions = [
  { label: 'Production', value: 'prod' },
  { label: 'Staging', value: 'staging' },
  { label: 'Local Development', value: 'local-development' },
]

const botInstanceOptionsCourier = [
  { label: 'Hosted', value: 'prod' },
  { label: 'Local Development', value: 'local-development' },
]

type IntegrationSettingsProps = {
  integrationChannelSettings: BotSettings['integrationChannels']
  onIntegrationChannelSettingsChange: (newIntegrationChannelSettings: BotSettings['integrationChannels']) => void
}

export default function IntegrationChannelSettings({
  integrationChannelSettings,
  onIntegrationChannelSettingsChange,
}: IntegrationSettingsProps): React.ReactElement {
  const isInitialized = useRef(false)
  const { classes } = useStyles()
  const theme = useTheme()
  const { bot } = useBotContext()
  const { lockState } = useLockingContext()

  // Custom webchat settings
  const [channel, setChannel] = useState<'courier' | 'directline'>()
  const [productionBot, setProductionBot] = useState<'prod' | 'staging' | 'local-development'>()
  const [stagingBot, setStagingBot] = useState<'prod' | 'staging' | 'local-development'>()
  const [courierSecret, setCourierSecret] = useState<string>()

  function updateIntegrationChannelSettings() {
    onIntegrationChannelSettingsChange({
      production: {
        channel: channel ?? 'directline',
        configuration: {
          bot: productionBot ?? 'prod',
          secret: channel === 'courier' ? courierSecret : undefined,
        },
      },
      staging: {
        channel: channel ?? 'directline',
        configuration: {
          bot: stagingBot ?? 'staging',
          secret: channel === 'courier' ? courierSecret : undefined,
        },
      },
    })
  }

  function onChannelChange(selectedChannel: Option) {
    setChannel(selectedChannel.value as 'courier' | 'directline')
  }

  function onCourierSecretChange(event: React.ChangeEvent<HTMLInputElement>) {
    setCourierSecret(event.target.value)
  }

  function onProductionBotChange(selectedBot: Option) {
    setProductionBot(selectedBot.value as 'prod' | 'staging' | 'local-development')
  }

  function onStagingBotChange(selectedBot: Option) {
    setStagingBot(selectedBot.value as 'prod' | 'staging' | 'local-development')
  }

  useEffect(() => {
    console.log('UPDATE SETTINGS INTEGRATION USE EFFECT')
    updateIntegrationChannelSettings()
  }, [channel, productionBot, stagingBot, courierSecret])

  useEffect(() => {
    if (isInitialized.current) return
    if (channel !== integrationChannelSettings?.production?.channel)
      setChannel(integrationChannelSettings?.production?.channel ?? 'directline')
    if (productionBot !== integrationChannelSettings?.production?.configuration?.bot)
      setProductionBot(integrationChannelSettings?.production?.configuration?.bot ?? 'prod')
    if (stagingBot !== integrationChannelSettings?.staging?.configuration?.bot)
      setStagingBot(integrationChannelSettings?.staging?.configuration?.bot ?? 'staging')
    if (courierSecret !== integrationChannelSettings?.production?.configuration?.secret)
      setCourierSecret(integrationChannelSettings?.production?.configuration?.secret ?? '')

    isInitialized.current = true
  }, [integrationChannelSettings])

  return (
    <div id='webchat-settings' className={classes.generalContainer}>
      <Typography className={classes.heading} variant='h2'>
        Integration Channel Einstellungen
      </Typography>
      <div className={classes.content}>
        <div className={classes.settingWrapperWithMargin}>
          {bot?.webchatVersion === 'v4' && (
            <div className={classes.settingWrapperWithMargin}>
              <Typography>Kommunikationsinfrastruktur</Typography>
              <SelectDropdown
                options={channelOptions}
                selected={channel}
                onChange={onChannelChange}
                isDisabled={lockState !== 'canEdit'}
                width='auto'
              />
            </div>
          )}
          {channel === 'courier' && (
            <div className={classes.settingWrapperWithMargin}>
              <Typography>Courier Integration Token</Typography>
              <Textfield
                placeholder='JWT Token für Courier-Auth'
                value={courierSecret}
                onChange={onCourierSecretChange}
                fullWidth
                disabled={lockState !== 'canEdit'}
                hint='Botspezifischer Integration Token für die Authentifizierung mit dem Courier Endpunkt.'
              />
            </div>
          )}
          {channel === 'directline' && (
            <>
              <div className={classes.settingWrapperWithMargin}>
                <Typography>Production Botinstanz</Typography>
                <SelectDropdown
                  options={channel === 'directline' ? botInstanceOptions : botInstanceOptionsCourier}
                  selected={productionBot}
                  onChange={onProductionBotChange}
                  isDisabled={lockState !== 'canEdit'}
                  width='auto'
                  placeholder='Production Botinstanz auswählen'
                />
              </div>
              {bot?.webchatVersion === 'v4' && (
                <div className={classes.settingWrapperWithMargin}>
                  <Typography>Staging Botinstanz</Typography>
                  <SelectDropdown
                    options={channel === 'directline' ? botInstanceOptions : botInstanceOptionsCourier}
                    selected={stagingBot}
                    onChange={onStagingBotChange}
                    isDisabled={lockState !== 'canEdit'}
                    width='auto'
                    placeholder='Staging Botinstanz auswählen'
                  />
                </div>
              )}
            </>
          )}
          {channel === 'courier' && (
            <div className={classes.settingWrapperWithMargin}>
              <Typography>Courier Deployment</Typography>
              <SelectDropdown
                options={botInstanceOptionsCourier}
                selected={productionBot}
                onChange={(value: Option) => {
                  setProductionBot(value.value as 'prod' | 'local-development')
                  setStagingBot(value.value as 'prod' | 'local-development')
                }}
                isDisabled={lockState !== 'canEdit'}
                width='auto'
                placeholder='Production Botinstanz auswählen'
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
