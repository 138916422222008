import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Check } from '@mui/icons-material'

import { Dialog } from '../../../components/Dialogs'
import { Textfield } from '../../../components/TextInput/Textfield'
import { Button } from '../../../components/Buttons'

import { Chart } from '../../../@types/Flowchart/types'
import { Typography } from '@mui/material'
import CustomizedTooltip from 'components/Tooltips/CustomContentTooltip'

const useStyles = makeStyles()((theme) => ({
  textfield: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  dialogIdContainer: { marginTop: theme.spacing(2) },
  nameContainer: {},
  descContainer: {
    marginTop: theme.spacing(3),
  },
  smallCopyButton: {
    cursor: 'pointer',
    fontSize: '0.8rem',
    position: 'relative',
    marginLeft: theme.spacing(1),
    // top: '2px',
  },
}))

type CreateDialogModalProps = {
  dialogId: string
  chart: Chart
  onClose: () => void
  onEdit: (dialogId: string, dialogName: string, dialogDescription: string) => void
}

export default function EditSubdialogModal({
  dialogId,
  chart,
  onClose,
  onEdit,
}: CreateDialogModalProps): React.ReactElement {
  const { classes } = useStyles()
  const [existingDialogNames, setExistingDialogNames] = useState<string[]>([])
  const [dialogName, setDialogName] = useState<string>()
  const [dialogNameError, setDialogNameError] = useState<string>()
  const [dialogDescription, setDialogDescription] = useState<string>()
  const [copyState, setCopyState] = useState(false)

  /**
   * Handles name change event.
   * @param event
   */
  function onNameChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setDialogName(event.target.value)
    if (dialogNameError) setDialogNameError(undefined)
  }

  /**
   * Handles onBlur event of name textfield.
   * Validates whether name is valid and does not exist yet.
   */
  function onNameBlur(): void {
    if (!dialogName || existingDialogNames.includes(dialogName))
      setDialogNameError('Es existiert bereits ein Dialog mit diesem Namen!')
    else setDialogNameError(undefined)
  }

  /**
   * Handles description change event.
   * @param event
   */
  function onDescriptionChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setDialogDescription(event.target.value)
  }

  /**
   * Handles final button click to create dialog.
   */
  function onEditConfirmClick(): void {
    if (dialogName && dialogDescription) onEdit(dialogId, dialogName, dialogDescription)
  }

  useEffect(
    function () {
      // find existing dialog names
      const dialogNames = Object.values(chart.dialogs ?? {}).map((dialog) => dialog.name)
      setExistingDialogNames(dialogNames)
      setDialogName(chart.dialogs[dialogId].name)
      setDialogDescription(chart.dialogs[dialogId].description)
    },
    [chart],
  )

  return (
    <Dialog
      id='create-dialog-modal'
      size='medium'
      open
      closable
      onClose={onClose}
      title={'Dialog bearbeiten'}
      primaryActionButton={
        <Button
          type='success'
          size='small'
          icon={<Check />}
          onClick={onEditConfirmClick}
          disabled={!!dialogNameError || !dialogName || !dialogDescription}
        >
          Speichern
        </Button>
      }
      secondaryActionText={'Abbrechen'}
      onSecondaryActionClick={onClose}
    >
      <div className={classes.nameContainer}>
        <Typography>Name des Dialogs:</Typography>
        <Textfield
          className={classes.textfield}
          onChange={onNameChange}
          onBlur={onNameBlur}
          multiline={false}
          value={dialogName}
          error={!!dialogNameError}
          label={'Name des Dialoges'}
          helperText={dialogNameError ?? ''}
        />
      </div>
      <div className={classes.descContainer}>
        <Typography>Dialogbeschreibung</Typography>
        <Textfield
          className={classes.textfield}
          onChange={onDescriptionChange}
          multiline={false}
          value={dialogDescription}
          label={'Beschreibung'}
        />
      </div>
      <div className={classes.dialogIdContainer}>
        <Typography variant='caption'>Dialog ID: {dialogId}</Typography>
        <CustomizedTooltip
          content={<Typography>Dialog ID kopieren</Typography>}
          elements={
            <i
              className={`${copyState ? 'ri-check-line' : 'ri-clipboard-line'} ${classes.smallCopyButton}`}
              onClick={(): void => {
                // Copy nodeId to clipboard
                navigator.clipboard.writeText(dialogId)
                setCopyState(true)
                setTimeout(() => {
                  setCopyState(false)
                }, 2000)
              }}
            />
          }
        />
      </div>
    </Dialog>
  )
}
