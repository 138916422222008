import React, { ReactElement } from 'react'
import { makeStyles } from 'tss-react/mui'

import { useDraggableContextDispatch } from '../../../hooks/contexts/useDraggableContext'

const useStyles = makeStyles()(() => ({
  root: {
    marginBottom: '10px',
  },
  title: {
    color: '#828282',
    fontSize: 14,
    lineHeight: '17px',
    marginBottom: '5px',
    width: 'fit-content',
    cursor: 'default',
  },
}))

type PopupContentBlockProps = {
  title: string
  content: ReactElement
}

/**
 * This component makes up the basic building blocks of the popup content.
 * Has a title and a content. Content is another component.
 */
export const PopupContentBlock = ({ title, content }: PopupContentBlockProps): ReactElement => {
  const { classes } = useStyles()
  const draggableContextDispatch = useDraggableContextDispatch()

  return (
    <div className={classes.root}>
      <div
        onMouseEnter={(): void => draggableContextDispatch({ type: false })}
        onMouseLeave={(): void => draggableContextDispatch({ type: true })}
        className={classes.title}
      >
        {title}
      </div>
      <div
        onMouseEnter={(): void => draggableContextDispatch({ type: false })}
        onMouseLeave={(): void => draggableContextDispatch({ type: true })}
      >
        {content}
      </div>
    </div>
  )
}
