import React from 'react'
import { makeStyles } from 'tss-react/mui'

// @mui/material components
import { TextField, FormControlLabel, Checkbox } from '@mui/material'

import { Chart } from '../../../../../@types/Flowchart/types'

const useStyles = makeStyles()({
  selectedDialogContainer: {},
  title: {},
  marginTopMedium: { marginTop: '15px' },
})

type SPTextsProps = {
  chart: Chart
  setStateCallback: (chart: Chart) => void
}

export default function SPTexts({ chart, setStateCallback }: SPTextsProps): React.ReactElement {
  const { classes } = useStyles()

  function onTextChange(event: React.ChangeEvent<HTMLInputElement>): void {
    if (typeof chart.selected?.id === 'undefined') return

    chart.nodes[chart.selected.id].properties.text = event.target.value
    setStateCallback(chart)
  }

  function onStepbackToggle(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void {
    if (typeof chart.selected?.id === 'undefined') return

    chart.nodes[chart.selected.id].properties.stepbackAllowed = checked
    setStateCallback(chart)
  }

  return (
    <div className={classes.selectedDialogContainer}>
      <React.Fragment>
        <div style={{ display: 'flex', width: '100%' }}>
          <h5 className={classes.title}>
            {typeof chart.selected?.id !== 'undefined' ? chart.nodes[chart.selected.id].properties.typeText : ''}
          </h5>
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  typeof chart.selected?.id !== 'undefined'
                    ? chart.nodes[chart.selected.id].properties.stepbackAllowed
                    : false
                }
                onChange={onStepbackToggle}
                name='stepback'
                style={{ color: '#0b2639' }}
              />
            }
            label='Erlaube Korrigieren des vorherigen Schrittes'
            labelPlacement='start'
            style={{ marginLeft: 'auto' }}
          />
        </div>
        <div>
          <TextField
            id='outlined-multiline-static'
            label='Aufforderung zum Senden von Bildern'
            multiline
            fullWidth
            maxRows={5}
            value={typeof chart.selected?.id !== 'undefined' ? chart.nodes[chart.selected.id].properties.text : ''}
            onChange={onTextChange}
            margin='normal'
          />
        </div>
      </React.Fragment>
    </div>
  )
}
