import React, { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useErrorContext } from '../../../../../../hooks/contexts/errorContext'

import { CheckCircle, Delete, FlashOn, Add } from '@mui/icons-material'
import { IconButton, Tooltip, Typography } from '@mui/material'

import { Textfield } from '../../../../../../components/TextInput/Textfield'
import { Button } from '../../../../../../components/Buttons'
import Table from '../../../../../../components/Table/Table'
import ErrorComponent from '../../../../../../components/Error/Error'

import { paraphrase } from '../../../../../../api/StudioBackend'
import { useAnswers } from 'hooks/contexts/answers-context'
import { useBotContext } from 'hooks/contexts/bot-context'

const useParaphraseListStyles = makeStyles()((theme) => ({
  container: {
    width: '100%',
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  rowActionContainer: {
    display: 'flex',
  },
  iconButton: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 'auto',
    color: theme.palette.primary.main,
  },
  icon: {
    fontSize: '1.5rem',
  },
}))

const useStyles = makeStyles()((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  overflowContainer: {
    marginTop: theme.spacing(3),
    overflow: 'auto',
  },
  flexContainer: {
    display: 'flex',
  },
  tooltip: {
    display: 'inline-block',
    textAlign: 'right',
  },
  iconButton: {
    marginLeft: 'auto',
    marginBottom: 'auto',
    marginTop: 'auto',
    color: theme.palette.primary.main,
  },
  utteranceListContainer: {
    marginBottom: theme.spacing(1),
  },
  utteranceContainer: {
    marginTop: theme.spacing(1),
    display: 'flex',
  },
  removeUtteranceTooltip: {
    display: 'inline-block',
    textAlign: 'right',
  },
  icon: {
    fontSize: '1.5rem',
  },
  paraphrasesContainer: {},
  textfieldContainer: { marginTop: 'auto', paddingTop: theme.spacing(3) },
  textfield: { width: '100%' },
}))

type ParaphraseTableRowActionsProps = {
  index: number
  onAddPhrase: (index: number) => void
  onRemovePhrase: (index: number) => void
}

// Handles add and remove buttons for paraphrases
function ParaphraseTableRowActions({
  index,
  onAddPhrase,
  onRemovePhrase,
}: ParaphraseTableRowActionsProps): React.ReactElement {
  const { classes: styles } = useStyles()
  const { classes: styles2 } = useParaphraseListStyles()
  const classes = { ...styles, styles2 }

  return (
    <div className={classes.flexContainer}>
      <Tooltip className={classes.tooltip} title='Vorschlag hinzufügen' placement='top' disableInteractive>
        <IconButton className={classes.iconButton} aria-label='Add' onClick={(): void => onAddPhrase(index)}>
          <CheckCircle />
        </IconButton>
      </Tooltip>
      <Tooltip className={classes.tooltip} title='Vorschlag entfernen' placement='top' disableInteractive>
        <IconButton className={classes.iconButton} aria-label='Delete' onClick={(): void => onRemovePhrase(index)}>
          <Delete />
        </IconButton>
      </Tooltip>
    </div>
  )
}

type ParaphrasesProps = {
  onAddPhrase: (phrase: string) => void
  utterances: string[]
}

/**
 * Responsible for getting, displaying and setting paraphrases.
 */
function Paraphrases({ onAddPhrase, utterances }: ParaphrasesProps): React.ReactElement {
  const { bot } = useBotContext()
  const { classes } = useParaphraseListStyles()
  const { setError } = useErrorContext()

  const [paraphraseStatus, setParaphraseStatus] = useState<'generating' | 'success' | 'error' | undefined>()
  const [paraphrases, setParaphrases] = useState<string[]>([])

  /**
   * Generates paraphrases via API.
   * Currently uses first utterance as basis.
   */
  async function generateParaphrases(): Promise<void> {
    if (utterances.length < 1 || !bot) return
    setParaphraseStatus('generating')
    const generatedParaphrases = await paraphrase(bot.id, utterances.slice(0, 10))

    if (generatedParaphrases === null) {
      setError(
        'Knowledge.specific.createAnswer.paraphraseError',
        'Vorschläge konnten nicht generiert werden. Bitte versuchen Sie es in wenigen Momenten erneut.',
        'Erneut versuchen',
        generateParaphrases,
      )
      setParaphraseStatus('error')
    } else {
      // could generate paraphrases, prepend them to existing paraphrases and remove duplicates
      const newParaphrases = Array.from(new Set([...paraphrases, ...generatedParaphrases]))
      setParaphrases(newParaphrases)
      setParaphraseStatus('success')
    }
  }

  /**
   * Removes paraphrase at index from paraphrses list
   */
  function onRemovePhrase(index: number): void {
    const newParaphrases = [...paraphrases]
    newParaphrases.splice(index, 1)
    setParaphrases(newParaphrases)
  }

  /**
   * Handles add of paraphrase.
   * @param index
   */
  function onAddParaphrase(index: number): void {
    onAddPhrase(paraphrases[index])
    onRemovePhrase(index)
  }

  return (
    <div className={classes.container}>
      {/* Generate Button */}
      <Button
        onClick={generateParaphrases}
        icon={<FlashOn />}
        loading={paraphraseStatus === 'generating'}
        disabled={paraphraseStatus === 'generating' || utterances.length === 0}
        size='small'
      >
        Vorschläge generieren
      </Button>
      {/* List */}
      <ErrorComponent errorCode='Knowledge.specific.createAnswer.paraphraseError'>
        {(paraphraseStatus === 'success' || paraphraseStatus === 'generating') && paraphrases.length > 0 && (
          <div>
            <Table
              title='Vorschläge'
              rows={paraphrases.map((phrase, index) => [
                <Typography key={`paraphrases-phrase-${index}`}>{phrase}</Typography>,
                <ParaphraseTableRowActions
                  key={`paraphrases-actions-${index}`}
                  index={index}
                  onAddPhrase={onAddParaphrase}
                  onRemovePhrase={onRemovePhrase}
                />,
              ])}
              cellAlignPattern={['left', 'right']}
              padding='none'
            />
          </div>
        )}
      </ErrorComponent>
    </div>
  )
}

type StepUtterancesProps = {
  utterances?: string[]
  onSetUtterances: (utterances: string[]) => void
  descriptionText: string
}

export default function StepUtterances({
  utterances: propsUtterances = [],
  onSetUtterances,
  descriptionText,
}: StepUtterancesProps): React.ReactElement {
  const { classes } = useStyles()
  const { answersArrayPrimaryLang } = useAnswers()

  const [utterances, setUtterances] = useState<string[]>(propsUtterances) // holds all utterances
  const [tmpUtterance, setTmpUtterance] = useState<string>() // holds tmp utterance from text input
  const [tmpUtteranceError, setTmpUtteranceError] = useState<string>()

  /**
   * Handles change of existing utterance
   * @param event
   */
  function onUtteranceChange(event: React.ChangeEvent<HTMLInputElement>, index: number): void {
    const newUtterance = event.target.value
    if (newUtterance.trim()) {
      utterances[index] = newUtterance
      const newUtterances = [...utterances]
      setUtterances(newUtterances)
      onSetUtterances(newUtterances)
    }
  }

  /**
   * Handles removal of utterance
   * @param index
   */
  function onUtteranceDelete(index: number): void {
    const newUtterances = [...utterances]
    newUtterances.splice(index, 1)
    setUtterances(newUtterances)
    onSetUtterances(newUtterances)
  }

  /**
   * Adds utterance to state and to parent
   * @param utterance
   */
  function onAddUtterance(utterance: string): void {
    const newUtterances = [...utterances, utterance.trim()]
    setUtterances(newUtterances)
    onSetUtterances(newUtterances)
  }

  /**
   * Handles form submit of textfield for utterance input.
   * Sets tmpUtterance to utterances and resets it.
   * @param event
   */
  function onCreateUtteranceTextfieldSubmit(event: React.FormEvent): void {
    event.preventDefault()
    if (tmpUtterance && tmpUtterance.trim()) {
      const trimmedTmpUtterance = tmpUtterance.trim()

      // check case insensitive if the entered utterance already exists
      const utteranceExistsInAnswer =
        utterances.filter((utterance) => utterance.trim().toLowerCase() === trimmedTmpUtterance.toLowerCase()).length >
        0

      if (utteranceExistsInAnswer) {
        setTmpUtteranceError('Die Frage existiert bereits')
        return
      }

      // check if utterance exists in any answer
      let doesUtteranceExistInOtherAnswer = false

      for (const answer of answersArrayPrimaryLang) {
        const isExisting =
          (answer.labels ? answer.labels.filter((label) => label.trim().toLowerCase() === trimmedTmpUtterance) : [])
            .length > 0
        if (isExisting) {
          doesUtteranceExistInOtherAnswer = true
          setTmpUtteranceError(`Die Frage existiert bereits in der Antwort "${answer.title}".`)
          return
        }
      }

      if (!doesUtteranceExistInOtherAnswer) {
        onAddUtterance(trimmedTmpUtterance)
        setTmpUtterance(undefined)
      }
    }
  }

  /**
   * Handles user typing in create utterance textfield.
   * @param event
   */
  function onCreateUtteranceTextfieldChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setTmpUtteranceError(undefined)
    setTmpUtterance(event.target.value)
  }

  return (
    <div className={classes.container}>
      <Typography>{descriptionText}</Typography>
      <div className={classes.overflowContainer}>
        {/* render existing utterances */}
        {utterances.length > 0 && (
          <div className={classes.utteranceListContainer}>
            <Typography>Fragen:</Typography>
            {utterances.map((utterance, index) => {
              return (
                <div key={`utterance-${index}`} className={classes.utteranceContainer}>
                  <Textfield
                    value={utterance}
                    error={utterance === ''}
                    className={classes.textfield}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => onUtteranceChange(event, index)}
                  />
                  <div className={classes.iconButton}>
                    <Tooltip
                      className={classes.removeUtteranceTooltip}
                      title='Frage entfernen'
                      placement='top'
                      disableInteractive
                    >
                      <IconButton aria-label='Delete' onClick={(): void => onUtteranceDelete(index)}>
                        <Delete classes={{ root: classes.icon }} />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              )
            })}
          </div>
        )}
        {/* Render paraphrases (generate button + list) */}
        <div className={classes.paraphrasesContainer}>
          <Paraphrases onAddPhrase={onAddUtterance} utterances={utterances} />
        </div>
      </div>
      {/* Textfield for manual addition of paraphrase */}
      <div className={classes.textfieldContainer}>
        <form onSubmit={onCreateUtteranceTextfieldSubmit}>
          <div className={classes.flexContainer}>
            <Textfield
              onChange={onCreateUtteranceTextfieldChange}
              value={tmpUtterance}
              placeholder='Frage'
              InputProps={{ autoComplete: 'off' }}
              label={tmpUtteranceError || 'Frage hinzufügen'}
              className={classes.textfield}
              error={!!tmpUtteranceError}
            />
            <Tooltip className={classes.tooltip} title='Frage hinzufügen' placement='top' disableInteractive>
              <div>
                <IconButton
                  type='submit'
                  aria-label='Add'
                  className={classes.iconButton}
                  disabled={!!tmpUtteranceError || !tmpUtterance || tmpUtterance.trim() === ''}
                >
                  <Add className={classes.icon} />
                </IconButton>
              </div>
            </Tooltip>
          </div>
        </form>
      </div>
    </div>
  )
}
