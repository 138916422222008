import { Template, Templates } from '../../@types/Templates/types'
import { TranslationFile } from '../../@types/Translations/types'
import { Chart } from '../../@types/Flowchart/types'
import React, { createContext, useContext, useReducer, useCallback, useEffect } from 'react'
import { saveProcessTemplate as saveProcessTemplateApi } from '../../api/StudioBackend'
import { useBotContext } from './bot-context'

// ----- TYPE DEFINITIONS -----

// Maps Actions to Payloads - Standard does not need customization
type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key
      }
    : {
        type: Key
        payload: M[Key]
      }
}

// Possible Actions
enum Types {
  SetSelectedDialogId = 'SET_DIALOG_ID',
  SetSearchNodeSelectionResult = 'SEARCH_NODE_SELECTION',
  SetProcessTemplates = 'SET_PROCESS_TEMPLATES',
  SetSingleProcessTemplate = 'SET_SINGLE_PROCESS_TEMPLATE',
  SetProcessTemplateLoadingState = 'SET_PROCESS_TEMPLATE_LOADING_STATE',
}

type SearchNodeSelectionResult = {
  nodeId: string
  hasDialogChanged: boolean
  newDialogId: string
}

type ProcessTemplates = Templates
type ProcessTemplatesLoadingState = 'loading' | 'saving' | 'error' | 'success' | undefined

// Payloads for each action
type StudioContextPayload = {
  [Types.SetSelectedDialogId]: {
    dialogId: string | null
  }
  [Types.SetSearchNodeSelectionResult]: {
    result: SearchNodeSelectionResult | null
  }
  [Types.SetProcessTemplates]: {
    processTemplates: ProcessTemplates
  }
  [Types.SetSingleProcessTemplate]: {
    template: Template
  }
  [Types.SetProcessTemplateLoadingState]: {
    loadingState: ProcessTemplatesLoadingState
  }
}

type Action = ActionMap<StudioContextPayload>[keyof ActionMap<StudioContextPayload>]
type State = {
  dialogId: string | null
  searchNodeSelection: SearchNodeSelectionResult | null
  processTemplates: ProcessTemplates
  processTemplateLoadingState: ProcessTemplatesLoadingState
}

type Dispatch = (action: Action) => void
type StudioProviderProps = { children: React.ReactNode }

/**
 * ----- CONTEXT -----
 * Flowdesigner Context is a context used for storing things like selected dialog id or selected node id from nodeserach.
 *
 *
 * To access this context:
 * import useFlowdesignerContext from '<path>/hooks/contexts/flowdesigner-context.tsx'
 *
 * // in a function
 * const {, setLanguage} = useStudioContext()
 *
 * setLangauge('en')
 */

const FlowdesignerContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined)

function flowdesignerReducer(state: State, action: Action): State {
  switch (action.type) {
    case Types.SetSearchNodeSelectionResult: {
      // make sure to spread that state just in case!
      return { ...state, searchNodeSelection: action.payload.result }
    }
    case Types.SetSelectedDialogId: {
      return { ...state, dialogId: action.payload.dialogId }
    }
    case Types.SetProcessTemplates: {
      return { ...state, processTemplates: action.payload.processTemplates }
    }
    case Types.SetSingleProcessTemplate: {
      const processTemplates = { ...state.processTemplates }
      processTemplates[action.payload.template.templateId] = action.payload.template
      return { ...state, processTemplates }
    }
    case Types.SetProcessTemplateLoadingState: {
      return { ...state, processTemplateLoadingState: action.payload.loadingState }
    }
    default: {
      // helps us avoid typos!
      throw new Error(`[StudioContext-Reducer] Unhandled action type: ${(action as any).type}`)
    }
  }
}

/**
 * Context provider for providing StudioContext
 * @returns
 */
function FlowdesignerContextProvider({ children }: StudioProviderProps): JSX.Element {
  const [state, dispatch] = useReducer(flowdesignerReducer, {
    searchNodeSelection: null,
    dialogId: null,
    processTemplates: {},
    processTemplateLoadingState: undefined,
  })
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  // const value = useMemo(() => [state, dispatch], [state])
  const value = { state, dispatch }
  return <FlowdesignerContext.Provider value={value}>{children}</FlowdesignerContext.Provider>
}

/**
 * Hook for accessing and manipulating the StudioContext state
 * @returns
 */
function useFlowdesignerContext(): {
  selectedDialogId: string | null
  setSelectedDialogId: (dialogId: string) => void
  resetSelectedDialogId: () => void
  searchNodeSelection: SearchNodeSelectionResult | null
  setSearchNodeSelection: (nodeId: string, hasDialogChanged: boolean, newDialogId: string) => void
  resetSearchNodeSelection: () => void
  processTemplateLoadingState: ProcessTemplatesLoadingState
  processTemplates: ProcessTemplates // all process templates
  setProcessTemplates: (processTemplates: ProcessTemplates) => void // sets all process templates. Replaces existing ones.
  setSingleProcessTemplate: (templateId: string, template: Template) => void // adds or updates single process template
  saveProcessTemplate: (
    dialogId: string,
    templateName: string,
    templateDescription?: string,
    templateId?: string,
    chart?: Chart,
    translations?: TranslationFile,
  ) => void // saves a template via the Studio API. If template id is given, updates existing template else creates a new one.
  canIEditFlowdesigner: boolean
} {
  const { bot, permissionsFlowdesigner } = useBotContext()
  const context = useContext(FlowdesignerContext)
  if (context === undefined) {
    throw new Error('[useFlowdesignerContext] useFlowdesignerContext must be used within a FlowdesignerContextProvider')
  }

  // splitting it up, allows us to add additional api calls
  const { state, dispatch } = context

  // ---- API ----
  // To not let anything work on the context state directly and to have an easy to use API

  // State
  const { searchNodeSelection, dialogId, processTemplates, processTemplateLoadingState } = state

  const canIEditFlowdesigner = permissionsFlowdesigner.includes('update')

  // Functions
  const setSelectedDialogId = useCallback(
    (dialogId) => dispatch({ type: Types.SetSelectedDialogId, payload: { dialogId } }),
    [],
  )
  const resetSelectedDialogId = useCallback(
    () => dispatch({ type: Types.SetSelectedDialogId, payload: { dialogId: null } }),
    [],
  )
  const setSearchNodeSelection = useCallback(
    (nodeId, hasDialogChanged, newDialogId) =>
      dispatch({
        type: Types.SetSearchNodeSelectionResult,
        payload: { result: { nodeId, hasDialogChanged, newDialogId } },
      }),
    [],
  )
  const resetSearchNodeSelection = useCallback(
    () => dispatch({ type: Types.SetSearchNodeSelectionResult, payload: { result: null } }),
    [],
  )
  const setProcessTemplates = useCallback(
    (processTemplates) =>
      dispatch({
        type: Types.SetProcessTemplates,
        payload: { processTemplates: processTemplates },
      }),
    [],
  )
  const setSingleProcessTemplate = useCallback(
    (template) => dispatch({ type: Types.SetSingleProcessTemplate, payload: { template } }),
    [],
  )

  const saveProcessTemplate = useCallback(
    /**
     * Saves a process template to the Studio API. Either creates new template (if no template id is given) or updates a existing one.
     * If successful, sets the new / updated template in the processTemplates state of the context.
     * @param dialogId
     * @param templateName
     * @param templateDescription
     * @param templateId
     * @param chart
     * @param translations
     * @returns
     */
    async (dialogId, templateName, templateDescription, templateId, chart, translations) => {
      if (!chart || !translations || !bot) {
        console.error('[saveProcessTemplate] chart, translations and botinfos are required but not present')
        return
      }

      dispatch({ type: Types.SetProcessTemplateLoadingState, payload: { loadingState: 'saving' } })
      const createdTemplate = await saveProcessTemplateApi(
        dialogId,
        templateName,
        templateDescription,
        chart,
        translations,
        templateId, // can be undefined
        translations,
      )
      if (!createdTemplate) {
        // error
        dispatch({ type: Types.SetProcessTemplateLoadingState, payload: { loadingState: 'error' } })
        return
      }

      dispatch({ type: Types.SetSingleProcessTemplate, payload: { template: createdTemplate } })
      dispatch({ type: Types.SetProcessTemplateLoadingState, payload: { loadingState: 'success' } })
    },
    [],
  )

  useEffect(
    function () {
      // reset state for notifications after 3 seconds
      if (processTemplateLoadingState === 'success' || processTemplateLoadingState === 'error') {
        const timeout = setTimeout(() => {
          dispatch({ type: Types.SetProcessTemplateLoadingState, payload: { loadingState: undefined } })
        }, 3000)

        return (): void => clearTimeout(timeout)
      }
    },
    [processTemplateLoadingState],
  )

  // Controll what is returned
  return {
    searchNodeSelection,
    setSearchNodeSelection,
    resetSearchNodeSelection,
    selectedDialogId: dialogId,
    setSelectedDialogId,
    resetSelectedDialogId,
    // process templates
    saveProcessTemplate,
    processTemplateLoadingState,
    processTemplates,
    setProcessTemplates,
    setSingleProcessTemplate,
    canIEditFlowdesigner,
  }
}

export { FlowdesignerContextProvider, useFlowdesignerContext }
