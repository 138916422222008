export enum TemplateType {
  PROCESS = 'process',
  KNOWLEDGE = 'knowledge',
  ASSISTANT = 'assistant',
}

export enum TemplateKind {
  CUSTOMER = 'customer',
  CONVAISE = 'convaise',
}

export type TemplateVersion = {
  versionNr: number // version number (increasing integer)
  timestamp: Date // timestamp of version
  file: string // path to file in blob container
}

export type TemplateVersions = {
  [versionNr: string]: TemplateVersion
}

export type Template = {
  templateId: string // uuid
  templateType: TemplateType // differentiates between process, knowledge and assistant templates
  templateKind: TemplateKind // to differentiate between convaise templates and customer templates
  customerId?: string // null if template is convaise template (kind = convaise)
  name: string // name of the template
  description: string // description of the template
  timestampCreated: Date // creation date
  timestampLastChanged: Date // date of last change
  versions: TemplateVersions
}

export type Templates = {
  [templateId: string]: Template
}
