import React, { useState, useEffect } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Typography } from '@mui/material'

import NodeContent from '../../Nodes/NodeContent'
import { Button } from 'components/Buttons'
import { FLOWDESIGNER_NODE_WIDTH } from 'utils/constants'
import { TranslationFile } from '../../../../../@types/Translations/types'
import { Chart, Node } from '../../../../../@types/Flowchart/types'

const useStyles = makeStyles()((theme) => ({
  container: { marginTop: theme.spacing(3), alignSelf: 'start' },
  content: { display: 'flex', flexDirection: 'column' },
  nodeContainer: { marginRight: 'auto', width: `${FLOWDESIGNER_NODE_WIDTH}px` },
  button: { marginTop: theme.spacing(3), marginLeft: 'auto', marginRight: 'auto' },
  outerNode: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '7px',
    cursor: 'pointer',
  },
}))

type SJumpConfiguredProps = {
  chart: Chart
  translationFile: TranslationFile
  onEdit: () => void
}

export default function SJumpConfigured({ chart, translationFile, onEdit }: SJumpConfiguredProps): React.ReactElement {
  const { classes } = useStyles()
  const [targetNode, setTargetNode] = useState<Node>()

  useEffect(
    function () {
      if (!chart.selected?.id) return
      const selectedNode = chart.nodes[chart.selected.id]
      const targetNodeId = selectedNode.properties.targetNode
      if (targetNodeId) setTargetNode(chart.nodes[targetNodeId])
    },
    [chart],
  )

  return (
    <>
      <Typography>Ausgewählter Block, zu dem beim Erreichen dieses Blocks gesprungen wird:</Typography>
      <div className={classes.container}>
        <div className={classes.content}>
          {targetNode && (
            <div className={classes.nodeContainer}>
              <NodeContent
                chart={chart}
                translationFile={translationFile}
                node={targetNode}
                onResize={(): void => {
                  // do nothing
                }}
                classes={classes}
              />
            </div>
          )}
          <div className={classes.button}>
            <Button icon='pencil-fill' iconType='remix' onClick={onEdit}>
              Bearbeiten
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
