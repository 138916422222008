import { QuestionAnswer } from '@mui/icons-material'
import React, { ReactElement } from 'react'
import EditorSidebarItem from '../editorSidebarItem'
import { FLOWDESIGNER_NODE_COLOR_MODULE } from 'utils/constants'

export default function qnaGetAnswer(): ReactElement {
  return (
    <EditorSidebarItem
      type='module/qna-getAnswer'
      ports={{
        port1: {
          id: 'port1',
          type: 'left',
          position: { x: 0, y: 0 },
          properties: {
            type: 'incoming',
          },
        },
        port2: {
          id: 'port2',
          type: 'right',
          position: { x: 0, y: 0 },
          properties: {
            name: 'Antwort gefunden',
            type: 'outgoing',
          },
        },
        port3: {
          id: 'port3',
          type: 'right',
          position: { x: 0, y: 0 },
          properties: {
            name: 'Trigger gefunden',
            type: 'outgoing',
          },
        },
        port4: {
          id: 'port4',
          type: 'right',
          position: { x: 0, y: 0 },
          properties: {
            name: 'Nichts gefunden',
            type: 'outgoing',
          },
        },
      }}
      properties={{
        typeText: 'QnA Antwort suchen',
        text: 'Nicht konfiguriert',
        xzufi: {},
        dialog: '',
      }}
      Icon={QuestionAnswer}
      color={FLOWDESIGNER_NODE_COLOR_MODULE}
    />
  )
}
