import { createContext } from 'react'
import { createContextualCan } from '@casl/react'
import { AnyAbility } from '@casl/ability/dist/types'
import { ContextType } from './ability'

export const AbilityContext = createContext<ContextType>(undefined)
const consumer: React.Consumer<AnyAbility> = AbilityContext.Consumer as React.Consumer<AnyAbility>
/**
 * Can components
 * possible subjects (the a or an parameter):
 * 'floweditor' | 'bot' | 'smartcards' | 'knowledge' | 'analytics' | 'websitetracking' | 'translations'
 *
 * possible actions (the I parameter):
 * 'read' | 'update' | 'create' | 'delete'
 */
export const Can = createContextualCan(consumer)
