import React, { useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { makeStyles } from 'tss-react/mui'
import { useTheme } from '@mui/material/styles'

import { useBotContext } from 'hooks/contexts/bot-context'
import {
  useCustomNotifications,
  createNotification,
  checkOverlapOfCustomNotification,
  saveNotifications,
} from 'hooks/contexts/customnotifications-context'
import { Dialog } from 'components/Dialogs'
import { Step, StepButton, Stepper, Typography } from '@mui/material'
import { Button } from 'components/Buttons'
import NotificationPreview from 'components/Previews/notificationPreview'
import { CustomNotification, CustomNotifications } from '../../../@types/CustomNotifications/types'
import StepTitle from './StepTitle'
import StepContent from './StepContent'
import StepTurnus from '../SharedComponents/StepTurnus'
import StepSummary from './StepSummary'
import { useLockingContext } from 'hooks/contexts/locking-context'
import { useStudioNotificationContext } from 'hooks/contexts/studio-notification-context'

const useStyles = makeStyles()((theme) => ({
  backButton: {
    margin: `auto ${theme.spacing(1)} auto 0`,
  },
  stepperContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  stepper: {
    width: '70%',
    margin: '0 calc(10% - 56px) 0 10%',
  },
  builderContent: {
    display: 'flex',
    marginTop: theme.spacing(4),
    paddingBottom: theme.spacing(1),
    height: 'calc(100% - 100px)',
    width: '100%',
  },
  container: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
  },
  builderActions: {
    height: '60px',
    marginTop: 'auto',
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'end',
  },
  primaryButton: {
    marginLeft: theme.spacing(2),
    width: '100px',
  },
  builderOptions: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(5),
    alignSelf: 'flex-start',
    height: '100%',
    width: '100%',
    overflowY: 'auto',
  },
  description: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))

enum Stages {
  Title,
  Content,
  Turnus,
  Summary,
}

type Stage = {
  label: string
  stage: Stages
}

function getStageLabels(stage: Stages): Stage {
  switch (stage) {
    case Stages.Title:
      return { stage: Stages.Title, label: 'Titel' }
    case Stages.Content:
      return { stage: Stages.Content, label: 'Inhalt' }
    case Stages.Turnus:
      return { stage: Stages.Turnus, label: 'Turnus' }
    case Stages.Summary:
    default:
      return { stage: Stages.Summary, label: 'Zusammenfassung' }
  }
}

function getStageDescription(stage: Stages): string {
  switch (stage) {
    case Stages.Title:
      return 'Der Name der Notification dient der Verwaltung und Bearbeitung in Studio. Titel und Untertitel gehören zu der Notification und werden den Nutzern angezeigt.'
    case Stages.Content:
      return 'Hier wird der Inhalt der Notification definiert. Sie können die Markdown Syntax nutzen, um den Inhalt zu definieren.'
    case Stages.Turnus:
      return 'Jede Notification bekommt einen Zeitraum mit Start- und Endzeit. Die Notification wird den Nutzern nur in diesem Zeitraum angezeigt. Notifications unterstützen 3 verschiedene Turnusse: Einmalig, täglich oder wöchentlich. Einmalige Notifications werden nur ein einziges mal angezeigt. Tägliche Notifications jeden Tag zwischen Start- und Enddatum in dem konfigurierten Zeitraum. Für wöchentliche Notifications können einzelne Wochentage gewählt werden, an denen die Notification in dem gewählten Zeitraum gezeigt wird.'
    case Stages.Summary:
      return 'Die Notification wird wie folgt angezeigt:'
    default:
      return ''
  }
}

function stageNumberToStage(stage: number): Stages {
  switch (stage) {
    case 0:
      return Stages.Title
    case 1:
      return Stages.Content
    case 2:
      return Stages.Turnus
    case 3:
      return Stages.Summary
    default:
      console.error('[stageNumberToBuilderStage] not expected stage number')
      return Stages.Title
  }
}

type CreateCustomNotificationDialogProps = {
  onClose: () => void
}

export default function CreateCustomNotificationDialog({
  onClose,
}: CreateCustomNotificationDialogProps): React.ReactElement {
  const theme = useTheme()
  const { classes } = useStyles()
  const { bot } = useBotContext()
  const { lockState } = useLockingContext()
  const {
    notifications,
    loadingState,
    dispatch: notificationsDispatch,
    overlappingNotifications,
  } = useCustomNotifications()
  const { setNotification: setSnackbarNotification } = useStudioNotificationContext()

  const [stage, setStage] = useState<number>(0)
  const [completedStages, setCompletedStages] = useState<{ [stage: number]: boolean }>({})
  const [stages, setStages] = useState<Stage[]>([
    getStageLabels(Stages.Title),
    getStageLabels(Stages.Content),
    getStageLabels(Stages.Turnus),
    getStageLabels(Stages.Summary),
  ])

  // notification
  // Stage 0: Title
  const [name, setName] = useState<string>('')
  const [title, setTitle] = useState<string>('')
  const [subtitle, setSubtitle] = useState<string>('')
  // Stage 1: Content
  const [content, setContent] = useState<string>('')
  // Stage 2: Turnus
  const [type, setType] = useState<CustomNotification['type']>()
  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()
  const [times, setTimes] = useState<CustomNotification['times']>([])
  // const [startTime, setStartTimes] = useState<Date[]>([new Date(2000, 0, 1, 7, 30, 0, 0)])
  // const [endTime, setEndTime] = useState<Date[]>([new Date(2000, 0, 1, 12, 0, 0, 0)])
  const [days, setDays] = useState<number[]>() // only if turnus is weekly

  // holds the notification which overlaps with the current (to be created) notification
  const [overlappingNotification, setOverlappingNotification] = useState<CustomNotification>()

  /**
   * Checks if all required properties exist for the notification to be created
   * @param notification
   */
  function checkAndBuildNotification(isActive: boolean): CustomNotification | boolean {
    if (
      !(bot && name && title && content && startDate && endDate && times && type && (type === 'weekly' ? days : true))
    )
      return false

    if (type === 'weekly' && !days) return false

    // else build object and return
    return {
      name,
      title,
      subtitle,
      content,
      type,
      startDate,
      endDate,
      times,
      isActive,
      createdTimestamp: new Date(),
      notificationId: uuid(),
      botId: bot.id,
      series: type === 'weekly' ? { days: days as number[] } : null,
    }
  }

  /**
   * Checks for each stage if it is fully configured and all required inputs are set.
   * @param stage
   * @returns
   */
  function checkCorrectConfiguration(stage: Stages): { complete: boolean; next: boolean } {
    const stageName = stageNumberToStage(stage)
    switch (stageName) {
      case Stages.Title: {
        const isComplete = !!(name && title)
        return { complete: isComplete, next: isComplete }
      }
      case Stages.Content: {
        return { complete: !!content, next: !!content }
      }
      case Stages.Turnus: {
        const isComplete = !!(
          type &&
          startDate &&
          endDate &&
          times &&
          times.length > 0 &&
          (type === 'weekly' ? days && days.length > 0 : true) &&
          startDate <= endDate &&
          times.every((obj) => obj.startTime < obj.endTime)
        )
        return { complete: isComplete, next: isComplete }
      }
      case Stages.Summary: {
        const isComplete = !!checkAndBuildNotification(true)
        return { complete: isComplete, next: isComplete }
      }

      default:
        return { complete: false, next: false }
    }
  }

  /**
   * Checks if next button is disabled. This is the case if stage is not complete
   */
  function isNextButtonDisabled(): boolean {
    const check = checkCorrectConfiguration(stage)
    if (!check.next) return true
    return false
  }

  // Stepper Handling
  function allStepsCompleted(): boolean {
    return Object.keys(completedStages).length === stages.length
  }

  /**
   * Handles back button click. Goes to previous step.
   */
  function onPreviousStep(): void {
    if (stage > 0) setStage(stage - 1)
  }

  /**
   * Handles button click to go to next step.
   */
  function onNextStep(): void {
    setStage(stage + 1)
  }

  function isLastStep(): boolean {
    return stage === stages.length - 1
  }

  async function doesOverlap(): Promise<void> {
    const notification = checkAndBuildNotification(true)
    if (!notifications || !bot || !notification) return

    const overlappingNotificationIds = await checkOverlapOfCustomNotification(
      notificationsDispatch,
      bot.id,
      notification as CustomNotification,
    )

    const overlappingNotification =
      overlappingNotificationIds && overlappingNotificationIds.length > 0
        ? notifications[overlappingNotificationIds[0]]
        : undefined

    setOverlappingNotification(overlappingNotification)
  }

  /**
   * Runs if stage is done and filled out correctly without errors - also moves to the next step
   */
  function handleComplete(): void {
    const newCompleted = { ...completedStages }
    newCompleted[stage] = true
    setCompletedStages(newCompleted)
    // handleNextStage()
  }

  function handleNextStage(): void {
    // check if stage is complete
    if (checkCorrectConfiguration(stage).complete) {
      handleComplete()
    }
    const newActiveStage =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          stages.findIndex((stage, i) => !(i in completedStages))
        : stage + 1
    setStage(newActiveStage)
  }

  /**
   * Handles final create click.
   * @returns
   */
  async function onCreate(create_active = true, closeOnComplete = true): Promise<void> {
    const notification = checkAndBuildNotification(create_active)
    if (!notification || !bot) return

    const { status } = await createNotification(
      notificationsDispatch,
      bot.id,
      setSnackbarNotification,
      notification as CustomNotification,
    )
    if (status === true && closeOnComplete) onClose()
  }

  /**
   * Callback function for when the user creates an overlapping notification.
   * Depending on the mode different actions are performed:
   * - create_active: deactivate the existing, overlapping active notification and activates the new one
   * - create_inactive: does not touch the existing notification and creates the new one inactive.
   *
   * @param mode
   */
  async function onCreateOverlapping(mode: 'create_active' | 'create_inactive'): Promise<void> {
    if (mode === 'create_inactive') {
      // normal create, but with deactivated notification, close on completion
      await onCreate(false, true)
    } else if (mode === 'create_active') {
      // we first create the new notification deactivated
      const newNotification = checkAndBuildNotification(true) as CustomNotification
      if (!newNotification || !bot) return

      // save notifications
      const notifications: CustomNotifications = {
        [newNotification.notificationId]: newNotification,
      }

      // deactivate other notification
      if (overlappingNotification) {
        overlappingNotification.isActive = false
        notifications[overlappingNotification.notificationId] = overlappingNotification
      }

      const { status } = await saveNotifications(notificationsDispatch, bot.id, notifications, setSnackbarNotification)
      if (status === true) onClose()
    }
  }

  // TODO: Might be good to do some validation
  const handleClickedStage = (stage: number) => (): void => {
    setStage(stage)
  }

  useEffect(
    function () {
      if (isLastStep()) {
        // check if notification overlaps with any other notification
        doesOverlap()
      }
    },
    [stage],
  )

  // ----- EDITOR COMPONENTS -----
  function getStageEditor(stage: Stages): React.ReactElement {
    switch (stage) {
      case Stages.Title:
        return (
          <StepTitle
            name={name}
            title={title}
            subtitle={subtitle}
            setNameCallback={setName}
            setTitleCallback={setTitle}
            setSubtitleCallback={setSubtitle}
            onNextStage={handleNextStage}
          />
        )
      case Stages.Content:
        return <StepContent content={content} setContentCallback={setContent} />
      case Stages.Turnus:
        return (
          <StepTurnus
            turnus={type}
            startDate={startDate}
            times={times}
            endDate={endDate}
            days={days}
            setStartDateCallback={(date: Date): void => {
              setStartDate(date)
              if (type === 'once') {
                setEndDate(date)
              }
            }}
            setTimesCallback={setTimes}
            // setStartTimeCallback={setStartTimes}
            setEndDateCallback={setEndDate}
            // setEndTimeCallback={setEndTime}
            setDaysCallback={setDays}
            setTurnusCallback={setType}
          />
        )
      case Stages.Summary:
        return (
          <StepSummary
            notification={checkAndBuildNotification(true)}
            overlappingNotification={overlappingNotification}
            onCreateOverlapping={onCreateOverlapping}
          />
        )
      default:
        return <></>
    }
  }

  const description = getStageDescription(stageNumberToStage(stage))
  const editor = getStageEditor(stageNumberToStage(stage))

  return (
    <Dialog
      id='create-notification-dialog'
      size='large'
      open
      closable
      disableBackdropClick
      onClose={onClose}
      title={'Notification erstellen'}
    >
      <div className={classes.container}>
        <div className={classes.stepperContainer}>
          {/* {stage === -1 && <Typography>Karte zurücksetzen und neu erstellen.</Typography>} */}
          {/* Stepper */}
          {/* Only show stepper if the overview is not open */}
          {stage !== -1 && (
            <Stepper
              nonLinear={true} // true means jump around, false means do not jump aroung
              // nonLinear if card was given, else be able to jump around
              activeStep={stage}
              className={classes.stepper}
            >
              {stages.map((stage, index) => (
                <Step key={stage.label} completed={completedStages[index]}>
                  <StepButton color='primary' onClick={handleClickedStage(index)}>
                    {stage.label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
          )}
        </div>
        {/* Step */}
        {/* Card Preview & Step Config*/}
        <div className={classes.builderContent}>
          <div style={{ minWidth: '380px', maxWidth: '380px' }}>
            <NotificationPreview title={title} subtitle={subtitle} content={content} />
          </div>
          <div className={classes.builderOptions}>
            <Typography variant='h4'>{stages[stage].label}</Typography>
            <Typography className={classes.description} mb={theme.spacing(2)}>
              {description}
            </Typography>
            {editor}
          </div>
        </div>
        <div className={classes.builderActions}>
          {stageNumberToStage(stage) !== Stages.Title && (
            <Button size='small' variant='tertiary' onClick={onPreviousStep} disabled={stage === 0}>
              {/* TODO: logic to disable buttons based on set properties and missing stuff etc. */}
              Zurück
            </Button>
          )}

          <Button
            onClick={(): void => {
              if (isLastStep()) {
                onCreate()
              } else {
                handleNextStage()
              }
            }}
            size='small'
            disabled={
              isNextButtonDisabled() ||
              loadingState === 'saving' ||
              lockState !== 'canEdit' ||
              loadingState === 'checkingOverlap' ||
              (isLastStep() && !!overlappingNotification)
            }
            type='success'
            loading={loadingState === 'saving'}
            className={classes.primaryButton}
          >
            {isLastStep() ? 'Erstellen' : 'Weiter'}
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
