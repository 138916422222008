import axios, { AxiosResponse } from 'axios'
// types
import { Signature, SyncedSignature } from '../@types/Ingest/types'
import { Chart } from '../@types/Flowchart/types'
import { BotCreationInfos, BotEnvironment, BotInfos, VersionType } from '../@types/BotInformation/types'
import { IngestPDFResult } from '../classes/Ingest'
import { Answer, AnswerWithoutIntent, TriggerAnswerWithoutIntent } from '../classes/Knowledge'
import {
  AnswerTemplateData,
  EndpointUtteranceAnswered,
  EndpointUtteranceUnanswered,
  KnowledgeDB,
  KnowledgeType,
  ModelStatus,
  ModelTrainingStatus,
  SimilarIntents,
  PredictAnswerResult,
  GetExportKnowledgeDbResponse,
  Answers,
  GetAnswersResult,
  LLMNLUEndpointUtterance,
} from '../@types/Knowledge/types'
import {
  AddLanguageResponse,
  DeleteLanguageResponse,
  TranslateBatchResult,
  TranslateResult,
  TranslationFile,
} from '../@types/Translations/types'
import { GetAnalyticsRequest, GetAnalyticsResponse } from '../@types/Analytics/types'
import { LockScope, RequestLockDenied, RequestLockSuccess } from '../@types/Locking/types'
// Auth
import { getAccount, getSilentToken } from 'components/AuthProvider/PublicClientApp'
// Config
import studioBackendConfig from '../config/studioBackend.config'
import { pdfFileToBase64String } from '../utils/utils'
import { mapLanguageCodeToAutomaticallyTranslateText } from 'utils/languageUtils'
import {
  CustomNotification,
  CustomNotifications,
  OverlappingCustomNotifications,
} from '../@types/CustomNotifications/types'
import { Dictionary, DictionaryEntry } from '../@types/Knowledge/Dictionaries/types'
import { Template, TemplateKind, Templates, TemplateType } from '../@types/Templates/types'
import { ModuleAlephAlphaRssFeedMetadata } from '../@types/Knowledge/ModuleAlephAlpha/types'
import { AuthorizationEntry } from '../@types/Authorization/types'
import {
  RAGDocumentForInsertion,
  RAGDocument,
  RAGDocumentForUpdate,
  RAGCurrentlyIngestingDocument,
} from '../@types/Knowledge/RAG/types'
import { BotSettings } from '../@types/Settings/types'
import { set } from 'lodash'
import { MODULE_ID_CONVAISE_LLM_NLU, MODULE_ID_CONVAISE_RAG } from 'utils/constants'
import { prepareInsertionRequest } from 'utils/ragUtils'

// ------ BOT INFORMATION -----

/**
 * Creates a new bot.
 * Returns botinfos of new bot.
 * @param infos
 */
export async function createBot(infos: BotCreationInfos): Promise<BotInfos | null> {
  try {
    const account = getAccount()
    const tokenClaims = account?.idTokenClaims ?? {}
    const customerId = tokenClaims['extension_CustomerID']
    if (account) {
      const accessToken = await getSilentToken(account)
      return axios({
        method: 'post',
        url: `${studioBackendConfig.url}/createBot`,
        data: {
          ...infos,
          customerId,
        },
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
      }).then((response: AxiosResponse<{ botInfos: BotInfos }>) => {
        return response.data.botInfos
      })
    }
    return null
  } catch (err) {
    console.error('[StudioBackend] createBot API call failed', err)
    return null
  }
}

/**
 * Deletes a bot.
 * @param infos
 */
export async function deleteBot(infos: BotInfos): Promise<void | null> {
  try {
    const account = getAccount()
    if (account) {
      const accessToken = await getSilentToken(account)
      return axios({
        method: 'delete',
        url: `${studioBackendConfig.url}/deleteBot`,
        data: {
          botId: infos.id,
        },
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
      }).then((response: AxiosResponse<void>) => {
        return
      })
    }
    return null
  } catch (err) {
    console.error('[StudioBackend] createBot API call failed', err)
    return null
  }
}

/**
 * Returns the botInfos for a specified botId
 *
 * TODO: different returns for different cases
 * TODO: redo mechanic
 *
 * @param botId Id of the requested bot
 * @returns Botinformation or null if none could get requested
 */
export async function getBotInformation(
  botId: string,
): Promise<{ botInfos: BotInfos; granularPermissions: AuthorizationEntry[] | null } | null> {
  try {
    if (typeof botId !== 'string' || botId.length === 0) {
      return null
    }
    const account = getAccount()
    if (account) {
      const accessToken = await getSilentToken(account)
      if (accessToken) {
        return axios({
          method: 'post',
          timeout: 10000,
          url: `${studioBackendConfig.url}/getBotInformation`,
          data: {
            id: botId,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((response: AxiosResponse<{ botInfos: BotInfos; granularPermissions: AuthorizationEntry[] | null }>) => {
            return response.data
          })
          .catch((err) => {
            console.error(`[getBotInformation] ${err}`)
            return null
          })
      }
    }
    return null
  } catch (err) {
    console.error(`[getBotInformation] ${err}`)
    return null
  }
}

/**
 * Returns the botInfos for a customer
 *
 * TODO: different returns for different cases
 * TODO: redo mechanic
 *
 * @param botId Id of the requested bot
 * @returns Botinformation or null if none could get requested
 */
export async function getBots(
  isRetry = false,
): Promise<{ botInfos: BotInfos; granularPermissions: AuthorizationEntry[] | null }[] | null> {
  try {
    const account = getAccount()
    if (account && account?.idTokenClaims?.['extension_CustomerID']) {
      const accessToken = await getSilentToken(account)
      if (accessToken) {
        const customerIds = [
          account.idTokenClaims['extension_CustomerID'],
          ...JSON.parse(account.idTokenClaims['extension_CustomerID_Inherited'] ?? '[]'),
        ]

        return axios({
          method: 'get',
          timeout: 5000,
          url: `${studioBackendConfig.url}/getBots`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            customerIds: JSON.stringify(customerIds),
          },
        })
          .then(
            (response: AxiosResponse<{ botInfos: BotInfos; granularPermissions: AuthorizationEntry[] | null }[]>) => {
              return response.data
            },
          )
          .catch((err) => {
            console.error(`[getBots] ${err}`)
            return null
          })
      }
    }
    return null
  } catch (err) {
    if (!isRetry) return getBots(true)
    else {
      console.error(`[getBots] ${err}`)
      return null
    }
  }
}

export async function getPDFSignature(file: File): Promise<Signature | null> {
  try {
    const account = getAccount()
    if (account) {
      const accessToken = await getSilentToken(account)
      // create filereader
      const reader = new FileReader()
      // read pdf as ArrayBuffer
      reader.readAsArrayBuffer(file)

      return new Promise<Signature | null>((resolve) => {
        // function that triggers as soon as the file is read
        reader.onload = async function (): Promise<void> {
          if (reader.result) {
            // API Call
            const uint8Array = new Uint8Array(reader.result as ArrayBufferLike)
            const response: Signature | null = await axios({
              method: 'POST',
              // eslint-disable-next-line no-undef
              url: `${studioBackendConfig.url}/getPDFSignature`,
              headers: {
                'Content-Type': 'application/octet-stream',
                Authorization: `Bearer ${accessToken}`,
              },
              data: uint8Array,
            })
              .then((response) => {
                if (response.status === 200 && response.data) {
                  if (response.data.signature) {
                    return { fields: response.data.signature } as Signature
                  }
                }
                return null
              })
              .catch((error: Error) => {
                console.error('[StudioBackend] Getting pdf signature failed:', error)
                return null
              })
            if (response !== null) {
              resolve(response)
            } else {
              resolve(null)
            }
          } else {
            resolve(null)
          }
        }
      })
    }
    return null
  } catch (err) {
    console.error(`[StudioBackend][getPDFSignature] ${err}`)
    return null
  }
}

/**
 * Fetches flowchart from API.
 * @param botId
 */
export async function getFlow(botId: string, isRetry = false): Promise<{ chart: Chart; botId: string } | null> {
  try {
    const account = getAccount()
    if (account) {
      const accessToken = await getSilentToken(account)
      return axios({
        method: 'GET',
        url: `${studioBackendConfig.url}/getFlow`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        params: { botId },
      })
        .then((response: AxiosResponse<{ chart: Chart; botId: string }>) => {
          return response.data
        })
        .catch((error) => {
          console.error('[StudioBackend] Get Flow failed.', error)
          throw error
        })
    }
    return null
  } catch (err) {
    if (!isRetry) return getFlow(botId, true)
    else {
      console.error(`[StudioBackend][getFlow] ${err}`)
      return null
    }
  }
}

/**
 * Saves flow chart.
 * @param backendUrl
 * @param jwtToken
 * @param botId
 * @param flowchart
 */
export async function saveFlow(
  botId: string,
  flowchart: Chart,
  versionType: VersionType = 'major',
  translations?: TranslationFile,
): Promise<AxiosResponse<{ botInfos: BotInfos; chart: Chart }> | null> {
  try {
    const account = getAccount()
    if (account) {
      const accessToken = await getSilentToken(account)
      return axios({
        method: 'POST',
        url: `${studioBackendConfig.url}/saveFlowchart`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          botId,
          chart: flowchart,
          versionType,
          translationFile: translations,
        },
      })
        .then((response: AxiosResponse<{ botInfos: BotInfos; chart: Chart }>) => {
          // return response.data.result.botInfos
          return response
        })
        .catch((error) => {
          console.error('[StudioBackend] Save Flow failed.', error)
          return null
        })
    }
    return null
  } catch (err) {
    console.error(`[StudioBackend][saveFlow] ${err}`)
    return null
  }
}

/**
 * Triggeres re-init of bot. Causes reload of either chart or translationfile or both.
 * @param botId
 * @param publishLanguagesAndTranslations
 * @param publishChart
 */
export async function reloadBot(
  botId: string,
  deployEnvironment: BotEnvironment,
  publishLanguagesAndTranslations = true,
  publishChart = true,
): Promise<{ botInfos: BotInfos } | null> {
  try {
    const account = getAccount()
    if (account) {
      const accessToken = await getSilentToken(account)
      return axios({
        method: 'POST',
        url: `${studioBackendConfig.url}/reloadBot`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          botId,
          deployEnvironment,
          publishChart,
          publishLanguagesAndTranslations,
        },
      })
        .then((response: AxiosResponse<{ botInfos: BotInfos }>) => {
          return response.data
        })
        .catch((error) => {
          console.error('[StudioBackend] Reload bot failed', error)
          return null
        })
    }
    return null
  } catch (err) {
    console.error(`[StudioBackend][reloadBot] ${err}`)
    return null
  }
}

/**
 * Sends upgrade translation request to the studio backend.
 * Target version is options. If not provided, will upgrade to latest version.
 * @param translations
 * @param targetVersion
 */
export async function upgradeTranslations(
  translations: TranslationFile,
  targetVersion?: string,
): Promise<{ translations: TranslationFile; version: string } | null> {
  try {
    const account = getAccount()
    if (account) {
      const accessToken = await getSilentToken(account)
      return axios({
        method: 'POST',
        url: `${studioBackendConfig.url}/upgradeTranslations`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          translations,
          targetVersion,
        },
      })
        .then((response: AxiosResponse<{ translations: TranslationFile; version: string }>) => {
          return response.data
        })
        .catch((error) => {
          console.error('[StudioBackend] Upgrade translations failed.', error)
          return null
        })
    }
    return null
  } catch (err) {
    console.error(`[StudioBackend][upgradeTranslations] ${err}`)
    return null
  }
}

/**
 * Sends upgrade chart request to the studio backend.
 * Target version is optional. If not provided, will upgrade to latest version.
 * @param chart
 * @param targetVersion
 * @returns
 */
export async function upgradeChart(
  chart: Chart,
  translations: TranslationFile,
  targetVersion?: string,
): Promise<{ chart: Chart; version: string } | null> {
  try {
    const account = getAccount()
    if (account) {
      const accessToken = await getSilentToken(account)
      return axios({
        method: 'POST',
        url: `${studioBackendConfig.url}/upgradeFlowchart`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          chart,
          translations,
          targetVersion,
        },
      })
        .then((response: AxiosResponse<{ chart: Chart; version: string }>) => {
          return response.data
        })
        .catch((error) => {
          console.error('[StudioBackend] Upgrade chart failed.', error)
          return null
        })
    }
    return null
  } catch (err) {
    console.error(`[StudioBackend][upgradeChart] ${err}`)
    return null
  }
}

export async function getTranslations(botId: string, isRetry = false): Promise<TranslationFile | null> {
  try {
    const account = getAccount()
    if (account) {
      const accessToken = await getSilentToken(account)
      return axios({
        method: 'GET',
        url: `${studioBackendConfig.url}/getTranslationsStudio`,
        timeout: 20000,
        params: {
          botId,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response: AxiosResponse<TranslationFile>) => {
          return response.data
        })
        .catch((error: Error) => {
          console.error('[StudioBackend] Get Translations failed.', error)
          throw error
        })
    }
    return null
  } catch (err) {
    if (!isRetry) return getTranslations(botId, true)
    else {
      console.error(`[StudioBackend][getTranslations] ${err}`)
      return null
    }
  }
}

export async function saveTranslationFile(
  botId: string,
  translations: TranslationFile,
  versionType: VersionType = 'major',
  chart?: Chart,
): Promise<{ translations: TranslationFile; botInfos: BotInfos } | null> {
  try {
    const account = getAccount()
    if (account) {
      const accessToken = await getSilentToken(account)
      return axios({
        method: 'POST',
        url: `${studioBackendConfig.url}/setTranslations`,
        data: {
          id: botId,
          translationFile: translations,
          versionType,
          chart,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response: AxiosResponse<{ translations: TranslationFile; botInfos: BotInfos }>) => {
          return response.data
        })
        .catch((error: Error) => {
          console.error('[StudioBackend] Set Translations failed.', error)
          return null
        })
    }
    return null
  } catch (err) {
    console.error(`[StudioBackend][setTranslations] ${err}`)
    return null
  }
}

/**
 * Adds a new language to the bot.
 * Adds language for botInfos, knowledgeDB, translationFile.
 * Returns updated Translation
 * @param botId
 * @param languageToAdd language code of language that should be added
 */
export async function addLanguage(botId: string, languageToAdd: string): Promise<AddLanguageResponse | null> {
  try {
    const account = getAccount()
    if (account) {
      const accessToken = await getSilentToken(account)
      return axios({
        method: 'post',
        url: `${studioBackendConfig.url}/addLanguage`,
        data: {
          botId: botId,
          newLanguage: languageToAdd,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response: AxiosResponse<AddLanguageResponse>) => {
          return response.data
        })
        .catch((error: Error) => {
          console.error('[StudioBackend] addLanguage failed.', error)
          return null
        })
    }
    return null
  } catch (err) {
    console.error('[StudioBackend] addLanguage failed.', err)
    return null
  }
}

/**
 * Deletes a language from the translation file.
 * Returns updated translation file and updated botInfos after language has been deleted.
 * @param botId
 * @param languageToDelete language code of language that should get deleted
 */
export async function deleteLanguage(botId: string, languageToDelete: string): Promise<DeleteLanguageResponse | null> {
  try {
    const account = getAccount()
    if (account) {
      const accessToken = await getSilentToken(account)
      return axios({
        method: 'delete',
        url: `${studioBackendConfig.url}/deleteLanguage`,
        data: {
          botId: botId,
          languageCode: languageToDelete,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response: AxiosResponse<DeleteLanguageResponse>) => {
          return response.data
        })
        .catch((error: Error) => {
          console.error('[StudioBackend] deleteLanguage failed.', error)
          return null
        })
    }
    return null
  } catch (err) {
    console.error('[StudioBackend] deleteLanguage failed.', err)
    return null
  }
}

/**
 * Activates a language for the bot.
 * Activates a language for an assistant by setting it in the publishedLanguages property of the translation file and stores the translation file as new major version. This makes the language available in the assistant, once the translations are published.
 * Returns updated BotInfos
 * @param botId
 * @param languageCode language code of language that should be activated
 */
export async function activateLanguage(botId: string, languageCode: string): Promise<BotInfos | null> {
  try {
    const account = getAccount()
    if (account) {
      const accessToken = await getSilentToken(account)
      return axios({
        method: 'post',
        url: `${studioBackendConfig.url}/activateLanguage`,
        data: {
          botId,
          languageCode,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response: AxiosResponse<{ botInfos: BotInfos }>) => {
          return response.data.botInfos
        })
        .catch((error: Error) => {
          console.error('[StudioBackend] activateLanguage failed.', error)
          return null
        })
    }
    return null
  } catch (err) {
    console.error('[StudioBackend] activateLanguage failed.', err)
    return null
  }
}

/**
 * Deactivates a language for the bot.
 * Deactivates a language for an assistant by removing it from the publishedLanguages property of the translation file and stores the translation file as new major version. This makes the language unavailable in the assistant, once the translations are published.
 * Returns updated BotInfos
 * @param botId
 * @param languageCode language code of language that should be activated
 */
export async function deactivateLanguage(botId: string, languageCode: string): Promise<BotInfos | null> {
  try {
    const account = getAccount()
    if (account) {
      const accessToken = await getSilentToken(account)
      return axios({
        method: 'post',
        url: `${studioBackendConfig.url}/deactivateLanguage`,
        data: {
          botId,
          languageCode,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response: AxiosResponse<{ botInfos: BotInfos }>) => {
          return response.data.botInfos
        })
        .catch((error: Error) => {
          console.error('[StudioBackend] deactivateLanguage failed.', error)
          return null
        })
    }
    return null
  } catch (err) {
    console.error('[StudioBackend] deactivateLanguage failed.', err)
    return null
  }
}

export type ProxyApiRequest = {
  url: string
  method: 'post' | 'put' | 'patch' | 'delete' | 'get'
  data?: any
  headers?: { [key: string]: string }
  params?: { [key: string]: string }
}

export type ProxyApiResponse =
  | string
  | {
      data: string | object
      status: number
      headers: { [key: string]: string }
      error?: string | boolean
    }

/**
 * calls proxy api to perform api request.
 * @param requestObj object that is used to build the actual api request
 */
export async function callProxyApi(requestObj: ProxyApiRequest): Promise<AxiosResponse<ProxyApiResponse> | null> {
  try {
    const account = getAccount()
    if (account) {
      const accessToken = await getSilentToken(account)
      return axios({
        method: 'POST',
        url: `${studioBackendConfig.url}/proxyApi`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        data: requestObj,
      })
        .then((response: AxiosResponse<ProxyApiResponse>) => {
          // return response.data.result.botInfos
          return response
        })
        .catch((error) => {
          console.error('[StudioBackend] Proxy API call failed.', error)
          return null
        })
    }
    return null
  } catch (err) {
    console.error(`[StudioBackend][proxyApi] ${err}`)
    return null
  }
}

/**
 * calls studio api to get analytics
 * @param requestObj
 * @returns
 */
export async function getAnalytics(requestObj: GetAnalyticsRequest): Promise<GetAnalyticsResponse | null> {
  try {
    const account = getAccount()
    if (account && account?.idTokenClaims?.['extension_CustomerID']) {
      const accessToken = await getSilentToken(account)
      if (accessToken) {
        return axios({
          method: 'POST',
          url: `${studioBackendConfig.url}/getAnalytics`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          data: requestObj,
        })
          .then((response: AxiosResponse<GetAnalyticsResponse>) => {
            return response.data
          })
          .catch((err) => {
            console.error(`[StudioBackend][getAnalytics] ${err}`)
            return null
          })
      }
    }
    return null
  } catch (err) {
    console.error(`[StudioBackend][getAnalytics] ${err}`)
    return null
  }
}

// ========================================
// KNOWLEDGE MANAGEMENT & NLU

export type RequestLockUnauthorized = {
  status: 'unauthorized'
}

export type RequestLockResult =
  | RequestLockUnauthorized
  | { [lockScope: string]: RequestLockDenied | RequestLockSuccess }

export async function requestLocks(
  botId: string,
  scopes: LockScope[],
  sessionId: string,
): Promise<RequestLockResult | null> {
  try {
    if (typeof botId !== 'string') return null
    const account = getAccount()
    if (account) {
      const accessToken = await getSilentToken(account)
      if (accessToken) {
        const oid = account.idTokenClaims?.oid ?? null
        const givenName = account.idTokenClaims?.given_name ?? null
        const familyName = account.idTokenClaims?.family_name ?? null
        // const role = account.idTokenClaims?.extension_Role ?? null

        return axios({
          method: 'post',
          url: `${studioBackendConfig.url}/requestLocks`,
          data: {
            oid,
            name: {
              given_name: givenName,
              family_name: familyName,
            },
            botId: botId,
            lockScopes: scopes,
            sessionId,
          },
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
        })
          .then((response: AxiosResponse<RequestLockResult>) => {
            if (response !== null) {
              switch (response.status) {
                case 200: {
                  return response.data
                }
                default:
                  return null
              }
            } else {
              return null
            }
          })
          .catch((error: any) => {
            // error codes like 423 are viewed as exceptions by axios
            if (error.response) {
              switch (error.response.status) {
                case 400:
                case 403:
                  return { status: 'unauthorized' } as RequestLockUnauthorized
                case 423:
                  // call was successful but one or more requests were not granted
                  return error.response.data as RequestLockResult
              }
            }
            console.error('[StudioBackend] requestLock API call failed', error)
            return null
          })
      }
    }
    return null
  } catch (err) {
    console.error(`[StudioBackend] requestLock API call failed ${err}`)
    return null
  }
}

type ReleaseLockResult = {
  status: 'success' | 'error'
}

/**
 * Releases lock for scope.
 * @param botId
 * @param scopes
 * @returns
 */
export async function releaseLocks(
  botId: string,
  scopes: LockScope[],
  sessionId: string,
  isRetry = false,
): Promise<ReleaseLockResult | null> {
  try {
    if (typeof botId !== 'string') return null
    const account = getAccount()
    if (account) {
      const accessToken = await getSilentToken(account)
      if (accessToken) {
        const oid = account.idTokenClaims?.oid ?? null

        return axios({
          method: 'post',
          url: `${studioBackendConfig.url}/releaseLocks`,
          data: {
            oid,
            botId,
            lockScopes: scopes,
            sessionId,
          },
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
        })
          .then((response: AxiosResponse<any>) => {
            if (response === null) {
              console.error('[StudioBackend] releaseLock API call failed. Result is null.')
              return null
            }

            if (response.status >= 200 && response.status < 300) {
              // success
              return { status: 'success' } as ReleaseLockResult
            } else {
              return { status: 'error' } as ReleaseLockResult
            }
          })
          .catch((error: any) => {
            console.error('[StudioBackend] releaseLock API call failed', error)
            return null // fail silently
          })
      }
    }
    return null
  } catch (err) {
    if (!isRetry) return releaseLocks(botId, scopes, sessionId, true)
    else {
      console.error(`[StudioBackend] releaseLock API call failed ${err}`)
      return null
    }
  }
}

type GetBudgetsResult = {
  budgets: {
    budgetName: string
    budgetId: string
  }[]
}

/**
 * Gets budgets of customer.
 */
export async function getBudgets(): Promise<GetBudgetsResult | null> {
  try {
    const account = getAccount()
    const tokenClaims = account?.idTokenClaims ?? {}
    const customerId = tokenClaims['extension_CustomerID']
    if (account) {
      const accessToken = await getSilentToken(account)
      return axios({
        method: 'get',
        url: `${studioBackendConfig.url}/getBudgets`,
        params: {
          customerId,
        },
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
      }).then((response: AxiosResponse<GetBudgetsResult>) => {
        return response.data
      })
    }
    return null
  } catch (err) {
    console.error('[StudioBackend] getBudgets API call failed', err)
    return null
  }
}

type CreateKnowledgeDbResponse = {
  knowledgeDbName: string
  knowledgeDbId: string
}

/**
 * Creates new knowledge db.
 */
export async function createKnowledgeDb(
  knowledgeDbName: string,
  language = 'de',
  dbType: 'classic' | 'rag' = 'classic',
): Promise<CreateKnowledgeDbResponse | null> {
  try {
    const account = getAccount()
    const tokenClaims = account?.idTokenClaims ?? {}
    const customerId = tokenClaims['extension_CustomerID']
    if (account) {
      const accessToken = await getSilentToken(account)
      const response = await axios({
        method: 'post',
        url: `${studioBackendConfig.url}/createKnowledgeDb`,
        data: {
          customerId,
          language,
          knowledgeDbName,
          dbType,
          moduleConfigId: dbType === 'classic' ? MODULE_ID_CONVAISE_LLM_NLU : MODULE_ID_CONVAISE_RAG,
        },
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
      })

      return response.data
    }
    return null
  } catch (err) {
    console.error('[StudioBackend] createKnowledgeDb API call failed', err)
    return null
  }
}

type GetKnowledgeDbsResult = {
  knowledgeDbs: KnowledgeDB[]
}

/**
 * Retrieves knowledge dbs of customer.
 */
export async function getKnowledgeDbs(): Promise<GetKnowledgeDbsResult | null> {
  try {
    const account = getAccount()
    const tokenClaims = account?.idTokenClaims ?? {}
    const customerId = tokenClaims['extension_CustomerID']
    if (account) {
      const accessToken = await getSilentToken(account)
      return axios({
        method: 'get',
        url: `${studioBackendConfig.url}/getKnowledgeDbs`,
        params: {
          customerId,
        },
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
      }).then((response: AxiosResponse<GetKnowledgeDbsResult>) => {
        return response.data
      })
    }
    return null
  } catch (err) {
    console.error('[StudioBackend] getKnowledgeDbs API call failed', err)
    return null
  }
}

// RAG KNOWLEDGE

type GetRAGDocumentsResult = {
  documents: RAGDocument[]
  currentlyIngestingDocuments: RAGCurrentlyIngestingDocument[]
}

/**
 * Fetches RAG documents from API.
 * @returns
 */
export async function getRAGDocuments(
  botId: string,
  knowledgeDbId: string,
  onlyMetadata = true,
  documentIds?: string[],
): Promise<GetRAGDocumentsResult | null> {
  try {
    const account = getAccount()
    const tokenClaims = account?.idTokenClaims ?? {}
    const customerId = tokenClaims['extension_CustomerID']
    if (account) {
      const accessToken = await getSilentToken(account)
      return axios({
        method: 'post',
        url: `${studioBackendConfig.url}/rag/getDocuments`,
        data: {
          botId,
          knowledgeDbId,
          onlyMetadata,
          documentIds,
        },
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
      }).then((response: AxiosResponse<GetRAGDocumentsResult>) => {
        return response.data
      })
    }
    return null
  } catch (err) {
    console.error('[StudioBackend] getDocuments API call failed', err)
    return null
  }
}

export type ParseRAGDocumentResult = {
  jobId: string
  status: string
}

/**
 * Sends file to backend parser. Extracts text from file.
 * @param file
 * @returns
 */
export async function parseRAGDocument(file: File): Promise<ParseRAGDocumentResult | null> {
  try {
    const account = getAccount()
    const tokenClaims = account?.idTokenClaims ?? {}
    const customerId = tokenClaims['extension_CustomerID']
    if (account) {
      const accessToken = await getSilentToken(account)

      // we set 3 form data fields per document:
      // <tmp-id>-meta: contains stringified json object with metadata (title, type, etc.)
      // <tmp-id>-content: contains the (parsed) content of the document (string)
      // <tmp-id>-file: contains the file object (Blob). We create a file for all types (also .txt files)
      // for HTML support: ensure that the parser returns the raw html together with the parsed content:
      const formData = new FormData()

      formData.append('file', file)

      return axios({
        method: 'post',
        url: `${studioBackendConfig.url}/rag/parseDocument`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${accessToken}` },
      }).then((response: AxiosResponse<ParseRAGDocumentResult>) => {
        return response.data[file.name]
      })
    }
    return null
  } catch (err) {
    console.error('[StudioBackend] parseDocuments API call failed', err)
    return null
  }
}

/**
 * Retrieves the status of a RAG document parsing job.
 * @param jobId - The ID of the parsing job to check.
 * @returns A promise that resolves to an object containing the job status, job ID, and parsed content (if available), or null if the request fails.
 */
export async function getRAGDocumentParsingJobStatusAPI(
  jobId: string,
): Promise<{ status: string; jobId: string; parsedContent?: string } | null> {
  try {
    const account = getAccount()
    const tokenClaims = account?.idTokenClaims ?? {}
    const customerId = tokenClaims['extension_CustomerID']
    if (account) {
      const accessToken = await getSilentToken(account)
      return axios({
        method: 'get',
        url: `${studioBackendConfig.url}/rag/getParseDocumentJob`,
        params: {
          jobId,
        },
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
      }).then((response: AxiosResponse<{ status: string; jobId: string; parsedContent?: string }>) => {
        return response.data
      })
    }
    return null
  } catch (err) {
    console.error('[StudioBackend] getRAGDocumentParsingJobStatusAPI API call failed', err)
    return null
  }
}

export type ParseRAGWebsiteResult = {
  content: string
  title?: string
  meta_description?: string | null
  meta_keywords?: string[] | null
  html_hash: string
  errorMsg?: string
}

/**
 * Extracts website content with backend parser.
 * @param url
 * @returns
 */
export async function parseRAGWebsite(
  url: string,
  botId: string,
  knowledgeDbId: string,
): Promise<ParseRAGWebsiteResult | null> {
  try {
    const account = getAccount()
    const tokenClaims = account?.idTokenClaims ?? {}
    const customerId = tokenClaims['extension_CustomerID']
    if (account) {
      const accessToken = await getSilentToken(account)

      return axios({
        method: 'post',
        url: `${studioBackendConfig.url}/rag/parseWebsite`,
        data: {
          websiteUrl: url,
          botId,
          knowledgeDbId,
        },
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
      }).then((response: AxiosResponse<ParseRAGWebsiteResult>) => {
        return response.data
      })
    }
    return null
  } catch (err) {
    console.error('[StudioBackend] parseDocuments API call failed', err)
    return null
  }
}

/**
 * Fetches RAG documents from API.
 * @returns
 */
export async function addRAGDocuments(
  botId: string,
  knowledgeDbId: string,
  documents: RAGDocumentForInsertion[],
): Promise<void | null> {
  try {
    const account = getAccount()
    const tokenClaims = account?.idTokenClaims ?? {}
    const customerId = tokenClaims['extension_CustomerID']
    if (account) {
      const accessToken = await getSilentToken(account)

      const formData = prepareInsertionRequest(botId, knowledgeDbId, documents, false)

      return axios({
        method: 'post',
        url: `${studioBackendConfig.url}/rag/addDocuments`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${accessToken}` },
      }).then((response: AxiosResponse<void>) => {
        return
      })
    }
  } catch (err) {
    console.error('[StudioBackend] addDocuments API call failed', err)
    return null
  }
}

type DeleteRAGDocumentsResult = {
  deletedDocumentIds: string[]
}

/**
 * Deletes RAG documents from API.
 * @param botId
 * @param knowledgeDbId
 * @param documentIds
 * @returns
 */
export async function deleteRAGDocuments(
  botId: string,
  knowledgeDbId: string,
  documentIds: string[],
): Promise<DeleteRAGDocumentsResult | null> {
  try {
    const account = getAccount()
    const tokenClaims = account?.idTokenClaims ?? {}
    const customerId = tokenClaims['extension_CustomerID']
    if (account) {
      const accessToken = await getSilentToken(account)
      return axios({
        method: 'delete',
        url: `${studioBackendConfig.url}/rag/deleteDocuments`,
        // timeout: 20000,
        data: {
          botId,
          knowledgeDbId,
          documentIds,
        },
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
      }).then((response: AxiosResponse<DeleteRAGDocumentsResult>) => {
        return response.data
      })
    }
    return null
  } catch (err) {
    console.error('[StudioBackend] deleteRAGDocuments API call failed', err)
    return null
  }
}

/**
 * Updates / replaces RAG document in db.
 * @param botId
 * @param knowledgeDbId
 * @param newDocument
 * @returns
 */
export async function updateRAGDocument(
  botId: string,
  knowledgeDbId: string,
  newDocument: RAGDocumentForUpdate,
): Promise<void | null> {
  try {
    const account = getAccount()
    const tokenClaims = account?.idTokenClaims ?? {}
    const customerId = tokenClaims['extension_CustomerID']
    if (account) {
      const accessToken = await getSilentToken(account)

      const formData = prepareInsertionRequest(botId, knowledgeDbId, [newDocument], true)

      return axios({
        method: 'post',
        url: `${studioBackendConfig.url}/rag/updateDocuments`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${accessToken}` },
      }).then((response: AxiosResponse<void>) => {
        return
      })
    }
  } catch (err) {
    console.error('[StudioBackend] updateRAGDocument API call failed', err)
  }
}

// NLU KNOWLEDGE

type CreateAnswerResult = {
  createdAnswer: {
    answerId: string
    answer: Answer
    translatedAnswers: {
      [lang: string]: Answer
    }
  }
  modelStatus: ModelTrainingStatus
}

/**
 * Creates answer and returns created answer
 * @param botId
 * @param answer
 * @param knowledgeType
 * @returns
 */
export async function createAnswer(
  botId: string,
  answer: AnswerWithoutIntent | TriggerAnswerWithoutIntent,
  knowledgeType: KnowledgeType,
): Promise<CreateAnswerResult | null> {
  try {
    if (typeof botId !== 'string') {
      return null
    }
    const account = getAccount()
    if (account) {
      const accessToken = await getSilentToken(account)
      if (accessToken) {
        return axios({
          method: 'post',
          url: `${studioBackendConfig.url}/createAnswer`,
          data: {
            botId,
            type: knowledgeType,
            answer,
          },
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
        })
          .then((response: AxiosResponse<CreateAnswerResult>) => {
            return response.data
          })
          .catch((err: any) => {
            console.error(`[StudioBackend] createAnswer API call failed ${err}`)
            return null
          })
      }
    }
    return null
  } catch (err) {
    console.error(`[StudioBackend] createAnswer API call failed ${err}`)
    return null
  }
}

export type SaveAnswersUtterances = {
  [answerId: string]: string[] // associates list of utterances to an answer
}

/**
 *
 * @param botId
 * @param knowledgeType
 * @param answerOrAnswers
 * @param newUtterances
 * @param deletedUtterances
 * @param answerTemplateData
 * @returns the saved answers by language
 */
export async function saveAnswers(
  botId: string,
  knowledgeType: KnowledgeType,
  answers: Answers,
  newUtterances?: SaveAnswersUtterances,
  deletedUtterances?: SaveAnswersUtterances,
  answerTemplateData?: AnswerTemplateData,
): Promise<Answers | null> {
  try {
    /**
     * API expexts:
     * export type SaveAnswersRequest = {
        answers: {
          [lang: string]: {
            [answerId: string]: AnswerComplete | AnswerCompleteTranslation
          }
        }
        newUtterances?: {
          [answerId: string]: string[]
        }
        deletedUtterances?: {
          [answerId: string]: string[]
        }
        botId: string
        type: KnowledgeDBType
        customerId: string
        templateData?: QnATemplateData
      }
     */
    if (!knowledgeType || !answers || !botId) return null
    const account = getAccount()
    if (account) {
      const accessToken = await getSilentToken(account)
      if (accessToken) {
        return axios({
          method: 'post',
          url: `${studioBackendConfig.url}/saveAnswers`,
          data: {
            botId,
            type: knowledgeType,
            templateData: answerTemplateData,
            answers,
            newUtterances,
            deletedUtterances,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((response: AxiosResponse<Answers>) => {
            return response.data
          })
          .catch((error: any) => {
            console.error('[StudioBackend] saveAnswers API call failed', error)
            return null
          })
      }
    }
    return null
  } catch (err) {
    console.error('[StudioBackend] saveAnswers API call failed', err)
    return null
  }
}

/**
 * Retrieves all answers.
 * @param backendUrl
 * @param botInfos
 * @param jwtToken
 * @param knowledgeType
 */
export async function getAnswers(
  botId: string,
  knowledgeType: KnowledgeType,
  getModelStatus?: boolean,
  languages?: string[], // lets user specify which languages should be returned - default all languages are returned
  returnUtterances = true, // if true, returns no utterances for the answer. If false returns utterances for answers in primary language
): Promise<GetAnswersResult | null> {
  try {
    if (!botId || !knowledgeType) return null
    const account = getAccount()
    const tokenClaims = account?.idTokenClaims ?? {}
    const customerId = tokenClaims['extension_CustomerID']
    if (account) {
      const accessToken = await getSilentToken(account)
      return axios({
        method: 'get',
        url: `${studioBackendConfig.url}/getAnswers`,
        params: {
          botId: botId,
          type: knowledgeType,
          customerId,
          getModelStatus,
          languages: languages ? JSON.stringify(languages) : undefined,
          noUtterances: !returnUtterances,
        },
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
      }).then((response: AxiosResponse<GetAnswersResult>) => {
        return response.data
      })
    }
    return null
  } catch (err) {
    console.error('[StudioBackend] getAnswers API call failed', err)
    return null
  }
}

/**
 * Retrieves all answers with labels in an export format.
 * @param backendUrl
 * @param botInfos
 * @param jwtToken
 * @param knowledgeType
 */
export async function getExportKnowledgeDb(
  botId: string,
  knowledgeType: KnowledgeType,
  languages: string[],
): Promise<GetExportKnowledgeDbResponse | null> {
  try {
    if (!botId || !knowledgeType) return null
    const account = getAccount()
    const tokenClaims = account?.idTokenClaims ?? {}
    const customerId = tokenClaims['extension_CustomerID']
    if (account) {
      const accessToken = await getSilentToken(account)
      return axios({
        method: 'get',
        url: `${studioBackendConfig.url}/getExportKnowledgeDb`,
        params: {
          botId: botId,
          type: knowledgeType,
          customerId,
          languages: JSON.stringify(languages),
        },
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
      }).then((response: AxiosResponse<GetExportKnowledgeDbResponse>) => {
        return response.data
      })
    }
    return null
  } catch (err) {
    console.error('[StudioBackend] getExportKnowledgeDb API call failed', err)
    return null
  }
}

export async function saveAnswerData(
  botId: string,
  knowledgeType: KnowledgeType,
  answerTemplateData: AnswerTemplateData,
): Promise<void | null> {
  try {
    if (!knowledgeType || !answerTemplateData || !botId) return null
    const account = getAccount()
    if (account) {
      const accessToken = await getSilentToken(account)
      if (accessToken) {
        return axios({
          method: 'post',
          url: `${studioBackendConfig.url}/saveAnswerData`,
          data: {
            botId,
            type: knowledgeType,
            answerData: answerTemplateData,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then(() => {
            return
          })
          .catch((error: any) => {
            console.error('[StudioBackend] saveAnswerData API call failed', error)
            return null
          })
      }
    }
    return null
  } catch (err) {
    console.error('[StudioBackend] saveAnswers API call failed', err)
    return null
  }
}

type GetAnswerDataResult = {
  answerData: AnswerTemplateData
}

/**
 * Fetches answer data from studio API.
 * Filters result to only return AnswerTemplateData
 */
export async function getAnswerData(botId: string): Promise<AnswerTemplateData | null> {
  try {
    const account = getAccount()
    if (account) {
      const accessToken = await getSilentToken(account)
      if (accessToken) {
        return axios({
          method: 'get',
          url: `${studioBackendConfig.url}/getAnswerData`,
          params: {
            botId,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((result: AxiosResponse<GetAnswerDataResult>) => {
            return result.data.answerData
          })
          .catch((error: any) => {
            console.error('[StudioBackend] getAnswerData API call failed', error)
            return null
          })
      }
    }
    return null
  } catch (err) {
    console.error('[StudioBackend] getAnswerData API call failed', err)
    return null
  }
}

/**
 * Retrieves all answers.
 * @param backendUrl
 * @param botInfos
 * @param jwtToken
 * @param knowledgeType
 */
export async function deleteAnswerOrAnswers(
  botId: string,
  knowledgeType: KnowledgeType,
  answerIdOrIds: string | string[],
  isRetry = false,
): Promise<GetAnswersResult | null> {
  try {
    if (!botId || !knowledgeType || !answerIdOrIds) return null
    const account = getAccount()
    if (account) {
      const customerId = account.idTokenClaims ? account.idTokenClaims['extension_CustomerID'] : undefined
      const accessToken = await getSilentToken(account)
      if (accessToken) {
        return axios({
          method: 'delete',
          url: `${studioBackendConfig.url}/deleteAnswers`,
          data: {
            botId,
            type: knowledgeType,
            customerId,
            answerIds: Array.isArray(answerIdOrIds) ? answerIdOrIds : [answerIdOrIds],
          },
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
        })
          .then((response: AxiosResponse<GetAnswersResult>) => {
            return response.data
          })
          .catch((error: any) => {
            console.error('[StudioBackend] deleteAnswerOrAnswers API call failed', error)
            return null
          })
      }
    }
    return null
  } catch (err) {
    if (!isRetry) {
      return deleteAnswerOrAnswers(botId, knowledgeType, answerIdOrIds, true)
    } else {
      console.error('[StudioBackend] deleteAnswerOrAnswers API call failed', err)
      return null
    }
  }
}

export async function getNLUStatus(botId: string, knowledgeType: KnowledgeType): Promise<ModelStatus | null> {
  try {
    if (!botId || !knowledgeType) return null
    const account = getAccount()
    if (account) {
      const accessToken = await getSilentToken(account)
      return axios({
        method: 'get',
        url: `${studioBackendConfig.url}/getNluStatus`,
        params: {
          botId,
          type: knowledgeType,
        },
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
      }).then((response: AxiosResponse<ModelStatus>) => {
        return response.data
      })
    }
    return null
  } catch (err) {
    console.error('[StudioBackend] getNLUStatus API call failed', err)
    return null
  }
}

export async function getNLUSimilarIntents(
  botId: string,
  knowledgeType: KnowledgeType,
  question: string,
): Promise<SimilarIntents | null> {
  try {
    if (!botId || !knowledgeType || !question) return null
    const account = getAccount()

    if (account) {
      const accessToken = await getSilentToken(account)
      return axios({
        method: 'get',
        url: `${studioBackendConfig.url}/NLUGetSimilarIntents`,
        params: {
          botId,
          type: knowledgeType,
          question,
        },
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
      }).then((response: AxiosResponse<{ similarIntents: SimilarIntents }>) => {
        return response.data.similarIntents
      })
    }
    return null
  } catch (err) {
    console.error('[StudioBackend] getSimilarIntents API call failed', err)
    return null
  }
}

/**
 * Retrieves endpoint utterances of NLU model (live endpoint hits of the model)
 * @param botId
 * @param knowledgeType
 * @param returnAnswers   Should answers be returned as well
 * @param mode            Get unanswered and answered (mode = all) or just one of them
 * @param answeredFrom    API Pagination - start index in result - starts with 0
 * @param answeredTo      API Pagination - end index in result
 * @param unansweredFrom  API Pagination - start index in result - starts with 0
 * @param unansweredTo    API Pagination - end index in result
 * @param sort            Sort for oldest or newest first - defaults to oldest first
 */
export async function getEndpointUtterances(
  botId: string,
  knowledgeType: KnowledgeType,
  returnAnswers = true,
  mode?: 'all' | 'unanswered' | 'answered' | undefined,
  answeredFrom?: number,
  answeredTo?: number,
  unansweredFrom?: number,
  unansweredTo?: number,
  sort?: 'oldest' | 'newest',
): Promise<{
  answeredEndpointUtterances?: EndpointUtteranceAnswered[]
  unansweredEndpointUtterances?: EndpointUtteranceUnanswered[]
  answers?: { [answerId: string]: Answer }
  totalAnswered?: number
  totalUnanswered?: number
} | null> {
  try {
    if (!botId || !knowledgeType) return null
    const account = getAccount()

    if (account) {
      const accessToken = await getSilentToken(account)
      const params = {
        botId,
        type: knowledgeType,
        returnAnswers,
        ...(typeof mode === 'string' && { mode }),
        ...(typeof answeredFrom === 'number' && { answeredFrom }),
        ...(typeof answeredTo === 'number' && { answeredTo }),
        ...(typeof unansweredFrom === 'number' && { unansweredFrom }),
        ...(typeof unansweredTo === 'number' && { unansweredTo }),
        ...(typeof sort === 'string' && { sort }),
      }
      return axios({
        method: 'get',
        url: `${studioBackendConfig.url}/NLUGetEndpointUtterances`,
        params,
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
      }).then(
        (
          response: AxiosResponse<{
            answeredEndpointUtterances?: EndpointUtteranceAnswered[]
            unansweredEndpointUtterances?: EndpointUtteranceUnanswered[]
            answers?: { [answerId: string]: Answer }
            totalAnswered?: number
            totalUnanswered?: number
          }>,
        ) => {
          return response.data
        },
      )
    }
    return null
  } catch (err) {
    console.error('[StudioBackend] getEndpointUtterances API call failed', err)
    return null
  }
}

/**
 * Retrieves endpoint utterances of LLM NLU module (live endpoint hits.)
 * Each endpoint utterance is scored against the first part of the prediction pipeline (without LLM)
 * to get a suggestion and to get trafficlight indicator for reliability of suggestion.
 * @param botId
 * @param from
 * @param to
 * @param sort
 */
export async function getLLMNLUEndpointUtterances(
  botId: string,
  from: number,
  to: number,
  sort: 'newest' | 'oldest',
  isRetry = false,
): Promise<{ preparedEndpointUtterances: LLMNLUEndpointUtterance[]; totalNumberOfEndpointUtterances: number } | null> {
  try {
    if (!botId) return null
    const account = getAccount()

    if (account) {
      const accessToken = await getSilentToken(account)
      const params = {
        botId,
        from,
        to,
        sort,
      }
      return axios({
        method: 'get',
        url: `${studioBackendConfig.url}/LLMNLUGetEndpointUtterances`,
        params,
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
      }).then(
        (
          response: AxiosResponse<{
            preparedEndpointUtterances: LLMNLUEndpointUtterance[]
            totalNumberOfEndpointUtterances: number
          }>,
        ) => {
          return response.data
        },
      )
    }
    return null
  } catch (err) {
    if (!isRetry) {
      return getLLMNLUEndpointUtterances(botId, from, to, sort, true)
    } else {
      console.error('[StudioBackend] getEndpointUtterances API call for NLU failed', err)
      return null
    }
  }
}

/**
 * Adds one or multiple utterances to an intent in the nlu model
 * @param botId
 * @param intentName
 * @param knowledgeType
 * @param utteranceOrUtterances
 */
export async function addUtteranceToNLUIntent(
  botId: string,
  intentName: string,
  knowledgeType: KnowledgeType,
  utteranceOrUtterances: string | string[],
  isRetry = false,
): Promise<void | null> {
  try {
    if (!botId || !knowledgeType || !utteranceOrUtterances || !intentName) return null
    const account = getAccount()

    if (account) {
      const accessToken = await getSilentToken(account)
      return axios({
        method: 'put',
        url: `${studioBackendConfig.url}/nluAddUtterancesToIntent`,
        data: {
          botId,
          type: knowledgeType,
          intentName,
          utterances: typeof utteranceOrUtterances === 'string' ? [utteranceOrUtterances] : utteranceOrUtterances,
        },
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
      }).then(() => {
        return
      })
    }
    return null
  } catch (err) {
    if (!isRetry) {
      return addUtteranceToNLUIntent(botId, intentName, knowledgeType, utteranceOrUtterances, true)
    }
    console.error('[StudioBackend] addUtteranceToNLUIntent API call failed', err)
    return null
  }
}

/**
 * Adds one or multiple utterances to an intent in the nlu model
 * @param botId
 * @param knowledgeType
 * @param utteranceOrUtterances
 */
export async function removeEndpointUtterances(
  botId: string,
  knowledgeType: KnowledgeType,
  utteranceOrUtterances: string | string[],
): Promise<void | null> {
  try {
    if (!botId || !knowledgeType || !utteranceOrUtterances) return null
    const account = getAccount()

    if (account) {
      const accessToken = await getSilentToken(account)
      return axios({
        method: 'delete',
        url: `${studioBackendConfig.url}/nluRemoveEndpointUtterances`,
        data: {
          botId,
          type: knowledgeType,
          utterances: typeof utteranceOrUtterances === 'string' ? [utteranceOrUtterances] : utteranceOrUtterances,
        },
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
      }).then(() => {
        return
      })
    }
    return null
  } catch (err) {
    console.error('[StudioBackend] removeEndpointUtterances API call failed', err)
    return null
  }
}

/**
 * Performs NLU training and publishing request against API.
 * @param btoid
 * @param knowledgeType
 * @returns
 */
export async function trainAndPublishNLUModel(botId: string, knowledgeType: KnowledgeType): Promise<void | null> {
  try {
    if (!botId || !knowledgeType) return null
    const account = getAccount()

    if (account) {
      const accessToken = await getSilentToken(account)
      return axios({
        method: 'post',
        url: `${studioBackendConfig.url}/trainAndPublishModel`,
        data: {
          botId,
          type: knowledgeType,
        },
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
      }).then(() => {
        // success
        return
      })
    }
    return null
  } catch (err) {
    if ((err as any).response?.status === 409) {
      // conflict - model already trained with same training data
      // treat is as successful training
      return
    }
    console.error('[StudioBackend] trainNLUModel API call failed', err)
    return null
  }
}

/**
 * Predict Answer.
 * @param botId
 * @param knowledgeType
 * @returns
 */
export async function predictAnswer(
  botId: string,
  knowledgeType: KnowledgeType,
  question: string,
  topX = 10,
): Promise<PredictAnswerResult | null> {
  try {
    if (!botId || !knowledgeType || !question || !topX) return null
    const account = getAccount()

    if (account) {
      const accessToken = await getSilentToken(account)
      // FIXME: Endpoint does not exist in API
      return null
      // return axios({
      //   method: 'get',
      //   url: `${studioBackendConfig.url}/predictAnswer`,
      //   params: {
      //     botId,
      //     type: knowledgeType,
      //     question,
      //     topX,
      //   },
      //   headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
      // }).then((response: AxiosResponse<PredictAnswerResult>) => {
      //   return response.data
      // })
    }
    return null
  } catch (err) {
    console.error('[StudioBackend] publishModel API call failed', err)
    return null
  }
}

// ========================================
// CUSTOM NOTIFICATIONS

type GetCustomNotificationsResponse = {
  botId: string
  customNotifications: CustomNotifications
  overlappingCustomNotifications: OverlappingCustomNotifications
}

/**
 * Retrieves all custom notifications.
 * @param botId
 */
export async function getCustomNotifications(botId: string): Promise<GetCustomNotificationsResponse | null> {
  try {
    if (!botId) return null
    const account = getAccount()
    if (account) {
      const accessToken = await getSilentToken(account)
      return axios({
        method: 'get',
        url: `${studioBackendConfig.url}/getCustomNotifications`,
        params: {
          botId: botId,
        },
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
      }).then((response: AxiosResponse<GetCustomNotificationsResponse>) => {
        for (const notification of Object.values(response.data.customNotifications)) {
          // parse to date objects
          if (notification.startDate) notification.startDate = new Date(notification.startDate)
          if (notification.endDate) notification.endDate = new Date(notification.endDate)
          if (notification.times)
            notification.times = notification.times.map((time) => {
              return {
                startTime: new Date(time.startTime),
                endTime: new Date(time.endTime),
              }
            })
          if (notification.createdTimestamp) notification.createdTimestamp = new Date(notification.createdTimestamp)
        }
        return response.data
      })
    }
    return null
  } catch (err) {
    console.error('[StudioBackend] getCustomNotifications API call failed', err)
    return null
  }
}

type SaveCustomNotificationsResponse = {
  overlappingCustomNotifications: OverlappingCustomNotifications
}
type DeleteCustomNotificationsResponse = SaveCustomNotificationsResponse

/**
 * Performs save notification request against API.
 * @param botId
 * @param customNotifications
 */
export async function saveCustomNotifications(
  botId: string,
  customNotifications: CustomNotification[],
): Promise<SaveCustomNotificationsResponse | null> {
  try {
    if (!botId) return null
    const account = getAccount()
    if (account) {
      const accessToken = await getSilentToken(account)
      return axios({
        method: 'post',
        url: `${studioBackendConfig.url}/saveCustomNotifications`,
        data: {
          botId: botId,
          customNotifications,
        },
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
      }).then((response: AxiosResponse<SaveCustomNotificationsResponse>) => {
        return response.data
      })
    }
    return null
  } catch (err) {
    console.error('[StudioBackend] saveCustomNotifications API call failed', err)
    return null
  }
}

/**
 * Deletes a custom notification.
 * @param botId
 * @param customNotificationIds
 */
export async function deleteCustomNotifications(
  botId: string,
  customNotificationIds: string[],
): Promise<DeleteCustomNotificationsResponse | null> {
  try {
    if (!botId) return null
    const account = getAccount()
    if (account) {
      const accessToken = await getSilentToken(account)
      return axios({
        method: 'delete',
        url: `${studioBackendConfig.url}/deleteCustomNotifications`,
        data: {
          botId,
          customNotificationIds,
        },
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
      }).then((response: AxiosResponse<DeleteCustomNotificationsResponse>) => {
        return response.data
      })
    }
    return null
  } catch (err) {
    console.error('[StudioBackend] deleteCustomNotification API call failed', err)
    return null
  }
}

type checkOverlapOfCustomNotificationResponse = {
  overlappingNotifications: OverlappingCustomNotifications
}

/**
 * Checks a custom notification for overlaps with other notifications.
 * @param botId
 * @param customNotificationIds
 * @param checkOnlyActive if true, only active notifications are checked, else all notifications are checked
 */
export async function checkOverlapOfCustomNotification(
  botId: string,
  customNotification?: CustomNotification,
  checkOnlyActive = true,
): Promise<OverlappingCustomNotifications | null> {
  try {
    if (!botId) return null
    const account = getAccount()
    if (account) {
      const accessToken = await getSilentToken(account)
      return axios({
        method: 'post',
        url: `${studioBackendConfig.url}/checkCustomNotificationOverlap`,
        data: {
          botId,
          notificationToCheck: customNotification,
          onlyActive: checkOnlyActive,
        },
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
      }).then((response: AxiosResponse<checkOverlapOfCustomNotificationResponse>) => {
        return response.data.overlappingNotifications
      })
    }
    return null
  } catch (err) {
    console.error('[StudioBackend] checkOverlapOfCustomNotification API call failed', err)
    return null
  }
}

/**
 * Fetches a specific dictionary by its id.
 * @param botId
 * @param dictionaryId
 * @returns the dictionary or null if the dictionary was not found or something went wrong
 */
export async function getDictionary(botId: string, dictionaryId: string): Promise<Dictionary | null> {
  try {
    if (!botId || !dictionaryId) return null
    const account = getAccount()
    if (account) {
      const accessToken = await getSilentToken(account)
      return axios({
        method: 'get',
        url: `${studioBackendConfig.url}/getDictionary`,
        params: {
          botId,
          dictionaryId,
        },
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
      }).then((response: AxiosResponse<Dictionary>) => {
        return response.data
      })
    }
    return null
  } catch (err) {
    console.error('[StudioBackend] getDictionary API call failed', err)
    return null
  }
}

/**
 * Creates and updates entries in a dictionary.
 * @param botId
 * @param dictionaryId
 * @param dictionaryEntries
 */
export async function setAndDeleteDictionaryEntries(
  botId: string,
  dictionaryId: string,
  dictionaryEntriesToSave: DictionaryEntry[],
  dictionaryEntryIdsToDelete: string[],
): Promise<void | null> {
  try {
    if (!botId || !dictionaryId || !dictionaryEntriesToSave || !dictionaryEntryIdsToDelete) return null
    const account = getAccount()
    if (account) {
      const accessToken = await getSilentToken(account)
      return axios({
        method: 'post',
        url: `${studioBackendConfig.url}/setAndDeleteDictionaryEntries`,
        data: {
          botId,
          dictionaryId,
          dictionaryEntriesToSave,
          dictionaryTermIdsToDelete: dictionaryEntryIdsToDelete,
        },
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
      }).then((response: AxiosResponse<void>) => {
        return
      })
    }
    return null
  } catch (err) {
    console.error('[StudioBackend] getDictionary API call failed', err)
    return null
  }
}

// ========================================
// DOCUMENTS

/**
 * Completes ingest process.
 * Stores pdf, pdfSignature, ingestResult and prepares flowchart.
 * Returns updated and most recent botInfos after ingest has been completed.
 * @param botId
 * @param pdfFile
 * @param pdfSignature
 * @param ingestPdfResult
 * @returns
 */
export async function addDocument(
  botId: string,
  pdfFile: File,
  pdfSignature: Signature,
  syncedSignature: SyncedSignature,
  ingestPdfResult: IngestPDFResult,
  documentTitle: string,
  documentDescription?: string,
  generate?: boolean,
  calculateOrder?: boolean,
): Promise<BotInfos | null> {
  try {
    const account = getAccount()
    if (account) {
      const accessToken = await getSilentToken(account)

      const pdfBase64 = await pdfFileToBase64String(pdfFile)

      return axios({
        method: 'post',
        url: `${studioBackendConfig.url}/addDocument`,
        data: {
          botId,
          pdfFile: pdfBase64,
          pdfSignature,
          flowGeneration: {
            generate: generate,
            setOrder: calculateOrder,
          },
          pdfIngestResult: ingestPdfResult,
          pdfSyncedSignature: syncedSignature,
          documentTitle,
          documentDescription: documentDescription ?? '',
        },
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
      }).then((response: AxiosResponse<{ botInfos: BotInfos; chart: Chart }>) => {
        return response.data.botInfos
      })
    }
    return null
  } catch (err) {
    console.error('[StudioBackend] addDocument API call failed', err)
    return null
  }
}

type GetDocumentDownloadURLResponse = {
  downloadUrl: string
}

/**
 * Fetches the download url for a specified document.
 * @param botId the bot id
 * @param documentId the document id
 * @param isRetry if true, mean the current call is the retry
 */
export async function getDocumentDownloadURL(
  botId: string,
  documentId: string,
  isRetry = false,
): Promise<string | null> {
  try {
    const account = getAccount()
    if (account) {
      const accessToken = await getSilentToken(account)
      if (accessToken) {
        return axios({
          method: 'get',
          timeout: 10000,
          url: `${studioBackendConfig.url}/getDocumentDownloadUrl`,
          params: {
            botId,
            documentId,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((response: AxiosResponse<GetDocumentDownloadURLResponse>) => {
            return response.data.downloadUrl ?? null
          })
          .catch((err: any) => {
            console.error(`[StudioBackend] GetDocumentDownloadURL API call failed ${err}`)
            return null
          })
      }
    }
    return null
  } catch (err) {
    if (!isRetry) {
      return getDocumentDownloadURL(botId, documentId, true)
    } else {
      console.error(`[StudioBackend] GetDocumentDownloadURL API call failed ${err}`)
      return null
    }
  }
}

export type RemoveDocumentResponse = {
  botInfos: BotInfos
  removedVariables: {
    [nodeId: string]: string[] // list of variables removed from the node
  }
}

/**
 * Deletes a document that is connected to the bot.
 * Returns the updated bot infos and the connected variables that where removed from the chart.
 * @param botId the bot id
 * @param documentId the document id
 * @param isRetry if true, mean the current call is the retry
 */
export async function deleteDocument(
  botId: string,
  documentId: string,
  isRetry = false,
): Promise<RemoveDocumentResponse | null> {
  try {
    const account = getAccount()
    if (account) {
      const accessToken = await getSilentToken(account)
      if (accessToken) {
        return axios({
          method: 'delete',
          timeout: 10000,
          url: `${studioBackendConfig.url}/deleteDocument`,
          data: {
            botId,
            documentIdToRemove: documentId,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((response: AxiosResponse<RemoveDocumentResponse>) => {
            return response.data ?? null
          })
          .catch((err: any) => {
            console.error(`[StudioBackend] DeleteDocument API call failed ${err}`)
            return null
          })
      }
    }
    return null
  } catch (err) {
    if (!isRetry) {
      return deleteDocument(botId, documentId, true)
    } else {
      console.error(`[StudioBackend] DeleteDocument API call failed ${err}`)
      return null
    }
  }
}

/**
 * Deletes a document that is connected to the bot.
 * Returns the updated bot infos and the connected variables that where removed from the chart.
 * @param botId the bot id
 * @param documentId the document id
 * @param isRetry if true, mean the current call is the retry
 */
export async function updateDocumentInfos(
  botId: string,
  documentId: string,
  isRetry = false,
  documentTitle?: string,
  documentDescription?: string,
): Promise<BotInfos | null> {
  try {
    const account = getAccount()
    if (account) {
      const accessToken = await getSilentToken(account)
      if (accessToken) {
        return axios({
          method: 'post',
          timeout: 10000,
          url: `${studioBackendConfig.url}/updateDocumentInfos`,
          data: {
            botId,
            documentId,
            documentTitle: documentTitle ?? undefined,
            documentDescription: documentDescription ?? undefined,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((response: AxiosResponse<{ botInfos: BotInfos }>) => {
            return response.data.botInfos ?? null
          })
          .catch((err: any) => {
            console.error(`[StudioBackend] UpdateDocumentInfos API call failed ${err}`)
            return null
          })
      }
    }
    return null
  } catch (err) {
    if (!isRetry) {
      return updateDocumentInfos(botId, documentId, true, documentTitle ?? undefined, documentDescription ?? undefined)
    } else {
      console.error(`[StudioBackend] UpdateDocumentInfos API call failed ${err}`)
      return null
    }
  }
}

// ========================================
// TEMPLATING

type GetProcessTemplatesResponse = {
  type: TemplateType.PROCESS
  templates: Templates
}

/**
 * Fetches all process templates that the current user can use.
 */
export async function getProcessTemplates(isRetry = false): Promise<Templates | null> {
  try {
    const account = getAccount()
    if (account) {
      const accessToken = await getSilentToken(account)
      const tokenClaims = account?.idTokenClaims ?? {}
      const customerId = tokenClaims['extension_CustomerID']
      if (accessToken) {
        return axios({
          method: 'get',
          timeout: 10000,
          url: `${studioBackendConfig.url}/listTemplates`,
          params: {
            templateType: TemplateType.PROCESS,
            customerId,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((response: AxiosResponse<GetProcessTemplatesResponse>) => {
            return response.data.templates ?? null
          })
          .catch((err: any) => {
            console.error(`[StudioBackend] GetProcessTemplates API call failed ${err}`)
            throw err
          })
      }
    }
    return null
  } catch (err) {
    if (!isRetry) {
      return getProcessTemplates(true)
    } else {
      console.error(`[StudioBackend] GetProcessTemplates API call failed ${err}`)
      return null
    }
  }
}

type CreateTemplateResponse = {
  status: string
  template?: Template
}

/**
 * Creates or updates a process template.
 * If the template id is given, updates the template, otherwise a new template is created.
 * @param dialogId
 * @param templateName
 * @param templateDescription
 * @param chart
 * @param translationFile
 * @param templateId if given, the template is updated, otherwise a new template is created
 * @param isRetry
 * @returns
 */
export async function saveProcessTemplate(
  dialogId: string,
  templateName: string,
  templateDescription: string,
  chart: Chart,
  translationFile: TranslationFile,
  templateId?: string,
  isRetry = false,
): Promise<Template | null> {
  try {
    if (!templateName || !templateDescription || !chart || !translationFile || !dialogId) {
      return null
    }
    const account = getAccount()
    if (account) {
      const accessToken = await getSilentToken(account)
      const tokenClaims = account?.idTokenClaims ?? {}
      const customerId = tokenClaims['extension_CustomerID']

      const url = templateId ? `${studioBackendConfig.url}/updateTemplate` : `${studioBackendConfig.url}/createTemplate`

      if (accessToken) {
        return axios({
          method: 'post',
          timeout: 20000,
          url,
          data: {
            templateId,
            templateName,
            templateDescription,
            dialogId,
            chart,
            translationFile,
            customerId,
            templateKind: TemplateKind.CUSTOMER,
            templateType: TemplateType.PROCESS,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((response: AxiosResponse<CreateTemplateResponse>) => {
            return response.data.template ?? null
          })
          .catch((err: any) => {
            console.error(`[StudioBackend] SaveProcessTemplate API call failed ${err}`)
            return null
          })
      }
    }
    return null
  } catch (err) {
    if (!isRetry) {
      return saveProcessTemplate(dialogId, templateName, templateDescription, chart, translationFile, templateId, true)
    } else {
      console.error(`[StudioBackend] SaveProcessTemplate API call failed ${err}`)
      return null
    }
  }
}

// ========================================
// BOT SETTINGS

/**
 * Saves custom styles
 * @deprecated
 */
export async function saveCustomStyles(
  botId: string,
  customStyles: BotInfos['customStyles'],
  avatarImageBase64?: string,
  avatarImageType?: string,
  isRetry = false,
): Promise<BotInfos | null> {
  try {
    if (!customStyles || !botId) {
      return null
    }
    const account = getAccount()
    if (account) {
      const accessToken = await getSilentToken(account)
      const tokenClaims = account?.idTokenClaims ?? {}
      const customerId = tokenClaims['extension_CustomerID']

      if (accessToken) {
        return axios({
          method: 'post',
          timeout: 10000,
          url: `${studioBackendConfig.url}/saveCustomStyles`,
          data: {
            botId,
            styles: {
              ...customStyles,
              avatarImageType,
              avatarImg: avatarImageBase64,
            },
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((response: AxiosResponse<BotInfos>) => {
            return response.data ?? null
          })
          .catch((err: any) => {
            console.error(`[StudioBackend] SaveCustomStyles API call failed ${err}`)
            return null
          })
      }
    }
    return null
  } catch (err) {
    if (!isRetry) {
      return saveCustomStyles(botId, customStyles, avatarImageBase64, avatarImageType, true)
    } else {
      console.error(`[StudioBackend] SaveCustomStyles API call failed ${err}`)
      return null
    }
  }
}

export type GetCustomStylesResponse = {
  customStyles: BotInfos['customStyles'] & {
    avatarFileType: string
    avatarBase64: string
  }
}

/**
 * Gets Custom Styles.
 * @param isRetry
 * @deprecated
 */
export async function getCustomStyles(
  botId: string,
  isRetry = false,
): Promise<GetCustomStylesResponse['customStyles'] | null> {
  try {
    const account = getAccount()
    if (account) {
      const accessToken = await getSilentToken(account)
      const tokenClaims = account?.idTokenClaims ?? {}
      const customerId = tokenClaims['extension_CustomerID']

      if (accessToken) {
        return axios({
          method: 'get',
          timeout: 10000,
          url: `${studioBackendConfig.url}/getCustomStyles`,
          params: {
            botId,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((response: AxiosResponse<GetCustomStylesResponse>) => {
            return response.data.customStyles ?? null
          })
          .catch((err: any) => {
            console.error(`[StudioBackend] getCustomStyles API call failed ${err}`)
            return null
          })
      }
    }
    return null
  } catch (err) {
    if (!isRetry) {
      return getCustomStyles(botId, true)
    } else {
      console.error(`[StudioBackend] getCustomStyles API call failed ${err}`)
      return null
    }
  }
}

/**
 * Gets bot settings
 * @param botId
 */
export async function getBotSettings(botId: string, isRetry = false): Promise<BotSettings | null> {
  try {
    if (!botId) {
      return null
    }
    const account = getAccount()
    if (account) {
      const accessToken = await getSilentToken(account)
      const tokenClaims = account?.idTokenClaims ?? {}
      const customerId = tokenClaims['extension_CustomerID']

      if (accessToken) {
        return axios({
          method: 'get',
          // timeout: 10000,
          url: `${studioBackendConfig.url}/getSettings`,
          params: {
            botId,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((response: AxiosResponse<{ settings: BotSettings }>) => {
            return response.data.settings ?? null
          })
          .catch((err: any) => {
            console.error(`[StudioBackend] GetBotSettings API call failed ${err}`)
            return null
          })
      }
    }
    return null
  } catch (err) {
    if (!isRetry) {
      return getBotSettings(botId, true)
    } else {
      console.error(`[StudioBackend] GetBotSettings API call failed ${err}`)
      return null
    }
  }
}

/**
 * Gets bot settings
 * @param botId
 */
export async function saveBotSettings(
  botId: string,
  botSettings: BotSettings,
  isRetry = false,
): Promise<BotInfos | null> {
  try {
    if (!botId) {
      return null
    }
    const account = getAccount()
    if (account) {
      const accessToken = await getSilentToken(account)
      const tokenClaims = account?.idTokenClaims ?? {}
      const customerId = tokenClaims['extension_CustomerID']

      if (accessToken) {
        return axios({
          method: 'post',
          // timeout: 10000,
          url: `${studioBackendConfig.url}/saveSettings`,
          data: {
            botId,
            ...botSettings,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((response: AxiosResponse<{ botInfos: BotInfos }>) => {
            return response.data.botInfos ?? null
          })
          .catch((err: any) => {
            console.error(`[StudioBackend] saveBotSettings API call failed ${err}`)
            return null
          })
      }
    }
    return null
  } catch (err) {
    if (!isRetry) {
      return saveBotSettings(botId, botSettings, true)
    } else {
      console.error(`[StudioBackend] saveBotSettings API call failed ${err}`)
      return null
    }
  }
}

// ========================================
// AUTOMATION FUNCTIONS (Paraphrasing, Summarization etc.)

/**
 * Translates a single text into the target lang.
 * @param text
 * @param sourceLang
 * @param targetLang
 */
export async function translate(
  text: string,
  sourceLang: string,
  targetLang: string,
  botId: string,
  useTermTranslations?: boolean,
  isRetry = false,
): Promise<TranslateResult | null> {
  try {
    if (typeof text !== 'string' || !text || !sourceLang || !targetLang) {
      return null
    }
    const account = getAccount()
    if (account) {
      const accessToken = await getSilentToken(account)
      if (accessToken) {
        const mappedSourceLang = mapLanguageCodeToAutomaticallyTranslateText(sourceLang)
        const mappedTargetLang = mapLanguageCodeToAutomaticallyTranslateText(targetLang)
        return axios({
          method: 'post',
          timeout: 40000,
          url: `${studioBackendConfig.url}/translate`,
          data: {
            text,
            sourceLang: mappedSourceLang,
            targetLang: mappedTargetLang,
            botId,
            useTermTranslations,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((response: AxiosResponse<TranslateResult>) => {
            return response.data
          })
          .catch((err: any) => {
            console.error(`[StudioBackend] translate API call failed ${err}`)
            return null
          })
      }
    }
    return null
  } catch (err) {
    if (!isRetry) {
      return translate(text, sourceLang, targetLang, botId, useTermTranslations, true)
    } else {
      console.error(`[StudioBackend] translate API call failed ${err}`)
      return null
    }
  }
}

/**
 * Translates a single text into the target lang.
 * @param text
 * @param sourceLang
 * @param targetLang
 */
export async function translateBatch(
  texts: string[],
  sourceLang: string,
  targetLang: string,
  botId: string,
  useTermTranslations?: boolean,
  isRetry = false,
): Promise<TranslateBatchResult | null> {
  try {
    if (!Array.isArray(texts) || !texts || !sourceLang || !targetLang) {
      return null
    }
    const account = getAccount()
    if (account) {
      const accessToken = await getSilentToken(account)
      if (accessToken) {
        const mappedSourceLang = mapLanguageCodeToAutomaticallyTranslateText(sourceLang)
        const mappedTargetLang = mapLanguageCodeToAutomaticallyTranslateText(targetLang)
        return axios({
          method: 'post',
          timeout: 40000,
          url: `${studioBackendConfig.url}/translateBatch`,
          data: {
            texts,
            sourceLang: mappedSourceLang,
            targetLang: mappedTargetLang,
            botId,
            useTermTranslations,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((response: AxiosResponse<TranslateBatchResult>) => {
            return response.data
          })
          .catch((err: any) => {
            console.error(`[StudioBackend] translate API call failed ${err}`)
            return null
          })
      }
    }
    return null
  } catch (err) {
    if (!isRetry) {
      return translateBatch(texts, sourceLang, targetLang, botId, useTermTranslations, true)
    } else {
      console.error(`[StudioBackend] translate API call failed ${err}`)
      return null
    }
  }
}

/**
 * Paraphrases a given text.
 */
export async function paraphrase(
  botId: string,
  phrases: string[],
  numberOfParaphrases = 5,
  language = 'de',
): Promise<string[] | null> {
  try {
    if (!phrases || phrases.length === 0) {
      return null
    }
    const account = getAccount()
    if (account) {
      const accessToken = await getSilentToken(account)
      if (accessToken) {
        return axios({
          method: 'post',
          timeout: 20000,
          url: `${studioBackendConfig.url}/paraphrase`,
          data: {
            botId,
            phrases,
            numberParaphrases: numberOfParaphrases,
            language,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((response: AxiosResponse<{ paraphrases: string[] }>) => {
            return response.data.paraphrases
          })
          .catch((err: any) => {
            console.error(`[StudioBackend] paraphrase API call failed ${err}`)
            return null
          })
      }
    }
    return null
  } catch (err) {
    console.error(`[StudioBackend] paraphrase API call failed ${err}`)
    return null
  }
}

export type TextSimplificationResult = {
  originalText: string
  simplifiedText: string
}

/**
 * Simplifies given text.
 * @param text
 * @param language
 */
export async function simplifyText(
  botId: string,
  text: string,
  language = 'de',
): Promise<TextSimplificationResult | null> {
  try {
    if (typeof text !== 'string' || text === '') {
      return null
    }
    const account = getAccount()
    if (account) {
      const accessToken = await getSilentToken(account)
      if (accessToken) {
        return axios({
          method: 'post',
          timeout: 20000,
          url: `${studioBackendConfig.url}/simplifyText`,
          data: {
            botId,
            text,
            lang: language,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((response: AxiosResponse<TextSimplificationResult>) => {
            return response.data
          })
          .catch((err: any) => {
            console.error(`[StudioBackend] simplifyText API call failed ${err}`)
            return null
          })
      }
    }
    return null
  } catch (err) {
    console.error(`[StudioBackend] simplifyText API call failed ${err}`)
    return null
  }
}

// ========================================
// ALEPH ALPHA Module (Datamanagement)

type GetMetadataAlephAlphaResponse = {
  metadata: ModuleAlephAlphaRssFeedMetadata | null
}

/**
 * Gets metadata of current datasource of assistant.
 * Retries once, then returns null
 * @param botId
 */
export async function getMetadataAlephAlpha(
  botId: string,
  isRetry = false,
): Promise<GetMetadataAlephAlphaResponse | null> {
  try {
    if (!botId) return null
    const account = getAccount()
    const tokenClaims = account?.idTokenClaims ?? {}
    // const customerId = tokenClaims['extension_CustomerID']
    if (account) {
      const accessToken = await getSilentToken(account)
      return axios({
        method: 'get',
        url: `${studioBackendConfig.url}/alephalpha/getMetadata`,
        params: {
          botId: botId,
        },
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
      }).then((response: AxiosResponse<GetMetadataAlephAlphaResponse>) => {
        if (response.data.metadata?.fetch_timestamp)
          response.data.metadata.fetch_timestamp = new Date(response.data.metadata.fetch_timestamp)
        if (response.data.metadata?.last_update)
          response.data.metadata.last_update = new Date(response.data.metadata.last_update)

        return response.data
      })
    }
    return null
  } catch (err) {
    if (!isRetry) {
      return getMetadataAlephAlpha(botId, true)
    } else {
      console.error('[StudioBackend] getExportKnowledgeDb API call failed', err)
      return null
    }
  }
}

/**
 * Sets new feed for aleph alpha assistant. Backend fetches data and makes it available to the assistant.
 * Returns metadata of new datasource.
 * Retries once, then returns null
 * @param botId
 */
export async function setRssFeedDataAlephAlpha(
  botId: string,
  feedUrl: string,
  contextProperty: string,
  titleProperty: string,
  addSummary: boolean,
  searchThreshold: number,
  isRetry = false,
): Promise<GetMetadataAlephAlphaResponse | null> {
  try {
    if (!botId) return null
    const account = getAccount()
    const tokenClaims = account?.idTokenClaims ?? {}
    // const customerId = tokenClaims['extension_CustomerID']
    if (account) {
      const accessToken = await getSilentToken(account)
      return axios({
        method: 'put',
        url: `${studioBackendConfig.url}/alephalpha/setRssFeed`,
        data: {
          botId,
          rssFeedUrl: feedUrl,
          contextProperty,
          titleProperty,
          addSummary,
          searchThreshold,
        },
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
      }).then((response: AxiosResponse<GetMetadataAlephAlphaResponse>) => {
        if (response.data.metadata?.fetch_timestamp)
          response.data.metadata.fetch_timestamp = new Date(response.data.metadata.fetch_timestamp)
        if (response.data.metadata?.last_update)
          response.data.metadata.last_update = new Date(response.data.metadata.last_update)

        return response.data
      })
    }
    return null
  } catch (err) {
    if (!isRetry) {
      return setRssFeedDataAlephAlpha(botId, feedUrl, contextProperty, titleProperty, addSummary, searchThreshold, true)
    } else {
      console.error('[StudioBackend] getExportKnowledgeDb API call failed', err)
      return null
    }
  }
}
