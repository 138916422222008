import { makeStyles } from 'tss-react/mui'
import { Typography } from '@mui/material'
import CustomContentTooltip from 'components/Tooltips/CustomContentTooltip'
import { useBotContext } from 'hooks/contexts/bot-context'
import React, { useEffect, useState } from 'react'
import { MODULE_TYPE_ALEPHALPHA } from 'utils/constants'
import { AlephAlphaLumiConversationalModule } from '../../../../../@types/BotInformation/types'
import { Chart, FileUploadFileType } from '../../../../../@types/Flowchart/types'
import SelectDropdown, { Option } from '../../../../../components/Dropdown/SelectDropdown'
import VariablesAutosuggestSelect from '../Variables/VariablesAutosuggestSelect'
import { Textfield } from 'components/TextInput/Textfield'
// @mui/material components

const useStyles = makeStyles()((theme) => ({
  container: { overflow: 'auto', height: '100%' },
  inputContainer: { marginBottom: theme.spacing(2) },
  inputInfoText: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  inputInfoIcon: {
    marginLeft: theme.spacing(1),
    height: '20px',
    color: theme.palette.grey[700],
  },
  moduleSelection: {
    marginTop: theme.spacing(4),
  },
  variables: {
    marginTop: theme.spacing(2),
  },
  varSelectionContainer: {
    marginTop: theme.spacing(2),
  },
}))

type FileUploadProps = {
  chart: Chart
  setIsSaveDisabled: (isSaveDisabled: boolean) => void
  setStateCallback: (chart: Chart) => void
}

export default function SFileUpload({
  chart,
  setIsSaveDisabled,
  setStateCallback,
}: FileUploadProps): React.ReactElement {
  const { classes } = useStyles()
  const { bot } = useBotContext()

  const [fileName, setFileName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [messageText, setMessageText] = useState<string>('')
  const [buttonText, setButtonText] = useState<string>('Datei hochladen')
  const [fileTypes, setFileTypes] = useState<FileUploadFileType[]>(['pdf', 'jpg', 'png'])
  // variables select for setting values
  const [varIdFileUrl, setVarIdFileUrl] = useState<string>() // id of variable for storing the download url of the uploaded file

  /**
   * Sets the variable in the node properties and updates the chart
   * @param selectedVarId
   * @param purpose
   */
  function onVariableSelect(newChart: Chart, prevSelectedVarIds: string[], selectedVarIds: string[]): void {
    const selectedVariableId = selectedVarIds.length > 0 ? selectedVarIds[0] : undefined
    const selectedId = newChart.selected?.id
    if (typeof selectedId === 'undefined') return
    const node = newChart.nodes[selectedId]
    if (
      typeof node === 'undefined' ||
      !node.properties.fileUpload ||
      Object.keys(node.properties.fileUpload ?? {}).length === 0
    )
      return

    // set in state
    setVarIdFileUrl(selectedVariableId ?? undefined)

    // set in node
    const fileUploadConfigId = Object.keys(node.properties.fileUpload ?? {})[0]
    node.properties.fileUpload[fileUploadConfigId].fileDownloadUrlVarId = selectedVariableId ?? undefined

    newChart.nodes[selectedId] = node
    setStateCallback(newChart)
  }

  function onFileNameChange(event: React.ChangeEvent<HTMLInputElement>): void {
    if (
      !chart.selected?.id ||
      !chart.nodes[chart.selected.id].properties.fileUpload ||
      Object.keys(chart.nodes[chart.selected.id].properties.fileUpload ?? {}).length === 0
    )
      return
    const node = chart.nodes[chart.selected.id]
    if (!node.properties.fileUpload) return
    const fileUploadConfigId = Object.keys(node.properties.fileUpload ?? {})[0]

    setFileName(event.target.value) // state
    node.properties.fileUpload[fileUploadConfigId].fileName = event.target.value
    node.properties.text = `Datei Upload: ${event.target.value}`
    chart.nodes[chart.selected.id] = node
    setStateCallback(chart)
  }

  function onFileDescriptionChange(event: React.ChangeEvent<HTMLInputElement>): void {
    if (
      !chart.selected?.id ||
      !chart.nodes[chart.selected.id].properties.fileUpload ||
      Object.keys(chart.nodes[chart.selected.id].properties.fileUpload ?? {}).length === 0
    )
      return
    const node = chart.nodes[chart.selected.id]
    if (!node.properties.fileUpload) return
    const fileUploadConfigId = Object.keys(node.properties.fileUpload ?? {})[0]

    setDescription(event.target.value) // state
    node.properties.fileUpload[fileUploadConfigId].fileDescription = event.target.value
    chart.nodes[chart.selected.id] = node
    setStateCallback(chart)
  }

  function onMessageTextChange(event: React.ChangeEvent<HTMLInputElement>): void {
    if (
      !chart.selected?.id ||
      !chart.nodes[chart.selected.id].properties.fileUpload ||
      Object.keys(chart.nodes[chart.selected.id].properties.fileUpload ?? {}).length === 0
    )
      return
    const node = chart.nodes[chart.selected.id]
    if (!node.properties.fileUpload) return
    const fileUploadConfigId = Object.keys(node.properties.fileUpload ?? {})[0]

    setMessageText(event.target.value) // state
    node.properties.fileUpload[fileUploadConfigId].messageText = event.target.value
    chart.nodes[chart.selected.id] = node
    setStateCallback(chart)
  }

  function onButtonTextChange(event: React.ChangeEvent<HTMLInputElement>): void {
    if (
      !chart.selected?.id ||
      !chart.nodes[chart.selected.id].properties.fileUpload ||
      Object.keys(chart.nodes[chart.selected.id].properties.fileUpload ?? {}).length === 0
    )
      return
    const node = chart.nodes[chart.selected.id]
    if (!node.properties.fileUpload) return
    const fileUploadConfigId = Object.keys(node.properties.fileUpload ?? {})[0]

    setButtonText(event.target.value) // state
    node.properties.fileUpload[fileUploadConfigId].buttonText = event.target.value
    chart.nodes[chart.selected.id] = node
    setStateCallback(chart)
  }

  useEffect(
    function () {
      const selectedId = chart.selected?.id
      if (typeof selectedId === 'undefined') return
      // get already selected Event
      const node = chart.nodes[selectedId]
      if (!node.properties.fileUpload || Object.keys(node.properties.fileUpload).length === 0) return

      const fileUploadConfigId = Object.keys(node.properties.fileUpload)[0]

      setFileName(node.properties.fileUpload[fileUploadConfigId].fileName)
      setDescription(node.properties.fileUpload[fileUploadConfigId].fileDescription)
      setFileTypes(node.properties.fileUpload[fileUploadConfigId].fileTypes)
      setVarIdFileUrl(node.properties.fileUpload[fileUploadConfigId].fileDownloadUrlVarId)
      setMessageText(node.properties.fileUpload[fileUploadConfigId].messageText)
      setButtonText(node.properties.fileUpload[fileUploadConfigId].buttonText)
    },
    [chart, bot],
  )

  useEffect(
    function () {
      if (fileName && fileTypes && varIdFileUrl) {
        setIsSaveDisabled(false)
      } else {
        setIsSaveDisabled(true)
      }
    },
    [fileName, fileTypes, varIdFileUrl],
  )

  return (
    <div className={classes.container}>
      <div>
        <Typography>
          Der <i>Datei Upload</i> Block startet den Datei Upload Vorgang für den User im Chatinterface. Hierbei wird der
          hier konfigurierte Name der Datei und die Beschreibung zur Unterstützung für den User angezeigt. Die vom User
          hochgeladene Datei wird gespeichert und per Download URL für die weitere Verwendung innerhalb der Konversation
          bereitgestellt. So kann die hochgelandene Datei beispielsweise per E-Mail versendet werden.
        </Typography>
      </div>
      <div className={classes.moduleSelection}>
        <div className={classes.inputContainer}>
          <div className={classes.inputInfoText}>
            <Typography>Dateiname</Typography>
            <CustomContentTooltip
              content={
                <Typography>Bitte geben Sie den Namen der Datei an, die im Chat hochgeladen werden soll.</Typography>
              }
              elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
            />
          </div>
          <Textfield fullWidth value={fileName} onChange={onFileNameChange} placeholder='Dateiname' />
        </div>
        <div className={classes.inputContainer}>
          <div className={classes.inputInfoText}>
            <Typography>Dateibeschreibung</Typography>
            <CustomContentTooltip
              content={
                <Typography>
                  Bitte geben Sie der hochzuladenden Datei eine Beschreibung. Die Beschreibung hilft Usern die korrekte
                  Datei zu finden.
                </Typography>
              }
              elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
            />
          </div>
          <Textfield fullWidth value={description} onChange={onFileDescriptionChange} placeholder='Dateibeschreibung' />
        </div>

        <div className={classes.inputContainer}>
          <div className={classes.inputInfoText}>
            <Typography>Nachrichtentext</Typography>
            <CustomContentTooltip
              content={
                <Typography>
                  Bitte definieren Sie den Inhalt der Nachricht, die den User zum Hochladen des Dokuments auffordert.
                </Typography>
              }
              elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
            />
          </div>
          <Textfield fullWidth value={messageText} onChange={onMessageTextChange} placeholder='Nachrichteninhalt' />
        </div>

        <div className={classes.inputContainer}>
          <div className={classes.inputInfoText}>
            <Typography>Hochladenbutton Text</Typography>
            <CustomContentTooltip
              content={
                <Typography>Bitte definieren Sie den Text des Buttons, der den Dateiupload initiiert.</Typography>
              }
              elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
            />
          </div>
          <Textfield fullWidth value={buttonText} onChange={onButtonTextChange} placeholder='Datei hochladen' />
        </div>
      </div>

      <div className={classes.variables}>
        <div className={classes.inputContainer}>
          <div className={classes.inputInfoText}>
            <Typography>Variable zum Speichern der Download URL der hochgeladenen Datei</Typography>
            <CustomContentTooltip
              content={
                <Typography>
                  Bite wählen sie eine Variable, in der die Download URL der hochgeladenen Datei gespeichert werden
                  soll. Die URL kann später dazu genutzt werden, die Datei beispielweise per E-Mail zu versenden.
                </Typography>
              }
              elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
            />
          </div>
          <VariablesAutosuggestSelect
            chart={chart}
            onChange={onVariableSelect}
            usageType='set'
            isMulti={false}
            isCreatable
            isClearable
            selectedVariableIds={varIdFileUrl ? [varIdFileUrl] : []}
          />
        </div>
      </div>
    </div>
  )
}
