import React, { useState, useEffect } from 'react'

import { Button } from 'components/Buttons/'

import { Chart, Node } from '../../../@types/Flowchart/types'
import { makeStyles } from 'tss-react/mui'
import { Grid } from '@mui/material'
import NodeContent from '../Editor/Nodes/NodeContent'
import { TranslationFile } from '../../../@types/Translations/types'

const useStyles = makeStyles()((theme) => ({
  searchResultContainer: {
    // textAlign: 'center',
  },
  outerNode: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '7px',
  },
  loadMoreButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: '100%',
    textAlign: 'center',
  },
}))

const useStylesSelectedNode = makeStyles()((theme) => ({
  outerNode: {
    border: `4px solid ${theme.palette.primary.main}`,
    borderRadius: '7px',
  },
}))

export type NodeSearchProps = {
  chart: Chart
  translationFile: TranslationFile
  nodes: Node[]
  selectedNodeId?: string // id of node which was selected. Used to display node as selected
  onNodeClick: (nodeId: string) => void
  DEFAULT_NUMBER_OF_NODES?: number
}

export default function NodeSearchResult({
  chart,
  translationFile,
  nodes,
  selectedNodeId,
  onNodeClick,
  DEFAULT_NUMBER_OF_NODES = 18,
}: NodeSearchProps): React.ReactElement {
  const { classes } = useStyles()
  const { classes: classesSelectedNode } = useStylesSelectedNode() // special styles for selected node
  const [nodeDisplayCount, setNodeDisplayCount] = useState<number>(DEFAULT_NUMBER_OF_NODES)

  useEffect(
    function () {
      // reset node display count if nodes change
      setNodeDisplayCount(DEFAULT_NUMBER_OF_NODES)
    },
    [nodes],
  )

  return (
    <div className={classes.searchResultContainer}>
      <Grid container spacing={3}>
        {nodes.slice(0, nodeDisplayCount).map((node) => (
          <Grid item key={node.id} xs={12} md={6} lg={4}>
            <div onClick={(): void => onNodeClick(node.id)}>
              <NodeContent
                chart={chart}
                translationFile={translationFile}
                node={node}
                onResize={(): void => {
                  // do nothing here; this is only used in the Flowdesigner
                }}
                classes={selectedNodeId && selectedNodeId === node.id ? classesSelectedNode : classes}
              />
            </div>
          </Grid>
        ))}
      </Grid>
      {nodes.length > nodeDisplayCount && (
        <div className={classes.loadMoreButton}>
          <Button
            icon='arrow-down-s-line'
            iconType='remix'
            onClick={(): void => setNodeDisplayCount(nodeDisplayCount + DEFAULT_NUMBER_OF_NODES)}
          >
            Mehr anzeigen
          </Button>
        </div>
      )}
    </div>
  )
}
