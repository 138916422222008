import React, { useEffect, useState } from 'react'

// Material Ui
import { makeStyles } from 'tss-react/mui'

import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
// Custom Components
import SelectDropdown, { Option, ActionMeta } from '../../components/Dropdown/SelectDropdown'
import AnalyticsCard from './AnalyticsCard'
import SelectSpecificDayDialog from './SelectSpecificDayDialog'
import SelectDateRangeDialog from './SelectDateRangeDialog'
import CustomizedTooltip from 'components/Tooltips/CustomContentTooltip'
// Types
import {
  TimespanOptionValue,
  TimespanOptions,
  ChartName,
  Granularity,
  Scope,
  Timespan,
  ChartType,
  Stat,
} from '../../@types/Analytics/types'
import { addDaysToDate, subtractDaysFromDate } from 'utils/dateUtils'
import { cloneDeep, isEqual } from 'lodash'
import { Grid, LinearProgress } from '@mui/material'

type StyleProps = {
  isLoading: boolean
}

const useStyles = makeStyles<StyleProps>()((theme, props) => ({
  container: {
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: props.isLoading ? '12px' : '0',
  },
  subStatsContainer: {
    flexGrow: 1,
  },
  extraButton: {
    marginRight: theme.spacing(2),
  },
  actionContainer: {
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'row',
  },
  linearProgressBarContainer: {
    display: 'flex',
    height: '100%',
    padding: theme.spacing(2),
  },
  linearProgressBar: {
    width: '80%',
    margin: 'auto',
  },
}))

type StatComponentProps = Stat & {
  statSize: 'h2' | 'h3'
}

function StatComponent({
  title: titleProps,
  titleValue: titleValueProps,
  titleValueChar: titleValueCharProps,
  switchTitleValueAndTitleValueChar: switchTitleValueAndTitleValueCharProps,
  statSize: statSizeProps,
  scope,
}: StatComponentProps): React.ReactElement {
  const [title, setTitle] = useState<string>(titleProps)
  const [titleValue, setTitleValue] = useState<string>(titleValueProps)
  const [titleValueChar, setTitleValueChar] = useState<string>(titleValueCharProps)
  const [switchTitleValueAndTitleValueChar, setSwitchTitleValueAndTitleValueChar] = useState<boolean>(
    !!switchTitleValueAndTitleValueCharProps,
  )
  const [statSize, setStatSize] = useState<'h2' | 'h3'>(statSizeProps)

  useEffect(
    function () {
      setTitle(titleProps)
      setTitleValue(titleValueProps)
      setTitleValueChar(titleValueCharProps)
      setSwitchTitleValueAndTitleValueChar(!!switchTitleValueAndTitleValueCharProps)
      setStatSize(statSizeProps)
    },
    [titleProps, titleValueProps, titleValueCharProps, switchTitleValueAndTitleValueCharProps, statSizeProps],
  )

  return (
    <div>
      <Typography variant='overline'>{title ?? ''}</Typography>
      <CustomizedTooltip
        placement='top'
        content={null}
        elements={
          <Typography variant={statSize}>
            {switchTitleValueAndTitleValueChar ? (
              <>
                {titleValue ?? ''} {titleValueChar}
              </>
            ) : (
              <>
                {titleValueChar} {titleValue ?? ''}
              </>
            )}
          </Typography>
        }
      />
    </div>
  )
}

interface AnalyticsStatBaseProps {
  id: ChartName
  shouldReload?: boolean
  title: string
  titleStat: Stat | null
  subStats?: Stat[]
  isLoading: boolean
  chartType: 'stat' // must be 'stat'
  onLoadDataCallback: (
    scopes: Scope[],
    timespan: Timespan,
    granularity: Granularity,
    chartType: ChartType,
    chartName: ChartName,
  ) => void
  onCustomTimespanSelection: (fromDate: Date, toDate: Date) => void
  scopes: Scope[] // all scopes that can be viewed in chart
  height?: string
  customTimespanSuggestion?: {
    fromDate: Date
    toDate: Date
  }
}

export default React.memo(function AnalyticsStat({
  id,
  shouldReload,
  title,
  titleStat: titleStatProps,
  subStats: subStatsProps,
  isLoading: isLoadingProps,
  chartType,
  onLoadDataCallback,
  onCustomTimespanSelection,
  scopes,
  height,
  customTimespanSuggestion,
}: AnalyticsStatBaseProps): React.ReactElement {
  const [timespanOptions, setTimespanOptions] = useState<TimespanOptions>([
    { label: 'Spezifischer Tag', value: 'specific_day' },
    { label: 'Letzte 24h', value: 'last_24h' },
    { label: 'Letzte 7 Tage', value: 'last_7d' },
    { label: 'Letzte 30 Tage', value: 'last_30d' },
    { label: 'Letzte 90 Tage', value: 'last_90d' },
    { label: 'Seit Jahresbeginn', value: 'since_start_of_year' },
    { label: 'Letztes Jahr', value: 'last_year' },
    { label: 'Gesamter Zeitraum', value: 'all_time' },
    { label: 'Benutzerdefiniert', selectedLabel: 'Benutzerdefiniert', value: 'custom' },
  ])

  // state - data
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [titleStat, setTitleStat] = useState<Stat | null>(cloneDeep(titleStatProps))
  const [subStats, setSubStats] = useState<Stat[] | undefined>(cloneDeep(subStatsProps))

  // state - visual
  const [displayDialog, setDisplayDialog] = useState<'specificDay' | 'customRange'>()
  const [prevSelectedValue, setPrevSelectedValue] = useState<TimespanOptionValue>('last_7d') // holds previously selected value for resetting on dialog close
  const [selectedValue, setSelectedValue] = useState<TimespanOptionValue>('last_7d') // holds selected dialog value
  const [selectedDate, setSelectedDate] = useState<Date>() // selected date, if specific day is selected
  const [selectedDateRange, setSelectedDateRange] = useState<{ from: Date; to: Date }>()

  const { classes } = useStyles({ isLoading })
  const theme = useTheme()

  /**
   * Triggers data retrieval from api
   */
  function onLoadData(timespan: Timespan): void {
    // NOTE: granularity is not needed for the stats, we simply set it to day because the callback function expects it
    // this is ugly and shoud be improved in the future
    onLoadDataCallback(scopes, timespan, 'day', chartType, id)
  }

  /**
   * Calculates timespan and triggers onLoadData.
   * Only receives a date object if timespanOptionValue === 'specific_day' or 'custom'
   * If 'custom', date is startDate and toDate is the end date of the range.
   *
   * @param timespanOptionValue
   * @param date (only if specific day or custom range)
   * @param toDate (only if custom range)
   */
  function calculateTimespanAndTriggerLoad(timespanOptionValue: TimespanOptionValue, date?: Date, toDate?: Date): void {
    let timespan
    switch (timespanOptionValue) {
      case 'specific_day': {
        if (typeof date === 'undefined') date = new Date() // if date is undefined, set to today - this should never be the case!
        date.setHours(0, 0, 0, 0)
        const endDate = addDaysToDate(date, 1)
        timespan = { from: date, to: endDate }
        break
      }
      case 'custom': {
        // custom date range
        if (typeof date === 'undefined') date = subtractDaysFromDate(new Date(), 7)
        if (typeof toDate === 'undefined') toDate = new Date()
        date.setHours(0, 0, 0, 0)
        toDate.setHours(23, 59, 59, 999)
        timespan = { from: date, to: toDate }
        onCustomTimespanSelection(date, toDate)
        break
      }
      case 'last_24h': {
        const endDate = new Date()
        const startDate = subtractDaysFromDate(endDate, 1)
        timespan = { from: startDate, to: endDate }
        break
      }
      case 'all_time': {
        const endDate = new Date()
        const startDate = new Date(2019, 0, 1, 0, 0)
        timespan = { from: startDate, to: endDate }
        break
      }
      case 'since_start_of_year': {
        const endDate = new Date()
        const startDate = new Date()
        startDate.setMonth(0, 1)
        startDate.setHours(0, 0, 0, 0)
        timespan = { from: startDate, to: endDate }
        break
      }
      case 'last_7d':
      case 'last_30d':
      case 'last_90d':
      case 'last_year': {
        // set end date to today 12am
        const endDate = new Date()
        endDate.setHours(23, 59, 59, 999)

        const daysFromDate =
          timespanOptionValue === 'last_7d'
            ? 7
            : timespanOptionValue === 'last_30d'
            ? 30
            : timespanOptionValue === 'last_90d'
            ? 90
            : 365

        const startDate = subtractDaysFromDate(endDate, daysFromDate)
        startDate.setHours(0, 0, 0, 0)
        timespan = { from: startDate, to: endDate }
        break
      }
    }

    if (timespan) {
      onLoadData(timespan)
    }
  }

  /**
   * Handles timespan change.
   * Calculates new timespan object (from and to dates) and also specifies granularity.
   * @param value
   */
  function onTimespanDropdownSelect(value: TimespanOptionValue): void {
    if (value === 'specific_day') setDisplayDialog('specificDay')
    else if (value === 'custom') setDisplayDialog('customRange')
    else calculateTimespanAndTriggerLoad(value)

    setPrevSelectedValue(selectedValue)
    setSelectedValue(value)
  }

  /**
   * Handles dialog close event.
   * Resets selected value to previously selected one
   */
  function onDialogClose(): void {
    setSelectedValue(prevSelectedValue)
    setDisplayDialog(undefined)
  }

  /**
   * Handles date selection in select specific date dialog.
   * Builds timespan object and calls onLoadData
   * @param date selected date
   * @param timespanOptionValue selected timespan value of dropdown, used to determine how to build timespan object
   */
  function onSelectDate(date: Date, timespanOptionValue: TimespanOptionValue): void {
    calculateTimespanAndTriggerLoad(timespanOptionValue, date)
    setDisplayDialog(undefined)
    setSelectedDate(date)
  }

  /**
   * Handles custom date range selection in select date range dialog.
   * Builds timespan object and calls onLoadData
   * @param from
   * @param to
   * @param timespanOptionValue selected timespan value of dropdown, used to determine how to build timespan object
   */
  function onSelectDateRange(from: Date, to: Date, timespanOptionValue: TimespanOptionValue): void {
    // trigger load
    calculateTimespanAndTriggerLoad(timespanOptionValue, from, to)
    // update dropdown options (change selectedLabel to selected range)
    const fromDate = from.getDate()
    const fromMonth = from.getMonth() + 1
    const toDate = to.getDate()
    const toMonth = to.getMonth() + 1
    const sFromDate = fromDate < 10 ? `0${fromDate}` : `${fromDate}`
    const sFromMonth = fromMonth < 10 ? `0${fromMonth}` : `${fromMonth}`
    const sToDate = toDate < 10 ? `0${toDate}` : `${toDate}`
    const sToMonth = toMonth < 10 ? `0${toMonth}` : `${toMonth}`

    const selectedLabel = `${sFromDate}.${sFromMonth}. - ${sToDate}.${sToMonth}.`

    const newTimespanOptions = [...timespanOptions]
    const optionIndex = newTimespanOptions.findIndex((option) => option.value === timespanOptionValue)
    if (optionIndex > -1) newTimespanOptions[optionIndex].selectedLabel = selectedLabel
    setTimespanOptions(newTimespanOptions)

    // set selected date range
    setDisplayDialog(undefined)
    setSelectedDateRange({ from, to })
  }

  useEffect(
    // trigger reload with the same timespan and detail level
    // calculates new timespan to take most recent events into consideration and triggers reload
    function () {
      if (shouldReload) {
        calculateTimespanAndTriggerLoad(selectedValue, selectedDate)
      }
    },
    [shouldReload],
  )

  useEffect(
    function () {
      if (!isEqual(titleStat, titleStatProps)) {
        setTitleStat(cloneDeep(titleStatProps))
      }
      if (!isEqual(subStats, subStatsProps)) {
        setSubStats(cloneDeep(subStatsProps))
      }
    },
    [titleStatProps, subStatsProps],
  )

  useEffect(
    function () {
      setIsLoading(isLoadingProps)
    },
    [isLoadingProps],
  )

  return (
    <>
      <AnalyticsCard height={height}>
        <div className={classes.container}>
          <div className={classes.header}>
            {isLoading || !titleStat ? (
              <div>
                <Typography variant='overline'>{title}</Typography>
              </div>
            ) : (
              <StatComponent
                title={titleStat.title ?? title ?? ''}
                titleValue={titleStat.titleValue}
                titleValueChar={titleStat.titleValueChar}
                switchTitleValueAndTitleValueChar={titleStat.switchTitleValueAndTitleValueChar}
                statSize={'h2'}
                scope={titleStat.scope}
              />
            )}

            <div className={classes.actionContainer}>
              <SelectDropdown
                onChange={(newValue: Option, action: ActionMeta<Option>): void => {
                  onTimespanDropdownSelect(newValue.value as TimespanOptionValue)
                }}
                selected={selectedValue}
                options={timespanOptions}
                maxDisplayOptions={5}
                width={'175px'}
                height={'56px'}
              />
            </div>
          </div>
          <div className={classes.subStatsContainer}>
            {isLoading ? (
              <div className={classes.linearProgressBarContainer}>
                <LinearProgress className={classes.linearProgressBar} />
              </div>
            ) : (
              <Grid container spacing={2} style={{ marginTop: theme.spacing(2) }}>
                {(subStats ?? []).map((subStat) => {
                  return (
                    <Grid item key={`${id}-${subStat.title}`} sm={6}>
                      <StatComponent
                        title={subStat.title}
                        titleValue={subStat.titleValue}
                        titleValueChar={subStat.titleValueChar}
                        switchTitleValueAndTitleValueChar={subStat.switchTitleValueAndTitleValueChar}
                        statSize={'h3'}
                        scope={subStat.scope}
                      />
                    </Grid>
                  )
                })}
              </Grid>
            )}
          </div>
        </div>
      </AnalyticsCard>
      {displayDialog === 'specificDay' && (
        <SelectSpecificDayDialog
          selectedDate={selectedDate}
          onClose={onDialogClose}
          onSelectDate={onSelectDate}
          timespanOptionValue={selectedValue || 'last_7d'}
        />
      )}
      {displayDialog === 'customRange' && (
        <SelectDateRangeDialog
          fromDate={selectedDateRange?.from ?? customTimespanSuggestion?.fromDate}
          toDate={selectedDateRange?.to ?? customTimespanSuggestion?.toDate}
          onClose={onDialogClose}
          onSelectDateRange={onSelectDateRange}
          timespanOptionValue={selectedValue || 'custom'}
        />
      )}
    </>
  )
}, isEqual)
