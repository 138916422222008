import React, { useState, useEffect } from 'react'

// @mui/material components
import { makeStyles } from 'tss-react/mui'

import { Tabs, Tab, Typography } from '@mui/material'
import Dialog from './Dialog'

const useStyles = makeStyles()({
  dialogTabs: {
    marginBottom: '15px',
  },
  smallCopyButton: {
    cursor: 'pointer',
    fontSize: '0.8rem',
    position: 'relative',
    top: '2px',
  },
  tabPanel: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 66px)',
  },
  dialogTabContent: {
    height: 'calc(100% - 66px)',
  },
})

type TabPanelProps = {
  value: number
  index: number
  content: React.ReactElement
}

function TabPanel(props: TabPanelProps): React.ReactElement {
  const { value, index, content, ...other } = props
  const { classes } = useStyles()

  return (
    // <Typography
    //   component='div'
    //   role='tabpanel'
    //   hidden={value !== index}
    //   id={`nav-tabpanel-${index}`}
    //   aria-labelledby={`nav-tab-${index}`}
    //   {...other}
    // >
    //   {value === index ? content : null}
    // </Typography>
    <>{value === index && <div className={classes.dialogTabContent}>{content}</div>}</>
  )
}

type TabDialogProps = {
  id: string
  nodeId: string
  tabLabels: string[]
  tabContents: React.ReactElement[]
  primaryActionButton?: React.ReactNode
  onSecondaryActionClick?: () => void
  secondaryActionText?: string
  isSecondaryActionDisabled?: boolean
  dialogTitle?: string
  closable?: boolean
  handleClose: () => void
}

export default function TabDialog(props: TabDialogProps): React.ReactElement {
  const { classes } = useStyles()
  const [tabValue, setTabValue] = useState(0)
  const [tabLabels, setTabLabels] = useState(props.tabLabels)
  const [tabContents, setTabContents] = useState(props.tabContents)
  const [copyState, setCopyState] = useState(false)

  useEffect(
    function () {
      setTabLabels(props.tabLabels)
    },
    [props.tabLabels],
  )

  useEffect(
    function () {
      setTabContents(props.tabContents)
    },
    [props.tabContents],
  )

  function handleTabChange(event: React.SyntheticEvent, newValue: any): void {
    setTabValue(newValue)
  }

  /**
   * Tab Dialog for nodes in the flow designer
   */
  return (
    <>
      <Dialog
        id={props.id}
        size='large'
        open={true}
        onClose={props.handleClose}
        aria-labelledby='tabdialog'
        primaryActionButton={props.primaryActionButton}
        secondaryActionText={props.secondaryActionText}
        onSecondaryActionClick={props.onSecondaryActionClick}
        isSecondaryActionDisabled={props.isSecondaryActionDisabled}
        title={
          <>
            <Typography variant='h4'>{props.dialogTitle}</Typography>
            {props.nodeId && (
              <Typography variant='caption'>
                {props.nodeId}{' '}
                <i
                  className={`${copyState ? 'ri-check-line' : 'ri-clipboard-line'} ${classes.smallCopyButton}`}
                  onClick={(): void => {
                    // Copy nodeId to clipboard
                    navigator.clipboard.writeText(props.nodeId)
                    setCopyState(true)
                    setTimeout(() => {
                      setCopyState(false)
                    }, 2000)
                  }}
                />
              </Typography>
            )}
          </>
        }
        closable={props.closable}
      >
        <>
          <Tabs
            value={tabValue}
            className={classes.dialogTabs}
            onChange={handleTabChange}
            indicatorColor='primary'
            textColor='primary'
            centered
          >
            {tabLabels.map((label, index) => {
              return <Tab label={label} key={`tab-${index}-${label}`} />
            })}
          </Tabs>
          {tabContents.map((tabContent, index) => {
            return <TabPanel value={tabValue} index={index} content={tabContent} key={`tab-content-${index}`} />
          })}
        </>
      </Dialog>
    </>
  )
}
