export enum DictionaryType {
  'TECHNICAL_TERM' = 'technicalTerm',
}

export type DictionaryEntry = {
  dictionaryId: string // id of dictionary
  dictionaryTermId: string // id of dictionary term (specific entry)
  term: string
  type: DictionaryType
  primaryLanguage: string // language code of primary language (i.e. language of term property)
  entries: {
    // mapping of language specific term translation / description etc. (depends on type of dictioanry)
    [langCode: string]: string
  }
  languagesFlaggedForDeletion?: string[] | null // holds languages that have been deleted, but changes have not yet been published
}

export type Dictionary = {
  [dictionaryTermId: string]: DictionaryEntry
}
