import React, { useEffect, useState } from 'react'
import { ArrowDropDown, ArrowDropUp, HelpOutline } from '@mui/icons-material'

import { Dialog } from 'components/Dialogs'
import { Button } from 'components/Buttons'
import { makeStyles } from 'tss-react/mui'
import { Checkbox, Collapse, Grid, IconButton, Typography, useTheme } from '@mui/material'
import { Textfield } from 'components/TextInput/Textfield'

import { isValidUrl } from 'utils/stringUtils'
import CustomizedTooltip from 'components/Tooltips/CustomContentTooltip'

const useStyles = makeStyles()((theme) => ({
  root: {},
  text: {
    marginTop: 'auto',
    marginBottom: 'auto',
    display: 'flex',
  },
  helpIcon: {
    marginTop: 'auto',
    marginBottom: 'auto',
    color: theme.palette.grey[800],
    marginLeft: theme.spacing(1),
  },
}))

type UpdateDataDialogProps = {
  feedUrl?: string
  contextProperty?: string
  titleProperty?: string
  addSummary?: boolean
  searchThreshold?: number
  onClose: () => void
  onSubmit: (
    feedUrl: string,
    contextProperty: string,
    titleProperty: string,
    createSummary: boolean,
    searchThreshold: number,
  ) => void
}

export default function UpdateDataDialog({
  feedUrl: _feedUrl,
  contextProperty: _contextProperty,
  titleProperty: _titleProperty,
  addSummary: _addSummary,
  searchThreshold: _searchThreshold,
  onClose,
  onSubmit,
}: UpdateDataDialogProps): React.ReactElement {
  const { classes } = useStyles()
  const theme = useTheme()
  const [showAdvanced, setShowAdvanced] = useState<boolean>(false)
  const [feedUrl, setFeedUrl] = useState<string>(_feedUrl || '')
  const [feedError, setFeedError] = useState<string>()
  const [contextProperty, setContextProperty] = useState<string>(_contextProperty || 'description')
  const [titleProperty, setTitleProperty] = useState<string>(_titleProperty || 'title')
  const [createSummary, setCreateSummary] = useState<boolean>(!!_addSummary)
  const [searchThreshold, setSearchThreshold] = useState<number>(_searchThreshold ?? 0.5)

  function onSubmitHandler(): void {
    onSubmit(feedUrl, contextProperty, titleProperty, createSummary, searchThreshold)
  }

  useEffect(
    function () {
      if ((_feedUrl || feedUrl) && !isValidUrl(feedUrl)) {
        setFeedError('Invalid URL')
      } else {
        setFeedError(undefined)
      }
    },
    [_feedUrl, feedUrl],
  )

  return (
    <Dialog
      id='update-rss-feed-data-dialog'
      size='medium-large'
      open
      closable
      disableBackdropClick
      onClose={onClose}
      title={'Update RSS feed'}
      primaryActionButton={
        <Button disabled={!feedUrl || !contextProperty || !titleProperty} type='success' onClick={onSubmitHandler}>
          Update
        </Button>
      }
    >
      <div className={classes.root}>
        {/* feed url */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              Add the URL of a RSS feed to give the assistant access to the content of the feed. You can also update the
              existing data with the current feed.
            </Typography>
          </Grid>
          <Grid item xs={3} className={classes.text}>
            <Typography fontWeight='bolder'>RSS-Feed URL:</Typography>
          </Grid>
          <Grid item xs={9} className={classes.text}>
            <Textfield
              fullWidth
              value={feedUrl}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => setFeedUrl(event.target.value)}
              error={!!feedError}
              helperText={feedError}
              placeholder='https://www.example.com/feed'
            />
          </Grid>

          <Grid item xs={12}>
            <div style={{ display: 'flex' }}>
              <CustomizedTooltip
                placement='top'
                disableInteractive
                content={
                  <Typography>
                    {showAdvanced ? 'Hide advanced config options' : 'Show advanced config options'}
                  </Typography>
                }
                elements={
                  <IconButton onClick={(): void => setShowAdvanced(!showAdvanced)}>
                    {showAdvanced ? <ArrowDropUp /> : <ArrowDropDown />}
                  </IconButton>
                }
              />

              <Typography style={{ marginTop: 'auto', marginBottom: 'auto' }}>Advanced config</Typography>
            </div>
            <Collapse in={showAdvanced}>
              <div style={{ marginLeft: theme.spacing(2) }}>
                <Grid container spacing={2} justifyContent='flex-start'>
                  <Grid item xs={3} className={classes.text}>
                    <Typography className={classes.text} fontWeight='bolder'>
                      Add summary:
                    </Typography>
                  </Grid>

                  <Grid item xs={9} className={classes.text}>
                    {/* <FormGroup>
                      <FormControlLabel
                        control={ */}
                    <Checkbox
                      checked={createSummary}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void =>
                        setCreateSummary(checked)
                      }
                      style={{ color: theme.palette.primary.main }}
                    />
                    {/* }
                        label='Montag'
                        labelPlacement='start'
                      />
                    </FormGroup> */}
                  </Grid>

                  {/* search threshold */}
                  <Grid item xs={3} className={classes.text}>
                    <Typography className={classes.text} fontWeight='bolder'>
                      Search Treshold:
                    </Typography>
                  </Grid>
                  <Grid item xs={9} className={classes.text}>
                    <Textfield
                      fullWidth
                      value={searchThreshold}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                        const threshold = parseFloat(event.target.value)
                        setSearchThreshold(threshold)
                      }}
                      type='number'
                      placeholder='0.5'
                      InputProps={{ inputProps: { min: 0, max: 1, step: 0.05 } }}
                    />
                    <CustomizedTooltip
                      placement='top'
                      disableInteractive
                      content={
                        <Typography>
                          Specifies the threshold that should be used when searching for answer candidates.
                        </Typography>
                      }
                      elements={<HelpOutline className={classes.helpIcon} />}
                    />
                  </Grid>

                  {/* context property */}
                  <Grid item xs={3} className={classes.text}>
                    <Typography className={classes.text} fontWeight='bolder'>
                      Content property:
                    </Typography>
                  </Grid>
                  <Grid item xs={9} className={classes.text}>
                    <Textfield
                      fullWidth
                      value={contextProperty}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                        setContextProperty(event.target.value)
                      }
                    />
                    <CustomizedTooltip
                      placement='top'
                      disableInteractive
                      content={
                        <Typography>
                          Specifies a custom property in the RSS feed entires that should be used to generate the
                          answers.
                        </Typography>
                      }
                      elements={<HelpOutline className={classes.helpIcon} />}
                    />
                  </Grid>
                  {/* title property */}
                  <Grid item xs={3} className={classes.text}>
                    <Typography className={classes.text} fontWeight='bolder'>
                      Title property:
                    </Typography>
                  </Grid>
                  <Grid item xs={9} className={classes.text}>
                    <Textfield
                      fullWidth
                      value={titleProperty}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                        setTitleProperty(event.target.value)
                      }
                    />
                    <CustomizedTooltip
                      placement='top'
                      disableInteractive
                      content={
                        <Typography>
                          Specified a custom property in the RSS feed that should be used for the user suggestions.
                        </Typography>
                      }
                      elements={<HelpOutline className={classes.helpIcon} />}
                    />
                  </Grid>
                </Grid>
              </div>
            </Collapse>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  )
}
