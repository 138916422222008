import React, { useState, useEffect, useReducer, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Fuse from 'fuse.js'

// Material UI
import { makeStyles } from 'tss-react/mui'

import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import Grid from '@mui/material/Grid'
// Custom components
import ContentPage, { ContentPageHeader } from '../../components/Page/ContentPage'
import { Searchfield } from '../../components/TextInput/Searchfield'
import BotCard from '../../components/Cards/BotCard'
import IconCard from '../../components/Cards/IconCard'
import Button from '../../components/Buttons/Button'
import Footer from '../../components/Footer/Footer'
import { Can, AbilityContext } from '../../components/Can/Can'
import CircularLoading from '../../components/Loading/CircularLoading'
import ErrorComponent from '../../components/Error/Error'
// Hooks
import { useErrorContext } from '../../hooks/contexts/errorContext'
import { useBotContext } from '../../hooks/contexts/bot-context'
// Auth
import { useMsal } from '@azure/msal-react'
// Assets
import logo from '../../assets/img/logos/convaise-logo-v8.svg'
// Config
import {
  ROUTE_HELP,
  ROUTE_BOTS,
  ROUTE_BOTID_KNOWLEDGE,
  ROUTE_BOTID_ANALYTICS,
  ROUTE_CREATEBOT,
  MODULE_TYPE_NLU,
  MODULE_TYPE_ALEPHALPHA,
  MODULE_TYPE_RAG,
} from '../../utils/constants'
// APIs
import { getBots } from '../../api/StudioBackend'
// Types
import { BotInfos } from '../../@types/BotInformation/types'
// Utils
import { stableSort } from '../../utils/utils'
import { AuthorizationEntry } from '../../@types/Authorization/types'

// Component Styles
const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  logo: {
    maxHeight: '48px',
    height: 'auto',
    width: 'auto',
  },
  botGrid: {
    marginBottom: theme.spacing(6),
    flex: '1 0 auto',
  },
  loading: {
    flex: '1 0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const fuseOptions = {
  shouldSort: true,
  threshold: 0.4,
  minMatchCharLength: 1,
  keys: ['botInfos.id', 'botInfos.name', 'botInfos.description'],
}

// Amount of displayed bots
const initialBotAmount = 12
const amountReducer = (state, action): number => {
  switch (action) {
    case 'increment':
      return state + 6 // increment in 6 bots steps
    default:
      throw new Error('[Agents] amountReducer: Unexpected action')
  }
}

function AgentsPage(): React.ReactElement {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)
  const [menuWidth, setMenuWidth] = useState(0)
  const [bots, setBots] = useState<{ botInfos: BotInfos; granularPermissions: AuthorizationEntry[] | null }[]>([])
  const [botsLoadingState, setBotsLoadingState] = useState<'loading' | 'loaded' | 'failed' | null>(null)
  const { setError } = useErrorContext()
  const [searchString, setSearchString] = useState('')
  const [botCount, dispatch] = useReducer(amountReducer, initialBotAmount)
  const { resetBot, setBot } = useBotContext()

  const { instance: PCA } = useMsal()
  const { classes } = useStyles()
  const navigate = useNavigate()
  const ability = useContext(AbilityContext)

  async function fetchBots(): Promise<void> {
    setBotsLoadingState('loading')
    const data = await getBots()
    if (data) {
      // bots loaded so sort asc for name and place in state
      const sortedBots = stableSort(data, 'asc', 'botInfos.name')
      setBots(sortedBots)
      setBotsLoadingState('loaded')
    } else {
      setBotsLoadingState('failed')
    }
  }

  useEffect(() => {
    fetchBots()
    // reset bot context when loading agents, since we know that the bot is not in context anymore
    resetBot()
  }, [])

  useEffect(() => {
    if (botsLoadingState === 'failed') {
      setError(
        'Agents.loadAgentsError',
        'Assistenten konnten nicht geladen werden. Bitte überprüfen Sie Ihre Internet-Verbindung und versuchen Sie es erneut!',
        'Assistenten laden',
        fetchBots,
      )
    }
  }, [botsLoadingState])

  // Menu Handling
  function handleClick(event): void {
    setMenuWidth(event.currentTarget.clientWidth)
    setMenuAnchorEl(event.currentTarget)
  }
  function handleClose(): void {
    setMenuAnchorEl(null)
  }

  // Search
  const fuse = new Fuse(bots, fuseOptions)
  const filteredBots = searchString
    ? fuse.search(searchString).map((result) => {
        return result.item
      })
    : bots

  return (
    <ContentPage>
      <ContentPageHeader
        title={<img className={classes.logo} src={logo} alt='Convaise Logo' />}
        actions={[
          <Searchfield
            key='search-field'
            autoFocus
            value={searchString}
            onChange={(event): void => setSearchString(event.target.value)}
          />,
          <>
            <Button
              aria-controls='menu'
              aria-haspopup='true'
              onClick={handleClick}
              icon={menuAnchorEl ? 'arrow-up-s-line' : 'arrow-down-s-line'}
              iconType='remix'
            >
              Menü
            </Button>
            <Menu
              id='menu'
              anchorEl={menuAnchorEl}
              keepMounted
              open={Boolean(menuAnchorEl)}
              onClose={handleClose}
              elevation={2}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              PaperProps={{
                style: {
                  width: menuWidth,
                },
              }}
            >
              <MenuItem onClick={(): void => navigate(ROUTE_HELP)}>
                <ListItemText primary='Hilfe' />
              </MenuItem>
              <MenuItem onClick={(): Promise<void> => PCA.logoutRedirect()}>
                <ListItemText primary='Ausloggen' />
              </MenuItem>
            </Menu>
          </>,
        ]}
      />
      <ErrorComponent errorCode='Agents.loadAgentsError'>
        {/* Loading State */}
        {/* Render Bot Cards */}
        {botsLoadingState === 'loaded' && (
          <Grid container direction='row' alignItems='flex-start' spacing={5} className={classes.botGrid}>
            {filteredBots.slice(0, botCount).map((entry) => {
              const bot = entry.botInfos
              return (
                <Grid item xs={12} sm={6} md={4} lg={4} key={`${bot.id}`}>
                  <BotCard
                    botName={bot.name}
                    botDescription={bot.description}
                    botId={bot.id}
                    active={
                      bot.integrationChannels.production.hostUrl !== null &&
                      bot.integrationChannels.production.hostUrl !== ''
                    }
                    onClick={(): void => {
                      if (ability) {
                        // also set bot in context, for now as preparations for loading the bot improvements, so we either have a bot already in context in the BotLayout or load it from the url param
                        setBot(bot, entry.granularPermissions)
                        if (ability.can('read', 'analytics')) {
                          // console.log(`Navigate to ${ROUTE_BOTS}/${bot.id}${ROUTE_BOTID_ANALYTICS}`)
                          // navigate(`${ROUTE_BOTS}/anal/analytics`)
                          // navigate(`${ROUTE_BOTS}/${bot.id}${ROUTE_BOTID_ANALYTICS}`)
                          navigate(`${ROUTE_BOTS}/${bot.id}`)
                          return
                        } else if (ability.can('read', 'knowledge')) {
                          const modules = Object.values(bot?.modules ?? {})
                          let moduleConfigId
                          if (modules.map((module) => module.type).includes(MODULE_TYPE_NLU)) {
                            // NLU knowledge
                            moduleConfigId = modules.find((module) => module.type === MODULE_TYPE_NLU)?.moduleConfigId
                          } else if (modules.map((module) => module.type).includes(MODULE_TYPE_RAG)) {
                            // RAG knowledge
                            moduleConfigId = modules.find((module) => module.type === MODULE_TYPE_RAG)?.moduleConfigId
                          } else if (modules.map((module) => module.type).includes(MODULE_TYPE_ALEPHALPHA)) {
                            moduleConfigId = modules.find((module) => module.type === MODULE_TYPE_ALEPHALPHA)
                              ?.moduleConfigId
                          }

                          if (moduleConfigId) {
                            navigate(`${ROUTE_BOTS}/${bot.id}${ROUTE_BOTID_KNOWLEDGE}/${moduleConfigId}`)
                            return
                          }
                        }
                      }
                    }}
                  />
                </Grid>
              )
            })}
            {/* Only show the option to load additional bots if not all bots are already shown */}
            {botCount < filteredBots.length && (
              <Grid item xs={12} sm={6} md={4} lg={4} key={`load-more-bots`}>
                <IconCard
                  footerText='Weitere Assistenten laden'
                  icon={<i className={`ri-more-line`} />}
                  onClick={(): void => dispatch('increment')}
                />
              </Grid>
            )}
            {/* Only show the option to create bots if the user has the rights */}
            <Can I='create' a='bot'>
              <Grid item xs={12} sm={6} md={4} lg={4} key={`create-new-bot`}>
                <IconCard
                  footerText='Neuen Assistenten erstellen'
                  icon={<i className={`ri-add-line`} />}
                  onClick={(): void => navigate(`${ROUTE_BOTS}${ROUTE_CREATEBOT}`)}
                />
              </Grid>
            </Can>
          </Grid>
        )}
        {/* Render Loading State */}
        {botsLoadingState === 'loading' && (
          <div className={classes.loading}>
            <CircularLoading text='Lade Ihre Assistenten...' size='large' />
          </div>
        )}
      </ErrorComponent>
      {/* Footer */}
      <Footer />
    </ContentPage>
  )
}

export default AgentsPage
