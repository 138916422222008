import React, { useState, useEffect } from 'react'
import { makeStyles } from 'tss-react/mui'

// @mui/material components
import { Typography } from '@mui/material'

import { SelectDropdown, Option, ActionMeta } from 'components/Dropdown'

import { Chart } from '../../../../../@types/Flowchart/types'

const useStyles = makeStyles()({
  selectedDialogContainer: {},
  title: {},
  marginBottomSmall: { marginBottom: '10px' },
})

type SIntentTriggerProps = {
  chart: Chart
  setStateCallback: (chart: Chart) => void
}

export default function STrigger({ chart: origChart, setStateCallback }: SIntentTriggerProps): React.ReactElement {
  const { classes } = useStyles()
  const [chart, setChart] = useState(origChart)
  const [options, setOptions] = useState<Option[]>([])
  const [selectedOption, setSelectedOption] = useState<Option>()

  /**
   * Prepares options for select and sets them in state.
   * @param {Chart} chart
   */
  function prepareOptions(chart: Chart): void {
    const options: Option[] = []
    const allTriggerNodes = Object.values(chart.nodes).filter((node) => node.type === 'trigger/intent')
    const usedTriggerIntents = allTriggerNodes
      .map((node) => node.properties.triggerIntent)
      .filter((trigger) => !!trigger)

    if (chart.triggerAnswers) {
      for (const answer of chart.triggerAnswers) {
        const dialogName = answer.triggerDialogName
        if (dialogName) {
          const isDisabled = usedTriggerIntents.includes(answer.intent)
          options.push({
            label: `${dialogName}`,
            value: answer.intent,
            disabled: isDisabled,
            disabledText: isDisabled ? '(bereits genutzt)' : undefined,
          })
        }
      }
    }
    setOptions(options)
  }

  function getSelectedOption(chart: Chart): Option | undefined {
    if (typeof chart.selected?.id === 'undefined') return
    const triggerIntent = chart.nodes[chart.selected.id].properties.triggerIntent
    const label = chart.nodes[chart.selected.id].properties.text
    if (triggerIntent && label)
      return {
        label,
        value: triggerIntent,
      }
  }

  useEffect(
    function () {
      setChart(origChart)
      prepareOptions(origChart)
      setSelectedOption(getSelectedOption(origChart))
    },
    [origChart],
  )

  /**
   * Handles selection
   * @param {Object} selection object with label and value of selected option
   */
  function onChange(newValue: Option, action: ActionMeta<Option>): void {
    if (typeof chart.selected?.id === 'undefined') return
    chart.nodes[chart.selected.id].properties.text = newValue.label
    chart.nodes[chart.selected.id].properties.triggerIntent = newValue.value

    setSelectedOption(newValue)
    setStateCallback(chart)
  }

  return (
    <div className={classes.selectedDialogContainer}>
      <div>
        <React.Fragment>
          <div className={classes.marginBottomSmall}>
            <Typography>Trigger auswählen, der diese Node triggern und den Dialog starten soll.</Typography>
          </div>
          <SelectDropdown
            selected={selectedOption}
            options={options}
            onChange={onChange}
            isSearchable
            placeholder='Bitte auswählen'
          />
        </React.Fragment>
      </div>
    </div>
  )
}
