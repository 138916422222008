import { Tree } from '../../../../../@types/Knowledge/types'
import { Answer } from '../../../../../classes/Knowledge'

/**
 * Looks through list and returns the first value that matches all of the key-value pairs listed in properties.
 * @param array
 * @param key
 * @param value
 */
function findWhere(array: any[], key: string, value: any): any {
  // Adapted from https://stackoverflow.com/questions/32932994/findwhere-from-underscorejs-to-jquery
  let t = 0 // t is used as a counter
  while (t < array.length && array[t][key] !== value) {
    t++
  } // find the index where the id is the as the aValue

  if (t < array.length) {
    return array[t]
  } else {
    return false
  }
}

type Path = (string | Answer)[]

/**
 * Arranges an array of paths into a tree structure.
 * Adapted from https://gist.github.com/stephanbogner/4b590f992ead470658a5ebf09167b03d
 * @param paths
 */
function arrangeIntoTree(paths: Path[]): Tree[] {
  const tree: Tree[] = []
  for (let i = 0; i < paths.length; i += 1) {
    // each path
    const path = paths[i]
    let currentLevel = tree
    for (let j = 0; j < path.length; j += 1) {
      // each part of path
      let part = path[j]

      // check if part is string or answer object
      if (typeof part === 'string') {
        // part is topic
        const existingPath = findWhere(currentLevel, 'name', part)
        if (existingPath) {
          currentLevel = existingPath.children
        } else {
          const newPart = {
            name: part,
            id: 'topic/' + part,
            children: [],
          }
          currentLevel.push(newPart)
          currentLevel = newPart.children
        }
      } else {
        part = part as Answer
        // part is answer object
        const existingPath = findWhere(currentLevel, 'name', part.title ? part.title : part.intent)
        if (existingPath) {
          currentLevel = existingPath.children
        } else {
          const newPart = {
            name: part.title ? part.title : part.intent,
            id: 'answer/' + part.answerId,
            children: [],
          }
          currentLevel.push(newPart)
          currentLevel = newPart.children
        }
      }
    }
  }
  return tree
}

function compare(a, b) {
  const topicA = a[0].toUpperCase()
  const topicB = b[0].toUpperCase()

  let comparison = 0
  if (topicA > topicB) {
    comparison = 1
  } else if (topicA < topicB) {
    comparison = -1
  }
  return comparison
}

/**
 * Builds tree from answer list, based on answer topics.
 * @param answers
 */
export function buildTreeFromAnswers(answers: Answer[], noTopicName: string): Tree[] {
  // build topics
  let paths: Path[] = []
  answers.forEach((answer) => {
    const topic = answer.topic
    if (topic && topic !== null) {
      if (topic.slice(-1) === '/') {
        // remove trailing '/'
        answer.topic = topic.slice(0, -1)
      }
      const pathArray: Path = topic.split('/')
      pathArray.push(answer)
      paths.push(pathArray)
    } else {
      paths.push([noTopicName, answer])
    }
  })
  paths = paths.sort(compare)
  return arrangeIntoTree(paths)
}

/**
 * Builds path for a node by recursively stepping to parent and prepending parent topic name.
 * @param node G6 node object
 * @param basePath path to which current node topic should be prepended
 */
export function buildBasePathFromParents(node: any, basePath = ''): string {
  if (node.model && node.model.depth > 1 && node.parent && node.parent._cfg && node.parent._cfg.model) {
    const parentNode = node.parent._cfg
    const parentTopicName = parentNode.model.name
    basePath = parentTopicName + (basePath !== '' ? '/' : '') + basePath // only prepend '/' if not empty string as we do not allow / as first char
    basePath = buildBasePathFromParents(parentNode, basePath)
  }
  return basePath
}

/**
 * Tests if first character of string is whitespace.
 * @param s
 */
function isWhitespace(s: string): boolean {
  if (s.length === 0) return false
  const white = new RegExp(/^\s$/)
  return white.test(s.charAt(0))
}

/**
 * Wraps a string by inserting newlines to ensure that string does not exceed maxWidth.
 * @param str
 * @param maxWidth
 */
export function wrapWord(str: string, maxWidth: number): string {
  const newLineStr = '\n'
  let res = ''
  while (str.length > maxWidth) {
    let found = false
    // inserts new line at first whitespace of the line (going backwards)
    for (let i = maxWidth - 1; i >= 0; i -= 1) {
      if (isWhitespace(str.charAt(i))) {
        res += [str.slice(0, i), newLineStr].join('')
        str = str.slice(i + 1)
        found = true
        break
      }
    }
    // insert new line at maxWidth position -> word is too long to wrap, cut it
    if (!found) {
      res += [str.slice(0, maxWidth), newLineStr].join('')
      str = str.slice(maxWidth)
    }
  }
  return res + str
}
