import React from 'react'
import { withStyles } from 'tss-react/mui'
import Tooltip from '@mui/material/Tooltip'

const HtmlTooltip = withStyles(Tooltip, () => ({
  tooltip: {
    backgroundColor: 'rgba(0,0,0,0.9)',
    color: 'white',
    maxWidth: 400,
    '& p': {
      marginTop: '0px',
      marginBottom: '0px',
      fontSize: '12px',
    },
    '& p a': {
      color: 'white',
      textDecorationColor: 'white',
      textDecorationThickness: '.125em',
      textUnderlineOffset: '1.5px',
    },
  },
  arrow: {
    color: 'rgba(0,0,0,0.9)',
  },
}))

type CustomizedTooltipProps = {
  content: React.ReactElement | null
  elements: React.ReactElement
  disableInteractive: boolean
  open?: boolean
  placement?:
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start'
    | undefined
}

function CustomizedTooltip(props: CustomizedTooltipProps): React.ReactElement {
  return (
    <HtmlTooltip
      arrow
      open={props.open}
      placement={props.placement ?? 'right'}
      disableInteractive={props.disableInteractive}
      title={props.content ? <React.Fragment>{props.content}</React.Fragment> : ''}
    >
      {props.elements}
    </HtmlTooltip>
  )
}

CustomizedTooltip.defaultProps = {
  disableInteractive: false,
}

export default CustomizedTooltip
