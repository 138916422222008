import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { useErrorContext, ErrorCode } from '../../hooks/contexts/errorContext/'

import Button from '../../components/Buttons/Button'
import { Typography } from '@mui/material'

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(3),
  },
  title: { marginBottom: '15px' },
  message: { marginBottom: '15px' },
  action: {},
}))

type ErrorProps = {
  errorCode: ErrorCode
  children: React.ReactNode
}

export default function ErrorComponent({ errorCode, children }: ErrorProps): React.ReactElement | null {
  const { classes } = useStyles()
  const { errors } = useErrorContext()
  const error = errors[errorCode]

  return error ? (
    <div className={classes.root}>
      {error.errorTitle && (
        <div className={classes.title}>
          <Typography variant='h5'>{error.errorTitle}</Typography>
        </div>
      )}
      <div className={classes.message}>
        <Typography>{error.errorMsg}</Typography>
      </div>
      <div className={classes.action}>
        <Button size='normal' type='normal' onClick={error.callbackFn}>
          {error.buttonText}
        </Button>
      </div>
    </div>
  ) : (
    <>{children}</>
  )
}
