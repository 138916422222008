import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { useState } from 'react'
import RMEEditor from 'components/TextEditors/editor-mdx-markdown'

const useStyles = makeStyles()((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  answerEditContainer: {
    marginTop: theme.spacing(3),
  },
  answerPreviewContainer: {
    marginTop: theme.spacing(2),
  },
  answerPreview: {
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
}))

type Step5AnswerProps = {
  answerText?: string
  onSetAnswer: (answer: string) => void
}

export default function StepAnswer({ answerText: propsAnswerText, onSetAnswer }: Step5AnswerProps): React.ReactElement {
  const { classes } = useStyles()

  const [answerText, setAnswerText] = useState<string | undefined>(propsAnswerText)

  /**
   * Handles user input.
   * Updates value in state and also updates parents state
   * @param newText
   */
  function onAnswerTextChange(newText: string): void {
    setAnswerText(newText)
    onSetAnswer(newText)
  }

  return (
    <div className={classes.container}>
      <Typography>Text der Antwort:</Typography>
      <div className={classes.answerEditContainer}>
        <RMEEditor
          // defaultInput={answerText || ''}
          input={answerText || ''}
          onChangeCallback={(markdown): void => {
            onAnswerTextChange(markdown)
          }}
          placeholder='Ihr Antworttext...'
        />
      </div>
    </div>
  )
}
