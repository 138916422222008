import React, { useState, useEffect } from 'react'

// @mui/material
import { makeStyles } from 'tss-react/mui'

import Button from '@mui/material/Button'
// @mui/icons-material
import CheckIcon from '@mui/icons-material/Check'

import CardList, { CardObject } from '../../../components/CardList/CardList'

import { IngestPDFResult } from '../../../classes/Ingest'

const useStyles = makeStyles()((theme) => ({
  viewControlls: {
    position: 'absolute',
    bottom: '0%',
    width: '100%',
  },
  finalButton: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  view: {
    height: 'calc(100% - 90px)',
    position: 'relative',
    marginTop: theme.spacing(4),
  },
}))

type InfotextsViewProps = {
  ingestPDFResult: IngestPDFResult
  setIngestPDFResult: (ingestPDFResult: IngestPDFResult) => void
  setIsInfotextDone: (e: number) => void
}

export default function InfotextsView({
  ingestPDFResult,
  setIngestPDFResult,
  setIsInfotextDone,
}: InfotextsViewProps): React.ReactElement {
  const [infotextList, setInfotextList] = useState<CardObject[]>([])
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>()
  const { classes } = useStyles()

  /**
   * Prepares infotext object for display in CardList.
   */
  function prepareList(): CardObject[] {
    const infotexts = ingestPDFResult.infotexts
    const infotextsList: CardObject[] = []
    Object.keys(infotexts).forEach((infotextId) => {
      const infotext = infotexts[infotextId]
      infotextsList.push({
        id: infotextId,
        title: infotext.title,
        subtitle: infotext.type === 'infotext' ? 'Infotext' : 'URL',
        text: infotext.text,
        color: undefined, // TODO: Colors
      })
    })

    return infotextsList
  }

  // prepare card list and select card if a card is selected
  useEffect(() => {
    const list = prepareList()
    // find selected card - if one selected
    if (ingestPDFResult.selectedId) {
      const selectedIndex = list.findIndex((card) => card.id === ingestPDFResult.selectedId)
      if (typeof selectedIndex === 'number' && selectedIndex >= 0) setSelectedIndex(selectedIndex)
    } else {
      setSelectedIndex(undefined)
    }
    setInfotextList(list)
  }, [ingestPDFResult.infotexts, ingestPDFResult.selectedId])

  // handles selection of card
  function onCardClick(card: CardObject, index: number): void {
    if (index !== selectedIndex) {
      // set selected
      ingestPDFResult.setSelectedId(card.id)
      setIngestPDFResult(ingestPDFResult)
      setSelectedIndex(index)
    } else {
      // set unselected
      ingestPDFResult.removeSelectedId()
      setIngestPDFResult(ingestPDFResult)
      setSelectedIndex(undefined)
    }
  }

  return (
    <div className={classes.view}>
      <CardList content={infotextList} selectedIndex={selectedIndex} onClick={onCardClick} />
      <div className={classes.viewControlls}>
        <Button
          variant='contained'
          component='span'
          className={classes.finalButton}
          startIcon={<CheckIcon />}
          onClick={(): void => setIsInfotextDone(1)}
        >
          Abschließen
        </Button>
      </div>
    </div>
  )
}
