import React, { useState, useEffect } from 'react'

// @mui/material
import { makeStyles } from 'tss-react/mui'

import { useTheme } from '@mui/material/styles'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
// @mui/icons-material
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import AccountTreeRoundedIcon from '@mui/icons-material/AccountTreeRounded'
import CropLandscapeRoundedIcon from '@mui/icons-material/CropLandscapeOutlined'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import LaunchIcon from '@mui/icons-material/Launch'
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Grid } from '@mui/material'

import { IngestPDFResult } from '../../../classes/Ingest'
import { addDocument } from '../../../api/StudioBackend'
import { BotInfos } from '../../../@types/BotInformation/types'
import { Signature, SyncedSignature } from '../../../@types/Ingest/types'
import { useBotContext } from 'hooks/contexts/bot-context'
import { ROUTE_BOTID_DOCUMENTS, ROUTE_BOTS } from 'utils/constants'
import BaseCard from 'components/Cards/BaseCard'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles()((theme) => ({
  loadingWrapper: { height: '100%', width: '100%', paddingTop: '30%' },
  contentRoot: { height: '100%', width: '100%', position: 'relative' },
  // contentWrapper: { display: 'flex', width: '75%', marginLeft: 'auto', marginRight: 'auto', height: '250px' },
  contentIcon: { width: '75px', height: '75px' },
  contentText: { marginTop: '20px' },
  // contentLeft: { width: '50%', height: '100%', padding: '15px', borderRight: '1px solid', position: 'relative' },
  // contentRight: { width: '50%', height: '100%', marginLeft: 'auto', padding: '15px', position: 'relative' },
  contentSingle: {
    position: 'relative',
    width: '75%',
    height: '100px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '50px',
  },
  pdfProgress: { color: theme.palette.text.primary, width: 'auto' },
  view: { height: '100%', position: 'relative' },
  successIcon: { color: theme.palette.success.main, fontSize: '3.5rem' },
  errorBox: {
    position: 'absolute',
    top: 0,
    width: '100%',
    minHeight: '50px',
    height: 'fit-content',
    color: 'white',
    background: theme.palette.error.main,
  },
  // button: {
  //   // position: 'absolute',
  //   left: 0,
  //   right: 0,
  //   marginLeft: 'auto',
  //   marginRight: 'auto',
  //   marginTop: 'auto',
  //   bottom: 0,
  //   textAlign: 'center',
  //   width: 'fit-content',
  // },
  menuContainer: {
    display: 'flex',
    // width: '100%'
    height: 'calc(100% - 60px)',
    alignItems: 'center',
    marginTop: theme.spacing(3),
  },
  card: {
    alignItems: 'center',
    boxShadow: '0 14px 26px -12px rgb(0 0 0 / 15%), 0 4px 23px 0px rgb(0 0 0 / 6%), 0 8px 10px -5px rgb(0 0 0 / 10%)', // '0px 2px 10px 2px rgba(0,0,0, 0.25)'
    transition: 'box-shadow 0.3s',
    '&:hover': {
      boxShadow:
        '0 14px 26px -12px rgb(0 0 0 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)', // '0px 2px 10px 2px rgba(0,0,0, 0.25)'
    },
  },
  cardIcon: {
    fontSize: '5rem',
  },
}))

const useDialogStyles = makeStyles()(() => ({
  actionButton: {
    marginLeft: '5px',
    marginRight: '5px',
    textAlign: 'center',
    width: '150px',
  },
  actionContainer: {
    width: '100%',
  },
  actionContainerMain: {
    width: '100%',
    marginTop: '25px',
    textAlign: 'center',
  },
  actionContainerCancel: {
    width: '100%',
    marginTop: '25px',
    textAlign: 'center',
  },
}))

type GenerationViewProps = {
  signature: Signature
  syncedSignature: SyncedSignature
  ingestPDFResult: IngestPDFResult
  setIngestPDFResult: (ingestPDFResult: IngestPDFResult) => void
  file: File
}

export default function GenerationView({
  signature,
  syncedSignature,
  ingestPDFResult,
  file,
}: GenerationViewProps): React.ReactElement {
  // const [chart, setChart] = useState<Chart | undefined>()
  const theme = useTheme()
  const navigate = useNavigate()
  const { bot, setBot } = useBotContext() as { bot: BotInfos; setBot: (bot: BotInfos) => void } // explicit casting because if this component is shown, the bot is set in the context
  const botId = bot?.id

  const [loading, setLoading] = useState<string>('unloaded')
  const [finishSuccess, setFinishSuccess] = useState<boolean>()

  // const [generateFlowSuccess, setGenerateFlowSuccess] = useState<boolean>()
  // const [uploadPdfSuccess, setUploadPdfSuccess] = useState<boolean>()
  const [generate, setGenerate] = useState<boolean>()
  const [orderDialogOpen, setOrderDialogOpen] = useState(false)

  const { classes } = useStyles()
  const { classes: dialogClasses } = useDialogStyles()

  useEffect(() => {
    if (finishSuccess) {
      setLoading('loaded')
    }
  }, [finishSuccess])

  // async function generateFlowchart(generate: boolean, calculateOrder: boolean): Promise<void> {
  //   let chart = await generateFlow(ingestPDFResult, botId, generate, calculateOrder)
  //   if (!chart) {
  //     console.error('Generate flow failed.')
  //     setTimeout(async () => {
  //       // one retry after 5 seconds
  //       console.error('Retry: Generate flow...')
  //       chart = await generateFlow(ingestPDFResult, botId, generate, calculateOrder)
  //       if (!chart) {
  //         setGenerateFlowSuccess(false)
  //         setLoading('unloaded')
  //         alert('Erstellen des Assistenten fehlgeschlagen')
  //       } else {
  //         setGenerateFlowSuccess(true)
  //       }
  //     }, 5000)
  //   } else {
  //     setGenerateFlowSuccess(true)
  //   }
  // }

  // async function uploadPDFToStorage(): Promise<void> {
  //   let success = await uploadPDF(botId, file)
  //   if (!success) {
  //     console.error('Upload PDF failed.')
  //     await setTimeout(async () => {
  //       // one retry after 5 seconds
  //       console.error('Retry: Upload PDF...')
  //       success = await uploadPDF(botId, file)
  //       if (!success) {
  //         setUploadPdfSuccess(false)
  //         setLoading('unloaded')
  //         alert('Erstellen des Assistenten fehlgeschlagen')
  //         return false
  //       } else {
  //         setUploadPdfSuccess(true)
  //       }
  //     }, 5000)
  //   } else {
  //     setUploadPdfSuccess(true)
  //   }
  // }

  // async function storeIngestPDFResult(): Promise<boolean | null> {
  //   ingestPDFResult.cleanup() // remove tmp config properties that were only used during ingest
  //   return storeIngestResult(botId, ingestPDFResult, signature, syncedSignature, file)
  // }

  /**
   * Finishes the ingest process by calling the API to generate the flow.
   * This sets the variables from the PDF file in the chart. If the option is set, this also generates a flow.
   * @param generateFlow
   * @param calculateOrder
   */
  async function completeIngest(generateFlow: boolean, calculateOrder?: boolean): Promise<void> {
    if (calculateOrder === undefined) calculateOrder = false
    setLoading('loading')
    setGenerate(generateFlow)

    // removing .pdf from title
    const title = file.name.slice(0, -4).indexOf('.pdf') > 0 ? file.name.slice(0, -4) : file.name

    const newBotInfos = await addDocument(
      botId,
      file,
      signature,
      syncedSignature,
      ingestPDFResult,
      title,
      '',
      generateFlow,
      calculateOrder,
    )

    if (newBotInfos === null) {
      // not succesful
      setFinishSuccess(false)
    } else {
      // update botinfos in Studio
      setBot(newBotInfos)
      setFinishSuccess(true)
    }
  }

  function onOrderDialogKeepOrder(): void {
    // generate flow, but do not change order
    completeIngest(true, false)
    setOrderDialogOpen(false)
  }

  function onOrderDialogCalculateOrder(): void {
    completeIngest(true, true)
    setOrderDialogOpen(false)
  }

  function onGenerateButtonClick(): void {
    // check if order of sections has been changed
    if (ingestPDFResult.regions.customOrder) {
      setOrderDialogOpen(true)
    } else {
      completeIngest(true, true)
    }
  }

  /**
   * Renders dialog to confirm whether user wants to keep custom order or if order should be generated.
   */
  function renderOrderDialog(): React.ReactElement {
    return (
      <Dialog
        open={orderDialogOpen}
        onBackdropClick={(): void => setOrderDialogOpen(false)}
        onClose={(): void => setOrderDialogOpen(false)}
      >
        <DialogTitle>Reihenfolge der Bereiche</DialogTitle>
        <DialogContent>
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '15px' }}>
              <ReportProblemOutlinedIcon
                style={{
                  width: '50px',
                  height: '50px',
                }}
              />
            </div>
            <div style={{ marginLeft: 'auto' }}>
              Sie haben die Reihenfolge der Bereiche manuell angepasst. Möchten Sie die aktuelle Reihenfolge beibehalten
              oder soll die Reihenfolge basierend auf dem PDF Formular erkannt und festgelegt werden?
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className={dialogClasses.actionContainer}>
            <div className={dialogClasses.actionContainerMain}>
              <Button
                className={dialogClasses.actionButton}
                variant='contained'
                component='span'
                startIcon={<AccountTreeRoundedIcon />}
                onClick={onOrderDialogKeepOrder}
              >
                Beibehalten
              </Button>
              <Button
                className={dialogClasses.actionButton}
                variant='contained'
                component='span'
                startIcon={<AccountTreeRoundedIcon />}
                onClick={onOrderDialogCalculateOrder}
              >
                Festlegen
              </Button>
            </div>
            <div className={dialogClasses.actionContainerCancel}>
              <Button>Abbrechen</Button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <div className={classes.view}>
      {renderOrderDialog()}
      <div className={classes.loadingWrapper}>
        {loading === 'unloaded' ? (
          <div className={classes.contentRoot}>
            <div>
              <Typography>
                {/* Alle relevanten Informationen sind nun zusammengestellt. Wenn Sie bereit sind können Sie nun den Import
                abschließen und danach das Dokument im Assistenten verwenden. */}
                Basierend auf dem vorangegangen Ingestprozess kann automatisch ein Konversationsfluss für den
                Assistenten generiert werden. Alternativ können Sie auch ohne die automatische Generierung fortfahren
                und den Konversationsfluss von Grund auf entwerfen.
              </Typography>
              <div className={classes.menuContainer}>
                <Grid container spacing={2} justifyContent='center'>
                  <Grid item xs={6}>
                    <BaseCard
                      footerText='Hauptdialog generieren'
                      footerColor={theme.palette.primary.main}
                      onClick={onGenerateButtonClick}
                      shadowWidth={20}
                      shadowBlur={20}
                      className={classes.card}
                      width='350px'
                      height='200px'
                    >
                      {/* <img className={classes.menuImage1} src={svgChooseCardMenuImage} alt={'Blockauswahl über Blocksuche'} /> */}
                      <AccountTreeRoundedIcon className={classes.cardIcon} />
                    </BaseCard>
                  </Grid>
                  {/* <button className={classes.button} onClick={(): void => onSelection(SJumpSelection.BLOCK_SEARCH)}>
            <Pageview className={classes.icon} />
            <Typography>Block auswählen</Typography>
          </button> */}
                  {/* <BaseCard
                  footerText='Leerer Hauptdialog'
                  footerColor={theme.palette.primary.main}
                  onClick={(): Promise<void> => completeIngest(false)}
                  shadowWidth={20}
                  shadowBlur={20}
                  className={classes.card}
                  width='90%'
                  height='200px'
                > */}
                  {/* <img className={classes.menuImage1} src={svgCreateCardMenuImage} alt={'Blockauswahl über Block ID'} /> */}
                  {/* <CropLandscapeRoundedIcon className={classes.cardIcon} />
                </BaseCard> */}
                  <Grid item xs={6}>
                    <BaseCard
                      footerText='Abschließen'
                      footerColor={theme.palette.primary.main}
                      onClick={(): Promise<void> => completeIngest(false)}
                      shadowWidth={20}
                      shadowBlur={20}
                      className={classes.card}
                      width='350px'
                      height='200px'
                    >
                      {/* <img className={classes.menuImage1} src={svgCreateCardMenuImage} alt={'Blockauswahl über Block ID'} /> */}
                      <CropLandscapeRoundedIcon className={classes.cardIcon} />
                    </BaseCard>
                  </Grid>
                </Grid>

                {/* <button className={classes.button} onClick={(): void => onSelection(SJumpSelection.BLOCK_ID)}>
            <Input className={classes.icon} />
            <Typography>Trigger</Typography>
          </button> */}
              </div>
            </div>
          </div>
        ) : // <div className={classes.contentRoot}>
        //   <div className={classes.contentWrapper}>
        //     <div className={classes.contentLeft}>
        //       <AccountTreeRoundedIcon className={classes.contentIcon} />
        //       <div className={classes.contentText}>
        //         Basierend auf dem vorangegangen Ingestprozess kann automatisch ein Konversationsfluss für den
        //         Assistenten generiert werden.
        //       </div>
        //       <div className={classes.button}>
        //         <Button
        //           // className={classes.button}
        //           variant='contained'
        //           component='span'
        //           startIcon={<AccountTreeRoundedIcon />}
        //           onClick={(): void => onGenerateButtonClick()}
        //         >
        //           Generieren
        //         </Button>
        //       </div>
        //     </div>
        //     {/* ACCOUNT TREE ICON -> Generate */}
        //     {/* CropLandscapeRounded -> Empty */}
        //     <div className={classes.contentRight}>
        //       <CropLandscapeRoundedIcon className={classes.contentIcon} />
        //       <div className={classes.contentText}>
        //         Sie können auch ohne die automatische Generierung fortfahren und den Konversationsfluss von Grund auf
        //         entwerfen.
        //       </div>
        //       <div className={classes.button}>
        //         <Button
        //           // className={classes.button}
        //           variant='contained'
        //           component='span'
        //           startIcon={<CropLandscapeRoundedIcon />}
        //           onClick={(): Promise<void> => completeIngest(false)}
        //         >
        //           Leerer Konversationsfluss
        //         </Button>{' '}
        //       </div>
        //     </div>
        //   </div>
        //   {!finishSuccess && generate !== undefined && (
        //     <div className={classes.contentSingle}>
        //       <div>Da der vorherige Versuch fehlgeschlagen ist, können sie es hier erneut versuchen.</div>
        //       <Button
        //         className={classes.button}
        //         variant='contained'
        //         component='span'
        //         startIcon={<ReplayRounded />}
        //         onClick={(): Promise<void> => completeIngest(generate)}
        //       >
        //         Erneut versuchen
        //       </Button>
        //     </div>
        //   )}
        // </div>
        loading === 'loading' ? (
          <>
            {/* TODO: Animation */}
            <CircularProgress className={classes.pdfProgress} size={'3rem'} />
            <br />
            <Typography>
              {/* TODO: I18n */}
              {generate ? 'Konversationsfluss wird generiert ...' : 'Assistent wird vorbereitet...'}
            </Typography>
            <br />
          </>
        ) : (
          loading === 'loaded' && (
            <>
              {/* TODO: picture / animation */}
              <CheckCircleOutlineIcon className={classes.successIcon} />
              <br />
              <Typography variant='h3'>
                {/* TODO: I18n */}
                Ihr Dokument wurde erfolgreich hinzugefügt.
              </Typography>
              <br />
              {/* TODO: Button to open link to Editor */}
              <Button
                variant='contained'
                component='span'
                startIcon={<LaunchIcon />}
                onClick={(): void => {
                  navigate(ROUTE_BOTS + `/${bot.id}` + ROUTE_BOTID_DOCUMENTS)
                }}
              >
                Dokumente öffnen
              </Button>
            </>
          )
        )}
      </div>
    </div>
  )
}
