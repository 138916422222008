import React from 'react'
import { makeStyles, withStyles } from 'tss-react/mui'

import { Stepper, Step, StepLabel, StepConnector, Paper } from '@mui/material'
import { Check } from '@mui/icons-material'
import { StepIconProps } from '@mui/material/StepIcon'

const useStyles = makeStyles<void, 'alternativeLabel'>()((theme, _params, classes) => ({
  root: {
    width: '100%',
  },
  labelContainer: {
    [`& .${classes.alternativeLabel}`]: {
      marginTop: 0,
    },
  },
  alternativeLabel: {},
  stepperPaper: {
    borderRadius: '8px',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}))

const Connector = withStyles(StepConnector, (theme, _params, classes) => ({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    [`& .${classes.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  completed: {
    [`& .${classes.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}))

const useStepIconStyles = makeStyles()((theme) => ({
  root: {
    color: theme.palette.grey[500],
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: theme.palette.primary.main,
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: theme.palette.primary.main,
    zIndex: 1,
    fontSize: 18,
  },
}))

function StepIcon(props: StepIconProps): React.ReactElement {
  const { classes, cx } = useStepIconStyles()
  const { active, completed } = props

  return (
    <div
      className={cx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  )
}

type HorizontalStepperProps = {
  activeStep: number
  steps: string[]
}

const HorizontalStepper = ({ activeStep, steps }: HorizontalStepperProps): React.ReactElement => {
  const { classes } = useStyles()

  return (
    <div className={classes.root}>
      <Paper className={classes.stepperPaper}>
        <Stepper alternativeLabel activeStep={activeStep} connector={<Connector />}>
          {steps.map((step) => {
            return (
              <Step key={step}>
                <StepLabel
                  classes={{ labelContainer: classes.labelContainer, alternativeLabel: classes.alternativeLabel }}
                  StepIconComponent={StepIcon}
                >
                  {step}
                </StepLabel>
              </Step>
            )
          })}
        </Stepper>
      </Paper>
    </div>
  )
}

export default HorizontalStepper
