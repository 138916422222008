import { SubdirectoryArrowRight } from '@mui/icons-material'
import React, { ReactElement } from 'react'
import EditorSidebarItem from '../editorSidebarItem'
import { FLOWDESIGNER_NODE_COLOR_LOGIC } from 'utils/constants'

export default function ifElse(): ReactElement {
  return (
    <EditorSidebarItem
      type='logic/ifElse'
      ports={{
        port1: {
          id: 'port1',
          type: 'left',
          position: { x: 0, y: 0 },
        },
        port2: {
          id: 'port2',
          type: 'right',
          position: { x: 0, y: 0 },
          properties: {
            cond: false,
            name: 'False',
            type: 'outgoing',
          },
        },
        port3: {
          id: 'port3',
          type: 'right',
          position: { x: 0, y: 0 },
          properties: {
            cond: true,
            name: 'True',
            type: 'outgoing',
          },
        },
      }}
      properties={{
        typeText: 'If-Else Logik',
        text: 'Nicht konfiguriert',
        dialog: '',
      }}
      Icon={SubdirectoryArrowRight}
      color={FLOWDESIGNER_NODE_COLOR_LOGIC}
    />
  )
}
