import { IngestPDFFieldDescription, SyncedCoordinates, SyncedDimensions } from '../@types/Ingest/types'

export class IngestPDFSubSection {
  id: string
  coords: SyncedCoordinates
  name: string
  fields: string[]
  parentsections: string[]
  page: number
  constructor(
    uuid: string,
    coords: SyncedCoordinates,
    fields: string[], // NOTE: ids of fields
    page: number,
    parentSections?: string[],
    name?: string,
  ) {
    this.id = uuid
    this.coords = coords
    this.fields = fields
    this.page = page
    // setting defaults
    this.name = name ? name : ''
    this.parentsections = parentSections ? parentSections : []
  }

  /**
   * Sets the name of the subsection
   * @param name Name of the subsection
   */
  setName(name: string): void {
    this.name = name
  }

  /**
   * Adds the Id of the parent section to the subsection
   * @param parentSectionId Id of a parent section
   */
  addParentSections(parentSectionId: string): void {
    this.parentsections.push(parentSectionId)
  }

  /**
   * Removes a parent section
   * @param parentSectionId Id of a parent section
   */
  removeParentSections(parentSectionId: string): void {
    this.parentsections = this.parentsections.filter((id) => id !== parentSectionId)
  }

  /**
   * Adds a field to the subsection
   * @param fieldId Id of a pdf field
   */
  addField(fieldId: string): void {
    this.fields.push(fieldId)
  }

  /**
   * Removes a field from the subsection
   * @param fieldId Id of a pdf field
   */
  removeField(fieldId: string): void {
    this.fields = this.fields.filter((id) => id !== fieldId)
  }

  /**
   * Returns subsection as ready to use JSON object
   */
  toJSON(): {
    id: string
    name: string
    coords: SyncedCoordinates
    fields: string[]
    parentsections: string[]
    page: number
  } {
    return {
      id: this.id,
      name: this.name,
      coords: this.coords,
      fields: this.fields,
      parentsections: this.parentsections,
      page: this.page,
    }
  }
}

export class IngestPDFSection {
  id: string
  coords: SyncedCoordinates
  name: string
  subsections: string[]
  page: number
  constructor(uuid: string, coords: SyncedCoordinates, page: number, subsections?: string[], name?: string) {
    this.id = uuid
    this.coords = coords
    this.page = page
    // setting defaults
    this.name = name ? name : ''
    this.subsections = subsections ? subsections : []
  }

  /**
   * Sets the name of the subsection
   * @param name Name of the subsection
   */
  setName(name: string): void {
    this.name = name
  }

  /**
   * Adds the Id of the parent section to the subsection
   * @param subSectionId Id of a parent section
   */
  addParentSections(subSectionId: string): void {
    this.subsections.push(subSectionId)
  }

  /**
   * Removes a parent section
   * @param subSectionId Id of a parent section
   */
  removeParentSections(subSectionId: string): void {
    this.subsections = this.subsections.filter((id) => id !== subSectionId)
  }

  /**
   * Returns subsection as ready to use JSON object
   */
  toJSON(): {
    id: string
    name: string
    page: number
    coords: SyncedCoordinates
    subsections: string[]
  } {
    return {
      id: this.id,
      name: this.name,
      page: this.page,
      coords: this.coords,
      subsections: this.subsections,
    }
  }
}

export class IngestPDFField {
  id: string
  displayName: string
  fieldName: string
  type: 'Text' | 'Checkbox' | 'RadioButton' | 'Choice' | 'Signature' | string
  page: number
  pageDimens: SyncedDimensions
  coords: SyncedCoordinates
  description: IngestPDFFieldDescription
  required: boolean
  textFont: string
  textSize: string
  maxLength: string | null
  format: 'date' | 'email' | 'german area code' | 'phone' | null
  options: string[]
  optionsDisplayValue: string[]
  isConfigured: boolean

  constructor(
    id: string,
    fieldName: string,
    type: 'Text' | 'Checkbox' | 'RadioButton' | 'Choice' | 'Signature' | string,
    page: number,
    pageDimens: SyncedDimensions,
    coords: SyncedCoordinates,
    description: IngestPDFFieldDescription,
    required: boolean,
    displayName?: string,
    textFont?: string,
    textSize?: string,
    maxLength?: string,
    format?: 'date' | 'email' | 'german area code' | 'phone',
    options?: string[],
    optionsDisplayValue?: string[],
    isConfigured?: boolean,
  ) {
    this.id = id
    this.fieldName = fieldName
    this.page = page
    this.pageDimens = pageDimens
    this.coords = coords
    this.description = description
    this.required = required
    this.displayName = displayName ? displayName : ''
    this.textFont = textFont ? textFont : ''
    this.textSize = textSize ? textSize : ''
    this.maxLength = maxLength ? maxLength : null
    this.format = format ? format : null
    this.options = options ? options : []
    this.optionsDisplayValue = optionsDisplayValue ? optionsDisplayValue : []
    this.isConfigured = isConfigured || false
    // we rename 'choice' type to 'Choiceset' -> easier to understand because field does not represent single choice, but whole choiceset
    if (type === 'Choice') {
      this.type = 'Choiceset'
    } else {
      this.type = type
    }
  }

  setIsConfigured(isConfigured: boolean): void {
    this.isConfigured = isConfigured
  }

  /** Resets description. If reset is true, resets it to empty description object, else keeps values */
  setIsDescriptionConfigured(isConfigured: boolean, reset?: boolean): void {
    this.description.isConfigured = isConfigured
    if (!isConfigured && reset) {
      this.description.coords = this.coords
      this.description.text = ''
    }
  }

  setDisplayName(displayName: string): void {
    this.displayName = displayName
  }

  setType(type: 'Text' | 'Checkbox' | 'RadioButton' | 'Choice' | 'Signature' | string): void {
    this.type = type
  }

  setDescription(description: IngestPDFFieldDescription): void {
    this.description = description
  }

  setDescriptionText(text: string): void {
    this.description.text = text
  }

  setDescriptonCoords(coords: SyncedCoordinates): void {
    this.description.coords = coords
  }

  toJSON(): {
    id: string
    fieldName: string
    type: 'Text' | 'Choiceset' | 'RadioButton' | 'Checkbox' | 'Signature' | string
    page: number
    pageDimens: SyncedDimensions
    coords: SyncedCoordinates
    description: IngestPDFFieldDescription
    required: boolean
    displayName: string
    textFont: string
    textSize: string
    maxLength: string | null
    format: 'date' | 'email' | 'german area code' | 'phone' | null
    options: string[]
    optionsDisplayValue: string[]
  } {
    return {
      id: this.id,
      fieldName: this.fieldName,
      displayName: this.displayName,
      type: this.type,
      page: this.page,
      pageDimens: this.pageDimens,
      coords: this.coords,
      description: this.description,
      required: this.required,
      textFont: this.textFont,
      textSize: this.textSize,
      maxLength: this.maxLength,
      format: this.format,
      options: this.options,
      optionsDisplayValue: this.optionsDisplayValue,
    }
  }
}

export class IngestPDFInfoText {
  id: string
  title: string
  type: 'URL' | 'infotext'
  text: string
  page: number
  subsections: string[] // subsection to which infotext belongs
  coords: SyncedCoordinates
  constructor(
    id: string,
    title: string,
    type: 'URL' | 'infotext',
    text: string,
    page: number,
    coords: SyncedCoordinates,
    subsections?: string[],
  ) {
    this.id = id
    this.title = title
    this.type = type
    this.text = text
    this.page = page
    this.coords = coords
    this.subsections = subsections ? subsections : []
  }

  /**
   * Adds a subsection to the Infotext
   * @param subSectionId Id of an subsection
   */
  addSubSection(subSectionId: string): void {
    this.subsections.push(subSectionId)
  }

  /**
   * Removes a subsection from the Infotext if existend
   * @param subSectionId Id of an subsection
   */
  removeSubSection(subSectionId: string): void {
    this.subsections = this.subsections.filter((id) => id !== subSectionId)
  }

  toJSON(): {
    id: string
    title: string
    type: 'URL' | 'infotext'
    text: string
    page: number
    subsections: string[] // subsection to which infotext belongs
    coords: SyncedCoordinates
  } {
    return {
      id: this.id,
      title: this.title,
      type: this.type,
      text: this.text,
      page: this.page,
      subsections: this.subsections,
      coords: this.coords,
    }
  }
}

export class IngestPDFResult {
  // config properties - these are only used during Ingest process and should be removed using the cleanup function on ingest complete
  selectedId?: string // id of selected element (section, subsection, infotext or field)
  selectFieldDescriptionActive?: boolean // true if popup is hidden to select field description
  disableAutoFieldSelection?: boolean // toggles auto field selection in fields step

  // IngestPDFResult properties
  regions: {
    customOrder: boolean
    sectionOrder: string[]
    sections: { [key: string]: IngestPDFSection }
    subsections: { [key: string]: IngestPDFSubSection }
  }
  infotexts: {
    [key: string]: IngestPDFInfoText
  }
  fields: {
    [key: string]: IngestPDFField
  }

  constructor() {
    this.regions = { customOrder: false, sectionOrder: [], sections: {}, subsections: {} }
    this.infotexts = {}
    this.fields = {}
  }

  // ============== CONFIG PROPS ================

  /** Removes config properties that are only used during Ingest and are not needed for flow generation */
  cleanup(): void {
    delete this.selectedId
    delete this.disableAutoFieldSelection
    delete this.selectFieldDescriptionActive
  }

  setSelectFieldDescriptionActive(isActive: boolean): void {
    this.selectFieldDescriptionActive = isActive
  }

  setAutoFieldSelectionDisabled(disableAutoFieldSelection: boolean): void {
    this.disableAutoFieldSelection = disableAutoFieldSelection
  }

  setSelectedId(id: string): void {
    this.selectedId = id
  }

  /**
   * Sets section order
   * @param sectionOrder ordered section ids
   */
  setSectionOrder(sectionOrder: string[]): void {
    this.regions.sectionOrder = sectionOrder
  }

  /**
   * Add a section
   * @param section Section to be added
   */
  addSection(section: IngestPDFSection): void {
    this.regions.sections[section.id] = section
  }

  /**
   * Add a subsection and a reference into the parentSection
   * @param subsection The subsection to be added
   */
  addSubSection(subsection: IngestPDFSubSection): void {
    this.regions.subsections[subsection.id] = subsection
    // add reference to sections
    subsection.parentsections.forEach((parentSectionId) => {
      if (this.regions.sections[parentSectionId]) {
        // add parentsection reference
        this.regions.sections[parentSectionId].subsections.push(subsection.id)
      }
    })
  }

  addInfoText(infoText: IngestPDFInfoText): void {
    this.infotexts[infoText.id] = infoText
  }

  addField(field: IngestPDFField): void {
    this.fields[field.id] = field
  }

  removeSelectedId(): void {
    delete this.selectedId
  }

  getSection(id: string): IngestPDFSection | null {
    if (this.regions.sections[id]) return this.regions.sections[id]
    else return null
  }

  getSubSection(id: string): IngestPDFSubSection | null {
    if (this.regions.subsections[id]) return this.regions.subsections[id]
    else return null
  }

  getSectionsSubsetions(id: string): IngestPDFSubSection[] | null {
    const result = this.regions.sections[id].subsections.map((subSectionId) => {
      return this.regions.subsections[subSectionId]
    })
    return result
  }

  updateSection(section: IngestPDFSection): void {
    this.regions.sections[section.id] = section
    // FIXME do we need to do additional checks? ~ Jakob 19.10.2020
  }

  updateSubSection(subsection: IngestPDFSubSection): void {
    this.regions.subsections[subsection.id] = subsection
    // FIXME: do we need to update parentsections as well? ~ Jakob 19.10.2020
  }

  /**
   * Removes a section and its connected subsections
   * @param sectionId Id of a section
   */
  removeSection(sectionId: string): void {
    if (this.regions.sections[sectionId]) {
      // collect subsections that need to be deleted
      let subSections = this.regions.sections[sectionId].subsections
      // go through all sections (exklusive the given) and check if subsections of sectionId are included
      const sections = Object.keys(this.regions.sections).filter((id) => id !== sectionId)
      const subSectionsCopy = [...subSections]
      sections.forEach((id) => {
        subSectionsCopy.forEach((subsectionId) => {
          if (this.regions.sections[id].subsections.includes(subsectionId)) {
            subSections = subSections.filter((id) => id !== subsectionId)
          }
        })
      })
      delete this.regions.sections[sectionId]
      // remove all subsections which are in no other sections
      subSections.forEach((subsectionId) => {
        delete this.regions.subsections[subsectionId]
      })
    }
  }

  /**
   * Removes a subsection and its references in sections
   * @param subsectionId Id of a subsection
   */
  removeSubSection(subsectionId: string): void {
    // go through sections and remove from their subsections
    const sectionIds = Object.keys(this.regions.sections)
    sectionIds.forEach((sectionId) => {
      // remove subsections from subsections of sections
      if (this.regions.sections[sectionId].subsections.includes(subsectionId)) {
        this.regions.sections[sectionId].subsections = this.regions.sections[sectionId].subsections.filter(
          (id) => id !== subsectionId,
        )
      }
    })
    // remove from subsections
    delete this.regions.subsections[subsectionId]
  }

  removeInfoText(infoTextId: string): void {
    delete this.infotexts[infoTextId]
  }

  toJSON(): {
    selectedId?: string
    regions: {
      sectionOrder: string[]
      sections: { [key: string]: IngestPDFSection }
      subsections: { [key: string]: IngestPDFSubSection }
    }
    infotexts: {
      [key: string]: IngestPDFInfoText
    }
    fields: {
      [key: string]: IngestPDFField
    }
  } {
    return {
      selectedId: this.selectedId || '',
      regions: this.regions,
      infotexts: this.infotexts,
      fields: this.fields,
    }
  }
}
