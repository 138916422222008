import React, { useState, useEffect } from 'react'

// @mui/material components
import xzufiSearchLeistung from 'assets/img/modules/xzufi_search_leistung.svg'
import { makeStyles } from 'tss-react/mui'
import { Chart } from '../../../../../@types/Flowchart/types'
import SelectDropdown, { Option } from '../../../../../components/Dropdown/SelectDropdown'
import { Typography, useTheme } from '@mui/material'
import ContentContainer from '../ContentContainer'
import { useBotContext } from 'hooks/contexts/bot-context'
import { XzufiModule } from '../../../../../@types/BotInformation/types'
import CustomContentTooltip from 'components/Tooltips/CustomContentTooltip'
import VariablesAutosuggestSelect from '../Variables/VariablesAutosuggestSelect'
import { removeVariableFromNode } from 'utils/chartUtils'

const useStyles = makeStyles()((theme) => ({
  container: { height: '100%' },
  inputWithInfo: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  inputInfoIcon: {
    marginLeft: theme.spacing(1),
    height: '20px',
    color: theme.palette.grey[700],
  },
  moduleSelection: {
    marginTop: theme.spacing(4),
  },
  trackingContainer: {
    marginTop: theme.spacing(4),
  },
  trackHelpfulButtonContainer: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  variables: {
    marginTop: theme.spacing(4),
  },
  varSelectionContainer: {
    marginTop: theme.spacing(2),
  },
}))

enum VariableSelectionPurpose {
  CHOSEN_RESULT,
  CORRELATION_ID,
}

type SXzufiTrackSelectedAnswerProps = {
  chart: Chart
  setIsSaveDisabled: (isSaveDisabled: boolean) => void
  setStateCallback: (chart: Chart) => void
}

export default function SXzufiTrackSelectedAnswer({
  chart,
  setIsSaveDisabled,
  setStateCallback,
}: SXzufiTrackSelectedAnswerProps): React.ReactElement {
  const { classes } = useStyles()
  const theme = useTheme()
  const { bot } = useBotContext()

  // module select
  const [moduleOptions, setModuleOptions] = useState<Option[]>([])
  const [selectedModuleId, setSelectedModuleId] = useState<string>()
  // variables select
  const [varIdChosenResult, setVarIdChosenResult] = useState<string>() // id of variable for storing the user selected chosen result
  const [varIdCorrId, setVarIdCorrId] = useState<string>() // id of variable for storing the correlation id of the answer

  /**
   * Reads all xzufi modules configured for this bot and prepares them for the dropdown.
   * If only one xzufi module exists, sets it.
   */
  function prepareModuleOptions(): void {
    if (!bot) return

    const xzufiModules = Object.values(bot.modules).filter((module) => module.type === 'xzufi')
    const moduleOptions = xzufiModules.map((module) => {
      const { name, domain, moduleConfigId } = module as XzufiModule
      return {
        label: `${name} - ${domain}`,
        value: moduleConfigId,
      }
    })

    setModuleOptions(moduleOptions)
    if (moduleOptions.length === 1) {
      // only 1 possible module -> select it
      onModuleSelect({ label: '', value: moduleOptions[0].value })
    }
  }

  /**
   * Resets the chosen result variable.
   * Removes the node property and also removes variable completely from node.
   */
  function resetVariableForChosenResult(): void {
    const selectedId = chart.selected?.id
    if (typeof selectedId === 'undefined') return
    const node = chart.nodes[selectedId]
    if (typeof node === 'undefined') return

    const varIdToRemove = node.properties.xzufi?.xZufiChosenLeistungsId

    if (!varIdToRemove) return

    // delete node property
    delete node.properties.xzufi?.xZufiChosenLeistungsId
    chart.nodes[selectedId] = node

    // remove variable from node to ensure variable usage count is not messed up
    const newChart = removeVariableFromNode(chart, 'set', varIdToRemove, node.id)

    setStateCallback(newChart)
  }

  // ========= HANDLER ============

  /**
   * Handles module selection
   * @param newValue
   */
  function onModuleSelect(newValue: Option): void {
    const selectedId = chart.selected?.id
    if (typeof selectedId === 'undefined') return
    const node = chart.nodes[selectedId]
    if (typeof node === 'undefined') return

    const moduleId = newValue.value

    node.properties.moduleConfigId = moduleId

    // also set new text for display in dialog designer
    const module = bot?.modules[moduleId] as XzufiModule
    if (module) {
      node.properties.text = `${module.type} - ${module.name} - ${module.domain} - track Answer`
    }
    if (module) setSelectedModuleId(moduleId)

    chart.nodes[selectedId] = node
    setStateCallback(chart)
  }

  /**
   * Sets the variable in the node properties and updates the chart
   * @param selectedVarId
   * @param purpose
   */
  function onVariableSelect(newChart: Chart, purpose: VariableSelectionPurpose, selectedVarId?: string): void {
    const selectedId = newChart.selected?.id
    if (typeof selectedId === 'undefined') return
    const node = newChart.nodes[selectedId]
    if (typeof node === 'undefined') return

    if (!node.properties.xzufi) node.properties.xzufi = {}

    switch (purpose) {
      case VariableSelectionPurpose.CHOSEN_RESULT:
        node.properties.xzufi.xZufiChosenLeistungsId = selectedVarId
        break
      case VariableSelectionPurpose.CORRELATION_ID:
        node.properties.xzufi.corrId = selectedVarId
        break
    }

    newChart.nodes[selectedId] = node
    setStateCallback(newChart)
  }

  /**
   * Handles variable selection for search result (found leistungen)
   * @param newChart
   * @param prevSelectedVarIds
   * @param selectedVarIds
   */
  function onVariableForChosenResultSelect(
    newChart: Chart,
    prevSelectedVarIds: string[],
    selectedVarIds: string[],
  ): void {
    const selectedVariableId = selectedVarIds.length > 0 ? selectedVarIds[0] : undefined
    onVariableSelect(newChart, VariableSelectionPurpose.CHOSEN_RESULT, selectedVariableId)
  }

  /**
   * Handles variable selection for correlation id
   * @param newChart
   * @param prevSelectedVarIds
   * @param selectedVarIds
   */
  function onVariableForCorrIdSelect(newChart: Chart, prevSelectedVarIds: string[], selectedVarIds: string[]): void {
    const selectedVariableId = selectedVarIds.length > 0 ? selectedVarIds[0] : undefined
    onVariableSelect(newChart, VariableSelectionPurpose.CORRELATION_ID, selectedVariableId)
  }

  useEffect(
    function () {
      const selectedId = chart.selected?.id
      if (typeof selectedId === 'undefined') return
      // get already selected Event
      const node = chart.nodes[selectedId]

      prepareModuleOptions()
      setVarIdCorrId(node.properties.xzufi?.corrId)
      setVarIdChosenResult(node.properties.xzufi?.xZufiChosenLeistungsId)
    },
    [chart, bot],
  )

  useEffect(
    function () {
      if (selectedModuleId && varIdCorrId && varIdChosenResult) {
        setIsSaveDisabled(false)
      } else {
        setIsSaveDisabled(true)
      }
    },
    [selectedModuleId, varIdCorrId, varIdChosenResult],
  )

  return (
    <div className={classes.container}>
      <ContentContainer
        headerText='XZuFi Modul: gewähltes Ergebnis tracken'
        descriptionText=''
        svg={xzufiSearchLeistung}
      >
        <div className={classes.moduleSelection}>
          <div className={classes.inputWithInfo}>
            <Typography>XZuFi Modul</Typography>
            <CustomContentTooltip
              content={
                <Typography>
                  Bitte wählen Sie das XZuFi Modul Ihres Assistenten, für das die Nutzer gewählte Antwort getrackt
                  werden sollen.
                </Typography>
              }
              elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
            />
          </div>
          <SelectDropdown
            onChange={onModuleSelect}
            options={moduleOptions}
            selected={selectedModuleId}
            placeholder='XZuFi Modul auswählen'
            width='100%'
          />
        </div>

        {selectedModuleId && (
          <>
            <div className={classes.variables}>
              <div className={classes.varSelectionContainer}>
                <div className={classes.inputWithInfo}>
                  <Typography>Variable mit der vom Nutzer ausgewählten Leistungs ID</Typography>
                  <CustomContentTooltip
                    content={
                      <Typography>
                        Bitte wählen Sie die Variable, die die von den Nutzer*innen gewählte Leistungs ID beinhaltet.
                      </Typography>
                    }
                    elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
                  />
                </div>
                <VariablesAutosuggestSelect
                  chart={chart}
                  onChange={onVariableForChosenResultSelect}
                  usageType='set'
                  isMulti={false}
                  isCreatable
                  isClearable
                  selectedVariableIds={varIdChosenResult ? [varIdChosenResult] : []}
                />
              </div>
              <div className={classes.varSelectionContainer}>
                <div className={classes.inputWithInfo}>
                  <Typography>Variable mit Feedback-ID der Antwort</Typography>
                  <CustomContentTooltip
                    content={
                      <Typography>
                        Bitte wählen Sie die Variable, in der die Feedback-ID der Suchanfrage gespeichert wurde. Die
                        Feedback-ID wird verwendet, um bei der Bewertung der Suchergebnisse die korrekte Zuordnung zu
                        der Suche zu ermöglichen.
                      </Typography>
                    }
                    elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
                  />
                </div>
                <VariablesAutosuggestSelect
                  chart={chart}
                  onChange={onVariableForCorrIdSelect}
                  usageType='consume'
                  isMulti={false}
                  isCreatable
                  isClearable
                  selectedVariableIds={varIdCorrId ? [varIdCorrId] : []}
                />
              </div>
            </div>
          </>
        )}
      </ContentContainer>
    </div>
  )
}
