import React, { useState, useEffect } from 'react'

// @mui/material components
import { TextField, Button, Grid } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { SelectDropdown, Option, ActionMeta } from 'components/Dropdown'

import {
  getVariables,
  getAllVariablesForSelect,
  replaceVarIdWithDisplayName,
  replaceVarDisplayNameWithId,
} from '../../../../../utils/chartUtils'

import { Chart } from '../../../../../@types/Flowchart/types'

const useStyles = makeStyles()({
  selectedDialogContainer: {},
})

type SEventTriggerProps = {
  chart: Chart
  setIsSaveDisabledCallback: (saveDisabled: boolean) => void
  setStateCallback: (chart: Chart) => void
}

export default function SEventTrigger({
  chart,
  setIsSaveDisabledCallback,
  setStateCallback,
}: SEventTriggerProps): React.ReactElement {
  const { classes } = useStyles()

  // const [selectedVars, setSelectedVars] = useState<Option[]>()
  const [allVarOptions, setAllVarOptions] = useState<Option[]>([])
  const [displayValue, setDisplayValue] = useState<string>()
  const [event, setEvent] = useState<string>()
  const [tmpVariable, setTmpVariable] = useState<Option>()

  // ========== ID <-> DISPLAYNAME REPLACEMENT ============

  // TODO: Use utils functions for id <-> displayname replacement
  /**
   * Prepares variable value text that is displayed in the textfield.
   *
   * - replaces variable ids with their display name for better usability
   * - IDEA: we could remove all ', ", + etc. that are needed to construct a valid JS string and add them again when setting the text in the chart
   *
   * @param {Chart} chart
   */
  function prepareVariableValueText(chart: Chart): string {
    const selectedId = chart.selected?.id
    if (typeof selectedId === 'undefined') return ''

    // NO LOOP
    const value = chart.nodes[selectedId].properties.value || ''
    const displayValue = replaceVarIdWithDisplayName(chart, value)
    return displayValue
  }

  /**
   * Takes the displayed text and transforms it into the correct format for the flowchart.
   * Replaces variable display name with variable id
   *
   * @param {string} displayValue
   */
  function setVariableValueTextInChart(displayValue: string): void {
    const selectedId = chart.selected?.id
    if (typeof selectedId === 'undefined' || typeof displayValue === 'undefined') return

    const value = replaceVarDisplayNameWithId(chart, displayValue)
    chart.nodes[selectedId].properties.value = value
    setStateCallback({ ...chart })
  }

  // ========= HANDLER ============

  /**
   * Handles textfield text change
   * @param {React.ChangeEvent<HTMLInputElement>} event textchange event
   */
  function onTextChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setDisplayValue(event.target.value)
  }

  /**
   * Callback function for adding a tmp variable to the state.
   * This tmp variable can then be added to the textfield.
   *
   * value textfield (for using a variable's value to populate another variable).
   * Adds
   *
   * @param {string} varId of of the variable that is added
   */
  function onVariableToValueSelection(newValue: Option, action: ActionMeta<Option>): void {
    setTmpVariable(newValue)
  }

  /**
   * Adds selected tmp variable to value text and updates chart.
   */
  function onAddVariableToValue(): void {
    const selectedId = chart.selected?.id
    if (typeof selectedId === 'undefined' || typeof tmpVariable === 'undefined') return

    const newDisplayValue = displayValue
      ? displayValue + " + ', ' + " + "'%" + chart.variables[tmpVariable.value].displayName + "'"
      : "'%" + chart.variables[tmpVariable.value].displayName + "'"

    setVariableValueTextInChart(newDisplayValue)
    setDisplayValue(newDisplayValue)
    setTmpVariable(undefined)
  }

  function onEventNameChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const selectedId = chart.selected?.id
    if (typeof selectedId === 'undefined') return

    setEvent(event.target.value)
    // set event in chart
    chart.nodes[selectedId].properties.event = event.target.value
    setStateCallback({ ...chart })
  }

  function onEventValueBlur(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void {
    if (typeof displayValue !== 'undefined') setVariableValueTextInChart(displayValue)
  }

  function isEventNameIllegal(event: string): boolean {
    const format = /[^A-Za-z_]+/g
    if (format.test(event)) {
      // illegal
      setIsSaveDisabledCallback(true)
      return true
    } else {
      // fine
      setIsSaveDisabledCallback(false)
      return false
    }
  }

  useEffect(function () {
    const selectedId = chart.selected?.id
    if (typeof selectedId === 'undefined') return

    const event = chart.nodes[selectedId].properties.event
    const displayValue = prepareVariableValueText(chart)

    const selectedVars = getVariables(chart)

    let allVarOptions = getAllVariablesForSelect(chart)
    allVarOptions = allVarOptions.filter((variable) => !selectedVars.some((v) => v.value === variable.value))

    // setSelectedVar(selectedVars)
    setAllVarOptions(allVarOptions)
    setDisplayValue(displayValue)
    setEvent(event)
  }, [])

  return (
    <div className={classes.selectedDialogContainer}>
      <div>
        <div>
          Mit Event Triggern können Sie mit verbunden Plattformen oder Chat-Kanälen Informationen austauschen, ohne dass
          der Nutzer davon etwas mitbekommt. Zum Beispiel kann der Webchat eingestellt werden, dass eine bestimmte
          Aktion auf der Website durchgeführt wird, sobald ein bestimmtes Event ausgelöst wird.
        </div>
        <div style={{ marginBottom: '10px' }}>Bitte geben Sie einen Namen zur Identifikation des Events an:</div>
        {/* TODO: filter out everything except chars */}
        <TextField
          id='outlined-static'
          label='Event Name'
          value={event || ''}
          fullWidth
          onChange={onEventNameChange}
          margin='normal'
          InputProps={{
            autoComplete: 'off',
          }}
          helperText={
            isEventNameIllegal(event || '')
              ? 'Event-Namen dürfen nur Buchstaben und _ enthalten.'
              : `Event-Name für Webchat: EVENT_${event}`
          }
          error={isEventNameIllegal(event || '')}
        />
        <>
          <div style={{ marginTop: '15px' }}>
            <span>Bitte geben Sie den Wert des Events an. Dieser wird mit dem Event übermittelt.</span>
          </div>
          <TextField
            id='outlined-multiline-static'
            label='Event Wert'
            value={displayValue || ''}
            fullWidth
            onChange={onTextChange}
            onBlur={onEventValueBlur}
            margin='normal'
            disabled={!event || event.length === 0}
          />

          <Grid container direction='row' justifyContent='space-evenly' alignItems='center'>
            <Grid item xs={10} style={{ height: '100%' }}>
              <SelectDropdown
                onChange={onVariableToValueSelection}
                options={allVarOptions}
                selected={tmpVariable}
                placeholder='Variable auswählen'
                isSearchable
              />
            </Grid>

            <Grid item xs={2} style={{ height: '100%' }}>
              <div style={{ textAlign: 'center' }}>
                <Button
                  disabled={!tmpVariable || !event || event.length === 0}
                  onClick={onAddVariableToValue}
                  variant='outlined'
                >
                  Hinzufügen
                </Button>
              </div>
            </Grid>
          </Grid>
        </>
      </div>
    </div>
  )
}
