import React from 'react'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  comment: {
    fontSize: '32px',
    color: theme.palette.primary.main,
  },
}))

export default function Comment(): React.ReactElement {
  const { classes } = useStyles()
  return <i className={'ri-chat-quote-line ' + classes.comment} />
}
