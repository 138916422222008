import React, { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useTheme } from '@mui/material/styles'
import { Collapse, IconButton, Typography } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'

type StyleProps = {
  minHeightCollapsed?: string
  minHeightExpanded?: string
  heightCollapsed?: string
  heightExpanded?: string
  width?: string
  isCollapsed?: boolean
}

// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. ArrowFunctionExpression in CSS prop.
// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. ArrowFunctionExpression in CSS prop.
// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. ArrowFunctionExpression in CSS prop.
const useStyles = makeStyles<StyleProps>()((theme, props) => ({
  ensureMinHeight: {
    minHeight: props?.isCollapsed ? props?.minHeightCollapsed : props?.minHeightExpanded,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  container: {
    boxSizing: 'border-box',
    position: 'relative',
    borderRadius: `${
      typeof theme.shape.borderRadius === 'string'
        ? parseInt(theme.shape.borderRadius) * 3
        : theme.shape.borderRadius * 3
    }px`,
    background: theme.palette.background.paper,
    height: (props?.isCollapsed ? props?.heightCollapsed : props?.heightExpanded) ?? '100px',
    // minHeight: (props?: StyleProps): string | undefined => props?.minHeight,
    width: props?.width || '100px',
    padding: theme.spacing(4),
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '-30px',
      left: '-30px',
      right: '-30px',
      bottom: '-30px',
      zIndex: -1,
      background: theme.palette.grey.A100,
      opacity: 1,
      WebkitFilter: 'blur(90px)',
      filter: 'blur(90px)',
      borderRadius: `${
        typeof theme.shape.borderRadius === 'string'
          ? parseInt(theme.shape.borderRadius) * 3
          : theme.shape.borderRadius * 3
      }px`,
    },
  },
  titleContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    cursor: 'pointer',
  },
  iconButtonExpanded: {
    transform: 'scaleY(-1)',
  },
  iconButtonCollapsed: {
    transform: 'scaleY(1)',
  },
  iconButton: {
    // transform: 'rotate(90deg)',
    marginLeft: 'auto',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
}))

type CollapsibleCardProps = {
  title?: string | React.ReactNode
  children?: React.ReactNode // are hidden if card is collapsed
  minHeightCollapsed?: string
  minHeightExpanded?: string
  heightCollapsed?: string
  heightExpanded?: string
  width?: string
  isCollapsed?: boolean
  className?: string
  onExpandChange?: (isExpanded: boolean) => void
}

export default function CollapsibleCard({
  title,
  children,
  minHeightCollapsed,
  minHeightExpanded,
  heightCollapsed,
  heightExpanded,
  width,
  isCollapsed,
  className,
  onExpandChange,
}: CollapsibleCardProps): React.ReactElement {
  const { classes } = useStyles({
    minHeightCollapsed,
    minHeightExpanded,
    heightCollapsed,
    heightExpanded,
    width,
    isCollapsed,
  })
  const [expanded, setExpanded] = useState<boolean>(!isCollapsed)

  /**
   * Handles expand icon button click.
   */
  function onExpandClick(): void {
    setExpanded(!expanded)
    if (onExpandChange) onExpandChange(!expanded)
  }

  return (
    <div className={classes.ensureMinHeight}>
      <div className={className ? `${classes.container} ${className}` : classes.container}>
        <div className={classes.titleContainer} onClick={onExpandClick}>
          {typeof title === 'string' ? <Typography variant='h4'>{title}</Typography> : title}
          <IconButton
            // disableRipple
            className={`${classes.iconButton} ${expanded ? classes.iconButtonExpanded : classes.iconButtonCollapsed}`}
            onClick={onExpandClick}
            aria-expanded={expanded}
            aria-label={'Mehr anzeigen'}
          >
            <ExpandMore />
          </IconButton>
        </div>
        <Collapse in={expanded}>{children}</Collapse>
      </div>
    </div>
  )
}
