import Fuse from 'fuse.js'
import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation, useNavigate, useParams, useResolvedPath } from 'react-router-dom'

import { isEmpty } from 'lodash'
import { useBotContext } from '../../../../hooks/contexts/bot-context'
import { useErrorContext } from '../../../../hooks/contexts/errorContext'

import { translate as translateApi, translateBatch as translateBatchApi } from '../../../../api/StudioBackend'

// Custom Components
import ContentPage, { ContentPageHeader } from '../../../../components/Page/ContentPage'

import * as chartUtils from 'utils/chartUtils'
import { canBeTranslatedAutomatically, isSupportedLangauge } from 'utils/languageUtils'
import * as translationUtils from 'utils/translationsUtils'

// constants
import { APP_TITLE, LANGUAGE_DICTIONARY_DE, ROUTE_BOTID_TRANSLATIONS, ROUTE_BOTS } from '../../../../utils/constants'

import { IconButton, Typography } from '@mui/material'
import Warning from 'assets/img/knowledge/icons/warning'
import { Button } from 'components/Buttons'
import { Can } from 'components/Can/Can'
import BaseCard from 'components/Cards/BaseCard'
import CircularLoading from 'components/Loading/CircularLoading'
import Table from 'components/Table/Table'
import EditableTypography from 'components/TextInput/EditableTypography'
import { Textfield } from 'components/TextInput/Textfield'
import CustomizedTooltip from 'components/Tooltips/CustomContentTooltip'
import { useChartContext } from 'hooks/contexts/chart-context'
import { useLockingContext } from 'hooks/contexts/locking-context'
import { useStudioNotificationContext } from 'hooks/contexts/studio-notification-context'
import { useTranslationsContext } from 'hooks/contexts/translations-context'
import { makeStyles } from 'tss-react/mui'
import { BotEnvironment, BotInfos } from '../../../../@types/BotInformation/types'
import { TranslationFile, TranslationsLoading } from '../../../../@types/Translations/types'
import { Delete } from '@mui/icons-material'
import { Dialog } from 'components/Dialogs'

type Sort = 'missingFirst' | 'onlyAlphabetical'

type TranslationPair = {
  translationId: string
  text: string
  translation: string
}

type TranslationContent = {
  [translationId: string]: TranslationPair
}

const useStyles = makeStyles()((theme) => ({
  rowActionContainer: {
    display: 'flex',
  },
  iconButton: {
    marginLeft: theme.spacing(1),
    marginBottom: 'auto',
    marginTop: 'auto',
    padding: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  icon: { fontSize: '1.2rem' },
  rowTranslationContainer: {
    display: 'flex',
    width: '100%',
  },
  missingTranslationWarning: {
    marginBottom: 'auto',
    marginTop: 'auto',
  },
}))

export default function TranslationsTableView({ mode }: { mode: 'conversation' | 'webchat' }): React.ReactElement {
  const { classes } = useStyles()
  const url = useResolvedPath('').pathname
  const { setNotification } = useStudioNotificationContext()
  const { botId, langCode: languageCode } = useParams() as { botId: string; langCode: string }
  const navigate = useNavigate()

  const { chart } = useChartContext()
  const {
    translationFile,
    hasTranslationFileChanged,
    loadingTranslationFile,
    conversationTextsTransIds,
    webchatTextsTransIds,
    discardTranslationChanges,
    updateTextTranslation,
    updateTextTranslationBatch,
    saveTranslationFile,
    areAllTextsOfLanguageTranslated,
  } = useTranslationsContext()

  // lock state
  const { lockState, lockedBy, lockInitialized } = useLockingContext()
  // search
  const [searchString, setSearchString] = useState<string>()
  const [sort, setSort] = useState<Sort>('missingFirst')
  // table content
  const [editedTranslationId, setEditedTranslationId] = useState<string>()
  const [tableContent, setTableContent] = useState<TranslationContent>({})
  const [displayedTableContent, setDisplayedTableContent] = useState<TranslationContent>({})
  const [showDiscardDialog, setShowDiscardDialog] = useState<boolean>()
  // auto translations
  const [translating, setTranslating] = useState<'translating' | 'translatingBatch'>()

  /**
   * Prepares content for the table based on the translation file.
   * @param translations
   */
  function prepareTranslationTable(translations: TranslationFile, _sort?: Sort): void {
    if (!_sort) _sort = sort
    if (!translations.translations?.texts || !chart) return
    const primaryLanguage = translations.primaryLanguage

    const translationContent: TranslationContent = {}
    if (_sort === 'missingFirst') {
      // sort lists alphabetically based on primary language and put missing translations first
      const translatedList: TranslationPair[] = []
      const missingTranslationList: TranslationPair[] = []

      const transIds = mode === 'conversation' ? conversationTextsTransIds : webchatTextsTransIds

      for (const transId of transIds) {
        if (
          !translations.translations.texts[transId][primaryLanguage] ||
          typeof translations.translations.texts[transId][primaryLanguage] !== 'string' ||
          !translationUtils.shouldTranslationBeDisplayed(translations.translations.texts[transId][primaryLanguage])
        ) {
          // ignore if empty string or not a string at all
          continue
        }
        if (translations.translations.texts[transId][languageCode]) {
          // translation exists
          translatedList.push({
            translationId: transId,
            text: chartUtils.replaceVarIdWithDisplayName(
              chart,
              translations.translations.texts[transId][primaryLanguage],
            ),
            translation: chartUtils.replaceVarIdWithDisplayName(
              chart,
              translations.translations.texts[transId][languageCode],
            ),
          })
        } else {
          missingTranslationList.push({
            translationId: transId,
            text: chartUtils.replaceVarIdWithDisplayName(
              chart,
              translations.translations.texts[transId][primaryLanguage],
            ),
            translation: chartUtils.replaceVarIdWithDisplayName(
              chart,
              translations.translations.texts[transId][languageCode],
            ),
          })
        }
      }

      const sortedTranslatedList = translatedList.sort((a, b) => {
        if (a.text < b.text) return -1
        if (a.text > b.text) return 1
        return 0
      })
      const sortedMissingTransList = missingTranslationList.sort((a, b) => {
        if (a.text < b.text) return -1
        if (a.text > b.text) return 1
        return 0
      })

      for (const sortedMissing of sortedMissingTransList) {
        translationContent[sortedMissing.translationId] = sortedMissing
      }
      for (const sortedTrans of sortedTranslatedList) {
        translationContent[sortedTrans.translationId] = sortedTrans
      }
    } else {
      // only sort alphabetically
      const list: TranslationPair[] = []
      for (const transId of conversationTextsTransIds) {
        if (
          !translations.translations.texts[transId][primaryLanguage] ||
          typeof translations.translations.texts[transId][primaryLanguage] !== 'string'
        )
          // ignore if empty string or not a string at all
          continue

        list.push({
          translationId: transId,
          text: translations.translations.texts[transId][primaryLanguage],
          translation: translations.translations.texts[transId][languageCode],
        })
      }
      // sort
      const sortedList = list.sort((a, b) => {
        if (a.text < b.text) return -1
        if (a.text > b.text) return 1
        return 0
      })
      for (const item of sortedList) {
        translationContent[item.translationId] = item
      }
    }
    setDisplayedTableContent(translationContent)
    setTableContent(translationContent)
  }

  /**
   * Filters content of translation list based on search string and returns filtered content.
   * @param translationContent
   */
  function filterTranslationsForSearch(
    translationContent: TranslationContent,
    searchString?: string,
  ): TranslationContent {
    if (typeof searchString === 'undefined') return translationContent

    const fuseOptions = {
      shouldSort: true,
      threshold: 0.4,
      minMatchCharLength: 1,
      keys: ['text', 'translation'],
    }

    const translationPairs = Object.values(translationContent)
    const fuse = new Fuse(translationPairs ?? [], fuseOptions)
    const searchResults = fuse.search(searchString).map((result) => result.item)
    const displayedTranslationContent: TranslationContent = {}

    for (const item of searchResults) {
      displayedTranslationContent[item.translationId] = item
    }

    return displayedTranslationContent
  }

  /**
   * Translates the given text and updates translation file with translation.
   * @param text
   * @param translationId
   */
  async function translate(text: string, translationId: string): Promise<void> {
    try {
      setTranslating('translating')

      const translationResult = await translateApi(
        text,
        translationFile?.primaryLanguage || 'de',
        languageCode,
        botId,
        true,
      )
      const translation = translationResult?.translatedText
      if (!translation) throw new Error('Translation is undefined')

      // set translated text in table content to populated field and let user confirm
      const updatedTableContent = { ...tableContent }
      updatedTableContent[translationId].translation = translation
      setEditedTranslationId(translationId)
      setTableContent(updatedTableContent)
    } catch (err) {
      setNotification(
        'error',
        'Text konnte nicht übersetzt werden. Bitte versuchen Sie es in wenigen Augenblicken erneut.',
      )
    }
    setTranslating(undefined)
  }

  /**
   * Finds and translates all texts that are missing translation.
   * updateds translation file with new translations.
   */
  async function onTranslateAll(): Promise<void> {
    if (!translationFile?.translations?.texts) return

    setTranslating('translatingBatch')
    // find all missing translations
    const missingTexts: TranslationPair[] = []
    for (const row of Object.values(tableContent)) {
      if (!row.translation) missingTexts.push(row)
    }

    try {
      const translationResult = await translateBatchApi(
        missingTexts.map((row) => row.text),
        translationFile?.primaryLanguage || 'de',
        languageCode,
        botId,
        true,
      )
      if (!translationResult) throw new Error('Translation result is undefined')

      // set translated texts
      const { translatedTexts } = translationResult
      const updatedTableContent = { ...tableContent }

      const translationForTranslationFileUpdate: { translationId: string; newTranslation: string }[] = []
      for (const translatedText of translatedTexts) {
        for (const row of missingTexts) {
          if (row.text === translatedText.text) {
            translationForTranslationFileUpdate.push({
              translationId: row.translationId,
              newTranslation: translatedText.translatedText,
            })
            row.translation = translatedText.translatedText
          }
        }
      }
      // we need to update the local table state and also the translation file in the parent component
      setTableContent(updatedTableContent)
      updateTextTranslationBatch(translationForTranslationFileUpdate, languageCode)
    } catch (err) {
      setNotification(
        'error',
        'Texte konnten nicht übersetzt werden. Bitte versuchen Sie es in wenigen Augenblicken erneut.',
      )
    }

    setTranslating(undefined)
  }

  /**
   * Handles search string change.
   * @param event
   */
  function onSearchStringChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setSearchString(event.target.value)
  }

  /**
   * Sets edit mode for specific translation.
   * @param translationId
   */
  function onEdit(translationId: string): void {
    setEditedTranslationId(translationId)
  }

  /**
   * @param newTranslation
   * @param translationId
   */
  function onTranslationChange(newTranslation: string, translationId: string): void {
    if (!translationFile || !chart) return

    // update table content
    tableContent[translationId].translation = newTranslation

    setTableContent({ ...tableContent })
    setEditedTranslationId(undefined)
    updateTextTranslation(translationId, languageCode, newTranslation) // update text translation in translation file
  }

  useEffect(
    function updateSearchResults() {
      if (typeof searchString !== 'undefined') {
        if (searchString === '') setDisplayedTableContent(tableContent)
        else {
          const filteredTranslationContent = filterTranslationsForSearch(tableContent, searchString)
          setDisplayedTableContent(filteredTranslationContent)
        }
      }
    },
    [searchString],
  )

  useEffect(
    function navigateBackOnUnknownLanguage(): void {
      if (!translationFile?.languages.includes(languageCode)) {
        navigate(ROUTE_BOTS + '/' + botId + ROUTE_BOTID_TRANSLATIONS)
      }
    },
    [translationFile],
  )

  useEffect(
    function initializeTableContent() {
      if (translationFile && isEmpty(tableContent)) prepareTranslationTable(translationFile, sort)
    },
    [translationFile],
  )

  // Actions
  const SearchField = <Textfield value={searchString} placeholder='Suchen' onChange={onSearchStringChange} />

  const TranslateAllButton = (
    <CustomizedTooltip
      placement='bottom'
      disableInteractive
      content={
        <Typography variant='body1'>
          {!canBeTranslatedAutomatically(languageCode)
            ? 'Diese Sprache kann nicht automatisch übersetzt werden'
            : 'Alle fehlenden Texte automatisch übersetzen'}
        </Typography>
      }
      elements={
        <div>
          <Button
            size='normal'
            type='normal'
            icon='magic-line'
            iconType='remix'
            onClick={onTranslateAll}
            loading={translating === 'translatingBatch'}
            disabled={
              typeof translating !== 'undefined' ||
              typeof loadingTranslationFile !== 'undefined' || // TODO: probably needs refinement to be enabled if success state
              lockState !== 'canEdit' ||
              !canBeTranslatedAutomatically(languageCode) ||
              !translationFile ||
              areAllTextsOfLanguageTranslated(translationFile, languageCode, mode)?.complete
            }
          >
            Alle übersetzen
          </Button>
        </div>
      }
    />
  )

  const isSaveButtonDisabled =
    !hasTranslationFileChanged ||
    typeof loadingTranslationFile !== 'undefined' ||
    typeof translating !== 'undefined' ||
    lockState !== 'canEdit'

  const SaveButton = (
    <CustomizedTooltip
      placement='bottom'
      disableInteractive
      content={
        <Typography variant='body1'>
          {!isSaveButtonDisabled ? 'Änderungen speichern' : 'Keine Änderungen zum Speichern vorhanden'}
        </Typography>
      }
      elements={
        <div>
          <Can I='update' a='translations' passThrough>
            {(can: boolean): React.ReactElement => (
              <Button
                size='normal'
                type='success'
                icon='refresh-line'
                iconType='remix'
                onClick={saveTranslationFile}
                loading={loadingTranslationFile === 'saving'}
                disabled={!can || isSaveButtonDisabled}
              >
                Speichern
              </Button>
            )}
          </Can>
        </div>
      }
    />
  )

  const actions = [SearchField, TranslateAllButton, SaveButton]

  const TableHeader = (
    <tr>
      <div style={{ display: 'flex' }}>
        <Typography style={{ marginTop: 'auto', marginBottom: 'auto' }} fontWeight='bolder'>
          Übersetzung
        </Typography>

        <CustomizedTooltip
          placement='top'
          disableInteractive
          content={
            <Typography>
              {sort === 'onlyAlphabetical' ? 'Fehlende Übersetzungen nach vorne sortieren.' : 'Alphabetisch sortieren.'}
            </Typography>
          }
          elements={
            <IconButton
              onClick={(): void => {
                if (translationFile) {
                  // set new sort state and update table content
                  const newSort = sort === 'missingFirst' ? 'onlyAlphabetical' : 'missingFirst'
                  setSort(newSort)
                  prepareTranslationTable(translationFile, newSort)
                }
              }}
              aria-label='sort'
              className={classes.iconButton}
            >
              <i className={`${sort === 'onlyAlphabetical' ? 'ri-sort-desc' : 'ri-font-size'} ` + classes.icon}></i>
            </IconButton>
          }
        />
      </div>
    </tr>
  )

  return (
    <>
      <Helmet>
        <title>{APP_TITLE} - Übersetzungen</title>
      </Helmet>
      <ContentPage>
        <ContentPageHeader
          title={`${
            isSupportedLangauge(languageCode) ? LANGUAGE_DICTIONARY_DE[languageCode] : 'Sprache nicht unterstützt'
          }`}
          actions={actions}
          previousUrl={url.substring(0, url.lastIndexOf('/'))}
          previousUrlCallback={(): boolean => {
            if (hasTranslationFileChanged) setShowDiscardDialog(true)
            return !hasTranslationFileChanged
          }}
        />
        <BaseCard width={'100%'} height={'100%'} minHeight={'80vh'}>
          {loadingTranslationFile === 'loading' ? (
            <CircularLoading text='Übersetzungen werden geladen...' />
          ) : (
            <Table
              headers={['Text (Primärsprache)', TableHeader, 'Aktionen']}
              cellAlignPattern={['left', 'left', 'right']}
              width={['45%', '45%', '10%']}
              padding='medium'
              rows={Object.values(displayedTableContent).map((row, index) => [
                <Typography key={`text-${row.text}`}>{row.text}</Typography>,
                <div key={`translation-${row.text}`} className={classes.rowTranslationContainer}>
                  {/* Show warning icon if translation is missing */}
                  {(typeof row.translation === 'undefined' || row.translation === '') &&
                    row.translationId !== editedTranslationId && (
                      <div className={classes.missingTranslationWarning}>
                        <Warning
                          tooltip={<Typography>Es existiert noch keine Übersetzung für diesen Text.</Typography>}
                        />
                      </div>
                    )}
                  <EditableTypography
                    key={`text-${row.text}`}
                    value={row.translation}
                    variant='body1'
                    onChange={(newValue: string): void => onTranslationChange(newValue, row.translationId)}
                    hideEditButton
                    editMode={row.translationId === editedTranslationId}
                    height='auto'
                    width='100%'
                    maxWidth='100%'
                    placeholder={'Keine Übersetzung vorhanden'}
                  />
                </div>,
                <div className={classes.rowActionContainer} key={`actions-${index}`}>
                  {/* Translate button*/}
                  <div style={{ marginLeft: 'auto' }}>
                    <CustomizedTooltip
                      placement='top'
                      disableInteractive
                      content={<Typography>Übersetzen</Typography>}
                      elements={
                        <IconButton
                          onClick={(): Promise<void> => translate(row.text, row.translationId)}
                          aria-label='translate'
                          className={classes.iconButton}
                          disabled={
                            typeof translating !== 'undefined' ||
                            typeof loadingTranslationFile !== 'undefined' || // TODO probably needs more refinement
                            lockState !== 'canEdit' ||
                            !canBeTranslatedAutomatically(languageCode)
                          }
                        >
                          <i className={'ri-magic-line ' + classes.icon}></i>
                        </IconButton>
                      }
                    />
                  </div>
                  {/* Edit button */}
                  <div style={{ marginLeft: '16px' }}>
                    <CustomizedTooltip
                      placement='top'
                      disableInteractive
                      content={<Typography>Bearbeiten</Typography>}
                      elements={
                        <IconButton
                          onClick={(): void => onEdit(row.translationId)}
                          aria-label='edit'
                          className={classes.iconButton}
                          disabled={lockState !== 'canEdit'}
                        >
                          <i className={'ri-pencil-fill ' + classes.icon}></i>
                        </IconButton>
                      }
                    />
                  </div>
                </div>,
              ])}
            />
          )}
        </BaseCard>
        {/* {showTranslationsDialog && (
          <ConfirmPublishDialog
            onClose={(): void => setShowPublishTranslationsDialog(undefined)}
            onConfirm={onPublishTranslationsConfirm}
          />
        )} */}
        {/* Discard changes dialog */}
        <Dialog
          id='discard-changes-dialog'
          size='small'
          open={showDiscardDialog}
          closable
          onClose={(): void => setShowDiscardDialog(undefined)}
          title='Ungespeicherte Änderungen'
          primaryActionButton={
            <Button
              size='small'
              type='danger'
              icon={<Delete />}
              onClick={(): void => {
                // discard all changes and go back
                discardTranslationChanges()
                navigate(-1)
              }}
            >
              Verwerfen
            </Button>
          }
        >
          <Typography>
            Es existieren ungespeicherte Änderungen. Sind Sie sicher, dass Sie zurückgehen möchten? Die Änderungen
            werden dabei verworfen.
          </Typography>
        </Dialog>
      </ContentPage>
    </>
  )
}
