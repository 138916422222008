import React, { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Typography } from '@mui/material'

import { Textfield } from '../../../../../../components/TextInput/Textfield'

const useStyles = makeStyles()((theme) => ({
  textField: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
}))

type Step1DialogNameProps = {
  dialogName?: string
  onSetDialogName: (name: string) => void
}

export default function StepDialogName({ dialogName, onSetDialogName }: Step1DialogNameProps): React.ReactElement {
  const { classes } = useStyles()

  const [name, setName] = useState<string | undefined>(dialogName)

  function onChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setName(event.target.value)
    onSetDialogName(event.target.value)
  }

  return (
    <div>
      <Typography>
        Legen Sie den Namen des neuen Dialogs fest, der durch diese <em>Trigger</em>-Antwort gestartet werden soll.
        Dieser Name kann dann im Flowdesigner ausgewählt werden.
      </Typography>
      <Textfield
        onChange={onChange}
        value={name}
        multiline={false}
        placeholder='Name'
        className={classes.textField}
        label={name && name.length > 49 ? 'Name zu lang' : 'Dialogname'}
        helperText={`${name ? name.length : 0}/49`}
        inputProps={{ maxLength: 49 }}
        InputProps={{ autoComplete: 'off' }}
      />
    </div>
  )
}
