import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useTheme } from '@mui/material/styles'
import React, { useEffect, useState } from 'react'

// assets
import svgAnalyticsDiagram from 'assets/img/analytics/analytics_diagram.svg'
import ContentContainer from '../../ContentContainer'
import { SelectDropdown, Option, ActionMeta } from 'components/Dropdown'
import { selectAnalyticsEventInNode } from 'utils/chartUtils'
import { Chart } from '../../../../../../@types/Flowchart/types'

import { CONVAISE_ANALYTICS_EVENTS } from '../SAnalyticsTrigger'
import { Button } from 'components/Buttons'

const useStyles = makeStyles()({
  container: {
    height: '100%',
  },
  centeringContainer: {
    textAlign: 'center',
    width: '100%',
  },
  menuContainer: {
    display: 'flex',
    width: '100%',
    height: 'calc(100% - 60px)',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  menuImage1: {
    marginBottom: '50px',
  },
  builderActions: {
    height: '60px',
    marginTop: 'auto',
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'end',
  },
  card: {
    alignItems: 'center',
    // transition: 'box-shadow 0.3s',
    // '&:hover': {
    //   boxShadow:
    //     '0 14px 26px -12px rgb(0 0 0 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)', // '0px 2px 10px 2px rgba(0,0,0, 0.25)'
    // },
  },
})

type SelectCustomEventProps = {
  chart: Chart
  descriptionText: string
  onSelectEvent: (chart: Chart) => void
  onBackClick: () => void
  onCreateNewEvent: () => void
}

export default function SelectCustomEvent({
  chart,
  descriptionText,
  onSelectEvent: onSelectEventProps,
  onCreateNewEvent,
  onBackClick,
}: SelectCustomEventProps): React.ReactElement {
  const { classes } = useStyles()
  const theme = useTheme()

  const [selectedEventId, setSelectedEventId] = useState<string>()
  const [selectEventOptions, setSelectEventOptions] = useState<Option[]>(CONVAISE_ANALYTICS_EVENTS)

  function onSelectEvent(event: Option): void {
    const selectedNodeId = chart.selected.id
    if (!selectedNodeId) return
    const eventName = chart.customAnalyticsEvents[event.value].eventName
    const newChart = selectAnalyticsEventInNode(chart, 'customer', selectedNodeId, eventName)
    setSelectedEventId(event.value)
    onSelectEventProps(newChart)
  }

  useEffect(
    function () {
      // build dropdown options and set selected if event is already selected
      const selectedId = chart.selected.id
      if (!selectedId) return
      const selectedEventId = chart.nodes[selectedId].properties.analyticsEvent?.customEvent?.eventId
      setSelectedEventId(selectedEventId)
      const customEvents: Option[] = []
      for (const customEvent of Object.values(chart.customAnalyticsEvents ?? {})) {
        customEvents.push({
          // replace prepended "customer/" from event name
          label: customEvent.eventName.replace(/^(customer\/)/, ''),
          value: customEvent.eventId,
        })
      }
      setSelectEventOptions(customEvents)
    },
    [chart],
  )

  return (
    <ContentContainer
      headerText='Benutzerdefinierte Analytics'
      descriptionText={descriptionText}
      onBackClick={onBackClick}
      svg={svgAnalyticsDiagram}
    >
      <div className={classes.container}>
        <div className={classes.centeringContainer}>
          <Typography fontWeight='bolder' marginBottom={theme.spacing(1)}>
            Event auswählen
          </Typography>
        </div>
        <SelectDropdown
          onChange={(newValue: Option, action: ActionMeta<Option>): void => onSelectEvent(newValue)}
          isSearchable
          selected={selectedEventId}
          options={selectEventOptions}
          maxDisplayOptions={5}
          noOptionsMessage='Noch keine benutzerdefinierten Events'
          // width={'175px'}
          // height={'56px'}
        />
        <div className={classes.centeringContainer}>
          <Typography fontWeight='bolder' marginTop={theme.spacing(2)} marginBottom={theme.spacing(2)}>
            oder
          </Typography>

          <Button onClick={onCreateNewEvent}>Neues Event erstellen</Button>
        </div>
      </div>
    </ContentContainer>
  )
}
