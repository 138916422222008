import React from 'react'
import { Route, Routes, Navigate, useLocation } from 'react-router-dom'

// Material UI
import { makeStyles } from 'tss-react/mui'

// Custom Components
import ContentPage from 'components/Page/ContentPage'
import ErrorComponent from 'components/Error/Error'
// Assets
// hooks
// constants
import { ROUTE_BOTID_DOCUMENTS_EDIT, ROUTE_BOTID_DOCUMENTS_PDFINGEST } from 'utils/constants'
import { DocumentsOverview } from './DocumentsOverview'
import Ingest from 'pages/PdfFormIngest'
// import { CustomNotificationOverview } from './Overview/CustomNotificationOverview'
// import EditCustomNotification from './EditCustomNotification/EditCustomNotification'

const useStyles = makeStyles()({})

export default function Documents(): React.ReactElement {
  const { pathname: path } = useLocation()

  return (
    <ErrorComponent errorCode='CustomNotifications.loadNotificationsError'>
      {/* Routes immer in Switch */}
      <Routes>
        <Route
          path={''}
          element={
            <ContentPage>
              {/* Menu */}
              {/* <CustomNotificationOverview /> */}
              <DocumentsOverview />
            </ContentPage>
          }
        />

        <Route path={ROUTE_BOTID_DOCUMENTS_PDFINGEST + '/*'} element={<Ingest />} />
        <Route
          path={ROUTE_BOTID_DOCUMENTS_EDIT + '/*'}
          element={
            <ContentPage>
              {/* <EditCustomNotification /> */}
              <p>Work in Progress</p>
            </ContentPage>
          }
        />
        <Route path='*' element={<Navigate to={path} />} />
      </Routes>
    </ErrorComponent>
  )
}
