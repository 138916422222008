import React, { useState } from 'react'
import { v4 as uuid } from 'uuid'

// material-ui
import { makeStyles } from 'tss-react/mui'

import { ArrowForward, Check } from '@mui/icons-material'
import { Typography } from '@mui/material'
// utils
import { lowercaseAndRemoveNonAlphaNumeric } from 'utils/stringUtils'
// custom components
import { Dialog } from 'components/Dialogs'
import { Button } from 'components/Buttons'
import { Textfield } from 'components/TextInput/Textfield'
// types
import { AnswerTemplateData, AnswerTemplateDataObject } from '../../../../../@types/Knowledge/types'

const useStyles = makeStyles()((theme) => ({
  textField: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
}))

type NextButtonProps = {
  onFinalClick: () => void
  disabled: boolean
}

function NextButton({ onFinalClick, disabled }: NextButtonProps): React.ReactElement {
  return (
    <Button type='success' size='small' icon={<Check />} onClick={onFinalClick} disabled={disabled}>
      Erstellen
    </Button>
  )
}

export type SimilarAnswerState = 'ok' | 'overwrite' | 'similarExists'

type CreateCategoryDialogProps = {
  answerTemplateData: AnswerTemplateData
  onClose: () => void
  onCreateCategory: (newCategory: AnswerTemplateDataObject) => void
}

export default function CreateCategoryDialog({
  answerTemplateData,
  onClose,
  onCreateCategory: onCreateCategoryCallback,
}: CreateCategoryDialogProps): React.ReactElement {
  const [nextDisabled, setNextDisabled] = useState<boolean>(true)
  const [topicName, setTopicName] = useState<string>('')
  const [error, setError] = useState<string>()
  const { classes } = useStyles()

  /**
   * Checks whether there already exists a category with that name.
   */
  function doesNameExist(name: string): boolean {
    for (const topicObj of answerTemplateData) {
      if (topicObj.topic.displayName === name) return true
    }
    return false
  }

  function onChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setTopicName(event.target.value)
    if (event.target.value.length === 0) {
      setError(undefined)
      setNextDisabled(true)
    } else if (doesNameExist(event.target.value)) {
      setError('Es existiert bereits eine Kategorie mit diesem Namen!')
      setNextDisabled(true)
    } else if (/^[0-9]+$/.test(event.target.value.substring(0, 1))) {
      setError('Der Kategoriename darf nicht mit einer Zahl beginnen.')
      setNextDisabled(true)
    } else if (event.target.value.substring(0, 1) === '/') {
      setError('Der Kategoriename darf nicht mit einem "/" beginnen.')
      setNextDisabled(true)
    } else if (event.target.value.substring(0, 1) === ' ') {
      setError('Der Kategoriename darf nicht mit einem "Leerzeichen" beginnen.')
      setNextDisabled(true)
    } else if (!/^[A-Za-zÀ-ȕ0-9- ]+$/g.test(event.target.value)) {
      setError('Der Kategoriename darf keine Sonderzeichen außer "leerzeichen" und "-" beinhalten.')
      setNextDisabled(true)
    } else if (/\s+$/.test(event.target.value)) {
      setError('Der Kategoriename darf nicht mit einem Leerzeichen enden.')
      setNextDisabled(true)
    } else {
      setError(undefined)
      setNextDisabled(false)
    }
  }

  /**
   * Uses category name and datapoints structure from state to build category object.
   * Adds category object via callback function from props.
   */
  function onCreateTopic(): void {
    const cleanTopicName = lowercaseAndRemoveNonAlphaNumeric(topicName)

    const topicObj: AnswerTemplateDataObject = {
      topic: {
        name: cleanTopicName,
        displayName: topicName,
      },
      data: [],
    }
    onCreateCategoryCallback(topicObj)
  }

  return (
    <Dialog
      id='create-category-dialog'
      size='medium'
      open
      closable
      onClose={onClose}
      title={'Kategorie benennen'}
      primaryActionButton={<NextButton onFinalClick={onCreateTopic} disabled={nextDisabled} />}
    >
      <Typography>Geben Sie der neuen Datenkategorie einen aussagekräftigen Namen.</Typography>
      <form
        onSubmit={(): void => {
          if (!error) {
            onCreateTopic()
          }
        }}
      >
        <Textfield
          onChange={onChange}
          autoFocus
          value={topicName}
          multiline={false}
          placeholder='Bitte eingeben'
          className={classes.textField}
          error={!!error}
          helperText={error ? error : ''}
          label={'Kategoriename'}
          InputProps={{ autoComplete: 'off' }}
        />
      </form>
    </Dialog>
  )
}
