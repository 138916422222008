import { AbilityBuilder, Ability } from '@casl/ability'

type Actions = 'manage' | 'save' | 'export' | 'publish' | 'read' | 'update' | 'create' | 'delete' | 'use' // FIXME: remove old Actions and replace in existing code
type Subjects =
  | 'all'
  | 'floweditor'
  | 'bot'
  | 'smartcards'
  | 'knowledge'
  | 'analytics'
  | 'websitetracking'
  | 'translations'
  | 'notifications'
  | 'feature_textsimplification'
  | 'documents_pdf'

type idTokenClaims = {
  extension_per_smartcards: string
  extension_per_floweditor: string
  extension_per_knowledge: string
  extension_per_analytics: string
  extension_per_bot: string
  extension_per_websitetracking: string
  extension_per_translations: string
  extension_per_notifications: string
  extension_per_feature_textsimplification: string
  extension_per_documents_pdf: string
}

export type ContextType = Ability<[Actions, Subjects]> | undefined

const { build } = new AbilityBuilder<Ability<[Actions, Subjects]>>(Ability)

// can('manage', 'all')
// can('save', 'Flow')
// can('publish', 'Flow')
// can('export', 'Bot')

// Export default abilities aka no abilities

export const ability = build()

/**
 * Update the ability based on the users token claims from the AAD
 * @param ability
 * @param idTokenClaims
 */
export function updatePermissions(ability, idTokenClaims): void {
  const { can, rules } = new AbilityBuilder<Ability<[Actions, Subjects]>>(Ability)
  // parse and create permissions based on the claims
  const per = preprocessPermissions(idTokenClaims)
  // set new rules for the user
  ability.update(definePermissionsFor(per, can, rules))
}

export function resetPermissions(ability): void {
  const { rules } = new AbilityBuilder<Ability<[Actions, Subjects]>>(Ability)
  ability.update(rules)
}

/**
 * Takes the idTokenClaims from the AAD and returns an object and array of the users permissions
 * @param idTokenClaims
 * @returns
 */
function preprocessPermissions(idTokenClaims: idTokenClaims) {
  const per = {}
  if (idTokenClaims['extension_per_smartcards']) {
    try {
      per['extension_per_smartcards'] = JSON.parse(idTokenClaims['extension_per_smartcards'])
    } catch (err) {
      console.error('[Ability] ', err)
    }
  }
  if (idTokenClaims['extension_per_floweditor']) {
    try {
      per['extension_per_floweditor'] = JSON.parse(idTokenClaims['extension_per_floweditor'])
    } catch (err) {
      console.error('[Ability] ', err)
    }
  }
  if (idTokenClaims['extension_per_knowledge']) {
    try {
      per['extension_per_knowledge'] = JSON.parse(idTokenClaims['extension_per_knowledge'])
    } catch (err) {
      console.error('[Ability] ', err)
    }
  }
  if (idTokenClaims['extension_per_analytics']) {
    try {
      per['extension_per_analytics'] = JSON.parse(idTokenClaims['extension_per_analytics'])
    } catch (err) {
      console.error('[Ability] ', err)
    }
  }
  if (idTokenClaims['extension_per_bot']) {
    try {
      per['extension_per_bot'] = JSON.parse(idTokenClaims['extension_per_bot'])
    } catch (err) {
      console.error('[Ability] ', err)
    }
  }
  if (idTokenClaims['extension_per_websitetracking']) {
    try {
      per['extension_per_websitetracking'] = JSON.parse(idTokenClaims['extension_per_websitetracking'])
    } catch (err) {
      console.error('[Ability] ', err)
    }
  }
  if (idTokenClaims['extension_per_translations']) {
    try {
      per['extension_per_translations'] = JSON.parse(idTokenClaims['extension_per_translations'])
    } catch (err) {
      console.error('[Ability] ', err)
    }
  }
  if (idTokenClaims['extension_per_feature_textsimplification']) {
    try {
      per['extension_per_feature_textsimplification'] = JSON.parse(
        idTokenClaims['extension_per_feature_textsimplification'],
      )
    } catch (err) {
      console.error('[Ability] ', err)
    }
  }
  if (idTokenClaims['extension_per_notifications']) {
    try {
      per['extension_per_notifications'] = JSON.parse(idTokenClaims['extension_per_notifications'])
    } catch (err) {
      console.error('[Ability] ', err)
    }
  }
  if (idTokenClaims['extension_per_documents_pdf']) {
    try {
      per['extension_per_documents_pdf'] = JSON.parse(idTokenClaims['extension_per_documents_pdf'])
    } catch (err) {
      console.error('[Ability] ', err)
    }
  }
  return per
}

/**
 * defines the ruleset for the different user permissions
 *
 * Version: 04.03.2021
 * per with the most permissions is:
 * {
 *  extension_per_smartcards: ['read', 'update', 'create', 'delete'],
 *  extension_per_floweditor: ['read', 'update'],
 *  extension_per_knowledge: ['read', 'update', 'create', 'delete'],
 *  extension_per_analytics: ['read'],
 *  extension_per_bot: ['read', 'update', 'create', 'delete'],
 *  extension_per_websitetracking: ['read', 'update', 'create', 'delete'],
 *  extension_per_translations: ['read', 'update', 'create', 'delete']
 *  extension_per_notifications: ['read', 'update', 'create', 'delete']
 *  extension_per_feature_textsimplification: ['use'] // IDEA: we could rename this to extension_per_feature and have feature in array instead of 'use'
 * }
 *
 * @param {JSON} per object of the AAD B2C idToken
 */
function definePermissionsFor(per, can, rules) {
  if (per) {
    // SmartCards
    if (per['extension_per_smartcards']) {
      if (per['extension_per_smartcards'].includes('read')) {
        can('read', 'smartcards')
      }
      if (per['extension_per_smartcards'].includes('update')) {
        can('update', 'smartcards')
      }
      if (per['extension_per_smartcards'].includes('create')) {
        can('create', 'smartcards')
      }
      if (per['extension_per_smartcards'].includes('delete')) {
        can('delete', 'smartcards')
      }
    }

    // FlowEditor
    if (per['extension_per_floweditor']) {
      if (per['extension_per_floweditor'].includes('read')) {
        can('read', 'floweditor')
      }
      if (per['extension_per_floweditor'].includes('update')) {
        can('update', 'floweditor')
      }
    }

    // Knowledge Management
    if (per['extension_per_knowledge']) {
      if (per['extension_per_knowledge'].includes('read')) {
        can('read', 'knowledge')
      }
      if (per['extension_per_knowledge'].includes('update')) {
        can('update', 'knowledge')
      }
      if (per['extension_per_knowledge'].includes('create')) {
        can('create', 'knowledge')
      }
      if (per['extension_per_knowledge'].includes('delete')) {
        can('delete', 'knowledge')
      }
    }

    // Analytics
    if (per['extension_per_analytics'] && per['extension_per_analytics'].includes('read')) {
      can('read', 'analytics')
    }

    // Bot
    if (per['extension_per_bot']) {
      if (per['extension_per_bot'].includes('read')) {
        can('read', 'bot')
      }
      if (per['extension_per_bot'].includes('update')) {
        can('update', 'bot')
      }
      if (per['extension_per_bot'].includes('create')) {
        can('create', 'bot')
      }
      if (per['extension_per_bot'].includes('delete')) {
        can('delete', 'bot')
      }
    }

    // WebisteTracking
    if (per['extension_per_websitetracking']) {
      if (per['extension_per_websitetracking'].includes('read')) {
        can('read', 'websitetracking')
      }
      if (per['extension_per_websitetracking'].includes('update')) {
        can('update', 'websitetracking')
      }
      if (per['extension_per_websitetracking'].includes('create')) {
        can('create', 'websitetracking')
      }
      if (per['extension_per_websitetracking'].includes('delete')) {
        can('delete', 'websitetracking')
      }
    }

    // Translations
    if (per['extension_per_translations']) {
      if (per['extension_per_translations'].includes('read')) {
        can('read', 'translations')
      }
      if (per['extension_per_translations'].includes('update')) {
        can('update', 'translations')
      }
      if (per['extension_per_translations'].includes('create')) {
        can('create', 'translations')
      }
      if (per['extension_per_translations'].includes('delete')) {
        can('delete', 'translations')
      }
    }

    // Notifications
    if (per['extension_per_notifications']) {
      if (per['extension_per_notifications'].includes('read')) {
        can('read', 'notifications')
      }
      if (per['extension_per_notifications'].includes('update')) {
        can('update', 'notifications')
      }
      if (per['extension_per_notifications'].includes('create')) {
        can('create', 'notifications')
      }
      if (per['extension_per_notifications'].includes('delete')) {
        can('delete', 'notifications')
      }
    }

    // Templates
    // Process Templates
    if (per['extension_per_template_process']) {
      if (per['extension_per_template_process'].includes('read')) {
        can('read', 'process_templates')
      }
      if (per['extension_per_template_process'].includes('update')) {
        can('update', 'process_templates')
      }
      if (per['extension_per_template_process'].includes('create')) {
        can('create', 'process_templates')
      }
      if (per['extension_per_template_process'].includes('delete')) {
        can('delete', 'process_templates')
      }
    }

    // Documents
    // PDF
    if (per['extension_per_documents_pdf']) {
      if (per['extension_per_documents_pdf'].includes('read')) {
        can('read', 'documents_pdf')
      }
      if (per['extension_per_documents_pdf'].includes('update')) {
        can('update', 'documents_pdf')
      }
      if (per['extension_per_documents_pdf'].includes('create')) {
        can('create', 'documents_pdf')
      }
      if (per['extension_per_documents_pdf'].includes('delete')) {
        can('delete', 'documents_pdf')
      }
    }

    // Features
    // Text Simplification
    if (per['extension_per_feature_textsimplification']) {
      if (per['extension_per_feature_textsimplification'].includes('use')) {
        can('use', 'feature_textsimplification')
      }
    }
  }

  return rules
}
