import React from 'react'

import { CardTypeEnum } from '../../../../../../@types/SmartCards/types'

// @mui/material components
import { makeStyles } from 'tss-react/mui'

import { useTheme } from '@mui/material/styles'
import { Grid, Typography, IconButton } from '@mui/material'
// custom components
import BaseCard from 'components/Cards/BaseCard'
// assets
import svgAction from 'assets/img/flowDesigner/cardTypeAction.svg'
import svgInfo from 'assets/img/flowDesigner/cardTypeInfo.svg'
import svgInput from 'assets/img/flowDesigner/cardTypeInput.svg'
import svgCustom from 'assets/img/flowDesigner/cardTypeCustom.svg'

const useStyles = makeStyles()((theme) => ({
  menuContainer: {
    display: 'flex',
    // height: '100%',
    alignItems: 'center',
  },
  card: {
    alignItems: 'center',
  },
  cardDisabled: {},
  title: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    alignItems: 'center',
  },
  backButton: {
    margin: `auto ${theme.spacing(1)} auto 0`,
  },
  menuImage1: {
    maxWidth: '150px',
    margin: 'auto',
  },
}))

type ChooseCardTypeTemplateProps = {
  setNext: (type: CardTypeEnum) => void
  onBackClick: () => void
}

// TODO: choose card

/**
 * Component to choose card type
 * @param props
 * @returns
 */
export default function ChooseCardTypeTemplate({
  setNext,
  onBackClick,
}: ChooseCardTypeTemplateProps): React.ReactElement {
  const { classes } = useStyles()
  const theme = useTheme()

  return (
    <>
      <div className={classes.title}>
        <IconButton className={classes.backButton} onClick={onBackClick}>
          <i className={`ri-arrow-left-line`} />
        </IconButton>
        <Typography>Bitte wählen Sie ein Smart Card Vorlage aus.</Typography>
      </div>
      <Grid container direction='row' alignItems='flex-start' spacing={5} className={classes.menuContainer}>
        <Grid item xs={12} sm={12} md={6} lg={6} key={CardTypeEnum.ActionChoiceCard}>
          <BaseCard
            titleVariant='h3'
            title='Aktions-Card'
            footerText='Auswählen'
            footerColor={theme.palette.warning.main}
            onClick={(): void => setNext(CardTypeEnum.ActionChoiceCard)}
            shadowWidth={20}
            shadowBlur={20}
            className={classes.card}
          >
            <img className={classes.menuImage1} src={svgAction} alt={'Aktionen abstrakt dargestellt'} />
          </BaseCard>
        </Grid>
        {/* 2st Card to create a new Card */}
        {/* <Grid item xs={12} sm={12} md={6} lg={6} key={CardTypeEnum.ContentCard}>
          <BaseCard
            titleVariant='h3'
            title='Informations-Card'
            footerText='Auswählen'
            footerColor={theme.palette.warning.main}
            onClick={(): void => setNext(CardTypeEnum.ContentCard)}
            shadowWidth={20}
            shadowBlur={20}
            className={classes.card + ' ' + classes.cardDisabled}
            disabled
          >
            <img className={classes.menuImage1} src={svgInfo} alt={'Informationen abstrakt dargestellt'} />
          </BaseCard>
        </Grid> */}
        <Grid item xs={12} sm={12} md={6} lg={6} key={CardTypeEnum.InputCard}>
          <BaseCard
            titleVariant='h3'
            title='Abfragen-Card'
            footerText='Auswählen'
            footerColor={theme.palette.warning.main}
            onClick={(): void => setNext(CardTypeEnum.InputCard)}
            shadowWidth={20}
            shadowBlur={20}
            className={classes.card}
          >
            <img className={classes.menuImage1} src={svgInput} alt={'Eingaben abstrakt dargestellt'} />
          </BaseCard>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} key={CardTypeEnum.CustomCard}>
          <BaseCard
            titleVariant='h3'
            title='Benutzerdefiniert'
            footerText='Auswählen'
            footerColor={theme.palette.warning.main}
            onClick={(): void => setNext(CardTypeEnum.CustomCard)}
            shadowWidth={20}
            shadowBlur={20}
            className={classes.card}
          >
            <img className={classes.menuImage1} src={svgCustom} alt={'Einstellungen abstrakt dargestellt'} />
          </BaseCard>
        </Grid>
      </Grid>
    </>
  )
}
