import React, { memo, useEffect, useState } from 'react'
import { Grid, Typography, useTheme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import EditableTypography from 'components/TextInput/EditableTypography'
import { Answer, TriggerAnswerWithoutIntent, buildAnswerObject } from 'classes/Knowledge'
import RMEEditor from 'components/TextEditors/editor-mdx-markdown'
import CustomizedTooltip from 'components/Tooltips/CustomContentTooltip'
import EditAnswerUtterances from './Components/EditAnswerUtterances'
import EditAnswerTranslations from './Components/EditAnswerTranslations'
import { simplifyText as simplifyTextAPI } from 'api/StudioBackend'
import TextSimplificationDialog from './Components/TextSimplificationDialog'
import { Can } from 'components/Can/Can'
import AnswerPreview from 'components/Previews/answerPreview'
import BaseCard from 'components/Cards/BaseCard'
import { IntentTriggerButtons } from '../../../../../@types/Knowledge/types'
import { Button } from 'components/Buttons'
import { useAnswers } from '../../../../../hooks/contexts/answers-context'
import { useBotContext } from '../../../../../hooks/contexts/bot-context'
import { useLockingContext } from 'hooks/contexts/locking-context'
import EditAnswerDialogName from './Components/EditAnswerDialogName'
import EditAnswerIntentTriggerButtons from './Components/EditAnswerIntentTriggerButtons'
import { useParams } from 'react-router-dom'
import { useDatamanagementContext } from 'hooks/contexts/datamanagement-context'
import { fillAnswerTemplate } from 'utils/answerUtils'
import GoodAnswersTips from './Components/GoodAnswersTips'

const useStyles = makeStyles()((theme) => ({
  root: { display: 'flex', flexDirection: 'row', height: '100%', marginBottom: theme.spacing(4) },
  title: { display: 'flex', width: 'fit-content' },
  titleIconButton: { marginLeft: 'auto' },
  tooltip: { width: 'fit-content' },
  flexContainer: { display: 'flex' },
  editAnswerContainer: {
    height: '100%',
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
  },
  editAnswerItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  editAnswerTextfield: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  userHintIcon: {
    fontSize: '1.5rem',
    marginRight: theme.spacing(1),
    color: theme.palette.error.main,
  },
  editAnswerTips: {
    marginTop: 'auto',
  },
  editAnswerTipContainer: {
    display: 'flex',
    flexDirection: 'row',
    '& h3': {
      marginRight: theme.spacing(1),
    },
    '& p': {
      marginTop: '0.4rem',
    },
  },
  answerPreviewContainer: {
    height: '100%',
    overflow: 'auto',
  },
  answerPreviewTitle: {
    marginTop: theme.spacing(4),
  },
  answerPreviewCard: {
    marginRight: theme.spacing(8),
  },
  answerEditMenu: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginBottom: theme.spacing(4),
    '& > div:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
  answerEdit: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  questionContainer: { marginTop: theme.spacing(4) },
  questions: { display: 'flex', flexDirection: 'column', width: '100%', height: '100%' },
  suggestions: { display: 'flex', flexDirection: 'column', height: '100%' },
  suggestionsContent: {
    // marginTop: theme.spacing(3)
  },
  suggestionChip: { marginRight: theme.spacing(2) },
  suggestionsInput: { marginTop: 'auto' },
  textfield: { width: '100%' },
  icon: {
    fontSize: '1.5rem',
  },
  markdown: { '& p': { margin: '0px !important' } },
  actionContainer: {
    display: 'flex',
    width: '100%',
    maxHeight: '200px',
    overflow: 'auto',
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius:
      typeof theme.shape.borderRadius === 'number' ? theme.shape.borderRadius * 2 : theme.shape.borderRadius,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  actionContainerRed: {
    color: theme.palette.error.main,
  },
  action: {
    width: '100%',
  },
  removeButton: {
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
  },
  removeActionTooltip: {
    display: 'inline-block',
    textAlign: 'right',
  },
  addActionContainer: {
    display: 'flex',
    width: '100%',
    maxHeight: '200px',
    overflow: 'auto',
    border: `1px solid ${theme.palette.grey[500]}`,
    cursor: 'pointer',
    borderRadius:
      typeof theme.shape.borderRadius === 'number' ? theme.shape.borderRadius * 2 : theme.shape.borderRadius,
    padding: `calc( ${theme.spacing(2)} - 1px)`,
    marginTop: 'auto',
    '&:hover, :active': {
      background: theme.palette.grey[100],
    },
  },
  translationContainer: {},
  markdownEditorTextfieldLabel: {
    marginTop: theme.spacing(1),
  },
  markdownEditorTextfieldInputContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  iconButton: {
    marginLeft: theme.spacing(1),
    marginBottom: 'auto',
    marginTop: 'auto',
    padding: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  iconButtonContainer: {
    display: 'flex',
  },
  inputHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
}))

type UserHint = {
  answerTemplate?: string
}

/** NOTE: we could have the tab menu set a qeury parameter so each menu has an url base on this: https://medium.com/@kate.pavlova/how-to-set-or-get-query-parameters-in-react-router-7d6e2440ede8
 *  Ist aber mit unnötig viel Aufwand für wenig Mehrwert verbunden -> kann man iwann mal machen
 */

type EditKnowledgeAnswerProps = {
  answer: Answer
  onEditAnswerCallback: (answer: Answer) => void
}

export default memo(function EditTriggerAnswer({
  answer: propsAnswer,
  onEditAnswerCallback,
}: EditKnowledgeAnswerProps): React.ReactElement {
  const { classes } = useStyles()
  const theme = useTheme()
  const { answerTemplateData, doAllVariablesInTextExist } = useDatamanagementContext()
  const { updateAnswer, hasAnswerChanged, canIEditAnswer } = useAnswers()
  const { bot, getLanguages } = useBotContext()
  const { botId } = useParams() as { botId: string }
  const { lockState } = useLockingContext()
  const [answer, setAnswer] = useState<Answer>(propsAnswer)
  const [answerTemplate, setAnswerTemplate] = useState<string>(propsAnswer.answerTemplate)
  // const [answerTranslations, setAnswerTranslations] = useState<{ [lang: string]: AnswerTranslation }>({})
  const [title, setTitle] = useState<string>(propsAnswer.title)
  const [triggerDialogName, setTriggerDialogName] = useState<string>(propsAnswer.triggerDialogName ?? '')
  const [intentTriggerButtons, setIntentTriggerButtons] = useState<IntentTriggerButtons>(
    answer.intentTriggerButtons ?? { startDialogButton: '' },
  )
  const [tab, setTab] = useState<'edit-text' | 'edit-dialog' | 'edit-questions' | 'edit-buttons' | 'edit-translations'>(
    'edit-text',
  )
  // text simplification
  const [showTextSimplificationDialog, setShowTextSimplificationDialog] = useState<boolean>(false)
  const [simplifiedAnswerTemplate, setSimplifiedAnswerTemplate] = useState<string>('') // holds result of simplification API
  const [simplificationStatus, setSimplificationStatus] = useState<'loading' | 'complete' | 'error'>()
  // text translation
  const [isTranslating, setIsTranslating] = useState<boolean>(false)
  // const [tmpTextFieldState, setTmpTextFieldState] = useState<string | undefined>()
  const [userHint, _setUserHint] = useState<UserHint>({})

  /**
   * Proxy for state update of user hint.
   * @param key
   * @param hint
   */
  function setUserHint(key: string, hint: string | undefined): void {
    const newUserHint = { ...(userHint ?? {}), [key]: hint }
    _setUserHint(newUserHint)
  }

  /**
   * Handles title change.
   * @param event
   */
  function onTitleChange(newTitle: string): void {
    if (!canIEditAnswer(answer?.answerId)) return
    setTitle(newTitle)
    updateAnswerInParent(undefined, undefined, newTitle, undefined, undefined)
  }

  /**
   * Handles answer template input.
   * @param event
   */
  function onAnswerTemplateChange(newText: string): void {
    if (!canIEditAnswer(answer?.answerId)) return
    setAnswerTemplate(newText)
  }

  function onTriggerDialogNameChange(newName: string): void {
    if (!canIEditAnswer(answer?.answerId)) return
    setTriggerDialogName(newName)
    updateAnswerInParent(undefined, undefined, undefined, newName, undefined)
  }

  function onIntentTriggerButtonChange(newButtons: IntentTriggerButtons): void {
    if (!canIEditAnswer(answer?.answerId)) return
    setIntentTriggerButtons({ ...newButtons })
    updateAnswerInParent(undefined, undefined, undefined, undefined, { ...newButtons })
  }

  /**
   * Creates a new answer object from the values in state and sets it to parent via the callback function.
   * @param utterances can receive utterances via props, to prevent async state update problem.
   */
  function updateAnswerInParent(
    answerTemp?: string,
    utterances?: string[],
    titleLocal?: string,
    triggerDialogName?: string,
    intentTriggerButtons?: IntentTriggerButtons,
  ): void {
    if (!canIEditAnswer(answer?.answerId)) return
    const newAnswer = buildAnswerObject(answer)

    newAnswer.setLabels(utterances ?? answer.labels)
    newAnswer.setTitle(titleLocal ?? title ?? answer.title)
    if (answerTemp) {
      // answer template update
      // check if all referenced variables do exist.
      const checkResult = doAllVariablesInTextExist(answerTemp)
      if (!checkResult.doExist) {
        // build string for notifying user
        const invalidVariables = Object.keys(checkResult.detailed)
          .filter((variable) => !checkResult.detailed[variable])
          .map((variable) => `"${variable}"`)

        const message = `Ungültige Variable(n) in Text: ${invalidVariables.join(
          ', ',
        )}. Korrigieren oder beheben Sie das Problem. Nutzer*innen sehen sonst die falsche Variablereferenz und keinen Wert im Chat!`

        setUserHint('answerTemplate', message)
      } else {
        setUserHint('answerTemplate', undefined) // reset if no error
      }
    }
    newAnswer.setAnswerTemplate(answerTemp ?? answerTemplate ?? answer.answerTemplate)
    newAnswer.setTopic(answer.topic)
    newAnswer.setTriggerDialogName(triggerDialogName ?? answer.triggerDialogName ?? '')
    newAnswer.setIntentTriggerButtons(intentTriggerButtons ?? answer.intentTriggerButtons ?? {})
    // we need a check for changes heres
    if (hasAnswerChanged(newAnswer)) {
      updateAnswer(newAnswer, 'modified')
    }
    onEditAnswerCallback(newAnswer)
  }

  /**
   * Callback function for utterances change.
   * @param newUtterances
   */
  function onUtterancesChangeCallback(newUtterances: string[]): void {
    if (!canIEditAnswer(answer?.answerId)) return
    updateAnswerInParent(answer.answerTemplate, newUtterances)
  }

  /**
   * Performs text simplification request.
   *
   * @param text
   */
  async function simplifyText(text: string): Promise<void> {
    try {
      if (!canIEditAnswer(answer?.answerId)) return

      setSimplificationStatus('loading')
      setShowTextSimplificationDialog(true)
      const result = await simplifyTextAPI(botId, text)
      if (result === null) {
        setSimplificationStatus('error')
        return
      }
      const simplifiedText = result.simplifiedText
      setSimplifiedAnswerTemplate(simplifiedText)
      setSimplificationStatus('complete')
    } catch (err) {
      // we could add automatic retry here
      setSimplificationStatus('error')
    }
  }

  /**
   * Confirm callback of simplification dialog.
   * @param simplifiedText
   */
  function onSimplificationDialogConfirm(simplifiedText: string): void {
    onAnswerTemplateChange(simplifiedText)
    updateAnswerInParent(simplifiedText)
    setShowTextSimplificationDialog(false)
    setSimplificationStatus(undefined)
  }

  /**
   * Close callback of simplification dialog.
   */
  function onSimplificationDialogClose(): void {
    setShowTextSimplificationDialog(false)
    setSimplificationStatus(undefined)
  }

  // /**
  //  * Builds final answer by filling answer template with answer data.
  //  * If template has no variables defined it uses the answerTemplate as answer.
  //  * If template has variables defined, but answer data does not provide values, gives back without filled in data.
  //  * @param {string} answerTemplate
  //  * @param {AnswerTemplateData} answerData
  //  */
  // function fillAnswerTemplate(answerTemplate: string, answerData: AnswerTemplateData): string {
  //   const regex = /\$\{(.+?)\}/g // matches variables in the form of ${info.category.variable}
  //   const matches: RegExpExecArray[] = []
  //   let m
  //   // find all variables in answer template
  //   while ((m = regex.exec(answerTemplate)) !== null) {
  //     matches.push(m)
  //   }

  //   // if no variables in template, return template as answer
  //   if (matches.length === 0) return answerTemplate

  //   let answer = answerTemplate
  //   // iterate over matches and replace with variables
  //   // if no variable for match throw exception
  //   // NOTE: Structure: ${category.variable}
  //   try {
  //     for (const match of matches) {
  //       const parts = match[1].split('.')
  //       if (parts[0] === 'info') parts.splice(0, 1) // remove first part "info" as it is the same for all

  //       let foundVariable = false // as soon as a var is found for the match, break and handle next match
  //       for (const template of answerData) {
  //         // for each template
  //         // check if category fits
  //         if (template.topic.name === parts[0]) {
  //           // check if variable name fits
  //           for (const varObject of template.data) {
  //             if (varObject.key === parts[1]) {
  //               // variable fits

  //               const value = varObject.value
  //               foundVariable = true

  //               // fill answer
  //               // we need to escape the '$' indicator in the variable name for the regex to work
  //               const patternEscaped = match[0].replace('$', '\\$')
  //               const re = new RegExp(patternEscaped, 'g')
  //               answer = answer.replace(re, value)
  //               break
  //             }
  //           }
  //           if (foundVariable) break
  //         }
  //       }
  //       // if not found, just go to the next
  //       // if (!foundVariable) {
  //       //   break
  //       // }
  //     }
  //   } catch (err) {
  //     // TODO: Error Handling
  //     // if (!(err instanceof NoDataError)) {
  //     //   this.logEvent({
  //     //     event_name: 'Error filling template. No NoDataError, something else',
  //     //     error: err,
  //     //   })
  //     // }
  //     // throw err
  //   }
  //   return answer
  // }

  useEffect(() => {
    setAnswer(propsAnswer)
    // setAnswerTemplate(propsAnswer.answerTemplate)
  }, [propsAnswer])

  return (
    <div id='edit-answer' className={classes.root}>
      <BaseCard width='450px' height='100%' minHeight='70vh' className={classes.answerPreviewCard}>
        <div className={classes.title}>
          <EditableTypography
            value={title}
            onChange={onTitleChange}
            maxWidth={'500px'}
            disabled={!canIEditAnswer(answer?.answerId)}
          />
        </div>
        <Typography className={classes.answerPreviewTitle}>
          <strong>Vorschau</strong>
        </Typography>
        <div className={classes.answerPreviewContainer}>
          <AnswerPreview
            answer={answerTemplate ? fillAnswerTemplate(answerTemplate, answerTemplateData) : ''}
            question={answer.labels && answer.labels.length > 0 ? answer.labels[0] : ''}
            displayFeedback={false}
            actions={intentTriggerButtons?.startDialogButton ? [intentTriggerButtons?.startDialogButton] : []}
          />
        </div>
      </BaseCard>
      <div id='edit-answer-editing' className={classes.answerEdit}>
        <div id='edit-answer-menu' className={classes.answerEditMenu}>
          <CustomizedTooltip
            placement='bottom'
            disableInteractive
            content={<Typography variant='body1'>Antworttext bearbeiten.</Typography>}
            elements={
              <div>
                <Button
                  size='normal'
                  type='normal'
                  icon='edit-box-line'
                  iconType='remix'
                  onClick={(): void => setTab('edit-text')}
                  variant={tab === 'edit-text' ? 'primary' : 'tertiary'}
                >
                  Antworttext
                </Button>
              </div>
            }
          />
          <CustomizedTooltip
            placement='bottom'
            disableInteractive
            content={<Typography variant='body1'>Dialognamen bearbeiten.</Typography>}
            elements={
              <div>
                <Button
                  size='normal'
                  type='normal'
                  icon='reply-line'
                  iconType='remix'
                  onClick={(): void => setTab('edit-dialog')}
                  variant={tab === 'edit-dialog' ? 'primary' : 'tertiary'}
                >
                  Dialog&shy;name
                </Button>
              </div>
            }
          />
          <CustomizedTooltip
            placement='bottom'
            disableInteractive
            content={<Typography variant='body1'>Zugeordnete Fragen bearbeiten und neue hinzufügen.</Typography>}
            elements={
              <div>
                <Button
                  size='normal'
                  type='normal'
                  icon='survey-line'
                  iconType='remix'
                  onClick={(): void => setTab('edit-questions')}
                  variant={tab === 'edit-questions' ? 'primary' : 'tertiary'}
                >
                  Zu&shy;geordnete Fragen
                </Button>
              </div>
            }
          />
          <CustomizedTooltip
            placement='bottom'
            disableInteractive
            content={<Typography variant='body1'>Button&shy;texte der Antwort be&shy;arbeiten.</Typography>}
            elements={
              <div>
                <Button
                  size='normal'
                  type='normal'
                  icon='survey-line'
                  iconType='remix'
                  onClick={(): void => setTab('edit-buttons')}
                  variant={tab === 'edit-buttons' ? 'primary' : 'tertiary'}
                >
                  Button&shy;texte be&shy;arbeiten
                </Button>
              </div>
            }
          />
          <Can I='read' a='translations' passThrough>
            {(can: boolean): React.ReactElement => (
              <CustomizedTooltip
                placement='bottom'
                disableInteractive
                content={<Typography variant='body1'>Übersetzungen der Antwort bearbeiten.</Typography>}
                elements={
                  <div>
                    <Button
                      size='normal'
                      type='normal'
                      icon='translate'
                      iconType='remix'
                      disabled={!can || getLanguages().length === 1}
                      onClick={(): void => setTab('edit-translations')}
                      variant={tab === 'edit-translations' ? 'primary' : 'tertiary'}
                    >
                      Über&shy;setzungen
                    </Button>
                  </div>
                }
              />
            )}
          </Can>
        </div>
        {tab === 'edit-text' && (
          <BaseCard width='100%' height='100%'>
            <div className={classes.editAnswerContainer}>
              <div className={classes.inputHeader}>
                <Typography>
                  <strong>Antwort bearbeiten</strong>
                </Typography>
                <Can I='use' a='feature_textsimplification' passThrough>
                  {(can: boolean): React.ReactElement => {
                    return (
                      <Button
                        size='small'
                        type='normal'
                        icon='magic-line'
                        iconType='remix'
                        disabled={!can || lockState !== 'canEdit' || !canIEditAnswer(answer?.answerId)}
                        onClick={(): void => {
                          if (!canIEditAnswer(answer?.answerId)) return
                          simplifyText(answerTemplate)
                          setShowTextSimplificationDialog(true)
                        }}
                        variant='secondary'
                      >
                        Text Vereinfachen
                      </Button>
                    )
                  }}
                </Can>
              </div>
              <div className={classes.editAnswerTextfield}>
                <RMEEditor
                  input={answer.answerTemplate || ''}
                  // autoFocus={true} // NOTE: I think this caused problems with the buttons of the editor ~ Jakob
                  onChangeCallback={(markdown): void => {
                    updateAnswerInParent(markdown)
                  }}
                  placeholder='Ihr Antworttext...'
                  readOnly={lockState !== 'canEdit' || !canIEditAnswer(answer?.answerId)}
                />
                {userHint && userHint?.answerTemplate && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <i className={`ri-error-warning-line ${classes.userHintIcon}`} />
                    <Typography variant='body1' style={{ color: theme.palette.error.main }}>
                      {userHint?.answerTemplate}
                    </Typography>
                  </div>
                )}
              </div>

              <GoodAnswersTips />
            </div>
          </BaseCard>
        )}
        {tab === 'edit-dialog' && (
          <BaseCard width='100%' height='100%'>
            <Typography>
              <strong>Dialognamen bearbeiten</strong>
            </Typography>
            <Typography>
              Der Dialogname dient als Referenz, um den Block im Konversationsdesigner richtig zu konfigurieren. Es wird
              empfohlen hier den tatsächlichen Namen des Dialoges zu wählen, den diese Antwort letztendlich starten
              soll.
              <br />
              <br />
              <b>Achtung:</b> Wenn Sie den Dialognamen ändern, müssen Sie die zugehörige <em>Trigger Node</em>{' '}
              aktualisieren!
            </Typography>
            <EditAnswerDialogName answerId={answer.answerId} onDialogNameChange={onTriggerDialogNameChange} />
          </BaseCard>
        )}
        {tab === 'edit-questions' && (
          <BaseCard width='100%' height='100%'>
            <Typography>
              <strong>Zugeordnete Fragen bearbeiten</strong>
            </Typography>
            <div className={classes.questions}>
              <EditAnswerUtterances
                answer={answer}
                onUtterancesChangeCallback={onUtterancesChangeCallback}
                rowsPerPage={10}
                allowDisplayAsDialog={false}
                displayType='table'
                generateUtterancesOption
                showTitle={false}
              />
            </div>
          </BaseCard>
        )}
        {tab === 'edit-buttons' && (
          <BaseCard width='100%' height='100%'>
            <Typography>
              <strong>Buttontexte bearbeiten</strong>
            </Typography>
            <Typography>
              Sie können hier die Texte festlegen, die den Benutzern zusammen mit der Antwort zur Auswahl angeboten
              werden sollen. Derzeit können Sie den Text für die Schaltfläche zum Starten des Dialogs sowie den Text für
              die Schaltfläche zum Überspringen konfigurieren. Überspringen ermöglicht es dem Benutzer, den Dialog nicht
              zu starten und stattdessen eine neue Frage zu stellen.
            </Typography>
            <EditAnswerIntentTriggerButtons answerId={answer.answerId} onButtonsChange={onIntentTriggerButtonChange} />
          </BaseCard>
        )}
        {tab === 'edit-translations' && (
          <BaseCard width='100%' height='auto'>
            <Typography>
              <strong>Übersetzungen bearbeiten</strong>
            </Typography>
            <EditAnswerTranslations
              answerId={answer.answerId}
              isTranslating={isTranslating}
              setIsTranslating={setIsTranslating}
            />
          </BaseCard>
        )}
      </div>
      {showTextSimplificationDialog && (
        <TextSimplificationDialog
          open={showTextSimplificationDialog}
          simplificationStatus={simplificationStatus}
          originalText={answerTemplate}
          simplifiedText={simplifiedAnswerTemplate}
          onClose={onSimplificationDialogClose}
          onSubmit={onSimplificationDialogConfirm}
        />
      )}
    </div>
  )
})
