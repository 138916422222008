import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useTheme } from '@mui/material/styles'
import BaseCard from 'components/Cards/BaseCard'
import React from 'react'
import { VIEW } from '../SAnalyticsTrigger'

// assets
import svgChooseCardMenuImage from 'assets/img/flowDesigner/chooseCardMenuImage.svg'
import svgCreateCardMenuImage from 'assets/img/flowDesigner/createCardMenuImage.svg'

const useStyles = makeStyles()({
  container: {
    height: '100%',
  },
  menuContainer: {
    display: 'flex',
    height: 'calc(100% - 60px)',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  menuImage1: {
    marginBottom: '50px',
  },
  builderActions: {
    height: '60px',
    marginTop: 'auto',
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'end',
  },
  card: {
    alignItems: 'center',
    // transition: 'box-shadow 0.3s',
    // '&:hover': {
    //   boxShadow:
    //     '0 14px 26px -12px rgb(0 0 0 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)', // '0px 2px 10px 2px rgba(0,0,0, 0.25)'
    // },
  },
})

type SAnalyticsTriggerOverviewProps = {
  onSelect: (view: VIEW) => void
}

export default function SAnalyticsTriggerOverview({ onSelect }: SAnalyticsTriggerOverviewProps): React.ReactElement {
  const { classes } = useStyles()
  const theme = useTheme()
  return (
    <>
      <Typography>
        Mit Analytics Triggern können Sie manuell Events den Assistenten Analytics hinzufügen. Dafür können Sie entweder
        die bestehenden Events von Convaise nutzen, die sie auch bereits in den Analytics sehen, oder benutzerdefinierte
        Events anlegen, die in einem separaten Chart angezeigt werden.
      </Typography>
      <div className={classes.menuContainer}>
        {/* 1st Card to choose an existing card */}
        <BaseCard
          footerText='Convaise Analytics'
          footerColor={theme.palette.success.main}
          onClick={(): void => onSelect(VIEW.SELECT_CONVAISE_EVENT)}
          shadowWidth={20}
          shadowBlur={20}
          className={classes.card}
        >
          <img
            className={classes.menuImage1}
            src={svgChooseCardMenuImage}
            alt={'Darstellung Auswahl einer existierenden Card'}
          />
        </BaseCard>
        {/* 2st Card to create a new Card */}
        <BaseCard
          footerText='Benutzerdefinierte Analytics'
          footerColor={theme.palette.warning.main}
          onClick={(): void => onSelect(VIEW.CHOOSE_CUSTOM_EVENT)}
          shadowWidth={20}
          shadowBlur={20}
          className={classes.card}
        >
          <img
            className={classes.menuImage1}
            src={svgCreateCardMenuImage}
            alt={'Darstellung Erstellung einer neuen Card'}
          />
        </BaseCard>
      </div>
    </>
  )
}
