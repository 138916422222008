/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, RefObject } from 'react'
import { TextField as MaterialTextField, Typography } from '@mui/material'
import CustomizedTooltip from 'components/Tooltips/CustomContentTooltip'

type TextfieldProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (event: any) => void
  onBlur?: () => void
  onClick?: () => void
  className?: string
  autoFocus?: boolean
  disabled?: boolean
  error?: boolean
  placeholder?: string
  label?: string
  helperText?: string | React.ReactNode
  hint?: string | React.ReactNode // if given, shows a helper icon next to the field that displays the hint on hover
  focused?: boolean
  value?: unknown
  defaultValue?: unknown
  id?: string
  type?: string
  rows?: number | string
  rowsMax?: number | string
  rowsMin?: number | string
  multiline?: boolean
  ref?: RefObject<HTMLInputElement>
  endButton?: React.ReactNode
  fullWidth?: boolean
  readOnly?: boolean
  onKeyUp?: React.KeyboardEventHandler<HTMLDivElement>
  // these two input props are currently only needed for Dropdown
  InputProps?: any
  inputProps?: any
}

export const Textfield = ({
  onChange,
  onBlur,
  onClick,
  focused,
  autoFocus,
  className,
  disabled,
  error,
  placeholder,
  label,
  helperText,
  hint,
  value,
  defaultValue,
  id,
  type,
  rows,
  rowsMax,
  rowsMin,
  ref,
  multiline,
  fullWidth,
  endButton,
  readOnly,
  onKeyUp,
  InputProps,
  inputProps,
}: TextfieldProps): ReactElement => {
  // remove css style from input props provided as params in order to not overwrite textfield style
  if (InputProps) delete InputProps.className
  if (inputProps) delete inputProps.className

  const textField = (
    <MaterialTextField
      className={className}
      InputProps={{
        // className: classes.input,
        // disableUnderline: true,
        endAdornment: endButton,
        readOnly: readOnly,
        ...InputProps,
      }}
      inputProps={{
        // className: classes.inputProps,
        ...inputProps,
      }}
      focused={focused}
      onClick={onClick}
      onChange={onChange}
      onBlur={onBlur}
      autoFocus={autoFocus}
      autoComplete='off'
      disabled={disabled}
      error={error}
      placeholder={placeholder}
      value={value}
      defaultValue={defaultValue}
      onKeyUp={onKeyUp}
      type={type}
      rows={rowsMax || rowsMin ? undefined : rows}
      maxRows={rowsMax}
      minRows={rowsMin}
      multiline={multiline}
      id={id}
      label={label}
      helperText={helperText}
      ref={ref}
      fullWidth={fullWidth}
    />
  )

  return hint ? (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      {textField}
      <CustomizedTooltip
        content={<>{typeof hint === 'string' ? <Typography>{hint}</Typography> : hint}</>}
        placement='top'
        elements={<i className='ri-question-line'></i>}
      ></CustomizedTooltip>
    </div>
  ) : (
    textField
  )
}

Textfield.defaultProps = {
  value: '',
}
