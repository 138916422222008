import { Collapse, Grid, Typography } from '@mui/material'
import Button from 'components/Buttons/Button'
import { Searchfield } from 'components/TextInput/Searchfield'
import Fuse from 'fuse.js'
import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import AnswerCard from './Cards/AnswerCard'

import { Answer } from 'classes/Knowledge'
import { useAnswers } from 'hooks/contexts/answers-context'
import { EndpointType, EndpointUtterance, LLMNLUEndpointUtterance } from '../../../../../../@types/Knowledge/types'
import { AssignUtteranceDialogScreen } from './AddUtteranceToAnswerDialog'

const useStyles = makeStyles()((theme, props) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  topContainer: {
    display: 'flex',
    width: '100%',
    marginBottom: theme.spacing(4),
  },
  changedAnswerContainer: { width: '100%', marginTop: theme.spacing(2), marginBottom: theme.spacing(2) },
  quickSelectContainer: { marginBottom: theme.spacing(4) },
  chosenCardContainer: {},
  searchContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  searchField: {
    // marginTop: theme.spacing(2),
    width: '100%',
  },
  searchResults: { marginTop: theme.spacing(2) },
  searchShowMoreButton: { marginTop: theme.spacing(1), width: '100%', textAlign: 'center' },
  subtitle: { marginTop: theme.spacing(1), marginBottom: theme.spacing(1) },
  questionContainer: {
    width: '100%',
  },
  flexContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  summarySelectedAnswerContainer: { marginTop: theme.spacing(2), marginBottom: theme.spacing(2) },
  markdown: { '& p': { margin: '0px !important' } },
  changedAnswerPreviewContainer: {
    maxHeight: '286px',
    overflowY: 'scroll',
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius:
      typeof theme.shape.borderRadius === 'number' ? theme.shape.borderRadius * 2 : theme.shape.borderRadius,
    // flex: 1,
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    // overflow: 'hidden',
    // marginBottom: '60px',
  },
}))

type AssignUtteranceDialogMainScreenProps = {
  utterance: LLMNLUEndpointUtterance
  answers: { [answerId: string]: Answer }
  onSelectAnswer: (answerId: string, selectFrom: AssignUtteranceDialogScreen) => void
  selectedAnswerId?: string
  type: 'main' | 'summary' // main: initial screen with quick select and search; summary summary screen with quickselect at bottom and no search
  endpointType: EndpointType
}

export default function AssignUtteranceDialogMainScreen({
  utterance,
  answers,
  selectedAnswerId,
  onSelectAnswer,
  type = 'main',
  endpointType,
}: AssignUtteranceDialogMainScreenProps): React.ReactElement {
  const { classes } = useStyles()
  const { canIEditAnswer } = useAnswers()
  // if (endpointType === 'answered') utterance = utterance as EndpointUtteranceAnswered
  // else if (endpointType === 'unanswered') utterance = utterance as EndpointUtteranceUnanswered

  const [selectedAnswer, setSelectedAnswer] = useState<Answer>()
  const [predictions, setPredictions] = useState<EndpointUtterance['intentPredictions']>()
  const [suggestions, setSuggestions] = useState<Answer[]>()
  const [numberSearchResultsShown, setNumberSearchResultsShown] = useState<number>(6)
  const [searchResults, setSearchResults] = useState<Answer[]>()
  const [searchString, setSearchString] = useState<string>()

  /**
   * Searches answers for search string and returns result.
   */
  function filterAnswers(_answers: Answer[]): Answer[] {
    const answers = _answers.filter((answer) => canIEditAnswer(answer))
    if (!searchString) return answers
    const fuseOptions = {
      shouldSort: true,
      threshold: 0.4,
      minMatchCharLength: 1,
      keys: ['title', 'actions', 'labels', 'answer'],
    }
    const fuse = new Fuse(answers ?? [], fuseOptions)
    return fuse.search(searchString).map((result) => result.item)
  }

  /**
   * Handles show more answers click.
   * Increases shown answers by 6.
   */
  function onShowMoreAnswers(): void {
    setNumberSearchResultsShown(
      numberSearchResultsShown === 3 ? numberSearchResultsShown + 3 : numberSearchResultsShown + 6,
    )
  }

  function onAnswerSelection(answerId: string): void {
    onSelectAnswer(answerId, 'main')
  }

  useEffect(
    function () {
      // order predictions by score
      const predictions = utterance.topPredictions
      predictions.sort((pred1, pred2) => {
        if (pred1.score >= pred2.score) return -1
        else return 1
      })
      // find answer for each predictions
      const suggestedAnswers: Answer[] = []
      predictions.slice(0, 3).forEach((prediction) => {
        const suggestedAns: Answer | undefined = prediction.answerId
          ? answers[prediction.answerId]
          : Object.values(answers).find((answer) => answer.intent === prediction.intent)

        if (suggestedAns) {
          suggestedAns.score = prediction.score
          suggestedAnswers.push(suggestedAns)
        }
      })
      setPredictions(predictions)
      setSuggestions(suggestedAnswers)
    },
    [utterance],
  )

  useEffect(function () {
    if (type === 'summary' && selectedAnswerId) {
      const answer = answers[selectedAnswerId]
      if (answer) setSelectedAnswer(answer)
    }
  }, [])

  useEffect(
    function () {
      const filteredAnswers = filterAnswers(Object.values(answers))
      filteredAnswers.sort((ans1, ans2) => {
        if (!ans1.score || !ans2.score || ans1.score < ans2.score) return 1
        else return -1
      })
      setNumberSearchResultsShown(6)
      setSearchResults(filteredAnswers)
    },
    [searchString, answers],
  )

  return (
    <>
      {type === 'summary' && (
        <div className={classes.chosenCardContainer}>
          {selectedAnswer && (
            <div className={classes.subtitle}>
              <Typography variant='h3'>Gewählte Antwort</Typography>
              <div className={classes.summarySelectedAnswerContainer}>
                <AnswerCard
                  answer={selectedAnswer}
                  isSelected={selectedAnswer.answerId === selectedAnswerId}
                  disabled={!canIEditAnswer(selectedAnswer)}
                  onClick={onAnswerSelection}
                />
              </div>
            </div>
          )}
        </div>
      )}
      <div className={classes.topContainer}>
        <Grid container spacing={2} justifyContent={'flex-start'} alignItems={'center'}>
          <Grid item xs={12} md={6}>
            <Typography fontSize={'16px'}>
              Frage: {'"'}
              <b>{utterance.endpointUtterance.trim()}</b>
              {'"'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.searchField}>
              <Searchfield
                autoFocus
                value={searchString}
                onChange={(event): void => setSearchString(event.target.value)}
                fullWidth
              />
            </div>
          </Grid>
        </Grid>
      </div>

      {suggestions && suggestions.length && (
        <div className={classes.quickSelectContainer}>
          <Collapse in={type === 'main' ? !searchString : true} timeout={50}>
            <div className={classes.subtitle}>
              <Typography variant='h4'>Vorschläge</Typography>
            </div>
            <div className={classes.flexContainer}>
              <Grid container spacing={4}>
                {suggestions &&
                  suggestions
                    .filter((suggestion) => canIEditAnswer(suggestion))
                    .map((suggestion, index) => {
                      if (typeof suggestion.answerId === 'undefined') return null
                      return (
                        <Grid key={`suggestion-${index}`} item xs={12} md={6} lg={4}>
                          <AnswerCard
                            answer={suggestion}
                            isSelected={selectedAnswerId === suggestion.answerId}
                            onClick={onAnswerSelection}
                            disabled={!canIEditAnswer(suggestion)}
                          />
                        </Grid>
                      )
                    })}
              </Grid>
            </div>
          </Collapse>
        </div>
      )}

      {type === 'main' && (
        <div className={classes.searchContainer}>
          <div className={classes.subtitle}>
            <Typography variant='h4'>Antworten</Typography>
            <div className={classes.searchResults}>
              <Grid container spacing={4}>
                {searchResults &&
                  searchResults.slice(0, numberSearchResultsShown).map((answer, index) => (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={4}
                      key={`answer-${index}`}
                      style={{ marginBottom: '8px' }}
                      spacing={2}
                    >
                      <AnswerCard
                        answer={answer}
                        isSelected={selectedAnswerId === answer.answerId}
                        onClick={onAnswerSelection}
                        disabled={!canIEditAnswer(answer)}
                      />
                    </Grid>
                  ))}
              </Grid>
              {searchResults && numberSearchResultsShown < searchResults.length && (
                <div className={classes.searchShowMoreButton}>
                  <Button size='small' icon='arrow-down-s-line' iconType='remix' onClick={onShowMoreAnswers}>
                    Mehr anzeigen
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
