import React from 'react'
import ReactMarkdown from 'react-markdown'
import { makeStyles } from 'tss-react/mui'

type StyleProps = {
  height?: string
}

// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. ArrowFunctionExpression in CSS prop.
const useStyles = makeStyles<StyleProps>()((theme, props) => ({
  previewContainer: {
    height: props?.height ?? 'auto',
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius:
      typeof theme.shape.borderRadius === 'number' ? theme.shape.borderRadius * 2 : theme.shape.borderRadius,
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    overflow: 'auto',
  },
  markdown: {
    '& p': { margin: '0px !important' },
  },
}))

type MessageProps = {
  markdownString: string
  height?: string
}

// TODO: use Regarding your question AC Template Card
export default function Message({ markdownString, height }: MessageProps): React.ReactElement {
  const { classes } = useStyles({ height })
  return (
    <div className={classes.previewContainer}>
      <ReactMarkdown className={classes.markdown}>{markdownString}</ReactMarkdown>
    </div>
  )
}
