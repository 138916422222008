import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { Notes, Start } from '@mui/icons-material'
import { Grid, Typography } from '@mui/material'
import { AnswerType } from '../../../../../@types/Knowledge/types'
import { useBotContext } from 'hooks/contexts/bot-context'
import { useAnswers } from 'hooks/contexts/answers-context'
import IconCard from 'components/Cards/IconCard'

const useStyles = makeStyles()((theme) => ({
  answerTypeButton: {
    textAlign: 'center',
  },
  buttonContainer: {
    marginTop: theme.spacing(6),
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '90%',
  },
  button: {
    height: '180px',
    width: '180px',
    borderRadius: theme.shape.borderRadius,
    textAlign: 'center',
    backgroundColor: theme.palette.grey[100],
    verticalAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    border: 'none',
    cursor: 'pointer',
    ':disabled': {
      cursor: 'default',
    },
  },
  icon: {
    height: '80px',
    width: '80px',
    color: theme.palette.text.primary,
  },
}))

type Step0AnswerTypeProps = {
  onAnswerTypeSelect: (answerType: AnswerType) => void
}

export default function Step0AnswerType({ onAnswerTypeSelect }: Step0AnswerTypeProps): React.ReactElement {
  const { classes } = useStyles()
  const { permissionsFlowdesigner } = useBotContext()
  const { canICreateTopicAndItsAnswers } = useAnswers()
  return (
    <div>
      <Typography>
        Es gibt zwei verschiedene Antworttypen: <em>Antwort</em> und <em>Trigger</em>. Antworten vom Typ{' '}
        <em>Antwort</em> sind klassische Anworten, die der Assistent auf Fragen geben kann. Sie bilden das Wissen des
        Assistenten ab. <em>Trigger</em> starten einen Unterdialog in der Konversation. Sie können Trigger nur
        erstellen, wie Sie die Berechtigung zum Bearbeiten des Konversationflusses haben.
      </Typography>
      <div className={classes.buttonContainer}>
        <Grid container justifyContent='center' justifySelf='center' justifyItems='center' component='div' spacing={2}>
          <Grid item xs={6} sm={6} md={6} className={classes.answerTypeButton}>
            <IconCard
              onClick={(): void => onAnswerTypeSelect('answer')}
              footerText='Antwort'
              isSelected={false}
              icon={<Notes className={classes.icon} />}
              iconSize={80}
            />

            {/* <button className={classes.button} onClick={(): void => onAnswerTypeSelect('answer')}></button> */}
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <IconCard
              onClick={(): void => onAnswerTypeSelect('trigger')}
              footerText='Trigger'
              isSelected={false}
              disabled={
                !permissionsFlowdesigner ||
                !permissionsFlowdesigner.includes('update') ||
                !canICreateTopicAndItsAnswers('Trigger')
              }
              icon={<Start className={classes.icon} />}
              iconSize={80}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
