import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React from 'react'
import Table from '../../../../../../components/Table/Table'
import { IntentTriggerButtons } from '../../../../../../@types/Knowledge/types'
import { useBotContext } from 'hooks/contexts/bot-context'
import { BotInfos } from '../../../../../../@types/BotInformation/types'

const useStyles = makeStyles()((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  tableContainer: {
    overflow: 'auto',
  },
}))

type StepTriggerSummaryProps = {
  topic: string
  dialogName: string
  title: string
  answerText: string
  triggerDialogButtons: IntentTriggerButtons
}

export default function StepTriggerSummary({
  topic,
  dialogName,
  title,
  answerText,
  triggerDialogButtons,
}: StepTriggerSummaryProps): React.ReactElement {
  const { bot } = useBotContext() as { bot: BotInfos }
  const isV4Bot = bot.webchatVersion === 'v4'
  const { classes } = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.tableContainer}>
        <Table
          rows={[
            [<Typography key='topic-1'>Thema</Typography>, <Typography key='topic-value'>{topic}</Typography>],
            [
              <Typography key='dialog-1'>Dialogname</Typography>,
              <Typography key='dialog-value'>{dialogName}</Typography>,
            ],
            ...(isV4Bot
              ? [[<Typography key='title-1'>Titel</Typography>, <Typography key='title-value'>{title}</Typography>]]
              : [[]]),
            ...(isV4Bot
              ? [
                  [
                    <Typography key='answer-1'>Antworttext</Typography>,
                    <Typography key='answer-value'>{answerText}</Typography>,
                  ],
                ]
              : [[]]),
            ...(isV4Bot
              ? [
                  [
                    <Typography key='startDialogButton-1'>Dialog starten Buttontext</Typography>,
                    <Typography key='startDialogButton-value'>{triggerDialogButtons.startDialogButton}</Typography>,
                  ],
                ]
              : [[]]),
          ]}
          cellAlignPattern={['left', 'left']}
          disablePagination
          padding='medium'
        />
      </div>
    </div>
  )
}
