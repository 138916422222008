import React, { ReactElement, useState, useEffect } from 'react'
import { makeStyles } from 'tss-react/mui'

import { IconButton } from '@mui/material'
import { Refresh } from '@mui/icons-material'

import { Draggable } from '../Draggable/Draggable'

import { PopupTitlebar } from './popupElements/PopupTitlebar'
import { PopupTextfield } from './popupElements/PopupTextfield'
import { PopupFooter } from './popupElements/PopupFooter'
import { PopupCheckbox } from './popupElements/PopupCheckbox'
import { PopupButton } from './popupElements/PopupButton'
import { PopupDropdown, DropdownOption } from './popupElements/PopupDropdown'

const useStyles = makeStyles()((theme) => ({
  popup: {
    background: 'rgba(255,255,255,0.98)',
    width: '250px',
    borderRadius: theme.shape.borderRadius,
    padding: '15px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    cursor: 'move',
  },
  radioButtonGroup: {
    display: 'flex',
    marginBottom: '10px',
  },
  alignRight: {
    marginLeft: 'auto',
  },
}))

export type PopupField = {
  displayName: string
  fieldType?: DropdownOption
  selectedSection?: DropdownOption
  fieldDescription: string
  isMandatory: boolean
}

type FieldPopupProps = {
  parent: HTMLDivElement
  startPosition: { x: number; y: number }
  title: string
  sectionOptions: DropdownOption[]
  fieldTypeOptions: DropdownOption[]
  fieldType?: DropdownOption
  onSelectFieldDescription: () => void
  onConfirm: (field: PopupField) => void
  onClose: () => void
  onDelete: () => void
  fieldName?: string
  selectedSection?: DropdownOption
  fieldDescription?: string
  fieldMandatory?: boolean
  isDescriptionConfigured?: boolean
}

/**
 * Popup for Fieldselection
 * TODO: I18n
 */
export default function FieldPopup({
  parent,
  startPosition,
  title,
  sectionOptions,
  fieldTypeOptions,
  onSelectFieldDescription,
  onConfirm,
  onClose,
  onDelete,
  fieldName: name,
  fieldType: type,
  selectedSection,
  fieldDescription: description,
  fieldMandatory,
  isDescriptionConfigured,
}: FieldPopupProps): ReactElement {
  const { classes } = useStyles()
  const [displayName, setDisplayName] = useState(name || '')
  //   const fieldTypeOptions = buildFieldTypeOptions(['text', 'checkbox', 'radio', 'choice', 'signature'])
  //   const selectedType = fieldTypeOptions.find((option) => option.title === (type || 'text'))
  const [fieldType, setFieldType] = useState(type)
  const [section, setSection] = useState<DropdownOption | undefined>(selectedSection)
  const [fieldDescription, setFieldDescription] = useState(description || '')
  const [isFieldMandatory, setFieldMandatory] = useState(fieldMandatory || false)

  // ========= USE EFFECTS ==========
  useEffect(() => {
    setFieldType(type)
  }, [type])

  useEffect(() => {
    setDisplayName(name || '')
  }, [name])

  useEffect(() => {
    setFieldDescription(description || '')
  }, [description])

  // Fieldname
  function onNameChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setDisplayName(event.target.value)
  }

  // Field description
  function onDescriptionChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setFieldDescription(event.target.value)
  }

  // Field Type
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function onTypeChange(event: object, options: DropdownOption[], reason: string): void {
    if (options.length > 0) setFieldType(options[0])
  }

  // Section
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function onSectionChange(event: object, options: DropdownOption[], reason: string): void {
    if (options.length > 0) setSection(options[0])
  }

  // Mandatory
  function onMandatoryChange(event: object, checked: boolean): void {
    setFieldMandatory(checked)
  }

  function onConfirmClick(): void {
    const field = {
      displayName,
      fieldType,
      selectedSection: section,
      fieldDescription,
      isMandatory: isFieldMandatory,
    }
    onConfirm(field)
  }

  const popup = (
    <div className={classes.popup}>
      <PopupTitlebar title={title} onClose={onClose} />
      {/* Field name = Display Name, does not change the actual fieldName in the PDF*/}
      <PopupTextfield
        title={'Feldname'}
        onChange={onNameChange}
        value={displayName}
        multiline
        placeholder={'Name des Feldes'}
      />
      {/* Field type */}
      <PopupDropdown
        title={'Feldtyp'}
        multiple={false}
        disableClearable
        options={fieldTypeOptions}
        placeholder={'Feldtyp auswählen'}
        disabled={true}
        onChange={onTypeChange}
        selectedValues={fieldType ? [fieldType] : []}
      />
      {/* Section */}
      <PopupDropdown
        title={'Abschnitt'}
        multiple={false}
        disableClearable={false}
        options={sectionOptions}
        placeholder={'Abschnitt auswählen'}
        onChange={onSectionChange}
        disabled={true}
        selectedValues={section ? [section] : []}
      />
      {/* Field description */}
      {isDescriptionConfigured ? (
        <PopupTextfield
          title={'Feldbeschreibung'}
          onChange={onDescriptionChange}
          value={fieldDescription}
          multiline
          placeholder={'Beschreibungstext des Feldes'}
          iconButton={
            <IconButton onClick={onSelectFieldDescription} size='small'>
              <Refresh />
            </IconButton>
          }
        />
      ) : (
        <PopupButton title={'Feldbeschreibung'} onClick={onSelectFieldDescription} buttonText='Markieren' />
      )}
      {/* Mandatory */}
      <PopupCheckbox
        checked={isFieldMandatory}
        title={'Pflichtfeld'}
        description={'Nutzereingabe erzwingen'}
        onChange={onMandatoryChange}
      />
      {/* Footer */}
      <PopupFooter onConfirm={onConfirmClick} onDelete={onDelete} />
    </div>
  )

  return <Draggable parent={parent} startPosition={startPosition} content={popup} />
}
