import React from 'react'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  sepLine: {
    display: 'block',
    height: '1px',
    margin: '1em 0',
    padding: '0',
    border: '0',
    borderTop: '1px solid #ccc',
  },
}))

const SepLine = ({ style }: any) => {
  const { classes } = useStyles()

  return <hr className={classes.sepLine} style={style} />
}

const useStylesText = makeStyles()((theme) => ({
  text: {
    margin: '10px',
    marginTop: '5px',
    fontSize: '10pt',
    color: '#eee',
  },
}))

const Text = ({ children }: any) => {
  const { classes } = useStylesText()

  return <p className={classes.text}>{children}</p>
}

type Separator = {
  text?: string
  style?: object
}

/**
 * @param {string} text Text to display under the separator line
 * @param {object} style
 */
export const Separator = ({ text, style }: Separator): React.ReactElement => {
  return (
    <div>
      <SepLine style={style} />
      {text && text !== '' ? <Text>{text}</Text> : null}
    </div>
  )
}
