import React, { ReactElement } from 'react'
import { makeStyles } from 'tss-react/mui'

import { Textfield } from '../../TextInput/Textfield'
import { PopupContentBlock } from '../popupBuildingBlocks/PopupContentBlock'

const useStyles = makeStyles()((theme) => ({
  container: {},
  containerWithIconButton: {
    display: 'flex',
  },
  iconButton: {
    marginLeft: 'auto',
  },
}))

type PopupTextfieldProps = {
  title: string
  placeholder?: string
  value?: string
  multiline?: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  autofocus?: boolean
  iconButton?: React.ReactElement
}

export const PopupTextfield = ({
  title,
  placeholder,
  value,
  multiline,
  onChange,
  autofocus,
  iconButton,
}: PopupTextfieldProps): ReactElement => {
  const { classes } = useStyles()
  return (
    <PopupContentBlock
      title={title}
      content={
        <div className={iconButton ? classes.containerWithIconButton : classes.container}>
          <Textfield
            autoFocus={autofocus ? autofocus : false}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            multiline={multiline}
          />
          {iconButton ? <div className={classes.iconButton}>{iconButton}</div> : null}
        </div>
      }
    />
  )
}
