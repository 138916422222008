import { useTheme } from '@mui/material'
import React, { FC } from 'react'
import {
  ConnectionLineComponentProps,
  getMarkerEnd,
  getSmoothStepPath,
  MarkerType,
  Node,
  HandleElement,
  Position,
} from 'reactflow'
import EdgeLabel from './EdgeLabel'
import { FLOWDESIGNER_EDGE_COLOR } from 'utils/constants'
import { getSmoothStepPathWithPadding } from './pathUtils'

function getLabelFromNode(node: Node | undefined, sourceHandle: HandleElement | undefined): string | undefined {
  const ports = node?.data?.node?.ports
  if (ports && sourceHandle && sourceHandle.id) {
    const portName = ports[sourceHandle.id].properties?.name
    return portName
  }
}

const DraggingSmoothStepEdge: FC<ConnectionLineComponentProps> = ({
  fromX,
  fromY,
  fromPosition,
  toPosition,
  toX,
  toY,
  fromNode,
  fromHandle,
}: ConnectionLineComponentProps) => {
  const theme = useTheme()
  const edgePath = getSmoothStepPathWithPadding({
    sourceX: fromX,
    sourceY: fromY,
    sourcePosition: fromPosition,
    targetX: toX,
    targetY: toY,
    targetPosition: toPosition,
  })
  const markerEnd = getMarkerEnd(MarkerType.Arrow, 'edge-marker')

  const label = getLabelFromNode(fromNode, fromHandle)

  return fromHandle?.position === Position.Right ? (
    <g>
      <path
        fill='none'
        stroke={FLOWDESIGNER_EDGE_COLOR}
        strokeWidth={2}
        className='animated'
        d={edgePath}
        markerEnd={markerEnd}
      />
      {typeof label !== 'undefined' && (
        <EdgeLabel
          x={fromX + 15}
          y={fromY}
          label={label}
          labelStyle={{ fill: theme.palette.primary.main, fontSize: 12 }}
          labelShowBg
          labelBgStyle={{ fill: 'white' }}
          labelBgPadding={[6, 4]}
          labelBgBorderRadius={4}
        />
      )}
    </g>
  ) : (
    <></>
  )
}

export default DraggingSmoothStepEdge
