import React, { createContext, useContext, useReducer, useCallback, useEffect } from 'react'

import { useBotContext } from './bot-context'
import { useLockingContext } from './locking-context'
import { useStudioNotificationContext } from './studio-notification-context'
import { AnswerTemplateData, AnswerTemplateVariableObject, LoadingState } from '../../@types/Knowledge/types'
import { getAnswerData as getAnswerDataAPI } from '../../api/StudioBackend'
import { useErrorContext } from './errorContext'
import ErrorComponent from 'components/Error/Error'
import { ANSWER_TEMPLATE_VARIABLE_REGEX } from 'utils/answerUtils'
import { info } from 'console'

// ----- TYPE DEFINITIONS -----

// Maps Actions to Payloads - Standard does not need customization
type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key
      }
    : {
        type: Key
        payload: M[Key]
      }
}

enum Types {
  SetInitialized = 'SET_INITIALIZED',
  SetData = 'SET_DATA',
  SetLoading = 'SET_LOADING',
  ResetState = 'RESET',
}

type DatamanagementContextPayload = {
  [Types.ResetState]: {}
  [Types.SetData]: {
    data: AnswerTemplateData
  }
  [Types.SetLoading]: {
    loading: LoadingState
  }
  [Types.SetInitialized]: {
    isInitialized: boolean
  }
}

type Action = ActionMap<DatamanagementContextPayload>[keyof ActionMap<DatamanagementContextPayload>]
type State = {
  loading: LoadingState | null
  data: AnswerTemplateData | null
  isInitialized: boolean
}

type Dispatch = (action: Action) => void
type DatamangementContextProviderProps = { children: React.ReactNode }

/**
 * ----- CONTEXT -----
 * Datamanagement Context is a local context used for storing everything needed to manage data / information for answers
 *
 * To access this context:
 * import { useDatamanagementContext } from '<path>/hooks/contexts/datamanagement-context.tsx'
 *
 * // in a function
 * const {data, loading, resetDatamanagementContext} = useDatamanagementContext()
 *
 */
const DatamanagementContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined)

function datamanagementReducer(state: State, action: Action): State {
  switch (action.type) {
    case Types.ResetState:
      return {
        ...state,
        loading: null,
        data: null,
        isInitialized: false,
      }
    case Types.SetData:
      return {
        ...state,
        data: action.payload.data,
      }
    case Types.SetLoading: {
      return {
        ...state,
        loading: action.payload.loading,
      }
    }
    case Types.SetInitialized: {
      return {
        ...state,
        isInitialized: action.payload.isInitialized,
      }
    }
    default: {
      // helps us avoid typos!
      throw new Error(`[DatamanagementContext-Reducer] Unhandled action type: ${(action as any).type}`)
    }
  }
}

export function DatamangementContextProvider({ children }: DatamangementContextProviderProps): JSX.Element {
  const { setError } = useErrorContext()
  const { bot } = useBotContext()
  const [state, dispatch] = useReducer(datamanagementReducer, {
    loading: null,
    data: null,
    isInitialized: false,
  })

  // simple wrapper around setloading dispatch
  function setLoading(loading: LoadingState): void {
    dispatch({ type: Types.SetLoading, payload: { loading } })
  }
  // simple wrapper around setloading dispatch
  function setData(data: AnswerTemplateData): void {
    dispatch({ type: Types.SetData, payload: { data } })
  }
  // simple wrapper around setInitialized dispatch
  function setInitialized(isInitialized: boolean): void {
    dispatch({ type: Types.SetInitialized, payload: { isInitialized } })
  }

  async function loadAnswerData(isRetry = false): Promise<void> {
    try {
      if (!bot?.id) return
      setLoading('loading')
      const answerTemplateData = await getAnswerDataAPI(bot.id)
      if (answerTemplateData === null) {
        // error
        throw new Error('Error loading answer template data')
      } else {
        setData(answerTemplateData)
        setInitialized(true)
        setLoading(undefined)
      }
    } catch (err) {
      if (!isRetry) {
        // try again a second time
        console.info('Retry: Load AnswerData')
        loadAnswerData(true)
      } else {
        // set error
        setError(
          'Knowledge.specific.infomanagement',
          'Das Wissen des Assistenten konnte nicht geladen werden. Bitte versuchen Sie es erneut.',
          'Erneut versuchen',
          loadAnswerData,
          'Fehler beim Laden des Wissens',
        )
        setLoading(undefined)
      }
    }
  }

  useEffect(function () {
    loadAnswerData()

    return () => {
      dispatch({ type: Types.ResetState, payload: {} })
    }
  }, [])

  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  // const value = useMemo(() => [state, dispatch], [state])
  const value = { state, dispatch }
  return (
    <ErrorComponent errorCode='Knowledge.specific.infomanagement'>
      <DatamanagementContext.Provider value={value}>{children}</DatamanagementContext.Provider>
    </ErrorComponent>
  )
}

/**
 * Consume only for now.
 * @returns
 */
export function useDatamanagementContext(): {
  answerTemplateData: AnswerTemplateData
  loading: LoadingState
  isInitialized: boolean
  doAllVariablesInTextExist: (text: string) => { doExist: boolean; detailed: { [variableName: string]: boolean } }
} {
  const context = useContext(DatamanagementContext)
  if (context === undefined) {
    throw new Error(
      '[useDatamanagementContext] useDatamanagementContext must be used within a DatamanagementContextProvider',
    )
  }
  const { state, dispatch } = context

  const { loading, data, isInitialized } = state

  const doesDataVariablesExist = useCallback(
    (variableName: string): boolean => {
      // variable name is in format info.category.variable
      if (!data) return false
      const [_, topic, variable] = variableName.split('.')
      if (!topic || !variable) return false
      for (const dataObj of data) {
        if (
          dataObj.topic.name === topic &&
          dataObj.data.find((v: AnswerTemplateVariableObject) => v.key === variable)
        ) {
          return true
        }
      }
      return false
    },
    [data],
  )

  /**
   * Checks if all variable references in text do exist in the template data.
   * Returns for each variable in the text a boolean and a global boolean true if all exist, false if at least one does not.
   */
  const doAllVariablesInTextExist = useCallback(
    (text: string): { doExist: boolean; detailed: { [variableName: string]: boolean } } => {
      // find all variable references in text (matches form "${info.category.variable}")
      const matches: RegExpExecArray[] = []
      let m
      // find all variables in answer template
      while ((m = ANSWER_TEMPLATE_VARIABLE_REGEX.exec(text)) !== null) {
        matches.push(m)
      }

      const variables = matches.map((match) => match[1])

      const result: { doExist: boolean; detailed: { [variableName: string]: boolean } } = {
        doExist: true,
        detailed: {},
      }
      variables.forEach((variable) => {
        const doesExist = doesDataVariablesExist(variable)
        if (!doesExist) result.doExist = false
        result.detailed[variable] = doesExist
      })
      return result
    },
    [doesDataVariablesExist],
  )

  return {
    loading: loading ?? undefined,
    answerTemplateData: data ?? [],
    isInitialized,
    doAllVariablesInTextExist,
  }
}
