/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react'

// Material UI
import { makeStyles } from 'tss-react/mui'

import InputAdornment from '@mui/material/InputAdornment'
// Custom Components
import { Textfield } from './Textfield'

type SearchfieldProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (event: any) => void
  autoFocus?: boolean
  disabled?: boolean
  error?: boolean
  placeholder?: string
  value?: unknown
  defaulValue?: unknown
  type?: string
  // rows?: number | string
  // rowsMax?: number | string
  // multiline?: boolean
  fullWidth?: boolean
  // these two input props are currently only needed for Dropdown
  InputProps?: any
  inputProps?: any
  autoComplete?: string
}

export const Searchfield = ({
  onChange,
  autoFocus,
  disabled,
  error,
  placeholder,
  autoComplete = 'off',
  value,
  defaulValue,
  type,
  // rows,
  // rowsMax,
  // multiline,
  fullWidth,
  InputProps,
  inputProps,
}: SearchfieldProps): ReactElement => {
  // remove css style from input props provided as params in order to not overwrite textfield style
  if (InputProps) delete InputProps.className
  if (inputProps) delete inputProps.className

  return (
    <Textfield
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <div style={{ fontSize: 25 }}>
              <i className={`ri-search-line`} />
            </div>
          </InputAdornment>
        ),
        ...InputProps,
      }}
      inputProps={{
        ...inputProps,
        autoComplete: autoComplete,
      }}
      onChange={onChange}
      autoFocus={autoFocus}
      disabled={disabled}
      error={error}
      placeholder={'Suche'}
      value={value}
      defaultValue={defaulValue}
      type={type}
      id='search'
      fullWidth={fullWidth}
    />
  )
}

Textfield.defaultProps = {
  value: '',
}
