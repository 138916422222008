import React, { ReactElement } from 'react'
import { makeStyles } from 'tss-react/mui'

import { PopupContentBlock } from '../popupBuildingBlocks/PopupContentBlock'

const useStyles = makeStyles()(() => ({
  text: {
    textAlign: 'left',
    fontSize: 12,
    lineHeight: '15px',
    maxHeight: '100px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'default',
  },
}))

type PopupTextblockProps = {
  title: string
  text: string
}

export const PopupTextblock = ({ title, text }: PopupTextblockProps): ReactElement => {
  const { classes } = useStyles()

  return <PopupContentBlock title={title} content={<div className={classes.text}>{text}</div>} />
}
