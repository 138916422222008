import React, { useState, useEffect } from 'react'
import { makeStyles } from 'tss-react/mui'
import { IconButton, Typography } from '@mui/material'
import { Textfield } from './Textfield'
import CustomizedTooltip from '../Tooltips/CustomContentTooltip'

type StyleProps = {
  width?: string
  maxWidth?: string
  height?: string
}

const useStyles = makeStyles<StyleProps>()((theme, props) => ({
  flexContainer: {
    display: 'flex',
    minHeight: '56px',
    height: props.height || '56px',
    width: props.width,
    maxWidth: props.maxWidth,
  },
  contentContainer: {
    // centers content vertically
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  icon: {
    fontSize: '1.2rem',
  },
  iconButton: {
    marginLeft: theme.spacing(1),
    marginBottom: 'auto',
    marginTop: 'auto',
    color: theme.palette.primary.main,
  },
  editButtonContainer: {
    marginLeft: 'auto',
  },
  flexContainerTextField: {},
  textfield: {
    width: props.width,
    maxWidth: props.maxWidth,
  },
}))

type EditableTypographyProps = {
  value: string
  onChange: (newValue: string) => void
  placeholder?: string
  variant?:
    | 'body1'
    | 'body2'
    | 'button'
    | 'caption'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'inherit'
    | 'overline'
    | 'subtitle1'
    | 'subtitle2'
  multiline?: boolean
  editMode?: boolean
  hideEditButton?: boolean // if this is set to false, edit mode can be activated via the editMode property
  label?: string
  isValidNewValue?: (newValue: string) => { isValid: boolean; message?: string }
  height?: string
  width?: string
  maxWidth?: string
  submitOnBlur?: boolean
  disabled?: boolean
}

export default function EditableTypography({
  value: valueProps,
  onChange,
  label,
  isValidNewValue,
  placeholder,
  multiline = false,
  disabled = false,
  hideEditButton = false,
  editMode: editModeProps = false,
  width,
  maxWidth,
  submitOnBlur = true,
  height = '56px',
  variant = 'h3',
}: EditableTypographyProps): React.ReactElement {
  const { classes } = useStyles({ width, maxWidth, height })
  const [editMode, setEditMode] = useState<boolean>(editModeProps)
  const [value, setValue] = useState<string>()
  const [error, setError] = useState<string>()

  /**
   * Handles text submit.
   */
  function onTextSubmit(event?: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>): void {
    if (event) event.preventDefault()
    // only submit if no error
    if (!error) {
      onChange(value || '')
      setEditMode(false)
    }
  }

  /**
   * Handles text change.
   * @param event
   */
  function onTextChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setValue(event.target.value)
    if (typeof isValidNewValue !== 'undefined') {
      const validityCheckResult = isValidNewValue(event.target.value)
      if (!validityCheckResult.isValid) {
        setError(validityCheckResult.message ?? 'Ungültiger Wert')
      } else {
        setError(undefined)
      }
    }
  }

  useEffect(
    function () {
      setEditMode(editModeProps)
    },
    [editModeProps],
  )

  useEffect(
    function () {
      setValue(valueProps)
    },
    [valueProps],
  )

  return editMode ? (
    <form onSubmit={onTextSubmit} className={classes.flexContainer}>
      {/* <div className={classes.flexContainer}> */}
      <Textfield
        onChange={onTextChange}
        value={value}
        InputProps={{ autoComplete: 'off' }}
        label={error ? error : label ? label : ''}
        error={!!error}
        onBlur={(): void => {
          if (submitOnBlur) onTextSubmit()
        }}
        autoFocus
        className={classes.textfield}
        placeholder={placeholder}
        multiline
        disabled={disabled}
      />
      <CustomizedTooltip
        placement='top'
        disableInteractive
        content={<Typography>Übernehmen</Typography>}
        elements={
          <IconButton type='submit' aria-label='Add' className={classes.iconButton} disabled={!!error || disabled}>
            <i className={'ri-check-fill ' + classes.icon}></i>
          </IconButton>
        }
      />
      {/* </div> */}
    </form>
  ) : (
    <div className={classes.flexContainer}>
      <div className={classes.contentContainer}>
        <Typography variant={variant}>{value ? value : <em>{placeholder}</em>}</Typography>
      </div>
      {!hideEditButton && (
        <div className={classes.contentContainer}>
          <div className={classes.editButtonContainer}>
            <CustomizedTooltip
              placement='top'
              disableInteractive
              content={<Typography>Bearbeiten</Typography>}
              elements={
                <IconButton
                  onClick={(event: React.MouseEvent): void => {
                    event.stopPropagation()
                    event.preventDefault()
                    setEditMode(true)
                  }}
                  aria-label='Edit'
                  disabled={disabled}
                  className={classes.iconButton}
                >
                  <i className={'ri-pencil-fill ' + classes.icon}></i>
                </IconButton>
              }
            />
          </div>
        </div>
      )}
    </div>
  )
}
