/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'

// This implementation is heavily inspired by this blog https://kentcdodds.com/blog/how-to-use-react-context-effectively

type Action = { type: boolean }
type Dispatch = (action: Action) => void
type State = { isDraggable: boolean }

const DraggableStateContext = React.createContext<State | undefined>(undefined)
const DraggableDispatchContext = React.createContext<Dispatch | undefined>(undefined)

function draggableReducer(state: { isDraggable: boolean }, action: { type: boolean }): { isDraggable: boolean } {
  switch (action.type) {
    case true:
      return { isDraggable: true }
    case false:
      return { isDraggable: false }
    default:
      throw new Error(`Unknown isDraggable state: ${action.type}`)
  }
}

function DraggableContextProvider({ children }: { children: React.ReactNode }): React.ReactElement {
  const [state, dispatch] = React.useReducer(draggableReducer, { isDraggable: true })
  return (
    <DraggableStateContext.Provider value={state}>
      <DraggableDispatchContext.Provider value={dispatch}>{children}</DraggableDispatchContext.Provider>
    </DraggableStateContext.Provider>
  )
}

function useDraggableContextState(): { isDraggable: boolean } {
  const context = React.useContext(DraggableStateContext)
  if (context === undefined) {
    throw new Error('useDraggableContextState must be used within a DraggableContextProvider')
  }
  return context
}

function useDraggableContextDispatch(): Function {
  const context = React.useContext(DraggableDispatchContext)
  if (context === undefined) {
    throw new Error('useDraggableContextDispatch must be used within a DraggableContextProvider')
  }
  return context
}

export { DraggableContextProvider, useDraggableContextState, useDraggableContextDispatch }
