import React, { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'
import { makeStyles } from 'tss-react/mui'
import { Grid, Typography } from '@mui/material'
import { Separator } from '../Separator'

import RequestMethodField from './ApiRequest/SAPIRequestMethod'
import SAPIRequestParams from './ApiRequest/SAPIRequestParams'
import SAPIRequestBody from './ApiRequest/SAPIRequestBody'

import TextFieldVariablesSuggestions from '../Variables/TextFieldVariablesSuggestions'

import { Chart, RequestParams, RequestBody, RequestMethod, RequestHeader } from '../../../../../@types/Flowchart/types'
import SAPIRequestHeaders from './ApiRequest/SAPIRequestHeaders'
import { replaceVarDisplayNameWithId } from '../../../../../utils/chartUtils'

/**
 * STYLING
 */
const useStyles = makeStyles()({
  selectedDialogContainer: {},
  sidebarH5style: {},
  inputWrapper: {},
  topGrid: {
    marginTop: '15px',
  },
  switchTrack: {
    backgroundColor: 'red',
  },
  switchBase: {
    color: '#3C4858',
    '&.Mui-checked': {
      color: '#3C4858',
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: 'green',
      opacity: 0.7,
    },
  },
  methodUrlContainer: { display: 'flex', width: '100%' },
  methodContainer: { width: '110px', minWidth: '110px', maxWidth: '110px', marginRight: '15px' },
  urlContainer: { marginLeft: 'auto', width: '100%' },
})

// ==============================================
// =============== COMPONENTS ===================

type SAPIRequestProps = {
  chart: Chart
  setStateCallback: (chart: Chart) => void
}

export default function SAPIRequest({ chart: origChart, setStateCallback }: SAPIRequestProps): React.ReactElement {
  const { classes } = useStyles()
  const methodOptions: { label: string; value: RequestMethod }[] = [
    { label: 'GET', value: 'get' },
    { label: 'POST', value: 'post' },
    { label: 'PUT', value: 'put' },
    { label: 'DELETE', value: 'delete' },
    { label: 'PATCH', value: 'patch' },
  ]
  const nodeId = origChart.selected?.id
  const [chart, setChart] = useState<Chart>(origChart)
  const [url, setUrl] = useState<string>('')
  const [method, setMethod] = useState<RequestMethod>('get')
  const [headers, setHeaders] = useState<RequestHeader>({})
  // const [toggleParams, setToggleParams] = useState<'on' | 'off'>('off')
  const [params, setParams] = useState<RequestParams>({})
  // const [toggleBody, setToggleBody] = useState<'on' | 'off'>('off')
  const [body, setBody] = useState<RequestBody>({})

  // ======= FINAL CALLBACK ======
  /**
   * Wrapper function around setStateCallback.
   * Creates deep copy of chart to trigger useEffect again.
   */
  function setUpdatedChart(chart: Chart): void {
    setStateCallback({ ...chart })
  }

  /**
   * Resets API test result.
   * Deletes test result from chart.
   * If given chart in props, returns chart, else calls callback with new chart.
   * @returns
   */
  function resetAPIResult(localChart?: Chart): Chart | undefined {
    const newChart = typeof localChart !== 'undefined' ? localChart : chart
    if (typeof nodeId === 'undefined') return newChart
    const api = newChart.nodes[nodeId].properties.api
    if (typeof api === 'undefined') return newChart

    delete api.apiTestResult
    newChart.nodes[nodeId].properties.api = api
    if (typeof localChart !== 'undefined') return newChart
    else setUpdatedChart(newChart)
  }

  // ======= VALIDATORS =======
  function validateUrl(): void {
    return
  }

  // ======= HANDLER =========

  /**
   * Handles URL change
   * @param {string} event
   */
  function onUrlChange(newUrl: string): void {
    setUrl(newUrl)
  }

  function onUrlBlur(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void {
    if (typeof nodeId === 'undefined') return
    const node = chart.nodes[nodeId]
    const api = node?.properties.api
    if (typeof api === 'undefined') return
    api.request.url = replaceVarDisplayNameWithId(chart, url)
    chart.nodes[nodeId].properties.api = api
    const newChart = resetAPIResult(chart)
    if (typeof newChart !== 'undefined') setUpdatedChart(newChart)
  }

  /**
   * Handles request method change.
   * @param {string} method
   */
  function onMethodChange(method: string): void {
    if (method === 'get' || method === 'post' || method === 'put' || method === 'delete' || method === 'patch') {
      if (typeof nodeId === 'undefined') return
      const node = chart.nodes[nodeId]
      const api = node?.properties.api
      if (typeof api === 'undefined') return
      api.request.method = method
      chart.nodes[nodeId].properties.api = api
      const newChart = resetAPIResult(chart)
      if (typeof newChart !== 'undefined') setUpdatedChart(newChart)
    }
  }

  // ======= USE EFFECT =======
  useEffect(
    function () {
      const node = chart.nodes[chart.selected?.id || '']
      // const loop = node.properties.loop
      const headers = node.properties.api?.request?.headers
      const method = node.properties.api?.request?.method
      const url = node.properties.api?.request?.url
      const params = node.properties.api?.request?.params
      const body = node.properties.api?.request?.body
      setChart(origChart)

      // header
      if (headers) {
        setHeaders(headers)
      } else {
        setHeaders({})
      }
      // params
      if (params && !isEmpty(params)) {
        // setToggleParams('on')
        setParams(params)
      } else {
        // setToggleParams('off')
        setParams({})
      }
      // body
      if (body && !isEmpty(body)) {
        // setToggleBody('on')
        setBody(body)
      } else {
        // setToggleBody('off')
        setBody({})
      }
      // method
      if (method) setMethod(method)
      // url
      if (url) setUrl(url)
    },
    [origChart],
  )

  return (
    <div className={classes.selectedDialogContainer}>
      <div className={classes.inputWrapper}>
        <div>
          <Typography>Methode & URL</Typography>

          <div className={classes.methodUrlContainer}>
            {/* METHOD */}
            <div className={classes.methodContainer}>
              <RequestMethodField method={method} options={methodOptions} methodSelectionCallback={onMethodChange} />
            </div>
            {/* URL */}
            <div className={classes.urlContainer}>
              <TextFieldVariablesSuggestions
                id='request-url'
                chart={chart}
                label='URL'
                value={url}
                fullWidth
                maxRows={2}
                onChange={onUrlChange}
                onBlur={onUrlBlur}
              />
            </div>
          </div>
        </div>
        <Separator />

        {typeof nodeId !== 'undefined' && (
          <div>
            {/* HEADERS */}
            <SAPIRequestHeaders
              classes={classes}
              chart={chart}
              nodeId={nodeId}
              headers={headers}
              resetAPIResult={resetAPIResult}
              setUpdatedChartCallback={setUpdatedChart}
            />
            <Separator />
            {/* PARAMS */}
            <SAPIRequestParams
              chart={chart}
              classes={classes}
              nodeId={nodeId}
              params={params}
              resetAPIResult={resetAPIResult}
              setUpdatedChartCallback={setUpdatedChart}
            />
            <Separator />
            <SAPIRequestBody
              chart={chart}
              classes={classes}
              nodeId={nodeId}
              body={body}
              resetAPIResult={resetAPIResult}
              setUpdatedChartCallback={setUpdatedChart}
            />
          </div>
        )}
      </div>
    </div>
  )
}
