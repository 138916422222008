import { cloneDeep, isEmpty } from 'lodash'

import { replaceVarDisplayNameWithId, replaceVarIdWithDisplayName } from '../../../../../../utils/chartUtils'
import { Chart, KeyValueObject, RequestBody } from '../../../../../../@types/Flowchart/types'

/**
 * Replaces variable ids for params with their display name.
 */
export function replaceVarIdsInObject(chart: Chart, object: KeyValueObject): KeyValueObject {
  const displayValue = {}
  const value = cloneDeep(object)
  for (const key of Object.keys(value)) {
    displayValue[key] = replaceVarIdWithDisplayName(chart, value[key])
  }

  return displayValue
}

/**
 * Prepares variable value text that is displayed in the body textfield.
 * Replaces variable ids with their display name for better usability.
 * @param {Chart} chart
 * @param {RequestBody} body
 * @param {Loop} loop
 */
export function replaceVarIdInBody(chart: Chart, body: RequestBody): RequestBody {
  let bodyWithDisplayName = cloneDeep(body)

  if (typeof chart.selected?.id === 'undefined') return {}
  const node = chart.nodes[chart.selected.id]
  const apiRequest = node?.properties?.api?.request
  if (typeof apiRequest === 'undefined') return {}

  // ===== NO LOOP =====
  if (isEmpty(bodyWithDisplayName)) bodyWithDisplayName = ''
  const value =
    typeof bodyWithDisplayName === 'object' ? JSON.stringify(bodyWithDisplayName, null, '\t') : bodyWithDisplayName
  const displayValue = replaceVarIdWithDisplayName(chart, value)

  return displayValue
}

/**
 * Takes the displayed text of the params and transforms it into the correct format for the flowchart.
 * Replaces variable display name with variable id.
 *
 * @param {Chart} chart
 * @param {RequestParams} object
 * @param {Loop} loop
 */
export function replaceVarDisplayNameInObject(chart: Chart, object: KeyValueObject): KeyValueObject {
  const objectWithId = cloneDeep(object)

  for (const key of Object.keys(objectWithId)) {
    const newKey = replaceVarDisplayNameWithId(chart, key)
    const displayValue = objectWithId[key]
    const value = typeof displayValue === 'string' ? replaceVarDisplayNameWithId(chart, displayValue) : displayValue
    delete objectWithId[key]
    objectWithId[newKey] = value
  }

  return objectWithId
}

/**
 * Takes the displayed text of the body and transforms it into the correct format for the flowchart.
 * Replaces variable display name with variable id.
 *
 * @param {Chart} chart
 * @param {RequestBody} body
 * @param {Loop} loop
 */
export function replareVarDisplayNameInBody(chart: Chart, body: RequestBody): RequestBody {
  let bodyWithId = cloneDeep(body)

  const displayValue = typeof bodyWithId === 'string' ? bodyWithId : JSON.stringify(bodyWithId)

  const value = replaceVarDisplayNameWithId(chart, displayValue)
  try {
    bodyWithId = JSON.parse(value)
  } catch (err) {
    // this is valid. If we have variable that is a number as json value, we do not want to put it into "" as this would effectively make the number a string further down
    bodyWithId = value
  }

  return bodyWithId
}
