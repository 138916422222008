import React from 'react'
import IconCard from '../../../../components/Cards/IconCard'

export type AddLanguageCardProps = {
  onClick: () => void
  disableInteraction?: boolean
}

export default function AddLanguageCard({ onClick, disableInteraction }: AddLanguageCardProps): React.ReactElement {
  return (
    <IconCard
      onClick={onClick}
      footerText='Sprache hinzufügen'
      icon={<i className={`ri-add-line`} />}
      height='220px'
      width='100%'
      disabled={disableInteraction}
    />
  )
}
