import React, { createContext, useContext, useEffect, useReducer, useRef } from 'react'
// Types
// Contexts
import { useBotContext } from './bot-context'
// API
import { cloneDeep } from 'lodash'
import { BotInfos } from '../../@types/BotInformation/types'
import { Chart } from '../../@types/Flowchart/types'
import { getFlow as loadChartApi } from '../../api/StudioBackend'
import { useErrorContext } from './errorContext'
import { useLockingContext } from './locking-context'
import { useStudioContext } from './studio-context'
import { useStudioNotificationContext } from './studio-notification-context'

// ----- TYPE DEFINITIONS -----

// Maps Actions to Payloads - Standard does not need customization
type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key
      }
    : {
        type: Key
        payload: M[Key]
      }
}

export type Loading = 'loading' | 'success' | 'error' | undefined

type SetLoadingOptions = { loadingChart?: Loading }

// Possible Actions
enum Types {
  SetLoading = 'SET_LOADING',
  SetChart = 'SET_CHART',
}

// Payloads for each action
type ChartPayload = {
  [Types.SetLoading]: { loadingChart: Loading }
  [Types.SetChart]: { chart: Chart }
}

type Action = ActionMap<ChartPayload>[keyof ActionMap<ChartPayload>]
type State = {
  loadingChart?: Loading
  chart?: Chart
}

type Dispatch = (action: Action) => void
type ChartContextProviderProps = { children: React.ReactNode }

/**
 * ----- CONTEXT -----
 * Locking Context is a local context used for managing
 *
 * To access this context:
 * import { useLockingContext } from '<path>/hooks/contexts/locking-context.tsx'
 *
 * // in a function
 * const {lockState, lockedBy} = useLockingContext()
 *
 * setLock()
 */

const ChartsContext = createContext<
  | {
      state: State
      dispatch: Dispatch
      setLoading: (options: SetLoadingOptions) => void
      setChart: (chart: Chart) => void
    }
  | undefined
>(undefined)

function chartReducer(state: State, action: Action): State {
  switch (action.type) {
    case Types.SetLoading: {
      const newState = { ...state }
      if (Object.keys(action.payload).includes('loadingCharts')) {
        newState.loadingChart = action.payload.loadingChart
      }
      return newState
    }
    case Types.SetChart: {
      return {
        ...state,
        chart: cloneDeep(action.payload.chart),
      }
    }
    default: {
      // helps us avoid typos!
      throw new Error(`[Chart-Reducer] Unhandled action type: ${(action as any).type}`)
    }
  }
}

/**
 * Context provider for providing StudioContext
 * @returns
 */
function ChartContextProvider({ children }: ChartContextProviderProps): JSX.Element {
  const { bot } = useBotContext() as { bot: BotInfos } // can cast here because bot has to be set if this provider is used
  const isinitializedRef = useRef<boolean>(false)
  const { sessionId } = useStudioContext()
  const { setError } = useErrorContext()
  // console.log('SessionId: ', sessionId)
  // const [sessionId, setSessionId] = useState<string | null>(null)
  const { setNotification, clearNotification, Notification } = useStudioNotificationContext()
  // init to isBlocked -> we only allow editing if the user really got a lock!
  const [state, dispatch] = useReducer(chartReducer, {
    loadingChart: undefined,
  })
  const { loadingChart } = state

  /**
   * Sets loading state.
   * @param loadingObject {loadingChart: Loading, etc.} to set specific states.
   */
  function setLoading(loadingOptions: SetLoadingOptions): void {
    if (Object.keys(loadingOptions).includes('loadingChart')) {
      dispatch({ type: Types.SetLoading, payload: { loadingChart: loadingOptions.loadingChart } })
    }
  }

  /**
   * Sets chart in state.
   * @param chart
   */
  function setChart(chart: Chart): void {
    dispatch({ type: Types.SetChart, payload: { chart } })
  }

  /**
   * Fetches translation file from API and sets it in state.
   */
  async function loadChart(): Promise<void> {
    if (!bot?.id) return
    setLoading({ loadingChart: 'loading' })
    const chart = await loadChartApi(bot.id)
    if (chart?.chart) {
      setChart(chart.chart)
      isinitializedRef.current = true
    } else {
      // set error
      setError(
        'Chart.loadChartError',
        'Der Konversationsfluss des Assistenten konnte nicht geladen werden. Bitte versuchen Sie es erneut.',
        'Erneut versuchen',
        loadChart,
        'Fehler beim Laden des Konversationsflusses',
      )
    }
    setLoading({ loadingChart: undefined })
  }

  useEffect(() => {
    // initially load translations. We need this to get languages of bot.
    if (!bot || isinitializedRef.current) return
    // console.log('provider: Loading translation file')
    loadChart()
  }, [bot])

  useEffect(() => {
    // reset loading state after time sensitive states
    let timeout
    if (loadingChart === 'success' || loadingChart === 'error') {
      timeout = setTimeout(() => {
        setLoading({ loadingChart: undefined })
      }, 3000)
    }
    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [loadChart])

  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  // const value = useMemo(() => [state, dispatch], [state])

  const value = { state, dispatch, setLoading, setChart }
  return <ChartsContext.Provider value={value}>{children}</ChartsContext.Provider>
}

/**
 * Hook for accessing and manipulating the LockingContext state
 * @returns
 *
 * To access this context:
 * import { useLockingContext } from '<path>/hooks/contexts/locking-context.tsx'
 *
 * // in a function
 * const {lockState, lockedBy} = useLockingContext()
 *
 */
function useChartContext(): {
  loadingChart: Loading
  chart?: Chart
} {
  const { bot, setBot } = useBotContext()
  const { setNotification } = useStudioNotificationContext()
  const { lockState } = useLockingContext()
  const context = useContext(ChartsContext)
  if (context === undefined) {
    throw new Error('[useChartContext] useChartContext must be used within a ChartContextProvider')
  }

  // splitting it up, allows us to add additional api calls
  const { state, dispatch, setLoading, setChart } = context
  const { loadingChart, chart } = state

  // ---- API ----
  // To not let anything work on the context state directly and to have an easy to use API

  // Controll what is returned
  return {
    loadingChart,
    chart,
  }
}

export { ChartContextProvider, useChartContext }
