import React, { useEffect, useRef, useState } from 'react'
import { Route, useParams, Navigate, Routes, useNavigate, useResolvedPath, useLocation } from 'react-router-dom'
import { useErrorContext } from '../../../../hooks/contexts/errorContext'
import { useAnswers } from '../../../../hooks/contexts/answers-context'

import { makeStyles } from 'tss-react/mui'

import ContentPage, { ContentPageHeader } from '../../../../components/Page/ContentPage'
import BaseCard from '../../../../components/Cards/BaseCard'
import SelectDropdown, { ActionMeta, Option } from '../../../../components/Dropdown/SelectDropdown'
import { Button, FabButton } from '../../../../components/Buttons'
import ErrorComponent from '../../../../components/Error/Error'
import CircularLoading from '../../../../components/Loading/CircularLoading'
import Mindmap from './Mindmap/Mindmap'
import List from './AnswerList/List'
import { Answer, AnswerWithoutIntent, TriggerAnswerWithoutIntent } from '../../../../classes/Knowledge'
import { LoadingState, ModelStatus, AnswerTemplateData } from '../../../../@types/Knowledge/types'
import CreateAnswerDialog from './AnswerCreation/CreateAnswerDialog'

// Constants
import {
  ROUTE_BOTS,
  ROUTE_BOTID_KNOWLEDGE,
  ROUTE_BOTID_KNOWLEDGE_NLU_CONVMANAGEMENT_EDITANSWER,
  ROUTE_BOTID_KNOWLEDGE_NLU_CONVMANAGEMENT_LIST,
  ROUTE_BOTID_KNOWLEDGE_NLU_CONVMANAGEMENT_MINDMAP,
  ROUTE_BOTID_KNOWLEDGE_NLU_CONVMANAGEMENT,
} from '../../../../utils/constants'
import EditAnswer from './AnswerEdit/EditAnswer'
import CustomizedTooltip from 'components/Tooltips/CustomContentTooltip'
import { Typography } from '@mui/material'
import { Dialog } from 'components/Dialogs'
import { useBotContext } from 'hooks/contexts/bot-context'
import { useLockingContext } from 'hooks/contexts/locking-context'
import { Pagination } from '../../../../@types/Tables/types'
import { useStudioNotificationContext } from 'hooks/contexts/studio-notification-context'
import { Searchfield } from 'components/TextInput/Searchfield'

const useStyles = makeStyles()((theme) => ({
  createAnswerButton: { position: 'absolute', zIndex: 1000, bottom: theme.spacing(3), right: theme.spacing(3) },
}))

export default function ConversationManagement(): React.ReactElement {
  const { classes } = useStyles()
  const url = useResolvedPath('').pathname

  const navigate = useNavigate()
  const { bot, hasOldClassicNLU } = useBotContext()
  const { lockState } = useLockingContext()
  const {
    answersArrayPrimaryLang,
    createAnswer,
    setKnowledgeType,
    modelStatus,
    setModelStatus: _setModelStatus,
    loading,
    trainModel,
    isModelTrainingButtonDisabled,
  } = useAnswers()
  const loadingRef = useRef<LoadingState | null>() // holds the loading state as ref for access in timeout callbacks
  loadingRef.current = loading

  let botId = ''
  const params = useParams()
  if (bot) {
    botId = bot.id
  } else {
    botId = params.botId as string
  }
  const { moduleConfigId } = params

  const views = [
    { label: 'Mind Map', value: 'mindmap' },
    { label: 'Liste', value: 'list' },
  ]
  // view
  const [selectedView, setSelectedView] = useState<Option>(
    window.location.pathname.includes(ROUTE_BOTID_KNOWLEDGE_NLU_CONVMANAGEMENT_MINDMAP) ? views[0] : views[1], // uses the window pathname, since path and url do not have the last path segment yet (map or list)
  )
  // pagination for list view
  const [listPagination, setListPagination] = useState<Pagination>({ page: 0, rowsPerPage: 10 })
  // collapsedTopics for mind map view
  const [collapsedTopics, setCollapsedTopics] = useState<string[]>([])
  // loading
  // const [loading, setLoading] = useState<LoadingState>()
  // const loadingRef = useRef<LoadingState>() // holds the loading state as ref for access in timeout callbacks
  // loadingRef.current = loading

  const modelStatusRef = useRef<ModelStatus | null>(null) // we keep model status in ref as well because setInterval in useEffect only knows the initial state, but we need to know the current one

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false)
  // const [answersArray, setAnswersArray] = useState<Answer[]>()
  // const [initialAnswers, setInitialAnswers] = useState<Answer[]>() // holds original answers to check for changes
  const [searchString, setSearchString] = useState<string>('') // search string for filtering answers
  const [hasSomethingChanged, setHasSomethingChanged] = useState<boolean>(false) // tracks if any answer has been changed (edited, created, deleted)
  // const [answerToEdit, setAnswerToEdit] = useState<Answer>() // holds answer that is being edited
  const [answerToCreate, setAnswerToCreate] = useState<{ createAnswer: boolean; topic: string }>() // set if answer should be created
  const [topicsWithoutAnswers, setTopicsWithoutAnswers] = useState<string[]>([])

  const [discardDialog, setDiscardDialog] = useState<boolean>(false) // defines if the the discard changes dialog is shown

  /**
   * Create answer callback.
   * Closes dialog and creates answer using answerContext.
   *ö
   * @param answer
   */
  async function onCreateAnswer(answer: AnswerWithoutIntent | TriggerAnswerWithoutIntent): Promise<void> {
    if (lockState !== 'canEdit') return
    // close dialog
    setAnswerToCreate(undefined)
    await createAnswer(answer)
  }

  // ========= CALLBACKS ==========

  /**
   * Callback function for mindmap and other views.
   * Begins answer creation by setting specified topic path
   * @param topicPath
   */
  function onCreateAnswerCallback(topicPath: string): void {
    setAnswerToCreate({
      createAnswer: true,
      topic: topicPath,
    })
  }

  /**
   * Callback function for mindmap and other views.
   * Sets answer to be edited in state. (E.g. when node in mindmap is clicked on)
   * @param answer
   */
  function onEditAnswer(answer: Answer): void {
    // setAnswerToEdit(answer)
    navigate(url + `/${answer.answerId}`)
  }

  /**
   * Callback for mindmap and other views.
   * Sets unsaved changes state.
   * @param hasUnsavedChanges
   */
  function onSetUnsavedChanges(hasUnsavedChanges: boolean): void {
    setHasUnsavedChanges(hasUnsavedChanges)
  }

  /**
   * Handles textinput into search field.
   * Updates search string in state.
   * @param event
   */
  function onSearchStringChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setSearchString(event.target.value)
  }

  // useEffect(() => {
  //   console.log('USEEFFECT PATH: ', path)
  //   setSelectedView(path.includes(ROUTE_BOTID_KNOWLEDGE_NLU_CONVMANAGEMENT_MINDMAP) ? views[0] : views[1])
  // }, [path])

  useEffect(() => {
    if (bot && bot.primaryLanguage) {
      // first render
      // init answerContext
      setKnowledgeType('specific')
    }
  }, [bot])

  // ===== ACTIONS ======
  const SearchField = (
    <Searchfield value={searchString} placeholder='Suchen' onChange={onSearchStringChange} autoFocus />
  )

  const Dropdown = (
    <SelectDropdown
      options={views}
      selected={selectedView}
      onChange={(newValue: Option, action: ActionMeta<Option>): void => {
        setSelectedView(newValue)
        const newRoute =
          ROUTE_BOTS +
          `/${botId}` +
          ROUTE_BOTID_KNOWLEDGE +
          `/${moduleConfigId}` +
          ROUTE_BOTID_KNOWLEDGE_NLU_CONVMANAGEMENT +
          (newValue.value === 'mindmap'
            ? ROUTE_BOTID_KNOWLEDGE_NLU_CONVMANAGEMENT_MINDMAP
            : ROUTE_BOTID_KNOWLEDGE_NLU_CONVMANAGEMENT_LIST)
        navigate(newRoute)
      }}
      isDisabled={loading === 'loading'}
    />
  )

  const NewAnswerButton = (
    <CustomizedTooltip
      placement='bottom'
      disableInteractive
      content={<Typography variant='body1'>Neue Antwort erstellen</Typography>}
      elements={
        <div>
          <Button
            size='normal'
            type='success'
            icon='add-line'
            iconType='remix'
            onClick={(): void => onCreateAnswerCallback('')}
            disabled={loading === 'loading' || lockState !== 'canEdit'}
          >
            Neue Antwort
          </Button>
        </div>
      }
    ></CustomizedTooltip>
  )

  // set actions based on route
  let actions = [Dropdown, NewAnswerButton]
  if (selectedView.value === 'list') {
    actions = [SearchField, ...actions]
  }

  type OverviewProps = {
    view: 'map' | 'list'
  }

  const Overview = ({ view }: OverviewProps) => (
    <>
      <ContentPageHeader
        title='Wissensdatenbank'
        actions={actions}
        previousUrl={ROUTE_BOTS + '/' + botId + ROUTE_BOTID_KNOWLEDGE + `/${moduleConfigId}`}
        previousUrlCallback={(): boolean => {
          return true
        }}
      />
      <BaseCard
        width='100%'
        height={selectedView.value === 'list' ? 'auto' : '100%'}
        minHeight='80vh'
        contentScrollable={view === 'list'}
      >
        <ErrorComponent errorCode='Knowledge.specific.loadAnswersError'>
          {/* this div ensures that min height is set */}
          {loading === 'creating' ? (
            <CircularLoading text='Antwort wird erstellt. Bitte warten...' size='medium' />
          ) : loading === 'loading' || typeof answersArrayPrimaryLang === 'undefined' ? (
            <CircularLoading text='Wissen wird geladen. Bitte warten...' size='medium' />
          ) : view === 'map' ? (
            <Mindmap
              key='mindmap'
              centerNodeName='Wissen'
              onEditAnswer={onEditAnswer}
              onCreateAnswerCallback={onCreateAnswerCallback}
              setUnsavedChanges={onSetUnsavedChanges}
              setTopicsWithoutAnswersCallback={setTopicsWithoutAnswers}
              collapsedTopics={collapsedTopics}
              setCollapsedTopics={setCollapsedTopics}
            />
          ) : view === 'list' ? (
            <List
              key='list'
              onEditAnswer={onEditAnswer}
              searchString={searchString}
              pagination={listPagination}
              updatePagination={({ page, rowsPerPage }): void => {
                setListPagination({ page, rowsPerPage })
              }}
            />
          ) : null}
          {/* <div className={classes.createAnswerButton}>
            <FabButton
              onClick={(): void => onCreateAnswerCallback('')}
              size='normal'
              type='success'
              disabled={loading === 'loading' || lockState !== 'canEdit'}
              icon={<i className='ri-add-line'></i>}
            >
              Neue Antwort
            </FabButton>
          </div> */}
        </ErrorComponent>
      </BaseCard>
    </>
  )

  return (
    <>
      <ContentPage>
        <Routes>
          {/* Overview */}
          <Route path={ROUTE_BOTID_KNOWLEDGE_NLU_CONVMANAGEMENT_MINDMAP + '/*'} element={<Overview view='map' />} />
          <Route path={ROUTE_BOTID_KNOWLEDGE_NLU_CONVMANAGEMENT_LIST + '/*'} element={<Overview view='list' />} />

          <Route
            path={ROUTE_BOTID_KNOWLEDGE_NLU_CONVMANAGEMENT_EDITANSWER + '/*'}
            element={
              <>
                {answersArrayPrimaryLang && (
                  <EditAnswer loadingState={loading ?? undefined} selectedView={selectedView.value} />
                )}
                {typeof answersArrayPrimaryLang === 'undefined' && (
                  <CircularLoading text='Antwort wird geladen...' size='medium' />
                )}
              </>
            }
          />
          <Route path='*' element={<Navigate to={ROUTE_BOTID_KNOWLEDGE_NLU_CONVMANAGEMENT_MINDMAP} />} />
        </Routes>
      </ContentPage>
      {answerToCreate?.createAnswer && (
        <CreateAnswerDialog
          onClose={(): void => setAnswerToCreate(undefined)}
          answers={answersArrayPrimaryLang || []}
          onCreateAnswer={onCreateAnswer}
          topicPath={answerToCreate?.topic ?? undefined}
          topicsWithoutAnswers={topicsWithoutAnswers}
          botId={botId}
        />
      )}
      {/* Discard changes dialog */}
      <Dialog
        id='discard-changes-dialog'
        size='small'
        open={discardDialog}
        closable
        onClose={(): void => {
          setDiscardDialog(false)
        }}
        title='Ungespeicherte Änderungen'
        primaryActionButton={
          <Button
            size='small'
            type='success'
            // icon={<Delete />}
            onClick={(): void => {
              setDiscardDialog(false)
            }}
          >
            Abbrechen
          </Button>
        }
        secondaryActionText='Verwerfen'
        onSecondaryActionClick={(): void => navigate(ROUTE_BOTS + '/' + botId + ROUTE_BOTID_KNOWLEDGE)}
      >
        <Typography>
          Es existieren ungespeicherte Änderungen. Sind Sie sicher, dass Sie zurückgehen möchten? Ihre Änderungen werden
          dabei verworfen.
        </Typography>
      </Dialog>
    </>
  )
}
