import React, { createContext, useCallback, useContext, useEffect, useMemo, useReducer, useRef } from 'react'
import { v4 as uuid } from 'uuid'
// Types
// Contexts
import { useBotContext } from './bot-context'
// API
import { MODULE_ID_CONVAISE_RAG } from 'utils/constants'
import { RAGModule } from '../../@types/BotInformation/types'
import {
  RAGCurrentlyIngestingDocument,
  RAGCurrentlyIngestingDocumentApi,
  RAGDocument,
  RAGDocumentForInsertion,
  RAGDocumentForUpdate,
  RAGDocumentType,
  RAGPdfDocForInsertion,
  RAGPdfDocForUpdate,
  RAGTxtDocForInsertion,
  RAGTxtDocForUpdate,
  RAGWebsiteSyncSchedule,
  RAGWordDocForInsertion,
  RAGWordDocForUpdate,
} from '../../@types/Knowledge/RAG/types'
import {
  addRAGDocuments as addRAGDocumentsAPI,
  deleteRAGDocuments as deleteRAGDocumentsAPI,
  getRAGDocuments as getRAGDocumentsAPI,
  updateRAGDocument as updateRAGDocumentAPI,
  parseRAGDocument as parseRAGDocumentAPI,
  parseRAGWebsite as parseRAGWebsiteAPI,
  getRAGDocumentParsingJobStatusAPI,
} from '../../api/StudioBackend'
import { useStudioNotificationContext } from './studio-notification-context'

// ----- TYPE DEFINITIONS -----

// Maps Actions to Payloads - Standard does not need customization
type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key
      }
    : {
        type: Key
        payload: M[Key]
      }
}

// Possible Actions
enum Types {
  SET_LOADING = 'SET_LOADING',
  SET_DOCUMENTS = 'SET_DOCUMENTS',
  ADD_CONTENT = 'ADD_CONTENT',
  DELETE_DOCUMENTS = 'DELETE_DOCUMENTS',
  REPLACE_DOCUMENTS = 'REPLACE_DOCUMENTS',
  RESET = 'RESET',
}

export type LoadingState =
  | 'loading'
  | 'creating'
  | 'creatingSuccess'
  | 'creatingError'
  | 'deleting'
  | 'deletingSuccess'
  | 'updating'
  | 'updatingSuccess'
  | 'parsing'
  | 'parsingSuccess'
  | 'parsingError'
  | undefined

// Payloads for each action
type RAGPayload = {
  [Types.SET_LOADING]: { loading: LoadingState }
  [Types.SET_DOCUMENTS]: { knowledgeDbId: string; documents: (RAGDocument | RAGCurrentlyIngestingDocument)[] }
  [Types.ADD_CONTENT]: { knowledgeDbId: string; documentId: string; content: string }
  [Types.DELETE_DOCUMENTS]: { knowledgeDbId: string; deletedDocumentIds: string[] }
  [Types.REPLACE_DOCUMENTS]: { knowledgeDbId: string; newDocuments: (RAGDocument | RAGCurrentlyIngestingDocument)[] }
  [Types.RESET]: {}
}

type Action = ActionMap<RAGPayload>[keyof ActionMap<RAGPayload>]
type State = {
  loading: LoadingState
  knowledgeDbs: {
    [knowledgeDbId: string]: {
      documents: {
        [documentId: string]: RAGDocument | RAGCurrentlyIngestingDocument
      }
    }
  }
}

type Dispatch = (action: Action) => void
type RAGProviderProps = { children: React.ReactNode }

/**
 * ----- CONTEXT -----
 * RAG Context is a local context used for managing our RAG knowledge
 *
 * To access this context:
 * import { useRAGContext } from '<path>/hooks/contexts/rag-context.tsx'
 *
 * // in a function
 * const {} = useRAGContext()
 *
 */

const RAGContext = createContext<
  | {
      state: State
      dispatch: Dispatch
      getDocuments: (
        knowledgeDbId: string,
        onlyMetadata: boolean,
        documentIds?: string[],
      ) => Promise<{ documents: RAGDocument[]; ingestingDocuments: RAGCurrentlyIngestingDocumentApi[] }>
      setLoading: (loading: LoadingState) => void
    }
  | undefined
>(undefined)

function ragReducer(state: State, action: Action): State {
  switch (action.type) {
    case Types.SET_LOADING: {
      return {
        ...state,
        loading: action.payload.loading,
      }
    }
    case Types.SET_DOCUMENTS: {
      if (action.payload.knowledgeDbId && action.payload.documents) {
        const { knowledgeDbId, documents } = action.payload
        if (!state.knowledgeDbs[knowledgeDbId]) state.knowledgeDbs[knowledgeDbId] = { documents: {} }
        documents.forEach((doc) => {
          state.knowledgeDbs[knowledgeDbId].documents[doc.documentId] = doc
        })
        return { ...state } // spread just in case
      }
      return state
    }
    case Types.ADD_CONTENT: {
      if (action.payload.knowledgeDbId && action.payload.documentId && action.payload.content) {
        const { knowledgeDbId, documentId, content } = action.payload

        const doc = state.knowledgeDbs[knowledgeDbId].documents[documentId]
        if (doc.type !== 'ingest-job') {
          ;(state.knowledgeDbs[knowledgeDbId].documents[documentId] as RAGDocument).content = content
        }

        return {
          ...state,
        }
      }
      return state
    }
    case Types.DELETE_DOCUMENTS: {
      if (action.payload.knowledgeDbId && action.payload.deletedDocumentIds) {
        const { knowledgeDbId, deletedDocumentIds } = action.payload
        const updatedDocs = { ...state.knowledgeDbs[action.payload.knowledgeDbId].documents }
        for (const documentId of deletedDocumentIds) {
          delete updatedDocs[documentId]
        }
        state.knowledgeDbs = {
          ...state.knowledgeDbs,
          [knowledgeDbId]: { documents: updatedDocs },
        }
      }
      return { ...state }
    }
    case Types.REPLACE_DOCUMENTS: {
      if (action.payload.knowledgeDbId && action.payload.newDocuments) {
        const { knowledgeDbId, newDocuments } = action.payload
        const updatedDocs = { ...state.knowledgeDbs[knowledgeDbId].documents }
        for (const newDocument of newDocuments) {
          updatedDocs[newDocument.documentId] = newDocument
        }
        state.knowledgeDbs = {
          ...state.knowledgeDbs,
          [knowledgeDbId]: { documents: updatedDocs },
        }
      }
      return { ...state }
    }
    case Types.RESET: {
      return {
        knowledgeDbs: {},
        loading: undefined,
      }
    }
    default: {
      // helps us avoid typos!
      throw new Error(`[RAG-Reducer] Unhandled action type: ${(action as any).type}`)
    }
  }
}

/**
 * Context provider for providing StudioContext
 * @returns
 */
function RAGContextProvider({ children }: RAGProviderProps): JSX.Element {
  const { bot } = useBotContext()
  const { setNotification } = useStudioNotificationContext()
  const isInitializedRef = useRef<boolean>(false)
  const ingestStatusCheckTimer = useRef<NodeJS.Timer | null>(null)

  const [state, dispatch] = useReducer(ragReducer, { knowledgeDbs: {}, loading: undefined })
  const stateRef = useRef(state)
  useEffect(
    function updateStateRef() {
      stateRef.current = state
    },
    [state],
  )

  /**
   * Gets currently ingesting documents.
   * These can either be new documents that are currently being ingested and created and do not exist in the DB yet ("ingest-jobs")
   * or existing documents that are currently being updated.
   * Existing documents that are currently being updated are flagged with an updateStatus flag which we use for handling in Studio.
   * They also have an ingest-job for the ingest of the updated version of the doc, but in Studio we use the existing document with the flag to
   * allow things like enabling to view the current document during the running ingest or if the ingest fails, still see the document that is currently live in the DB.
   * Mainly for simpler handling in Studio.
   * @returns
   */
  function getIngestingDocuments(): RAGCurrentlyIngestingDocument[] {
    const state = stateRef.current
    const ragModule = bot?.modules[MODULE_ID_CONVAISE_RAG] as RAGModule
    const ragKnowledgeDbId = ragModule?.knowledgeDbId

    if (!ragKnowledgeDbId) return []

    const docs = Object.values(state.knowledgeDbs[ragKnowledgeDbId]?.documents ?? {}).filter(
      (doc) =>
        (doc.type === 'ingest-job' && doc.status === 'running') || (doc as RAGDocument).updateStatus === 'running',
    )

    return docs as RAGCurrentlyIngestingDocument[]
  }

  /**
   * Creates timer that periodically fetches the documents that are currently being ingested.
   * Updates the document in the context once its ingest has finished or it failed.
   * Only performs a call if there are documents currently being ingested (= an ingest job is in the document list)
   * Adds a ingest-job as placeholder document for newly created documents.
   * Sets a flag on existing documents that are currently being updated (instead of the ingest-job placeholder).
   */
  function createIngestStatusCheckTimer(): void {
    if (ingestStatusCheckTimer.current) return
    const interval = setInterval(async () => {
      const ragKnowledgeDbId = (bot?.modules[MODULE_ID_CONVAISE_RAG] as RAGModule)?.knowledgeDbId
      if (!ragKnowledgeDbId) return

      const ingestingDocuments = getIngestingDocuments() // this returns ingest-jobs for newly created docs and docs that are currently being updated
      if (ingestingDocuments.length === 0) return

      const documentIds = ingestingDocuments.map((doc) => doc.documentId)
      // fetch the documents that are currently ingesting
      const { documents, ingestingDocuments: ingestingJobs } = await getDocuments(ragKnowledgeDbId, true, documentIds)

      const updatedDocuments: (RAGDocument | RAGCurrentlyIngestingDocument)[] = []
      let hasNewError = false

      for (const doc of ingestingDocuments) {
        // for each document that is currently ingesting, check if its status has changed
        const newDoc = documents.find((d) => d.documentId === doc.documentId)
        const updatedIngestDoc = ingestingJobs.find((d) => d.documentId === doc.documentId)

        // check if the document is already existing and currently being updated
        if (newDoc) {
          // UPDATE OF EXISTING DOC
          // see if there still exists an ingest job for the document
          if (updatedIngestDoc) {
            newDoc.updateStatus = updatedIngestDoc.status === 'error' ? 'error' : 'running'
            hasNewError ||= updatedIngestDoc.status === 'error'
          } else {
            // ingest job has finished
            // remove the update status flag
            delete newDoc.updateStatus
          }
          updatedDocuments.push(newDoc)
        } else if (updatedIngestDoc) {
          // CREATION OF NEW DOC
          if (updatedIngestDoc.status === 'error') {
            // ingest job failed
            hasNewError ||= updatedIngestDoc.status === 'error'
            updatedDocuments.push(updatedIngestDoc)
          }
        }

        if (hasNewError) {
          setNotification('error', 'Import für mindestens einen Inhalt fehlgeschlagen!', 3000)
        }

        if (updatedDocuments.length > 0) {
          dispatch({
            type: Types.REPLACE_DOCUMENTS,
            payload: { knowledgeDbId: ragKnowledgeDbId, newDocuments: updatedDocuments },
          })
        }
      }
    }, 5000)
    ingestStatusCheckTimer.current = interval
  }

  /**
   * Clears and resets interval that periodically checks ingest job status.
   */
  function clearIngestStatusCheckInterval(): void {
    if (ingestStatusCheckTimer.current) {
      clearInterval(ingestStatusCheckTimer.current)
      ingestStatusCheckTimer.current = null
    }
  }

  function setLoading(loading: LoadingState): void {
    dispatch({ type: Types.SET_LOADING, payload: { loading } })
  }

  /**
   * Load documents of knowledge db
   */
  async function getDocuments(
    knowledgeDbId: string,
    onlyMetadata = true,
    documentIds?: string[],
  ): Promise<{ documents: RAGDocument[]; ingestingDocuments: RAGCurrentlyIngestingDocument[] }> {
    if (!bot) return { documents: [], ingestingDocuments: [] }
    if (!knowledgeDbId) return { documents: [], ingestingDocuments: [] }
    try {
      const result = await getRAGDocumentsAPI(bot.id, knowledgeDbId, onlyMetadata, documentIds)
      if (!result) {
        throw new Error('GetDocuments result is null')
      }

      const { documents, currentlyIngestingDocuments: ingestingDocuments } = result

      if (documents) {
        documents.forEach((doc) => (doc.lastChanged = new Date(doc.lastChanged))) // ensure we have date object
      }
      return { documents, ingestingDocuments }
    } catch (err) {
      console.error(`[RAG] Could not load documents: ${err}`)
      setNotification('error', 'Dokumente konnten nicht geladen werden.', 3000)
      return { documents: [], ingestingDocuments: [] }
    }
  }

  /**
   * Performs initial load of documents.
   */
  async function initialLoad(): Promise<void> {
    const ragKnowledgeDbId = (bot?.modules[MODULE_ID_CONVAISE_RAG] as RAGModule)?.knowledgeDbId
    if (!ragKnowledgeDbId) return
    setLoading('loading')
    const { documents, ingestingDocuments } = await getDocuments(ragKnowledgeDbId, true)
    // check if a document is currently being ingested (updated)
    for (const doc of documents) {
      const ingestDoc = ingestingDocuments.find((ingestDoc) => ingestDoc.documentId === doc.documentId)
      if (ingestDoc) {
        doc.updateStatus = ingestDoc.status === 'running' ? 'running' : 'error'
      }
    }
    dispatch({
      type: Types.SET_DOCUMENTS,
      payload: { knowledgeDbId: ragKnowledgeDbId, documents: [...ingestingDocuments, ...documents] },
    })
    setLoading(undefined)
    isInitializedRef.current = true
  }

  /**
   * Resets all locks to initial state.
   */
  function reset(): void {
    dispatch({ type: Types.RESET, payload: {} })
    dispatch({ type: Types.SET_LOADING, payload: { loading: undefined } })
  }

  useEffect(
    function () {
      if (bot === null || isInitializedRef.current) return
      // initial load
      initialLoad()
      createIngestStatusCheckTimer()

      return () => {
        reset()
        clearIngestStatusCheckInterval()
      }
    },
    [bot],
  )

  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  // const value = useMemo(() => [state, dispatch], [state])
  const value = { state, dispatch, getDocuments, setLoading }
  return <RAGContext.Provider value={value}>{children}</RAGContext.Provider>
}

function useRAGContext(): {
  ragKnowledgeDbId: string
  documents: { [documentId: string]: RAGDocument | RAGCurrentlyIngestingDocument }
  documentsCurrentlyImporting: RAGCurrentlyIngestingDocument[]
  documentsWithImportError: RAGCurrentlyIngestingDocument[]
  loadContentOfDocument: (documentId: string) => Promise<{ content: string } | null>
  addNewStringDocument: (title: string, content: string, url?: string) => Promise<void>
  addNewFileDocument: (title: string, content: string, file: File, url?: string) => Promise<void>
  addNewWebsiteDocument: (
    title: string,
    content: string,
    url: string,
    htmlHash: string,
    keywords: string[],
    syncSchedule?: RAGWebsiteSyncSchedule,
  ) => Promise<void>
  updateStringDocument: (newTitle: string, newContent: string, documentId: string, url?: string) => Promise<void>
  updateFileDocument: (
    type: RAGDocumentType,
    documentId: string,
    title: string,
    content: string,
    filePath: string,
    file?: File,
    url?: string,
  ) => Promise<void>
  deleteDocument: (documentId: string) => Promise<void>
  parseDocument: (file: File) => Promise<{ jobId: string; status: string } | null>
  getDocumentParsingJobStatus: (
    jobId: string,
  ) => Promise<{ status: string; jobId: string; parsedContent?: string } | null>
  parseWebsite: (
    websiteUrl: string,
    botId: string,
    knowledgeDbId: string,
  ) => Promise<{
    status: 'success' | 'error'
    title?: string
    content: string // content of website or error message in case of error
    htmlHash?: string // only if success
    metaDescription?: string // only if success
    keywords?: string[] // only if success
  } | null>
  doesUrlExist: (url: string) => boolean
  loading: LoadingState
} {
  const { bot } = useBotContext()
  const { setNotification } = useStudioNotificationContext()
  const knowledgeDbId = (bot?.modules[MODULE_ID_CONVAISE_RAG] as RAGModule)?.knowledgeDbId
  const context = useContext(RAGContext)
  if (context === undefined) {
    throw new Error('[useLockingContext] useLockingContext must be used within a LockingContextProvider')
  }

  // splitting it up, allows us to add additional api calls
  const { state, dispatch, getDocuments, setLoading } = context

  // ---- API ----
  // To not let anything work on the context state directly and to have an easy to use API
  // State
  const { knowledgeDbs, loading } = state
  const documents = knowledgeDbs[knowledgeDbId]?.documents ?? {}

  function setDocument(knowledgeDbId: string, document: RAGDocument | RAGCurrentlyIngestingDocument): void {
    dispatch({ type: Types.SET_DOCUMENTS, payload: { knowledgeDbId, documents: [document] } })
  }

  /**
   * Load content of document and return it.
   */
  const loadContentOfDocument = useCallback(
    async (documentId: string): Promise<{ content: string; filePath?: string } | null> => {
      if (!bot || !knowledgeDbId) return null
      try {
        const tmpId = uuid()
        setLoading('loading')
        const result = await getDocuments(knowledgeDbId, false, [documentId])

        if (result && result.documents.length > 0) {
          const document = result.documents[0]
          if (document?.content) {
            setLoading(undefined)
            return { content: document.content, filePath: (document as any).filePath }
          }
        }
        throw new Error()
      } catch (err) {
        console.error(`[RAG] Could not load document conetnt: ${err}`)
        setNotification('error', 'Fehler beim Laden des Inhalts', 3000)
        setLoading(undefined)
        return null
      }
    },
    [documents],
  )

  /**
   * Loads newly created document and adds it to the context.
   * Sets it as "complete" document or as ingest-job if the document is currently being ingested.
   * @param knowledgeDbId
   * @param documentId
   */
  async function fetchNewOrUpdatedDocument(knowledgeDbId: string, documentId: string): Promise<void> {
    if (documentId) {
      const fetchResults = await getDocuments(knowledgeDbId, true, [documentId])
      // maybe document is already ingested. In this case it would appear as final document (not ingesting)
      if (
        fetchResults.documents.length > 0 &&
        fetchResults.documents[0].documentId === documentId &&
        fetchResults.ingestingDocuments.length === 0
      ) {
        // DOCUMENT CREATION (very fast, should be very rare)
        // document has already been added successfully - job no longer running
        const doc = fetchResults.documents[0]
        setDocument(knowledgeDbId, doc)
        setLoading('creatingSuccess')
        return
      } else if (
        fetchResults.documents.length === 0 &&
        fetchResults.ingestingDocuments.length > 0 &&
        fetchResults.ingestingDocuments[0].documentId === documentId
      ) {
        // DOCUMENT CREATION
        // the ingesting job is added as placeholder to the documents list for the user to see in the list
        const doc = fetchResults.ingestingDocuments[0]
        setDocument(knowledgeDbId, { ...doc, type: 'ingest-job' })
        setLoading('creatingSuccess')
        return
      } else if (
        fetchResults.documents.length > 0 &&
        fetchResults.ingestingDocuments.length > 0 &&
        fetchResults.ingestingDocuments[0].documentId === documentId &&
        fetchResults.documents[0].documentId === documentId
      ) {
        // DOCUMENT UPDATE
        // document does exist in DB and is currently being ingested
        // we flag the existing document with the isUpdating flag to show that it is currently being updated
        // Note: we do not use an ingest-job here because we might want to enable the user to view the (old) document while it is being updated
        const doc = fetchResults.documents[0]
        doc.updateStatus = 'running'
        setDocument(knowledgeDbId, doc)
        setLoading('updatingSuccess')
        return
      }
      throw new Error('Error adding document.')
    }
  }

  /**
   * Adds and creates a new text document.
   * On success: Adds document to context.
   * On failure: Sets notification
   */
  const addNewStringDocument = useCallback(
    async (title: string, content: string, url?: string): Promise<void> => {
      if (!bot) return
      if (!knowledgeDbId) return
      try {
        const documentId = uuid()
        const document: RAGDocumentForInsertion = {
          title,
          content,
          type: 'string',
          sourceUrl: url,
          documentId,
          lastChanged: new Date(),
        }
        setLoading('creating')
        const result = await addRAGDocumentsAPI(bot.id, knowledgeDbId, [document])

        if (result !== null) {
          await fetchNewOrUpdatedDocument(knowledgeDbId, documentId)
        } else {
          throw new Error()
        }
      } catch (err) {
        console.error(`[RAG] Could not create documents: ${err}`)
        setNotification('error', 'Fehler beim Hinzufügen des Texts.', 3000)
        setLoading(undefined)
      }
    },
    [bot, knowledgeDbId],
  )

  const updateStringDocument = useCallback(
    async (newTitle: string, newContent: string, documentId: string, url?: string): Promise<void> => {
      if (!bot || !knowledgeDbId || !documentId) return
      try {
        const tmpId = uuid()
        const document: RAGDocumentForUpdate = {
          title: newTitle,
          content: newContent,
          type: 'string',
          documentId,
          sourceUrl: url,
          lastChanged: new Date(),
          tmpId,
        }
        setLoading('updating')
        const result = await updateRAGDocumentAPI(bot.id, knowledgeDbId, document)
        if (result === null) {
          throw new Error('UpdateDocument result is null')
        }
        await fetchNewOrUpdatedDocument(knowledgeDbId, documentId)
      } catch (err) {
        console.error(`[RAG] Could not update document: ${err}`)
        setNotification('error', 'Fehler beim Aktualisieren des Inhalts.', 3000)
        setLoading(undefined)
      }
    },
    [bot, knowledgeDbId],
  )

  /**
   * Adds and creates a new website document.
   *
   * On success: Adds document to context.
   * On failure: Sets notification
   */
  const addNewWebsiteDocument = useCallback(
    async (
      title: string,
      content: string,
      url: string,
      htmlHash: string,
      keywords: string[],
      syncSchedule?: RAGWebsiteSyncSchedule,
    ): Promise<void> => {
      if (!bot) return
      if (!knowledgeDbId) return
      try {
        const documentId = uuid()
        const document: RAGDocumentForInsertion = {
          title,
          content,
          type: 'website',
          sourceUrl: url,
          documentId,
          lastChanged: new Date(),
          synonyms: keywords,
          website: {
            website_url: url,
            website_fetch_date: new Date().toISOString(),
            html_hash: htmlHash,
            meta_keywords: keywords,
            website_sync_schedule: syncSchedule,
          },
        }
        setLoading('creating')
        const result = await addRAGDocumentsAPI(bot.id, knowledgeDbId, [document])

        if (result !== null) {
          await fetchNewOrUpdatedDocument(knowledgeDbId, documentId)
        } else {
          throw new Error()
        }
      } catch (err) {
        console.error(`[RAG] Could not create documents: ${err}`)
        setNotification('error', 'Fehler beim Hinzufügen des website documents.', 3000)
        setLoading(undefined)
      }
    },
    [bot, knowledgeDbId],
  )

  const addNewFileDocument = useCallback(
    async (title: string, content: string, file: File, url?: string): Promise<void> => {
      if (!bot) return
      if (!knowledgeDbId) return
      try {
        const documentId = uuid()

        let doc: RAGDocumentForInsertion
        switch (file.type) {
          case 'application/pdf': {
            const pdfFile: RAGPdfDocForInsertion = {
              title,
              content,
              type: 'pdf',
              documentId,
              sourceUrl: url,
              lastChanged: new Date(),
              file,
              fileName: file.name,
            }
            doc = pdfFile
            break
          }
          case 'application/msword':
          case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
            // doc + docx
            const wordFile: RAGWordDocForInsertion = {
              title,
              content,
              type: 'docx',
              documentId,
              sourceUrl: url,
              lastChanged: new Date(),
              file,
              fileName: file.name,
            }
            doc = wordFile
            break
          }
          case 'text/plain':
          default: {
            // txt
            const txtFile: RAGTxtDocForInsertion = {
              title,
              content,
              type: 'txt',
              documentId,
              sourceUrl: url,
              lastChanged: new Date(),
              file,
              fileName: file.name,
            }
            doc = txtFile
            break
          }
        }

        setLoading('creating')
        const result = await addRAGDocumentsAPI(bot.id, knowledgeDbId, [doc])

        if (result !== null) {
          await fetchNewOrUpdatedDocument(knowledgeDbId, documentId)
        } else {
          throw new Error()
        }
      } catch (err) {
        console.error(`[RAG] Could not add new file documents: ${err}`)
        setNotification('error', 'Fehler beim Hinzufügen der Datei.', 3000)
        setLoading(undefined)
      }
    },
    [bot, knowledgeDbId],
  )

  /**
   * Updates a file document. If a new file is given, overwrites the file in storage.
   * Otherwise, simply updates the content and the metadata and leaves the file as is.
   */
  const updateFileDocument = useCallback(
    async (
      type: RAGDocumentType,
      documentId: string,
      title: string,
      content: string,
      filePath: string,
      file?: File,
      url?: string,
    ): Promise<void> => {
      if (!bot || !knowledgeDbId) return
      try {
        const tmpId = uuid()

        let doc: RAGDocumentForUpdate
        switch (type) {
          case 'pdf': {
            const pdfFile: RAGPdfDocForUpdate = {
              documentId,
              title,
              content,
              type: 'pdf',
              tmpId,
              sourceUrl: url,
              lastChanged: new Date(),
              file,
              filePath,
              fileName: file?.name,
            }
            doc = pdfFile
            break
          }
          case 'docx': {
            // doc + docx
            const wordFile: RAGWordDocForUpdate = {
              documentId,
              title,
              content,
              type: 'docx',
              tmpId,
              sourceUrl: url,
              lastChanged: new Date(),
              file,
              filePath,
              fileName: file?.name,
            }
            doc = wordFile
            break
          }
          case 'txt':
          default: {
            // txt
            const txtFile: RAGTxtDocForUpdate = {
              documentId,
              title,
              content,
              type: 'txt',
              tmpId,
              sourceUrl: url,
              lastChanged: new Date(),
              file,
              filePath,
              fileName: file?.name,
            }
            doc = txtFile
            break
          }
        }

        setLoading('updating')
        const result = await updateRAGDocumentAPI(bot.id, knowledgeDbId, doc)
        if (result === null) {
          throw new Error('UpdateDocument result is null')
        }
        await fetchNewOrUpdatedDocument(knowledgeDbId, documentId)
      } catch (err) {
        console.error(`[RAG] Could not update file document: ${err}`)
        setNotification('error', 'Fehler beim Aktualisieren der Datei.', 3000)
        setLoading(undefined)
      }
    },
    [bot, knowledgeDbId],
  )

  /**
   * Deletes a document.
   */
  const deleteDocument = useCallback(
    async (documentId: string): Promise<void> => {
      if (!bot) return
      if (!knowledgeDbId) return
      try {
        setLoading('deleting')
        const result = await deleteRAGDocumentsAPI(bot.id, knowledgeDbId, [documentId])
        if (result === null) {
          throw new Error('DeleteDocuments result is null')
        }
        const { deletedDocumentIds } = result
        // remove document from context
        dispatch({ type: Types.DELETE_DOCUMENTS, payload: { knowledgeDbId, deletedDocumentIds } })
        setLoading('deletingSuccess')
      } catch (err) {
        console.error(`[RAG] Could not delete document: ${err}`)
        setNotification('error', 'Fehler beim Löschen des Dokuments.', 3000)
        setLoading(undefined)
      }
    },
    [bot, knowledgeDbId],
  )

  const parseDocument = useCallback(async (file: File): Promise<{ jobId: string; status: string } | null> => {
    try {
      setLoading('parsing')
      const result = await parseRAGDocumentAPI(file)
      if (result === null) {
        throw new Error('ParseDocument result is null')
      }
      return result
    } catch (err) {
      console.error(`[RAG] Could not parse document: ${err}`)
      setNotification('error', 'Fehler beim Parsen des Dokuments.', 3000)
      setLoading('parsingError')
      return null
    }
  }, [])

  const getDocumentParsingJobStatus = useCallback(
    async (jobId: string): Promise<{ status: string; jobId: string; parsedContent?: string } | null> => {
      try {
        const result = await getRAGDocumentParsingJobStatusAPI(jobId)
        if (result === null) {
          throw new Error('GetParsingJobStatus result is null')
        }
        if (result.status === 'completed' && result.parsedContent) {
          // parsing has completed
          setLoading('parsingSuccess')
        } else if (result.status === 'error') {
          setLoading('parsingError')
          setNotification('error', 'Fehler beim Parsen des Dokuments.', 3000)
          return null
        }
        return result
      } catch (err) {
        console.error(`[RAG] Could not get parsing job status: ${err}`)
        setNotification('error', 'Fehler beim Abrufen des Parsing-Status.', 3000)
        setLoading(undefined)
        return null
      }
    },
    [],
  )

  const parseWebsite = useCallback(
    async (
      websiteUrl: string,
      botId: string,
      knowledgeDbId: string,
    ): Promise<{
      status: 'success' | 'error'
      title?: string
      content: string // content of website or error message in case of error
      htmlHash?: string // only if success
      metaDescription?: string // only if success
      keywords?: string[] // only if success
    } | null> => {
      try {
        setLoading('parsing')

        const result = await parseRAGWebsiteAPI(websiteUrl, botId, knowledgeDbId)
        if (result === null) {
          throw new Error('ParseWebsite result is null')
        }
        if (result.errorMsg) {
          // error
          setLoading('parsingError')
          return { status: 'error', content: result.errorMsg }
        }

        // success
        setLoading('parsingSuccess')
        return {
          status: 'success',
          title: result.title,
          content: result.content,
          htmlHash: result.html_hash,
          metaDescription: result.meta_description ?? undefined,
          keywords: result.meta_keywords ?? [],
        }
      } catch (err) {
        console.error(`[RAG] Could not parse document: ${err}`)
        setNotification('error', 'Fehler beim Parsen des Dokuments.', 3000)
        setLoading('parsingError')
        return null
      }
    },
    [],
  )

  const doesUrlExist = useCallback(
    (url: string): boolean => {
      return Object.values(documents).some((doc) => doc.type !== 'ingest-job' && doc.sourceUrl === url)
    },
    [documents],
  )

  const ragKnowledgeDbId = useMemo(() => knowledgeDbId, [knowledgeDbId])

  const documentsCurrentlyImporting = useMemo(() => {
    return Object.values(documents).filter(
      (doc) =>
        (doc.type === 'ingest-job' && doc.status === 'running') || (doc as RAGDocument).updateStatus === 'running',
    ) as RAGCurrentlyIngestingDocument[]
  }, [documents])

  const documentsWithImportError = useMemo(() => {
    return Object.values(documents).filter(
      (doc) => (doc.type === 'ingest-job' && doc.status === 'error') || (doc as RAGDocument).updateStatus === 'error',
    ) as RAGCurrentlyIngestingDocument[]
  }, [documents])

  // Controll what is returned
  return {
    ragKnowledgeDbId,
    documents,
    documentsCurrentlyImporting,
    documentsWithImportError,
    loadContentOfDocument,
    addNewStringDocument,
    addNewFileDocument,
    addNewWebsiteDocument,
    updateFileDocument,
    deleteDocument,
    updateStringDocument,
    parseDocument,
    getDocumentParsingJobStatus,
    parseWebsite,
    doesUrlExist,
    loading,
  }
}

export { RAGContextProvider, useRAGContext }
