import { Typography } from '@mui/material'
import React from 'react'
import Button from '../../../components/Buttons/Button'
import Dialog from '../../../components/Dialogs/Dialog'

type DeleteSubdialogModalProps = {
  dialogId: string
  onClose: () => void
  onDelete: (dialogId: string) => void
}

export default function DeleteSubdialogModal({
  dialogId,
  onClose,
  onDelete,
}: DeleteSubdialogModalProps): React.ReactElement {
  return (
    <Dialog
      id='delete-subdialog-modal'
      size='small'
      open
      title='Dialog löschen'
      closable
      onClose={onClose}
      primaryActionButton={
        <Button
          size='small'
          type='danger'
          onClick={(): void => onDelete(dialogId)}
          icon={'delete-bin-2-line'}
          iconType='remix'
        >
          Dialog löschen
        </Button>
      }
    >
      <Typography>
        Möchten Sie diesen Dialog und alle dazugehörigen Blöcke endgültig löschen? Dies kann nicht rückgängig gemacht
        werden!
      </Typography>
    </Dialog>
  )
}
