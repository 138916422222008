import React, { useState, useEffect } from 'react'

// @mui/material components
import xzufiSearchLeistung from 'assets/img/modules/xzufi_search_leistung.svg'
import { makeStyles } from 'tss-react/mui'
import { Chart, XZufiProperties } from '../../../../../@types/Flowchart/types'
import SelectDropdown, { Option } from '../../../../../components/Dropdown/SelectDropdown'
import { Grid, Typography, useTheme } from '@mui/material'
import ContentContainer from '../ContentContainer'
import { useBotContext } from 'hooks/contexts/bot-context'
import { XzufiModule } from '../../../../../@types/BotInformation/types'
import CustomContentTooltip from 'components/Tooltips/CustomContentTooltip'
import VariablesAutosuggestSelect from '../Variables/VariablesAutosuggestSelect'
import CardButton from '../../../../../components/Buttons/CardButton'
import { removeVariableFromNode } from 'utils/chartUtils'

const useStyles = makeStyles()((theme) => ({
  container: { height: '100%' },
  inputWithInfo: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  inputInfoIcon: {
    marginLeft: theme.spacing(1),
    height: '20px',
    color: theme.palette.grey[700],
  },
  moduleSelection: {
    marginTop: theme.spacing(4),
  },
  trackingContainer: {
    marginTop: theme.spacing(4),
  },
  trackHelpfulButtonContainer: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  variables: {
    marginTop: theme.spacing(4),
  },
  varSelectionContainer: {
    marginTop: theme.spacing(2),
  },
}))

enum VariableSelectionPurpose {
  CHOSEN_RESULT,
  CORRELATION_ID,
  TEXT_FEEDBACK,
}

type SXzufiTrackHelpfulProps = {
  chart: Chart
  setIsSaveDisabled: (isSaveDisabled: boolean) => void
  setStateCallback: (chart: Chart) => void
}

export default function SXzufiTrackHelpful({
  chart,
  setIsSaveDisabled,
  setStateCallback,
}: SXzufiTrackHelpfulProps): React.ReactElement {
  const { classes } = useStyles()
  const theme = useTheme()
  const { bot } = useBotContext()

  // module select
  const [moduleOptions, setModuleOptions] = useState<Option[]>([])
  const [selectedModuleId, setSelectedModuleId] = useState<string>()
  // variables select
  const [varIdChosenResult, setVarIdChosenResult] = useState<string>() // id of variable for storing the user selected chosen result
  const [varIdCorrId, setVarIdCorrId] = useState<string>() // id of variable for storing the correlation id of the answer
  const [varIdTextFeedback, setVarIdTextFeedback] = useState<string | null>() // id of variable for storing the text feedback of the user (can be empty)
  // track mode
  const [helpfulType, setIsHelpful] = useState<XZufiProperties['helpful']>()

  /**
   * Reads all xzufi modules configured for this bot and prepares them for the dropdown.
   * If only one xzufi module exists, sets it.
   */
  function prepareModuleOptions(): void {
    if (!bot) return

    const xzufiModules = Object.values(bot.modules).filter((module) => module.type === 'xzufi')
    const moduleOptions = xzufiModules.map((module) => {
      const { name, domain, moduleConfigId } = module as XzufiModule
      return {
        label: `${name} - ${domain}`,
        value: moduleConfigId,
      }
    })

    setModuleOptions(moduleOptions)
    if (moduleOptions.length === 1) {
      // only 1 possible module -> select it
      onModuleSelect({ label: '', value: moduleOptions[0].value })
    }
  }

  /**
   * Resets the chosen result variable.
   * Removes the node property and also removes variable completely from node.
   */
  function resetVariableForChosenResult(): void {
    const selectedId = chart.selected?.id
    if (typeof selectedId === 'undefined') return
    const node = chart.nodes[selectedId]
    if (typeof node === 'undefined') return

    const varIdToRemove = node.properties.xzufi?.xZufiChosenLeistungsId

    if (!varIdToRemove) return

    // delete node property
    delete node.properties.xzufi?.xZufiChosenLeistungsId
    chart.nodes[selectedId] = node

    // remove variable from node to ensure variable usage count is not messed up
    const newChart = removeVariableFromNode(chart, 'set', varIdToRemove, node.id)

    setStateCallback(newChart)
  }

  // ========= HANDLER ============

  /**
   * Handles module selection
   * @param newValue
   */
  function onModuleSelect(newValue: Option): void {
    const selectedId = chart.selected?.id
    if (typeof selectedId === 'undefined') return
    const node = chart.nodes[selectedId]
    if (typeof node === 'undefined') return

    const moduleId = newValue.value

    node.properties.moduleConfigId = moduleId

    // also set new text for display in dialog designer
    const module = bot?.modules[moduleId] as XzufiModule
    if (module) {
      const helpfulText =
        helpfulType === 'helpful'
          ? 'Positiv'
          : helpfulType === 'notHelpful'
          ? 'Negativ'
          : helpfulType === 'noAnswerFound'
          ? 'Keine Antwort'
          : ''
      node.properties.text = `${module.type} - ${module.name} - ${module.domain} - ${helpfulText}`
    }
    if (module) setSelectedModuleId(moduleId)

    chart.nodes[selectedId] = node
    setStateCallback(chart)
  }

  /**
   * Sets the variable in the node properties and updates the chart
   * @param selectedVarId
   * @param purpose
   */
  function onVariableSelect(newChart: Chart, purpose: VariableSelectionPurpose, selectedVarId?: string | null): void {
    const selectedId = newChart.selected?.id
    if (typeof selectedId === 'undefined') return
    const node = newChart.nodes[selectedId]
    if (typeof node === 'undefined') return

    if (!node.properties.xzufi) node.properties.xzufi = {}

    switch (purpose) {
      case VariableSelectionPurpose.CHOSEN_RESULT:
        if (selectedVarId) node.properties.xzufi.xZufiChosenLeistungsId = selectedVarId
        break
      case VariableSelectionPurpose.CORRELATION_ID:
        if (selectedVarId) node.properties.xzufi.corrId = selectedVarId
        break
      case VariableSelectionPurpose.TEXT_FEEDBACK:
        node.properties.xzufi.textFeedback = selectedVarId
        break
    }

    newChart.nodes[selectedId] = node
    setStateCallback(newChart)
  }

  /**
   * Handles variable selection for search result (found leistungen)
   * @param newChart
   * @param prevSelectedVarIds
   * @param selectedVarIds
   */
  function onVariableForChosenResultSelect(
    newChart: Chart,
    prevSelectedVarIds: string[],
    selectedVarIds: string[],
  ): void {
    const selectedVariableId = selectedVarIds.length > 0 ? selectedVarIds[0] : undefined
    onVariableSelect(newChart, VariableSelectionPurpose.CHOSEN_RESULT, selectedVariableId)
  }

  /**
   * Handles variable selection for correlation id
   * @param newChart
   * @param prevSelectedVarIds
   * @param selectedVarIds
   */
  function onVariableForCorrIdSelect(newChart: Chart, prevSelectedVarIds: string[], selectedVarIds: string[]): void {
    const selectedVariableId = selectedVarIds.length > 0 ? selectedVarIds[0] : undefined
    onVariableSelect(newChart, VariableSelectionPurpose.CORRELATION_ID, selectedVariableId)
  }

  /**
   * Handles variable selection for text feedback
   * @param newChart
   * @param prevSelectedVarIds
   * @param selectedVarIds
   */
  function onVariableForTextFeedbackSelect(
    newChart: Chart,
    prevSelectedVarIds: string[],
    selectedVarIds: string[],
  ): void {
    const selectedVariableId = selectedVarIds.length > 0 ? selectedVarIds[0] : null
    onVariableSelect(newChart, VariableSelectionPurpose.TEXT_FEEDBACK, selectedVariableId)
  }

  /**
   * Handles helpful change (click on one of the buttons)
   * Updates state and also node properties
   * @param isHelpful
   */
  function onHelpfulChange(isHelpful: XZufiProperties['helpful']): void {
    const selectedId = chart.selected?.id
    if (typeof selectedId === 'undefined' || typeof selectedModuleId === 'undefined') return
    const node = chart.nodes[selectedId]
    if (typeof node === 'undefined') return

    if (!node.properties.xzufi) node.properties.xzufi = {}

    node.properties.xzufi.helpful = isHelpful

    // also set new text for display in dialog designer
    const module = bot?.modules[selectedModuleId] as XzufiModule
    if (module) {
      const helpfulText =
        isHelpful === 'helpful'
          ? 'Positiv'
          : isHelpful === 'notHelpful'
          ? 'Negativ'
          : isHelpful === 'noAnswerFound'
          ? 'Keine Antwort'
          : ''
      node.properties.text = `${module.type} - ${module.name} - ${module.domain} - ${helpfulText}`
    }

    chart.nodes[selectedId] = node
    setIsHelpful(isHelpful)
    setStateCallback(chart)
  }

  useEffect(
    function () {
      const selectedId = chart.selected?.id
      if (typeof selectedId === 'undefined') return
      // get already selected Event
      const node = chart.nodes[selectedId]

      prepareModuleOptions()
      setVarIdCorrId(node.properties.xzufi?.corrId)
      setVarIdChosenResult(node.properties.xzufi?.xZufiChosenLeistungsId)
      setIsHelpful(node.properties.xzufi?.helpful)
      setVarIdTextFeedback(node.properties.xzufi?.textFeedback)
    },
    [chart, bot],
  )

  useEffect(
    function () {
      if (selectedModuleId && varIdCorrId && (helpfulType !== 'noAnswerFound' ? !!varIdChosenResult : true)) {
        setIsSaveDisabled(false)
      } else {
        setIsSaveDisabled(true)
      }
    },
    [selectedModuleId, varIdCorrId, varIdChosenResult, helpfulType],
  )

  return (
    <div className={classes.container}>
      <ContentContainer headerText='XZuFi Modul: Ergebnis bewerten' descriptionText='' svg={xzufiSearchLeistung}>
        <div className={classes.moduleSelection}>
          <div className={classes.inputWithInfo}>
            <Typography>XZuFi Modul</Typography>
            <CustomContentTooltip
              content={
                <Typography>
                  Bitte wählen Sie das XZuFi Modul Ihres Assistenten, für das Suchergebnisse bewertet werden sollen.
                </Typography>
              }
              elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
            />
          </div>
          <SelectDropdown
            onChange={onModuleSelect}
            options={moduleOptions}
            selected={selectedModuleId}
            placeholder='XZuFi Modul auswählen'
            width='100%'
          />
        </div>

        {selectedModuleId && (
          <>
            <div className={classes.trackingContainer}>
              <div className={classes.inputWithInfo}>
                <Typography>Welche Bewertung soll der Block tracken?</Typography>
                <CustomContentTooltip
                  content={
                    <Typography>
                      Sie können wählen, welche Bewertung dieser Block für die Suchergebnisse tracken soll. Positiv
                      bedeutet, Nutzer*innen haben gefunden, was sie gesucht haben. Negativ bedeutet, Nutzer*innen haben
                      nicht gefunden, was sie gesucht haben. Keine Antwort bedeutet, dass entweder keine Leistungen für
                      die Anfrage gefunden wurden oder in den Suchergebnissen keine passende Leistung gefunden werden
                      konnte.
                    </Typography>
                  }
                  elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
                />
              </div>

              <Grid container justifyContent={'center'} spacing={2} className={classes.trackHelpfulButtonContainer}>
                <Grid item xs={4}>
                  <CardButton
                    isSelected={helpfulType === 'helpful'}
                    onClick={(): void => onHelpfulChange('helpful')}
                    selectedColor={theme.palette.success.main}
                  >
                    <div style={{ display: 'flex' }}>
                      <Typography>Positiv</Typography>
                      {'  '}
                      <i
                        className='ri-thumb-up-fill'
                        style={{
                          marginLeft: theme.spacing(1),
                          color: helpfulType === 'helpful' ? theme.palette.success.main : undefined,
                        }}
                      />
                    </div>
                  </CardButton>
                </Grid>

                <Grid item xs={4}>
                  <CardButton
                    isSelected={helpfulType === 'notHelpful'}
                    onClick={(): void => onHelpfulChange('notHelpful')}
                    selectedColor={theme.palette.error.main}
                  >
                    <div style={{ display: 'flex' }}>
                      <Typography>Negativ</Typography>
                      {'  '}
                      <i
                        className='ri-thumb-down-fill'
                        style={{
                          marginLeft: theme.spacing(1),
                          color: helpfulType === 'notHelpful' ? theme.palette.error.main : undefined,
                        }}
                      />
                    </div>
                  </CardButton>
                </Grid>
                <Grid item xs={4}>
                  <CardButton
                    isSelected={helpfulType === 'noAnswerFound'}
                    onClick={(): void => {
                      // we need to reset the variable for the chosen result
                      onHelpfulChange('noAnswerFound')
                      resetVariableForChosenResult()
                    }}
                    selectedColor={theme.palette.primary.main}
                  >
                    <div style={{ display: 'flex' }}>
                      <Typography>Keine Antwort</Typography>
                      {'  '}
                      <i
                        className='ri-chat-off-fill'
                        style={{
                          marginLeft: theme.spacing(1),
                          color: helpfulType === 'noAnswerFound' ? theme.palette.primary.main : undefined,
                        }}
                      />
                    </div>
                  </CardButton>
                </Grid>
              </Grid>
            </div>

            <div className={classes.variables}>
              {helpfulType !== 'noAnswerFound' && (
                <div className={classes.varSelectionContainer}>
                  <div className={classes.inputWithInfo}>
                    <Typography>Variable mit der vom Nutzer ausgewählten Leistungs ID</Typography>
                    <CustomContentTooltip
                      content={
                        <Typography>
                          Bitte wählen Sie die Variable, die die von den Nutzer*innen gewählte Leistungs ID beinhaltet.
                        </Typography>
                      }
                      elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
                    />
                  </div>
                  <VariablesAutosuggestSelect
                    chart={chart}
                    onChange={onVariableForChosenResultSelect}
                    usageType='set'
                    isMulti={false}
                    isCreatable
                    isClearable
                    selectedVariableIds={varIdChosenResult ? [varIdChosenResult] : []}
                  />
                </div>
              )}
              <div className={classes.varSelectionContainer}>
                <div className={classes.inputWithInfo}>
                  <Typography>Variable mit Feedback-ID der Antwort</Typography>
                  <CustomContentTooltip
                    content={
                      <Typography>
                        Bitte wählen Sie die Variable, in der die Feedback-ID der Suchanfrage gespeichert wurde. Die
                        Feedback-ID wird verwendet, um bei der Bewertung der Suchergebnisse die korrekte Zuordnung zu
                        der Suche zu ermöglichen.
                      </Typography>
                    }
                    elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
                  />
                </div>
                <VariablesAutosuggestSelect
                  chart={chart}
                  onChange={onVariableForCorrIdSelect}
                  usageType='consume'
                  isMulti={false}
                  isCreatable
                  isClearable
                  selectedVariableIds={varIdCorrId ? [varIdCorrId] : []}
                />
              </div>
              <div className={classes.varSelectionContainer}>
                <div className={classes.inputWithInfo}>
                  <Typography>Variable mit Text Feedback des Nutzers (Optional)</Typography>
                  <CustomContentTooltip
                    content={
                      <Typography>
                        Bitte wählen Sie die Variable, in der das Text-Feedback des Nutzers gespeichert werden soll.
                      </Typography>
                    }
                    elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
                  />
                </div>
                <VariablesAutosuggestSelect
                  chart={chart}
                  onChange={onVariableForTextFeedbackSelect}
                  usageType='set'
                  isMulti={false}
                  isCreatable
                  isClearable
                  selectedVariableIds={varIdTextFeedback ? [varIdTextFeedback] : []}
                />
              </div>
            </div>
          </>
        )}
      </ContentContainer>
    </div>
  )
}
