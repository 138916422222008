import { cloneDeep, isEmpty, isEqual, xorWith } from 'lodash'

/**
 * Moves item in array from one index to another index.
 * @param arr
 * @param fromIndex
 * @param toIndex
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function arrayMove(arr: any[], fromIndex: number, toIndex: number): any[] {
  const newArr = cloneDeep(arr)
  if (toIndex >= newArr.length) toIndex = newArr.length - 1

  const element = newArr[fromIndex]
  newArr.splice(fromIndex, 1)
  newArr.splice(toIndex, 0, element)
  return newArr
}

/**
 * Renames a key of an object, while keeping its position within the object.
 * Object.keys would give the same order with the changed key in the same position.
 *
 * Previous: {
 *  key1: { name: 'key1' },
 *  key2: { name: 'key2' },
 *  key3: { name: 'key3' }
 * }
 *
 * Renamed: {
 *  key1: { name: 'key2' },
 *  'new-key-2': { name: 'key2' },
 *  key3: { name: 'key2' }
 * }
 *
 * @param obj
 * @param oldKey
 * @param newKey
 */
export function renameObjectKey(obj: object, oldKey: string, newKey: string): object {
  const changedKeyMap = { [oldKey]: newKey }
  const keys = Object.keys(obj)
  const value = obj[oldKey]
  const result = keys.reduce((acc, val) => {
    // modify key if neccessary
    if (changedKeyMap[val]) {
      val = changedKeyMap[val]
    }
    acc[val] = value
    return acc
  }, {})
  return result
}

// ----- Sorting -----

/**
 * Sorts array for a property desc or asc
 * @param array Array to be sorted
 * @param orderParam desc | asc
 * @param orderByParam Property name that is filtered by
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function stableSort(array: any[], orderParam: 'desc' | 'asc', orderByParam: string): any[] {
  function getValue(object: any, propertyPath: string): any {
    const properties = propertyPath.split('.')
    let value = object
    for (let i = 0; i < properties.length; i++) {
      if (Object.hasOwnProperty.call(value, properties[i])) {
        value = value[properties[i]]
      } else {
        throw new Error(`Invalid property path: ${propertyPath}`)
      }
    }
    return value
  }
  function getSorting(a: any, b: any, order: 'desc' | 'asc'): number {
    if (order === 'asc') {
      return a < b ? -1 : a > b ? 1 : 0
    } else {
      return a > b ? -1 : a < b ? 1 : 0
    }
  }
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = getSorting(getValue(a[0], orderByParam), getValue(b[0], orderByParam), orderParam)
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((result) => result[0])
}

// Sorting
function desc(a, b, orderBy: string): 1 | -1 | 0 {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getSorting(a, b, order: 'desc' | 'asc', orderBy: string): number {
  if (order === 'desc') {
    return desc(a, b, orderBy)
  } else {
    return -desc(a, b, orderBy)
  }
}

/**
 * Checks if two arrays of objects are equal
 * Reference: https://stackoverflow.com/questions/37065663/array-of-object-deep-comparison-with-lodash
 * @param x Array of objects the second one is compared to
 * @param y Array of objects geting compared with
 * @returns Boolean - true if euqal, false if not
 */
export function isArrayEqual(x: any[], y: any[]): boolean {
  return isEmpty(xorWith(x, y, isEqual))
}

/**
 * Converts a file to a base64 string.
 * Strips away the metadata at the beginning and ensures correct padding.
 * https://stackoverflow.com/a/52311051
 * @param file
 */
export async function pdfFileToBase64String(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (): void => {
      let encoded = (reader.result || '').toString().replace(/^data:(.*,)?/, '')
      if (encoded.length % 4 > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4))
      }
      resolve(encoded)
    }
    reader.onerror = (error): void => reject(error)
  })
}
