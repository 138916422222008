import React, { useEffect, useRef, useState } from 'react'
import { useParams, useNavigate, useResolvedPath } from 'react-router-dom'
import { useBotContext } from '../../../hooks/contexts/bot-context'

// Material UI
import { makeStyles } from 'tss-react/mui'

import { Grid, Typography } from '@mui/material'
import { Delete } from '@mui/icons-material'
// Custom Components
import { ContentPageHeader } from '../../../components/Page/ContentPage'
import Dialog from '../../../components/Dialogs/Dialog'
import Button from '../../../components/Buttons/Button'
import CircularLoading from '../../../components/Loading/CircularLoading'
import ErrorComponent from '../../../components/Error/Error'
import { SelectDropdown, Option } from 'components/Dropdown'

import LanguageCard from '../../../components/Cards/LanguageCard'
import AddLanguageCard from './SharedComponents/AddLanguageCard'
import AddLanguageDialog from './SharedComponents/AddLanguageDialog'

import { BotEnvironment, BotInfos } from '../../../@types/BotInformation/types'

import CustomizedTooltip from 'components/Tooltips/CustomContentTooltip'
import { Can } from 'components/Can/Can'
import { useLockingContext } from 'hooks/contexts/locking-context'
import { useTranslationsContext, Loading as LoadingTranslationState } from 'hooks/contexts/translations-context'
import {
  loadDictionary as loadTermsDictionary,
  useTermTranslationsContext,
} from 'hooks/contexts/term-translations-context'
import { Dictionary } from '../../../@types/Knowledge/Dictionaries/types'
import { useAnswers } from 'hooks/contexts/answers-context'

const dropdownOptions: Option[] = [
  { label: 'Test', value: 'staging', disabled: true, disabledText: '(nicht konfiguriert)' },
  { label: 'Production', value: 'production' },
]

type ConfirmPublishDialogProps = {
  onConfirm: (deployEnvironment: BotEnvironment) => void
  onClose: () => void
}

function PublishLanguagesDialog({ onConfirm, onClose }: ConfirmPublishDialogProps): React.ReactElement {
  const { bot: botInfos } = useBotContext() as { bot: BotInfos } // can cast safely because this dialog is only shown if the botinfos have been loaded and set
  const [hasStaging, setHasStaging] = useState<boolean>(false)
  const [deployEnvironment, setDeployEnvironment] = useState<BotEnvironment>()

  useEffect(function () {
    // check if bot has staging env, if not, simply offer production publish
    if (
      botInfos.webchatVersion === 'v4' &&
      (botInfos.integrationChannels.staging.courier !== null ||
        botInfos.integrationChannels.staging.directline !== null)
    ) {
      setHasStaging(true)
      setDeployEnvironment('staging')
      dropdownOptions[0].disabled = false
    }
  }, [])

  return (
    <Dialog
      id='confirm-publish-dialog'
      open
      title='Sprachen veröffentlichen'
      size='medium'
      primaryActionButton={
        <Button type='normal' onClick={(): void => onConfirm(deployEnvironment ?? 'production')}>
          Veröffentlichen
        </Button>
      }
      onClose={onClose}
    >
      {hasStaging ? (
        <>
          <Typography>
            Bitte wählen Sie die Umgebung in die der die aktuell aktivierten Sprachen veröffentlicht werden sollen.
            Bitte beachten Sie, dass bei einer Veröffentichung in <i>Production</i> auch immer die <i>Test</i>
            -Umgebung mit aktualisiert wird. Vorsicht beim Veröffentichen in <i>Production</i>. Stellen Sie immer zuerst
            in der Test-Umgebung sicher, dass alles so funktioniert wie geplant!
          </Typography>
          <Typography>
            <br />
            <strong>Achtung:</strong> Das Veröffentlichen der Sprachen und Übersetzungen veröffentlicht zwangsläufig
            auch die neueste Version des Konversationsflusses! Stellen Sie sicher, dass der Konversationsfluss so
            veröffentlicht werden kann.
          </Typography>
        </>
      ) : (
        <>
          <Typography>
            Möchten Sie die Sprachen veröffentlichen? Dadurch werden die aktuell aktivierten Sprachen des
            Live-Assistenten ersetzt und die Änderungen sofort veröffentlicht!
            <strong>Achtung:</strong>Das Veröffentlichen der Sprachen und Übersetzungen veröffentlicht zwangsläufig auch
            die neueste Version des Konversationsflusses! Stellen Sie sicher, dass der Konversationsfluss so
            veröffentlicht werden kann.
          </Typography>
          <Typography variant='caption' component='p' style={{ marginTop: '16px' }}>
            Dieser Assistent hat keine Test-Umgebung konfiguriert. &quot;Veröffentlichen&quot; macht die Änderungen
            direkt im Live-Assistenten für alle Nutzer*innen verfügbar.
          </Typography>
        </>
      )}

      <div style={{ marginTop: '16px' }}>
        <SelectDropdown
          options={dropdownOptions}
          onChange={(newValue): void => setDeployEnvironment(newValue.value)}
          selected={deployEnvironment}
          isClearable={false}
          isCreatable={false}
          isSearchable={true}
          width={'100%'}
        />
      </div>
    </Dialog>
  )
}

const useStyles = makeStyles()({
  overviewContainer: {
    height: '100%',
  },
  loadingContainer: {
    height: '100%',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
})

function PublishButton(): React.ReactElement {
  const { lockState } = useLockingContext()
  const {
    publishLanguagesAndTranslations,
    loadingTranslationFile: loadingLanguage,
    isPublishButtonEnabled,
  } = useTranslationsContext()
  const [displayPublishDialog, setDisplayPublishDialog] = useState<boolean>(false)

  function onPublishLanguages(): void {
    setDisplayPublishDialog(true)
  }

  return (
    <>
      <CustomizedTooltip
        placement='bottom'
        disableInteractive
        content={<Typography variant='body1'>Übersetzungen veröffentlichen</Typography>}
        elements={
          <div>
            <Can I='update' a='translations' passThrough>
              {(can: boolean): React.ReactElement => (
                <Button
                  // className={classes.button}
                  type='normal'
                  size='normal'
                  icon='chat-upload-line'
                  iconType='remix'
                  onClick={onPublishLanguages}
                  disabled={
                    !can || typeof loadingLanguage !== 'undefined' || lockState !== 'canEdit' || !isPublishButtonEnabled
                  }
                  loading={loadingLanguage === 'publishing'}
                >
                  Veröffentlichen
                </Button>
              )}
            </Can>
          </div>
        }
      ></CustomizedTooltip>
      {displayPublishDialog && (
        <PublishLanguagesDialog
          onClose={() => {
            setDisplayPublishDialog(false)
          }}
          onConfirm={(deployEnvironment: BotEnvironment) => {
            publishLanguagesAndTranslations(deployEnvironment)
            setDisplayPublishDialog(false)
          }}
        />
      )}
    </>
  )
}

/** */
export default function LanguagesOverview(): React.ReactElement {
  const { classes } = useStyles()
  const { bot } = useBotContext() as { bot: BotInfos; setBot: (bot: BotInfos) => void }
  const { lockState } = useLockingContext()
  const {
    translationFile,
    loadingTranslationFile: loadingLanguage,
    deleteLanguage,
    deactivateLanguage,
    activateLanguage,
    addLanguage,
  } = useTranslationsContext()
  const prevLoadingLanguageStateRef = useRef<LoadingTranslationState>(loadingLanguage)
  const { loading: loadingAnswers } = useAnswers()
  const { loading: loadingTerms } = useTermTranslationsContext()

  const timeoutIdRef = useRef<NodeJS.Timeout>() // holds id of timeout for confirm delete language dialog
  const [deleteCountdownSeconds, setDeleteCountdownSeconds] = useState<number>(3)

  const navigate = useNavigate()

  // const { url } = useRouteMatch()
  const url = useResolvedPath('').pathname
  let botId = ''
  const params = useParams()
  if (bot) {
    botId = bot.id
  } else {
    botId = params.botId as string
  }

  // const [loading, _setLoading] = useState<TranslationsLoading>('loading')
  // const [bot, _setBot] = useState<BotInfos | null>(botProps)
  // const [translationFile, _setTranslationFile] = useState<TranslationFile>()
  const [langToDelete, setLangToDelete] = useState<string>() // holds language code if delete button is pressed. If set, display confirm dialog
  const [showAddLanguageDialog, setShowAddLanguageDialog] = useState<boolean>(false)
  const [termsDictionary, setTermsDictionary] = useState<Dictionary>()

  /**
   * Resets states.
   */
  function resetStates(): void {
    setLangToDelete(undefined)
    setShowAddLanguageDialog(false)
    setDeleteCountdownSeconds(3)
  }

  /**
   * Handles language selection.
   * Pushes route.
   * @param langCode
   */
  function onSelectLanguage(langCode: string): void {
    navigate(url + `/${langCode}`)
  }

  /**
   * Opens delete confirm dialog.
   * @param langCode
   */
  function onDeleteLanguage(langCode: string): void {
    setLangToDelete(langCode)
    // clear any potentially existing timeouts
    // if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current)
    // // set a timeout to enable confirm delete button only after 2 seconds ~ 10 seconds feels too long as a user -> user thinks he/she is not allowed to
    // const timeout = setTimeout(function () {
    //   setIsConfirmDeleteButtonEnabled(true)
    // }, 2000)
    // timeoutIdRef.current = timeout
  }

  /**
   * Callback for confirm lang delete.
   * Deletes language.
   */
  async function onDeleteLanguageConfirm(): Promise<void> {
    if (!bot || !langToDelete) return
    await deleteLanguage(langToDelete)
  }

  /**
   * Aborts language creation / deletion.
   */
  function onAbort(): void {
    if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current)
    resetStates()
  }

  /**
   * Add language callback.
   * @param langCode
   * @returns
   */
  async function onAddLanguage(langCode: string): Promise<void> {
    if (!translationFile || !langCode) return
    await addLanguage(langCode)
    resetStates()
  }

  /**
   * NOTE: temporary doing this here so that we can have terms here.
   * Should normally be done through the context, but while our LockingContext cannot handle nested locks / multiple locks at once, we need to
   * fetch the terms in the language overview screen in Studio to display the number of translated entries.
   */
  async function loadTechnicalTerms(): Promise<void> {
    const termsDictionary = await loadTermsDictionary(bot)
    if (termsDictionary) setTermsDictionary(termsDictionary)
  }

  useEffect(function clearAnyRemainingTimeouts() {
    // clear timeout on unmount
    return (): void => {
      if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current)
    }
  }, [])

  useEffect(function loadTechnicalTermDictionary() {
    loadTechnicalTerms()
  }, [])

  useEffect(
    function updateDeleteCountdown() {
      let timer

      if (langToDelete && deleteCountdownSeconds > 0) {
        timer = setTimeout(() => {
          setDeleteCountdownSeconds(deleteCountdownSeconds - 1)
        }, 1000)
        if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current)
        timeoutIdRef.current = timer
      }

      // cleanup on unmount
      return () => {
        clearTimeout(timer)
      }
    },
    [langToDelete, deleteCountdownSeconds],
  )

  useEffect(
    function handleLoadingStateChanges() {
      if (loadingLanguage === 'error' || loadingLanguage === 'success') {
        setLangToDelete(undefined)
      }
      prevLoadingLanguageStateRef.current = loadingLanguage
    },
    [loadingLanguage],
  )

  useEffect(
    function () {
      const prev = prevLoadingLanguageStateRef.current
      if (
        (loadingLanguage === 'success' || loadingLanguage === 'error') &&
        (prev === 'activating' ||
          prev === 'creating' ||
          prev === 'deactivating' ||
          prev === 'deleting' ||
          prev === 'publishing')
      ) {
        // activate, deactivate, create or delete process has completed with success or error.
        // reset local states for hiding dialogs etc.
        resetStates()
      }
    },
    [loadingLanguage],
  )

  return (
    <>
      <ContentPageHeader
        title='Sprachen & Übersetzungen'
        actions={[<PublishButton key='publishbutton' />]}
        // previousUrl={ROUTE_BOTS + '/' + botId + ROUTE_BOTID_TRANSLATIONS}
      />
      <>
        <ErrorComponent errorCode='Knowledge.specific.loadAnswersError'>
          <div className={classes.overviewContainer}>
            {loadingLanguage === 'loading' ||
            loadingAnswers === 'loading' ||
            loadingTerms === 'loading' ||
            !translationFile ||
            !bot ? (
              <div className={classes.loadingContainer}>
                <CircularLoading text={'Übersetzungen werden geladen...'} />
              </div>
            ) : (
              <Grid container spacing={6}>
                {/* primary language card */}
                <Grid item xs={12} md={4}>
                  <LanguageCard
                    // lockState={lockState}
                    languageCode={bot.primaryLanguage}
                    // translationFile={translationFile}
                    onClick={onSelectLanguage}
                    onDelete={onDeleteLanguage}
                    onChangeActiveState={(): void => {
                      // nothing should happen
                    }}
                    // disableInteraction
                    isPrimaryLanguage
                    isActive={true}
                  />
                </Grid>
                {/* Secondary languages */}
                {translationFile.languages
                  .filter((lang) => lang !== translationFile.primaryLanguage)
                  .map((lang) => (
                    <Grid key={`lang-card-${lang}`} item xs={12} md={4}>
                      <LanguageCard
                        // lockState={lockState}
                        languageCode={lang}
                        onClick={onSelectLanguage}
                        onDelete={onDeleteLanguage}
                        onChangeActiveState={(langCode: string): void => {
                          if (translationFile.publishedLanguages.includes(langCode)) {
                            // deactivate
                            deactivateLanguage(langCode)
                          } else {
                            // activate
                            activateLanguage(langCode)
                          }
                        }}
                        isActive={translationFile.publishedLanguages.includes(lang)}
                      />
                    </Grid>
                  ))}
                <Grid key={'add-lang'} item xs={12} md={4}>
                  <Can I='create' a='translations' passThrough>
                    {(canCreate): React.ReactElement => (
                      <AddLanguageCard
                        onClick={(): void => setShowAddLanguageDialog(true)}
                        disableInteraction={lockState !== 'canEdit' || !canCreate}
                      />
                    )}
                  </Can>
                </Grid>
              </Grid>
            )}
          </div>
        </ErrorComponent>

        {/* Delete language confirm dialog */}
        {langToDelete && (
          <Dialog
            id='delete-confirm-dialog'
            size='small'
            open={!!langToDelete}
            closable
            onClose={onAbort}
            title='Sprache löschen'
            primaryActionButton={
              <Button
                size='small'
                type='danger'
                icon={<Delete />}
                disabled={deleteCountdownSeconds !== 0}
                onClick={onDeleteLanguageConfirm}
              >
                {deleteCountdownSeconds ? 'Löschen ' + `${'(' + deleteCountdownSeconds + ')'}` : 'Löschen'}
              </Button>
            }
          >
            {loadingLanguage === 'deleting' ? (
              <div className={classes.loadingContainer}>
                <CircularLoading text={'Sprache wird gelöscht...'} />
              </div>
            ) : (
              <Typography>
                Möchten Sie diese Sprache wirklich löschen? Dadurch werden alle Übersetzungen dieser Sprache entfernt.
                Dies kann nicht rückgängig gemacht werden!
              </Typography>
            )}
          </Dialog>
        )}
        {/* Add language dialog */}
        {showAddLanguageDialog && (
          <AddLanguageDialog
            loading={loadingLanguage}
            open={showAddLanguageDialog}
            existingLanguages={translationFile?.languages || []}
            onClose={onAbort}
            onAddLanguage={onAddLanguage}
          />
        )}
        {/* Create language loading */}
        {loadingLanguage === 'creating' && (
          <Dialog id='activate-language-dialog' size='small' open={true}>
            <div className={classes.loadingContainer}>
              <CircularLoading text={'Sprache wird hinzugefügt...'} />
            </div>
          </Dialog>
        )}
        {/* Activate language loading */}
        {loadingLanguage === 'activating' && (
          <Dialog id='activate-language-dialog' size='small' open={true}>
            <div className={classes.loadingContainer}>
              <CircularLoading text={'Sprache wird aktiviert...'} />
            </div>
          </Dialog>
        )}
        {/* Deactivate language loading */}
        {loadingLanguage === 'deactivating' && (
          <Dialog id='deactivate-language-dialog' size='small' open={true}>
            <div className={classes.loadingContainer}>
              <CircularLoading text={'Sprache wird deaktiviert...'} />
            </div>
          </Dialog>
        )}
        {/* Publishing language loading */}
        {loadingLanguage === 'publishing' && (
          <Dialog id='deactivate-language-dialog' size='small' open={true}>
            <div className={classes.loadingContainer}>
              <CircularLoading text={'Sprachen werden veröffentlicht...'} />
            </div>
          </Dialog>
        )}
      </>
      )
    </>
  )
}
