import React, { useEffect, useState } from 'react'

import { Chart, Nodes } from '../../../../../@types/Flowchart/types'
import SJumpConfigured from './SJumpConfigured'
import SJumpMenu from './SJumpMenu'
import SJumpNodeId from './SJumpNodeId'
import SJumpNodeSearch from './SJumpNodeSearch'
import { TranslationFile } from '../../../../../@types/Translations/types'

type LegalReason = 'NODE_NOT_EXIST' | 'NODE_IS_SELF'

export enum SJumpSelection {
  'BLOCK_ID' = 'BLOCK_ID',
  'BLOCK_SEARCH' = 'BLOCK_SEARCH',
}

enum Screen {
  'BLOCK_ID' = 'BLOCK_ID',
  'BLOCK_SEARCH' = 'BLOCK_SEARCH',
  'CONFIGURED' = 'CONFIGURED',
  'MENU' = 'MENU',
}

/**
 * Returns true if nodeId exists or is empty. Else returns false
 * @param nodeId
 * @param nodes
 */
function isNodeIdLegal(chart: Chart, nodeId: string, nodes: Nodes): { isLegal: boolean; reason?: LegalReason } {
  let isLegal = true
  if (typeof nodeId !== 'string') isLegal = false
  if (nodeId.length === 0) return { isLegal: true } // empty input is considered legal
  if (nodeId.trim() === '' || !Object.prototype.hasOwnProperty.call(nodes, nodeId)) {
    return {
      isLegal: false,
      reason: 'NODE_NOT_EXIST',
    }
  }

  if (chart.selected.id && chart.selected.id === nodeId) {
    return {
      isLegal: false,
      reason: 'NODE_IS_SELF',
    }
  }

  return { isLegal }
}

type SJumpProps = {
  chart: Chart
  translationFile: TranslationFile
  setSaveDisabled: (isDisabled: boolean) => void
  setStateCallback: (chart: Chart) => void
}

export default function SJump({
  chart,
  translationFile,
  setSaveDisabled,
  setStateCallback,
}: SJumpProps): React.ReactElement {
  const [screen, setScreen] = useState<Screen>(Screen.MENU)
  const [editMode, setEditMode] = useState<'edit'>()

  /**
   * Handles node selection.
   * Sets selected node id in targetNode property of current node in chart.
   * @param nodeId
   */
  function onNodeSelection(nodeId: string): void {
    if (isNodeIdLegal(chart, nodeId, chart.nodes) && chart.selected.id) {
      chart.nodes[chart.selected.id].properties.targetNode = nodeId
      setSaveDisabled(false)
      setStateCallback(chart)
    } else {
      setSaveDisabled(true)
    }
  }

  function onScreenSelection(selection: SJumpSelection): void {
    switch (selection) {
      case SJumpSelection.BLOCK_ID:
        setScreen(Screen.BLOCK_ID)
        break
      case SJumpSelection.BLOCK_SEARCH:
        setScreen(Screen.BLOCK_SEARCH)
        break
      default:
        setScreen(Screen.MENU)
    }
  }

  useEffect(function () {
    if (typeof chart.selected.id === 'undefined') return
    const targetNode = chart.nodes[chart.selected.id].properties.targetNode
    if (targetNode) setScreen(Screen.CONFIGURED)
    else setScreen(Screen.MENU)
    setSaveDisabled(true)
  }, [])

  return screen === Screen.CONFIGURED && editMode !== 'edit' ? (
    <SJumpConfigured chart={chart} translationFile={translationFile} onEdit={(): void => setEditMode('edit')} />
  ) : screen === Screen.BLOCK_ID ? (
    <SJumpNodeId chart={chart} translationFile={translationFile} onNodeSelection={onNodeSelection} />
  ) : screen === Screen.BLOCK_SEARCH ? (
    <SJumpNodeSearch chart={chart} translationFile={translationFile} onNodeSelection={onNodeSelection} />
  ) : (
    <SJumpMenu onSelection={onScreenSelection} />
  )
}
