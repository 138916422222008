import { makeStyles } from 'tss-react/mui'
import React, { memo } from 'react'

type ColorBoxProps = {
  color: 'default' | 'positive' | 'destructive'
  className?: string
}

type StyleProps = {
  color: 'default' | 'positive' | 'destructive'
}

// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. ArrowFunctionExpression in CSS prop.
const useStyles = makeStyles<StyleProps>()((theme, props) => ({
  // TODO: custom styling based on customer settings
  colorBox: {
    backgroundColor:
      props.color === 'positive'
        ? theme.palette.cardActions.positive
        : props.color === 'destructive'
        ? theme.palette.cardActions.destructive
        : theme.palette.cardActions.default,
    width: '25px',
    height: '25px',
    borderRadius: theme.shape.borderRadius,
  },
}))

export default memo(function ColorBox(props: ColorBoxProps & React.HTMLAttributes<HTMLDivElement>): React.ReactElement {
  const { color, className } = props
  const { classes } = useStyles({ color })
  return <div className={classes.colorBox + ' ' + className} />
})
