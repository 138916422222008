import React, { memo, useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
// Components
import { FabButton } from 'components/Buttons'
// Types
import { AnswerAction } from '../../../../../../@types/Knowledge/types'
// Hooks
import { useAnswers } from 'hooks/contexts/answers-context'
import { useLockingContext } from 'hooks/contexts/locking-context'
import ActionCard from 'components/Cards/ActionCard'
import EditAnswerActionsDialog from './EditAnswerActionsDialog'
import { Can } from 'components/Can/Can'

const useStyles = makeStyles()((theme) => ({
  actionOverview: {
    display: 'flex',
    zIndex: 200,
    padding: theme.spacing(6),
  },
  createActionButton: {
    position: 'absolute',
    zIndex: 1000,
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
}))

type Props = {
  answerId: string
}

export default memo(function EditAnswerActions({ answerId }: Props): React.ReactElement {
  const { classes } = useStyles()
  const {
    getAnswerAction,
    getAnswerTranslations,
    updateSingleAnswerTranslationsBatch,
    updateAnswer,
    flaggedAnswers,
    setAnswers,
    getAnswer,
    canIEditAnswer,
  } = useAnswers()
  const { lockState } = useLockingContext()

  const [actions, setActions] = useState<AnswerAction[]>([])
  const [actionIndexToEdit, setActionIndexToEdit] = useState<number>(-2) // 0-3 for the possible index of the action, -1 for new actions, -2 for dialog closed

  useEffect(() => {
    setActions(getAnswerAction(answerId))
  }, [flaggedAnswers])

  /**
   * Deletes action from answer and answer translations.
   * Stateful
   * @param index Index of the action
   */
  function onDeleteAction(index: number): void {
    if (!canIEditAnswer(answerId)) return

    // remove actions locally
    const newActions = actions
    newActions.splice(index, 1)
    // setActions(newActions)

    // remove actions from answer
    const _answer = getAnswer(answerId)
    if (_answer) {
      _answer.setActions(newActions)
      updateAnswer(_answer, 'modified')
    }

    // remove all translated action at index position
    const _translations = getAnswerTranslations(answerId)
    const _newTranslations = { ..._translations }
    for (const lang of Object.keys(_translations)) {
      _newTranslations[lang].actions.splice(index, 1)
    }
    updateSingleAnswerTranslationsBatch(_newTranslations, 'modified')
  }

  return (
    <Can I='update' a='knowledge' passThrough>
      {(can): React.ReactElement => (
        <div className={classes.actionOverview}>
          {/* Cards of Actions */}
          <Grid container spacing={6}>
            {actions &&
              Array.isArray(actions) &&
              actions.map((action, index) => (
                <Grid key={`action-${index}`} item xs={12} md={6}>
                  <ActionCard
                    action={action}
                    actionIndex={index}
                    onEdit={(actionIndex): void => setActionIndexToEdit(actionIndex)}
                    onDelete={onDeleteAction}
                    disabled={!canIEditAnswer(answerId)}
                  />
                </Grid>
              ))}
          </Grid>
          {/* Add Action Button */}
          <div className={classes.createActionButton}>
            <FabButton
              onClick={(): void => setActionIndexToEdit(-1)}
              size='normal'
              type='success'
              disabled={!can || lockState !== 'canEdit' || !canIEditAnswer(answerId)}
              icon={<i className='ri-add-line'></i>}
            >
              Folgefrage hinzufügen
            </FabButton>
          </div>
          {/* Edit / Create Action Dialog */}
          {actionIndexToEdit > -2 && (
            <EditAnswerActionsDialog
              open={actionIndexToEdit > -2}
              answerId={answerId}
              action={actionIndexToEdit > -1 ? actions[actionIndexToEdit] : undefined}
              actionIndex={actionIndexToEdit > -1 ? actionIndexToEdit : undefined}
              onClose={(): void => setActionIndexToEdit(-2)}
              onSubmit={(): void => setActionIndexToEdit(-2)}
            />
          )}
        </div>
      )}
    </Can>
  )
})
