import { QuestionAnswer } from '@mui/icons-material'
import React, { ReactElement } from 'react'
import EditorSidebarItem from '../editorSidebarItem'
import { FLOWDESIGNER_NODE_COLOR_MODULE } from 'utils/constants'

export default function xzufiGetAnswer(): ReactElement {
  return (
    <EditorSidebarItem
      type='module/xzufi-getAnswerNew'
      ports={{
        port1: {
          id: 'port1',
          type: 'left',
          position: { x: 0, y: 0 },
          properties: {
            type: 'incoming',
          },
        },
        portXzufiResults: {
          id: 'portXzufiResults',
          type: 'right',
          position: { x: 0, y: 0 },
          properties: {
            type: 'outgoing',
            name: 'Leistungen gefunden',
          },
        },
        portNoResults: {
          id: 'portNoResults',
          type: 'right',
          position: { x: 0, y: 0 },
          properties: {
            type: 'outgoing',
            name: 'Keine Ergebnisse',
          },
        },
      }}
      properties={{
        typeText: 'XZuFi Leistung suchen',
        text: 'Nicht konfiguriert',
        xzufi: {},
        dialog: '',
      }}
      Icon={QuestionAnswer}
      color={FLOWDESIGNER_NODE_COLOR_MODULE}
    />
  )
}
