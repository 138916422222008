import React, { useEffect, useState } from 'react'
import Dialog from 'components/Dialogs/Dialog'
import { makeStyles } from 'tss-react/mui'
import { Typography, Grid } from '@mui/material'
import Message from 'components/Webchat/Message'
import RMEEditor from 'components/TextEditors/editor-mdx-markdown'
import { Check, ErrorOutline } from '@mui/icons-material'
import { Button } from 'components/Buttons'
import CircularLoading from 'components/Loading/CircularLoading'

const useStyles = makeStyles()((theme) => ({
  errorRoot: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  errorText: {
    marginTop: theme.spacing(1),
    maxWidth: '75%',
  },
  container: {
    height: '100%',
    display: 'flex',
  },
  editAnswerContainer: {
    width: '100%',
    marginTop: theme.spacing(2),
    // height: 'fit-content',
    // display: 'flex',
    // flexDirection: 'column',
  },
  editAnswerItem: {
    // height: '380px',
    display: 'flex',
    flexDirection: 'column',
  },
  editAnswerTextfield: {
    flex: 1,
    marginTop: theme.spacing(2),
  },
  previewContainer: {
    height: '286px',
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius:
      typeof theme.shape.borderRadius === 'number' ? theme.shape.borderRadius * 2 : theme.shape.borderRadius,
    // flex: 1,
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    // overflow: 'hidden',
    // marginBottom: '60px',
  },
  previewContent: {
    overflow: 'auto',
    height: '100%',
  },
}))

type SimplificationStatus = 'loading' | 'complete' | 'error' | undefined

type TextSimplificationDialogProps = {
  open: boolean
  originalText: string
  simplifiedText: string
  simplificationStatus: SimplificationStatus
  onClose: () => void
  onSubmit: (newSimplifiedText: string) => void
}

export default function TextSimplificationDialog({
  open,
  originalText,
  simplifiedText: simplifiedTextProps,
  simplificationStatus: simplificationStatusProps,
  onClose,
  onSubmit,
}: TextSimplificationDialogProps): React.ReactElement {
  const { classes } = useStyles()
  const [simplifiedText, setSimplifiedText] = useState<string>()
  const [simplificationStatus, setSimplificationStatus] = useState<SimplificationStatus>()

  useEffect(() => {
    if (!simplifiedText) setSimplifiedText(simplifiedTextProps)
  }, [simplifiedTextProps])

  useEffect(() => {
    setSimplificationStatus(simplificationStatusProps)
  }, [simplificationStatusProps])

  return (
    <Dialog
      id='create-action-dialog'
      size='large'
      open={open}
      closable
      onClose={onClose}
      title='Text vereinfachen'
      primaryActionButton={
        <Button
          type='success'
          size='small'
          icon={<Check />}
          onClick={(): void => {
            setSimplifiedText(undefined)
            if (simplifiedText) onSubmit(simplifiedText)
          }}
          disabled={simplificationStatus !== 'complete' || !simplifiedText}
        >
          Übernehmen
        </Button>
      }
      secondaryActionText={'Schließen'}
      onSecondaryActionClick={onClose}
    >
      <div className={classes.container}>
        {/* Edit answer + Preview */}
        <div className={classes.editAnswerContainer}>
          <Grid container spacing={2}>
            <Grid item className={classes.editAnswerItem} xs={12} md={6}>
              {/* Preview answer */}
              <Typography>
                <strong>Vorschau</strong>
              </Typography>
              <Message markdownString={originalText} />
            </Grid>
            <Grid item className={classes.editAnswerItem} xs={12} md={6}>
              {/* Edit answer */}
              {simplificationStatus === 'complete' && simplifiedText ? (
                <>
                  <Typography>
                    <strong>Vereinfachter Text</strong>
                  </Typography>
                  <div className={classes.editAnswerTextfield}>
                    <RMEEditor
                      input={simplifiedText}
                      onChangeCallback={setSimplifiedText}
                      placeholder='Ihr Antworttext...'
                    />
                  </div>
                </>
              ) : simplificationStatus === 'loading' ? (
                <CircularLoading text='Text wird vereinfacht. Bitte warten...' size='large' />
              ) : simplificationStatus === 'error' ? (
                <div className={classes.errorRoot}>
                  <ErrorOutline style={{ color: 'red', fontSize: '35px' }} />
                  <div className={classes.errorText}>
                    <Typography>Das hat leider nicht geklappt. Fehler beim Vereinfachen des Texts.</Typography>
                  </div>
                </div>
              ) : null}
            </Grid>
          </Grid>
        </div>
      </div>
    </Dialog>
  )
}
