import { IAdaptiveCard, IContainer } from '../@types/SmartCards/types'
import jp from 'jsonpath'
import { cloneDeep } from 'lodash'
import { changeValuesByPath } from './jsonUtils'
import { Chart } from '../@types/Flowchart/types'

/**
 * Helper functions to edit and manage smart cards using the easy editor (not the big card designer).
 * Centralizing functionality to not have complete island solutions for the different card types.
 * Using jasonpath to get and edit json (cards)
 */

/**
 * Updates a card. Uses the query to identiy properties to update and sets the newValue for all identified query results.
 * @param card Card to update
 * @param query Query to choose properties to update
 * @param valueId Name of the property that will be updated
 * @param newValue New value that is set for the valueId and all paths that are found based on the query
 */
export function updateCardProperties(
  card: IAdaptiveCard | IContainer | object,
  query: string,
  valueId: string,
  newValue: string | number | any,
): IAdaptiveCard | IContainer | any {
  const updates = jp.apply(card, query, function (value) {
    return newValue
  })
  const newCard = changeValuesByPath(card, updates, valueId)
  return newCard
}

/**
 * Checks all actions in the card property for a style property, if it is not set it is set to 'default'.
 * Return the card with the potentially updated action styles.
 * @param card The card to check
 * @returns
 */
export function setActionStyleDefault(card: IAdaptiveCard | IContainer): IAdaptiveCard | IContainer {
  let newCard = cloneDeep(card)
  // collection of updated actions
  const actions = jp.apply(newCard, '$..actions', function (value) {
    // value is the action in an array like [{type: 'Action.Submit', title: 'Weiter'}]
    const newValue = value
    // check if a value is set and if that value is conform
    if (typeof newValue[0].style === 'undefined' || !isActionStyleConform(newValue[0].style)) {
      // set default style
      newValue.style = 'default'
    }
    // return action value
    return newValue
  })
  // update card actions with the changes from actions
  newCard = changeValuesByPath(newCard, actions, 'actions')

  return newCard
}

/**
 * Checks if a style property is conform with offered styles.
 * @returns true if conform, false if not
 * @param style style property from the card
 */
function isActionStyleConform(style: string): boolean {
  switch (style) {
    case 'default':
    case 'positive':
    case 'destructive':
      return true
    default:
      return false
  }
}

/**
 * Checks if a smart card is already used in a node.
 * Returns true or false.
 * @param chart
 * @param cardId
 */
export function isSmartCardUsed(chart: Chart, cardId: string): boolean {
  for (const node of Object.values(chart.nodes)) {
    if (node.type === 'basic/adaptiveCard') {
      if (node.properties.card === cardId) return true
    }
  }
  return false
}

/**
 * Counts usage of a certain smart card.
 * @param chart
 * @param cardId
 */
export function countSmartCardUsage(chart: Chart, cardId: string): number {
  if (cardId === '') return 0

  let usageCount = 0
  for (const node of Object.values(chart.nodes)) {
    if (node.type === 'basic/adaptiveCard') {
      if (node.properties.card === cardId) usageCount += 1
    }
  }
  return usageCount
}
