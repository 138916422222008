import React, { useEffect, useState } from 'react'

import { Dialog } from 'components/Dialogs'
import CircularLoading from 'components/Loading/CircularLoading'
import { createKnowledgeDb as createKnowledgeDbApi } from 'api/StudioBackend'
import { makeStyles } from 'tss-react/mui'
import { Typography, useTheme } from '@mui/material'
import { Textfield } from 'components/TextInput/Textfield'
import { SelectDropdown, Option, ActionMeta } from 'components/Dropdown'
import { Button } from 'components/Buttons'
import { ErrorOutline } from '@mui/icons-material'

const useStyles = makeStyles()((theme) => ({
  input: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
  errorRoot: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: theme.spacing(3),
  },
  errorText: {
    marginTop: theme.spacing(1),
    maxWidth: '75%',
  },
}))

type CreatingKnowledgeDbDialogProps = {
  open: boolean
  existingKnowledgeDbs: string[] // list of names of existing knowledge dbs
  onClose: () => void
  onKnowledgeDbCreated: (knowledgeDbName: string, knowledgeDbId: string) => void
  languages: Option[]
}

export default function CreateKnowledgeDbDialog({
  open,
  existingKnowledgeDbs,
  onKnowledgeDbCreated,
  onClose,
  languages,
}: CreatingKnowledgeDbDialogProps): React.ReactElement {
  const { classes } = useStyles()
  const theme = useTheme()
  const [loading, setLoading] = useState<'creating' | 'error'>()
  const [name, setName] = useState<string>()
  const [nameError, setNameError] = useState<string>()
  const [selectedLang, setSelectedLang] = useState<Option>()
  const [dbType, setDbType] = useState<'classic' | 'rag'>('classic')
  const dbTypeOptions: Option[] = [
    { label: 'Klassisch', value: 'classic' },
    { label: 'Generativ', value: 'rag' },
  ]

  function onNameChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setName(event.target.value)
    if (existingKnowledgeDbs.includes(event.target.value))
      setNameError('Es existiert bereits eine Wissensdatenbank mit diesem Namen.')
    else setNameError(undefined)
  }

  function onLanguageSelection(option: Option, action: ActionMeta<Option>): void {
    setSelectedLang(option)
  }

  function onDbTypeSelection(option: Option, action: ActionMeta<Option>): void {
    setDbType(option.value as 'classic' | 'rag')
  }

  /**
   * Creates new knowledge db
   */
  async function createKnowledgeDb(): Promise<void> {
    if (!name) return
    setLoading('creating')
    const result = await createKnowledgeDbApi(name, selectedLang?.value || 'de', dbType)
    if (result !== null) {
      const { knowledgeDbName, knowledgeDbId } = result
      onKnowledgeDbCreated(knowledgeDbName, knowledgeDbId)
      setLoading(undefined)
    } else {
      // not successful
      setLoading('error')
    }
  }

  useEffect(
    function () {
      // pre-set german as selected language
      const german = languages.find((obj) => obj.value === 'de')
      if (german) setSelectedLang(german)
    },
    [languages],
  )

  return (
    <Dialog
      id='creating-assistent-dialog'
      title='Wissensdatenbank erstellen'
      size='medium'
      open={open}
      disableBackdropClick={loading === 'creating'}
      closable={loading !== 'creating'}
      primaryActionButton={
        <Button
          onClick={createKnowledgeDb}
          size='small'
          disabled={!!(!name || nameError || !selectedLang || loading === 'creating')}
          type='success'
        >
          Erstellen
        </Button>
      }
      onClose={onClose}
    >
      {loading !== 'creating' && (
        <>
          <div>
            <Typography>Namen der Wissensdatenbank festlegen</Typography>
            <Textfield
              onChange={onNameChange}
              value={name}
              placeholder='Name der Wissensdatenbank'
              InputProps={{ autoComplete: 'off' }}
              label={'Name der Wissensdatenbank'}
              className={classes.input}
              error={!!nameError}
              helperText={nameError ? nameError : undefined}
            />
          </div>
          <div style={{ marginTop: theme.spacing(2) }}>
            <Typography>Primäre Sprache des Wissensdatenbank festlegen</Typography>
            <SelectDropdown
              className={classes.input}
              width='100%'
              options={languages}
              selected={selectedLang}
              onChange={onLanguageSelection}
              isSearchable
              placeholder='Sprache des Wissensdatenbank'
            />
          </div>
          <div style={{ marginTop: theme.spacing(2) }}>
            <Typography>
              Art der Wissensdatenbank festlegen. Sie haben die Wahl zwischen <em>klassisch</em> und <em>generativ</em>.
              Klassische Wissensdatenbanken nutzen vordefinierte Frage-Antwortpaare, um eingehende Fragen zu
              beantworten. Generative Wissensdatenbanken beinhalten unstrukturierte Textdokumente und beantworten
              eingehende Fragen frei durch den Einsatz von Generative AI.
            </Typography>
            <SelectDropdown
              className={classes.input}
              width='100%'
              options={dbTypeOptions}
              selected={dbTypeOptions.find((option) => option.value === dbType)}
              onChange={onDbTypeSelection}
              isSearchable
              placeholder='Art der Wissensdatenbank'
            />
          </div>
        </>
      )}
      {loading === 'creating' && <CircularLoading text='Wissensdatenbank wird erstellt...' size='medium' />}
      {loading === 'error' && (
        <div className={classes.errorRoot}>
          <ErrorOutline style={{ color: 'red', fontSize: '35px' }} />
          <div className={classes.errorText}>
            <Typography>Das hat leider nicht geklappt. Fehler beim Erstellen der Wissensdatenbank.</Typography>
          </div>
        </div>
      )}
    </Dialog>
  )
}
