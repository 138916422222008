import React, { useEffect, useState } from 'react'
import { useParams, useLocation, useNavigate, useResolvedPath } from 'react-router-dom'
import { isEqual } from 'lodash'
import { useBotContext } from '../../../hooks/contexts/bot-context'
import { ReactFlowInstance } from 'reactflow'

import { makeStyles } from 'tss-react/mui'
import { useTheme } from '@mui/material/styles'
import SelectDropdown, { Option } from '../../../components/Dropdown/SelectDropdown'
import { Can } from '../../../components/Can/Can'
import { Button, CustomIconButton } from '../../../components/Buttons'
import NotificationSnackbar from '../../../components/NotificationSnackbar/NotificationSnackbar'

import { clearCyclicStructure } from '../../../utils/chartUtils'
import { validateChart } from '../../../utils/chartValidator'

import * as api from '../../../api/StudioBackend'

import { BotEnvironment, BotInfos } from '../../../@types/BotInformation/types'
import { Chart } from '../../../@types/Flowchart/types'
import { TranslationFile } from '../../../@types/Translations/types'
import { LockState } from '../../../@types/Locking/types'
import {
  ROUTE_BOTID_DESIGNER,
  ROUTE_BOTID_DESIGNER_DIALOGOVERVIEW,
  ROUTE_BOTS,
  ROUTE_BOTID_DESIGNER_NODESEARCH,
} from 'utils/constants'
import PublishDialog from './ConfirmPublishDialog'
import { Typography } from '@mui/material'
import CustomizedTooltip from 'components/Tooltips/CustomContentTooltip'
import { useFlowdesignerContext } from 'hooks/contexts/flowdesigner-context'

const useStyles = makeStyles()((theme) => ({
  appBar: {
    background: 'white',
    width: '100%',
    padding: 0,
  },
  content: {
    display: 'flex',
    padding: theme.spacing(1) + ' 0',
    width: '100%',
    // height: '100%',
  },
  notificationSnackbar: { marginTop: theme.spacing(5) },
  dialogSelection: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  flowActions: {
    marginLeft: 'auto',
    display: 'flex',
  },
  button: { width: '180px', margin: `0 ${theme.spacing(1)}` },
  chartErrorContainer: {
    padding: '5px',
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'center',
    background: 'rgba(255,0,0,1)',
    color: '#FFF',
    borderRadius: '3px',
  },
  chartErrorIcon: {
    height: '100%',
    color: 'white',
    marginTop: 'auto',
    marginBottom: 'auto',
    display: 'inline-flex',
    verticalAlign: 'middle',
  },
  chartErrorText: {
    marginLeft: '5px',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  iconButton: {
    width: '40px',
    height: '40px',
    marginRight: theme.spacing(2),
  },
}))

type EditorTopbarProps = {
  chart: Chart
  translationFile: TranslationFile
  saveTranslations: boolean
  reactFlowInstance?: ReactFlowInstance
  displayAssistantToTest: boolean
  resetSaveTranslationsCallback: () => void
  setStateCallback: (chart: Chart) => void
  onDialogSelection: (dialogId: string) => void
  onToggleDisplayAssistant: (show: boolean) => void
  lockState: LockState
  setOrigChartRefCallback: (chart: Chart) => void
}

export default function EditorTopbar({
  chart: propsChart,
  translationFile,
  reactFlowInstance,
  saveTranslations,
  resetSaveTranslationsCallback,
  setStateCallback,
  onDialogSelection,
  displayAssistantToTest,
  onToggleDisplayAssistant,
  lockState,
  setOrigChartRefCallback,
}: EditorTopbarProps): React.ReactElement {
  const { classes } = useStyles()
  const theme = useTheme()

  const navigate = useNavigate()
  const { pathname: path } = useLocation()
  const url = useResolvedPath('').pathname

  const { botId } = useParams()
  const { bot, setBot } = useBotContext() as { bot: BotInfos; setBot: (botInfos: BotInfos) => void }
  const { canIEditFlowdesigner } = useFlowdesignerContext()
  // -779, -924

  const flowNodes = reactFlowInstance?.getNodes() ?? []

  const [chart, setChart] = useState<Chart>(propsChart)
  const [selectSubdialogOptions, setSelectSubdialogOptions] = useState<Option[]>([])
  const [selectedSubdialog, setSelectedSubdialog] = useState<string>()
  const [showPublishChartDialog, setShowPublishChartDialog] = useState<boolean>()
  const [loading, setLoading] = useState<
    'saving' | 'saved' | 'savingError' | 'publishing' | 'published' | 'publishError' | undefined
  >()

  /**
   * Checks if the publish button is disabled.
   * The button is disabled, if the last saved major version of the chart is smaller than the last minor version.
   * This is determined by checking the versions in the bot infos.
   */
  function isPublishButtonDisabled(): boolean {
    if (!canIEditFlowdesigner) return true

    // sort versions in descending order
    const descSortedVersions = Object.values(bot.chartVersions).sort((a, b) => {
      if (a.timestamp < b.timestamp) return 1
      if (b.timestamp < a.timestamp) return -1
      return 0
    })
    if (descSortedVersions.length === 0) return true
    const lastMajorVersionTimestamp = descSortedVersions[0].timestamp
    const minorVersionTimestamp = bot.minorChartVersion?.timestamp
    if (!minorVersionTimestamp) return false

    return lastMajorVersionTimestamp < minorVersionTimestamp
  }

  /**
   * Prepares dropdown options for subdialog dropdown.
   * @param chart
   */
  function prepareSubdialogOptions(chart: Chart): Option[] {
    if (!chart.dialogs || Object.keys(chart.dialogs).length <= 1) return []

    const options: Option[] = []
    Object.values(chart.dialogs).forEach((dialog) => {
      options.push({
        label: dialog.name,
        value: dialog.id,
      })
    })
    return options
  }

  /**
   * Updates position object of all nodes in flow chart with actual position in the react-flow.
   */
  function updateNodePositionsInChart(chart: Chart): Chart {
    for (const node of flowNodes) {
      const nId = node.id
      chart.nodes[nId].position = node.position
    }
    return chart
  }

  /**
   * Handles dialog selection.
   * Sets selected dialog as active dialog in the chart.
   * Sets selected dialog in state.
   * @param dialogId
   */
  function onSelectDialog(dialogId: string): void {
    // const newChart = selectDialog(chart, dialogId)
    setSelectedSubdialog(dialogId)
    onDialogSelection(dialogId)
    // setStateCallback(newChart)
  }

  /**
   * Handles dialog overview button click.
   * Pushes overview to route and with that causes dialog overview to be displayed.
   */
  function onDialogOverviewClick(): void {
    // navigate(path + ROUTE_BOTID_DESIGNER_DIALOGOVERVIEW)
    navigate(ROUTE_BOTS + `/${botId}` + ROUTE_BOTID_DESIGNER + ROUTE_BOTID_DESIGNER_DIALOGOVERVIEW)
  }

  /**
   * Handles search node button click.
   * Pushes to route and with that causes search node view to be displayed.
   */
  function onSearchNodeClick(): void {
    navigate(ROUTE_BOTS + `/${bot.id}` + ROUTE_BOTID_DESIGNER + ROUTE_BOTID_DESIGNER_NODESEARCH)
  }

  /**
   * Saves chart.
   */
  async function onSaveChart(): Promise<void> {
    if (!canIEditFlowdesigner) return

    if (lockState === 'isBlocked') {
      console.info('Editing is locked. Not saving chart.')
      return
    }

    // remove loading and upgrade dialog from chart
    let chartForSaving = { ...chart }

    delete chartForSaving.loading
    delete chartForSaving.upgradeDialog
    delete chartForSaving.triggerAnswers

    // validate chart; do not save if validation error
    const chartToSave = validateChart(chartForSaving, translationFile, botId)

    // set new / fixed chart
    if (chartToSave.hasError) {
      // chart is not valid
      // do not save
      setStateCallback({ ...chartToSave })
      return
    }

    setLoading('saving')

    // update node positions with actual ones from the react-flow flow.
    chartForSaving = updateNodePositionsInChart(chartToSave)
    if (reactFlowInstance) {
      const flowObj = reactFlowInstance.toObject()
      chartForSaving.offset = {
        ...chartForSaving.offset,
        x: flowObj.viewport.x,
        y: flowObj.viewport.y,
      }
      chartForSaving.zoom = flowObj.viewport.zoom
      flowObj.nodes.forEach((node) => {
        const id = node.id
        const position = node.position
        chartForSaving.nodes[id].position = position
      })
    }

    // remove properties that may cause cyclic structure
    const clearedChart = clearCyclicStructure(chartForSaving)

    return api
      .saveFlow(bot.id, clearedChart, 'major', saveTranslations ? translationFile : undefined)
      .then((result) => {
        if (result && result.status === 200) {
          const newChart = result.data.chart || chartToSave
          const newBotInfos = result.data.botInfos
          // success
          setLoading('saved')
          setBot(newBotInfos) // update local bot infos
          setStateCallback(newChart)
          resetSaveTranslationsCallback()
          setOrigChartRefCallback(newChart)
        } else {
          // error
          throw new Error(`Saving failed. Status ${result?.status}`)
        }
      })
      .catch((error) => {
        console.error(`Saving chart failed.`, error)
        setLoading('savingError')
      })
  }

  function onCancelPublishChart(): void {
    setShowPublishChartDialog(undefined)
  }

  /**
   * Publishes changes to assistant.
   */
  async function onConfirmPublishChart(deployEnvironment: BotEnvironment): Promise<void> {
    if (!canIEditFlowdesigner) return

    if (lockState === 'isBlocked') {
      console.info('Editing is locked. Not publishing chart.')
      return
    }

    setLoading('publishing')
    setShowPublishChartDialog(undefined)

    return api
      .reloadBot(bot.id, deployEnvironment, true, true)
      .then((result) => {
        if (result) {
          // success
          setLoading('published')
        } else {
          // error
          throw new Error(`Publish failed.`)
        }
      })
      .catch((error) => {
        console.error(`Publishing chart failed.`, error)
        setLoading('publishError')
        setShowPublishChartDialog(undefined)
      })
  }

  /**
   * Handles publish button click. Sets state to show confirm dialog.
   */
  function onPublishChart(): void {
    if (!canIEditFlowdesigner) return
    setShowPublishChartDialog(true)
  }

  useEffect(
    function () {
      // if (propsChart.activeDialog !== chart.activeDialog || propsChart.hasError !== chart.hasError) {
      // if (!isEqual(propsChart, chart)) {
      // prepare subdialog options
      const newOptions = prepareSubdialogOptions(propsChart)

      if (!isEqual(newOptions, selectSubdialogOptions)) setSelectSubdialogOptions(newOptions)
      if (!selectedSubdialog || !isEqual(propsChart.activeDialog, chart.activeDialog))
        setSelectedSubdialog(propsChart.activeDialog)

      setChart({ ...propsChart })
    },
    [propsChart],
  )

  /**
   * Renders notification snackbar based on loading state.
   * @returns
   */
  function renderNotificationSnackbar(): React.ReactElement | null {
    let message = ''
    let severity

    if (loading === 'saved') {
      severity = 'success'
      message = 'Dialog gespeichert.'
    } else if (loading === 'published') {
      severity = 'success'
      message = 'Dialog veröffentlicht.'
    } else if (loading === 'savingError') {
      severity = 'error'
      message =
        'Fehler beim Speichern des Dialogs. Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es in einigen Minuten nochmal.'
    } else if (loading === 'publishError') {
      severity = 'error'
      message =
        'Fehler beim Veröffentlichen des Dialogs. Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es in einigen Minuten nochmal.'
    } else {
      return null
    }

    return (
      <NotificationSnackbar
        style={{ marginTop: theme.spacing(5) }}
        position='top'
        severity={severity}
        message={message}
        open={true}
        onCloseNotification={(): void => setLoading(undefined)}
      />
    )
  }

  function buildValidationErrorMessage() {
    if (!chart.hasError) return ''

    let errorMessage = 'Konversationsgraph enthält nicht vollständig oder fehlerhaft konfigurierte Interaktions-Blöcke.'
    const nodesWithError = Object.values(chart.nodes).filter((node) => node.properties.validationError)

    let addedDetails = 0
    for (let i = 0; i < nodesWithError.length; i += 1) {
      if (addedDetails === 0) errorMessage += '\n'
      if (addedDetails === 5) {
        errorMessage += '\n...'
        break
      }

      if (nodesWithError[i].properties.validationErrorMsg) {
        errorMessage += `\n- ${nodesWithError[i].properties.validationErrorMsg}`
        addedDetails += 1
      }
    }

    return errorMessage
  }

  const publishButtonDisabled = isPublishButtonDisabled()

  return (
    <>
      <div className={classes.content}>
        {renderNotificationSnackbar()}
        <div className={classes.content}>
          <div className={classes.dialogSelection}>
            <SelectDropdown
              options={selectSubdialogOptions}
              selected={selectedSubdialog}
              isDisabled={selectSubdialogOptions.length <= 1}
              onChange={(newValue: Option): void => onSelectDialog(newValue.value)}
              width='300px'
              height='40px'
            />
          </div>
          <Button className={classes.button} type='normal' size='small' onClick={onDialogOverviewClick}>
            Dialogübersicht
          </Button>
          <Button className={classes.button} type='normal' size='small' onClick={onSearchNodeClick}>
            Block suchen
          </Button>
          <div className={classes.flowActions}>
            <Can I='update' a='floweditor' passThrough>
              {(can: boolean): React.ReactElement => (
                <CustomizedTooltip
                  disableInteractive
                  placement='bottom'
                  content={
                    publishButtonDisabled ? (
                      <Typography variant='body1'>
                        Sie müssen den Dialog erst speichern. Dabei wird eine neue Version erstellt, die dann
                        veröffentlicht werden kann.
                      </Typography>
                    ) : (
                      <Typography variant='body1'>Veröffentlicht die zuletzt gespeicherte Version.</Typography>
                    )
                  }
                  elements={
                    <div>
                      <Button
                        className={classes.button}
                        type='normal'
                        size='small'
                        onClick={onPublishChart}
                        disabled={
                          !can ||
                          !canIEditFlowdesigner ||
                          chart.hasError ||
                          lockState !== 'canEdit' ||
                          publishButtonDisabled
                        }
                        loading={loading === 'publishing'}
                      >
                        Veröffentlichen
                      </Button>
                    </div>
                  }
                />
              )}
            </Can>
            <Can I='update' a='floweditor' passThrough>
              {(can: boolean): React.ReactElement => (
                <CustomizedTooltip
                  disableInteractive
                  placement='bottom'
                  content={<Typography variant='body1'>Speichert die aktuellen Änderungen.</Typography>}
                  elements={
                    <div>
                      <Button
                        className={classes.button}
                        type='success'
                        size='small'
                        onClick={onSaveChart}
                        disabled={!can || !canIEditFlowdesigner || chart.hasError || lockState !== 'canEdit'}
                        loading={loading === 'saving'}
                      >
                        Speichern
                      </Button>
                    </div>
                  }
                />
              )}
            </Can>
            {bot.webchatVersion === 'v4' && (
              <Can I='read' a='floweditor' passThrough>
                {(can: boolean): React.ReactElement => (
                  <CustomIconButton
                    icon='ri-flask-line'
                    tooltip={displayAssistantToTest ? 'Test Assistent ausblenden' : 'Assistent zum Testen einblenden'}
                    onClick={(): void => {
                      onToggleDisplayAssistant(!displayAssistantToTest)
                    }}
                    disabled={!can}
                    className={classes.iconButton}
                  />
                )}
              </Can>
            )}
          </div>
          {chart.hasError ? (
            <NotificationSnackbar
              position='top'
              marginTop='50px'
              severity='error'
              open={chart.hasError}
              message={buildValidationErrorMessage()}
              disableAutoHide
            />
          ) : null}
        </div>
        {showPublishChartDialog && <PublishDialog onClose={onCancelPublishChart} onConfirm={onConfirmPublishChart} />}
      </div>
    </>
  )
}
