import React, { useEffect, useRef, useState } from 'react'

import { makeStyles } from 'tss-react/mui'
import { TextField, BaseTextFieldProps } from '@mui/material'

import { escapeSpecialCharsForRegex } from '../../utils/stringUtils'

const useStyles = makeStyles()({
  suggestionsContainer: {
    // these styles are taken from the react-select dropdown which we use to select variables
    // background: 'red',
    // position: 'absolute',
    // zIndex: 9999,
    // maxWidth: '300px',
    backgroundColor: 'rgb(255,255,255)',
    borderRadius: '4px',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 4px 11px',
    marginBottom: '8px',
    marginTop: '8px',
    position: 'absolute',
    minWidth: '200px',
    maxWidth: '500px',
    zIndex: 9999,
    boxSizing: 'border-box',
  },
  suggestionItem: {
    cursor: 'pointer',
    listStyle: 'none',
    margin: 0,
    padding: 0,
    backgroundColor: 'transparent',
    color: 'inherit',
    display: 'block',
    // fontSize: 'inherit',
    // padding: '8px 12px',
    width: '100%',
    userSelect: 'none',
    boxSizing: 'border-box',
  },
  suggestionItemActive: {
    // background: 'blue',
    backgroundColor: 'rgb(222, 235, 255)',
  },
  listItem: {
    fontSize: '16px',
    fontWeight: 400,
    letterSpacing: '0.15px',
    lineHeight: '24px',
    padding: '8px 12px',
    '&:hover': {
      backgroundColor: 'rgb(222, 235, 255)',
    },
  },
})

interface TextfieldSuggestionsProps extends BaseTextFieldProps {
  suggestPattern: string // pattern that value must match to display suggestions
  endPattern?: string // pattern that indicates "end" e.g. for variables this could be '}' if vars are displayed in this format ${var1}
  suggestions: string[] // list of suggestions to display
  noSuggestionsMessage: string
  isPatternIncludedInSuggestions?: boolean // if suggestions already include the suggestPattern
  value?: string
  onBlur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onChange?: (value: string) => void
}

/**
 * Wrapper around the standard MUI textfield.
 * Takes a list of suggestions and a pattern to listen to and displays suggestions matching the pattern
 * if the pattern is typed by the user.
 *
 * Note: This component does not have an onChange function, only onBlur
 *
 * This component is inspired by this example: https://codepen.io/jh3y/pen/rpoxxL describes in this article: https://jh3y.medium.com/how-to-where-s-the-caret-getting-the-xy-position-of-the-caret-a24ba372990a
 * @returns
 */
export default function TextfieldSuggestions(props: TextfieldSuggestionsProps): React.ReactElement {
  const {
    suggestions,
    suggestPattern,
    endPattern,
    value: givenValue,
    isPatternIncludedInSuggestions = false,
    onBlur,
    noSuggestionsMessage,
    onChange: onChangeCallback,
  } = props
  const { classes } = useStyles()
  const processClickFnRef = useRef<((event: MouseEvent) => void) | null>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [value, setValue] = useState<string | undefined>()
  const hasValueBeenSetRef = useRef<boolean | null>(null)
  const [displaySuggestions, setDisplaySuggestions] = useState<boolean>(false)

  /**
   * Compares the inputted value against the provided suggestPattern.
   * Returns true if the suggestPattern is matched by the last X chart of the value.
   * Uses the value in the state if no parameter is provided.
   * Parameter can be provided to not be dependent on async behaviour of setState.
   */
  function checkInputMatch(position: number, inputValue?: string): boolean {
    if (typeof inputValue === 'undefined') inputValue = value || ''

    // we need to increase position by 1, because we added the "tmp" char
    const checkPosition = position + 1

    // if input value is shorter than pattern, there can be no match
    if (checkPosition - suggestPattern.length < 0) return false

    const substringToCheck = inputValue.substring(checkPosition - suggestPattern.length, checkPosition)
    return substringToCheck === suggestPattern
  }

  /**
   * Handles key down event in the textfield.
   * Displays suggestions if the given pattern is matched by the input.
   * Adds suggestion to value if a suggestion is selected.
   * @param event
   * @returns
   */
  function onKeyDown(event: React.KeyboardEvent<HTMLInputElement>): void {
    const { key, type } = event
    const input = inputRef.current as any
    if (input === null) return

    // grab properties of input that we are interested in
    const { selectionStart } = input

    // Note: we are basically intercepting the event. At this point the char has not been added to the input field
    // -> we have to manually add it at the right position in the value for the pattern match check
    let isPatternMatch = false
    let tmpValue = input.value
    if (typeof value !== 'undefined' && event.key && event.key.length === 1) {
      tmpValue = value.slice(0, selectionStart) + event.key + value.slice(selectionStart)
      isPatternMatch = checkInputMatch(selectionStart, tmpValue)
    }

    /**
     * Creates and returns a div element that holds the suggestions.
     * Adds css classes to that div element.
     * @param content
     * @param modifier
     * @returns
     */
    function createContainer(content: string | null, modifier: string): HTMLDivElement {
      // create a marker for the input
      const marker = document.createElement('div')
      marker.classList.add(classes.suggestionsContainer, `${classes.suggestionsContainer}--${modifier}`)
      marker.textContent = content
      return marker
    }

    /**
     * Returns x, y coordinates for absolute positioning of the suggestion box.
     * Uses the bounding rect of the input and creates a "shadow clone" of the input element to determine the cursor position.
     * Adds cursor position to bound rect coordinates and returns x and y coordinates (left and top) for suggestion box.
     * @param input
     * @param selectionPoint
     */
    function getSuggestionContainerPosition(selectionPoint: number): { x: number; y: number } {
      const rect = input.getBoundingClientRect()
      const { left: inputX, bottom: inputY } = rect

      // create a dummy element that will be a clone of our input
      // and get the computed style of the input and clone it onto the dummy element (to achieve the same style as close as possible)
      const div = document.createElement('div')
      const copyStyle = window.getComputedStyle(input)
      Array.from(copyStyle).forEach((key) =>
        div.style.setProperty(key, copyStyle.getPropertyValue(key), copyStyle.getPropertyPriority(key)),
      )
      // we need a character that will replace whitespace when filling our dummy element if it's a single line <input/>
      const swap = '.'
      const inputValue = input.tagName === 'INPUT' ? input.value.replace(/ /g, swap) : input.value
      // set the div content to that of the textarea up until selection
      const textContent = inputValue.substr(0, selectionPoint)
      // set the text content of the dummy element div
      div.textContent = textContent
      if (input.tagName === 'TEXTAREA') div.style.height = 'auto'
      // if a single line input then the div needs to be single line and not break out like a text area
      if (input.tagName === 'INPUT') div.style.width = 'auto'
      // create a marker element to obtain caret position
      const span = document.createElement('span')
      // give the span the textContent of remaining content so that the recreated dummy element is as close as possible
      span.textContent = inputValue.substr(selectionPoint) || '.'
      // append the span marker to the div
      div.appendChild(span)
      // append the dummy element to the body
      document.body.appendChild(div)
      // get the marker position, this is the caret position top and left relative to the input
      const { offsetLeft: spanX, offsetTop: spanY } = span
      // lastly, remove that dummy element
      // NOTE:: can comment this out for debugging purposes if you want to see where that span is rendered
      document.body.removeChild(div)

      return {
        x: inputX + spanX,
        y: inputY,
      }
    }

    /**
     * toggles selected item in list via arrow keys
     * create a new selected item if one doesn't exist
     * else update the selected item based on the given selection direction
     * @param dir - defines which element sibling to select next
     */
    function toggleItem(dir = 'next'): void {
      const list = input.__CUSTOM_UI.querySelector('ul')
      if (!input.__SELECTED_ITEM) {
        input.__SELECTED_ITEM = input.__CUSTOM_UI.querySelector('li')
        input.__SELECTED_ITEM.classList.add(classes.suggestionItemActive)
      } else {
        input.__SELECTED_ITEM.classList.remove(classes.suggestionItemActive)
        let nextActive = input.__SELECTED_ITEM[`${dir}ElementSibling`]
        if (!nextActive && dir === 'next') nextActive = list.firstChild
        else if (!nextActive) nextActive = list.lastChild
        input.__SELECTED_ITEM = nextActive
        nextActive.classList.add(classes.suggestionItemActive)
      }
    }

    /**
     * filter a dummy list of data and append a <ul> to the marker element to show to the end user
     */
    function filterList(): void {
      if (typeof value === 'undefined') return
      let valueToFilter = value

      // NOTE: as we intercept the key event with this onKeyDown function, we need to manually append the pressed key for the filtering
      // same for backspace where char is removed
      if (event.key && event.key.length === 1) valueToFilter = value.slice(0, selectionStart) + event.key
      else if (event.key === 'Backspace') valueToFilter = value.slice(0, selectionStart - 1)

      // this regex matches the last occurance of the provided pattern before the current position
      // escape special chars
      const escapedPattern = escapeSpecialCharsForRegex(suggestPattern)
      // build regex that matches the last occurance of the suggested pattern in the valueToFile
      const regex = new RegExp(escapedPattern + '(?!.*' + escapedPattern + ')([a-zäöüßA-ZÄÖÜ0-9-_]*)(\\S)', 'gm')

      // get string to check for match
      const match = valueToFilter.match(regex)
      let filter = ''
      if (match !== null) {
        filter = match[0]
        if (!isPatternIncludedInSuggestions)
          // remove suggestPattern from beginning if it is not included in each suggetions - otherwise filter does not work
          filter = filter.substring(suggestPattern.length, filter.length)
      }

      const filteredSuggestions = suggestions.filter((entry) => entry.includes(filter))

      if (!filteredSuggestions.length) filteredSuggestions.push(noSuggestionsMessage)
      const suggestedList = document.createElement('ul')
      suggestedList.classList.add(classes.suggestionItem)
      filteredSuggestions.forEach((entry) => {
        const entryItem = document.createElement('li')
        entryItem.classList.add(classes.listItem)
        entryItem.textContent = entry
        suggestedList.appendChild(entryItem)
      })
      if (input.__CUSTOM_UI.firstChild) input.__CUSTOM_UI.replaceChild(suggestedList, input.__CUSTOM_UI.firstChild)
      else input.__CUSTOM_UI.appendChild(suggestedList)
    }

    /**
     * given a selected value, replace the special character and insert selected value
     * @param selected - the selected value to be inserted into inputs text content
     * @param click - defines whether the event was a click or not
     */
    function selectItem(selected: string, click = false): void {
      if (selected === noSuggestionsMessage) return
      const start = input.value.slice(0, click ? input.__EDIT_START + 1 : input.__EDIT_START)
      // const end = input.value.slice(click ? selectionStart + 1 : selectionStart, input.value.length)
      const end = input.value.slice(click ? selectionStart + 1 : selectionStart, input.value.length)

      // we replace the already entered chars of the selected value so that they do not show double
      // to do that we iterate backwards over the start value and check for each iteration of the last x chars of the start value match the first x chars of the selected value
      // if thats the case, we remove them from the selected value and append the remaining selected value string.
      for (let i = selectionStart; i >= 0; i -= 1) {
        let match = true
        const substring = start.slice(selectionStart - i, selectionStart)
        for (let j = 0; j < substring.length; j += 1) {
          if (substring[j] !== selected[j]) {
            match = false
          }
        }
        if (match) {
          selected = selected.replace(substring, '')
        }
      }

      // Add ending pattern (e.g. if suggestions are display with surrounding {})
      if (!isPatternIncludedInSuggestions) selected = selected + (typeof endPattern !== 'undefined' ? endPattern : '')

      onChange(`${start}${selected}${end}`)
    }

    /**
     * handle when the suggestions list is clicked so that user can select from list
     * @param {event} e - click event on marker element
     */
    function clickItem(e: any): void {
      // e.preventDefault()
      // e.stopPropagation()
      if (e.target.tagName === 'LI' && e.target.textContent !== noSuggestionsMessage) {
        input.focus()
        toggleSuggestions()
        selectItem(e.target.textContent, true)
      }
    }

    // create a function that will handle clicking off of the input and hide the marker
    function processClick(evt: MouseEvent): void {
      toggleSuggestions()
    }

    /**
     * Displays or hides suggestions container.
     */
    function toggleSuggestions(): void {
      input.__EDIT_START = selectionStart
      input.__IS_SHOWING_CUSTOM_UI = !input.__IS_SHOWING_CUSTOM_UI

      if (input.__IS_SHOWING_CUSTOM_UI && !input.__CUSTOM_UI) {
        setDisplaySuggestions(true)
        // assign a created marker to input
        input.__CUSTOM_UI = createContainer(null, 'custom')
        // append it to the body
        document.body.appendChild(input.__CUSTOM_UI)
        input.__CUSTOM_UI.addEventListener('click', clickItem)
        // document.addEventListener('click', processClick)
        document.addEventListener('click', processClick)
        processClickFnRef.current = processClick
      } else {
        setDisplaySuggestions(false)
        input.__CUSTOM_UI.removeEventListener('click', clickItem)
        document.body.removeChild(input.__CUSTOM_UI)
        input.__CUSTOM_UI = null
        // document.removeEventListener('click', processClick)
        if (processClickFnRef.current !== null) document.removeEventListener('click', processClickFnRef.current)
      }

      if (input.__IS_SHOWING_CUSTOM_UI) {
        // update list to show
        filterList()
        // update position
        const { x, y } = getSuggestionContainerPosition(selectionStart)
        input.__CUSTOM_UI.setAttribute('style', `left: ${x}px; top: ${y}px`)
      }
    }

    // determine whether we can show custom UI, format must be special character preceded by a space
    // if (!input.__IS_SHOWING_CUSTOM_UI && key === '%') {
    if (!input.__IS_SHOWING_CUSTOM_UI && isPatternMatch) {
      toggleSuggestions()
    } else if (input.__IS_SHOWING_CUSTOM_UI) {
      switch (key) {
        // case '%':
        case 'Space':
          toggleSuggestions()
          break
        case 'Escape':
          toggleSuggestions()
          event.stopPropagation() // stop propagation; we only want to close the suggestions on escape, nothing more
          break
        case 'Backspace':
          if (selectionStart === input.__EDIT_START) toggleSuggestions()
          else filterList()
          break
        case 'Enter':
          if (input.__SELECTED_ITEM) {
            event.preventDefault()
            const item = input.__CUSTOM_UI.querySelector(`[class*=${classes.suggestionItemActive}]`)
            if (item !== 'null') {
              const textContent = item.textContent
              input.focus()
              toggleSuggestions()
              selectItem(textContent)
            }
          } else {
            toggleSuggestions()
          }
          break
        case 'ArrowUp':
        case 'ArrowDown':
          if (type === 'keydown') {
            event.preventDefault()
            // up is 38
            toggleItem(key === 'ArrowUp' ? 'previous' : 'next')
            // down is 40
          }
          break
        case 'ArrowLeft':
        case 'ArrowRight':
          if (selectionStart < input.__EDIT_START + 1) toggleSuggestions()
          break
        default:
          filterList()
          break
      }
    }
  }

  /**
   * Updates local state and also tells parent new value.
   * @param event
   */
  function onChange(value: string): void {
    setValue(value)
    if (typeof onChangeCallback !== 'undefined') onChangeCallback(value)
  }

  /**
   * OnChange for MUI textfield.
   * @param event
   */
  function onTextFieldChange(event: React.ChangeEvent<HTMLInputElement>): void {
    onChange(event.target.value)
  }

  /**
   * Intercepts the onBlur event of the textfield.
   * Calls onBlur callback with event and current value of textfield.
   * @param event
   */
  function onBlurIntercept(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void {
    if (!displaySuggestions && typeof onBlur !== 'undefined') {
      onBlur(event)
    }
  }

  useEffect(
    function () {
      // state (displayed text) is managed by the value in the state of this component.
      // in order to fill the field with existing values we have to set that state value to the given value.
      // To prevent the given value from overwritting the local state all the time, we only set the local value once and keep track of that using the ref in state.
      // setting the given value during init of value state, does not work, because of the nature of replacing variable ids with display names in parent component.
      // This is asynchronous and causes a re-render which would mean, that this component would not get the correct value to display.
      if (givenValue && !hasValueBeenSetRef.current) {
        hasValueBeenSetRef.current = true
        // onChange(givenValue)
        setValue(givenValue)
      }
    },
    [givenValue],
  )

  return (
    <TextField
      {...props}
      inputRef={inputRef}
      onKeyDown={onKeyDown}
      value={value || ''}
      onChange={onTextFieldChange}
      onBlur={onBlurIntercept}
    />
  )
}
