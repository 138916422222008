import React, { useState, useEffect } from 'react'

// @mui/material components
import xzufiSearchLeistung from 'assets/img/modules/xzufi_search_leistung.svg'
import { makeStyles } from 'tss-react/mui'
import { Chart, QnAModuleNodeProperties } from '../../../../../@types/Flowchart/types'
import SelectDropdown, { Option } from '../../../../../components/Dropdown/SelectDropdown'
import { Grid, Typography, useTheme } from '@mui/material'
import ContentContainer from '../ContentContainer'
import { useBotContext } from 'hooks/contexts/bot-context'
import { NLUModule } from '../../../../../@types/BotInformation/types'
import CustomContentTooltip from 'components/Tooltips/CustomContentTooltip'
import VariablesAutosuggestSelect from '../Variables/VariablesAutosuggestSelect'
import CardButton from '../../../../../components/Buttons/CardButton'

const useStyles = makeStyles()((theme) => ({
  container: { height: '100%' },
  inputWithInfo: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  inputInfoIcon: {
    marginLeft: theme.spacing(1),
    height: '20px',
    color: theme.palette.grey[700],
  },
  moduleSelection: {
    marginTop: theme.spacing(4),
  },
  trackingContainer: {
    marginTop: theme.spacing(4),
  },
  trackHelpfulButtonContainer: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  variables: {
    marginTop: theme.spacing(4),
  },
  varSelectionContainer: {
    marginTop: theme.spacing(2),
  },
}))

enum VariableSelectionPurpose {
  CORRELATION_ID,
  TEXT_FEEDBACK,
}

type SQnATrackHelpfulProps = {
  chart: Chart
  setIsSaveDisabled: (isSaveDisabled: boolean) => void
  setStateCallback: (chart: Chart) => void
}

export default function SQnATrackHelpful({
  chart,
  setIsSaveDisabled,
  setStateCallback,
}: SQnATrackHelpfulProps): React.ReactElement {
  const { classes } = useStyles()
  const theme = useTheme()
  const { bot } = useBotContext()

  // module select
  const [moduleOptions, setModuleOptions] = useState<Option[]>([])
  const [selectedModuleId, setSelectedModuleId] = useState<string>()
  // variables select
  const [varIdTextFeedback, setVarIdTextFeedback] = useState<string | null>() // id of variable for storing the text feedback
  const [varIdCorrId, setVarIdCorrId] = useState<string>() // id of variable for storing the correlation id of the answer
  // track mode
  const [helpfulType, setIsHelpful] = useState<QnAModuleNodeProperties['helpful']>()

  /**
   * Reads all nlu modules configured for this bot and prepares them for the dropdown.
   * If only one nlu module exists, sets it.
   */
  function prepareModuleOptions(): void {
    if (!bot) return

    const nluModules = Object.values(bot.modules).filter((module) => module.type === 'nlu')
    const moduleOptions = nluModules.map((module) => {
      const { type, moduleConfigId } = module as NLUModule
      return {
        label: `${type === 'nlu' ? 'QnA' : type}`,
        value: moduleConfigId,
      }
    })

    setModuleOptions(moduleOptions)
    if (moduleOptions.length === 1) {
      // only 1 possible module -> select it
      onModuleSelect({ label: '', value: moduleOptions[0].value })
    }
  }

  // ========= HANDLER ============

  /**
   * Handles module selection
   * @param newValue
   */
  function onModuleSelect(newValue: Option): void {
    const selectedId = chart.selected?.id
    if (typeof selectedId === 'undefined') return
    const node = chart.nodes[selectedId]
    if (typeof node === 'undefined') return

    const moduleId = newValue.value

    node.properties.moduleConfigId = moduleId

    // also set new text for display in dialog designer
    const module = bot?.modules[moduleId] as NLUModule
    if (module) {
      const helpfulText = helpfulType === 'helpful' ? 'Positiv' : helpfulType === 'notHelpful' ? 'Negativ' : ''
      node.properties.text = `${module.type === 'nlu' ? 'QnA' : ''} - ${helpfulText}`
    }
    if (module) setSelectedModuleId(moduleId)

    chart.nodes[selectedId] = node
    setStateCallback(chart)
  }

  /**
   * Sets the variable in the node properties and updates the chart
   * @param selectedVarId
   * @param purpose
   */
  function onVariableSelect(newChart: Chart, purpose: VariableSelectionPurpose, selectedVarId?: string | null): void {
    const selectedId = newChart.selected?.id
    if (typeof selectedId === 'undefined') return
    const node = newChart.nodes[selectedId]
    if (typeof node === 'undefined') return

    if (!node.properties.qna) node.properties.qna = {}

    switch (purpose) {
      case VariableSelectionPurpose.CORRELATION_ID:
        if (selectedVarId) {
          node.properties.qna.corrId = selectedVarId
        }
        break
      case VariableSelectionPurpose.TEXT_FEEDBACK:
        node.properties.qna.textFeedback = selectedVarId
    }

    newChart.nodes[selectedId] = node
    setStateCallback(newChart)
  }

  /**
   * Handles variable selection for correlation id
   * @param newChart
   * @param prevSelectedVarIds
   * @param selectedVarIds
   */
  function onVariableForCorrIdSelect(newChart: Chart, prevSelectedVarIds: string[], selectedVarIds: string[]): void {
    const selectedVariableId = selectedVarIds.length > 0 ? selectedVarIds[0] : undefined
    onVariableSelect(newChart, VariableSelectionPurpose.CORRELATION_ID, selectedVariableId)
  }

  /**
   * Handles variable selection for text feedback
   * @param newChart
   * @param prevSelectedVarIds
   * @param selectedVarIds
   */
  function onVariableForTextFeedbackSelect(
    newChart: Chart,
    prevSelectedVarIds: string[],
    selectedVarIds: string[],
  ): void {
    const selectedVariableId = selectedVarIds.length > 0 ? selectedVarIds[0] : null
    onVariableSelect(newChart, VariableSelectionPurpose.TEXT_FEEDBACK, selectedVariableId)
  }

  /**
   * Handles helpful change (click on one of the buttons)
   * Updates state and also node properties
   * @param isHelpful
   */
  function onHelpfulChange(isHelpful: QnAModuleNodeProperties['helpful']): void {
    const selectedId = chart.selected?.id
    if (typeof selectedId === 'undefined' || typeof selectedModuleId === 'undefined') return
    const node = chart.nodes[selectedId]
    if (typeof node === 'undefined') return

    if (!node.properties.qna) node.properties.qna = {}

    node.properties.qna.helpful = isHelpful

    // also set new text for display in dialog designer
    const module = bot?.modules[selectedModuleId] as NLUModule
    if (module) {
      const helpfulText = isHelpful === 'helpful' ? 'Positiv' : isHelpful === 'notHelpful' ? 'Negativ' : ''
      node.properties.text = `${module.type === 'nlu' ? 'QnA' : ''} - ${helpfulText}`
    }

    chart.nodes[selectedId] = node
    setIsHelpful(isHelpful)
    setStateCallback(chart)
  }

  useEffect(
    function () {
      const selectedId = chart.selected?.id
      if (typeof selectedId === 'undefined') return
      // get already selected Event
      const node = chart.nodes[selectedId]

      prepareModuleOptions()
      setVarIdCorrId(node.properties.qna?.corrId)
      setVarIdTextFeedback(node.properties.qna?.textFeedback)
      setIsHelpful(node.properties.qna?.helpful)
    },
    [chart, bot],
  )

  useEffect(
    function () {
      if (selectedModuleId && varIdCorrId) {
        setIsSaveDisabled(false)
      } else {
        setIsSaveDisabled(true)
      }
    },
    [selectedModuleId, varIdCorrId, helpfulType],
  )

  return (
    <div className={classes.container}>
      <ContentContainer headerText='QnA Modul: Ergebnis bewerten' descriptionText='' svg={xzufiSearchLeistung}>
        <div className={classes.moduleSelection}>
          <div className={classes.inputWithInfo}>
            <Typography>QnA Modul</Typography>
            <CustomContentTooltip
              content={
                <Typography>
                  Bitte wählen Sie das QnA Modul Ihres Assistenten, für das Suchergebnisse bewertet werden sollen.
                </Typography>
              }
              elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
            />
          </div>
          <SelectDropdown
            onChange={onModuleSelect}
            options={moduleOptions}
            selected={selectedModuleId}
            placeholder='QnA Modul auswählen'
            width='100%'
          />
        </div>

        {selectedModuleId && (
          <>
            <div className={classes.trackingContainer}>
              <div className={classes.inputWithInfo}>
                <Typography>Welche Bewertung soll der Block tracken?</Typography>
                <CustomContentTooltip
                  content={
                    <Typography>
                      Sie können wählen, welche Bewertung dieser Block für die Suchergebnisse tracken soll. Positiv
                      bedeutet, Nutzer*innen haben gefunden, was sie gesucht haben. Negativ bedeutet, Nutzer*innen haben
                      nicht gefunden, was sie gesucht haben.
                    </Typography>
                  }
                  elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
                />
              </div>

              <Grid container justifyContent={'center'} spacing={2} className={classes.trackHelpfulButtonContainer}>
                <Grid item xs={6}>
                  <CardButton
                    isSelected={helpfulType === 'helpful'}
                    onClick={(): void => onHelpfulChange('helpful')}
                    selectedColor={theme.palette.success.main}
                  >
                    <div style={{ display: 'flex' }}>
                      <Typography>Positiv</Typography>
                      {'  '}
                      <i
                        className='ri-thumb-up-fill'
                        style={{
                          marginLeft: theme.spacing(1),
                          color: helpfulType === 'helpful' ? theme.palette.success.main : undefined,
                        }}
                      />
                    </div>
                  </CardButton>
                </Grid>

                <Grid item xs={6}>
                  <CardButton
                    isSelected={helpfulType === 'notHelpful'}
                    onClick={(): void => onHelpfulChange('notHelpful')}
                    selectedColor={theme.palette.error.main}
                  >
                    <div style={{ display: 'flex' }}>
                      <Typography>Negativ</Typography>
                      {'  '}
                      <i
                        className='ri-thumb-down-fill'
                        style={{
                          marginLeft: theme.spacing(1),
                          color: helpfulType === 'notHelpful' ? theme.palette.error.main : undefined,
                        }}
                      />
                    </div>
                  </CardButton>
                </Grid>
              </Grid>
            </div>

            <div className={classes.variables}>
              <div className={classes.varSelectionContainer}>
                <div className={classes.inputWithInfo}>
                  <Typography>Variable mit Feedback-ID der Antwort</Typography>
                  <CustomContentTooltip
                    content={
                      <Typography>
                        Bitte wählen Sie die Variable, in der die Feedback-ID der Suchanfrage gespeichert wurde. Die
                        Feedback-ID wird verwendet, um bei der Bewertung der Suchergebnisse die korrekte Zuordnung zu
                        der Suche zu ermöglichen.
                      </Typography>
                    }
                    elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
                  />
                </div>
                <VariablesAutosuggestSelect
                  chart={chart}
                  onChange={onVariableForCorrIdSelect}
                  usageType='consume'
                  isMulti={false}
                  isCreatable
                  isClearable
                  selectedVariableIds={varIdCorrId ? [varIdCorrId] : []}
                />
              </div>
              <div className={classes.varSelectionContainer}>
                <div className={classes.inputWithInfo}>
                  <Typography>Variable mit Text Feedback des Nutzers (Optional)</Typography>
                  <CustomContentTooltip
                    content={
                      <Typography>
                        Bitte wählen Sie die Variable, in der das Text-Feedback des Nutzers gespeichert werden soll.
                      </Typography>
                    }
                    elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
                  />
                </div>
                <VariablesAutosuggestSelect
                  chart={chart}
                  onChange={onVariableForTextFeedbackSelect}
                  usageType='set'
                  isMulti={false}
                  isCreatable
                  isClearable
                  selectedVariableIds={varIdTextFeedback ? [varIdTextFeedback] : []}
                />
              </div>
            </div>
          </>
        )}
      </ContentContainer>
    </div>
  )
}
