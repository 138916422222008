import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useTheme } from '@mui/material/styles'
import React, { useEffect, useRef, useState } from 'react'

// assets
import svgAnalyticsDiagram from 'assets/img/analytics/analytics_diagram.svg'
import ContentContainer from '../../ContentContainer'
import * as chartUtils from 'utils/chartUtils'
import { Chart } from '../../../../../../@types/Flowchart/types'

import { Textfield } from 'components/TextInput/Textfield'
import { Button } from 'components/Buttons'

const useStyles = makeStyles()((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
    width: '100%',
    textAlign: 'center',
  },
}))

// allows alphanumeric (cased), " ", "-" and "_"
const EVENT_NAME_CHECK_REGEX = /^[a-zA-Z0-9 \-_öäüÖÄÜ]+$/i

/**
 * Checks if entered event name matches the event name guidelines.
 * @param name
 * @returns
 */
function isLegalEventName(
  chart: Chart,
  name: string,
): {
  isLegal: boolean
  message?: string
} {
  if (!EVENT_NAME_CHECK_REGEX.test(name)) {
    return {
      isLegal: false,
      message: 'Unerlaubtes Zeichen. Erlaubt, Buchstaben und Zahlen, Leerzeichen, Bindestriche und Unterstriche.',
    }
  }

  if (name.length < 5) {
    return {
      isLegal: false,
      message: 'Der Event Name muss mindestens 5 Zeichen lang sein.',
    }
  }

  const existingEventNames = Object.values(chart.customAnalyticsEvents ?? {}).map((event) => event.eventName)
  if (existingEventNames.includes(name)) {
    return {
      isLegal: false,
      message: 'Ein Event mit diesem Namen existiert bereits.',
    }
  }

  // TODO: check allowed chars

  return {
    isLegal: true,
  }
}

type CreateEditCustomEvent = {
  chart: Chart
  onCreateOrEditEvent: (chart: Chart) => void
  onBackClick: () => void
  descriptionText: string
  mode: 'create' | 'edit' // create creates new event, edit edits existing event
}

export default function CreateEditCustomEvent({
  chart,
  onCreateOrEditEvent: onCreateOrEditEventProps,
  onBackClick,
  descriptionText,
  mode,
}: CreateEditCustomEvent): React.ReactElement {
  const { classes } = useStyles()
  const theme = useTheme()

  const initialEventNameRef = useRef<string | null>(null)
  const initialEventDescriptionRef = useRef<string | null>(null)
  const [eventId, setEventId] = useState<string>('')
  const [eventName, setEventName] = useState<string>('')
  const [eventNameError, setEventNameError] = useState<string>()
  const [eventDescription, setEventDescription] = useState<string>('')

  function onNameChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setEventName(event.target.value)
    const isLegalName = isLegalEventName(chart, event.target.value)
    if (!isLegalName.isLegal) {
      setEventNameError(isLegalName.message)
    } else {
      setEventNameError(undefined)
    }
  }

  function onDescriptionChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setEventDescription(event.target.value)
  }

  function onCreateOrEditEvent(): void {
    const selectedId = chart.selected.id
    if (!selectedId) return

    if (mode === 'create') {
      // create event and select it for current node
      const newChart = chartUtils.createAndSelectCustomAnalyticsEvent(chart, eventName, eventDescription, selectedId)
      onCreateOrEditEventProps(newChart)
    } else if (mode === 'edit') {
      const newChart = chartUtils.changeAnalyticsEventNameAndDescription(chart, eventId, eventName, eventDescription)
      onCreateOrEditEventProps(newChart)
    }
  }

  useEffect(
    function () {
      // build dropdown options and set selected if event is already selected
      const selectedId = chart.selected.id
      if (!selectedId) return

      if (mode === 'edit') {
        const eventName = chart.nodes[selectedId].properties.analyticsEvent?.eventName
        const eventDescription = chart.nodes[selectedId].properties.analyticsEvent?.customEvent?.description
        const eventId = chart.nodes[selectedId].properties.analyticsEvent?.customEvent?.eventId
        if (!eventId) {
          console.error('Event without eventId!')
          return
        }
        const preparedEventName = (eventName ?? '').replace(/^(customer\/)/, '')
        setEventId(eventId)
        setEventName(preparedEventName)
        setEventDescription(eventDescription ?? '')
        initialEventNameRef.current = preparedEventName
        initialEventDescriptionRef.current = eventDescription ?? ''
      }
    },
    [chart],
  )

  return (
    <ContentContainer
      headerText={mode === 'create' ? 'Event erstellen' : 'Event bearbeiten'}
      descriptionText={descriptionText}
      onBackClick={onBackClick}
      svg={svgAnalyticsDiagram}
    >
      <div className={classes.container}>
        <Typography marginBottom={theme.spacing(1)}>Name des Events</Typography>
        <Textfield
          onChange={onNameChange}
          error={!!eventNameError}
          helperText={eventNameError}
          value={eventName}
          placeholder='Event Name'
        />
        <Typography marginTop={theme.spacing(2)} marginBottom={theme.spacing(1)}>
          Beschreibung des Events
        </Typography>
        <Textfield
          onChange={onDescriptionChange}
          multiline
          rows={3}
          rowsMax={5}
          value={eventDescription}
          placeholder='Beschreibungstext für das Event'
        />
        <div className={classes.buttonContainer}>
          {mode === 'edit' ? (
            <Typography fontWeight='bolder' marginBottom={theme.spacing(1)}>
              <i>
                Achtung: Änderungen an Events wirken sich auf alle Stellen im Konversationsfluss aus! Änderungen des
                Eventnamen führen dazu, dass in den Analytics zwei verschiedene Events erscheinen (eins mit dem alten
                Namen und eins mit dem neuen Namen)!
              </i>
            </Typography>
          ) : null}
          <Button
            disabled={
              !!eventNameError ||
              !eventName ||
              !eventDescription ||
              (eventName === initialEventNameRef.current && eventDescription === initialEventDescriptionRef.current)
            }
            onClick={onCreateOrEditEvent}
          >
            {mode === 'create' ? 'Erstellen' : 'Übernehmen'}
          </Button>
        </div>
      </div>
    </ContentContainer>
  )
}
