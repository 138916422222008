import React, { useEffect, useState } from 'react'
import { Route, useParams, Routes, useNavigate } from 'react-router-dom'
import {
  useCustomNotifications,
  saveNotifications,
  deleteNotifications,
  activateNotification,
  deactivateNotification,
  checkOverlapOfCustomNotification,
} from 'hooks/contexts/customnotifications-context'
import { ROUTE_BOTID_NOTIFICATIONS, ROUTE_BOTS } from 'utils/constants'
import { ContentPageHeader } from 'components/Page/ContentPage'
import { Button } from 'components/Buttons'
import { CustomNotification, CustomNotifications } from '../../../@types/CustomNotifications/types'
import { useLockingContext } from 'hooks/contexts/locking-context'
import { cloneDeep, isEqual } from 'lodash'
import { Delete, Notifications, NotificationsOff } from '@mui/icons-material'
import { Dialog } from 'components/Dialogs'
import { Typography } from '@mui/material'
import EditCustomNotificationContent from './EditCustomNotificationContent'
import { Can } from 'components/Can/Can'
import { useStudioNotificationContext } from 'hooks/contexts/studio-notification-context'

export default function EditCustomNotification(): React.ReactElement {
  const navigate = useNavigate()
  const { botId, notificationId } = useParams() as { botId: string; notificationId: string } // can cast here because component would not be shown if undefined
  const { lockState } = useLockingContext()
  const {
    notifications,
    loadingState,
    dispatch: notificationsDispatch,
    resetSingleNotification,
    hasChanges,
  } = useCustomNotifications()
  const { setNotification: setSnackbarNotification } = useStudioNotificationContext()

  const [overlapState, setOverlapState] = useState<'overlap' | 'noOverlap'>()
  const [notification, _setNotification] = useState<CustomNotification>()
  const [overlappingNotification, setOverlappingNotification] = useState<CustomNotification>()
  const [displayDialog, setDisplayDialog] = useState<'delete' | 'discard'>()

  /**
   * Checks if notification overlaps
   * @param newNotification
   */
  async function checkOverlap(newNotification: CustomNotification): Promise<'overlap' | 'noOverlap'> {
    const overlappingIds = await checkOverlapOfCustomNotification(notificationsDispatch, botId, newNotification)
    if (notifications && overlappingIds && overlappingIds.length > 0) {
      setOverlapState('overlap')
      setOverlappingNotification(notifications[overlappingIds[0]])
      return 'overlap'
    } else {
      setOverlapState('noOverlap')
      setOverlappingNotification(undefined)
      return 'noOverlap'
    }
  }

  function setNotification(_notification: CustomNotification): void {
    // we need to check if there is an overlap if any of the turnus settings changed
    if (
      notification &&
      _notification &&
      (_notification.startDate !== notification?.startDate ||
        _notification.endDate !== notification.endDate ||
        _notification.type !== notification.type ||
        !isEqual(_notification.times, notification.times) ||
        !isEqual(_notification.series, notification.series))
    ) {
      checkOverlap(_notification)
    }

    _setNotification(cloneDeep(_notification))
  }

  /**
   * Saves notification.
   * Waits until call was successful or failed.
   * Then re-routs (if successful).
   */
  async function onSave(): Promise<void> {
    if (!notification || !botId || loadingState === 'checkingOverlap') return

    // if overlap, set the notification to deactivated by default
    if (overlapState === 'overlap') notification.isActive = false

    const notifications: CustomNotifications = { [notification.notificationId]: notification }
    const successful = await saveNotifications(notificationsDispatch, botId, notifications, setSnackbarNotification)
    if (successful) {
      // route back
      navigate(-1)
    }
  }

  /**
   * Deletes notification.
   * Waits until call was successful or failed.
   * Then re-routs (if successful).
   */
  async function onDeleteConfirm(): Promise<void> {
    if (!notificationId || !botId) return

    const successful = await deleteNotifications(
      notificationsDispatch,
      botId,
      [notificationId],
      setSnackbarNotification,
    )
    if (successful) {
      // route back
      navigate(-1)
    }
  }

  function onDelete(): void {
    setDisplayDialog('delete')
  }

  function onDialogAbort(): void {
    setDisplayDialog(undefined)
  }

  useEffect(
    function () {
      if (!notifications || !notificationId) return
      const notification = notifications[notificationId]
      setNotification(cloneDeep(notification))
    },
    [hasChanges, notifications],
  )

  // ===== ACTIONS ======

  const SaveButton = (
    <Can I='update' a='notifications' passThrough>
      {(can): React.ReactElement => (
        <Button
          size='normal'
          type='success'
          icon='save-line'
          iconType='remix'
          onClick={onSave}
          loading={loadingState === 'saving'}
          disabled={
            !can ||
            lockState !== 'canEdit' ||
            loadingState === 'saving' ||
            loadingState === 'deleting' ||
            loadingState === 'checkingOverlap' ||
            // !!overlappingNotification ||
            !!(!hasChanges && notifications && notificationId && isEqual(notification, notifications[notificationId]))
          }
        >
          Speichern
        </Button>
      )}
    </Can>
  )

  const DeleteButton = (
    <Can I='delete' a='notifications' passThrough>
      {(can): React.ReactElement => (
        <Button
          size='normal'
          type='danger'
          icon={<Delete />}
          onClick={onDelete}
          loading={loadingState === 'deleting'}
          disabled={
            !can ||
            lockState !== 'canEdit' ||
            loadingState === 'saving' ||
            loadingState === 'deleting' ||
            !notifications
          }
        >
          Löschen
        </Button>
      )}
    </Can>
  )

  const EnableDisableButton = (
    <Can I='update' a='notifications' passThrough>
      {(can): React.ReactElement => (
        <Button
          size='normal'
          type='normal'
          icon={notification?.isActive ? <NotificationsOff /> : <Notifications />}
          onClick={async (): Promise<void> => {
            if (notification?.isActive) {
              deactivateNotification(notificationsDispatch, botId, notification, setSnackbarNotification)
            } else {
              if (notification) {
                const doesOverlap = await checkOverlap({ ...notification, isActive: true })
                if (doesOverlap === 'noOverlap')
                  activateNotification(notificationsDispatch, botId, notification, setSnackbarNotification)
              }
            }
          }}
          disabled={
            !can ||
            lockState !== 'canEdit' ||
            loadingState === 'saving' ||
            loadingState === 'deleting' ||
            loadingState === 'checkingOverlap' ||
            overlapState === 'overlap' ||
            !notifications
          }
          loading={loadingState === 'checkingOverlap'}
        >
          {notification?.isActive ? 'Deaktivieren' : 'Aktivieren'}
        </Button>
      )}
    </Can>
  )

  return (
    <>
      <ContentPageHeader
        title='Notification bearbeiten'
        actions={[EnableDisableButton, DeleteButton, SaveButton]}
        previousUrl={ROUTE_BOTS + '/' + botId + ROUTE_BOTID_NOTIFICATIONS}
        previousUrlCallback={(): boolean => {
          const hasSomethingChanged =
            hasChanges ||
            (notifications && notificationId ? !isEqual(notification, notifications[notificationId]) : false)
          if (hasSomethingChanged) setDisplayDialog('discard')
          return !hasSomethingChanged
        }}
      />
      {notification && (
        <EditCustomNotificationContent
          notification={notification}
          onEditNotificationCallback={setNotification}
          overlappingNotification={overlappingNotification}
        />
      )}
      {/* Delete confirm dialog */}
      <Dialog
        id='delete-confirm-dialog'
        size='small'
        open={displayDialog === 'delete'}
        closable
        onClose={onDialogAbort}
        title='Notification löschen'
        primaryActionButton={
          <Button size='small' type='danger' icon={<Delete />} onClick={onDeleteConfirm}>
            Löschen
          </Button>
        }
      >
        <Typography>
          Möchten Sie diese Notification wirklich löschen? Dies kann nicht rückgängig gemacht werden!
        </Typography>
      </Dialog>
      {/* Discard changes dialog */}
      <Dialog
        id='discard-changes-dialog'
        size='small'
        open={displayDialog === 'discard'}
        closable
        onClose={onDialogAbort}
        title='Ungespeicherte Änderungen'
        primaryActionButton={
          <Button
            size='small'
            type='danger'
            icon={<Delete />}
            onClick={(): void => {
              if (!notificationId) return
              // discard all changes and go back
              resetSingleNotification(notificationId)
              navigate(-1)
            }}
          >
            Verwerfen
          </Button>
        }
      >
        <Typography>
          Es existieren ungespeicherte Änderungen. Sind Sie sicher, dass Sie zurückgehen möchten? Die Änderungen werden
          dabei verworfen.
        </Typography>
      </Dialog>
    </>
  )
}
