import React, { useState } from 'react'

// @mui/material components
import alephalphaSearchAnswer from 'assets/img/modules/alephalpha_search_answer.svg'
import { Chart } from '../../../../../@types/Flowchart/types'
import ContentContainer from '../ContentContainer'
import { makeStyles } from 'tss-react/mui'
import { Tab, Tabs } from '@mui/material'
import SAlephAlphaGetAnswerBasicView from './SAlephAlphaGetAnswerBasicView'
import GetAlephAlphaGetAnswerMetadataView from './SAlephAlphaGetAnswerMetadataView'

const useStyles = makeStyles()((theme) => ({
  container: { height: '100%' },
  content: {
    height: '100%',
    paddingRight: theme.spacing(1),
    overflow: 'auto',
    /* Set margin for scrollbar */
    '&::-webkit-scrollbar': {
      marginLeft: theme.spacing(1),
    },
  },
}))

enum View {
  BASIC,
  METADATA,
}

type SAlephAlphaGetAnswerProps = {
  chart: Chart
  setIsSaveDisabled: (isSaveDisabled: boolean) => void
  setStateCallback: (chart: Chart) => void
}

export default function SAlephAlphaGetAnswer({
  chart,
  setIsSaveDisabled,
  setStateCallback,
}: SAlephAlphaGetAnswerProps): React.ReactElement {
  const { classes } = useStyles()
  const [view, setView] = useState<View>(View.BASIC)

  function onChangeView(event: React.SyntheticEvent, newValue: View): void {
    setView(newValue)
  }

  return (
    <div className={classes.container}>
      <ContentContainer headerText='Aleph Alpha Modul: Antwort suchen' descriptionText='' svg={alephalphaSearchAnswer}>
        <Tabs value={view} onChange={onChangeView} centered>
          <Tab label='Basic' value={View.BASIC} />
          <Tab label='Metadaten' value={View.METADATA} />
        </Tabs>
        <div className={classes.content}>
          {view === View.BASIC ? (
            <SAlephAlphaGetAnswerBasicView
              chart={chart}
              setIsSaveDisabled={setIsSaveDisabled}
              setStateCallback={setStateCallback}
            />
          ) : (
            <GetAlephAlphaGetAnswerMetadataView
              chart={chart}
              setIsSaveDisabled={setIsSaveDisabled}
              setStateCallback={setStateCallback}
            />
          )}
        </div>
      </ContentContainer>
    </div>
  )
}
