import React from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from './theme/StudioTheme'

import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate, useMsal } from '@azure/msal-react'
import { useStudioNotificationContext } from 'hooks/contexts/studio-notification-context'
import { AuthEventsHandler, msalInstance } from 'components/AuthProvider/PublicClientApp'
import { BotContextProvider } from 'hooks/contexts/bot-context'
import {
  BrowserRouter,
  Navigate,
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom'
import { ROUTE_BOTID, ROUTE_BOTS, ROUTE_CREATEBOT, ROUTE_LOGIN } from 'utils/constants'
import BotCreation from 'pages/BotCreation/BotCreation'
import BotLayout from 'layouts/BotLayout'
import Agents from 'pages/Agents'
import Login from 'pages/Login'
import { StudioContextProvider } from 'hooks/contexts/studio-context'
import { AbilityContext } from 'components/Can/Can'
import { ability } from 'components/Can/ability'
import { ErrorContextProvider } from 'hooks/contexts/errorContext'
import { CssBaseline } from '@mui/material'
import { RootBoundary } from 'components/Error/ErrorBoundaries'

function App() {
  // Route and Layout Management here
  const { inProgress } = useMsal()
  const { Notification } = useStudioNotificationContext()

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        path='*'
        errorElement={<RootBoundary />}
        element={
          <MsalProvider instance={msalInstance}>
            <StudioContextProvider>
              <AbilityContext.Provider value={ability}>
                <ErrorContextProvider>
                  <ThemeProvider theme={theme}>
                    <CssBaseline />
                    {/* If authenticated - Main Menu or Bot */}
                    <AuthenticatedTemplate>
                      <AuthEventsHandler />
                      <BotContextProvider>
                        <Routes>
                          <Route path={ROUTE_BOTS + ROUTE_CREATEBOT} element={<BotCreation />} />
                          <Route path={ROUTE_BOTS + ROUTE_BOTID + '/*'} element={<BotLayout />} />
                          <Route path={ROUTE_BOTS} element={<Agents />} />
                          <Route path='/*' element={<Navigate to={ROUTE_BOTS} />} />
                        </Routes>
                      </BotContextProvider>
                      {/* </Router> */}
                    </AuthenticatedTemplate>
                    {/* If not authenticated - redirect to Login */}
                    <UnauthenticatedTemplate>
                      <Routes>
                        <Route path={ROUTE_LOGIN} element={<Login />} />
                        <Route path='*' element={<Navigate to={ROUTE_LOGIN} />} />
                      </Routes>
                    </UnauthenticatedTemplate>
                    {/* Notification from notification context. Is either snackbar or null */}
                    {Notification}
                  </ThemeProvider>
                </ErrorContextProvider>
              </AbilityContext.Provider>
            </StudioContextProvider>
          </MsalProvider>
        }
      />,
    ),
  )

  return (
    <div className='App'>
      {inProgress === 'none' && (
        <>
          <RouterProvider router={router} />
        </>
      )}
    </div>
  )

  // return (
  //   <ThemeProvider theme={theme}>
  //     <div className='App'>
  //       <header className='App-header'>
  //         <img src={logo} className='App-logo' alt='logo' />
  //         <p>
  //           Edit <code>src/App.tsx</code> and save to reload.
  //           {process.env.REACT_APP_AAD_SCOPE}
  //         </p>
  //         <a className='App-link' href='https://reactjs.org' target='_blank' rel='noopener noreferrer'>
  //           Learn React
  //         </a>
  //       </header>
  //     </div>
  //   </ThemeProvider>
  // )
}

export default App
