import React from 'react'
import { makeStyles } from 'tss-react/mui'
// Custom Componets
import BaseCard from './BaseCard'
import SmartCard from 'components/SmartCard/SmartCard'
import { Card } from '../../@types/SmartCards/types'

// Component Styles
const useStyles = makeStyles()((theme) => ({
  baseCard: {},
  smartCard: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
  },
}))

type SmartCardProps = {
  card: Card
  onClick: (card: string) => void
  deleteButton: React.ReactElement
}

export default function MenuSmartCard({ card, onClick, deleteButton }: SmartCardProps): React.ReactElement {
  const { classes } = useStyles()
  return (
    <BaseCard
      title={card.id}
      titleVariant='h4'
      shadowBlur={10}
      shadowWidth={10}
      height='380px'
      onClick={(): void => onClick(card.id)}
      className={classes.baseCard}
      contentScrollable
      topRightActionButton={deleteButton}
    >
      <SmartCard card={card.data} className={classes.smartCard} />
    </BaseCard>
  )
}
