import React, { useEffect, useRef, useState } from 'react'

//
import { makeStyles } from 'tss-react/mui'

import { TextField } from '@mui/material'

const useStyles = makeStyles()((theme) => ({
  editor: {},
}))

type StepTitleProps = {
  name: string
  title: string
  subtitle: string
  setNameCallback: (name: string) => void
  setTitleCallback: (title: string) => void
  setSubtitleCallback: (subtitle: string) => void
  onNextStage: () => void
}

export default function StepTitle({
  name: _name,
  title: _title,
  subtitle: _subtitle,
  setNameCallback,
  setTitleCallback,
  setSubtitleCallback,
  onNextStage,
}: StepTitleProps): React.ReactElement {
  const { classes } = useStyles()
  const formRef = useRef<HTMLFormElement | null>(null)
  const [name, setName] = useState<string>(_name)
  const [title, setTitle] = useState<string>(_title)
  const [subtitle, setSubtitle] = useState<string>(_subtitle)

  /**
   * Intercept enter and go to next input field.
   * @param event
   */
  function preventSubmit(event: React.KeyboardEvent<HTMLInputElement>): void {
    if (event.key === 'Enter') {
      event.preventDefault()
    }
  }

  useEffect(
    function () {
      setName(_name)
    },
    [_name],
  )

  useEffect(
    function () {
      setTitle(_title)
    },
    [_title],
  )

  useEffect(
    function () {
      setSubtitle(_subtitle)
    },
    [_subtitle],
  )

  return (
    <div className={classes.editor}>
      <form ref={formRef} onSubmit={(): void => onNextStage()}>
        <TextField
          id='notification-name'
          label='Name'
          defaultValue={name}
          fullWidth
          onChange={(event): void => setNameCallback(event.target.value)}
          margin='normal'
          autoFocus
          onKeyUp={preventSubmit}
          inputProps={{
            autoComplete: 'off',
          }}
        />
        <TextField
          id='notification-title'
          label='Titel'
          defaultValue={title}
          fullWidth
          onChange={(event): void => setTitleCallback(event.target.value)}
          margin='normal'
          onKeyUp={preventSubmit}
          helperText={`${title ? title.length : 0}/150`}
          inputProps={{
            autoComplete: 'off',
            maxLength: 150,
          }}
        />
        <TextField
          id='notification-subtitle'
          label='Untertitel (optional)'
          defaultValue={subtitle}
          fullWidth
          onChange={(event): void => setSubtitleCallback(event.target.value)}
          margin='normal'
          helperText={`
            ${
              subtitle
                ? subtitle.length > 150
                  ? `${subtitle.length}/150: Der Untertitel ist länger als empfohlen. Dadurch sinkt die Nutzerfreundlichkeit.`
                  : `${subtitle.length}/150`
                : '0/150'
            }`}
          inputProps={{
            autoComplete: 'off',
          }}
        />
      </form>
    </div>
  )
}
