import { findValuesInputFields } from './chartUtils'
import { LOGIC_OPERATORS } from './constants'

import { Chart } from '../@types/Flowchart/types'
import { TranslationFile } from '../@types/Translations/types'
import { cloneDeep, hasIn } from 'lodash'
import { eventNames } from 'process'

// =========================================
// == CHART VALIDATION ==
// =========================================

/**
 * Validates xzufi getAnswer node.
 * Checks if all required xzufi properties are set.
 * @since 2.3.10
 * @param chart
 * @param nodeId
 */
function validateXzufiGetAnswerNode(chart: Chart, nodeId: string): { chart: Chart; hasError: boolean } {
  let hasError = false
  const node = chart.nodes[nodeId]

  if (!node.properties.moduleConfigId) {
    hasError = true
    node.properties.validationError = true
    node.properties.validationErrorMsg = 'Kein XZuFi Modul ausgewählt.'
  } else if (
    !node.properties.xzufi ||
    !node.properties.xzufi.corrId ||
    !node.properties.xzufi.xZufiLeistungen ||
    !node.properties.xzufi.xZufiLeistungenLength ||
    !node.properties.xzufi.xZufiQuery ||
    (node.properties.xzufi.addConvaiseNlu &&
      (!node.properties.xzufi.qnaActions ||
        !node.properties.xzufi.qnaModuleConfigId ||
        !node.properties.xzufi.qnaTriggerIntent ||
        !node.properties.xzufi.qnaAnswer))
  ) {
    hasError = true
    node.properties.validationError = true
    node.properties.validationErrorMsg = 'Block ist nicht vollständig konfiguriert.'
  }

  chart.nodes[nodeId] = node

  return { chart, hasError }
}

/**
 * Validates xzufi trackHelpful node.
 * Checks if all required xzufi properties are set.
 * @since 2.3.10
 * @param chart
 * @param nodeId
 */
function validateXzufiTrackHelpfulNode(chart: Chart, nodeId: string): { chart: Chart; hasError: boolean } {
  let hasError = false
  const node = chart.nodes[nodeId]

  if (!node.properties.moduleConfigId) {
    hasError = true
    node.properties.validationError = true
    node.properties.validationErrorMsg = 'Kein XZuFi Modul ausgewählt.'
  } else if (
    !node.properties.xzufi ||
    !node.properties.xzufi.corrId ||
    !node.properties.xzufi.helpful ||
    (node.properties.xzufi.helpful !== 'noAnswerFound' && !node.properties.xzufi.xZufiChosenLeistungsId)
  ) {
    hasError = true
    node.properties.validationError = true
    node.properties.validationErrorMsg = 'Block ist nicht vollständig konfiguriert.'
  }

  chart.nodes[nodeId] = node

  return { chart, hasError }
}

/**
 * Validates a jump node.
 * Checks if targetNode property is set.
 * @since 2.0.22
 * @param chart
 * @param nodeId
 */
function validateJumpNode(chart: Chart, nodeId: string): { chart: Chart; hasError: boolean } {
  let hasError = false
  const node = chart.nodes[nodeId]

  if (!node.properties.targetNode) {
    hasError = true
    node.properties.validationError = true
    node.properties.validationErrorMsg = 'Kein Ziel festgelegt.'
  } else if (node.properties.targetNode === node.id) {
    hasError = true
    node.properties.validationError = true
    node.properties.validationErrorMsg = 'Block kann nicht selbst das Ziel sein.'
  }

  chart.nodes[nodeId] = node

  return { chart, hasError }
}

/**
 * Validates an event trigger node.
 * Atm only checks if node has varible set.
 * @since 2.0.22
 * @param chart
 * @param nodeId
 */
function validateEventTriggerNode(chart: Chart, nodeId: string): { chart: Chart; hasError: boolean } {
  let hasError = false
  const node = chart.nodes[nodeId]

  if (!node.properties.event) {
    hasError = true
    node.properties.validationError = true
    node.properties.validationErrorMsg = 'Kein Trigger Event ausgewählt.'
  } else if (!node.properties.value) {
    hasError = true
    node.properties.validationError = true
    node.properties.validationErrorMsg = 'Kein Trigger Event Wert gesetzt.'
  }

  chart.nodes[nodeId] = node

  return { chart, hasError }
}

/**
 * Validates an event trigger node.
 * Atm only checks if node has varible set.
 * @since 2.3.5
 * @param chart
 * @param nodeId
 */
function validateAnalyticsTriggerNode(chart: Chart, nodeId: string): { chart: Chart; hasError: boolean } {
  let hasError = false
  const node = chart.nodes[nodeId]

  const customAnalyticsEventNames = Object.values(chart.customAnalyticsEvents).map((event) => event.eventName)

  if (!node.properties.analyticsEvent || !node.properties.analyticsEvent?.eventName) {
    hasError = true
    node.properties.validationError = true
    node.properties.validationErrorMsg = 'Kein Analytics Event ausgewählt.'
  } else if (
    node.properties.analyticsEvent &&
    node.properties.analyticsEvent.origin === 'customer' &&
    !customAnalyticsEventNames.includes(node.properties.analyticsEvent.eventName)
  ) {
    hasError = true
    node.properties.validationError = true
    node.properties.validationErrorMsg = 'Ausgewähltes Analytics Event existiert nicht.'
  }

  chart.nodes[nodeId] = node

  return { chart, hasError }
}

/**
 * Validates a (intent) trigger node.
 * Checks if trigger intent is set in properties.
 * @since 2.0.22
 * @param chart
 * @param nodeId
 */
function validateTriggerNode(chart: Chart, nodeId: string): { chart: Chart; hasError: boolean } {
  let hasError = false
  const node = chart.nodes[nodeId]

  if (!node.properties.triggerIntent) {
    hasError = true
    node.properties.validationError = true
    node.properties.validationErrorMsg = 'Kein Trigger ausgewählt.'
  }

  chart.nodes[nodeId] = node

  return { chart, hasError }
}

/**
 * Validates a switch node (old one).
 * Atm only checks if node has varible set.
 * @since 2.0.20
 * @param chart
 * @param nodeId
 */
function validateOldSwitchNode(chart: Chart, nodeId: string): { chart: Chart; hasError: boolean } {
  let hasError = false
  const node = chart.nodes[nodeId]

  if (!node.properties.varname) {
    hasError = true
    node.properties.validationError = true
    node.properties.validationErrorMsg = 'Keine Variable ausgewählt.'
  }

  chart.nodes[nodeId] = node

  return { chart, hasError }
}

/**
 * Validates a switch node.
 * Checks if variable is set.
 * We do not check for conditions because there is always the default case.
 * @param chart
 * @param nodeId
 */
function validateSwitchNode(chart: Chart, nodeId: string): { chart: Chart; hasError: boolean } {
  let hasError = false
  const node = chart.nodes[nodeId]

  if (!node.properties.switchConditions?.variableIdToCheck) {
    hasError = true
    node.properties.validationError = true
    node.properties.validationErrorMsg = 'Keine Variable ausgewählt.'
  }

  chart.nodes[nodeId] = node

  return { chart, hasError }
}

/**
 * Validates ifElse node.
 * Validates if condition is set and valid.
 * NOTE: for now, we only perform a basic check. This needs to be more sophisticated in the future!
 * @since 2.0.20
 */
function validateIfElseNode(chart: Chart, nodeId: string): { chart: Chart; hasError: boolean } {
  let hasError = false
  const node = chart.nodes[nodeId]

  const conditionIds = Object.keys(node?.properties.conditions || {})
  if (!node.properties.conditions || !conditionIds || conditionIds.length === 0) {
    // no condition is set
    hasError = true
    node.properties.validationError = true
    node.properties.validationErrorMsg = 'Logikbedingungen fehlt.'
    chart.nodes[nodeId] = node
    return { chart, hasError }
  }
  const conditionId = conditionIds[0]

  const condition = node.properties.conditions[conditionId]
  const variableIdsOfCondition = Object.keys(condition)

  let isValidCondition = true
  for (const variableId of variableIdsOfCondition) {
    const conditionString = condition[variableId].trim()
    // check if condition starts with valid logic operator
    let doesStartWithOperator = false
    let logicOperator = ''
    for (const operator of LOGIC_OPERATORS) {
      if (conditionString.startsWith(operator)) {
        doesStartWithOperator = true
        logicOperator = operator
        break
      }
    }
    // check if string behind logic operator is not empty
    let validStringAfterOperator = false
    if (conditionString.split(logicOperator)[1]) validStringAfterOperator = true

    if (!doesStartWithOperator || !validStringAfterOperator) isValidCondition = false
  }

  if (!isValidCondition) {
    hasError = true
    node.properties.validationError = true
    node.properties.validationErrorMsg = 'Ungültige Logikbedingung.'
  }
  chart.nodes[nodeId] = node

  return { chart, hasError }
}

/**
 * Checks card node.
 * Validates if card is set and card exists in translations.
 * @param chart
 * @param translations
 */
function validateCardNode(
  chart: Chart,
  translations: TranslationFile,
  nodeId: string,
): { chart: Chart; hasError: boolean } {
  const node = chart.nodes[nodeId]
  let hasError = false
  if (!node.properties.card) {
    // card is not set
    hasError = true
    node.properties.validationError = true
    node.properties.validationErrorMsg = 'Keine Smart Card ausgewählt.'
  } else if (!Object.keys(translations.ac[translations.primaryLanguage]).includes(node.properties.card)) {
    // card does not exist
    hasError = true
    node.properties.validationError = true
    node.properties.validationErrorMsg = 'Ausgewählte Smart Card existiert nicht.'
  } else {
    // no error
    delete node.properties.validationError
    delete node.properties.validationErrorMsg
  }
  chart.nodes[nodeId] = node
  return { chart, hasError }
}

/**
 * Checks API node.
 * Validations:
 * - is method set
 * - is url set
 * - is expected status set in response
 * @param chart
 * @param nodeId
 */
function validateApiNode(chart: Chart, nodeId: string): { chart: Chart; hasError: boolean } {
  const node = chart.nodes[nodeId]
  const api = node.properties.api
  let hasError = false
  if (!api?.request?.method) {
    hasError = true
    node.properties.validationError = true
    node.properties.validationErrorMsg = 'Keine Request Methode ausgewählt.'
  } else if (!api?.request.url) {
    hasError = true
    node.properties.validationError = true
    node.properties.validationErrorMsg = 'Keine Request URL konfiguriert.'
  } else if (!api?.response?.status) {
    hasError = true
    node.properties.validationError = true
    node.properties.validationErrorMsg =
      'Erwarteter Status nicht konfiguriert. API Node testen, um den erwarteten Status zu konfigurieren.'
  } else {
    // no error
    delete node.properties.validationError
    delete node.properties.validationErrorMsg
  }
  chart.nodes[nodeId] = node
  return { chart, hasError }
}

/**
 * Checks setVariables node.
 * Validations:
 * - is at least one variable set
 * @param chart
 * @param nodeId
 */
function validateSetVariablesNode(chart: Chart, nodeId: string): { chart: Chart; hasError: boolean } {
  const node = chart.nodes[nodeId]
  const setVars = node.properties.setVariables?.setVars
  let hasError = false
  if (Object.keys(setVars ?? {}).length === 0 || Object.keys(setVars ?? {}).includes('')) {
    hasError = true
    node.properties.validationError = true
    node.properties.validationErrorMsg = 'Keine Variable gesetzt'
  } else if (Object.values(setVars ?? {}).includes('')) {
    hasError = true
    node.properties.validationError = true
    node.properties.validationErrorMsg = 'Wert darf nicht leer sein'
  } else {
    // no error
    delete node.properties.validationError
    delete node.properties.validationErrorMsg
  }
  chart.nodes[nodeId] = node
  return { chart, hasError }
}

/**
 * Validate file upload node.
 * Validations:
 * - fileName, fieldTypes, downloadUrlVarId
 * @param validatedChart
 * @param nodeId
 */
function validateFileUploadNode(chart: Chart, nodeId: string): { chart: Chart; hasError: boolean } {
  const node = chart.nodes[nodeId]
  let hasError = false

  if (node.properties.fileUpload && Object.keys(node.properties.fileUpload).length > 0) {
    const configId = Object.keys(node.properties.fileUpload)[0]

    if (
      !(
        node.properties.fileUpload[configId].fileName &&
        node.properties.fileUpload[configId].fileTypes &&
        node.properties.fileUpload[configId].fileDownloadUrlVarId
      )
    ) {
      hasError = true
      node.properties.validationError = true
      node.properties.validationErrorMsg = 'Unvollständig konfiguriert.'
    } else {
      // no error
      delete node.properties.validationError
      delete node.properties.validationErrorMsg
    }
  }
  chart.nodes[nodeId] = node
  return { chart, hasError }
}

/**
 * Validate llm task node.
 * Validates that all required proeprties are configured.
 */
function validateLLMTaskNode(chart: Chart, nodeId: string): { chart: Chart; hasError: boolean } {
  const node = chart.nodes[nodeId]
  let hasError = false

  if (node.properties.llmTask && Object.keys(node.properties.llmTask).length > 0) {
    if (
      !(
        (node.properties.llmTask.userPrompt || node.properties.llmTask.systemPrompt) &&
        node.properties.llmTask.displayTextFirstTurn &&
        (node.properties.llmTask.historyEnabled
          ? node.properties.llmTask.displayTextLaterTurns &&
            node.properties.llmTask.buttonTextFinish &&
            node.properties.llmTask.buttonTextTweak
          : true)
      )
    ) {
      hasError = true
      node.properties.validationError = true
      node.properties.validationErrorMsg = 'Unvollständig konfiguriert.'
    } else {
      // no error
      delete node.properties.validationError
      delete node.properties.validationErrorMsg
    }
  }
  chart.nodes[nodeId] = node
  return { chart, hasError }
}

/**
 * Checks all start-dialog nodes.
 * Validations:
 * - is dialog selected
 * - does dialog exist
 * @param chart
 */
function validateStartDialogNode(chart: Chart, nodeId: string): { chart: Chart; hasError: boolean } {
  let hasError = false
  const node = chart.nodes[nodeId]
  const targetDialogId = node.properties.targetDialog
  const allDialogsIds = Object.keys(chart.dialogs)
  if (!targetDialogId) {
    hasError = true
    node.properties.validationError = true
    node.properties.validationErrorMsg = 'Kein Dialog ausgewählt.'
  } else if (!allDialogsIds.includes(targetDialogId)) {
    hasError = true
    node.properties.validationError = true
    node.properties.validationErrorMsg = 'Dialog existiert nicht.'
  } else {
    // no error
    delete node.properties.validationError
    delete node.properties.validationErrorMsg
  }
  chart.nodes[node.id] = { ...node }
  return { chart, hasError }
}

/**
 * Validates that all intent triggers are used <= once.
 * If they are used more than once, returns the nodeids of the nodes using that trigger
 * @param chart
 */
function validateIntentTriggersOnlyUsedOnce(chart: Chart): {
  hasError: boolean
  chart: Chart
  erroneousNodeIds: string[]
} {
  const triggerNodes = Object.values(chart.nodes).filter((node) => node.type === 'trigger/intent')
  const triggerIntentNodeMapping: { [intent: string]: string[] } = {} // map nodeIds to each intent
  for (const node of triggerNodes) {
    const intent = node.properties.triggerIntent
    if (intent) {
      if (!triggerIntentNodeMapping[intent]) triggerIntentNodeMapping[intent] = []
      triggerIntentNodeMapping[intent].push(node.id)
    }
  }

  let hasError = false
  const erroneousNodeIds: string[] = []
  for (const intent of Object.keys(triggerIntentNodeMapping)) {
    if (triggerIntentNodeMapping[intent].length > 1) {
      hasError = true
      for (const nodeId of triggerIntentNodeMapping[intent]) {
        erroneousNodeIds.push(nodeId)
        chart.nodes[nodeId].properties.validationError = true
        chart.nodes[nodeId].properties.validationErrorMsg = 'Trigger wird in mehreren Nodes verwendet!'
      }
    }
  }

  return {
    hasError,
    chart,
    erroneousNodeIds,
  }
}

/**
 * Validates that all nodes have outgoing ports connected.
 * This way we ensure that there are no dead ends in the conversation.
 * @param chart
 */
function validateAllPortsConnected(
  chart: Chart,
  botId?: string,
): {
  hasError: boolean
  chart: Chart
  erroneousNodeIds: string[]
} {
  // we disable this check for the BVK bot ddxur44gkzqviz2q
  if (!botId || botId === 'ddxur44gkzqviz2q') return { hasError: false, chart, erroneousNodeIds: [] }

  let hasError = false
  const erroneousNodeIds: string[] = []
  for (const node of Object.values(chart.nodes)) {
    const outgoingPorts = Object.values(node.ports).filter((port) => port.type === 'right')
    if (outgoingPorts.length === 0) continue

    for (const port of outgoingPorts) {
      // if there is no link starting from this port, we have a dead end.
      // iterate over links.
      let hasOutgoing = false
      let hasIncoming = false
      for (const link of Object.values(chart.links)) {
        if (link.from.nodeId === node.id && link.from.portId === port.id) {
          hasOutgoing = true
        }
        if (link.to.nodeId === node.id) {
          hasIncoming = true
        }
        if (hasOutgoing) break
      }
      if (!hasOutgoing && hasIncoming) {
        // set error if incoming and no outgoing links
        hasError = true
        erroneousNodeIds.push(node.id)
        chart.nodes[node.id].properties.validationError = true
        chart.nodes[node.id].properties.validationErrorMsg = `Nicht verbundene Ports (Dialog: ${
          chart.dialogs[chart.nodes[node.id].properties.dialog].name
        })`
      }
    }
  }

  return {
    chart,
    hasError,
    erroneousNodeIds: Array.from(new Set(erroneousNodeIds)),
  }
}

/**
 * Checks and validates chart.
 * This function should capture invalid charts that could break the assistant and not run successfully.
 *
 * Iterates over nodes and checks if each node is correctly configured.
 *
 * // TODO: add other nodes
 * @param chart
 */
export function validateChart(chart: Chart, translations: TranslationFile, botId?: string): Chart {
  let validatedChart = cloneDeep(chart)

  const erroneousNodeIds: string[] = []
  let hasError = false

  // check all individual nodes for error
  for (const nodeId of Object.keys(validatedChart.nodes)) {
    let validationResult
    switch (validatedChart.nodes[nodeId].type) {
      case 'basic/adaptiveCard': {
        validationResult = validateCardNode(validatedChart, translations, nodeId)
        break
      }
      case 'basic/api': {
        validationResult = validateApiNode(validatedChart, nodeId)
        break
      }
      case 'logic/startDialog': {
        validationResult = validateStartDialogNode(validatedChart, nodeId)
        break
      }
      case 'logic/ifElse': {
        validationResult = validateIfElseNode(validatedChart, nodeId)
        break
      }
      case 'logic/switch': {
        // old switch node
        validationResult = validateOldSwitchNode(validatedChart, nodeId)
        break
      }
      case 'logic/switchCondition': {
        // new switch node
        validationResult = validateSwitchNode(validatedChart, nodeId)
        break
      }
      case 'logic/jump': {
        validationResult = validateJumpNode(validatedChart, nodeId)
        break
      }
      case 'logic/setVariables': {
        validationResult = validateSetVariablesNode(validatedChart, nodeId)
        break
      }
      case 'trigger/intent': {
        validationResult = validateTriggerNode(validatedChart, nodeId)
        break
      }
      case 'trigger/event': {
        validationResult = validateEventTriggerNode(validatedChart, nodeId)
        break
      }
      case 'trigger/analytics': {
        validationResult = validateAnalyticsTriggerNode(validatedChart, nodeId)
        break
      }
      case 'module/xzufi-getAnswer': {
        validationResult = validateXzufiGetAnswerNode(validatedChart, nodeId)
        break
      }
      case 'module/xzufi-trackHelpful': {
        validationResult = validateXzufiTrackHelpfulNode(validatedChart, nodeId)
        break
      }
      case 'module/llm-task': {
        validationResult = validateLLMTaskNode(validatedChart, nodeId)
        break
      }
      case 'basic/fileUpload': {
        validationResult = validateFileUploadNode(validatedChart, nodeId)
        break
      }
    }

    if (validationResult) {
      if (validationResult.hasError) {
        hasError = true
      } else {
        // remove error from node properties (if it was previously there)
        delete validatedChart.nodes[nodeId].properties.validationError
        delete validatedChart.nodes[nodeId].properties.validationErrorMsg
      }
      validatedChart = validationResult.chart
      if (validationResult.hasError) erroneousNodeIds.push(nodeId)
    }
  }

  // check for other things

  // check that each intent trigger is only used once
  const {
    hasError: hasErrorIntentTrigger,
    chart: chartIntentTriggerValidation,
    erroneousNodeIds: erroneousNodeIdsIntentTrigger,
  } = validateIntentTriggersOnlyUsedOnce(validatedChart)
  if (hasErrorIntentTrigger) hasError = true
  validatedChart = chartIntentTriggerValidation
  erroneousNodeIdsIntentTrigger.forEach((nodeId) => {
    if (!erroneousNodeIds.includes(nodeId)) erroneousNodeIds.push(nodeId)
  })

  // check that all smart card nodes have all outgoing ports connected
  const {
    hasError: hasErrorAllPortsConnected,
    chart: chartAllPortsConnectedValidation,
    erroneousNodeIds: erroneousNodeIdsAllPortsConnectedValidation,
  } = validateAllPortsConnected(validatedChart, botId)
  if (hasErrorAllPortsConnected) hasError = true
  validatedChart = chartAllPortsConnectedValidation
  erroneousNodeIdsAllPortsConnectedValidation.forEach((nodeId) => {
    if (!erroneousNodeIds.includes(nodeId)) erroneousNodeIds.push(nodeId)
  })

  if (!hasError) {
    delete validatedChart.hasError
    delete validatedChart.nodesWithErrors
  } else {
    validatedChart.hasError = true
    validatedChart.nodesWithErrors = erroneousNodeIds
  }

  return validatedChart
}

// =========================================
// == CHART FIXES ==
// =========================================

/**
 * Iterates over all nodes and checks for each card node if all fieldIds in the mandatoryInputField array exist in the node.
 * Removes fieldIds from the array that no longer exist.
 * @since 0.2.11
 * @param chart
 */
function removeStaleMandatoryFieldIds(chart: Chart, translations: TranslationFile): Chart {
  for (const nodeId of Object.keys(chart.nodes)) {
    const node = chart.nodes[nodeId]
    if (
      node.type === 'basic/adaptiveCard' &&
      typeof node.properties.mandatoryInputFields !== 'undefined' &&
      node.properties.mandatoryInputFields.length > 0 &&
      typeof node.properties.card !== 'undefined' &&
      Object.keys(translations.ac[translations.primaryLanguage]).includes(node.properties.card)
    ) {
      const card = translations.ac[translations.primaryLanguage][node.properties.card].data
      const allInputFieldIds = findValuesInputFields(card).map((field) => field.id)

      node.properties.mandatoryInputFields = node.properties.mandatoryInputFields.filter((fieldId) =>
        allInputFieldIds.includes(fieldId),
      )
      chart.nodes[nodeId] = node
    }
  }
  return chart
}

/**
 * Checks chart and fixes minor problems.
 * This should remove stale "things from older chart versions".
 *
 * Functionality:
 * - Checks fieldIds in the mandatoryInputFields array if the card for that node doesn't have that field anymore and removes it
 * @since 0.2.11
 * @param chart
 */
export function fixChart(chart: Chart, translations: TranslationFile): Chart {
  // remove stale fieldIds
  const newChart = removeStaleMandatoryFieldIds(chart, translations)
  return newChart
}
