import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import Fuse from 'fuse.js'

// Material UI
import { makeStyles } from 'tss-react/mui'

import { Typography, Grid } from '@mui/material'
// API
import Dialog from 'components/Dialogs/Dialog'
// Custom Components
import Table from 'components/Table/Table'
import { Answer } from 'classes/Knowledge'
import { Textfield } from 'components/TextInput/Textfield'
import { Button, CustomIconButton } from 'components/Buttons'
// Icons
// Hooks
import { useAnswers } from 'hooks/contexts/answers-context'
// Utils
// Types
import { AnswerAction } from '../../../../../../@types/Knowledge/types'
import AnswerPreview from 'components/Previews/answerPreview'

const useStyles = makeStyles()((theme) => ({
  editContainer: {
    display: 'flex',
    marginTop: theme.spacing(4),
    height: '85%',
    width: '100%',
  },
  previewContainer: {
    minWidth: '380px',
    maxWidth: '380px',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(5),
    alignSelf: 'flex-start',
    height: '100%',
    width: '100%',
    paddingTop: theme.spacing(3),
  },
  editAnswerTips: {
    marginTop: 'auto',
  },
  editAnswerTipContainer: {
    display: 'flex',
    flexDirection: 'row',
    '& h3': {
      marginRight: theme.spacing(1),
    },
    '& p': {
      marginTop: '0.4rem',
    },
  },
  search: {
    marginLeft: 'auto',
  },
  markdown: {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical',
    '& p': {
      margin: 0,
    },
  },
}))

type EditAnswerActionsDialogProps = {
  open: boolean
  answerId: string
  action?: AnswerAction
  actionIndex?: number
  onClose: () => void
  onSubmit: () => void
}

// TODO: submit answer in here
export default function EditAnswerActionsDialog({
  open,
  answerId,
  action: propAction, // only relevant when editing existing action
  actionIndex, // only relevant when editing existing action
  onClose,
  onSubmit,
}: EditAnswerActionsDialogProps): React.ReactElement {
  const { classes } = useStyles()
  const { getAnswer, answersArrayPrimaryLang, updateAnswerAction } = useAnswers()
  const [step, setStep] = useState<number>(0)
  const [action, setAction] = useState<AnswerAction>(propAction ?? { action: '', answerId: null })
  const [searchString, setSearchString] = useState<string>('')
  const [displayedAnswers, setDisplayedAnswers] = useState<Answer[]>([])

  /**
   * Searches answers for search string and returns result.
   */
  function filterAnswers(): void {
    if (!searchString) {
      setDisplayedAnswers(answersArrayPrimaryLang)
      return
    }
    const fuseOptions = {
      shouldSort: true,
      threshold: 0.4,
      minMatchCharLength: 1,
      keys: ['title', 'actions', 'labels', 'answer'],
    }
    const fuse = new Fuse(answersArrayPrimaryLang, fuseOptions)
    setDisplayedAnswers(fuse.search(searchString).map((result) => result.item))
  }

  function updateAction(): void {
    // editing existing action
    if (propAction && typeof actionIndex === 'number') {
      updateAnswerAction(answerId, action, actionIndex)
    } else {
      // new action
      updateAnswerAction(answerId, action, null)
    }
  }

  useEffect(() => {
    filterAnswers()
  }, [searchString])

  return (
    <Dialog
      id='create-edit-action-dialog'
      size='large'
      open={open}
      closable
      onClose={onClose}
      title={step === 0 ? 'Folgefragen-Text (1/2)' : 'Folgefragen-Antwort (2/2)'}
      primaryActionButton={
        <Button
          type={step === 0 ? 'normal' : 'success'}
          size='small'
          iconType='remix'
          icon={step === 0 ? 'arrow-right-line' : 'check-line'}
          onClick={(): void => {
            if (step === 0) {
              setStep(1)
            } else {
              // save action in answer
              updateAction()
              onSubmit()
            }
          }}
          disabled={step === 1 && action.answerId === null}
        >
          {step === 0 ? 'Weiter' : 'Speichern'}
        </Button>
      }
      secondaryActionText={step === 0 ? undefined : 'Zurück'}
      onSecondaryActionClick={
        step === 0
          ? undefined
          : (): void => {
              if (step === 1) {
                setStep(0)
              }
            }
      }
    >
      {/* Step specific "Task" description */}
      {step === 0 ? (
        <Typography>
          Bitte geben Sie den Text an, der im Chat unter der aktuellen Antwort als Auswahlmöglichkeit erscheinen soll.
          Für eine gute Benutzerfreundlichkeit im Chat, darf eine Folgefrage maximal 50 Zeichen lang sein.
        </Typography>
      ) : (
        <Typography>
          Bitte wählen Sie eine Antwort, die angezeigt werden soll, wenn die Folgefrage von Nutzer*innen ausgewählt
          wird.
        </Typography>
      )}
      {/* Edit Container */}
      <div className={classes.editContainer}>
        <div className={classes.previewContainer}>
          <Typography>
            <strong>Vorschau der Folgefrage</strong>
          </Typography>
          <AnswerPreview
            question={action.action ?? ''}
            answer={(action.answerId ? getAnswer(action.answerId)?.answer : '') ?? ''}
            actions={[]} // FIXME: use actional answer actions
          />
        </div>
        <div className={classes.inputContainer}>
          {step === 0 ? (
            <>
              <Textfield
                onChange={(event): void => {
                  const _action = { ...action }
                  _action.action = event.target.value
                  setAction(_action)
                }}
                value={action.action}
                placeholder={'Bitte Folgefrage eingeben...'}
                helperText={`${action.action ? action.action.length : 0}/50`}
                inputProps={{
                  autoComplete: 'off',
                  maxLength: 50,
                }}
              />
              {/* FIXME: Tipps für gute Folgefragen */}
              <Grid container spacing={2} className={classes.editAnswerTips}>
                <Grid item xs={12} sm={6} md={6} className={classes.editAnswerTipContainer}>
                  <Typography variant='h3'>1.</Typography>
                  <Typography>
                    <strong>Kontext</strong>
                    <br />
                    Der Antworttext sollte so formuliert sein, dass der Kontext der Antwort deutlich erkennbar ist. Eine
                    einfache Überprüfungsmethode ist: Kann der Antworttext eigenständig stehen, ohne eine Frage, und ist
                    er dennoch verständlich?
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} className={classes.editAnswerTipContainer}>
                  <Typography variant='h3'>2.</Typography>
                  <Typography>
                    <strong>Antwortlänge</strong>
                    <br />
                    Um Nutzer*innen das beste Erlebnis zu bieten, sollten Antworttexte klar und knapp gehalten werden.
                  </Typography>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Textfield
                className={classes.search}
                value={searchString}
                placeholder='Suchen...'
                onChange={(event): void => setSearchString(event.target.value)}
              />
              <Table
                headers={['Titel', 'Antwort', '']}
                cellAlignPattern={['left', 'left', 'right']}
                width={['30%', '60%', '60px']}
                padding='medium'
                searchString={searchString}
                rows={(displayedAnswers ?? [])
                  .filter((answer) => answer.answerId !== answerId)
                  .sort(function (x, y) {
                    return x.answerId == action.answerId ? -1 : y.answerId == action.answerId ? 1 : 0
                  })
                  .map((answer, idx) => {
                    return [
                      <>
                        <Typography key={`answer-topic-${idx}`} variant='small'>
                          {answer.topic ?? ''}
                        </Typography>
                        <Typography key={`answer-title-${idx}`}>
                          {answer.answerId === action.answerId ? <strong>{answer.title}</strong> : answer.title}
                        </Typography>
                      </>,
                      <Typography key={`answer-answer-${idx}`} className={classes.markdown}>
                        <ReactMarkdown>{answer.answer}</ReactMarkdown>
                      </Typography>,
                      <CustomIconButton
                        key={`answer-action-${idx}`}
                        type='accept'
                        onClick={(): void => {
                          const _action = { ...action }
                          _action.answerId = answer.answerId
                          setAction(_action)
                        }}
                        disabled={answer.answerId === action.answerId}
                        tooltip='Antwort auswählen'
                      />,
                    ]
                  })}
                // isLoading={isLoading}
              />
            </>
          )}
        </div>
      </div>
    </Dialog>
  )
}
