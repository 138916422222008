import React, { useState } from 'react'

// Material ui
import { makeStyles } from 'tss-react/mui'

import Typography from '@mui/material/Typography'
import { Divider, IconButton, ListItemText, MenuItem, MenuList, Menu } from '@mui/material'
import { MoreVert } from '@mui/icons-material'
// Custom Componets
import BaseCard from './BaseCard'
import { Can } from 'components/Can/Can'
// Hooks
import { useLockingContext } from 'hooks/contexts/locking-context'
// Utils
import { CUSTOM_NOTIFICATION_CARD_HEIGHT } from 'utils/constants'
// Types
import { AnswerAction } from '../../@types/Knowledge/types'
import { useAnswers } from 'hooks/contexts/answers-context'
import ReactMarkdown from 'react-markdown'

const useStyles = makeStyles()((theme) => ({
  card: {
    // transition: 'box-shadow 0.3s',
    // cursor: 'pointer',
    // '&:hover': {
    //   boxShadow:
    //     '0 14px 26px -12px rgb(0 0 0 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)', // '0px 2px 10px 2px rgba(0,0,0, 0.25)'
    // },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  popupMenu: {
    padding: 0,
  },
  popupMenuList: {
    padding: 0,
  },
  popupMenuRedText: {
    color: theme.palette.error.main,
  },
  markdown: {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical',
    '& p': {
      margin: 0,
    },
  },
}))

type PopupMenuProps = {
  onEdit: () => void
  onDelete: () => void
  open: boolean
  onClose: () => void
  anchorElement: Element
}

/**
 * Menu component for menu button of card.
 */
function PopupMenu({ onEdit, onDelete, open, onClose, anchorElement }: PopupMenuProps): React.ReactElement {
  const { classes } = useStyles()
  return (
    <Menu
      open={open}
      onClose={onClose}
      anchorEl={anchorElement}
      classes={{ list: classes.popupMenuList }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{
        style: {
          width: '150px',
        },
      }}
    >
      <MenuList>
        <MenuItem onClick={onEdit}>
          <ListItemText>Bearbeiten</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={onDelete}>
          <ListItemText classes={{ primary: classes.popupMenuRedText }} primary='Löschen' />
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

type CardProps = {
  action: AnswerAction
  actionIndex: number
  onEdit: (actionIndex: number) => void
  onDelete: (actionIndex: number) => void
  disabled?: boolean
  height?: string
}

/**
 * Card for Custom Notification
 */
export default function ActionCard({
  action,
  actionIndex,
  onEdit,
  onDelete,
  disabled = false,
  height = CUSTOM_NOTIFICATION_CARD_HEIGHT,
}: CardProps): React.ReactElement {
  const { classes } = useStyles()
  const { lockState } = useLockingContext()
  const { getAnswer } = useAnswers()
  const [anchorElementForMenu, setAnchorElementForMenu] = useState<HTMLElement | null>(null)
  const menuOpen = Boolean(anchorElementForMenu)
  const answer = action.answerId ? getAnswer(action.answerId) : null

  function handleMenuClose(): void {
    setAnchorElementForMenu(null)
  }

  function handleMenuClick(event: React.MouseEvent<HTMLButtonElement>): void {
    event.stopPropagation()
    if (menuOpen) {
      handleMenuClose()
    } else {
      setAnchorElementForMenu(event.currentTarget)
    }
  }

  // FIXME Warning if answer does not exist anymore

  return (
    <Can I='update' a='knowledge' passThrough>
      {(can): React.ReactElement => (
        <>
          <BaseCard
            title={action.action}
            height={height}
            width='100%'
            className={classes.card}
            onClick={(): void => {
              onEdit(actionIndex)
            }}
            shadowWidth={10}
            shadowBlur={15}
            titleVariant='h3'
            topRightActionButton={
              can && !disabled ? (
                <IconButton
                  onClick={handleMenuClick}
                  disabled={lockState !== 'canEdit'}
                  aria-controls={menuOpen ? 'basic-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={menuOpen ? 'true' : undefined}
                >
                  <MoreVert />
                </IconButton>
              ) : null
            }
          >
            <Divider />
            {answer && (
              <div className={classes.content}>
                <Typography variant='small'>{answer.topic}</Typography>
                <Typography variant='h3' mb={2}>
                  {answer.title}
                </Typography>
                {/* FIXME: validateDOMNesting(...): <p> cannot appear as a descendant of <p>. - first <p> by Typography and second <p> by ReactMarkdown */}
                <Typography className={classes.markdown} variant='body2' mt='auto'>
                  <ReactMarkdown>{answer.answer}</ReactMarkdown>
                </Typography>
              </div>
            )}
          </BaseCard>
          {anchorElementForMenu && (
            <PopupMenu
              open={menuOpen}
              onClose={handleMenuClose}
              onDelete={(): void => {
                if (disabled) return
                handleMenuClose()
                onDelete(actionIndex)
              }}
              onEdit={(): void => {
                if (disabled) return
                handleMenuClose()
                onEdit(actionIndex)
              }}
              anchorElement={anchorElementForMenu}
            />
          )}
        </>
      )}
    </Can>
  )
}
