import React, { useEffect, useState } from 'react'
import BaseCard from 'components/Cards/BaseCard'
import { makeStyles } from 'tss-react/mui'
import { List, ListItem, ListItemText, Typography } from '@mui/material'
import ReactMarkdown from 'react-markdown'
import { Answer } from 'classes/Knowledge'

const useStyles = makeStyles()((theme) => ({
  markdown: {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical',
    '& p': {
      margin: 0,
    },
    fontSize: '12px',
  },
  answerText: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '120px',
      wordBreak: 'break-word',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '300px',
      wordBreak: 'break-word',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '500px',
      wordBreak: 'break-word',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '800px',
      wordBreak: 'break-word',
    },
    height: '60px',
  },
  utterances: {
    marginTop: theme.spacing(2),
    borderTop: '1px solid rgba(0,0,0,0.2)',
    overflow: 'hidden',
  },
  utteranceListItem: {
    whiteSpace: 'nowrap',
    padding: 0,
    margin: 0,
    marginTop: `-${theme.spacing(1)}`,
  },
  utteranceListItemText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    margin: 0,
    padding: 0,
  },
  hover: {
    transition: 'box-shadow 0.3s',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0 7px 13px -6px rgb(0 0 0 / 42%), 0 2px 11px 0px rgb(0 0 0 / 12%), 0 4px 5px -3px rgb(0 0 0 / 20%)',
    },
  },
}))

type AnswerCardProps = {
  answer: Answer
  isSelected: boolean
  disabled?: boolean
  onClick: (answerId: string) => void
}

export default React.memo(function AnswerCard({
  answer,
  isSelected: isSelectedProps,
  onClick,
  disabled = false,
}: AnswerCardProps): React.ReactElement {
  const { classes } = useStyles()
  const [isSelected, setIsSelected] = useState<boolean>()

  useEffect(
    function () {
      setIsSelected(isSelectedProps)
    },
    [isSelectedProps],
  )

  return (
    // <div style={{ width: '390px' }}>
    <BaseCard
      title={
        <div>
          <Typography variant='caption'>{answer.topic}</Typography>
          <Typography variant='h4'>
            {answer.title} ({answer.score ? Math.round((answer.score || 0) * 100 * 10) / 10 : '-'} %)
          </Typography>
        </div>
      }
      isSelected={isSelected}
      height={'233px'}
      width={'390px'}
      paddingTop='12px'
      paddingSide='18px'
      paddingBottom='12px'
      onClick={(): void => onClick(answer.answerId)}
      shadowWidth={10}
      shadowBlur={15}
      className={classes.hover}
      disabled={disabled}
    >
      <Typography className={classes.answerText}>
        <ReactMarkdown className={classes.markdown}>{answer.answer}</ReactMarkdown>
      </Typography>

      <div className={classes.utterances}>
        <Typography variant='small'>Beispielfragen:</Typography>
        <List dense>
          {answer.labels.slice(0, 3).map((label, index) => (
            <ListItem key={`${answer.answerId}-utt-${index}`} classes={{ root: classes.utteranceListItem }}>
              <ListItemText
                classes={{ root: classes.utteranceListItemText }}
                primary={<Typography variant='caption'>{label}</Typography>}
              />
            </ListItem>
          ))}
        </List>
      </div>
    </BaseCard>
    // </div>
  )
})
