import React from 'react'
import { makeStyles } from 'tss-react/mui'
import Button from '../Buttons/Button'
import Typography from '@mui/material/Typography'

type CardHeight = 'fixed' | 'auto'

type StyleProps = { height: CardHeight; width: string }

const useStyles = makeStyles<StyleProps>()((theme, props, classes) => ({
  container: {
    maxWidth: '800px',
    width: props.width === '600' ? '600px' : '800px',
    position: 'relative',
    height: props.height === 'fixed' ? '280px' : 'auto',
    borderRadius:
      typeof theme.shape.borderRadius === 'string'
        ? parseInt(theme.shape.borderRadius) * 3
        : theme.shape.borderRadius * 3,
    display: 'flex',
    background: theme.palette.background.paper,
    // marginBottom: theme.spacing(8),
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '-30px',
      left: '-30px',
      right: '-30px',
      bottom: '-30px',
      zIndex: -1,
      background: theme.palette.grey.A100,
      opacity: 1,
      WebkitFilter: 'blur(90px)',
      filter: 'blur(90px)',
      borderRadius: `${
        typeof theme.shape.borderRadius === 'string'
          ? parseInt(theme.shape.borderRadius) * 3
          : theme.shape.borderRadius * 3
      }px`,
    },
  },
  graphicContainer: {
    background: theme.palette.primary[100],
    width: '170px',
    minWidth: '170px',
    borderRadius: `${
      typeof theme.shape.borderRadius === 'string'
        ? parseInt(theme.shape.borderRadius) * 3
        : theme.shape.borderRadius * 3
    }px 0px 0px ${
      typeof theme.shape.borderRadius === 'string'
        ? parseInt(theme.shape.borderRadius) * 3
        : theme.shape.borderRadius * 3
    }px`,
    display: 'flex',
    verticalAlign: 'middle',
    position: 'relative',
    padding: theme.spacing(2),
  },
  graphic: {
    width: '100%',
  },
  content: { display: 'flex', flexDirection: 'column', margin: theme.spacing(4), width: '100%' },
  actions: {
    width: '100%',
    display: 'flex',
    marginTop: props.height === 'fixed' ? 'auto' : theme.spacing(4),
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  actionsContainer: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  disabledText: {
    marginRight: theme.spacing(2),
  },
}))

type SubpageCardProps = {
  graphic: string // svg string
  graphicAlt?: string
  title: string
  description: string | React.ReactElement
  onClick: () => void
  buttonText?: string
  disabled?: boolean
  disabledText?: string
  height?: CardHeight
  loading?: boolean
  width?: string
  hideButton?: boolean
}

export default function SubpageCard({
  graphic,
  graphicAlt = '',
  title,
  description,
  onClick,
  disabled = true,
  disabledText = 'Deaktiviert',
  buttonText = 'Öffnen',
  height = 'fixed',
  loading = false,
  width = '600',
  hideButton = false,
}: SubpageCardProps): React.ReactElement {
  const { classes } = useStyles({ height, width })
  return (
    <div className={classes.container}>
      <div className={classes.graphicContainer}>
        {/* <svg>{graphic}</svg> */}
        <img className={classes.graphic} src={graphic} alt={graphicAlt} />
      </div>
      <div className={classes.content}>
        <Typography variant='h2' gutterBottom>
          {title}
        </Typography>
        <Typography variant='body1' gutterBottom style={{ whiteSpace: 'pre-line' }}>
          {description}
        </Typography>
        {!hideButton && (
          <div className={classes.actions}>
            <div className={classes.actionsContainer}>
              {disabled && (
                <Typography variant='disabled' style={{ marginRight: '28px' }}>
                  {disabledText}
                </Typography>
              )}
              <Button
                onClick={onClick}
                // size='small'
                type='normal'
                disabled={disabled}
                loading={loading}
              >
                {buttonText}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
