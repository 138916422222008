import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import { cloneDeep, isEqual } from 'lodash'

import { makeStyles } from 'tss-react/mui'
import { useTheme } from '@mui/material/styles'
import { useDropdownOptionStyles } from '../../../../../components/Dropdown/BaseDropdown'
import G6, {
  IG6GraphEvent,
  IGroup,
  IItemBaseConfig,
  Item,
  Menu,
  ModelConfig,
  Minimap,
  TreeGraph,
  TreeGraphData,
} from '@antv/g6'

import { buildTreeFromAnswers, buildBasePathFromParents, wrapWord } from './mindmapUtils'
import MindmapAutoSaveIndicator from './MindmapAutoSaveIndicator'
import CreateTopicDialog from './CreateTopicDialog'
import DeleteAnswerDialog from './DeleteAnswerDialog'
import CircularLoading from '../../../../../components/Loading/CircularLoading'
import { Tree, LoadingState } from '../../../../../@types/Knowledge/types'
import { Answer, buildAnswerObject } from '../../../../../classes/Knowledge'
import { getUUIDFromString } from '../../../../../utils/stringUtils'
import { useLockingContext } from 'hooks/contexts/locking-context'
import { useAnswers } from 'hooks/contexts/answers-context'
import { LockState } from '../../../../../@types/Locking/types'
import { SaveAnswersUtterances } from 'api/StudioBackend'

// import mindmap styles that we can not set via make styles
// import '../../../../../assets/css/knowledgeMindmapStyles.css'

const NO_TOPIC_NAME = 'Kein Thema'

const useStyles = makeStyles()((theme) => ({
  mindmapContainer: {
    width: '100%',
    minHeight: '70vh',
    height: '100%',
  },
  minimap: {
    position: 'absolute',
    bottom: '0px',
    marginBottom: '20px',
    backgroundColor: '#ffffffb8',
    border: '1px solid #A3B1BF !important',
  },
  minimapViewPort: {
    outline: `${theme.palette.primary.main} solid 1px !important`,
  },
  mindmapContextMenu: {
    padding: '0',
    backgroundColor: 'rgba(255,255,255,1)',
    border: '1px solid #e2e2e2',
    borderRadius: theme.shape.borderRadius,
    minWidth: '150px',
    '& p': {
      color: '#435D6B',
      margin: '15px',
      fontWeight: 'normal',
      '&:hover': {
        color: 'rgba(0,52,84,1)',
        fontWeight: 'bolder',
        cursor: 'pointer',
      },
    },
  },
}))

type GraphContextMenu = typeof Menu.prototype
type MiniMap = typeof Minimap.prototype

type GraphNodeTypes = 'center' | 'topic' | 'answer'

type Topic = {
  topic: string
  creationStep: number
  parent: IItemBaseConfig | null
}

function shouldMindmapRerender(prevProps, props): boolean {
  const should = !(
    isEqual(prevProps.answers, props.answers) &&
    isEqual(prevProps.loading, props.loading) &&
    isEqual(prevProps.lockState, props.lockState)
  )
  return should
}

type MindmapProps = {
  centerNodeName: string // name of the center node
  onEditAnswer: (answer: Answer) => void // callback to trigger editAnswerDialog // TODO: answerId statt answer
  // onAnswersChanged: (changedAnswers: Answer[]) => void // callback for setting changed answers into state of parent component, used when category has changed after drag
  // onChangeAnswersCallback: (
  //   changedAnswers: Answer[],
  //   newUtterances?: SaveAnswersUtterances,
  //   deletedUtterances?: SaveAnswersUtterances,
  //   showNotification?: boolean,
  // ) => void // callback for changing answer(s)
  onCreateAnswerCallback: (topicPath: string) => void
  setUnsavedChanges: (hasUnsavedChanges: boolean) => void
  // loadingState?: LoadingState
  setTopicsWithoutAnswersCallback: (topics: string[]) => void
  collapsedTopics?: string[]
  setCollapsedTopics?: (collapsedTopics: string[]) => void // save which topics are closed - so if the user edits an answer an goes back to the mindmap, it looks the same
}

type MindmapComponentProps = {
  // props from answer context
  answers: Answer[] // all answers to display in the mindmap
  canIEditTopicAndItsAnswers: (topic?: string | undefined) => boolean
  canICreateTopicAndItsAnswers: (topic?: string | undefined) => boolean
  canIDeleteTopicAndItsAnswers: (topic?: string | undefined) => boolean
  canICreateAnswer: (topic: string) => boolean
  canIDeleteAnswer: (answerIdOrAnswer?: string | Answer | undefined) => boolean
  canIEditAnswer: (answerIdOrAnswer?: string | Answer | undefined) => boolean
  onChangedAnswers: (
    changedAnswers: Answer[],
    newUtterances?: SaveAnswersUtterances | undefined,
    deletedUtterances?: SaveAnswersUtterances | undefined,
    showNotification?: boolean | undefined,
  ) => void
  onDeleteAnswersCallback: (answerIdsToDelete: string[], showNotification: boolean) => void // callback for deleting answer(s)
  loading: LoadingState | null
}

const MindmapContent = function MindmapContent({
  answers,
  centerNodeName,
  onEditAnswer,
  onCreateAnswerCallback,
  // onChangeAnswersCallback,
  onDeleteAnswersCallback,
  // loadingState,
  setUnsavedChanges,
  setTopicsWithoutAnswersCallback,
  collapsedTopics: collapsedTopicsProps,
  setCollapsedTopics: setCollapsedTopicsProps,
  // locking context
  lockState,
  // answer context
  canIEditTopicAndItsAnswers,
  canICreateTopicAndItsAnswers,
  canIDeleteTopicAndItsAnswers,
  canICreateAnswer,
  canIDeleteAnswer,
  canIEditAnswer,
  onChangedAnswers,
  loading,
}: MindmapProps & { lockState: LockState } & MindmapComponentProps): React.ReactElement {
  const { classes } = useStyles()
  const theme = useTheme()

  const mindMapContainerRef = useRef<HTMLDivElement>(null)
  const [mounted, setMounted] = useState<boolean>(false)
  // const [loading, setLoading] = useState<LoadingState>(loadingState)
  const [newTopic, setNewTopic] = useState<Topic | null>(null)
  // graph
  const answersInGraphRef = useRef<Answer[]>([])
  // const [answersInGraph, setAnswersInGraph] = useState<Answer[]>([]) // local state of the answers which are displayed in the chart, used to determine difference when answers in props change.
  const [graph, setGraph] = useState<TreeGraph>()
  // save which topics are closed - so if the user edits an answer an goes back to the mindmap, it looks the same
  const [collapsedTopics, setCollapsedTopics] = useState<string[]>(collapsedTopicsProps ?? [])
  // answer & topic deletion
  const [itemToDelete, setItemToDelete] = useState<Item | null>(null)
  const [deletedEmptyTopics, setDeletedEmptyTopics] = useState<string[]>([])

  // ========= VALIDATION ============

  /**
   * Checks if chart has empty topics.
   * If so triggers setUnsavedChanges callback to let parent now.
   * Returns true or false.
   * @param graph
   */
  function checkAndSetUnsavedChanges(graph: TreeGraph): boolean {
    let emptyTopic = false
    const data = graph.findDataById('center/')
    G6.Util.traverseTree(data, function (item: Tree): void {
      if (item.children && item.children.length === 0) emptyTopic = true
    })
    if (emptyTopic) {
      setUnsavedChanges(true)
      return true
    } else {
      setUnsavedChanges(false)
      return false
    }
  }

  // ========= ANSWER MANIPULATION THROUGH GRAPH ============

  /**
   * Recursively iterates over all children of a node and updates topic of answer object, if child is answer.
   * Add each recursion level, the base path is prependend
   * Returns list of all changed answers.
   * @param baseTopicPath
   * @param nodeCfg
   * @param answers list of all answers
   * @param updatedAnswers list of changed answers
   */
  function adaptAnswersWithNewTopicPath(
    baseTopicPath: string,
    nodeCfg: IItemBaseConfig,
    answers: Answer[],
    updatedAnswers: Answer[],
  ): Answer[] {
    if (nodeCfg.id && nodeCfg.id.startsWith('topic/') && nodeCfg.children) {
      // Topic node
      // append current topic to topicPath
      const topic = nodeCfg.model?.name ?? ''
      baseTopicPath += baseTopicPath !== '' ? `/${topic}` : topic // no leading '/' if first part of path
      // iterate over all children
      for (const child of nodeCfg.children) {
        updatedAnswers = adaptAnswersWithNewTopicPath(baseTopicPath, child._cfg, answers, updatedAnswers)
      }
    } else {
      // Answer node
      // get answer objcet from all answers and change topic to new topic path
      if (nodeCfg.id && nodeCfg.id.startsWith('answer/')) {
        const answerId = nodeCfg.id.replace('answer/', '')
        const matchingAnswers = answers.filter((ans) => ans.answerId === answerId)
        if (matchingAnswers.length > 0) {
          const answer = buildAnswerObject(matchingAnswers[0])
          answer.setTopic(baseTopicPath)
          updatedAnswers.push(answer)
        }
      }
    }
    return updatedAnswers
  }

  /**
   * Handles all neccessary changes after a node has been dragged.
   * - restructure tree
   * - update topic paths for all changed nodes
   * - adapt all affected answers
   * - automatically save all all affected answers
   * - set answers back in parent
   * @param graph
   */
  function handleNodeDragChange(
    graph: TreeGraph,
    draggedNode: Item,
    draggedNodeData: TreeGraphData,
    targetNode: Item,
  ): void {
    // check if user is allowed to edit the dragged node and the target topic
    const targetTopic = targetNode?._cfg?.id?.split('/')[1] ?? '*'
    const sourceTopic = draggedNode?._cfg?.parent?._cfg?.id?.split('/')[1]
    const sourceTopicOrAnswerId = draggedNode?._cfg?.id?.split('/')[1]

    const canIEditOrCreateInTargetTopic =
      canIEditTopicAndItsAnswers(targetTopic) && canICreateTopicAndItsAnswers(targetTopic)
    const canIEditDraggedNode = draggedNode?._cfg?.id?.startsWith('answer/')
      ? canIEditAnswer(sourceTopicOrAnswerId)
      : canIEditTopicAndItsAnswers(sourceTopicOrAnswerId)
    if (!canIEditOrCreateInTargetTopic || !canIEditDraggedNode) {
      // missing permissions for either the dragged node or the target topic. return and do nothing
      return
    }

    const draggedNodeCopy = cloneDeep(draggedNode)

    const targetNodeId = targetNode.getID()
    const draggedNodeId = draggedNode.getID()
    graph.removeChild(draggedNodeId) // remove node from "old" position
    // check for unsaved changes
    checkAndSetUnsavedChanges(graph)
    setTimeout(() => {
      const newParent = graph.findById(targetNodeId)
      const newParentCfg = newParent._cfg
      const newParentData = graph.findDataById(targetNodeId)
      // add subtree of dragged node to new parent
      if (newParentData && newParentData.children) newParentData.children.push(draggedNodeData)
      else if (newParentData) newParentData.children = [draggedNodeData]
      graph.layout() // re-structure graph

      // Parse paths back into answer format
      // 1. get base path
      const draggedNodeCfg = draggedNodeCopy._cfg
      let basePath = ''
      if (newParentData !== null && (newParentData?.depth ?? 0) > 0) basePath += `${newParentData.name}`
      const basePathTopic = buildBasePathFromParents(newParentCfg, basePath)

      // 2. loop recursively over children, children children etc. and update all answers with the new topic path
      const updatedAnswers =
        draggedNodeCfg !== null ? adaptAnswersWithNewTopicPath(basePathTopic, draggedNodeCfg, answers, []) : []

      // 3. autosave and callback to set answers
      if (updatedAnswers.length > 0 && lockState === 'canEdit') {
        onChangedAnswers(updatedAnswers, undefined, undefined, false)
      }
    }, 600)
  }

  /**
   * Compares new answers from props with answers in state.
   * Removes answers that no longer exist (have been deleted)
   * TODO: check if we also need to add created answers here or if this is handled elsewhere
   * @param newAnswers
   */
  function syncGraphToAnswers(newAnswers: Answer[]): void {
    const removedAnswers: Answer[] = []
    for (const answer of answersInGraphRef.current) {
      if (!newAnswers.some((ans) => ans.answerId === answer.answerId)) {
        removedAnswers.push(answer)
      }
    }

    // remove answers from graph
    for (const answer of removedAnswers) {
      const graphId = 'answer/' + answer.answerId
      graph?.removeChild(graphId)
    }

    // remove empty topic nodes
    // if (graph) {
    //   const nodes = graph.getNodes()
    //   for (const node of nodes) {
    //     const id = node._cfg?.id
    //     if (id && id.startsWith('topic/') && node._cfg?.children && node._cfg?.children.length === 0) {
    //       graph.removeChild(id)
    //     }
    //   }
    // }

    // update answersInGraph
    answersInGraphRef.current = newAnswers
  }

  // ========= PREPRARE GRAPH ===========
  /**
   * Prepares context menu for nodes.
   * Supported node types are: 'topic', 'center' and 'answer'.
   */
  function buildContextMenu(nodeType: GraphNodeTypes): GraphContextMenu {
    return new G6.Menu({
      getContent(graph: any): string {
        setGraph(graph.currentTarget)

        if (graph?.item?._cfg?.id === 'center/' && !canIEditTopicAndItsAnswers('*')) {
          return ''
        }

        switch (nodeType) {
          case 'topic': {
            // first part of id is node type, rest topic if topic
            const topic = graph?.item?._cfg?.id.split('/')[1]
            return `${
              (graph?.item?._cfg?.id && graph?.item?._cfg?.id.includes('Trigger')) ||
              !canICreateTopicAndItsAnswers(topic)
                ? ''
                : '<p id="createTopic">Thema erstellen</p>'
            }${
              !canICreateAnswer(topic)
                ? ''
                : graph?.item?._cfg?.id && graph?.item?._cfg?.id.includes('Trigger')
                ? '<p id="createAnswer">Trigger erstellen</p>'
                : '<p id="createAnswer">Antwort erstellen</p>'
            }${
              graph?.item?._cfg?.id &&
              (graph?.item?._cfg?.id.includes('Trigger') || !canIDeleteTopicAndItsAnswers(topic))
                ? ''
                : '<p id="delete">Löschen</p>'
            }`
          }
          case 'center':
            return canIEditTopicAndItsAnswers('*') ? `<p id="createTopic">Thema erstellen</p>` : ''
          case 'answer': {
            const answerId = graph?.item?._cfg?.id.split('/')[1]
            return canIDeleteAnswer(answerId) ? `<p id="delete">Löschen</p>` : ''
          }
        }
      },
      shouldBegin: function (event?: IG6GraphEvent): boolean {
        // THIS DETERMINES IF THE MENU SHOULD BE SHOWN
        if (typeof event === 'undefined' || event === null || event.item === null) return false
        const itemConfig = event.item._cfg
        if (itemConfig === null) return false
        // TODO: check if other contextMenus are open and close them
        switch (nodeType) {
          case 'topic': {
            const topic = itemConfig?.id?.split('/')[1]
            return canICreateTopicAndItsAnswers(topic) ||
              canIEditTopicAndItsAnswers(topic) ||
              canIDeleteTopicAndItsAnswers(topic)
              ? !!(itemConfig.id && itemConfig.id.includes('topic/') && lockState === 'canEdit')
              : false
          }
          case 'center': {
            const topic = '*'
            return canICreateTopicAndItsAnswers(topic) ||
              canIEditTopicAndItsAnswers(topic) ||
              canIDeleteTopicAndItsAnswers(topic)
              ? !!(itemConfig.id && itemConfig.id.includes('center/') && lockState === 'canEdit')
              : false
          }
          case 'answer': {
            const answerId = itemConfig?.id?.split('/')[1]
            return canIEditTopicAndItsAnswers(answerId) || canIDeleteAnswer(answerId)
              ? !!(itemConfig.id && itemConfig.id.includes('answer/') && lockState === 'canEdit')
              : false
          }
        }
      },
      handleMenuClick: function (target, item: Item): void {
        switch (nodeType) {
          case 'center':
          case 'topic': {
            // topic node & center node
            if (target.id && target.id === 'createTopic') {
              setNewTopic({ topic: '', creationStep: 1, parent: item._cfg })
            } else if (target.id && target.id === 'createAnswer') {
              const basePath = buildBasePathFromParents(item._cfg, item._cfg?.model?.name as string)
              onCreateAnswerCallback(basePath)
            } else if (nodeType === 'topic' && target.id && target.id === 'delete') {
              // delete is only option for topic node, not for center node
              setItemToDelete(item)
              // setShouldDeleteTopic(true)
            }
            break
          }
          case 'answer': {
            // answer node
            if (target.id && target.id === 'delete') {
              setItemToDelete(item)
              // setShouldDeleteAnswer(true)
            }
          }
        }
      },
      offsetX: 5, // defines offset of top left corner from mouse click
      offsetY: 5,
      itemTypes: ['node'], // types of items that allow menu to show,
      className: classes.mindmapContextMenu,
    })
  }

  /**
   * Defines custom node for the mindmap.
   */
  function registerGraphNode(): void {
    G6.registerNode(
      'tree-node', // name of new node
      {
        // node definition
        drawShape: function drawShape(cfg?: ModelConfig, group?: IGroup): any {
          if (typeof cfg === 'undefined' || typeof group === 'undefined') return

          const id = cfg.id as string
          const rect = group.addShape('rect', {
            attrs: {
              fill: id.includes('center/')
                ? '#4CAE4F'
                : id.includes('topic/')
                ? id.includes('Trigger')
                  ? '#4D75CB'
                  : '#ff9800'
                : '#233b4c', // TODO: use theme as color source
              radius: [12],
            },
            name: 'rect-shape',
            draggable: !id.includes('center/') || !id.includes('Trigger'), // not draggable if center node or trigger
          })
          const text = group.addShape('text', {
            attrs: {
              text: cfg.name,
              x: 12,
              y: 12,
              textAlign: 'left',
              textBaseline: 'middle',
              fill: '#fff',
              cursor: 'pointer',
              // lineHeight: 3,
              fontSize: 14,
              fontFamily: 'Noto Sans,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Helvetica Neue',
            },
            name: 'rect-shape',
            draggable: !id.includes('center/') || !id.includes('Trigger'), // not draggable if center node or trigger
          })
          const bbox = text.getBBox()
          const hasChildren = cfg.children && Array.isArray(cfg.children) && cfg.children.length > 0
          if (hasChildren) {
            group.addShape('marker', {
              attrs: {
                x: bbox.maxX + 12,
                y: 12,
                r: 6,
                symbol: cfg.collapsed ? G6.Marker.expand : G6.Marker.collapse,
                stroke: '#fff',
                lineWidth: 2,
                cursor: 'pointer',
              },
              name: 'collapse-icon',
              draggable: !id.includes('center/') || !id.includes('Trigger'), // not draggable if center node or trigger
            })
          }
          rect.attr({
            x: bbox.minX - 12,
            y: bbox.minY - 12,
            width: bbox.width + (hasChildren ? 44 : 26),
            height: bbox.height + 24,
            cursor: 'pointer',
          })
          return rect
        },
      },
      'single-node', // node shape to extend from
    )
  }

  /**
   * Registers event handler for click, dragstart, etc. events.
   * @param graph
   */
  function registerEventHandlerForGraph(graph: TreeGraph): void {
    // click on node
    graph.on('node:click', function (e: IG6GraphEvent): void {
      const nodeItem = e.item // clicked item
      const nodeId = nodeItem?._cfg?.id
      if (typeof nodeId !== 'undefined') {
        const matchingAnswers = answers.filter((answer) => answer.answerId === nodeId.replace('answer/', ''))
        if (matchingAnswers.length > 0) onEditAnswer(matchingAnswers[0])
      }
    })
    // node drag
    let targetNode // node that inode is dragged onto (target node)
    let startNode // node that is dragged
    graph.on('node:dragstart', function (e: IG6GraphEvent): void {
      targetNode = undefined
      const item = e.item
      if (!item) return
      const id = item.getID()
      const inode = graph.findById(id)
      startNode = inode
    })

    graph.on('node:dragenter', function (e: IG6GraphEvent): void {
      const item = e.item
      if (!item || !startNode) return
      const id = item.getID()
      const inode = graph.findById(id)
      // rules to define which node can be connected to which node
      // NOTE: This if else is ugly, but we have to take this two-step approach and also this order of conditions to prevent unwanted behaviour.
      // maybe there is a neater way, but this works for now.

      // defines that center and trigger node can not be merged into other categories
      if (startNode.getID().includes('center/') || startNode.getID().includes('Trigger')) {
        return
      } else if (startNode.getID().includes('topic/')) {
        if (id.includes('answer/')) return
      } else {
        if (id.includes('center') || id.includes('answer/')) return
        const answerId = getUUIDFromString(startNode.getID())
        if (answerId) {
          // prevent dragging if node is a trigger answer
          const answer = answers.find((answer) => answer.answerId === answerId)
          if (answer?.answerType === 'trigger') return
        }
      }

      // node can be connected according to rules
      // if inode is descendent of dragged node (started node) return
      const data = graph.findById(startNode.getID())
      let isDescendent = false
      G6.Util.traverseTree(data, function (d: Tree): void {
        if (d.id === id) isDescendent = true
      })
      if (isDescendent) return
      targetNode = inode
      graph.setItemState(inode, 'choosen', true)
    })

    graph.on('node:dragleave', function (e: IG6GraphEvent): void {
      const item = e.item
      if (!item) return
      const id = item.getID()
      const inode = graph.findById(id)
      targetNode = undefined
      graph.setItemState(inode, 'choosen', false)
    })

    graph.on('node:dragend', function (e: IG6GraphEvent): void {
      if (lockState !== 'canEdit') {
        console.info('Mindmap is locked by different user')
        return
      }
      if (!targetNode) {
        console.info('Failed. No node close do dragged node.')
        return
      }
      const item = e.item
      if (!item) return
      const id = item.getID()
      const data = graph.findDataById(id)
      graph.setItemState(targetNode, 'choosen', false) // reset choosen state of target node
      let isDescendent = false // if target node is descent of dragged node, return and do nothing
      const targetNodeId = targetNode.getID()
      G6.Util.traverseTree(data, function (d: Tree): void {
        if (d.id === targetNodeId) isDescendent = true
      })
      if (isDescendent) return
      if (data !== null) handleNodeDragChange(graph, item, data, targetNode)
    })

    graph.on('itemcollapsed', (e) => {
      // make sure we have an id of the topic node (the only ones that are collapsible)
      if (e.item && e.item !== null && e.item._cfg && e.item._cfg !== null && e.item._cfg.id) {
        // is collapsing(`true`) and not already in clodesTopics - add to collapsedTopics array
        if (e.collapsed && !collapsedTopics.includes(e.item._cfg.id)) {
          const _closedTopics = [...collapsedTopics]
          _closedTopics.push(e.item._cfg.id)
          setCollapsedTopics(_closedTopics)
        } else if (!e.collapsed && collapsedTopics.includes(e.item._cfg.id)) {
          // is expanding (false) and in clodesTopics - remove from collapsedTopics array
          const _closedTopics = [...collapsedTopics].filter((id) => {
            if (e.item && e.item !== null && e.item._cfg && e.item._cfg !== null && e.item._cfg.id) {
              return id !== e.item._cfg.id
            }
            return true
          })
          setCollapsedTopics(_closedTopics)
        }
      }
    })
  }

  /**
   * Instantiates graph
   * @param plugins
   */
  function buildGraph(plugins: (MiniMap | GraphContextMenu)[]): TreeGraph {
    return new G6.TreeGraph({
      container: 'mindMapContainer',
      width: mindMapContainerRef.current?.scrollWidth ?? 500,
      height: mindMapContainerRef.current?.scrollHeight ?? 500,
      // animate: false, // uncomment to deactivate all animations
      modes: {
        default: [
          {
            type: 'collapse-expand',
            onChange: function onChange(item, collapsed): boolean {
              if (typeof item === 'undefined') return false
              const data = item.get('model')
              if (typeof data === 'undefined') return true
              const icon = item.get('group').find((element: any) => element.get('name') === 'collapse-icon')
              if (collapsed) icon.attr('symbol', G6.Marker.expand)
              else icon.attr('symbol', G6.Marker.collapse)
              data.collapsed = collapsed
              return true
            },
          },
          'drag-canvas',
          {
            type: 'zoom-canvas',
            sensitivity: 1,
            minZoom: 0.25,
            maxZoom: 5,
          },
          {
            type: 'drag-node',
            enableDelegate: true,
          },
        ],
      },
      defaultNode: {
        type: 'tree-node',
        anchorPoints: [
          [0, 0.5],
          [1, 0.5],
        ],
      },
      nodeStateStyles: {
        choosen: {
          fill: '4caf50',
        },
      },
      defaultEdge: {
        type: 'cubic-horizontal',
        style: {
          stroke: '#A3B1BF',
        },
      },
      layout: {
        type: 'compactBox',
        direction: 'H',
        getId: function getId(d: any): string {
          return d.id
        },
        getHeight: function (): number {
          return 16
        },
        getWidth: function (): number {
          return 16
        },
        getVGap: function (): number {
          return 20
        },
        getHGap: function (): number {
          return 150
        },
      },
      plugins: plugins, // configure plugins like minimap for the graph
    })
  }

  /**
   * Prepares graph elements.
   */
  function prepareGraph(answers: Answer[]): void {
    // create and register custom node for mindmap
    registerGraphNode()
    // prepare minimap
    const minimap = new G6.Minimap({
      size: [100, 100],
      className: classes.minimap,
      viewportClassName: classes.minimapViewPort,
      type: 'delegate',
      delegateStyle: {
        fill: theme.palette.primary.main,
      },
    })
    // prepare context menus for clicking on nodes
    const contextMenuTopicNodes = buildContextMenu('topic')
    const contextMenuCenterNode = buildContextMenu('center')
    const contextMenuAnswerNodes = buildContextMenu('answer')

    // prepare tree
    const children = buildTreeFromAnswers(answers, NO_TOPIC_NAME)
    // data for the mindmap
    const data: Tree = { name: centerNodeName, id: 'center/', children }

    // instantiate graph
    if (!document.getElementById('mindMapContainer')?.hasChildNodes()) {
      const graph = buildGraph([minimap, contextMenuTopicNodes, contextMenuCenterNode, contextMenuAnswerNodes])
      // prepare data
      G6.Util.traverseTree(data, function (item: Tree) {
        item.name = wrapWord(item.name, 30)
        // check if topics need to be collapsed
      })

      graph.data(data)
      // initiate based on the data
      graph.render()
      graph.fitView()
      // setAnswersInGraph(answer
      answersInGraphRef.current = answers

      // collapse all topic nodes that were previous collapsed
      collapsedTopics.forEach((id) => {
        const item = graph.findById(id)
        if (item) {
          const itemdata = item.getModel()
          if (itemdata) {
            itemdata.collapsed = true
            graph.updateItem(id, itemdata)
          }
        }

        // Does not work: itemdata is not what was exptected (only id, name and children)
        // const itemdata = graph.findDataById(id)
        // if (itemdata) {
        //   itemdata.collapsed = true
        //   graph.updateItem(id, itemdata)
        // }

        // Does not work: overwrites all data
        // const data = graph.findDataById(id)
        // if (data) {
        //   data.collapsed = true
        //   graph.changeData(data)
        //   graph.updateItem
        // }

        // Does not work: just does nothing
        // graph.setItemState(id, 'collapsed', true)
      })

      // to refresh the layout
      graph.layout()
      // register event handler -> defines what happens if nodes get clicked, dragged etc.
      registerEventHandlerForGraph(graph)
    }
  }

  // always runs this if any changes happen to topics.
  function getAllEmptyTopics(): string[] {
    // G6.Util.traverseTree(graph?.getNodes, function (_item) {})
    const nodes = graph?.getNodes()
    let topics: string[] = []
    if (Array.isArray(nodes) && nodes.length > 0) {
      for (const node of nodes) {
        const isEmpty = node.getOutEdges().length === 0
        if (isEmpty && typeof node?._cfg?.id !== 'undefined' && node?._cfg?.id.includes('topic/')) {
          const id = buildBasePathFromParents(node._cfg, node._cfg?.model?.name as string)
          topics.push(id)
        }
      }
    }
    topics = topics.filter((topic) => {
      return !deletedEmptyTopics.includes(topic)
    })
    // console.log(topics)
    return topics
  }

  // ======== DIALOG CALLBACKS ==========
  /**
   * Handles topic creation form new topic dialog.
   * @param newTopicName
   */
  function onCreateTopic(newTopicName: string): void {
    if (lockState !== 'canEdit') return

    const parentId = newTopic?.parent?.id
    if (typeof graph === 'undefined' || typeof parentId === 'undefined') return
    // this checks if the topic already exists in the graph
    const hasTopic = graph.findDataById(`topic/${newTopicName}`)
    if (hasTopic === null) {
      // this just checks if the parent node exists and then adds the new topic as child
      const newParentData = graph.findDataById(parentId)
      if (newParentData === null) return
      if (newParentData.children) {
        newParentData.children.push({
          name: newTopicName,
          id: `topic/${newTopicName}`,
          children: [],
        })
      } else {
        newParentData.children = [{ name: newTopicName, id: `topic/${newTopicName}`, children: [] }]
      }
      // re-build graph
      graph.layout()
      // Update topicsWithoutAnswers
      setTopicsWithoutAnswersCallback(getAllEmptyTopics())
      // reset new topic and with that close dialog
      setNewTopic(null)
      checkAndSetUnsavedChanges(graph)
    } else {
      setNewTopic(null)
    }
  }

  /**
   * On close callback of create topic dialog.
   */
  function onCreateTopicClose(): void {
    setNewTopic(null)
  }

  /**
   * Deletes answers.
   * Finds deleted nodes and uses them to find ids of answers that should be deleted.
   * Deletes answers via the onDeleteCallback.
   */
  function onDeleteNode(): void {
    if (lockState !== 'canEdit') return

    if (itemToDelete !== null && itemToDelete._cfg && itemToDelete._cfg.model) {
      const type = itemToDelete._cfg.id
      if (typeof type === 'undefined') return

      // get answer id(s) of answer(s) that should be deleted
      const answerIds: string[] = []
      const graphNodeIds: string[] = []
      G6.Util.traverseTree(itemToDelete._cfg.model, function (_item) {
        graphNodeIds.push(_item.id)
        if (_item.id.includes('answer/') && (typeof _item.children === 'undefined' || _item.children.length === 0)) {
          // item is answer and has no children
          const id = _item.id.replace('answer/', '')
          answerIds.push(id)
        }
      })

      // itemsToDeleteRef.current = {
      //   itemIds: graphNodeIds,
      //   type: type.includes('answer/') ? 'answer' : 'topic',
      // }
      // FIXME
      // Check if we delete a topic node without any children, because then we only have to remove the node and do not need to delete any answers
      if (answerIds.length > 0) {
        // if we also need to delete answers, then the mindmap will get a new build, therefore also removing the topic node
        onDeleteAnswersCallback(answerIds, false) // don't show notification unless it fails
        // check if item is topic node and also delete it since the user clicked delete topic with all included answers
        if (
          typeof graph !== 'undefined' &&
          typeof itemToDelete._cfg.id !== 'undefined' &&
          itemToDelete._cfg.id.includes('topic/')
        ) {
          graph.removeChild(itemToDelete._cfg.id)
          const _id = itemToDelete._cfg.id.replace('topic/', '')
          const _deletedEmptyTopics = deletedEmptyTopics
          _deletedEmptyTopics.push(_id)
          setDeletedEmptyTopics(_deletedEmptyTopics)
        }
      } else {
        if (typeof graph !== 'undefined' && typeof itemToDelete._cfg.id !== 'undefined') {
          graph.removeChild(itemToDelete._cfg.id)
          const _id = itemToDelete._cfg.id.replace('topic/', '')
          const _deletedEmptyTopics = deletedEmptyTopics
          _deletedEmptyTopics.push(_id)
          setDeletedEmptyTopics(_deletedEmptyTopics)
        }
      }
      // Update topicsWithoutAnswers
      setTopicsWithoutAnswersCallback(getAllEmptyTopics())
      // reset item to delete (to close dialog)
      setItemToDelete(null)
    }
  }

  /**
   * On close callback of delete node dialog.
   */
  function onDeleteNodeClose(): void {
    // itemsToDeleteRef.current = null
    setItemToDelete(null)
  }

  useEffect(
    function () {
      // if (!loading || loading === 'loading') setLoading(undefined)
      setMounted(true)

      if (typeof answers === 'undefined' || typeof graph === 'undefined') {
        // init and build graph
        if (!isEqual(answersInGraphRef.current, answers)) {
          prepareGraph(answers)
        }
      } else {
        // remove or add answers to existing graph
        syncGraphToAnswers(answers)
      }
      return (): void => {
        setMounted(false)
      }
    },
    [answers, answersInGraphRef],
  )

  useEffect(
    function () {
      if (loading === 'deletingSuccess' || loading === 'deletingError') {
        // reset items to delete once deleting process is complete (successful or unsuccessful)
        // itemsToDeleteRef.current = null
        setItemToDelete(null)
      }
    },
    [loading],
  )

  useEffect(() => {
    if (typeof setCollapsedTopicsProps !== 'undefined') setCollapsedTopicsProps(collapsedTopics)
  }, [collapsedTopics])

  return (
    <div style={{ height: 'calc(100%)', position: 'relative' }}>
      {loading === 'loading' ? (
        <CircularLoading text={'Wissen wird geladen. Bitte warten...'} />
      ) : (
        <>
          <div className={classes.mindmapContainer} id='mindMapContainer' ref={mindMapContainerRef} />
          {typeof loading !== 'undefined' && <MindmapAutoSaveIndicator saving={loading} />}
          {/* Dialogs */}
          {typeof graph !== 'undefined' && newTopic !== null ? (
            <CreateTopicDialog onClose={onCreateTopicClose} onCreate={onCreateTopic} graph={graph} />
          ) : null}
          {itemToDelete !== null ? (
            // && itemsToDeleteRef !== null
            <DeleteAnswerDialog
              type={
                itemToDelete._cfg?.id?.includes('answer/') ? 'answer' : 'topic'
                // itemsToDeleteRef.current?.type
                // 'answer'
              }
              onClose={onDeleteNodeClose}
              onDelete={onDeleteNode}
            />
          ) : null}
        </>
      )}
    </div>
  )
}

// Wrapping function for the mindmap. Hook update force rerender of the using component.
// we do not want to re-render the mindmap everytime we perform a lock request
export default function Mindmap(props: MindmapProps): React.ReactElement {
  const { lockState } = useLockingContext()
  const {
    canIEditTopicAndItsAnswers,
    canICreateTopicAndItsAnswers,
    canIDeleteTopicAndItsAnswers,
    canICreateAnswer,
    canIDeleteAnswer,
    canIEditAnswer,
    onChangedAnswers,
    deleteAnswers,
    loading,
    answersArrayPrimaryLang,
  } = useAnswers()
  return (
    <MindmapContent
      key='knowledge-mindmap'
      {...props}
      answers={answersArrayPrimaryLang}
      lockState={lockState}
      canICreateAnswer={canICreateAnswer}
      canICreateTopicAndItsAnswers={canICreateTopicAndItsAnswers}
      canIDeleteAnswer={canIDeleteAnswer}
      canIDeleteTopicAndItsAnswers={canIDeleteTopicAndItsAnswers}
      canIEditAnswer={canIEditAnswer}
      onChangedAnswers={onChangedAnswers}
      canIEditTopicAndItsAnswers={canIEditTopicAndItsAnswers}
      onDeleteAnswersCallback={deleteAnswers}
      loading={loading}
    />
  )
}
