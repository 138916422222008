import React from 'react'
import { useDrop } from 'react-dnd'

import { CardObject } from '../CardList'

type DraggableGridItemProps = {
  index: number // index of the grid item in the grid layout
  card: CardObject // card object that is display in grid item
  item: React.ReactElement // React element that should be renderd inside draggable grid item
  acceptItemType: string // item type that allows drop
  allowedDropEffect: 'any' | 'copy' | 'move' // allowed drop effects coming from react-dnd; can be 'any', 'copy' and 'move'
}

export default function DraggableGridItem({
  index,
  card,
  item,
  acceptItemType,
  allowedDropEffect,
}: DraggableGridItemProps): React.ReactElement {
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: acceptItemType,
    drop: () => ({
      id: card.id,
      index, // returns index of GridItem that was target of drop
      allowedDropEffect,
      dropEffect: 'move',
    }),
    // disable any type because react-dnd uses any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    collect: (monitor: any) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  //   const isActive = canDrop && isOver

  return <div ref={drop}>{item}</div>
}
