import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'

// @mui/material components
import { TextField, IconButton, FormControlLabel, Checkbox, Typography, Tooltip } from '@mui/material'
import { Delete } from '@mui/icons-material'

// custom components
import { Separator } from '../Separator'

import { Chart, Port } from '../../../../../@types/Flowchart/types'

const useStyles = makeStyles()({
  selectedDialogContainer: {},
  title: {},
  stepbackContainer: { display: 'flex', width: '100%' },
  marginTopMedium: { marginTop: '15px' },
  answerContainer: { padding: '10px' },
  answerText: {
    display: 'inline-block',
    margin: 0,
    fontSize: '1rem',
  },
  answerRemoveTooltip: { display: 'inline-block', textAlign: 'right' },
  answerRemoveButton: { padding: '4px', float: 'right' },
  newAnswerTextfield: { width: '100%' },
  icon: { fontSize: '1rem' },
})

type SQBMTextsProps = {
  chart: Chart
  setStateCallback: (chart: Chart, portChange: boolean) => void
}

export default function SQBMTexts({ chart, setStateCallback }: SQBMTextsProps): React.ReactElement {
  const { classes } = useStyles()
  const [questionText, setQuestionText] = useState<string>('')
  const [newAnswerText, setNewAnswerText] = useState<string>('')

  /**
   * Handles textfield value change and updates chart
   */
  function onTextChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setQuestionText(event.target.value)
  }

  function onTextBlur(event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>): void {
    if (typeof chart.selected?.id === 'undefined') return

    chart.nodes[chart.selected.id].properties.text = questionText
    setStateCallback(chart, false)
  }

  /**
   * Callback function for adding new answer option to question message
   * TODO current maximum of 4 answers is allowed, for more answers port positioning must be changed in the framework,
   *      or node must increase size, as ports are currently only placed at the right side and coordinate calculation
   *      for new ports relies on node height which currently only works for max. 4 ports
   */
  function onAnswerAdd(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault()

    if (typeof chart.selected?.id === 'undefined') return

    const selectedNode = chart.nodes[chart.selected.id]

    // add port
    const newNumberPorts = Object.keys(selectedNode.ports).length + 1

    // reject if more than 4 ports
    // TODO: use a dialog as alert method so it also can be connected to translations
    if (Object.keys(selectedNode.ports).length + 1 > 5) {
      alert('Zu viele Anschlüsse!\nAktuell werden maximimal 4 Anschlüsse unterstützt.')
      setNewAnswerText('')
      return
    }

    // make sure new key does not already exist, can happen if previously added port has been removed
    let uniquifier = 0
    let newPortKey
    do {
      newPortKey = `port${newNumberPorts + uniquifier}`
      uniquifier++
    } while (Object.keys(selectedNode.ports).includes(newPortKey))

    const newPort: Port = {
      id: newPortKey,
      type: 'right',
      properties: {
        type: 'outgoing',
        name: newAnswerText,
      },
    }

    // add port and answer option
    selectedNode.ports[newPortKey] = newPort
    selectedNode.properties.answers = selectedNode.properties.answers || []
    selectedNode.properties.answers.push(newAnswerText)

    chart.nodes[chart.selected.id] = selectedNode
    setStateCallback(chart, true)
  }

  /**
   * Called if answer option is removed via button click in the question message
   * Removes the corresponding port from the node
   */
  function onAnswerRemove(index: number, answer: string): void {
    if (typeof chart.selected?.id === 'undefined') return

    const selectedNode = chart.nodes[chart.selected.id]

    // find port to remove
    let portKey
    for (const key of Object.keys(selectedNode.ports)) {
      if (selectedNode.ports[key].properties?.name === answer) {
        portKey = key
        break
      }
    }

    for (const id of Object.keys(chart.links)) {
      if (chart.links[id].from.nodeId === selectedNode.id && chart.links[id].from.portId === portKey) {
        delete chart.links[id]
      }
    }

    // remove port
    delete selectedNode.ports[portKey]
    chart.nodes[chart.selected.id] = selectedNode

    // remove answer option
    let answers = chart.nodes[chart.selected.id].properties.answers
    if (typeof answers !== 'undefined') answers = answers.filter((answ) => answ !== answer)

    chart.nodes[chart.selected.id].properties.answers = answers

    setStateCallback(chart, true)
  }

  /**
   * Handles change in answer textfield
   */
  function onAnswerTextChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setNewAnswerText(event.target.value)
  }

  /**
   * Handles change in checkbox that sets stepback property in node
   */
  function onStepbackToggle(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void {
    if (typeof chart.selected?.id === 'undefined') return
    chart.nodes[chart.selected.id].properties.stepbackAllowed = checked
  }

  useEffect(
    function () {
      if (typeof chart.selected.id === 'undefined') return
      const text = chart.nodes[chart.selected.id].properties.text || ''
      setQuestionText(text)
    },
    [chart],
  )

  return (
    <div className={classes.selectedDialogContainer}>
      <React.Fragment>
        <div className={classes.stepbackContainer}>
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  typeof chart.selected?.id !== 'undefined'
                    ? chart.nodes[chart.selected.id].properties.stepbackAllowed
                    : true
                }
                onChange={onStepbackToggle}
                name='stepback'
                style={{ color: '#0b2639' }}
              />
            }
            label='Korrigieren des vorherigen Schrittes erlauben'
            labelPlacement='start'
            style={{ marginLeft: 'auto' }}
          />
        </div>
        <div>
          <TextField
            id='question-textinput'
            label='Frage'
            multiline
            fullWidth
            maxRows={5}
            value={questionText}
            onChange={onTextChange}
            onBlur={onTextBlur}
            margin='normal'
          />
        </div>
        <Separator />
        <Typography>Antwortmöglichkeiten</Typography>

        {/* Answers */}
        {typeof chart.selected?.id !== 'undefined' && (
          <div>
            {/* IDEA: sort answer options via drag&drop and order ports acordingly */}
            {(chart.nodes[chart.selected.id].properties.answers || []).map((answer, idx) => (
              <div key={`answer-${idx}`} className={classes.answerContainer}>
                <div className={classes.answerText}>{answer}</div>
                <Tooltip className={classes.answerRemoveTooltip} title='Antwortmöglichkeit entfernen' placement='top'>
                  <IconButton
                    className={classes.answerRemoveButton}
                    aria-label='Delete'
                    onClick={(): void => onAnswerRemove(idx, answer)}
                  >
                    <Delete className={classes.icon} />
                  </IconButton>
                </Tooltip>
              </div>
            ))}
          </div>
        )}
        {/* Add answer */}
        <div>
          <form onSubmit={onAnswerAdd}>
            <TextField
              className={classes.newAnswerTextfield}
              id='new-answer-textfield'
              label='Antwortmöglichkeit hinzufügen'
              placeholder='Neue Antwortmöglichkeit'
              value={newAnswerText}
              InputProps={{
                autoComplete: 'off',
                classes: {},
              }}
              onChange={onAnswerTextChange}
              margin='none'
            />
          </form>
        </div>
      </React.Fragment>
    </div>
  )
}
