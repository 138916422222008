import React from 'react'
import { ErrorCode } from './errorCodes'

// Maps Actions to Payloads - Standard does not need customization
type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key
      }
    : {
        type: Key
        payload: M[Key]
      }
}

// possible actions
enum Types {
  SetError = 'SET_ERROR',
  RemoveError = 'REMOVE_ERROR',
}

// payloads for each action
type ErrorContextPayload = {
  [Types.SetError]: {
    errorCode: ErrorCode
    errorMsg: string
    buttonText: string
    callbackFn: (props: any) => void
    errorTitle?: string
  }
  [Types.RemoveError]: {
    errorCode: ErrorCode
  }
}

type Action = ActionMap<ErrorContextPayload>[keyof ActionMap<ErrorContextPayload>]
type State =
  | {
      [errorCode in ErrorCode]: {
        errorCode: ErrorCode
        errorMsg: string
        buttonText: string
        callbackFn: (props: any) => void
        errorTitle?: string
      }
    }
  | Record<string, never>

type Dispatch = (action: Action) => void
type ErrorProviderProps = { children: React.ReactNode }

/**
 * ----- CONTEXT -----
 * Error Context is a context used for storing errors.
 *
 *
 * To access this context:
 * import useErrorContext from '<path>/hooks/contexts/error-context.tsx'
 *
 * // in a function
 * const {errors, setError, resetError} = useStudioContext()
 *
 * setError("errorCode1", "This is a message to display!", () => console.log('callback function for action button'))
 */

const ErrorContext = React.createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined)

function errorReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SET_ERROR': {
      return {
        ...state,
        [action.payload.errorCode]: {
          errorCode: action.payload.errorCode,
          errorTitle: action.payload.errorTitle,
          errorMsg: action.payload.errorMsg,
          buttonText: action.payload.buttonText,
          callbackFn: action.payload.callbackFn,
        },
      } as State
    }
    case 'REMOVE_ERROR': {
      const newState = { ...state }
      delete newState[action.payload.errorCode]
      return newState
    }
    default: {
      throw new Error(`Unsupported action type: ${action.type}`)
    }
  }
}

/**
 * Hook for accessing and manipulating the ErrorContext state.
 */
function useErrorContext(): {
  errors: State
  setError: (
    errorCode: ErrorCode,
    errorMsg: string,
    buttonText: string,
    callbackFn: () => void,
    errorTitle?: string | undefined,
  ) => void
  resetError: (errorCode: any) => void
} {
  const context = React.useContext(ErrorContext)
  if (!context) throw new Error('useError must be used within an ErrorProvider')
  const { state, dispatch } = context

  const setError = React.useCallback(
    (errorCode: ErrorCode, errorMsg: string, buttonText: string, callbackFn: () => void, errorTitle?: string) => {
      dispatch({
        type: Types.SetError,
        payload: {
          errorCode,
          errorMsg,
          errorTitle,
          buttonText,
          callbackFn: (): void => {
            dispatch({ type: Types.RemoveError, payload: { errorCode } })
            callbackFn()
          },
        },
      })
    },
    [dispatch],
  )

  const resetError = React.useCallback(
    (errorCode) => dispatch({ type: Types.RemoveError, payload: { errorCode } }),
    [dispatch],
  )

  return {
    errors: state,
    setError,
    resetError,
  }
}

function ErrorContextProvider({ children }: ErrorProviderProps): JSX.Element {
  // const [error, setError] = React.useState()
  const [state, dispatch] = React.useReducer(errorReducer, {})
  const value = { state, dispatch }
  return <ErrorContext.Provider value={value}>{children}</ErrorContext.Provider>
}

export { ErrorContextProvider, useErrorContext }
