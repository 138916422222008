import React, { memo, useEffect, useState } from 'react'
import { cloneDeep, isEqual } from 'lodash'

// @material-ui
import { makeStyles } from 'tss-react/mui'

import {
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography,
  ListItemIcon,
  IconButton,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
// Custom Components
import {
  SmartCardInputContainer,
  SmartCardInputOptions,
  inputOptionsToLabel,
} from 'components/SmartCard/smartCardModules'
import Button from 'components/Buttons/Button'
import CustomIconButton from 'components/Buttons/CustomIconButton'
import SelectDropdown, { Option, ActionMeta } from 'components/Dropdown/SelectDropdown'
import CustomContentTooltip from 'components/Tooltips/CustomContentTooltip'
import VariablesAutosuggestSelect from '../../../Variables/VariablesAutosuggestSelect'
// Types
import { Chart } from '../../../../../../../@types/Flowchart/types'
import { Card, IContainer, InputTypeEnum } from '../../../../../../../@types/SmartCards/types'
import AcChoiceValueInput from '../../components/ACChoiceValueInput'
import { findValuesInputFields, removeVariableFromNode } from 'utils/chartUtils'

const useStyles = makeStyles()((theme) => ({
  editor: {
    height: '100%',
    overflowY: 'auto',
  },
  editorContainer: {
    borderRadius:
      typeof theme.shape.borderRadius === 'number' ? theme.shape.borderRadius * 2 : theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[500]}`,
    padding: theme.spacing(1),
    height: '100%',
    // overflowY: 'auto',
  },
  editorOverflow: {
    height: '100%',
    overflowY: 'auto',
    padding: theme.spacing(1),
  },
  editorHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  editorContentGeneral: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    width: '100%',
    marginTop: theme.spacing(1),
    marginLeft: '0px',
    // alignItems: 'center'
  },
  inputsList: {
    flex: '1 1 auto',
    overflowY: 'auto',
    paddingRight: theme.spacing(1),
  },
  inputWithInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  inputInfoIcon: {
    marginLeft: theme.spacing(1),
    height: '20px',
    color: theme.palette.grey[700],
  },
  inputContainer: {
    height: '56px',
    borderRadius:
      typeof theme.shape.borderRadius === 'number' ? theme.shape.borderRadius * 2 : theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[500]}`,
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  inputName: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  inputActions: {
    marginLeft: 'auto',
  },
  inputButton: {
    marginRight: theme.spacing(1),
  },
  menuContainer: {
    width: '50%',
    marginTop: theme.spacing(2),
  },
  addButton: {
    width: '300px',
  },
  menuIcon: {
    color: theme.palette.primary.main,
  },
  iconButtonAccept: {
    marginLeft: theme.spacing(1),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.light,
    },
    '&:disabled': {
      backgroundColor: theme.palette.grey[400],
      color: theme.palette.common.white,
    },
  },
  iconButtonDelete: {
    marginLeft: theme.spacing(1),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
    '&:disabled': {
      backgroundColor: theme.palette.grey[400],
      color: theme.palette.common.white,
    },
  },
  icon: { fontSize: '1.2rem' },
  nameField: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    width: '80 %',
    borderRadius:
      typeof theme.shape.borderRadius === 'number' ? theme.shape.borderRadius * 2 : theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[500]}`,
    padding: theme.spacing(1),
  },
  accordionRoot: {
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
  },
  accordionSummaryRoot: {
    padding: 0,
  },
  accordionDetailsRoot: {
    padding: 0,
  },
}))

type CardInputProps = {
  card: Card
  chart: Chart
  setCardCallback: (card: Card) => void
  setChartCallback: (chart: Chart) => void
  setBlockNextButtonCallback: (block: boolean) => void
}

// TODO: use a tmp state for all text inputs for onChangen and use onBlur to update card and inputs

export default memo(function CardInput({
  chart: origChart,
  card: origCard,
  setCardCallback,
  setChartCallback,
  setBlockNextButtonCallback,
}: CardInputProps): React.ReactElement {
  const { classes } = useStyles()
  const [card, _setCard] = useState<Card>(origCard)
  const [inputsContainer, setInputsContainer] = useState<IContainer>(loadInputContainer(origCard))
  const [inputs, setInputs] = useState<any>()
  // Editing inputs selection
  const [selectedInput, setSelectedInput] = useState<string>()
  const [stage, setStage] = useState<'overview' | 'edit' | 'template' | 'create'>('overview') // create is prestep to edit - setting the input id
  // Menu handling
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)
  const [menuWidth, setMenuWidth] = useState(0)

  function setCard(card: Card): void {
    _setCard(card)
    setCardCallback(card)
  }

  /**
   * Handles input deletion.
   * Removes input from card and inputs object.
   * Also removes variables from node that were associated with the input.
   */
  function onDeleteInput(inputName: string): void {
    const tmpInputs = { ...inputs }
    delete tmpInputs[inputName]

    // check if input has variable set and remove that variable from node
    const selectedId = origChart.selected.id
    if (selectedId) {
      let chart = origChart
      for (const varId of Object.keys(origChart.nodes[selectedId].properties.variables ?? {})) {
        // check if that variable is used for the current field
        if (
          origChart.variables[varId].usage[selectedId] &&
          origChart.variables[varId].usage[selectedId].ac?.acFieldIds &&
          Object.keys((origChart.variables[varId].usage[selectedId].ac ?? {}).acFieldIds ?? []).includes(inputName)
        ) {
          // this fields value is set in this variable
          // remove variable from node
          chart = removeVariableFromNode(chart, 'set', varId, selectedId)
        }
      }
      setChartCallback(chart)
    }

    setInputs(tmpInputs)
  }

  useEffect(() => {
    setInputs(loadInputs(origCard))
  }, [])

  // NOTE: updates card and inputsContainer
  // Build InputsContainer from existing inputsContainer and inputs and update id in the card
  useEffect(() => {
    if (inputs) {
      const tmpInputsContainer = { ...inputsContainer }
      const inputKeys = Object.keys(inputs)
      tmpInputsContainer.items = []
      inputKeys.forEach((key) => {
        if (typeof tmpInputsContainer.items === 'undefined') tmpInputsContainer.items = []
        tmpInputsContainer.items.push(inputs[key])
      })
      setInputsContainer(tmpInputsContainer)
      // Build Card with new container
      const inputIndex = card.data.body?.findIndex((block) => block.id === SmartCardInputContainer.id)
      if (typeof inputIndex !== 'undefined' && inputIndex >= 0 && card.data && card.data.body) {
        // Has input Container
        const tmpCard = cloneDeep(card)
        tmpCard.data.body?.splice(inputIndex, 1, tmpInputsContainer)
        setCard(tmpCard)
      } else {
        const tmpCard = cloneDeep(card)
        // FIXME: TODO: check if title and content are set to define where to correctly place the new input container
        tmpCard.data.body?.splice(2, 0, tmpInputsContainer) // TODO: this means title and content needs to be set
        setCard(tmpCard)
      }
    }
  }, [inputs])

  // actions depending on stage like updating an input
  useEffect(() => {
    if (stage === 'create') {
      setSelectedInput('')
      setBlockNextButtonCallback(true)
    }
    // only update card (preview) if we are in the overview
    if (stage === 'overview') {
      // for live preview
      setCardCallback(card)
      setBlockNextButtonCallback(false)
    }
  }, [stage])

  // useEffect(() => {
  //   // defines when the next button of the CardBuilder is disabled
  //   if (typeof selectedInput === 'undefined') {
  //     setBlockNextButtonCallback(true)
  //   } else {
  //     setBlockNextButtonCallback(false)
  //   }
  // }, [selectedInput])

  // useEffect(() => {
  //   // NOTE: for live preview
  //   setCardCallback(card)
  // }, [card])

  // stateless - find and return the input container - if not found return the template
  function loadInputContainer(inputCard): IContainer {
    if (inputCard.data.body) {
      const inputIndex = inputCard.data.body?.findIndex((block, index) => block.id === SmartCardInputContainer.id)
      if (typeof inputIndex !== 'undefined' && inputIndex >= 0) {
        // Has input Container
        const inputContainer = inputCard.data.body[inputIndex] as IContainer
        return inputContainer
      } else {
        return SmartCardInputContainer as IContainer
      }
    }
    return SmartCardInputContainer as IContainer
  }

  // stateless - find and return the input container - if not found return {}
  function loadInputs(inputCard): any {
    if (inputCard.data.body) {
      const inputIndex = inputCard.data.body?.findIndex((block, index) => block.id === SmartCardInputContainer.id)
      if (typeof inputIndex !== 'undefined' && inputIndex >= 0) {
        // Has input Container
        const inputContainer = inputCard.data.body[inputIndex] as IContainer
        if (inputContainer.items && inputContainer.items.length > 0) {
          const tmpInputs = {}
          inputContainer.items.forEach((input, index) => {
            tmpInputs[input && input.id ? input.id : `Eingabe ${index + 1}`] = input
          })
          return tmpInputs
        } else {
          return {}
        }
      } else {
        return {}
      }
    }
    return {}
  }

  // TODO: Collect all existing Input fields - jsonpath-plus

  // TODO: Menu for choosing templates

  // TODO: Menus for different types of inputs

  // TODO: Manage Datachecks

  function renderInputs(): React.ReactElement {
    return (
      <div className={classes.inputsList}>
        {inputs &&
          Object.keys(inputs).map((input) => {
            return (
              <div className={classes.inputContainer} key={inputs[input].id}>
                {/* TODO: name etc. */}
                <Typography className={classes.inputName}>{inputs[input].id}</Typography>
                <div className={classes.inputActions}>
                  <IconButton onClick={(): void => onDeleteInput(input)} className={classes.inputButton}>
                    <i className='ri-delete-bin-4-line'></i>
                  </IconButton>
                  <IconButton
                    onClick={(): void => {
                      setSelectedInput(input)
                      setStage('edit')
                    }}
                    className={classes.inputButton}
                  >
                    <i className='ri-pencil-line'></i>
                  </IconButton>
                </div>
              </div>
            )
          })}
      </div>
    )
  }

  /** MENU HANDLING **/

  function handleClick(event): void {
    setMenuWidth(event.currentTarget.clientWidth)
    setMenuAnchorEl(event.currentTarget)
  }
  function handleClose(): void {
    setMenuAnchorEl(null)
  }

  function renderInputMenu(): React.ReactElement {
    return (
      <div className={classes.menuContainer}>
        <Button
          aria-controls='menu'
          aria-haspopup='true'
          onClick={handleClick}
          icon={'add-line'}
          iconType='remix'
          className={classes.addButton}
          variant='secondary'
        >
          Eingabe hinzufügen
        </Button>
        <Menu
          id='menu'
          anchorEl={menuAnchorEl}
          keepMounted
          open={Boolean(menuAnchorEl)}
          onClose={handleClose}
          elevation={2}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          PaperProps={{
            style: {
              width: menuWidth,
            },
          }}
        >
          <MenuItem
            onClick={(): void => {
              setStage('create')
              setMenuAnchorEl(null)
            }}
          >
            <ListItemIcon className={classes.menuIcon}>
              <i className='ri-pencil-line'></i>
            </ListItemIcon>
            <ListItemText primary='Feld selbst definieren' />
          </MenuItem>
          <MenuItem disabled onClick={(): void => console.log('Vorlage')}>
            <ListItemIcon className={classes.menuIcon}>
              <i className='ri-drag-drop-line'></i>
            </ListItemIcon>
            <ListItemText primary='Vorlage auswählen' />
          </MenuItem>
        </Menu>
      </div>
    )
  }

  /** INPUT EDITOR */
  function renderInputEditor(): React.ReactElement {
    const typeOptions = SmartCardInputOptions.map((option) => {
      return {
        label: inputOptionsToLabel(option),
        value: option,
      }
    })

    return (
      <div className={classes.editorContainer}>
        <div className={classes.editorOverflow}>
          {selectedInput && (
            <>
              <div className={classes.editorHeader}>
                <Typography variant='h4' style={{ marginRight: 'auto' }}>
                  {inputs[selectedInput].id}
                </Typography>
                <CustomIconButton
                  type='decline'
                  onClick={(): void => {
                    const tmpInputs = inputs
                    delete tmpInputs[selectedInput]
                    setSelectedInput(undefined)
                    setStage('overview')
                    setInputs(tmpInputs)
                  }}
                  tooltip='Eingabe löschen'
                  size='small'
                />
                <CustomIconButton
                  type='accept'
                  onClick={(): void => {
                    setSelectedInput(undefined)
                    setStage('overview')
                  }}
                  // TODO: check if we can save or if props are missing / not valid - exmp. isRequired and error Message
                  tooltip='Eingabe speichern'
                  size='small'
                />
              </div>
              <Divider />
              <Accordion disableGutters defaultExpanded classes={{ root: classes.accordionRoot }}>
                <AccordionSummary
                  classes={{ root: classes.accordionSummaryRoot }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='general-content'
                  id='general-header'
                >
                  <Typography>Allgemein</Typography>
                </AccordionSummary>
                <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
                  <Grid container className={classes.editorContentGeneral} spacing={3}>
                    <Grid item md={6} sm={6} xs={12} className={classes.inputWithInfo}>
                      <SelectDropdown
                        onChange={(newValue: Option, action: ActionMeta<Option>): void => {
                          const tmpInputs = { ...inputs }
                          // type must be a valid AdaptiveCard Input type
                          // for addititional properties for our custom input types (e.g. for datacheck), we introduce the dataType property
                          // that give us more options in total. Note that we map them to valid AdaptiveCard Inputs
                          tmpInputs[selectedInput].type = InputTypeEnum[newValue.value]
                          tmpInputs[selectedInput].dataType = newValue.value

                          // add / remove not needed values
                          // ChoiceSets
                          if (
                            typeof tmpInputs[selectedInput].choices !== 'undefined' &&
                            newValue.value !== InputTypeEnum['Input.ChoiceSet']
                          ) {
                            delete tmpInputs[selectedInput].choices
                          }
                          if (
                            typeof tmpInputs[selectedInput].choices === 'undefined' &&
                            newValue.value === InputTypeEnum['Input.ChoiceSet']
                          ) {
                            tmpInputs[selectedInput].choices = []
                          }

                          setInputs(tmpInputs)
                        }}
                        selected={inputs[selectedInput].dataType ?? inputs[selectedInput].type}
                        options={typeOptions}
                        maxDisplayOptions={6}
                        width={'100%'}
                        height={'56px'}
                      />
                      <CustomContentTooltip
                        content={
                          <Typography>
                            Definiert den Typen der Eingabe. Zum Beispiel können bei Zahlen-Eingaben nur Zahlen von den
                            Nutzer*innen eingetippt werden und bei Datums-Eingaben nur ein Datum ausgewählt oder
                            eingetippt werden.
                          </Typography>
                        }
                        elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
                      />
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} className={classes.inputWithInfo}>
                      <TextField
                        label='Eingaben Label'
                        value={inputs[selectedInput].label ?? ''}
                        onChange={(event): void => {
                          const tmpInputs = { ...inputs }
                          tmpInputs[selectedInput].label = event.target.value
                          setInputs(tmpInputs)
                        }}
                        fullWidth
                      />
                      <CustomContentTooltip
                        content={
                          <Typography>
                            Wird über der Eingabe angezeigt und beschreibt die Eingabe. Wir empfehlen immer ein Label zu
                            setzen.
                          </Typography>
                        }
                        elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
                      />
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} className={classes.inputWithInfo}>
                      <ToggleButtonGroup
                        fullWidth
                        size='large'
                        value={inputs[selectedInput].isRequired}
                        exclusive
                        onChange={(event, newValue): void => {
                          const tmpInputs = { ...inputs }
                          tmpInputs[selectedInput].isRequired = !inputs[selectedInput].isRequired
                          setInputs(tmpInputs)
                        }}
                      >
                        <ToggleButton value={false}>Optional</ToggleButton>
                        <ToggleButton value={true}>Erforderlich</ToggleButton>
                      </ToggleButtonGroup>
                      <CustomContentTooltip
                        content={
                          <Typography>
                            Definiert, ob eine Eingabe getätigt werden muss, um fortfahren zu können.
                          </Typography>
                        }
                        elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
                      />
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} className={classes.inputWithInfo}>
                      <TextField
                        label='Error Nachricht'
                        value={inputs[selectedInput].errorMessage ?? ''}
                        onChange={(event): void => {
                          const tmpInputs = { ...inputs }
                          tmpInputs[selectedInput].errorMessage = event.target.value
                          setInputs(tmpInputs)
                        }}
                        fullWidth
                        disabled={
                          typeof inputs[selectedInput].isRequired === 'undefined' || !inputs[selectedInput].isRequired
                        }
                        // helperText='Wird angezeigt, wenn die erforderliche Eingabe nicht korrekt ausgefüllt wurde.'
                      />
                      <CustomContentTooltip
                        content={
                          <Typography>
                            Wird angezeigt, wenn die erforderliche Eingabe nicht (korrekt) ausgefüllt wurde.
                          </Typography>
                        }
                        elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
                      />
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} className={classes.inputWithInfo}>
                      <ToggleButtonGroup
                        fullWidth
                        size='large'
                        value={
                          typeof inputs[selectedInput].placeholder !== 'undefined'
                            ? 'placeholder'
                            : typeof inputs[selectedInput].value !== 'undefined'
                            ? 'value'
                            : undefined
                        }
                        exclusive
                        onChange={(event, newValue): void => {
                          // console.log(newValue)
                          const tmpInputs = { ...inputs }
                          if (newValue === 'value') {
                            tmpInputs[selectedInput].value = ''
                            delete tmpInputs[selectedInput].placeholder
                          } else if (
                            newValue === 'placeholder' &&
                            (inputs[selectedInput].type === InputTypeEnum['Input.Text'] ||
                              inputs[selectedInput].type === InputTypeEnum['Input.ChoiceSet'] ||
                              inputs[selectedInput].type === InputTypeEnum['Input.Number'])
                          ) {
                            tmpInputs[selectedInput].placeholder = ''
                            delete tmpInputs[selectedInput].value
                          }
                          setInputs(tmpInputs)
                        }}
                        // TODO: Standardwert integrieren von variablen
                      >
                        <ToggleButton
                          value='placeholder'
                          disabled={
                            inputs[selectedInput].type === InputTypeEnum['Input.Date'] ||
                            inputs[selectedInput].type === InputTypeEnum['Input.Time'] ||
                            inputs[selectedInput].type === InputTypeEnum['Input.Toggle']
                          }
                        >
                          Platzhalter
                        </ToggleButton>
                        <ToggleButton value='value'>Wert</ToggleButton>
                      </ToggleButtonGroup>
                      <CustomContentTooltip
                        content={
                          <Typography>
                            Standardwerte für Eingaben sind Werte die bereits im Eingabenfeld stehen, wenn Sie den
                            Nutzer*innen angezeigt werden. Platzhalter ist der typische Platzhalter Text für Eingaben.
                          </Typography>
                        }
                        elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
                      />
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} className={classes.inputWithInfo}>
                      {(typeof inputs[selectedInput].placeholder !== 'undefined' ||
                        typeof inputs[selectedInput].value !== 'undefined') && (
                        <>
                          <TextField
                            label={
                              typeof inputs[selectedInput].placeholder !== 'undefined'
                                ? 'Platzhalter'
                                : typeof inputs[selectedInput].value !== 'undefined'
                                ? 'Standardwert'
                                : ''
                            }
                            value={
                              typeof inputs[selectedInput].placeholder !== 'undefined'
                                ? inputs[selectedInput].placeholder
                                : typeof inputs[selectedInput].value !== 'undefined'
                                ? inputs[selectedInput].value
                                : ''
                            }
                            onChange={(event): void => {
                              const tmpInputs = { ...inputs }
                              if (typeof inputs[selectedInput].placeholder !== 'undefined') {
                                tmpInputs[selectedInput].placeholder = event.target.value
                              } else if (typeof inputs[selectedInput].value !== 'undefined') {
                                tmpInputs[selectedInput].value = event.target.value
                              }
                              setInputs(tmpInputs)
                            }}
                            fullWidth
                            disabled={
                              typeof inputs[selectedInput].placeholder !== 'undefined' &&
                              typeof inputs[selectedInput].value !== 'undefined'
                            }
                          />
                          <CustomContentTooltip
                            content={
                              <Typography>
                                {typeof inputs[selectedInput].placeholder !== 'undefined'
                                  ? 'Platzhalter Texte werden in der Eingabe angezeigt und verschwinden, sobald Nutzer*innen eine Eingabe tätigen.'
                                  : 'Standardwerte verhalten sie wie Nutzereingaben im Eingabenfeld und können von den Nutzer*innen verändert werden.'}
                              </Typography>
                            }
                            elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
                          />
                        </>
                      )}
                    </Grid>
                    {/* ---- Text specific properties ---- */}
                    {inputs[selectedInput].type === InputTypeEnum['Input.Text'] && (
                      <Grid item md={6} sm={6} xs={12} className={classes.inputWithInfo}>
                        <ToggleButtonGroup
                          fullWidth
                          size='large'
                          value={inputs[selectedInput].isMultiline ? 'multiline' : 'singleline'}
                          exclusive
                          onChange={(event, newValue): void => {
                            const tmpInputs = { ...inputs }
                            if (newValue === 'singleline') {
                              tmpInputs[selectedInput].isMultiline = false
                            } else if (newValue === 'multiline') {
                              tmpInputs[selectedInput].isMultiline = true
                            }
                            setInputs(tmpInputs)
                          }}
                        >
                          <ToggleButton
                            value='singleline'
                            disabled={inputs[selectedInput].type !== InputTypeEnum['Input.Text']}
                          >
                            Einzeilig
                          </ToggleButton>
                          <ToggleButton
                            value='multiline'
                            disabled={inputs[selectedInput].type !== InputTypeEnum['Input.Text']}
                          >
                            Mehrzeilig
                          </ToggleButton>
                        </ToggleButtonGroup>
                        <CustomContentTooltip
                          content={
                            <Typography>
                              Mehrzeilige Eingaben vereinfachen die Eingabe von langen Texten. Wenn keine langen Texte
                              eingegeben werden sollen, sollten Sie einzeilige Text Eingaben verwenden.
                            </Typography>
                          }
                          elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
                        />
                      </Grid>
                    )}
                    {/* ---- Choice Set ---- */}
                    {inputs[selectedInput].type === InputTypeEnum['Input.ChoiceSet'] && (
                      <Grid item md={6} sm={6} xs={12} className={classes.inputWithInfo}>
                        <ToggleButtonGroup
                          fullWidth
                          size='large'
                          value={inputs[selectedInput].style}
                          exclusive
                          onChange={(event, newValue): void => {
                            const tmpInputs = { ...inputs }
                            tmpInputs[selectedInput].style = newValue
                            setInputs(tmpInputs)
                          }}
                        >
                          <ToggleButton value='compact'>Dropdown</ToggleButton>
                          <ToggleButton value='expanded'>Ausgeklappt</ToggleButton>
                        </ToggleButtonGroup>
                        <CustomContentTooltip
                          content={
                            <Typography>
                              Ausgeklappte Auswahl-Eingaben zeigen die Optionen der Eingabe ohne ein Dropdown. Dadurch
                              wird auch kein Platzhalter Wert angezeigt.
                            </Typography>
                          }
                          elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
                        />
                      </Grid>
                    )}
                    {inputs[selectedInput].type === InputTypeEnum['Input.ChoiceSet'] &&
                      typeof inputs[selectedInput].choices !== 'undefined' &&
                      Array.isArray(inputs[selectedInput].choices) && (
                        <>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography variant='h5' style={{ marginBottom: '8px' }}>
                              Auswahl-Optionen
                            </Typography>
                          </Grid>
                          {/* Display of existing choices*/}
                          {inputs[selectedInput].choices.map((choice, index) => {
                            return (
                              <>
                                <Grid
                                  key={`input-choice-name-${choice.index}`}
                                  item
                                  md={2}
                                  sm={3}
                                  xs={12}
                                  className={classes.inputWithInfo}
                                >
                                  <Typography>Option {index + 1}:</Typography>
                                </Grid>
                                <Grid
                                  key={`input-choice-change-${choice.index}`}
                                  item
                                  md={10}
                                  sm={9}
                                  xs={12}
                                  className={classes.inputWithInfo}
                                >
                                  <TextField
                                    fullWidth
                                    value={inputs[selectedInput].choices[index].value ?? ''}
                                    label='Wert'
                                    onChange={(event): void => {
                                      const tmpInputs = { ...inputs }
                                      tmpInputs[selectedInput].choices[index].value = event.target.value
                                      tmpInputs[selectedInput].choices[index].title = event.target.value
                                      setInputs(tmpInputs)
                                    }}
                                  />
                                  <CustomIconButton
                                    tooltip='Option löschen'
                                    type='decline'
                                    onClick={(): void => {
                                      const tmpInputs = { ...inputs }
                                      tmpInputs[selectedInput].choices.splice(index, 1)
                                      setInputs(tmpInputs)
                                    }}
                                  />
                                </Grid>
                              </>
                            )
                          })}
                          <Grid item md={6} sm={6} xs={12} className={classes.inputWithInfo}>
                            <Button
                              icon='add-line'
                              iconType='remix'
                              variant='secondary'
                              onClick={(): void => {
                                const tmpInputs = { ...inputs }
                                tmpInputs[selectedInput].choices.push({ title: '', value: '' })
                                setInputs(tmpInputs)
                              }}
                              disabled={inputs[selectedInput].choices.indexOf((choice) => choice.value === '') >= 0}
                            >
                              Option hinzufügen
                            </Button>
                          </Grid>
                        </>
                      )}
                    {/* ---- Toggle ---- */}
                    {inputs[selectedInput].type === InputTypeEnum['Input.Toggle'] && (
                      <>
                        <Grid item md={6} sm={6} xs={false}></Grid>
                        <Grid item md={6} sm={6} xs={12} className={classes.inputWithInfo}>
                          <TextField
                            label='Schalter Text'
                            fullWidth
                            value={inputs[selectedInput].title ?? ''}
                            onChange={(event): void => {
                              const tmpInputs = { ...inputs }
                              tmpInputs[selectedInput].title = event.target.value
                              setInputs(tmpInputs)
                            }}
                          />
                          <CustomContentTooltip
                            content={
                              <Typography>
                                Mehrzeilige Eingaben vereinfachen die Eingabe von langen Texten. Wenn keine langen Texte
                                eingegeben werden sollen, sollten Sie einzeilige Text Eingaben verwenden.
                              </Typography>
                            }
                            elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Divider />
              {/* ---- Variables and Datachecks ---- */}
              <Accordion disableGutters classes={{ root: classes.accordionRoot }}>
                <AccordionSummary
                  classes={{ root: classes.accordionSummaryRoot }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='variables-content'
                  id='variables-header'
                >
                  <Typography>Variablen</Typography>
                </AccordionSummary>
                <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
                  <Grid container className={classes.editorContentGeneral} spacing={3}>
                    {inputs[selectedInput].type === InputTypeEnum['Input.ChoiceSet'] &&
                    Array.isArray(inputs[selectedInput].choices) ? (
                      findValuesInputFields(card.data, 'type').map((inputField, index) => {
                        return inputField && inputField.choices ? (
                          inputField.choices.map((choice, index) => {
                            return (
                              <Grid
                                key={`choice-${choice.id}-${index}`}
                                container
                                direction='row'
                                style={{ width: '100%' }}
                              >
                                <Grid item xs={12} style={{ width: '100%' }}>
                                  <Grid container direction='row' alignItems='center'>
                                    <Grid item xs={5} style={{ height: '100%' }}>
                                      <div
                                        style={{
                                          marginRight: '15px',
                                        }}
                                      >
                                        <Typography>Auswahl Titel:</Typography>
                                      </div>
                                    </Grid>

                                    <Grid item xs={7} style={{ height: '100%' }}>
                                      <div style={{ alignSelf: 'center' }}>
                                        <b>
                                          <i>{choice.acValue ? choice.acValue : 'Kein Titel festgelegt'}</i>
                                        </b>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <Grid item xs={12} style={{ width: '100%' }}>
                                  <Grid container direction='row' justifyContent='space-evenly' alignItems='center'>
                                    <Grid item xs={5} style={{ height: '100%' }}>
                                      <div style={{ marginRight: '15px' }}>
                                        <Typography>
                                          Variable & Wert der Variablen, falls eine Choice gewählt ist.
                                        </Typography>
                                      </div>
                                    </Grid>
                                    <Grid item xs={7} style={{ height: '100%' }}>
                                      <div style={{ width: '100%', marginBottom: '25px' }}>
                                        <AcChoiceValueInput
                                          chart={origChart}
                                          acChoiceName={choice.acValue}
                                          setStateCallback={setChartCallback}
                                          setVariablesCallback={(
                                            chart: Chart,
                                            prevSelectedVarIds: string[],
                                            selectedVarIds: string[],
                                          ): void => {
                                            setChartCallback(chart)
                                            // console.log(chart) // FIXME
                                          }}
                                          choiceSetId={inputs[selectedInput].id}
                                          acFieldType={inputs[selectedInput].type}
                                        />
                                      </div>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )
                          })
                        ) : (
                          <></>
                        )
                      })
                    ) : (
                      <Grid item md={6} sm={6} xs={12}>
                        <VariablesAutosuggestSelect
                          chart={origChart}
                          onChange={(chart: Chart, prevSelectedVarIds: string[], selectedVarIds: string[]): void => {
                            setChartCallback(chart)
                            // console.log(chart) // FIXME
                          }}
                          usageType='set'
                          acFieldId={inputs[selectedInput].id}
                          acFieldType={inputs[selectedInput].type}
                          isResult={false}
                        />
                      </Grid>
                    )}
                  </Grid>
                  {/* </div> */}
                </AccordionDetails>
              </Accordion>
              <Divider />
            </>
          )}
        </div>
      </div>
    )
  }

  /** SET NAME */
  function renderInputName(): React.ReactElement {
    return (
      <>
        <Typography style={{ marginTop: '16px' }}>
          Bitte geben Sie der Eingabe einen beschreibenden Namen, damit Sie sie wiedererkennen.
        </Typography>
        <form
          className={classes.nameField}
          onSubmit={(): void => {
            if (
              selectedInput &&
              selectedInput.length > 0 &&
              Object.keys(inputs).filter((input) => input === selectedInput).length === 0
            ) {
              const tempInputs = { ...inputs }
              tempInputs[selectedInput] = {
                id: selectedInput,
                type: InputTypeEnum['Input.Text'],
                isRequired: false,
                isMultiline: false,
                placeholder: '--Bitte eingeben--',
              } // NOTE: default settings
              setInputs(tempInputs)
              setStage('edit')
            }
          }}
        >
          <TextField
            autoFocus
            label='Eingaben Name'
            value={selectedInput ?? ''}
            onChange={(event): void => {
              setSelectedInput(event.target.value)
            }}
            error={Object.keys(inputs).filter((input) => input === selectedInput).length > 0}
            helperText={
              Object.keys(inputs).filter((input) => input === selectedInput).length > 0
                ? 'Eine Eingabe mit diesem Namen existiert bereits.'
                : selectedInput && selectedInput.length === 0
                ? 'Eingabe Namen müssen aus mindestens einen Buchstaben oder Zahl bestehen.'
                : ''
            }
            style={{ width: '100%' }}
          />
          <CustomIconButton
            tooltip='Name bestätigen'
            type='accept'
            onClick={(): void => {
              if (
                selectedInput &&
                selectedInput.length > 0 &&
                Object.keys(inputs).filter((input) => input === selectedInput).length === 0
              ) {
                const tempInputs = { ...inputs }
                tempInputs[selectedInput] = {
                  id: selectedInput,
                  type: InputTypeEnum['Input.Text'],
                  isRequired: false,
                  isMultiline: false,
                } // NOTE: default settings
                setInputs(tempInputs)
              }
              setStage('edit')
            }}
            disabled={
              Object.keys(inputs).filter((input) => input === selectedInput).length > 0 ||
              typeof selectedInput === 'undefined' ||
              (typeof selectedInput === 'string' && selectedInput.length === 0)
            }
          />
          <CustomIconButton
            tooltip='Abbrechen'
            type='decline'
            onClick={(): void => {
              setSelectedInput(undefined)
              setStage('overview')
            }}
          />
        </form>
      </>
    )
  }

  const inputsMap = stage === 'overview' ? renderInputs() : <></>
  const menu = stage === 'overview' ? renderInputMenu() : <></>
  const inputName = stage === 'create' ? renderInputName() : <></>
  const editor = stage === 'edit' && typeof selectedInput !== 'undefined' ? renderInputEditor() : <></>

  return (
    <div className={classes.editor}>
      {stage === 'create' && inputName}
      {stage === 'overview' && inputsMap}
      {stage === 'overview' && menu}
      {stage === 'edit' && typeof selectedInput !== 'undefined' && editor}
    </div>
  )
}, isEqual)
