import React, { useState, useEffect } from 'react'
import TextFieldSuggestions from '../../../../../components/TextInput/TextfieldSuggestions'

import { Chart } from '../../../../../@types/Flowchart/types'
import { replaceVarIdWithDisplayName } from '../../../../../utils/chartUtils'

type TextFieldVariablesSuggestionsProps = {
  chart: Chart
  value?: string
  id: string
  label?: string
  multiline?: boolean
  maxRows?: number
  rows?: number
  fullWidth?: boolean
  autoFocus?: boolean
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onChange: (value: string) => void
}

export default function TextFieldVariablesSuggestions({
  chart,
  value: givenValue,
  id,
  label,
  multiline,
  maxRows,
  rows,
  fullWidth,
  autoFocus,
  onBlur,
  onChange,
}: TextFieldVariablesSuggestionsProps): React.ReactElement {
  const [allVariables, setAllVariables] = useState<string[]>([])
  const [value, setValue] = useState<string>(replaceVarIdWithDisplayName(chart, givenValue || ''))

  useEffect(function () {
    const variableDisplayNames: string[] = []
    for (const variable of Object.values(chart.variables)) {
      variableDisplayNames.push(variable.displayName)
    }
    setAllVariables(variableDisplayNames)
  }, [])

  useEffect(
    function () {
      // replace varIds with display names
      const newValue = replaceVarIdWithDisplayName(chart, givenValue || '')
      if (value !== newValue) {
        setValue(newValue)
      }
    },
    [givenValue],
  )

  return (
    <TextFieldSuggestions
      id={id}
      value={value}
      suggestions={allVariables}
      suggestPattern={'%'}
      noSuggestionsMessage='Keine Variablen'
      isPatternIncludedInSuggestions={false}
      onChange={onChange}
      onBlur={onBlur}
      label={label}
      margin='normal'
      variant='outlined'
      multiline={multiline}
      maxRows={maxRows}
      rows={rows}
      autoFocus={autoFocus}
      fullWidth={fullWidth}
    />
  )
}
