export const mode2Preview = {
  type: 'AdaptiveCard',
  body: [
    {
      type: 'Container',
      style: 'accent',
    },
    {
      type: 'ColumnSet',
      columns: [
        {
          type: 'Column',
          width: 'stretch',
          items: [
            {
              type: 'ActionSet',
              actions: [
                {
                  type: 'Action.Submit',
                  title: 'Zurück',
                  data: {
                    '.stepBack': 'true',
                  },
                  associatedInputs: 'none',
                  ignoreInputValidation: true,
                },
              ],
            },
          ],
        },
        {
          type: 'Column',
          width: 'stretch',
          items: [
            {
              type: 'ActionSet',
              actions: [
                {
                  type: 'Action.Submit',
                  title: 'Weiter',
                },
              ],
            },
          ],
        },
      ],
      separator: true,
      spacing: 'Medium',
    },
  ],
  $schema: 'http://adaptivecards.io/schemas/adaptive-card.json',
  version: '1.3',
}

export const mode1Preview = {
  type: 'AdaptiveCard',
  body: [
    {
      type: 'Container',
      style: 'accent',
    },
    {
      type: 'ActionSet',
      actions: [
        {
          type: 'Action.Submit',
          title: 'Weiter',
        },
      ],
      separator: true,
      spacing: 'Medium',
    },
  ],
  $schema: 'http://adaptivecards.io/schemas/adaptive-card.json',
  version: '1.3',
}

export const mode1PreviewOptions = {
  type: 'AdaptiveCard',
  body: [
    {
      type: 'Container',
      style: 'accent',
    },
    {
      type: 'ActionSet',
      actions: [
        {
          type: 'Action.Submit',
          title: 'Option 1',
        },
      ],
      separator: true,
      spacing: 'Medium',
    },
  ],
  $schema: 'http://adaptivecards.io/schemas/adaptive-card.json',
  version: '1.3',
}

export const mode2PreviewNoStepBack = {
  type: 'AdaptiveCard',
  body: [
    {
      type: 'Container',
      style: 'accent',
    },
    {
      type: 'ColumnSet',
      columns: [
        {
          type: 'Column',
          width: 'stretch',
          items: [
            {
              type: 'ActionSet',
              actions: [
                {
                  type: 'Action.Submit',
                  title: 'Option 1',
                },
              ],
            },
          ],
        },
        {
          type: 'Column',
          width: 'stretch',
          items: [
            {
              type: 'ActionSet',
              actions: [
                {
                  type: 'Action.Submit',
                  title: 'Option 2',
                },
              ],
            },
          ],
        },
      ],
      separator: true,
      spacing: 'Medium',
    },
  ],
  $schema: 'http://adaptivecards.io/schemas/adaptive-card.json',
  version: '1.3',
}

export const mode3PreviewNoStepBack = {
  type: 'AdaptiveCard',
  body: [
    {
      type: 'Container',
      style: 'accent',
    },
    {
      type: 'ColumnSet',
      columns: [
        {
          type: 'Column',
          width: 'stretch',
          items: [
            {
              type: 'ActionSet',
              actions: [
                {
                  type: 'Action.Submit',
                  title: 'Option 1',
                },
              ],
            },
          ],
        },
        {
          type: 'Column',
          width: 'stretch',
          items: [
            {
              type: 'ActionSet',
              actions: [
                {
                  type: 'Action.Submit',
                  title: 'Option 2',
                },
              ],
            },
          ],
        },
        {
          type: 'Column',
          width: 'stretch',
          items: [
            {
              type: 'ActionSet',
              actions: [
                {
                  type: 'Action.Submit',
                  title: 'Option 3',
                },
              ],
            },
          ],
        },
      ],
      separator: true,
      spacing: 'Medium',
    },
  ],
  $schema: 'http://adaptivecards.io/schemas/adaptive-card.json',
  version: '1.3',
}
