import React, { useEffect, useState } from 'react'

import { makeStyles } from 'tss-react/mui'
import { Grid, IconButton, Typography } from '@mui/material'
import { Delete, FileCopy } from '@mui/icons-material'

import DeleteVariableConfirmDialog from '../Dialogs/DeleteVariableConfirmDialog'
import CollapsibleCard from 'components/Cards/CollapsibleCard'
import EditableTypography from 'components/TextInput/EditableTypography'
import CustomizedTooltip from 'components/Tooltips/CustomContentTooltip'
import { Textfield } from 'components/TextInput/Textfield'

import { AnswerTemplateNameObject, AnswerTemplateVariableObject } from '../../../../../@types/Knowledge/types'

const useStyles = makeStyles()((theme) => ({
  container: {
    marginBottom: theme.spacing(2),
  },
  dataPointContainer: {
    width: '100%',
    display: 'flex',
    marginTop: theme.spacing(2),
  },
  previewContainer: {
    cursor: 'pointer',
    display: 'flex',
  },
  previewContainerText: {},
  iconButton: {
    // marginLeft: theme.spacing(1),
    // marginBottom: 'auto',
    // marginTop: 'auto',
    // padding: 0,
    color: theme.palette.primary.main,
  },
  iconButtonCopy: {
    height: '100%',
    marginTop: 'auto',
    marginLeft: theme.spacing(1),
  },
  icon: {
    // fontSize: '1.5rem',
  },
  iconCopy: {
    fontSize: '1rem',
  },
  textfield: {
    width: '100%',
  },
}))

type VariablesCardProps = {
  topic: AnswerTemplateNameObject
  variable: AnswerTemplateVariableObject
  isValidVariableName: (newDisplayName: string) => { isValid: boolean; message?: string }
  onVariableNameChange: (categoryName: string, oldName: string, newDisplayName: string) => void
  onValueChange: (categoryName: string, variableName: string, newValue: string) => void
  onDeleteVariable: (variableName: string) => void
  isExpanded: boolean
  onExpandChange: (variableName: string, isExpanded: boolean) => void
}

export default function VariablesCard({
  topic,
  variable,
  isValidVariableName,
  onVariableNameChange: onVariableNameChangeCallback,
  onValueChange,
  onDeleteVariable,
  isExpanded: isExpandedProps,
  onExpandChange: onExpandChangeProps,
}: VariablesCardProps): React.ReactElement {
  const { classes } = useStyles()

  // confirm delete dialog
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>()

  const [variableData, setVariableData] = useState<AnswerTemplateVariableObject>(variable)
  // copy success tooltip
  const [copySuccessTooltipKey, setCopySuccessTooltipKey] = useState<string>() // holds the key of the copy button for which a copy success tooltip should be shown

  const [isExpanded, setIsExpanded] = useState<boolean>(isExpandedProps)

  /**
   * Handles variable name change.
   * Intercepts change event and adds required additional infos for the callback.
   * @param newDisplayName
   */
  function onVariableNameChange(newDisplayName: string): void {
    onVariableNameChangeCallback(topic.name, variable.key, newDisplayName)
  }

  /**
   * Updates datapoint value in local state.
   * @param dataPointName name of the datapoint (not the display name)
   * @param newValue
   */
  function onDataPointValueChange(dataPointName: string, newValue: string): void {
    const tmpData = { ...variableData }
    tmpData.value = newValue
    setVariableData(tmpData)
  }

  /**
   * Updates variable value in local state.
   * @param newValue
   */
  function onValueDataChange(newValue: string): void {
    const tmpData = { ...variableData }
    tmpData.value = newValue
    setVariableData(tmpData)
  }

  /**
   * Sets changed datapoints into parent component using the provided callback function.
   * We do this on blur for performance reasons.
   */
  function onValueBlur(): void {
    onValueChange(topic.name, variableData.key, variableData.value)
  }

  /**
   * Deletes variable via callback.
   */
  function onDeleteClick(): void {
    setShowDeleteDialog(true)
  }

  /**
   * Deletes variable using callback and removes confirm dialog.
   */
  function onDeleteConfirm(): void {
    onDeleteVariable(variableData.key)
    setShowDeleteDialog(false)
  }

  /**
   * Copies text to clipboard and triggers success tooltip with timeout for targetElement.
   * Tooltip is shown for element with targetElementKey and timeouts after 2 seconds.
   * @param textToCopy
   * @param targetElement
   */
  async function copyTextToClipboard(textToCopy: string, targetElementKey: string): Promise<void> {
    // copy text to clipboard and also trigger copy success tooltip for button
    await navigator.clipboard.writeText(textToCopy)
    setCopySuccessTooltipKey(targetElementKey)
    // set timeout to remove tooltip after X seconds
    setTimeout(function () {
      setCopySuccessTooltipKey(undefined)
    }, 2000)
  }

  function onExpandChange(isExpanded: boolean): void {
    // console.log('Variable: ', variable.name)
    // console.log('isExpanded: ', isExpanded)
    onExpandChangeProps(variableData.key, isExpanded)
    setIsExpanded(isExpanded)
  }

  useEffect(
    function () {
      setVariableData(variable)
    },
    [variable],
  )

  useEffect(
    function () {
      setIsExpanded(isExpandedProps)
    },
    [isExpandedProps],
  )

  return (
    <div className={classes.container}>
      <CollapsibleCard
        title={
          <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
            <EditableTypography
              value={variableData.key}
              onChange={onVariableNameChange}
              isValidNewValue={isValidVariableName}
            />
            <div
              style={{
                marginLeft: 'auto',
                // , marginTop: 'auto', marginBottom: 'auto'
              }}
            >
              <CustomizedTooltip
                placement='top'
                disableInteractive
                content={<Typography>Variable löschen</Typography>}
                elements={
                  <IconButton onClick={onDeleteClick} aria-label='delete' className={classes.iconButton}>
                    <Delete className={classes.icon} />
                  </IconButton>
                }
              />
            </div>
          </div>
        }
        heightExpanded='auto'
        heightCollapsed='auto'
        minHeightCollapsed={'65px'}
        width={'100%'}
        onExpandChange={onExpandChange}
        isCollapsed={!isExpanded}
      >
        <div className={classes.dataPointContainer}>
          <Grid container spacing={3} justifyContent='center'>
            <Grid item xs={12} md={6}>
              <Textfield
                // TODO: Input validation an error messages
                value={variableData.value}
                label={`Wert für ${variableData.key}`}
                placeholder='Bitte eingeben'
                onBlur={onValueBlur}
                onChange={(event: React.ChangeEvent<HTMLInputElement>): void => onValueDataChange(event.target.value)}
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <div
                className={classes.previewContainer}
                onClick={async (): Promise<void> =>
                  copyTextToClipboard(`$\{info.${topic.name}.${variableData.key}}`, `${topic.name}.${variableData.key}`)
                }
              >
                <div className={classes.previewContainerText}>
                  <Typography variant='caption'>Einbindung der Variable</Typography>
                  <Typography variant='body1'>
                    <b>{`$\{info.${topic.name}.${variableData.key}}`}</b>
                  </Typography>
                </div>
                <CustomizedTooltip
                  placement='top'
                  disableInteractive
                  open={copySuccessTooltipKey === `${topic.name}.${variableData.key}`}
                  content={<Typography>Kopiert.</Typography>}
                  elements={
                    <IconButton
                      onClick={async (): Promise<void> =>
                        copyTextToClipboard(
                          `$\{info.${topic.name}.${variableData.key}}`,
                          `${topic.name}.${variableData.key}`,
                        )
                      }
                      aria-label='Copy'
                      className={classes.iconButton + ' ' + classes.iconButtonCopy}
                    >
                      <FileCopy className={classes.iconCopy} />
                    </IconButton>
                  }
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </CollapsibleCard>
      {showDeleteDialog && (
        <DeleteVariableConfirmDialog onClose={(): void => setShowDeleteDialog(false)} onDelete={onDeleteConfirm} />
      )}
    </div>
  )
}
