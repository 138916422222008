import { Announcement } from '@mui/icons-material'
import React, { ReactElement } from 'react'
import EditorSidebarItem from '../editorSidebarItem'
import { FLOWDESIGNER_NODE_COLOR_TRIGGER } from 'utils/constants'

export default function eventTrigger(): ReactElement {
  return (
    <EditorSidebarItem
      type='trigger/event'
      ports={{
        port1: {
          id: 'port1',
          type: 'left',
          position: { x: 0, y: 0 },
          properties: {
            custom: 'incoming',
          },
        },
        port2: {
          id: 'port2',
          type: 'right',
          position: { x: 0, y: 0 },
          properties: {
            custom: 'outgoing',
          },
        },
      }}
      properties={{
        typeText: 'Event Trigger',
        event: '',
        value: '',
        dialog: '',
      }}
      Icon={Announcement}
      color={FLOWDESIGNER_NODE_COLOR_TRIGGER}
    />
  )
}
