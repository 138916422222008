/* eslint-disable no-constant-condition */
import { Delete } from '@mui/icons-material'
import { Typography, useTheme } from '@mui/material'
import PDFIcon from 'assets/img/fileIcons/pdf.svg'
import TxtIcon from 'assets/img/fileIcons/txt.svg'
import WordIcon from 'assets/img/fileIcons/word.svg'
import { Button, CustomIconButton } from 'components/Buttons'
import BaseCard from 'components/Cards/BaseCard'
import { Dialog } from 'components/Dialogs'
import CircularLoading from 'components/Loading/CircularLoading'
import ContentPage, { ContentPageHeader } from 'components/Page/ContentPage'
import { Textfield } from 'components/TextInput/Textfield'
import { LoadingState, useRAGContext } from 'hooks/contexts/rag-context'
import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation, useNavigate, useParams, useResolvedPath } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'
import { APP_TITLE } from 'utils/constants'
import { isValidUrl } from 'utils/stringUtils'
import { RAGWebsiteSyncSchedule } from '../../../../@types/Knowledge/RAG/types'
import Content from './Components/Content'
import { Keywords } from './Components/Keywords'
import SyncSchedule from './WebsiteComponents/SyncSchedule'

function getIcon(fileName: string): string {
  const ext = fileName.split('.').pop()
  switch (ext) {
    case 'pdf':
      return PDFIcon
    case 'doc':
    case 'docx':
      return WordIcon
    case 'txt':
    default:
      return TxtIcon
  }
}

const useStyles = makeStyles()((theme) => {
  return {
    sectionHeading: {
      marginBottom: theme.spacing(2),
    },
    titleContainer: {},
    fileUploadContainer: { marginTop: theme.spacing(2), maxHeight: '300px' },
    fileContainer: { display: 'flex', flexDirection: 'column', marginBottom: theme.spacing(2) },
    file: { display: 'flex', alignItems: 'center' },
    fileIcon: { height: '32px', width: '32px', marginRight: theme.spacing(2) },
    fileName: { marginRight: theme.spacing(2) },
    fileDelete: {},
    fileDeleteButton: { color: theme.palette.error.main },
    contentContainer: { marginTop: theme.spacing(6) },
    textContentContainer: { marginTop: theme.spacing(4) },
    keywordsContainer: { marginTop: theme.spacing(4) },
    urlContainer: { marginTop: theme.spacing(2) },
    urlInput: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: theme.spacing(2),
    },
    urlInputField: { flexGrow: 1 },
    parseButton: { marginTop: theme.spacing(2) },
    actionsContainer: { display: 'flex', flexDirection: 'column', width: '100%', marginTop: theme.spacing(6) },
  }
})

export default function RAGAddWebsite(): React.ReactElement {
  const { classes } = useStyles()
  const theme = useTheme()
  const { botId } = useParams() as { botId: string }
  const { pathname: path } = useLocation()
  const navigate = useNavigate()
  const url = useResolvedPath('').pathname
  const { addNewWebsiteDocument, parseWebsite, loading, ragKnowledgeDbId, doesUrlExist } = useRAGContext()
  const prevLoadingRef = useRef<LoadingState>(loading)

  const [showDialog, setShowDialog] = useState<'discard' | 'creating'>()
  const [urlLocked, setUrlLocked] = useState<boolean>(false)
  const [title, setTitle] = useState<string>('')
  const [content, setContent] = useState<string>('')
  const [htmlHash, setHTMLHash] = useState<string>('')
  const [keywords, setKeywords] = useState<string[]>([])
  const [metaDescription, setMetaDescription] = useState<string>('')
  const [sourceUrl, setSourceUrl] = useState<string>('')
  const [urlError, setUrlError] = useState<string>('')
  const [parsingError, setParsingError] = useState<string>('')
  // sync
  const [syncSchedule, setSyncSchedule] = useState<RAGWebsiteSyncSchedule>() // init undefined
  console.log('Sync Schedule: ', syncSchedule)

  function reset(): void {
    setUrlLocked(false)
    setSourceUrl('')
    setUrlError('')
    setContent('')
    setHTMLHash('')
    setKeywords([])
    setMetaDescription('')
    setParsingError('')
  }

  function hasChanges(): boolean {
    return !!(title || content)
  }

  function onTitleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setTitle(event.target.value)
  }

  async function onParseWebsite(): Promise<void> {
    if (!sourceUrl || urlError) return
    const result = await parseWebsite(sourceUrl, botId, ragKnowledgeDbId)
    if (result === null) {
      // TODO handle
    } else {
      setUrlLocked(true)
      setContent(result.content)
      setTitle(result.title ?? '')
      setHTMLHash(result.htmlHash ?? '')
      setKeywords(result.keywords ?? [])
      setMetaDescription(result.metaDescription ?? '')
    }
  }

  function onContentChange(content: string): void {
    setContent(content)
  }

  function onUrlChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const value = event.target.value
    setSourceUrl(value)

    const isValid = isValidUrl(value)
    const isAllowed = !(value.endsWith('.pdf') || value.endsWith('.docx') || value.endsWith('.doc'))
    const doesExist = doesUrlExist(value)
    if (!isValid && !urlError && event.target.value.length > 0) {
      setUrlError('Die URL ist ungültig.')
    } else if (!isAllowed) {
      setUrlError('Die URL darf keine Dateiendung haben.')
    } else if (doesExist) {
      setUrlError('Die URL existiert bereits in der Wissensdatenbank.')
    } else if ((isValid && isAllowed && !doesExist && urlError) || event.target.value.length === 0) {
      setUrlError('')
    }
  }

  async function onAdd(): Promise<void> {
    if (urlError) return
    if (!title || !content || !sourceUrl || !htmlHash) return
    await addNewWebsiteDocument(title, content, sourceUrl, htmlHash, keywords, syncSchedule)
  }

  useEffect(
    function navigateBackAfterSuccess() {
      if (prevLoadingRef.current === 'creating' && loading === 'creatingSuccess') {
        // successfully created - navigate back to overview
        navigate(url.substring(0, url.lastIndexOf('/')))
      }
      prevLoadingRef.current = loading
    },
    [loading],
  )

  return (
    <>
      <Helmet>
        <title>{APP_TITLE} - Wissensdatenbank</title>
      </Helmet>
      <ContentPage>
        <ContentPageHeader
          title={'Webseite importieren'}
          actions={[
            <Button
              key='add-button'
              onClick={onAdd}
              disabled={
                !title ||
                !content ||
                !!urlError ||
                loading === 'creating' ||
                loading === 'parsing' ||
                loading === 'parsingError' ||
                !!parsingError
              }
              type='success'
              loading={loading === 'creating'}
            >
              Hinzufügen
            </Button>,
          ]}
          previousUrl={url.substring(0, url.lastIndexOf('/'))}
          previousUrlCallback={(): boolean => {
            const changes = hasChanges()
            if (changes) setShowDialog('discard')
            return !changes
          }}
        />
        <BaseCard width={'100%'} height={'100%'} minHeight={'80vh'} contentScrollable>
          <div className={classes.urlContainer}>
            <Typography variant='h4' className={classes.sectionHeading}>
              Konfiguration
            </Typography>
            <Typography style={{ marginBottom: theme.spacing(1) }}>
              Geben Sie die URL der Webseite ein, die Sie in die Wissensdatenbank importieren möchten. Durch Klick auf
              &quot;Inhalte laden&quot; werden die Inhalte der Webseite extrahiert, können eingesehen und bearbeitet
              werden.
            </Typography>
            <div className={classes.urlInput}>
              <Textfield
                label='URL'
                placeholder='Link zur Website des Textes'
                className={classes.urlInputField}
                value={sourceUrl}
                onChange={onUrlChange}
                fullWidth
                error={!!urlError}
                helperText={urlError}
                disabled={urlLocked}
                endButton={
                  <div style={{ display: 'flex' }}>
                    <CustomIconButton
                      onClick={reset}
                      disabled={loading === 'parsing' || !sourceUrl}
                      type='default'
                      icon='ri-reset-left-line'
                      tooltip='Zurücksetzen'
                    />
                    <CustomIconButton
                      onClick={(): void => {
                        window.open(sourceUrl, '_blank')
                      }}
                      disabled={!sourceUrl}
                      type='default'
                      icon='ri-external-link-line'
                      tooltip='Öffnen'
                    />
                  </div>
                }
              />
              {!content && (
                <Button
                  type='normal'
                  onClick={onParseWebsite}
                  loading={loading === 'parsing'}
                  disabled={!sourceUrl || !!urlError || loading === 'parsing'}
                  className={classes.parseButton}
                >
                  Inhalte laden
                </Button>
              )}
            </div>
          </div>
          {loading === 'parsing' && (
            <div style={{ height: '400px' }}>
              <CircularLoading text='Inhalt der Webseite wird verarbeitet. Dies kann einen Moment dauern...' />
            </div>
          )}
          {content && loading !== 'parsing' && (
            // only show once parsing is done
            <div className={classes.contentContainer}>
              <Typography variant='h4' className={classes.sectionHeading}>
                Inhalt
              </Typography>
              <div className={classes.titleContainer}>
                <Typography style={{ marginBottom: theme.spacing(2) }}>
                  Titel des neuen Inhalts, z.B. der Titel der Website.
                </Typography>
                <Textfield
                  label='Titel'
                  placeholder='Titel des neuen Inhalts'
                  value={title}
                  onChange={onTitleChange}
                  fullWidth
                  multiline
                  rowsMax={3}
                />
              </div>
              <div className={classes.textContentContainer}>
                <Content
                  descriptionText={
                    <Typography>
                      Inhalt der Website, der zur Wissensdatenbank hinzugefügt werden soll (Vorschau): <br />
                      <Typography variant='caption'>
                        <b>Tipp:</b> Klick auf den Text öffnet Bearbeitungsdialog.
                      </Typography>
                    </Typography>
                  }
                  content={content}
                  onContentChange={(content: string) => {
                    onContentChange(content)
                  }}
                />
              </div>
              <div className={classes.keywordsContainer}>
                <Typography variant='h4' className={classes.sectionHeading}>
                  Stichwörter
                </Typography>
                <Keywords
                  descriptionText={
                    <Typography>
                      Sie können passende Stichwörter für den Inhalt hinzufügen. Stichwörter helfen dabei, den Inhalt zu
                      kategorisieren und schneller zu finden. Bei einigen Webseiten werden Stichwörter automatisch aus
                      den Metadaten extrahiert, sofern vorhanden.
                    </Typography>
                  }
                  keywords={keywords}
                  onKeywordsChange={setKeywords}
                />
              </div>
              <div className={classes.actionsContainer}>
                <Typography variant='h4' className={classes.sectionHeading}>
                  Automatische Aktualisierung
                </Typography>
                <SyncSchedule setSyncSchedule={setSyncSchedule} syncSchedule={syncSchedule} />
              </div>
            </div>
          )}
        </BaseCard>
        {loading === 'creating' && (
          <Dialog id='creating-document-dialog' open={loading === 'creating'} closable={false} size='small'>
            <CircularLoading text='Datei wird importiert...' />
          </Dialog>
        )}
        {showDialog === 'discard' && (
          <Dialog
            id='discard-changes-dialog'
            size='small'
            open={showDialog === 'discard'}
            closable
            onClose={() => setShowDialog(undefined)}
            title='Ungespeicherte Änderungen'
            primaryActionButton={
              <Button
                size='small'
                type='danger'
                icon={<Delete />}
                onClick={(): void => {
                  navigate(-1)
                }}
              >
                Verwerfen
              </Button>
            }
          >
            <Typography>
              Es existieren ungespeicherte Änderungen. Sind Sie sicher, dass Sie zurückgehen möchten? Die Änderungen
              werden dabei verworfen.
            </Typography>
          </Dialog>
        )}
      </ContentPage>
    </>
  )
}
