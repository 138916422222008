import React from 'react'
import { makeStyles } from 'tss-react/mui'
import CustomizedTooltip from '../../../../components/Tooltips/CustomContentTooltip'

// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. ArrowFunctionExpression in CSS prop.
const useStyles = makeStyles<CheckProps>()((theme, props) => ({
  check: {
    fontSize: '1rem',
    color: theme.palette.success.main,
    // cursor: (props: { tooltip?: string | React.ReactElement }): string => (props?.tooltip ? 'pointer' : 'default'),
    // cursor: (): CSSProperties => (props.tooltip ? 'pointer' : 'default'),
    cursor: props.tooltip ? 'pointer' : 'default',
    marginRight: theme.spacing(1),
  },
}))

type CheckProps = {
  tooltip?: string | React.ReactElement
}

export default function Check({ tooltip }: CheckProps): React.ReactElement {
  const { classes } = useStyles({ tooltip })
  if (typeof tooltip !== 'undefined') {
    return (
      <CustomizedTooltip
        disableInteractive
        elements={<i className={'ri-checkbox-circle-line ' + classes.check} />}
        content={<>{tooltip}</>}
        placement='top'
      />
    )
  } else {
    return <i className={'ri-checkbox-circle-line ' + classes.check} />
  }
}
