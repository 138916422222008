import { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import { Node } from '../../@types/Flowchart/types'
import { NODE_NAMES, CARD_TYPE_NAMES } from '../../utils/constants'
import { cloneDeep, debounce } from 'lodash'
import Fuse from 'fuse.js'

// extend Node type for better search
type EnhancedNode = Node & {
  cardTypeName?: string
  nodeTypeName?: string
}

/**
 * Enhances nodes for better searchability.
 * Adds aliases for card type and node type.
 * @param nodes
 */
function enhanceNodesForSearch(nodes: Node[]): EnhancedNode[] {
  const enhancedNodes: EnhancedNode[] = []
  for (const node of nodes) {
    const eNode = cloneDeep(node) as EnhancedNode
    eNode.nodeTypeName = NODE_NAMES[eNode.type]
    if (eNode.properties.cardType) eNode.cardTypeName = CARD_TYPE_NAMES[eNode.properties.cardType]
    enhancedNodes.push(eNode)
  }
  return enhancedNodes
}

type SearchOptions<T> = Fuse.IFuseOptions<T> & {
  limit?: number
  matchAllOnEmptyQuery: boolean
}

const defaultSearchOptions: SearchOptions<Node> = {
  matchAllOnEmptyQuery: true,
  shouldSort: true,
  threshold: 0.4,
  minMatchCharLength: 1,
  keys: [
    'type',
    'cardTypeName',
    'nodeTypeName',
    'properties.text',
    'properties.card',
    'properties.cardType',
    'properties.api.request.url',
    'id',
  ],
}

/**
 * Custom search hook for searching nodes.
 * See https://dev.to/noclat/using-fuse-js-with-react-to-build-an-advanced-search-with-highlighting-4b93 for reference.
 */
export function useNodeSearch(
  nodes: Node[],
  options: SearchOptions<Node> = defaultSearchOptions,
): {
  hits: Node[] // search results
  onSearch: (searchString: string) => void // sets new search query
  query: string // search query
} {
  // defining our query state in there directly
  const [query, updateQuery] = useState('')

  // removing custom options from Fuse options object
  // NOTE: `limit` is actually a `fuse.search` option, but we merge all options for convenience
  const { limit, matchAllOnEmptyQuery, ...fuseOptions } = options

  // let's memoize the fuse instance for performances
  const fuse = useMemo(() => new Fuse(enhanceNodesForSearch(nodes), fuseOptions), [nodes, fuseOptions])

  // memoize results whenever the query or options change
  const hits = useMemo(
    // if query is empty and `matchAllOnEmptyQuery` is `true` then return all list
    () => (!query && matchAllOnEmptyQuery ? nodes : (fuse.search(query).map((result) => result.item) as Node[])),
    [fuse, limit, matchAllOnEmptyQuery, query],
  )

  // debounce updateQuery and rename it `setQuery` so it's transparent
  const setQuery = useCallback(debounce(updateQuery, 100), [])

  // pass a handling helper to speed up implementation
  const onSearch = useCallback((searchString: string) => setQuery(searchString.trim()), [setQuery])

  return {
    hits,
    onSearch,
    query,
  }
}
