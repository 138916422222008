import React, { memo, useEffect, useState } from 'react'
import { cloneDeep, isEqual } from 'lodash'

// @material-ui
import { makeStyles } from 'tss-react/mui'

// Custom Components
import { SmartCardActionsContainer } from 'components/SmartCard/smartCardModules'
import ChoiceActions from './cardActionsType/actionChoice'
import ContentAndInputActions from './cardActionsType/contentAndInput'
// Utils
import { updateCardProperties } from 'utils/smartCardUtils'
// Types
// import { Node, Chart } from '../../../../../../../@types/Flowchart/types'
import {
  Card,
  CardTypeEnum,
  IContainer,
  // ITextBlock,
  // InputTypeEnum,
} from '../../../../../../../@types/SmartCards/types'

const useStyles = makeStyles()((theme) => ({
  editor: {
    // height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  editorContainer: {
    borderRadius:
      typeof theme.shape.borderRadius === 'number' ? theme.shape.borderRadius * 2 : theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[500]}`,
    padding: theme.spacing(1),
    height: '100%',
  },
  editorOverflow: {
    height: '100%',
    overflowY: 'auto',
    padding: theme.spacing(1),
  },
  editorHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  editorContentGeneral: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    // alignItems: 'center'
  },
  inputWithInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  inputInfoIcon: {
    marginLeft: theme.spacing(1),
    height: '20px',
    color: theme.palette.grey[700],
  },
  inputContainer: {
    height: '56px',
    borderRadius:
      typeof theme.shape.borderRadius === 'number' ? theme.shape.borderRadius * 2 : theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[500]}`,
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  inputName: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  inputActions: {
    marginLeft: 'auto',
  },
  inputButton: {
    marginRight: theme.spacing(1),
  },
  menuContainer: {
    width: '50%',
    marginTop: theme.spacing(2),
  },
  addButton: {
    width: '300px',
  },
  menuIcon: {
    color: theme.palette.primary.main,
  },
  iconButtonAccept: {
    marginLeft: theme.spacing(1),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.light,
    },
    '&:disabled': {
      backgroundColor: theme.palette.grey[400],
      color: theme.palette.common.white,
    },
  },
  iconButtonDelete: {
    marginLeft: theme.spacing(1),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
    '&:disabled': {
      backgroundColor: theme.palette.grey[400],
      color: theme.palette.common.white,
    },
  },
  icon: { fontSize: '1.2rem' },
  nameField: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    width: '80 %',
  },
  accordionRoot: {
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
  },
  accordionSummaryRoot: {
    padding: 0,
  },
  accordionDetailsRoot: {
    padding: 0,
  },
}))

type CardActionsProps = {
  card: Card
  cardType: CardTypeEnum
  setCardCallback: (card: Card) => void
}

// TODO: use a tmp state for all text inputs for onChangen and use onBlur to update card and inputs

export default memo(function CardActions({
  card: origCard,
  cardType,
  setCardCallback,
}: CardActionsProps): React.ReactElement {
  const { classes } = useStyles()
  const [card, setCard] = useState<Card>(origCard)
  const [actionsContainer, setActionsContainer] = useState<IContainer>(loadActionContainer(origCard))
  // const [actions, setActions] = useState<any>([]) // action is modeled as { data: {}, type: 'OpenURL' | 'Message' | 'Submit', row: indexNumber, column: indexNumber}
  // const [stage, setStage] = useState<'overview' | 'edit' | 'template' | 'create'>('overview') // create is prestep to edit - setting the input id

  useEffect(() => {
    // check if action container exists, else add to card
    const actionIndex = card.data.body?.findIndex((block, index) => block.id === SmartCardActionsContainer.id)
    if (typeof actionIndex !== 'undefined' && actionIndex < 0) {
      const newCard = { ...card }
      if (newCard.data && newCard.data.body) {
        newCard.data?.body.push(SmartCardActionsContainer as IContainer)
      }
      setCard(newCard)
    }
  }, [])

  // - stateless
  function loadActionContainer(inputCard): IContainer {
    if (inputCard.data.body) {
      const actionIndex = inputCard.data.body?.findIndex((block, index) => block.id === SmartCardActionsContainer.id)
      if (typeof actionIndex !== 'undefined' && actionIndex >= 0) {
        // Has input Container
        const actionContainer = inputCard.data.body[actionIndex] as IContainer
        return actionContainer
      } else {
        return SmartCardActionsContainer as IContainer
      }
    }
    return SmartCardActionsContainer as IContainer
  }

  useEffect(() => {
    // update the action container in the card
    const newCard = cloneDeep(card)
    newCard.data = updateCardProperties(card.data, `$..body[?(@.id=="${SmartCardActionsContainer.id}")]`, 'items', {
      ...actionsContainer,
    })
    // updateCard(actionsContainer, card.data)
    setCard(newCard)
  }, [actionsContainer])

  // update card in parents
  useEffect(() => {
    setCardCallback(card)
  }, [card])

  // TODO: 3 columns for action choice
  return (
    <div className={classes.editor}>
      {/* Card Type is Content or Inputs -> means we can have one submit action with no data and optionally a stepBack action */}
      {(cardType === CardTypeEnum.ContentCard || cardType === CardTypeEnum.InputCard) && (
        <ContentAndInputActions
          cardType={cardType}
          actionsContainer={actionsContainer}
          setActionsContainerCallback={(container: IContainer): void => setActionsContainer(cloneDeep(container))}
        />
      )}
      {cardType === CardTypeEnum.ActionChoiceCard && (
        <ChoiceActions
          actionsContainer={actionsContainer}
          setActionsContainerCallback={(container: IContainer): void => setActionsContainer(cloneDeep(container))}
        />
      )}
    </div>
  )
}, isEqual)
