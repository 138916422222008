import React from 'react'
import { Alert, AlertTitle } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
// I18n
// import { Trans } from '@lingui/macro'

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    '& p': {
      marginTop: theme.spacing(1),
      marginBottom: 0,
      textAlign: 'start',
    },
    '& .MuiAlert-action': {
      paddingRight: '16px',
    },
  },
  title: {
    textAlign: 'start',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
}))

type BoxProps = {
  action?: React.ReactNode
}

// _____ ERROR BOX _____

export function ErrorBox(props: React.PropsWithChildren<BoxProps>): React.ReactElement {
  const { classes } = useStyles()
  return (
    <Alert severity='error' className={classes.root} action={props.action}>
      <AlertTitle className={classes.title}>
        {/* <Trans>Error</Trans> */}
        Error
      </AlertTitle>
      {props.children && props.children}
    </Alert>
  )
}

// _____ WARNING BOX _____

export function WarningBox(props: React.PropsWithChildren<BoxProps>): React.ReactElement {
  const { classes } = useStyles()
  return (
    <Alert severity='warning' className={classes.root} action={props.action}>
      <AlertTitle className={classes.title}>
        {/* <Trans>Warning</Trans> */}
        Warnung
      </AlertTitle>
      {props.children && props.children}
    </Alert>
  )
}
