import { UploadFile } from '@mui/icons-material'
import React, { ReactElement, useRef } from 'react'
import EditorSidebarItem from '../editorSidebarItem'
import { FLOWDESIGNER_NODE_COLOR_FILE } from 'utils/constants'
import { v4 as uuid } from 'uuid'

export default function documentUpload(): ReactElement {
  const fileUploadConfigIdRef = useRef<string>(uuid())

  return (
    <EditorSidebarItem
      type='basic/fileUpload'
      ports={{
        port1: {
          id: 'port1',
          type: 'left',
          position: { x: 0, y: 0 },
          properties: {
            type: 'incoming',
          },
        },
        port2: {
          id: 'port2',
          type: 'right',
          position: { x: 0, y: 0 },
          properties: {
            type: 'outgoing',
            cond: false,
          },
        },
      }}
      properties={{
        typeText: 'Datei Upload',
        text: 'Datei Upload',
        dialog: '',
        fileUpload: {
          [fileUploadConfigIdRef.current]: {
            fileUploadConfigId: fileUploadConfigIdRef.current,
            fileDescription: '',
            fileName: '',
            fileTypes: ['pdf', 'jpg', 'png'],
            messageText: '',
            buttonText: '',
          },
        },
      }}
      Icon={UploadFile}
      color={FLOWDESIGNER_NODE_COLOR_FILE}
    />
  )
}
