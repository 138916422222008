import React, { useEffect, useState } from 'react'

import * as AdaptiveCards from 'adaptivecards'
import MarkdownIt from 'markdown-it'

// adaptive card styling
// require('../../assets/css/smartCardStylesOld.css')
import '../../assets/css/smartCardStylesOld.css'
import '../../assets/css/smartCardStylesv4.css'
import { IAdaptiveCard } from '../../@types/SmartCards/types'
import { useBotContext } from 'hooks/contexts/bot-context'
import { hostConfig as hostConfigV4 } from './acHostConfigWebchatV4'

/**
 * Build HTMLDivElement from json AC definition
 * @param {Object} c json object of adaptive card
 */
function buildAdaptiveCardHTMLElement(c: any, webchatVersion?: string): HTMLElement | undefined {
  if (typeof c === 'undefined') return

  delete c.$schema

  const adaptiveCard = new AdaptiveCards.AdaptiveCard()
  if (webchatVersion === 'v4') {
    const acHostConfig = new AdaptiveCards.HostConfig(hostConfigV4)
    adaptiveCard.hostConfig = acHostConfig
  }
  // keep result.didProcess = true !
  adaptiveCard.constructor['onProcessMarkdown'] = function (text: any, result: any): void {
    result.outputHtml = MarkdownIt().render(text)
    result.didProcess = true
  }

  // Parse the card payload
  adaptiveCard.parse(c)
  // Render the card to an HTML element:
  return adaptiveCard.render()
}

type SmartCardProps = {
  card?: IAdaptiveCard
  className?: string
}

export default function SmartCard({ card, className }: SmartCardProps): React.ReactElement {
  const { bot, customWebchatStyles } = useBotContext()

  const [renderedCard, setRenderedCard] = useState<HTMLElement | undefined>()

  // useEffect(function () {
  //   // we dynamically load the css styling for the smart cards depending on the webchat version of the bot.
  //   const link = document.createElement('link')
  //   link.rel = 'stylesheet'
  //   // if (bot?.webchatVersion === 'v4') {
  //   //   link.href = '../../assets/css/smartCardStylesv4.css'
  //   // } else {
  //   //   link.href = '../../assets/css/smartCardStylesOld.css'
  //   // }
  //   document.head.append(link)

  //   return (): void => {
  //     document.head.removeChild(link)
  //   }
  // }, [])

  useEffect(
    function () {
      const rendered = buildAdaptiveCardHTMLElement(card, bot?.webchatVersion)
      setRenderedCard(rendered)
    },
    [card, bot],
  )

  return typeof renderedCard !== 'undefined' ? (
    <div
      ref={(ref: any): void => {
        // remove previous displayed cards
        if (ref !== null) {
          for (const child of ref.childNodes) {
            ref.removeChild(child)
          }
          ref.appendChild(renderedCard)
        }
      }}
      className={`${bot?.webchatVersion === 'v4' ? 'convaise-webchat-v4' : 'legacy-webchat'} ${className}`}
      style={customWebchatStyles}
      // className={className ?? undefined}
    />
  ) : (
    <div />
  )
}
