import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => {
  return {
    editAnswerTips: {
      '&:before': {
        display: 'none',
      },
    },
    editAnswerTipContainer: {
      display: 'flex',
      flexDirection: 'row',
      '& h3': {
        marginRight: theme.spacing(1),
      },
      '& p': {
        marginTop: '0.4rem',
      },
    },
    icon: {
      fontSize: '1.5rem',
    },
  }
})

export default function GoodAnswersTips(): React.ReactElement {
  const { classes } = useStyles()
  const [showTips, setShowTips] = useState<boolean>(false)

  return (
    <Accordion
      elevation={0}
      key={`tips`}
      className={classes.editAnswerTips}
      disableGutters
      expanded={showTips}
      onChange={() => setShowTips(!showTips)}
      sx={{
        '& .MuiAccordionSummary-root': {
          padding: 0, // Removes padding from the Accordion summary
        },
        '& .MuiAccordionDetails-root': {
          padding: 0, // Removes padding from the Accordion details
        },
      }}
    >
      <AccordionSummary expandIcon={<i className={'ri-arrow-down-s-line ' + classes.icon} />}>
        <Typography>
          <strong>Tipps für gute Antworten</strong>
          {!showTips && <> ({<em>ausklappen</em>})</>}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} className={classes.editAnswerTipContainer}>
            <Typography variant='h3'>1.</Typography>
            <Typography>
              <strong>Kontext</strong>
              <br />
              Der Antworttext sollte so formuliert sein, dass der Kontext der Antwort deutlich erkennbar ist. Eine
              einfache Überprüfungsmethode ist: Kann der Antworttext eigenständig stehen, ohne eine Frage, und ist er
              dennoch verständlich?
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.editAnswerTipContainer}>
            <Typography variant='h3'>2.</Typography>
            <Typography>
              <strong>Antwortlänge</strong>
              <br />
              Um Nutzer*innen das beste Erlebnis zu bieten, sollten Antworttexte klar und knapp gehalten werden.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.editAnswerTipContainer}>
            <Typography variant='h3'>3.</Typography>
            <Typography>
              <strong>Textformatierung</strong>
              <br />
              Es gibt zwei Wege Texte zu formatieren: Sie können einen bestehenden Text auswählen und über das Pop-Up
              Menü formatieren oder eine neue Zeile mit &quot;/&quot; beginnen und eine Formatierung auswählen.
            </Typography>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}
