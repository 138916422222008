import React, { ReactElement } from 'react'
import EditorSidebarItem from '../editorSidebarItem'
import { SubdirectoryArrowRight } from '@mui/icons-material'
import { FLOWDESIGNER_NODE_COLOR_TRIGGER } from 'utils/constants'

export default function qnaTrigger(): ReactElement {
  return (
    <EditorSidebarItem
      type='trigger/qna'
      ports={{
        port1: {
          id: 'port1',
          type: 'left',
          position: { x: 0, y: 0 },
          properties: {
            custom: 'incoming',
          },
        },
      }}
      properties={{
        typeText: 'QnA Modus Trigger',
        dialog: '',
      }}
      Icon={SubdirectoryArrowRight}
      color={FLOWDESIGNER_NODE_COLOR_TRIGGER}
    />
  )
}
