import React, { useState, useRef, useEffect } from 'react'

//@mui/material
import { makeStyles } from 'tss-react/mui'

import Button from '@mui/material/Button'
//@mui/icons-material
import { CloudUpload } from '@mui/icons-material'
// I18n
// import { Trans } from '@lingui/macro'

type StyleProps = {
  disableBorder?: boolean
}

// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. ArrowFunctionExpression in CSS prop.
const useStyles = makeStyles<StyleProps>()((theme, props, classes) => ({
  uploadWrapper: {
    width: '100%',
    position: 'relative',
    height: '100%',
    border: !props.disableBorder ? '3px dashed grey' : undefined,
    display: 'flex',
    flexDirection: 'column',
  },
  textWrapper: {
    margin: 'auto 0',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  uploadButton: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
}))

type DragAndDropProps = {
  handleDrop: (files: FileList) => void
  allowedFileTypes?: string
  uploadButtonText?: string
  optionalText?: string
  optionalTextHint?: string
  disableBorder?: boolean
}

const fileExtensionTypeMap = {
  '.pdf': 'application/pdf',
  '.json': 'application/json',
  '.txt': 'text/plain',
  '.doc': 'application/msword',
  '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
}

let dragCounter = 0

function DragAndDrop({
  handleDrop: handleDropProps,
  allowedFileTypes = 'application/pdf, .json',
  uploadButtonText = 'Datei hochladen',
  optionalText,
  optionalTextHint,
  disableBorder,
}: DragAndDropProps): React.ReactElement {
  const [drag, setDrag] = useState(false)
  // const [dragCounter, setDragCounter] = useState(0)
  const dropRef = useRef<HTMLDivElement | null>(null)
  const { classes } = useStyles({ disableBorder })

  function isAllowedFile(file: File): boolean {
    const fileExtension = '.' + file.name.split('.').pop()
    if (!allowedFileTypes.split(',').includes(fileExtension)) return false

    if (fileExtensionTypeMap[fileExtension]) {
      return file.type === fileExtensionTypeMap[fileExtension]
    }

    return false
  }

  // _____ HANDLERS _____
  function handleDrag(e: Event): void {
    e.preventDefault()
    e.stopPropagation()
  }

  function handleDragIn(e: DragEvent): void {
    e.preventDefault()
    e.stopPropagation()
    dragCounter += 1
    if (e.dataTransfer && e.dataTransfer.items && e.dataTransfer.items.length > 0 && dragCounter === 1) {
      console.log(e.dataTransfer.files)
      setDrag(true)
    }
  }

  function handleDragOut(e: Event): void {
    e.preventDefault()
    e.stopPropagation()
    dragCounter -= 1
    if (dragCounter === 0) {
      setDrag(false)
    }
  }

  // Handle files from dropping into element
  function handleDrop(e: DragEvent): void {
    e.preventDefault()
    e.stopPropagation()
    setDrag(false)
    dragCounter = 0
    if (e.dataTransfer) {
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        // ensure all files are allowed
        const filesArray = Array.from(e.dataTransfer.files)
        if (filesArray.every((file) => isAllowedFile(file))) {
          handleDropProps(e.dataTransfer.files)
          e.dataTransfer.clearData()
        }
      }
    }
  }

  // Handle Files from input button
  function handleInput(e: React.ChangeEvent<HTMLInputElement>): void {
    e.preventDefault()
    e.stopPropagation()
    setDrag(false)
    if (e.target) {
      if (e.target.files && e.target.files.length > 0) {
        handleDropProps(e.target.files)
        dragCounter = 0
      }
    }
  }

  useEffect(() => {
    const div: HTMLElement | null = dropRef.current
    if (div) {
      div.addEventListener('dragenter', handleDragIn)
      div.addEventListener('dragleave', handleDragOut)
      div.addEventListener('dragover', handleDrag)
      div.addEventListener('drop', handleDrop)
    }

    return (): void => {
      if (div) {
        div.removeEventListener('dragenter', handleDragIn)
        div.removeEventListener('dragleave', handleDragOut)
        div.removeEventListener('dragover', handleDrag)
        div.removeEventListener('drop', handleDrop)
      }
    }
  }, [])

  return (
    <div className={classes.uploadWrapper} ref={dropRef}>
      {drag ? (
        <div className={classes.textWrapper}>
          <br />
          <h4>
            Sie können die Datei hier ablegen.
            {/* <Trans>You can drop the file here.</Trans> */}
          </h4>
        </div>
      ) : (
        <div className={classes.textWrapper}>
          <input
            onChange={handleInput}
            className={classes.input}
            id='file'
            name='file'
            type='file'
            accept={allowedFileTypes}
          />
          <label htmlFor='file'>
            <Button
              variant='contained'
              size='large'
              className={classes.uploadButton}
              startIcon={<CloudUpload />}
              component='span'
            >
              {uploadButtonText}
            </Button>
          </label>
          {optionalText ? (
            <h4>
              {optionalText}
              {/* <Trans>You can drag and drop a file as well.</Trans> */}
            </h4>
          ) : null}
          {optionalTextHint ? (
            <p>
              {optionalTextHint}
              {/* <Trans>You can drag and drop a file as well.</Trans> */}
            </p>
          ) : null}
        </div>
      )}
    </div>
  )
}
export default DragAndDrop
