/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef } from 'react'
import { isEqual } from 'lodash'

/**
 * useDeepEffect hook.
 * Same functionality as useEffect, but performs a deep comparision for objects instead of references.
 * @param effectFunc
 * @param deps
 */
export function useDeepEffect(effectFunc: () => void, deps: any): any {
  const isFirst = useRef(true)
  const prevDeps = useRef(deps)

  useEffect(() => {
    const isSame = prevDeps.current.every((obj: any, index: number): boolean => isEqual(obj, deps[index]))

    if (isFirst.current || !isSame) {
      effectFunc()
    }

    isFirst.current = false
    prevDeps.current = deps
  }, deps)
}
