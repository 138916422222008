import React from 'react'
import { makeStyles } from 'tss-react/mui'

import VariablesAutosuggestSelect, { OnChangeOptions } from '../Variables/VariablesAutosuggestSelect'

import { Chart } from '../../../../../@types/Flowchart/types'

const useStyles = makeStyles()({
  selectedDialogContainer: {},
  title: {},
  marginTopMedium: { marginTop: '15px' },
})

type SPVariablesProps = {
  chart: Chart
  setStateCallback: (chart: Chart) => void
}

export default function SPVariables({ chart, setStateCallback }: SPVariablesProps): React.ReactElement {
  const { classes } = useStyles()

  /**
   * Handles variable change event.
   * Simply sets the updated chart in the parent component
   */
  function onVariableChange(
    chart: Chart,
    prevSelectedVarIds: string[],
    selectedVarIds: string[],
    options: OnChangeOptions,
  ) {
    setStateCallback(chart)
  }

  return (
    <div className={classes.selectedDialogContainer}>
      <VariablesAutosuggestSelect chart={chart} onChange={onVariableChange} usageType='set' />
    </div>
  )
}
