import React, { useState, useEffect } from 'react'
import { v4 as uuid } from 'uuid'

import CreatableSelect, { ChangeValue, ActionType } from '../../../../../components/Dropdown/ReactSelectDropdown'

// Types
import { Chart, DisplayDatacheckOption } from '../../../../../@types/Flowchart/types'
import { getDatachecks } from '../../../../../utils/datacheckUtils'

export type CheckCallbackType = {
  isNew: boolean
  props: {
    id: string
    name: string
  }
}

type DataCheckAutosuggestSelectProps = {
  chart: Chart
  setCheckCallback: (check: CheckCallbackType) => void
  dataCheckId?: string
  selectOnly: boolean
}

export default function DataCheckAutosuggestSelect({
  chart: originalChart,
  setCheckCallback,
  dataCheckId,
  selectOnly,
}: DataCheckAutosuggestSelectProps): React.ReactElement {
  const [allOptions, setAllOptions] = useState<DisplayDatacheckOption[]>([])
  const [selectedOption, setSelectedOption] = useState<DisplayDatacheckOption | null>(null)

  /**
   * Returns name (label) and id (value) of a datacheck based on its ids.
   * For usage in DatacheckAutosuggestSelect component.
   * @param {Chart} chart
   * @param {string} datacheckId
   */
  function getSelectedDatacheckOption(chart: Chart): DisplayDatacheckOption | null {
    if (typeof dataCheckId !== 'undefined') {
      return { label: chart.datachecks[dataCheckId].name, value: dataCheckId }
    } else {
      return null
    }
  }

  // ========== HANDLER =============

  /**
   * Handles selection of existing datacheck.
   * @param {DisplayDatacheckOption} checkId
   */
  function onSelect(selectedOption: DisplayDatacheckOption): void {
    const check = originalChart.datachecks[selectedOption.value]
    if (typeof check === 'undefined') return

    const chosenCheck = {
      isNew: false,
      props: {
        id: selectedOption.value,
        name: selectedOption.label,
      },
    }

    setSelectedOption(selectedOption)
    setCheckCallback(chosenCheck)
  }

  /**
   * Handles creation of datacheck.
   * @param {string | DisplayDatacheckOption} newValue
   */
  function onCreate(newValue: string | DisplayDatacheckOption): void {
    const id = uuid()
    const name = typeof newValue === 'string' ? newValue : newValue.label

    const newCheck = {
      isNew: true,
      props: {
        id,
        name,
      },
    }

    setSelectedOption({ value: id, label: name })
    setCheckCallback(newCheck)
  }

  /**
   * Handles removal of a selected datacheck.
   */
  function onRemove(): void {
    setSelectedOption(null)
  }

  /**
   * Handles clear of Autocomplete component
   */
  function onClear(): void {
    onRemove()
  }

  /**
   * Callback function for changes in the Autocomplete field.
   * @param {ChangeValue} newValue
   * @param {ActionType} action
   */
  function onChange(newValue: ChangeValue, action: ActionType): void {
    newValue = newValue as DisplayDatacheckOption

    switch (action.action) {
      case 'select-option': {
        if (newValue !== null && typeof newValue !== 'string') onSelect(newValue)
        break
      }
      case 'remove-value': {
        onRemove()
        break
      }
      case 'create-option': {
        if (newValue !== null) onCreate(newValue)
        break
      }
      case 'clear': {
        onClear()
        break
      }
      default:
        break
    }
  }

  // ========== USE EFFECT =============

  useEffect(
    function () {
      const options = getDatachecks(originalChart)
      const selected = getSelectedDatacheckOption(originalChart)
      setAllOptions(options)
      setSelectedOption(selected)
    },
    [originalChart],
  )

  // ========== RENDER =============

  return (
    <CreatableSelect
      isMulti={false}
      isCreatable={!selectOnly}
      isClearable
      onChange={onChange}
      options={allOptions}
      selectedOptions={selectedOption || []}
      placeholder={'Datencheck'}
      noOptionsMessage={'Kein Datencheck gefunden'}
      createOptionText={(inputValue) => `Neuen Datencheck "${inputValue}" erstellen`}
    />
  )
}
