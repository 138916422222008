import React, { useState, useEffect } from 'react'
import { makeStyles } from 'tss-react/mui'

// @mui/material components
import TextFieldVariablesSuggestions from '../Variables/TextFieldVariablesSuggestions'
import { replaceVarDisplayNameWithId, replaceVarIdWithDisplayName } from '../../../../../utils/chartUtils'

import { Chart } from '../../../../../@types/Flowchart/types'

const useStyles = makeStyles()({
  selectedDialogContainer: {},
  title: {},
})

type SBMTextsProps = {
  chart: Chart
  setStateCallback: (chart: Chart) => void
}

export default function SBMTexts({ chart, setStateCallback }: SBMTextsProps): React.ReactElement {
  const { classes } = useStyles()

  const [title, setTitle] = useState<string>()
  const [text, setText] = useState<string>()

  /**
   * Handles textfield text change
   * @param event
   */
  function onTextChange(value: string): void {
    const selectedId = chart.selected?.id
    if (typeof selectedId === 'undefined') return

    value = replaceVarDisplayNameWithId(chart, value)

    chart.nodes[selectedId].properties.text = value
    setStateCallback({ ...chart })
  }

  useEffect(
    function () {
      const selectedId = chart.selected?.id
      if (typeof selectedId === 'undefined') return

      let text = chart.nodes[selectedId].properties.text || ''
      text = replaceVarIdWithDisplayName(chart, text)
      const title = chart.nodes[selectedId].properties.typeText

      setText(text)
      setTitle(title)
    },
    [chart],
  )

  return (
    <div className={classes.selectedDialogContainer}>
      <h5 className={classes.title}>{title}</h5>
      <div>
        <TextFieldVariablesSuggestions
          id='outlined-multiline-static'
          label='Nachricht'
          chart={chart}
          multiline
          fullWidth
          maxRows={15}
          value={text}
          onChange={onTextChange}
        />
      </div>
    </div>
  )
}
