import React from 'react'

// @mui/material
import { makeStyles } from 'tss-react/mui'

import CircularProgress from '@mui/material/CircularProgress'
// I18n
// import { Trans } from '@lingui/macro'
// Custom Components
import { ErrorBox, WarningBox } from '../AlertBoxes/AlertBox'

const useStyles = makeStyles()((theme) => ({
  infoWrapper: {
    height: '100%',
    width: '100%',
    display: 'relative',
    paddingTop: '50%',
  },
  loadingWrapper: {
    height: '100%',
    width: '100%',
    display: 'relative',
    paddingTop: '50%',
  },
  pdfProgress: {
    color: theme.palette.text.primary,
    width: 'auto',
  },
}))

export function Loading(): React.ReactElement {
  const { classes } = useStyles()
  return (
    <div className={classes.loadingWrapper}>
      <CircularProgress className={classes.pdfProgress} size={'3rem'} />
    </div>
  )
}

export function LoadingDocument(): React.ReactElement {
  const { classes } = useStyles()
  return (
    <div className={classes.loadingWrapper}>
      <CircularProgress className={classes.pdfProgress} size={'3rem'} />
      <br />
      <h3>
        Lade Dokument ...
        {/* <Trans>Loading document ...</Trans> */}
      </h3>
    </div>
  )
}

export function AnalyzingDocument(): React.ReactElement {
  const { classes } = useStyles()
  return (
    <div className={classes.loadingWrapper}>
      <CircularProgress className={classes.pdfProgress} size={'3rem'} />
      <br />
      <h3>
        {/* TODO: I18n */}
        Dokument wird analysiert ...
      </h3>
    </div>
  )
}

export function LoadingPage(): React.ReactElement {
  const { classes } = useStyles()
  return (
    <div className={classes.loadingWrapper}>
      <CircularProgress className={classes.pdfProgress} size={'3rem'} />
      <br />
      <h3>
        Lade Seite ...
        {/* <Trans>Loading page ...</Trans> */}
      </h3>
    </div>
  )
}

export function LoadingError({ bttn }: { bttn: React.ReactNode }): React.ReactElement {
  const { classes } = useStyles()
  return (
    <div className={classes.infoWrapper}>
      <ErrorBox action={bttn}>
        <p>
          Das Dokument konnte nicht geladen werden. Bitte versuchen Sie es erneut.
          {/* <Trans>The document could not be loaded. Please try again.</Trans> */}
        </p>
      </ErrorBox>
    </div>
  )
}

export function ErrorNoFileSpecified({ bttn }: { bttn: React.ReactNode }): React.ReactElement {
  const { classes } = useStyles()
  return (
    <div className={classes.infoWrapper}>
      <WarningBox action={bttn}>
        <p>
          Kein Dokument angegeben. Bitte erneut versuchen.
          {/* <Trans>No document specified. Please try again.</Trans> */}
        </p>
      </WarningBox>
    </div>
  )
}

export function ErrorTooManyFiles({ bttn }: { bttn: React.ReactNode }): React.ReactElement {
  const { classes } = useStyles()
  return (
    <div className={classes.infoWrapper}>
      <WarningBox action={bttn}>
        <p>
          Zu viele Dateien. Bitte laden Sie nur eine Datei hoch.
          {/* <Trans>Trying to upload too many files. Please only upload one file.</Trans> */}
        </p>
      </WarningBox>
    </div>
  )
}

export function ErrorWrongFileType({ bttn }: { bttn: React.ReactNode }): React.ReactElement {
  const { classes } = useStyles()
  return (
    <div className={classes.infoWrapper}>
      <WarningBox action={bttn}>
        <p>
          Die hochgeladene Datei kann von Convaise Studio nicht importiert werden. Bitte laden Sie nur PDF oder JSON
          hoch.
          {/* <Trans>The uploaded file can not be imported by Convaise Studio. Please upload PDF or Json.</Trans> */}
        </p>
      </WarningBox>
    </div>
  )
}

export function ErrorTooLargeFile({ bttn }: { bttn: React.ReactNode }): React.ReactElement {
  const { classes } = useStyles()
  return (
    <div className={classes.infoWrapper}>
      <WarningBox action={bttn}>
        <p>
          Die von Ihnen angegebene Datei ist größer als 10Mb. Bitte laden Sie eine Datei kleiner als 10Mb hoch.
          {/* <Trans>
            The uploaded file exceedes the size of 10Mb and can not be analyzed by Convaise Studio. Please upload a file
            smaller than 10Mb.
          </Trans> */}
        </p>
      </WarningBox>
    </div>
  )
}

export function ErrorConnectionFailed({ bttn }: { bttn: React.ReactNode }): React.ReactElement {
  const { classes } = useStyles()
  return (
    <div className={classes.infoWrapper}>
      <WarningBox action={bttn}>
        <p>
          Die Datei konnte nicht hochgeladen werden. Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es
          erneut.
          {/* <Trans>
            The uploaded file exceedes the size of 10Mb and can not be analyzed by Convaise Studio. Please upload a file
            smaller than 10Mb.
          </Trans> */}
        </p>
      </WarningBox>
    </div>
  )
}
