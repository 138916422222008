import React, { useState, useEffect } from 'react'

// @mui/material components
import xzufiSearchLeistung from 'assets/img/modules/xzufi_search_leistung.svg'
import { makeStyles } from 'tss-react/mui'
import { Chart } from '../../../../../@types/Flowchart/types'
import SelectDropdown, { Option } from '../../../../../components/Dropdown/SelectDropdown'
import { Typography } from '@mui/material'
import ContentContainer from '../ContentContainer'
import { useBotContext } from 'hooks/contexts/bot-context'
import { NLUModule } from '../../../../../@types/BotInformation/types'
import CustomContentTooltip from 'components/Tooltips/CustomContentTooltip'
import VariablesAutosuggestSelect from '../Variables/VariablesAutosuggestSelect'
import { MODULE_TYPE_NLU } from 'utils/constants'

const useStyles = makeStyles()((theme) => ({
  container: { height: '100%' },
  inputWithInfo: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  inputInfoIcon: {
    marginLeft: theme.spacing(1),
    height: '20px',
    color: theme.palette.grey[700],
  },
  moduleSelection: {
    marginTop: theme.spacing(4),
  },
  variables: {
    marginTop: theme.spacing(4),
  },
  varSelectionContainer: {
    marginTop: theme.spacing(2),
  },
}))

enum VariableSelectionPurpose {
  TRIGGER_INTENT,
  ANSWER,
  CORRELATION_ID,
  ACTIONS,
  QUESTION,
  POTENTIAL_ANSWERS,
}

type SQnAGetAnswerProps = {
  chart: Chart
  setIsSaveDisabled: (isSaveDisabled: boolean) => void
  setStateCallback: (chart: Chart) => void
}

export default function SQnAGetAnswer({
  chart,
  setIsSaveDisabled,
  setStateCallback,
}: SQnAGetAnswerProps): React.ReactElement {
  const { classes } = useStyles()
  const { bot } = useBotContext()

  // module select
  const [moduleOptions, setModuleOptions] = useState<Option[]>([])
  const [selectedModuleId, setSelectedModuleId] = useState<string>()
  // variables select
  const [varIdAnswer, setVarIdAnswer] = useState<string>() // id of variable for storing the retrieved answer
  const [varIdActions, setVarIdActions] = useState<string>() // id of variable for storing the actions of the retrieved answer (if any)
  const [varIdCorrId, setVarIdCorrId] = useState<string>() // id of variable for storing the correlation id of the answer
  const [varIdTriggerIntent, setVarIdTriggerIntent] = useState<string>() // id of variable for storing intent of the trigger answer
  const [varIdQuery, setVarIdQuery] = useState<string>() // id of variable for storing the user query
  const [varIdPotentialAnswers, setVarIdPotentialAnswers] = useState<string | null>() // id of variable for storing the potential answers (can be empty)

  /**
   * Reads all qna modules configured for this bot and prepares them for the dropdown.
   * If only one qna module exists, sets it.
   */
  function prepareModuleOptions(): void {
    if (!bot) return

    const nluModules = Object.values(bot.modules).filter((module) => module.type === MODULE_TYPE_NLU)
    const moduleOptions = nluModules.map((module) => {
      const { type, moduleConfigId } = module as NLUModule
      return {
        label: `${type === 'nlu' ? 'QnA' : type}`,
        value: moduleConfigId,
      }
    })

    setModuleOptions(moduleOptions)
    if (moduleOptions.length === 1) {
      // only 1 possible module -> select it
      onModuleSelect({ label: '', value: moduleOptions[0].value })
    }
  }

  // ========= HANDLER ============

  /**
   * Handles module selection
   * @param newValue
   */
  function onModuleSelect(newValue: Option): void {
    const selectedId = chart.selected?.id
    if (typeof selectedId === 'undefined') return
    const node = chart.nodes[selectedId]
    if (typeof node === 'undefined') return

    const moduleId = newValue.value

    node.properties.moduleConfigId = moduleId

    // also set new text for display in dialog designer
    const module = bot?.modules[moduleId] as NLUModule
    if (module) {
      node.properties.text = `${module.type === 'nlu' ? 'QnA -' : ''} Antwort suchen`
    }
    if (module) setSelectedModuleId(moduleId)

    chart.nodes[selectedId] = node
    setStateCallback(chart)
  }

  /**
   * Sets the variable in the node properties and updates the chart
   * @param selectedVarId
   * @param purpose
   */
  function onVariableSelect(newChart: Chart, purpose: VariableSelectionPurpose, selectedVarId?: string | null): void {
    const selectedId = newChart.selected?.id
    if (typeof selectedId === 'undefined') return
    const node = newChart.nodes[selectedId]
    if (typeof node === 'undefined') return

    if (!node.properties.qna) node.properties.qna = {}

    switch (purpose) {
      case VariableSelectionPurpose.ACTIONS:
        if (selectedVarId) node.properties.qna.actions = selectedVarId
        break
      case VariableSelectionPurpose.TRIGGER_INTENT:
        if (selectedVarId) node.properties.qna.intent = selectedVarId
        break
      case VariableSelectionPurpose.CORRELATION_ID:
        if (selectedVarId) node.properties.qna.corrId = selectedVarId
        break
      case VariableSelectionPurpose.QUESTION:
        if (selectedVarId) node.properties.qna.question = selectedVarId
        break
      case VariableSelectionPurpose.ANSWER:
        if (selectedVarId) node.properties.qna.answer = selectedVarId
        break
      case VariableSelectionPurpose.POTENTIAL_ANSWERS:
        node.properties.qna.potentialAnswers = selectedVarId
        break
    }

    newChart.nodes[selectedId] = node
    setStateCallback(newChart)
  }

  /**
   * Handles variable selection for answer actions
   * @param newChart
   * @param prevSelectedVarIds
   * @param selectedVarIds
   */
  function onVariableForActionsSelect(newChart: Chart, prevSelectedVarIds: string[], selectedVarIds: string[]): void {
    const selectedVariableId = selectedVarIds.length > 0 ? selectedVarIds[0] : undefined
    onVariableSelect(newChart, VariableSelectionPurpose.ACTIONS, selectedVariableId)
  }

  /**
   * Handles variable selection for trigger intent
   * @param newChart
   * @param prevSelectedVarIds
   * @param selectedVarIds
   */
  function onVariableForTriggerIntentSelect(
    newChart: Chart,
    prevSelectedVarIds: string[],
    selectedVarIds: string[],
  ): void {
    const selectedVariableId = selectedVarIds.length > 0 ? selectedVarIds[0] : undefined
    onVariableSelect(newChart, VariableSelectionPurpose.TRIGGER_INTENT, selectedVariableId)
  }

  /**
   * Handles variable selection for correlation id
   * @param newChart
   * @param prevSelectedVarIds
   * @param selectedVarIds
   */
  function onVariableForCorrIdSelect(newChart: Chart, prevSelectedVarIds: string[], selectedVarIds: string[]): void {
    const selectedVariableId = selectedVarIds.length > 0 ? selectedVarIds[0] : undefined
    onVariableSelect(newChart, VariableSelectionPurpose.CORRELATION_ID, selectedVariableId)
  }

  /**
   * Handles variable selection for user query
   * @param newChart
   * @param prevSelectedVarIds
   * @param selectedVarIds
   */
  function onVariableForQuestionSelect(newChart: Chart, prevSelectedVarIds: string[], selectedVarIds: string[]): void {
    const selectedVariableId = selectedVarIds.length > 0 ? selectedVarIds[0] : undefined
    onVariableSelect(newChart, VariableSelectionPurpose.QUESTION, selectedVariableId)
  }

  /**
   * Handles variable selection for answer
   * @param newChart
   * @param prevSelectedVarIds
   * @param selectedVarIds
   */
  function onVariableForAnswerSelect(newChart: Chart, prevSelectedVarIds: string[], selectedVarIds: string[]): void {
    const selectedVariableId = selectedVarIds.length > 0 ? selectedVarIds[0] : undefined
    onVariableSelect(newChart, VariableSelectionPurpose.ANSWER, selectedVariableId)
  }

  /**
   * Handles variable selection for potentialAnswers
   * @param newChart
   * @param prevSelectedVarIds
   * @param selectedVarIds
   */
  function onVariableForPotentialAnswersSelect(
    newChart: Chart,
    prevSelectedVarIds: string[],
    selectedVarIds: string[],
  ): void {
    const selectedVariableId = selectedVarIds.length > 0 ? selectedVarIds[0] : null
    onVariableSelect(newChart, VariableSelectionPurpose.POTENTIAL_ANSWERS, selectedVariableId)
  }

  // function onSelectEvent(event): void {
  //   const selectedId = chart.selected?.id
  //   if (typeof selectedId === 'undefined') return
  //   const node = chart.nodes[selectedId]
  //   if (typeof node === 'undefined') return
  //   // set event property with AnalyticsEvent value
  //   node.properties.event = event.value
  //   chart.nodes[selectedId] = node
  //   // setSelectedEvent(event)
  //   setStateCallback(chart)
  // }

  useEffect(
    function () {
      const selectedId = chart.selected?.id
      if (typeof selectedId === 'undefined') return
      // get already selected Event
      const node = chart.nodes[selectedId]

      prepareModuleOptions()
      setVarIdAnswer(node.properties.qna?.answer)
      setVarIdActions(node.properties.qna?.actions)
      setVarIdCorrId(node.properties.qna?.corrId)
      setVarIdQuery(node.properties.qna?.question)
      setVarIdTriggerIntent(node.properties.qna?.intent)
      setVarIdPotentialAnswers(node.properties.qna?.potentialAnswers)
    },
    [chart, bot],
  )

  useEffect(
    function () {
      if (selectedModuleId && varIdAnswer && varIdCorrId && varIdActions && varIdQuery && varIdTriggerIntent) {
        setIsSaveDisabled(false)
      } else {
        setIsSaveDisabled(true)
      }
    },
    [selectedModuleId, varIdAnswer, varIdCorrId, varIdActions, varIdQuery, varIdTriggerIntent],
  )

  return (
    <div className={classes.container}>
      <ContentContainer headerText='QnA Modul: Leistung suchen' descriptionText='' svg={xzufiSearchLeistung}>
        <div className={classes.moduleSelection}>
          <div className={classes.inputWithInfo}>
            <Typography>QnA Modul</Typography>
            <CustomContentTooltip
              content={<Typography>Bitte wählen Sie das QnA Modul Ihres Assistenten.</Typography>}
              elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
            />
          </div>
          <SelectDropdown
            onChange={onModuleSelect}
            options={moduleOptions}
            selected={selectedModuleId}
            placeholder='QnA Modul auswählen'
            width='100%'
          />
        </div>
        <div className={classes.variables}>
          <div className={classes.varSelectionContainer}>
            <div className={classes.inputWithInfo}>
              <Typography>Variable zum Laden der Nutzerfrage</Typography>
              <CustomContentTooltip
                content={
                  <Typography>
                    Bite wählen sie eine Variable, in der die Anfrage des/der Nutzer*in gespeichert ist, die beantwortet
                    werden soll.
                  </Typography>
                }
                elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
              />
            </div>
            <VariablesAutosuggestSelect
              chart={chart}
              onChange={onVariableForQuestionSelect}
              usageType='set'
              isMulti={false}
              isCreatable
              isClearable
              selectedVariableIds={varIdQuery ? [varIdQuery] : []}
            />
          </div>
          <div className={classes.varSelectionContainer}>
            <div className={classes.inputWithInfo}>
              <Typography>Variable zum Speichern der Antwort</Typography>
              <CustomContentTooltip
                content={
                  <Typography>
                    Bitte wählen Sie eine Variable, in der die gegebene Antwort gespeichert werden sollen.
                  </Typography>
                }
                elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
              />
            </div>
            <VariablesAutosuggestSelect
              chart={chart}
              onChange={onVariableForAnswerSelect}
              usageType='set'
              isMulti={false}
              isCreatable
              isClearable
              selectedVariableIds={varIdAnswer ? [varIdAnswer] : []}
            />
          </div>
          <div className={classes.varSelectionContainer}>
            <div className={classes.inputWithInfo}>
              <Typography>Variable zum Speichern der Folgefragen der gegebenen Antwort</Typography>
              <CustomContentTooltip
                content={
                  <Typography>
                    Bitte wählen sie eine Variable, in der die Folgefragen der gegebenen Antwort gespeichert werden
                    sollen.
                  </Typography>
                }
                elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
              />
            </div>
            <VariablesAutosuggestSelect
              chart={chart}
              onChange={onVariableForActionsSelect}
              usageType='set'
              isMulti={false}
              isCreatable
              isClearable
              selectedVariableIds={varIdActions ? [varIdActions] : []}
            />
          </div>
          <div className={classes.varSelectionContainer}>
            <div className={classes.inputWithInfo}>
              <Typography>Variable zum Speichern des Trigger Dialogs</Typography>
              <CustomContentTooltip
                content={
                  <Typography>
                    Bitte wählen sie eine Variable, in der der zu triggernde Dialog gespeichert werden soll.
                  </Typography>
                }
                elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
              />
            </div>
            <VariablesAutosuggestSelect
              chart={chart}
              onChange={onVariableForTriggerIntentSelect}
              usageType='set'
              isMulti={false}
              isCreatable
              isClearable
              selectedVariableIds={varIdTriggerIntent ? [varIdTriggerIntent] : []}
            />
          </div>
          <div className={classes.varSelectionContainer}>
            <div className={classes.inputWithInfo}>
              <Typography>Variable zum Speichern der Feedback-ID</Typography>
              <CustomContentTooltip
                content={
                  <Typography>
                    Bitte wählen Sie eine Variable, in der die Feedback-ID der Suchanfrage gespeichert werden soll. Die
                    Feedback-ID wird verwendet, um bei der Bewertung der Suchergebnisse die korrekte Zuordnung zu der
                    Suche zu ermöglichen.
                  </Typography>
                }
                elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
              />
            </div>
            <VariablesAutosuggestSelect
              chart={chart}
              onChange={onVariableForCorrIdSelect}
              usageType='set'
              isMulti={false}
              isCreatable
              isClearable
              selectedVariableIds={varIdCorrId ? [varIdCorrId] : []}
            />
          </div>
          <div className={classes.varSelectionContainer}>
            <div className={classes.inputWithInfo}>
              <Typography>Variable zum Speichern von potentiellen Antworten (Optional)</Typography>
              <CustomContentTooltip
                content={
                  <Typography>
                    Bitte wählen Sie eine Variable, in der ein Array an potentiellen Antworten gespeichert wird.
                    Potentielle Antworten sind Antworten, die der Nutzer*in angeboten werden können, wenn das QnA Moduls
                    keine sichere Antwort geben kann.
                  </Typography>
                }
                elements={<i className={'ri-information-line ' + classes.inputInfoIcon}></i>}
              />
            </div>
            <VariablesAutosuggestSelect
              chart={chart}
              onChange={onVariableForPotentialAnswersSelect}
              usageType='set'
              isMulti={false}
              isCreatable
              isClearable
              selectedVariableIds={varIdPotentialAnswers ? [varIdPotentialAnswers] : []}
            />
          </div>
        </div>
      </ContentContainer>
    </div>
  )
}
