import React, { useState, useEffect, Fragment, useCallback } from 'react'
import { v4 as uuid } from 'uuid'
import { useDeepEffect } from '../../hooks/effects/useDeepEffect'

// types
import { Field, Dimensions, Coordinates, Container, InfotextType, SyncedCoordinates } from '../../@types/Ingest/types'
import { IngestPDFInfoText, IngestPDFResult } from '../../classes/Ingest'

// custom components
import InfotextPopup from '../Popups/InfotextPopup'

// @mui/material
import { makeStyles } from 'tss-react/mui'

import { DropdownOption } from '../Popups/popupElements/PopupDropdown'

const CONTAINER_PADDING = 5

const useStyles = makeStyles()((theme) => ({
  containerHighlight: {
    position: 'absolute',
    background: 'rgba(0, 0, 0, 0.1)',
    cursor: 'pointer',
    borderRadius: theme.shape.borderRadius,
  },
  fieldHightlight: {
    position: 'absolute',
    borderRadius: theme.shape.borderRadius,
  },
}))

type TextSelection = {
  id: string
  text: string
  coords: SyncedCoordinates
  page: number
}

type Props = {
  syncedSignature: Field[]
  target: HTMLDivElement
  pageNumber: number
  onSignatureChange: (element: Field[]) => void
  ingestPDFResult: IngestPDFResult
  setIngestPDFResult: (e: IngestPDFResult) => void
}

// TODO: Styling via classes not inline styles
// TODO: Colors for Containers
// TODO: Handle differently sized PDF pages
// TODO: Area selection of infotexts (OCR approach)
function InfotextHighlights(props: Props): React.ReactElement {
  const [targetCoordinates, setTargetCoordinates] = useState<Coordinates>({ x: 0, y: 0, h: 0, w: 0 })
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false)
  const [popUpStartingPoint, setPopUpStartingPoint] = useState({ x: 0, y: 0 })
  const [popupSelectedSubsections, setPopupSelectedSubsections] = useState<DropdownOption[]>([]) // selected subsections for display in popup
  const [popupSubsectionOptions, setPopupSubsectionOptions] = useState<DropdownOption[]>([]) // available subsections for display in popup
  const [containers, setContainers] = useState<Container[]>([]) // holds containers that should be highlighted
  const [textSelection, setTextSelection] = useState<TextSelection | null>(null) // stores selection until Popup Confirm or Cancel
  const { classes } = useStyles()

  // _____ Preparation _____
  function preparePopupDropdown(): void {
    // prepare subsection dropdown options
    const options: DropdownOption[] = []
    const subsectionKeys = Object.keys(props.ingestPDFResult.regions.subsections)
    for (const key of subsectionKeys) {
      options.push({
        label: props.ingestPDFResult.regions.subsections[key].name,
        value: props.ingestPDFResult.regions.subsections[key].id,
      })
    }
    setPopupSubsectionOptions(options)

    // prepare selected subsections
    if (props.ingestPDFResult.selectedId) {
      const selectedId = props.ingestPDFResult.selectedId
      const selectedOptions = options.filter((option) => {
        return props.ingestPDFResult.infotexts[selectedId].subsections.find((subsectionId) => {
          return subsectionId === option.value
        })
      })
      setPopupSelectedSubsections(selectedOptions)
    } else {
      setPopupSelectedSubsections([])
    }
  }

  // _____ Positions & calculations  _____

  /**
   * Returns the height and width of the target prop based on getBoundingClientRect()
   */
  function getTargetCoordinates(): Coordinates {
    if (props.target) {
      const _boundingBox = props.target.getBoundingClientRect()
      return { h: _boundingBox.height, w: _boundingBox.width, x: _boundingBox.x, y: _boundingBox.y }
    }
    return { h: 0, w: 0, x: 0, y: 0 }
  }

  /**
   * Returns original PDF page dimensions of current page number
   * Iterates over fields until first field's page number matches current page.
   *
   * // TODO: This might be a problem if a page has no fields.
   * //       For now we then use the dimens of some fields,
   * //       but PDFs with pages that have different dimensions could be problematic.
   */
  function getCurrentPageDimens(): Dimensions | null {
    for (const field of props.syncedSignature) {
      if (field.page === props.pageNumber) {
        return { w: field.pageDimens.original.w, h: field.pageDimens.original.h }
      }
    }
    // no field on this page found
    // get first field and return its page dimensions
    if (props.syncedSignature && props.syncedSignature.length > 0) {
      return { w: props.syncedSignature[0].pageDimens.original.w, h: props.syncedSignature[0].pageDimens.original.h }
    }

    return null
  }

  /**
   * Calculates coordinates of text selection relative to PDF.
   * In other words: The exact position in the actual PDF.
   * Scales to PDF.
   */
  function calculateOriginalCoordsInPDF(boundingRect: Coordinates): Coordinates {
    // 1. calculate position of selection relative to displayed PDF (inside PDFViewer)
    const syncCoords = targetCoordinates ? targetCoordinates : getTargetCoordinates()

    // 2. calculate x and y ratio of PDFViewer dimens <-> PDF dimens
    const pdfDimens = getCurrentPageDimens()
    if (!pdfDimens)
      // if we can not determine PDF page dimensions, we return coordinates relative to PDFViewer
      return { x: syncCoords.x, y: syncCoords.y, w: syncCoords.w, h: syncCoords.h }

    // 3. adjust position of selection using calculated ratios
    // ratio = PDF dimens / PDFView dimens
    const xRatio = syncCoords.w / pdfDimens.w
    const yRatio = syncCoords.h / pdfDimens.h
    const xOriginal = Math.round(boundingRect.x / xRatio)
    const hOriginal = Math.round(boundingRect.h / yRatio)
    const wOriginal = Math.round(boundingRect.w / xRatio)
    // first calculate left lower y value of sync box, then adjust to original PDF dimensions
    const yOriginal = (syncCoords.h - (boundingRect.y + boundingRect.h)) / yRatio

    return { x: xOriginal, y: yOriginal, h: hOriginal, w: wOriginal }
  }

  // _____ HANDLERS _____
  /**
   * Sets selected container, sets id in ingestPDFResult and calls callback.
   * @param index
   */
  function onContainerClick(index: number): void {
    setIsPopupOpen(true)
    const container = containers[index]
    props.ingestPDFResult.setSelectedId(container.id)
    props.setIngestPDFResult(props.ingestPDFResult)
  }

  /**
   * Calculates where the PopUp should be opended
   * @param containerCoords Coordinates of the connected Container
   */
  function calculateStartPositionPopUp(containerCoords: Coordinates): { x: number; y: number } {
    // For now very simple RuleSet
    if (containerCoords.y + containerCoords.h > 450) {
      if (containerCoords.y - 200 >= 0) {
        return { x: containerCoords.x, y: containerCoords.y - 200 }
      } else if (containerCoords.y - 100 >= 0) {
        return { x: containerCoords.x, y: containerCoords.y - 100 }
      } else {
        return { x: containerCoords.x, y: containerCoords.y }
      }
    } else {
      return { x: containerCoords.x, y: containerCoords.y + containerCoords.h + CONTAINER_PADDING }
    }
  }

  /**
   * Creates container for display in the PDFView.
   * @param id id of the infotext object
   * @param coords window coordinates of the box (not the PDF coords!)
   * @param page page number
   */
  function createContainerBox(id: string, coords: SyncedCoordinates, page: number): void {
    // define new container
    const container: Container = {
      id: id,
      box: coords,
      page: page,
    }

    // Add new container
    const _containers = containers
    _containers.push(container)

    // calculate startping position of new popup
    setPopUpStartingPoint(calculateStartPositionPopUp(container.box.sync))

    setContainers(_containers)
  }

  /**
   * Removes browser text highlighting of selection.
   */
  function clearBrowserSelection(): void {
    const selection = window.getSelection ? window.getSelection() : null
    if (selection) selection.empty ? selection.empty() : selection.removeAllRanges()
  }

  function registerOnKeyDownEventListener(): void {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    document.addEventListener('keydown', onKeyDown, true)
  }

  function removeOnKeyDownEventListener(): void {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    document.removeEventListener('keydown', onKeyDown, true)
  }

  /**
   * MouseUp event handler for handling text selection.
   * Sets selected text, gets bounding rect coordinates of selection, adjusts them to PDF coordinates and sets them.
   * We need to use useCallback to memoize the function so that we can properly remove the event listener when the popup is open.
   * @param event mouse up event.
   */
  const onMouseUp = useCallback(
    function _onMouseUp(event: MouseEvent): void {
      const selection = document.getSelection()
      if (!isPopupOpen && selection && selection.rangeCount > 0 && props.target) {
        const selectedText = selection.toString()
        if (!selectedText) return
        const boundingRect = selection.getRangeAt(0).getBoundingClientRect()
        const parentCoords = props.target.getBoundingClientRect()
        const adjustedCoords = {
          x: boundingRect.x - window.scrollX - parentCoords.x,
          y: boundingRect.y - window.scrollY - parentCoords.y,
          w: boundingRect.width,
          h: boundingRect.height,
        }

        const x = boundingRect.x - parentCoords.x
        const y = boundingRect.y - parentCoords.y

        // clear selection (removes browser highlighting) -> we only use container
        clearBrowserSelection()

        const textSelection = {
          id: uuid(),
          text: selectedText,
          coords: {
            sync: { x: adjustedCoords.x, y: adjustedCoords.y, w: adjustedCoords.w, h: adjustedCoords.h },
            original: calculateOriginalCoordsInPDF(adjustedCoords),
          },
          page: props.pageNumber,
        }
        registerOnKeyDownEventListener()
        setTextSelection(textSelection)
        // add to containers for displaying container over selection
        createContainerBox(textSelection.id, textSelection.coords, textSelection.page)
        // open popup
        setIsPopupOpen(true)
        event.preventDefault()
      }
    },
    [containers, isPopupOpen],
  )

  function removeOnMouseUpEventListener(): void {
    const _target = props.target
    if (_target) {
      _target.removeEventListener('mouseup', onMouseUp, true)
    }
  }

  function registerOnMouseUpEventListener(): void {
    const _target = props.target
    if (_target) {
      _target.addEventListener('mouseup', onMouseUp, true)
    }
  }

  // _____ POPUP ______

  /**
   * Cleans up after popup is closed (either through confirm, close or delete)
   */
  function popupCleanup(): void {
    setTextSelection(null)
    setIsPopupOpen(false)
    clearBrowserSelection()
    registerOnMouseUpEventListener()
  }

  /**
   * Handles popup confirm.
   * Sets infotext object in PDFIngestResult object and calls callback funtion.
   */
  function onPopupConfirm(type: InfotextType, name: string, sections: DropdownOption[] | undefined): void {
    if (textSelection) {
      // create UUID
      const id = textSelection.id
      const infotext = new IngestPDFInfoText(
        id,
        name,
        type,
        textSelection.text,
        props.pageNumber,
        textSelection.coords,
        sections ? sections.map((section) => section.value) : [],
      )
      const ingestPDFResult = props.ingestPDFResult
      ingestPDFResult.infotexts[id] = infotext
      ingestPDFResult.removeSelectedId()
      props.setIngestPDFResult(ingestPDFResult)
      popupCleanup()
    }
  }

  function onPopupDelete(): void {
    if (textSelection) {
      const id = textSelection.id
      // remove container
      const filteredContainers = containers.filter((container) => container.id !== id)
      setContainers(filteredContainers)
      // remove infotext from ingestPDFResult
      const ingestPDFResult = props.ingestPDFResult
      ingestPDFResult.removeSelectedId()
      ingestPDFResult.removeInfoText(id)
      props.setIngestPDFResult(ingestPDFResult)
      popupCleanup()
    }
  }

  function onPopupClose(): void {
    // remove container if popup is closed and infotext is not persisted on ingestPDFResult
    if (textSelection && !Object.keys(props.ingestPDFResult.infotexts).includes(textSelection.id)) {
      const filteredContainers = containers.filter((container) => container.id !== textSelection.id)
      setContainers(filteredContainers)
    }
    const ingestPDFResult = props.ingestPDFResult
    ingestPDFResult.removeSelectedId()
    props.setIngestPDFResult(ingestPDFResult)
    popupCleanup()
  }

  const onKeyDown = useCallback(
    function _onKeyDown(event: KeyboardEvent): void {
      if (event.key === 'Escape') {
        // ESC is pressed
        // removeOnKeyDownEventListener()
        onPopupClose()
        event.preventDefault()
      }
    },
    [isPopupOpen],
  )

  // _____ EFFECTS ______

  // build container from infotexts in ingestPDFResult
  useDeepEffect(() => {
    const containers: Container[] = []
    let tSelection = textSelection
    let selectedContainerIndex
    Object.values(props.ingestPDFResult.infotexts).forEach((infotext, index) => {
      if (infotext.id === props.ingestPDFResult.selectedId) {
        // build textSelection object
        tSelection = {
          id: infotext.id,
          text: infotext.text,
          coords: infotext.coords,
          page: infotext.page,
        }
        selectedContainerIndex = index
      }
      const container: Container = {
        id: infotext.id,
        box: infotext.coords,
        page: infotext.page,
      }
      containers.push(container)
    })

    setContainers(containers)
    if (selectedContainerIndex !== undefined && tSelection) {
      // set popup starting point
      setPopUpStartingPoint(calculateStartPositionPopUp(containers[selectedContainerIndex].box.sync))
      setTextSelection(tSelection)
    } else {
      setTextSelection(null)
    }
  }, [props.ingestPDFResult.selectedId, props.ingestPDFResult.infotexts])

  // As soon as a signature exists, calculate target dimensions
  useEffect(() => {
    setTargetCoordinates(getTargetCoordinates())
  }, [props.syncedSignature])

  // Prepares subsection dropdown options & selected options for popup
  useDeepEffect(() => {
    preparePopupDropdown()
  }, [props.ingestPDFResult.selectedId])

  // Register event listener for text selection
  // NOTE: useEffect behaviour: If deps change return function is run before useEffect is run.
  useEffect(() => {
    isPopupOpen ? removeOnMouseUpEventListener() : registerOnMouseUpEventListener()
    return (): void => {
      // remove eventlistener when component will unmount or on deps change
      removeOnMouseUpEventListener()
    }
  }, [containers, isPopupOpen])

  // registers onKeyDownEventListener if popup is open, removes it once popup is closed
  useEffect(() => {
    isPopupOpen ? registerOnKeyDownEventListener() : removeOnKeyDownEventListener()
    return (): void => {
      removeOnKeyDownEventListener()
    }
  }, [isPopupOpen])

  // _____ RENDER _____

  function renderContainers(): React.ReactElement {
    return (
      <Fragment>
        {/* Display overlay containers */}
        {containers
          // eslint-disable-next-line react/prop-types
          .filter((container) => container.page === props.pageNumber)
          .map((container, index) => {
            return (
              <div
                id={`container_highlight_${index}`}
                key={container.id}
                className={classes.containerHighlight}
                style={{
                  top: container.box.sync.y,
                  left: container.box.sync.x,
                  width: container.box.sync.w,
                  height: container.box.sync.h,
                }}
                onClick={(event): void => {
                  onContainerClick(index)
                  event.preventDefault()
                }}
              />
            )
          })}
      </Fragment>
    )
  }

  return (
    <Fragment>
      <div id='containers'>
        {/* {renderFields()} */}
        {renderContainers()}
      </div>
      {textSelection !== null && (
        <InfotextPopup
          parent={props.target}
          startPosition={popUpStartingPoint}
          title={'Infotext Bearbeiten'} // TODO: what is the title supposed to be? - I18n
          name={
            props.ingestPDFResult.selectedId !== undefined
              ? props.ingestPDFResult.infotexts[props.ingestPDFResult.selectedId].title
              : undefined
          }
          text={textSelection.text}
          selectionOptions={popupSubsectionOptions}
          selectedSections={popupSelectedSubsections}
          onConfirm={onPopupConfirm}
          onClose={onPopupClose}
          onDelete={onPopupDelete}
        />
      )}
      <div id='container_controls'></div>
    </Fragment>
  )
}

InfotextHighlights.defaultProps = {
  pageNumber: 0,
}

export default InfotextHighlights
